import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import { Heading } from '@components/atoms/heading/Heading';
import React from 'react';
import SEO from '@components/atoms/seo/Seo';

const ContentSchools = (context) => {
  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        Schools
      </Heading>
    </AdminLayout>
  );
};

export default ContentSchools;
