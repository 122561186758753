import {
  BtnContainer,
  BtnLink,
  BtnText,
  GroupDescription,
  GroupIconsRow,
  GroupIconsWrapper,
  GroupInfo,
  GroupMembersAvatars,
  GroupMembersWrapper,
  GroupModerator,
  GroupModeratorsWrapper,
  ShowGuidelinesBtn,
  ShowMoreButton,
  SideNav,
  SideNavContainer,
  SideSocialsContainer,
  StyledSvg,
} from './GroupSidebarLeft.styled';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import Avatar from '@components/atoms/avatar/Avatar';
import GuidelineModal from '@components/molecules/guidelinesModal/GuidelineModal';
import { Heading } from '@components/atoms/heading/Heading';
import { Nav } from '@components/atoms/nav/Nav';
import ProfileBoxSmall from '@components/molecules/profileBoxSmall/ProfileBoxSmall';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import Svg from '@components/atoms/svg/Svg';
import useModal from '@components/atoms/modal/useModal';
import footerMenu from '../../../menus/FooterMenu';

function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue(v => !v);
  }, []);
  return [value, toggle];
}

const GroupSidebarLeft = props => {
  const { isShowing, toggle } = useModal(false);
  const [membersListState, setMembersListState] = useState({
    showAll: false,
  });
  const maxMembersCount = 4;

  const btnLabel = <String id="community_groups_info_rules_label" />;

  const onToggleGuidelinesClick = () => {
    toggle();
  };

  // const data = useStaticQuery(graphql`
  //   query {
  //     craft {
  //       footerSecondary: nodes(
  //         navHandle: "footerSecondaryNavigation"
  //         level: 1
  //       ) {
  //         id
  //         title
  //         url
  //         newWindow
  //       }
  //       policy: nodes(navHandle: "policiesNavigation", level: 1) {
  //         id
  //         title
  //         url
  //         newWindow
  //       }
  //     }
  //   }
  // `);
  const sideNavItems = footerMenu.footerSecondary.concat(footerMenu.policy);
  const stringsContext = useContext(StringsContext);

  const socialMediaList = [
    {
      name: 'Instagram',
      icon: 'instagram',
      url: stringsContext?.footer_social_instagram_url?.field,
    },
    {
      name: 'Linkedin',
      icon: 'linkedin',
      url: stringsContext?.footer_social_linkedin_url?.field,
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_3ds_url?.field,
      altTitle: '3ds Max',
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_maya_url?.field,
      altTitle: 'Maya',
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_flame_url?.field,
      altTitle: 'Flame',
    },
  ];

  useEffect(() => {
    let lis = document
      .querySelector('.side-nav-sidebar')
      .getElementsByTagName('li');

    for (let i = 0; i < lis.length; i++) {
      if (i + 1 < lis.length) {
        if (
          lis[i].getBoundingClientRect().top ===
          lis[i + 1].getBoundingClientRect().top
        ) {
          lis[i].classList.add('add-side-seperator');
        }
      }
    }
  });

  return (
    <div>
      <GroupInfo>
        <GroupDescription
          dangerouslySetInnerHTML={{ __html: props.description }}
        />
        <GroupDescription>
          <String id="community_groups_info_support" />
        </GroupDescription>
        <GroupIconsWrapper>
          <GroupIconsRow>
            <ShowGuidelinesBtn onClick={onToggleGuidelinesClick}>
              <StyledSvg icon="help" />
              {btnLabel}
            </ShowGuidelinesBtn>
          </GroupIconsRow>
        </GroupIconsWrapper>
      </GroupInfo>
      <GroupModeratorsWrapper>
        {props.moderators?.length > 0 ? (
          <Heading
            level="h5"
            weight="legendRegular"
            css={{
              marginBottom: '$12',
              textTransform: 'uppercase',
            }}
          >
            <String id="community_groups_info_moderators_label" />
          </Heading>
        ) : null}

        {props.moderators.map(moderator => (
          <GroupModerator key={moderator.title + moderator.subtitle}>
            <ProfileBoxSmall
              name={moderator.title}
              jobTitle={moderator.subtitle}
              avatar={moderator.avatar}
              type="normal"
              isPost={false}
              isCommunity={false}
            />
          </GroupModerator>
        ))}
      </GroupModeratorsWrapper>
      <GroupMembersWrapper>
        <Heading
          level="h5"
          weight="legendRegular"
          css={{
            marginBottom: '$12',
            textTransform: 'uppercase',
          }}
        >
          <String id="community_groups_info_members_label" />
        </Heading>
        <GroupMembersAvatars>
          {props.members.map((member, i) => {
            if (i >= maxMembersCount && !membersListState.showAll) return;
            return (
              <Avatar
                shape="circle"
                key={i}
                {...member.avatar}
                css={{ marginRight: '$8', flexShrink: '0' }}
              />
            );
          })}

          {props.mermbersCount > maxMembersCount &&
          !membersListState.showAll ? (
            <ShowMoreButton>
              +{props.mermbersCount - maxMembersCount}
            </ShowMoreButton>
          ) : null}
        </GroupMembersAvatars>
      </GroupMembersWrapper>
      <SideNavContainer>
        <SideNav>
          <Nav
            className="side-nav-sidebar"
            navItems={sideNavItems}
            isSideBarNav={true}
          />
        </SideNav>
      </SideNavContainer>
      <SideSocialsContainer>
        {socialMediaList.map((socialMedia, index) => {
          return (
            <BtnContainer
              key={socialMedia.icon + socialMedia.name + socialMedia.altTitle}
            >
              <BtnLink
                href={socialMedia.url}
                alt={socialMedia.name}
                target="_blank"
              >
                <Svg icon={socialMedia.icon} />
                {socialMedia.altTitle ? (
                  <BtnText>{socialMedia.altTitle}</BtnText>
                ) : null}
              </BtnLink>
            </BtnContainer>
          );
        })}
      </SideSocialsContainer>
      <GuidelineModal
        {...props.guidelinesModal}
        visibleDefault={isShowing}
        onToggleGuidelinesClick={onToggleGuidelinesClick}
      />
    </div>
  );
};

GroupSidebarLeft.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // moderators: PropTypes.instanceOf(ProfileBoxSmall),
  // members: PropTypes.instanceOf(Avatar),
};

export default GroupSidebarLeft;
