
export function generateRandomColor(alpha = 1){
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const h = randomInt(0, 360);
  const s = randomInt(42, 98);
  const l = randomInt(40, 90);
  return `hsla(${h},${s}%,${l}%, ${alpha})`;
};
