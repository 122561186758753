import 'swiper/swiper.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import {
  TabButton,
  TabListItem,
  TabNavWrapper,
  TabsWrapper,
} from './TabNav.styled';

import PropTypes from 'prop-types';
import React from 'react';

export const TabNav = props => {
  const { tabsItems, currentSection, changeCurrentSection } = props;

  return (
    <TabsWrapper>
      <nav>
        <TabNavWrapper>
          <Swiper slidesPerView="auto">
            {tabsItems.map((tab, index) =>
              tab && (
                <SwiperSlide key={tab.label + tab.url}>
                  <TabListItem>
                    <TabButton
                      alt={tab.alt}
                      to={tab.url}
                      onClick={() => changeCurrentSection(tab.sectionName, tab.url)}
                      isActive={tab.sectionName === currentSection}
                    >
                      {tab.label}
                    </TabButton>
                  </TabListItem>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </TabNavWrapper>
      </nav>
    </TabsWrapper>
  );
};

TabNav.propTypes = {
  tabsItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      alt: PropTypes.string,
      panel: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        content: PropTypes.node.isRequired,
      }),
    })
  ),
};
