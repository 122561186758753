import { styled } from '@root/stitches.config';

export const Counter = styled('p', {
  fontSize: '10px',
  color: '$lightGrey',
  textAlign: 'right',

  variants: {
    isMax: {
      true: {
        color: '$red',
      },
    },
  },
});
