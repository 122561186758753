import React, { useContext, useState } from 'react';

import AuthContext from '@context/AuthContext';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
import String from '@components/atoms/string/String';
import UrlsContext from '@context/UrlsContext';
import UserSignUpForm from '@components/organisms/signUpBox/userSignUpForm/UserSignUpForm.jsx';
import { navigate } from 'gatsby';

const SignUp = () => {
  const [onboardingCompletionState, setOnboardingCompletionContext] = useState([
    {
      title: <String id="sign_up_page_steps_1" />,
      isActive: true,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_2" />,
      isActive: false,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_3" />,
      isActive: false,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_4" />,
      isActive: false,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_5" />,
      isActive: false,
      isCompleted: false,
    },
  ]);

  const authContext = useContext(AuthContext);
  const UrlContext = useContext(UrlsContext);
  const [signInPageState, setSignInPageState] = useState({
    email: '',
    isSigningUp: true,
    pageTitle: <String id="sign_up_page_title" />,
  });

  const signUpComplete = email => {
    window.scrollTo(0, 0);

    setSignInPageState({
      ...signInPageState,
      isSigningUp: false,
      email,
      pageTitle: (
        <>
          👍 <String id="sign_up_page_title_success" />
        </>
      ),
    });
    let newArr = [...onboardingCompletionState];
    onboardingCompletionState[0].isCompleted = true;
    setOnboardingCompletionContext(newArr);
  };

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    navigate(`/m/${authContext.userSlug}`);
  } else if (authContext.isAuthenticated && !authContext.isVerified) {
    navigate(GetUrl('signupValidation', UrlContext));
  } else if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    !authContext.isOnboarded
  ) {
    navigate(GetUrl('onboarding', UrlContext));
  }

  return (
    <OnboardingCompletionContext.Provider
      value={[onboardingCompletionState, setOnboardingCompletionContext]}
    >
      <OnboardingLayout
        pageTitle={signInPageState.pageTitle}
        sideBarTitle={<String id="sign_up_page_sidebarTitle" />}
        displaySteps
      >
        <UserSignUpForm signUpComplete={signUpComplete} />
      </OnboardingLayout>
    </OnboardingCompletionContext.Provider>
  );
};

export default SignUp;
