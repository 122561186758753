const Emitter = require('tiny-emitter/instance');

const { Quill } =
  typeof window === 'object' ? require('react-quill') : () => false;

const Delta = typeof window === 'object' ? require('quill-delta') : null;

const Clipboard =
  typeof window === 'object' ? Quill.import('modules/clipboard') : null;

export class CopyPasteQuill extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain');

    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);

    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, 'silent');
    this.quill.setSelection(index, length, 'silent');

    Emitter.emit('reactQuill::paste', this.quill);
  }
}
