import { styled } from '@root/stitches.config';

export const ToolsEditContainer = styled('div', {
  width: 'auto',
  minHeight: '70vh',
  paddingTop: '$32',
  '@bp2': {
    width: 'calc(640px - 144px)',
    minHeight: 0,
    paddingTop: 0,
  },
});

export const ImageCropWrapper = styled('div', {
  width: 'auto',
  minHeight: '70vh',
  '@bp2': {
    minHeight: 0,
  },
});

export const ImageUploaderWrapper = styled('div', {
  width: 'auto',
  '@bp2': {
    width: '450px ',
    minHeight: 0,
  },

  '.upload__image-wrapper': {
    display: 'flex',
    alignItems: 'center',
    marginTop: '$24',
  },
});
