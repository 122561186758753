import {
  CLOSE_POST_REPORT,
  CMS_DELETE_GROUP_POSTS,
} from '@src/apollo/mutation';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_POST_REPORTS_ADMIN } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import Options from '@components/organisms/table/components/columnItem/Options';
import ReportedByList from '@components/organisms/table/components/columnItem/ReportedByList';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import Table from '@components/organisms/table/Table';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { Text } from '@components/atoms/text/Text';

const PostsReports = context => {
  const onCreatedDateChange = (name, date) => {
    const updatedFilters = { ...filtersState };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'created-date') {
        filter[name] = date;
      }
    });
    setFiltersState({ filters: updatedFilters.filters });
  };

  const onChangeReportType = e => {
    const updatedFilters = { ...filtersState };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'report-type') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isChecked = false;
          if (option.value === e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });
    setFiltersState({ filters: updatedFilters.filters });
  };
  const stringsContext = useContext(StringsContext);
  const ViolenceLabel = <String id="community_admin_violence" />;
  const NudityLabel = <String id="community_admin_nudity" />;
  const SuspiciousLabel = <String id="community_admin_suspicious" />;
  const PostLabel = <String id="community_admin_post" />;
  const CommentLabel = <String id="community_admin_comment" />;

  const onSubmitFilters = () => {
    // All Filters
    const allSelectedFilters = {};
    //       Radio - Status
    let type = null;
    filtersState.filters.forEach(filter => {
      if (filter.name == 'report-type') {
        type = filter.selectedValue;
      }
    });
    if (type) {
      allSelectedFilters.type = type;
    }

    let created_date_start = null;
    let created_date_end = null;
    filtersState.filters.forEach(filter => {
      if (filter.name == 'created-date') {
        created_date_start = filter.startDate;
        created_date_end = filter.endDate;
      }
    });
    if (created_date_start) {
      allSelectedFilters.created_date_start = created_date_start.toString();
    }
    if (created_date_end) {
      allSelectedFilters.created_date_end = created_date_end.toString();
    }
    setReports({
      ...reports,
      activeFilters: allSelectedFilters,
    });
    reportsData.refetch();
  };

  // const onChangeRole = e => {
  //   filters.forEach(filter => {
  //     if (filter.name == 'role') {
  //       filter.options.forEach(option => {
  //         if (option.value == e.currentTarget.value) {
  //           option.isChecked = e.currentTarget.checked;
  //         }
  //       });
  //     }
  //   });
  // };

  //   Radio - Status

  // //   Date - Last Post
  // const onLastPostChange = (name, date) => {
  //   filters.forEach(filter => {
  //     if (filter.name == 'last-post') {
  //       filter[name] = date;
  //     }
  //   });
  // };

  // //   Date - First Post
  // const onFirstPostChange = (name, date) => {
  //   filters.forEach(filter => {
  //     if (filter.name == 'first-post') {
  //       filter[name] = date;
  //     }
  //   });
  // };

  const filters = [
    {
      title: 'Report type',
      type: 'radio',
      name: 'report-type',
      onChange: onChangeReportType,
      selectedValue: '',
      options: [
        {
          name: 'All',
          id: 'All',
          label: 'All',
          value: '',
          isChecked: false,
        },
        {
          name: 'Violence',
          id: 'Violence',
          label: ViolenceLabel,
          value: 'abusive',
          isChecked: false,
        },
        {
          name: 'Adult content',
          id: 'Adult content',
          label: NudityLabel,
          value: 'adult-content',
          isChecked: false,
        },
        {
          name: 'Suspicious',
          id: 'Suspicious',
          label: SuspiciousLabel,
          value: 'suspicious',
          isChecked: false,
        },
      ],
    },
    {
      title: 'Created date',
      type: 'date',
      name: 'created-date',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onCreatedDateChange,
    },
  ];

  const [filtersState, setFiltersState] = useState({
    filters,
  });

  const [deleteReport, deleteReportData] = useMutation(CMS_DELETE_GROUP_POSTS);

  const [closeReport, closeReportData] = useMutation(CLOSE_POST_REPORT, {
    onCompleted: () => {
      reportsData.refetch();
    },
  });
  const convertUTCToLocalDate = date => {
    if (!date) {
      return date;
    }
    date = new Date(date * 1000);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );

    return format(date, 'LLL, d y');
  };

  const convertUTCtoLocalTime = date => {
    if (!date) {
      return date;
    }

    date = new Date(date * 1000);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let suffix = '';
    if (hours >= 12) {
      suffix = ' PM';
    } else {
      suffix = ' AM';
    }
    hours = hours % 12 || 12;
    minutes = ('0' + minutes).slice(-2);
    const formatedDate = `${hours}:${minutes}${suffix}`;
    return formatedDate;
  };

  const getAvatar = (profilePicture, size) => {
    return getAvatarImage(profilePicture, size);
  };

  const [reportsList, setReportsList] = useState([]);

  const [reports, setReports] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    activeFilters: {},
    totalCount: 0,
    columns: [
      {
        header: <String id="community_admin_reported_by" />,
        accessor: 'reporter',
      },
      {
        header: <String id="community_admin_report_detail" />,
        accessor: 'report_detail',
      },
      {
        header: <String id="community_admin_author" />,
        accessor: 'author',
      },
      {
        header: 'Group',
        accessor: 'group',
      },
      {
        header: <String id="community_admin_post_date" />,
        accessor: 'post_date',
      },
      {
        header: <String id="community_admin_report_date" />,
        accessor: 'report_date',
      },
      {
        header: <String id="community_admin_content_type" />,
        accessor: 'content_type',
      },
      {
        header: <String id="community_admin_report_type" />,
        accessor: 'report_type',
      },
      {
        header: 'status',
        accessor: 'status',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const singleRowActions = [
    {
      label: <String id="community_admin_reject_post" />,
      onClickAction: e => {
        closeReport({
          variables: {
            report_ids: e.id,
            action: 'approve',
          },
        });
        deleteReport({
          variables: {
            ids: [e.id],
          },
        });
      },
    },
    {
      label: <String id="community_admin_dismiss_report" />,
      onClickAction: e => {
        closeReport({
          variables: {
            report_ids: e.id,
            action: 'reject',
          },
        });
      },
    },
  ];

  const reportsData = useQuery(GET_POST_REPORTS_ADMIN, {
    context: { clientName: 'cms' },
    variables: {
      input: reports.activeFilters,
      limit: reports.limitRow,
      page: reports.page,
    },
    fetchPolicy: 'no-cache',
  });

  const onNextPage = () => {
    const pageCountFirstNumber = reports.page * reports.limitRow + 1;
    let pageCountSecondNumber = pageCountFirstNumber + reports.limitRow - 1;

    if (pageCountSecondNumber > reportsData.data.postReports.total) {
      pageCountSecondNumber = reportsData.data.postReports.total;
    }

    setReports({
      ...reports,
      page: reports.page + 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber = (reports.page - 2) * reports.limitRow + 1;
    let pageCountSecondNumber = pageCountFirstNumber + reports.limitRow - 1;
    if (pageCountSecondNumber > reportsData.data.postReports.length) {
      pageCountSecondNumber = reportsData.data.postReports.length;
    }

    setReports({
      ...reports,
      page: reports.page - 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setReports({
      ...reports,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(reports.totalCount / rowCount),
    });
  };

  useEffect(() => {
    if (reportsData.data) {
      const rowDatas = [];

      reportsData.data?.postReports?.data.forEach(post => {
        const reporting_list = [];
        let postOrCommentData = {};
        let report_type = '';
        let report_type_list = [];

        post.reports?.data.map((report, j) => {
          const profilPicture = getAvatar(
            report.reporter?.userProfile?.profile_picture,
            'xsmall'
          );

          const reportData = {
            profilPicture,
          };
          reporting_list.push(reportData);
          report_type_list.push(report.type);
        });
        let separator = ', ';
        let report_label = '';
        for (let i = 0; i < report_type_list.length; i++) {
          if (i === report_type_list.length - 1) {
            separator = '';
          }
          if (report_type_list[i] === 'abusive') {
            report_label = stringsContext['community_admin_violence'].field;
          } else if (report_type_list[i] === 'adult-content') {
            report_label = stringsContext['community_admin_nudity'].field;
          } else {
            report_label = stringsContext['community_admin_suspicious'].field;
          }
          report_type = report_type + report_label + separator;
        }

        const profilPicture = getAvatar(
          post.author?.userProfile?.profile_picture,
          'xsmall'
        );

        const postImages = [];

        post.images ? (
          <>
            {post.images.map((image, i) => {
              const imageContent = {
                src: CDN_URL + image.path,
                source: [
                  {
                    srcset: `${CDN_URL}${image.sizes[1]?.path}`,
                    media: '(min-width: 769px)',
                  },
                  {
                    srcset: `${CDN_URL}${image.sizes[0]?.path}`,
                    media: '(max-width: 768px)',
                  },
                ],
                alt: `${post.author.first_name} ${post.author.last_name}`,
                id: image.id,
              };
              postImages.push({ ...image, ...!imageContent });
            })}
          </>
        ) : null;

        let postLinkPreview = {};

        if (post.link_url !== null && post.link_url !== '') {
          postLinkPreview = {
            url: post.link_url,
            title: post.link_title,
            description: post.link_description,
            previewImage: post.link_image
              ? {
                  src: {
                    src: CDN_URL + post.link_image.path,
                  },
                  source: [
                    {
                      srcset: `${CDN_URL}${post.link_image[1]?.path}`,
                      media: '(min-width: 769px)',
                    },
                    {
                      srcset: `${CDN_URL}${post.link_image[0]?.path}`,
                      media: '(max-width: 768px)',
                    },
                  ],
                  alt: post.link_url,
                }
              : null,
          };
        }

        if (post.level === 0) {
          const replies = [];

          postOrCommentData = {
            reportId: '',
            replies: [],
            postCount: 0,
            postLinkPreview:
              post.link_url !== null && post.link_url !== ''
                ? postLinkPreview
                : null,
            postId: post.id,
            level: post.level,
            userInfos: {
              avatarProps: {
                shape: 'circle',
                alt: `${post.author.first_name} ${post.author.last_name}`,

                src: {
                  childImageSharp: {
                    fixed: {
                      height: 32,
                      width: 32,
                      src: profilPicture,
                      srcSet: `${profilPicture} 1x,${profilPicture} 1.5x,${profilPicture} 2x`,
                    },
                  },
                },
                url: `/m/${post.author.username}`,
              },
              modalProps: {},
            },
            postLikes: post.usersLikedCount,
            postDate: convertUTCToLocalDate(post.createdAt),
            postAuthor: {
              username: post.author.username,
              name: `${post.author.first_name} ${post.author.last_name}`,
              jobTitle: post.author.title,
              avatar: {
                src: {
                  childImageSharp: {
                    fixed: {
                      height: 48,
                      width: 48,
                      src: profilPicture,
                      srcSet: `${profilPicture} 1x,${profilPicture} 1.5x,${profilPicture} 2x`,
                    },
                  },
                },
              },
              url: `/m/${post.author.username}`,
            },
            postText: post.content,
            postImages,
            favoriteList: post.usersFavoriteCount,
            likesList: post.usersLikedCount,
          };
        } else {
          postOrCommentData = {
            replies: [],
            postCount: 0,
            postLinkPreview:
              post.link_url !== null && post.link_url !== ''
                ? postLinkPreview
                : null,
            postId: post.id,
            level: post.level,
            userInfos: {
              avatarProps: {
                shape: 'circle',
                alt: `${post.author.first_name} ${post.author.last_name}`,

                src: {
                  childImageSharp: {
                    fixed: {
                      height: 32,
                      width: 32,
                      src: profilPicture,
                      srcSet: `${profilPicture} 1x,${profilPicture} 1.5x,${profilPicture} 2x`,
                    },
                  },
                },
                url: `/m/${post.author.username}`,
              },
              modalProps: {},
            },
            postLikes: post.usersLikedCount,
            postDate: convertUTCToLocalDate(post.createdAt),
            postAuthor: {
              username: post.author.username,
              name: `${post.author.first_name} ${post.author.last_name}`,
              jobTitle: post.author.title,
              avatar: {
                src: {
                  childImageSharp: {
                    fixed: {
                      height: 48,
                      width: 48,
                      src: profilPicture,
                      srcSet: `${profilPicture} 1x,${profilPicture} 1.5x,${profilPicture} 2x`,
                    },
                  },
                },
              },
              url: `/m/${post.author.username}`,
            },
            postText: post.content,
            postImages: postImages,
            commentImages: postImages,
            favoriteList: post.usersFavoriteCount,
            likesList: post.usersLikedCount,
          };
        }

        rowDatas.push({
          reporter: (
            <>
              {post.reports.data.map((report, i) => {
                if (i < 2) {
                  return (
                    <UserInfos
                      url={report.reporter.userProfile.profile_picture}
                      key={report.createdAt}
                      name={
                        report.reporter.first_name === 'anonymized'
                          ? 'Anonymized'
                          : `${report.reporter.first_name} ${report.reporter.last_name}`
                      }
                      css={i > 0 ? { mt: '$16' } : {}}
                      link={
                        report.reporter.first_name !== '' &&
                        report.reporter.first_name !== 'anonymized'
                          ? `/m/${report.reporter.username}`
                          : undefined
                      }
                    />
                  );
                }
              })}
              {post.reports.data.length > 3 && (
                <Text>
                  <String
                    id="admin_reports_reporter_number"
                    extras={{ count: post.reports.data.length - 3 }}
                  />
                </Text>
              )}
            </>
          ),
          status: post.status,
          report_detail: (
            <>
              {post.reports.data.map((report, i) => {
                if (i < 2) {
                  return (
                    <Text
                      key={report.createdAt + 'detail'}
                      css={i > 0 ? { mt: '$16' } : {}}
                    >
                      {report.detail}
                    </Text>
                  );
                }
              })}
              {post.reports.data.length > 3 && (
                <Text>
                  <String
                    id="admin_reports_reporter_number"
                    extras={{ count: post.reports.data.length - 3 }}
                  />
                </Text>
              )}
            </>
          ),
          author: (
            <UserInfos
              url={post.author.userProfile.profile_picture}
              name={
                post.author.first_name === 'anonymized'
                  ? 'Anonymized'
                  : `${post.author.first_name} ${post.author.last_name}`
              }
              job={post.author.title}
              link={
                post?.author?.first_name !== '' &&
                post?.author?.first_name !== 'anonymized'
                  ? `/m/${post.author.username}`
                  : undefined
              }
            />
          ),
          group: post.group?.name,
          post_date: convertUTCToLocalDate(post.createdAt),
          report_date: (
            <DateIconLink
              date={convertUTCToLocalDate(
                post.reports.data[post.reports.data.length - 1].createdAt
              )}
              time={convertUTCtoLocalTime(
                post.reports.data[post.reports.data.length - 1].createdAt
              )}
              actionType="onclick"
              postData={postOrCommentData}
              postType={post.level === 0 ? 0 : 1}
            />
          ),
          content_type: post.level === 0 ? PostLabel : CommentLabel,
          report_type: report_type,
          options: (
            <Options
              actions={singleRowActions}
              id={post.reports.data[post.reports.data.length - 1].id}
            />
          ),
          id: post.reports.data[post.reports.data.length - 1].id,
        });
      });
      setReportsList(rowDatas);

      if (reports.page === 1) {
        const pageCountFirstNumber = (reports.page - 1) * reports.limitRow + 1;
        let pageCountSecondNumber = pageCountFirstNumber + reports.limitRow - 1;
        if (pageCountSecondNumber > reportsData.data.total) {
          pageCountSecondNumber = reportsData.data.total;
        }
        setReports({
          ...reports,
          isLoaded: true,
          totalCount: reportsData.data.postReports.total,
          currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
          maxPage: Math.ceil(
            reportsData.data.postReports.total / reports.limitRow
          ),
        });
      } else {
        setReports({
          ...reports,
          isLoaded: true,
          totalCount: reportsData.data.postReports.total,
          maxPage: Math.ceil(
            reportsData.data.postReports.total / reports.limitRow
          ),
        });
      }
    }
  }, [reportsData.data]);

  const bulkActions = [
    {
      label: <String id="community_admin_reject_post" />,
      onClickAction: posts => {
        const ids = posts.map(post => post.id);
        closeReport({
          variables: {
            report_ids: ids,
            action: 'approve',
          },
        });
        deleteReport({
          variables: {
            ids,
          },
        });
      },
    },
    {
      label: <String id="community_admin_dismiss_report" />,
      onClickAction: posts => {
        const ids = posts.map(post => post.id);
        closeReport({
          variables: {
            report_ids: ids,
            action: 'reject',
          },
        });
      },
    },
  ];

  const onSearchSubmit = searchInput => {
    setReports({
      ...reports,
      activeFilters: searchInput !== '' ? { search: searchInput } : {},
      page: 1,
    });
    reportsData.refetch();
  };

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        <String id="admin_reports_community_title" />
      </Heading>
      <AdvanceFilters
        onSubmitFilters={onSubmitFilters}
        filters={filtersState.filters}
        onSearchSubmit={onSearchSubmit}
      />
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={reports.totalCount}
        currentCount={reports.currentCount}
        loading={reportsData.loading || closeReportData.loading}
        rowData={reportsList}
        columns={reports.columns}
        maxPage={reports.maxPage}
        currentPage={reports.page}
        hasOptions
        bulkActions={bulkActions}
      />
    </AdminLayout>
  );
};

export default PostsReports;
