import {
  LearnTutorialsListing,
  LinkListWrapper,
  PaginationWrapper,
  StyledHeading,
  PageWrapper,
} from './LearnTutorials.styled';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';
import { GET_INDUSTRIES_TOOLS_LEVELS, GET_TUTORIALS } from '@src/apollo/query';
import { GET_PAGE_DATA_TUTORIALS } from '@src/apollo/queryPages';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import AuthContext from '@src/context/AuthContext';
import FilterBar from '@components/organisms/filterBar/FilterBar';
import String from '@components/atoms/string/String';
import Layout from '@components/organisms/layout/Layout';
import LinkThumbnailList from '@components/molecules/linkThumbnail/linkThumbnailList/LinkThumbnailList';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import NoResult from '@components/molecules/noResult/NoResult';
import Pagination from '@components/atoms/pagination/Pagination';
import SEO from '@components/atoms/seo/Seo';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import { dataToLinkThumbnailViewModel } from '@src/utils/dataToLinkThumbnailViewModel';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { sortArrayByTitle } from '@src/utils/sortArray';
import { useIsMobile } from '@src/utils/useIsMobile';

const LearnTutorialsPage = context => {
  const [filtersState, setFiltersState] = useState({
    isActive: [
      {
        filterKey: 'recent',
        title: 'listing_page_recent_filter',
        type: 'single',
      },
      {
        id: 'tools',
        selectedValues: [],
        type: 'dropdown',
      },
      {
        id: 'industries',
        selectedValues: [],
        type: 'dropdown',
      },
      {
        id: 'levels',
        selectedValues: [],
        type: 'dropdown',
      },
      {
        type: 'checkbox',
        title: 'listing_page_series_filter',
        filterKey: 'series',
        checked: false,
      },
    ],
    isDataSet: false,
    filters: [
      {
        type: 'single',
        title: 'listing_page_recent_filter',
        filterKey: 'recent',
      },
      {
        type: 'single',
        title: 'listing_page_popular_filter',
        filterKey: 'popular',
      },
    ],
    checkBoxFilters: [
      {
        type: 'checkbox',
        title: 'listing_pages_series_filter',
        filterKey: 'series',
        checked: false,
      },
    ],
    dropdownFilters: [
      {
        type: 'dropdown',
        title: 'listing_page_tools_filter',
        filterKey: 'tools',
        options: [],
      },
      {
        type: 'dropdown',
        title: 'listing_pages_industries_filter',
        filterKey: 'industries',
        options: [],
      },
      {
        type: 'dropdown',
        title: 'listing_pages_levels_filter',
        filterKey: 'levels',
        options: [],
      },
    ],
  });
  const [excludedIds, setExcludedIds] = useState([]);
  const [urlParams, setUrlParams] = useState({});
  const authContext = useContext(AuthContext);

  const isSeriesFilter = filtersState.isActive.filter(
    el => el?.filterKey === 'series'
  )[0]?.checked;
  const isMobile = useIsMobile();

  const { data: pageData, loading: pageDataLoading } = useQuery(
    GET_PAGE_DATA_TUTORIALS,
    {
      variables: {
        slug: 'learn/tutorials',
      },
      context: { clientName: 'public' },
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (pageData?.page) {
      setExcludedIds(
        [pageData?.page?.userContent_feature?.id].filter(el => el !== undefined)
      );
    }
  }, [pageData]);

  const [
    getTutorials,
    { loading: loadingTutorials, error: errorTutorials, data: dataTutorials },
  ] = useLazyQuery(GET_TUTORIALS, {
    context: { clientName: 'public' },
    variables: {
      limit: isMobile ? 8 : 16,
      contentType: isSeriesFilter ? 'tutorial_series' : 'tutorial',
      excludedIds,
    },
  });

  const {
    loading: loadingFiltersInfos,
    error: errorFiltersInfos,
    data: dataFiltersInfos,
  } = useQuery(GET_INDUSTRIES_TOOLS_LEVELS, {
    context: { clientName: 'public' },
  });

  const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  };

  const setDataFilters = data => {
    const { industries } = data;
    const { tools } = data;
    const { tutorialLevels: levels } = data;

    setFiltersState({
      ...filtersState,
      isDataSet: true,
      dropdownFilters: [
        {
          type: 'dropdown',
          title: 'listing_page_tools_filter',
          filterKey: 'tools',
          options: tools.slice().sort(sortArrayByTitle),
        },
        {
          type: 'dropdown',
          title: 'listing_pages_industries_filter',
          filterKey: 'industries',
          options: industries,
        },
        {
          type: 'dropdown',
          title: 'listing_page_levels_filter',
          filterKey: 'levels',
          options: levels,
        },
      ],
    });
  };

  useEffect(() => {
    if (dataFiltersInfos && !loadingFiltersInfos && !filtersState.isDataSet) {
      setDataFilters(dataFiltersInfos);
    }
  }, [loadingFiltersInfos, filtersState]);

  const page = getQueryVariable('page') || 1;
  const order = getQueryVariable('order');
  const tools = getQueryVariable('tools');
  const industries = getQueryVariable('industries');
  const levels = getQueryVariable('levels');
  const series = getQueryVariable('series');

  useEffect(() => {
    setUrlParams({
      ...urlParams,
      ...(page && { page }),
      ...(order !== undefined && order !== '' && { order }),
      ...(tools !== undefined &&
        tools !== '' && {
          tools: tools.split(',').map(el => parseInt(el, 10)),
        }),
      ...(industries !== undefined &&
        industries !== '' && {
          industries: industries.split(',').map(el => parseInt(el, 10)),
        }),
      ...(levels !== undefined &&
        levels !== '' &&
        !isSeriesFilter && {
          levels: levels.split(',').map(el => parseInt(el, 10)),
        }),
      ...(series !== undefined && series !== 'false' && { series }),
    });
  }, [page, order, tools, industries, levels, series]);

  useEffect(() => {
    const newParams = { ...urlParams };
    if (newParams?.tools && newParams?.tools?.length === 0) {
      delete newParams.tools;
    }
    if (newParams?.industries && newParams?.industries?.length === 0) {
      delete newParams.industries;
    }
    if (newParams?.levels && newParams?.levels?.length === 0) {
      delete newParams.levels;
    }
    if (newParams?.series === undefined || newParams?.series === 'false') {
      delete newParams.series;
    }
    const url = Object.keys(newParams)
      .map((key, i) => `${i === 0 ? '?' : '&'}${key}=${newParams[key]}`)
      .join('');
    window.history.replaceState({}, '', url);

    getTutorials({ variables: newParams });

    if (tools && filtersState?.isActive) {
      const toolsParam = tools.split(',').map(el => parseInt(el, 10));
      const activeTools = filtersState.isActive.find(el => el.id === 'tools');
      activeTools.selectedValues = [
        ...new Set([...toolsParam, ...activeTools?.selectedValues]),
      ];
    }
    if (industries && filtersState?.isActive) {
      const industriesParam = industries.split(',').map(el => parseInt(el, 10));
      const activeIndustries = filtersState.isActive.find(
        el => el.id === 'industries'
      );
      activeIndustries.selectedValues = [
        ...new Set([...industriesParam, ...activeIndustries?.selectedValues]),
      ];
    }
    if (levels && filtersState?.isActive) {
      const levelsParam = levels.split(',').map(el => parseInt(el, 10));
      const activeLevels = filtersState.isActive.find(el => el.id === 'levels');
      activeLevels.selectedValues = [
        ...new Set([...levelsParam, ...activeLevels?.selectedValues]),
      ];
    }
  }, [urlParams, filtersState, tools, industries, levels, series]);

  useEffect(() => {
    if (order) {
      const activeOrderFilter = filtersState.filters.find(
        el => el.filterKey === order
      );
      setFiltersState({
        ...filtersState,
        isActive: [
          ...filtersState.isActive.filter(el => el?.type !== 'single'),
          activeOrderFilter,
        ],
      });
    }
  }, [order]);

  useEffect(() => {
    if (series === 'true') {
      const activeSeries = filtersState.checkBoxFilters.find(
        el => el.filterKey === 'series'
      );

      setFiltersState({
        ...filtersState,
        checkBoxFilters: [{ ...activeSeries, checked: true }],
        isActive: [
          ...filtersState.isActive.filter(el => el?.type !== 'checkbox'),
          { ...activeSeries, checked: true },
        ],
      });
    }
  }, [series]);

  useEffect(() => {
    if (
      parseInt(page, 10) > 1 &&
      dataTutorials?.userContents?.data.length === 0
    ) {
      changePage(1);
    }
  }, [page, dataTutorials]);

  const changePage = num => {
    setUrlParams({
      ...urlParams,
      page: num,
    });
  };

  function dropdownOptionsToVM(options) {
    return options.map(option =>
      option.__typename === 'Tool'
        ? { id: option.id, name: option.title }
        : { id: option.id, name: option.name }
    );
  }

  const filtersToVM = filters =>
    filters.map(filter => {
      if (filter.type === 'dropdown') {
        return {
          ...filter,
          options: dropdownOptionsToVM(filter.options),
        };
      }
      return filter;
    });

  const hasFiltersActive =
    filtersState.isActive
      .filter(el => el?.type === 'dropdown')
      .reduce((acc, i) => acc.concat(i.selectedValues), []).length > 0 ||
    !filtersState.isActive
      .filter(el => el?.type === 'single')
      .map(el => el.filterKey)
      .includes('recent');

  return (
    <Layout location={context.location} showBreadcrumb>
      <SEO
        title={'Tutorials'}
        slug="learn-tutorials"
        seomatic={context?.pageContext?.entry?.seomatic}
      />
      <LandingPageWrapper>
        <SectionWrapper>
          {pageData?.page ? (
            <>
              <ListingHeader
                title={pageData.page.title}
                intro={pageData.page.introduction}
                isCMSDynamic
              />
              <FilterBar
                filters={[
                  ...filtersState.checkBoxFilters,
                  ...filtersToVM([
                    ...filtersState.filters,
                    ...(isSeriesFilter
                      ? filtersState.dropdownFilters.filter(
                          el => el.filterKey !== 'levels'
                        )
                      : filtersState.dropdownFilters),
                  ]),
                ]}
                onChange={e => {
                  const selectedFilters = JSON.parse(e);
                  setUrlParams({
                    ...urlParams,
                    tools: selectedFilters.find(el => el?.id === 'tools')
                      ?.selectedValues,
                    industries: selectedFilters.find(
                      el => el?.id === 'industries'
                    )?.selectedValues,
                    levels: selectedFilters.find(el => el?.id === 'levels')
                      ?.selectedValues,
                    order: selectedFilters.find(el => el?.type === 'single')
                      ?.filterKey,
                    ...(selectedFilters.find(
                      el => el?.filterKey === 'series'
                    ) && {
                      series: selectedFilters.find(
                        el => el?.filterKey === 'series'
                      )?.checked,
                    }),
                  });
                  setFiltersState({
                    ...filtersState,
                    isActive: selectedFilters,
                  });
                }}
                defaultFilters={filtersState.isActive}
              />
            </>
          ) : (
            <LoaderSkeleton height="300px" width="100%" />
          )}
        </SectionWrapper>
        <SectionWrapper>
          <LearnTutorialsListing>
            {parseInt(urlParams.page, 10) === 1 && !hasFiltersActive && (
              <>
                {pageData?.page?.userContent_feature && (
                  <Thumbnail
                    {...dataToThumbnailViewModel(
                      pageData?.page?.userContent_feature,
                      false,
                      authContext.isAuthenticated
                    )}
                    className="thumbnail-featured"
                    isFeatured={!isMobile}
                  />
                )}
                {pageDataLoading && !pageData?.page && (
                  <LoaderSkeleton
                    height="870px"
                    width="100%"
                    isFeatured={!isMobile}
                    isRadius
                  />
                )}
              </>
            )}
            {loadingTutorials ? (
              <>
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
              </>
            ) : dataTutorials?.userContents.data.length === 0 ? (
              <NoResult />
            ) : (
              dataTutorials?.userContents.data.map((item, i) => {
                return (
                  <Thumbnail
                    key={item.id}
                    {...dataToThumbnailViewModel(
                      item,
                      false,
                      authContext.isAuthenticated
                    )}
                  />
                );
              })
            )}
          </LearnTutorialsListing>

          <PaginationWrapper>
            <Pagination
              isUrlLink={false}
              activePage={parseInt(urlParams.page, 10)}
              onCurrentPageChange={e => changePage(e)}
              pages={Math.ceil(
                dataTutorials?.userContents.total /
                  dataTutorials?.userContents.per_page
              )}
            />
          </PaginationWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <LinkListWrapper>
            {pageDataLoading && (
              <LoaderSkeleton height="420px" width="100%" isRadius />
            )}
            {!pageDataLoading && pageData && (
              <>
                <StyledHeading level="h3">
                  <String id="Learn more" />
                </StyledHeading>
                <LinkThumbnailList
                  listData={[
                    dataToLinkThumbnailViewModel(pageData.page.learn_more_1),
                    dataToLinkThumbnailViewModel(pageData.page.learn_more_2),
                  ]}
                />
              </>
            )}
          </LinkListWrapper>
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};
export default LearnTutorialsPage;
