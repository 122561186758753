import React, { useState } from 'react';
import { CopyPasteQuill } from '@src/utils/copyPasteQuill';
import { BlockTextWrapper } from './BlockText.styled';
import { EditorLoadingWrapper } from '../EditorBlock.styled';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import { debounce } from 'throttle-debounce';
import { removeStyleAttributeFromHTMLString } from '@src/utils/removeStyleAttributeFromHTMLString';

const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false;

import 'react-quill/dist/quill.snow.css';

require('medium-editor/dist/css/medium-editor.css');
require('../../../../styles/content_editor.css');

const debouncefunc = debounce(1500, false, (func, blockIndex, value) => {
  func(blockIndex, value);
});

const { Quill } =
  typeof window === 'object' ? require('react-quill') : () => false;

if (typeof window === 'object') {
  Quill.register('modules/clipboard', CopyPasteQuill, true);
}

const BlockText = props => {
  const [value, setValue] = useState(props.block.text);

  const onChange = text => {
    const removedStyleTagText = removeStyleAttributeFromHTMLString(text);
    setValue(removedStyleTagText);
    debouncefunc(props.onEditBlock, props.blockIndex, removedStyleTagText);
  };

  return (
    <BlockTextWrapper>
      {props.block.isLoading && (
        <EditorLoadingWrapper>
          <LoaderSpinner />
        </EditorLoadingWrapper>
      )}
      <ReactQuill
        theme="bubble"
        defaultValue={props.block.text}
        value={value}
        onChange={onChange}
        modules={{
          toolbar: [
            [{ header: 2 }, { header: 3 }, { header: 4 }],
            ['bold', 'italic'],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        }}
        bounds=".wysiwyg-editor"
        placeholder="Write something..."
      />
    </BlockTextWrapper>
  );
};

export default BlockText;
