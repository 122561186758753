import { styled } from '@root/stitches.config';

export const TrendingContentWrapper = styled('div', {
  backgroundColor: '$greyBox',
  padding: '$40 $32',
  borderRadius: '$16',
  position: 'relative',
});

export const TrendingContentHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$32',
});
export const TrendingContentContent = styled('div', {
  display: 'flex',
});

export const GraphContainer = styled('div', {
  display: 'flex',
  width: '55%',
  backgroundColor: 'white',
  borderRadius: '16px',
  padding: '10px',
});

export const InfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '45%',
  paddingLeft: '$54',
  justifyContent: 'center',
});

export const InfoContainerFooter = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
});
