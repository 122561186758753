import Breadcrumb from '@components/molecules/breadcrumb/Breadcrumb';
import Footer from './footer/Footer';
import Header from './header/Header';
import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import AuthContext from '@context/AuthContext';

const Layout = ({ children, className, style, location, showBreadcrumb }) => {
  const isCommunity = location?.pathname === '/community';
  const authContext = useContext(AuthContext);

  const defaultOptions = {
    visitor: {
      id: '',
      role: '',
    },
    account: {
      id: `AREA-${process.env.GATSBY_ACTIVE_ENV}`,
    },
  };

  const authOptions = {
    visitor: {
      id: authContext.userSlug,
      role: authContext.generalRole,
    },
    account: {
      id: `AREA-${process.env.GATSBY_ACTIVE_ENV}`,
    },
  };

  const MainStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'var(--colors-bg)',
    backgroundSize: 'cover',
    color: 'var(--colors-mainTextColor)',
    backgroundSize: 'cover',
    overflowX: 'hidden',
  };

  return (
    <>
      <Header siteTitle="Area" location={location} />
      <div id="body-wrapper" style={{ ...MainStyle, ...style }}>
        {location?.pathname && showBreadcrumb && (
          <Breadcrumb pathname={location?.pathname} />
        )}
        {children}
      </div>
      {!isCommunity && <Footer location={location} />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  showBreadcrumb: PropTypes.bool,
};

Layout.defaultProps = {
  className: '',
  style: {},
  showBreadcrumb: false,
};

export default Layout;
