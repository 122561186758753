import {
  ContentWrapper,
  ListItemContainer,
  NewAlertWrapper,
  OverallWrapper,
  PendingPublicationWrapper,
  RedDot,
  StyledNewText,
  StyledTitle,
  TitleWrapper,
  TopWrapper,
} from './CommunityPendingPublicationFeed.styled';
import React, { useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import CommunityPostBox from '@components/molecules/communityPostBox/CommunityPostBox';
import Modal from '@components/atoms/modal/Modal';
import PendingPublicationsModal from '@components/molecules/pendingPublicationsModal/PendingPublicationsModal';
import String from '@components/atoms/string/String';
import useModal from '@components/atoms/modal/useModal';

const CommunityPendingPublicationFeed = props => {
  const { isShowing, toggle } = useModal();

  const [rejectedId, setRejectedId] = useState(null);

  const approveAllBtnText = <String id="general_approve_all_text" />;

  return (
    <OverallWrapper>
      <TitleWrapper>
        <StyledTitle size="24">
          <String id="community_moderation_pending_post_text" /> ({props.amount}
          )
        </StyledTitle>
        {props.hasNewPendingItems ? (
          <NewAlertWrapper>
            <RedDot />
            <StyledNewText size="12">
              {props.count} <String id="general_new_text" />
            </StyledNewText>
          </NewAlertWrapper>
        ) : null}
      </TitleWrapper>
      <PendingPublicationWrapper>
        <TopWrapper>
          {props.postList.length !== 0 ? (
            <Button
              type="normal"
              label={approveAllBtnText}
              colorTheme="normal"
              actionType="onclick"
              onClick={props.onApproveAllClick}
            />
          ) : null}
        </TopWrapper>
        <ContentWrapper>
          {props.postList.map((item, i) => {
            return (
              <ListItemContainer>
                <CommunityPostBox
                  {...item}
                  onRejectClick={toggle}
                  onApproveClick={props.onApproveClick}
                  setRejectedId={setRejectedId}
                  loading={props.loading}
                  moderatedId={props.moderatedId}
                  postContent={item}
                  isPending={true}
                />
              </ListItemContainer>
            );
          })}
        </ContentWrapper>
        {props.postList.length !== 0 ? (
          <Button
            type="normal"
            label={approveAllBtnText}
            colorTheme="normal"
            actionType="onclick"
            onClick={props.onApproveAllClick}
          />
        ) : null}
        {props.amount > props.limit || props.moreLoading ? (
          <Button
            type="normal"
            colorTheme="normal"
            actionType="onclick"
            onClick={props.onLoadMoreClick}
            label={'load more'}
            css={{ margin: '$64 auto' }}
            isDisabled={props.moreLoading}
          />
        ) : null}
        <Modal isShowing={isShowing} hide={toggle}>
          <PendingPublicationsModal
            closeModal={toggle}
            postId={rejectedId}
            onRejectClick={props.onRejectClick}
          />
        </Modal>
      </PendingPublicationWrapper>
    </OverallWrapper>
  );
};

export default CommunityPendingPublicationFeed;
