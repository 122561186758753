import {
  BtnContainer,
  BtnLink,
  BtnText,
  SideNav,
  SideNavContainer,
  SideSocialsContainer,
} from '@components/organisms/groupSidebarLeft/GroupSidebarLeft.styled';
import {
  CommunityContentActionCard,
  CommunitySidebarFixed,
  CommunitySidebarWrapper,
  DontShowBtn,
  NavContainer,
  StyledButton,
  StyledText,
  TextWrapper,
} from './CommunitySidebar.styled';
import React, { useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Nav } from '@components/atoms/nav/Nav';
import PropTypes from 'prop-types';
import StringsContext from '@context/StringsContext';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import footerMenu from '../../../menus/FooterMenu';

const CommunitySidebar = props => {
  const { sidebarData } = props;

  const [showState, setShowState] = useState(false);
  const [checkLocalStorageState, setCheckLocalStorageState] = useState(false);

  const sideNavItems = footerMenu.footerSecondary.concat(footerMenu.policy);
  const stringsContext = useContext(StringsContext);

  const socialMediaList = [
    {
      name: 'Instagram',
      icon: 'instagram',
      url: stringsContext?.footer_social_instagram_url?.field,
    },
    {
      name: 'Linkedin',
      icon: 'linkedin',
      url: stringsContext?.footer_social_linkedin_url?.field,
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_3ds_url?.field,
      altTitle: '3ds Max',
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_maya_url?.field,
      altTitle: 'Maya',
    },
    {
      name: 'Youtube',
      icon: 'youtube',
      url: stringsContext?.footer_social_youtube_flame_url?.field,
      altTitle: 'Flame',
    },
  ];

  useEffect(() => {
    const lis = document
      .querySelector('.side-nav-sidebar')
      .getElementsByTagName('li');

    for (let i = 0; i < lis.length; i++) {
      if (i + 1 < lis.length) {
        if (
          lis[i].getBoundingClientRect().top ===
          lis[i + 1].getBoundingClientRect().top
        ) {
          lis[i].classList.add('add-side-seperator');
        }
      }
    }

    if (!checkLocalStorageState) {
      setCheckLocalStorageState(true);
      if (localStorage.getItem('dont-show-again')) {
        setShowState(!showState);
      }
    }
  });

  const onShowHideClick = () => {
    if (showState) {
      localStorage.removeItem('dont-show-again');
    } else {
      localStorage.setItem('dont-show-again', true);
    }
    setShowState(!showState);
  };
  return (
    <CommunitySidebarWrapper>
      <CommunitySidebarFixed>
        {sidebarData.length > 0 &&
          sidebarData.map(data => {
            const { title, content, actions } = data;
            return (
              <CommunityContentActionCard key={title}>
                <TextWrapper isHidden={showState}>
                  {title && (
                    <Text
                      size="18"
                      weight="legendBold"
                      css={{ '@bp2': { fontSize: '$16' } }}
                    >
                      {title}
                    </Text>
                  )}
                  {content && <StyledText size="14">{content}</StyledText>}
                </TextWrapper>

                {/* <DontShowBtn
                  label={showState ? 'Read introduction' : "Don't show again"}
                  svgIcon={showState ? 'arrow-down-short' : 'arrow-up-short'}
                  actionType="onclick"
                  onClick={onShowHideClick}
                  type="normal"
                  colorTheme="transparent"
                  svgLocation="after"
                /> */}
                {/* {actions.map(action => {
                  const {
                    label,
                    svgIcon,
                    onClick,
                    internalUrl,
                    externalUrl,
                    colorTheme,
                    actionType,
                    target,
                  } = action;

                  return (
                    <StyledButton
                      key={label}
                      label={label}
                      actionType={actionType}
                      colorTheme={colorTheme}
                      onClick={onClick}
                      svgIcon={svgIcon}
                      internalUrl={internalUrl}
                      externalUrl={externalUrl}
                      target={target}
                    />
                  );
                })} */}
                <NavContainer>
                  <SideNavContainer>
                    <SideNav>
                      <Nav
                        className="side-nav-sidebar"
                        navItems={sideNavItems}
                        isSideBarNav={true}
                      />
                    </SideNav>
                  </SideNavContainer>
                  <SideSocialsContainer>
                    {socialMediaList.map((socialMedia, index) => {
                      return (
                        <BtnContainer
                          key={socialMedia.name + socialMedia.altTitle}
                        >
                          <BtnLink
                            href={socialMedia.url}
                            alt={socialMedia.name}
                            target="_blank"
                          >
                            <Svg icon={socialMedia.icon} />
                            {socialMedia.altTitle ? (
                              <BtnText>{socialMedia.altTitle}</BtnText>
                            ) : null}
                          </BtnLink>
                        </BtnContainer>
                      );
                    })}
                  </SideSocialsContainer>
                </NavContainer>
              </CommunityContentActionCard>
            );
          })}
      </CommunitySidebarFixed>
    </CommunitySidebarWrapper>
  );
};

export default CommunitySidebar;

CommunitySidebar.propTypes = {
  sidebarData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          colorTheme: PropTypes.string,
          svgIcon: PropTypes.string,
          onClick: PropTypes.func,
          internalUrl: PropTypes.string,
          externalUrl: PropTypes.string,
          actionType: PropTypes.oneOf([
            'internalLink',
            'externalLink',
            'onclick',
          ]),
        })
      ),
    })
  ),
};

CommunitySidebar.defaultProps = {
  sidebarData: [],
};
