import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import Svg from '@components/atoms/svg/Svg';
import String from '@components/atoms/string/String';

import { TooltipContentWrapper } from './TooltipBox.styled';

const TooTipBox = props => {
  const {
    theme,
    delay,
    hideDelay,
    style,
    iconName,
    stringId,
    instruction,
    position,
    trigger,
    isArrow,
    size,
    children,
  } = props;

  return (
    <Tooltip
      theme={theme}
      delay={delay}
      hideDelay={hideDelay}
      useContext
      html={
        <TooltipContentWrapper theme={theme} style={style}>
          {iconName && <Svg icon={iconName} />}
          {stringId && <String id={stringId} />}
          {instruction && instruction}
        </TooltipContentWrapper>
      }
      position={position}
      trigger={trigger}
      arrow={isArrow}
      size={size}
    >
      {children}
    </Tooltip>
  );
};

TooTipBox.propTypes = {
  theme: PropTypes.string,
  delay: PropTypes.number,
  hideDelay: PropTypes.number,
  style: PropTypes.object,
  iconName: PropTypes.string,
  stringId: PropTypes.string,
  instruction: PropTypes.string,
  position: PropTypes.string,
  trigger: PropTypes.string,
  isArrow: PropTypes.bool,
  size: PropTypes.string,
};

TooTipBox.defaultProps = {
  theme: 'light',
  delay: 0,
  hideDelay: 0,
  style: {},
  iconName: '',
  position: 'top',
  trigger: 'mouseenter',
  isArrow: true,
  size: 'small',
};

export default TooTipBox;
