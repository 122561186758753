import { ImageCropWrapper, ImageUploaderWrapper } from './ModalEdit.styled';
import React, { useContext, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import ImageCrop from '@components/molecules/ImageCrop/ImageCrop';
import ImageUploading from 'react-images-uploading';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const AvatarEdit = props => {
  const [images, setImages] = React.useState([]);
  const [errorUpload, setErrorUpload] = React.useState(false);
  const [errorUploadToCdn, setErrorUploadToCdn] = React.useState(false);
  const [cropData, setCropsData] = React.useState({});
  const [cropRotationData, setCropsRotation] = React.useState({});
  const [avatarEditData, setAvatarEditsData] = React.useState({
    isLoading: false,
  });
  const authContext = useContext(AuthContext);

  const onSave = () => {
    setAvatarEditsData({ isLoading: true });
    const data = {
      image_file: images[0].data_url,
      x: cropData.x,
      y: cropData.y,
      width: cropData.width,
      height: cropData.height,
      user_id: props.userId,
      token: authContext.userToken,
    };

    const bearer = `Bearer ${localStorage.getItem('userToken').slice(1, -1)}`;

    fetch(`${API_URL}/upload-image`, {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.success) {
          props.onSetAvatarImage(response.result.url);
          setAvatarEditsData({ isLoading: false });
          props.onClose();
          if (!props.isAdminNotCurrentProfile) {
            authContext.updateProfilePicture(response.result.url);
          }
        } else {
          setErrorUploadToCdn(true);
          setAvatarEditsData({ isLoading: false });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  const getRotatedImage = async (imageSrc, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const orientationChanged =
      rotation === 90 ||
      rotation === -90 ||
      rotation === 270 ||
      rotation === -270;
    if (orientationChanged) {
      canvas.width = image.height;
      canvas.height = image.width;
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);

    return new Promise(resolve => {
      const image = canvas.toDataURL('image/jpeg');
      resolve(image);
    });
  };

  const onDrop = async images => {
    let imageDataUrl = await readFile(images[0].file);
    imageDataUrl = await getRotatedImage(imageDataUrl);
    images[0].data_url = imageDataUrl;
    setImages(images);
  };

  const onCropChange = crop => {
    setCropsData(crop);
  };

  const onUploadAgain = () => {
    setErrorUploadToCdn(false);
    setImages([]);
  };

  return (
    <div>
      {!errorUploadToCdn ? (
        <>
          {images.length > 0 ? (
            <ImageCropWrapper>
              <Heading
                level="h3"
                weight="legendBold"
                css={{ lineHeight: '1.3em', marginBottom: '$24' }}
              >
                <String id="profile_edit_avatar_crop_title" />
              </Heading>
              <Text css={{ marginBottom: '$32' }}>
                <String id="profile_edit_avatar_crop_text" />
              </Text>
              <div style={{ opacity: avatarEditData.isLoading ? 0.3 : 1 }}>
                <ImageCrop
                  onCropChange={onCropChange}
                  image={images[0]}
                  cropperStyle={{
                    height: '280px',
                    width: '100%',
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="normal"
                  label="Cancel"
                  colorTheme="gray"
                  onClick={props.onClose}
                  actionType="onclick"
                  css={{
                    marginRight: '$16',
                    opacity: avatarEditData.isLoading ? 0.3 : 1,
                  }}
                />
                <Button
                  type="normal"
                  label="Save"
                  colorTheme="normal"
                  onClick={onSave}
                  actionType="onclick"
                  css={{ opacity: avatarEditData.isLoading ? 0.3 : 1 }}
                  isLoading={avatarEditData.isLoading}
                />
              </div>
            </ImageCropWrapper>
          ) : (
            <ImageUploaderWrapper>
              <Heading
                level="h3"
                weight="legendBold"
                css={{ lineHeight: '1.3em', marginBottom: '$24' }}
              >
                <String id="profile_edit_avatar_upload_title" />
              </Heading>
              <Heading
                level="h6"
                css={{ fontFamily: 'var(--ArtifaktLegendBold)' }}
              >
                <String id="profile_edit_avatar_upload_text" />
              </Heading>
              <ImageUploading
                value={images}
                onChange={onDrop}
                maxNumber={1}
                dataURLKey="data_url"
                resolutionType="more"
                resolutionWidth="400"
                resolutionHeight="400"
                onError={() => {
                  setErrorUpload(true);
                }}
              >
                {({ onImageUpload, dragProps }) => (
                  <div className="upload__image-wrapper">
                    <Button
                      actionType="onclick"
                      onClick={onImageUpload}
                      label={<String id="profile_edit_avatar_crop_button" />}
                      css={{ whiteSpace: 'nowrap', marginRight: '$16' }}
                      {...dragProps}
                    />
                    <Text css={{ lineHeight: '1.3em', fontSize: '$12' }}>
                      <String id="profile_edit_avatar_crop_instruction" />
                      {errorUpload ? (
                        <Text css={{ color: 'red', marginTop: '$8' }}>
                          <String id="profile_edit_avatar_crop_error_size" />
                        </Text>
                      ) : null}
                    </Text>
                  </div>
                )}
              </ImageUploading>
            </ImageUploaderWrapper>
          )}
        </>
      ) : (
        <ImageUploaderWrapper>
          <Heading level="h3" css={{ lineHeight: '1em', marginBottom: '$24' }}>
            <String id="profile_avatar_error_upload_recognition_title" />
          </Heading>
          <Text css={{ marginBottom: '$32' }}>
            <String id="profile_avatar_error_upload_recognition_text" />
          </Text>
          <Button
            type="normal"
            label={
              <String id="profile_avatar_error_upload_recognition_button" />
            }
            onClick={onUploadAgain}
            actionType="onclick"
            css={{ marginRight: '$16' }}
          />
        </ImageUploaderWrapper>
      )}
    </div>
  );
};

export default AvatarEdit;
