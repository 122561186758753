import {
  BadgeImage,
  BadgeInfoboxWrapper,
  BadgeWrapper,
} from './BadgeToolsIcon.styled.js';
import React, { useState } from 'react';
import { validImageUrl } from '@src/templates/create/utils';
import Image from '@components/atoms/image/Image';
import Infobox from '@components/atoms/infobox/Infobox';
import PropTypes from 'prop-types';

const BadgeToolsIcon = props => {
  const [isShown, setIsShown] = useState(false);

  let imageUrl = '';
  const url = props.imageDynamic?.src?.path;
  const newUrl = url?.replace(/^\/|\/$/g, '');

  if (newUrl?.includes('images')) {
    imageUrl = `/${newUrl}`;
  } else {
    imageUrl = `${CDN_URL}${newUrl}`;
  }

  return (
    <BadgeWrapper
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      {props.infoboxActive ? (
        <BadgeInfoboxWrapper visible={isShown}>
          <Infobox
            title={props.infoboxTitle}
            label={props.infoboxLabel}
          ></Infobox>
        </BadgeInfoboxWrapper>
      ) : null}
      <BadgeImage>
        <img src={imageUrl} alt={props.alt} />
      </BadgeImage>
    </BadgeWrapper>
  );
};

BadgeToolsIcon.propTypes = {
  imageDynamic: PropTypes.object.isRequired,
  alt: PropTypes.string,
  infoboxActive: PropTypes.bool,
  infoboxTitle: PropTypes.string,
  infoboxLabel: PropTypes.string,
};

BadgeToolsIcon.defaultProps = {
  infoboxActive: false,
  infoboxTitle: null,
  infoboxLabel: null,
  imageDynamic: {},
  alt: '',
};

export default BadgeToolsIcon;
