import {
  BackBtn,
  BackLinkText,
  BannerText,
  ContentBottomContainer,
  ContentTopContainer,
  ContentWrapper,
  IconImage,
  PageWrapper,
  SidebarWrapper,
  StyledSvg,
  TableWrapper,
  Title,
  TitleContainer,
  TopBanner,
} from '../groupMembersModeration/GroupMembersModeration.styled';
import {
  GET_GROUP_BASIC_DETAILS,
  GET_MY_GROUPS_ID,
  GET_POSTS_FILTERED,
  GET_POSTS_FILTERED_COUNT,
} from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@context/AuthContext';
import GroupCommunitySideBar from '@components/molecules/groupCommunitySidebar/GroupCommunitySideBar';
import Image from '@components/atoms/image/Image';
import Layout from '@components/organisms/layout/Layout';
import Loader from '@components/atoms/loader/Loader';
import PropTypes from 'prop-types';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import ReportedContentTable from './ReportedContentTable';
import String from '@components/atoms/string/String';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';

const ReportedContentPage = context => {
  const convertUTCToLocalDate = date => {
    if (!date) {
      return date;
    }
    date = new Date(date * 1000);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );

    return format(date, 'LLL, d y');
  };

  const authContext = useContext(AuthContext);
  const [userRole, setUserRole] = useState('');

  const [groupState, setGroupState] = useState({
    name: '',
    iconUrl: '',
    loaded: false,
    id: null,
    reportedContentCount: 0,
  });

  const [groupReportsState, setGroupReportsState] = useState({
    loaded: false,
    limitRow: 12,
    page: 1,
    currentPageCount: null,
    maxPage: 1,
    loading: false,
    input: {},
  });

  const [reportsListState, setReportsList] = useState({
    reportsList: [],
    limit: 5,
    page: 1,
  });

  const [reportedPostsCount, setReportedPostsCount] = useState(null);
  const [pendingPostsCount, setPendingPostsCount] = useState(0);

  const [groupRoleState, setGroupRole] = useState({
    role: '',
    loaded: false,
  });

  const [getgroupRoleData, groupRoleData] = useLazyQuery(GET_MY_GROUPS_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (groupState.id !== null) {
      getgroupRoleData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (groupRoleData.data) {
      let role = '';
      for (let i = 0; i < groupRoleData.data.mygroups.length; i++) {
        if (groupRoleData.data.mygroups[i].id === groupState.id) {
          role = groupRoleData.data.mygroups[i].group_role;
        }
      }
      setGroupRole({
        ...groupRoleState,
        role,
        loaded: true,
      });
    }
  }, [groupRoleData.data]);

  useEffect(() => {
    if (groupRoleState.loaded) {
      setUserRole(
        authContext.generalRole === 'admin' ||
          groupRoleState.role === 'moderator'
          ? 'admin'
          : 'member'
      );
    }
  }, [groupRoleState.loaded]);

  const { loading, error, data, refetch } = useQuery(GET_GROUP_BASIC_DETAILS, {
    variables: {
      slug: context.params.slug,
      feed: 'recent',
    },
    context: { clientName: 'public' },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.group) {
      setGroupState({
        ...groupState,
        name: data?.group?.name,
        iconUrl: getImageSrc(data.group.icon, 'group_icon', data.group.title),
        loaded: true,
        id: data.group.id,
      });

      setGroupReportsState({
        ...groupReportsState,
        maxPage: Math.ceil(
          data.group.members_count / groupReportsState.limitRow
        ),
        loaded: true,
      });
    }
  }, [data]);

  const [getPostFilteredData, postFilteredData] = useLazyQuery(
    GET_POSTS_FILTERED,
    {
      variables: {
        groupId: groupState.id,
        status: 'reported',
        limit: groupReportsState.limitRow,
        page: groupReportsState.page,
        input: groupReportsState.input,
      },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getPostFilteredData();
    }
  }, [groupState.id]);

  const fetchWithFilters = filters => {
    setGroupReportsState({
      ...groupReportsState,
      input: filters,
      page: 1,
    });
    postFilteredData.refetch();
  };

  const onSearchSubmit = searchInput => {
    setGroupReportsState({
      ...groupReportsState,
      input: searchInput !== '' ? { search: searchInput } : {},
      page: 1,
    });
    postFilteredData.refetch();
  };

  const getAvatar = profilePicture => {
    return getAvatarImage(profilePicture);
  };

  useEffect(() => {
    if (postFilteredData.data) {
      const reportsList = [];
      postFilteredData.data.postsFiltered.map((post, index) => {
        const reporting_list = [];
        let postOrCommentData = {};

        let profilePicture = getAvatar(
          post?.author?.userProfile?.profile_picture,
          'xsmall'
        );

        let postLinkPreview = {};

        if (post.link_url !== null && post.link_url !== '') {
          postLinkPreview = {
            url: post.link_url,
            title: post.link_title,
            description: post.link_description,
            previewImage: post.link_image
              ? {
                  src: {
                    src: CDN_URL + post.link_image.path,
                  },
                  source: [
                    {
                      srcset: `${CDN_URL}${post.link_image[1]?.path}`,
                      media: '(min-width: 769px)',
                    },
                    {
                      srcset: `${CDN_URL}${post.link_image[0]?.path}`,
                      media: '(max-width: 768px)',
                    },
                  ],
                  alt: post.link_url,
                }
              : null,
          };
        }

        const comments = [];

        post.children.map((reply, i) => {
          if (reply.status === 'approved') {
            comments.push(reply);
          }
        });

        const postImages = [];

        post.images ? (
          <>
            {post.images.map((image, i) => {
              const imageContent = {
                src: CDN_URL + image.path,
                source: [
                  {
                    srcset: `${CDN_URL}${image.sizes[1]?.path}`,
                    media: '(min-width: 769px)',
                  },
                  {
                    srcset: `${CDN_URL}${image.sizes[0]?.path}`,
                    media: '(max-width: 768px)',
                  },
                ],
                alt: `${post.author.first_name} ${post.author.last_name}`,
                id: image.id,
              };
              postImages.push({...image, ...imageContent});
            })}
          </>
        ) : null;
        if (post.level === 0) {
          postOrCommentData = {
            reportId: '',
            replies: comments,
            postCount: comments.length,
            postLinkPreview:
              post.link_url !== null && post.link_url !== ''
                ? postLinkPreview
                : null,
            postId: post.id,
            level: post.level,
            userInfos: {
              avatarProps: {
                shape: 'circle',
                alt: `${post.author.first_name} ${post.author.last_name}`,

                src: profilePicture,
                url: `/m/${authContext.userSlug}`,
              },
              modalProps: {},
            },
            postLikes: post.usersLikedCount,
            postDate: convertUTCToLocalDate(post.createdAt),
            postAuthor: {
              username: post.author.username,
              name: `${post.author.first_name} ${post.author.last_name}`,
              jobTitle: post.author.title,
              avatar: {
                src: profilePicture,
              },
              url: `/m/${post.author.username}`,
            },
            postText: post.content,
            postImages,
            favoriteList: post.usersFavorite,
            likesList: post.usersLiked,
          };
        } else {
          postOrCommentData = {
            replies: comments,
            postCount: 0,
            postLinkPreview:
              post.link_url !== null && post.link_url !== ''
                ? postLinkPreview
                : null,
            postId: post.id,
            level: post.level,
            userInfos: {
              avatarProps: {
                shape: 'circle',
                alt: `${post.author.first_name} ${post.author.last_name}`,

                src: profilePicture,
                url: `/m/${post.author.username}`,
              },
              modalProps: {},
            },
            postLikes: post.usersLikedCount,
            postDate: convertUTCToLocalDate(post.createdAt),
            postAuthor: {
              username: post.author.username,
              name: `${post.author.first_name} ${post.author.last_name}`,
              jobTitle: post.author.title,
              avatar: {
                src: profilePicture,
              },
              url: `/m/${post.author.username}`,
            },
            postText: post.content,
            postImages: postImages,
            commentImages: postImages,
            favoriteList: post.usersFavoriteCount,
            likesList: post.usersLikedCount,
          };
        }
        post.reports.data.map((report, j) => {
          const reportData = {
            username: report.reporter.username,
            name: `${report.reporter.first_name} ${report.reporter.last_name}`,
            avatar: report.reporter.userProfile.profile_picture,
          };
          reporting_list.push(reportData);
        });
        const postData = {
          first_name: post.author.first_name,
          last_name: post.author.last_name,
          title: post.author.title,
          userProfile: post.author.userProfile,
          postData: postOrCommentData,
          username: post.author.username,
          group_posts_count: post.reports.total,
          reporting_list,
          post_date: post.createdAt,
          report_date:
            post.reports.data[post.reports.data.length - 1].createdAt,
          id: post.reports.data[post.reports.data.length - 1].id,
          content_type: post.level === 0 ? 0 : 1,
          report_type: post.reports.data[post.reports.data.length - 1].type,
          total: postOrCommentData.replies.length,
        };

        reportsList.push(postData);
      });

      setReportsList({
        ...reportsListState,
        reportsList,
      });
    }
  }, [postFilteredData.data]);

  const [getPostFilteredCountData, postFilteredCountData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'reported',
      },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getPostFilteredCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (postFilteredData.data) {
      if (groupReportsState.page === 1) {
        setGroupReportsState({
          ...groupReportsState,
          loaded: true,
          loading: false,
          maxPage: Math.ceil(reportedPostsCount / groupReportsState.limitRow),
          currentPageCount: `1 - ${
            groupReportsState.limitRow <
            postFilteredData?.data?.postsFiltered?.length
              ? groupReportsState.limitRow
              : postFilteredData?.data?.postsFiltered?.length
          }`,
        });
      } else {
        setGroupReportsState({
          ...groupReportsState,
          loaded: true,
          loading: false,
        });
      }
    }
  }, [postFilteredData.data]);

  useEffect(() => {
    if (postFilteredCountData.data) {
      setReportedPostsCount(postFilteredCountData.data.postsFilteredCount);
    }
  }, [postFilteredCountData.data]);

  const [getPendingCountData, pendingCountData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'pending',
      },
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getPendingCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (pendingCountData.data) {
      setPendingPostsCount(pendingCountData.data.postsFilteredCount);
    }
  }, [pendingCountData.data]);

  const groupUrl = context.location.pathname.substring(
    0,
    context.location.pathname.indexOf(`/${context.params.slug}`) +
      `/${context.params.slug}`.length
  );

  const activeTab = context.location.pathname.substring(
    context.location.pathname.indexOf('/admin/') + '/admin/'.length
  );

  const onNextPage = () => {
    const pageCountFirstNumber =
      groupReportsState.page * groupReportsState.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + groupReportsState.limitRow - 1;
    if (pageCountSecondNumber > reportedPostsCount) {
      pageCountSecondNumber = reportedPostsCount;
    }

    setGroupReportsState({
      ...groupReportsState,
      page: groupReportsState.page + 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (groupReportsState.page - 2) * groupReportsState.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + groupReportsState.limitRow - 1;
    if (pageCountSecondNumber > reportedPostsCount) {
      pageCountSecondNumber = reportedPostsCount;
    }
    setGroupReportsState({
      ...groupReportsState,
      page: groupReportsState.page - 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setGroupReportsState({
      ...groupReportsState,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(reportedPostsCount / rowCount),
    });
  };

  const onCloseReportFetch = () => {
    postFilteredData.refetch();
    postFilteredCountData.refetch();
  };

  return (
    <ProtectedRoute
      redirectTo={groupUrl}
      userAccessReq={['auth', userRole, ['admin']]}
    >
      <Layout location={context.location}>
        <PageWrapper>
          <TopBanner>
            <BannerText size="12">
              <String id="community_moderation_title_bar" />
            </BannerText>
          </TopBanner>
          <ContentWrapper>
            <ContentTopContainer>
              <BackBtn
                title={`${(<String id="community_moderation_title_bar" />)} ${
                  context.params.slug
                }`}
                to={groupUrl}
              >
                <StyledSvg icon="arrow-left-short" />
                <BackLinkText size="12">
                  <String id="community_moderation_back_text" />
                </BackLinkText>
              </BackBtn>
              {groupState.loaded ? (
                <TitleContainer>
                  <IconImage>
                    <Image
                      src={groupState.iconUrl}
                      alt="icon"
                      isBackground={false}
                    />
                  </IconImage>

                  <Title>{groupState.name}</Title>
                </TitleContainer>
              ) : null}
            </ContentTopContainer>
            <ContentBottomContainer>
              <SidebarWrapper>
                <GroupCommunitySideBar
                  activeTab={activeTab}
                  groupUrl={groupUrl}
                  location={context.location}
                  pendingNotifications={{
                    members: {
                      amount: 0,
                    },
                    publication: {
                      amount: pendingPostsCount,
                    },
                    reportContent: {
                      amount: reportedPostsCount,
                    },
                  }}
                />
              </SidebarWrapper>
              <TableWrapper>
                {groupReportsState.loaded && reportedPostsCount !== null ? (
                  <ReportedContentTable
                    reportsList={reportsListState.reportsList}
                    onNextPage={onNextPage}
                    onPrevPage={onPrevPage}
                    reportedContentCount={reportedPostsCount}
                    currentPageCount={groupReportsState?.currentPageCount}
                    onChangeLimitRow={onChangeLimitRow}
                    reportsCount={reportedPostsCount}
                    currentPage={groupReportsState.page}
                    maxPage={groupReportsState.maxPage}
                    groupId={groupState.id}
                    refetch={onCloseReportFetch}
                    loading={
                      groupReportsState.loading || postFilteredData.loading
                    }
                    fetchWithFilters={fetchWithFilters}
                    onSearchSubmit={onSearchSubmit}
                  />
                ) : (
                  <Loader isLoading="true" />
                )}
              </TableWrapper>
            </ContentBottomContainer>
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </ProtectedRoute>
  );
};
export default ReportedContentPage;

ReportedContentPage.propTypes = {};

ReportedContentPage.defaultProps = {};
