import { styled } from '@root/stitches.config';

export const Container = styled('div', {
  height: '5px',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '$16',
});

export const Label = styled('span', {
  padding: '$4',
  color: '$mainTextColor',
  fontFamily: '$ArtifaktLegendBold',
  marginLeft: '$24',
  fontSize: '$16',
});

export const ProgressBarWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
