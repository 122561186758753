import { styled } from '@root/stitches.config';

export const WrapperBlock = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  a: {
    textDecoration: 'none',
  },
});

export const WrapperTextAsset = styled('div', {
  display: 'flex',
  gap: '$12',
});
