import {
  GuidelineModalContent,
  StyledHeading2,
  StyledHeading6,
} from './GuidelineModal.styled';

import Button from '@components/atoms/button/Button';
import { Flex } from '@components/atoms/flex/Flex';
import { Heading } from '@components/atoms/heading/Heading';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';

const GuidelineModal = props => {
  const {
    visibleDefault,
    onToggleGuidelinesClick,
    modalTitle,
    actions,
    sections,
  } = props;

  return (
    <Modal isShowing={visibleDefault} hide={onToggleGuidelinesClick}>
      <Heading level="h3">
        <String id={modalTitle} />
      </Heading>
      {sections.map((item, i) => {
        const { title, contents } = item;
        return (
          <GuidelineModalContent key={i}>
            <StyledHeading2 level="h2">
              <String id={title} />
            </StyledHeading2>
            {contents.map((content, i) => {
              return (
                <Flex key={i} className="community_guidelines_content">
                  {content.bulletSvg && (
                    <Svg
                      className={`community_guidelines_svg ${content.bulletSvg}`}
                      icon={content.bulletSvg}
                    />
                  )}
                  <div>
                    <StyledHeading6 level="h6" wieght="bold">
                      {content.title}
                    </StyledHeading6>
                    {content.text && <Text size="14">{content.text}</Text>}
                  </div>
                </Flex>
              );
            })}
          </GuidelineModalContent>
        );
      })}
      {actions && (
        <GuidelineModalContent>
          <Flex className="community_guidelines_actions">
            {actions.map((action, i) => (
              <Button
                key={i}
                label={action.label}
                internalUrl={action.internalUrl}
                externalUrl={action.externalUrl}
                onClick={action.onClick}
                actionType={action.actionType}
              />
            ))}
          </Flex>
        </GuidelineModalContent>
      )}
    </Modal>
  );
};

export default GuidelineModal;

GuidelineModal.propTypes = {
  visibleDefault: PropTypes.bool,

  modalTitle: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      contents: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          bulletSvg: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      colorTheme: PropTypes.string,
      internalUrl: PropTypes.string,
      externalUrl: PropTypes.string,
      actionType: PropTypes.oneOf(['onclick', 'externalLink', 'internalLink']),
      onClick: PropTypes.func,
    })
  ),
};

GuidelineModal.defaultProps = {
  visibleDefault: false,
};
