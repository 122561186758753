module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AREA - Autodesk","short_name":"AREA","start_url":"/","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"db66ea1d1d9b4055c46598bf53ff6b9a"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["./static/fonts/artifakt","./static/fonts/artifakt_legend"],"custom":[{"name":["ArtifaktElementLight","ArtifaktElementMedium","ArtifaktElementRegular","ArtifaktElementBold","ArtifaktElementExtraBold","ArtifaktElementBlack","ArtifaktLegendLight","ArtifaktLegendMedium","ArtifaktLegendRegular","ArtifaktLegendBold","ArtifaktLegendExtraBold","ArtifaktLegendBlack"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
