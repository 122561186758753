import { PageInner, PageWrapper } from './AccountSettings.styled';
import React, { useContext, useEffect, useState } from 'react';

import AccountSettingsContent from './AccountSettingsContent';
import AccountSettingsSidebar from '@components/molecules/accountSettingsSidebar/AccountSettingsSidebar';
import AuthContext from '@src/context/AuthContext';
import { GET_PROFILE_SETTINGS_INFO } from '@src/apollo/query';
import Layout from '@components/organisms/layout/Layout';
import Loader from '@components/atoms/loader/Loader';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import SEO from '@components/atoms/seo/Seo';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { useQuery } from '@apollo/client';

const sidebarLinks = [
  {
    to: '#login-and-security',
    label: 'account_settings_login_and_security_link',
  },
  {
    to: '#autodesk-password',
    label: 'account_settings_autodesk_password_link',
  },
  {
    to: '#two-factor-identification',
    label: 'account_settings_two_factor_identification_link',
  },
  {
    to: '#connexion',
    label: 'account_settings_connexion_link',
  },
  {
    to: '#app-access',
    label: 'account_settings_app_access_link',
  },
  {
    to: '#delete-account',
    label: 'account_settings_delete_account_link',
  },
];

const AccountSettingsPage = context => {
  const { loading, error, data } = useQuery(GET_PROFILE_SETTINGS_INFO, {
    variables: { username: context.slug },
  });

  const [profileData, setProfileData] = useState();

  useEffect(() => {
    if (data?.user) {
      const avatarSrc = getAvatarImage(
        data.user.userProfile.profile_picture,
        'small'
      );
      setProfileData({
        firstName: data.user.first_name,
        lastName: data.user.last_name,
        // email: data.user.email,
        username: data.user.username,
        id: data.user.id,
        memberSince: parseInt(data.user.createdAt, 10),
        avatar: avatarSrc,
      });
    }
  }, [data]);

  return (
    <ProtectedRoute redirectTo="/login" userAccessReq={['auth', 'verified']}>
      <Layout location={context.location} showBreadcrumb>
        <SEO
          title="Account settings"
          slug="account-settings"
          seomatic={context?.pageContext?.entry?.seomatic}
        />
        <PageWrapper>
          <PageInner>
            {loading && <Loader isLoading={loading} />}
            {profileData !== undefined && !loading && (
              <>
                <AccountSettingsSidebar
                  profileData={profileData}
                  links={sidebarLinks}
                />
                <AuthContext.Consumer>
                  {auth => (
                    <AccountSettingsContent
                      auth={auth}
                      context={context}
                      userData={{
                        username: profileData.username,
                        // email: profileData.email,
                        id: profileData.id,
                      }}
                    />
                  )}
                </AuthContext.Consumer>
              </>
            )}
          </PageInner>
        </PageWrapper>
      </Layout>
    </ProtectedRoute>
  );
};

export default AccountSettingsPage;
