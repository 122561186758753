import { styled } from '@root/stitches.config';

export const ProfileBoxListWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '60px auto',
  gridGap: '$24',
  gridArea: 'right',
  variants: {
    hasImage: {
      false: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  '@bp1': {
    gridArea: 'center',
    alignItems: 'center',
  },
});

export const ProfileBoxListImage = styled('div', {
  position: 'relative',
  background: 'white',
  variants: {
    type: {
      listThumbsVertical: {
        height: '80px',
        borderRadius: '$8',
        img: {
          width: '60px',
          height: '80px',
          objectFit: 'cover',
          borderRadius: '$8',
        },
      },
      listThumbsCircle: {
        height: '60px',
        borderRadius: '$round',
        img: {
          width: '60px',
          height: '60px',
          objectFit: 'cover',
          boxShadow: '$4',
        },
      },
      listThumbsSquare: {
        height: '60px',
        borderRadius: '$8',
        img: {
          width: '60px',
          height: '60px',
          objectFit: 'cover',
          boxShadow: '$4',
          borderRadius: '$8',
        },
      },
    },
  },
});

export const ProfileBoxListContentCenter = styled('div', {
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'auto auto',
  gridTemplateRows: 'auto auto',
  gridTemplateAreas: '"left right" "date date"',

  '@bp1': {
    alignItems: 'center',
    gridTemplateColumns: 'auto minmax(160px, 1fr) auto',
    gridTemplateAreas: '"left center right"',
    gridTemplateRows: 'auto',
  },
});

export const ProfileBoxListContentInfo = styled('div', {
  gridArea: 'left',
});

export const ProfileBoxListTitle = styled('div', {
  color: '$mainTextColor',
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$18',
  lineHeight: '20px',
});
export const ProfileBoxListSubTitle = styled('div', {
  fontFamily: '$ArtifaktElementBold',
  fontSize: '$14',
});
export const ProfileBoxListLocation = styled('div', {
  color: '$darkGrey',
  fontSize: '$14',
  fontFamily: '$ArtifaktElement',
  lineHeight: '16px',
  '@bp1': {
    fontSize: '$12',
  },
});

export const ProfileBoxListContentRight = styled('div', {
  display: 'flex',

  justifyContent: 'flex-end',

  bp0: {
    ai: 'center',
    textAlign: 'right',
  },
});

export const ProfileBoxListDate = styled('div', {
  color: '$darkGrey',
  fontSize: '$14',
  fontFamily: '$ArtifaktElement',
  lineHeight: '16px',
  gridArea: 'date',

  '@bp1': {
    gridArea: 'center',
    textAlign: 'right',
    marginRight: '$16',
    fontSize: '$12',
  },
});
