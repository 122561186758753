import { styled } from '@root/stitches.config';

export const CompWrapper = styled('div', {});

export const LinksWrapper = styled('ul', {
  marginTop: '$32',
  li: {
    marginTop: '$16',
  },
  a: {
    textDecoration: 'none',
  },
  'li:first-child': {
    marginTop: 0,
  },
});

export const ProfileDataWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center'
});

export const ProfileDataContent = styled('div', {
  marginLeft: '$16',
});
