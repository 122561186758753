import {useEffect, useState} from 'react';

export function useIsMobile(minWidth = 768, maxWidth = 1024){
  const [isMobile, setIsMobile] = useState(false);
  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const isTouchDevice = () => window.matchMedia("(pointer: coarse)").matches;

  function useCurrentWidth() {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
      let timeoutId = null;

      const isItMobile = () => {
        const width = getWidth();

        if (width <= minWidth || (width <= maxWidth && isTouchDevice())) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      };
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(
          () =>
            setWidth(() => {
              isItMobile();
            }),
          150
        );
      };
      window.addEventListener('resize', resizeListener);

      isItMobile();

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }, []);
    return width;
  }

  const resize = useCurrentWidth();

  return isMobile;
}