import { styled } from '@root/stitches.config';

export const ImageCropImageContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '400px',
  borderRadius: '12px',
  overflow: 'hidden',
  marginBottom: '$24',
  boxShadow: '$2',
});

export const SliderContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  svg: {
    fill: '$mainTextColor',
  },
});

export const ImageCropContainer = styled('div', {
  textAlign: 'center',
  marginBottom: '$32',
  width: '100%',

  '.MuiSlider-root': {
    maxWidth: '180px',
    width: '180px',
    margin: 'auto $16',
    display: 'inline-block',
  },

  '.MuiSlider-rail': {
    color: '$mediumGrey',
  },

  '.MuiSlider-track': {
    color: '$darkGrey',
  },
  '.MuiSlider-thumb': {
    backgroundColor: 'white',
    border: '1px solid $darkGrey',
    width: '$16',
    height: '$16',
    marginTop: '-7px',
  },
  '.reactEasyCrop_CropArea': {
    color: 'rgba(115, 115, 115, 0.5)',
  },
  'img': {
    imageOrientation: 'none',
  },
})

