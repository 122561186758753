import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import { Heading } from '@components/atoms/heading/Heading';
import React from 'react';
import SEO from '@components/atoms/seo/Seo';
import { Row2Col, Row3Col, RowFull } from './Stats.styled';
import { SmallStatBlock } from '@components/molecules/statsBlocks/SmallStatBlock';
import { TrendingContentBlock } from '@components/molecules/statsBlocks/TrendingContentBlock';
import { PolarGraphBlock } from '@components/molecules/statsBlocks/PolarGraphBlock';
import { DoughnutGraphBlock } from '@components/molecules/statsBlocks/DoughnutGraphBlock';
import TableBlock from '@components/molecules/statsBlocks/TableBlock';
import MapChartBlock from '@components/molecules/statsBlocks/MapChartBlock';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_DATA } from '@src/apollo/query';
import String from '@components/atoms/string/String';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import { generateRandomColor } from '@src/utils/generateRandomColor';

const Dashboard = context => {
  const { data, loading, error } = useQuery(GET_DASHBOARD_DATA, {
    context: { clientName: 'cms' },
  });

  return (
    <AdminLayout
      location={context.location}
      roles={['admin', 'editor']}
    >
      <SEO title="Area" />
      <Heading level="h1" weight="bold" css={{ fontSize: '$48' }}>
        <String id="admin_cms_dashboard_title" />
      </Heading>
      <div style={{ position: 'relative', width: '100%' }}>
        <Row3Col>
          {loading ? (
            <>
              <LoaderSkeleton height="200px" width="100%" isRadius />
              <LoaderSkeleton height="200px" width="100%" isRadius />
              <LoaderSkeleton height="200px" width="100%" isRadius />
            </>
          ) : (
            <>
              <SmallStatBlock
                title="Members"
                stat={data.dashboardStats.members_count}
                percentage={`${
                  data.dashboardStats.members_diff >= 0 ? '+' : ''
                }${data.dashboardStats.members_diff}%`}
                iconName="thumbnail-level"
                negativePercent={data.dashboardStats.members_diff < 0}
              />
              <SmallStatBlock
                title="Contents"
                stat={data.dashboardStats.contents_count}
                percentage={`${
                  data.dashboardStats.contents_diff >= 0 ? '+' : ''
                }${data.dashboardStats.contents_diff}%`}
                iconName="article"
                negativePercent={data.dashboardStats.contents_diff < 0}
              />
              <SmallStatBlock
                title="Community Posts"
                stat={data.dashboardStats.posts_count}
                percentage={`${data.dashboardStats.posts_diff >= 0 ? '+' : ''}${
                  data.dashboardStats.posts_diff
                }%`}
                iconName="edit"
                negativePercent={data.dashboardStats.posts_diff < 0}
              />
            </>
          )}
        </Row3Col>
        <RowFull>
          {loading ? (
            <LoaderSkeleton height="400px" width="100%" isRadius />
          ) : (
            <TrendingContentBlock
              title={data.dashboardStats.trending_content.title}
              data={{
                labels: data.dashboardStats.trending_content.datas.map(
                  l => l.key
                ),
                datasets: [
                  {
                    label: 'Dataset',
                    data: data.dashboardStats.trending_content.datas.map(
                      d => d.count
                    ),
                    borderColor: '#916cf6',
                    fill: false,
                    stepped: true,
                  },
                ],
              }}
              percentagePageView={`${
                data.dashboardStats.trending_content.views >= 0 ? '+' : ''
              }${data.dashboardStats.trending_content.views}%`}
              percentagePageViewColor={
                data.dashboardStats.trending_content.likes >= 0 ? 'green' : 'red'
              }
              percentageLikes={`${
                data.dashboardStats.trending_content.likes >= 0 ? '+' : ''
              }${data.dashboardStats.trending_content.likes}%`}
              percentageLikesColor={
                data.dashboardStats.trending_content.likes >= 0 ? 'green' : 'red'
              }
            />
          )}
        </RowFull>
        <RowFull>
          {loading ? (
            <LoaderSkeleton height="400px" width="100%" isRadius />
          ) : (
            <MapChartBlock
              title="Members by location"
              countriesData={data.dashboardStats.members_locations.map(el => ({
                numericCode: el.key,
                userCount: el.count,
              }))}
            />
          )}
        </RowFull>
        <Row2Col>
          {loading ? (
            <>
              <LoaderSkeleton height="400px" width="100%" isRadius />
              <LoaderSkeleton height="400px" width="100%" isRadius />
            </>
          ) : (
            <>
              <PolarGraphBlock
                title="Report activity"
                data={{
                  labels: data.dashboardStats.report_activity.map(l => l.key),
                  datasets: [
                    {
                      data: data.dashboardStats.report_activity.map(
                        d => d.count
                      ),
                      backgroundColor: data.dashboardStats.report_activity.map(
                        () => generateRandomColor(0.5)
                      ),
                    },
                  ],
                }}
              />
              <DoughnutGraphBlock
                title="Content creation"
                data={{
                  labels: data.dashboardStats.contents_creation.map(l => l.key),
                  datasets: [
                    {
                      data: data.dashboardStats.contents_creation.map(
                        d => d.count
                      ),
                      backgroundColor: data.dashboardStats.contents_creation.map(
                        () => generateRandomColor()
                      ),
                    },
                  ],
                }}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading ? (
            <>
              <LoaderSkeleton height="600px" width="100%" isRadius />
              <LoaderSkeleton height="600px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Most active creators',
                    accessor: 'key',
                  },
                  {
                    Header: '# of content',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.dashboardStats.top_active_creators}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Top Page Views',
                    accessor: 'key',
                  },
                  {
                    Header: '# of Views',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.dashboardStats.top_page_views}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading ? (
            <>
              <LoaderSkeleton height="600px" width="100%" isRadius />
              <LoaderSkeleton height="600px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Most active groups',
                    accessor: 'key',
                  },
                  {
                    Header: '# of posts',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.dashboardStats.top_active_groups}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Top Liked Content',
                    accessor: 'key',
                  },
                  {
                    Header: '# of Likes',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.dashboardStats.top_liked_contents}
              />
            </>
          )}
        </Row2Col>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
