import React, { useContext, useState } from 'react';

import AuthContext from '@context/AuthContext';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import { SignUpBoxWrapper } from '@src/templates/signup-validation/SignUpValidation.styled';
import String from '@components/atoms/string/String';
import UserPhoneVerificationCode from '@components/organisms/signUpBox/userSignUpVerification/UserPhoneVerificationCode';
import VerifyByPhoneConfirmation from './verifyByPhoneConfirmation/VerifyByPhoneConfirmation';

const VerifySmsCodePage = ({ context, roles }) => {
  const authContext = useContext(AuthContext);
  const [isVerifyCompleted, setIsVerifyCompleted] = useState(false);
  const [onboardingCompletionState, setOnboardingCompletionContext] = useState([
    {
      title: <String id="sign_up_page_steps_1" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_2" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_3" />,
      isActive: true,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_4" />,
      isActive: false,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_5" />,
      isActive: false,
      isCompleted: false,
    },
  ]);
  const pageTitle = (
    <>
      👋 <String id="sign_up_validation_welcome" />
    </>
  );

  return (
    <ProtectedRoute
      redirectTo={'/login'}
      userAccessReq={[
        'auth',
        authContext.generalRole,
        roles || ['member', 'contributor', 'editor', 'admin'],
      ]}
    >
      <OnboardingCompletionContext.Provider
        value={[onboardingCompletionState, setOnboardingCompletionContext]}
      >
        <OnboardingLayout
          pageTitle={pageTitle}
          sideBarTitle={<String id="sign_up_validation_sideBarTitle" />}
          displaySteps
          displayAvatar
        >
          {isVerifyCompleted}
          {!isVerifyCompleted ? (
            <SignUpBoxWrapper>
              <UserPhoneVerificationCode />
            </SignUpBoxWrapper>
          ) : (
            <VerifyByPhoneConfirmation />
          )}
        </OnboardingLayout>
      </OnboardingCompletionContext.Provider>
    </ProtectedRoute>
  );
};

export default VerifySmsCodePage;
