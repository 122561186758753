import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

export const Heading = props => {
  const { level, css, initial, bp2, children, className, weight } = props;

  const Heading1 = () => (
    <Text
      as="h1"
      size={{
        '@initial': '50',
        '@bp2': '60',
      }}
      className={className}
      weight={weight ? weight : 'ArtifaktLegendBold'}
      css={{
        ...css,
        '@bp2': {
          bp2,
        },
      }}
    >
      {children}
    </Text>
  );

  const Heading2 = () => (
    <Text
      as="h2"
      size={{
        '@initial': '42',
        '@bp2': '42',
      }}
      className={className}
      weight={weight ? weight : 'ArtifaktLegendBold'}
      css={{
        ...css,
        '@bp2': {
          bp2,
        },
      }}
    >
      {children}
    </Text>
  );

  const Heading3 = () => (
    <Text
      as="h3"
      size={{
        '@initial': '24',
        '@bp2': '32',
      }}
      className={className}
      weight={weight ? weight : 'ArtifaktLegendBold'}
      css={{
        ...css,
        '@bp2': {
          bp2,
        },
      }}
    >
      {children}
    </Text>
  );

  const Heading4 = () => (
    <Text
      as="h4"
      size={{
        '@initial': '24',
        '@bp2': '24',
      }}
      className={className}
      weight={weight ? weight : 'ArtifaktLegendBold'}
      css={{
        ...css,
        '@bp2': {
          bp2,
        },
      }}
    >
      {children}
    </Text>
  );
  const Heading5 = () => (
    <Text
      as="h5"
      size={{
        '@initial': '21',
        '@bp2': '18',
      }}
      className={className}
      weight={weight ? weight : 'ArtifaktLegendBold'}
      css={{
        ...css,
        '@bp2': {
          bp2,
        },
      }}
    >
      {children}
    </Text>
  );
  const Heading6 = () => (
    <Text
      as="h6"
      size={{
        '@initial': '16',
        '@bp2': '16',
      }}
      className={className}
      weight={weight ? weight : 'ArtifaktLegendBold'}
      css={{
        ...css,
        '@bp2': {
          bp2,
        },
      }}
    >
      {children}
    </Text>
  );

  switch (level) {
    case 'h1':
      return <Heading1 />;

    case 'h2':
      return <Heading2 />;

    case 'h3':
      return <Heading3 />;

    case 'h4':
      return <Heading4 />;

    case 'h5':
      return <Heading5 />;

    case 'h6':
      return <Heading6 />;

    default:
      // WIP unknown type! based on the language,
      // there should probably be some error-handling
      // here, maybe an exception
      return <Text />;
  }
};

Heading.propTypes = {
  css: PropTypes.objectOf(PropTypes.any),
  // '@initial': PropTypes.objectOf(PropTypes.any),
  bp2: PropTypes.objectOf(PropTypes.any),
  level: PropTypes.string,
};

Heading.defaultProps = {
  css: {},
  initial: {},
  bp2: {},
  level: '',
  children: '',
};
