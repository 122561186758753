import React from 'react';

import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import Layout from '@components/organisms/layout/Layout';
import { Text } from '@components/atoms/text/Text';
import Unicorn from '@src/assets/svg/unicorn.svg';
import { styled } from '@root/stitches.config';

// import SEO from '@components/atoms/seo/Seo';

const NotFoundPage = () => {
  const stringElements = {
    title: '404',
    intro1: 'This page is just like unicorns.',
    intro2: 'It might exist but we can’t find it.',
    btnLabel: 'GO BACK HOME',
  };

  return (
    <Layout>
      {/* <SEO title="404: Not found" /> */}
      <WrapperPage>
        <SvgUnicorn />
        <WrapperText>
          <Heading404 level="h1">{stringElements.title}</Heading404>
          <TextIntro>{stringElements.intro1}</TextIntro>
          <TextIntro>{stringElements.intro2}</TextIntro>
          <ButtonStyled
            type="normal"
            colorTheme="normal"
            label={stringElements.btnLabel}
            actionType="internalLink"
            internalUrl={'/'}
          />
        </WrapperText>
      </WrapperPage>
    </Layout>
  );
};

export default NotFoundPage;

const WrapperPage = styled('div', {
  //maxWidth: '$container',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$40 $24 $40 $24',
  textAlign: 'center',
  gap: '$24',

  '@bp2': {
    flexDirection: 'row',
    gap: '$92',
    padding: '$84 0 $84 0',
  },
});

const SvgUnicorn = styled(Unicorn, {
  maxWidth: '429px',
  width: '100%',
  path: {
    fill: '$mainTextColor',
  },
});

const WrapperText = styled('div', {
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '@bp2': {
    maxWidth: '30%',
    alignItems: 'flex-start',
  },
});

const Heading404 = styled(Heading, {
  fontSize: '$92 !important',
  lineHeight: '$92 !important',
  fontFamily: '$ArtifaktLegendExtraBold',
  color: '$mainTextColor',
});

const TextIntro = styled(Text, {
  fontSize: '$20',
  lineHeight: '$24 !important',
  fontFamily: '$ArtifaktLegendMedium',
  color: '$mainTextColor',
  '@bp2': {
    fontSize: '$24'
  }
});

const ButtonStyled = styled(Button, {
  width: 'fit-content',
  marginTop: '$40',
});
