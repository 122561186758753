import React from 'react';
import PropTypes from 'prop-types';
import FileUpload from '@components/molecules/fileUpload/FileUpload';
import { AssetBlockWrapper } from './AssetBlock.styled';

const MAX_ASSET_SIZE = 20000;

function AssetBlock(props){
  const {
    currentFileName,
    currentFileSizeKb,
    onChange,
  } = props;
  
  return (
    <AssetBlockWrapper>
      <FileUpload
        fileType='.zip'
        maxSizeKb={MAX_ASSET_SIZE}
        currentFileName={currentFileName}
        currentFileSizeKb={currentFileSizeKb}
        onChange={onChange}
        id="assets_file_upload"
        label={`create_information_field_${(currentFileName !== undefined && currentFileSizeKb !== undefined) ? 'replace' : 'upload'}_asset`}
      />
    </AssetBlockWrapper>
  )
}

AssetBlock.propTypes = {
  currentFileName: PropTypes.string,
  currentFileSizeKb: PropTypes.number,
  onChange: PropTypes.func,
};

AssetBlock.defaultProps = {
  currentFileName: undefined,
  currentFileSizeKb: undefined,
  onChange: () => {},
}

export default AssetBlock;