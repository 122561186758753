import {
  CenterContent,
  CommunityWrapper,
  RightSideBarContainer,
  CommunityFeedWrapper,
} from './Community.styled';
import { GET_MY_GROUPS, GET_POSTS } from '@src/apollo/query';
import { CMS_DELETE_GROUP_POSTS } from '@src/apollo/mutation';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AuthContext from '@context/AuthContext';
import CommunityFeed from '@components/organisms/communityFeed/CommunityFeed';
import CommunityGroupSidebar from '@components/molecules/communityGroupSidebar/CommunityGroupSidebar';
import CommunitySidebar from '@components/organisms/communitySidebar/CommunitySidebar';
import Layout from '@components/organisms/layout/Layout';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import useInfiniteScroll from '@src/utils/useInfiniteScroll';
import CommunityFeedContext from '@context/CommunityFeedContext';

const Community = props => {
  const [isInitialFetching, setIsInitialFetching] = useState(true);
  const authContext = useContext(AuthContext);
  const myGroupsText = <String id="community_sidebar_my_groups" />;
  const recentText = <String id="general_recent_filter_text" />;
  const popularText = <String id="general_popular_filter_text" />;

  const [activeFilter, setActiveFilter] = useState('recent');

  const [communityState, setCommunityState] = useState({
    myGroupsId: [],
  });

  const [communityFeedState, setCommunityFeedState] = useState({
    activityFeed: [],
    isFetching: false,
    infinityOn: false,
    actualPrev: [],
    total: 0,
    activePage: 1,
    lastPage: 1,
    hasMore: false,
    joinNew: null,
    newIds: [],
    feedLoaded: false,
    userInfos: {},
  });

  const [myGroupState, setMyGroupState] = useState({
    myGroupsId: [],
    myGroupsLoaded: false,
    myGroupsJoinDate: [],
    myGroupsRoles: [],
  });

  const {
    loading: loadingMyGroups,
    error: errorMyGroups,
    data: dataMyGroups,
  } = useQuery(GET_MY_GROUPS, {
    notifyOnNetworkStatusChange: true,
    skip: !authContext.isAuthenticated,
  });

  useEffect(() => {
    if (dataMyGroups) {
      const groupsIdList = [];
      const myGroupsJoinDate = [];
      const myGroupsRoles = [];
      dataMyGroups.mygroups.map((group, i) => {
        groupsIdList.push(group.id);
        myGroupsJoinDate.push({ id: group.id, date: group.join_date });
        myGroupsRoles.push({ id: group.id, role: group.group_role });
      });

      if (authContext.isAuthenticated) {
        setCommunityState({
          myGroupsId: groupsIdList,
        });
        setMyGroupState({
          ...myGroupState,
          myGroupsId: groupsIdList,
          myGroupsLoaded: true,
          myGroupsJoinDate: myGroupsJoinDate,
          myGroupsRoles: myGroupsRoles,
        });
      } else {
        setMyGroupState({
          ...myGroupState,
          myGroupsLoaded: true,
        });
      }
    }
    if (!authContext.isAuthenticated) {
      setMyGroupState({
        ...myGroupState,
        myGroupsLoaded: true,
      });
    }
  }, [dataMyGroups]);

  const max_posts = 10;

  const [
    getPosts,
    {
      loading: loadingFeed,
      error: errorFeed,
      data: dataFeed,
      fetchMore: dataFetchMore,
      refetch,
    },
  ] = useLazyQuery(GET_POSTS, {
    variables: {
      limit: max_posts,
      page: 1,
      feed: activeFilter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'public' },
  });

  useEffect(() => {
    if (myGroupState.myGroupsLoaded) {
      getPosts();
    }
  }, [myGroupState.myGroupsLoaded]);

  const onLoadMore = () => {
    const nextPage = dataFeed && dataFeed.posts.current_page + 1;
    dataFetchMore({
      variables: {
        page: nextPage,
        feed: activeFilter,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (prev.posts.data != fetchMoreResult.posts.data) {
          const allPosts = [...prev.posts.data, ...fetchMoreResult.posts.data];
          fetchMoreResult.posts.data = allPosts;
          return fetchMoreResult;
        }
      },
    });
  };

  useEffect(() => {
    if (dataFeed) {
      let filtersList = [];
      if (authContext.isAuthenticated) {
        filtersList = [
          { title: recentText, id: 0 },
          { title: popularText, id: 1 },
          { title: myGroupsText, id: 2 },
        ];
      } else {
        filtersList = [
          { title: recentText, id: 0 },
          { title: popularText, id: 1 },
        ];
      }
      const activityFeed = {
        filtersList,
      };
      setCommunityFeedState({
        ...communityFeedState,
        activityFeed,
        feedLoaded: true,
        lastPage: dataFeed.posts.last_page,
        activePage: dataFeed.posts.current_page,
        hasMore: dataFeed.posts.has_more_pages,
      });
      setIsInitialFetching(false);
    }
  }, [dataFeed]);

  const { element } = useInfiniteScroll({
    threshold: 0.1,
    onBottomHit: onLoadMore,
    hasMore: dataFeed?.posts?.has_more_pages,
    currentPage: communityFeedState.activePage,
    isLoading: loadingFeed,
  });

  const onJoinGroup = id => {
    const newArray = communityState.myGroupsId;

    if (!newArray.indexOf(id)) {
      newArray.push(id);
    }

    newArray.push(id);
    setCommunityState({
      ...communityState,
      myGroupsId: newArray,
    });
    setCommunityFeedState({
      ...communityFeedState,
      joinNew: id,
      newIds: newArray,
    });
  };

  const [deletePosts, deletePostsData] = useMutation(CMS_DELETE_GROUP_POSTS, {
    onCompleted: () => {
      refetch();
    },
  });

  const onDeleteClick = id => {
    deletePosts({
      variables: {
        ids: [id],
      },
    });
  };

  return (
    <Layout location={props.location}>
      <SEO
        title="Community"
        slug="community"
        seomatic={props?.pageContext?.entry?.seomatic}
      />
      <CommunityFeedContext.Provider
        value={[communityState, setCommunityState]}
      >
        <CommunityWrapper>
          <CommunitySidebar sidebarData={props.communitySidebarLeftData} />
          <CenterContent>
            <div ref={element}>
              <CommunityFeedWrapper>
              <CommunityFeed
                postSlugs={dataFeed?.posts?.data.map(post => post.slug)}
                loading={loadingFeed || loadingMyGroups || isInitialFetching}
                showGroupSlider
                onJoinGroup={onJoinGroup}
                isLogged={authContext.isAuthenticated}
                isVerified={
                  authContext.isAuthenticated && authContext.isVerified
                }
                isOnboarded={
                  authContext.isAuthenticated &&
                  JSON.parse(localStorage.getItem('isOnboarded')) === 1
                }
                myGroupState={myGroupState}
                activeFilter={activeFilter}
                onFilterClick={setActiveFilter}
                isFeedLoaded={communityFeedState.feedLoaded}
                infinityLoad={!!(loadingFeed && !isInitialFetching)}
                isInitialFetching={isInitialFetching}
                newIds={communityFeedState.newIds}
                isCommunity
                hasGroups={!!myGroupState.myGroupsId.length}
                maxPosts={max_posts}
                isPost
                activePage={communityFeedState.activePage}
                hasMore={communityFeedState.hasMore}
                onDeleteClick={onDeleteClick}
                generalRole={authContext?.generalRole}
              />
            </CommunityFeedWrapper>
            </div>
          </CenterContent>
          <RightSideBarContainer>
            <CommunityGroupSidebar
              isLogged={authContext.isAuthenticated}
              isVerified={authContext.isVerified && authContext.isAuthenticated}
              isOnboarded={
                authContext.isAuthenticated &&
                JSON.parse(localStorage.getItem('isOnboarded')) === 1
              }
              isSideBarLoaded={
                !authContext.isAuthenticated
                  ? true
                  : myGroupState.myGroupsLoaded
              }
              onJoinGroup={onJoinGroup}
            />
          </RightSideBarContainer>
        </CommunityWrapper>
      </CommunityFeedContext.Provider>
    </Layout>
  );
};

export default Community;

Community.defaultProps = {
  communitySidebarLeftData: [
    {
      title: 'Welcome to our community',
      content:
        'Read our latest posts and stay current of your favorite industries. AREA blogs covers games, film and VFX, design vizualization and VR while focusing on Autodesk 3ds Max, Maya, Flame, Maya LT and more.',
      actions: [
        {
          label: 'suggest a group',
          colorTheme: 'elements',
          externalUrl: 'https://help.area.autodesk.com/',
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
  ],
};
