import { styled } from '@root/stitches.config';

export const ImageWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  img: {
    borderRadius: '50%',
    width: '64px',
    height: '64px',
  },
});

export const EditorHeader = styled('div', {
  textAlign: 'left',
  mb: '$12',
  width: '90%',
  margin: '0 auto',
  '@bp2': {
    width: '80%',
  },
  h1: {
    fontSize: '$60',
    fontFamily: '$ArtifaktLegendExtraBold',
    lineHeight: '$2',
    margin: '$32 0',
    wordBreak: 'break-all'
  },
  '@bp2': {
    h1: {
      wordBreak: 'break-word'
    }
  }
});

export const CreateInfoWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const CreateEditorInfoWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  maxHeight: '64px',
  justifyContent: 'space-between',
  svg: {
    width: '14px',
    fill: '$blackToWhite',
    mr: '$4',
  },
});

export const EditorBuilder = styled('div', {
  position: 'relative',
  width: '100%',
});

export const EditorBuilderBlocks = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const CreateEditorWrapper = styled('div', {
  // minHeight: '70vh',
  height: '100%',
  padding: '$24 $24 0',
  textAlign: 'center',
  // backgroundColor: '$mainBackgroundAltColor',
  maxWidth: '1100px',
  mx: 'auto',
  '@bp2': {
    padding: '$24 $24 $72',
  },
});
