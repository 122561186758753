import {
  CMS_DELETE_USER_CONTENTS,
  CMS_USER_CONTENTS_ACTION,
  UPDATE_USERCONTENT,
} from '@src/apollo/mutation';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import ChangeDate from './ChangeDate';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_USERCONTENTS } from '@src/apollo/query';
import Modal from '@components/atoms/modal/Modal';
import Options from '@components/organisms/table/components/columnItem/Options';
import SEO from '@components/atoms/seo/Seo';
import SelectUserTable from '@components/molecules/selectUserTable/SelectUserTable';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import UserContent from '@components/organisms/table/components/columnItem/UserContent';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { navigate } from 'gatsby';
import useModal from '@components/atoms/modal/useModal';

const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(date, 'LLL, d y');
};

const CreateContent = context => {
  const [dateState, setDate] = useState();

  const onPublishedDateChange = (name, date) => {
    const updatedFilters = { ...createContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'published-date') {
        filter[name] = date;
      }
    });
    setCreateContentFilters({ filters: updatedFilters.filters });
  };

  const { isShowing, toggle } = useModal();
  const [authorOrDate, setAuthorOrDate] = useState({
    value: null,
    id: null,
    date: null,
  });

  const [
    currentSelectedCreateContent,
    setCurrentSelectedCreateContent,
  ] = useState({
    createIds: [],
  });

  const [createContent, setCreateContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    filterContentType: ['article', 'tutorial', 'artwork'],
    filterContentStatus: ['pending', 'publish'],
    input: {},
    loading: false,
    search: '',
    columns: [
      {
        header: 'Content',
        accessor: 'content',
      },
      {
        header: 'Type',
        accessor: 'type',
      },

      {
        header: 'Created Date',
        accessor: 'create_date',
      },
      {
        header: 'Views',
        accessor: 'views',
      },
      {
        header: 'Likes',
        accessor: 'likes',
      },
      {
        header: "Editor's Pick",
        accessor: 'editors_pick',
      },
      {
        header: 'Status',
        accessor: 'status',
      },

      {
        header: 'Author',
        accessor: 'author',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const onChangeTypeFilter = e => {
    const updatedFilters = { ...createContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'type') {
        filter.options.forEach(option => {
          if (option.value == e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });
    setCreateContentFilters({ filters: updatedFilters.filters });
  };

  const onChangeStatusFilter = e => {
    const updatedFilters = { ...createContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'status') {
        filter.options.forEach(option => {
          if (option.value == e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });
    setCreateContentFilters({ filters: updatedFilters.filters });
  };

  const onChangeArticleType = e => {
    const updatedFilters = { ...createContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'article-type') {
        filter.options.forEach(option => {
          if (option.value == e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });

    setCreateContentFilters({ filters: updatedFilters.filters });
  };

  const onChangeArticleSection = e => {
    const updatedFilters = { ...createContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'article-section') {
        filter.options.forEach(option => {
          if (option.value == e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });

    setCreateContentFilters({ filters: updatedFilters.filters });
  };

  const onChangeEditorsPick = e => {
    const updatedFilters = { ...createContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name === 'editors-pick') {
        filter.options.forEach(option => {
          if (option.value == 'editors_pick') {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });

    setCreateContentFilters({ filters: updatedFilters.filters });
  };

  const filters = [
    {
      title: 'Type',
      type: 'checkbox',
      name: 'type',
      onChange: onChangeTypeFilter,
      options: [
        {
          label: 'Tutorial',
          value: 'tutorial',
          isChecked: false,
        },
        {
          label: 'Tutorial Series',
          value: 'tutorial_series',
          isChecked: false,
        },
        {
          label: 'Article',
          value: 'article',
          isChecked: false,
        },
        {
          label: 'Artwork',
          value: 'artwork',
          isChecked: false,
        },
        {
          label: 'Assets',
          value: 'asset',
          isChecked: false,
        },
      ],
    },
    {
      title: <String id="community_admin_article_type" />,
      type: 'checkbox',
      name: 'article-type',
      onChange: onChangeArticleType,
      options: [
        {
          label: <String id="thumbnail_motm" />,
          value: 2,
          isChecked: false,
        },
        {
          label: <String id="thumbnail_moty" />,
          value: 3,
          isChecked: false,
        },
      ],
    },
    {
      title: <String id="admin_cms_editors_pick_text" />,
      type: 'checkbox',
      name: 'editors-pick',
      onChange: onChangeEditorsPick,
      options: [
        {
          label: <String id="admin_cms_editors_pick_text" />,
          value: 'editors_pick',
          isChecked: false,
        },
      ],
    },
    {
      title: 'Article section',
      type: 'checkbox',
      name: 'article-section',
      onChange: onChangeArticleSection,
      options: [
        {
          label: 'Inspire',
          value: 1,
          isChecked: false,
        },
      ],
    },
    {
      title: 'Status',
      type: 'checkbox',
      name: 'status',
      onChange: onChangeStatusFilter,
      options: [
        {
          label: 'Publish',
          value: 'publish',
          isChecked: false,
        },
        {
          label: 'Draft',
          value: 'draft',
          isChecked: false,
        },
        {
          label: 'Pending',
          value: 'pending',
          isChecked: false,
        },
      ],
    },
    {
      title: 'Created date',
      type: 'date',
      name: 'published-date',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onPublishedDateChange,
    },
  ];

  const onApplyFilters = () => {
    // All Filters
    const allSelectedFilters = {};

    // Checkbox - Types
    const selectedTypes = [];
    const selectedArticleType = [];
    const selectedArticleSection = [];
    createContentFilters.filters.forEach(filter => {
      if (filter.name == 'type') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            selectedTypes.push(option.value);
          }
        });
      }
      if (filter.name == 'article-type') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            selectedArticleType.push(parseInt(option.value, 10));
          }
        });
      }
      if (filter.name == 'article-section') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            selectedArticleType.push(parseInt(option.value, 10));
          }
        });
      }
    });
    if (selectedTypes.length > 0) {
      allSelectedFilters.selectedTypes = selectedTypes;
    }

    // Checkbox - Status
    const selectedStatus = [];
    createContentFilters.filters.forEach(filter => {
      if (filter.name == 'status') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            selectedStatus.push(option.value);
          }
        });
      }
    });
    if (selectedStatus.length > 0) {
      allSelectedFilters.selectedStatus = selectedStatus;
    }

    const allSelectedInput = {};

    let published_date_start = null;
    let published_date_end = null;
    createContentFilters.filters.forEach(filter => {
      if (filter.name == 'published-date') {
        published_date_start = filter.startDate;
        published_date_end = filter.endDate;
      }
      if (filter.name == 'editors-pick') {
        allSelectedInput.editors_pick = filter.options[0].isChecked;
      }
    });
    if (published_date_start) {
      allSelectedInput.published_date_start = published_date_start.toString();
    }
    if (published_date_end) {
      allSelectedInput.published_date_end = published_date_end.toString();
    }
    if (selectedArticleType.length >= 1) {
      allSelectedInput.article_type = selectedArticleType;
    }
    if (selectedArticleSection.length >= 1) {
      allSelectedInput.article_section = selectedArticleSection;
    }

    setCreateContent({
      ...createContent,
      filterContentType: allSelectedFilters.selectedTypes,
      filterContentStatus: allSelectedFilters.selectedStatus,
      filters: allSelectedInput,
    });
  };

  const [createContentFilters, setCreateContentFilters] = useState({
    filters,
  });

  const createContentDataVar = {
    limit: createContent.limitRow,
    page: createContent.page,
    content_type: createContent.filterContentType,
    status: createContent.filterContentStatus,
    search: createContent.search,
    filters: createContent.filters,
  };

  const createContentData = useQuery(GET_USERCONTENTS, {
    context: { clientName: 'cms' },
    variables: createContentDataVar,
    fetchPolicy: 'cache-and-network',
  });

  const [deleteUserContents, deleteUserContentsData] = useMutation(
    CMS_DELETE_USER_CONTENTS,
    {
      onCompleted: () => {
        createContentData.refetch();
      },
    }
  );

  const [updateUserContentAction, updateUserContentActionData] = useMutation(
    UPDATE_USERCONTENT
  );

  useEffect(() => {
    if (updateUserContentActionData.data) {
      createContentData.refetch();
    }
  }, [updateUserContentActionData.data]);

  const [userContentAction, userContentActionData] = useMutation(
    CMS_USER_CONTENTS_ACTION,
    {
      context: { clientName: 'cms' },
      onCompleted: () => {
        createContentData.refetch();
      },
    }
  );

  const [createContentList, setCreateContentList] = useState([]);

  const onDeleteUserContents = ids => {
    deleteUserContents({
      variables: {
        userContentIds: ids,
      },
    });
  };

  const onUserContentAction = (ids, action) => {
    userContentAction({
      variables: {
        userContentIds: ids,
        action,
      },
    });
  };

  const bulkActions = [
    {
      label: <String id="admin_create_content_delete_label" />,
      onClickAction: contents => {
        onDeleteUserContents(contents.map(c => c.id));
      },
    },
    {
      label: <String id="admin_create_content_publish_label" />,
      onClickAction: contents => {
        onUserContentAction(
          contents.map(c => c.id),
          'publish'
        );
      },
    },
    {
      label: <String id="admin_create_content_unpublish_label" />,
      onClickAction: contents => {
        onUserContentAction(
          contents.map(c => c.id),
          'unpublish'
        );
      },
    },
  ];

  const onChangeAuthor = id => {
    setAuthorOrDate({
      value: 'author',
      id: id,
      date: null,
    });
    toggle();
  };

  const onChangeDate = (id, date) => {
    setAuthorOrDate({
      value: 'date',
      id: id,
      date: date,
    });
  };

  useEffect(() => {
    if (authorOrDate.date) {
      setDate(authorOrDate.date);
      toggle();
    }
  }, [authorOrDate.date]);

  const closeModal = () => {
    setAuthorOrDate({
      value: null,
      id: null,
    });
    toggle();
  };

  const singleRowActions = args => [
    {
      label: 'Edit',
      onClickAction: item => {
        navigate(item.editUrl);
      },
    },
    {
      label: <String id="admin_create_content_delete_label" />,
      onClickAction: item => {
        onDeleteUserContents([item.id]);
      },
    },
    {
      label: (
        <String
          id={`admin_create_content_${
            args.status === 'publish' ? 'un' : ''
          }publish_label`}
        />
      ),
      onClickAction: item => {
        onUserContentAction(
          [item.id],
          `${
            args.status === 'publish' && args.status !== 'pending' ? 'un' : ''
          }publish`
        );
      },
    },
    {
      label: (
        <String
          id={`admin_create_content_${
            args.editors_pick ? 'unset' : 'set'
          }_editor_pick_label`}
        />
      ),
      onClickAction: item => {
        onUserContentAction(
          [item.id],
          `${args.editors_pick ? 'remove' : 'add'}_editors_pick`
        );
      },
    },
    {
      label: 'Change date',
      onClickAction: item => {
        onChangeDate(item.id, item.date);
      },
    },
    {
      label: 'Change author',
      onClickAction: item => {
        onChangeAuthor(item.id);
      },
    },
  ];

  const getAvatar = (profilePicture, firstname) => {
    return getAvatarImage(profilePicture);
  };

  const onNextPage = () => {
    const pageCountFirstNumber =
      (createContent.page - 1) * createContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + createContent.limitRow - 1;
    if (pageCountSecondNumber > createContentData.data.userContents.total) {
      pageCountSecondNumber = createContentData.data.userContents.total;
    }

    setCreateContent({
      ...createContent,
      page: createContent.page + 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (createContent.page - 2) * createContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + createContent.limitRow - 1;
    if (pageCountSecondNumber > createContentData.data.userContents.total) {
      pageCountSecondNumber = createContentData.data.userContents.total;
    }

    setCreateContent({
      ...createContent,
      page: createContent.page - 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setCreateContent({
      ...createContent,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(createContent.totalCount / rowCount),
    });
  };

  useLayoutEffect(() => {
    if (createContentData.data) {
      const rowDatas = [];
      createContentData.data.userContents.data.forEach(createContent => {
        const getImage = () => {
          if (
            createContent?.image?.sizes === undefined ||
            createContent?.image?.sizes.length === 0
          ) {
            const noSrcSet = {
              src: `${CDN_URL}${createContent?.image?.path}`,
              id: createContent?.image?.id,
            };
            return noSrcSet;
          } else {
            const imageUrl = {
              src: `${CDN_URL}${createContent?.image?.sizes[2]?.path}`,
              id: createContent?.image?.id,
            };
            return imageUrl;
          }
        };
        let name = '';

        if (
          createContent?.creator?.first_name !== null &&
          createContent?.creator?.first_name !== ''
        ) {
          name =
            createContent?.creator?.first_name +
            ' ' +
            createContent?.creator?.last_name;
        }

        const singleRow = {
          content: (
            <UserContent
              name={createContent.title}
              image={getImage()}
              url={`/m/${createContent.creator.username}/${
                createContent.userContentType
              }${
                createContent.userContentType !== 'tutorial_series' ? 's' : ''
              }/${createContent.slug}${
                createContent.status === 'draft' ? '-draft' : ''
              }/${createContent.status === 'draft' ? 'draft' : ''}`}
            />
          ),
          author: (
            <UserInfos
              name={name}
              job={createContent.creator.title}
              url={createContent.creator.userProfile.profile_picture}
              link={`/m/${createContent.creator.username}`}
            />
          ),
          create_date: (
            <DateIconLink
              date={convertUTCToLocalDate(
                createContent.publishedAt
                  ? createContent.publishedAt * 1000
                  : createContent.createdAt * 1000
              )}
            />
          ),
          views: createContent.views,
          type: (
            <Text css={{ textTransform: 'capitalize' }}>
              {createContent.userContentType}
            </Text>
          ),
          likes: (
            <Text css={{ textTransform: 'capitalize' }}>
              {createContent.usersLikedCount}
            </Text>
          ),
          status: (
            <Text css={{ textTransform: 'capitalize' }}>
              {createContent.status}
            </Text>
          ),
          editors_pick: (
            <Text css={{ textTransform: 'capitalize' }}>
              {createContent.editors_pick ? 'Yes' : 'No'}
            </Text>
          ),
          options: (
            <Options
              actions={singleRowActions(createContent)}
              id={createContent.id}
              editUrl={`/m/${createContent.creator.username}/${
                createContent.userContentType
              }${
                createContent.userContentType !== 'tutorial_series' ? 's' : ''
              }/${createContent.slug}/${createContent.status === 'draft' ? 'draft/' : ''}edit`}
              date={
                createContent.publishedAt
                  ? createContent.publishedAt * 1000
                  : createContent.createdAt * 1000
              }
            />
          ),
          id: createContent.id,
        };
        rowDatas.push(singleRow);
      });

      const pageCountFirstNumber =
        (createContent.page - 1) * createContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + createContent.limitRow - 1;
      if (pageCountSecondNumber > createContentData.data.userContents.total) {
        pageCountSecondNumber = createContentData.data.userContents.total;
      }

      setCreateContent({
        ...createContent,
        isLoaded: true,
        totalCount: createContentData.data.userContents.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(
          createContentData.data.userContents.total / createContent.limitRow
        ),
        loading: false,
      });

      setCreateContentList(rowDatas);
    }
  }, [createContentData.data]);

  const onSearchSubmit = searchInput => {
    setCreateContent({
      ...createContent,
      search: searchInput !== '' ? searchInput : '',
      page: 1,
    });
    createContentData.refetch();
  };

  const onSaveNewInfos = el => {
    setCreateContent({
      ...createContent,
      loading: true,
    });
    if (authorOrDate.value === 'author') {
      updateUserContentAction({
        variables: {
          id: authorOrDate.id,
          admin_input: { author: el.id },
        },
      });
      closeModal();
    }
  };
  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <AdvanceFilters
        title="AREA Create Content"
        onSubmitFilters={onApplyFilters}
        filters={createContentFilters.filters}
        onSearchSubmit={onSearchSubmit}
      />
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={createContent.totalCount}
        currentCount={createContent.currentCount}
        loading={
          createContentData.loading ||
          deleteUserContentsData.loading ||
          userContentActionData.loading ||
          updateUserContentActionData.loading ||
          createContent.loading
        }
        rowData={createContentList}
        columns={createContent.columns}
        maxPage={createContent.maxPage}
        currentPage={createContent.page}
        hasOptions
        bulkActions={bulkActions}
      />
      <Modal
        isShowing={isShowing}
        hide={closeModal}
        isSelectUserContent={authorOrDate.value === 'author'}
        isContainedModal
      >
        {authorOrDate.value === 'author' ? (
          <SelectUserTable onSelectUser={onSaveNewInfos} isSelectNewAuthor />
        ) : (
          <ChangeDate
            date={dateState}
            closeModal={closeModal}
            updateUserContentAction={updateUserContentAction}
            id={authorOrDate.id}
            onSaveNewInfos={onSaveNewInfos}
          />
        )}
      </Modal>
    </AdminLayout>
  );
};

export default CreateContent;
