import {
  TablePaginationCount,
  TablePaginationCountLabel,
  TablePaginationWrapper,
} from './TablePagination.styled';

import Button from '@components/atoms/button/Button';
import React from 'react';
import String from '@components/atoms/string/String';

const TablePagination = props => {
  return (
    <TablePaginationWrapper>
      <TablePaginationCount>
        <TablePaginationCountLabel>
          {props.currentCount} <String id="community_admin_pagination_of" />{' '}
          {props.totalCount}
        </TablePaginationCountLabel>
        <Button
          type="circle"
          colorTheme="gray"
          svgIcon="arrow-left-short"
          actionType="onclick"
          onClick={props.onPrevPage}
          circleSize="small"
          css={{ marginRight: '$8' }}
          isDisabled={props.currentPage == 1 || props.loading}
        ></Button>
        <Button
          type="circle"
          colorTheme="gray"
          svgIcon="arrow-right-short"
          actionType="onclick"
          onClick={props.onNextPage}
          circleSize="small"
          isDisabled={props.currentPage >= props.maxPage || props.loading}
        ></Button>
      </TablePaginationCount>
    </TablePaginationWrapper>
  );
};

export default TablePagination;
