import { styled } from '@root/stitches.config';

export const GalleryWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$4'
});

export const HasMoreWrapper = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
})

export const ImageWrapper = styled('div', {
  position: 'relative',
  cursor: 'zoom-in',
  objectFit: 'cover',
  '> div:first-child': {
    height: '100%'
  },
  variants: {
    isFirst: {
      true: {
        flexBasis: '100%',
      },
      false: {
        flexBasis: 'calc(50% - $2)',
        maxHeight: '240px'
      }
    },
    hasTwoImages: {
      true: {
        flexBasis: 'calc(50% - $2)',
        maxHeight: 'unset !important'
      }
    },
    isAboveMax: {
      true: {
        display: 'none'
      }
    },
    hasMore: {
      true: {
        '&:after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          background: 'hsla(0, 0%, 0%, .4)',
          transition: 'background .2s ease-out',
          top: '0',
          left: '0',
          pointerEvents: 'none'
        },
        '&:hover': {
          '&:after': {
            background: 'hsla(0, 0%, 0%, .2)',
          }
        }
      }
    }
  }
});