import { styled } from '@root/stitches.config';

import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';

export const WrapperPage = styled('div', {
  width: '100%',
  maxWidth: '1440px',
  minHeight: '100vh',
  margin: '0 auto',
  justifyContent: 'center',
  padding: '$16',

  '@bp1': {
    padding: '$48 $64 $84 $64',
  },
});

export const HeadingStyled = styled(Heading, {
  fontFamily: '$ArtifaktLegendBold',
  lineHeight: '96px',
  fontSize: '$48 !important',

  '@bp1': {
    fontSize: '$92 !important',
    margin: '0 0 $16 0',
  },
});

export const PageIntro = styled(Text, {
  margin: '0 0 $16 0',

  '@bp1': {
    maxWidth: '50%',
    margin: '0 0 $32 0',
  },
});

export const SectionFavorites = styled('div', {
  marginTop: '$48',
});

export const InnerFavorites = styled('div', {
  marginTop: '$24',
});

export const ThumbnailWrapper = styled('div', {
  position: 'relative',
  marginTop: '$16',
  marginBottom: '$32',
  display: 'grid',
  gap: '32px',
  gridTemplateColumns: 'repeat(1, 1fr)',
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    margin: '$64 0',
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

export const WrapperPosts = styled('div', {
  width: '100%',
  margin: '0 auto',
  '@bp2': {
    maxWidth: '640px'
  }
});

export const WrapperPagination = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});
