import { styled } from '@root/stitches.config';

import { Text } from '@components/atoms/text/Text';

export const NoResultWrapper = styled('div', {
  width: '100%',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  gridColumn: '1 / 1',
  bp0: {
    gridColumn: '1 / 2',
  },
  '@bp3': {
    gridColumn: '1 / 4',
  },
  '@bp4': {
    gridColumn: '1 / 5',
  },
});

export const TextStyled = styled(Text, {
  fontFamily: '$ArtifaktElementRegular',
  fontSize: '$24',
  color: '$mainTextColor',
});
