import { Heading } from '@components/atoms/heading/Heading';
import { styled } from '@root/stitches.config';

export const StyledHeading4 = styled(Heading, {
  marginBottom: '$16',
  fontFamily: '$ArtifaktLegendBold',
  '@bp3': {
    fontSize: '$32',
    marginBottom: '$24',
  },
});
