import { styled } from '@root/stitches.config';

export const TablePaginationWrapper = styled('div', {});

export const TablePaginationCountLabel = styled('span', {
  fontFamily: '$ArtifaktElementRegular',
  color: '$mediumGrey',
  fontSize: '$14',
  marginRight: '$16',
});

export const TablePaginationCount = styled('div', {
  display: 'flex',
  alignItems: 'center',
});
