import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const WrapperPage = styled('div', {
  minHeight: '70vh',
  justifyContent: 'center',
  margin: '0 auto',
  overflowX: 'hidden',
  padding: '$32 $16 $64 $16',
  '@bp2': {
    padding: '$48 $54 $84 $54',
  },
  '@bp4': {
    paddingTop: '$48',
    paddingBottom: '$84',
  },
  variants: {
    isNotMainWrapper: {
      true: {
        minHeight: 'auto',
      },
    },
  },
});

export const WrapperGroupSlider = styled('div', {
  justifyContent: 'center',
  margin: '40px auto 16px auto',
  '@bp1': {
    margin: '40px auto 96px auto',
  },
});

export const WrapperHighLight = styled('div', {
  display: 'grid',
  gap: '$16',
  margin: '0 auto $16 auto',
  gridTemplateColumns: 'repeat(1, 1fr)',
  '@bp0': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp1': {
    margin: '0 auto 40px auto',
    gap: '32px',
  },
  '@bp2': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

export const ButtonSpacer = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Inverter = styled('div', {
  variants: {
    isLogged: {
      true: {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
  },
});

export const WrapperListingHeader = styled('div', {
  display: 'none',
  '@bp1': {
    display: 'flex',
  },
  width: '100%',
  margin: '0 auto 0 auto',
  alignItems: 'flex-end',
  justifyContent: 'space-between',

  '@bp4': {
    maxWidth: '$container',
  },
});

export const WrapperTextListingHeader = styled('div', {
  width: '100%',
  marginBottom: '16px',
});

export const StyledButton = styled(Button, {
  margin: '0 0 $64 0',
  whiteSpace: 'nowrap',
});
