import {
  CommunityPostBoxImagesWrapper,
} from './CommunityPostBoxImages.styled';
import React, { useEffect, useState } from 'react';
import LayoutImagesPost from '@components/molecules/layoutImagesPost/LayoutImagesPost';

const CommunityPostBoxImages = React.memo(props => {
  let imagesCounter = props.postImages.length;
  let postContent = props.postContent;
  const [imageShown, setImageShown] = useState(false);

  useEffect(() => {
    imagesCounter = props.postImages.length;
    postContent = props.postContent;

    setImageShown(false);
    getLayout();
  }, [props.postImages]);

  const getLayout = () => {
    if(imagesCounter >= 1){
      setImageShown(true);
    }
  };

  return (
    <CommunityPostBoxImagesWrapper>
      {imageShown && (
        <LayoutImagesPost images={props.postImages} maxImages={3} />
      )}
    </CommunityPostBoxImagesWrapper>
  );
});

CommunityPostBoxImages.propTypes = {};

export default CommunityPostBoxImages;
