import { styled, keyframes } from '@root/stitches.config';

const autoDismissAnimationUp = keyframes({
  '0%': {
    opacity: '0',
    transform: 'translateY(200%)',
  },
  '5%': {
    transform: 'translateY(0%)',
  },
  '10%': {
    opacity: '1',
  },
  '95%': {
    opacity: '1',
    transform: 'translateY(0%)',
  },
  '98%': {
    opacity: '.3',
    transform: 'translateY(150%)',
  },
  '100%': {
    opacity: '0',
    transform: 'translateY(200%)',
  },
});

const autoDismissAnimationDown = keyframes({
  '0%': {
    opacity: '0',
    transform: 'translateY(-200%)',
  },
  '5%': {
    transform: 'translateY(0%)',
  },
  '10%': {
    opacity: '1',
  },
  '95%': {
    opacity: '1',
    transform: 'translateY(0%)',
  },
  '98%': {
    opacity: '.3',
    transform: 'translateY(-150%)',
  },
  '100%': {
    opacity: '0',
    transform: 'translateY(-200%)',
  },
});

const animationUp = keyframes({
  '0%': {
    opacity: '0',
    transform: 'translateY(200%)',
  },
  '15%': {
    transform: 'translateY(0%)',
  },
  '25%': {
    opacity: '1',
  },
  '100%': {
    opacity: '1',
    transform: 'translateY(0%)',
  },
});

const animationDown = keyframes({
  '0%': {
    opacity: '1',
    transform: 'translateY(0%)',
  },
  '10%': {
    opacity: '.3',
    transform: 'translateY(-150%)',
  },
  '50%': {
    opacity: '0',
    transform: 'translateY(-200%)',
  },
  '100%': {
    opacity: '0',
  },
});

export const NotificationWrapper = styled('div', {
  boxShadow: '$1',
  width: '100%',
  pointerEvents: 'all',
  transform: 'translateY(200%)',
  padding: '$16',
  transition: 'all 1s linear',
  '&:not(:first-of-type)': {
    marginTop: '$12',
  },
  '@bp2': {
    br: 16,
    maxWidth: '344px',
    minWidth: '344px',
  },
  '.icon-notification': {
    width: '$24',
    height: '$24',
    mr: '$8',
  },
  variants: {
    type: {
      error: {
        background: '$error',
        color: 'white',
        svg: {
          fill: 'white !important',
        },
      },
      success: {
        background: '$success',
        color: 'white',
        svg: {
          fill: 'white !important',
        },
      },
      info: {
        background: 'white',
        color: 'black',
      },
    },
    autoDismiss: {
      true: {
        animation: `${autoDismissAnimationDown} ease-out forwards`,
        '@bp2': {
          animation: `${autoDismissAnimationUp} ease-out forwards`,
        },
      },
      false: {
        animation: `${animationUp} 1.5s ease-out forwards`,
        '@bp2': {
          animation: `${animationDown} 1.5s ease-out forwards`,
        },
      },
    },
    isDismissed: {
      true: {
        animation: `${animationDown} 1.5s ease-out forwards`,
        '@bp2': {
          animation: `${animationUp} 1.5s ease-out forwards`,
        },
      },
    },
  },
});

export const NotificationHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: '$8',
  'div:first-of-type': {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    minWidth: '$16',
    minHeight: '$16',
    width: '$16',
    height: '$16',
    alignSelf: 'flex-start',
    '&:hover': {
      background: 'transparent',
    },
    svg: {
      width: '$12',
      height: '$12',
    },
  },
});

export const NotificationActionsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '$8',
  marginTop: '$16',
  position: 'relative',
  'button, a': {
    padding: '0',
    minHeight: 'auto',
    textTransform: 'capitalize',
    'span + span': {
      margin: '0',
    },
    svg: {
      height: '10px',
      width: 'auto',
      margin: '$4',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  '&::before': {
    content: '',
    width: '100%',
    height: '1px',
    display: 'block',
    position: 'absolute',
    left: '0',
    top: '0',
    opacity: '.1',
  },
  variants: {
    type: {
      error: {
        'button, a': {
          color: 'white',
        },
        '&::before': {
          background: 'white',
        },
      },
      success: {
        'button, a': {
          color: 'white',
        },
        '&::before': {
          background: 'white',
        },
      },
      info: {
        '&::before': {
          background: 'black',
        },
      },
    },
  },
});
