import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import Button from '@components/atoms/button/Button';
import CreateDraftList from '@components/molecules/createDraftList/CreateDraftList';
import { CreateDraftWrapper } from './CreateDraft.styled';
import { DELETE_USERCONTENT } from '@src/apollo/mutation';
import { Heading } from '@components/atoms/heading/Heading';
import Layout from '@components/organisms/layout/Layout';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import Modal from '@components/atoms/modal/Modal';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import useModal from '@components/atoms/modal/useModal';
import { GET_CREATE_DRAFTS } from '@src/apollo/query';
import { formatDate } from '@src/utils/formatDate';
import { Text } from '@components/atoms/text/Text';
import Options from '@components/organisms/table/components/columnItem/Options';
import { navigate } from 'gatsby';

const CreateDraft = props => {
  const { isShowing, toggle } = useModal();
  const [draftToDelete, setDraftToDelete] = useState({
    id: null,
    cb: () => {},
  });

  const [selectUserContent, setSelectUserContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    isSelectUserContent: true,
    columns: [
      {
        header: <String id="select_user_content_content" />,
        accessor: 'title',
      },
      {
        header: <String id="community_admin_content_type" />,
        accessor: 'type',
      },
      {
        header: <String id="select_user_content_creation_date" />,
        accessor: 'publish_date',
      },
      {
        header: '',
        accessor: 'actions',
      },
    ],
    rowData: [],
  });

  const [userContent, setUserContent] = useState({
    userContentList: [],
  });

  const [deleteUserContent, dataDeleteUserContent] = useMutation(
    DELETE_USERCONTENT,
    {
      onCompleted: () => draftToDelete.cb(),
    }
  );

  const { loading, error, data, refetch } = useQuery(GET_CREATE_DRAFTS, {
    variables: {
      status: 'draft',
      limit: selectUserContent.limitRow,
      page: selectUserContent.page,
    },
    fetchPolicy: 'no-cache',
  });

  const onDeleteDraft = () => {
    toggle();
    deleteUserContent({
      variables: {
        ids: [draftToDelete.id],
      },
    });

    setDraftToDelete({
      ...draftToDelete,
      id: null,
    });
  };

  const onDeleteDraftConfirmation = (id, cb) => {
    setDraftToDelete({ id, cb });
    toggle();
  };

  const onNextPage = () => {
    if (selectUserContent.page < selectUserContent.maxPage) {
      const pageCountFirstNumber =
        selectUserContent.page * selectUserContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + selectUserContent.limitRow - 1;

      if (pageCountSecondNumber > selectUserContent.totalCount) {
        pageCountSecondNumber = selectUserContent.totalCount;
      }

      setSelectUserContent({
        ...selectUserContent,
        page: selectUserContent.page + 1,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        loading: true,
        isLoaded: false,
      });
    }
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (selectUserContent.page - 2) * selectUserContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + selectUserContent.limitRow - 1;
    if (pageCountSecondNumber > selectUserContent.totalCount) {
      pageCountSecondNumber = selectUserContent.totalCount;
    }
    setSelectUserContent({
      ...selectUserContent,
      page: selectUserContent.page - 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
      isLoaded: false,
    });
  };

  const onChangeLimitRow = rowCount => {
    setSelectUserContent({
      ...selectUserContent,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(selectUserContent.totalCount / rowCount),
      isLoaded: false,
      currentCount: `1 - ${
        selectUserContent.limitRow < data.userContents.total
          ? selectUserContent.limitRow
          : data.userContents.total
      }`,
    });
    window.scrollTo(0, 0);
  };

  const singleRowActions = [
    {
      label: 'Edit',
      onClickAction: item => {
        navigate(`${item.editUrl}/edit`);
      },
    },
    {
      label: 'Preview',
      onClickAction: item => {
        navigate(item.editUrl);
      },
    },
    {
      label: 'Delete',
      onClickAction: item => {
        props.onDeleteDraft(item.id, refetch);
      },
    },
  ];

  useEffect(() => {
    if (data) {
      const pageCountFirstNumber =
        (selectUserContent.page - 1) * selectUserContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + selectUserContent.limitRow - 1;
      if (pageCountSecondNumber > data.userContents.total) {
        pageCountSecondNumber = data.userContents.total;
      }

      setSelectUserContent({
        ...selectUserContent,
        isLoaded: true,
        totalCount: data.userContents.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(
          data.userContents.total / selectUserContent.limitRow
        ),
      });

      const rowDatas = [];
      data.userContents.data.forEach((element, index) => {
        const elementContentType = () => {
          if (
            element.userContentType === 'article' &&
            element.article_type?.id > 1
          ) {
            if (element.article_type.id === 2) {
              return 'motm';
            } else if (element.article_type.id === 3) {
              return 'moty';
            }
          } else {
            return element.userContentType;
          }
        };

        const singleRow = {
          title: <Text>{element.title}</Text>,
          index,
          type: <Text>{elementContentType()}</Text>,
          publish_date: formatDate(element.publishedAt || element.createdAt),
          actions: (
            <Options
              alignLeft
              actions={singleRowActions}
              id={element.id}
              editUrl={`/m/${element.creator.username}/${
                element.userContentType
              }${element.userContentType !== 'tutorial_series' ? 's' : ''}/${
                element.slug
              }/draft`}
            />
          ),
        };
        rowDatas.push(singleRow);
      });

      setUserContent({
        userContentList: rowDatas,
      });
    }
  }, [data]);

  useEffect(() => {
    const pageCountFirstNumber =
      (selectUserContent.page - 1) * selectUserContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + selectUserContent.limitRow - 1;
    if (pageCountSecondNumber > selectUserContent.totalCount) {
      pageCountSecondNumber = selectUserContent.totalCount;
    }
    setSelectUserContent({
      ...selectUserContent,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
    });
  }, [selectUserContent.limitRow]);

  return (
    <Layout location={props.location} showBreadcrumb>
      <SEO
        title="Create draft"
        slug="create-draft"
        seomatic={props?.pageContext?.entry?.seomatic}
      />
      <CreateDraftWrapper>
        <ListingHeader
          title="create_draft_page_title"
          intro="create_draft_page_intro"
          isCMSDynamic
        />
        <CreateDraftList
          onDeleteDraft={onDeleteDraftConfirmation}
          draftsList={data}
          loading={loading}
          selectUserContent={selectUserContent}
          userContent={userContent}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onChangeLimitRow={onChangeLimitRow}
          onDeleteDraftConfirmation={onDeleteDraftConfirmation}
        />
      </CreateDraftWrapper>
      <Modal isShowing={isShowing} hide={toggle}>
        <Heading level="h3" css={{ marginBottom: '$32' }}>
          <String id="create_draft_delete_modal_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={onDeleteDraft}
          label={<String id="create_draft_delete_modal_delete_button_label" />}
          type="normal"
          css={{ marginRight: '$32' }}
        />
        <Button
          actionType="onclick"
          colorTheme="gray"
          onClick={toggle}
          label={<String id="create_draft_delete_modal_cancel_button_label" />}
          type="normal"
        />
      </Modal>
    </Layout>
  );
};
export default CreateDraft;
