import { CREATE_TOOL, EDIT_TOOL } from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import Button from '@components/atoms/button/Button';
import ContentDataEdit from '@components/molecules/contentDataEdit/ContentDataEdit';
import EditButton from '@components/organisms/table/components/columnItem/EditButton';
import { GET_TOOLS } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import ImageCircle from '@components/organisms/table/components/columnItem/ImageCircle';
import Modal from '@components/atoms/modal/Modal';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import { ToolsHeader } from './ContentTools.styled';
import useModal from '@components/atoms/modal/useModal';

const ContentTools = context => {
  const { isShowing, toggle } = useModal();
  const { isShowing: isShowingEdit, toggle: toggleEdit } = useModal();

  const [toolsContent, setToolsContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    columns: [
      {
        header: 'Tool icon',
        accessor: 'toolIcon',
      },
      {
        header: 'Tool name',
        accessor: 'toolName',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const [toolsList, setToolsList] = useState([]);

  const [currentTool, setCurrentTool] = useState({
    id: '',
    isEdit: false,
    fields: [
      {
        type: 'text',
        label: <String id="admin_content_tools_input_text_label" />,
        value: '',
        name: 'toolName',
      },
      {
        type: 'image',
        label: <String id="admin_content_tools_input_image_label" />,
        value: '',
        name: 'toolIcon',
      },
    ],
  });

  const toolsData = useQuery(GET_TOOLS, {
    fetchPolicy: 'no-cache',
  });

  const [editTool, editToolData] = useMutation(EDIT_TOOL, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      toolsData.refetch();
    },
  });

  const [createTools, createToolsData] = useMutation(CREATE_TOOL, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      toolsData.refetch();
    },
  });

  const onAddNewTool = () => {
    setCurrentTool({
      id: '',
      isEdit: false,
      fields: [
        {
          type: 'text',
          label: <String id="admin_content_tools_input_text_label" />,
          value: '',
          name: 'toolName',
        },
        {
          type: 'image',
          label: <String id="admin_content_tools_input_image_label" />,
          value: '',
          name: 'toolIcon',
        },
      ],
    });
    toggleEdit();
  };

  const onEdit = id => {
    const updatedCurrentTool = { ...currentTool };

    toolsData.data.tools.forEach(tool => {
      if (tool.id == id) {
        updatedCurrentTool.fields.forEach(field => {
          if (field.type == 'image') {
            let iconUrl = '';
            const url = tool.icon.path;
            const trimSlashes = str =>
              str
                .split('/')
                .filter(v => v !== '')
                .join('/');
            const newUrl = trimSlashes(url);

            if (newUrl.includes('images')) {
              iconUrl = `/${newUrl}`;
            } else {
              iconUrl = `${CDN_URL}${newUrl}`;
            }

            field.value = iconUrl;
          } else {
            field.value = tool.title;
          }
        });
      }
    });

    setCurrentTool({
      id,
      isEdit: true,
      fields: updatedCurrentTool.fields,
    });

    toggleEdit();
  };

  const onSaveAdd = (id, fieldsData) => {
    createTools({
      variables: {
        toolInput: {
          title: fieldsData[0].value,
          icon: fieldsData[1].value.file,
          description: 'description',
          product_key: Date.now(),
        },
      },
    });
    toggleEdit();
  };

  const onSaveEdit = (id, fieldsData) => {
    editTool({
      variables: {
        toolId: id,
        toolInput: {
          title: fieldsData[0].value,
          icon: fieldsData[1].value.file,
        },
      },
    });
    toggleEdit();
  };

  useEffect(() => {
    if (toolsData.data) {
      const rowDatas = [];

      toolsData.data.tools.forEach(tool => {
        let iconUrl = '';
        const url = tool.icon.path;
        const trimSlashes = str =>
          str
            .split('/')
            .filter(v => v !== '')
            .join('/');
        const newUrl = trimSlashes(url);

        if (newUrl.includes('images')) {
          iconUrl = `/${newUrl}`;
        } else {
          iconUrl = `${CDN_URL}${newUrl}`;
        }

        const singleRow = {
          toolIcon: <ImageCircle src={iconUrl} />,
          toolName: <Text>{tool.title}</Text>,
          options: <EditButton action={onEdit} id={tool.id} />,
          id: tool.id,
        };
        rowDatas.push(singleRow);
      });

      setToolsContent({
        ...toolsContent,
        isLoaded: true,
        currentCount: `1-${toolsData.data.tools.length}`,
        totalCount: toolsData.data.tools.length,
      });

      setToolsList(rowDatas);
    }
  }, [toolsData.data]);

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <ToolsHeader>
        <Heading level="h3" css={{ lineHeight: '1em' }}>
          <String id="admin_content_tools_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={onAddNewTool}
          label={<String id="admin_content_tools_add_tool_label" />}
          type="normal"
          css={{ marginLeft: '$16' }}
        />
      </ToolsHeader>

      <Table
        nextPage={() => {}}
        prevPage={() => {}}
        changeRowPerPage={9999}
        totalCount={toolsContent.totalCount}
        currentCount={toolsContent.currentCount}
        loading={
          toolsData.loading || editToolData.loading || createToolsData.loading
        }
        rowData={toolsList}
        columns={toolsContent.columns}
        maxPage={toolsContent.maxPage}
        currentPage={toolsContent.page}
        hasOptions
        isNotMultiSelect
        hasNoPagination
        isFirstColImage
      />
      <br />

      <Modal isShowing={isShowingEdit}>
        {currentTool.isEdit ? (
          <Heading level="h3" css={{ marginBottom: '$16' }}>
            <String id="admin_content_tools_modal_edit_title" />
          </Heading>
        ) : (
          <Heading level="h3" css={{ marginBottom: '$16' }}>
            <String id="admin_content_tools_modal_add_title" />
          </Heading>
        )}

        <ContentDataEdit
          {...currentTool}
          toggleEdit={toggleEdit}
          onSave={currentTool.isEdit ? onSaveEdit : onSaveAdd}
        />
      </Modal>
    </AdminLayout>
  );
};

export default ContentTools;
