import {
  ButtonHeaderSection,
  ButtonStyled,
  HeaderSectionWrapper,
  HeadingStyled,
  HighlightWrapper,
  StyledThumbnailSlider,
} from './Learn.styled';

import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';

import {
  GET_ASSETS,
  GET_MIXED_CONTENT,
  GET_TUTORIALS,
} from '@src/apollo/query';
import { GET_PAGE_DATA_LEARN } from '@src/apollo/queryPages';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@src/context/AuthContext';
import CourseThumbnailList from '@components/molecules/courseThumbnail/courseThumbnailList/CourseThumbnailList';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import Layout from '@components/organisms/layout/Layout';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import PageHero from '@components/organisms/pageHero/PageHero';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import UrlsContext from '@context/UrlsContext';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import learnDatas from './Learn.datas';
import { useIsMobile } from '@src/utils/useIsMobile';
import { validURL } from '@src/utils/validURL';

const LearnPage = context => {
  const [heroData, setHeroData] = useState({
    heading: {
      text: '',
      level: 'large',
    },
    subHeading: {
      text: '',
    },
    artwork: {},
    isDataSet: false,
  });

  const isMobile = useIsMobile();
  const authContext = useContext(AuthContext);
  const UrlContext = useContext(UrlsContext);
  const [excludedIds, setExcludedIds] = useState([]);

  const [getMixedContentBlock, mixedContentBlock] = useLazyQuery(
    GET_MIXED_CONTENT,
    {
      variables: {
        limit: 4,
        types: ['tutorial', 'tutorial_series', 'asset'],
        filters: {
          exclude_ids: excludedIds,
        },
      },
      context: { clientName: 'public' },
    }
  );

  const [getTutorialsBlock, tutorialsBlock] = useLazyQuery(GET_TUTORIALS, {
    variables: {
      limit: 8,
      excludedIds,
      contentType: 'tutorial',
    },
    context: { clientName: 'public' },
  });

  const [getAssetsBlock, assetsBlock] = useLazyQuery(GET_ASSETS, {
    variables: {
      limit: 8,
      excludedIds,
    },
    context: { clientName: 'public' },
  });

  const { data: pageData, loading: pageDataLoading } = useQuery(
    GET_PAGE_DATA_LEARN,
    {
      variables: {
        slug: 'learn',
      },
      context: { clientName: 'public' },
    }
  );

  useEffect(() => {
    if (pageData?.page) {
      const heroBg = getImageSrc(
        pageData.page.background_image,
        'page_hero',
        pageData?.page?.header_image_credit
      );

      setExcludedIds(
        [
          pageData?.page?.userContent_feature?.id,
          pageData?.page?.userContent?.id,
        ].filter(el => el !== undefined)
      );
      setHeroData({
        heading: {
          text: pageData?.page.title,
          level: 'medium',
        },
        subHeading: {
          text: pageData?.page.introduction,
        },
        artwork: {
          artistCredits: {
            name: pageData?.page?.header_image_credit,
            url: pageData?.page?.header_image_credit_link,
          },
          image: {
            ...heroBg,
            alt: `Artwork by ${
              pageData?.page?.header_image_credit
                ? pageData?.page?.header_image_credit
                : 'Autodesk'
            }`,
          },
        },
        isDataSet: true,
      });
    }
  }, [pageData]);

  useEffect(() => {
    if (excludedIds) {
      getMixedContentBlock();
      getTutorialsBlock();
      getAssetsBlock();
    }
  }, [excludedIds]);

  return (
    <Layout location={context.location}>
      <SEO
        slug="learn"
        title={'Learn'}
        seomatic={context?.pageContext?.entry?.seomatic}
      />

      {!heroData.isDataSet ? (
        <LoaderSkeleton height="560px" width="100%" />
      ) : (
        <PageHero heroData={heroData} />
      )}
      <LandingPageWrapper>
        <SectionWrapper>
          <HighlightWrapper>
            {pageData?.page?.userContent_feature && !pageDataLoading && (
              <Thumbnail
                {...dataToThumbnailViewModel(
                  pageData?.page?.userContent_feature,
                  false,
                  authContext.isAuthenticated
                )}
                isFeatured={!isMobile}
                displayType
              />
            )}
            {pageDataLoading && (
              <LoaderSkeleton
                height="870px"
                width="100%"
                isFeatured={!isMobile}
                isRadius
              />
            )}
            {mixedContentBlock.data?.userContents?.data.length >= 1 ? (
              mixedContentBlock.data?.userContents?.data.map((item, i) => {
                return (
                  <Thumbnail
                    key={item.id}
                    displayType
                    {...dataToThumbnailViewModel(
                      item,
                      false,
                      authContext.isAuthenticated
                    )}
                  />
                );
              })
            ) : (
              <>
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
              </>
            )}
          </HighlightWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <HeaderSectionWrapper>
            <HeadingStyled as="h3" weight="legendExtraBold">
              <String id="learn_freshen_skills" />
            </HeadingStyled>
            <ButtonHeaderSection
              label={<String id="general_see_all" />}
              type="normal"
              colorTheme="normal"
              actionType="internalLink"
              internalUrl={GetUrl('courses', UrlContext)}
            />
          </HeaderSectionWrapper>

          <CourseThumbnailList listData={learnDatas.courseListDatas} />

          <ButtonStyled
            type="normal"
            colorTheme="gray"
            actionType="onclick"
            onClick={() => console.log('OK')}
            label={<String id="general_see_all" />}
          />
        </SectionWrapper>
        <SectionWrapper>
          {tutorialsBlock.data?.userContents?.data.length >= 1 ? (
            <StyledThumbnailSlider
              title={<String id="page_learn_section_tutorials" />}
              isInternalLink
              type="tutorial"
              thumbnailList={tutorialsBlock.data?.userContents?.data.map(el =>
                dataToThumbnailViewModel(el, false, authContext.isAuthenticated)
              )}
              btnUrl={GetUrl('learnTutorials', UrlContext)}
            />
          ) : (
            <LoaderSkeleton height="200px" width="100%" />
          )}
        </SectionWrapper>
        <SectionWrapper>
          {assetsBlock.data?.userContents?.data.length >= 1 ? (
            <StyledThumbnailSlider
              title={<String id="page_learn_section_assets" />}
              isInternalLink
              type="asset"
              thumbnailList={assetsBlock.data?.userContents?.data.map(el =>
                dataToThumbnailViewModel(el, false, authContext.isAuthenticated)
              )}
              btnUrl={GetUrl('learnAssets', UrlContext)}
            />
          ) : (
            <LoaderSkeleton height="200px" width="100%" />
          )}
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};

export default LearnPage;
