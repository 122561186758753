import { styled } from '@root/stitches.config';

export const BtnContainer = styled('div', {
  marginTop: '$32'
});

export const DatePickerWrapper = styled('div', {
  // marginBottom: '$24',
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  gridColumnGap: '$16',
  width: '260px',
  alignItems: 'end',
  // alignItems: 'center',
});
