import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const CommunityReactionsBarWrapper = styled('div', {
  position: 'relative',
});

export const CommunityReactionsBarContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '.btn-counter': {
    alignSelf: 'center',
    position: 'absolute',
    justifySelf: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  '.post-comment': {
    '.btn-counter': {
      color: '$postsReactionsHoverEffect'
    }
  },
  '.post-btn': {
    padding: '16px',
    svg: {
      marginRight: '0 !important'
    },
  },
  '.btn-label': {
    ml: '$8'
  },
  '.post-like': {
    padding: '0',
    '.btn-counter': {
      position: 'relative',
      left: '0',
      transform: 'translateX(0)',
      ml: '$8'
    }
  },
});

export const ButtonArrowContainer = styled('div', {
  position: 'relative',
  mr: '$16',
  variants: {
    active: {
      true: {
        backgroundColor: '$postsReactionsHoverEffect'
      }
    }
  }
})

export const CommunityReactionsBarButtons = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const Triangle = styled('div', {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '-1px',
  width: '0',
  height: '0',
  borderLeft: '8px solid transparent',
  borderRight: '8px solid transparent',
  borderBottom: '8px solid $elements',
  display: 'none',

  variants: {
    isVisible: {
      true: {
        display: 'block',
      },
    },
  },
});

export const StyledBtns = styled(Button, {
  fontSize: '$12',
  fontFamilly: '$ArtifaktElementRegular',
  textTransform: 'unset',
  borderRadius: '$24 !important',

  '&:hover': {
    backgroundColor: 'transparent !important',

    '@bp2': {
      backgroundColor: '$elements !important',
    },
  },

  '&:focus': {
    boxShadow: 'unset',
  },

  '& > .btn-label': {
    lineHeight: '1em',
  },

  variants: {
    isLiked: {
      true: {
        svg: {
          fill: '$red',
        },
        span: {
          color: '$red',
        },
      },
    },
  },
});

export const SaveBtn = styled(Button, {
  display: 'block',
  marginLeft: '$16',

  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  '@bp2': {
    display: 'none',
  },
});
