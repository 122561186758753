import { styled, keyframes } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';
const fadeIn = keyframes({
  '0%': { opacity: '0' },
  '100%': { opacity: '1' },
});
export const GroupFeedWrapper = styled('div', {
  // maxWidth: '$container',
  margin: '0 auto',
  padding: '$24 $16 $40',
  maxWidth: '$container',

  '@bp2': {
    padding: '$48 $32',
    flexDirection: 'row',
  },

  '@bp4': {
    padding: '$48 0px $84 0px',
  },

  '@bp6': {
    padding: '$48 0px $64 0px',
  },
});

export const CenterContent = styled('div', {
  width: '100%',

  '@bp2': {
    maxWidth: '640px',
    margin: '0 auto',
  },
  variants: {
    isNotShowing: {
      true: {
        display: 'none',

        '@bp2': {
          display: 'block',
        },
      },
    },
  },
});

export const GroupSidebarLeftStyled = styled('div', {
  maxWidth: 'unset',

  '@bp2': {
    maxWidth: '262px',
  },

  variants: {
    isNotShowing: {
      true: {
        display: 'none',

        '@bp2': {
          display: 'block',
        },
      },
    },
  },
});

export const GroupSidebarRightStyled = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',

  '@bp2': {
    marginLeft: '$32',
  },
});

export const PaginationWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '$84',
});

export const GroupFeedPageContent = styled('div', {
  '@bp2': {
    display: 'grid',
    gridTemplateColumns: '1fr 640px 1fr',
  },

  '& > div': {
    '&:nth-of-type(1)': {
      '@bp1': {
        marginRight: '$48',
      },

      '@bp4': {
        marginRight: '$84',
      },
    },
  },
});

export const MobileNavWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  padding: '$16 $16 0',
  textAlign: 'center',
  display: 'block',

  '@bp1': {
    padding: '$16 $32 0',
  },

  '@bp2': {
    display: 'none',
  },

  '&:before': {
    content: '""',
    display: 'block',
    width: 'calc(100% - 2rem)',
    height: '1px',
    backgroundColor: '$lightGrey',
    bottom: '0',
    position: 'absolute',
    zIndex: '0',
  },
});

export const MobileNavBtns = styled('button', {
  backgroundColor: 'unset',
  border: 'none',
  fontSize: '$14',
  fontFamily: '$ArtifaktLegendRegular',
  textTransform: 'uppercase',
  padding: '$4 $20',
  position: 'relative',
  color: '$mainTextColor',
  marginBottom: '0',

  variants: {
    isSelected: {
      true: {
        fontFamily: '$ArtifaktLegendBold',
        borderBottom: '1px solid $blackToWhite',
      },
    },
  },
});

const loadingAnimation = keyframes({
  '0%': { backgroundColor: 'white' },
  '50%': { backgroundColor: '#ccc' },
  '100%': { backgroundColor: 'white' },
});

export const PlaceHolderHeader = styled('div', {
  width: '100%',
  maxHeight: '200px',
  overflow: 'hidden',

  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '55.47%',
    animation: `${loadingAnimation} 1s infinite`,
  },

  '@bp2': {
    maxHeight: '300px',

    '&:before': {
      paddingBottom: '20.83%',
    },
  },
});

export const PopUp = styled('div', {
  backgroundColor: '$green',
  color: '$white',
  width: '344px',
  borderRadius: '$16',
  transition: 'opacity 0.2s ease-out',
  position: 'fixed',
  right: '50%',
  transform: 'translateX(50%)',
  zIndex: '$max',
  opacity: '0',
  boxShadow: '$2',
  padding: '$16',
  pointerEvents: 'none',

  '@bp2': {
    bottom: '50px',
    right: '$24',
    transform: 'unset',
  },

  variants: {
    isVisible: {
      true: {
        opacity: '1',
        pointerEvents: 'all',
      },
    },
  },
});

export const PopUpTitle = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  lineHeight: '20px',
  marginBottom: '$8',
});

export const PopUpText = styled(Text, {
  fontFamily: '$ArtifaktElementRegular',
  lineHeight: '20px',
});

export const PopUpClose = styled(Button, {
  position: 'absolute',
  right: '-$12',
  top: '-$12',

  '&:focus': {
    boxShadow: 'unset',
  },

  svg: {
    fill: '$white',
  },
});

export const PopUpWrapper = styled('div', {
  position: 'relative',
});

export const LoaderWrapper = styled('div', {
  width: '100%',
  height: '400px',
  backgroundColor: '$box',
  borderRadius: '$16',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    width: '32px',
    height: '32px',
  },
});

export const FakeModal = styled('div', {
  display: 'none',
  position: 'fixed',
  left: '0',
  zIndex: '$max',
  width: '100vw',
  height: 'calc(100% - 100px)',
  overflowX: 'hidden',
  overflowY: 'auto',
  outline: '0',
  animation: `${fadeIn} ease .4s`,
  textAlign: 'center',
  top: '54px',

  '@bp2': {
    top: '0',
    height: '100%',
  },
  backgroundColor: 'rgba(0,0,0,.5)',

  variants: {
    isShowing: {
      true: {
        display: 'block',
      },
    },
  },
});

export const ModalWrapper = styled('div', {
  position: 'fixed',
  left: '0',
  zIndex: '$max',
  width: '100vw',
  height: 'calc(100% - 100px)',
  overflowX: 'hidden',
  overflowY: 'auto',
  outline: '0',
  animation: `${fadeIn} ease .4s`,
  textAlign: 'center',
  top: '54px',
  '@bp2': {
    height: '100%',
  },
  backgroundColor: 'rgba(0,0,0,.5)',
});
export const ModalInner = styled('div', {
  zIndex: 500,
  backgroundColor: '$box',
  color: '$mainTextColor',
  position: 'relative',
  display: 'inline-block',
  textAlign: 'left',
  width: '100%',
  paddingTop: '$54',
  paddingBottom: '$40',

  '@bp2': {
    maxWidth: '864px',
    margin: '100px auto $64',
    borderRadius: '$16',
  },
});

export const ExitModal = styled(Button, {
  position: 'absolute',
  top: '$12',
  right: '$12',
  zIndex: '$1',
});

export const ErrorText = styled(Text, {
  color: '$mainTextColor',
  padding: '$32',
  display: 'block',
});
