import { styled } from '@root/stitches.config';

export const ProfileMenuWrapper = styled('div', {
  width: '100vw',
  overflow: 'scroll',
  height: 'calc(100vh - 66px)',
  position: 'fixed',
  top: '0px',
  zIndex: '1',
  transform: 'translateY(100%)',
  opacity: '1',
  transition: 'transform 330ms ease-in-out',
  padding: '$24 $16',
  paddingBottom: '90px',
  backgroundImage: 'url("/images/mobile_bg.jpg")',
  display: 'block',
  overflowY: 'auto',
  overflowX: 'hidden',
  pointerEvents: 'none',
  zIndex: '1000',
  a: {
    textDecoration: 'none',
  },

  variants: {
    isShown: {
      true: {
        opacity: '1',
        transform: 'translateY(0%)',
        pointerEvents: 'all',
      },
    },
  },

  '@bp2': {
    display: 'none',
  },
});

export const ProfileTopContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '$24',
});

export const ProfileBottomContainer = styled('div', {
  marginTop: '$40',
  backgroundColor: '$whiteToBlack',
  padding: '$32',
  width: '100%',
  borderRadius: '18px',
  position: 'relative',

  h4: {
    marginBottom: '$4',
  },
});

export const UserContainer = styled('div', {
  opacity: '1',
  transition: 'opacity .2s ease-out',
  zIndex: '1',
  pointerEvents: 'all',

  '&:hover': {
    opacity: '0.7',
  },

  a: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const UserTextContainer = styled('div', {
  marginLeft: '$12',
});

export const BigButtonsContainer = styled('div', {
  marginTop: '$54',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '$40',

  a: {
    backgroundColor: '$elements',
    borderRadius: '18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48%',
    padding: '$20 0',
    color: '$mainTextColor',
    fontFamily: '$ArtifaktLegendBold',
  },
});

export const BigButton = styled('div', {
  width: '44px',
  height: '44px',
  borderRadius: '50%',
  position: 'relative',
  marginBottom: '$8',

  svg: {
    width: '$24',
    height: '$24',
    fill: '$blackToWhite',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

export const CreateButton = styled('div', {
  position: 'absolute',
  marginTop: '-$54',
  color: '$blackToWhite',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  left: '0',
  right: '0',

  a: {
    color: '$mainTextColor',
  },

  button: {
    backgroundColor: '$blackToWhite',
    color: '$whiteToBlack',
    transition: 'color 0.3s ease 0s',
    width: '$54',
    height: '$54',
    borderRadius: '50%',
    border: '1px solid $whiteToBlack',
  },
  svg: {
    fill: '$whiteToBlack',
    width: '$24',
    height: '$24',
  },
});

export const BottomBtnContainer = styled('div', {
  width: '100%',
  marginBottom: '$32',

  a: {
    justifyContent: 'space-between',
    alignItems: 'left',
    color: '$mainTextColor',
    width: '100%',
    marginBottom: '$8',

    '&:last-of-type': {
      marginBottom: '0',
    },
  },
});

export const HorizontalButton = styled('div', {
  width: '100%',
  marginBottom: '$8',

  a: {
    textTransform: 'uppercase',
    lineHeight: '1em',
    display: 'inline-flex',
    fontFamily: 'var(--ArtifaktLegendBold)',
    letterSpacing: '0.2px',
    position: 'relative',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '$grey',

    borderRadius: '$8',
    padding: '$16 20px',

    justifyContent: 'space-between',
    alignItems: 'center',
    color: '$black',
  },

  svg: {
    width: 'var(--sizes-24)',
    height: 'var(--sizes-24)',
    fill: 'var(--colors-black)',
  },
});

export const DarkModeContainer = styled('button', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: 'none',
  backgroundColor: 'unset',
});

export const DarkModeLeft = styled('div', {
  display: 'flex',
  alignItems: 'center',


  svg: {
    width: '$24',
    height: '$24',
    marginRight: '$16',
    fill: '$mainTextColor',
  },
});

export const DarkModeRight = styled('div', {
  flexShrink: '0',
  backgroundColor: '$blackToWhite',
  display: 'flex',
  alignItems: 'center',
  color: '$whiteToBlack',
  position: 'relative',
  borderRadius: '18px',
  width: '$64',
  height: '$32',
  justifyContent: 'space-between',
  padding: '$8',
  fontFamily: '$ArtifaktElementBold',
  textTransform: 'uppercase',
  right: '$32',

  span: {
    position: 'absolute',
    top: '2px',
    left: '2px',
    width: '28px',
    height: '28px',
    backgroundColor: '$whiteToBlack',
    borderRadius: '100%',
    transform: 'translateX(0%)',
    transition: 'transform 0.2s ease-out',
  },

  variants: {
    isDark: {
      true: {
        span: {
          transform: 'translateX(calc(100% + 5px))',
        },
      },
    },
  },
});
