import {
  BackBtn,
  BackLinkText,
  BannerText,
  ContentBottomContainer,
  ContentTopContainer,
  ContentWrapper,
  IconImage,
  ModalContentWrapper,
  ModalSubTitle,
  ModalTitle,
  PageWrapper,
  SidebarWrapper,
  StyledSvg,
  Title,
  TitleContainer,
  TopBanner,
} from '../groupMembersModeration/GroupMembersModeration.styled';
import {
  GET_GROUP_BASIC_DETAILS,
  GET_MY_GROUPS_ID,
  GET_POSTS_FILTERED,
  GET_POSTS_FILTERED_COUNT,
} from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import CommunityPendingPublicationFeed from '@components/organisms/communityPendingPublicationFeed/CommunityPendingPublicationFeed';
import GroupCommunitySideBar from '@components/molecules/groupCommunitySidebar/GroupCommunitySideBar';
import Image from '@components/atoms/image/Image';
import Layout from '@components/organisms/layout/Layout';
import Loader from '@components/atoms/loader/Loader';
import { POST_MODERATION } from '@src/apollo/mutation';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import String from '@components/atoms/string/String';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';

const PendingPublicationPage = context => {
  const authContext = useContext(AuthContext);
  const [userRole, setUserRole] = useState('');
  const convertUTCToLocalDate = date => {
    if (!date) {
      return date;
    }
    date = new Date(date * 1000);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );

    return format(date, 'd LLL. y');
  };
  const [postModeration, postModerationData] = useMutation(POST_MODERATION, {
    onCompleted: () => {},
  });

  const [moderatedId, setModeratedId] = useState(null);

  const [groupState, setGroupState] = useState({
    name: '',
    iconUrl: '',
    loaded: false,
    id: null,
  });

  const [pendingPostsState, setPendingPostsState] = useState({
    postsList: [],
    limit: 12,
    page: 1,
    loaded: false,
    refetched: false,
    loading: true,
    loadMore: false,
  });

  const [pendingPostsCount, setPendingPostsCount] = useState(0);
  const [reportedPostsCount, setReportedPostsCount] = useState(0);

  const [groupRoleState, setGroupRole] = useState({
    role: '',
    loaded: false,
  });

  const [getgroupRoleData, groupRoleData] = useLazyQuery(GET_MY_GROUPS_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (groupState.id !== null) {
      getgroupRoleData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (groupRoleData.data) {
      let role = '';
      for (let i = 0; i < groupRoleData.data.mygroups.length; i++) {
        if (groupRoleData.data.mygroups[i].id === groupState.id) {
          role = groupRoleData.data.mygroups[i].group_role;
        }
      }
      setGroupRole({
        ...groupRoleState,
        role,
        loaded: true,
      });
    }
  }, [groupRoleData.data]);

  useEffect(() => {
    if (groupRoleState.loaded) {
      setUserRole(
        authContext.generalRole === 'admin' ||
          groupRoleState.role === 'moderator'
          ? 'admin'
          : 'member'
      );
    }
  }, [groupRoleState.loaded]);

  const { loading, error, data } = useQuery(GET_GROUP_BASIC_DETAILS, {
    variables: {
      slug: context.params.slug,
      feed: 'recent',
    },
    context: { clientName: 'public' },
  });

  useEffect(() => {
    if (data) {
      setGroupState({
        ...groupState,
        name: data.group.name,
        iconUrl: getImageSrc(data.group.icon, 'group_icon', data.group.title),
        loaded: true,
        id: data.group.id,
      });
    }
  }, [data]);

  const [getPostFilteredData, postFilteredData] = useLazyQuery(
    GET_POSTS_FILTERED,
    {
      variables: {
        groupId: groupState.id,
        status: 'pending',
        limit: pendingPostsState.limit,
        page: pendingPostsState.page,
      },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getPostFilteredData();
    }
  }, [groupState.id]);

  const getAvatar = profilePicture => {
    return getAvatarImage(profilePicture, 'xsmall');
  };

  useEffect(() => {
    if (postFilteredData.data) {
      let postList = [];
      if (pendingPostsState.loadMore) {
        postList = pendingPostsState.postsList;
      }
      postFilteredData.data.postsFiltered.map((post, index) => {
        let profilePicture = getAvatar(
          post?.author?.userProfile?.profile_picture,
          'xsmall'
        );

        const postImages = [];
        post.images && (
          <>
            {post.images.map((image, i) => {
              const imageContent = {
                src: CDN_URL + image.path,
                source: [
                  {
                    srcset: `${CDN_URL}${image.sizes[1]?.path}`,
                    media: '(min-width: 769px)',
                  },
                  {
                    srcset: `${CDN_URL}${image.sizes[0]?.path}`,
                    media: '(max-width: 768px)',
                  },
                ],
                alt: `${post.author.first_name} ${post.author.last_name}`,
                id: image.id,
              };
              postImages.push({...image, ...imageContent});
            })}
          </>
        );

        let postLinkPreview = {};

        if (post.link_url !== null && post.link_url !== '') {
          postLinkPreview = {
            url: post.link_url,
            title: post.link_title,
            description: post.link_description,
            previewImage: post.link_image
              ? {
                  src: {
                    src: CDN_URL + post.link_image.path,
                  },
                  source: [
                    {
                      srcset: `${CDN_URL}${post.link_image[1]?.path}`,
                      media: '(min-width: 769px)',
                    },
                    {
                      srcset: `${CDN_URL}${post.link_image[0]?.path}`,
                      media: '(max-width: 768px)',
                    },
                  ],
                  alt: post.link_url,
                }
              : null,
          };
        }

        const postData = {
          postLinkPreview:
            post.link_url !== null && post.link_url !== ''
              ? postLinkPreview
              : null,
          postId: post.id,
          postLikes: 0,
          postDate: convertUTCToLocalDate(post.createdAt),
          isCommunity: false,
          isPost: true,
          isPending: true,
          postAuthor: {
            name: `${post.author.first_name} ${post.author.last_name}`,
            jobTitle: post.author.title,
            groupName: '',
            avatar: {
              src: profilePicture,
              alt: `${`${post.author.first_name} ${post.author.last_name}`}`,
            },
            url: `/m/${post.author.username}`,
          },
          postText: post.content,
          postImages,
        };
        postList.push(postData);
      });
      setPendingPostsState({
        ...pendingPostsState,
        postsList: postList,
        loaded: true,
        refetched: false,
        loading: false,
        loadMore: false,
      });
      setModeratedId(null);
    }
  }, [postFilteredData.data]);

  const [getPostFilteredCountData, postFilteredCountData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'pending',
      },
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getPostFilteredCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (postFilteredCountData.data) {
      setPendingPostsCount(postFilteredCountData.data.postsFilteredCount);
    }
  }, [postFilteredCountData.data]);

  const [getReportedCountData, reportedCountDataData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'reported',
      },
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getReportedCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (reportedCountDataData.data) {
      setReportedPostsCount(reportedCountDataData.data.postsFilteredCount);
    }
  }, [reportedCountDataData.data]);

  const groupUrl = context.location.pathname.substring(
    0,
    context.location.pathname.indexOf(`/${context.params.slug}`) +
      `/${context.params.slug}`.length
  );

  const activeTab = context.location.pathname.substring(
    context.location.pathname.indexOf('/admin/') + '/admin/'.length
  );

  const onApproveAllClick = () => {
    for (let i = 0; i < pendingPostsState.postsList.length; i++) {
      postModeration({
        variables: {
          postId: pendingPostsState.postsList[i].postId,
          status: 'approved',
          moderatorNotes: 'approved',
        },
      });
    }
    postFilteredCountData.refetch();

    setPendingPostsState({
      ...pendingPostsState,
      postsList: [],
      loaded: false,
    });
  };
  const onRejectClick = (postId, reason) => {
    setModeratedId(postId);
    postModeration({
      variables: {
        postId,
        status: 'rejected',
        moderatorNotes: reason,
      },
    });

    setPendingPostsState({
      ...pendingPostsState,
      loading: true,
      page: 1,
    });

    postFilteredCountData.refetch();
  };
  const onApproveClick = postId => {
    setModeratedId(postId);

    postModeration({
      variables: {
        postId,
        status: 'approved',
        moderatorNotes: 'approved',
      },
    });

    setPendingPostsState({
      ...pendingPostsState,
      loading: true,
      page: 1,
    });

    postFilteredCountData.refetch();
  };

  useEffect(() => {
    if (postModerationData.data && !pendingPostsState.refetched) {
      setPendingPostsState({
        ...pendingPostsState,
        refetched: true,
        // loaded: false,
      });

      postFilteredData.refetch();
      postFilteredCountData.refetch();
    }
  }, [postModerationData.data]);

  const onLoadMoreClick = () => {
    const newPage = pendingPostsState.page + 1;
    setPendingPostsState({
      ...pendingPostsState,
      page: newPage,
      loadMore: true,
    });
    postFilteredData.refetch();
  };

  return (
    <ProtectedRoute
      redirectTo={groupUrl}
      userAccessReq={['auth', userRole, ['admin']]}
    >
      <Layout location={context.location}>
        <PageWrapper>
          <TopBanner>
            <BannerText size="12">
              <String id="community_moderation_title_bar" />
            </BannerText>
          </TopBanner>
          <ContentWrapper>
            <ContentTopContainer>
              <BackBtn title={`back to ${context.params.slug}`} to={groupUrl}>
                <StyledSvg icon="arrow-left-short" />
                <BackLinkText size="12">
                  <String id="community_moderation_back_text" />
                </BackLinkText>
              </BackBtn>
              {groupState.loaded ? (
                <TitleContainer>
                  <IconImage>
                    <Image
                      src={groupState.iconUrl}
                      alt="icon"
                      isBackground={false}
                    />
                  </IconImage>

                  <Title>{groupState.name}</Title>
                </TitleContainer>
              ) : null}
            </ContentTopContainer>
            <ContentBottomContainer>
              <SidebarWrapper>
                <GroupCommunitySideBar
                  activeTab={activeTab}
                  groupUrl={groupUrl}
                  location={context.location}
                  pendingNotifications={{
                    members: {
                      amount: 0,
                    },
                    publication: {
                      amount: pendingPostsCount,
                    },
                    reportContent: {
                      amount: reportedPostsCount,
                    },
                  }}
                />
              </SidebarWrapper>
              {pendingPostsState.loaded ? (
                <CommunityPendingPublicationFeed
                  hasNewPendingItems={false}
                  count={0}
                  amount={pendingPostsCount}
                  limit={pendingPostsState.limit * pendingPostsState.page}
                  onApproveAllClick={onApproveAllClick}
                  onRejectClick={onRejectClick}
                  onApproveClick={onApproveClick}
                  postList={pendingPostsState.postsList}
                  loading={pendingPostsState.loading}
                  moderatedId={moderatedId}
                  onLoadMoreClick={onLoadMoreClick}
                  moreLoading={postFilteredData.loading}
                />
              ) : (
                <Loader isLoading="true" />
              )}
            </ContentBottomContainer>
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </ProtectedRoute>
  );
};
export default PendingPublicationPage;

PendingPublicationPage.propTypes = {};

PendingPublicationPage.defaultProps = {};
