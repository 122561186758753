import { styled } from '@root/stitches.config';

export const CreatePageSection = styled('div', {
  // display: 'flex'
  width: '100%',
});

export const CreateNavigationHeader = styled('div', {
  // display: 'flex'
});

export const CreateNavigationContent = styled('div', {
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  '@bp2': {
    flexDirection: 'row',
  },
});
