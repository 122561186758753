import { CREATE_INDUSTRY, EDIT_INDUSTRY } from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import Button from '@components/atoms/button/Button';
import ContentDataEdit from '@components/molecules/contentDataEdit/ContentDataEdit';
import EditButton from '@components/organisms/table/components/columnItem/EditButton';
import { GET_INDUSTRIES } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import { IndustriesHeader } from './ContentIndustries.styled';
import Modal from '@components/atoms/modal/Modal';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import useModal from '@components/atoms/modal/useModal';

const ContentIndustries = (context) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: isShowingEdit, toggle: toggleEdit } = useModal();

  const [industriesContent, setIndustriesContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    columns: [
      {
        header: 'Industry name',
        accessor: 'industryName',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const [industriesList, setIndustriesList] = useState([]);

  const [currentIndustry, setCurrentIndustry] = useState({
    id: '',
    isEdit: false,
    fields: [
      {
        type: 'text',
        label: <String id="admin_content_industries_input_text_label" />,
        value: '',
        name: 'industryName',
      },
    ],
  });

  const industriesData = useQuery(GET_INDUSTRIES, {
    fetchPolicy: 'no-cache',
  });

  const [editIndustry, editIndustryData] = useMutation(EDIT_INDUSTRY, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      industriesData.refetch();
    },
  });

  const [createIndustry, createIndustryData] = useMutation(CREATE_INDUSTRY, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      industriesData.refetch();
    },
  });

  const onAddNewIndustry = () => {
    setCurrentIndustry({
      id: '',
      isEdit: false,
      fields: [
        {
          type: 'text',
          label: <String id="admin_content_industries_input_text_label" />,
          value: '',
          name: 'industryName',
        },
      ],
    });
    toggleEdit();
  };

  const onEdit = id => {
    const updatedCurrentIndustry = { ...currentIndustry };

    industriesData.data.industries.forEach(industry => {
      if (industry.id == id) {
        updatedCurrentIndustry.fields.forEach(field => {
          field.value = industry.name;
        });
      }
    });

    setCurrentIndustry({
      id,
      isEdit: true,
      fields: updatedCurrentIndustry.fields,
    });

    toggleEdit();
  };

  const onSaveAdd = (id, fieldsData) => {
    createIndustry({
      variables: {
        industryInput: { name: fieldsData[0].value },
      },
    });
    toggleEdit();
  };

  const onSaveEdit = (id, fieldsData) => {
    editIndustry({
      variables: {
        industryId: id,
        industryInput: { name: fieldsData[0].value },
      },
    });
    toggleEdit();
  };

  useEffect(() => {
    if (industriesData.data) {
      const rowDatas = [];

      industriesData.data.industries.forEach(industry => {
        const singleRow = {
          industryName: <Text>{industry.name}</Text>,
          options: <EditButton action={onEdit} id={industry.id} />,
          id: industry.id,
        };
        rowDatas.push(singleRow);
      });

      setIndustriesContent({
        ...industriesContent,
        isLoaded: true,
        currentCount: `1-${industriesData.data.industries.length}`,
        totalCount: industriesData.data.industries.length,
      });

      setIndustriesList(rowDatas);
    }
  }, [industriesData.data]);

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <IndustriesHeader>
        <Heading level="h3" css={{ lineHeight: '1em' }}>
          <String id="admin_content_industries_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={onAddNewIndustry}
          label={<String id="admin_content_industries_add_industry_label" />}
          type="normal"
          css={{ marginLeft: '$16' }}
        />
      </IndustriesHeader>

      <Table
        nextPage={() => {}}
        prevPage={() => {}}
        changeRowPerPage={9999}
        totalCount={industriesContent.totalCount}
        currentCount={industriesContent.currentCount}
        loading={
          industriesData.loading ||
          editIndustryData.loading ||
          createIndustryData.loading
        }
        rowData={industriesList}
        columns={industriesContent.columns}
        maxPage={industriesContent.maxPage}
        currentPage={industriesContent.page}
        hasOptions
        isNotMultiSelect
        hasNoPagination
       />
      <br />

      <Modal isShowing={isShowingEdit}>
        {currentIndustry.isEdit ? (
          <Heading level="h3" css={{ marginBottom: '$16' }}>
            <String id="admin_content_industries_modal_edit_title" />
          </Heading>
        ) : (
          <Heading level="h3" css={{ marginBottom: '$16' }}>
            <String id="admin_content_industries_modal_add_title" />
          </Heading>
        )}

        <ContentDataEdit
          {...currentIndustry}
          toggleEdit={toggleEdit}
          onSave={currentIndustry.isEdit ? onSaveEdit : onSaveAdd}
         />
      </Modal>
    </AdminLayout>
  );
};

export default ContentIndustries;
