import { styled } from '@root/stitches.config';

export const BreadcrumbWrapper = styled('ol', {
  display: 'flex',
  alignItems: 'center',

  lineHeight: '2',
  fontFamily: '$ArtifaktLegendBold',
  margin: '0 auto',
  px: '$16',
  py: '$4',

  '@bp2': {
    py: '$12',
  },
  '@bp5': {
    px: '0',
  },

  '@bp4': {
    maxWidth: '$container',
  },

  '.last-breadcrumb-item': {
    opacity: 0.6,
  },
  '> li': {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    'a, button, p': {
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '$mainTextColor',
    },
    'a, button': {
      textTransform: 'capitalize',
      transition: 'opacity .4s ease-out',
      '&:hover': {
        opacity: 0.6,
      },
    },
    button: {
      padding: 0,
      margin: 0,
      border: 0,
      fontSize: '$16',
      lineHeight: '2',
      fontFamily: '$ArtifaktElementBold',
      background: 'none',
      cursor: 'pointer',
    },
    svg: {
      height: '10px',
      width: 'auto',
      margin: '0 $8',
      fill: '$blackToWhite',
    },
  },
  'li:last-child': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
