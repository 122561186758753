import { styled } from '@root/stitches.config';

export const BlockImageWrapper = styled('div', {
  
});

export const ImageWrapper = styled('div', {
  width: '100%',
  mb: '$8',

  '@bp2': {
    mb: '$24',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    position: 'relative',
    maxWidth: '100%',
    width: 'auto',
    alignSelf: 'center'
  },
});
