import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const AccountSettingWrapper = styled('div', {
  paddingBottom: '$40',
  '@bp3': {
    paddingRight: '20%',
  },
  position: 'relative',
  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '1px',
    background: '$lightGrey',
    bottom: 0,
    left: 0,
  },
});

export const InputWrapper = styled('div', {
  marginBottom: '$16',
});

export const ButtonWrapper = styled('div', {
  marginTop: '$24',

  variants:{
    isPassword:{
      true:{
        marginTop: '$40',
      }
    }
  }
});

export const StyledBtn = styled(Button, {
  '.dark-mode &':{
    backgroundColor: '$white',
    color: '$black'
  }
});