import { Dropdown, DropdownBtnWrapper, StyledBtn } from './Dropdown.styled';

import React from 'react';

const DropdownComponent = React.memo(props => {
  return (
    <DropdownBtnWrapper key={'dropdown' + props.index} css={props.css}>
      <StyledBtn
        label={props.title}
        actionType="onclick"
        colorTheme={props.colorTheme}
        svgIcon={props.isOpened ? 'arrow-up-short' : 'arrow-down-short'}
        notificationBubbleActive={false}
        svgLocation={props.svgLocation}
        onClick={() => {
          props.onFilterDropdownClick(props.index);
        }}
      />
      <Dropdown
        className={'dropdowns dropdown-' + props.index}
        isOpened={props.isOpened}
      >
        {props.items.map((item, i) => {
          return (
            <li
              className={
                props.activeOption === i ? 'active-drowpdown-item' : null
              }
              key={props.index + 'item' + i}
              onClick={() => {
                props.onDropdownItemClick(item.title, props.title);
              }}
            >
              {item.title.replace('_', ' ')}
            </li>
          );
        })}
      </Dropdown>
    </DropdownBtnWrapper>
  );
});

export default DropdownComponent;
