import { styled } from '@root/stitches.config';
import Svg from '@components/atoms/svg/Svg';
import Image from '@components/atoms/image/Image';
import { Text } from '@components/atoms/text/Text';

export const PublishNotificationWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  overflow: 'hidden',
  backgroundColor: '$box',

  variants: {
    artwork: {
      true: {
        padding: 'unset',
        backgroundColor: 'unset',

        '@bp1': {
          padding: 'unset',
        },
      },
      false: {
        backgroundColor: '$box',
        // padding: '125px $48 $40',
      },
    },
  },
});

export const StyledSvg = styled(Svg, {
  position: 'absolute',
  top: '-30px',
  left: '-30px',
  display: 'none',
  fill: '$box',
  width: '190px',
  height: '202px',

  '@bp1': {
    top: '0',
    left: '0',
    display: 'block',
  },
});

export const PublishNotificationContentWrapper = styled('div', {
  padding: 'unset',
  backgroundColor: '$box',

  variants: {
    artwork: {
      true: {
        backgroundColor: '$box',

        div: {
          color: '$mainTextColor',
        },
      },
      false: {
        backgroundColor: '$box',
        padding: 'unset',

        '@bp1': {
          width: '55%',
          margin: '0 auto',
        },
      },
    },
  },
});

export const PublishNotificationContentContainer = styled('div', {
  backgroundColor: '$box',

  variants: {
    artwork: {
      true: {
        backgroundColor: '$box',
      },
      false: {
        backgroundColor: '$box',
      },
    },
  },
});

export const PublishArtworkImageWrapper = styled('div', {
  position: 'relative',
});

export const StyledImage = styled(Image, {
  position: 'absolute !important',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
});

export const JoinBtnWrapper = styled('div', {
  marginTop: '$48',
});

export const StyledTextBig = styled(Text, {
  lineHeight: '52px',
  fontFamily: '$ArtifaktLegendBold',
  marginBottom: '$12',
  color: '$box',
});

export const StyledTextSmall = styled(Text, {
  color: '$box',
  marginBottom: '$24',
});

export const OnboardingButtons = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '$12',
});
