import React, { useContext, useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_USER_DATA } from '@src/apollo/query';
import AuthContext from '@context/AuthContext';
const UserContext = createContext({});

export const UserProvider = props => {
  const authContext = useContext(AuthContext);

  const userInfo = () => {
    if (!authContext.isAuthenticated) {
      return null;
    } else {
      const { loading, error, data, refetch } = useQuery(GET_USER_DATA, {
        variables: { username: authContext.userSlug },
        context: { clientName: 'public' },
        fetchPolicy: 'no-cache',
      });
      const user = loading || !data ? null : data?.user;
      return user;
    }
  };

  return (
    <UserContext.Provider value={userInfo()}>
      {props.children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;
export default UserContext;

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
