import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const BadgeListWrapper = styled('div', {
  textAlign: 'center',
});

export const ShowMoreButton = styled('div', {
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  boxShadow: '$2',
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$14',
  cursor: 'pointer',
  transition: 'box-shadow .3s ease',
  backgroundColor: '$box',

  '&:hover': {
    boxShadow: '$1',
  },
});

export const BadgeListBadges = styled('div', {
  display: 'grid',
  maxWidth: '250px',
  margin: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',

  '&>div': {
    margin: '0 $16 $16',
  },
});

export const TitleText = styled(Text, {
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: '$16',
  lineHeight: '1em',
  display: 'inline-block',
  gridArea: 'second',
});

export const TitleWrapper = styled('div', {
  textAlign: 'center',
  alignItems: 'center',
  mb: '$8',
  
  bp2: {
    display: 'grid',
    gridTemplateAreas: "'first second third fourth'",
    gridTemplateColumns: '1fr 3fr 1fr 1fr',
  },

  button: {
    gridArea: 'third',
    svg: {
      position: 'relative',
      right: '-1px',
    },
  },
});
