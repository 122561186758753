import {
  LinkStyled,
  SignUpBoxList,
  SignUpBoxTitle,
  SignUpBoxWrapper,
} from './SignUpBox.styled';
import React, { useContext } from 'react';

import GetUrl from '@components/atoms/getUrl/GetUrl';
import SocialAuthBox from '@components/organisms/socialAuthBox/SocialAuthBox';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';

const SignUpBox = props => {
  const UrlContext = useContext(UrlsContext);

  return (
    <SignUpBoxWrapper css={props.css} className={props.className}>
      <SignUpBoxTitle>
        <Text
          as="h2"
          weight="legendExtraBold"
          css={{
            marginBottom: '$16',
            // Temporary
            fontSize: '$24',
            '@bp1': {
              fontSize: '$42',
              lineHeight: '1.2',
            },
          }}
        >
          <String id="sign_up_title" />
        </Text>
        <Text
          as="h2"
          weight="legendBold"
          css={{
            marginBottom: '$24',
            fontSize: '$20',
            lineHeight: '1.4',
            '@bp1': {
              fontSize: '$24',
              lineHeight: '1.2',
            },
          }}
        >
          <String id="sign_up_description" />
        </Text>
        <SignUpBoxList>
          <ul>
            <li>
              <Svg icon={'circle-check'} />
              <String id="sign_up_features_1" />
            </li>
            <li>
              <Svg icon={'circle-check'} />
              <String id="sign_up_features_2" />
            </li>
            <li>
              <Svg icon={'circle-check'} />
              <String id="sign_up_features_3" />
            </li>
          </ul>
        </SignUpBoxList>
      </SignUpBoxTitle>
      <SocialAuthBox type="signup" />
      {/* <Separator label="OR" /> */}

      <Text css={{ textAlign: 'center' }} size="$14">
        <String id="sign_up_have_an_account" />
        <LinkStyled to={GetUrl('login', UrlContext)}>
          {' '}
          <String id="sign_up_have_an_account_link_label" />
        </LinkStyled>
      </Text>
    </SignUpBoxWrapper>
  );
};

export default SignUpBox;
