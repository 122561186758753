import {
  Logo,
  LogoBtn,
  MobileNavBottomInner,
  MobileNavBottomWrapper,
} from './MobileNavBottom.styled';
import React, { useContext } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';

const MobileNavBottom = props => {
  const authContext = useContext(AuthContext);

  return (
    <MobileNavBottomWrapper>
      <MobileNavBottomInner isNotLogged={!props.isLogged}>
        <Button
          actionType="internalLink"
          internalUrl="/"
          colorTheme="transparent"
          svgIcon="home"
          css={{
            padding: '$8',
            minHeight: 'auto',
            svg: {
              fill: '#fff',
              marginRight: 0,
            },
            '.btn-label': {
              display: 'none',
            },
            span: {
              lineHeight: 0,
            },
          }}
        />
        {!props.isLogged ? (
          <LogoBtn onClick={props.onProfileClick}>
            <Logo />
          </LogoBtn>
        ) : null}
        <Button
          actionType="internalLink"
          internalUrl="/search"
          colorTheme="transparent"
          svgIcon="search"
          css={{
            padding: '$8',
            minHeight: 'auto',
            svg: {
              fill: '#fff',
              marginRight: 0,
            },
            '.btn-label': {
              display: 'none',
            },
            span: {
              lineHeight: 0,
            },
          }}
        />
        {props.isLogged ? (
          <>
            <LogoBtn onClick={props.onProfileClick}>
              <Logo />
            </LogoBtn>
            <Button
              actionType="internalLink"
              internalUrl="/notifications"
              colorTheme="transparent"
              svgIcon="notification"
              css={{
                padding: '$8',
                minHeight: 'auto',
                svg: {
                  fill: '#fff',
                  marginRight: 0,
                },
                '.btn-label': {
                  display: 'none',
                },
                span: {
                  lineHeight: 0,
                },
              }}
            />
            <Button
              actionType="internalLink"
              colorTheme="transparent"
              svgIcon="thumbnail-level"
              internalUrl={`/m/${authContext.userSlug}`}
              css={{
                padding: '$8',
                minHeight: 'auto',
                svg: {
                  fill: '#fff',
                  marginRight: 0,
                },
                '.btn-label': {
                  display: 'none',
                },
                span: {
                  lineHeight: 0,
                },
              }}
            />
          </>
        ) : null}
      </MobileNavBottomInner>
    </MobileNavBottomWrapper>
  );
};

export default MobileNavBottom;
