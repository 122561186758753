import {
  BtnContainer,
  DatePickerWrapper,
} from './CreateContent.styled';
import React, { forwardRef, useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import DatePicker from '@components/molecules/datePicker/DatePicker';
import String from '@components/atoms/string/String';

const ChangeDate = props => {
  const [newDate, setNewDate] = useState(props.date);

  const onChangeDate = date => {
    const theDate = new Date(date).getTime();
    setNewDate(theDate);
  };

  const onSaveNewInfos = () => {
    props.onSaveNewInfos();
    props.updateUserContentAction({
      variables: {
        id: props.id,
        admin_input: { publication_date: newDate / 1000 },
      },
    });

    props.closeModal();
  };

  return (
    <>
      <DatePickerWrapper>
        <DatePicker
          selected={newDate}
          onChange={date => onChangeDate(date)}
          dateFormat="dd/MM/yyyy"
          showDayMonthYearPicker
          label="change_date_label"
          showCalendarIcon
        />
      </DatePickerWrapper>
      <BtnContainer>
        <Button
          actionType="onclick"
          onClick={props.closeModal}
          colorTheme="gray"
          label={<String id="general_cancel_btn_text" />}
          type="normal"
        />
        <Button
          actionType="onclick"
          onClick={onSaveNewInfos}
          colorTheme="normal"
          label={<String id="table_bulk_operation_confirm" />}
          type="normal"
          css={{ marginLeft: '$16' }}
        />
      </BtnContainer>
    </>
  );
};

export default ChangeDate;
