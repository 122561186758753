import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const ButtonsWrapper = styled('div', {
  marginTop: '$28',
  display: 'flex',
});

export const ImageWrapper = styled('div', {
  width: '100%',
  height: '206px',
  top: '0px',
  left: '0px',
  position: 'absolute !important',
});

export const StyledButton = styled(Button, {
  marginLeft: '$16',
  '&:first-of-type': {
    marginLeft: 0,
  },
});

export const ContentWrapper = styled('div', {
  marginTop: '$16',
});

export const ModalContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});
