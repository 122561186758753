import { styled } from '@root/stitches.config';

export const TableBlockWrapper = styled('div', {
  variants: {
    isLastRowGreen: {
      true: {
        'tbody tr:last-of-type td:last-of-type': {
          color: '$green !important',
        },
      },
    },
  },
  table: {
    width: '100%',
    color: '#000',
    fontSize: '14px',
  },
  thead: {
    fontFamily: 'ArtifaktLegendMedium',
    textTransform: 'uppercase',
    th: {
      padding: '$16 $24',
      textAlign: 'right',
      '&:first-of-type': {
        textAlign: 'left',
      },
    },
    tr: {
      backgroundColor: '$ultraLightGrey',
    },
  },
  tbody: {
    color: '$mainTextColor',
    fontFamily: 'ArtifaktElementRegular',
    td: {
      padding: '$16 $24',
      borderBottom: '1px solid $ultraLightGrey',
      textAlign: 'right',
      '&:first-of-type': {
        textAlign: 'left',
      },
    },
  },
});
