import { AddImageContainer, AddImageContent } from './AddImage.styled';
import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import ImageUploading from 'react-images-uploading';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const AddImage = props => {
  const [images, setImages] = React.useState([]);
  const [error, setError] = React.useState({
    isError: false,
    errorMsg: '',
  });

  const onDrop = imgs => {
    if (
      props?.imageMaxSizeBytes &&
      imgs[0].file.size < props?.imageMaxSizeBytes
    ) {
      setImages(imgs);
      props.onSave('image', imgs[0]);
      props.onClose();
    } else {
      setError({
        isError: true,
      });
    }
  };

  return (
    <AddImageContainer>
      <Heading level="h3" css={{ 
        lineHeight: '1em',
        marginBottom: '$32',
        fontFamily: '$ArtifaktLegendBold',
      }}>
        <String id="create_modal_image_title" />
      </Heading>
      <AddImageContent>
        <ImageUploading
          value={images}
          onChange={onDrop}
          maxNumber={1}
          dataURLKey="data_url"
          resolutionType="more"
          resolutionWidth={
            props.resolutionWidth ? props.resolutionWidth : '1000'
          }
          resolutionHeight={props.resolutionHeight}
          onError={(e, a, b) => {
            setError({ ...error, isError: true });
          }}
        >
          {({ onImageUpload, dragProps }) => {
            return (
              <Button
                type="normal"
                colorTheme="normal"
                svgIcon="image"
                actionType="onclick"
                label="Upload"
                css={{ marginBottom: '$16', marginTop: '$16' }}
                onClick={onImageUpload}
              />
            );
          }}
        </ImageUploading>
        <Text
          size="12"
          css={{
            marginLeft: '$20',
            color: error.isError ? '$red' : '$mainTextColor',
          }}
        >
          <String 
            id={`add_image${props.resolutionHeight ? '_height_limit' : ''}${props.resolutionWidth ? '_width_limit' : ''}_instructions`}
            extras={{
              ...props.resolutionHeight ? {height: props.resolutionHeight} : {},
              ...props.resolutionWidth ? {width: props.resolutionWidth} : {},
              size: (props?.imageMaxSizeBytes / 1000000).toFixed(1) || 10
            }}
          />
        </Text>
      </AddImageContent>
    </AddImageContainer>
  );
};

export default AddImage;
