import CourseThumbnail from '../CourseThumbnail';
import { Grid } from '@components/atoms/grid/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const CourseThumbnailList = props => {
  const { listData } = props;
  return (
    <Grid
      css={{
        gap: '$16',
        '@bp1': {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@bp3': {
          gap: '$32',
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      }}
    >
      {listData.map((item, index) => {
        return <CourseThumbnail key={`${item.title + index}`} {...item} />;
      })}
    </Grid>
  );
};

export default CourseThumbnailList;

CourseThumbnailList.propTypes = {
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      staticImages: PropTypes.arrayOf(
        PropTypes.shape({
            alt: PropTypes.string.isRequired,
            src: PropTypes.shape({
              childImageSharp: PropTypes.shape({
                fluid: PropTypes.oneOfType([
                  PropTypes.shape({}),
                  PropTypes.arrayOf(PropTypes.shape({})),
                ]),
              }).isRequired,
            }),
        }),
      ),
      title: PropTypes.string.isRequired,
      intro: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          colorTheme: PropTypes.string,
          internalUrl: PropTypes.string,
          externalUrl: PropTypes.string,
          actionType: PropTypes.oneOf([
            'onclick',
            'externalLink',
            'internalLink',
          ]),
          onClick: PropTypes.func,
          svgIcon: PropTypes.string,
          svgLocation: PropTypes.oneOf(['before', 'after']),
        })
      ),
    })
  ).isRequired,
};
