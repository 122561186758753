import { styled } from '@root/stitches.config';
import ThumbnailSlider from '@components/organisms/thumbnailSlider/ThumbnailSlider';
import Button from '@components/atoms/button/Button';
import { Text } from '@components/atoms/text/Text';

export const PageWrapper = styled('div', {
  overflow: 'hidden',
  minHeight: '100vh',
  justifyContent: 'center',
  margin: '0 auto',
  padding: '$32 $16 $64 $16',
  '@bp2': {
    padding: '$48 0px $84 0px',
  },

  '@bp4': {
    width: '100%',
    maxWidth: '$container',
  },
});

export const HighlightWrapper = styled('div', {
  display: 'grid',
  gap: '$16',
  margin: '0 auto $16 auto',
  gridTemplateColumns: 'repeat(1, 1fr)',
  '@bp0': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp1': {
    margin: '0 auto $92 auto',
    gap: '32px',
  },
  '@bp2': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',
    maxWidth: '$container',
  },
});

export const ButtonSpacer = styled('div', {
  width: '100%',
  margin: '0 0 $40 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@bp1': {
    margin: '0 0 93px 0',
  },
});

export const HeaderSectionWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: '0 0 $16 0',
});

export const HeadingStyled = styled(Text, {
  zIndex: '1',
  maxWidth: '70%',
  fontSize: '$48',
  fontFamily: '$ArtifaktLegendBold',
  lineHeight: '48px',

  '@bp2': {
    width: '100%',
    fontSize: '90px',
    lineHeight: '96px',
  },
});

export const ButtonHeaderSection = styled(Button, {
  display: 'none',

  '@bp1': {
    display: 'inline-flex',
    margin: '0 0 $16 0',
  },
});

export const ButtonStyled = styled(Button, {
  width: '100%',
  display: 'inline-flex',
  margin: '$16 0 0 0',

  '@bp1': {
    display: 'none',
  },
});

export const StyledThumbnailSlider = styled(ThumbnailSlider, {
  margin: '$40 0 0 0',

  '@bp3': {
    margin: '$64 0 0 0',
  },
});
