import { styled } from '@root/stitches.config';

export const TableRowPerPageWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  svg: {
    width: '24px',
    height: '24px',
  },
});

export const TableRowPerPageDropdown = styled('div', {
  position: 'relative',
});

export const TableRowPerPageDropdownCurrentValue = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '$8',
  fontFamily: '$ArtifaktElementRegular',
  color: '$mediumGrey',
  fontSize: '$14',
  cursor: 'pointer',
});

export const TableRowPerPageDropdownContainer = styled('div', {
  position: 'absolute',
  width: '100%',

  outline: '1px solid $lightGrey',
  borderRadius: '$4',
  backgroundColor: '$box',
});

export const TableRowPerPageLabel = styled('div', {
  fontFamily: '$ArtifaktElementRegular',
  color: '$mediumGrey',
  fontSize: '$14',
});

export const TableRowPerPageDropdownValue = styled('div', {
  fontFamily: '$ArtifaktElementRegular',
  color: '$mediumGrey',
  fontSize: '$14',
  cursor: 'pointer',
  padding: '0 $8',
  '&:hover': {
    backgroundColor: '$lightGrey',
  },
});
