import { ProfileContentPanel, ProfileContentStyled } from './Profile.styled';
import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import CreateWarning from '../create/sections/CreateWarning';
import Modal from '@components/atoms/modal/Modal';
import ProfileCompletionContext from '@context/ProfileCompletionContext';
import ProfileMessage from '@components/molecules/profileMessage/ProfileMessage';
import Resume from './sections/Resume';
import { TabNav } from '@components/molecules/tabNav/TabNav';
import { navigate } from 'gatsby-link';
import useModal from '@components/atoms/modal/useModal';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_CREATE_TC_ACCEPTED } from '@src/apollo/mutation';
import ProfileSection from './sections/ProfileSection';

const ProfileContent = props => {
  const { authContext, hasData } = props;
  const [profileContentTypes, setProfileContentTypes] = useState([]);
  const { isShowing, toggle } = useModal();
  const [modalType, setModalType] = useState();

  const [profileCompletionState, setProfileCompletionState] = useContext(
    ProfileCompletionContext
  );

  const [setCreateTCAccepted, createTCAcceptedData] = useMutation(UPDATE_USER_CREATE_TC_ACCEPTED)

  const isCurrentUserProfile = !!(
    authContext.isAuthenticated && authContext.userSlug === props.context.slug
  );

  const isSuperMember =
    authContext.generalRole === 'admin' ||
    authContext.generalRole === 'editor' ||
    authContext.generalRole === 'contributor';

  const profileContents = useQuery(
    gql`
      query checkProfileContents($id: Int) {
        userContents(creator_id: $id) {
          data {
            userContentType
          }
        }
      }
    `,
    {
      variables: {
        id: props.profileData.id,
      },
      context: {
        clientName: 'public',
      },
    }
  );

  useEffect(() => {
    // set profile contents state array
    if (profileContents.data?.userContents) {
      setProfileContentTypes([
        ...new Set(
          profileContents.data.userContents.data.map(el =>
            el.userContentType === 'tutorial_series'
              ? 'tutorials'
              : `${el.userContentType}s`
          )
        ),
      ]);
    }
  }, [profileContents.data]);

  const saveChanges = () => {
    refetch();
  };

  const getCurrentSection = () => {
    // check if pageSection from props is included in the fetched content types
    if (
      profileContentTypes.includes(props.context.pageContext.pageSection) ||
      isCurrentUserProfile
    ) {
      switch (props.context.pageContext.pageSection) {
        case 'resume':
          return 'resume';
        case 'artworks':
          return 'artworks';
        case 'tutorials':
          return 'tutorials';
        case 'assets':
          return 'assets';
        case 'articles':
          return 'articles';
        default:
          return profileContentTypes.includes(
            props.profileData.userProfile.navigation_tab
          )
            ? props.profileData.userProfile.navigation_tab
            : 'resume';
      }
    } else {
      return profileContentTypes.includes(
        props.profileData.userProfile.navigation_tab
      )
        ? props.profileData.userProfile.navigation_tab
        : 'resume';
    }
  };

  const dynamicNavItems = [
    {
      label: 'Artworks',
      alt: 'Artworks',
      sectionName: 'artworks',
      url: `/m/${props.context.slug}/artworks`,
    },
    {
      label: 'Tutorials',
      alt: 'Tutorials',
      sectionName: 'tutorials',
      url: `/m/${props.context.slug}/tutorials`,
    },
    {
      label: 'Articles',
      alt: 'Articles',
      sectionName: 'articles',
      url: `/m/${props.context.slug}/articles`,
    },
    {
      label: 'Assets',
      alt: 'Assets',
      sectionName: 'assets',
      url: `/m/${props.context.slug}/assets`,
    },
  ];

  const [profileContentState, setProfileContentState] = useState({
    navItems: [
      {
        label: 'Resume',
        alt: 'Resume',
        sectionName: 'resume',
        url: `/m/${props.context.slug}/resume`,
      },
    ],
  });

  const changeCurrentSection = section => {
    setProfileContentState({
      ...profileContentState,
      currentSection: section,
    });
  };

  // define navItems
  useEffect(() => {
    let newNavItems = [];
    if (
      profileContentTypes.length >= 1 &&
      profileContentState.navItems.length <= 1
    ) {
      if (profileContentTypes.length >= 1 && !isCurrentUserProfile) {
        // if not logged user profile
        // keep all types from default nav except ones that aren't in the fetched types
        newNavItems = dynamicNavItems.filter(el =>
          profileContentTypes.includes(el.sectionName)
        );
        // if logged user profile
        // keep all types from default except if user don't have assets
      } else if (
        isCurrentUserProfile &&
        !profileContentTypes.includes('assets') &&
        !isSuperMember
      ) {
        newNavItems = dynamicNavItems.filter(el => el.sectionName !== 'assets');
      } else if (
        (isCurrentUserProfile && profileContentTypes.includes('assets')) ||
        (isSuperMember && isCurrentUserProfile)
      ) {
        newNavItems = dynamicNavItems;
      }
      if (newNavItems.length >= 1 || isCurrentUserProfile) {
        setProfileContentState({
          ...profileContentState,
          navItems: [...newNavItems, ...profileContentState.navItems],
          currentSection: getCurrentSection(),
        });
      } else {
        setProfileContentState({
          ...profileContentState,
          currentSection: getCurrentSection(),
        });
      }
    }
  }, [profileContentTypes]);

  useEffect(() => {
    if (profileContentState.currentSection) {
      window.history.replaceState(
        null,
        null,
        `/m/${props.context.slug}/${profileContentState.currentSection}`
      );
    }
  }, [profileContentState.currentSection]);

  useEffect(() => {
    if (profileCompletionState.profile_completion >= 70) {
      if (!authContext.isOnboarded) {
        localStorage.setItem('isOnboarded', 1);
      }
    }
  }, [profileCompletionState.profile_completion]);

  useEffect(() => {
    if (hasData) {
      setProfileContentState({
        ...profileContentState,
        currentSection: getCurrentSection(),
      });
    }
  }, [hasData]);

  return (
    <>
      <ProfileContentStyled>
        <Modal
          isShowing={isShowing}
          hide={() => {
            toggle();
            setModalType();
          }}
        >
          {isShowing && (
            <CreateWarning
              gotoNextSection={() => {
                authContext.updateCreateTermsAndConditionsAccepted(true);
                setCreateTCAccepted();
                navigate(`/create/build/${modalType}`);
              }}
              type={modalType}
            />
          )}
        </Modal>
        {isCurrentUserProfile &&
          profileCompletionState.profile_completion < 70 &&
          !props.isPublic && <ProfileMessage data={props.profileData} />}

        {!profileContents.loading && profileContents.called && (
          <>
            <TabNav
              changeCurrentSection={changeCurrentSection}
              currentSection={profileContentState.currentSection}
              tabsItems={profileContentState.navItems}
            />
            <ProfileContentPanel>
              {profileContentState.currentSection === 'resume' && (
                <Resume
                  isCurrentUserProfile={props.isCurrentUserProfile}
                  profile_completion={props.profileData.profile_completion}
                  updateProfileSave={props.updateProfileSave}
                  userData={{
                    firstName: props.profileData.first_name,
                    lastName: props.profileData.last_name,
                  }}
                  user={props.context.slug}
                  save={saveChanges}
                />
              )}
              {profileContentState.currentSection === 'artworks' && (
                <ProfileSection
                  type={["artwork"]}
                  isCurrentUserProfile={isCurrentUserProfile && !props.isPublic}
                  userId={props.profileData.id}
                  isAuthenticated={authContext.isAuthenticated}
                  isSuperMember={isSuperMember}
                  onCreateButtonClick={() => {
                    if(!authContext.createTermsAndConditionsAccepted){
                      toggle();
                      setModalType('artwork');
                    } else {
                      navigate("/create/build/artwork");
                    }
                  }}
                />
              )}
              {profileContentState.currentSection === 'tutorials' && (
                <ProfileSection
                  type={["tutorial", "tutorial_series"]}
                  isCurrentUserProfile={isCurrentUserProfile && !props.isPublic}
                  userId={props.profileData.id}
                  isAuthenticated={authContext.isAuthenticated}
                  isSuperMember={isSuperMember}
                  onCreateButtonClick={() => {
                    if(!authContext.createTermsAndConditionsAccepted){
                      toggle();
                      setModalType('tutorial');
                    } else {
                      navigate("/create/build/tutorial");
                    }
                  }}
                />
              )}
              {profileContentState.currentSection === 'assets' && (
                <ProfileSection
                  type={["asset"]}
                  isCurrentUserProfile={isCurrentUserProfile && !props.isPublic}
                  userId={props.profileData.id}
                  isAuthenticated={authContext.isAuthenticated}
                  isSuperMember={isSuperMember}
                  onCreateButtonClick={() => {
                    if(!authContext.createTermsAndConditionsAccepted){
                      toggle();
                      setModalType('asset');
                    } else {
                      navigate("/create/build/asset");
                    }
                  }}
                />
              )}
              {profileContentState.currentSection === 'articles' && (
                <ProfileSection
                  type={["article"]}
                  isCurrentUserProfile={isCurrentUserProfile && !props.isPublic}
                  userId={props.profileData.id}
                  isAuthenticated={authContext.isAuthenticated}
                  isSuperMember={isSuperMember}
                  onCreateButtonClick={() => {
                    if(!authContext.createTermsAndConditionsAccepted){
                      toggle();
                      setModalType('article');
                    } else {
                      navigate("/create/build/article");
                    }
                  }}
                />
              )}
            </ProfileContentPanel>
          </>
        )}
      </ProfileContentStyled>
    </>
  );
};

export default ProfileContent;
