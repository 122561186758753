import React, { useState } from 'react';

import { ImageCircleWrapper } from './ImageCircle.styled';

const ImageCircle = props => {
  return (
    <ImageCircleWrapper>
      <img src={props.src} />
    </ImageCircleWrapper>
  );
};

export default ImageCircle;
