import {
  AddContentWrapper,
  BlockWrapper,
  ControlsWrapper,
  ReorderWrapper,
  ThumbnailControlsWrapper,
  Wrapper,
} from './UserContentBlock.styled';
import React, { useContext, useEffect, useRef, useState } from 'react';

import Button from '@components/atoms/button/Button';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import SelectUserContent from '@components/molecules/selectUserContent/SelectUserContent';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import { formatDate } from '@src/utils/formatDate';
import { monthsNames } from '@src/utils/monthNames';
import { debounce } from 'throttle-debounce';
import { onChangeBlockChangeOrder } from '@src/templates/create/utils';
import { useIsMobile } from '@src/utils/useIsMobile';
import useModal from '@components/atoms/modal/useModal';
import { validURL } from '@src/utils/validURL';
import CharsCount from '@components/atoms/charsCount/CharsCount';
import { InputText } from '@components/atoms/input/inputText/InputText';

const MAX_USER_CONTENTS = 4;
const captionMaxLength = 200;

const debouncefunc = debounce(1000, false, (func, blockIndex, value) => {
  func(blockIndex, value);
});

function UserContentBlock(props) {
  const {
    userContentTypes,
    onEditBlock,
    blockIndex,
    forceContentType,
    block,
  } = props;

  const isMobile = useIsMobile();

  const { isShowing, toggle } = useModal();

  const [selectedUserContents, setSelectedUserContents] = useState(
    block?.contents || []
  );
  const [captionState, setCaptionState] = useState(
    block.text ? block.text : ''
  );
  const onTitleChange = e => {
    if (e.target.value.length < captionMaxLength) {
      setCaptionState(e.target.value);
      debouncefunc(onEditBlock, blockIndex, {
        text: e.target.value,
        value: selectedUserContents,
      });
    } else {
      setCaptionState(e.target.value.slice(0, captionMaxLength));
      debouncefunc(onEditBlock, blockIndex, {
        text: e.target.value.slice(0, captionMaxLength),
        value: selectedUserContents,
      });
    }
  };

  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  function onSelectUserContent(els) {
    const newSelectedContents = els.map !== undefined ? [...els] : [{ ...els }];
    const newSelectedContentsWithOrder = newSelectedContents.map((el, i) => ({
      ...el,
      order: selectedUserContents.length + i,
    }));
    const updatedSelectContentState = [
      ...selectedUserContents,
      ...newSelectedContentsWithOrder,
    ];

    const withoutDuplicates = updatedSelectContentState.filter(
      (content, index, self) =>
        index === self.findIndex(c => c.id === content.id)
    );

    if (updatedSelectContentState.length <= MAX_USER_CONTENTS) {
      setSelectedUserContents(updatedSelectContentState);
      onEditBlock(blockIndex, {
        value: updatedSelectContentState,
        text: captionState,
      });
    }
    if (withoutDuplicates.length !== updatedSelectContentState.length) {
      setSelectedUserContents(withoutDuplicates);
      handleUpdateClientNotification({
        type: 'info',
        text: 'create_user_content_removed_duplicates',
        autoDismiss: true,
        id: `notification-${new Date().getTime()}`,
      });
      onEditBlock(blockIndex, { value: withoutDuplicates, text: captionState });
    }
    if (updatedSelectContentState.length > MAX_USER_CONTENTS) {
      setSelectedUserContents({
        value: updatedSelectContentState.slice(0, MAX_USER_CONTENTS),
        text: captionState,
      });
      handleUpdateClientNotification({
        type: 'error',
        text: 'create_user_content_block_max_contents',
        autoDismiss: true,
        id: `notification-${new Date().getTime()}`,
      });
      onEditBlock(blockIndex, {
        value: updatedSelectContentState.slice(0, MAX_USER_CONTENTS),
        text: captionState,
      });
    }
    toggle();
  }

  function onReorder(index, direction) {
    const updatedOrder = onChangeBlockChangeOrder(
      index,
      direction,
      selectedUserContents
    );
    setSelectedUserContents(updatedOrder);
    onEditBlock(blockIndex, { value: updatedOrder, text: captionState });
  }

  function onDelete(id) {
    const updatedState = selectedUserContents.filter(el => el.id !== id);
    setSelectedUserContents(updatedState);
    onEditBlock(blockIndex, { value: updatedState, text: captionState });
  }

  const wrapperRef = useRef();

  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        <InputText
          value={captionState}
          onChange={onTitleChange}
          placeholder="Add a title...(optional)"
          css={{
            input: {
              background: 'transparent',
              border: 'none',
              outline: 'none',
              fontSize: '$32',
              fontFamily: '$ArtifaktLegendBold',
              textAlign: 'center',
              padding: '0',
            },
          }}
        />
        <CharsCount
          stringToCount={captionState}
          maxLength={captionMaxLength}
          isQuill={false}
          css={{ textAlign: 'center' }}
        />
      </div>
      {selectedUserContents.length >= 1 && (
        <BlockWrapper ref={wrapperRef}>
          {selectedUserContents
            .sort((a, b) => a.order - b.order)
            .map((userContent, i) => {
              const isMemberOf =
                userContent.userContentType === 'article' &&
                userContent.article_type?.key !== 'article';
              function dateString() {
                if (isMemberOf) {
                  return userContent.motm_year;
                }
                return formatDate(userContent.createdAt);
              }
              const profilePicture =
                userContent.creator?.userProfile.profile_picture;
              const hasProfilePicture =
                profilePicture && profilePicture !== null;
              const validProfilePicture = () => {
                if (hasProfilePicture) {
                  if (validURL(profilePicture)) {
                    return profilePicture;
                  }
                  return `${CDN_URL}${profilePicture}`;
                }
                return null;
              };
              return (
                <ThumbnailControlsWrapper key={userContent.id}>
                  <ControlsWrapper className="controls-wrapper">
                    <ReorderWrapper>
                      {i >= 1 && (
                        <Button
                          type="circle"
                          name="prev"
                          actionType="onclick"
                          onClick={() => {
                            onReorder(i, 'up');
                          }}
                          className="prev"
                          svgIcon="arrow-left-chevron"
                          colorTheme="grey"
                        />
                      )}
                      {i < selectedUserContents.length - 1 && (
                        <Button
                          type="circle"
                          name="next"
                          className="next"
                          actionType="onclick"
                          onClick={() => {
                            onReorder(i, 'down');
                          }}
                          svgIcon="arrow-right-chevron"
                          colorTheme="grey"
                        />
                      )}
                    </ReorderWrapper>
                    <Button
                      type="circle"
                      name="next"
                      actionType="onclick"
                      onClick={() => onDelete(userContent.id)}
                      svgIcon="trash"
                    />
                  </ControlsWrapper>
                  <Thumbnail
                    imageUrl={
                      validURL(userContent.image.path)
                        ? userContent.image.path
                        : `${CDN_URL}${userContent.image.path}`
                    }
                    srcSet={userContent.image.sizes}
                    type={
                      isMemberOf ? 'ambassador' : userContent.userContentType
                    }
                    ambassadorTitle={userContent.motm_member?.title}
                    datePosted={dateString()}
                    ambassadorType={isMemberOf && userContent.article_type?.key}
                    urlTarget="_blank"
                    authoring={{
                      profilePicture: validProfilePicture(),
                      author: {
                        name: `${userContent.creator?.first_name} ${userContent.creator?.last_name}`,
                      },
                    }}
                    badgeList={userContent?.tools?.map(tool => ({
                      imageDynamic: {
                        src: tool.icon,
                        srcset: `${tool.icon} 1x, ${tool.icon} 250w, ${tool.icon} 500w`,
                      },
                      alt: tool.title,
                    }))}
                    title={userContent.title}
                    tutorialLevel={userContent?.tutorial_level?.name.toLowerCase()}
                    isMini={window.innerWidth >= 1200 && !isMobile}
                  />
                </ThumbnailControlsWrapper>
              );
            })}
        </BlockWrapper>
      )}
      <Modal isShowing={isShowing} hide={toggle} isSelectUserContent>
        <SelectUserContent
          onRowActionClick={onSelectUserContent}
          status="publish"
          isGroupSettings
          excludedIds={[props.postId]}
          showSearchbar
          contentType={userContentTypes}
          forceContentType={forceContentType}
          isNotMultiSelect={selectedUserContents.length === MAX_USER_CONTENTS}
          bulkActions={[
            {
              label: (
                <String id="create_user_content_block_add_multiple_contents" />
              ),
              onClickAction: e => {
                onSelectUserContent(e.map(el => el.saveBtn.props));
              },
            },
          ]}
        />
      </Modal>
      {selectedUserContents.length < MAX_USER_CONTENTS && (
        <AddContentWrapper
          css={selectedUserContents.length < 1 ? { mt: '$16' } : {}}
        >
          <Button
            type="circle"
            actionType="onclick"
            svgIcon="plus"
            onClick={toggle}
            css={selectedUserContents.length >= 1 ? { ml: '$24' } : {}}
          />
          <Text size="14" as="p">
            <String id="create_user_content_block_add_block_label" />
          </Text>
        </AddContentWrapper>
      )}
    </Wrapper>
  );
}

UserContentBlock.propTypes = {
  userContentTypes: PropTypes.arrayOf(
    PropTypes.oneOf(['artwork', 'article', 'tutorial', 'tutorial_series'])
  ),
  onEditBlock: PropTypes.func,
  blockIndex: PropTypes.func.isRequired,
  forceContentType: PropTypes.bool,
};

UserContentBlock.defaultProps = {
  test: 'test',
  userContentTypes: ['artwork', 'article', 'tutorial'],
  onEditBlock: () => {},
  forceContentType: false,
};

export default UserContentBlock;
