import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import { Heading } from '@components/atoms/heading/Heading';
import LogInBox from '@components/organisms/logInBox/LogInBox';
import Logo from '@src/assets/svg/area_logo_full.svg';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import { VerifyEmailWrapper } from './VerifyEmail.styled';
import queryString from 'query-string';
import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const AreaLogo = styled(Logo, {
  mt: '$24',
  height: '64px',
  width: '125px',
  fill: '#000',
});

const LinkStyled = styled(Button, {
  textDecoration: 'underline',
  textTransform: "unset",
  transition: 'opacity .4s ease',
  padding: '0 !important',
  minHeight: 'unset !important',
  margin: '0 !important',
  '&:hover': {
    opacity: 0.6,
    backgroundColor: '$transparent !important'
  },
});

const VerifyEmailForm = props => {
  const authContext = useContext(AuthContext);
  const EmailStatus = {
    Verified: 'Verified',
    Verifying: 'Verifying',
    Failed: 'Failed',
  };

  const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  };

  const [resendEmailState, setResendEmailState] = useState({
    value: undefined,
    isError: false,
  });

  const handleResendEmail = () => {
    const data = {
      email: getQueryVariable('email'),
      successCallback: () => setResendEmailState({
        value: 'verify_email_resend_token_success',
        isError: false,
      }),
      errorCallback: () => setResendEmailState({
        value: 'verify_email_resend_token_error',
        isError: true,
      }),
    };
    authContext.sendVerificationEmail(data)
  }

  const [
    onboardingCompletionState,
    setOnboardingCompletionContext,
  ] = useContext(OnboardingCompletionContext);

  const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);
  const UrlContext = useContext(UrlsContext);

  useEffect(() => {
    const { token } = queryString.parse(location.search);
    verifyEmailToken(token);
  }, []);

  const verifyEmailToken = token => {
    var data = {
      token: token,
    };

    let resStatus;
    let newArr = [...onboardingCompletionState];
    fetch(`${API_URL}/verify-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 201:
            setEmailStatus(EmailStatus.Verified);
            onboardingCompletionState[1].isCompleted = true;
            setOnboardingCompletionContext(newArr);
            break;

          case 401:
            setEmailStatus(EmailStatus.Failed);
            onboardingCompletionState[1].isCompleted = false;
            setOnboardingCompletionContext(newArr);
            break;

          default:
            break;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    navigate(`/m/${authContext.userSlug}`, { replace: true });
  } else if (authContext.isAuthenticated && !authContext.isVerified) {
    navigate(GetUrl('onboardingSignupValidation', UrlContext));
  } else if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    !authContext.isOnboarded
  ) {
    navigate(GetUrl('onboardingAvatarAndBio', UrlContext));
  }

  const onResendEmailVerification = email => {
    setLoginState({
      email,
      emailConfirmationSent: true,
    });
  };

  function getBody() {
    switch (emailStatus) {
      case EmailStatus.Verified:
        return (
          <VerifyEmailWrapper>
            <Text
              css={{
                marginBottom: '$4',
              }}
            >
              {/* <String id="verify_email_text" />{' '} */}
              🎉 Good Job, your email has been validated!
            </Text>
            <Text
              css={{
                marginBottom: '$32',
              }}
            >
              You can now Sign in to AREA
            </Text>
            <LogInBox isVerifyPage={true} />
          </VerifyEmailWrapper>
        );
      case EmailStatus.Verifying:
        return (
          <Heading
            level="h2"
            css={{
              marginBottom: '$8',
              fontSize: '$24',
              lineHeight: '1.2em',
              '@bp2': {
                fontSize: '$42',
              },
            }}
          >
            <String id="verify_email_verifying" />
          </Heading>
        );
      case EmailStatus.Failed:
        return (
          <>
            <Heading
              level="h2"
              css={{
                marginBottom: '$8',
                fontSize: '$24',
                lineHeight: '1.2em',
                '@bp2': {
                  fontSize: '$42',
                },
              }}
            >
              <String id="verify_email_failed_text" />
            </Heading>
            <Text size="14" css={{ lineHeight: '2.5em', display: 'flex' }}>
              <String id="verify_email_failed_text" />{' '}
              <LinkStyled
                colorTheme='transparent'
                onClick={handleResendEmail}
                actionType="onclick"
                css={{marginLeft: '4px !important'}}
              >
                <String id="verify_email_send_token" />
              </LinkStyled>
            </Text>
            {resendEmailState.value !== undefined && (
              <Text size="14" css={{color: resendEmailState.isError ? '$red' : '$green'}}>
                <String id={resendEmailState.value} />
              </Text>
            )}
          </>
        );
    }
  }

  return <div>{getBody()}</div>;
};

export default VerifyEmailForm;
