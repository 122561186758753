import 'react-loading-skeleton/dist/skeleton.css';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import React from 'react';
import { SkeletonWrapper } from './LoaderSkeleton.styled.js';

const LoaderSkeleton = ({
  height,
  width,
  isRadius,
  isCircle,
  isFeatured,
  isFeaturedReverse,
  count,
  css,
}) => {
  return (
    <SkeletonWrapper
      css={css}
      isFeatured={isFeatured}
      isFeaturedReverse={isFeaturedReverse}
    >
      <SkeletonTheme
        baseColor="var(--colors-elements)"
        highlightColor="var(--colors-lightGrey)"
        borderRadius={isRadius ? '18px' : false}
      >
        <Skeleton
          height={height}
          width={width}
          count={count}
          circle={isCircle}
        />
      </SkeletonTheme>
    </SkeletonWrapper>
  );
};

export default LoaderSkeleton;
