import {
  BlockWrapper,
  CreateBlocks,
  CreateDetailWrapper,
  CreateHeader,
  CreateHeaderBottom,
  CreateHeaderBottomLeft,
  CreateHeaderBottomRight,
  CreateHeaderMemberOf,
  CreateRelated,
  CreateRelatedLoadMore,
  CreateRelatedThumbnails,
  DateWrapper,
  HeadingTitle,
  ImageWrapper,
  MemberOfFooter,
  SkeletonLoaderAuthorWrapper,
  SkeletonLoaderTitleWrapper,
  StyledLinks,
  UserContentDetails,
} from './CreateDetail.styled';
import {
  DELETE_USERCONTENT,
  USERCONTENT_FAVORITE,
  USERCONTENT_REACTION,
} from '@src/apollo/mutation';
import {
  GET_CREATE_DETAIL,
  GET_RELATED_CONTENT_CREATE_DETAIL,
} from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Avatar from '@components/atoms/avatar/Avatar';
import AuthContext from '@src/context/AuthContext';
import BlockAsset from './blocks/BlockAsset';
import BlockImage from './blocks/BlockImage';
import BlockText from './blocks/BlockText';
import BlockUserContent from './blocks/BlockUserContent';
import BlockVideo from './blocks/BlockVideo';
import Button from '@components/atoms/button/Button';
import CreateFooter from '@components/molecules/createFooter/CreateFooter';
import CreateHeaderBar from '@components/molecules/createHeaderBar/CreateHeaderBar';
import CreateModal from '@components/molecules/createModal/CreateModal';
import DeleteModal from '@components/molecules/deleteModal/DeleteModal';
import { Heading } from '@components/atoms/heading/Heading';
import Image from '@components/atoms/image/Image';
import Layout from '@components/organisms/layout/Layout';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import Modal from '@components/atoms/modal/Modal';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import ThumbnailSlider from '@components/organisms/thumbnailSlider/ThumbnailSlider';
import { copyToClipboard } from '../create/utils';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { formatDate } from '@src/utils/formatDate';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import Loader from '@components/atoms/loader/Loader';
import { navigate } from 'gatsby-link';
import { useIsMobile } from '@src/utils/useIsMobile';
import useModal from '@components/atoms/modal/useModal';
import Breadcrumb from '@components/molecules/breadcrumb/Breadcrumb';
import SketchfabBlock from '../create/editorBlock/sketchfabBlock/SketchfabBlock';
import { Gallery } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

const CreateDetail = context => {
  const [relatedContentState, setRelatedContentState] = useState({
    page: 1,
    limit: 8,
    thumbnailList: [],
    loaded: false,
    hasMore: false,
  });

  const getAvatar = (profilePicture, size) => {
    return getAvatarImage(profilePicture, size);
  };

  function contentStatus() {
    const { pathname } = context.location;
    if (pathname.includes('publish')) {
      return 'publish';
    }
    if (pathname.includes('pending')) {
      return 'pending';
    }
    if (pathname.includes('draft')) {
      return 'draft';
    }
    return undefined;
  }

  const onLoadMore = () => {
    setRelatedContentState({
      ...relatedContentState,
      page: relatedContentState.page + 1,
    });
    dataRelatedContent.refetch();
  };

  const authContext = useContext(AuthContext);

  const { loading, error, data } = useQuery(GET_CREATE_DETAIL, {
    variables: {
      slug: context.slug,
      author_username: context.username,
      status: contentStatus(),
    },
    context: { clientName: contentStatus() !== 'draft' ? 'public' : null },
    fetchPolicy: 'network-only',
  });

  const isMemberOf =
    data?.userContent?.article_type?.key === 'motm' ||
    data?.userContent?.article_type?.key === 'moty';

  useEffect(() => {
    if (data?.userContent === null) {
      navigate('/404');
    }
  }, [data]);

  function getSeoSlug() {
    if (data?.userContent?.userContentType && !isMemberOf) {
      return `create-${data?.userContent?.userContentType}`;
    } else if (data?.userContent?.article_type.key === 'motm') {
      return `create-member-spotlight`;
    } else if (data?.userContent?.article_type.key === 'moty') {
      return `create-member-of-the-year`;
    }
  }

  const dataRelatedContent = useQuery(GET_RELATED_CONTENT_CREATE_DETAIL, {
    variables: {
      usercontent_slug: context.slug,
      id: data?.userContent?.id,
      page: relatedContentState.page,
      limit: relatedContentState.limit,
    },
    context: { clientName: 'public' },
  });

  useEffect(() => {
    if (dataRelatedContent?.data?.relatedUserContents) {
      setRelatedContentState({
        ...relatedContentState,
        thumbnailList: [
          ...relatedContentState.thumbnailList,
          ...dataRelatedContent?.data?.relatedUserContents?.data,
        ].filter(
          (item, index, self) => index === self.findIndex(t => t.id === item.id)
        ),
        loaded: true,
      });
    }
  }, [dataRelatedContent]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const now = new Date().valueOf();
  const postDate = parseInt(data?.userContent?.publishedAt, 10) * 1000;

  const howOldPostMins = (now - postDate) / 60000;

  const isEditableByClient =
    data?.userContent?.creator?.username === authContext.userSlug;

  const [modalData, setModalData] = useState();

  useEffect(() => {
    if (isEditableByClient) {
      if (contentStatus() === 'publish' && howOldPostMins < 2) {
        setModalData({
          isShowing: true,
          hide: () => {
            setModalData({ isShowing: false });
          },
          modalType: 'publish',
          createType: data.userContent?.userContentType,
          image: `${FE_URL}${data.userContent?.image}`,
          actions: [
            {
              label: (
                <String
                  id={`create_publish_modal_link_${
                    isLinkCopied ? 'copied' : 'not_copied'
                  }`}
                />
              ),
              onClick: () => {
                setIsLinkCopied(true);
                if (!isLinkCopied) {
                  copyToClipboard(
                    `${FE_URL}${context.location.pathname}`.replace(
                      `/${contentStatus()}`,
                      ''
                    )
                  );
                }
              },
              colorTheme: isLinkCopied ? 'normal' : 'gray',
              svgIcon: isLinkCopied ? 'check' : 'copy',
            },
          ],
        });
      }
      if (contentStatus() && contentStatus() !== 'publish') {
        setModalData({
          isShowing: true,
          hide: () => {
            setModalData({ isShowing: false });
          },
          modalType: contentStatus(),
          createType: data.userContent?.userContentType,
        });
      }
    }
  }, [postDate, data, isLinkCopied]);

  const [deleteUserContent, dataDeleteUserContent] = useMutation(
    DELETE_USERCONTENT
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  function deleteContent(id) {
    if (isEditableByClient || authContext.generalRole === 'admin') {
      deleteUserContent({
        variables: {
          ids: [id],
        },
      });
    }
  }

  const isArtwork = data?.userContent?.userContentType === 'artwork';
  const isTutorial = data?.userContent?.userContentType === 'tutorial';
  const isArticle = data?.userContent?.userContentType === 'article';
  const isAsset = data?.userContent?.userContentType === 'asset';

  const sortedBlocks = data?.userContent?.blocs
    .slice()
    .sort((a, b) => (a.order > b.order ? 1 : -1));

  if (dataDeleteUserContent?.data?.deleteUserContent) {
    navigate(`/m/${authContext.userSlug}`);
  }

  const isMobile = useIsMobile();

  // TODO: remove extra s and 'tutorial_series' condition once backend has changed
  let editUrl = '';
  const username = data?.userContent?.creator.username;
  const contentType = `${data?.userContent?.userContentType}${
    data?.userContent?.userContentType !== 'tutorial_series' ? 's' : ''
  }`;
  const slug = data?.userContent?.slug;

  const avatarSrc = getAvatarImage(
    data?.userContent?.creator?.userProfile.profile_picture,
    'medium'
  );

  if (data?.userContent?.hasDraft) {
    editUrl = `/m/${username}/${contentType}/${slug}-draft/draft/edit`;
  } else {
    editUrl = `/m/${username}/${contentType}/${slug}${
      contentStatus() && contentStatus() !== 'publish'
        ? `/${contentStatus()}`
        : ''
    }/edit`;
  }

  const [statsState, setStatsState] = useState({
    views: 0,
    favoriteCount: 0,
  });

  const [isFavoritedState, setIsFavoritedState] = useState(false);
  useEffect(() => {
    data
      ? (setIsFavoritedState(data.userContent?.isFavorited),
        setStatsState({
          views: data.userContent?.views,
          favoriteCount: data.userContent?.usersFavoritedCount,
        }))
      : null;
  }, [data]);

  const [toggleFavoriteStatus, favoriteStatusData] = useMutation(
    USERCONTENT_FAVORITE
  );
  const onToggleFavoriteStatus = () => {
    authContext.isAuthenticated
      ? authContext.isVerified && authContext.isOnboarded
        ? (setIsFavoritedState(!isFavoritedState),
          setStatsState({
            ...statsState,
            favoriteCount: isFavoritedState
              ? statsState.favoriteCount - 1
              : statsState.favoriteCount + 1,
          }),
          toggleFavoriteStatus({
            variables: {
              usercontentId: data.userContent?.id,
              input: {
                action: !isFavoritedState ? 'add' : 'remove',
              },
            },
          }))
        : toggleJoin()
      : toggleJoin();
  };

  const [isLikedState, setIsLikedState] = useState(false);
  useEffect(() => {
    data
      ? (setIsLikedState(data.userContent?.isLiked),
        setStatsState({
          views: data.userContent?.views,
          favoriteCount: data.userContent?.usersFavoritedCount,
        }))
      : null;
  }, [data]);

  const [toggleLikeStatus, likeStatusData] = useMutation(USERCONTENT_REACTION);
  const onToggleLikeStatus = () => {
    authContext.isAuthenticated
      ? authContext.isVerified &&
        JSON.parse(localStorage.getItem('isOnboarded')) === 1
        ? (setIsLikedState(!isLikedState),
          toggleLikeStatus({
            variables: {
              userContentId: data.userContent?.id,
              input: {
                reaction: 'like',
                action: !isLikedState ? 'add' : 'remove',
              },
            },
          }))
        : toggleJoin()
      : toggleJoin();
  };

  const onToggleReport = () => {
    authContext.isAuthenticated
      ? authContext.isVerified &&
        JSON.parse(localStorage.getItem('isOnboarded')) === 1
        ? (setIsLikedState(!isLikedState),
          toggleLikeStatus({
            variables: {
              userContentId: data.userContent?.id,
              input: {
                reaction: 'like',
                action: !isLikedState ? 'add' : 'remove',
              },
            },
          }))
        : toggleJoin()
      : toggleJoin();
  };

  //Content not found
  useEffect(() => {
    if (!loading && data?.userContent === null) {
      navigate('/404');
    }
  }, [data]);

  const { isShowing: isShowingJoin, toggle: toggleJoin } = useModal();

  const motmImageSrc = getAvatarImage(
    data?.userContent?.motm_member?.userProfile?.profile_picture,
    'medium'
  );
  return (
    <Layout location={context.location} style={{ overflow: 'hidden' }}>
      <SEO
        title={`${
          data?.userContent?.title ? `${data?.userContent?.title}` : ''
        }`}
        slug={getSeoSlug()}
        description={
          data?.userContent?.blocs?.filter(el => el.type === 'text')[0]?.text ||
          'AREA Member content'
        }
        image={`${CDN_URL}${data?.userContent?.image?.path}`}
      />
      <CreateHeaderBar
        onDeleteClick={() => setShowDeleteModal(true)}
        editable={
          isEditableByClient ||
          authContext.generalRole === 'admin' ||
          authContext.generalRole === 'editor'
        }
        isPending={contentStatus() === 'pending'}
        isDraft={contentStatus() === 'draft'}
        editUrl={editUrl}
        userContentId={data?.userContent?.id}
        onReportClick={onToggleReport}
        onFavoriteClick={onToggleFavoriteStatus}
        onLikeClick={onToggleLikeStatus}
        isFavorited={isFavoritedState}
        isLiked={isLikedState}
        isDataSet={!loading}
        href={context.location.href}
        isLogged={authContext.isAuthenticated}
        isVerified={authContext.isVerified}
        isOnboarded={
          authContext.isAuthenticated &&
          JSON.parse(localStorage.getItem('isOnboarded')) === 1
        }
        pathname={location.pathname.replace(slug, '')}
        title={data?.userContent?.title}
      />
      {isMobile && (
        <Breadcrumb
          pathname={location.pathname.replace(slug, '')}
          customLastItem={data?.userContent?.title}
          css={{
            margin: '$16 0',
          }}
        />
      )}
      <CreateDetailWrapper>
        {showDeleteModal && (
          <DeleteModal
            isShowing={showDeleteModal}
            hide={() => setShowDeleteModal(false)}
            onConfirmClick={() => deleteContent(data.userContent?.id)}
            isContainedModal
          />
        )}
        {!loading && data?.userContent ? (
          <>
            <CreateHeader isMemberOf={isMemberOf}>
              <HeadingTitle level="h1" weight="legendExtraBold">
                {data.userContent?.title}
              </HeadingTitle>
              {!isMemberOf ? (
                <CreateHeaderBottom>
                  {!data?.userContent?.creator?.is_internal && (
                    <CreateHeaderBottomLeft>
                      <ImageWrapper>
                        <StyledLinks
                          alt={`${data.userContent?.creator.first_name} ${data.userContent?.creator.last_name}`}
                          to={`/m/${data.userContent?.creator.username}`}
                        >
                          <Avatar
                            alt={`${data.userContent?.creator.first_name} ${data.userContent?.creator.last_name}`}
                            src={avatarSrc}
                            size="medium"
                          />
                        </StyledLinks>
                      </ImageWrapper>
                      <StyledLinks
                        alt={`${data.userContent?.creator.first_name} ${data.userContent?.creator.last_name}`}
                        to={`/m/${data.userContent?.creator.username}`}
                      >
                        <Text size="16" weight="bold">
                          {data.userContent?.creator.first_name}{' '}
                          {data.userContent?.creator.last_name}
                        </Text>
                        {!(
                          isMemberOf && data.userContent?.motm_member !== null
                        ) && (
                          <DateWrapper>
                            {data.userContent?.publishedAt && (
                              <Svg
                                icon="calendar"
                                css={{
                                  height: '14px',
                                  width: '$12',
                                  marginRight: '$4',
                                  fill: '$mainTextColor',
                                }}
                              />
                            )}
                            <Text size="12">
                              {formatDate(data.userContent?.publishedAt)}
                            </Text>
                          </DateWrapper>
                        )}
                      </StyledLinks>
                    </CreateHeaderBottomLeft>
                  )}
                  {data?.userContent?.creator?.is_internal && (
                    <DateWrapper css={{ alignSelf: 'flex-end' }}>
                      <Svg
                        icon="calendar"
                        css={{
                          height: '14px',
                          width: '$12',
                          marginRight: '$4',
                          fill: '$mainTextColor',
                        }}
                      />
                      <Text size="12">
                        {formatDate(data.userContent?.publishedAt)}
                      </Text>
                    </DateWrapper>
                  )}
                  <CreateHeaderBottomRight>
                    <UserContentDetails>
                      {isArticle &&
                        data.userContent?.article_type?.key !== 'article' && (
                          <div className="create_detail_detailsWrapper">
                            <Svg
                              icon="favorite"
                              css={{
                                height: '14px',
                                width: '$12',
                                marginRight: '$4',
                                fill: '$mainTextColor',
                              }}
                            />
                            <Text size="12">
                              {data.userContent?.article_type.name}
                            </Text>
                          </div>
                        )}
                    </UserContentDetails>
                    {isTutorial && (
                      <UserContentDetails>
                        <div className="create_detail_detailsWrapper">
                          <Svg
                            icon="clock"
                            css={{
                              height: '14px',
                              width: '14px',
                              marginRight: '$4',
                              fill: '$mainTextColor',
                            }}
                          />
                          <Text size="12">
                            <String id="create_detail_tutorial_duration" />
                            {` ${data.userContent?.tutorial_duration} mins`}
                          </Text>
                        </div>
                        <div className="create_detail_detailsWrapper">
                          <Svg
                            icon="user"
                            css={{
                              height: '14px',
                              width: '14px',
                              marginRight: '$4',
                              fill: '$mainTextColor',
                            }}
                          />
                          <Text size="12">
                            <String
                              id={`thumbnail_tutorial_level_${data.userContent?.tutorial_level.name.toLowerCase()}`}
                            />
                          </Text>
                        </div>
                      </UserContentDetails>
                    )}
                  </CreateHeaderBottomRight>
                </CreateHeaderBottom>
              ) : (
                <CreateHeaderMemberOf>
                  <Text as="p" size="16">
                    <String
                      id={`create_detail_type_${data?.userContent.article_type.key}`}
                    />
                  </Text>

                  {data?.userContent?.article_type?.key === 'moty' &&
                    data?.userContent.motm_year && (
                      <Text
                        as="p"
                        size="16"
                        css={{ fontFamily: '$ArtifaktLegendBold' }}
                      >
                        {data?.userContent.motm_year}
                      </Text>
                    )}
                </CreateHeaderMemberOf>
              )}
            </CreateHeader>
            <CreateBlocks>
              <Gallery shareButton={false} fullscreenButton={false}>
                {modalData?.isShowing && <CreateModal {...modalData} />}
                {(sortedBlocks || []).map((block, i) => {
                  const Block = () => {
                    switch (block.type) {
                      case 'text':
                        return <BlockText block={block} />;
                      case 'image':
                        return (
                          <BlockImage
                            block={block}
                            alt={`${data.userContent?.creator.first_name} ${data.userContent?.creator.last_name}, ${data?.userContent?.userContentType} - ${data?.userContent?.title}`}
                          />
                        );
                      case 'video':
                        return <BlockVideo block={block} />;
                      case 'sketchfab':
                        return <SketchfabBlock block={block} />;
                      case 'asset':
                        return <BlockAsset block={block} />;
                      case 'content':
                        return (
                          <BlockUserContent
                            block={block}
                            isAuthenticated={authContext.isAuthenticated}
                            title={block?.text}
                          />
                        );
                      default:
                        return null;
                    }
                  };
                  return (
                    <BlockWrapper key={block.id}>
                      <Block />
                    </BlockWrapper>
                  );
                })}
              </Gallery>
            </CreateBlocks>
            {isMemberOf && data.userContent?.motm_member !== null && (
              <MemberOfFooter>
                <ImageWrapper>
                  <StyledLinks
                    alt={`${data.userContent?.motm_member?.first_name} ${data.userContent?.motm_member?.last_name}`}
                    to={`/m/${data.userContent?.motm_member?.username}`}
                  >
                    <Avatar
                      alt={`${data.userContent?.motm_member?.first_name} ${data.userContent?.motm_member?.last_name}`}
                      src={motmImageSrc}
                      size="medium"
                    />
                  </StyledLinks>
                </ImageWrapper>
                <StyledLinks
                  alt={`${data.userContent?.motm_member.first_name} ${data.userContent?.motm_member.last_name}`}
                  to={`/m/${data.userContent?.motm_member.username}`}
                >
                  <Text
                    as="p"
                    size="16"
                    css={{ fontFamily: '$ArtifaktLegendBold' }}
                  >
                    {`${data.userContent?.motm_member.first_name} ${data.userContent?.motm_member.last_name}`}
                  </Text>
                </StyledLinks>
                <Text as="p" size="16">
                  {data.userContent?.motm_member.title}
                </Text>
              </MemberOfFooter>
            )}
            <CreateFooter
              industries={data?.userContent?.industries || []}
              tools={data?.userContent?.tools || []}
              assetType={data?.userContent?.asset_type}
              createType={data?.userContent?.userContentType}
            />
            {relatedContentState.thumbnailList.length >= 1 && (
              <CreateRelated>
                <Heading level="h3" css={{ mb: '$40' }}>
                  Keep exploring
                </Heading>
                {relatedContentState.loaded ? (
                  <>
                    {!isMobile ? (
                      <CreateRelatedThumbnails>
                        {relatedContentState.thumbnailList.map(item => {
                          return (
                            <Thumbnail
                              key={item.id}
                              isMini
                              displayType
                              {...dataToThumbnailViewModel(
                                item,
                                false,
                                authContext.isAuthenticated
                              )}
                            />
                          );
                        })}
                      </CreateRelatedThumbnails>
                    ) : (
                      <ThumbnailSlider
                        thumbnailList={relatedContentState.thumbnailList.map(
                          el => ({
                            ...dataToThumbnailViewModel(
                              el,
                              false,
                              authContext.isAuthenticated
                            ),
                          })
                        )}
                        btnUrl="/inspire"
                        type="thumbnail"
                        isAuthenticated={authContext.isAuthenticated}
                      />
                    )}
                  </>
                ) : (
                  <LoaderSpinner />
                )}
                {(dataRelatedContent.loading ||
                  !relatedContentState.loaded) && (
                  <Loader isLoading isSection />
                )}
                {!isMobile &&
                  dataRelatedContent?.data?.relatedUserContents
                    .has_more_pages && (
                    <CreateRelatedLoadMore>
                      <Button
                        actionType="onclick"
                        colorTheme="normal"
                        type="normal"
                        label="Load more"
                        onClick={onLoadMore}
                        isDisabled={dataRelatedContent.loading}
                      />
                    </CreateRelatedLoadMore>
                  )}
              </CreateRelated>
            )}
          </>
        ) : (
          <>
            <CreateHeader>
              <LoaderSkeleton height="64px" width="80%" />
              <CreateHeaderBottom>
                <SkeletonLoaderAuthorWrapper>
                  <LoaderSkeleton height="64px" width="100%" isCircle />
                  <LoaderSkeleton height="64px" width="100%" />
                </SkeletonLoaderAuthorWrapper>
              </CreateHeaderBottom>
            </CreateHeader>
            <CreateBlocks>
              <LoaderSkeleton height="150px" width="100%" />
            </CreateBlocks>
          </>
        )}
        <Modal isShowing={isShowingJoin} hide={toggleJoin} hasImageHeader>
          {(!authContext.isOnboarded || !authContext.isVerified) &&
          authContext.isAuthenticated ? (
            <PublishNotification
              type={!authContext.isOnboarded ? 'onboarding' : 'validation'}
              url={!authContext.isOnboarded ? `/m/${authContext.userSlug}` : ''}
            />
          ) : (
            <PublishNotification
              type="join"
              groupName=""
              // onJoinClick={onJoinClick}
            />
          )}
        </Modal>
      </CreateDetailWrapper>
    </Layout>
  );
};
export default CreateDetail;
