import { useEffect, useRef, useState } from 'react';

function useInfiniteScroll({
  callback,
  threshold = 0,
  hasMore,
  onBottomHit,
  isLoading,
}) {
  const [element, setElement] = useState();
  const [container, setContainer] = useState();

  const callbackRef = useRef();
  callbackRef.current = callback;

  useEffect(() => {
    if (!element) return;
    const topMargin = -(100 + 100 * threshold);
    const bottomMargin = 100 * threshold;

    const observer = new IntersectionObserver(
      async entries => {
        if (!entries[0].isIntersecting && hasMore && !isLoading) {
          onBottomHit();
        }
      },
      { root: container, rootMargin: `${topMargin}% 0% ${bottomMargin}%` }
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element, container, threshold, hasMore, isLoading]);

  // passing them to refs will be handled as a callback ref
  return { element: setElement, container: setContainer };
}

export default useInfiniteScroll;
