import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

import { Heading } from '@components/atoms/heading/Heading';

export const CreateDetailWrapper = styled('div', {
  minHeight: '70vh',
  position: 'relative',
  height: '100%',
  padding: '$32 $32 0',
  maxWidth: '1100px',
  mx: 'auto',
  '@bp2': {
    padding: '$72 $24',
  },
});

export const DateWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const UserContentDetails = styled('div', {
  display: 'flex',
  marginTop: '$16',
  '.create_detail_detailsWrapper': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '$8',
  },
  '.create_detail_detailsWrapper:first-of-type': {
    marginLeft: 0,
  },
});
export const CreateHeaderBottom = styled('div', {
  '@bp2': {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const CreateHeaderBottomLeft = styled('div', {
  display: 'grid',
  gridTemplateColumns: '$64 auto',
  gridColumnGap: '$16',
  alignItems: 'center',
});

export const CreateHeaderBottomRight = styled('div', {
  display: 'flex',
});

export const CreateHeader = styled('div', {
  margin: 'auto',
  mb: '$16',
  display: 'flex',
  flexDirection: 'column',

  '@bp2': {
    maxWidth: '80%',
    mb: '$24',
  },

  variants: {
    isMemberOf: {
      true: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        h1: {
          marginBottom: '0'
        }
      }
    }
  }
});

export const CreateHeaderMemberOf = styled('div', {
  textAlign: 'right',
});

export const MemberOfFooter = styled('div', {
  display: "flex",
  flexDirection: 'column',
  alignItems: 'center',
  margin: '$64 0'
})

export const HeadingTitle = styled(Heading, {
  lineHeight: 1.2,
  fontSize: '$24',
  marginBottom: '$32',
  wordBreak: 'break-word',
  '@bp2': {
    marginBottom: '$48',
    fontSize: '$48',
  },
});

export const CreateBlocks = styled('div', {
  position: 'relative',
});

export const BlockWrapper = styled('div', {
  marginBottom: '$40',
  boxSizing: 'border-box',
});

export const CreateRelatedLoadMore = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '$64',
});

export const CreateRelated = styled('div', {
  mt: '$84',
  mb: '$32',
});

export const CreateRelatedThumbnails = styled('div', {
  display: 'grid',
  gap: '32px',
  gridTemplateColumns: 'repeat(1, 1fr)',
  width: '100%',
  '@bp1': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp2': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

export const ImageWrapper = styled('div', {
  position: 'relative',
  width: '64px',
  height: '64px',
  margin: 'auto',
  img: {
    borderRadius: '100%',
  },
});

export const StyledLinks = styled(Link, {
  textDecoration: 'none',
  opacity: '1',
  transition: 'opacity .2s ease-out',
  img: {
    opacity: '1',
    transition: 'opacity .2s ease-out',
  },

  '&:hover': {
    opacity: '.7',

    img: {
      opacity: '.7',
    },
  },
});
export const SkeletonLoaderAuthorWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '$64 auto',
  alignItems: 'center',
  gridColumnGap: '$16',
  width: '50%',
  mt: '$40',
});
export const SkeletonLoaderTitleWrapper = styled('div', {
  maxWidth: '80%',
  margin: '0 auto',
});
