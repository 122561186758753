import React, { useState } from 'react';
import {
  ToggleButtonLabel,
  ToggleButtonStyled,
  ToggleButtonWrapper,
} from './ToggleButton.styled';

import PropTypes from 'prop-types';

const ToggleButton = props => {
  const { index, isDisabled, id, label, isSelected, onClick } = props;
  const [isActive, setIsActive] = useState(false);

  const onClickToggleActivate = () => {
    setIsActive(!isActive);
    onClick(index);
  };

  return (
    <ToggleButtonWrapper isDisabled={isDisabled}>
      <ToggleButtonStyled
        id={id}
        name={label}
        type="checkbox"
        disabled={isDisabled}
        onClick={onClickToggleActivate}
      />
      <ToggleButtonLabel
        isActive={isSelected ? 'isActive' : 'isNotActive'}
        htmlFor={id}
      >
        {label}
      </ToggleButtonLabel>
    </ToggleButtonWrapper>
  );
};

ToggleButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
};

ToggleButton.defaultProps = {
  label: undefined,
  onClick: () => {},
  isDisabled: false,
  isSelected: false,
};

export default ToggleButton;
