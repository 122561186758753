import {
  DropDownToggleButton,
  DropdownWrapper,
  Option,
  OptionsWrapper,
  SelectedItemCount,
} from './DropDown.styled';
import React, { useEffect, useRef, useState } from 'react';

import Button from '@components/atoms/button/Button';
import PropTypes from 'prop-types';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { useOnClickOutside } from '@src/utils/useOnClickOutside';
import String from '@components/atoms/string/String';

const DropDown = props => {
  const {
    title,
    options,
    id,
    defaultSelected,
    onChange,
    isMultiSelect,
    colorTheme,
    alignOptions,
    chevronLocation,
  } = props;

  const [dropDownState, setDropDownState] = useState(defaultSelected);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    onChange(JSON.stringify({ id, selectedValues: dropDownState }));
  }, [dropDownState]);

  useOnClickOutside(ref, () => setIsOpen(false));

  function isOptionSelected(id, name) {
    if (
      (dropDownState || []).find(e => {
        return parseFloat(e) == id || parseFloat(e) == name;
      })
    ) {
      return true;
    }
    return false;
  }

  function onOptionClick(e) {
    console.warn(e);
    if (!isOptionSelected(e)) {
      if (isMultiSelect) {
        setDropDownState([...dropDownState, e]);
      } else {
        setDropDownState([e]);
      }
    } else {
      setDropDownState(dropDownState.filter(v => v !== e));
    }
    if (!isMultiSelect) {
      setIsOpen(false);
    }
  }

  function getDropdownLabel() {
    if (isMultiSelect) {
      return <String id={title}/>;
    } else if (options?.[dropDownState?.[0] - 1]?.name === undefined) {
      return (
        options?.find(op => op?.id === parseInt(dropDownState[0], 10))?.id ||
        <String id={title}/>
      );
    } else {
      return options[dropDownState[0] - 1].name;
    }
  }

  return (
    <DropdownWrapper ref={ref} css={props.css} className={props.className}>
      <DropDownToggleButton
        actionType="onclick"
        colorTheme={colorTheme}
        label={getDropdownLabel()}
        svgIcon={isOpen ? 'arrow-up-short' : 'arrow-down-short'}
        onClick={() => setIsOpen(!isOpen)}
        svgLocation={chevronLocation}
      >
        {dropDownState.length > 0 && isMultiSelect && (
          <SelectedItemCount>{dropDownState.length}</SelectedItemCount>
        )}
      </DropDownToggleButton>
      {isOpen && (
        <OptionsWrapper aria-expanded="true" alignOptions={alignOptions}>
          {options.map(option => {
            return (
              <Option
                key={option.id}
                label={option.name}
                value={option.id}
                isSelected={isOptionSelected(option.id, option.name)}
                onClick={() => onOptionClick(option.id)}
              >
                <Text as="p" size={14} css={{ color: '$black' }}>
                  {option.name}
                </Text>
                {/* <Button
                  key={option.id}
                  label={option.name}
                  value={option.id}
                  // actionType="onclick"
                  colorTheme="transparentFull"
                  // svgIcon={
                  //   isOptionSelected(option.id, option.name) ? 'check' : ''
                  // }
                  svgLocation="after"
                  // onClick={onOptionClick}
                  isForDropDown={true}
                /> */}
                {isOptionSelected(option.id, option.name) && (
                  <Svg icon="check" css={{ width: '$24', height: '$24' }} />
                )}
              </Option>
            );
          })}
        </OptionsWrapper>
      )}
    </DropdownWrapper>
  );
};

export default DropDown;

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
  ).isRequired,
  id: PropTypes.string.isRequired,
  defaultSelected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  onChange: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  alignOptions: PropTypes.oneOf(['left', 'right']),
  chevronLocation: PropTypes.oneOf(['before', 'after']),
};

DropDown.defaultProps = {
  defaultSelected: [],
  isMultiSelect: true,
  alignOptions: 'left',
  chevronLocation: 'before',
};
