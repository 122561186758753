import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const SelectToolsWrapper = styled('div', {});

export const Tool = styled('div', {
  width: '50px',
  height: '50px',
  position: 'relative',
  marginRight: '$12',
  display: 'inline-block',
  button: {
    opacity: 1,
    pointerEvents: 'auto',
  },
  '@bp2': {
    button: {
      opacity: 0,
      pointerEvents: 'auto',
    },
  },
  '&:hover': {
    button: {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
});

export const InputTextLabel = styled('label', {
  marginBottom: '$4',
  display: 'inline-block',
  fontSize: '$14',
  fontFamily: 'var(--ArtifaktElement)',
  display: 'flex',
  alignContent: 'center',
  marginRight: '$8',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});

export const ImageWrapper = styled('div', {
  width: '50px',
  height: '50px',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  overflow: 'hidden',
  boxShadow: '$1',
  marginRight: '$12',

  img: {
    width: '50px',
    height: '50px',
  },
});

export const RemoveButton = styled('button', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: '-5px',
  borderRadius: '50%',
  background: '$lightGrey',
  border: 'none',
  zIndex: '3',
  width: '24px',
  height: '24px',
  padding: 0,
  opacity: 0,
  pointerEvents: 'none',
  transition: 'opacity .4s ease',

  svg: {
    width: '18px',
    height: '18px',
    fill: '$mainTextColor',
  },
});
export const SelectedToolsWrapper = styled('div', {});
export const SelectedToolsTitle = styled('h5', {
  fontFamily: '$ArtifaktElementBold',
  fontSize: '$14',
  marginTop: '$16',
  marginBottom: '$12',
});

export const SelectToolsHeader = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const RequiredLabel = styled(Text, {
  color: '$darkGrey',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});

export const InputTextErrorMsg = styled('div', {
  gridArea: 'error',
  fontSize: '$12',
  marginTop: '$4',
  minHeight: '$24',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});
