import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useIsMobile } from '@src/utils/useIsMobile';
import ModalContent from './ModalContent';

function Modal(props) {
  const {
    innerCss,
    outerCss,
    children,
    isShowing,
    hide,
    isContainedModal,
    hasImageHeader,
    isSelectUserContent,
    scrollable,
  } = props;

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isShowing && !document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    }
    else if(!isShowing){
      document.body.style.overflow = '';
    }
  }, [isShowing]);

  const handleHide = (e) => {
    document.body.style.overflow = '';
    hide(e);
  }

  const modalContent = () => {
    return (
      <ModalContent
        isMobile={isMobile}
        hide={handleHide}
        innerCss={innerCss}
        outerCss={outerCss}
        hasImageHeader={hasImageHeader}
        isSelectUserContent={isSelectUserContent}
        scrollable={scrollable}
      >
        {children}
      </ModalContent>
    );
  };

  if (isShowing && !isContainedModal) {
    return ReactDOM.createPortal(modalContent(), document.body);
  } else if (isShowing) {
    return modalContent();
  } else {
    return null;
  }
}

Modal.propTypes = {
  innerCss: PropTypes.object,
  outerCss: PropTypes.object,
  children: PropTypes.node,
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  isContainedModal: PropTypes.bool,
  hasImageHeader: PropTypes.bool,
  isSelectUserContent: PropTypes.bool,
  scrollable: PropTypes.bool,
};

Modal.defaultProps = {
  innerCss: {},
  outerCss: {},
  children: undefined,
  isShowing: false,
  hide: () => {},
  isContainedModal: false,
  hasImageHeader: false,
  isSelectUserContent: false,
  scrollable: false,
};

export default Modal;
