import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';
import Svg from '@components/atoms/svg/Svg';
import { ErrorMsgBox, ErrorHeader } from './FormErrorMsg.styled';

const FormErrorMsg = ({ title, desc }) => {
  return (
    <ErrorMsgBox>
      <ErrorHeader>
        <Svg icon="warning" />
        <Text as="h3" size="16" weight="legendBold">
          {title}
        </Text>
      </ErrorHeader>

      <Text as="p" size="14">
        {desc}
      </Text>
    </ErrorMsgBox>
  );
};

FormErrorMsg.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default FormErrorMsg;
