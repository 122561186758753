import { styled } from '@root/stitches.config';

export const SelectUserContentWrapper = styled('div', {
  backgroundColor: '$box',
  padding: '',
});

export const SelectUserContentHeader = styled('div', {
  height: '64px',
  width: '100%',
  borderBottom: '1px solid $lightGrey',
});

export const SelectUserContentContainer = styled('div', {
  padding: '$16',
  '@bp2': {
    padding: '$48 $72',
  }
});
