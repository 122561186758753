import React from 'react';
import { urlsData } from './craftData/UrlsData';

const UrlsContext = React.createContext({
  urls: [],
});

class UrlsProvider extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children } = this.props;

    const urls = {};
    urlsData.data.entries.map(entry => {
      const url = {
        title: entry.title,
        handle: entry.typeHandle,
        url: entry.url,
      };
      urls[entry.typeHandle] = url;
    });
    return <UrlsContext.Provider value={urls}>{children}</UrlsContext.Provider>;
  }
}

export default UrlsContext;

export { UrlsProvider };
