import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { GET_INDUSTRIES } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import SelectTags from '@components/molecules/selectTags/SelectTags';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { UPDATE_PROFILE_INDUSTRIES } from '@src/apollo/mutation';

const IndustriesEdit = props => {
  const authContext = useContext(AuthContext);
  const { loading, error, data } = useQuery(GET_INDUSTRIES);
  const [
    updateProfileIndustries,
    {
      loading: loadingIndustries,
      error: errorIndustries,
      data: dataIndustries,
    },
  ] = useMutation(UPDATE_PROFILE_INDUSTRIES, {
    onCompleted: () => {
      // Parse Tools for profile sidebar
      // Parse Tools for profile sidebar
      let updatedIndustriesList = industriesState.industriesList.map(
        industry => {
          return industry.isSelected ? industry : false;
        }
      );
      updatedIndustriesList = updatedIndustriesList.filter(industryId => {
        return industryId ? true : false;
      });
      props.onClose();
      props.onIndustriesEditSave(updatedIndustriesList);
    },
  });
  useEffect(() => {
    if (
      dataIndustries &&
      dataIndustries.updateUserProfile.profile_completion >= 70
    ) {
      authContext.setIsOnboarded();
    }
  }, [dataIndustries]);

  const [industriesError, setIndustriesError] = useState();

  // Todo need to fetch current data
  const [industriesState, setIndustriesState] = useState({
    isDataSet: false,
    industriesList: [],
  });

  const onIndustriesSelect = index => {
    const updatedIndustriesList = JSON.parse(
      JSON.stringify(industriesState.industriesList)
    );
    updatedIndustriesList[index].isSelected = !updatedIndustriesList[index]
      .isSelected;
    setIndustriesState({
      ...industriesState,
      industriesList: updatedIndustriesList,
    });
  };

  const setData = data => {
    const industriesList = [];

    data.industries.forEach(industry => {
      const industrySingle = {
        id: industry.id.toString(),
        label: industry.name,
        url: null,
        isSelected: false,
      };

      props.selectedIndustries.forEach(selectedIndustry => {
        if (industrySingle.id == selectedIndustry.id) {
          industrySingle.isSelected = true;
        }
      });

      industriesList.push(industrySingle);
    });

    setIndustriesState({
      ...industriesState,
      isDataSet: true,
      industriesList: industriesList,
      wasDataSetBefore: props.selectedIndustries.length > 0,
    });
  };

  if (!loading && !industriesState.isDataSet) {
    setData(data);
  }

  const onSave = () => {
    const selectedIndustries = industriesState.industriesList.filter(
      el => el.isSelected
    );
    if (selectedIndustries.length > 0) {
      let selectedIndustries = industriesState.industriesList.map(industry => {
        return industry.isSelected ? industry.id : false;
      });
      selectedIndustries = selectedIndustries.filter(industryId => {
        return industryId ? true : false;
      });
      updateProfileIndustries({
        variables: { user_id: props.userId, industries: selectedIndustries },
      });

      // Parse Tools for profile sidebar
      let updatedIndustriesList = industriesState.industriesList.map(
        industry => {
          return industry.isSelected ? industry : false;
        }
      );
      updatedIndustriesList = updatedIndustriesList.filter(industryId => {
        return industryId ? true : false;
      });
    } else {
      setIndustriesError('empty_industries');
    }
  };

  return (
    <div style={{ maxWidth: '640px' }}>
      {!loading ? (
        <>
          <Heading
            level="h3"
            weight="legendBold"
            css={{ lineHeight: '1.3em', marginBottom: '$24' }}
          >
            <String id="profile_edit_industries_title" />
          </Heading>
          <Text css={{ marginBottom: '$24' }}>
            <String id="profile_edit_industries_text" />
          </Text>
          <SelectTags
            onToggleButtonClick={onIndustriesSelect}
            list={industriesState.industriesList}
            css={{ marginBottom: industriesError ? '0' : '$48' }}
          ></SelectTags>
          {industriesError && (
            <Text
              css={{
                marginBottom: '$48',
                marginTop: '$8',
                color: '$red',
                fontFamily: '$ArtifaktLegendBold',
              }}
            >
              <String id={`profile_tools_error_${industriesError}`} />
            </Text>
          )}
          <Button
            type="normal"
            label="Cancel"
            colorTheme="gray"
            onClick={props.onClose}
            actionType="onclick"
            css={{ marginRight: '$16' }}
          />
          <Button
            type="normal"
            label="Save"
            colorTheme="normal"
            onClick={onSave}
            actionType="onclick"
            isLoading={loadingIndustries}
          />
        </>
      ) : (
        <>
          <LoaderSkeleton height="40px" width="100%" />
          <LoaderSkeleton height="100px" width="100%" />
        </>
      )}
    </div>
  );
};

export default IndustriesEdit;
