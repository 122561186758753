import { styled } from '@root/stitches.config';

export const CommunityCommentBoxWrapper = styled('div', {
  backgroundColor: '$elements',
  borderRadius: '0 0 $24 $24',
});

export const CommentsWrapper = styled('div', {
  padding: '$16 $16 0',

  '& .loader':{
    width: '50px',
    height: '50px',
    marginBottom: '-13px',
    marginTop: '-12px',
    marginLeft: '-15px'
  }
});

export const PreviousCommentsBtn = styled('button', {
  backgroundColor: 'unset',
  textDecoration: 'underline',
  border: 'none',
  color: '$mainTextColor',
  fontFamily: '$ArtifaktElementBold',

  '&:hover': {
    cursor: 'pointer',
  },

  variants: {
    isVisible: {
      true: {
        display: 'none',
      },
    },

    isDisabled: {
      true: {
        pointerEvents: 'none',
        opacity: '0.5',
      },
    },
  },
});
