import { styled } from '@root/stitches.config';

export const DropdownActionsWrapper = styled('div', {
  backgroundColor: '$elements',
  position: 'relative',
  borderRadius: '$8',
  cursor: 'pointer',
  zIndex: '4',
  svg: {
    width: '24px',
    height: '24px',
    marginRight: '$12',
  },

  variants: {
    isOpened: {
      true: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        svg: {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
});

export const DropdownActionsContainer = styled('div', {
  borderTop: '1px solid $lightGrey',
  position: 'absolute',
  backgroundColor: '$elements',
  borderRadius: '$8',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  width: '100%',
});

export const DropdownAction = styled('div', {
  padding: '$8 $16',
  fontFamily: '$ArtifaktLegend',
  lineHeight: '16px',
  fontSize: '$14',
  '&:hover': {
    backgroundColor: '$white',
  },
});

export const DropdownActionsLabel = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$14',
  padding: '$12 $16',
});
