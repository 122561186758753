import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const ActionLabel = styled(Text, {
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  transition: 'opacity .3s ease',
  '&:hover': { },
  lineHeight: '2.3em',
  padding: '$16',
  '@bp1': {
    padding: '0 $16'
  },
  variants: {
    status: {
      attention: {
        color: '$red',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid $lightGrey',
        paddingTop: '$8',
        marginTop: '$8',
        svg: {
          fill: '$red',
        }
      },
      helpCarefull: {
        color: '$green',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid $lightGrey',
        paddingTop: '$8',
        marginTop: '$8',
        svg: {
          fill: '$green',
        },
        opacity: '1',
        '&:hover': { cursor: 'pointer',opacity: '.4'  },                
      },

      carefull: {
        color: '$red',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid $lightGrey',
        paddingTop: '$8',
        marginTop: '$8',
        svg: {
          fill: '$red',
        },
        opacity: '1 !important',
        '&:hover': { cursor: 'pointer !important',opacity: '.4 !important'  },                
      },
      help: {
        color: '$green',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid $lightGrey',
        paddingTop: '$8',
        marginTop: '$8',
        svg: {
          fill: '$green',
        },
      },
    },
    isDisabled:{
      true:{
        opacity: '.4',
        '&:hover': { cursor: 'default' },
      }
    }
  },
  svg: {
    width: '20px',
    height: '20px',
    fill: '$red',
    marginRight: '$8',
  },
});

export const OptionsWrapper = styled('div', {
  display: 'block',
  position: 'relative',
  padding: 'auto $16'
});

export const OptionsContainer = styled('div', {
  position: 'absolute',
  top: '$48',
  right: '$8',
  backgroundColor: '$box',
  borderRadius: '$8',
  boxShadow: '$2',
  zIndex: '4',
  padding: '$8 0',
  variants: {
    alignLeft: {
      true: {
        right: 'unset',
      }
    },
    isOptionsOpened: {
      isOpened: {
        right: '0',
        width: '100%',
        '@bp1': {
          width: 'auto'
        },
        display: 'block',
      },
      isNotOpened: {
        display: 'none',
      },
    },
  },
});
