import Image from '@components/atoms/image/Image';
import React from 'react';
import { validImageUrl } from '@src/templates/create/utils';

import {
  ImageWrapper,
  LinkWrapper,
  StyledName,
  UserContentBoxWrapper,
} from './UserContent.styled';

const UserContent = props => {
  return (
    <UserContentBoxWrapper>
      <LinkWrapper href={props.url} target="_blank">
        <ImageWrapper>
          <Image src={props.image} />
        </ImageWrapper>
        <StyledName size="14">{props.name}</StyledName>
      </LinkWrapper>
    </UserContentBoxWrapper>
  );
};

export default UserContent;
