import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';
import { Text } from '@components/atoms/text/Text';

export const GroupListingFilters = styled('div', {
  '& > span': {
    display: 'none',
    justifyContent: 'space-between',
    marginBottom: '$16',

    '@bp2': {
      display: 'flex',
      marginBottom: '0',
    },
  },
});

export const BtnWrapper = styled('span', {
  button: {
    backgroundColor: 'unset',
    color: '$mainTextColor',

    '&:hover':{
      backgroundColor: '$elements'
    },
  },

 

  variants: {
    isActive: {
      true: {
        button: {
          backgroundColor: '$blackToWhite',
          color: '$whiteToBlack',

          '&:hover':{
            backgroundColor: '$blackToWhite'
          },
        },
      },
    },
    isMobile: {
      true: {
        button: {
          color: '$black'
        }
      }
    },
    isMobileActive: {
      true: {
        button: {
          backgroundColor: '$black',
          color: '$white !important',

          '&:hover':{
            backgroundColor: '$black'
          },
        },
      }
    }
  },
});

export const OtherFiltersWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const MobileFiltersWrapper = styled('div', {
  display: 'block',
  width: '87.2vw',
  padding: '$48 $20',
  height: '100vh',
  backgroundColor: '$white',
  position: 'fixed',
  top: '54px',
  right: '0',
  zIndex: '$2',
  transform: 'translateX(100%)',
  transition: 'transform 0.2s ease-out',

  ul: {
    width: '100%',
    left: '0',
    top: '$48',
  },

  div: {
    display: 'flex',
    flexDirection: 'column',

    button: {
      width: '100%',
      margin: '$8 0 0',
    },
  },

  '@bp2': {
    display: 'none',
  },

  variants: {
    isVisible: {
      true: {
        transform: 'translateX(0%)',
      },
    },
  },
});

export const OtherFiltersMobileWrapper = styled('div', {
  marginTop: '$48',
});

export const BlackOverlay = styled('div', {
  position: 'absolute',
  width: '100%',
  zIndex: '1',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,.5)',
  top: '0',
  left: '-0',
  opacity: '0',
  transition: 'opacity 0.2s ease-out',
  pointerEvents: 'none',
  display: 'block',

  variants: {
    isVisible: {
      true: {
        opacity: '1',
      },
    },
  },

  '@bp2': {
    display: 'none',
  },
});

export const StyledBtn = styled(Button, {
  display: 'flex',
  width: '100%',
  color: '$mainTextColor',

  '@bp2': {
    display: 'none',
  },
});

export const CloseBtn = styled(Button, {
  position: 'absolute',
  top: '$8',
  right: '$8',
});

export const FilterTitle = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  marginBottom: '$12',
  textTransform: 'capitalize',
});
