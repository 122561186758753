import {
  CreateFooterWrapper,
  FlexContainer,
} from './CreateFooter.styled';
import React from 'react';

import BadgeToolsList from '@components/molecules/badgesToolsList/BadgeToolsList';
import TagList from '@components/molecules/tagList/TagList';

const setIndustriesData = props => {
  const industriesData = [];
  for (let index = 0; index < props.industries.length; index++) {
    const element = props.industries[index];
    const singleIndustry = {
      id: index,
      label: element.name,
      // TODO: reenable when we have pages for industries
      // url: '/',
    };
    industriesData.push(singleIndustry);
  }
  return industriesData;
};

const setToolsData = props => {
  const toolsData = [];
  for (let index = 0; index < props.tools.length; index++) {
    const element = props.tools[index];
    const singleTool = {
      id: index,
      imageDynamic: {
        src: element.icon,
      },
      alt: element.title,

      infoboxActive: true,
      infoboxTitle: element.title,
    };
    toolsData.push(singleTool);
  }
  return toolsData;
};

const CreateFooter = props => {
  return (
    <CreateFooterWrapper>
      <FlexContainer>
        <BadgeToolsList
          list={setToolsData(props)}
          title="Tools"
          maxTools={setToolsData(props).length > 6 ? 5 : 6}
        />
      </FlexContainer>
      {(props.industries.length >= 1 && props.createType !== "asset") && (
        <FlexContainer singleItem={setIndustriesData(props).length === 1}>
          <TagList
            list={setIndustriesData(props)}
            title="Industries"
            css={{ marginBottom: '$24', justifyContent: 'unset' }}
          />
        </FlexContainer>
      )}
      {(props.assetType && props.createType === "asset") && (
        <TagList
          list={[{id: props.assetType.id, label: props.assetType.name}]}
          title="Asset type"
          css={{ marginBottom: '$24', justifyContent: 'unset' }}
        />
      )}
    </CreateFooterWrapper>
  );
};

export default CreateFooter;
