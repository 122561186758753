import { styled } from '@root/stitches.config';

export const BlockTextWrapper = styled('div', {
  m: 'auto',
  '@bp2': {
    width: '80%',
  },
  p: {
    lineHeight: '1.8',
  },

  ul: {
    listStyle: 'initial',
    listStylePosition: 'initial',
    paddingLeft: '$24',
    marginBottom: '$24',
  },
  h2: {
    fontSize: '$32',
    marginBottom: '$16',
  },
  h3: {
    fontSize: '$24',
    marginBottom: '$16',
  },
  h4: {
    marginBottom: 0,
  },
  a: {
    //color: '$purple',
    textDecoration: 'underline',
    fontFamily: '$ArtifaktElementBold',
    textDecorationThickness: '1px',
    textUnderlineOffset: '2px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  ol: {
    listStyle: 'decimal',
    listStylePosition: 'initial',
    paddingLeft: '$24',
    marginBottom: '$24',
  },
});
