import { styled } from '@root/stitches.config';

export const PageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const PageInner = styled('div', {
  width: '100%',
  maxWidth: '1440px',
  minHeight: '100vh',

  padding: '$16',
  '@bp2': {
    display: 'grid',
    gridTemplateColumns: '280px auto',
    gridGap: '$54',
    minHeight: '80vh',
    padding: '$54 $32 $84',
  },
  '@bp3': {
    display: 'grid',
    gridTemplateColumns: '280px auto 200px',
    gridGap: '$54',
  },
});

export const AccountSettingsContentWrapper = styled('div', {
  marginTop: '$32',
  '@bp2': {
    marginTop: 0,
  },
});
