import { styled } from '@root/stitches.config';

export const SketchfabBlockWrapper = styled('div', {
  position: 'relative',
  zIndex: '3',
});

export const SketchfabBlockErrorWrapper = styled('div', {
  padding: '$64',
  br: '$16',
  '.sketchfab-embed-wrapper': {
    width: '100%',
    iframe: {
      width: '100%'
    }
  }
});
