import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';

const SocialMediaPopup = props => {
  const { provider } = props;

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
    const params = QueryString.parse(window.location.search);
    if (params.error) {
      const errorMessage =
        params.error_description || 'Login failed. Please try again.';
      window.opener &&
        window.opener.postMessage(
          {
            error: params.error,
            state: params.state,
            errorMessage,
            from: provider,
          },
          window.location.origin
        );
      // Close tab if user cancelled login
      if (params.error === 'user_cancelled_login') {
        window.close();
      }
    }
    if (params.token) {
      window.opener &&
        window.opener.postMessage(
          { code: params.token, state: params.state, from: provider },
          window.location.origin
        );
    }
    if (params.linkedin_redirect_url) {
      window.location.href = params.linkedin_redirect_url;
    }
  }, []);

  if (!hasMounted) {
    return null;
  }
  return <>Login Successful, you're now redirected to AREA</>;
};

export default SocialMediaPopup;
