import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const OptionsWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
});

export const SaveBtnAction = styled('div', {
  fontFamily: '$ArtifaktElementBold',
  fontWeight: '600',
  fontSize: '$14',
  cursor: 'pointer',
});

export const SaveBtnActions = styled('div', {
  marginTop: '$16',
});

export const OptionsContainer = styled('div', {
  position: 'absolute',
  top: '$48',
  right: '$8',
  backgroundColor: '$box',
  borderRadius: '$8',
  right: 0,
  boxShadow: '$2',
  zIndex: '4',
  padding: '$8 0',
  variants: {
    isOptionsOpened: {
      isOpened: {
        display: 'block',
      },
      isNotOpened: {
        display: 'none',
      },
    },
  },
});
