import Modal from '@components/atoms/modal/Modal';
import React from 'react';

const AuthContext = React.createContext({
  authenticated: false,
  userId: '',
  userToken: '',
  userSlug: '',
  isVerified: false,
  isOnboarded: false,
  profilePicture: '',
  firstName: '',
  lastName: '',
  title: '',
  linkedinVerificationAvailable: true,
  generalRole: '',
  createTermsAndConditionsAccepted: false,
  login: () => {},
  logout: () => {},
  register: () => {},
  forgotPassword: () => {},
  changePassword: () => {},
  setUserName: () => {},
  loginLinkedIn: () => {},
  socialAuth: () => {},
  verifyLinkedIn: () => {},
  sendVerificationEmail: () => {},
  checkIfLoggedLocalStorage: () => {},
  verifyByPhone: () => {},
  verifySmsCode: () => {},
  updateProfilePicture: () => {},
  updateName: () => {},
  updateUserSlug: () => {},
  updateCreateTermsAndConditionsAccepted: () => {},
});

class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    const isLoggedLocalStorage = this.checkIfLoggedLocalStorage();
    this.setInitState(isLoggedLocalStorage);
  }

  setInitState = isLoggedLocalStorage => {
    this.state = {
      isAuthenticated: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageHasAuth
        : false,
      userId: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageHasUserId
        : null,
      userToken: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageHasUserToken
        : null,
      userSlug: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageUserSlug
        : null,
      isVerified: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageIsVerified
        : null,
      isOnboarded: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageIsOnboarded
        : null,
      profilePicture: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageProfilePicture
        : null,
      firstName: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageFirstName
        : null,
      lastName: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageLastName
        : null,
      title: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageTitle
        : null,
      generalRole: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageGeneralRole
        : null,
      isShowingModal: false,
      createTermsAndConditionsAccepted: isLoggedLocalStorage
        ? isLoggedLocalStorage.localStorageCreateTermsAndConditionsAccepted
        : false,
    };
  };

  checkIfLoggedLocalStorage = () => {
    const localStorageHasAuth = JSON.parse(
      localStorage.getItem('authenticated')
    );
    const localStorageHasUserId = JSON.parse(localStorage.getItem('userId'));
    const localStorageCreateTermsAndConditionsAccepted = JSON.parse(
      localStorage.getItem('createTermsAndConditionsAccepted')
    );
    const localStorageHasUserToken = JSON.parse(
      localStorage.getItem('userToken')
    );

    const localStorageIsVerified = JSON.parse(
      localStorage.getItem('isVerified')
    );
    const localStorageIsOnboarded = JSON.parse(
      localStorage.getItem('isOnboarded')
    );
    const localStorageUserSlug = JSON.parse(localStorage.getItem('userSlug'));
    const localStorageProfilePicture = JSON.parse(
      localStorage.getItem('profilePicture')
    );
    const localStorageFirstName = JSON.parse(localStorage.getItem('firstName'));
    const localStorageLastName = JSON.parse(localStorage.getItem('lastName'));
    const localStorageGeneralRole = JSON.parse(
      localStorage.getItem('generalRole')
    );
    const localStorageTitle = JSON.parse(localStorage.getItem('title'));

    if (
      localStorageHasAuth &&
      localStorageHasUserId &&
      localStorageHasUserToken &&
      localStorageUserSlug
    ) {
      return {
        localStorageHasAuth,
        localStorageHasUserId,
        localStorageHasUserToken,
        localStorageIsVerified,
        localStorageIsOnboarded,
        localStorageUserSlug,
        localStorageProfilePicture,
        localStorageFirstName,
        localStorageLastName,
        localStorageGeneralRole,
        localStorageTitle,
        localStorageCreateTermsAndConditionsAccepted,
      };
    }
    return false;
  };

  toggle = () => {
    this.setState({
      ...this.state,
      isShowingModal: !this.state.isShowingModal,
    });
  };

  // REGISTER

  register = registerForm => {
    const data = {
      email: registerForm.email,
      first_name: registerForm.firstName,
      last_name: registerForm.lastName,
      username: registerForm.username,
      password: registerForm.password,
      password_confirmation: registerForm.passwordConfirmation,
    };

    let resStatus;

    fetch(`${API_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 201:
            registerForm.successCallback(response.message);
            break;

          default:
            registerForm.errorCallback(response);
            break;
        }
      })
      .catch(err => {
        console.error(err);
        registerForm.errorCallback(
          'An error has occured. Please try again later.'
        );
      });
  };

  // LOGIN
  login = logins => {
    this.logout();
    const data = {
      email: logins.email,
      password: logins.password,
    };
    let resStatus;

    fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 200:
            this.setValidLogins(response.data, logins);
            logins.successCallback(response.message, response.data);
            break;

          default:
            logins.errorCallback(response);
            break;
        }
      })
      .catch(err => {
        console.error(err);
        logins.errorCallback('An error has occured. Please try again later.');
      });
  };

  // FORGOT PASSWORD

  forgotPassword = forgotPasswordForm => {
    const data = {
      email: forgotPasswordForm.email,
    };

    let resStatus;

    fetch(`${API_URL}/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 201:
            forgotPasswordForm.successCallback(response.message);
            break;

          default:
            forgotPasswordForm.errorCallback(response);
            break;
        }
      })
      .catch(err => {
        console.error(err);
        forgotPasswordForm.errorCallback(
          'An error has occured. Please try again later.'
        );
      });
  };

  changePassword = changePasswordForm => {
    const data = {
      password: changePasswordForm.password,
      password_confirmation: changePasswordForm.passwordConfirm,
      token: changePasswordForm.token,
    };

    let resStatus;

    fetch(`${API_URL}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 201:
            changePasswordForm.successCallback(response.message);
            break;

          case 401:
            changePasswordForm.errorCallback(response.message);
            break;

          case 503:
            changePasswordForm.errorCallback(
              'An error has occured. Please try again later.'
            );
            break;

          default:
            changePasswordForm.errorCallback(response.message);
            break;
        }
      })
      .catch(err => {
        console.error(err);
        changePasswordForm.errorCallback(
          'An error has occured. Please try again later.'
        );
      });
  };

  setValidLogins = (loginData, logins) => {
    // Placeholder data

    const isAuthenticated = true;
    const userId = loginData.user_id;
    const userToken = loginData.token;
    const isVerified = loginData.account_verified;
    const isOnboarded = loginData.is_onboarded;
    const userSlug = loginData.username;
    const profilePicture = loginData.profile_picture;
    const firstName = loginData.first_name;
    const lastName = loginData.last_name;
    const title = loginData.title;
    const generalRole = loginData.role;
    const createTermsAndConditionsAccepted =
      loginData.createTermsAndConditionsAccepted;

    localStorage.setItem('authenticated', JSON.stringify(isAuthenticated));
    localStorage.setItem('userId', JSON.stringify(userId));
    localStorage.setItem('userToken', JSON.stringify(userToken));
    localStorage.setItem('isVerified', JSON.stringify(isVerified));
    localStorage.setItem('isOnboarded', JSON.stringify(isOnboarded));
    localStorage.setItem('userSlug', JSON.stringify(userSlug));
    localStorage.setItem('profilePicture', JSON.stringify(profilePicture));
    localStorage.setItem('firstName', JSON.stringify(firstName));
    localStorage.setItem('lastName', JSON.stringify(lastName));
    localStorage.setItem('title', JSON.stringify(title));
    localStorage.setItem('generalRole', JSON.stringify(generalRole));
    localStorage.setItem(
      'createTermsAndConditionsAccepted',
      JSON.stringify(createTermsAndConditionsAccepted === 1)
    );

    this.setState({
      isAuthenticated,
      userId,
      userToken,
      isVerified,
      isOnboarded,
      userSlug,
      profilePicture,
      firstName,
      lastName,
      title,
      generalRole,
      createTermsAndConditionsAccepted,
    });
  };

  setValidLoginsSocial = loginData => {
    const isAuthenticated = true;
    const userId = loginData.user_id;
    const userToken = loginData.token;
    const isVerified = loginData.account_verified;
    const isOnboarded = loginData.is_onboarded;
    const userSlug = loginData.username;
    const profilePicture = loginData.profile_picture;
    const firstName = loginData.first_name;
    const lastName = loginData.last_name;
    const title = loginData.title;
    const generalRole = loginData.role;
    const linkedinVerificationAvailable =
      loginData.linkedin_verification_available;
    const createTermsAndConditionsAccepted =
      loginData.createTermsAndConditionsAccepted;

    localStorage.setItem('authenticated', JSON.stringify(isAuthenticated));
    localStorage.setItem('userId', JSON.stringify(userId));
    localStorage.setItem('userToken', JSON.stringify(userToken));
    localStorage.setItem('isVerified', JSON.stringify(isVerified));
    localStorage.setItem('isOnboarded', JSON.stringify(isOnboarded));
    localStorage.setItem('userSlug', JSON.stringify(userSlug));
    localStorage.setItem('profilePicture', JSON.stringify(profilePicture));
    localStorage.setItem('firstName', JSON.stringify(firstName));
    localStorage.setItem('lastName', JSON.stringify(lastName));
    localStorage.setItem('title', JSON.stringify(title));
    localStorage.setItem('generalRole', JSON.stringify(generalRole));
    localStorage.setItem(
      'createTermsAndConditionsAccepted',
      JSON.stringify(createTermsAndConditionsAccepted === 1)
    );

    this.setState({
      isAuthenticated,
      userId,
      userToken,
      isVerified,
      isOnboarded,
      userSlug,
      profilePicture,
      firstName,
      lastName,
      linkedinVerificationAvailable,
      createTermsAndConditionsAccepted,
      title,
      generalRole,
    });
  };

  setVerified = verifiedData => {
    // Placeholder data
    const isVerified = 1;
    localStorage.setItem('isVerified', 1);

    this.setState({
      isVerified,
    });
  };

  setIsOnboarded = () => {
    localStorage.setItem('isOnboarded', 1);
    this.setState({
      ...this.state,
      isOnboarded: true,
    });
  };

  logout = () => {
    const isAuthenticated = false;
    const isVerified = 0;
    const isOnboarded = false;
    localStorage.setItem('authenticated', JSON.stringify(isAuthenticated));
    localStorage.removeItem('userId');
    localStorage.removeItem('userToken');
    localStorage.removeItem('isVerified');
    localStorage.removeItem('isOnboarded');
    localStorage.removeItem('userSlug');
    localStorage.removeItem('profilePicture');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('title');
    localStorage.removeItem('generalRole');
    localStorage.removeItem('createTermsAndConditionsAccepted');

    this.setState({
      isAuthenticated,
      userId: null,
      userToken: null,
      isVerified,
      isOnboarded,
      userSlug: null,
      profilePicture: '',
      firstName: '',
      lastName: '',
      title: '',
      generalRole: '',
      createTermsAndConditionsAccepted: false,
    });
  };

  setFirstNameLastName = fullname => {
    const firstName = fullname.first_name;
    const lastName = fullname.last_name;
    localStorage.setItem('firstName', JSON.stringify(firstName));
    localStorage.setItem('lastName', JSON.stringify(lastName));

    this.setState({
      firstName,
      lastName,
    });
  };

  setUserName = userInfo => {
    const data = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
    };

    const bearer = `Bearer ${localStorage.getItem('userToken').slice(1, -1)}`;

    let resStatus;

    fetch(`${API_URL}/update-user-infos`, {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 200:
            this.setFirstNameLastName(data);
            userInfo.successCallback();
            break;

          default:
            userInfo.errorCallback(response.message);
            break;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  loginLinkedIn = code => {
    const data = {
      code,
      provider: 'linkedin',
    };

    fetch(`${API_URL}/login-external/linkedin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        console.log(response);
      })
      .catch(err => {
        console.error(err);
      });
  };

  socialAuth = token => {
    const data = {
      token,
    };

    let resStatus;

    fetch(`${API_URL}/sso-login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.token),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        if (!response.success && response.error === 'account_deleted') {
          this.toggle();
        }
        switch (resStatus) {
          case 200:
            this.setValidLoginsSocial(response.data);
            token.successCallback(response);
            break;

          default:
            token.errorCallback(response);
            break;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  verifyLinkedIn = token => {
    const data = {
      token,
    };
    const bearer = `Bearer ${localStorage.getItem('userToken').slice(1, -1)}`;
    let resStatus;
    fetch(`${API_URL}/verify-linkedin-token`, {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.token),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 200:
            this.setVerified(response.data);
            break;

          default:
            break;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  updateProfilePicture = profilePictureUrl => {
    const profilePicture = `${profilePictureUrl}?${Date.now()}`;

    localStorage.setItem('profilePicture', JSON.stringify(profilePicture));

    this.setState({
      profilePicture,
    });
  };

  updateName = (firstName, lastName) => {
    localStorage.setItem('firstName', JSON.stringify(firstName));
    localStorage.setItem('lastName', JSON.stringify(lastName));

    this.setState({
      firstName,
      lastName,
    });
  };

  updateUserSlug = userSlug => {
    localStorage.setItem('userSlug', JSON.stringify(userSlug)),
      this.setState({
        userSlug,
      });
  };

  updateCreateTermsAndConditionsAccepted = value => {
    localStorage.setItem('createTermsAndConditionsAccepted', value);
    this.setState({
      createTermsAndConditionsAccepted: value,
    });
  };

  sendVerificationEmail = sendLinkFn => {
    const data = {
      email: sendLinkFn.email,
    };

    let resStatus;

    fetch(`${API_URL}/send-verification-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case response.success !== false && 200:
            sendLinkFn.successCallback(response);
            break;

          default:
            sendLinkFn.errorCallback(response);
            break;
        }
      })
      .catch(err => {
        console.error(err);
        sendLinkFn.errorCallback(
          'An error has occured. Please try again later.'
        );
      });
  };

  verifyByPhone = authPhoneVerificationFn => {
    const data = {
      phoneNumber: authPhoneVerificationFn.phoneNumber,
    };
    const bearer = `Bearer ${localStorage.getItem('userToken').slice(1, -1)}`;
    let resStatus;
    fetch(`${API_URL}/verify-phone`, {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 200:
            authPhoneVerificationFn.successCallback(response.message);
            //this.setVerified(response.data);
            break;

          default:
            authPhoneVerificationFn.errorCallback(response);
            break;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  verifySmsCode = authSmsCodeVerificationFn => {
    const data = {
      code: authSmsCodeVerificationFn.smsCode,
    };

    const bearer = `Bearer ${localStorage.getItem('userToken').slice(1, -1)}`;
    let resStatus;
    fetch(`${API_URL}/verify-phone-code`, {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res.json();
      })
      .then(response => {
        switch (resStatus) {
          case 200:
            authSmsCodeVerificationFn.successCallback(response);
            this.setVerified(response.data);
            break;

          default:
            authSmsCodeVerificationFn.errorCallback(response);
            break;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    const { children } = this.props;
    const {
      isAuthenticated,
      userId,
      userToken,
      isVerified,
      isOnboarded,
      userSlug,
      profilePicture,
      firstName,
      lastName,
      title,
      linkedinVerificationAvailable,
      createTermsAndConditionsAccepted,
      generalRole,
    } = this.state;

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          userId,
          userToken,
          isVerified,
          isOnboarded,
          userSlug,
          profilePicture,
          lastName,
          firstName,
          title,
          linkedinVerificationAvailable,
          createTermsAndConditionsAccepted,
          generalRole,
          login: this.login,
          logout: this.logout,
          setIsOnboarded: this.setIsOnboarded,
          register: this.register,
          forgotPassword: this.forgotPassword,
          changePassword: this.changePassword,
          setUserName: this.setUserName,
          loginLinkedIn: this.loginLinkedIn,
          socialAuth: this.socialAuth,
          verifyLinkedIn: this.verifyLinkedIn,
          sendVerificationEmail: this.sendVerificationEmail,
          checkIfLoggedLocalStorage: this.checkIfLoggedLocalStorage,
          verifyByPhone: this.verifyByPhone,
          verifySmsCode: this.verifySmsCode,
          updateProfilePicture: this.updateProfilePicture,
          updateName: this.updateName,
          updateUserSlug: this.updateUserSlug,
          updateCreateTermsAndConditionsAccepted: this
            .updateCreateTermsAndConditionsAccepted,
        }}
      >
        {children}
        <Modal hide={() => this.toggle()} isShowing={this.state.isShowingModal}>
          Your account has been deleted, if you think it's an error, please use
          the 'Assistance' button to contact the support.
        </Modal>
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;

export { AuthProvider };
