import { NoResultWrapper, TextStyled } from './NoResult.styled';

import React from 'react';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';

const NoResult = props => {
  const { isInFavorite, stringId } = props;
  return (
    <NoResultWrapper>
      {!isInFavorite ? (
        <Svg
          css={{ width: '$24', height: '$24', marginRight: '$8' }}
          icon="search"
        />
      ) : null}
      <TextStyled>
        <String id={stringId} />
      </TextStyled>
    </NoResultWrapper>
  );
};

export default NoResult;

NoResult.defaultProps = {
  isInFavorite: false,
  stringId: 'noResult_text',
};
