import * as yup from 'yup';

import {
  ChangePasswordBoxWrapper,
  ChangePasswordPageWrapper,
  ErrorMsg,
  FormStyled,
  SuccessBox,
  ThankYouWrapper,
} from './ChangePasswordBox.styled';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { Formik } from 'formik';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import { InputText } from '@components/atoms/input/inputText/InputText';
import LogInBox from '@components/organisms/logInBox/LogInBox';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import queryString from 'query-string';

function ChangePasswordBox() {
  const TokenStatus = {
    Validating: 'Validating',
    Valid: 'Valid',
    Invalid: 'Invalid',
  };
  const authContext = useContext(AuthContext);
  const [token, setToken] = useState(null);
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);
  const UrlContext = useContext(UrlsContext);
  const [changePasswordFormState, setChangePasswordFormState] = useState({
    password: '',
    passwordConfirm: '',
    token: '',
    authErrorMessage: '',
    isAuthError: false,
    isLoading: false,
    passwordErrorMsg: null,
    isPasswordError: false,
    passwordConfirmErrorMsg: null,
    isPasswordConfirmError: false,
    isPasswordChanged: false,
  });

  useEffect(() => {
    const { passwordResetToken } = queryString.parse(location.search);

    if (
      !passwordResetToken ||
      passwordResetToken == undefined ||
      passwordResetToken == null
    ) {
      setToken(null);
      setTokenStatus(TokenStatus.Invalid);
    } else {
      setToken(passwordResetToken);
      setTokenStatus(TokenStatus.Valid);
    }
  }, []);

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    navigate(`/m/${authContext.userSlug}`, { replace: true });
  } else if (authContext.isAuthenticated && !authContext.isVerified) {
    navigate(GetUrl('onboardingSignupValidation', UrlContext));
  } else if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    !authContext.isOnboarded
  ) {
    navigate(GetUrl('onboardingAvatarAndBio', UrlContext));
  }

  function getForm() {
    //creating the validation schema

    const Schema = yup.object().shape({
      password: yup
        .string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          () => <String id="sign_up_error_password_requirements" />
        ),

      passwordConfirm: yup.string().when('password', {
        is: password => (password && password.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref('password')], () => (
            <String id="sign_up_error_password_not_match" />
          )),
      }),
    });

    const handleAuthError = response => {
      switch (response) {
        default:
          setChangePasswordFormState({
            ...changePasswordFormState,
            isLoading: false,
            isAuthError: true,
            authErrorMessage: <String id="change_password_error_general" />,
            isPasswordConfirmError: false,
            passwordConfirmErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
          });
          break;
        case 'the_token_field_is_required':
          setChangePasswordFormState({
            ...changePasswordFormState,
            isLoading: false,
            isAuthError: true,
            authErrorMessage: (
              <String id="change_password_error_token_required" />
            ),
            isPasswordConfirmError: false,
            passwordConfirmErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
          });
          break;
        case 'the_password_field_is_required':
          setChangePasswordFormState({
            ...changePasswordFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: null,
            isPasswordConfirmError: false,
            passwordConfirmErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: (
              <String id="change_password_error_valid_password" />
            ),
          });
          break;
        case 'the_password_must_be_at_least8_characters':
          setChangePasswordFormState({
            ...changePasswordFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: null,
            isPasswordConfirmError: false,
            passwordConfirmErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: (
              <String id="change_password_error_password_requirements" />
            ),
          });
          break;
        case 'the_password_format_is_invalid':
          setChangePasswordFormState({
            ...changePasswordFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: null,
            isPasswordConfirmError: false,
            passwordConfirmErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: (
              <String id="change_password_error_password_requirements" />
            ),
          });
          break;
        case 'the_password_confirmation_does_not_match':
          setChangePasswordFormState({
            ...changePasswordFormState,
            isLoading: false,
            isAuthError: true,
            authErrorMessage: (
              <String id="change_password_error_password_not_match" />
            ),
            isPasswordConfirmError: false,
            passwordConfirmErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
          });
          break;
        case 'user_password_change_error':
          setChangePasswordFormState({
            ...changePasswordFormState,
            isLoading: false,
            isAuthError: true,
            authErrorMessage: <String id="change_password_error_general" />,
            isPasswordConfirmError: false,
            passwordConfirmErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
          });
          break;
      }
    };

    const handleAuthSuccess = () => {
      setChangePasswordFormState({
        ...changePasswordFormState,
        isLoading: false,
        isAuthError: false,
        authErrorMessage: null,
        isPasswordChanged: true,
      });
    };

    const handleFormSubmit = (e, authChangePasswordFn, values) => {
      setChangePasswordFormState({
        ...changePasswordFormState,
        password: values.password,
        passwordConfirm: values.passwordConfirm,
      });
      e.preventDefault();

      setChangePasswordFormState({
        ...changePasswordFormState,
        isAuthError: false,
        isLoading: true,
      });

      authChangePasswordFn({
        password: values.password,
        passwordConfirm: values.passwordConfirm,
        token: token,
        errorCallback: handleAuthError,
        successCallback: handleAuthSuccess,
      });
    };

    return (
      <Formik
        initialValues={{
          password: '',
          passwordConfirm: '',
        }}
        validationSchema={Schema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isValid,
          handleChange,
          handleBlur,
        }) => {
          return (
            <AuthContext.Consumer>
              {auth => (
                <>
                  <Text size="14" css={{ lineHeight: '1.5em', my: '$24' }}>
                    <String id="change_password_title" />
                  </Text>

                  <FormStyled
                    onSubmit={e =>
                      handleFormSubmit(e, auth.changePassword, values)
                    }
                  >
                    <div
                      style={{
                        width: '100%',
                        opacity: changePasswordFormState.isLoading ? 0.3 : 1,
                      }}
                    >
                      <InputText
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        inputShape="normal"
                        name="password"
                        label={<String id="change_password_label" />}
                        value={values.password}
                        css={{ marginBottom: '$8' }}
                        error={errors.password}
                        isError={
                          errors.password && touched.password ? true : false
                        }
                        touched={touched.password}
                        instruction={
                          <String id="sign_up_password_instruction" />
                        }
                        isInstruction={true}
                      />

                      <InputText
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        inputShape="normal"
                        name="passwordConfirm"
                        label={
                          <String id="change_password_confirmation_label" />
                        }
                        value={values.passwordConfirm}
                        css={{ marginBottom: '$24' }}
                        error={errors.passwordConfirm}
                        isError={
                          errors.passwordConfirm && touched.passwordConfirm
                            ? true
                            : false
                        }
                        touched={touched.passwordConfirm}
                        isInstruction={false}
                      />
                    </div>
                    {changePasswordFormState.isAuthError ? (
                      <ErrorMsg>
                        {changePasswordFormState.authErrorMessage}
                      </ErrorMsg>
                    ) : null}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        type="submit"
                        value="Submit"
                        type="normal"
                        label={<String id="change_password_button_label" />}
                        colorTheme="normal"
                        notificationBubbleActive={false}
                        actionType="onclick"
                        isDisabled={!(isValid && dirty)}
                        css={{
                          width: '100%',
                        }}
                        isLoading={changePasswordFormState.isLoading}
                      />
                    </div>
                  </FormStyled>
                </>
              )}
            </AuthContext.Consumer>
          );
        }}
      </Formik>
    );
  }

  function getBody() {
    switch (tokenStatus) {
      case TokenStatus.Valid:
        return <>{getForm()}</>;

      case TokenStatus.Invalid:
        return (
          <Text size="14" css={{ lineHeight: '1.5em', my: '$24' }}>
            <String id="change_password_validation_error" />{' '}
            <Link to={GetUrl('forgotPassword', UrlContext)}>
              <String id="change_password_validation_link_label" />
            </Link>
          </Text>
        );
      case TokenStatus.Validating:
        return (
          <Text size="14" css={{ lineHeight: '1.5em', my: '$24' }}>
            <String id="change_password_validation_token" />
          </Text>
        );
    }
  }
  const ThankYou = () => (
    <ThankYouWrapper>
      <SuccessBox>
        <Text
          as="h2"
          weight="legendExtraBold"
          css={{
            marginBottom: '$16',
            // Temporary
            fontSize: '$24',
            '@bp2': {
              fontSize: '$42',
              lineHeight: '1.2',
            },
          }}
        >
          <String id="change_password_success_text" />
        </Text>
        <Text
          css={{
            marginBottom: '$32',
          }}
        >
          You can now Sign in to AREA
        </Text>

        <LogInBox isVerifyPage={true} />
      </SuccessBox>
    </ThankYouWrapper>
  );

  return (
    <>
      {!changePasswordFormState.isPasswordChanged ? (
        <ChangePasswordPageWrapper className="container">
          <ChangePasswordBoxWrapper>
            <div>
              <Text
                as="h2"
                weight="legendExtraBold"
                css={{
                  marginBottom: '$16',
                  // Temporary
                  fontSize: '$24',
                  '@bp2': {
                    fontSize: '$42',
                    lineHeight: '1.2',
                  },
                }}
              >
                <String id="change_password_page_title" />
              </Text>
              {getBody()}
            </div>
          </ChangePasswordBoxWrapper>
        </ChangePasswordPageWrapper>
      ) : (
        <ThankYou />
      )}
    </>
  );
}

export default ChangePasswordBox;
