import {
  ButtonSpacer,
  Inverter,
  StyledButton,
  WrapperHighLight,
  WrapperListingHeader,
  WrapperPage,
  WrapperTextListingHeader,
} from './Homepage.styled';

import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';

import {
  GET_ALL_GROUPS,
  GET_INSPIRE_CONTENT,
  GET_TUTORIALS,
} from '@src/apollo/query';
import { GET_PAGE_DATA_HOME } from '@src/apollo/queryPages';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
// import AppBanner from '@components/molecules/appBanner/AppBanner';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
// import CommunityBanner from '@components/molecules/communityBanner/CommunityBanner';
// import ExploreAreaBanner from '@components/organisms/exploreAreaBanner/ExploreAreaBanner';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import Layout from '@components/organisms/layout/Layout';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import PageHero from '@components/organisms/pageHero/PageHero';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import ThumbnailSlider from '@components/organisms/thumbnailSlider/ThumbnailSlider';
import UrlsContext from '@context/UrlsContext';
// import { dataToGroupBoxViewModel } from '@src/utils/dataToGroupBoxViewModel';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
// import { exploreBannerArtworks } from './Homepage.data';
// import { shuffleArray } from '@src/utils/shuffleArray';
import { useIsMobile } from '@src/utils/useIsMobile';
// import { validURL } from '@src/utils/validURL';

const Homepage = props => {
  const authContext = useContext(AuthContext);
  const UrlContext = useContext(UrlsContext);
  const isMobile = useIsMobile();

  const [heroData, setHeroData] = useState();
  // const [myGroups, setMyGroups] = useState();
  // const [shuffledGroups, setShuffledGroups] = useState([]);
  const [excludedIds, setExcludedIds] = useState([]);

  // PAGE DATA
  const {
    loading: loadingPageData,
    error: errorPageData,
    data: pageData,
    called: pageDataCalled,
  } = useQuery(GET_PAGE_DATA_HOME, {
    variables: {
      slug: authContext.isAuthenticated
        ? 'home-signed-in'
        : 'home-not-signed-in',
    },
    context: { clientName: 'public' },
  });

  // USERCONTENTS
  const [
    getMixedContent,
    {
      loading: loadingMixedContent,
      error: errorMixedContent,
      data: dataMixedContent,
    },
  ] = useLazyQuery(GET_INSPIRE_CONTENT, {
    variables: {
      limit: 4,
      exclude_ids: excludedIds,
    },
    context: { clientName: 'public' },
  });

  const [getTutorials, { data: dataTutorialsBlock }] = useLazyQuery(
    GET_TUTORIALS,
    {
      variables: {
        contentType: 'tutorial',
        limit: 4,
        excludedIds,
      },
      context: { clientName: 'public' },
    }
  );

  // const {
  //   data: dataAllGroups,
  //   loading: loadingAllGroups,
  //   error: errorAllGroups,
  //   called: calledAllGroups,
  // } = useQuery(GET_ALL_GROUPS, {
  //   context: { clientName: 'public' },
  // });

  // useEffect(() => {
  //   if (dataAllGroups?.groups) {
  //     setShuffledGroups(
  //       shuffleArray(
  //         dataAllGroups.groups.map(group => dataToGroupBoxViewModel(group))
  //       ).slice(0, 6)
  //     );
  //   }
  // }, [dataAllGroups?.groups]);

  useEffect(() => {
    if (pageData?.page) {
      const heroBg = getImageSrc(
        pageData.page.background_image,
        'page_hero',
        pageData?.page?.header_image_credit
      );

      setExcludedIds(
        [
          pageData?.page?.userContent_feature?.id,
          pageData?.page?.userContent?.id,
        ].filter(el => el !== undefined)
      );
      setHeroData({
        heading: {
          text: pageData.page.title,
          level: 'medium',
        },
        subHeading: { text: pageData.page.introduction },
        artwork: {
          artistCredits: {
            name: pageData?.page?.header_image_credit,
            url: pageData?.page?.header_image_credit_link,
          },

          image: {
            ...heroBg,
            alt: `Artwork by ${
              pageData?.page?.header_image_credit
                ? pageData?.page?.header_image_credit
                : 'Autodesk'
            }`,
          },
        },
        actions: authContext.isAuthenticated
          ? pageData.page.learn_more_1?.label && [
              {
                label: pageData.page.learn_more_1.label,
                colorTheme: 'white',
                actionType: 'externalLink',
                externalUrl: pageData.page.learn_more_1.url,
                target: '_blank',
              },
            ]
          : [
              {
                label: 'Create an account',
                colorTheme: 'whiteToBlack',
                actionType: 'internalLink',
                internalUrl: GetUrl('join', UrlContext),
              },
              {
                label: 'Sign in',
                colorTheme: 'reverse',
                actionType: 'internalLink',
                internalUrl: GetUrl('login', UrlContext),
              },
            ],
      });
    }
  }, [pageData]);

  useEffect(() => {
    if (excludedIds) {
      getMixedContent();
      getTutorials();
    }
  }, [excludedIds]);

  const isPageLoaded = !loadingPageData && !errorPageData && pageDataCalled;
  // !loadingAllGroups &&
  // !errorAllGroups &&
  // calledAllGroups;

  return (
    <Layout location={props.location}>
      <SEO slug="homepage" seomatic={props?.pageContext?.entry?.seomatic} />
      {loadingPageData && (
        <LoaderSkeleton height="560px" width="100%" css={{ mb: '$64' }} />
      )}
      {!!pageData?.page?.background_image && <PageHero heroData={heroData} />}
      <LandingPageWrapper
        css={{
          mt: !!pageData?.page?.background_image ? '0px' : '$24',
          '@bp1': {
            mt: !!pageData?.page?.background_image ? '0px' : '$48',
          },
        }}
      >
        <SectionWrapper>
          <WrapperHighLight>
            {pageData?.page?.userContent && !loadingPageData && (
              <Thumbnail
                {...dataToThumbnailViewModel(
                  pageData?.page?.userContent,
                  false,
                  authContext.isAuthenticated
                )}
                isFeatured={!isMobile}
                displayType
              />
            )}
            {loadingPageData && (
              <LoaderSkeleton
                height="870px"
                width="100%"
                isFeatured={!isMobile}
                isRadius
              />
            )}
            {dataMixedContent?.inspirePageContent.length >= 1 ? (
              dataMixedContent?.inspirePageContent.map((item, i) => {
                return (
                  <Thumbnail
                    key={item.id}
                    displayType
                    {...dataToThumbnailViewModel(
                      item,
                      false,
                      authContext.isAuthenticated
                    )}
                  />
                );
              })
            ) : (
              <>
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
              </>
            )}
          </WrapperHighLight>
        </SectionWrapper>
        <SectionWrapper>
          {dataMixedContent?.userContents?.data.length >= 1 && (
            <ButtonSpacer>
              <Button
                type="normal"
                colorTheme={isMobile ? 'gray' : 'normal'}
                css={{ width: isMobile ? '100%' : 'auto' }}
                actionType="internalLink"
                label={<Text>EXPLORE MORE</Text>}
                internalUrl={GetUrl('inspire', UrlContext)}
              />
            </ButtonSpacer>
          )}
        </SectionWrapper>

        {/* {dataAllGroups?.groups.length >= 1 ? (
          <CommunityBanner
            groupList={shuffledGroups}
            myGroupsIds={(myGroups || []).map(gr => gr.groupId)}
            isLogged={authContext.isAuthenticated}
          />
        ) : (
          <LoaderSkeleton height="400px" width="100%" css={{ mb: '$32' }} />
        )} */}
        <SectionWrapper>
          <Inverter isLogged={authContext.isAuthenticated}>
            <div className={isPageLoaded ? 'page-loaded' : ''}>
              <WrapperListingHeader>
                <WrapperTextListingHeader>
                  <ListingHeader
                    title="page_home_learn_section_title_part1"
                    isCMSDynamic
                    // isArticles
                  />
                </WrapperTextListingHeader>
                <StyledButton
                  label={<String id="general_see_more" />}
                  actionType="internalLink"
                  internalUrl={GetUrl('learnTutorials', UrlContext)}
                  type="normal"
                  colorTheme="normal"
                />
              </WrapperListingHeader>
              {!isMobile && (
                <WrapperHighLight>
                  {pageData?.page?.userContent_feature && !loadingPageData && (
                    <Thumbnail
                      {...dataToThumbnailViewModel(
                        pageData?.page?.userContent_feature,
                        false,
                        authContext.isAuthenticated
                      )}
                      isFeatured={!isMobile}
                      displayType
                    />
                  )}
                  {loadingPageData && (
                    <LoaderSkeleton
                      height="870px"
                      width="100%"
                      isFeatured={!isMobile}
                      isRadius
                    />
                  )}
                  {dataTutorialsBlock?.userContents?.data.length >= 1 ? (
                    dataTutorialsBlock?.userContents?.data.map((item, i) => {
                      return (
                        <Thumbnail
                          key={item.id}
                          displayType
                          {...dataToThumbnailViewModel(
                            item,
                            false,
                            authContext.isAuthenticated
                          )}
                        />
                      );
                    })
                  ) : (
                    <>
                      <LoaderSkeleton height="420px" width="100%" isRadius />
                      <LoaderSkeleton height="420px" width="100%" isRadius />
                      <LoaderSkeleton height="420px" width="100%" isRadius />
                      <LoaderSkeleton height="420px" width="100%" isRadius />
                    </>
                  )}
                </WrapperHighLight>
              )}
              {pageData?.page?.userContent && isMobile && (
                <ThumbnailSlider
                  title={<String id="page_home_learn_section_title" />}
                  isInternalLink
                  thumbnailList={[
                    pageData?.page?.userContent,
                    ...(dataTutorialsBlock?.userContents?.data || []),
                  ].map(el =>
                    dataToThumbnailViewModel(
                      el,
                      false,
                      authContext.isAuthenticated
                    )
                  )}
                  type="articles"
                  btnUrl={GetUrl('inspireArticles', UrlContext)}
                />
              )}
            </div>
          </Inverter>
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};

export default Homepage;
