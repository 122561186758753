import {
  CommentsWrapper,
  CommunityCommentBoxWrapper,
  PreviousCommentsBtn,
} from './CommunityCommentBox.styled';
import React, { useEffect, useState, useContext } from 'react';

import { CREATE_POST_REPORT } from '@src/apollo/mutation';
import CommentBox from '@components/organisms/communityCommentBox/commentBox/CommentBox';
import CommunityAddPost from '@components/molecules/communityAddPost/CommunityAddPost';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { useIsMobile } from '@src/utils/useIsMobile';
import { useMutation } from '@apollo/client';
import CommentWireFrame from '@components/molecules/communityPost/wireframe/CommentWireFrame';
import CommunityPostContext from '@context/CommunityPostContext';

const CommunityCommentBox = props => {
  const [postReport, dataPostReport] = useMutation(CREATE_POST_REPORT);

  const [commentsState, setCommentsState] = useContext(CommunityPostContext);

  // useEffect(() => {
  //   setCommentsState({
  //     ...commentsState,
  //     replyingToWhich: '',
  //   });
  // }, [props.postComments]);

  // useEffect(() => {
  //   setCommentsState({
  //     ...commentsState,
  //     limit: props.limit,
  //     showPreviousComments: false,
  //   });
  // }, [props.limit]);

  const isMobile = useIsMobile();

  const onReplyClick = (e, artist, artistProfilUrl, level) => {
    setCommentsState({
      ...commentsState,
      replyingToWhich: e,
      artistName: `@${artist}`,
      artistProfilUrl,
    });
  };

  const onReplyDeleteClick = (id, siblingTotal, parentId, isReply) => {
    if (isReply) {
      const newArray = commentsState.whichCommentExpended;

      let index;
      let newTotal;
      if (siblingTotal) {
        if (commentsState.commentCount === null) {
          newTotal = siblingTotal - 1;
        } else {
          newTotal = commentsState.commentCount - 1;
        }
        if (newTotal === 0) {
          if (newArray.indexOf(parentId) !== -1) {
            index = newArray.indexOf(parentId);
            newArray.splice(index, 1);
          }
        }
      }

      setCommentsState({
        ...commentsState,
        whichCommentExpended: newArray,
        commentCount: newTotal,
      });
    }

    props.onDeleteClick(id, !isReply);
  };

  const onReportClick = (postId, type) => {
    postReport({
      variables: {
        postId: postId,
        type: type,
      },
    });
  };

  const [optionsIds, setOptionsId] = useState();

  const openOptionsId = id => {
    setOptionsId(id);
  };

  const updateComment = (data, isReply, parentId) => {
    const commentsList = commentsState.postComments;

    if (isReply) {
      commentsState.postComments.map((post, i) => {
        if (post.commentId === parentId) {
          post.updatedData = data;
        }
      });
    } else {
      commentsList.map((post, i) => {
        if (post.commentId === post.id) {
          post.postText = post.content;

          const postImages = [];

          post.images ? (
            <>
              {post.images.map((image, i) => {
                const imageContent = {
                  src: {
                    src: CDN_URL + image.path,
                    srcset: `${CDN_URL + image.path} 1x,${
                      CDN_URL + image.path
                    }  250w,${CDN_URL + image.path}  500w`,
                  },
                  alt: 'My new Post',
                  id: image.id,
                };
                postImages.push({ ...image, ...imageContent });
              })}
            </>
          ) : null;
          post.postImages = postImages;

          let postLinkPreview = null;

          if (post.link_url !== null && post.link_url !== '') {
            postLinkPreview = {
              url: post.link_url,
              title: post.link_title,
              description: post.link_description,
              previewImage: post.link_image
                ? {
                    src: {
                      src: CDN_URL + post.link_image.path,
                    },
                    source: [
                      {
                        srcset: `${CDN_URL}${post.link_image[1]?.path}`,
                        media: '(min-width: 769px)',
                      },
                      {
                        srcset: `${CDN_URL}${post.link_image[0]?.path}`,
                        media: '(max-width: 768px)',
                      },
                    ],
                    alt: post.link_url,
                    id: post.link_image.id,
                  }
                : null,
            };
          }
          post.postLinkPreview = postLinkPreview;
        }
      });
    }

    setCommentsState({
      ...commentsState,
      postComments: commentsList,
    });
  };

  return (
    <CommunityCommentBoxWrapper>
      <CommentsWrapper>
        {props.hasMorePages && (
          <PreviousCommentsBtn
            onClick={props.onPreviousCommentsClick}
            isVisible={commentsState.showPreviousComments}
            isDisabled={props.loading}
          >
            <String id="general_previous_comments_text" />
          </PreviousCommentsBtn>
        )}
        {props.loading ? <CommentWireFrame /> : null}

        {commentsState.postComments.map((comment, i) => {
          return (
            <CommentBox
              key={`comment-${i}`}
              {...comment}
              {...props.userInfos}
              //onShowRepliesClick={onShowRepliesClick}
              //whichCommentExpended={commentsState.whichCommentExpended}
              commentId={comment.commentId}
              repliesCount={comment.repliesCount}
              indexId={i}
              onReplyClick={onReplyClick}
              replyingToWhich={commentsState.replyingToWhich}
              whichLiked={commentsState.whichLiked}
              replyingTo={commentsState.artistName}
              artistProfilUrl={commentsState.artistProfilUrl}
              onSaveClick={props.onSaveClick}
              isMobile={isMobile}
              isLogged={props.isLogged}
              commentImages={comment.postImages}
              groupName={props.groupName}
              groupUrl={props.groupUrl}
              groupId={props.groupId}
              parentId={comment.commentId}
              onReportClick={onReportClick}
              isCurrentUserGroupMember={props.isCurrentUserGroupMember}
              onJoinGroup={props.onJoinGroup}
              onDeleteClick={onReplyDeleteClick}
              isVerified={props.isVerified}
              isOnboarded={props.isOnboarded}
              commentCount={comment.commentCount}
              isReported={props.isReported}
              groupRole={props.groupRole}
              generalRole={props.generalRole}
              isBlocked={props.isBlocked}
              commentAuthor={comment.postAuthor.username}
              optionsIds={optionsIds}
              openOptionsId={openOptionsId}
              updateComment={updateComment}
              refetch={props.refetch}
            />
          );
        })}
      </CommentsWrapper>
      <CommunityAddPost
        {...props.userInfos}
        onSaveClick={props.onSaveClick}
        isLogged={props.isLogged}
        isVerified={props.isVerified}
        isOnboarded={props.isOnboarded}
        groupName={props.groupName}
        isAtBottom
        isCurrentUserGroupMember={props.isCurrentUserGroupMember}
        isComment
        onJoinGroup={props.onJoinGroup}
        parentId={props.parentId}
        groupId={props.groupId}
        isReported={props.isReported}
        groupRole={props.groupRole}
        isBlocked={props.isBlocked}
        myGroupsId={props.myGroupsId}
      />
    </CommunityCommentBoxWrapper>
  );
};

CommunityCommentBox.propTypes = {
  comments: PropTypes.array,
  userInfos: PropTypes.object,
};

export default CommunityCommentBox;
