import React, { useContext, useState, useEffect } from 'react';

import Button from '@components/atoms/button/Button';
import { GET_PROFILE_INFO } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import FormErrorMsg from '@components/atoms/formErrorMsg/FormErrorMsg';
import InputGooglePlacesLocation from '@components/atoms/input/inputGooglePlacesLocation/InputGooglePlacesLocation';
import InputRadio from '@components/atoms/input/inputRadio/InputRadio';
import { InputText } from '@components/atoms/input/inputText/InputText';
import { ProfileEditWrapper } from './ProfileEdit.styled';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import { Text } from '@components/atoms/text/Text';
import { UPDATE_PROFILE } from '@src/apollo/mutation';
import { useMutation } from '@apollo/client';
import AuthContext from '@src/context/AuthContext';
import Svg from '@components/atoms/svg/Svg';
import { validWebsiteUrl } from '@src/utils/validWebsiteUrl';

const ProfileEdit = props => {
  const stringsContext = useContext(StringsContext);

  const authContext = useContext(AuthContext);

  const [updateProfile, { loading, errors, data }] = useMutation(
    UPDATE_PROFILE,
    {
      onCompleted: () => {
        props.onClose();
      },
    }
  );

  const instagramUrl = props.profileData.socials.instagramUrl.url;
  const behanceUrl = props.profileData.socials.behanceUrl.url;
  const linkedinUrl = props.profileData.socials.linkedinUrl.url;
  const youtubeUrl = props.profileData.socials.youtubeUrl.url;
  const websiteUrl = props.profileData.socials.personnalWebsite.url;
  const artworksLabel = stringsContext['thumbnail_artwork']?.field + 's';
  const tutorialsLabel = stringsContext['thumbnail_tutorial']?.field + 's';
  const articlesLabel = stringsContext['thumbnail_article']?.field + 's';

  const [profileState, setProfileState] = useState({
    submittingForm: false,
    isFormError: false,
    displayFormError: false,
    occupation: props.profileData?.title || '',
    gender: props.profileData?.gender || '',
    location: props.profileData.location,
    locationFull: props.profileData.locationFull,
    locationPlaceId: '',
    locationCity: '',
    instagram: instagramUrl,
    behance: behanceUrl,
    youtube: youtubeUrl,
    linkedin: linkedinUrl,
    personalWebsite: websiteUrl,
    firstTab: props.profileData.navigation_tab,
    firstname: props.profileData?.firstname,
    lastname: props.profileData?.lastname,
    instagramIsError: false,
    youtubeIsError: false,
    linkedinIsError: false,
    behanceIsError: false,
    personalWebsiteIsError: false,
  });

  const options = [
    {
      name: 'Artworks',
      id: 'Artworks',
      label: artworksLabel,
      value: 'artworks',
    },
    {
      name: 'Assets',
      id: 'Assets',
      label: 'Assets',
      value: 'assets',
    },
    {
      name: 'Tutorials',
      id: 'Tutorials',
      label: tutorialsLabel,
      value: 'tutorials',
    },
    {
      name: 'Articles',
      id: 'Articles',
      label: articlesLabel,
      value: 'articles',
    },
    {
      name: 'Resume',
      id: 'Resume',
      label: <String id="profile_resume_tab_text" />,
      value: 'resume',
    },
  ];
  const validateUrl = (url, media) => {
    const linkedinRegex = /^(https?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
    const instagramRegex = /^https?:\/\/www\.instagram\.com\/[a-z].{3}/;
    const behanceRegex = /^https?:\/\/www\.behance\.net\/[a-z].{3}/;
    const youtubeRegex = /^https?:\/\/www\.youtube\.com\/((channel|c)\/)?(?!feed|user\/|watch\?)([a-zA-Z0-9-_.])*.*/;

    switch (media) {
      //Instagram
      case 'instagram':
        if (!url.length) {
          setProfileState(prev => ({
            ...prev,
            instagramIsError: false,
          }));
          return true;
        }

        if (!instagramRegex.test(url)) {
          setProfileState(prev => ({
            ...prev,
            instagramIsError: true,
          }));
          return false;
        } else {
          setProfileState(prev => ({
            ...prev,
            instagramIsError: false,
          }));
          return true;
        }
        break;
      //Behance
      case 'behance':
        if (!url.length) {
          setProfileState(prev => ({
            ...prev,
            behanceIsError: false,
          }));
          return true;
        }
        if (!behanceRegex.test(url)) {
          setProfileState(prev => ({
            ...prev,
            behanceIsError: true,
          }));
          return false;
        } else {
          setProfileState(prev => ({
            ...prev,
            behanceIsError: false,
          }));
          return true;
        }
        break;
      //LinkedIn
      case 'linkedin':
        if (!url.length) {
          setProfileState(prev => ({
            ...prev,
            linkedinIsError: false,
          }));
          return true;
        }
        if (!linkedinRegex.test(url)) {
          setProfileState(prev => ({
            ...prev,
            linkedinIsError: true,
          }));
          return false;
        } else {
          setProfileState(prev => ({
            ...prev,
            linkedinIsError: false,
          }));
          return true;
        }
        break;

      //YouTube
      case 'youtube':
        if (!url.length) {
          setProfileState(prev => ({
            ...prev,
            youtubeIsError: false,
          }));
          return true;
        }
        if (!youtubeRegex.test(url)) {
          setProfileState(prev => ({
            ...prev,
            youtubeIsError: true,
          }));
          return false;
        } else {
          setProfileState(prev => ({
            ...prev,
            youtubeIsError: false,
          }));
          return true;
        }
        break;
      //Personal Website
      case 'personalWebsite':
        if (!url.length) {
          setProfileState(prev => ({
            ...prev,
            personalWebsiteIsError: false,
          }));
          return true;
        }
        if (!validWebsiteUrl(url)) {
          setProfileState(prev => ({
            ...prev,
            personalWebsiteIsError: true,
          }));
          return false;
        } else {
          setProfileState(prev => ({
            ...prev,
            personalWebsiteIsError: false,
          }));
          return true;
        }

      default:
    }
  };

  const onSave = () => {
    setProfileState({
      ...profileState,
      submittingForm: true,
    });
    const socialMediaValidations = [
      {
        state: profileState.instagram,
        media: 'instagram',
      },
      {
        state: profileState.behance,
        media: 'behance',
      },
      {
        state: profileState.linkedin,
        media: 'linkedin',
      },
      {
        state: profileState.youtube,
        media: 'youtube',
      },
      {
        state: profileState.personalWebsite,
        media: 'personalWebsite',
      },
    ];

    let valid = true;

    socialMediaValidations.forEach(function (item) {
      const validationResult = validateUrl(item.state, item.media);
      if (!validationResult) {
        valid = false;
      }
      return;
    });

    if (!valid) {
      setProfileState(prev => ({
        ...prev,
        displayFormError: true,
      }));
    } else {
      setProfileState(prev => ({
        ...prev,
        displayFormError: false,
      }));
      const variables = {
        title: profileState.occupation,
        gender: profileState.gender,
        navigation_tab: profileState.firstTab,
        first_name: profileState.firstname,
        last_name: profileState.lastname,
        user_id: props.profileData.user_id,
        instagram_url: profileState.instagram,
        behance_url: profileState.behance,
        youtube_url: profileState.youtube,
        linkedin_url: profileState.linkedin,
        website_url: profileState.personalWebsite,
      };

      if (profileState.locationPlaceId) {
        variables.location = {
          placeId: profileState.locationPlaceId,
        };
      }

      const hasFirstName =
        profileState.firstname !== null && profileState.firstname !== '';
      const hasLastName =
        profileState.lastname !== null && profileState.lastname !== '';
      if (hasFirstName && hasLastName) {
        updateProfile({
          variables,
          refetchQueries: [
            {
              query: GET_PROFILE_INFO,
              variables: { username: props.profileData?.username },
            },
          ],
        });
        authContext.updateName(profileState.firstname, profileState.lastname);
        props.onSave({
          title: profileState.occupation,
          location: profileState.location,
          locationCity: profileState.locationCity,
          gender: profileState.gender,
          locationFull: profileState.locationFull,
          instagram_url: profileState.instagram,
          behance_url: profileState.behance,
          youtube_url: profileState.youtube,
          linkedin_url: profileState.linkedin,
          website_url: profileState.personalWebsite,
          navigation_tab: profileState.firstTab,
          locationPlaceId: profileState.locationPlaceId,
          firstname: profileState.firstname,
          lastname: profileState.lastname,
        });
      } else {
        if (!hasFirstName) {
          setProfileState({
            ...profileState,
            firstnameIsError: true,
            firstnameErrorMessage: 'profile_input_empty_field',
          });
        }
        if (!hasLastName) {
          setProfileState({
            ...profileState,
            lastnameIsError: true,
            lastnameErrorMessage: 'profile_input_empty_field',
          });
        }
      }
    }
  };

  const handleBlur = (e, media) => {
    e.preventDefault();
    validateUrl(e.target.value, media);
  };

  const onChange = e => {
    const { value } = e.target;
    const { name } = e.target;
    const updatedState = { ...profileState };
    updatedState[name] = value;
    setProfileState(updatedState);
  };

  const onLocationChange = data => {
    const { country } = data;
    const { place_id } = data;
    const { label } = data;
    const { city } = data;
    const updatedState = { ...profileState };
    updatedState.location = country;
    updatedState.locationPlaceId = place_id;
    updatedState.locationFull = label;
    updatedState.locationCity = city;

    setProfileState(updatedState);
  };
  useEffect(() => {
    if (
      profileState.instagramIsError ||
      profileState.behanceIsError ||
      profileState.linkedinIsError ||
      profileState.youtubeIsError ||
      profileState.personalWebsiteIsError
    ) {
      setProfileState({
        ...profileState,
        isFormError: true,
      });
      return;
    }
  }, [
    profileState.instagramIsError,
    profileState.behanceIsError,
    profileState.linkedinIsError,
    profileState.youtubeIsError,
    profileState.personalWebsiteIsError,
  ]);

  // useEffect(() => {
  //   if (profileState.submittingForm && profileState.isFormError) {
  //     setProfileState({
  //       ...profileState,
  //       displayFormError: true,
  //     });
  //     return;
  //   }
  // }, [profileState.submittingForm, profileState.isFormError]);

  return (
    <ProfileEditWrapper>
      <Heading
        level="h3"
        weight="legendBold"
        css={{ lineHeight: '1.3em', marginBottom: '$24' }}
      >
        <String id="profile_edit_profile_text" />
      </Heading>
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="firstname"
        label={<String id="edit_first_name" />}
        css={{ marginBottom: '$12' }}
        value={profileState.firstname}
        onChange={onChange}
        isError={profileState?.firstnameIsError}
        error={<String id={profileState?.firstnameErrorMessage} />}
        required
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="lastname"
        label={<String id="edit_last_name" />}
        css={{ marginBottom: '$12' }}
        value={profileState.lastname}
        onChange={onChange}
        isError={profileState?.lastnameIsError}
        error={<String id={profileState?.lastnameErrorMessage} />}
        required
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="occupation"
        label={<String id="edit_occupation" />}
        css={{ marginBottom: '$12' }}
        value={profileState.occupation}
        onChange={onChange}
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="gender"
        label={<String id="edit_gender" />}
        css={{ marginBottom: '$12' }}
        value={profileState.gender}
        onChange={onChange}
      />
      <InputGooglePlacesLocation
        onLocationChange={onLocationChange}
        location={profileState.location}
        locationFull={profileState.locationFull}
        autoCompleteRequestTypes={['(cities)']}
        label="profile_gplaces_input_label"
      />

      <Heading
        weight="legendBold"
        level="h6"
        css={{ fontSize: '$16', marginBottom: '$8' }}
      >
        <String id="profile_edit_social_text" />
      </Heading>
      <Text size="12" css={{ marginBottom: '$16', '@bp1': { mb: '$24' } }}>
        <String id="profile_edit_add_socials_links_text" />
      </Text>
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="instagram"
        css={{ marginBottom: '$16' }}
        onChange={onChange}
        prefixLabel={<Svg icon="instagram" />}
        value={profileState.instagram}
        isError={profileState.instagramIsError}
        error={<String id="form_field_error_instagram" />}
        onBlur={e => {
          handleBlur(e, 'instagram');
        }}
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="behance"
        css={{ marginBottom: '$16' }}
        onChange={onChange}
        prefixLabel={<Svg icon="behance" />}
        value={profileState.behance}
        isError={profileState.behanceIsError}
        onBlur={e => {
          handleBlur(e, 'behance');
        }}
        error={<String id="form_field_error_behance" />}
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="linkedin"
        css={{ marginBottom: '$16' }}
        onChange={onChange}
        prefixLabel={<Svg icon="linkedin" />}
        value={profileState.linkedin}
        isError={profileState.linkedinIsError}
        error={<String id="form_field_error_linkedin" />}
        onBlur={e => {
          handleBlur(e, 'linkedin');
        }}
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="youtube"
        css={{ marginBottom: '$16' }}
        onChange={onChange}
        prefixLabel={<Svg icon="youtube" />}
        value={profileState.youtube}
        isError={profileState.youtubeIsError}
        error={<String id="form_field_error_youtube" />}
        onBlur={e => {
          handleBlur(e, 'youtube');
        }}
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="personalWebsite"
        css={{ marginBottom: '$32' }}
        onChange={onChange}
        prefixLabel={<Svg icon="internet" />}
        value={profileState.personalWebsite}
        isError={profileState.personalWebsiteIsError}
        error={<String id="form_field_error_website" />}
        onBlur={e => {
          handleBlur(e, 'personalWebsite');
        }}
      />

      <Heading
        weight="legendBold"
        level="h6"
        css={{ fontSize: '$16', marginBottom: '$8' }}
      >
        <String id="profile_edit_navigation_text" />
      </Heading>
      <InputRadio
        name="firstTab"
        label={<String id="profile_edit_choose_first_tab_text" />}
        options={options}
        selectedValue={profileState.firstTab}
        css={{ marginBottom: '$40' }}
        onChange={onChange}
        mobileVerticalDispay
        isProfileEdit
      />

      {profileState.displayFormError && (
        <FormErrorMsg
          title={<String id="form_general_error_title" />}
          desc={<String id="form_general_error_desc" />}
        />
      )}

      <Button
        type="normal"
        label={<String id="general_cancel_btn_text" />}
        colorTheme="gray"
        onClick={props.onClose}
        actionType="onclick"
        css={{ marginRight: '$16' }}
      />
      <Button
        type="normal"
        label={<String id="general_save_btn_text" />}
        colorTheme="normal"
        onClick={onSave}
        actionType="onclick"
        isLoading={loading}
      />
    </ProfileEditWrapper>
  );
};

export default ProfileEdit;
