import {
  ButtonsWrapper,
  ContentWrapper,
  ModalContent,
  StyledButton,
} from './CreateModal.styled';

import { Heading } from '@components/atoms/heading/Heading';
import Image from '@components/atoms/image/Image';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

function CreateModal(props) {
  const { hide, actions, modalType, isShowing, createType } = props;

  const image = modalType === 'publish' && {
    alt: 'publish banner',
    src: '/images/bifrost_final_banner.jpg',
  };

  return (
    <>
      <Modal isShowing={isShowing} hide={hide} hasImageHeader={image !== false}>
        {image && <Image isStatic src={image} alt={image.alt} />}
        <ModalContent>
          <Heading
            level={modalType === 'publish' ? 'h1' : 'h3'}
            css={{ fontFamily: '$ArtifaktLegendBold', lineHeight: '$1', mt: '$32' }}
          >
            <String
              id={`create_build${
                createType ? `_${createType}` : ''
              }_${modalType}_notification_title`}
            />
          </Heading>
          <ContentWrapper>
            <Text as="p">
              <String
                id={`create_build${
                  createType ? `_${createType}` : ''
                }_${modalType}_notification_text`}
              />
            </Text>
          </ContentWrapper>
          <ButtonsWrapper>
            {actions.map((action, i) => (
              <StyledButton
                key={`create_build${
                  createType ? `_${createType}` : ''
                }_${modalType}_notification_link_${i}`}
                type="normal"
                label={
                  action.label || (
                    <String
                      id={`create_build${
                        createType ? `_${createType}` : ''
                      }_${modalType}_notification_link${
                        actions.length > 1 ? `_${i + 1}` : ''
                      }`}
                    />
                  )
                }
                colorTheme={action.colorTheme}
                actionType="onclick"
                onClick={action.onClick}
                svgIcon={action.svgIcon}
              />
            ))}
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  );
}

CreateModal.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  modalType: PropTypes.oneOf(['publish', 'draft', 'nsfw', 'pending'])
    .isRequired,
  createType: PropTypes.oneOf(['artwork', 'tutorial', 'article']).isRequired,
  image: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      colorTheme: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      svgIcon: PropTypes.string.isRequired,
    })
  ),
};

CreateModal.defaultProps = {
  isShowing: false,
  hide: undefined,
  actions: [],
  image: undefined,
};

export default CreateModal;
