import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { GET_TOOLS } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import SelectTools from '@components/molecules/selectTools/SelectTools';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { ToolsEditContainer } from './ModalEdit.styled';
import { UPDATE_PROFILE_TOOLS } from '@src/apollo/mutation';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import { sortArrayByTitle } from '@src/utils/sortArray';

const ToolsEdit = props => {
  const authContext = useContext(AuthContext);
  const { loading, error, data } = useQuery(GET_TOOLS);
  const [
    updateProfileTools,
    { loading: loadingTools, error: errorTools, data: dataTools },
  ] = useMutation(UPDATE_PROFILE_TOOLS, {
    onCompleted: () => {
      // Parse Tools for profile sidebar
      let updatedToolsList = toolsState.toolsList.map(tool => {
        return tool.isSelected ? tool : false;
      });
      updatedToolsList = updatedToolsList.filter(toolId => {
        return toolId ? true : false;
      });
      props.onClose();
      props.onToolEditSave(parseToolsList(updatedToolsList));
    },
  });

  useEffect(() => {
    if (dataTools && dataTools.updateUserProfile.profile_completion >= 70) {
      authContext.setIsOnboarded();
    }
  }, [dataTools]);

  const [toolsError, setToolsError] = useState();

  const [toolsState, setToolsState] = useState({
    isDataSet: false,
    toolsList: [],
  });

  const setData = data => {
    const toolsList = [];
    const toolsSorted = data.tools.slice().sort(sortArrayByTitle);
    toolsSorted.forEach(tool => {
      const toolSingle = {
        id: tool.id.toString(),
        value: tool.title,
        name: tool.title,
        imageFileName: {
          path: tool.icon.path,
        },
        isSelected: false,
      };
      props.selectedTools.forEach(selectedTool => {
        if (toolSingle.id == selectedTool.id) {
          toolSingle.isSelected = true;
        }
      });
      toolsList.push(toolSingle);
    });

    setToolsState({
      ...toolsState,
      isDataSet: true,
      toolsList: toolsList,
      wasDataSetBefore: props.selectedTools.length > 0,
    });
  };

  if (!loading && !toolsState.isDataSet) {
    setData(data);
  }

  const onToolsSelect = index => {
    const updatedToolsList = JSON.parse(JSON.stringify(toolsState.toolsList));
    updatedToolsList[index].isSelected = !updatedToolsList[index].isSelected;
    setToolsState({
      ...toolsState,
      toolsList: updatedToolsList,
    });
  };

  const parseToolsList = updatedToolsList => {
    const parsedTools = [];
    updatedToolsList.forEach(tool => {
      const toolSingle = {
        id: tool.id,
        alt: tool.name,
        imageDynamic: {
          src: tool.imageFileName,
        },

        infoboxActive: true,
        infoboxTitle: tool.name,
      };
      parsedTools.push(toolSingle);
    });
    return parsedTools;
  };

  const onSave = () => {
    // Parse Tools for apollo mutation

    const selectedTools = toolsState.toolsList.filter(el => el.isSelected);
    if (selectedTools.length > 0) {
      let selectedToolsIds = toolsState.toolsList.map(tool => {
        return tool.isSelected ? parseInt(tool.id) : false;
      });
      selectedToolsIds = selectedToolsIds.filter(toolId => {
        return toolId ? true : false;
      });
      updateProfileTools({
        variables: { user_id: props.userId, tools: selectedToolsIds },
      });

      // Parse Tools for profile sidebar
      let updatedToolsList = toolsState.toolsList.map(tool => {
        return tool.isSelected ? tool : false;
      });
      updatedToolsList = updatedToolsList.filter(toolId => {
        return toolId ? true : false;
      });
    } else {
      setToolsError('empty_tools');
    }
  };

  return (
    <ToolsEditContainer>
      {!loading ? (
        <>
          <Heading
            level="h3"
            weight="legendBold"
            css={{ lineHeight: '1.3em', marginBottom: '$24' }}
          >
            <String id="profile_edit_tools_title" />
          </Heading>
          <Text css={{ marginBottom: '$24' }}>
            <String id="profile_edit_tools_text" />
          </Text>
          <SelectTools
            toolsList={toolsState.toolsList}
            onToolsSelect={onToolsSelect}
            css={{ marginBottom: toolsError ? '0' : '$48' }}
          ></SelectTools>
          {toolsError && (
            <Text
              css={{
                marginBottom: '$48',
                marginTop: '$8',
                color: '$red',
                fontFamily: '$ArtifaktLegendBold',
              }}
            >
              <String id={`profile_tools_error_${toolsError}`} />
            </Text>
          )}
          <Button
            type="normal"
            label="Cancel"
            colorTheme="gray"
            onClick={props.onClose}
            actionType="onclick"
            css={{ marginRight: '$16' }}
          />
          <Button
            type="normal"
            label="Save"
            colorTheme="normal"
            onClick={onSave}
            actionType="onclick"
            isLoading={loadingTools}
          />
        </>
      ) : (
        <>
          <LoaderSkeleton height="40px" width="100%" />
          <LoaderSkeleton height="100px" width="100%" />
        </>
      )}
    </ToolsEditContainer>
  );
};

export default ToolsEdit;
