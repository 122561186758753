import { styled } from '@root/stitches.config';

export const CreateWarningWrapper = styled('div', {
  textAlign: 'center',
});

export const WarningContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',  
});

export const WarningCol = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'left',

  width: '100%',
  h2: {
    fontSize: '$42',
  },
  '@bp2': {
    h2: {
      fontSize: '$42',
    },
  },

  li: {
    '&:before': {
      content: 'url(/svg/cross-red.svg)',
      display: 'inline-block',
      width: '17px',
      height: '11px',
      position: 'absolute',
      left: 0,
      top: '6px',
    },
  },

  '&:first-of-type': {
    marginBottom: '$32',
    li: {
      '&:before': {
        content: 'url(/svg/check-green.svg)',
        display: 'inline-block',
        width: '17px',
        height: '11px',
        position: 'absolute',
        left: 0,
        top: '6px',
      },
    },
  },
});

export const WarningFooter = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const WarningFooterWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Wysiwyg = styled('div', {
  li: {
    fontSize: '$14',
    marginBottom: '$16',
    paddingLeft: '$32',
    position: 'relative',
  },
  b: {
    fontSize: '$16',
    marginBottom: '$8',
    display: 'inline-block',
  },
});
