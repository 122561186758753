import {
  BackBtn,
  BackLinkText,
  BannerText,
  ContentBottomContainer,
  ContentTopContainer,
  ContentWrapper,
  IconImage,
  LoadingOverlay,
  PageWrapper,
  SidebarWrapper,
  StyledSvg,
  TableWrapper,
  Title,
  TitleContainer,
  TopBanner,
} from './GroupMembersModeration.styled';
import {
  GET_GROUPS_MEMBERS,
  GET_GROUP_BASIC_DETAILS,
  GET_MY_GROUPS_ID,
  GET_POSTS_FILTERED_COUNT,
} from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@context/AuthContext';
import GroupCommunitySideBar from '@components/molecules/groupCommunitySidebar/GroupCommunitySideBar';
import Image from '@components/atoms/image/Image';
import Layout from '@components/organisms/layout/Layout';
import Loader from '@components/atoms/loader/Loader';
import MembersTable from './MembersTable';
import PropTypes from 'prop-types';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import String from '@components/atoms/string/String';

const GroupMembersModerationPage = context => {
  const authContext = useContext(AuthContext);

  const [userRole, setUserRole] = useState('');

  const [groupState, setGroupState] = useState({
    name: '',
    iconUrl: '',
    loaded: false,
    id: null,
    memberCount: null,
  });
  const [groupMembersState, setGroupMembersState] = useState({
    loaded: false,
    limitRow: 12,
    page: 1,
    currentPageCount: null,
    maxPage: 1,
    loading: false,
    filters: {},
    memberCount: null,
  });

  const [membersListState, setMembersList] = useState({
    membersList: [],
  });

  const [groupRoleState, setGroupRole] = useState({
    role: '',
    loaded: false,
  });

  const { loading, error, data, refetch } = useQuery(GET_GROUP_BASIC_DETAILS, {
    variables: {
      slug: context.params.slug,
      feed: 'recent',
    },
    context: { clientName: 'public' },
    fetchPolicy: 'no-cache',
  });

  const [getgroupRoleData, groupRoleData] = useLazyQuery(GET_MY_GROUPS_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (groupState.id !== null) {
      getgroupRoleData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (groupRoleData.data) {
      let role = '';
      for (let i = 0; i < groupRoleData.data.mygroups.length; i++) {
        if (groupRoleData.data.mygroups[i].id === groupState.id) {
          role = groupRoleData.data.mygroups[i].group_role;
        }
      }
      setGroupRole({
        ...groupRoleState,
        role,
        loaded: true,
      });
    }
  }, [groupRoleData.data]);

  const [getMembersData, membersData] = useLazyQuery(GET_GROUPS_MEMBERS, {
    variables: {
      slug: context.params.slug,
      limit: groupMembersState.limitRow,
      page: groupMembersState.page,
      filters: groupMembersState.filters,
    },
    fetchPolicy: 'no-cache',
  });

  const fetchWithFilters = filters => {
    setGroupMembersState({
      ...groupMembersState,
      filters,
      page: 1,
    });
    membersData.refetch();
  };

  useEffect(() => {
    if (data) {
      setGroupState({
        ...groupState,
        name: data.group.name,
        iconUrl: getImageSrc(data.group.icon, 'group_icon', data.group.title),
        loaded: true,
        id: data.group.id,
        memberCount: data.group.members_count,
      });
    }
  }, [data]);

  useEffect(() => {
    if (membersData.data) {
      if (groupMembersState.page === 1) {
        setGroupMembersState({
          ...groupMembersState,
          loaded: true,
          loading: false,
          maxPage: Math.ceil(
            groupState.memberCount / groupMembersState.limitRow
          ),
          currentPageCount: `1 - ${
            groupMembersState.limitRow <
            membersData?.data?.groupMembers?.data?.length
              ? groupMembersState.limitRow
              : membersData?.data?.groupMembers?.data?.length
          }`,
        });
      } else {
        setGroupMembersState({
          ...groupMembersState,
          loaded: true,
          loading: false,
        });
      }

      setMembersList({
        membersList: membersData?.data?.groupMembers?.data,
      });
    }
  }, [membersData.data]);

  useEffect(() => {
    if (groupState.loaded) {
      getMembersData();
    }
  }, [groupState.loaded]);

  useEffect(() => {
    const pageCountFirstNumber =
      (groupMembersState.page - 1) * groupMembersState.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + groupMembersState.limitRow - 1;
    if (pageCountSecondNumber > groupState.memberCount) {
      pageCountSecondNumber = groupState.memberCount;
    }

    setGroupMembersState({
      ...groupMembersState,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
    });
  }, [groupMembersState.limitRow]);

  const groupUrl = context.location.pathname.substring(
    0,
    context.location.pathname.indexOf(`/${context.params.slug}`) +
      `/${context.params.slug}`.length
  );

  const activeTab = context.location.pathname.substring(
    context.location.pathname.indexOf('/admin/') + '/admin/'.length
  );

  const [pendingPostsCount, setPendingPostsCount] = useState(0);
  const [reportedPostsCount, setReportedPostsCount] = useState(0);

  const [getPendingCountData, pendingCountData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'pending',
      },
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getPendingCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (pendingCountData.data) {
      setPendingPostsCount(pendingCountData.data.postsFilteredCount);
    }
  }, [pendingCountData.data]);

  const [getReportedCountData, reportedCountDataData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'reported',
      },
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getReportedCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (reportedCountDataData.data) {
      setReportedPostsCount(reportedCountDataData.data.postsFilteredCount);
    }
  }, [reportedCountDataData.data]);

  useEffect(() => {
    if (groupRoleState.loaded) {
      setUserRole(
        authContext.generalRole === 'admin' ||
          groupRoleState.role === 'moderator'
          ? 'admin'
          : 'member'
      );
    }
  }, [groupRoleState.loaded]);

  const onNextPage = () => {
    const pageCountFirstNumber =
      groupMembersState.page * groupMembersState.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + groupMembersState.limitRow - 1;
    if (pageCountSecondNumber > groupState.memberCount) {
      pageCountSecondNumber = groupState.memberCount;
    }

    setGroupMembersState({
      ...groupMembersState,
      page: groupMembersState.page + 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (groupMembersState.page - 2) * groupMembersState.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + groupMembersState.limitRow - 1;
    if (pageCountSecondNumber > groupState.memberCount) {
      pageCountSecondNumber = groupState.memberCount;
    }
    setGroupMembersState({
      ...groupMembersState,
      page: groupMembersState.page - 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setGroupMembersState({
      ...groupMembersState,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(groupState.memberCount / rowCount),
    });
  };

  const onDeleteRefecth = () => {
    refetch();
    membersData.refetch();
  };

  const onSearchSubmit = searchInput => {
    setGroupMembersState({
      ...groupMembersState,
      filters: searchInput !== '' ? { search: searchInput } : {},
      page: 1,
    });
    membersData.refetch();
  };

  return (
    <ProtectedRoute
      redirectTo={groupUrl}
      userAccessReq={['auth', userRole, ['admin']]}
    >
      <Layout location={context.location}>
        <PageWrapper>
          <TopBanner>
            <BannerText size="12">
              <String id="community_moderation_title_bar" />
            </BannerText>
          </TopBanner>
          <ContentWrapper>
            <ContentTopContainer>
              <BackBtn title={`back to ${context.params.slug}`} to={groupUrl}>
                <StyledSvg icon="arrow-left-short" />
                <BackLinkText size="12">
                  <String id="community_moderation_back_text" />
                </BackLinkText>
              </BackBtn>
              {groupState.loaded ? (
                <TitleContainer>
                  <IconImage>
                    <Image
                      src={groupState.iconUrl}
                      alt="icon"
                      isBackground={false}
                    />
                  </IconImage>
                  <Title>{groupState.name}</Title>
                </TitleContainer>
              ) : null}
            </ContentTopContainer>
            <ContentBottomContainer>
              <SidebarWrapper>
                <GroupCommunitySideBar
                  activeTab={activeTab}
                  groupUrl={groupUrl}
                  location={context.location}
                  pendingNotifications={{
                    members: {
                      amount: 0,
                    },
                    publication: {
                      amount: pendingPostsCount,
                    },
                    reportContent: {
                      amount: reportedPostsCount,
                    },
                  }}
                />
              </SidebarWrapper>
              <TableWrapper>
                {groupMembersState.loaded && groupState.memberCount !== null ? (
                  <MembersTable
                    membersList={membersListState.membersList}
                    onNextPage={onNextPage}
                    onPrevPage={onPrevPage}
                    memberCount={groupState.memberCount}
                    currentPageCount={groupMembersState?.currentPageCount}
                    onChangeLimitRow={onChangeLimitRow}
                    currentPage={groupMembersState.page}
                    maxPage={groupMembersState.maxPage}
                    groupId={groupState.id}
                    refetch={onDeleteRefecth}
                    loading={groupMembersState.loading || membersData.loading}
                    fetchWithFilters={fetchWithFilters}
                    onSearchSubmit={onSearchSubmit}
                    locationOrigin={context.location.origin}
                  />
                ) : (
                  <Loader isLoading="true" />
                )}
              </TableWrapper>
            </ContentBottomContainer>
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </ProtectedRoute>
  );
};
export default GroupMembersModerationPage;

GroupMembersModerationPage.propTypes = {};

GroupMembersModerationPage.defaultProps = {};
