import {
  NotificationActionsWrapper,
  NotificationHeader,
  NotificationWrapper,
} from './ClientNotification.styled';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';

function notifIconForType(type) {
  switch (type) {
    case 'info':
      return 'help';
    case 'error':
      return 'close-circled';
    case 'success':
      return 'circle-check';
    default:
      return type;
  }
}
function ClientNotification(props) {
  const {
    text,
    type,
    autoDismiss,
    closable,
    onClose,
    autoDismissTimeoutMs,
    onAutoDismiss,
    actions,
    extras,
  } = props;

  const [isDismissed, setIsDismissed] = useState(false);

  const timeoutRef = useRef();
  const autoDismissTimeoutRef = useRef();

  function handleClose() {
    setIsDismissed(true);
    onClose();
  }

  useLayoutEffect(() => {
    if (isDismissed) {
      timeoutRef.current = setTimeout(() => {
        setIsDismissed(false);
      }, 1500);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isDismissed]);

  useEffect(() => {
    if (autoDismiss) {
      autoDismissTimeoutRef.current = setTimeout(() => {
        onAutoDismiss();
      }, [autoDismissTimeoutMs]);
    }
    return () => {
      clearTimeout(autoDismissTimeoutRef.current);
    };
  }, []);

  return (
    <NotificationWrapper
      type={type}
      autoDismiss={autoDismiss}
      isDismissed={isDismissed}
      css={
        autoDismiss
          ? {
              animationDuration: `${autoDismissTimeoutMs}ms`,
            }
          : {}
      }
    >
      <NotificationHeader>
        <div>
          <Svg className="icon-notification" icon={notifIconForType(type)} />
          <Heading level="h6">
            <String id={`client_notification_${type}_title`} />
          </Heading>
        </div>
        {closable && onClose !== undefined && (
          <Button
            type="circle"
            svgIcon="close-big"
            onClick={handleClose}
            actionType="onclick"
            colorTheme="transparent"
          />
        )}
      </NotificationHeader>
      {text && (
        <Text as="p" size="14">
          {props.text ===
          'client_notification_could_not_create_post : member is blocked in this group_error' ? (
            <String id={`client_notification_blocked_user_title`} extras={extras} />
          ) : (
            <String id={text} extras={extras} />
          )}
        </Text>
      )}
      {actions.length > 0 && (
        <NotificationActionsWrapper type={type}>
          {actions.map(action => {
            return <Button key={action.label} {...action} />;
          })}
        </NotificationActionsWrapper>
      )}
    </NotificationWrapper>
  );
}

ClientNotification.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'info']),
  autoDismiss: PropTypes.bool,
  autoDismissTimeoutMs: PropTypes.number,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  onAutoDismiss: PropTypes.func,
  extras: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      colorTheme: PropTypes.string,
      internalUrl: PropTypes.string,
      externalUrl: PropTypes.string,
      actionType: PropTypes.oneOf(['onclick', 'externalLink', 'internalLink']),
      onClick: PropTypes.func,
      svgIcon: PropTypes.string,
      svgLocation: PropTypes.oneOf(['before', 'after']),
    })
  ),
};

ClientNotification.defaultProps = {
  type: 'info',
  autoDismiss: false,
  onAutoDismiss: () => {},
  autoDismissTimeoutMs: 5000,
  closable: false,
  extras: {},
  onClose: () => {},
  actions: [],
};

export default ClientNotification;
