import Logo from '@src/assets/svg/area_logo.svg';
import { styled } from '@root/stitches.config';

export const HeaderWrapper = styled('div', {
  backgroundColor: 'black',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  overflow: 'visible',

  '@bp2': {
    height: '94px',
  },
});

export const HeaderContainer = styled('header', {
  position: 'sticky',
  top: '0',
  zIndex: '1000',

  '@bp2': {
    position: 'relative',
  },
});

export const HeaderGrid = styled('div', {
  width: '100%',
  maxWidth: '$container',
  display: 'grid',
  gridTemplateColumns: '100px 1fr',
  ai: 'center',
  justifyContent: 'space-between',
  px: '$8',
  py: '$4',

  '@bp2': {
    gridTemplateColumns: '100px 1fr auto',
    gridGap: '$24',
    px: '$32',
    py: '$24',
  },
  '@bp3': {
    gridGap: '$48',
  },
  '@bp5': {
    px: '0',
  },
});

export const AreaLogo = styled(Logo, {
  display: 'flex',
  height: '$24',
  width: '100%',
  fill: '#FFF',
  '@bp2': {
    height: '$32',
  },
});

export const NavWrapper = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'block',
  },
  li: {
    mr: '$24',
    zIndex: 1,
    '.header-submenu': {
      // background: 'green',
      position: 'absolute',
      visibility: 'hidden',
      zIndex: 1,
      paddingTop: '$40',
      paddingBottom: '$16',
      opacity: '0',
      width: '100%',
      left: 0,
      transform: 'translateY(-5px)',
      transition: 'transform .2s ease-in, opacity .2s ease-in',
      ul: {
        maxWidth: '$container',
        left: '50%',
        position: 'absolute',
        transform: 'translateX(-50%)',
        zIndex: 1,
        '@bp2': {
          paddingLeft: '158px',
        },
        '@bp3': {
          paddingLeft: '180px',
        },
        '@bp5': {
          paddingLeft: '148px',
        },
        width: '100%',
      },
      a: {
        //color: 'black',
        fontFamily: 'var(--fonts-ArtifaktLegendRegular)',
        textTransform: 'none',
      },
      '&:after': {
        content: '""',
        width: '100%',
        zIndex: 0,
        height: '56px',
        left: '0',
        top: '28px',
        background: '$headerBlack',
        position: 'absolute',
        borderTop: '1px solid $headerButtons',
      },
    },
    a: {
      color: 'white',
      textDecoration: 'none',
      fontFamily: '$ArtifaktElementBold',
      letterSpacing: '0.5px',
      fontSize: '$18',

      ':hover': {
        color: '$blackToWhite',
        transition: 'color .3s ease',
      },
    },
    '&:hover': {
      '.header-submenu': {
        visibility: 'visible',
        opacity: '1',
        transform: 'translateY(0px)',
      },
    },
  },
  'li:last-child': {
    mr: '0',
  },
});
