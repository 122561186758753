import { styled } from '@root/stitches.config';

export const BoxWrapper = styled('div', {
  width: '100%',
  position: 'relative',
  variants: {
    color: {
      white: {
        backgroundColor: '$box',
        color: '$mainTextColor',
      },
      grey: {
        backgroundColor: '$elements',
      },
    },
    radius: {
      small: {
        borderRadius: '$8',
      },
      medium: {
        borderRadius: '$16',
      },
      large: {
        borderRadius: '$24',
      },
    },
    shadow: {
      small: {
        boxShadow: '$2',
      },
      medium: {
        boxShadow: '$3',
      },
      large: {
        boxShadow: '$4',
      },
    },
    type: {
      centeredMessage: {
        zIndex: '$max',
        textAlign: 'center',
        width: '480px',
        position: 'relative',
        padding: '$24',
        marginBottom: '$24',
        '@bp2': {
          padding: '$32 $48',
          marginBottom: '$24',
        },
        '@bp3': {
          padding: '$48 $72',
          marginBottom: '$24',
        },
      },
    },
  },
});
