import {
  CommunityLinkPreviewContent,
  CommunityLinkPreviewWrapper,
  ExitBtn,
  ImageWrapper,
  StyledText,
} from './CommunityLinkPreview.styled';

import { Heading } from '@components/atoms/heading/Heading';
import Image from '@components/atoms/image/Image';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';
const CommunityLinkPreview = props => {
  const onCloseClick = e => {
    e.preventDefault();
    props.onCloseClick();
  };

  return (
    <CommunityLinkPreviewWrapper
      href={props.url}
      rel="nofollow noopener"
      role="link"
      tabIndex="0"
      target="_blank"
      inModal={props.inModal}
      className={props.className}
      isSinglePost={props.isSinglePost}
    >
      {props.previewImage ? (
        <ImageWrapper>
          <Image src={props.previewImage} alt={props.description} />
        </ImageWrapper>
      ) : null}
      <CommunityLinkPreviewContent>
        <Text as="p" size={12} css={{ marginBottom: '$8' }}>
          {props.url && new URL(props.url)?.hostname}
        </Text>
        {/* <Heading
          level="h5"
          css={{
            fontSize: '$24',
          }}
        >
          {props.title}
        </Heading> */}
        <StyledText as="p" size={16}>
          {props.title}
        </StyledText>
      </CommunityLinkPreviewContent>
      {props.inModal ? (
        <ExitBtn
          type="circle"
          svgIcon="close-small"
          colorTheme="gray"
          actionType="onclick"
          onClick={onCloseClick}
        />
      ) : null}
    </CommunityLinkPreviewWrapper>
  );
};

CommunityLinkPreview.propTypes = {};

export default CommunityLinkPreview;
