import { styled } from '@root/stitches.config';

export const SeparatorWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const Line = styled('div', {
  display: 'inline-block',
  height: '1px',
  width: '100%',
  backgroundColor: '$lightGrey',
});
