import { styled } from '@root/stitches.config';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { Link } from 'gatsby';

export const ProfileBoxSmallWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const ProfileBoxAvatar = styled(Link, {
  marginRight: '$16',
  opacity: '1',
  transition: 'opacity .2s ease-out',

  '&:hover': {
    opacity: '.75'
  }
});

export const ProfileBoxSmallInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  variants: {
    type: {
      isPost: {
        flexDirection: 'column',

        '@bp2': {
          flexDirection: 'row',
        },
      },
    },
  },
});
export const ProfileBoxSmallGroup = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const ProfileBoxSmallInfoWrapper = styled('div', {
  display: 'block',
});

export const StyledSvg = styled(Svg, {
  height: '$24',
  width: '$24',
  marginRight: '$4',
  fill: '$mainTextColor',
});

export const StyledBoldText = styled(Link, {
  fontFamily: '$ArtifaktElementBold',
  marginRight: '$4',
  textDecoration: 'none',
  fontSize: '$14',
  position: 'relative',

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '3px',
    left: '0',
    width: '100%',
    height: '1px',
    backgroundColor: '$blackToWhite',
    opacity: '0',
  },

  '&:hover': {
    '&:before': {
      opacity: '1',
    },
  },

  variants: {
    hasNoHover: {
      true: {
        '&:hover': {
          '&:before': {
            opacity: '0',
          },
        },
      }
    }
  }
});
