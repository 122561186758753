import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';
import { Text } from '@components/atoms/text/Text';
import Svg from '@components/atoms/svg/Svg';

export const InputTextWrapper = styled('div', {
  width: '100%',
  position: 'relative',
  display: 'grid',
  gridTemplateAreas:
    "'header header header' 'input input input' 'error error error'",
  '.input__icon' : {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '$32',
    width: '$24',
    height: '$24',
    cursor: 'pointer',
  },
});

export const PrefixLabel = styled('div', {
  padding: '$8',
  // width: '100%',
  fontFamily: 'var(--ArtifaktElementRegular)',
  fontSize: '$16',
  // position:'absolute',
  backgroundColor: '$lightGrey',
  borderRadius: '$8',
  borderTopRightRadius: '0',
  borderBottomRightRadius: '0',
  svg: {
    height: '$32',
    width: '$32',
    fill: '$darkGrey',
  },
  display: 'flex',
  alignItems: 'center',
  // lineHeight: '1.18em',
});

export const LinkWrapper = styled('span', {
  gridArea: 'link',
  textAlign: 'right',
});

export const RequiredLabel = styled(Text, {
  color: '$darkGrey',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});

export const LinkStyled = styled(Link, {
  color: '$darkGrey',
  textDecoration: 'none',
  fontSize: '$12',
  fontFamily: 'var(--ArtifaktElement)',
  textAlign: 'right',
  '&:hover': {
    color: '$black',
    transition: 'color .1s ease',
  },
});

export const InputTextHeader = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 16px 1fr',
  gridTemplateAreas: "'label instructions link'",
  gridArea: 'header',
});

export const InputTextLabel = styled('label', {
  marginBottom: '$4',
  display: 'inline-block',
  fontSize: '$14',
  fontFamily: 'var(--ArtifaktElement)',
  display: 'flex',
  alignContent: 'center',
  gridArea: 'label',
  marginRight: '$8',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
  svg: {
    display: 'inline',
  },
});

export const SvgHelp = styled(Svg, {
  fill: '$darkGrey',
  height: '$16',
  width: '$16',
  position: 'relative',
  top: '$2',
  color: '$darkGrey',
});

export const InputTextErrorMsg = styled('div', {
  textAlign: 'left',
  gridArea: 'error',
  fontSize: '$12',
  marginTop: '$4',
  minHeight: '$24',
  variants: {
    isError: {
      true: {
        color: '$red',
        fontSize: '$12',
        lineHeight: '1',
      },
    },
  },
});

export const InputInstructions = styled('span', {
  gridArea: 'instructions',
});

export const InputTextInstructionMsg = styled('div', {
  gridArea: 'error',
  fontSize: '$12',
  marginTop: '$4',
  color: '$darkGrey',
});

export const InputTextStyled = styled('input', {
  padding: '$16 $24',
  width: '100%',
  fontFamily: 'var(--ArtifaktElementRegular)',
  fontSize: '$16',
  lineHeight: '1.18em',
  boxShadow: 'none',
  appearance: 'none',
  color: '$mainTextColor',
  margin: '0px',
  variants: {
    isError: {
      true: {
        border: '$red 1px solid',
      },
    },
    inputTheme: {
      transparent: {
        backgroundColor: 'transparent',
        border: '1px solid $lightGrey',
        color: '$mainTextColor',
        outline: '0',
        svg: {
          fill: '#gray900',
        },
        '::placeholder': {
          color: '$lightGrey',
        },
        '&:focus': {
          borderColor: '$mediumGrey',
        },
        '&:hover': {
          borderColor: '$mediumGrey',
        },
      },
      white: {
        backgroundColor: '$box',
        border: '1px solid $lightGrey',
        svg: {
          fill: '#gray900',
        },
      },
    },
    inputShape: {
      rounded: {
        borderRadius: '$24',
      },
      normal: {
        borderRadius: '$8',
        padding: '$12 $16',
      },
    },
    isPrefixLabel: {
      hasPrefixLabel: {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
      },
    },
  },
});

export const InputWrapper = styled('div', {
  gridArea: 'input',
  position: 'relative',
  display: 'block',
  variants: {
    isPrefixLabel: {
      hasPrefixLabel: {
        display: 'flex',
      },
      noPrefixLabel: {
        display: 'block',
      },
    },
  },
});

export const HelpWrapper = styled('div', {
  position: 'relative',
  display: 'inline-block',
  fontSize: '0',
});

export const HelpInfoboxWrapper = styled('div', {
  position: 'absolute',
  top: '-100%',
  left: '50%',
  transition: 'transform .2s ease-out, opacity .2s ease-out',
  opacity: '0',
  transform: 'translate(-50%, 10px)',
  pointerEvents: 'none',
  zIndex: '2',
  variants: {
    visible: {
      true: {
        transform: 'translate(-50%,0px)',
        opacity: '1',
      },
      false: {
        transform: 'translate(-50%,10px)',
        opacity: '0',
      },
    },
  },
});
