import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const AvatarWrapper = styled('div', {
  position: 'relative',
  fontSize: 0,

  img: {
    borderRadius: '50%',
  },
  width: '40px',
  height: '40px',
  variants: {
    size: {
      xsmall: {
        width: '32px',
        height: '32px',
      },
      small: {
        width: '40px',
        height: '40px',
      },
      medium: {
        width: '64px',
        height: '64px',
      },
      large: {
        width: '80px',
        height: '80px',
      },
      xlarge: {
        width: '160px',
        height: '160px',
      },
    },
  },
});

export const StyledButton = styled(Button, {
  width: '32px',
  height: '32px',
  display: 'inline-block',
  position: 'absolute',
  bottom: '$8',
  right: '$8',
  svg: {
    position: 'relative',
    right: '-1px',
  },
});
