import React, { useState } from 'react';
import Button from '@components/atoms/button/Button';
import {
  ActionLabel,
  OptionsContainer,
  OptionsWrapper,
  SaveBtnActions,
  SaveBtnAction,
} from './SaveBtn.styled';

const SaveBtn = props => {
  const [options, setOptions] = useState({
    isOptionsOpened: false,
  });

  const onToggleOptions = () => {
    setOptions({
      isOptionsOpened: !options.isOptionsOpened,
    });
  };

  const onClickAction = action => {
    onToggleOptions();
    props.action.onClickAction(props);
  };

  return (
    <OptionsWrapper>
      <Button
        type="circle"
        colorTheme="black"
        svgIcon={props.isGroupSettings ? 'plus' : 'save'}
        actionType="onclick"
        onClick={onToggleOptions}
      />
      <OptionsContainer
        isOptionsOpened={options.isOptionsOpened ? 'isOpened' : 'isNotOpened'}
        css={{ width: '250px', padding: '$16' }}
      >
        <div>
          {props.action.text}
          <SaveBtnActions>
            <SaveBtnAction
              weight="bold"
              onClick={() => {
                onClickAction();
              }}
              css={{ display: 'inline-block', marginRight: '$16' }}
            >
              {props.action.label}
            </SaveBtnAction>
            <SaveBtnAction
              weight="bold"
              onClick={onToggleOptions}
              css={{ display: 'inline-block' }}
            >
              No
            </SaveBtnAction>
          </SaveBtnActions>
        </div>
      </OptionsContainer>
    </OptionsWrapper>
  );
};

export default SaveBtn;
