import { styled } from '@root/stitches.config';
import Image from '@components/atoms/image/Image';
import Layout from '@components/organisms/layout/Layout';
import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';
import { Link } from 'gatsby';
import Svg from '@components/atoms/svg/Svg';
import Separator from '@components/atoms/separator/Separator';

export const AddRuleModalWrapper = styled('div', {
  width: '100%',
});
export const EditorWrapper = styled('div', {
  div: {
    padding: '$16',
    border: '1px solid $lightGrey',
    borderRadius: '$8',
    maxWidth: '456px',
  },
});

export const AddRuleModalContentWrapper = styled('div', {
  width: '100%',
  marginBottom: '$40',

  input: {
    marginBottom: '$12',
    fontFamily: '$ArtifaktLegendBold',
  },
});

export const AddRuleModalBtnWrapper = styled('div', {
  display: 'flex',
});

export const AddRuleModalTitle = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  color: '$mainTextColor',
  marginBottom: '$16',
});

export const AddRuleModalSubTitle = styled(Text, {
  color: '$mainTextColor',
  marginBottom: '$24',
});

export const AddRuleModalMandatory = styled(Text, {
  color: '$mediumGrey',
  marginTop: '$8',
});

export const InputLabels = styled(Text, {
  fontFamily: '$ArtifaktLegendRegular',
  color: '$mediumGrey',
  marginBottom: '$4',
});
