import { styled } from '@root/stitches.config';

export const ForgotPasswordBoxWrapper = styled('div', {
  padding: '$32 $16',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  maxWidth: '420px',
});

export const FormStyled = styled('form', {
  maxWidth: '500px',
  width: '100%',
  '@bp2': {
    maxWidth: '600px',
  },
});

export const ErrorMsg = styled('p', {
  marginTop: '$24',
  color: '$red',
});

export const ResendText = styled('span', {
  textDecoration: 'underline',
  cursor: 'pointer',
  transition: 'opacity .4s ease',
  '&:hover': {
    opacity: 0.6,
  },
});

export const SuccessBox = styled('div', {
  maxWidth: '500px',
  width: '100%',
  '@bp2': {
    maxWidth: '600px',
  },
});
