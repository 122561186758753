import React from 'react';
import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';
import { useTable } from 'react-table';
import { TableBlockWrapper } from './TableBlock.styled';

const TableBlock = props => {
  const data = React.useMemo(() => {
    const rows = [];
    props.dataRows.map(el => {
      rows.push({
        ...el,
      });
    });
    return rows;
  }, []);

  const columns = React.useMemo(() => {
    const head = [];
    props.headColumns.map(el => {
      head.push({
        ...el,
      });
    });
    return head;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <TableBlockWrapper isLastRowGreen={props.isLastRowGreen}>
      <table {...getTableProps()} cellSpacing="0">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableBlockWrapper>
  );
};

export default TableBlock;
