import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

const textSharedStyle = {
  zIndex: '1',
  color: '$white',
  '@bp2': {
    maxWidth: '350px',
  },
};

export const StyledText = styled(Text, {
  ...textSharedStyle,
  fontSize: '$14',
  fontFamily: '$ArtifaktLegendRegular',
  marginTop: '$8',
  lineHeight: '1.3',
  '@bp1': {
    fontSize: '20px',
  },
});

export const StyledHeading = styled(Heading, {
  ...textSharedStyle,

  fontFamily: '$ArtifaktLegendBold',
  lineHeight: '$1',
  fontSize: '$32',
  '@bp2': {
    fontSize: '$60 !important',
  },
});

const linkThumbnailStyle = {
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  background: '$black',
  color: '$white',
  br: 18,
  boxShadow: '$1',
  overflow: 'hidden',
  textDecoration: 'none',
  justifyContent: 'center',
  minHeight: '204px',
  '> .image-wrapper': {
    zIndex: '0',
    left: '0',
    top: '0',
    transition: 'transform .2s ease-out',
    width: '100%',
    height: '100%',
    position: 'absolute',
    img: {
      objectFit: 'cover',
    },
  },
  '&:hover': {
    '> .image-wrapper': {
      transform: 'scale(1.01)',
    },
  },
  img: {
    opacity: 0.2,
  },
  '@bp2': {
    width: '100%',
    minHeight: '380px',
    maxHeight: '380px',
    padding: '$40',
  },
};

export const IntenalLinkThumbnailCard = styled(Link, linkThumbnailStyle);
export const ExternalLinkThumbnailCard = styled('a', linkThumbnailStyle);
