import React from 'react';
import { WysiwygContainer } from './Wysiwyg.styled';

const Wysiwyg = props => {
  return (
    <WysiwygContainer
      align={props.align}
      fontSize={props.fontSize}
      dangerouslySetInnerHTML={{ __html: props.data }}
    ></WysiwygContainer>
  );
};
export default Wysiwyg;
