import {
  AreaLogo,
  DarkmodeWrapper,
  FakeRadio,
  FakeRadioBackground,
  FakeRadioCircle,
  HeaderContainer,
  HeaderGrid,
  HeaderWrapper,
  ImageProfileWrapper,
  ImageWrapper,
  NavWrapper,
  RightSide,
  UserMenu,
  UserMenuIcon,
  UserMenuItem,
  UserMenuItemProfile,
} from './AdminHeader.styled';
import React, { useContext, useEffect, useRef, useState } from 'react';

import AuthContext from '@context/AuthContext';
import { AutodeskLogo } from '@components/organisms/layout/header/userBar/UserBar.styled';
import Button from '@components/atoms/button/Button';
import { Link } from 'gatsby';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import UserBarNotifications from '@components/organisms/layout/header/userBar/UserBarNotifications';
import { navigate } from 'gatsby';
import useDarkMode from '@src/utils/use-dark-mode';
import Avatar from '@components/atoms/avatar/Avatar';
const AdminHeader = ({ siteTitle, className }) => {
  const authContext = useContext(AuthContext);
  const UrlContext = useContext(UrlsContext);
  const [darkMode, setDarkMode] = useDarkMode();
  const menuBoxRef = useRef(null);
  const [userBarState, setUserBarState] = useState({
    isUserMenuOpened: false,
  });
  <FakeRadioBackground isActive={darkMode} />;

  const onToggleUserMenu = () => {
    setUserBarState({
      ...userBarState,
      isUserMenuOpened: !userBarState.isUserMenuOpened,
    });
  };

  const handleLogout = () => {
    authContext.logout();
  };

  const navigateAndCloseMenu = to => {
    onToggleUserMenu();
    navigate(to);
  };

  const handleClickOutside = event => {
    if (
      menuBoxRef &&
      menuBoxRef.current &&
      !menuBoxRef.current.contains(event.target)
    ) {
      onToggleUserMenu();
    }
  };

  useEffect(() => {
    if (userBarState.isUserMenuOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // cleanup this component
    return () => {
      if (userBarState.isUserMenuOpened) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [userBarState]);

  return (
    <AuthContext.Consumer>
      {auth => (
        <HeaderContainer>
          <HeaderWrapper className={className}>
            <HeaderGrid>
              <Link to="/" alt={siteTitle}>
                <AreaLogo />
              </Link>
              <RightSide ref={menuBoxRef}>
                <UserBarNotifications auth={auth}></UserBarNotifications>
                <Button
                  type="circle"
                  colorTheme="reverse"
                  actionType="onclick"
                  onClick={onToggleUserMenu}
                  css={{ marginLeft: '$16' }}
                >
                  <ImageWrapper>
                    <Avatar
                      alt={`${authContext.firstName} ${authContext.lastName}`}
                      src={authContext?.profilePicture}
                    />
                  </ImageWrapper>
                </Button>
                {userBarState.isUserMenuOpened ? (
                  <UserMenu>
                    <UserMenuItemProfile
                      onClick={() =>
                        navigateAndCloseMenu(`/m/${authContext.userSlug}`)
                      }
                    >
                      <ImageProfileWrapper className="menuIcon">
                        <Avatar
                          alt={`${authContext.firstName} ${authContext.lastName}`}
                          src={authContext?.profilePicture}
                        />
                      </ImageProfileWrapper>
                      <div>
                        <Text
                          css={{
                            fontFamily: '$ArtifaktElementBold',
                            lineHeight: '1.2em',
                          }}
                          size="14"
                        >
                          {`${authContext.firstName} ${authContext.lastName}`}
                        </Text>
                        <Text size="14">See your Profile</Text>
                      </div>
                    </UserMenuItemProfile>
                    <UserMenuItem onClick={() => setDarkMode(!darkMode)}>
                      <UserMenuIcon className="menuIcon">
                        <Svg icon="darkmode-small" />
                      </UserMenuIcon>
                      <DarkmodeWrapper>
                        <Text size="16" css={{ marginRight: '$24' }}>
                          Dark mode
                        </Text>
                        <FakeRadio>
                          <FakeRadioBackground isActive={darkMode} />
                          <FakeRadioCircle isActive={darkMode} />
                        </FakeRadio>
                      </DarkmodeWrapper>
                    </UserMenuItem>
                    <UserMenuItem onClick={() => navigateAndCloseMenu(`/`)}>
                      <UserMenuIcon className="menuIcon">
                        <Svg icon="logout" />
                      </UserMenuIcon>
                      <Text size="16">Exit Admin CMS</Text>
                    </UserMenuItem>
                    <UserMenuItem onClick={handleLogout}>
                      <UserMenuIcon className="menuIcon">
                        <Svg icon="power" />
                      </UserMenuIcon>
                      <Text size="16">Log Out</Text>
                    </UserMenuItem>
                  </UserMenu>
                ) : null}
                <AutodeskLogo css={{ marginLeft: '$32' }} />
                {/* <Button
                  type="normal"
                  colorTheme="normal"
                  actionType="internalLink"
                  internalUrl="/"
                  label="Go to AREA"
                  css={{ marginLeft: '$32' }}
                /> */}
              </RightSide>
            </HeaderGrid>
          </HeaderWrapper>
        </HeaderContainer>
      )}
    </AuthContext.Consumer>
  );
};
export default AdminHeader;
