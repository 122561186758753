import { styled } from '@root/stitches.config';
import SignUpBox from '@components/organisms/signUpBox/SignUpBox';
import { Text } from '@components/atoms/text/Text';

export const SignUpPageWrapper = styled('div', {
  // display: 'grid',
  // gridTemplateColumns: '1fr',
  // width: '100%',
  minHeight: '0',
  overflow: 'hidden',
  position: 'relative',
  '@bp2': {
    // gridTemplateColumns: '1fr 2fr',
    minHeight: '90vh',
  },
});

export const SignUpBoxStyled = styled(SignUpBox, {
  width: '100%',
  '@bp2': {
    maxWidth: '420px',
  },
});

export const SignUpBoxWrapper = styled('div', {
  padding: '$32 $16',

  width: '100%',
  maxWidt: '1000px',
  height: '100%',
  overflow: 'auto',
  textAlign: 'center',

  '@bp2': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '$72',
    overflow: 'auto',
    textAlign: 'left',
    alignItems: 'center',
  },
});

export const SignAsideTitleLabel = styled(Text, {
  fontSize: '$32',
  color: '$ultraLightGrey',
  fontFamily: 'var(--ArtifaktElementBold)',
  mb: '$24',
  maxWidth: '300px',
  textAlign: 'center',
  lineHeight: '1.2em',
});

export const SignAsideLabel = styled(Text, {
  fontSize: '$12',
  color: '$ultraLightGrey',
  fontFamily: 'var(--ArtifaktElementBold)',
  mb: '$24',
  textAlign: 'center',
  lineHeight: '1.2em',
});
