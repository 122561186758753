import { styled } from '@root/stitches.config';

export const BlockVideoWrapper = styled('div', {
  position: 'relative',
  zIndex: '3',
});

export const BlockVideoErrorWrapper = styled('div', {
  padding: '$64',
  br: '$16',
});
