// import { Text } from '@components/atoms/text/Text';
import * as yup from 'yup';

import { ErrorMsg, FormStyled } from './RegistrationName.styled';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { Formik } from 'formik';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import { InputText } from '@components/atoms/input/inputText/InputText';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import String from '@components/atoms/string/String';
import UrlsContext from '@context/UrlsContext';
import { navigate } from 'gatsby';

const RegistrationName = ({ roles }) => {
  const UrlContext = useContext(UrlsContext);
  const authContext = useContext(AuthContext);

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    navigate(`/m/${authContext.userSlug}`);
  } else if (
    authContext.isAuthenticated &&
    !authContext.isVerified &&
    !authContext.isOnboarded &&
    authContext.firstName !== null
  ) {
    navigate(GetUrl('onboardingSignupValidation', UrlContext));
  } else if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    !authContext.isOnboarded
  ) {
    navigate(GetUrl('onboardingAvatarAndBio', UrlContext));
  }

  const [formState, setFormState] = useState({
    isAuthError: false,
    isLoading: false,
    authErrorMessage: 'An error has occured. Please try again!',
  });

  const [onboardingCompletionState, setOnboardingCompletionContext] = useState([
    {
      title: <String id="sign_up_page_steps_1" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_2" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_3" />,
      isActive: true,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_4" />,
      isActive: false,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_5" />,
      isActive: false,
      isCompleted: false,
    },
  ]);

  const onSave = () => {
    navigate(GetUrl('onboardingSignupValidation', UrlContext));
  };

  const Schema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, <String id="sign_up_error_valid_first_name" />)
      // .max(50, 'Too Long!')
      .required(<String id="sign_up_error_valid_first_name" />),

    lastName: yup
      .string()
      .min(2, <String id="sign_up_error_valid_last_name" />)
      // .max(50, 'Too Long!')
      .required(<String id="sign_up_error_valid_last_name" />),
  });

  const handleSubmit = async (authNameFn, values) => {
    setFormState({
      ...formState,
      isAuthError: false,
      isLoading: true,
    });

    authNameFn({
      first_name: values.firstName,
      last_name: values.lastName,
      errorCallback: handleAuthError,
      successCallback: handleAuthSuccess,
    });
  };

  const handleAuthError = response => {
    setFormState({
      ...formState,
      isAuthError: true,
      isLoading: false,
    });
  };

  const handleAuthSuccess = () => {
    navigate(GetUrl('onboardingSignupValidation', UrlContext));
  };

  return (
    <ProtectedRoute
      redirectTo={'/login'}
      userAccessReq={[
        'auth',
        authContext.generalRole,
        roles || ['member', 'contributor', 'editor', 'admin'],
      ]}
    >
      <OnboardingCompletionContext.Provider
        value={[onboardingCompletionState, setOnboardingCompletionContext]}
      >
        <OnboardingLayout
          sideBarTitle={'Welcome to AREA'}
          pageTitle={"What's your name?"}
          percentage="30%"
          displayAvatar={false}
          displaySkip={false}
          displaySteps
        >
          <AuthContext.Consumer>
            {auth => (
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                }}
                validationSchema={Schema}
                onSubmit={(values, errors) => {
                  handleSubmit(auth.setUserName, values, errors);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  dirty,
                  isValid,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <FormStyled onSubmit={handleSubmit}>
                      <InputText
                        autoFocus={true}
                        onBlur={handleBlur('firstName')}
                        //onChange={handleFirstNameChange}
                        error={errors.firstName}
                        touched={touched.firstName}
                        onChange={handleChange}
                        type="text"
                        inputShape="normal"
                        name="firstName"
                        label={<String id="sign_up_form_first_name" />}
                        value={values.firstName}
                        css={{ marginBottom: '$12' }}
                        isError={
                          errors.firstName && touched.firstName ? true : false
                        }
                        inputTheme="transparent"
                      />
                      <InputText
                        onBlur={handleBlur('lastName')}
                        //onChange={handleLastNameChange}
                        error={errors.lastName}
                        touched={touched.lastName}
                        onChange={handleChange}
                        type="text"
                        inputShape="normal"
                        name="lastName"
                        label={<String id="sign_up_form_last_name" />}
                        value={values.lastName}
                        css={{ marginBottom: '$12' }}
                        isError={
                          errors.lastName && touched.lastName ? true : false
                        }
                        inputTheme="transparent"
                      />
                      <Button
                        isDisabled={!(isValid && dirty)}
                        value="Submit"
                        type="normal"
                        label={'Continue'}
                        colorTheme="normal"
                        notificationBubbleActive={false}
                        actionType="onclick"
                        css={{
                          width: '100%',
                        }}
                      />
                      {formState.isLoading ? <LoaderSpinner /> : null}
                      {formState.isAuthError ? (
                        <ErrorMsg>{formState.authErrorMessage}</ErrorMsg>
                      ) : null}
                    </FormStyled>
                  );
                }}
              </Formik>
            )}
          </AuthContext.Consumer>
        </OnboardingLayout>
      </OnboardingCompletionContext.Provider>
    </ProtectedRoute>
  );
};

export default RegistrationName;
