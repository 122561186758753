import {
  InputGooglePlacesLocationWrapper,
  InputTextErrorMsg,
} from './InputGooglePlacesLocation.styled';
import {
  InputTextHeader,
  InputTextLabel,
  LinkWrapper,
  RequiredLabel,
} from '../inputText/InputText.styled';
import React, { useState } from 'react';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';

const InputGooglePlacesLocation = props => {
  const {
    onLocationChange,
    label,
    required,
    isError,
    autoCompleteRequestTypes,
    locationFull,
    error,
    placeholder,
    css,
  } = props;

  const [valueLocation, setValueLocation] = useState(null);

  const onChangeValue = value => {
    setValueLocation(value);

    const data = {
      place_id: value.value.place_id,
      label: value.label,
      country: value.value.terms[value.value.terms.length - 1].value,
      city: value.value.terms[0].value,
    };
    onLocationChange(data);
  };

  return (
    <InputGooglePlacesLocationWrapper isError={isError} css={css}>
      <InputTextHeader>
        {label && <InputTextLabel isError={isError}>
          <String id={label} />
        </InputTextLabel>}
        {required && (
          <LinkWrapper>
            <RequiredLabel size="12" isError={isError}>
              <String id="input_required_text" />
            </RequiredLabel>
          </LinkWrapper>
        )}
      </InputTextHeader>
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_PLACES_API_KEY}
        autocompletionRequest={{
          types: autoCompleteRequestTypes,
        }}
        selectProps={{
          valueLocation,
          onChange: onChangeValue,
          placeholder: `${locationFull || placeholder}`,
          classNamePrefix: 'g-places-select',
        }}
      />
      {isError ? (
        <InputTextErrorMsg isError={isError}>{error}</InputTextErrorMsg>
      ) : null}
    </InputGooglePlacesLocationWrapper>
  );
};

InputGooglePlacesLocation.propTypes = {
  onLocationChange: PropTypes.func,
  required: PropTypes.bool,
  isError: PropTypes.bool,
  autoCompleteRequestTypes: PropTypes.arrayOf(PropTypes.string),
  locationFull: PropTypes.string,
  css: PropTypes.object,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

InputGooglePlacesLocation.defaultProps = {
  onLocationChange: () => {},
  required: false,
  isError: false,
  autoCompleteRequestTypes: [],
  locationFull: undefined,
  css: {},
  error: undefined,
  label: undefined,
  placeholder: 'Enter your location',
};

export default InputGooglePlacesLocation;
