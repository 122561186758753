import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as animationData from './Loader.json';
import Lottie from 'lottie-react';
import { LottieWrapper } from './CommunityFeed.styled';
import CommunityPost from '@components/molecules/communityPost/CommunityPost';
import SuggestedGroupSlider from '../suggestedGroupSlider/SuggestedGroupSlider';
import Button from '@components/atoms/button/Button';
import String from '@components/atoms/string/String';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function CommunityFeed(props) {
  const {
    postSlugs,
    loading,
    showGroupSlider,
    onJoinGroup,
    isLogged,
    isVerified,
    isOnboarded,
    activeFilter,
    onFilterClick,
    showFilters,
  } = props;

  return (
    <>
      {showFilters && (
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          {[
            'recent',
            'popular',
            ...(showGroupSlider && isLogged ? ['mygroups'] : []),
          ].map(el => {
            return (
              <Button
                key={`community_filter_${el}`}
                actionType="onclick"
                onClick={() => onFilterClick(el)}
                label={<String id={`general_${el}_filter_text`} />}
                colorTheme={activeFilter === el ? 'normal' : 'transparent'}
              />
            );
          })}
        </div>
      )}
      {!!postSlugs &&
        postSlugs?.map((post, i) => {
          if (showGroupSlider && i === 2) {
            return (
              <React.Fragment key={post}>
                <SuggestedGroupSlider
                  onJoinGroup={onJoinGroup}
                  url="/community/groups"
                  isLogged={isLogged}
                  isVerified={isVerified}
                  isOnboarded={isOnboarded}
                  css={{ mb: '$16' }}
                />
                <CommunityPost slug={post} {...props} />
              </React.Fragment>
            );
          }
          return <CommunityPost key={post} slug={post} {...props} />;
        })}
      {!!loading && (
        <LottieWrapper>
          <div style={{ width: '100px', height: '100px' }}>
            <Lottie
              {...defaultOptions}
              options={defaultOptions}
              height={100}
              width={100}
            />
          </div>
        </LottieWrapper>
      )}
    </>
  );
}

CommunityFeed.propTypes = {
  postSlugs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  loading: PropTypes.bool,
  onJoinGroup: PropTypes.func,
  isLogged: PropTypes.bool,
  showGroupSlider: PropTypes.bool,
  isVerified: PropTypes.bool,
  showFilters: PropTypes.bool,
  isOnboarded: PropTypes.bool,
  onFilterClick: PropTypes.func,
  activeFilter: PropTypes.oneOf(['popular', 'recent', 'my_groups']),
};

CommunityFeed.defaultProps = {
  postSlugs: [],
  loading: true,
  onJoinGroup: () => {},
  onFilterClick: () => {},
  isLogged: false,
  isVerified: false,
  showFilters: true,
  showGroupSlider: false,
  isOnboarded: false,
  activeFilter: 'recent',
};

export default CommunityFeed;
