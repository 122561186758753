import React from 'react';
import Wysiwyg from '@components/atoms/wysiwyg/Wysiwyg';
import Image from '@components/atoms/image/Image';
import { getImageSrc } from '@src/utils/getImageSrc';
import { Item } from 'react-photoswipe-gallery';
import { BlockImageWrapper, ImageWrapper } from './BlockImage.styled';

const BlockImage = props => {
  const imageSrc = getImageSrc(props.block.image, 'create_details', '');

  return (
    <BlockImageWrapper>
      <ImageWrapper css={{ cursor: 'zoom-in' }} onClick={props.onImageClick}>
        <Item
          original={imageSrc?.src}
          width={props.block.image?.width}
          height={props.block.image?.height}
        >
          {({ ref, open }) => (
            <div ref={ref}>
              <Image
                src={{ src: imageSrc?.src }}
                alt={props.alt}
                onClick={open}
              />
            </div>
          )}
        </Item>
      </ImageWrapper>
      <Wysiwyg align="center" fontSize="small" data={props.block.text} />
    </BlockImageWrapper>
  );
};
export default BlockImage;

