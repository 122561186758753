import {
  ArtworkByContainer,
  BlockTextWrapper,
  ButtonContainer,
  CommunityAddPostModalWrapper,
  ErrorWrapper,
  ImagesContainer,
  SingleImageContainer,
  StyledBtn,
  StyledButton,
  StyledText,
  StyledTextVariation,
} from './CommunityAddPostModal.styled';
import { AvatarContainer } from '../CommunityAddPost.styled';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import {
  CREATE_COMMENT,
  CREATE_POST,
  GET_LINK_EMBED,
  UPDATE_POST,
} from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Avatar from '@components/atoms/avatar/Avatar';
import Button from '@components/atoms/button/Button';
import CommunityLinkPreview from '@components/molecules/communityLinkPreview/CommunityLinkPreview';
import Image from '@components/atoms/image/Image';
import ImageUploading from 'react-images-uploading';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { GET_POST_COMMENTS } from '@src/apollo/query';

require('medium-editor/dist/css/medium-editor.css');
require('../../../../styles/content_editor.css');
const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false;

const CommunityAddPostModal = props => {
  const saveString = <String id="general_save_btn_text" />;
  const cancelString = <String id="general_cancel_btn_text" />;

  const [createPost, { data, error, loading }] = useMutation(CREATE_POST, {
    onCompleted: data => {
      props.hide();
      props.onSaveClick(data, props.parentId, props.isReply);
    },
  });
  const [createPostComment, dataComment] = useMutation(CREATE_COMMENT, {
    refetchQueries: [
      {
        query: GET_POST_COMMENTS,
        variables: {
          parent_id: props.parentId,
          limit: 3,
          page: 1,
          level: 2,
          order: 'desc',
        },
      },
    ],
    onCompleted: data => {
      props.hide();
      props.onSaveClick(data, props.parentId, props.isReply);
    },
  });

  const [updatePostComment, dataUpdatedPost] = useMutation(UPDATE_POST, {
    onCompleted: () => {
      if (props?.refetch) {
        props?.refetch();
      }
      props?.hide();
      props.closeMenus();
    },
  });

  const [getLinkEmbed, dataLink] = useMutation(GET_LINK_EMBED);
  const [createPostState, setCreatePostState] = useState({
    link: '',
    images: [],
    imagesData: [],
    loading: false,
    isFocused: false,
    imagesSet: false,
    linkDisplay: false,
    modules: [],
    linksSet: [],
  });

  const [linkState, setLinkState] = useState({
    link_description: '',
    link_image: '',
    link_title: '',
    link_url: '',
    link_data_set: false,
  });

  const [previewVisible, setPreviewVisible] = useState(false);

  const [contentState, setContentState] = useState({
    content: props.replyingTo
      ? "<a class='content-link' href='" +
        props.artistProfilUrl +
        "'>" +
        props.replyingTo +
        '</a>'
      : '',
  });

  const onSaveClick = () => {
    const imageFiles = createPostState.images
      .filter(el => el.file !== undefined)
      .map(el => el.file);
    const imagesId = createPostState.images
      .filter(el => el.id !== undefined)
      .map(el => el.id);
    props.onSaveClick;

    if (props.isEdit) {
      updatePostComment({
        variables: {
          id: props.postId,
          images_id: imagesId,
          input: {
            group: props.groupId,
            images: imageFiles,
            content: contentState.content,
            ...(createPostState.linkDisplay && previewVisible
              ? {
                  link: createPostState.link,
                }
              : {}),
          },
        },
      });
    } else {
      if (props.isComment || props.isReply) {
        createPostComment({
          variables: {
            images: imageFiles,
            content: contentState.content,
            groupId: props.groupId,
            parentId: props.parentId,
            ...(createPostState.linkDisplay && previewVisible
              ? {
                  link: createPostState.link,
                }
              : {}),
          },
        });
      } else {
        createPost({
          variables: {
            images: imageFiles,
            content: contentState.content,
            groupId: props.groupId,
            ...(createPostState.linkDisplay && previewVisible
              ? {
                  link: createPostState.link,
                }
              : {}),
          },
        });
      }
    }
  };

  const onTextChange = text => {
    let newText = text;
    if (newText !== contentState.content) {
      const re = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/gim;
      const link = text.match(re);

      if (link) {
        if (
          !createPostState.linkDisplay &&
          createPostState.content !== newText
        ) {
          newText = text.replace(
            re,
            "<a class='content-link' href='$1' target='_blank'>$1</a>"
          );
          setContentState({
            ...contentState,
            content: newText,
          });

          getLinkEmbed({
            variables: {
              link: link[0],
            },
          });

          let newArray = [link[0]];

          setCreatePostState({
            ...createPostState,
            link: link[0],
            linkDisplay: true,
            linksSet: newArray,
          });
        } else {
          if (createPostState.linksSet.length > 0) {
            for (let i = 0; i < link.length; i++) {
              if (
                createPostState.linksSet.find(el => el === link[i]) ===
                undefined
              ) {
                newText = text.replace(
                  link[i],
                  "<a class='content-link' href='" +
                    link[i] +
                    "' target='_blank'>" +
                    link[i] +
                    '</a>'
                );

                let newArray = createPostState.linksSet;
                newArray.push(link[i]);
                setCreatePostState({
                  ...createPostState,
                  linksSet: newArray,
                });
              }
            }
          }

          setContentState({
            ...contentState,
            content: newText,
          });
        }
      } else {
        setContentState({
          ...contentState,
          content: newText,
        });
        setPreviewVisible(false);
        setLinkState({
          ...linkState,
          link_data_set: false,
        });

        setCreatePostState({
          ...createPostState,
          link: '',
          linkDisplay: false,
          linksSet: [],
        });
      }
    }
  };

  const hideOrRemoveImagesAndHide = () => {
    if (props.onRemoveAll) {
      props.onRemoveAll();
      props.hide();
    } else {
      props.hide();
    }
  };
  // const linkImage = getImageSrc(
  //   dataLink.data.getLinkEmbed.link_image,
  //   'link_image',
  //   dataLink.data.getLinkEmbed.link_title
  // );
  useEffect(() => {
    if (dataLink.data && dataLink.called) {
      setLinkState({
        ...linkState,
        link_description: dataLink.data.getLinkEmbed?.link_description,
        link_image: dataLink.data.getLinkEmbed?.link_image
          ? dataLink.data.getLinkEmbed?.link_image.path
          : null,
        link_title: dataLink.data.getLinkEmbed?.link_title,
        link_url: dataLink.data.getLinkEmbed?.link_url,
        link_data_set: true,
      });
      setPreviewVisible(true);
    }
  }, [dataLink.data]);

  const onChangeImage = imageList => {
    const count = imageList.length;
    let imagesArray = [];

    if (props.isEdit) {
      imagesArray = createPostState.imagesData;
      for (let i = 0; i < imagesArray.length; i++) {
        let isInThere = false;
        imageList.map((imageUrl, j) => {
          if (imageUrl.src?.src === imagesArray[i].src) {
            isInThere = true;
          }
        });

        if (!isInThere) {
          imagesArray.splice(i, 1);
        }
      }
    }

    imageList.map((image, i) => {
      if (image.file) {
        imagesArray.push(image.file);
      }
    });

    if (count === 0 && createPostState.link !== '') {
      setCreatePostState({
        ...createPostState,
        images: imageList,
        imagesData: imagesArray,
        linkDisplay: true,
      });
    } else {
      setCreatePostState({
        ...createPostState,
        images: imageList,
        imagesData: imagesArray,
        linkDisplay: false,
      });
    }

    setContentState({
      ...contentState,
      content: contentState.content,
    });
  };

  useEffect(() => {
    if (!createPostState.isFocused) {
      const strLength = contentState.content.length * 2;

      document.querySelector('.ql-editor').focus();

      if (props.imagesProp.length > 0 && !createPostState.imagesSet) {
        const imagesArray = [];

        if (props.isEdit) {
          props.imagesProp.map((image, i) => {
            imagesArray.push(image);
          });
        } else {
          props.imagesProp.map((image, i) => {
            imagesArray.push(image.file);
          });
        }

        setCreatePostState({
          ...createPostState,
          images: props.imagesProp,
          imagesData: imagesArray,
          imagesSet: true,
          isFocused: true,
        });

        if (props.content) {
          setContentState({
            ...contentState,
            content: props.content,
          });
        } else {
          setContentState({
            ...contentState,
            content: contentState.content,
          });
        }
      } else {
        if (props.content) {
          setContentState({
            ...contentState,
            content: props.content,
          });
        }

        setCreatePostState({
          ...createPostState,
          isFocused: true,
        });
      }
    }
  }, [createPostState.isFocused]);

  const onCloseLinkPreviewClick = () => {
    setPreviewVisible(false);
  };

  return (
    <CommunityAddPostModalWrapper>
      <StyledText size="32">
        {props.isComment ? (
          props.isEdit ? (
            <String id="edit_your_comment_label" />
          ) : (
            <String id="community_comment_title" />
          )
        ) : props.isEdit ? (
          <String id="edit_your_post_label" />
        ) : (
          <String id="community_post_title" />
        )}
      </StyledText>
      <ArtworkByContainer>
        <AvatarContainer>
          <Avatar {...(props?.avatarProps || props?.artist?.avatarProps)} />
        </AvatarContainer>
        <div style={{ marginLeft: '1rem' }}>
          <StyledTextVariation
            size="14"
            css={{ fontFamily: '$ArtifaktElementBold' }}
          >
            {props?.artist?.name || props?.modalProps?.artist?.name}
          </StyledTextVariation>
          <StyledTextVariation size="14">
            {props?.artist?.job || props?.modalProps?.artist?.job}
          </StyledTextVariation>
        </div>
      </ArtworkByContainer>
      <BlockTextWrapper>
        <ReactQuill
          className="text-editor"
          theme="bubble"
          value={contentState.content}
          onChange={onTextChange}
          placeholder="What do you want to talk about?"
          preserveWhitespace
          modules={{
            toolbar: createPostState.modules,
          }}
        />
        {dataLink.loading && (
          <LoaderSkeleton height="100px" width="100%" isRadius />
        )}

        {linkState.link_data_set &&
        createPostState.linkDisplay &&
        createPostState.images.length === 0 &&
        previewVisible ? (
          <CommunityLinkPreview
            url={linkState.link_url}
            title={linkState.link_title}
            domain={linkState.link_url}
            description={linkState.link_description}
            previewImage={
              linkState.link_image
                ? {
                    src: CDN_URL + linkState.link_image,
                  }
                : null
            }
            inModal={true}
            onCloseClick={onCloseLinkPreviewClick}
          />
        ) : null}
        <ImageUploading
          multiple
          value={createPostState.images}
          maxNumber={props.isComment ? 1 : 10}
          onChange={onChangeImage}
          dataURLKey="data_url"
          acceptType={['jpg', 'jpeg', 'gif', 'png', 'svg']}
        >
          {({ imageList, onImageUpload, onImageRemove, errors }) => (
            <>
              <ImagesContainer>
                {errors && (
                  <ErrorWrapper>
                    {errors.maxNumber && (
                      <span>
                        Number of selected images exceed{' '}
                        {props.isComment ? 1 : 10}
                      </span>
                    )}
                    {errors.acceptType && (
                      <span>
                        <String id="community_post_bad_image_type" />
                      </span>
                    )}
                  </ErrorWrapper>
                )}
                {imageList.map((image, index) => (
                  <SingleImageContainer
                    key={image.src + image.alt + index}
                    isMultiple={imageList.length > 1}
                  >
                    {props.isEdit ? (
                      image['data_url'] ? (
                        <img src={image['data_url']} />
                      ) : (
                        <Image src={image} alt={image.alt} />
                      )
                    ) : (
                      <img src={image['data_url']} />
                    )}
                    <StyledButton
                      type="circle"
                      colorTheme="gray"
                      actionType="onclick"
                      svgIcon="close-big"
                      onClick={() => onImageRemove(index)}
                    />
                  </SingleImageContainer>
                ))}
              </ImagesContainer>

              <div>
                <Button
                  type="circle"
                  colorTheme="gray"
                  actionType="onclick"
                  svgIcon="image-inverted-no-fill"
                  css={{
                    width: '44px',
                    height: '44px',
                    marginTop: '$8',
                  }}
                  onClick={onImageUpload}
                  isDisabled={
                    props.isComment || props.isReply
                      ? createPostState.images.length === 1
                      : createPostState.images.length === 10
                  }
                />
                <ErrorWrapper>
                  {(error || dataComment.error || dataUpdatedPost.error) && (
                    <span>
                      <String id="community_remove_bad_words" />
                    </span>
                  )}
                </ErrorWrapper>
              </div>
            </>
          )}
        </ImageUploading>
      </BlockTextWrapper>
      <ButtonContainer>
        <StyledBtn
          type="normal"
          colorTheme="gray"
          actionType="onclick"
          onClick={hideOrRemoveImagesAndHide}
          label={cancelString}
          isDisabled={loading || dataComment.loading || dataUpdatedPost.loading}
        />
        <StyledBtn
          type="normal"
          colorTheme="normal"
          actionType="onclick"
          onClick={onSaveClick}
          label={saveString}
          isLoading={loading || dataComment.loading || dataUpdatedPost.loading}
          isDisabled={
            loading ||
            dataComment.loading ||
            dataUpdatedPost.loading ||
            (createPostState.images.length === 0 &&
              (contentState.content === '<p><br></p>' ||
                contentState.content === '' ||
                contentState.content === '<br>') &&
              'disabled')
          }
        />
      </ButtonContainer>
    </CommunityAddPostModalWrapper>
  );
};

CommunityAddPostModal.propTypes = {
  artist: PropTypes.object,
  replyingTo: PropTypes.string,
};
export default CommunityAddPostModal;
