import { styled } from '@root/stitches.config';

export const InputRadioOptionsWrapper = styled('div', {
  display: 'block',
  fontSize: '14px',
  marginRight: '$24',
  marginTop: '$4',

  '&:last-of-type': {
    marginRight: 0,
  },
  variants: {
    mobileVerticalDispay: {
      true: {
        flexDirection: 'column',
        '@bp1': {
          flexDirection: 'row',
        },
      },
    },
    isProfileEdit: {
      true: {
        display: 'flex',
        flexWrap: 'nowrap',
      },
    },
  },
});

export const InputRadioOptionWrapper = styled('div', {
  display: 'inline-flex',
  marginRight: '$24',
  height: '24px',
  variants: {
    mobileVerticalDispay: {
      true: {
        display: 'flex',
        mb: '$16',
        '@bp1': {
          display: 'inline-flex',
          mb: '$8',
        },
      },
    },
  },
});

export const InputRadioErrorMsg = styled('div', {
  fontSize: '$12',
  marginTop: '$4',
  variants: {
    isError: {
      true: {
        color: '$red900',
      },
    },
  },
});

export const InputRadioWrapper = styled('div', {
  marginRight: '$24',
});

export const LabelStyled = styled('p', {
  display: 'block',
  mb: '$20',
  fontSize: '$12',
  fontFamily: 'var(--ArtifaktElement)',
  variants: {
    isError: {
      true: {
        color: '$red900',
      },
    },
  },
});

export const OptionLabelStyled = styled('label', {
  display: 'inline-block',
  marginLeft: '$4',
  cursor: 'pointer',
  lineHeight: '24px',
});

export const InputRadioStyled = styled('input', {
  '&:checked, &:not(:checked)': {
    position: 'absolute',
    left: '-9999px',
  },

  '&:checked + .label-radio, &:not(:checked) + .label-radio': {
    position: 'relative',
    paddingLeft: '27px',
    cursor: 'pointer',
    lineHeight: '20px',
    display: 'inline-block',
    color: '$elements',
  },

  '&:checked + .label-radio:before, &:not(:checked) + .label-radio:before': {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '24px',
    height: '24px',
    border: '2px solid $lightGrey',
    borderRadius: '100%',
    background: 'transparent',
  },

  '&:checked + .label-radio:after, &:not(:checked) + .label-radio:after': {
    content: '""',
    width: '10px',
    height: '10px',
    background: '$darkGrey',
    position: 'absolute',
    top: '7px',
    left: '7px',
    borderRadius: '100%',
    transition: 'all 0.2s ease',
  },

  '&:not(:checked) + .label-radio:after': {
    opacity: 0,
    transform: 'scale(0)',
  },

  '&:checked + .label-radio:after': {
    opacity: 1,
    transform: 'scale(1)',
    background: '$blackToWhite',
  },

  '&:checked + .label-radio:before': {
    border: '1px solid $blackToWhite',
  },
});
