import { styled } from '@root/stitches.config';

export const WysiwygContainer = styled('div', {
  textAlign: 'center',
  mx: 'auto',

  '@bp2': {
    mx: '10%',
  },

  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
    },
    fontSize: {
      small: {
        '& p': {
          fontSize: '12px',
        },
      },
    },
  },
});
