import { styled, keyframes } from '@root/stitches.config';
import Svg from '@components/atoms/svg/Svg';

const ringAnimation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const dashAnimation = keyframes({
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: '0',
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-35',
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-124',
  },
});

const fadeIn = keyframes({
  '0%': { opacity: '0' },
  '100%': { opacity: '1' },
});

// ds_clean
// This component isn't used
// export const RingLoader = styled('div', {
//   display: 'inline-block',
//   position: 'relative',
//   width: '80px',
//   height: '80px',
//   zIndex: 999,

//   div: {
//     boxSizing: 'border-box',
//     display: 'block',
//     position: 'absolute',
//     width: '64px',
//     height: '64px',
//     margin: '$8',
//     border: '8px solid #fff',
//     borderRadius: '50%',
//     animation: `${ringAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
//     borderColor: '$teal500 transparent transparent transparent',

//     '&:nth-child(1)': {
//       animationDelay: '-0.45s',
//     },

//     '&:nth-child(2)': {
//       animationDelay: '-0.3s',
//     },

//     '&:nth-child(3)': {
//       animationDelay: ' -0.15s',
//     },
//   },
// });

// ds_clean
// This component isn't used
// export const RingLoaderSection = styled('div', {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',

//   div: {
//     boxSizing: 'border-box',
//     display: 'block',
//     position: 'absolute',
//     width: '64px',
//     height: '64px',
//     margin: '$8',
//     border: '8px solid #fff',
//     borderRadius: '50%',
//     animation: `${ringAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
//     borderColor: '$teal500 transparent transparent transparent',

//     '&:nth-child(1)': {
//       animationDelay: '-0.45s',
//     },

//     '&:nth-child(2)': {
//       animationDelay: '-0.3s',
//     },

//     '&:nth-child(3)': {
//       animationDelay: ' -0.15s',
//     },
//   },
// });

export const LoaderWrapper = styled('div', {
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '9999',
  width: '100%',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  outline: '0',
  animation: `${fadeIn} ease .4s`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(4px)',
  svg: {
    path: {
      stroke: 'white',
    },
  },
  '.theme-0 &': {
    svg: {
      path: {
        stroke: 'black',
      },
    },
  },
});

export const LoaderWrapperSection = styled('div', {
  width: '100%',
  height: '370px',
  animation: `${fadeIn} ease .4s`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  backdropFilter: 'blur(4px)',

  gridColumn: '1 / 1',
  bp0: {
    gridColumn: '1 / 2',
  },
  '@bp3': {
    gridColumn: '1 / 4',
  },
  '@bp4': {
    gridColumn: 'span 2',
  },
});

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '$4',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#000',
  opacity: '.5',
});

export const ModalOverlaySection = styled('div', {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundColor: '#d6d6d6',
  opacity: '1',
});

export const StyledSpinner = styled('div', {
  '.spinner': {
    // animation: `${ringAnimation} 1.5s ease-in-out infinite`,
    zIndex: '2',
    position: 'absolute',
    width: '64px',
    height: '64px',
  },

  '.spinner_bg': {
    stroke: '$lightGrey',
    strokeLinecap: 'round',
  },

  '.spinner_path': {
    stroke: '$blackToWhite',
    strokeLinecap: 'round',
    animation: `${dashAnimation} 1.5s ease-in-out infinite`,
  },
});
