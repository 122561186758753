import { styled } from '@root/stitches.config';

export const SelectUserTableWrapper = styled('div', {
  backgroundColor: '$box',
  padding: '',
});

export const SelectUserTableHeader = styled('div', {
  height: '64px',
  width: '100%',
  borderBottom: '1px solid $lightGrey',
});

export const SelectUserTableContainer = styled('div', {
  padding: '$48 $72',
});