import React, { useState } from 'react';
import { GET_FAVORITED_POSTS } from '@src/apollo/query';
import NoResult from '@components/molecules/noResult/NoResult';
import { useQuery, useMutation } from '@apollo/client';
import { WrapperPosts } from '../../Favorites.styled';
import CommunityFeed from '@components/organisms/communityFeed/CommunityFeed';
import { CMS_DELETE_GROUP_POSTS } from '@src/apollo/mutation';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import CommunityFeedContext from '@src/context/CommunityFeedContext';

const Posts = ({ username }) => {
  const {data, loading, error, refetch } = useQuery(GET_FAVORITED_POSTS, {
    variables: {
      username,
    },
    fetchPolicy: 'cache-and-network'
  });

  const [deletePosts, deletePostsData] = useMutation(CMS_DELETE_GROUP_POSTS, {
    refetchQueries: [{
      query: GET_FAVORITED_POSTS,
      variables: {
        username,
      }
    }],
  });

  const [communityState, setCommunityState] = useState({
    myGroupsId: [],
  });

  return (
    <WrapperPosts>
      {(data?.user?.postsFavorite?.length >= 1 && !loading) && (
        <CommunityFeedContext.Provider value={[communityState, setCommunityState]}>
          <CommunityFeed 
            postSlugs={data?.user?.postsFavorite?.map(post => post.slug)}
            loading={loading}
            showFilters={false}
            isInFavorites
            onDeleteClick={(id) => deletePosts({
              variables: {
                ids: [id],
              },
            })}
          />
        </CommunityFeedContext.Provider>
      )}
      {(data?.user?.postsFavorite?.length < 1 && !loading) && (
        <NoResult stringId={'noResult_text_in_favorite_post'} isInFavorite />
      )}
      {loading && (
        <>
          <LoaderSkeleton height="240px"/>
          <LoaderSkeleton height="240px"/>
          <LoaderSkeleton height="240px"/>
          <LoaderSkeleton height="240px"/>
        </>
      )}
    </WrapperPosts>
  );
};

export default Posts;
