import Logo from '@src/assets/svg/area_logo.svg';
import { styled } from '@root/stitches.config';

export const HeaderWrapper = styled('header', {
  color: '$mainTextColor',
  backgroundColor: 'black',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',

  '@bp2': {
    height: '94px',
  },
});

export const HeaderContainer = styled('header', {
  position: 'sticky',
  top: '0',
  zIndex: '6',

  '@bp2': {
    position: 'relative',
  },
});

export const HeaderGrid = styled('div', {
  width: '100%',
  maxWidth: '$container',
  display: 'flex',
  ai: 'center',
  justifyContent: 'space-between',
  px: '$8',
  py: '$4',

  '@bp2': {
    px: '$32',
    py: '$24',
  },
  '@bp5': {
    px: '0',
  },
});

export const AreaLogo = styled(Logo, {
  display: 'flex',
  height: '$24',
  width: '100px',
  fill: '#FFF',
  '@bp2': {
    height: '$32',
  },
});

export const RightSide = styled('div', {
  display: 'flex',
  ai: 'center',
  position: 'relative',
});

export const ImageWrapper = styled('div', {
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0,
  border: '$white solid  2px',

  '&:hover, &:focus, &:visited': {
    transition: 'all .3s ease',
    boxShadow: '0px 0px 5px #E1E1E1',
  },
});

export const UserMenu = styled('div', {
  position: 'absolute',
  backgroundColor: '$headerMenu',
  borderRadius: '$8',
  padding: '$16 $24',
  right: 0,
  top: '60px',
  boxShadow: '$2',

  '.theme-0 &': {
    border: '1px solid hsl(211,19%,28%,1)',
  },

  variants: {
    isUserMenuOpened: {
      isOpened: {
        display: 'block',
      },
      isNotOpened: {
        display: 'none',
      },
    },
  },
});

export const ImageProfileWrapper = styled('div', {
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'relative',
  width: '48px',
  height: '48px',
  marginRight: '$16',
  transition: 'box-shadow .4s ease',
});

export const UserMenuItemProfile = styled('li', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  cursor: 'pointer',
  borderRadius: '$24',
  transition: 'background .2s ease',
  '&:hover, &:focus, &:visited': {
    transition: 'background .1s easeInOut',
    backgroundColor: '$elements',
    '.menuIcon': {
      boxShadow: '$2',
    },
  },
});

export const UserMenuItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$16',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  borderRadius: '$24',
  transition: 'background .5s ease',
  a: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  '&:hover, &:focus, &:visited': {
    transition: 'background .1s easeInOut',
    backgroundColor: '$elements',
    '.menuIcon': {
      boxShadow: '$2',
    },
  },
});

export const UserMenuIcon = styled('div', {
  width: '44px',
  height: '44px',
  overflow: 'hidden',
  backgroundColor: '$elements',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '$16',
  transition: 'box-shadow .4s ease',

  svg: {
    width: '24px',
    height: '24px',
    fill: '$blackToWhite',
  },
});

export const DarkmodeWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const FakeRadio = styled('div', {
  display: 'block',
  width: '60px',
  height: '30px',
  position: 'relative',
});

export const FakeRadioBackground = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: '$ultraLightGrey',
  borderRadius: '100px',
  transition: 'background-color .4s ease',
  variants: {
    isActive: {
      true: {
        backgroundColor: '$darkGrey',
      },
    },
  },
});
export const FakeRadioCircle = styled('div', {
  width: '26px',
  height: '26px',
  backgroundColor: '$whiteToBlack',
  borderRadius: '100px',
  position: 'absolute',
  top: '2px',
  left: '2px',
  transform: 'translateX(0)',
  transition: 'transform .3s ease',
  variants: {
    isActive: {
      true: {
        transform: 'translateX(30px)',
      },
    },
  },
});
