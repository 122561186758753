import * as yup from 'yup';

import { ErrorMsg, FormStyled } from './UserSignUpForm.styled';
import React, { useContext, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { Formik } from 'formik';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import { InputText } from '@components/atoms/input/inputText/InputText';
import { Link } from 'gatsby';

import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';

const UserSignUpForm = props => {
  const UrlContext = useContext(UrlsContext);
  const [logInFormState, setLogInFormState] = useState({
    username: '',
    password: '',
    passwordConfirmation: '',
    email: '',
    firstName: '',
    lastName: '',
    termsAccept: false,
    isLoading: false,
    isAuthError: false,
    isSuccess: false,
    authErrorMessage: '',
    firstNameErrorMsg: null,
    lastNameErrorMsg: null,
    emailErrorMsg: null,
    passwordErrorMsg: null,
    passwordConfirmationErrorMsg: null,
    termsAcceptErrorMsg: null,
    isFirstNameError: false,
    isLastNameError: false,
    isEmailError: false,
    isPasswordError: false,
    isPasswordConfirmationError: false,
    isTermsAcceptError: false,
    passwordSuccess: false,
    passwordConfirmationSuccess: false,
  });

  const handleValidation = () => {
    const isEmailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(
      logInFormState.email
    );
    const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/.test(
      logInFormState.password
    );

    const isPasswordLengthValid =
      logInFormState.passwordConfirmation.length > 7;
    const isPasswordConfirmationValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/.test(
      logInFormState.passwordConfirmation
    );
    const isPasswordConfirmationLengthValid =
      logInFormState.passwordConfirmation.length > 7;

    const isFirstNameValid = logInFormState.firstName != '' ? true : false;
    const isLastNameValid = logInFormState.lastName != '' ? true : false;
    const isTermsAcceptValid = logInFormState.termsAccept;

    if (
      !isEmailValid ||
      !isPasswordValid ||
      !isPasswordConfirmationValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isTermsAcceptValid
    ) {
      setLogInFormState({
        ...logInFormState,
        emailErrorMsg: !isEmailValid ? (
          <String id="sign_up_error_valid_email" />
        ) : null,
        isEmailError: !isEmailValid ? true : false,
        passwordErrorMsg:
          !isPasswordValid || !isPasswordLengthValid ? (
            <String id="sign_up_error_valid_password" />
          ) : null,
        isPasswordError:
          !isPasswordValid || !isPasswordLengthValid ? true : false,
        passwordConfirmationErrorMsg:
          !isPasswordConfirmationValid || !isPasswordConfirmationLengthValid ? (
            <String id="sign_up_error_valid_password" />
          ) : null,
        isPasswordConfirmationError:
          !isPasswordConfirmationValid || !isPasswordConfirmationLengthValid
            ? true
            : false,
        firstNameErrorMsg: !isFirstNameValid ? (
          <String id="sign_up_error_valid_first_name" />
        ) : null,
        isFirstNameError: !isFirstNameValid ? true : false,
        lastNameErrorMsg: !isLastNameValid ? (
          <String id="sign_up_error_valid_last_name" />
        ) : null,
        isLastNameError: !isLastNameValid ? true : false,
        termsAcceptErrorMsg: !isTermsAcceptValid ? (
          <String id="sign_up_error_term_accept" />
        ) : null,
        isTermsAcceptError: !isTermsAcceptValid ? true : false,
      });
    } else {
      setLogInFormState({
        ...logInFormState,
        firstNameErrorMsg: null,
        lastNameErrorMsg: null,
        emailErrorMsg: null,
        passwordErrorMsg: null,
        passwordConfirmationErrorMsg: null,
        termsAcceptErrorMsg: null,
        isFirstNameError: false,
        isLastNameError: false,
        isEmailError: false,
        isPasswordError: false,
        isPasswordConfirmationError: false,
        isTermsAcceptError: false,
        isLoading: true,
      });
    }

    return isPasswordValid &&
      isPasswordConfirmationValid &&
      isEmailValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isTermsAcceptValid
      ? true
      : false;
  };

  const handleAuthError = response => {
    if (response.error) {
      switch (response.error[0]) {
        case 'the_email_field_is_required':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: true,
            emailErrorMsg: <String id="sign_up_error_valid_email" />,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_email_must_be_a_valid_email_address':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: true,
            emailErrorMsg: <String id="sign_up_error_valid_email" />,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_password_must_be_a_string':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: <String id="sign_up_error_valid_password" />,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_first_name_field_is_required':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: true,
            firstNameErrorMsg: <String id="sign_up_error_valid_first_name" />,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_last_name_field_is_required':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: true,
            lastNameErrorMsg: <String id="sign_up_error_valid_last_name" />,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_password_field_is_required':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: <String id="sign_up_error_valid_password" />,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_password_must_be_at_least8_characters':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: <String id="sign_up_error_password_length" />,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_password_format_is_invalid':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: <String id="sign_up_error_valid_password" />,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'the_password_confirmation_does_not_match':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: true,
            passwordErrorMsg: <String id="sign_up_error_password_not_match" />,
            isPasswordConfirmationError: true,
            passwordConfirmationErrorMsg: (
              <String id="sign_up_error_password_not_match" />
            ),
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
        case 'invalid_email':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: true,
            emailErrorMsg: <String id="sign_up_error_valid_email" />,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;

        case 'user_email_verification_link_sent_error':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: true,
            authErrorMessage: <String id="sign_up_error_valid_email" />,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;

        case 'the_email_has_already_been_taken':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: false,
            authErrorMessage: false,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: true,
            emailErrorMsg: (
              <>
                <String id="sign_up_error_email_taken" />
                <span> </span>
                <Link
                  to={GetUrl('login', UrlContext)}
                  style={{ color: 'black' }}
                >
                  <String id="sign_up_error_email_taken_goto_sign_in" />
                </Link>
              </>
            ),
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;

        case 'could_not_register_user':
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: true,
            authErrorMessage: <String id="sign_up_error_could_not_register" />,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;

        default:
          setLogInFormState({
            ...logInFormState,
            isLoading: false,
            isAuthError: true,
            authErrorMessage: <String id="sign_up_error_could_not_register" />,
            isFirstNameError: false,
            firstNameErrorMsg: null,
            isLastNameError: false,
            lastNameErrorMsg: null,
            isEmailError: false,
            emailErrorMsg: null,
            isPasswordError: false,
            passwordErrorMsg: null,
            isPasswordConfirmationError: false,
            passwordConfirmationErrorMsg: null,
            isTermsAcceptError: false,
            termsAcceptErrorMsg: null,
          });
          break;
      }
    } else {
      setLogInFormState({
        ...logInFormState,
        isLoading: false,
        isAuthError: true,
        authErrorMessage: <String id="sign_up_error_could_not_register" />,
        isFirstNameError: false,
        firstNameErrorMsg: null,
        isLastNameError: false,
        lastNameErrorMsg: null,
        isEmailError: false,
        emailErrorMsg: null,
        isPasswordError: false,
        passwordErrorMsg: null,
        isPasswordConfirmationError: false,
        passwordConfirmationErrorMsg: null,
        isTermsAcceptError: false,
        termsAcceptErrorMsg: null,
      });
    }
  };

  const handleAuthSuccess = signUpComplete => {
    setLogInFormState({
      ...logInFormState,
      isLoading: false,
      isAuthError: false,
      isSuccess: true,
      authErrorMessage: null,
    });

    props.signUpComplete(logInFormState.email);
  };

  const Schema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, <String id="sign_up_error_valid_first_name" />)
      // .max(50, 'Too Long!')
      .required(<String id="sign_up_error_valid_first_name" />),

    lastName: yup
      .string()
      .min(2, <String id="sign_up_error_valid_last_name" />)
      // .max(50, 'Too Long!')
      .required(<String id="sign_up_error_valid_last_name" />),

    email: yup
      .string()
      .email(<String id="sign_up_error_valid_email" />)
      .required(<String id="sign_up_error_valid_email" />),

    password: yup
      .string()
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        () => <String id="sign_up_password_instruction" />
      )
      .required(<String id="sign_up_error_valid_password" />),

    passwordConfirmation: yup
      .string()
      .required(<String id="sign_up_error_password_not_match" />)
      .oneOf(
        [yup.ref('password'), null],
        <String id="sign_up_error_password_not_match" />
      ),
    termsAccept: yup
      .bool()
      .required(<String id="sign_up_error_term_accept" />)
      .oneOf([true], <String id="sign_up_error_term_accept" />),
  });

  const handleSubmit = async (authRegisterFn, signUpComplete, values) => {
    setLogInFormState({
      ...logInFormState,
      email: values.email,
      isAuthError: false,
      isLoading: true,
    });

    authRegisterFn({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      errorCallback: handleAuthError,
      successCallback: handleAuthSuccess.bind(signUpComplete),
    });
  };

  const [resendEmailState, setResendEmailState] = useState({
    value: undefined,
    isError: false,
  })

  const handleSendVerificationEmail = (email, callback) => {
    const data = {
      email,
      successCallback: () => setResendEmailState({
        value: 'verify_email_resend_token_success',
        isError: false,
      }),
      errorCallback: () => setResendEmailState({
        value: 'verify_email_resend_token_error',
        isError: true,
      }),
    };
    callback(data)
  }

  return (
    <AuthContext.Consumer>
      {auth => (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            termsAccept: false,
          }}
          validationSchema={Schema}
          // onSubmit={handleSubmit(auth.register)}
          onSubmit={(values, errors) => {
            handleSubmit(auth.register, props.signUpComplete, values, errors);
          }}

          //onSubmit={e => handleFormSubmit(e, auth.register, props.signUpComplete)}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <FormStyled onSubmit={handleSubmit}>
                {logInFormState.isSuccess ? (
                  <Text>
                    <String id="sign_up_validation_message_1" />{' '}
                    <strong>{values.email}</strong>
                    <br />
                    <String id="sign_up_validation_message_2" />
                    <Text as="p" css={{
                      alignSelf: 'center',
                      mt: '$12',
                      fontSize: '$12',
                      "@bp1": {
                        fontSize: '$14'
                      }
                    }}>
                      <String id="sign_up_validation_message_3" />
                      <Button
                        colorTheme='transparent'
                        css={{
                          padding: 0,
                          textTransform: 'unset',
                          margin: 0,
                          textDecoration: 'underline',
                          minHeight: 'unset',
                          fontSize: '$12',
                          '@bp1': {
                            fontSize: '$14',
                          },
                          '&:hover': {
                            opacity: .6,
                            backgroundColor: 'transparent'
                          }
                        }}
                        actionType="onclick"
                        onClick={() => handleSendVerificationEmail(values.email, auth.sendVerificationEmail)}
                      >
                        <String id="verify_email_send_token" />
                      </Button>
                    </Text>
                    {resendEmailState.value !== undefined && (
                      <Text size="14" css={{color: resendEmailState.isError ? '$red' : '$green', mt: '$8'}}>
                        <String id={resendEmailState.value} />
                      </Text>
                    )}
                  </Text>
                ) : (
                  <>
                    <div
                      style={{
                        width: '100%',
                        opacity: logInFormState.isLoading ? 0.3 : 1,
                      }}
                    >
                      <InputText
                        autoFocus={true}
                        onBlur={handleBlur('firstName')}
                        //onChange={handleFirstNameChange}
                        error={errors.firstName}
                        touched={touched.firstName}
                        onChange={handleChange}
                        type="text"
                        inputShape="normal"
                        name="firstName"
                        label={<String id="sign_up_form_first_name" />}
                        value={values.firstName}
                        css={{ marginBottom: '$12' }}
                        error={
                          errors.firstName || logInFormState.firstNameErrorMsg
                        }
                        isError={
                          (errors.firstName && touched.firstName) ||
                          logInFormState.isFirstNameError
                            ? true
                            : false
                        }
                        inputTheme="transparent"
                      />
                      <InputText
                        onBlur={handleBlur('lastName')}
                        //onChange={handleLastNameChange}
                        error={errors.lastName}
                        touched={touched.lastName}
                        onChange={handleChange}
                        type="text"
                        inputShape="normal"
                        name="lastName"
                        label={<String id="sign_up_form_last_name" />}
                        value={values.lastName}
                        css={{ marginBottom: '$12' }}
                        error={
                          errors.lastName || logInFormState.lastNameErrorMsg
                        }
                        isError={
                          (errors.lastName && touched.lastName) ||
                          logInFormState.isLastNameError
                            ? true
                            : false
                        }
                        inputTheme="transparent"
                      />

                      <InputText
                        onBlur={handleBlur('email')}
                        touched={touched.email}
                        onChange={handleChange}
                        type="email"
                        inputShape="normal"
                        name="email"
                        value={values.email}
                        label={<String id="sign_up_form_email_address" />}
                        css={{ marginBottom: '$12' }}
                        error={errors.email || logInFormState.emailErrorMsg}
                        isError={
                          (errors.email && touched.email) ||
                          logInFormState.emailErrorMsg
                            ? true
                            : false
                        }
                        inputTheme="transparent"
                      />
                      <InputText
                        autoComplete={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        name="password"
                        css={{ marginBottom: '$12' }}
                        value={values.password}
                        label={<String id="sign_up_form_password" />}
                        inputShape="normal"
                        instruction={
                          <String id="sign_up_password_instruction" />
                        }
                        isInstruction={true}
                        touched={touched.passwordSuccess}
                        inputTheme="transparent"
                        error={
                          errors.password || logInFormState.passwordErrorMsg
                        }
                        isError={
                          (errors.password && touched.password) ||
                          logInFormState.isPasswordError
                            ? true
                            : false
                        }
                      />
                      <InputText
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        name="passwordConfirmation"
                        css={{ marginBottom: '$12' }}
                        value={values.passwordConfirmation}
                        label={
                          <String id="sign_up_form_password_confirmation" />
                        }
                        inputShape="normal"
                        error={
                          errors.passwordConfirmation ||
                          logInFormState.passwordConfirmationErrorMsg
                        }
                        isError={
                          (errors.passwordConfirmation &&
                            touched.passwordConfirmation) ||
                          logInFormState.isPasswordConfirmationError
                            ? true
                            : false
                        }
                        touched={touched.passwordConfirmation}
                        isInstruction={false}
                        inputTheme="transparent"
                      />

                      <InputCheckBox
                        css={{ marginBottom: '$32' }}
                        checked={values.termsAccept}
                        isError={
                          (errors.termsAccept && touched.termsAccept) ||
                          logInFormState.isTermsAcceptError
                            ? true
                            : false
                        }
                        error={
                          values.termsAccept ||
                          logInFormState.termsAcceptErrorMsg
                        }
                        onChange={handleChange}
                        inputTheme="transparent"
                        name="termsAccept"
                      >
                        I agree to the{' '}
                        <a
                          href="https://www.autodesk.com/termsofservice"
                          target="_blank"
                          alt="Autodesk Terms of Use"
                        >
                          Autodesk Terms of Use
                        </a>{' '}
                        and acknowledge the{' '}
                        <a
                          href="https://www.autodesk.com/privacy"
                          target="_blank"
                          alt="Autodesk Privacy Statement"
                        >
                          Privacy Statement.
                        </a>
                      </InputCheckBox>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Button
                        isDisabled={!(isValid && dirty)}
                        value="Submit"
                        type="normal"
                        label={<String id="sign_up_form_button_label" />}
                        colorTheme="normal"
                        notificationBubbleActive={false}
                        actionType="onclick"
                        isLoading={logInFormState.isLoading}
                        css={{
                          width: '100%',
                        }}
                      />
                    </div>
                    {logInFormState.isAuthError ? (
                      <ErrorMsg>{logInFormState.authErrorMessage}</ErrorMsg>
                    ) : null}
                  </>
                )}
              </FormStyled>
            );
          }}
        </Formik>
      )}
    </AuthContext.Consumer>
  );
};

export default UserSignUpForm;
