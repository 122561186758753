import { CMS_DELETE_GROUPS, CMS_GROUP_ACTION } from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_ALL_GROUPS_CMS } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import Options from '@components/organisms/table/components/columnItem/Options';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import UserContent from '@components/organisms/table/components/columnItem/UserContent';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import useModal from '@components/atoms/modal/useModal';

const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(date, 'LLL, d y');
};

const CommunityGroupGroups = context => {
  const { isShowing, toggle } = useModal();

  const [currentSelectedGroups, setCurrentSelectedGroups] = useState({
    groupIds: [],
  });

  const [groups, setGroups] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    input: {},
    columns: [
      {
        header: 'Name',
        accessor: 'name',
      },
      {
        header: 'Members',
        accessor: 'members',
      },

      {
        header: 'Posts',
        accessor: 'posts',
      },
      {
        header: 'Last Post Date',
        accessor: 'last_post_date',
      },
      {
        header: 'Status',
        accessor: 'status',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const groupsData = useQuery(GET_ALL_GROUPS_CMS, {
    context: { clientName: 'cms' },
    variables: {
      limit: groups.limitRow,
      page: groups.page,
      input: groups.input,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteGroups, deleteGroupsData] = useMutation(CMS_DELETE_GROUPS, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      groupsData.refetch();
    },
  });

  const [groupAction, groupActionData] = useMutation(CMS_GROUP_ACTION, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      groupsData.refetch();
    },
  });

  const [groupsList, setGroupsList] = useState([]);

  const onDeleteGroups = ids => {
    deleteGroups({
      variables: {
        groupIds: ids,
      },
    });
  };

  const onGroupAction = (ids, action) => {
    groupAction({
      variables: {
        groupIds: ids,
        action,
      },
    });
  };

  const bulkActions = [
    {
      label: <String id="admin_create_content_delete_label" />,
      onClickAction: groups => {
        onDeleteGroups(groups.map(gr => gr.id));
      },
    },
    {
      label: 'Publish',
      onClickAction: groups => {
        onGroupAction(
          groups.map(gr => gr.id),
          'publish'
        );
      },
    },
    {
      label: 'Unpublish',
      onClickAction: groups => {
        onGroupAction(
          groups.map(gr => gr.id),
          'unpublish'
        );
      },
    },
  ];

  const singleRowActions = [
    {
      label: <String id="admin_create_content_delete_label" />,
      onClickAction: item => {
        onDeleteGroups([item.id]);
      },
    },
    {
      label: 'Publish',
      onClickAction: item => {
        onGroupAction([item.id], 'publish');
      },
    },
    {
      label: 'Unpublish',
      onClickAction: item => {
        onGroupAction([item.id], 'unpublish');
      },
    },
  ];

  const onNextPage = () => {
    const pageCountFirstNumber = (groups.page - 1) * groups.limitRow + 1;
    let pageCountSecondNumber = pageCountFirstNumber + groups.limitRow - 1;
    if (pageCountSecondNumber > groupsData.data.groups.total) {
      pageCountSecondNumber = groupsData.data.groups.total;
    }

    setGroups({
      ...groups,
      page: groups.page + 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber = (groups.page - 2) * groups.limitRow + 1;
    let pageCountSecondNumber = pageCountFirstNumber + groups.limitRow - 1;
    if (pageCountSecondNumber > groupsData.data.groups.total) {
      pageCountSecondNumber = groupsData.data.groups.total;
    }

    setGroups({
      ...groups,
      page: groups.page - 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setGroups({
      ...groups,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(groups.totalCount / rowCount),
    });
  };

  useEffect(() => {
    if (groupsData.data) {
      const rowDatas = [];
      groupsData.data.groups.data.forEach(group => {
        const imageUrl = '';

        const imageContent = {
          src: CDN_URL + group.icon.path,
        };
        const singleRow = {
          name: <UserContent name={group.name} image={imageContent} />,
          members: <Text>{group.members_count}</Text>,
          posts: <Text>{group.posts.total}</Text>,
          last_post_date: (
            <DateIconLink
              date={convertUTCToLocalDate(
                group.posts?.data?.[0]?.createdAt * 1000
              )}
              url={`/community/groups/${group.slug}`}
            />
          ),
          status: <Text>{group.status}</Text>,
          options: <Options actions={singleRowActions} id={group.id} />,
          id: group.id,
        };
        rowDatas.push(singleRow);
      });

      const pageCountFirstNumber = (groups.page - 1) * groups.limitRow + 1;
      let pageCountSecondNumber = pageCountFirstNumber + groups.limitRow - 1;
      if (pageCountSecondNumber > groupsData.data.groups.total) {
        pageCountSecondNumber = groupsData.data.groups.total;
      }

      setGroups({
        ...groups,
        isLoaded: true,
        totalCount: groupsData.data.groups.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(groupsData.data.groups.total / groups.limitRow),
      });

      setGroupsList(rowDatas);
    }
  }, [groupsData.data]);

  const onSearchSubmit = searchInput => {
    setGroups({
      ...groups,
      input: searchInput !== '' ? { search: searchInput } : {},
      page: 1,
    });
    groupsData.refetch();
  };

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" seomatic={context?.pageContext?.entry?.seomatic} />
      <AdvanceFilters
        title="All Groups"
        showFiltersButton={false}
        // onSubmitFilters={onApplyFilters}
        // filters={createContentFilters.filters}
        onSearchSubmit={onSearchSubmit}
      />
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={groups.totalCount}
        currentCount={groups.currentCount}
        loading={
          groupsData.loading ||
          deleteGroupsData.loading ||
          groupActionData.loading
        }
        rowData={groupsList}
        columns={groups.columns}
        maxPage={groups.maxPage}
        currentPage={groups.page}
        hasOptions
        bulkActions={bulkActions}
      />
    </AdminLayout>
  );
};

export default CommunityGroupGroups;
