import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Svg from '@components/atoms/svg/Svg';
import { Link } from 'gatsby';

export const ComponentWrapper = styled('div', {
  width: '100%',
  height: 'auto',
  borderRadius: '$16',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '$24 $16',
  backgroundColor: '$elements',
  width: '100%',
});

export const TextTitle = styled(Text, {
  fontSize: '$20',
  lineHeight: '24px',
  color: '$mainTextColor',
  fontWeight: 'bold',
  marginBottom: '$16',
  // textTransform: 'uppercase',
  fontFamily: '$ArtifaktLegendBold',
});

export const NavWrapper = styled('ul', {
  width: '100%',
  height: '100%',
});

export const NavItems = styled('li', {
  margin: '0 0 $16 0',

  '&:last-of-type': {
    marginBottom: '0',
  },
  variants: {
    isMenuOpen: {
      true: {
        // opacity: '0.5',
        backgroundColor: '$lightGrey',
        borderRadius: '$24',
      },
    },
  },
});

export const CTAWrapper = styled(Link, {
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  padding: '$4',
  transition: 'background .5s ease',
  borderRadius: '$24',

  '&:hover, &:focus, &:visited': {
    backgroundColor: '$lightGrey',
  },

  variants: {
    isActive: {
      true: {
        backgroundColor: '$lightGrey',
        borderRadius: '$24',
        pointerEvents: 'none',
      },
    },
  },
});

export const PendingNotification = styled('div', {
  width: '100%',
  height: 'auto',
  display: 'none',
  display: 'flex',
  alignItems: 'center',
});

export const TextPending = styled(Text, {
  color: '$mainTextColor',
  fontFamily: '$ArtifaktElementLight',
});

export const BadgeCTA = styled('div', {
  minWidth: '$40',
  minHeight: '$40',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$black',
  margin: '0 $16 0 0',

  '@bp1': {
    minWidth: '$48',
    minHeight: '$48',
  },
});

export const SvgCTA = styled(Svg, {
  height: '$24',
  width: '$24',
  fill: '$white',
});

export const TextStyled = styled(Text, {
  fontSize: '$14',
  lineHeight: '16px',
  color: '$mainTextColor',
  fontWeight: 'bold',
});

export const RedBubble = styled('div', {
  width: '12px',
  height: '12px',
  margin: '0 $8 0 0',
  backgroundColor: '$red',
  borderRadius: '100%',
});

export const SubMenu = styled('div', {
  padding: '$32 $32 $16',
  backgroundColor: '$mediumGrey',
  borderBottomRightRadius: '$24',
  borderBottomLeftRadius: '$24',
});

export const SubMenuLink = styled('a', {
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  margin: '$16 0 0 0',
  textDecoration: 'none',
  cursor: 'pointer',
  position: 'relative',
  transition: 'opacity .3s ease',
  padding: '$4',
  borderRadius: '$24',
  transition: 'background .5s ease',
  '&:hover, &:focus, &:visited': {
    backgroundColor: '$lightGrey',
    '.menuIcon': {
      boxShadow: '$2',
    },
  },

  '.arrow': {
    width: '$24',
    height: '$24',
    position: 'absolute',
    right: '$16',
    transition: 'transform .3s ease',
  },
  variants: {
    isMenuOpen: {
      true: {
        '.arrow': {
          transform: 'rotate(180deg)',
        },
      },
    },
    isActive: {
      true: {
        // opacity: '0.5',
        backgroundColor: '$lightGrey',
        borderTopRightRadius: '$24',
        borderTopLeftRadius: '$24',
      },
    },
  },
});

export const LinkStyled = styled(Link, {
  // marginLeft: '$64',
  display: 'block',
  fontSize: '$14',
  lineHeight: '16px',
  color: '$black',
  fontWeight: 'bold',
  marginBottom: '$16',
  paddingBottom: '$16',
  textDecoration: 'none',
  borderBottom: '1px solid $lightGrey',

  '&:last-of-type': {
    border: '0',
  },

  '&:hover': {
    color: '$white',
    transition: 'color .3s ease',
  },
  variants: {
    isActive: {
      true: {
        color: '$white',
        fontFamily: '$ArtifaktElementBlack',
        pointerEvents: 'none',
      },
    },
  },
});
