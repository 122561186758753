import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const PendingPublicationWrapper = styled('div', {
  width: '100%',
  maxWidth: '640px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  '@bp0': {
    alignItems: 'flex-end',
  },
});

export const ContentWrapper = styled('div', {
    width: '100%'
});

export const OverallWrapper = styled('div', {
  width: '100%',
});

export const ListItemContainer = styled('div', {
  marginBottom: '$16',

  '&:last-of-type': {
    marginBottom: '$24',
  },
});

export const StyledTitle = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  marginRight: '$16',
});

export const TopWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  marginBottom: '$16',
  flexDirection: 'column',
  alignItems: 'flex-start',

  '@bp0': {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '$24',
  },
});

export const TitleWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$8',
  color: '$mainTextColor',

  '@bp0': {
    marginBottom: '0',
    position: 'absolute',
  },
});

export const NewAlertWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const RedDot = styled('div', {
  width: '$12',
  height: '$12',
  backgroundColor: '$red',
  borderRadius: '100%',
  marginRight: '$4',
});

export const StyledNewText = styled(Text, {
  display: 'flex',
  alignItems: 'center',
});
