import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import { Heading } from '@components/atoms/heading/Heading';
import React, { useEffect } from 'react';
import SEO from '@components/atoms/seo/Seo';
import { Row2Col, Row3Col, RowFull } from './StatsCommunity.styled';
import TableBlock from '@components/molecules/statsBlocks/TableBlock';
import { useLazyQuery } from '@apollo/client';
import { GET_STATS_DATA } from '@src/apollo/query';
import String from '@components/atoms/string/String';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import LineChartBlock from '@components/molecules/statsBlocks/LineChartBlock';
import { monthsNames } from '@src/utils/monthNames';
import { Text } from '@components/atoms/text/Text';
import { BigStatBlock } from '@components/molecules/statsBlocks/BigStatBlock';
import { SmallStatCenteredBlock } from '@components/molecules/statsBlocks/SmallStatCenteredBlock';
import BarChartBlock from '@components/molecules/statsBlocks/BarChartBlock';
import { format, parseISO } from 'date-fns';

const StatsCommunity = context => {
  const [getStats, { data, loading, error, called }] = useLazyQuery(GET_STATS_DATA, {
    context: { clientName: 'cms' },
    variables: {
      type: 'community'
    }
  });

  useEffect(() => {
    const dateEarly = new Date();
    getStats({
      variables: {
        dateStart: dateEarly.setMonth(dateEarly.getMonth() - 1) / 1000,
        dateEnd: new Date().valueOf() / 1000,
      },
    });
  }, []);

  function barChartBlockContent() {
    const allKeys = [
      ...new Set([
        ...data.stats.comments_graph.map(el => el.key),
        ...data.stats.reactions_graph.map(el => el.key),
      ]),
    ];
    const datas = allKeys
      .sort((a, b) => new Date(`${a}`) - new Date(`${b}`))
      .map(k => {
        const date = new Date(`${k}`);
        return {
          label: format(parseISO(k), 'MMM/dd/yyyy'),
          datas: {
            comments:
              data.stats.comments_graph.find(el => el.key === k)?.count || 0,
            reactions:
              data.stats.reactions_graph.find(el => el.key === k)?.count || 0,
          },
        };
      });
    return datas;
  }

  return (
    <AdminLayout
      location={context.location}
      roles={['admin', 'editor', 'contributor']}
    >
      <SEO title="Area" />
      <Heading level="h1" weight="bold" css={{ fontSize: '$48' }}>
        <String id="admin_cms_stats_community_title" />
      </Heading>
      <div style={{ position: 'relative', width: '100%' }}>
        <RowFull>
          {loading || !data ? (
            <LoaderSkeleton height="600px" width="100%" isRadius />
          ) : (
            <LineChartBlock
              bigStat={{
                label: 'Total Posts',
                value: data.stats.total_count,
              }}
              onChange={({ startDate, endDate }) => {
                getStats({
                  variables: {
                    dateStart: startDate.valueOf() / 1000,
                    dateEnd: endDate.valueOf() / 1000,
                  },
                });
              }}
              datas={{
                dataSets: [
                  {
                    label: 'Previous period',
                    datas: data.stats.period_previous_graph.map(item => {
                      return {
                        date: `${item.key}`,
                        label: format(parseISO(item.key), 'MMM/dd/yyyy'),
                        value: item.count,
                      };
                    }),
                  },
                  {
                    label: 'Current period',
                    datas: data.stats.period_current_graph.map(item => {
                      return {
                        date: `${item.key}`,
                        label: format(parseISO(item.key), 'MMM/dd/yyyy'),
                        value: item.count,
                      };
                    }),
                  },
                ],
              }}
            />
          )}
        </RowFull>
        <Row2Col>
          {loading || !data ? (
            <>
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Total new posts',
                    accessor: 'key',
                  },
                  {
                    Header: 'Posts',
                    accessor: 'count',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.period_current_count,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.period_previous_count,
                  },
                  {
                    key: '% Change',
                    count: (
                      <Text
                        css={{
                          color: data.stats.period_diff < 0 ? '$red' : '$green',
                        }}
                      >{`${data.stats.period_diff < 0 ? '' : '+'}${
                        data.stats.period_diff
                      }%`}</Text>
                    ),
                  },
                ]}
              />
              <BigStatBlock
                stat={data.stats.period_current_count}
                text="Total new posts for the period"
                percentage={`${data.stats.period_diff < 0 ? '' : '+'}${
                  data.stats.period_diff
                }%`}
                percentageColor={data.stats.period_diff < 0 ? 'red' : 'green'}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading || !data ? (
            <>
              <Row2Col>
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
              </Row2Col>
              <LoaderSkeleton height="400px" width="100%" isRadius />
            </>
          ) : (
            <>
              <Row2Col>
                <SmallStatCenteredBlock
                  stat={data.stats.reactions.current_count}
                  text="Reactions"
                  percentage={`${
                    data.stats.reactions.period_diff > 0 ? '+' : ''
                  }${data.stats.reactions.period_diff}%`}
                  percentageColor={
                    data.stats.reactions.period_diff > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.comments.current_count}
                  text="Comments"
                  percentage={`${
                    data.stats.comments.period_diff > 0 ? '+' : ''
                  }${data.stats.comments.period_diff}%`}
                  percentageColor={
                    data.stats.comments.period_diff > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.posts_replies_per_member.current_count}
                  text="Post replies per member"
                  percentage={`${
                    data.stats.posts_replies_per_member.period_diff > 0 ? '+' : ''
                  }${data.stats.posts_replies_per_member.period_diff}%`}
                  percentageColor={
                    data.stats.posts_replies_per_member.period_diff > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.avg_members_per_group.current_count}
                  text="Average members per group"
                  percentage={`${
                    data.stats.avg_members_per_group.period_diff > 0 ? '+' : ''
                  }${data.stats.avg_members_per_group.period_diff}%`}
                  percentageColor={
                    data.stats.avg_members_per_group.period_diff > 0 ? 'green' : 'red'
                  }
                />
              </Row2Col>
              <BarChartBlock
                horizontal
                datas={{
                  dataSet: barChartBlockContent(),
                }}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading || !data ? (
            <>
              <LoaderSkeleton height="800px" width="100%" isRadius />
              <LoaderSkeleton height="800px" width="100%" isRadius />
              <LoaderSkeleton height="800px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Groups activity',
                    accessor: 'key',
                  },
                  {
                    Header: '# Of Posts',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.stats.per_groups}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Top community members',
                    accessor: 'name',
                  },
                  {
                    Header: '# Of Posts',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.stats.top_community_members}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Top groups',
                    accessor: 'name',
                  },
                  {
                    Header: '# Members',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.stats.top_group_members}
              />
            </>
          )}
        </Row2Col>
      </div>
    </AdminLayout>
  );
};

export default StatsCommunity;
