import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '@context/AuthContext';
import AvatarEdit from './AvatarEdit';
import BioEdit from './BioEdit';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import String from '@components/atoms/string/String';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/client';
import { GET_USER_DATA } from '@src/apollo/query';

const AvatarAndBio = context => {
  const authContext = useContext(AuthContext);

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    navigate(`/m/${authContext.userSlug}`);
  }

  const { loading, error, data, refetch } = useQuery(GET_USER_DATA, {
    variables: { username: authContext.userSlug },
    context: { clientName: 'public' },
    fetchPolicy: 'network-only',
  });

  const [onboardingCompletionState, setOnboardingCompletionContext] = useState([
    {
      title: <String id="sign_up_page_steps_1" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_2" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_3" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_4" />,
      isActive: true,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_5" />,
      isActive: false,
      isCompleted: false,
    },
  ]);

  const [avatarAndBioState, setAvatarAndBioState] = useState({
    currentStep: 'avatar',
    isLoading: true,
  });

  const nextStep = () => {
    refetch();
    setAvatarAndBioState({
      currentStep: 'bio',
    });
  };

  const onSaveBio = () => {
    navigate('/onboarding/industries-tools');
  };

  return (
    <ProtectedRoute redirectTo={'/login'} userAccessReq={['auth', 'verified']}>
      <OnboardingCompletionContext.Provider
        value={[onboardingCompletionState, setOnboardingCompletionContext]}
      >
        <OnboardingLayout
          sideBarTitle={<String id="avatar_bio_sideBar_title" />}
          pageTitle={<String id="sign_up_page_steps_4" />}
          percentage="40%"
          displayAvatar
          displaySkip
          displaySteps
        >
          {avatarAndBioState.currentStep == 'avatar' ? (
            <AvatarEdit onContinue={nextStep} />
          ) : (
            <BioEdit onSaveBio={onSaveBio} />
          )}
        </OnboardingLayout>
      </OnboardingCompletionContext.Provider>
    </ProtectedRoute>
  );
};

export default AvatarAndBio;
