import { styled } from '@root/stitches.config';

export const MapChartCanvas = styled('canvas', {
  background: 'white',
  padding: '$16',
  br: 18,
})

export const MapChartWrapper = styled('div', {
  margin: '$24 0',
});