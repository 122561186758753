import { styled } from '@root/stitches.config';

export const BadgeWrapper = styled('div', {
  position: 'relative',
  display: 'inline-block',
  fontSize: '0',
});

export const BadgeInfoboxWrapper = styled('div', {
  position: 'absolute',
  top: '-50%',
  left: '50%',
  transition: 'transform .2s ease-out, opacity .2s ease-out',
  opacity: '0',
  transform: 'translate(-50%, 10px)',
  pointerEvents: 'none',
  zIndex: '2',
  variants: {
    visible: {
      true: {
        transform: 'translate(-50%,0px)',
        opacity: '1',
      },
      false: {
        transform: 'translate(-50%,10px)',
        opacity: '0',
      },
    },
  },
});

export const BadgeImage = styled('div', {
  display: 'inline-block',
  objectFit: 'cover',
  position: 'relative',
  width: '50px',
  height: '50px',
  fontSize: '0',
  borderRadius: '50%',
  // overflow:'hidden',
  borderRadius: '$2',

  span: {
    position: 'relative',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },

  img: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    boxShadow: '$1',
  },
});
