import {
  ExternalLinkThumbnailCard,
  IntenalLinkThumbnailCard,
  StyledHeading,
  StyledText,
} from './LinkThumbnail.styled';

import Image from '@components/atoms/image/Image';
import PropTypes from 'prop-types';
import React from 'react';

const LinkThumbnail = props => {
  const { staticImage, link, text } = props;
  const { src, alt } = staticImage;
  const { actionType, to, href, target, label } = link;

  const cardContent = () => {
    return (
      <>
        <StyledHeading level="h4">{label}</StyledHeading>
        {text && text !== '' && <StyledText as="p">{text}</StyledText>}
        <Image src={src} alt={alt} isBackground />
      </>
    );
  };

  if (actionType === 'internalLink') {
    return (
      <IntenalLinkThumbnailCard to={to} target={target}>
        {cardContent()}
      </IntenalLinkThumbnailCard>
    );
  }
  return (
    <ExternalLinkThumbnailCard
      href={href}
      target={target}
      rel="noopener noreferrer"
    >
      {cardContent()}
    </ExternalLinkThumbnailCard>
  );
};

export default LinkThumbnail;

LinkThumbnail.propTypes = {
  staticImage: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({})),
        ]),
      }),
    }),
  }).isRequired,
  link: PropTypes.shape({
    actionType: PropTypes.oneOf(['internalLink', 'externalLink']).isRequired,
    to: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  text: PropTypes.string,
};

LinkThumbnail.defaultProps = {
  text: '',
};
