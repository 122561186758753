import { BlockTools, EditorBlockWrapper } from './EditorBlock.styled';
import React from 'react';

import BlockImage from './blocks/BlockImage';
import BlockText from './blocks/BlockText';
import BlockVideo from './blocks/BlockVideo';
import Button from '@components/atoms/button/Button';
import UserContentBlock from './blocks/userContentBlock/UserContentBlock';
import BlockSketchfab from './sketchfabBlock/SketchfabBlock';

const EditorBlock = props => {
  const UpDownButton = ({ direction, onClick, disabled }) => {
    return (
      <Button
        actionType="onclick"
        colorTheme="gray"
        css={{
          margin: '4px',
          width: '$32',
          height: '$32',
        }}
        svgIcon={`arrow-${direction}-short`}
        type="circle"
        circleSize="small"
        onClick={onClick}
        isDisabled={disabled}
      />
    );
  };

  const handleClickMoveUp = () => {
    props.onChangeBlockChangeOrder(props.blockIndex, 'up');
  };

  const handleClickMoveDown = () => {
    props.onChangeBlockChangeOrder(props.blockIndex, 'down');
  };
  
  return (
    <EditorBlockWrapper
      className="block"
      type={props.type}
      blockOrder={props.blockIndex}
    >
      <BlockTools className="block-tools">
        <UpDownButton
          direction="up"
          onClick={handleClickMoveUp}
          disabled={(props.createType === 'asset' && props.blockIndex === 1) || (props.isAddingBlockDone || props.blockIndex === 0)}
        />
        <UpDownButton
          direction="down"
          onClick={handleClickMoveDown}
          disabled={props.isAddingBlockDone || props.blockIndex + 1 === props.totalBlocks}
        />
        <Button
          actionType="onclick"
          colorTheme="gray"
          css={{
            margin: '4px',
            width: '$32',
            height: '$32',
          }}
          svgIcon="trash"
          type="circle"
          circleSize="small"
          onClick={props.onRemoveBlock.bind(null, props.blockIndex)}
          isDisabled={props.isAddingBlockDone}
        />
      </BlockTools>
      {props.type === 'text' && (
        <BlockText
          onEditBlock={props.onEditBlock}
          block={props.block}
          blockIndex={props.blockIndex}
        />
      )}
      {props.type === 'image' && (
        <BlockImage
          block={props.block}
          onEditBlock={props.onEditBlock}
          blockIndex={props.blockIndex}
        />
      )}
      {props.type === 'video' && (
        <BlockVideo
          block={props.block}
          blockIndex={props.blockIndex}
          onEditVideoBlock={props.onEditVideoBlock}
        />
      )}
      {props.type === 'sketchfab' && (
        <BlockSketchfab
          block={props.block}
          blockIndex={props.blockIndex}
          onEditSketchfabBlock={props.onEditSketchfabBlock}
        />
      )}
      {props.type === 'content' && (
        <UserContentBlock
          block={props.block}
          blockIndex={props.blockIndex}
          forceContentType={props.createType === 'tutorial_series'}
          userContentTypes={props.createType === 'tutorial_series' ? 'tutorial' : ['tutorial', 'article', 'artwork']}
          onEditBlock={props.onEditUserContentblock}
          postId={props.postId}
        />
      )}
    </EditorBlockWrapper>
  );
};

export default EditorBlock;
