import {
  ErrorMsg,
  ForgotPasswordBoxWrapper,
  FormStyled,
  ResendText,
  SuccessBox,
} from './ForgotPasswordBox.styled';
import React, { useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const ForgotPasswordBox = () => {
  const [forgotPasswordFormState, setForgotPasswordFormState] = useState({
    email: '',
    isLoading: false,
    emailErrorMsg: null,
    isEmailError: false,
    isEmailSent: false,
  });

  const handleEmailChange = e => {
    setForgotPasswordFormState({
      ...forgotPasswordFormState,
      email: e.currentTarget.value,
    });
  };

  //Regex validation from https://emailregex.com/

  const handleValidation = () => {
    const isEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      forgotPasswordFormState.email
    );

    if (!isEmailValid) {
      setForgotPasswordFormState({
        ...forgotPasswordFormState,
        emailErrorMsg: !isEmailValid ? (
          <String id="forgot_password_error_valid_email" />
        ) : null,
        isEmailError: !isEmailValid ? true : false,
        isLoading: false,
      });
    } else {
      setForgotPasswordFormState({
        ...forgotPasswordFormState,
        emailErrorMsg: null,
        isEmailError: false,
        isLoading: true,
      });
    }

    return isEmailValid ? true : false;
  };

  const handleAuthSuccess = () => {
    setForgotPasswordFormState({
      ...forgotPasswordFormState,
      isLoading: false,
      isAuthError: false,
      isEmailSent: true,
      authErrorMessage: null,
    });

    // navigate("/")
  };

  const handleAuthError = response => {
    switch (response.error) {
      case 'the_email_field_is_required':
        setLogInFormState({
          ...forgotPasswordFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: false,
          emailErrorMsg: <String id="sign_up_error_valid_email" />,
          isEmailError: true,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;
      case 'the_email_must_be_a_valid_email_address':
        setLogInFormState({
          ...forgotPasswordFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="sign_up_error_valid_email" />,
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;

      case 'user_password_reset_link_sent_error':
        setLogInFormState({
          ...forgotPasswordFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: (
            <String id="forgot_password_error_send_link_error" />
          ),
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;

      default:
        break;
    }
  };

  const handleFormSubmit = (e, authForgotPasswordFn) => {
    e.preventDefault();
    setForgotPasswordFormState({
      ...forgotPasswordFormState,
      isAuthError: false,
      isLoading: true,
    });

    if (handleValidation()) {
      authForgotPasswordFn({
        email: forgotPasswordFormState.email,
        errorCallback: handleAuthError,
        successCallback: handleAuthSuccess,
      });
    }
  };

  return (
    <ForgotPasswordBoxWrapper>
      {!forgotPasswordFormState.isEmailSent ? (
        <AuthContext.Consumer>
          {auth => (
            <FormStyled
              onSubmit={e => handleFormSubmit(e, auth.forgotPassword)}
            >
              <Text
                as="h2"
                weight="legendExtraBold"
                css={{
                  marginBottom: '$16',
                  // Temporary
                  fontSize: '$24',
                  '@bp2': {
                    fontSize: '$42',
                    lineHeight: '1.2',
                  },
                }}
              >
                <String id="forgot_password_title" />
              </Text>
              <Text
                size="14"
                css={{
                  lineHeight: '1.5em',
                  marginTop: '$16',
                  marginBottom: '$16',
                }}
              >
                <String id="forgot_password_text" />
              </Text>

              <InputText
                autoComplete={true}
                autoFocus={true}
                onChange={handleEmailChange}
                type="text"
                inputShape="normal"
                name="email"
                label={<String id="forgot_password_email_label" />}
                value={forgotPasswordFormState.email}
                css={{
                  marginBottom: '$24',
                  opacity: forgotPasswordFormState.isLoading ? 0.3 : 1,
                }}
                error={forgotPasswordFormState.emailErrorMsg}
                isError={forgotPasswordFormState.isEmailError}
                inputTheme="transparent"
              />
              <div
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <Button
                  type="submit"
                  value="Submit"
                  type="normal"
                  label={<String id="forgot_password_button_label" />}
                  colorTheme="normal"
                  notificationBubbleActive={false}
                  actionType="onclick"
                  css={{
                    opacity: forgotPasswordFormState.isLoading ? 0.3 : 1,
                    width: '100%',
                  }}
                  isLoading={forgotPasswordFormState.isLoading}
                />
              </div>

              {forgotPasswordFormState.isAuthError ? (
                <ErrorMsg>{forgotPasswordFormState.authErrorMessage}</ErrorMsg>
              ) : null}
            </FormStyled>
          )}
        </AuthContext.Consumer>
      ) : (
        <SuccessBox>
          <AuthContext.Consumer>
            {auth => {
              return (
                <>
                  <Text
                    as="h2"
                    weight="legendExtraBold"
                    css={{
                      marginBottom: '$16',
                      // Temporary
                      fontSize: '$24',
                      '@bp2': {
                        fontSize: '$42',
                        lineHeight: '1.2',
                      },
                    }}
                  >
                    <String id="forgot_password_success_title" />
                  </Text>
                  <Text
                    size="14"
                    css={{
                      lineHeight: '1.5em',
                      marginTop: '$16',
                      marginBottom: '$24',
                    }}
                  >
                    <String id="forgot_password_success_text" />
                  </Text>
                  <Text
                    size="14"
                    css={{
                      lineHeight: '1.5em',
                      marginTop: '$16',
                      marginBottom: '$24',
                    }}
                  >
                    <String id="forgot_password_success_text_resend" />
                    &nbsp;
                    <ResendText
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={e => handleFormSubmit(e, auth.forgotPassword)}
                    >
                      <String id="forgot_password_success_text_resend_label" />
                    </ResendText>
                  </Text>
                  <Button
                    actionType="internalLink"
                    type="normal"
                    label={<String id="forgot_password_success_button_label" />}
                    colorTheme="normal"
                    notificationBubbleActive={false}
                    internalUrl="/"
                    css={{
                      width: '100%',
                    }}
                  />
                  {forgotPasswordFormState.isLoading && (
                    <Loader
                      noPortal={true}
                      isLoading={forgotPasswordFormState.isLoading}
                    />
                  )}
                </>
              );
            }}
          </AuthContext.Consumer>
        </SuccessBox>
      )}
    </ForgotPasswordBoxWrapper>
  );
};

export default ForgotPasswordBox;
