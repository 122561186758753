import ChangePasswordBox from '@components/organisms/changePasswordBox/ChangePasswordBox';
import { ForgotPasswordPageWrapper } from '../forgot-password/ForgotPassword.styled';
import Layout from '@components/organisms/layout/Layout';
import React from 'react';
import SEO from '@components/atoms/seo/Seo';

const ChangePasswordPage = context => {
  return (
    <Layout location={context.location}>
      <SEO title="Area - Change Password" slug="changePassword" seomatic={context?.pageContext?.entry?.seomatic} />
      <ForgotPasswordPageWrapper className="container">
        <ChangePasswordBox />
      </ForgotPasswordPageWrapper>
    </Layout>
  );
};
export default ChangePasswordPage;
