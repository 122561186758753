import { InnerFavorites, SectionFavorites } from './Favorites.styled';
import React, { useState } from 'react';

import Articles from './sections/articles/Articles';
import Artworks from './sections/artworks/Artworks';
import Motm from './sections/motm/Motm';
import Posts from './sections/posts/Posts';
import { TabNav } from '@components/molecules/tabNav/TabNav';
import Tutorials from './sections/tutorials/Tutorials';

const FavoritesContent = props => {
  const { slug: username } = props.context;
  const getCurrentSection = () => {
    switch (props.context.pageContext.pageSection) {
      case 'articles':
        return 'articles';
      case 'motm':
        return 'motm';
      case 'posts':
        return 'posts';
      case 'tutorials':
        return 'tutorials';
      default:
        return 'artworks';
    }
  };

  const navItems = [
    {
      label: 'Artworks',
      alt: 'Artworks',
      sectionName: 'artworks',
      url: `/m/${props.context.slug}/favorites/artworks`,
    },
    {
      label: 'Articles',
      alt: 'Articles',
      sectionName: 'articles',
      url: `/m/${props.context.slug}/favorites/articles`,
    },
    {
      label: 'Member Of The Month',
      alt: 'Member Of The Month',
      sectionName: 'motm',
      url: `/m/${props.context.slug}/favorites/mom`,
    },
    {
      label: 'Tutorials',
      alt: 'Tutorials',
      sectionName: 'tutorials',
      url: `/m/${props.context.slug}/favorites/tutorials`,
    },
    {
      label: 'Posts',
      alt: 'Posts',
      sectionName: 'posts',
      url: `/m/${props.context.slug}/favorites/posts`,
    },
  ];

  const [currentSectionState, setCurrentSectionState] = useState(
    getCurrentSection()
  );

  const changeCurrentSection = section => {
    setCurrentSectionState(section);
  };

  const getContent = section => {
    switch (section) {
      case 'articles':
        return <Articles username={username} />;
      case 'motm':
        return <Motm username={username} />;
      case 'tutorials':
        return <Tutorials username={username} />;
      case 'posts':
        return <Posts username={username} />;
      default:
        return <Artworks username={username} />;
    }
  };

  return (
    <SectionFavorites>
      <TabNav
        changeCurrentSection={changeCurrentSection}
        currentSection={currentSectionState}
        tabsItems={navItems}
      />
      <InnerFavorites>{getContent(currentSectionState)}</InnerFavorites>
    </SectionFavorites>
  );
};

export default FavoritesContent;
