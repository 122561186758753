import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';
export const LogInBoxWrapper = styled('div', {
  width: '100%',
});

export const LogInBoxTitle = styled('div', {
  marginBottom: '$24',
});

export const LinkStyled = styled(Link, {
  marginBottom: '$24',
  fontFamily: 'var(--ArtifaktLegendBold)',
  color: '$MainTextColor',
  textDecoration: 'none',
  transition: 'opacity .4s ease',
  '&:hover': {
    opacity: 0.5,
  },
});
