import { styled } from '@root/stitches.config';

export const MenuWrapper = styled('div', {
  position: 'absolute',
  display: 'block',
  bottom: '$16',
  right: '$8',
  zIndex: 2,
});

export const ActionWrapper = styled('div', {
  position: 'absolute',
  right: '0',
  bottom: '54px',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  padding: '$16 $24',
  alignItems: 'flex-start',
  br: 8,
  '.thumbnail-menu-action': {
    padding: '$2 $4',
    minHeight: 'unset',
    textTransform: 'unset',
    textAlign: 'left',
    '&:hover': {
      background: '$lightGrey'
    },
    span: {
      textAlign: 'left',
      margin: '0'
    }
  }
})