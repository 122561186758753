import { styled } from '@root/stitches.config';

export const TableActionsWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  my: '$8',
});

export const TableActionsDropdownWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  svg: {
    width: '24px',
    height: '24px',
    marginRight: '$16',
    fill: '$mainTextColor',
  },
});

export const SelectAllLabel = styled('div', {
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$14',
  color: '$mediumGrey',
  marginLeft: '$4',
  fontWeight: '700',
  cursor: 'pointer',
});
