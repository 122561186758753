import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const SignInPageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  minHeight: '0',
  textAlign: 'center',
  '@bp2': {
    minHeight: '90vh',
  },
});

export const LogInBoxWrapper = styled('div', {
  padding: '$32 $16',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '@bp2': {
    padding: '$72 0px',
    width: '415px',
  },
});

export const SignAsideTitleLabel = styled(Text, {
  fontSize: '$18',
  color: '$ultraLightGrey',
  letterSpacing: '1.4px',
  textTransform: 'uppercase',
  fontFamily: 'var(--ArtifaktElementBold)',
  mb: '$24',
});
