import React, { useEffect, useState } from 'react';

import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
import SEO from '@components/atoms/seo/Seo';
import VerifyEmailForm from './VerifyEmailForm';

const VerifyEmailPage = context => {
  const [onboardingCompletionState, setOnboardingCompletionContext] = useState([
    { title: 'Registration', isActive: false, isCompleted: true },
    { title: 'Email Validation', isActive: true, isCompleted: false },
    { title: 'Account Verification', isActive: false, isCompleted: false },
    { title: 'Avatar and Bio', isActive: false, isCompleted: false },
    { title: 'Industries and Tools', isActive: false, isCompleted: false },
  ]);

  return (
    <OnboardingCompletionContext.Provider
      value={[onboardingCompletionState, setOnboardingCompletionContext]}
    >
      <OnboardingLayout
        sideBarTitle="Get Started with your account"
        pageTitle="Email Verification"
        displaySteps
      >
        <VerifyEmailForm {...context} />
      </OnboardingLayout>
    </OnboardingCompletionContext.Provider>
  );
};

export default VerifyEmailPage;
