import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { getImageSrc } from '@src/utils/getImageSrc';
import { formatDateTimeAgo } from '@src/utils/datesHelper';

export function postBoxDataToViewModel(data, authContext) {
  let post = {};
  if (data?.post) {
    const postComments = [];
    data?.post?.children?.map((child, j) => {
      if (child.status === 'approved') {
        postComments.push(child);
      }
    });
    const parentId = data?.post?.id;
    const postImages = data?.post?.images.map((image, i) => {
      const imageContent = getImageSrc(
        image,
        'post_image',
        `${data?.post?.author.first_name} ${data?.post?.author.last_name}`,
        image.id
      );

      return { ...image, ...imageContent };
    });

    const profilePicture = url => getAvatarImage(url, 'small');

    let postLinkPreview = {};

    if (data?.post?.link_url !== null && data?.post?.link_url !== '') {
      const linkImage = getImageSrc(
        data?.post?.link_image,
        'link_image',
        `${data?.post?.author.first_name} ${data?.post?.author.last_name}`
      );
      postLinkPreview = {
        url: data?.post?.link_url,
        title: data?.post?.link_title,
        description: data?.post?.link_description,
        previewImage: linkImage,
      };
    }
    let isBlocked = false;
    let groupRole = '';

    if (
      data?.myGroupState?.myGroupsRoles?.find(
        obj => obj.id === data?.post?.group.id
      )
    ) {
      if (
        data?.myGroupState?.myGroupsRoles?.find(
          obj => obj.id === data?.post?.group.id
        ).role === 'blocked'
      ) {
        isBlocked = true;
      }
      groupRole = data?.myGroupState?.myGroupsRoles.find(
        obj => obj.id === data?.post?.group.id
      ).role;
    } else if (data?.myGroupState?.myGroupsRole) {
      groupRole = data?.myGroupState?.myGroupsRole;
    }

    post = {
      postComments,
      parentId,
      postLinkPreview,
      commentCount: data?.post?.children?.filter(el => el.status === 'approved')
        .length,
      userInfos: {
        avatarProps: {
          shape: 'circle',
          alt: `${authContext.firstName} ${authContext.lastName}`,
          src: authContext.profilePicture,
          url: `/m/${authContext.userSlug}`,
        },
        modalProps: data?.userInfos,
      },
      postLikes: data?.post?.usersLikedCount,
      postDate: formatDateTimeAgo(data?.post?.createdAt),
      isCurrentUserGroupMember: data?.myGroupState?.myGroupsId.includes(
        data?.post?.group.id
      ),
      isBlocked: data?.isBlocked,
      groupRole,
      groupUrl: `/community/groups/${data?.post?.group.slug}`,
      groupAlt: data?.post?.group.name,
      postAuthor: {
        name: `${data?.post?.author.first_name} ${data?.post?.author.last_name}`,
        jobTitle: data?.post?.author.title,
        groupName: data?.post?.group.name,
        username: data?.post?.author.username,
        avatar: {
          src: profilePicture(data?.post?.author?.userProfile?.profile_picture),
          alt: `${`${data?.post?.author.first_name} ${data?.post?.author.last_name}`}`,
        },
        url: `/m/${data?.post?.author.username}`,
      },
      postText: data?.post?.content,
      postUrl: `${FE_URL}/community/groups/${data?.post?.group.slug}/${data?.post?.slug}`,
      postImages,
      postId: data?.post?.id,
      groupId: data?.post?.group.id,
      likesList: data?.post?.usersLiked,
      isReported: data?.post?.reported,
      total: data?.post?.total,
      isFavorited: data?.post?.isFavorited,
    };
  }

  return post;
}
