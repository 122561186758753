import { styled } from '@root/stitches.config';

export const TableWrapper = styled('div', {
  marginBottom: '$72',
});

export const TableHead = styled('thead', {
  py: '$16',
  position: 'relative',
});

export const TableHeadRow = styled('tr', {
  backgroundColor: '$elements',
});

export const TableFooter = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '$16',
});

export const TableTools = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '$8',
  minHeight: '64px',
});

export const TableHeadCell = styled('th', {
  fontFamily: '$ArtifaktLegendBold',
  textTransform: 'uppercase',
  fontSize: '$14',
  py: '$16',
  textAlign: 'left',
  color: '$mainTextColor',
  paddingRight: '$32',

  '&:nth-of-type(1)': {
    width: '$84',
  },
  variants: {
    isNotMultiSelect: {
      true: {
        '&:nth-of-type(1)': {
          width: 'auto',
          paddingLeft: '$16',
        },
      },
    },
    isFirstColImage: {
      true: {
        '&:nth-of-type(1)': {
          width: '120px',
          paddingLeft: '$16',
        },
      },
    },
  },
});

export const TableBody = styled('tbody');

export const TableCell = styled('td', {
  fontSize: '$14',
  py: '$16',
  textAlign: 'left',
  fontFamily: '$ArtifaktElementBold',
  color: '$mainTextColor',
  paddingRight: '$32',
  verticalAlign: 'center',

  '&:nth-of-type(1)': {
    width: '$84',
    paddingLeft: '$16',
  },
  variants: {
    isNotMultiSelect: {
      true: {
        '&:nth-of-type(1)': {
          width: 'auto',
          paddingLeft: '$16',
        },
      },
    },
    isFirstColImage: {
      true: {
        '&:nth-of-type(1)': {
          width: '120px',
          paddingLeft: '$16',
        },
      },
    },
    isFirstColImage: {
      true: {
        '&:nth-of-type(1)': {
          width: '120px',
          paddingLeft: '$16',
        },
      },
    },
    hasOptions: {
      true: {
        '&:last-of-type': {
          // display: 'flex',
          // justifyContent: 'flex-end',
          // paddingRight: '$16',
        },
      },
    },
  },
});

export const TableRow = styled('tr', {
  width: '100%',

  borderBottom: '1px solid $tableRowBorder',
  transition: 'background-color .4s ease',

  variants: {
    isSelected: {
      true: {
        backgroundColor: '$tableRowBackgroundHover',
      },
    },
  },
});

export const TableContainer = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  minWidth: '1000px',
  // minHeight: '300px',

  variants: {
    loading: {
      true: {
        opacity: '.5',
      },
    },
  },
});

export const TableLoading = styled('div', {
  width: '100%',
  //backgroundColor: '$black',
  // opacity: '.5',
  minHeight: '84px',
  //backdropFilter: 'blur(4px)',
  position: 'absolute',
  display: 'flex',
  ai: 'center',
  jc: 'center',
  ai: 'center',
  zIndex: '30',

  '.loader': {
    top: '17px',
    right: '0px',
    position: 'relative',
    width: '50px',
    height: '50px',
  },
});

export const TableContainerWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  borderCollapse: 'collapse',
  overflowX: 'auto',
  overflowY: 'visible',
  position: 'relative',
  '@bp4': {
    overflowX: 'visible',
  },
});

export const MobileCell = styled('div', {
  marginBottom: '$4',
  variants: {
    isOptions: {
      true: {
        width: '100%',
        button: {
          width: '100%',
          br: 8,
          '&:before': {
            content: 'Options'
          },
          svg: {
            display: 'none'
          }
        }
      }
    }
  }
});

export const MobileRow = styled('div', {
  marginBottom: '$16',
  backgroundColor: '$box',
  br: 8,
  padding: '$16',
  display: 'flex',
  gap: '$24',
  flexWrap: 'wrap',
});
