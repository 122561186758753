import {
  TagListStyled,
  TagListWrapper,
  TitleText,
  TitleWrapper,
} from './TagList.styled';

import Button from '@components/atoms/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from './tag/Tag';

const TagList = props => {
  const { list, title, isOnBoarding, css } = props;

  return (
    <TagListStyled css={{ ...css }}>
      <TitleWrapper>
        <TitleText as="h6" css={{ color: isOnBoarding && 'white' }}>
          {title}
        </TitleText>
        {props.isEditMode ? (
          <Button
            css={{ ml: '$24' }}
            type="circle"
            actionType="onclick"
            svgIcon="edit"
            onClick={props.onEditClick}
            circleSize="small"
          />
        ) : null}
      </TitleWrapper>
      <TagListWrapper>
        {list.map((tag, index) => (
          <li key={tag.label + tag.id}>
            <Tag
              label={tag.label}
              url={tag.url}
              color={isOnBoarding && 'white'}
            />
          </li>
        ))}
      </TagListWrapper>
    </TagListStyled>
  );
};

TagList.propTypes = {
  // title: PropTypes.string,
  // list: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.string.isRequired,
  //     label: PropTypes.string.isRequired,
  //     url: PropTypes.string,
  //   })
  // ),
};

TagList.defaultProps = {
  title: '',
  tags: [],
};

export default TagList;
