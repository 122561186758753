import { styled } from '@root/stitches.config';

export const ToggleButtonWrapper = styled('div', {
  position: 'relative',
  display: 'inline-flex',
  marginRight: '$12',
  marginBottom: '$12',
  variants: {
    isDisabled: {
      true: {
        opacity: 0.3,
      },
    },
  },
});

export const ToggleButtonLabel = styled('label', {
  fontSize: '$12',
  textTransform: 'uppercase',
  lineHeight: '1em !important',
  display: 'inline-flex',
  fontFamily: 'var(--ArtifaktElementRegular)',
  letterSpacing: '.2px',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '$16 $24',
  border: '1px solid $mainTextColor',
  borderRadius: '$pill',
  cursor: 'pointer',
  userSelect: 'none',
  variants: {
    isActive: {
      isActive: {
        backgroundColor: '$blackToWhite',
        color: '$whiteToBlack',
      },
      isNotActive: {
        backgroundColor: 'transparent',
      },
    },
  },
  ':before': {
    display: 'none',
  },
  ':after': {
    display: 'none',
  },
});

export const ToggleButtonStyled = styled('input', {
  display: 'none',
});
