import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import { Heading } from '@components/atoms/heading/Heading';
import React, { useEffect } from 'react';
import SEO from '@components/atoms/seo/Seo';
import { Row2Col, RowFull } from './StatsMembers.styled';
import { DoughnutGraphBlock } from '@components/molecules/statsBlocks/DoughnutGraphBlock';
import TableBlock from '@components/molecules/statsBlocks/TableBlock';
import { useLazyQuery } from '@apollo/client';
import { GET_STATS_DATA } from '@src/apollo/query';
import String from '@components/atoms/string/String';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import { generateRandomColor } from '@src/utils/generateRandomColor';
import LineChartBlock from '@components/molecules/statsBlocks/LineChartBlock';
import { Text } from '@components/atoms/text/Text';
import { BigStatBlock } from '@components/molecules/statsBlocks/BigStatBlock';
import { SmallStatCenteredBlock } from '@components/molecules/statsBlocks/SmallStatCenteredBlock';
import { format, parseISO } from 'date-fns';

const StatsMember = context => {
  const [getStats, { data, loading, error, called }] = useLazyQuery(GET_STATS_DATA, {
    context: { clientName: 'cms' },
    fetchPolicy: 'network-only',
    variables: {
      type: 'members'
    }
  });

  useEffect(() => {
    const dateEarly = new Date();
    getStats({
      variables: {
        dateStart: dateEarly.setMonth(dateEarly.getMonth() - 1) / 1000,
        dateEnd: new Date().valueOf() / 1000,
      },
    });
  }, []);

  return (
    <AdminLayout
      location={context.location}
      roles={['admin', 'editor', 'contributor']}
    >
      <SEO title="Area" />
      <Heading level="h1" weight="bold" css={{ fontSize: '$48' }}>
        <String id="admin_cms_stats_members_title" />
      </Heading>
      <div style={{ position: 'relative', width: '100%' }}>
        <RowFull>
          {loading || !data ? (
            <LoaderSkeleton height="600px" width="100%" isRadius />
          ) : (
            <LineChartBlock
              bigStat={{
                label: 'Members',
                value: data.stats.total_count,
              }}
              onChange={({ startDate, endDate }) => {
                getStats({
                  variables: {
                    dateStart: startDate.valueOf() / 1000,
                    dateEnd: endDate.valueOf() / 1000,
                  },
                });
              }}
              datas={{
                dataSets: [
                  {
                    label: 'Previous period',
                    datas: data.stats.period_previous_graph.map(item => {
                      return {
                        date: `${item.key}`,
                        label: format(parseISO(item.key), 'MMM/dd/yyyy'),
                        value: item.count,
                      };
                    }),
                  },
                  {
                    label: 'Current period',
                    datas: data.stats.period_current_graph.map(item => {
                      return {
                        date: `${item.key}`,
                        label: format(parseISO(item.key), 'MMM/dd/yyyy'),
                        value: item.count,
                      };
                    }),
                  },
                ],
              }}
            />
          )}
        </RowFull>
        <Row2Col>
          {loading || !data ? (
            <>
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Total new members',
                    accessor: 'key',
                  },
                  {
                    Header: 'users',
                    accessor: 'count',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.period_current_count,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.period_previous_count,
                  },
                  {
                    key: '% change',
                    count: (
                      <Text
                        css={{
                          color: data.stats.period_diff < 0 ? '$red' : '$green',
                        }}
                      >{`${data.stats.period_diff < 0 ? '' : '+'}${
                        data.stats.period_diff
                      }%`}</Text>
                    ),
                  },
                ]}
              />
              <BigStatBlock
                stat={data.stats.period_current_count}
                text="Total new registrations for the period"
                percentage={`${data.stats.period_diff < 0 ? '' : '+'}${
                  data.stats.period_diff
                }%`}
                percentageColor={data.stats.period_diff < 0 ? 'red' : 'green'}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading || !data ? (
            <>
              <Row2Col smallGap>
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
              </Row2Col>
              <LoaderSkeleton height="400px" width="100%" isRadius />
            </>
          ) : (
            <>
              <Row2Col smallGap>
                <SmallStatCenteredBlock
                  stat={data.stats.completed_profiles.current_count}
                  text="Completed profile"
                  percentage={`${
                    data.stats.completed_profiles.period_diff > 0 ? '+' : ''
                  }${data.stats.completed_profiles.period_diff}%`}
                  percentageColor={
                    data.stats.completed_profiles.period_diff > 0
                      ? 'green'
                      : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.unverified_phone.current_count}
                  text="Unverified phone"
                  percentage={`${
                    data.stats.unverified_phone.period_diff > 0 ? '+' : ''
                  }${data.stats.unverified_phone.period_diff}%`}
                  percentageColor={
                    data.stats.unverified_phone > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.uncompleted_profiles.current_count}
                  text="Uncompleted Profiles"
                  percentage={`${
                    data.stats.uncompleted_profiles.period_diff > 0 ? '+' : ''
                  }${data.stats.uncompleted_profiles.period_diff}%`}
                  percentageColor={
                    data.stats.uncompleted_profiles.period_diff > 0
                      ? 'green'
                      : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.unverified_email.current_count}
                  text="Unverfied Email"
                  percentage={`${
                    data.stats.unverified_email.period_diff > 0 ? '+' : ''
                  }${data.stats.unverified_email.period_diff}%`}
                  percentageColor={
                    data.stats.unverified_email.period_diff > 0
                      ? 'green'
                      : 'red'
                  }
                />
              </Row2Col>
              <DoughnutGraphBlock
                insideTitle="AREA New Members"
                data={{
                  labels: [
                    'Completed Profiles',
                    'Uncompleted profiles',
                    'Unverified Phone',
                    'Unverified Email',
                  ],
                  datasets: [
                    {
                      data: [
                        data.stats.completed_profiles.current_count,
                        data.stats.uncompleted_profiles.current_count,
                        data.stats.unverified_phone.current_count,
                        data.stats.unverified_email.current_count,
                      ],
                      backgroundColor: [
                        generateRandomColor(),
                        generateRandomColor(),
                        generateRandomColor(),
                        generateRandomColor(),
                      ],
                    },
                  ],
                }}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading || !data ? (
            <>
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Unverified phone',
                    accessor: 'key',
                  },
                  {
                    Header: 'users',
                    accessor: 'count',
                  },
                  {
                    Header: 'users%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.unverified_phone.current_count,
                    diff: `${data.stats.unverified_phone.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.unverified_phone.previous_count,
                    diff: `${data.stats.unverified_phone.previous_diff}%`,
                  },
                  {
                    key: '% change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.unverified_phone.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${
                        data.stats.unverified_phone.period_diff < 0 ? '' : '+'
                      }${data.stats.unverified_phone.period_diff}%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Unverified email',
                    accessor: 'key',
                  },
                  {
                    Header: 'users',
                    accessor: 'count',
                  },
                  {
                    Header: 'users%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.unverified_email.current_count,
                    diff: `${data.stats.unverified_email.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.unverified_email.previous_count,
                    diff: `${data.stats.unverified_email.previous_diff}%`,
                  },
                  {
                    key: '% change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.unverified_email.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${
                        data.stats.unverified_email.period_diff < 0 ? '' : '+'
                      }${data.stats.unverified_email.period_diff}%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Uncompleted profiles',
                    accessor: 'key',
                  },
                  {
                    Header: 'users',
                    accessor: 'count',
                  },
                  {
                    Header: 'users%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.uncompleted_profiles.current_count,
                    diff: `${data.stats.uncompleted_profiles.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.uncompleted_profiles.previous_count,
                    diff: `${data.stats.uncompleted_profiles.previous_diff}%`,
                  },
                  {
                    key: '% change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.uncompleted_profiles.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${
                        data.stats.uncompleted_profiles.period_diff < 0
                          ? ''
                          : '+'
                      }${data.stats.uncompleted_profiles.period_diff}%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Completed profiles',
                    accessor: 'key',
                  },
                  {
                    Header: 'users',
                    accessor: 'count',
                  },
                  {
                    Header: 'users%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.completed_profiles.current_count,
                    diff: `${data.stats.completed_profiles.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.completed_profiles.previous_count,
                    diff: `${data.stats.completed_profiles.previous_diff}%`,
                  },
                  {
                    key: '% change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.completed_profiles.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${
                        data.stats.completed_profiles.period_diff < 0 ? '' : '+'
                      }${data.stats.completed_profiles.period_diff}%`}</Text>
                    ),
                  },
                ]}
              />
            </>
          )}
        </Row2Col>
      </div>
    </AdminLayout>
  );
};

export default StatsMember;
