import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

function SEO({ description, title, slug, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            pathPrefix
            image
            ogLanguage
          }
        }
      }
    `
  );

  return (
    <GatsbySeo
      title={title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title}
      description={description || site.siteMetadata.description}
      canonical={FE_URL}
      openGraph={{
        url: `${FE_URL}/${slug}`,
        title: title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title,
        description: description || site.siteMetadata.description,
        images: [
          {
            url: image || `${FE_URL}/seo/${site.siteMetadata.image}`,
            width: 800,
            height: 600,
            alt: title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title,
          },
        ],
        site_name: 'AREA by Autodesk',
      }}
      twitter={{
        handle: '@handle',
        site: '@site',
        cardType: 'summary_large_image',
      }}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
