import {
  AreaLogo,
  BoxMessage,
  ContentWrapper,
} from './VerifyByPhoneConfirmation.styled';

import AuthContext from '@context/AuthContext';
import Box from '@components/atoms/box/Box';
import FullBackground from '@components/molecules/fullBackground/FullBackground';
import { Heading } from '@components/atoms/heading/Heading';
import React from 'react';
import String from '@components/atoms/string/String';

const VerifyByPhoneConfirmation = props => {
  const FullBackgroundProps = {
    artworkByProps: {
      avatarProps: {
        shape: 'circle',
        alt: 'Alternative User Name',
        src: {
          childImageSharp: {
            fixed: {
              height: 60,
              width: 60,
              src: '/images/profile_image_placeholder.jpg',
              srcSet:
                '/images/profile_image_placeholder.jpg 1x,/images/profile_image_placeholder.jpg 1.5x,/images/profile_image_placeholder.jpg 2x',
            },
          },
        },
      },
      label: 'Artwork by',
      artistName: 'Yuriy Dulich',
      artistProfession: 'FX Artist / 3D Artist',
    },
    image: {
      src: {
        src: '/images/maya_bg.jpg',
        srcset:
          '/images/maya_bg.jpg 1x,/images/maya_bg.jpg  250w,/images/maya_bg.jpg  500w',
      },

      alt: 'Yuriy Dulich',
    },
  };

  return (
    <AuthContext.Consumer>
      {auth => (
        <ContentWrapper>
          <FullBackground {...FullBackgroundProps}></FullBackground>
          <Box
            color="white"
            radius="medium"
            shadow="medium"
            type="centeredMessage"
          >
            <Heading level="h2">
              <String id="sign_up_confirmation_title" />
              <div>
                <AreaLogo />
              </div>
            </Heading>

            <BoxMessage>
              <String id="sign_up_confirmation_text_line1" />{' '}
              <strong>{props.email}</strong>
            </BoxMessage>
            <BoxMessage>Got to onboarding</BoxMessage>
          </Box>
        </ContentWrapper>
      )}
    </AuthContext.Consumer>
  );
};

export default VerifyByPhoneConfirmation;
