import React, { useCallback, useReducer, useState } from 'react';

const initialState = {
  keywords: '',
  types: '',
  contentType: 'multiple',
  filters: {},
  page: 1,
  isSearch: false,
}

const SearchContext = React.createContext({
  ...initialState,
  updateContext: () => {},
});


const searchReducer = (
  state = initialState,
  action
) => {
  const { payload: payload } = action;
  switch (action.type) {
    case 'update':
      return {
        ...payload,
        contentType: payload.types === 'all' ? 'multiple' : 'single',
      };
    case 'clean':
      return initialState;
    default:
      return state;
  }
};

const SearchProdiver = React.memo(({ children }) => {
  const [searchState, dispatch] = useReducer(searchReducer, initialState);

  const handleContext = useCallback(payload => {
    dispatch(payload);
  }, [searchState]);

  return (
    <SearchContext.Provider
      value={{
        handleContext,
        searchState,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
});

export default SearchContext;

export { SearchProdiver };
