const footerMenu = {
  footerMain: [
    {
      title: 'Inspire',
      url: '/inspire',
      children: [
        {
          title: 'Articles',
          url: '/inspire/articles',
        },
        {
          title: 'Gallery',
          url: '/inspire/gallery',
        },
      ],
    },
    {
      title: 'Learn',
      url: '/learn',
      children: [
        {
          title: 'Assets',
          url: '/learn/assets',
        },
        {
          title: 'Courses',
          url: '/learn/courses',
        },
        {
          title: 'Tutorials',
          url: '/learn/tutorials',
        },
      ],
    },
    {
      title: 'Community',
      url: '/community',
      children: [
        {
          title: 'Groups',
          url: '/community/groups',
        },
      ],
    },
  ],
  footerSecondary: [
    {
      title: 'Forums',
      url: 'https://forums.autodesk.com',
      newWindow: '1',
    },
    {
      title: 'Help',
      url:
        'https://forums.autodesk.com/t5/report-a-website-issue/ct-p/report-a-website-issue',
      newWindow: '1',
    },
    {
      title: 'About AREA',
      url: '/about',
      newWindow: '0',
    },
  ],
  policy: [
    {
      title: 'Autodesk',
      url: 'https://autodesk.com',
      newWindow: '1',
    },
    {
      title: 'AREA terms of use',
      url: 'https://area.autodesk.com/legal',
      newWindow: '0',
    },
    {
      title: 'Legal Notices & Trademarks',
      url: 'https://www.autodesk.com/company/legal-notices-trademarks',
      newWindow: '1',
    },
    {
      title: 'Privacy',
      url:
        'https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement',
      newWindow: '1',
    },
    {
      title: 'Terms of Use',
      url:
        'https://www.autodesk.com/company/legal-notices-trademarks/access-use/website-terms-of-use',
      newWindow: '1',
    },
  ],
};

export default footerMenu;
