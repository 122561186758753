import React, { forwardRef } from 'react';

import { DatePickerInner } from './DatePicker.styled';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDateInput = forwardRef(({ value, onClick, label, showCalendarIcon }, ref) => (
  <>
    <label htmlFor={label}>{label}</label>
    <div className="date-picker-input-wrapper">
      <input name="from" className={showCalendarIcon ? 'with-calendar' : ''} onClick={onClick} readOnly ref={ref} value={value} />
      {showCalendarIcon && <Svg icon="calendar" />}
    </div>
  </>
));

function DatePicker(props){
  const {
    selected,
    startDate,
    endDate,
    onChange,
    dateFormat,
    selectsStart,
    showMonthYearPicker,
    showDayMonthYearPicker,
    showCalendarIcon,
    withPortal,
    label,
  } = props;
  
  return (
    <DatePickerInner css={props.css}>
      <ReactDatePicker
        selected={selected}
        onChange={onChange}
        selectsStart={selectsStart}
        showPreviousMonths={props.showPreviousMonths}
        minDate={props.minDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={props.maxDate}
        dateFormat={dateFormat}
        showMonthYearPicker={showMonthYearPicker}
        showDayMonthYearPicker={showDayMonthYearPicker}
        withPortal={withPortal}
        customInput={
          <CustomDateInput
            label={<String id={label} />}
            value={selected}
            showCalendarIcon={showCalendarIcon}
          />
        }
      />
    </DatePickerInner>
  )
}

DatePicker.propTypes = {
  selected: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  selectsStart: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  showDayMonthYearPicker: PropTypes.bool,
  showCalendarIcon: PropTypes.bool,
  withPortal: PropTypes.bool,
  label: PropTypes.string,
};

DatePicker.defaultProps = {
  selected: undefined,
  startDate: new Date(),
  endDate: undefined,
  dateFormat: "YYYY/MM/dd",
  selectsStart: false,
  showMonthYearPicker: false,
  showDayMonthYearPicker: true,
  withPortal: false,
  showCalendarIcon: false,
  label: "Date",
};

export default DatePicker