import { styled } from '@root/stitches.config';

export const Text = styled('div', {
  variants: {
    size: {
      12: {
        fontSize: '$12',
      },
      14: {
        fontSize: '$14',
      },
      16: {
        fontSize: '$16',
      },
      18: {
        fontSize: '$18',
      },
      20: {
        fontSize: '$20',
      },
      24: {
        fontSize: '$24',
      },
      28: {
        fontSize: '$28',
      },
      32: {
        fontSize: '$32',
      },
      36: {
        fontSize: '$36',
      },
      42: {
        fontSize: '$42',
      },
      48: {
        fontSize: '$48',
      },
      54: {
        fontSize: '$54',
      },
      60: {
        fontSize: '$60',
      },
      68: {
        fontSize: '$68',
      },
      76: {
        fontSize: '$76',
      },
      84: {
        fontSize: '$84',
      },
      92: {
        fontSize: '$92',
      },
    },
    weight: {
      regular: {
        fontFamily: '$ArtifaktElementRegular',
      },
      bold: {
        fontFamily: '$ArtifaktElementBold',
      },
      black: {
        fontFamily: '$ArtifaktElementBlack',
      },
      legendRegular: {
        fontFamily: '$ArtifaktLegendRegular',
      },
      legendBold: {
        fontFamily: '$ArtifaktLegendBold',
      },
      legendBlack: {
        fontFamily: '$ArtifaktLegendBlack',
      },
      legendExtraBold: {
        fontFamily: 'ArtifaktLegendExtraBold',
      },
    },
  },
});
