import { styled, tokens } from '@root/stitches.config';

export const ProfileCardBuilderWrapper = styled('div', {
  //width: '240px',
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const ProfileCardBox = styled('div', {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '$black',
  borderRadius: '$16',
  padding: '$8',
  width: '120px',
  boxShadow: '$2',
  right: '0px',
  top: '$16',

  '@bp2': {
    width: '100%',
    position: 'relative',
    marginBottom: '$48',
    backgroundColor: '$white',
    marginTop: '88px',
    position: 'relative',
    textAlign: 'center',
    bottom: 'auto',
    height: 'auto',
  },
});
export const TextMobileWrapper = styled('div', {
  display: 'block',
  '@bp2': {
    display: 'none',
  },
});

export const TextWrapper = styled('div', {
  display: 'none',
  color: '$black',
  '@bp2': {
    display: 'block',
  },
});
