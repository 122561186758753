import {
  GroupBoxLoadingWrapper,
  HeaderLoading,
  IconLoading,
  LineLoading1,
  LineLoading2,
} from './GroupBox.styled';

import PropTypes from 'prop-types';
import React from 'react';

const GroupBoxLoading = props => {
  return (
    <GroupBoxLoadingWrapper inSlider={props.inSlider}>
      <HeaderLoading></HeaderLoading>
      <IconLoading></IconLoading>
      <LineLoading1></LineLoading1>
      <LineLoading2></LineLoading2>
    </GroupBoxLoadingWrapper>
  );
};

export default GroupBoxLoading;
