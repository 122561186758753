import React, { useEffect, useState } from 'react';

import AboutMeEdit from '@components/organisms/profileEdit/resume/AboutMeEdit';
import { GET_PROFILE_INFO } from '@src/apollo/query';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import Modal from '@components/atoms/modal/Modal';
import ProfileBox from '@components/molecules/profileBox/ProfileBox';
import RecentWorkEdit from '@components/organisms/profileEdit/resume/RecentWorkEdit';
import SchoolAndTrainingEdit from '@components/organisms/profileEdit/resume/SchoolAndTrainingEdit';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import WorkExperienceEdit from '@components/organisms/profileEdit/resume/WorkExperienceEdit';
import useModal from '@components/atoms/modal/useModal';
import { useQuery } from '@apollo/client';

function Resume(props) {
  const { isCurrentUserProfile } = props;

  const { loading, error, data, refetch } = useQuery(GET_PROFILE_INFO, {
    variables: { username: props.user },
    context: { clientName: 'public' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [boxesState, setBoxesState] = useState([]);

  const { isShowing, toggle } = useModal();
  const [boxEditState, setBoxEditState] = useState({
    currentEditSection: '',
    currentEditId: '',
    currentBoxItemId: '',
    boxItemsLength: 0,
    list: [],
  });

  function sortedByDate(arr) {
    arr.sort(function compare(a, b) {
      var dateA = new Date(a.dateStart);
      var dateB = new Date(b.dateStart);
      return dateB - dateA;
    });
    return arr;
  }

  function locationToString(obj) {
    let locationFull = '';

    if (obj && obj.city) {
      locationFull += obj.city;
    }

    if (obj && obj.state) {
      if (locationFull != '') {
        locationFull += ', ';
      }
      locationFull += obj.state;
    }

    if (obj && obj.country) {
      if (locationFull != '') {
        locationFull += ', ';
      }
      locationFull += obj.country;
    }
    return locationFull;
  }

  useEffect(() => {
    if (data) {
      const newDataArray = [
        {
          id: data.user.userProfile.aboutMeOrder.toString(),
          title: 'profile_resume_aboutMe_title',
          section: 'about_me',
          type: 'text',
          copy: data.user.aboutMe,
          display:
            props.isCurrentUserProfile ||
            ((data.user.aboutMe.length > 0 ||
              data.user.aboutMe !== '<p></p>') &&
              true),
        },
        {
          id: data.user.userProfile.schoolsOrder.toString(),
          title: 'profile_resume_schools_title',
          section: 'schools',
          type: 'listThumbsSquare',
          list: data.user.schools.length
            ? newSchoolsArr(data.user.schools)
            : [],
          display: props.isCurrentUserProfile || data.user.schools.length > 0,
        },
        {
          id: data.user.userProfile.companiesOrder.toString(),
          title: 'profile_resume_companies_title',
          section: 'work_experience',
          type: 'listThumbsSquare',
          list: data.user.companies.length
            ? newCompaniesArr(data.user.companies)
            : [],
          display: props.isCurrentUserProfile || data.user.companies.length > 0,
        },
        {
          id: data.user.userProfile.projectsOrder.toString(),
          title: 'profile_resume_projects_title',
          section: 'recent_work',
          type: 'listThumbsVertical',
          list: data.user.projects.length
            ? newProjectsArr(data.user.projects)
            : [],
          display: props.isCurrentUserProfile || data.user.projects.length > 0,
        },
      ];

      setBoxesState(newDataArray);
    }
  }, [data]);

  const newSchoolsArr = schools => {
    const newArr = [];
    schools.map(school => {
      newArr.push({
        id: school.id,
        name: school.name,
        course: school.course,
        image: school.icon,
        order: school.order,
        dateStart: school.dateStart,
        dateEnd: school.dateEnd,
        is_current: school.is_current,
        location: {
          placeId: school.location.placeId,
        },
        locationFull: locationToString(school.location),
      });
    });
    const newObj = newArr.length ? sortedByDate(newArr) : [];
    newObj.map((obj, index) => (obj.order = index));
    return newObj;
  };

  const newProjectsArr = projects => {
    const newArr = projects.map(project => ({
      id: project.id,
      url: project.url,
      image: project.image,
      title: project.title,
      job_title: project.job_title,
      order: project.order,
      dateStart: project.dateStart,
      dateEnd: project.dateEnd,
      location: {
        placeId: project.location.placeId,
      },
      locationFull: locationToString(project.location),
    }));

    const newObj = newArr.length ? sortedByDate(newArr) : [];
    newObj.map((obj, index) => (obj.order = index));
    return newObj;
  };

  const newCompaniesArr = companies => {
    const newArr = companies.map(company => ({
      id: company.id,
      name: company.name,
      image: company.image,
      job_title: company.job_title,
      order: company.order,
      dateStart: company.dateStart,
      dateEnd: company.dateEnd,
      is_current: company.is_current,
      location: {
        placeId: company.location.placeId,
      },
      locationFull: locationToString(company.location),
    }));

    const newObj = newArr.length ? sortedByDate(newArr) : [];
    newObj.map((obj, index) => (obj.order = index));
    return newObj;
  };

  const openEditModal = (id, section, boxItemId) => {
    setBoxEditState({
      ...boxEditState,
      currentEditSection: section,
      currentEditId: id,
      currentBoxItemId: boxItemId,
      boxItemsLength: boxesState[id].list ? boxesState[id].list.length : 0,
      list: boxesState[id].list,
    });
    toggle();
  };

  const onSaveAboutMe = aboutMeState => {
    let newArr = [...boxesState];
    newArr[boxEditState.currentEditId].copy = aboutMeState.copy;
    setBoxesState(newArr);
    props.updateProfileSave();
    toggle();
  };

  const onSaveWorkExperience = () => {
    props.updateProfileSave();
  };

  const onSaveSchools = schoolAndTrainingState => {
    let newSchools = newSchoolsArr(schoolAndTrainingState.schools);
    let newArr = [...boxesState];

    newArr[boxEditState.currentEditId].list = [...newSchools];

    props.updateProfileSave();
    setBoxesState(newArr);
  };

  const onSaveProjects = projectsState => {
    let newProjects = newProjectsArr(projectsState.projects);
    let newArr = [...boxesState];
    newArr[boxEditState.currentEditId].list = [...newProjects];
    props.updateProfileSave();

    setBoxesState(newArr);
  };

  const BoxItem = props => {
    const { title, copy, type, section, position, boxNumber, box } = props;

    return (
      <ProfileBox
        title={title}
        copy={copy}
        type={type}
        section={section}
        position={position}
        boxNumber={boxNumber}
        box={box}
        onEditClick={openEditModal.bind(null, boxNumber, section)}
        isCurrentUserProfile={isCurrentUserProfile}
      />
    );
  };

  const hasNoItemsToShow = boxesState =>
    boxesState.every(v => v.display === true);

  return (
    <>
      {hasNoItemsToShow ? (
        <div>
          {loading && (
            <>
              <LoaderSkeleton height="200px" width="100%" isRadius />
              <LoaderSkeleton height="200px" width="100%" isRadius />
              <LoaderSkeleton height="200px" width="100%" isRadius />
              <LoaderSkeleton height="200px" width="100%" isRadius />
            </>
          )}
          {boxesState.map((box, index) => {
            if (box.display) {
              return (
                <div
                  key={box.id + box.title + box.type}
                  className="draggable-inner"
                >
                  <BoxItem
                    id={box.id}
                    title={box.title}
                    copy={box.copy}
                    type={box.type}
                    section={box.section}
                    boxNumber={index}
                    box={box}
                  />
                </div>
              );
            }
          })}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Text as="h3" css={{ fontFamily: '$ArtifaktLegendBold' }}>
            {`${props.userData.firstName} ${props.userData.lastName} `}
            <String id="profile_empty_resume_title" />
          </Text>
          <Text as="p">
            <String id="profile_empty_resume_paragraph" />
          </Text>
        </div>
      )}

      <Modal isShowing={isShowing} hide={toggle}>
        {boxEditState.currentEditSection == 'about_me' && (
          <AboutMeEdit
            data={boxesState[boxEditState.currentEditId].copy}
            onClose={toggle}
            onSave={onSaveAboutMe}
          />
        )}

        {boxEditState.currentEditSection == 'recent_work' && (
          <RecentWorkEdit
            onClose={toggle}
            onSave={onSaveProjects}
            list={[...boxEditState.list]}
            order={boxEditState.boxItemsLength}
            id={boxEditState.currentBoxItemId}
            data={boxEditState.list[boxEditState.currentBoxItemId]}
          />
        )}

        {boxEditState.currentEditSection == 'work_experience' && (
          <WorkExperienceEdit
            onClose={toggle}
            onSave={onSaveWorkExperience}
            list={[...boxEditState.list]}
            order={boxEditState.boxItemsLength}
            id={boxEditState.currentBoxItemId}
            data={boxEditState.list[boxEditState.currentBoxItemId]}
          />
        )}

        {boxEditState.currentEditSection == 'schools' && (
          <SchoolAndTrainingEdit
            onClose={toggle}
            onSave={onSaveSchools}
            list={[...boxEditState.list]}
            order={boxEditState.boxItemsLength}
            id={boxEditState.currentBoxItemId}
            data={boxEditState.list[boxEditState.currentBoxItemId]}
          />
        )}
      </Modal>

      {error ? <p>An error occurred</p> : null}
    </>
  );
}
export default Resume;
