import { styled } from '@root/stitches.config';

export const BoxHeaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '$24',
  '@bp1': {
    pr: '$48',
    pl: '$48',
  },
});

export const BoxToolsWrapper = styled('div', {
  display: 'flex',
});

export const AddContentWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontFamily: '$ArtifaktElementBold',
});

export const BoxWrapper = styled('div', {
  padding: '12px 0',
  marginBottom: '20px',
  borderRadius: '16px',
  transition: 'background-color .7s ease',

  '&:hover': {
    backgroundColor: '$lightGrey',
    borderRadius: '$16',
    transition: 'background-color .5s ease',
  },

  '@bp1': {
    display: 'grid',
    gridTemplateAreas: '"left center right"',
    gridTemplateColumns: '54px auto 54px',
    alignItems: 'center',
  },
});

export const BoxContentWrapper = styled('div', {
  ol: {
    listStylePosition: 'inside',
    listStyleType: 'decimal',
  },
  ul: {
    listStylePosition: 'inside',
    listStyleType: 'disc',
  },

  '@bp1': {
    pr: '$48',
    pl: '$48',
  },
});
