import { styled } from '@root/stitches.config';
import SignUpBox from '@components/organisms/signUpBox/SignUpBox';
import { Text } from '@components/atoms/text/Text';

export const SignUpPageWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  width: '100%',
  minHeight: '0',
  overflow: 'hidden',
  position: 'relative',
  '@bp2': {
    gridTemplateColumns: '1fr 2fr',
    minHeight: '90vh',
  },
});

export const SignUpBoxStyled = styled(SignUpBox, {
  maxWidth: '580px',
});

export const SignUpBoxWrapper = styled('div', {
  width: '100%',
  '@bp2': {
    maxWidth: '420px',
  },
});

export const SignAsideTitleLabel = styled(Text, {
  fontSize: '$32',
  color: '$ultraLightGrey',
  fontFamily: 'var(--ArtifaktElementBold)',
  mb: '$24',
  maxWidth: '300px',
  textAlign: 'center',
  lineHeight: '1.2em',
});

export const SignAsideLabel = styled(Text, {
  fontSize: '$12',
  color: '$ultraLightGrey',
  fontFamily: 'var(--ArtifaktElementBold)',
  mb: '$24',
  textAlign: 'center',
  lineHeight: '1.2em',
});

export const ThankYouContentWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  ai: 'center',
  padding: '$72 $16',

  '@bp2': {
    minHeight: '600px',
  },
  '@bp3': {
    minHeight: '800px',
  },
});
