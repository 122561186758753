import { Listing, ListingWrapper } from './ThumbnailListing.styled';

import React from 'react';
import Thumbnail from '../thumbnail/Thumbnail';

const ThumbnailListing = props => {
  return (
    <ListingWrapper>
      <Listing>
        {(props?.thumbnailList || []).map((thumbnail, i) => {
          return (
            <Thumbnail
              key={thumbnail.id}
              id={thumbnail.id}
              type={props.type || thumbnail.type}
              status={thumbnail.status}
              tutorialLevel={thumbnail.tutorialLevel}
              ambassadorType={thumbnail.ambassadorType}
              authoring={thumbnail.authoring}
              badgeList={thumbnail.badgeList}
              menuActions={thumbnail.menuActions}
              title={thumbnail.title}
              imageUrl={thumbnail.imageUrl}
              srcSet={thumbnail.srcSet}
              ambassadorTitle={thumbnail.ambassadorTitle}
              datePosted={thumbnail.datePosted}
              url={thumbnail.url}
              urlTarget={thumbnail.urlTarget}
              seriesCount={thumbnail.seriesCount}
              onLikeClick={thumbnail.onLikeClick}
              showLikeButton={thumbnail.showLikeButton}
              isLiked={thumbnail.isLiked}
              isFeatured={thumbnail.isFeatured}
              isMini={thumbnail.isMini}
            />
          );
        })}
      </Listing>
    </ListingWrapper>
  );
};

export default ThumbnailListing;
