import {
  GroupListingWrapper,
  Listing,
  StyledText,
} from './GroupListing.styled';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import React, { useEffect, useState } from 'react';
import GroupBox from '@components/molecules/groupBox/GroupBox';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import ListingNav from '@components/molecules/listingHeader/listingNav/ListingNav';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';

const GroupListing = props => {
  const suggestGroupText = <String id="community_groups_suggest_group" />;

  const [filterInUseState, setFilterInUse] = useState({
    isActive: props.isActive,
    mobileOpened: false,
    activeSet: false,
  });

  const onFilterDropdownClick = e => {
    const dropdownClass = '.dropdown-' + e;
    const dropdowns = document.querySelectorAll('.dropdowns');
    const thisDropdown = document.querySelector(dropdownClass);

    if (thisDropdown.classList.contains('dropdown-opened')) {
      thisDropdown.classList.toggle('dropdown-opened');
    } else {
      for (let i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('dropdown-opened');
      }

      thisDropdown.classList.add('dropdown-opened');
    }
  };

  const onFilterClick = e => {
    setFilterInUse({
      ...filterInUseState,
      isActive: e,
    });

    props.onFilterClick(e);
  };

  const onMobileFiltersClick = () => {
    if (filterInUseState.mobileOpened) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setFilterInUse({
      ...filterInUseState,
      mobileOpened: !filterInUseState.mobileOpened,
    });
  };

  const onSuggestGroupClick = e => {
    alert('suggest a group');
  };

  useEffect(() => {
    if (props.loadingComplete) {
      setFilterInUse({
        ...filterInUseState,
        activeSet: true,
      });
    }
  }, [props.loadingComplete]);

  return (
    <GroupListingWrapper>
      <ListingHeader title={props.title} intro={props.intro} isCMSDynamic />
      {props?.myGroupsId?.length >= 1 && (
        <ListingNav
          onFilterClick={onFilterClick}
          isActive={filterInUseState.isActive}
          onSuggestGroupClick={onSuggestGroupClick}
          filtersList={props.filtersList}
          otherFilters={props.otherFilters}
          searchResultCount={props.searchResultCount}
          onFilterDropdownClick={onFilterDropdownClick}
          onMobileFiltersClick={onMobileFiltersClick}
          mobileOpened={filterInUseState.mobileOpened}
          type={props.type}
          isLogged={props.isLogged}
          isVerified={props.isVerified}
          isOnboarded={props.isOnboarded}
          css={{ mb: '$24' }}
        />
      )}
      <Listing>
        {filterInUseState.activeSet ? (
          props.groupList.length > 0 ? (
            <>
              {props.groupList.map((group, i) => {
                return (
                  <GroupBox
                    onJoinGroup={() => {
                      props.onJoinGroup(group.groupId);
                    }}
                    key={i}
                    {...group}
                    inListing={true}
                    myGroupsId={props.myGroupsId}
                    isLogged={props.isLogged}
                    isVerified={props.isVerified && props.isOnboarded}
                    myGroupsJoinDate={props.myGroupsJoinDate}
                  />
                );
              })}
              <GroupBox
                groupTitle={'Forums'}
                groupId={props?.groupList?.length + 1 || 'forums-card'}
                key={'forums-card'}
                description="Find answers, share expertise, and connect with your peers."
                inListing={true}
                isForums={true}
                isLogged={props.isLogged}
                isVerified={props.isVerified && props.isOnboarded}
                imageGroupHead={{
                  // url: 'https://forums.autodesk.com/',
                  src:
                    'https://damassets.autodesk.net/content/dam/autodesk/draftr/24577/cropped-1697781048.jpeg',
                }}
              />
            </>
          ) : (
            <StyledText size="24">You have yet to join a group..</StyledText>
          )
        ) : (
          <>
            <LoaderSkeleton height="420px" width="100%" isRadius />
            <LoaderSkeleton height="420px" width="100%" isRadius />
            <LoaderSkeleton height="420px" width="100%" isRadius />
            <LoaderSkeleton height="420px" width="100%" isRadius />
          </>
        )}
      </Listing>
    </GroupListingWrapper>
  );
};

GroupListing.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  groupList: PropTypes.array,
  filtersList: PropTypes.array.isRequired,
  otherFilters: PropTypes.array,
  searchResultCount: PropTypes.number,
  type: PropTypes.string,
};

export default GroupListing;
