import { styled } from '@root/stitches.config';

export const MainSection = styled('div', {
  backgroundRepeat: 'no-repeat',
  backgroundColor: '$bg',
  color: '$mainTextColor',
  maxWidth: '$container',
  margin: 'auto',
  py: '$48',
  display: 'grid',
  gridTemplateColumns: '300px 1fr',
  alignItems: 'flex-start',
  variants: {
    isMobile: {
      true: {
        flexDirection: 'column'
      }
    }
  }
});

export const MainSectionWrapper = styled('div', {
  backgroundColor: '$bg',
  minHeight: 'calc(100vh - 94px)',
});
