import { Line, SeparatorWrapper } from './Separator.styled';

import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const Separator = ({ label, css }) => (
  <SeparatorWrapper css={css}>
    <Line />
    {label ? (
      <>
        <Text css={{ mx: '$4' }} size="12">
          {label}
        </Text>
        <Line />
      </>
    ) : null}
  </SeparatorWrapper>
);
export default Separator;

Separator.defaultProps = {
  label: null,
};

Separator.propTypes = {
  label: PropTypes.string,
};
