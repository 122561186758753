import {
  ModalContentWrapper,
  ModalSubTitle,
  ModalTitle,
} from './PendingPublicationsModal.styled';
import React, { useContext, useState } from 'react';

import Button from '@components/atoms/button/Button';
import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';

const PendingPublicationsModal = props => {
  const stringsContext = useContext(StringsContext);
  const [selectValue, setSelectValue] = useState('');

  const onSelectChange = e => {
    setSelectValue(e);
  };

  const saveBtnText = <String id="general_save_btn_text" />;
  const cancelBtnText = <String id="general_cancel_btn_text" />;

  let options = [];

  props.isReporting
    ? (options = [
        {
          name: stringsContext['community_admin_report_spam']?.field,
          value: 'spam',
        },
        {
          name: stringsContext['community_admin_report_violence']?.field,
          value: 'violence',
        },
        {
          name: stringsContext['community_admin_report_nudity']?.field,
          value: 'adult-content',
        },
        {
          name: stringsContext['community_admin_report_racism']?.field,
          value: 'racism',
        },
        {
          name: stringsContext['community_admin_report_disinformation']?.field,
          value: 'disinformation',
        },
        {
          name: stringsContext['community_admin_report_other']?.field,
          value: 'other',
        },
      ])
    : (options = [
        {
          name: stringsContext['community_admin_report_spam']?.field,
          value: 'spam',
        },
        {
          name: stringsContext['community_admin_report_violence']?.field,
          value: 'violence',
        },
        {
          name: stringsContext['community_admin_report_nudity']?.field,
          value: 'adult-content',
        },
        {
          name: stringsContext['community_admin_report_racisim']?.field,
          value: 'racism',
        },
        {
          name: stringsContext['community_admin_report_disinformation']?.field,
          value: 'disinformation',
        },
        {
          name: stringsContext['community_admin_report_other']?.field,
          value: 'other',
        },
      ]);

  const closeModal = () => {
    setSelectValue('');
    props.closeModal();
  };

  const onRejectClick = () => {
    props.closeModal();
    props.onRejectClick(props.postId, selectValue);
  };

  const onReportClick = () => {
    props.closeModal();
    if (props.isReportingUserContent) {
      props.onReportUserContentClick(selectValue);
    } else {
      props.onReportClick(props.postId, selectValue);
    }
  };

  return (
    <div>
      <ModalContentWrapper>
        <ModalTitle size="32">
          {!props.isReporting ? (
            <String id="community_admin_reject_post" />
          ) : null}
        </ModalTitle>
        <ModalSubTitle size={!props.isReporting ? '16' : '32'}>
          {props.isReporting ? (
            <String id="community_admin_report_subtilte" />
          ) : (
            <String id="community_admin_reject_title" />
          )}
        </ModalSubTitle>
        <InputDropdownSearch
          options={options}
          value={selectValue}
          placeholder={stringsContext['community_admin_reject_choose'].field}
          simpleSelect={true}
          onSelect={onSelectChange}
        />
      </ModalContentWrapper>
      <div>
        <Button
          type="normal"
          actionType="onclick"
          onClick={closeModal}
          colorTheme="gray"
          label={cancelBtnText}
        />
        <Button
          type="normal"
          actionType="onclick"
          onClick={props.isReporting ? onReportClick : onRejectClick}
          colorTheme="normal"
          label={saveBtnText}
          css={{ marginLeft: '$8' }}
          isDisabled={selectValue === ''}
        />
      </div>
    </div>
  );
};

export default PendingPublicationsModal;
