import { styled } from '@root/stitches.config';

export const Row3Col = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '0px 30px',
  marginBottom: '$48',
});

export const Row2Col = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '0px 30px',
  marginBottom: '$48',

  variants: {
    smallGap: {
      true: {
        gap: '0px $16',
      },
    },
  },
});

export const RowFull = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  marginBottom: '$48',
});
