import {
  ArtworkInfoWrapper,
  HeroWrapper,
  StyledButton,
  StyledHeading1,
  StyledHeading4,
  StyledText,
} from './PageHero.styled';

import { Flex } from '@components/atoms/flex/Flex';
import Image from '@components/atoms/image/Image';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const PageHero = props => {
  const { heroData } = props;
  const { heading, subHeading, paragraph, actions, artwork } = heroData;
  return (
    <HeroWrapper hasArtworkInfo={artwork?.artistCredits !== undefined}>
      <Image
        alt={artwork?.artistCredits?.name}
        src={artwork?.image}
        isBackground
      />
      <div className="pageHero_content_wrapper">
        <StyledHeading1 level="h1" className={heading?.level}>
          {heading?.text}
        </StyledHeading1>
        <div
          className={`pageHero_text_wrapper ${
            heading?.level === 'normal' ? 'fullWidth' : ''
          }`}
        >
          {subHeading && subHeading.text && (
            <StyledHeading4 level="h4">{subHeading.text}</StyledHeading4>
          )}
          {paragraph && paragraph.text && (
            <StyledText size="19" as="p">
              {paragraph.text}
            </StyledText>
          )}
        </div>
        {actions && actions.length > 0 && (
          <Flex className="pageHero_actions_wrapper">
            {actions.map(action => (
              <StyledButton
                key={action.label}
                label={action.label}
                internalUrl={action.internalUrl}
                externalUrl={action.externalUrl}
                colorTheme={action.colorTheme}
                onClick={action.onClick}
                actionType={action.actionType}
                svgIcon={action.svgIcon}
                svgLocation={action.svgLocation}
                target={action.target}
              />
            ))}
          </Flex>
        )}
      </div>
      {artwork?.artistCredits?.url && artwork?.artistCredits?.name && (
        <ArtworkInfoWrapper>
          <a
            href={artwork.artistCredits.url}
            target={
              artwork.artistCredits.url.includes(FE_URL) ? '_self' : '_blank'
            }
          >
            <Text as="span" className="info_title">
              {/* <String id="page_artwork_credits" />{' '} */}
              <Text as="span" className="info_title">
                {artwork.artistCredits.name}
              </Text>
            </Text>
          </a>
        </ArtworkInfoWrapper>
      )}
    </HeroWrapper>
  );
};

export default PageHero;

PageHero.propTypes = {
  heroData: PropTypes.shape({
    heading: PropTypes.shape({
      text: PropTypes.string.isRequired,
      level: PropTypes.oneOf(['normal', 'medium', 'large']),
    }),
    subHeading: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    paragraph: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        colorTheme: PropTypes.string,
        internalUrl: PropTypes.string,
        externalUrl: PropTypes.string,
        actionType: PropTypes.oneOf([
          'onclick',
          'externalLink',
          'internalLink',
        ]),
        onClick: PropTypes.func,
        svgIcon: PropTypes.string,
        svgLocation: PropTypes.oneOf(['before', 'after']),
      })
    ),
  }),
};

PageHero.defaultProps = {
  heroData: {},
};
