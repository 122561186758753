import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

const wrapperStyle = {
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  '.thumbnail-authoring-profile-picture': {
    width: '$40',
    height: '$40',
    display: 'circle',
    br: 100,
    mr: '$8',
    objectFit: 'cover',
    position: 'relative',
  },
  '.thumbnail-authoring-light': {
    textTransform: 'uppercase',
    fontSize: '$12',
    fontFamily: '$ArtifaktElement',
    lineHeight: '1',
    color: 'white',
    textAlign: 'left',
  },
  '.thumbnail-authoring-bold': {
    fontSize: '$16',
    fontFamily: '$ArtifaktLegendBold',
    lineHeight: '$2',
    color: 'white',
    textAlign: 'left',
  },
};

export const AvatarWrapper = styled('div', {
  marginRight: '$8',
});

export const TextWrapper = styled('div', {
  '> p': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    boxSizing: 'content-box',
  },
  variants: {
    hasMenu: {
      true: {
        maxWidth: 'calc(100% - 54px)',
      },
    },
  },
});

export const AuthoringWrapper = styled('div', wrapperStyle);
export const AuthoringLinkWrapper = styled(Link, {
  ...wrapperStyle,
  pointerEvents: 'all',
  transition: 'opacity .3s',
  textDecoration: 'none',
  width: 'fit-content',
  '&:hover': {
    opacity: '.7',
  },
});
