import {
  ButtonWrapper,
  InputWrapper,
} from './AccountSettingsModalContent.styled';
import React, { useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import { InputText } from '@components/atoms/input/inputText/InputText';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { UPDATE_USER_PASSWORD } from '@src/apollo/mutation';
import { useMutation } from '@apollo/client';

const AccountSettingsModalContent = props => {
  const { type, setModalState, onSubmit } = props;

  const [currentPassword, setCurrentPassword] = useState({
    text: '',
  });
  const [newPassword, setNewPassword] = useState({
    text: '',
  });

  const [updateUserPassword, updateUserPasswordData] = useMutation(
    UPDATE_USER_PASSWORD,
    {
      onCompleted: () => {
        setModalState({ isShowing: false });
      },
    }
  );

  function submitNewPassword() {
    updateUserPassword({
      variables: {
        password: currentPassword.text,
        new_password: newPassword.text,
      },
    });
  }

  useEffect(() => {
    if (
      updateUserPasswordData.error &&
      updateUserPasswordData.error.graphQLErrors
    ) {
      const source = Object.keys(
        updateUserPasswordData.error.graphQLErrors[0].extensions.validation ||
          {}
      ).join(',');
      if (source === 'new_password') {
        setNewPassword({
          ...newPassword,
          error: (
            <String
              id={`account_settings_update_password_new_password_error_${updateUserPasswordData.error.message}`}
            />
          ),
          isError: true,
        });
      } else {
        setNewPassword({
          ...newPassword,
          error: undefined,
          isError: false,
        });
      }

      if (source !== 'new_password') {
        setCurrentPassword({
          ...currentPassword,
          error: (
            <>
              <String
                id={`account_settings_update_password_current_password_error_${updateUserPasswordData.error.message}`}
              />
              {updateUserPasswordData.error.graphQLErrors &&
              updateUserPasswordData.error.graphQLErrors[0].extra ? (
                <>
                  <br />
                  {` (${updateUserPasswordData.error.graphQLErrors[0].extra.duration} seconds)`}
                </>
              ) : (
                ''
              )}
            </>
          ),
          isError: true,
        });
      } else {
        setCurrentPassword({
          ...currentPassword,
          error: undefined,
          isError: false,
        });
      }
    }
  }, [updateUserPasswordData.error]);

  return (
    <>
      {type === 'password' && (
        <InputWrapper>
          <InputText
            onChange={e => {
              setCurrentPassword({
                ...currentPassword,
                text: e.target.value,
              });
            }}
            type="password"
            inputShape="normal"
            name="current-password"
            forgotPasswordUrl="/forgot-password"
            forgotPasswordLabel={
              <String id="account_settings_change_password_form_current_forgot_password_label" />
            }
            label={
              <String id="account_settings_change_password_form_current_label" />
            }
            value={currentPassword.text}
            css={{ marginBottom: '$32' }}
            error={currentPassword?.error || null}
            isError={currentPassword?.isError || false}
            inputTheme="transparent"
          />
          <InputText
            onChange={e => {
              setNewPassword({
                ...newPassword,
                text: e.target.value,
              });
            }}
            type="password"
            inputShape="normal"
            name="new-password"
            label={
              <String id="account_settings_change_password_form_new_label" />
            }
            value={newPassword.text}
            css={{ marginBottom: '$32' }}
            error={newPassword?.error || null}
            isError={newPassword?.isError || false}
            inputTheme="transparent"
          />
        </InputWrapper>
      )}
      <ButtonWrapper>
        {type !== 'username_changed' && (
          <Button
            label={
              <String id={`account_settings_${type}_modal_cancel_button`} />
            }
            actionType="onclick"
            colorTheme="gray"
            onClick={() => setModalState({ isShowing: false })}
          />
        )}
        <Button
          label={
            <String id={`account_settings_${type}_modal_confirm_button`} />
          }
          css={{ marginLeft: type !== 'username_changed' ? '$16' : '' }}
          actionType="onclick"
          onClick={() => {
            if (type === 'password') {
              if (currentPassword.text.length === 0) {
                setCurrentPassword({
                  ...currentPassword,
                  isError: true,
                  error: <String id="account_settings_input_error_empty" />,
                });
              } else {
                setCurrentPassword({
                  ...currentPassword,
                  isError: false,
                  error: undefined,
                });
              }
              if (newPassword.text.length === 0) {
                setNewPassword({
                  ...newPassword,
                  isError: true,
                  error: <String id="account_settings_input_error_empty" />,
                });
              } else {
                setNewPassword({
                  ...newPassword,
                  isError: false,
                  error: undefined,
                });
              }
              if (
                newPassword.text.length > 1 &&
                currentPassword.text.length > 1 &&
                newPassword.text !== currentPassword.text
              ) {
                submitNewPassword();
              }
            } else {
              onSubmit();
            }
          }}
        />
      </ButtonWrapper>
    </>
  );
};

AccountSettingsModalContent.propTypes = {
  type: PropTypes.oneOf(['password', 'delete_account']).isRequired,
  setModalState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccountSettingsModalContent;
