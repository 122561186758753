import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const ThumbnailSliderWrapper = styled('div', {
  '& .swiper-slide': {
    opacity: '.5',
    transition: 'opacity 0.2s ease-out',

    div: {
      marginBottom: '0',
    },
  },

  '& .swiper-slide.swiper-slide-visible': {
    opacity: '1',
  },
});

export const StyledTitle = styled(Text, {
  textTransform: 'capitalize',
  fontSize: '$48',
  '@bp1': { fontSize: '$84' },
});

export const StyledText = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$32',
  '@bp1': { fontSize: '$32' },
});

export const ThumbnailSliderHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  variants: {
    needMargin: {
      true: {
        marginBottom: '$8',

        '@bp1': {
          marginBottom: '$24',
        },
      },
    },
  },
});

export const SwiperContainer = styled('div', {
  position: 'relative',

  '& .swiper-container': {
    overflow: 'visible',
  },

  button: {
    padding: 'unset',
  },
});

export const PrevBtns = styled('div', {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: '$1',
  left: '10px',
  opacity: '1',
  pointerEvents: 'all',
  transition: 'opacuty 0.2s ease-out',
  display: 'none',

  '&.is-invisible': {
    opacity: '0',
    pointerEvents: 'none',
  },

  '@bp1': {
    display: 'flex',
  },
  '@bp2': {
    left: '-20px',
  }
});

export const NextBtns = styled('div', {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: '$1',
  right: '10px',
  opacity: '1',
  pointerEvents: 'all',
  transition: 'opacuty 0.2s ease-out',
  display: 'none',

  '&.is-invisible': {
    opacity: '0',
    pointerEvents: 'none',
  },

  '@bp1': {
    display: 'flex',
  },
  '@bp2': {
    right: '-20px',
  }
});
