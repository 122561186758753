import { InfoboxWrapper } from './Infobox.styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const Infobox = ({ title, label, css}) => (
  <InfoboxWrapper css={css}>
    <Text size="12" css={{ fontFamily:'var(--ArtifaktElementBold)' }}>{title}</Text>
    <Text size="12">{label}</Text>
  </InfoboxWrapper>
);

Infobox.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
};

Infobox.defaultProps = {
  title: null,
  label: null,
};

export default Infobox;


