import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const DropdownBtnWrapper = styled('span', {
  position: 'relative',
});

export const Dropdown = styled('ul', {
  position: 'absolute',
  top: '42px',
  left: '$16',
  width: 'calc(100% - 1rem)',
  display: 'none',
  zIndex: '$1',
  borderRadius: '0 0 $8 $8',
  backgroundColor: '$box',
  boxShadow: '$1',
  maxHeight: '100px',
  overflowX: 'hidden',
  overflowY: 'auto',

  bp0: {
    maxHeight: '152px',
  },

  '.theme-0 &': {
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '$darkGrey',
      outline: '1px solid $darkGrey',
    },
  },

  '&::-webkit-scrollbar': {
    width: '$4',
    zIindex: '0',
    position: 'absolute',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$lightGrey',
    outline: '1px solid $lightGrey',
  },

  '&.dropdown-opened': {
    display: 'block',
  },

  variants: {
    isOpened: {
      true: {
        display: 'block',
      },
    },
  },

  li: {
    textAlign: 'center',
    padding: '$8',
    transition: 'background-color .2s ease-out',
    textTransform: 'capitalize',
    fontFamily: '$ArtifaktLegendBold',
    fontSize: '$14',
    color: '$mainTextColor',
    zIindex: '1',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '$mediumGrey',
    },
  },
});

export const StyledBtn = styled(Button, {
  marginLeft: '$16',

  // ':focus':{
  //     outline: 'none'
  // }
});
