import {
  AllGroupBoxContainer,
  BtnWrapper,
  CommunityGroupSideBarWrapper,
  DesktopLayout,
  GroupBoxContainer,
  MobileLayout,
  StyledBtn,
  StyledSlider,
  StyledText,
} from './CommunityGroupSidebar.styled';
import React, { useEffect, useState, useContext } from 'react';
import { getImageSrc } from '@src/utils/getImageSrc';
import Button from '@components/atoms/button/Button';
import { GET_ALL_GROUPS } from '@src/apollo/query';
import GroupBox from '@components/molecules/groupBox/GroupBox';
import GroupBoxLoading from '@components/molecules/groupBox/GroupBoxLoading';
import String from '@components/atoms/string/String';
import ThumbnailSlider from '@components/organisms/thumbnailSlider/ThumbnailSlider';

import { useQuery } from '@apollo/client';
import CommunityFeedContext from '@context/CommunityFeedContext';
import { isGroupMember } from '@src/utils/getMemberGroupInfo';

const CommunityGroupSideBar = props => {
  const [groupBoxListState, setGroupBoxListState] = useState({
    showAll: false,
  });

  const [communityState, setCommunityState] = useContext(CommunityFeedContext);

  const [groupBoxState, setGroupBoxState] = useState({
    allGroupList: [],
    myGroupsId: [communityState.myGroupsId],
    myGroupsList: null,
    myGroupsId: [],
    sideBarLoaded: false,
  });

  const onClickShowAll = () => {
    setGroupBoxListState({
      showAll: true,
    });
  };
  useEffect(() => {
    if (props.isSideBarLoaded) {
      setGroupBoxState({
        ...groupBoxState,
        sideBarLoaded: props.isSideBarLoaded,
        myGroupsId: communityState.myGroupsId,
      });
    }
  }, [props.isSideBarLoaded]);

  // useEffect(() => {
  //   if (props.joinNew !== null) {
  //     onJoinGroup(props.joinNew);
  //   }
  // }, [props.joinNew]);

  const seeAllString = <String id="general_see_all" />;

  const { loading, error, data } = useQuery(GET_ALL_GROUPS, {
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'public' },
  });

  useEffect(() => {
    if (data && groupBoxState.sideBarLoaded) {
      const groups = data.groups;
      const groupsIdList = [];

      const allGroupList = [];
      const myGroupsList = [];
      const myGroupsId = [];
      let groupsCount = 0;

      {
        groups.map((element, i) => {
          groupsIdList.push(element.id);

          const isInMyGroups = isGroupMember(
            communityState.myGroupsId,
            element.id
          );
          const iconUrl = getImageSrc(
            element.icon,
            'group_icon',
            element.title
          );
          const headerUrl = getImageSrc(
            element.header_image,
            'group_header_small',
            element.title
          );

          const singleGroup = {
            groupTitle: element.name,
            description: element.description,
            imageGroupIcon: iconUrl,
            imageGroupHead: {
              ...headerUrl,
              url: '/community/groups/' + element.slug,
            },
            groupId: element.id,
          };
          if (isInMyGroups) {
            myGroupsList.push(singleGroup);
            myGroupsId.push(element.id);
          } else {
            allGroupList.push(singleGroup);
          }
        });
      }

      setGroupBoxState({
        ...groupBoxState,
        allGroupList: allGroupList,
        myGroupsList: myGroupsList,
        myGroupsId: myGroupsId,
      });
    }
  }, [data, groupBoxState.sideBarLoaded]);
  const seeMoreText = <String id="general_see_more" />;
  const seeAllGroupsText = <String id="community_mobile_group_slider_link" />;

  return (
    <CommunityGroupSideBarWrapper>
      <DesktopLayout>
        {data ? (
          <>
            {groupBoxState.myGroupsList &&
            groupBoxState.myGroupsList.length > 0 &&
            props.isLogged ? (
              <GroupBoxContainer>
                {groupBoxState.myGroupsList.map((groupBox, i) => {
                  if (i >= 3 && !groupBoxListState.showAll) return;
                  return (
                    <GroupBox
                      {...groupBox}
                      myGroupsId={communityState.myGroupsId}
                      key={i}
                      myGroupsJoinDate={props.myGroupsJoinDate}
                    />
                  );
                })}

                {groupBoxState.myGroupsList.length > 3 &&
                !groupBoxListState.showAll ? (
                  <StyledBtn
                    type="normal"
                    label={seeAllString}
                    colorTheme="elements"
                    notificationBubbleActive={false}
                    actionType="internalLink"
                    internalUrl="groups/my-groups"
                  />
                ) : null}
              </GroupBoxContainer>
            ) : null}
            {groupBoxState.allGroupList.length > 0 && (
              <>
                <StyledText size="18">
                  <String id="community_sidebar_explore_groups" />
                </StyledText>
                <AllGroupBoxContainer>
                  {groupBoxState.allGroupList.map((groupBox, i) => {
                    if (i >= 3) return;
                    return (
                      <GroupBox
                        {...groupBox}
                        myGroupsId={communityState.myGroupsId}
                        key={i}
                        isLogged={props.isLogged}
                        isVerified={props.isVerified && props.isOnboarded}
                      />
                    );
                  })}
                </AllGroupBoxContainer>
                <Button
                  type="normal"
                  label={seeAllString}
                  colorTheme="elements"
                  notificationBubbleActive={false}
                  actionType="internalLink"
                  internalUrl="groups"
                />
              </>
            )}
          </>
        ) : (
          <>
            <GroupBoxContainer>
              {[1, 2, 3].map((groupBox, i) => {
                return <GroupBoxLoading key={i} />;
              })}
              <BtnWrapper>
                <Button
                  type="normal"
                  label={seeAllString}
                  colorTheme="gray"
                  notificationBubbleActive={false}
                  actionType="internalLink"
                  internalUrl="groups"
                />
              </BtnWrapper>
            </GroupBoxContainer>
            {groupBoxState.allGroupList.length > 0 && (
              <>
                <StyledText size="18">
                  <String id="community_sidebar_explore_groups" />
                </StyledText>
                <AllGroupBoxContainer>
                  {[1, 2, 3].map((groupBox, i) => {
                    if (i >= 3) return;
                    return <GroupBoxLoading key={i} />;
                  })}
                </AllGroupBoxContainer>
                <Button
                  type="normal"
                  label={seeAllString}
                  colorTheme="gray"
                  notificationBubbleActive={false}
                  actionType="internalLink"
                  internalUrl="groups"
                />
              </>
            )}
          </>
        )}
      </DesktopLayout>
      <MobileLayout>
        {data ? (
          <>
            {groupBoxState.myGroupsList &&
            groupBoxState.myGroupsList.length > 0 &&
            props.isLogged ? (
              <StyledSlider>
                <ThumbnailSlider
                  thumbnailList={groupBoxState.myGroupsList}
                  myGroupsJoinDate={props.myGroupsJoinDate}
                  btnString={seeMoreText}
                  myGroupsId={groupBoxState.myGroupsId}
                  btnUrl="/community/groups/my-groups"
                />
              </StyledSlider>
            ) : null}
            {groupBoxState.allGroupList.length > 0 && (
              <>
                <StyledText size="18">
                  <String id="community_sidebar_explore_groups" />
                </StyledText>
                <StyledSlider>
                  <ThumbnailSlider
                    thumbnailList={groupBoxState.allGroupList}
                    btnString={seeAllGroupsText}
                    onJoinGroup={props.onJoinGroup}
                    btnUrl="/community/groups"
                    isLogged={props.isLogged}
                    isVerified={props.isVerified}
                    isOnboarded={props.isOnboarded}
                  />
                </StyledSlider>
              </>
            )}
          </>
        ) : (
          <>
            <StyledSlider>
              <ThumbnailSlider
                thumbnailList={[1, 2, 3]}
                btnString={seeMoreText}
                btnUrl="/community/groups/my-groups"
              />
            </StyledSlider>
            <StyledText size="18">
              <String id="community_sidebar_explore_groups" />
            </StyledText>
            <StyledSlider>
              <ThumbnailSlider
                thumbnailList={[1, 2, 3]}
                btnString={seeAllGroupsText}
                onJoinGroup={props.onJoinGroup}
                btnUrl="/community/groups"
              />
            </StyledSlider>
          </>
        )}
      </MobileLayout>
    </CommunityGroupSideBarWrapper>
  );
};

export default CommunityGroupSideBar;
