import React, { useContext } from 'react';

import Avatar from '@components/atoms/avatar/Avatar';
import { Text } from '@components/atoms/text/Text';
import {
  AvatarStyled,
  ProfileCardBox,
  ProfileCardBuilderWrapper,
  TextMobileWrapper,
  TextWrapper,
} from './ProfileCardBuilder.styled';
import AuthContext from '@context/AuthContext';
import UserContext from '@context/UserContext';

const ProfileCardBuilder = props => {
  const authContext = useContext(AuthContext);
  const currentUser = useContext(UserContext);
  const avatarStyle = {
    marginTop: '-64px',
    border: '2px solid $white',
    br: 100,
    boxShadow: '$1',
    width: '80px',
    height: '80px',
    '@bp2': {
      marginTop: '-110px',
      border: '4px solid $white',
      width: '160px',
      height: '160px',
    },
  };

  return (
    <ProfileCardBuilderWrapper>
      <ProfileCardBox>
        <Avatar
          alt=""
          shape="circle"
          src={authContext.profilePicture}
          css={avatarStyle}
          width={'152px'}
          height={'152px'}
          //   onRefreshAvatar={props.onRefreshAvatar}
          //isRefreshAvatar={true}
          //isRandomMode={true}
        />

        <TextMobileWrapper>
          <Text
            size="16"
            weight="legendBold"
            css={{
              lineHeight: '1.2',
              marginTop: '$24',
              marginBottom: '$8',
              textAlign: 'center',
              color: '$white',
            }}
          >
            {props.percentage} Completed
          </Text>
        </TextMobileWrapper>
        <TextWrapper>
          <Text
            size="16"
            weight="legendBold"
            css={{ lineHeight: '1.2', marginTop: '$24', marginBottom: '$8' }}
          >
            Hello
          </Text>
          <Text
            size="32"
            weight="legendBold"
            css={{ lineHeight: '1.2', marginBottom: '$12' }}
          >
            {props.fullName}
          </Text>
        </TextWrapper>
      </ProfileCardBox>
    </ProfileCardBuilderWrapper>
  );
};

export default ProfileCardBuilder;
