import { styled } from '@root/stitches.config';
import Image from '@components/atoms/image/Image';
import Layout from '@components/organisms/layout/Layout';
import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';
import Svg from '@components/atoms/svg/Svg';
import Button from '@components/atoms/button/Button';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';

export const LayoutStyled = styled(Layout, {
});

export const StyledThumbnail = styled(Thumbnail, {
  maxWidth: '232px',
  marginTop: '$16'
});

export const MainImage = styled(Image, {
  width: '130px !important',
  height: '130px !important',
  marginBottom: '$16',
  borderRadius: '$8',
});

export const CoverImage = styled(Image, {
  width: '100%',
  marginBottom: '$16',
});

export const PromoImage = styled(Image, {
  width: '100%',
  marginBottom: '$16',
});

export const DoDontText = styled(Text, {
  marginBottom: '$12',
  textTransform: 'uppercase',
  color: '$mainTextColor',
  fontFamily: '$ArtifaktLegendBold',
});

export const DoDontWrapper = styled('div', {
  marginBottom: '$40',

  '&:nth-of-type(2)': {
    marginBottom: '0',
  },
});

export const MainImageContainer = styled('div', {
  position: 'relative',
  width: '130px',
  height: '130px',
  marginBottom: '$16',

  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '$8',
  },
});

export const CoverImageContainer = styled('div', {
  position: 'relative',
  width: '100%',
  marginBottom: '$16',

  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '44%',
  },

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    objectFit: 'cover',
    objectPosition: 'center',
  },

  '@bp2':{
    '&:before':{
      paddingBottom: '21%',
    },
  
  },
});

export const EditorWrapper = styled('div', {
  textarea: {
    padding: '$16',
    border: '1px solid $lightGrey',
    borderRadius: '$8',
    width: '100%',
    outline: 'unset',
    overflow: 'hidden',
    resize: 'none',
    color: '$blackToWhite',
    backgroundColor: '$box',
    fontSize: '$14',
    fontFamily: '$ArtifaktElementRegular',
    height: 'auto'
  },
});

export const PromoImageContainer = styled('div', {
  position: 'relative',
  width: '232px',
  marginBottom: '$16',

  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '145%',
  },

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

export const WrapperPage = styled('div', {
  justifyContent: 'flex-start',
  margin: '0 auto',
  padding: '0 0 $64 0',
  display: 'flex',
  flexDirection: 'column',
  '@bp2': {
    flexDirection: 'row',
  },
  '@bp3': {
    padding: '0 $84 $84 0',
    maxWidth: '$container',
  },
});

export const WrapperSide = styled('div', {
  width: '100%',
  marginRight: '$32',
  flexShrink: '0',
  marginBottom: '$24',

  '@bp2': {
    marginBottom: '0',
    width: '232px',
  },
});


export const TitleWrapper = styled('div', {
 display: 'flex',
 alignItems: 'center',
 marginBottom: '$16',

 h6:{
   marginBottom: '0'
 }
});

export const BackLinkText = styled(Text, {
  lineHeight: '16px',
  fontFamily: '$ArtifaktElementBold',
  color: '$mainTextColor',
});

export const WrapperContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  flexShrink: '0',

  '@bp2': {
    width: '640px',
  },
});

export const WrapperInput = styled('div', {
  minWidth: '100%',
  margin: '0 0 32px 0',
  // display: 'flex',
  // flexDirection: 'column',

  input:{
    fontSize: '$14'
  },

  '@bp2': {
    maxWidth: '80%',
  },
});

export const InputLabel = styled(Heading, {
  margin: '0 0 $16 0',
  lineHeight: '1em',
  fontSize: '$18',
});

export const WrapperImageUploader = styled('div', {
  width: 'auto',
  minHeight: '0',
  borderRadius: '5px',

  '.upload__image-wrapper': {
    display: 'flex',
    marginTop: '$24',
    flexDirection: 'column',
  },
});

export const HeadingStyled = styled(Heading, {
  height: 'fit-content',
  margin: '0 0 24px 0',
  lineHeight: '30px',
  fontFamily: '$ArtifaktLegendBold',
});

export const ButtonSave = styled(Button, {
  width: 'fit-content',
});

export const AddBtn = styled('button', {
  display: 'flex',
  alignItems: 'center',
  color: '$mainTextColor',
  backgroundColor: 'unset',
  padding: 'unset',
  border: 'unset',
  borderWidth: '0',
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$14',
  opacity: '1',
  transition: 'opacity 0.1s ease-out',

  '&:hover': {
    cursor: 'pointer',
    opacity: '.5',
  },
});

export const AddBtnSvg = styled('span', {
  backgroundColor: '$mainTextColor',
  borderRadius: '100%',
  marginRight: '$16',
  width: '$40',
  height: '$40',
});

export const StyledAddBtnSvg = styled(Svg, {
  width: '$24',
  height: '$24',
  fill: '$white',
  marginTop: '$8',
});

export const CloseImgBtn = styled(Button, {
  position: 'absolute',
  top: '$8',
  right: '$8',
  width: '$32 !important',
  height: '$32 !important',
  minHeight: 'unset !important',
  minWidth: 'unset !important',
  svg: {
    fill: '$white',
    width: '20px !important',
    height: '20px !important'
  },
});

export const UploadBtn = styled(Button, {
  whiteSpace: 'nowrap',
  marginRight: '$16',
  width: '200px',
});

export const CheckMark = styled(Svg, {
  width: '$24',
  height: '$24',
  fill: '$green',
  position: 'relative',
  top: '13px'
});