import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const SelectTagsWrapper = styled('div', {
  width: '100%',
});

export const RequiredLabel = styled(Text, {
  color: '$darkGrey',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});

export const SelectTagsHeader = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const InputTextLabel = styled('label', {
  marginBottom: '$8',
  display: 'inline-block',
  fontSize: '$14',
  fontFamily: 'var(--ArtifaktElement)',
  display: 'flex',
  alignContent: 'center',
  marginRight: '$8',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});
export const InputTextErrorMsg = styled('div', {
  gridArea: 'error',
  fontSize: '$12',
  marginTop: '$4',
  minHeight: '$24',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});
