import React, { useContext, useState } from 'react';

import SocialAuthButton from './SocialAuthButton';
import { SocialAuthBoxWrapper } from './SocialAuthBox.styled';
import AuthContext from '@context/AuthContext';
import String from '@components/atoms/string/String';
import Button from '@components/atoms/button/Button';
import Separator from '@components/atoms/separator/Separator';
import Loader from '@components/atoms/loader/Loader';

const SocialAuthBox = props => {
  const authContext = useContext(AuthContext);

  const [logInFormState, setLogInFormState] = useState({
    isLoading: false,
    isAuthError: false,
    isErrorMsg: '',
  });

  const handleFailure = message => {
    setLogInFormState({
      ...logInFormState,
      isLoading: false,
      isAuthError: true,
      isErrorMsg: message,
    });
  };

  const handleAuthSuccess = message => {
    setLogInFormState({
      ...logInFormState,
      isLoading: false,
      isAuthError: false,
    });
  };

  const handleSuccess = data => {
    const loginData = {
      token: data.token,
      successCallback: handleAuthSuccess,
    };

    authContext.socialAuth(loginData);
  };

  const handleOnClick = () => {
    setLogInFormState({
      ...logInFormState,
      isLoading: true,
    });
  };

  const linkedinRedirectUri = `${API_URL}/login-external/linkedin`;
  const googleRedirectUri = `${API_URL}/login-external/google`;
  const appleRedirectUri = `${API_URL}/login-external/apple`;

  const linkedInClientId = '7729hczzu0bt66';
  const googleClientId =
    '67450846701-o6jhauclqfvnsefhp37cu8c9qt24vcet.apps.googleusercontent.com';

  const getLabel = () => {
    switch (props.type) {
      case 'signup':
        return {
          google: 'sign_up_google_button_label',
          apple: 'sign_up_apple_button_label',
          linkedin: 'sign_up_linkedin_button_label',
        };

      case 'signin':
        return {
          google: 'sign_in_google_button_label',
          apple: 'sign_in_apple_button_label',
          linkedin: 'sign_in_linkedin_button_label',
        };

      default:
        break;
    }
  };

  return (
    <>
      <SocialAuthBoxWrapper>
        {props.type == 'signup' && (
          <>
            <Button
              type="normal"
              label={'Join with Email'}
              colorTheme={'gray'}
              svgIcon={'mail'}
              actionType="internalLink"
              internalUrl="/sign-up"
              css={{ marginBottom: '$24', width: '100%' }}
            />
            <Separator label="OR" css={{ marginBottom: '$24' }} />
          </>
        )}

        <SocialAuthButton
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          redirectUri={googleRedirectUri}
          label={<String id={`${getLabel().google}`} />}
          provider="google"
          clientId={googleClientId}
          scope=""
          onClick={handleOnClick}
        />
        <SocialAuthButton
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          redirectUri={appleRedirectUri}
          label={<String id={`${getLabel().apple}`} />}
          provider="apple"
          scope=""
          onClick={handleOnClick}
        />
        {/* <SocialAuthButton
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          redirectUri={linkedinRedirectUri}
          label={<String id={`${getLabel().linkedin}`} />}
          provider="linkedin"
          clientId={linkedInClientId}
          scope="r_liteprofile r_emailaddress"
        /> */}
      </SocialAuthBoxWrapper>
    </>
  );
};

export default SocialAuthBox;
