import { styled } from '@root/stitches.config';
import Svg from '@components/atoms/svg/Svg';

export const MobileNavWrapper = styled('div', {
  position: 'fixed',
  top: '54px',
  right: '0',
  width: '91%',
  backgroundColor: '$box',
  height: 'calc(100vh - 54px)',
  padding: '$32 $24 $92',
  display: 'block',
  overflowY: 'scroll',
  transform: 'translateX(100%)',
  transition: 'transform 0.25s ease-in-out',
  zIndex: '$1',
  backgroundColor: '$blackToWhite',
  ul: {
    a: {
      color: '$whiteToBlack',

      svg: {
        fill: '$whiteToBlack',
      },
    },
  },

  div: {
    color: '$whiteToBlack',
  },

  '@bp2': {
    display: 'none',
  },

  variants: {
    isOpened: {
      true: {
        transform: 'translateX(0%)',
      },
      false: {
        transform: 'translateX(100%)',
      },
    },
  },
});

export const MobileNavMainNavWrapper = styled('div', {
  ul: {
    li: {
      marginBottom: '$24',
      display: 'block',
    },
  },

  a: {
    textDecoration: 'none',
    color: '$mainTextColor',
    fontFamily: '$ArtifaktLegendExtraBold',

    fontSize: '$32',
    lineHeight: '36px',
  },
});

export const MobileNavSecondNavWrapper = styled('div', {
  marginBottom: '$24',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '260px',
  minWidth: '200px',

  ul: {
    li: {
      marginBottom: '$16',
      display: 'block',

      '&:last-of-type': {
        marginBottom: '0',
      },
    },
  },

  a: {
    textDecoration: 'none',
    color: 'unset',
    fontFamily: '$ArtifaktLegendBold',
    fontSize: '$16',
    lineHeight: '$1',
    position: 'relative',

    svg: {
      position: 'absolute',
      top: '0',
      right: '-$24',
    },
  },
});

export const MobileNavLoginWrapper = styled('div', {
  marginBottom: '$24',
  display: 'flex',
});

export const MobileNavContainer = styled('div', {
  position: 'relative',
  zIndex: '$1',
});

export const MobileNavBottomButton = styled('div', {
  margin: '$16 0 $8',
});

export const MobileNavGoogleBtn = styled('div', {
  a: {
    span: {
      order: '2',
    },
    svg: {
      order: '1',
      marginRight: '$8',
    },
  },
});

export const MobileNavBlackBG = styled('div', {
  width: '100vw',
  height: 'calc(100vh - 54px)',
  backgroundColor: 'rgba(0,0,0,.9)',
  position: 'fixed',
  opacity: '0',
  top: '54px',
  left: '0',
  pointerEvents: 'none',
  transition: 'opacity .25s ease-in-out',
  zIndex: '0',

  variants: {
    isOpened: {
      true: {
        opacity: '.5',
      },
      false: {
        opacity: '0',
      },
    },
  },

  '@bp2': {
    display: 'none',
  },
});

export const StyledAppsLinks = styled('a', {
  color: '$black',
  display: 'grid',
  gridTemplateColumns: '$24 1fr',
  gridGap: '$8',
  padding: '$12 $24',
  backgroundColor: '$blackToWhite',
  borderRadius: '$12',
  textDecoration: 'none',
  fontSize: '$14',
  ai: 'center',
  marginBottom: '$16',
  border: '1px solid $lightGrey',
  transition: 'all .5s ease',

  '&:hover': {
    backgroundColor: '$darkGrey',
    transition: 'all .5s ease',
  },
});

export const SvgStyled = styled(Svg, {
  margin: '0 $16 0 0',
  fill: '$whiteToBlack',
  height: '$24',
  width: '$24',
});
