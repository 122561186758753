import {
  AvatarContainer,
  AvatarWrapper,
  CommunityAddPostWrapper,
  ErrorWrapper,
  OverallWrapper,
  Placeholder,
  StyledBtn,
  UploadWrapper,
} from './CommunityAddPost.styled';
import React, { useContext } from 'react';

import AuthContext from '@context/AuthContext';
import Avatar from '@components/atoms/avatar/Avatar';
import Button from '@components/atoms/button/Button';
import CommunityAddPostModal from '@components/molecules/communityAddPost/communityAddPostModal/CommunityAddPostModal';
import ImageUploading from 'react-images-uploading';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import useModal from '@components/atoms/modal/useModal';

const CommunityAddPost = props => {
  const authContext = useContext(AuthContext);
  const stringsContext = useContext(StringsContext);
  let placeholderText = '';
  const { isShowing, toggle } = useModal();
  const [images, setImages] = React.useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    !isShowing ? toggle() : null;
  };

  if (props.replyingTo) {
    placeholderText = props.replyingTo;
  } else if (props.isGroupFeed) {
    placeholderText = stringsContext['write_amazing_post_placeholder']?.field;
  } else {
    placeholderText = stringsContext['write_comment_placeholder']?.field;
  }

  const onFocusClick = e => {
    if (!props.isReported) {
      e.target.blur();
      toggle();
    }
  };

  const onJoinClick = () => {
    toggle();
    props.onJoinGroup(props.groupId);
  };

  const onClose = () => {
    toggle();
  };

  return (
    <OverallWrapper
      isAtTop={props.isAtTop}
      id={`add__comment__reply-${props.parentId}`}
    >
      <CommunityAddPostWrapper
        isAtBottom={props.isAtBottom}
        isAtTop={props.isAtTop}
      >
        {!props.isMobile && (
          <AvatarWrapper>
            <AvatarContainer>
              {props.isLogged && (
                <Avatar
                  {...(props?.modalProps?.avatarProps || props?.avatarProps)}
                />
              )}
            </AvatarContainer>
          </AvatarWrapper>
        )}
        <Placeholder isMobile={props.isMobile} onClick={onFocusClick}>{placeholderText}</Placeholder>
      </CommunityAddPostWrapper>

      {authContext.isAuthenticated &&
      props.isVerified &&
      props.isOnboarded &&
      props.isCurrentUserGroupMember &&
      props.groupRole !== 'blocked' ? (
        <ImageUploading
          multiple
          value={images}
          maxNumber={props.isComment ? 1 : 10}
          onChange={onChange}
          dataURLKey="data_url"
          acceptType={['jpg', 'jpeg', 'gif', 'png', 'svg']}
        >
          {({
            imageList,
            onImageUpload,
            errors,
            onImageRemoveAll,
            onImageRemove,
          }) => (
            <UploadWrapper
              isAtBottom={props.isAtBottom}
              isAtTop={props.isAtTop}
            >
              <StyledBtn
                type="circle"
                colorTheme="gray"
                actionType="onclick"
                svgIcon="image-inverted-no-fill"
                onClick={onImageUpload}
              />
              {errors && (
                <ErrorWrapper>
                  {errors.maxNumber && (
                    <span>
                      Number of selected images exceed{' '}
                      {props.isComment ? 1 : 10}
                    </span>
                  )}
                  {errors.acceptType && (
                    <span>
                      <String id="community_post_bad_image_type" />
                    </span>
                  )}
                </ErrorWrapper>
              )}
              <Modal
                isShowing={isShowing}
                hide={() => {
                  toggle();
                  onImageRemoveAll();
                }}
              >
                <CommunityAddPostModal
                  {...props.modalProps}
                  avatarProps={props.avatarProps}
                  content={props.content}
                  refetch={props.refetch}
                  replyingTo={props.replyingTo}
                  artistProfilUrl={props.artistProfilUrl}
                  hide={() => {
                    toggle();
                    onImageRemoveAll();
                  }}
                  imagesProp={images}
                  onRemoveAll={onImageRemoveAll}
                  onImageRemove={onImageRemove}
                  groupName={props.groupName}
                  groupId={props.groupId}
                  parentId={props.parentId}
                  isComment={props.isComment}
                  onSaveClick={props.onSaveClick}
                  isReply={props.isReply}
                />
              </Modal>
            </UploadWrapper>
          )}
        </ImageUploading>
      ) : null}

      {!props.isCurrentUserGroupMember && (
        <UploadWrapper isAtBottom={props.isAtBottom} isAtTop={props.isAtTop}>
          <Button
            type="circle"
            colorTheme="gray"
            actionType="onclick"
            svgIcon="image-inverted-no-fill"
            css={{
              width: '44px',
              height: '44px',
              minHeight: 'unset',
              minWidth: 'unset',
              margin: '0',
            }}
            onClick={toggle}
          />
        </UploadWrapper>
      )}
      {(!props.isVerified || !props.isLogged || !props.isOnboarded || !props.isCurrentUserGroupMember) && (
        <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
          {(!props.isVerified && props.isLogged) && (
            <PublishNotification type="validation" onClose={onClose} />
          )}
          {(!props.isOnboarded && props.isLogged) && (
            <PublishNotification
              type="onboarding"
              url={'/m/' + authContext.userSlug}
              myGroupsId={!props.isLogged ? '' : props.myGroupsId}
              groupId={!props.isLogged ? '' : props.groupId}
              onClose={onClose}
            />
          )}
          {!props.isLogged && (
            <PublishNotification
              type="join"
              groupName=""
              onJoinClick={onJoinClick}
              myGroupsId=""
              groupId=""
              onClose={onClose}
            />
          )}
          {(props.isLogged && props.isOnboarded && !props.isCurrentUserGroupMember) && (
            <PublishNotification
              type="joinGroup"
              groupName={props.groupName}
              onJoinClick={onJoinClick}
              myGroupsId={props.myGroupsId}
              groupId={props.groupId}
              onClose={onClose}
            />
          )}
        </Modal>
      )}
      {props.isCurrentUserGroupMember &&
        (props.groupRole === 'blocked' || props.isBlocked) && (
          <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
            <PublishNotification type={'blocked'} />
          </Modal>
        )}
    </OverallWrapper>
  );
};

CommunityAddPost.propTypes = {
  avatarProps: PropTypes.object,
  modalProps: PropTypes.object,
  replyingTo: PropTypes.string,
};

export default CommunityAddPost;
