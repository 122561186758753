import React from 'react';
import PropTypes from 'prop-types';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Image from '@components/atoms/image/Image';
import { ImageWrapper, GalleryWrapper, HasMoreWrapper } from './LayoutImagesPost.styled';
import { Text } from '@components/atoms/text/Text';

function LayoutImagesPost(props){

  const {
    images,
    maxImages
  } = props

  return (
    <Gallery shareButton={false} fullscreenButton={false}>
      <GalleryWrapper>
        {images.map((image, i) => {
          return (
            <Item
              key={image.src + image.id + i}
              original={image.src}
              width={image.width}
              height={image.height}
            >
              {({ref, open}) => (
                <ImageWrapper 
                  ref={ref}
                  isFirst={i === 0}
                  isSingle={images.length === 1}
                  hasTwoImages={images.length === 2 || maxImages === 2}
                  isMax={i === (maxImages - 1)}
                  hasMore={i === (maxImages - 1) && maxImages < images.length}
                  isAboveMax={i > (maxImages - 1)}
                >
                  <Image src={image} alt={image.alt} onClick={open} />
                  {(i === (maxImages - 1) && maxImages < images.length) && (
                    <HasMoreWrapper>
                      <Text
                        as="p"
                        size="24"
                        css={{zIndex: 2, fontFamily: '$ArtifaktLegend'}}
                      >
                        {`+${images.length - maxImages}`}
                      </Text>
                    </HasMoreWrapper>
                  )}
                </ImageWrapper>
              )}
            </Item>
          )
        })}
      </GalleryWrapper>
    </Gallery>
  )
}

LayoutImagesPost.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  })),
  maxImages: PropTypes.number,
}

LayoutImagesPost.defaultProps = {
  maxImages: 2
}

export default LayoutImagesPost;