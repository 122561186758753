import {
  MobileNavBlackBG,
  MobileNavBottomButton,
  MobileNavContainer,
  MobileNavGoogleBtn,
  MobileNavLoginWrapper,
  MobileNavMainNavWrapper,
  MobileNavSecondNavWrapper,
  MobileNavWrapper,
  StyledAppsLinks,
  SvgStyled,
} from './MobileNav.styled';

import React, { useContext, useState } from 'react';

import Button from '@components/atoms/button/Button';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import GooglePlay from '@src/assets/svg/google_play.svg';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import { useTransitionMount } from '@src/utils/useTransitionMount';

const getInternalLink = url => url.replace(CMS_URL, '');

export const MobileNav = props => {
  const {
    mainNavItems,
    secondNavItems,
    isLogged,
    mobileNavOpened,
    auth,
    onMenuClick,
  } = props;
  const UrlContext = useContext(UrlsContext);
  const stringsContext = useContext(StringsContext);
  const googleId = 'mobile_nav_google_store_link';
  const appleId = 'mobile_nav_apple_store_link';

  const handleLogout = () => {
    auth.logout();
  };

  const hasTransitioned = useTransitionMount(props.mobileNavOpened, 1000);

  if (hasTransitioned || props.mobileNavOpened) {
    return (
      <MobileNavContainer>
        <MobileNavWrapper isOpened={props.mobileNavOpened && hasTransitioned}>
          <MobileNavMainNavWrapper>
            <ul>
              {mainNavItems.map((navItem, i) => {
                return (
                  <li key={i}>
                    <span>
                      {navItem.url.includes(CMS_URL) ? (
                        <Link
                          alt={navItem.title}
                          to={getInternalLink(navItem.url)}
                          onClick={onMenuClick}
                        >
                          {navItem.title}
                        </Link>
                      ) : (
                        <a
                          alt={navItem.title}
                          href={getInternalLink(navItem.url)}
                          onClick={onMenuClick}
                        >
                          {navItem.title}
                        </a>
                      )}
                    </span>
                  </li>
                );
              })}
            </ul>
          </MobileNavMainNavWrapper>
          {!props.isLogged ? (
            <MobileNavLoginWrapper onClick={onMenuClick}>
              <Button
                type="normal"
                colorTheme="normalReverse"
                label="Create an account"
                actionType="internalLink"
                internalUrl={GetUrl('join', UrlContext)}
                css={{ marginRight: '$12', padding: '$16' }}
              />
              <Button
                type="normal"
                colorTheme="gray"
                label="Sign in"
                actionType="internalLink"
                internalUrl={GetUrl('login', UrlContext)}
              />
            </MobileNavLoginWrapper>
          ) : (
            <Button
              type="normal"
              colorTheme="gray"
              actionType="onclick"
              label="Log out"
              onClick={handleLogout}
              css={{ marginBottom: '$32' }}
            />
          )}
          <MobileNavSecondNavWrapper>
            {/* INTERNAL LINKS */}
            <div>
              <ul>
                {secondNavItems.map((secondNavItem, i) => {
                  if (secondNavItem.newWindow == 0) {
                    return (
                      <li key={i}>
                        <span>
                          {secondNavItem.url.includes(CMS_URL) ? (
                            <Link
                              alt={secondNavItem.title}
                              to={getInternalLink(secondNavItem.url)}
                              onClick={onMenuClick}
                            >
                              {secondNavItem.title}
                            </Link>
                          ) : (
                            <a
                              alt={secondNavItem.title}
                              href={getInternalLink(secondNavItem.url)}
                              onClick={onMenuClick}
                            >
                              {secondNavItem.title}
                            </a>
                          )}
                        </span>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            {/* EXTERNAL LINKS */}
            <div>
              <ul>
                {secondNavItems.map((secondNavItem, i) => {
                  if (secondNavItem.newWindow == 1) {
                    return (
                      <li key={i}>
                        <span>
                          {secondNavItem.url.includes(CMS_URL) ? (
                            <Link
                              alt={secondNavItem.title}
                              to={getInternalLink(secondNavItem.url)}
                              target="_blank"
                            >
                              {secondNavItem.title}
                              <Svg
                                icon="arrow-diag"
                                css={{
                                  width: '$24',
                                  height: '$24',
                                  fill: 'rgba(0,0,0,.9)',
                                }}
                              />
                            </Link>
                          ) : (
                            <a
                              alt={secondNavItem.title}
                              href={getInternalLink(secondNavItem.url)}
                              target="_blank"
                            >
                              {secondNavItem.title}
                              <Svg
                                icon="arrow-diag"
                                css={{
                                  width: '$24',
                                  height: '$24',
                                  fill: 'rgba(0,0,0,.9)',
                                }}
                              />
                            </a>
                          )}
                        </span>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </MobileNavSecondNavWrapper>
        </MobileNavWrapper>
        <MobileNavBlackBG isOpened={props.mobileNavOpened}></MobileNavBlackBG>
      </MobileNavContainer>
    );
  }
  return null;
};

MobileNav.propTypes = {
  mainNavItems: PropTypes.array.isRequired,
  secondNavItems: PropTypes.array.isRequired,
  isLogged: PropTypes.bool,
  mobileNavOpened: PropTypes.bool,
  onMenuClick: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
