import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Subscribe,
  SubscribeDescription,
  SubscribeIframe,
  SubscribeThankYou,
  WrapperPage,
} from './Newsletter.styled';
import useDarkMode from '@src/utils/use-dark-mode';
import Layout from '@components/organisms/layout/Layout';
import { Text } from '@components/atoms/text/Text';
import Svg from '@components/atoms/svg/Svg';
import String from '@components/atoms/string/String';
// import Loader from '@components/atoms/loader/Loader';

const Support = context => {
  const [darkMode, setDarkMode] = useDarkMode();
  const marketoDiv = useRef(null);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    wp_mktoForm();
  }, [darkMode]);

  function wp_mktoForm() {
    marketoAEM.methods.init({
      targetNode: '#marketoDiv',
      formLang: 'en-us',
      activityId: '3547528',
      activityName: 'Newsletter - Non Lead generating form',
      formId: '4223',
      industryGroup: 'M&E',
      landingPageId: '11564',
      communicationChannel: 'CONTACT',
      leadType: '',
      productInterests: '3DSMAX',
      fieldList: 'FirstName,LastName,Email,privacycb,Unsubscribed',
      PN_programFilters: ['ADSK_NEWSLETTER_AREA'],
      impliedConsent: true,
      // mktvar001: marketoAEM.getQstring().mktvar001
      //   ? marketoAEM.getQstring().mktvar001
      //   : marketoAEM.getCookie('mktvar001')
      //   ? marketoAEM.getCookie('mktvar001')
      //   : '',
      // mktvar002: marketoAEM.getQstring().mktvar002
      //   ? marketoAEM.getQstring().mktvar002
      //   : marketoAEM.getCookie('mktvar002')
      //   ? marketoAEM.getCookie('mktvar002')
      //   : '',
      // mktvar004: marketoAEM.getQstring().mktvar004
      //   ? marketoAEM.getQstring().mktvar004
      //   : marketoAEM.getCookie('mktvar004')
      //   ? marketoAEM.getCookie('mktvar004')
      //   : '',
      // resellerId: marketoAEM.getQstring().rid
      //   ? marketoAEM.getQstring().rid
      //   : marketoAEM.getCookie('rid')
      //   ? marketoAEM.getCookie('rid')
      //   : '',
      onSuccess: () => {
        setShowThankYou(true);
      },
    });
  }

  return (
    <Layout>
      <WrapperPage className="container">
        <Subscribe>
          <Text as="h1" weight="legendExtraBold" css={{ fontSize: '$68' }}>
            <String id="newsletter_page_title" />
          </Text>
          <SubscribeDescription>
            <String id="newsletter_page_description" />
          </SubscribeDescription>
          {showThankYou ? (
            <SubscribeThankYou id="thankYouMessage">
              <div className="content">
                <Svg icon="check" />
                <Text as="p">
                  <String id="footer_newsletter_thank_you" />
                </Text>
              </div>
            </SubscribeThankYou>
          ) : (
            <SubscribeIframe
              ref={marketoDiv}
              id="marketoDiv"
              className={`${darkMode ? 'dark-mode' : 'light-mode'}`}
            />
          )}
        </Subscribe>
      </WrapperPage>
    </Layout>
  );
};

Support.defaultProps = {
  feedbackSubject: 'Website Feedback',
  defaultUserName: 'Anonymous user',
};

Support.propTypes = {
  subdomain: PropTypes.string,
  feedbackSubject: PropTypes.string,
  defaultUserName: PropTypes.string,
  currentPageURL: PropTypes.string,
};

export default Support;
