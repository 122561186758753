export default function formatFileSize(inputSize) {
  return !isNaN(inputSize)
    ? inputSize > 1000000000
      ? `${(inputSize / 1000000000).toFixed(2)} GB`
      : inputSize > 1000000
      ? `${(inputSize / 1000000).toFixed(2)} MB`
      : inputSize > 1000
      ? `${(inputSize / 1000).toFixed(2)} KB`
      : ''
    : '';
}
