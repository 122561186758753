import { styled } from '@root/stitches.config';

export const BigStatBlockWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const Percentage = styled('div', {
  position: 'absolute',
  right: '-10px',
  top: '-15px',
  fontSize: '$16',
  lineHeight: '1.1em',
});
