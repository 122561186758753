import React from 'react';
import { BlockTextWrapper } from './BlockText.styled';
require('medium-editor/dist/css/medium-editor.css');
require('../../../styles/content_editor.css');
const BlockText = props => {
  return (
    <BlockTextWrapper>
      <div dangerouslySetInnerHTML={{ __html: props.block.text }}></div>
    </BlockTextWrapper>
  );
};
export default BlockText;
