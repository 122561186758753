import {
  FilterBarWrapper,
  FiltersWrapper,
  MobileWrapper,
  MobileWrapperHeader,
  StyledButton,
} from './FilterBar.styled';

import React, { useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import DropDown from '@components/molecules/dropDown/DropDown';
import { Heading } from '@components/atoms/heading/Heading';
import PropTypes from 'prop-types';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import { useIsMobile } from '@src/utils/useIsMobile';
import String from '@components/atoms/string/String';


const FiltersContent = ({
  singleFilters,
  selectedFilters,
  setSelectedFilters,
  dropdownFilters,
  checkBoxFilters,
  isMobile,
  isMultiSelect,
}) => {
  return (
    <>
      <FiltersWrapper>
        {singleFilters.length >= 1 &&
          singleFilters.map(filter => {
            return (
              <Button
                key={`${filter.filterKey}_${filter.title}`}
                actionType="onclick"
                label={<String id={filter.title}/>}
                value={JSON.stringify(filter)}
                onClick={e => {
                  if (
                    selectedFilters.filter(el => el?.type === 'single').length >
                    0
                  ) {
                    setSelectedFilters([
                      ...selectedFilters.filter(el => el?.type !== 'single'),
                      JSON.parse(e.currentTarget.value),
                    ]);
                  } else {
                    setSelectedFilters([
                      ...selectedFilters,
                      JSON.parse(e.currentTarget.value),
                    ]);
                  }
                }}
                colorTheme={`${
                  selectedFilters.find(
                    el => el?.filterKey === filter.filterKey
                  ) !== undefined
                    ? 'normal'
                    : 'transparent'
                }`}
              />
            );
          })}
      </FiltersWrapper>
      <FiltersWrapper>
        {checkBoxFilters?.length >= 1 && 
          checkBoxFilters.map((filter) => {
            return (
              <InputCheckBox 
                checked={filter.checked}
                key={filter.filterKey}
                onChange={(e) => {
                  if (
                    selectedFilters.find(el => el.filterKey === filter.filterKey) !== undefined
                  ) {
                    setSelectedFilters([
                      ...selectedFilters.filter(el => el.filterKey !== filter.filterKey),
                      { ...filter, checked: e.target.checked },
                    ]);
                  } else {
                    setSelectedFilters([
                      ...selectedFilters,
                      { ...filter, checked: e.target.checked },
                    ]);
                  }
                }}
                css={{mr: '$16'}}
                value={filter.checked}
                labelClassName="checkbox-filter-label"
                name={filter.title}
              >{<String id={filter.title} />}</InputCheckBox>
            )
          })
        }
        {dropdownFilters.length >= 1 &&
          dropdownFilters.map((filter, i) => {
            const filterInState = selectedFilters.find(
              el => el?.id === filter.filterKey
            );
            return (
              <DropDown
                key={`${filter.filterKey}_${filter.title}`}
                title={filter.title}
                options={filter.options}
                colorTheme={
                  filterInState?.selectedValues?.length >= 1 ? 'normal' : 'gray'
                }
                id={filter.filterKey}
                onChange={e => {
                  const value = JSON.parse(e);

                  if (
                    selectedFilters.find(el => el.id === value.id) !== undefined
                  ) {
                    setSelectedFilters([
                      ...selectedFilters.filter(el => el?.id !== value.id),
                      { ...value, type: 'dropdown' },
                    ]);
                  } else {
                    setSelectedFilters([
                      ...selectedFilters,
                      { ...value, type: 'dropdown' },
                    ]);
                  }
                }}
                defaultSelected={
                  filterInState ? filterInState.selectedValues : []
                }
                isMultiSelect={isMultiSelect}
                alignOptions="right"
                css={{
                  marginLeft: `${!isMobile && i >= 1 ? '$16' : '0'}`,
                  marginTop: `${isMobile ? '$8' : '0'}`,
                }}
              />
            );
          })}
      </FiltersWrapper>
    </>
  );
};

FiltersContent.propTypes = {
  singleFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  dropdownFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const FilterBar = props => {
  const { filters, onChange, defaultFilters, isMultiSelect } = props;

  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);

  useEffect(() => {
    onChange(JSON.stringify(selectedFilters));
  }, [selectedFilters]);

  const singleFilters = filters.filter(f => f.type === 'single');
  const dropdownFilters = filters.filter(f => f.type === 'dropdown');
  const checkBoxFilters = filters.filter(f => f.type === 'checkbox');

  const isMobile = useIsMobile();
  const [showFilters, setShowFilters] = useState(false);

  return (
    <FilterBarWrapper>
      {isMobile && (
        <StyledButton
          label="Filters"
          actionType="onclick"
          colorTheme="gray"
          svgIcon="filters"
          svgLocation="after"
          onClick={() => setShowFilters(!showFilters)}
        />
      )}
      {!isMobile && (
        <FiltersContent
          singleFilters={singleFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          dropdownFilters={dropdownFilters}
          checkBoxFilters={checkBoxFilters}
          isMobile={isMobile}
          isMultiSelect={isMultiSelect}
        />
      )}
      {isMobile && showFilters && (
        <MobileWrapper>
          <MobileWrapperHeader>
            <Button
              type="circle"
              svgIcon="close-big"
              actionType="onclick"
              colorTheme="gray"
              onClick={() => setShowFilters(false)}
            />
            <Heading
              level="h4"
              css={{
                marginBottom: '$24',
                alignSelf: 'flex-start',
                color: '$mainTextColor',
              }}
            >
              Filters
            </Heading>
          </MobileWrapperHeader>
          <FiltersContent
            singleFilters={singleFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dropdownFilters={dropdownFilters}
            isMobile={isMobile}
            isMultiSelect={isMultiSelect}
          />
        </MobileWrapper>
      )}
    </FilterBarWrapper>
  );
};

export default FilterBar;

FilterBar.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['single', 'dropdown', 'checkbox']).isRequired,
      title: PropTypes.string.isRequired,
      filterKey: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      ),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['single', 'dropdown', 'checkbox']),
      title: PropTypes.string,
      filterKey: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      ),
    })
  ),
};

FilterBar.defaultProps = {
  defaultFilters: [],
  isMultiSelect: true,
};
