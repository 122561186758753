import { DELETE_USERCONTENT, UPDATE_USERCONTENT } from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { ArtworkStyled } from './ProfileSection.styled';
import Button from '@components/atoms/button/Button';
import CreateProfileBanner from '@components/molecules/createProfileBanner/CreateProfileBanner';
import DeleteModal from '@components/molecules/deleteModal/DeleteModal';
import { GET_PROFILE_WORK } from '@src/apollo/query';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import Pagination from '@components/atoms/pagination/Pagination';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import ThumbnailListing from '@components/molecules/thumbnailListing/ThumbnailListing';
import { ThumbnailsListing } from '../Profile.styled';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { navigate } from 'gatsby';
import { useIsMobile } from '@src/utils/useIsMobile';

function actionsPerType(type, element, isSuperMember, isInspireArticle){
      let options = [];
      const parsedType = type[0];
      switch(parsedType){
        case 'artwork':
          options = [
            {
              label: 'thumbnail_menu_edit',
              onActionClick: () => {
                if (element.hasDraft) {
                  navigate(
                    `/m/${element.creator.username}/${type === 'tutorial_series' ? type : type + 's'}/${element.slug}-draft/draft/edit`
                  );
                } else {
                  navigate(
                    `/m/${element.creator.username}/${type === 'tutorial_series' ? type : type + 's'}/${element.slug}/${
                      element.status && element.status !== 'publish'
                        ? `${element.status}/`
                        : ''
                    }edit`
                  );
                }
              },
            },
          ]
        break;
        case 'asset':
          options = [
          ...(isSuperMember
            ? [
                {
                  label: 'thumbnail_menu_edit',
                  onActionClick: () => {
                    if (element.hasDraft) {
                      navigate(
                        `/m/${element.creator.username}/assets/${element.slug}-draft/draft/edit`
                      );
                    } else {
                      navigate(
                        `/m/${element.creator.username}/assets/${
                          element.slug
                        }/${
                          element.status && element.status !== 'publish'
                            ? `${element.status}/`
                            : ''
                        }edit`
                      );
                    }
                  },
                },
              ]
            : []),
        ];
        break;
        case 'tutorial': 
          options = [
          ...(element.status !== 'pending'
            ? [
                {
                  label: 'thumbnail_menu_edit',
                  onActionClick: () => {
                    if (element.hasDraft) {
                      navigate(
                        `/m/${element.creator.username}/${
                          element.userContentType === 'tutorial'
                            ? 'tutorials'
                            : element.userContentType
                        }/${element.slug}-draft/draft/edit`
                      );
                    } else {
                      navigate(
                        `/m/${element.creator.username}/${
                          element.userContentType === 'tutorial'
                            ? 'tutorials'
                            : element.userContentType
                        }/${element.slug}/${
                          element.status && element.status !== 'publish'
                            ? `${element.status}/`
                            : ''
                        }edit`
                      );
                    }
                  },
                },
              ]
            : []),
        ];
        break;
        case 'article':
          options = [
          {
            label: 'thumbnail_menu_edit',
            onActionClick: () => {
              if (!isInspireArticle) {
                if (element.hasDraft) {
                  navigate(
                    `/m/${element.creator.username}/articles/${element.slug}-draft/draft/edit`
                  );
                } else {
                  navigate(
                    `/m/${element.creator.username}/articles/${
                      element.slug
                    }/${
                      element.status && element.status !== 'publish'
                        ? `${element.status}/`
                        : ''
                    }edit`
                  );
                }
              } else {
                if (element.hasDraft) {
                  navigate(
                    `/inspire/${type}/${element.slug}-draft/draft/edit`
                  );
                } else {
                  navigate(
                    `/inspire/${type}/${element.slug}/${
                      element.status && element.status !== 'publish'
                        ? `${element.status}/`
                        : ''
                    }edit`
                  );
                }
              }
            },
          },
        ]
        break;
        default:
          options = []
      }

      return options;
}

const ProfileSection = ({
  type,
  userId,
  isCurrentUserProfile,
  isAuthenticated,
  onCreateButtonClick,
  isSuperMember,
}) => {
  const isMobile = useIsMobile();

  const [content, setContent] = useState({
    isLoaded: false,
    contentList: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdTodelete] = useState();

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_PROFILE_WORK, {
    variables: {
      id: userId,
      limit: isMobile ? 4 : 9,
      content_type: type,
      page: 1,
    },
    fetchPolicy: 'cache-first',
    context: { clientName: 'public' },
  });

  const [featureContent, dataFeatureContent] = useMutation(UPDATE_USERCONTENT);
  const [deleteContent, dataDeleteContent] = useMutation(DELETE_USERCONTENT, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    const { userContents } = data || [];

    if(data){
      const content = userContents?.data.map(element => {
        const isInspireArticle = element.article_section?.name === 'Inspire';

        return dataToThumbnailViewModel(
          element,
          true,
          isAuthenticated,
          (isCurrentUserProfile || isSuperMember) ? [
            ...actionsPerType(type, element, isSuperMember, isInspireArticle),
            ...element.status !== "pending" ? [{
              label: 'thumbnail_menu_feature',
              onActionClick: () =>
                featureContent({
                  variables: {
                    id: element.id,
                    input: { feature: !element.feature },
                  },
                }),
            }]: [],
            {
              label: 'thumbnail_menu_delete',
              onActionClick: () => {
                setShowDeleteModal(true);
                setIdTodelete(element.id);
              },
            },
          ] : [],
        );
      });

      const sortedContentList = content.slice().sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

      setContent({
        isLoaded: true,
        contentList: sortedContentList,
      });
    }
  }, [data]);

  async function onDelete() {
    const newArtworkList = content.contentList
      .slice()
      .filter(art => art.id !== idToDelete);
    setContent({
      ...content,
      contentList: newArtworkList,
    });
    deleteContent({
      variables: {
        ids: [idToDelete],
      },
    });
    if (data.userContents.data.length === 1) {
      setCurrentPage(currentPage - 1);
    }
    setShowDeleteModal(false);
    setIdTodelete();
  }

  const showPager = data?.userContents.total > data?.userContents.per_page;

  useEffect(() => {
    refetch({ page: currentPage });
  }, [currentPage]);

  return (
    <ArtworkStyled>
      <DeleteModal
        hide={() => setShowDeleteModal(false)}
        isShowing={showDeleteModal}
        onConfirmClick={onDelete}
      />
      {isCurrentUserProfile && content.isLoaded && (
        <CreateProfileBanner
          type={type.includes('tutorial') ? 'tutorial' : type[0]}
          isEmptySection={content.isLoaded && content?.contentList?.length === 0}
          onCreateButtonClick={onCreateButtonClick}
        />
      )}
      {content.isLoaded ? (
        <ThumbnailListing
          isCurrentUserProfile={isCurrentUserProfile}
          thumbnailList={content.contentList}
          isLoading={
            dataDeleteContent?.loading || dataFeatureContent?.loading || loading
          }
        />
      ) : (
        <ThumbnailsListing>
          <LoaderSkeleton height="420px" width="100%" isRadius />
          <LoaderSkeleton height="420px" width="100%" isRadius />
          <LoaderSkeleton height="420px" width="100%" isRadius />
        </ThumbnailsListing>
      )}
      {showPager && !isMobile && (
        <Pagination
          onCurrentPageChange={setCurrentPage}
          currentPage={currentPage}
          pages={data?.userContents?.last_page}
        />
      )}
      {(isMobile && content.contentList.length < data?.userContents?.total && showPager) && (
        <Button
          label={<String id="profile_load_more_button" />}
          actionType="onclick"
          onClick={() => fetchMore({
            variables: {
              page: currentPage + 1,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              const previousPosts = prev.userContents.data;
              const fetchMorePosts = fetchMoreResult.userContents.data;
              fetchMoreResult.userContents.data = [...previousPosts, ...fetchMorePosts];
              fetchMoreResult.mobileFetch = true;

              return fetchMoreResult;
            },
          })}
        />
      )}
    </ArtworkStyled>
  );
};

ProfileSection.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCurrentUserProfile: PropTypes.bool.isRequired,
};

export default ProfileSection;
