import {
  eachDayOfInterval,
  formatDistance,
  formatDistanceToNowStrict,
  formatRelative,
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

export const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
};

export const formatDateTimeAgo = date => {
  const today = utcToZonedTime(Date.now());
  const timestamp = date ? new Date(date * 1000) : '';
  const daysDifference = eachDayOfInterval({ start: timestamp, end: today })
    .length;

  let postDate;

  if (daysDifference <= 1) {
    postDate = formatDistanceToNowStrict(timestamp, today);
  } else if (daysDifference < 3) {
    postDate = formatRelative(timestamp, today);
  } else {
    postDate = format(timestamp, 'd LLL. y');
  }
  return postDate;
};
