import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import { EditButtonWrapper } from './EditButton.styled';

const EditButton = props => {
  return (
    <EditButtonWrapper>
      <Button
        type="circle"
        colorTheme="normal"
        svgIcon="edit"
        actionType="onclick"
        onClick={props.action.bind(null, props.id)}
      ></Button>
    </EditButtonWrapper>
  );
};

export default EditButton;
