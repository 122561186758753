import React from 'react';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
const OnboardingPage = () => {
  return (
    <OnboardingLayout
      title="Let's setup your account!"
      activeStep="Registration"
    ></OnboardingLayout>
  );
};

export default OnboardingPage;
