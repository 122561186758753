import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const StyledButton = styled(Button, {
  width: '100%',
});

export const FilterBarWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  // mb: '$16',

  '@bp1': {
    justifyContent: 'space-between',
    flexDirection: 'row',
    // mb: '$64',
  },
});

export const MobileWrapper = styled('div', {
  position: 'fixed',
  width: '90%',
  right: 0,
  top: '$48',
  padding: '$24',
  background: '$elements',
  height: '100%',
  zIndex: 5,
  '::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '-100%',
    top: '0',
  },
});

export const MobileWrapperHeader = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const FiltersWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$8',
  '.checkbox-filter-label': {
    fontSize: '$14',
    fontFamily: '$ArtifaktLegend',
  },
  '&:nth-of-type(n+1)': {
    marginTop: '$24',
  },
  '@bp1': {
    flexDirection: 'row',
    marginTop: '0',
    '&:nth-of-type(n+1)': {
      marginTop: '0',
    },
  },
});
