
export function formatDate(jsTimestamp, numberDateOnly = false){
  if(!jsTimestamp) return null
  const date = new Date(jsTimestamp * 1000);
  const day = date.getDate();
  let month = numberDateOnly ? date.getMonth() + 1 : date.toLocaleString('en-US', {month: 'short'});
  if(month < 10){
    month = '0' + month.toString()
  }
  // TODO: add support for current language
  const year = date.getFullYear();

  return numberDateOnly ? `${year} ${month} ${day}` : `${month} ${day}, ${year}`
}
