import {
  AvatarContainer,
  BoldText,
  LeftSideWrapper,
  TextContainer,
  ReactionText,
} from '../NotificationBox.styled';

import Avatar from '@components/atoms/avatar/Avatar';
import { Link } from 'gatsby';
import React from 'react';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';

const ReactionNotification = props => {
  let iconId = '';
  let subject = '';

  if (props.subject) {
    subject = props.subject;

    if (props.subject.length > 100) {
      subject = subject.substring(0, 100) + '...';
    }
  }

  switch (props.reactionType) {
    case 'post_new':
      iconId = 'edit';
      break;
    case 'post_like':
    case 'content_like':
      iconId = 'heart-filled';
      break;
    case 'post_comment':
      iconId = 'comment';
      break;
    case 'post_comment_reply':
      iconId = 'arrow-in-group';
      break;
    case 'post_approval':
      iconId = 'check';
      break;
    case 'post_rejection':
      iconId = 'warning';
      break;
    case 'report_post':
      iconId = 'report-flag';
      break;
    case 'report_comment':
      iconId = 'report-flag';
      break;
    case 'report_content':
      iconId = 'report-flag';
      break;
    case 'report_member':
      iconId = 'report-flag';
      break;
    case 'role_grant':
      iconId = 'thumbnail-level';
      break;
  }

  return (
    <LeftSideWrapper>
      <Link alt={props.title} to={props.url} onClick={props.onRemoveNewClick}>
        <AvatarContainer>
          <Avatar {...props.avatarProps} />
          <Svg icon={iconId} />
        </AvatarContainer>
        <div>
          <ReactionText>
            <BoldText>{props.author} </BoldText>
            <span>{props.notifiedAbout}</span>
          </ReactionText>
          {props.subject ? <ReactionText>“{subject}”</ReactionText> : null}
        </div>
      </Link>
    </LeftSideWrapper>
  );
};

export default ReactionNotification;
