import {
  BlackOverlay,
  BtnWrapper,
  CloseBtn,
  FilterTitle,
  GroupListingFilters,
  MobileFiltersWrapper,
  OtherFiltersMobileWrapper,
  OtherFiltersWrapper,
  StyledBtn,
} from './ListingNav.styled';
import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import DropdownComponent from '@components/molecules/listingHeader/listingNav/dropdown/Dropdown';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const ListingNav = props => {
  const suggestGroupText = <String id="community_groups_suggest_group" />;
  const filtersText = <String id="general_filters_btn_text" />;

  const [dropdowntState, setDropdowntState] = useState({
    filters: props.otherFilters,
    filtersDataSet: false,
  });

  const onDropdownItemClick = (title, parentTitle) => {
    const newArray = dropdowntState.filters;
    if (newArray.findIndex(obj => obj.title === parentTitle) !== -1) {
      const index = newArray.findIndex(obj => obj.title === parentTitle);
      newArray[index].title = title;
      setDropdowntState({
        ...dropdowntState,
        filters: newArray,
      });
    }
  };

  if (props.loadingComplete && !dropdowntState.filtersDataSet) {
    setDropdowntState({
      ...dropdowntState,
      filters: props.otherFilters,
      filtersDataSet: true,
    });
  }
  return (
    <GroupListingFilters css={props.css}>
      <BlackOverlay isVisible={props.mobileOpened}></BlackOverlay>
      <span>
        <div>
          {props.filtersList.map((filter, i) => {
            return (
              <BtnWrapper
                key={'filter' + i}
                isActive={props.isActive === i + 1}
              >
                <Button
                  label={filter.title}
                  actionType="onclick"
                  colorTheme="normal"
                  notificationBubbleActive={false}
                  onClick={() => {
                    props.onFilterClick(i + 1);
                  }}
                />
              </BtnWrapper>
            );
          })}
        </div>
        {!props.type === 'groups' && (
          <OtherFiltersWrapper>
            <Text size="14" css={{ color: '$darkGrey' }}>
              <span style={{ fontFamily: 'var(--ArtifaktElementBold)' }}>
                {props.searchResultCount}
              </span>{' '}
              <String id="community_tutorials_found" />
            </Text>
            {dropdowntState.filters.map((filter, i) => {
              return (
                <DropdownComponent
                  key={'dropdown' + i}
                  {...filter}
                  onFilterDropdownClick={props.onFilterDropdownClick}
                  onDropdownItemClick={onDropdownItemClick}
                  index={i}
                />
              );
            })}
          </OtherFiltersWrapper>
        )}
      </span>
      <MobileFiltersWrapper isVisible={props.mobileOpened}>
        <CloseBtn
          actionType="onclick"
          colorTheme="gray"
          svgIcon="close-big"
          type="circle"
          onClick={props.onMobileFiltersClick}
        />
        <div>
          <FilterTitle size="24" css={{color: "black"}}>{filtersText}</FilterTitle>
          {props.filtersList.map((filter, i) => {
            return (
              <BtnWrapper
                key={'filter' + i}
                isMobileActive={props.isActive === i + 1}
                isMobile
              >
                <Button
                  label={filter.title}
                  actionType="onclick"
                  colorTheme="normal"
                  notificationBubbleActive={false}
                  onClick={() => {
                    props.onFilterClick(i + 1);
                  }}
                />
              </BtnWrapper>
            );
          })}

          {props.type !== 'groups' && (
            <OtherFiltersMobileWrapper>
              {dropdowntState.filters.map((filter, i) => {
                return (
                  <DropdownComponent
                    key={'dropdown' + i}
                    {...filter}
                    onFilterDropdownClick={props.onFilterDropdownClick}
                    onDropdownItemClick={onDropdownItemClick}
                    index={'mobile' + i}
                  />
                );
              })}
            </OtherFiltersMobileWrapper>
          )}
        </div>
      </MobileFiltersWrapper>
      <StyledBtn
        label={filtersText}
        actionType="onclick"
        colorTheme="gray"
        svgIcon="filters"
        svgLocation="after"
        notificationBubbleActive={false}
        onClick={props.onMobileFiltersClick}
      />
    </GroupListingFilters>
  );
};

ListingNav.propTypes = {
  onFilterClick: PropTypes.func.isRequired,
  isActive: PropTypes.number,
  onSuggestGroupClick: PropTypes.func,
  onFilterDropdownClick: PropTypes.func.isRequired,
  onMobileFiltersClick: PropTypes.func.isRequired,
  filtersList: PropTypes.array.isRequired,
  otherFilters: PropTypes.array,
  type: PropTypes.string,
  searchResultCount: PropTypes.number,
  mobileOpened: PropTypes.bool,
};

export default ListingNav;
