import { styled } from '@root/stitches.config';

export const FormStyled = styled('form', {
  maxWidth: '420px',
  marginTop: '$24',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@bp2': {
    alignItems: 'flex-start',
  },
});

export const ErrorMsg = styled('p', {
  marginTop: '$24',
  color: '$red',
});

export const NotificationBox = styled('div', {
  padding: '$24',
  backgroundColor: '$box',
  marginBottom: '$16',
  borderRadius: '$16',
});
