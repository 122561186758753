import { styled } from '@root/stitches.config';

export const ThreeBadgesWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  margin: '0 0 0 -$16',

  '& div': {
    width: '44px',
    height: '44px',
    flexShrink: 0,
  },

  '& > div:nth-of-type(1)': {
    position: 'relative',
    zIndex: '0',
    left: '$16',
  },
  '& > div:nth-of-type(2)': {
    zIndex: '1',
  },
  '& > div:nth-of-type(3)': {
    position: 'relative',
    zIndex: '2',
    left: '-$16',
  },
  '& > div:nth-of-type(n+3)': {
    position: 'relative',
    zIndex: '3',
    left: '-$16',
    marginRight: '-$16',
  },
  margin: '0 0 0 -$16',
});

export const TwoBadgesWrapper = styled('div', {
  display: 'flex',
  margin: '0 0 0 -$8',

  '& div': {
    width: '44px',
    height: '44px',
    flexShrink: 0,
  },

  '& > div:nth-of-type(1)': {
    position: 'relative',
    zIndex: '0',
    left: '$16',
  },
  '& > div:nth-of-type(2)': {
    position: 'relative',
    zIndex: '1',
    left: '-$8',
  },

  variants: {
    siblings: {
      true: {
        margin: '0 $16 0 -$16',
      },
      false: {
        margin: '0 0 0 -$16',
      },
    },
  },
});

export const SingleBadgeWrapper = styled('div', {
  display: 'flex',

  '& div': {
    width: '44px',
    height: '44px',
    flexShrink: 0,
  },

  variants: {
    siblings: {
      true: {
        margin: '0 $24 0 0',
      },
      false: {
        margin: 'unset',
      },
    },
  },
});

export const MoreButton = styled('div', {
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  boxShadow: '$2',
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$14',
  transition: 'box-shadow .3s ease',
  backgroundColor: '$elements',
  flexShrink: '0',
  transform: 'translateY(0)',
  transition: 'transform .2s ease-out',

  // ds_clean
  // '.theme-0 &': {
  //   backgroundColor: '$charcoal100',
  //   color: '$mainTextColor',
  // },
});
