import {
  BigButton,
  BigButtonsContainer,
  BottomBtnContainer,
  CreateButton,
  DarkModeContainer,
  DarkModeLeft,
  DarkModeRight,
  HorizontalButton,
  ProfileBottomContainer,
  ProfileMenuWrapper,
  ProfileTopContainer,
  UserContainer,
  UserTextContainer,
} from './ProfileMenu.styled';
import { Link, navigate } from 'gatsby';
import React, { useContext } from 'react';

import AuthContext from '@context/AuthContext';
import Avatar from '@components/atoms/avatar/Avatar';
import Button from '@components/atoms/button/Button';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { useTransitionMount } from '@src/utils/useTransitionMount';

const ProfileMenu = props => {
  const myGroupsText = <String id="general_my_groups" />;
  const favoritesText = <String id="general_favorites" />;
  const profileSettingsText = <String id="profile_settings" />;
  const accountSettingsText = <String id="profile_account_settings" />;

  const UrlContext = useContext(UrlsContext);
  const authContext = useContext(AuthContext);

  const onLogoutClick = () => {
    props.onProfileClick();
    props.auth.logout();
  };

  const bannerActions = {
    actionType: 'internalLink',
    internalUrl: '/create',
    label: 'Feeling inspired?',
  };

  const getAvatar = (profilePicture, size) => {
    return getAvatarImage(profilePicture, size);
  };

  let profilPicture = getAvatar(
    authContext?.profilePicture,
    authContext?.firstName
  );

  const userProfileProps = {
    avatarProps: {
      shape: 'circle',
      alt: authContext.firstName + ' ' + authContext.lastName,
      src: {
        childImageSharp: {
          fixed: {
            height: 40,
            width: 40,
            src: `${profilPicture}`,
            srcset: `${profilPicture} 1x,${profilPicture} 250w,${profilPicture} 500w`,
          },
        },
      },
    },
    url: `/m/${authContext.userSlug}`,
    alt: 'my profile',
  };

  const hasTransitioned = useTransitionMount(props.isShown, 1000);

  if (props.isShown || hasTransitioned) {
    return (
      <ProfileMenuWrapper isShown={props.isShown && hasTransitioned}>
        <ProfileTopContainer>
          <UserContainer>
            <Link
              alt={userProfileProps.alt}
              to={userProfileProps.url}
              style={{
                textDecoration: 'none',
                color: 'unset',
              }}
            >
              <Avatar {...userProfileProps.avatarProps} />
              <UserTextContainer>
                <Text
                  size="16"
                  css={{
                    fontFamily: '$ArtifaktLegendBold',
                    color: '$white',
                    lineHeight: '$2',
                  }}
                >
                  <String id="profile_morning_greetings" />{' '}
                  {authContext.firstName}!
                </Text>
                <Text
                  size="14"
                  css={{
                    fontFamily: '$ArtifaktElementRegular',
                    lineHeight: '1em',
                    color: '$white',
                  }}
                >
                  <String id="profile_my_profile" />
                </Text>
              </UserTextContainer>
            </Link>
          </UserContainer>
          <Button
            type="circle"
            svgIcon="logout"
            actionType="onclick"
            onClick={onLogoutClick}
            colorTheme="transparent"
            css={{ svg: { fill: '$white' } }}
          />
        </ProfileTopContainer>
        <ProfileBottomContainer>
          <CreateButton>
            <button>
              <Link to={GetUrl('create', UrlContext)}>
                <Svg icon="plus" />
              </Link>
            </button>
            <Link to={GetUrl('create', UrlContext)}>
              <Text as="h4" size="24" weight="legendExtraBold">
                Create
              </Text>
            </Link>
          </CreateButton>

          <BigButtonsContainer>
            <Link
              alt="my groups"
              to={GetUrl('communityGroups', UrlContext) + '/my-groups'}
            >
              <BigButton>
                <Svg icon="groups" />
              </BigButton>
              <Text size="14">{myGroupsText}</Text>
            </Link>
            <Link alt="favorites" to={`/m/${authContext.userSlug}/favorites`}>
              <BigButton>
                <Svg icon="favorite"></Svg>
              </BigButton>
              <Text size="14">{favoritesText} </Text>
            </Link>
          </BigButtonsContainer>
          <Text
            size="24"
            css={{
              fontFamily: '$ArtifaktLegendExtraBold',
              marginBottom: '$16',
              color: '$mainTextColor',
              lineHeight: '30px',
            }}
          >
            <String id="profile_configuration_preferences" />
          </Text>
          <BottomBtnContainer>
            <HorizontalButton>
              <Link
                alt="Account Settings"
                to={`/m/${authContext.userSlug}/account-settings`}
              >
                <Text size="14">{accountSettingsText}</Text>
                <Svg icon="settings" />
              </Link>
            </HorizontalButton>
            <HorizontalButton>
              <Link
                alt="Profile Settings"
                to={`/m/${authContext.userSlug}?edit=profile`}
              >
                <Text size="14">{profileSettingsText}</Text>
                <Svg icon="thumbnail-level" />
              </Link>
            </HorizontalButton>
          </BottomBtnContainer>
          <DarkModeContainer>
            <DarkModeLeft>
              <Svg icon="darkmode-small" />
              <Text size="16" css={{ color: '$mainTextColor' }}>
                Dark Mode
              </Text>
            </DarkModeLeft>
            <DarkModeRight>
              <span></span>
              <Text size="12">on</Text>
              <Text size="12">off</Text>
            </DarkModeRight>
          </DarkModeContainer>
        </ProfileBottomContainer>
      </ProfileMenuWrapper>
    );
  }
  return null;
};

export default ProfileMenu;
