import React, { useContext, useEffect, useState } from 'react';

import FilterBarSearch from './filters/FilterBarSearch';
import { GET_INDUSTRIES_AND_TOOLS } from '@src/apollo/query';
import Layout from '@components/organisms/layout/Layout';
import MultipleContent from './sections/MultipleContent';
import SEO from '@components/atoms/seo/Seo';
import SearchBarWithTags from '@components/organisms/searchBarWithTags/SearchBarWithTags';
import SearchContext from '@context/SearchContext';
import SingleContent from './sections/SingleContent';
import { WrapperPage } from './Search.styled';
import { useQuery } from '@apollo/client';
import { sortArrayByTitle } from '@src/utils/sortArray';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';

const SearchPage = context => {
  const { handleContext, searchState } = useContext(SearchContext);

  useEffect(() => {
    return () => {
      handleContext({
        type: 'clean',
      });
    };
  }, []);

  const [filtersState, setFiltersState] = useState({
    isActive: [
      {
        filterKey: 'industries',
        title: 'Recent',
        type: 'single',
      },
    ],
    isDataSet: false,
    dropdownFilters: [
      {
        type: 'dropdown',
        title: 'All Tools',
        filterKey: 'tools',
        options: [],
      },
      {
        type: 'dropdown',
        title: 'All Industries',
        filterKey: 'industries',
        options: [],
      },
    ],
  });

  const [searchUrlState, setSearchUrlState] = useState({
    isSearchFromUrlLoaded: false,
  });

  // filters
  const { data: dataFiltersInfos } = useQuery(GET_INDUSTRIES_AND_TOOLS, {
    context: { clientName: 'public' },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    dataFiltersInfos && !filtersState.isDataSet
      ? setDataFilters(dataFiltersInfos)
      : null;
  }, [dataFiltersInfos]);

  const setDataFilters = data => {
    const { industries } = data;
    const { tools } = data;

    setFiltersState({
      ...filtersState,
      dropdownFilters: [
        {
          type: 'dropdown',
          title: 'All Tools',
          filterKey: 'tools',
          options: tools.slice().sort(sortArrayByTitle),
        },
        {
          type: 'dropdown',
          title: 'All Industries',
          filterKey: 'industries',
          options: industries,
        },
      ],
      isDataSet: true,
    });
  };

  function dropdownOptionsToVM(options) {
    return options.map(option =>
      option.__typename === 'Tool'
        ? { id: option.id, name: option.title }
        : { id: option.id, name: option.name }
    );
  }
  const filtersToVM = filters =>
    filters.map(filter => {
      if (filter.type === 'dropdown') {
        return {
          ...filter,
          options: dropdownOptionsToVM(filter.options),
        };
      }
      return filter;
    });

  // searchBar
  const searchBarFilters = {
    title: 'articles',
    colorTheme: 'transparent',
    items: [
      { title: 'articles' },
      { title: 'artworks' },
      { title: 'tutorials' },
      { title: 'assets' },
      { title: 'tutorial_series' },
      { title: 'members' },
    ],
  };

  const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  };

  useEffect(() => {
    if (
      window.location.search !== '' &&
      !searchUrlState.isSearchFromUrlLoaded &&
      filtersState.isDataSet
    ) {
      const query = getQueryVariable('query');
      const type = getQueryVariable('type');
      const industries = getQueryVariable('industries')?.split(',');
      const tools = getQueryVariable('tools')?.split(',');
      const page = getQueryVariable('page');

      setFiltersState({
        ...filtersState,
        isActive: [
          {
            id: 'industries',
            selectedValues: industries,
            type: 'dropdown',
          },
          {
            id: 'tools',
            selectedValues: tools,
            type: 'dropdown',
          },
        ],
      });

      handleContext({
        type: 'update',
        payload: {
          keywords: query,
          types: type,
          page: parseInt(page, 10),
          filters: {
            industries: industries,
            tools: tools,
          },
          isSearch: true,
        },
      });

      setSearchUrlState({
        isSearchFromUrlLoaded: true,
      });
    }
  }, [window.location.search, searchUrlState, filtersState]);

  const isInfoLoaded =
    searchUrlState.isSearchFromUrlLoaded || window.location.search == '';

  if (
    window.location.search == '' &&
    !searchUrlState.isSearchFromUrlLoaded &&
    filtersState.isDataSet
  ) {
    setSearchUrlState({
      isSearchFromUrlLoaded: true,
    });
  }

  return (
    <Layout location={context.location}>
      <SEO
        title="Search"
        slug="search"
        seomatic={context?.pageContext?.entry?.seomatic}
      />
      <LandingPageWrapper>
        {isInfoLoaded && (
          <SearchBarWithTags
            filters={searchBarFilters}
            activeFilter={searchState.types || 'articles'}
            query={searchState.keywords || ''}
            onChange={e => handleContext(e)}
          />
        )}

        <SectionWrapper>
          {isInfoLoaded && searchState.contentType === 'single' && (
            <>
              <FilterBarSearch
                css={{ justifyContent: 'flex-end' }}
                filters={filtersToVM([...filtersState.dropdownFilters])}
                onChange={e =>
                  setFiltersState({
                    ...filtersState,
                    isActive: JSON.parse(e),
                    isDataSet: false,
                  })
                }
                defaultFilters={filtersState.isActive}
              />
            </>
          )}

          {searchState.contentType === 'multiple' && (
            <MultipleContent
              context={context}
              search={searchState}
              filters={filtersState}
            />
          )}

          {searchState.contentType === 'single' && isInfoLoaded && (
            <SingleContent
              context={context}
              search={searchState}
              filters={filtersState}
            />
          )}
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};

export default SearchPage;
