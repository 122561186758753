import { styled } from '@root/stitches.config';

export const CreatePageWrapper = styled('div', {
  minHeight: '70vh',
  // display: 'flex'
});

export const CreateTypeCardsWrapper = styled('div', {
  display: 'grid',
  gap: '$32',
  mt: '$24',
  '@bp2': {
    gridTemplateColumns: '1fr 1fr',
  },
  '@bp3': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  // display: 'flex'
});

export const PageWrapper = styled('div', {
  minHeight: '70vh',
  margin: '0 auto',
  padding: '$32 $16 $64 $16',
  '@bp2': {
    padding: '$48 $64 $84 $64',
  },

  '@bp4': {
    maxWidth: '$container',
  },
});
