import { styled } from '@root/stitches.config';

import { Heading } from '@components/atoms/heading/Heading';
import ThumbnailSlider from '@components/organisms/thumbnailSlider/ThumbnailSlider';

export const WrapperPage = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  minHeight: '70vh',
  padding: '$54 $20 $72 $20',

  '@bp1': {
    maxWidth: '$container',
    margin: 'auto',
    padding: '$72 $64 $72 $64',
  },
});

export const WrapperHeading = styled('div', {
  margin: '$24 0 $32 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '@bp1': {
    margin: '$24 0 $40 0',
  },
});

export const Title = styled(Heading, {
  fontSize: '$32 !important',
  lineHeight: '$36 !important',
  fontFamily: '$ArtifaktLegendBold',
  textAlign: 'center',
});

export const TitleSignleContent = styled(Heading, {
  fontSize: '$48 !important',
  lineHeight: '$54 !important',
  fontFamily: '$ArtifaktLegendBold',
  // textAlign: 'center',
  textTransform: 'capitalize',
});

export const Intro = styled(Heading, {
  fontSize: '$14 !important',
  lineHeight: '$20 !important',
  fontFamily: '$ArtifaktElementRegular',
  textAlign: 'center',

  '@bp1': {
    maxWidth: '50%',
  },
});

export const ThumbnailSliderStyled = styled(ThumbnailSlider, {
  margin: '0 0 $40 0',

  h3: {
    fontSize: '$48',
    fontFamily: '$ArtifaktLegendBold',
  },

  '@bp1': {
    margin: '0 0 $48 0',
  },
});

export const WrapperThumbnail = styled('div', {
  marginTop: '$16',
  marginBottom: '$54',
  display: 'grid',
  gap: '32px',
  gridTemplateColumns: 'repeat(1, 1fr)',
  bp0: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginTop: '$24',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: '$64',
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

export const PaginationWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const NoResultContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '300px',
  maxWidth: '360px',
  py: '$16',
  width: '100%',
  textAlign: 'center',
  margin: 'auto',
  marginBottom: '$92',
  svg: {
    stroke: '$blackToWhite'
  }
});
