import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BarChartWrapper } from './BarChartBlock.styled';
import { generateRandomColor } from '@src/utils/generateRandomColor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChartBlock(props) {
  const {
    horizontal,
    datas,
  } = props;

  const keys = [...new Set([].concat.apply([], datas.dataSet.map(el => Object.keys(el.datas))))];
  const labels = datas.dataSet.map(el => el.label);
  const datasets = keys.map((el, index) => ({
    label: `${el.charAt(0).toUpperCase()}${el.slice(1)}`.replace(/[^a-zA-Z ]/g, " "),
    data: datas.dataSet.map(item => item.datas[el]),
    ...datas.colors?.[index] !== undefined 
      ? datas.colors[index] 
      : { // generate colors in case there's no colors object
        backgroundColor: generateRandomColor(),
        borderColor: generateRandomColor(),
      },
  }));

  const options = {
    indexAxis: horizontal ? 'y' : 'x',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  return (
    <BarChartWrapper>
      <Bar options={options} data={{labels, datasets}}/>
    </BarChartWrapper>
  );
}

BarChartBlock.propTypes = {
  horizontal: PropTypes.bool,
  datas: PropTypes.shape({
    dataSet: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      datas: PropTypes.objectOf(PropTypes.number).isRequired,
    })).isRequired,
    colors: PropTypes.arrayOf(PropTypes.shape({
      border: PropTypes.string,
      backgroundColor: PropTypes.string,
    }))
  }).isRequired,
};

BarChartBlock.defaultProps = {
  horizontal: false,
}

export default BarChartBlock
