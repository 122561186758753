import {
  CREATE_USERCONTENT,
  REMOVE_USERCONTENT_BLOC,
  UPDATE_USERCONTENT,
} from '@src/apollo/mutation';
import { GET_CREATE_INFO, GET_USER_DATA } from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import {
  addImageBlock,
  createViewToDataModel,
  goToSection,
  initialAdminFormState,
  initialCreateState,
  initialInfoFormState,
  initialThumbnailCropData,
  onAddBlock,
  onChangeBlockChangeOrder,
  onChangeImageCropIndex,
  onCreatePostToDataModel,
  onEditBlock,
  onEditUserContentblock,
  onEditVideoBlock,
  onEditSketchfabBlock,
  onNavItemClick,
  onReOrderBlocks,
  onRemoveBlock,
  onUpdatePostToDataModel,
  setData,
  useCreateNextButtonValidation,
  useShowAdminSettings,
} from './utils';
import { useMutation, useQuery } from '@apollo/client';
import AuthContext from '@context/AuthContext';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import {
  userContentType,
  userContentUrl,
} from '@src/utils/dataToThumbnailViewModel';
import CreateForm from './createForm/CreateForm';
import { CreatePageWrapper } from './Create.styled';
import Layout from '@components/organisms/layout/Layout';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import SEO from '@components/atoms/seo/Seo';
import { navigate } from 'gatsby';
import useModal from '@components/atoms/modal/useModal';
import Modal from '@components/atoms/modal/Modal';
import CreateWarning from './sections/CreateWarning';
import { UPDATE_USER_CREATE_TC_ACCEPTED } from '@src/apollo/mutation';

const CreateBuild = props => {
  const authContext = useContext(AuthContext);
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  const [modalType, setModalType] = useState();
  const { isShowing, toggle } = useModal();

  const [setCreateTCAccepted, createTCAcceptedData] = useMutation(
    UPDATE_USER_CREATE_TC_ACCEPTED
  );

  useEffect(() => {
    if (!authContext.createTermsAndConditionsAccepted) {
      toggle();
      setModalType(props.params.contentType);
    }
  }, [authContext.createTermsAndConditionsAccepted]);

  const isSuperMember =
    authContext.generalRole === 'admin' ||
    authContext.generalRole === 'editor' ||
    authContext.generalRole === 'contributor';

  const CREATE_TYPES = [
    'artwork',
    'article',
    'tutorial',
    'tutorial_series',
    ...(isSuperMember ? ['asset'] : []),
  ];

  if (!authContext.isVerified && authContext.isAuthenticated) {
    navigate('/signup-validation');
  }

  const showAdminSettings = useShowAdminSettings(
    authContext.isAuthenticated,
    authContext.generalRole
  );

  const [isPublishBtnDisabled, setIsPublishBtnDisabled] = useState(true);
  const [creatorData, setCreatorData] = useState();
  const [postIdToEdit, setPostIdToEdit] = useState();

  const [createState, setCreateState] = useState(
    initialCreateState(authContext, props.contentType)
  );
  const [createInfoFormState, setCreateInfoFormState] = useState(
    initialInfoFormState
  );
  const [createAdminFormState, setCreateAdminFormState] = useState(
    initialAdminFormState
  );
  const [thumbnailCropData, setThumbnailCropsData] = useState(
    initialThumbnailCropData
  );

  const [modal, setModalData] = useState({
    isShowing: false,
  });

  const { loading, error, data } = useQuery(GET_CREATE_INFO, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: creatorDataLoading,
    error: creatorDataError,
    data: creatorDataData,
  } = useQuery(GET_USER_DATA, {
    variables: {
      username: authContext.userSlug,
    },
  });

  const [updateCreate, dataUpdateCreate] = useMutation(UPDATE_USERCONTENT, {
    onCompleted: res => {
      const sortedResBlocks = res.updateUserContent.blocs.sort(
        (a, b) => a.order - b.order
      );

      setPostIdToEdit(res.updateUserContent.id);

      const mergedBlocks = createState.blockList.map((el, i) => ({
        ...el,
        isLoading: false,
        ...sortedResBlocks[i],
      }));

      setCreateState({
        ...createState,
        blockList: mergedBlocks,
      });
    },
  });

  useEffect(() => {
    if (
      props.params.contentType &&
      CREATE_TYPES.includes(props.params.contentType)
    ) {
      setCreateState({
        ...createState,
        createType: props.params.contentType,
      });
    } else {
      navigate('/404');
    }
  }, []);

  const [publishCreate, dataPublishCreate] = useMutation(UPDATE_USERCONTENT, {
    onCompleted: res => {
      const isInspireArticle =
        res.updateUserContent?.article_section?.name === 'Inspire';
      const isNotSameAuthor =
        createAdminFormState.authoring.author !== null &&
        createAdminFormState.authoring.author !== authContext.userId;
      const contentType = userContentType(
        res.updateUserContent.userContentType,
        res.updateUserContent?.article_type?.key
      );
      const postSlug = `${userContentUrl(
        contentType,
        res.updateUserContent?.article_type?.key,
        isInspireArticle,
        res.updateUserContent?.creator?.username,
        res.updateUserContent.slug,
        res.updateUserContent.status,
        res.updateUserContent.motm_year
      )}/${
        res.updateUserContent.status !== 'pending'
          ? res.updateUserContent.status
          : ''
      }`;

      if (!isNotSameAuthor) {
        navigate(postSlug);
      } else if (isNotSameAuthor && isInspireArticle) {
        handleUpdateClientNotification({
          text: 'create_different_author_inspire',
          type: 'success',
          autoDismiss: true,
        });
        navigate('/inspire');
      } else if (isNotSameAuthor && !isInspireArticle) {
        handleUpdateClientNotification({
          text: 'create_different_author',
          type: 'success',
          autoDismiss: true,
        });
        navigate('/');
      }
    },
  });

  const [create, dataCreate] = useMutation(CREATE_USERCONTENT, {
    onCompleted: res =>
      setCreateState({
        ...createState,
        blockList: [
          { ...createState.blockList[0], ...res.createUserContent.blocs[0] },
        ].map(el => ({ ...el, isLoading: false })),
      }),
  });

  useEffect(() => {
    setPostIdToEdit(dataCreate?.data?.createUserContent?.id);
  }, [dataCreate?.data?.createUserContent?.id]);

  const [removeUserContentBloc, dataRemoveUserContentBloc] = useMutation(
    REMOVE_USERCONTENT_BLOC
  );

  useEffect(() => {
    const dataError =
      (dataCreate.error || dataUpdateCreate.error || dataPublishCreate.error)
        ?.graphQLErrors || [].map(({ debugMessage }) => debugMessage).join('');
    if (
      dataError === 'could_not_create_user_content : inappropriate_content' ||
      dataError === 'could_not_update_some_fields : inappropriate_content' ||
      dataCreate.error?.[0]?.exception?.prev?.prev?.message ===
        'inappropriate_content' ||
      dataUpdateCreate.error?.[0]?.exception?.prev?.prev?.message ===
        'inappropriate_content'
    ) {
      setModalData({
        isShowing: true,
        modalType: 'nsfw',
        createType: 'artwork',
        actions: [
          {
            label: 'reupload image',
            onClick: () => {
              setModalData({ isShowing: false });
              if (dataCreate.error) {
                setCreateState({
                  ...createState,
                  blockList: [],
                });
              }
              if (dataUpdateCreate.error) {
                const newBlockList = createState.blockList;
                newBlockList.pop();
                setCreateState({
                  ...createState,
                  blockList: newBlockList,
                });
              }
              if (dataPublishCreate.error) {
                const newBlockList = createState.imageBlockList;
                newBlockList.pop();
                setCreateState({
                  ...createState,
                  createCover: undefined,
                  imageBlockList: newBlockList,
                });
                setThumbnailCropsData({
                  image: '',
                  cropData: {},
                  currentImageCrop: '',
                });
              }
            },
            colorTheme: 'normal',
          },
        ],
      });
    }
  }, [dataCreate.error, dataUpdateCreate.error, dataPublishCreate.error]);

  useEffect(() => {
    const coverError =
      dataPublishCreate.error?.networkError?.response?.statusText ||
      dataPublishCreate?.error?.message;
    if (
      (coverError && coverError === 'Request Entity Too Large') ||
      coverError === 'validation'
    ) {
      setModalData({
        isShowing: true,
        modalType: 'cover_too_large',
        actions: [
          {
            label: 'reupload image',
            onClick: () => {
              setModalData({ isShowing: false });
              if (dataPublishCreate.error) {
                const newBlockList = createState.imageBlockList;
                newBlockList.pop();
                setCreateState({
                  ...createState,
                  createCover: undefined,
                  imageBlockList: newBlockList,
                });
                setThumbnailCropsData({
                  image: '',
                  cropData: {},
                  currentImageCrop: '',
                });
              }
            },
            colorTheme: 'normal',
          },
        ],
      });
    }
  }, [dataPublishCreate.error]);

  useEffect(() => {
    if (!loading && !createState.isDataSet) {
      setData(
        data,
        createState,
        setCreateState,
        createInfoFormState,
        setCreateInfoFormState,
        createAdminFormState,
        setCreateAdminFormState
      );
    }
  }, [loading, createState.isDataSet]);

  useEffect(() => {
    if (
      typeof createAdminFormState.publish_options.selectedMoMember
        .first_name === 'string' &&
      typeof createAdminFormState.publish_options.selectedMoMember.last_name ===
        'string'
    ) {
      setCreateInfoFormState({
        ...createInfoFormState,
        title: `${createAdminFormState.publish_options.selectedMoMember.first_name} ${createAdminFormState.publish_options.selectedMoMember.last_name}`,
      });
    }
  }, [
    createAdminFormState.publish_options.selectedMoMember.first_name,
    createAdminFormState.publish_options.selectedMoMember.last_name,
  ]);

  const isFirstBlockAdded =
    (!dataCreate.called && dataCreate.data === undefined) || dataCreate.error;

  const onAssetChange = asset => {
    const blockId = createState?.blockList[0]?.id;

    const newBlock = {
      type: 'asset',
      slug: 'block-0',
      order: 0,
      asset: {
        file: asset.file,
        title: asset.file.name,
      },
    };

    setCreateState({
      ...createState,
      assetBlockHasError: asset.hasError,
    });

    if (!asset.hasError) {
      if (isFirstBlockAdded) {
        const createPostData = onCreatePostToDataModel({
          createInfoFormState,
          createAdminFormState,
          createState: {
            ...createState,
            blockList: [newBlock],
          },
          isAdmin: showAdminSettings,
        });
        create({
          variables: {
            input: createPostData.userInput,
            admin_input: createPostData.adminInput,
          },
        });
      }
      if (blockId) {
        updateCreate({
          variables: {
            id: postIdToEdit,
            input: {
              status: 'draft',
              blocs: [
                {
                  ...newBlock,
                  id: blockId,
                },
              ],
            },
          },
        });
      }
    }
  };

  const addBlock = block => {
    onAddBlock(block, createState, setCreateState);
    const newBlock =
      block.type === 'text'
        ? {
            ...block,
            text: '<p></p>',
          }
        : block;

    if (isFirstBlockAdded) {
      const createPostData = onCreatePostToDataModel({
        createInfoFormState,
        createAdminFormState,
        createState: {
          ...createState,
          blockList: [newBlock],
        },
        isAdmin: showAdminSettings,
      });

      create({
        variables: {
          input: createPostData.userInput,
          admin_input: createPostData.adminInput,
        },
      });
    } else {
      updateCreate({
        variables: {
          id: postIdToEdit,
          input: onUpdatePostToDataModel({ blocks: [newBlock] }),
        },
      });
    }
  };

  const handleEditBlock = (index, value) => {
    let newBlockData = {};
    const blockId = createState?.blockList[index]?.id;
    const blockOrder = createState?.blockList[index]?.order;

    if (value?.type && value?.type === 'content') {
      const valueToDataModel = items =>
        items.map(item => ({
          id: item.id,
          order: item.order,
        }));

      newBlockData = {
        contents: valueToDataModel(value.value),
        order: blockOrder,
        text: value.text,
        id: blockId,
      };
    }

    if (!value?.type || (value?.type && value?.type !== 'content')) {
      onEditBlock(index, value, createState, setCreateState);
      newBlockData = {
        text: value,
        order: blockOrder,
        id: blockId,
      };
    }

    if (blockId !== undefined && blockOrder !== undefined) {
      updateCreate({
        variables: {
          id: postIdToEdit,
          input: {
            status: 'draft',
            blocs: [newBlockData],
          },
        },
      });
    }
  };

  const onPublish = type => {
    const finalData = createViewToDataModel({
      createInfoFormState,
      createState,
      createAdminFormState,
      thumbnailCropData,
      isDraft: type === 'draft',
      isAdmin: showAdminSettings,
    });

    if (dataCreate.data?.createUserContent?.id) {
      publishCreate({
        variables: {
          id:
            dataUpdateCreate?.data?.updateUserContent?.id ||
            dataCreate.data.createUserContent.id,
          input: finalData.userInput,
          admin_input: finalData.adminInput,
        },
      });
    }
  };

  useEffect(() => {
    if (
      !(
        createState.blockList.length < 1 ||
        (createState.createCover && createState.createCover.image === '')
      )
    ) {
      setIsPublishBtnDisabled(false);
    } else {
      setIsPublishBtnDisabled(true);
    }
  }, [createState]);

  useEffect(() => {
    if (creatorDataData && creatorDataData.user) {
      setCreatorData({
        name: `${creatorDataData?.user?.first_name} ${creatorDataData?.user?.last_name}`,
        profilePicture: creatorDataData?.user?.userProfile?.profile_picture,
        title: creatorDataData?.user?.title,
      });
    }
  }, [creatorDataData]);

  const currentStepName = createState.steps[createState.currentStep];

  const {
    isNextDisabled,
    content: nextDisabledContent,
    emptyBlockIndexes,
  } = useCreateNextButtonValidation(
    createState,
    currentStepName,
    createInfoFormState,
    createAdminFormState,
    showAdminSettings
  );

  const isLoading =
    dataCreate?.loading ||
    dataPublishCreate?.loading ||
    dataRemoveUserContentBloc?.loading ||
    loading;

  return (
    <ProtectedRoute redirectTo="/login" userAccessReq={['auth', 'verified']}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <Layout location={props.location}>
        <SEO
          title="CreateBuild"
          slug="createBuild"
          seomatic={props?.pageContext?.entry?.seomatic}
        />
        {modalType !== undefined && (
          <Modal
            isShowing={isShowing}
            hide={() => {
              toggle();
              navigate('/create');
            }}
          >
            <CreateWarning
              gotoNextSection={() => {
                authContext.updateCreateTermsAndConditionsAccepted(true);
                setCreateTCAccepted();
                toggle();
              }}
              type={modalType}
            />
          </Modal>
        )}
        <CreatePageWrapper>
          <CreateForm
            creatorData={creatorData}
            modalData={modal}
            emptyBlockIndexes={emptyBlockIndexes}
            isNextBtnDisabled={
              isNextDisabled || dataUpdateCreate?.loading || isLoading
            }
            nextDisabledContent={nextDisabledContent}
            showAdminSettings={showAdminSettings}
            createAdminFormState={createAdminFormState}
            setCreateAdminFormState={setCreateAdminFormState}
            onAssetChange={onAssetChange}
            isLoading={isLoading}
            setModalData={setModalData}
            currentStepName={currentStepName}
            onEditUserContentblock={(i, v) => {
              onEditUserContentblock(i, v, createState, setCreateState);
              handleEditBlock(i, { type: 'content', ...v });
            }}
            createState={createState}
            onNavItemClick={idx =>
              onNavItemClick(
                idx,
                createState,
                createInfoFormState,
                createAdminFormState,
                setCreateState,
                setCreateInfoFormState,
                setCreateAdminFormState,
                showAdminSettings
              )
            }
            gotoPrevSection={() =>
              goToSection(
                'prev',
                createState,
                createInfoFormState,
                createAdminFormState,
                setCreateState,
                setCreateInfoFormState,
                setCreateAdminFormState,
                showAdminSettings
              )
            }
            gotoNextSection={() =>
              goToSection(
                'next',
                createState,
                createInfoFormState,
                createAdminFormState,
                setCreateState,
                setCreateInfoFormState,
                setCreateAdminFormState,
                showAdminSettings
              )
            }
            postId={postIdToEdit}
            createInfoFormState={createInfoFormState}
            setCreateInfoFormState={setCreateInfoFormState}
            isAddingBlockDone={dataUpdateCreate.loading}
            onAddBlock={blc => addBlock(blc)}
            onEditBlock={(idx, val) => handleEditBlock(idx, val)}
            onRemoveBlock={(idx, id) => {
              onRemoveBlock(idx, createState, setCreateState);
              if (id) {
                removeUserContentBloc({
                  variables: {
                    ids: [parseInt(id, 10)],
                  },
                });
              }
            }}
            onChangeBlockChangeOrder={(idx, dir) => {
              const reordredStateBlocks = onChangeBlockChangeOrder(
                idx,
                dir,
                createState.blockList
              );

              setCreateState({
                ...createState,
                blockList: reordredStateBlocks,
              });
              updateCreate({
                variables: {
                  id: postIdToEdit,
                  input: {
                    blocs: reordredStateBlocks.map(el => ({
                      order: el.order,
                      id: el.id,
                      slug: el.slug,
                    })),
                  },
                },
              });
            }}
            onEditVideoBlock={(idx, blc) =>
              onEditVideoBlock(idx, blc, createState, setCreateState)
            }
            onEditSketchfabBlock={(idx, blc) =>
              onEditSketchfabBlock(idx, blc, createState, setCreateState)
            }
            thumbnailCropData={thumbnailCropData}
            setThumbnailCropsData={setThumbnailCropsData}
            setCreateState={setCreateState}
            onReOrderBlocks={bl => {
              const reordredStateBlocks = onReOrderBlocks(bl);

              setCreateState({
                ...createState,
                blockList: reordredStateBlocks,
              });

              updateCreate({
                variables: {
                  id: postIdToEdit,
                  input: {
                    blocs: reordredStateBlocks.map(el => ({
                      order: el.order,
                      id: el.id,
                      slug: el.slug,
                    })),
                  },
                },
              });
            }}
            onChangeImageCropIndex={idx =>
              onChangeImageCropIndex(idx, createState, setCreateState)
            }
            addImageBlock={img =>
              addImageBlock(img, createState, setCreateState)
            }
            onPublish={onPublish}
            isPublishBtnDisabled={isPublishBtnDisabled}
          />
        </CreatePageWrapper>
      </Layout>
    </ProtectedRoute>
  );
};

export default CreateBuild;
