import { styled } from '@root/stitches.config';

export const CommunityWrapper = styled('div', {
  color: '$mainTextColor',
  display: 'flex',
  margin: '0 auto',
  padding: '$24 $16 $40',
  width: '100%',
  maxWidth: '$container',
  flexDirection: 'column',
  overflowX: 'hidden',

  '@bp2': {
    padding: '$48 $32',
    flexDirection: 'row',
  },

  '@bp4': {
    padding: '$48 0px $84 0px',
  },

  '@bp6': {
    padding: '$48 0px $64 0px',
  },
});
export const CommunityFeedWrapper = styled('div', {
  margin: '0 auto',
  maxWidth: '640px',
});

export const CenterContent = styled('div', {
  width: '100%',
  order: '2',

  '@bp2': {
    mx: '$48',
  },

  '@bp4': {
    mx: '$84',
  },
  variants: {
    isNotShowing: {
      true: {
        display: 'none',

        '@bp2': {
          display: 'block',
        },
      },
    },
  },
});

export const RightSideBarContainer = styled('div', {
  order: '1',

  '@bp2': {
    order: '3',
  },
});
