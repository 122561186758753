import {
  InputTextErrorMsg,
  InputTextHeader,
  InputTextLabel,
  SvgHelp,
  InputTextStyled,
  InputTextWrapper,
  InputWrapper,
  LinkStyled,
  LinkWrapper,
  PrefixLabel,
  RequiredLabel,
} from './InputText.styled';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Svg from '@components/atoms/svg/Svg';
import TooltipBox from '@components/atoms/tooltip/TooltipBox';

export const InputText = ({
  name,
  placeholder,
  value,
  error,
  label,
  required,
  autoComplete,
  autoFocus,
  disabled,
  maxLength,
  inputShape,
  inputTheme,
  forgotPasswordLabel,
  forgotPasswordUrl,
  type,
  isError,
  isRequired,
  onChange,
  css,
  instruction,
  onBlur,
  onFocus,
  onKeyUp,
  touched,
  displayErrorIcon,
  prefixLabel,
  min,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <InputTextWrapper css={css}>
      <InputTextHeader>
        {label ? (
          <InputTextLabel htmlFor={name} isError={isError}>
            {label}
          </InputTextLabel>
        ) : null}

        {instruction && (
          <TooltipBox trigger="click" instruction={instruction}>
            <SvgHelp className="input__error" icon={'circle-help'} />
          </TooltipBox>
        )}
        {isRequired && (
          <LinkWrapper>
            <RequiredLabel size="12" isError={isError}>
              Required
            </RequiredLabel>
          </LinkWrapper>
        )}
        {forgotPasswordUrl && (
          <LinkWrapper>
            <LinkStyled to={forgotPasswordUrl}>
              {forgotPasswordLabel}
            </LinkStyled>
          </LinkWrapper>
        )}
      </InputTextHeader>
      <InputWrapper
        isPrefixLabel={prefixLabel ? 'hasPrefixLabel' : 'noPrefixLabel'}
      >
        {prefixLabel ? <PrefixLabel>{prefixLabel}</PrefixLabel> : null}
        <InputTextStyled
          inputShape={inputShape}
          inputTheme={inputTheme}
          id={name}
          name={name}
          type={passwordShown ? 'text' : type}
          placeholder={placeholder}
          value={value}
          required={required}
          autoComplete={autoComplete ? autoComplete.toString() : 'false'}
          autoFocus={autoFocus}
          disabled={disabled}
          maxLength={maxLength}
          aria-label={label}
          isError={isError}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          min={min}
          isPrefixLabel={prefixLabel ? 'hasPrefixLabel' : 'noPrefixLabel'}
        />
        {type === 'password' && (
          <Svg
            className="input__icon"
            icon={passwordShown ? 'visibility-on' : 'visibility-off'}
            onClick={togglePasswordVisiblity}
            css={{
              fill: '$ultraLightGrey'
            }}
          />
        )}
        {isError && displayErrorIcon && (
          <Svg
            className="input__icon"
            icon={'error'}
            css={{
              fill: '$red',
            }}
          />
        )}
        {touched && (
          <Svg
            className="input__icon"
            icon={'circle-check'}
            css={{
              fill: '$darkGrey',
            }}
          />
        )}
      </InputWrapper>

      {(isError && error) && (
        <InputTextErrorMsg isError={isError}>{error}</InputTextErrorMsg>
      )}
    </InputTextWrapper>
  );
};

InputText.defaultProps = {
  type: 'text',
  placeholder: '',
  inputTheme: 'white',
  inputShape: 'normal',
  isError: false,
  isInstruction: false,
  svgIcon: null,
  displayErrorIcon: true,
};

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  value: PropTypes.any,
  type: PropTypes.string,
  required: PropTypes.bool,
  svgIcon: PropTypes.string,
  autoComplete: PropTypes.bool,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxlength: PropTypes.number,
  inputShape: PropTypes.string.isRequired,
  inputTheme: PropTypes.string.isRequired,
  forgotPasswordUrl: PropTypes.string,
  onChange: PropTypes.func,
  isInstruction: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  displayErrorIcon: PropTypes.bool,
};
