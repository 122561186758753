import {
  MembersAvatars,
  ShowMoreButton,
  StyledAvatar,
} from './ReportedByList.styled';

import Avatar from '@components/atoms/avatar/Avatar';
import React from 'react';

const ReportedByList = props => {
  const maxMembersCount = 2;
  const membersCount = props.members.length;
  return (
    <MembersAvatars>
      {props.members.map((member, i) => {
        if (i >= maxMembersCount) return;
        return (
          <StyledAvatar isSecond={i === 1}>
            <Avatar shape="circle" key={i} {...member.avatar} />
          </StyledAvatar>
        );
      })}

      {membersCount > maxMembersCount ? (
        <ShowMoreButton>+{membersCount - maxMembersCount}</ShowMoreButton>
      ) : null}
    </MembersAvatars>
  );
};

export default ReportedByList;
