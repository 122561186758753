import { styled } from '@root/stitches.config';
import { Heading } from '@components/atoms/heading/Heading';

export const WrapperThumbnail = styled('div', {
  marginTop: '$16',
  marginBottom: '$54',
  display: 'grid',
  gap: '$16',
  gridTemplateColumns: 'repeat(1, 1fr)',
  bp0: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp1': {
    gap: '32px',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

export const WrapperPagination = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '$48',
});

export const WrapperBanner = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '$32',

  '@bp1': {
    marginTop: '$40',
  },
});

export const WrapperLinkList = styled('div', {});

export const StyledHeading = styled(Heading, {
  fontFamily: '$ArtifaktElementBold',
  marginBottom: '$32',
});
