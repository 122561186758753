import { styled, tokens } from '@root/stitches.config';

export const ProfileSidebarCardWrapper = styled('div', {
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: '$mainTextColor',
  margin: '0',
  '@bp2': {
    maxWidth: '304px',
  },
});
export const ProfileSidebarLoading = styled('div', {
  position: 'relative',
  maxWidth: '304px',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  minHeight: '100vh',
});
export const ProfileSidebarSkeleton = styled('div', {
  textAlign: 'center',
});

export const ProfileSidebarCard = styled('div', {
  backgroundColor: '$box',
  borderRadius: '$16',
  padding: '$24',
  width: '100%',
  boxShadow: '$2',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '106px',
  position: 'relative',
  marginBottom: '0',
  '.more-button': {
    transition: 'opacity .4s ease',
  },
  variants: {
    isMobile: {
      true: {
        '.more-button': {
          opacity: 1,
        },
      },
      false: {
        '.more-button': {
          opacity: 0,
        },
      },
    },
  },
  '&:hover': {
    '.more-button': {
      opacity: 1,
    },
  },
  '@bp2': {
    marginBottom: '$48',
  },
});

export const Flag = styled('img', {
  width: '26px',
  overflow: 'hidden',
});

export const SettingButtonWrapper = styled('div', {
  position: 'relative',
  margin: '8px',
  position: 'absolute',
  right: '0',
  top: '0',
  width: '50px',
  height: '50px',
  '&:focus': {
    outline: '0',
  },
});

export const SettingMenuBoxLink = styled('div', {
  fontFamily: 'var(--ArtifaktLegendBold)',
  fontSize: '$14',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  transition: 'opacity .4s ease',
  '&:hover': {
    opacity: '.5',
  },
});

export const SettingMenuBox = styled('div', {
  position: 'absolute',
  backgroundColor: '$box',
  borderRadius: '$8',
  padding: '$16',
  right: 0,
  top: '60px',
  boxShadow: '$2',
  variants: {
    isEditSettingsOpened: {
      isOpened: {
        display: 'block',
      },
      isNotOpened: {
        display: 'none',
      },
    },
  },
});

export const ProfileSidebarBadges = styled('div', {
  variants: {
    isMobile: {
      true: {
        // marginBottom: '$48',
      },
    },
  },
  '@bp2': {
    display: 'block',
  },
});

export const ProfileLocation = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  img: {
    width: '$24',
    // height: '$16',
    marginRight: '$8',
  },
});

export const ProfileInfoWrapper = styled('div', {
  marginBottom: '$24',
  textAlign: 'center',
});

export const ProfileSidebarTags = styled('div', {
  // display: 'none',
  py: '$32',
  '@bp2': {
    display: 'block',
  },
});
