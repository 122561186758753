import { CardWrapper } from './CreateTypeCard.styled';
import { Heading } from '@components/atoms/heading/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { useIsMobile } from '@src/utils/useIsMobile';

function CreateTypeCard({type, onClick}){
  const isMobile = useIsMobile();
  return (
    <CardWrapper onClick={() => onClick(type)} isMobile={isMobile}>
      <Svg icon={type} />
      <div className="create-type-card-content-wrapper">
        <Heading level="h3">
          <String id={`create_intro_${type}_title`} />
        </Heading>
        <Text as="p" size="16">
          <String id={`create_intro_${type}_text`} />
        </Text>
      </div>
      <div className="create-type-card-cta">
        <Svg icon="plus"/>
      </div>
    </CardWrapper>
  )
};

CreateTypeCard.propTypes = {
  type: PropTypes.oneOf(['asset', 'artwork', 'article', 'tutorial', 'tutorial_series']).isRequired,
  onClick: PropTypes.func.isRequired
};

export default CreateTypeCard;