import { styled, keyframes } from '@root/stitches.config';
const rotate = keyframes({
  '0%': { transform: 'rotate3d(0,0,1,0deg)' },
  '100%': { transform: 'rotate3d(0,0,1,3600deg)' },
});

const ringAnimation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const dashAnimation = keyframes({
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: '0',
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-35',
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-124',
  },
});
export const Spinner = styled('img', {
  width: '20px',
  height: '20px',
  animation: `${rotate} linear 7.4s infinite`,
});

export const SpinnerWrapper = styled('div', {
  mx: '$16',
  display: 'flex',
  alignitems: 'center',
  justifyContent: 'center',
  // minHeight: '300px',
});

export const StyledSpinner = styled('svg', {
  '.spinner': {
    animation: `${ringAnimation} 1.5s ease-in-out infinite`,
    width: '100%',
    height: '100%',
  },
  '.spinner_bg': {
    stroke: '$lightGrey',
    strokeLinecap: 'round',
  },

  '.spinner_path': {
    stroke: '$blackToWhite',
    strokeLinecap: 'round',
    animation: `${dashAnimation} 1.5s ease-in-out infinite`,
  },
});
