import { styled } from '@root/stitches.config';

export const CreateDraftWrapper = styled('div', {
  minHeight: '70vh',
  margin: '0 auto',
  padding: '$32 $16 $64 $16',
  '@bp2': {
    padding: '$48 $64 $84 $64',
  },

  '@bp4': {
    maxWidth: '$container',
  },

  h3: {
    fontSize: '$32',
    marginBottom: '$32',
  }
});
