import {
  CreateLevel,
  CreateLevelHeader,
} from '../../sections/CreateInfo.styled';
import { DatePickerWrapper, SectionWrapper } from './Sections.styled';
import React, { forwardRef, useEffect, useState } from 'react';

import DatePicker from '@components/molecules/datePicker/DatePicker';
import { GET_ALL_USERS } from '@src/apollo/query';
import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import { InputTextLabel } from '@components/atoms/input/inputText/InputText.styled';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { useIsMobile } from '@src/utils/useIsMobile';
import { useQuery } from '@apollo/client';

function Authoring(props) {
  const { value, onChange, isMom } = props;
  const isMobile = useIsMobile();

  const [state, setState] = useState(value);
  const [users, setUsers] = useState([]);
  const { data, loading, error } = useQuery(GET_ALL_USERS, {
    context: { clientName: 'cms' },
    variables: {
      input: {
        roles: ['admin', 'editor', 'contributor'],
      },
    },
  });

  useEffect(() => {
    if (data?.users) {
      const usersToVm = data.users.data.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      }));
      setUsers(usersToVm);
    }
  }, [data]);

  useEffect(() => {
    if (value.author && data?.users) {
      setState({
        ...state,
        author: value.author,
      });
    }
  }, [value.author, data]);

  useEffect(() => {
    if (value.publishedDate) {
      setState({
        ...state,
        publishedDate: value.publishedDate,
      });
    }
  }, [value.publishedDate]);

  const { author, publishedDate } = state;

  useEffect(() => {
    onChange('authoring', state);
  }, [author, publishedDate]);

  return (
    <SectionWrapper>
      <CreateLevel>
        <CreateLevelHeader>
          <InputTextLabel>
            <String id="create_admin_author_label" />
          </InputTextLabel>
        </CreateLevelHeader>
        <InputDropdownSearch
          onSelect={e => {
            setState({
              ...state,
              author: e,
            });
          }}
          value={author}
          options={users}
          css={{ marginTop: '$32' }}
          placeholder="Select member"
        />
      </CreateLevel>
      {!isMom && (
        <CreateLevel>
          <DatePickerWrapper>
            <DatePicker
              selected={publishedDate}
              onChange={date =>
                setState({
                  ...state,
                  publishedDate: date,
                })
              }
              selectsStart
              startDate={publishedDate}
              dateFormat="dd/MM/yyyy"
              withPortal={isMobile}
              label="create_admin_published_date_label"
            />
          </DatePickerWrapper>
        </CreateLevel>
      )}
    </SectionWrapper>
  );
}

Authoring.propTypes = {
  value: PropTypes.shape({
    publishedDate: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.date,
    ]),
    author: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Authoring;
