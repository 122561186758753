import { styled } from '@root/stitches.config';
import { Heading } from '@components/atoms/heading/Heading';

export const ContentWrapper = styled('div', {
  display: 'flex',
  margin: 'auto',
  alignItems: 'center',
});

export const BannerWrapper = styled('button', {
  backgroundColor: '$ultraLightGrey',
  borderRadius: '18px',
  color: '$black',
  textDecoration: 'none',
  border: 'none',
  fontFamily: '$ArtifaktLegend',
  textAlign: 'left',
  cursor: 'pointer',
  display: 'flex',
  padding: '$16 $16',
  alignItems: 'center',
  marginBottom: '$24',
  transition: 'box-shadow .4s ease',

  '@bp2': {
    padding: '$32 $32',
  },
  '&:hover': {
    boxShadow: '$1',
  },
});

export const CirclePlus = styled('div', {
  width: '44px',
  height: '44px',
  overflow: 'hidden',
  backgroundColor: '$black',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '$16',
  flexShrink: 0,
  svg: {
    width: '24px',
    height: '24px',
    fill: '$ultraLightGrey',
  },
});

export const TextWrapper = styled('div', {
  h4: {
    lineHeight: '1.2em',
    fontSize: '$18',
    marginBottom: '$4',
    '@bp2': {
      fontSize: '$24',
      marginBottom: '0',
    },
  },
  p: {
    lineHeight: '1.2em',
    '@bp2': {
      lineHeight: '1.4em',
    },
  },
});

export const HeadingStyled = styled(Heading, {
  fontWeight: 800,
});

export const EmptySectionWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  h3: {
    lineHeight: 1,
    fontFamily: '$ArtifaktLegendBold',
    mt: '$32',
    mb: '$54',
    textAlign: 'center',
  },
  a: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});
