import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';

export const CommunityLinkPreviewWrapper = styled('a', {
  display: 'block',
  color: '$blackToWhite',
  textDecoration: 'unset',
  backgroundColor: '$postBoxBg',
  // maxWidth: '640px',
  mt: '$20',
  mx: '$16',
  borderRadius: '$8',
  position: 'relative',

  variants: {
    inModal: {
      true: {
        mx: '0',
        p: {
          color: '$mainTextColor !important',
        },
      },
    },
    isSinglePost: {
      true: {
        maxWidth: 'unset',
      },
    },
  },
});

export const CommunityLinkPreviewContent = styled('div', {
  py: '$16',
  px: '$16',
  overflow: 'hidden',
});

export const ImageWrapper = styled('div', {
  position: 'relative',

  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '55%',
  },

  img: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '$8 $8 0 0',
  },
});

export const StyledText = styled(Text, {
  fontFamily: '$ArtifaktElementBold',
  width: '100%',
  lineHeight: '1.2em',

  '@bp2': {
    // width: '70%',
  },
});

export const ExitBtn = styled(Button, {
  position: 'absolute',
  top: '$8',
  right: '$8',
  width: '$32',
  height: '$32',
});
