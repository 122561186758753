import {
  CourseThumbnailCard,
  StyledButton,
  StyledHeading,
  StyledText,
  ImageWrapper,
} from './CourseThumbnail.styled';

import {Flex} from '@components/atoms/flex/Flex';
import Image from '@components/atoms/image/Image';
import PropTypes from 'prop-types';
import React from 'react';

const CourseThumbnail = props => {
  const {staticImages, title, intro, actions} = props;

  return (
    <CourseThumbnailCard>
      {staticImages.length > 0 && (
        <Flex className="course_thumbnail_icons">
          {staticImages.map(image => (
            <ImageWrapper>
              <Image src={image.src} alt={image.alt}/>
            </ImageWrapper>
          ))}
        </Flex>
      )}
      <StyledHeading as="h3">{title}</StyledHeading>
      {intro && <StyledText as="p">{intro}</StyledText>}
      {actions.length > 0 && (
        <Flex className="course_thumbnail_actions">
          {actions.map(action => (
            <StyledButton
              key={action.label}
              label={action.label}
              internalUrl={action.internalUrl}
              externalUrl={action.externalUrl}
              colorTheme={action.colorTheme}
              onClick={action.onClick}
              actionType={action.actionType}
              svgIcon={action.svgIcon}
              svgLocation={action.svgLocation}
              className={action.colorTheme}
              target={action.target}
            />
          ))}
        </Flex>
      )}
    </CourseThumbnailCard>
  );
};

export default CourseThumbnail;

CourseThumbnail.propTypes = {
  staticImages: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.arrayOf(PropTypes.shape({})),
          ]),
        }).isRequired,
      }),
    }),
  ),
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      colorTheme: PropTypes.string,
      internalUrl: PropTypes.string,
      externalUrl: PropTypes.string,
      actionType: PropTypes.oneOf(['onclick', 'externalLink', 'internalLink']),
      onClick: PropTypes.func,
      svgIcon: PropTypes.string,
      svgLocation: PropTypes.oneOf(['before', 'after']),
    })
  ),
};

CourseThumbnail.defaultProps = {
  staticImages: [],
  intro: '',
  actions: [],
};
