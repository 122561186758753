import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const ModalTitle = styled(Text, {
    color: '$mainTextColor',
    fontFamily: '$ArtifaktLegendBold',
    marginBottom: '$24'
  });
  
  export const ModalSubTitle = styled(Text, {
    color: '$mainTextColor',
    fontFamily: '$ArtifaktLegendBold',
    marginBottom: '$32'
  });
  
  export const ModalContentWrapper = styled('div', {
    marginBottom: '$48',
    '.select-search__input': {
      borderRadius: '$8',
      border: '1px solid $ultraLightGrey',
      boxShadow: 'none',
      backgroundColor: '$mainBackgroundColor',
      color: '$mainTextColor',
      padding: '$16 $24',
      height: '44px',
  
      '&::placeholder': {
        color: '$mainTextColor',
      },
    },
  
    variants: {
      isError: {
        true: {
          '.select-search__input': {
            borderColor: '$red',
          },
        },
      },
  
      isSimpleSelect: {
        true: {
          '.select-search__value': {
            '&:after': {
              display: 'block',
            },
          },
        },
      },
    },
  
    '.select-search--multiple .select-search__input': {
      borderRadius: '3px 3px 0 0',
      border: '1px solid $lightGrey',
  
    },
  
    '.select-search--multiple .select-search__select': {
      borderRadius:  '0 0 3px 3px',
      border: '1px solid $lightGrey',
      
    },

    '.select-search__row:not(:first-child)':{
        borderTop: '1px solid $mediumGrey'   
    },
  
    '.select-search__option': {
      backgroundColor: '$mainBackgroundColor',
      color: '$mainTextColor', 
      background: '$mainBackgroundColor',
  
      '.dark-mode &':{
        '&:hover':{
          color: 'black !important'
        }
      },

      '&:hover':{
        background: '$ultraLightGrey',
      }
     
    },
    '.select-search': {
      width: '100%',
      zIndex: '5',
      '@bp2': {
        width: '100%',
      },
    },
  
    '.select-search__value': {
      '&:after': {
        display: 'none',
      },
    },
  
    '.select-search--multiple .select-search__select': {
      borderTop: 'red !important',
      boxShadow: 'none',
    },
    '.select-search--multiple': {
      border: '1px solid $elements',
      boxShadow: 'none',
    },
  
    '.select-search:not(.select-search--multiple) .select-search__select': {
      borderRadius: '$8 $8 $8 $8 !important',
    },
  
    '.select-search:not(.select-search--multiple) .select-search__input:hover': {
      borderColor: '$darkGrey',
    },
  
    '.select-search__option.is-highlighted': {
      background: '$ultraLightGrey',
    },
  
    '.select-search__option:not(.is-selected):hover': {
      background: '$ultraLightGrey',

    },
  
    '.select-search__group-header': {
      background: '$ultraLightGrey',
      fontSize: '$14',
    },
  
    '.select-search__option.is-selected': {
      background: '$lightGrey',

      '&:hover':{
        background: '$ultraLightGrey',
      }
    },

    '.select-search__select': {
      boxShadow: '0px 1px 4px var(--colors-lightGrey)',
    },
  });
  
