import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';

export const StyledH1 = styled(Text, {
  fontSize: '$48',
  '@bp1': { fontSize: '$84' },
  lineHeight: '$2',

  variants: {
    isArticles: {
      true: {
        //color: '$whiteToBlack',
        WebkitTextStroke: '1px var(--colors-mediumGrey)',
        WebkitTextFillColor: 'transparent',
        //textShadow: '0 0 3px var(--colors-mainTextColor)',
        //border: '1px solid #9F9F9F',
        '@bp3': {
          fontSize: '$152',
          lineHeight: '130px',
        },
      },
    },
  },
});

export const StyledText = styled(Text, {
  color: '$mainTextColor',
  fontSize: '$14',
  marginTop: '$8',

  '@bp3': {
    fontSize: '$20',
    width: '70%',
  },
});

export const GroupListingHeader = styled('div', {
  width: '100%',
  marginBottom: '$32',
  div: {
    marginTop: '$12',

    '@bp1': {
      marginTop: '$32',
    },
  },
  '@bp1': {
    width: '75%',
  },
});
