import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import { Heading } from '@components/atoms/heading/Heading';
import React from 'react';
import SEO from '@components/atoms/seo/Seo';

const ContentCompanies = (context) => {
  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        Companies
      </Heading>
    </AdminLayout>
  );
};

export default ContentCompanies;
