import { styled } from '@root/stitches.config';

export const ModalEditorWrapper = styled('div', {
  width: '100%',
  maxWidth: '456px',
  minWidth: '300px',
  marginBottom: '$54',
  wordBreak: 'break-all',

  ol: {
    listStylePosition: 'inside',
    listStyleType: 'decimal',
  },
  ul: {
    listStylePosition: 'inside',
    listStyleType: 'disc',
  },
  '.ql-editor': {
    overflowY: 'visible',
  },

  '.quill': {
    border: '1px solid $lightGrey',
    borderRadius: '$8',
    padding: '$12 $16',
    minHeight: '240px',
  },
});

export const CharsCount = styled('p', {
  fontSize: '10px',
  color: '$lightGrey',
  textAlign: 'right',
});

export const ModalDescription = styled('div', {
  fontSize: '$14',
  marginBottom: '$24',
  maxWidth: '456px',
});

export const ModalControlsWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto auto',

  'div:nth-child(2)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const DatePickerWrapper = styled('div', {
  marginBottom: '$32',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '$16',
  // alignItems: 'center',
});
