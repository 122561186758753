import { styled } from '@root/stitches.config';
import { Heading } from '@components/atoms/heading/Heading';

export const LearnTutorialsListing = styled('div', {
  display: 'grid',
  gap: '$16',
  marginTop: '$16',
  gridTemplateColumns: 'repeat(1, 1fr)',

  '@bp1': {
    gap: '$32',
    marginBottom: '$54',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

export const PaginationWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const LinkListWrapper = styled('div', {
  marginTop: '$48',
});

export const StyledHeading = styled(Heading, {
  fontFamily: '$ArtifaktElementBold',
  marginBottom: '$32',
});
