import { CREATE_TAG, EDIT_TAG } from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import Button from '@components/atoms/button/Button';
import ContentDataEdit from '@components/molecules/contentDataEdit/ContentDataEdit';
import EditButton from '@components/organisms/table/components/columnItem/EditButton';
import { GET_TAGS } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import Modal from '@components/atoms/modal/Modal';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { TagsHeader } from './ContentTags.styled';
import { Text } from '@components/atoms/text/Text';
import useModal from '@components/atoms/modal/useModal';

const ContentTags = (context) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: isShowingEdit, toggle: toggleEdit } = useModal();

  const [tagsContent, setTagsContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    columns: [
      {
        header: 'Tag name',
        accessor: 'tagName',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const [tagsList, setTagsList] = useState([]);

  const [currentTag, setCurrentTag] = useState({
    id: '',
    isEdit: false,
    fields: [
      {
        type: 'text',
        label: <String id="admin_content_tags_input_text_label" />,
        value: '',
        name: 'tagName',
      },
    ],
  });

  const tagData = useQuery(GET_TAGS, {
    fetchPolicy: 'no-cache',
  });

  const [editTag, editTagData] = useMutation(EDIT_TAG, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      tagData.refetch();
    },
  });

  const [createTag, createTagData] = useMutation(CREATE_TAG, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      tagData.refetch();
    },
  });

  const onAddNewTag = () => {
    setCurrentTag({
      id: '',
      isEdit: false,
      fields: [
        {
          type: 'text',
          label: <String id="admin_content_tags_input_text_label" />,
          value: '',
          name: 'tagName',
        },
      ],
    });
    toggleEdit();
  };

  const onEdit = id => {
    const updatedCurrentTag = { ...currentTag };

    tagData.data.tags.forEach(tag => {
      if (tag.id == id) {
        updatedCurrentTag.fields.forEach(field => {
          field.value = tag.name;
        });
      }
    });

    setCurrentTag({
      id,
      isEdit: true,
      fields: updatedCurrentTag.fields,
    });

    toggleEdit();
  };

  const onSaveAdd = (id, fieldsData) => {
    createTag({
      variables: {
        tagInput: { name: fieldsData[0].value },
      },
    });
    toggleEdit();
  };

  const onSaveEdit = (id, fieldsData) => {
    editTag({
      variables: {
        tagId: id,
        tagInput: { name: fieldsData[0].value },
      },
    });
    toggleEdit();
  };

  useEffect(() => {
    if (tagData.data) {
      const rowDatas = [];

      tagData.data.tags.forEach(tag => {
        const singleRow = {
          tagName: <Text>{tag.name}</Text>,
          options: <EditButton action={onEdit} id={tag.id} />,
          id: tag.id,
        };
        rowDatas.push(singleRow);
      });

      setTagsContent({
        ...tagsContent,
        isLoaded: true,
        currentCount: `1-${tagData.data.tags.length}`,
        totalCount: tagData.data.tags.length,
      });

      setTagsList(rowDatas);
    }
  }, [tagData.data]);

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <TagsHeader>
        <Heading level="h3" css={{ lineHeight: '1em' }}>
          <String id="admin_content_tags_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={onAddNewTag}
          label={<String id="admin_content_tags_add_tags_label" />}
          type="normal"
          css={{ marginLeft: '$16' }}
        />
      </TagsHeader>

      <Table
        nextPage={() => {}}
        prevPage={() => {}}
        changeRowPerPage={9999}
        totalCount={tagsContent.totalCount}
        currentCount={tagsContent.currentCount}
        loading={
          tagData.loading || editTagData.loading || createTagData.loading
        }
        rowData={tagsList}
        columns={tagsContent.columns}
        maxPage={tagsContent.maxPage}
        currentPage={tagsContent.page}
        hasOptions
        isNotMultiSelect
        hasNoPagination
       />
      <br />

      <Modal isShowing={isShowingEdit}>
        {currentTag.isEdit ? (
          <Heading level="h3" css={{ marginBottom: '$16' }}>
            <String id="admin_content_tags_modal_edit_title" />
          </Heading>
        ) : (
          <Heading level="h3" css={{ marginBottom: '$16' }}>
            <String id="admin_content_tags_modal_add_title" />
          </Heading>
        )}

        <ContentDataEdit
          {...currentTag}
          toggleEdit={toggleEdit}
          onSave={currentTag.isEdit ? onSaveEdit : onSaveAdd}
         />
      </Modal>
    </AdminLayout>
  );
};

export default ContentTags;
