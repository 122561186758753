import { styled } from '@root/stitches.config';

export const InputDropdownSearchWrapper = styled('div', {
  '.select-search__input, .Dropdown-control': {
    borderRadius: '$8',
    border: '1px solid $lightGrey',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: '$mainTextColor',
    padding: '$8 $24',
    height: '44px',

    '.Dropdown-arrow': {
      top: ' 20px',
    },

    '&::placeholder': {
      color: '$mainTextColor',
    },
  },

  variants: {
    isError: {
      true: {
        '.select-search__input': {
          borderColor: '$red',
        },
      },
    },

    isSimpleSelect: {
      true: {
        '.select-search__value': {
          '&:after': {
            display: 'block',
          },
        },
      },
    },
  },

  '.select-search__option, .Dropdown-option': {
    backgroundColor: '$box',
    color: '$mainTextColor',
  },
  '.select-search': {
    width: '100%',
    // zIndex: '5',
    '@bp2': {
      width: '100%',
    },
  },

  '.select-search__value': {
    '&:after': {
      display: 'none',
    },
  },

  '.select-search--multiple .select-search__select': {
    zIndex: '999',
    border: '1px solid $elements',
    boxShadow: 'none',
  },
  '.select-search--multiple': {
    border: '1px solid $eleents',
    boxShadow: 'none',
  },

  '.select-search:not(.select-search--multiple) .select-search__select': {
    borderRadius: '$8 $8 $8 $8 !important',
  },

  '.select-search:not(.select-search--multiple) .select-search__input:hover': {
    borderColor: '$mediumGrey',
  },

  '.select-search__option.is-highlighted': {
    background: '$mediumGrey',
  },

  '.select-search__option:not(.is-selected):hover, .Dropdown-option:hover': {
    background: '$elements',
    color: '$mainTextColor',
  },

  '.select-search__group-header': {
    background: '$mediumGrey',
    fontSize: '$14',
  },

  '.select-search__option.is-selected': {
    background: '$lightGrey',
  },
});
