import {
  DropdownActionsWrapper,
  SelectAllLabel,
  TableActionsDropdownWrapper,
  TableActionsWrapper,
} from './TableActions.styled';

import DropdownActions from './DropdownActions';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import React from 'react';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';

const TableActions = props => {
  return (
    <TableActionsWrapper>
      {props.selectedRows?.length == 0 ? (
        <>
          <InputCheckBox
            onChange={props.selectAllRows}
            checked={false}
          ></InputCheckBox>
          <SelectAllLabel onClick={props.selectAllRows}>
            <String id="community_admin_choose_all" />
          </SelectAllLabel>
        </>
      ) : (
        <TableActionsDropdownWrapper>
          <Svg icon="checkbox-cancel" onClick={props.deselectAllRows}></Svg>
          <DropdownActions
            selectedRows={props.selectedRows}
            bulkActions={props.bulkActions}
          />
        </TableActionsDropdownWrapper>
      )}
    </TableActionsWrapper>
  );
};

export default TableActions;
