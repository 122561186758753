import { BlockImageWrapper, ImageWrapper } from './BlockImage.styled';
import React, { useState } from 'react';

import { EditorLoadingWrapper } from '../EditorBlock.styled';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import { debounce } from 'throttle-debounce';
import CharsCount from '@components/atoms/charsCount/CharsCount';
import { InputText } from '@components/atoms/input/inputText/InputText';

const debouncefunc = debounce(1000, false, (func, blockIndex, value) => {
  func(blockIndex, value);
});

const BlockImage = props => {
  const { block, blockIndex } = props;

  const [captionState, setCaptionState] = useState(
    block.text ? block.text : ''
    );

  const captionMaxLength = 300;
    
  const onChange = e => {
    if(e.target.value.length < captionMaxLength){
      setCaptionState(e.target.value);
      debouncefunc(props.onEditBlock, props.blockIndex, e.target.value);
    } else {
      setCaptionState(e.target.value.slice(0, captionMaxLength));
      debouncefunc(props.onEditBlock, props.blockIndex, e.target.value.slice(0, captionMaxLength));
    }
  };

  const isNewImage = props.block?.data?.data_url ? true : false;
  const imgSrc = isNewImage
    ? props.block?.data?.data_url
    : `${CDN_URL}${props.block?.image?.sizes[0]?.path}`;

  return (
    <BlockImageWrapper>
      {block.isLoading && (
        <EditorLoadingWrapper>
          {/* <Text as="p" size="12">
            <String id="create_block_loading_text" />
          </Text> */}
          <LoaderSpinner />
        </EditorLoadingWrapper>
      )}
      <ImageWrapper>
        <img src={imgSrc} alt={`block-${props.blockIndex}`} />
      </ImageWrapper>
      <InputText 
        defaultValue={captionState}
        value={captionState}
        onChange={onChange}
        placeholder="Add a caption ..."
        css={{
          input: {
            background: 'transparent',
            border: 'none',
            outline: 'none',
            fontSize: '$14',
            fontStyle: 'italic',
            textAlign: 'center',
            padding: '0'
          }
        }}
      />
      <CharsCount
        stringToCount={captionState}
        maxLength={captionMaxLength}
        isQuill={false}
      />
    </BlockImageWrapper>
  );
};

export default BlockImage;

BlockImage.defaultProps = {
  block: {
    text: '',
  },
};
