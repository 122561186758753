import React, { createContext, useState } from 'react';

export const NotificationsContext = createContext({
  notificationsCount: 0,
  setNotificationsState: () => {},
});

const NotificationsProvider = props => {
  const [notificationsState, setNotificationsState] = useState({
    notificationsCount: 0,
    firebaseToken: null,
  });
  const value = { notificationsState, setNotificationsState };

  return (
    <NotificationsContext.Provider value={value}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
