import React, { useState } from 'react';
import {
  TableRowPerPageDropdown,
  TableRowPerPageDropdownContainer,
  TableRowPerPageDropdownCurrentValue,
  TableRowPerPageDropdownValue,
  TableRowPerPageLabel,
  TableRowPerPageWrapper,
} from './TableRowPerPage.styled';

import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';

const TableRowPerPage = props => {
  const [tableRowPerPage, setTableRowPerPage] = useState({
    isOpened: false,
    currentLimitRow: 12,
  });

  const onToggleDropdown = () => {
    setTableRowPerPage({
      ...tableRowPerPage,
      isOpened: !tableRowPerPage.isOpened,
    });
  };

  const onChangeRowCount = rowCount => {
    props.onChangeRowPerPage(rowCount);
    setTableRowPerPage({
      ...tableRowPerPage,
      isOpened: false,
      currentLimitRow: rowCount,
    });
  };

  return (
    <TableRowPerPageWrapper>
      <TableRowPerPageLabel>
        <String id="community_admin_pagination_row_per_page" />
      </TableRowPerPageLabel>
      <TableRowPerPageDropdown>
        <TableRowPerPageDropdownCurrentValue onClick={onToggleDropdown}>
          <span>{tableRowPerPage.currentLimitRow}</span>
          <Svg icon="arrow-down-short"></Svg>
        </TableRowPerPageDropdownCurrentValue>
        {tableRowPerPage.isOpened ? (
          <TableRowPerPageDropdownContainer>
            <TableRowPerPageDropdownValue
              onClick={onChangeRowCount.bind(null, 12)}
            >
              12
            </TableRowPerPageDropdownValue>
            <TableRowPerPageDropdownValue
              onClick={onChangeRowCount.bind(null, 16)}
            >
              16
            </TableRowPerPageDropdownValue>
            <TableRowPerPageDropdownValue
              onClick={onChangeRowCount.bind(null, 32)}
            >
              32
            </TableRowPerPageDropdownValue>
            <TableRowPerPageDropdownValue
              onClick={onChangeRowCount.bind(null, 64)}
            >
              64
            </TableRowPerPageDropdownValue>
          </TableRowPerPageDropdownContainer>
        ) : null}
      </TableRowPerPageDropdown>
    </TableRowPerPageWrapper>
  );
};

export default TableRowPerPage;
