import { styled } from '@root/stitches.config';

export const FileUploadWrapper = styled('div', {
  'input[type="file"]': {
    display: 'none'
  },
  label: {
    padding: '$12 $20',
    background: '$mainTextColor',
    color: '$bg',
    br: 8,
    display: 'flex',
    width: 'fit-content',
    textTransform: 'uppercase',
    fontFamily: '$ArtifaktLegendBold',
    fontSize: '$16',
    cursor: 'pointer',
    svg: {
      fill: '$bg',
      width: '$24',
      height: '$24',
      alignSelf: 'center',
      marginRight: '$8'
    }
  },
  '.file-input-info': {
    fontSize: '$12',
    mt: '$8',
    color: '$darkGrey',
    fontFamily: '$ArtifaktLegendBold'
  },
  '.file-input-error': {
    color: '$error'
  },
})

export const FileInfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.file-details': {
    fontSize: '$14',
    marginTop: '$16',
    display: 'flex',
    color: '$darkGrey',
    fontFamily: '$ArtifaktLegendBold',
    'p:not(:first-child)': {
      marginLeft: '$16'
    }
  }
});