import {
  AddRuleModalBtnWrapper,
  AddRuleModalContentWrapper,
  AddRuleModalMandatory,
  AddRuleModalSubTitle,
  AddRuleModalTitle,
  AddRuleModalWrapper,
  EditorWrapper,
  InputLabels,
} from './AddRuleModal.styled';
import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import Editor from 'react-medium-editor';
import { InputText } from '@components/atoms/input/inputText/InputText';

require('medium-editor/dist/css/medium-editor.css');
require('../../../styles/content_editor.css');

const AddRuleModal = props => {
  const [ruleState, setRuleState] = useState({
    title: '',
    description: false,
    doOrDont: props.doOrDont,
  });

  const onTitleChange = e => {
    setRuleState({
      ...ruleState,
      title: e.target.value,
    });
  };

  const onDescriptionChange = e => {
    setRuleState({
      ...ruleState,
      description: e,
    });
  };

  const onApplyClick = () => {
    props.hide();
    props.onApplyClick(
      ruleState.doOrDont,
      ruleState.title,
      ruleState.description
    );
  };
  return (
    <AddRuleModalWrapper>
      <AddRuleModalTitle size="32">Add a group rules</AddRuleModalTitle>
      <AddRuleModalSubTitle size="14">
        Lorem ipsum dolor sit amet consectetur
      </AddRuleModalSubTitle>
      <AddRuleModalContentWrapper>
        <InputLabels size="14">Title*</InputLabels>
        <InputText
          name="rule_title"
          placeholder=""
          maxLength={45}
          onChange={onTitleChange}
        ></InputText>
        <InputLabels size="14">Description</InputLabels>
        <EditorWrapper>
          <Editor
            text=""
            onChange={onDescriptionChange}
            options={{
              placeholder: {
                text: '',
              },
              toolbar: false,
              imageDragging: false,
            }}
          />
        </EditorWrapper>
        <AddRuleModalMandatory size="12">* Mandatory</AddRuleModalMandatory>
      </AddRuleModalContentWrapper>
      <AddRuleModalBtnWrapper>
        <Button
          type="normal"
          actionType="onclick"
          onClick={props.hide}
          label="cancel"
          colorTheme="gray"
        />
        <Button
          type="normal"
          actionType="onclick"
          onClick={onApplyClick}
          label="apply"
          colorTheme="normal"
          css={{ marginLeft: '$16' }}
          isDisabled={ruleState.title.length === 0}
        />
      </AddRuleModalBtnWrapper>
    </AddRuleModalWrapper>
  );
};
export default AddRuleModal;
