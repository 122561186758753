import {
  JoinBtnWrapper,
  OnboardingButtons,
  PublishArtworkImageWrapper,
  PublishNotificationContentContainer,
  PublishNotificationContentWrapper,
  PublishNotificationWrapper,
  StyledImage,
  StyledSvg,
  StyledTextBig,
  StyledTextSmall,
} from './PublishNotification.styled';
import React, { useContext } from 'react';

import Button from '@components/atoms/button/Button';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import UrlsContext from '@context/UrlsContext';
import { useMutation } from '@apollo/client';
import { JOIN_GROUPS } from '@src/apollo/mutation';
import { GET_MY_GROUPS } from '@src/apollo/query';

export const PublishNotification = props => {
  const joinNowText = <String id="general_join_now" />;
  const signInText = <String id="general_sign_in" />;
  const UrlContext = useContext(UrlsContext);

  const [joinGroups, { loading, error, data }] = useMutation(JOIN_GROUPS, {
    refetchQueries: [{ query: GET_MY_GROUPS }],
    onCompleted: () => {
      props.onClose();
      console.log(data);
    },
  });

  const onJoinGroup = () => {
    let newArray = [];
    newArray = props.myGroupsId;
    newArray.push(props.groupId);
    joinGroups({ variables: { groups: newArray } });
  };

  const copyText = () => {
    navigator.clipboard.writeText(props.linkText);
  };
  return (
    <>
      {props.type !== 'artwork' &&
      props.type !== 'join' &&
      props.type !== 'validation' &&
      props.type !== 'onboarding' &&
      props.type !== 'completeOnboarding' &&
      props.type !== 'joinGroup' &&
      props.type !== 'blocked' ? (
        <StyledSvg icon="submission-sun" />
      ) : (
        <StyledImage {...props.image} />
      )}
      <PublishNotificationWrapper
        artwork={
          props.type === 'artwork' ||
          props.type === 'join' ||
          props.type === 'joinGroup' ||
          props.type === 'validation' ||
          props.type === 'onboarding' ||
          props.type === 'completeOnboarding' ||
          props.type === 'blocked'
        }
      >
        <PublishNotificationContentContainer
          artwork={
            props.type === 'artwork' ||
            props.type === 'join' ||
            props.type === 'joinGroup' ||
            props.type === 'validation' ||
            props.type === 'onboarding' ||
            props.type === 'completeOnboarding' ||
            props.type === 'blocked'
          }
        >
          <PublishNotificationContentWrapper
            artwork={
              props.type === 'artwork' ||
              props.type === 'join' ||
              props.type === 'joinGroup' ||
              props.type === 'validation' ||
              props.type === 'onboarding' ||
              props.type === 'completeOnboarding' ||
              props.type === 'blocked'
            }
          >
            <StyledTextBig size="48">
              {props.type === 'artwork' ? (
                <String id="create_artwork_publish_notification_title" />
              ) : null}
              {props.type === 'blocked' ? 'Sorry...' : null}
              {props.type === 'onboarding' ||
              props.type === 'completeOnboarding' ? (
                <String id="profile_message_incomplete_title" />
              ) : null}
              {props.type === 'join' ? (
                <String id="general_join_community" />
              ) : null}
              {props.type !== 'artwork' &&
              props.type !== 'join' &&
              props.type !== 'validation' &&
              props.type !== 'onboarding' &&
              props.type !== 'completeOnboarding' &&
              props.type !== 'joinGroup' &&
              props.type !== 'blocked' ? (
                <String id="publish_artwork_notification_title" />
              ) : null}
              {props.type === 'validation' ? (
                <String id="sign_up_validation_title" />
              ) : null}

              {props.type === 'joinGroup' ? <>Join {props.groupName}</> : null}
            </StyledTextBig>
            <StyledTextSmall size="14">
              {props.type === 'blocked' ? (
                <String id={`client_notification_blocked_user_title`} />
              ) : null}
              {props.type === 'artwork' ? (
                <String id="create_artwork_publish_notification_text" />
              ) : null}
              {props.type === 'onboarding' ||
              props.type === 'completeOnboarding' ? (
                <String id="profile_message_info_description" />
              ) : null}
              {props.type === 'join' ? (
                <String id="join_community_notification_text" />
              ) : null}
              {props.type !== 'artwork' &&
              props.type !== 'join' &&
              props.type !== 'validation' &&
              props.type !== 'onboarding' &&
              props.type !== 'completeOnboarding' &&
              props.type !== 'joinGroup' &&
              props.type !== 'blocked' ? (
                <String id="publish_artwork_notification_text" />
              ) : null}
              {props.type === 'joinGroup' ? (
                <>To interact with this post join the {props.groupName} group</>
              ) : null}
              {props.type === 'validation' ? (
                <String id="sign_up_validation_text" />
              ) : null}
            </StyledTextSmall>
            {props.type === 'artwork' && (
              <Button
                actionType="onclick"
                colorTheme="gray"
                css={{
                  height: '$48',
                }}
                svgIcon="copy"
                type="normal"
                label={props.copyLink}
                onClick={copyText}
              />
            )}

            {props.type === 'validation' && (
              <Button
                actionType="internalLink"
                colorTheme="gray"
                css={{
                  height: '$48',
                }}
                onClick=""
                type="normal"
                label={<String id="sign_up_validation_phone_button" />}
                internalUrl={'/verify-by-phone'}
              />
            )}

            {props.type === 'completeOnboarding' && (
              <OnboardingButtons>
                <Button
                  actionType="internalLink"
                  colorTheme="transparent"
                  type="normal"
                  label={'Yes, skip the onboarding'}
                  internalUrl={props.url}
                />
                <Button
                  actionType="onclick"
                  colorTheme="normal"
                  type="normal"
                  label="Continue with the onboarding"
                  onClick={props.onClose}
                />
              </OnboardingButtons>
            )}

            {props.type === 'onboarding' && (
              <Button
                actionType="internalLink"
                colorTheme="gray"
                css={{
                  height: '$48',
                }}
                onClick=""
                type="normal"
                label={<String id="complete_profile_text" />}
                internalUrl={props.url}
              />
            )}

            {props.type === 'join' && (
              <JoinBtnWrapper>
                <Button
                  actionType="internalLink"
                  internalUrl={GetUrl('join', UrlContext)}
                  colorTheme="normal"
                  css={{
                    height: '$48',
                  }}
                  type="normal"
                  label={joinNowText}
                  onClick={copyText}
                />
                <Button
                  actionType="internalLink"
                  internalUrl={GetUrl('login', UrlContext)}
                  colorTheme="gray"
                  css={{
                    height: '$48',
                    marginLeft: '$16',
                  }}
                  type="normal"
                  label={signInText}
                  onClick={copyText}
                />
              </JoinBtnWrapper>
            )}
            {props.type === 'joinGroup' && props.groupName ? (
              <JoinBtnWrapper>
                <Button
                  actionType="onclick"
                  colorTheme="normal"
                  css={{
                    height: '$48',
                  }}
                  type="normal"
                  label={joinNowText}
                  onClick={onJoinGroup}
                  isLoading={loading}
                  isDisabled={loading}
                />
              </JoinBtnWrapper>
            ) : null}
          </PublishNotificationContentWrapper>
        </PublishNotificationContentContainer>
      </PublishNotificationWrapper>
    </>
  );
};

PublishNotification.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  copyLink: PropTypes.string,
  linkText: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.object,
};

PublishNotification.defaultProps = {
  image: {
    src: {
      src: '/images/join_area_v2.jpeg',
      srcset: `/images/join_area_v2.jpeg 1x,/images/join_area_v2.jpeg 250w,/images/join_area_v2.jpeg 500w`,
    },
    alt: 'join area',
  },
};
export default PublishNotification;
