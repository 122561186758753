import {
  CreateWarningWrapper,
  WarningCol,
  WarningContent,
  WarningFooter,
  WarningFooterWrapper,
  Wysiwyg,
} from './CreateWarning.styled';
import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const CreateWarning = props => {
  const { gotoNextSection, type } = props;

  const [disabledNextButton, setDisabledNextButton] = useState(true);

  const bulletPoints = bulletName => {
    switch (type) {
      case type:
        return <String id={`create_warning_${bulletName}_${type}_text`} />;

      default:
        return null;
    }
  };

  const checkbox = () => {
    return (
      <InputCheckBox
        css={{ marginLeft: '$32' }}
        checked={false}
        onChange={e => setDisabledNextButton(!e.target.checked)}
      >
        <String id="create_warning_rules_agreement_label" />
      </InputCheckBox>
    );
  };

  return (
    <CreateWarningWrapper>
      <Heading
        level="h3"
        css={{
          marginBottom: '$8',
          fontSize: '$32',
          lineHeight: '1.2em',
          fontFamily: 'ArtifaktLegendBold',
        }}
      >
        <String id="create_warning_title" />
      </Heading>
      <Text>
        <String id="create_warning_text" />
      </Text>
      <WarningContent>
        <WarningCol>
          <Heading
            level="h2"
            css={{
              lineHeight: '1em',
              marginBottom: '$16',
              fontFamily: 'ArtifaktLegendBold',
            }}
          >
            <String id="create_warning_dos_title" />
          </Heading>
          <Wysiwyg>{bulletPoints('dos')}</Wysiwyg>
        </WarningCol>
        <WarningCol>
          <Heading
            level="h2"
            css={{
              lineHeight: '1em',
              marginBottom: '$16',
              fontFamily: 'ArtifaktLegendBold',
            }}
          >
            <String id="create_warning_donts_title" />
          </Heading>
          <Wysiwyg>{bulletPoints('donts')}</Wysiwyg>
        </WarningCol>
      </WarningContent>
      <WarningFooter>
        <WarningFooterWrapper>
          {checkbox()}
          <Button
            type="normal"
            label={<String id="create_warning_button_label" />}
            colorTheme="normal"
            actionType="onclick"
            css={{marginLeft: '$32'}}
            onClick={gotoNextSection}
            isDisabled={disabledNextButton}
          />
        </WarningFooterWrapper>
      </WarningFooter>
    </CreateWarningWrapper>
  );
};

CreateWarning.propTypes = {
  gotoPrevSection: PropTypes.func.isRequired,
  gotoNextSection: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['article', 'tutorial', 'artwork', 'tutorial_series']).isRequired,
};

export default CreateWarning;
