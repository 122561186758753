import {
  ButtonActionStyled,
  ButtonLinkExternalStyled,
  ButtonLinkStyled,
  ButtonNotificationBubble,
  CounterAndSVGWrapper,
} from './Button.styled';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import PropTypes from 'prop-types';
import React from 'react';
import Svg from '@components/atoms/svg/Svg';

const Button = props => {
  let ButtonStyled;
  const buttonProps = {
    title: props.title,
    colortheme: props.colorTheme,
    css: { ...props.css },
    type: props.type,
    circleSize: props.circleSize,
    children: props.children,
    className: props.className,
    value: props.value,
  };

  switch (props.actionType) {
    case 'internalLink':
      ButtonStyled = ButtonLinkStyled;
      buttonProps.to = props.internalUrl;
      buttonProps.target = props.target;
      break;
    case 'externalLink':
      ButtonStyled = ButtonLinkExternalStyled;
      buttonProps.href = props.externalUrl;
      buttonProps.target = props.target;
      buttonProps.rel = 'noopener noreferrer';

      break;
    case 'onclick':
      ButtonStyled = ButtonActionStyled;
      buttonProps.onClick = props.onClick;
      break;
    default:
      break;
  }

  const realCount = typeof props?.counter === "string" ? parseInt(props.counter.replace('+', ''), 10) : props.counter;

  return (
    <ButtonStyled
      {...buttonProps}
      key={props.title}
      isDisabled={props.isDisabled ? 'isDisabled' : 'isNotDisabled'}
      isSvgIconWithLabel={!!(props.svgIcon && props.label)}
      hasChildren={props.children ? 'hasChildren' : 'hasNoChildren'}
      download={props.download ? props.download : null}
      isForDropDown={props.isForDropDown}
    >
      {((props.isCounter && props.isCounter && realCount > 0) ||
        (props.svgIcon && props.svgLocation === 'before')) && (
        <CounterAndSVGWrapper>
          {props.svgIcon && props.svgLocation == 'before' && (
            <Svg
              css={{
                marginLeft: '0',
                marginRight: props.type == 'normal' ? '$8' : '0',
                height: '$16',
                width: '$16',
              }}
              icon={props.svgIcon}
            />
          )}
          {props.isCounter && realCount > 0 && (
            <span className="btn-counter">{props.counter}</span>
          )}
        </CounterAndSVGWrapper>
      )}
      {(props.type === 'normal' && props.label && props.label  !== "") && (
        <span className="btn-label">{props.label}</span>
      )}
      {props.svgIcon && props.svgLocation == 'after' && (
        <Svg
          css={{
            marginRight: '0',
            marginLeft: props.type == 'normal' ? '$8' : '0',
            height: '$16',
            width: '$16',
            order: 1,
          }}
          icon={props.svgIcon}
        />
      )}
      {props.notificationBubbleActive ? (
        <ButtonNotificationBubble>
          <span>{props.notificationBubbleCount}</span>
        </ButtonNotificationBubble>
      ) : (
        ''
      )}
      {props.children ? props.children : null}
      {props.isLoading && (
        <LoaderSpinner stroke="4" />
      )}
    </ButtonStyled>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  colorTheme: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  notificationBubbleActive: PropTypes.bool,
  notificationBubbleCount: PropTypes.number,
  svgIcon: PropTypes.string,
  internalUrl: PropTypes.string,
  title: PropTypes.string,
  actionType: PropTypes.string,
  externalUrl: PropTypes.string,
  target: PropTypes.string,
  circleSize: PropTypes.string,
  svgLocation: PropTypes.string,
  // className: PropTypes.string,
  isCounter: PropTypes.bool,
  counter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  download: PropTypes.string,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  label: null,
  type: 'normal',
  colorTheme: 'normal',
  onClick: () => {},
  internalUrl: null,
  isDisabled: false,
  notificationBubbleActive: false,
  notificationBubbleCount: 0,
  svgIcon: null,
  externalUrl: null,
  title: null,
  actionType: 'internalLink',
  target: null,
  circleSize: 'normal',
  svgLocation: 'before',
  className: '',
  isCounter: false,
  download: '',
  isLoading: false,
};

export default Button;
