import { styled } from '@root/stitches.config';

export const ErrorMsgBox = styled('div', {
  marginBottom: '$32',
  color: '$white',
  textAlign: 'left',
  padding: '$20 $16',
  border: '2px solid $error',
  backgroundColor: '$error',
  borderRadius: '$16',

  svg: {
    height: '$24',
    width: '$24',
    fill: '$white',
    marginRight: '$8',
  },
});

export const ErrorHeader = styled('div', {
  marginBottom: '$16',
  color: '$white',
  display: 'flex',
  ai: 'center',
});
