import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import Editor from 'react-medium-editor';
import { ModalEditorWrapper } from './AvatarAndBio.styled';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { UPDATE_ABOUT_ME } from '@src/apollo/mutation';

require('medium-editor/dist/css/medium-editor.css');
require('../../../styles/content_editor.css');
require('../../../styles/quill.bubble.css');
import 'react-quill/dist/quill.snow.css';

const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false;

const AboutMedEdit = props => {
  const authContext = useContext(AuthContext);
  window.scrollTo(0, 0);
  const [aboutMeState, setAboutMeState] = useState('');
  const [updateAboutMe, { loading, error, data }] = useMutation(
    UPDATE_ABOUT_ME
  );
  const isBtnDisabled = () => {
    if (
      loading ||
      aboutMeState == '' ||
      aboutMeState == undefined ||
      aboutMeState == emptyPattern
    ) {
      return true;
    } else return false;
  };

  const onChange = text => {
    setAboutMeState(rtrim(text));
  };

  function rtrim(str) {
    if (!str) return str;
    return str.replace(/<p><br[\/]?><[\/]?p>/g, '');
  }

  const onSave = () => {
    updateAboutMe({
      variables: {
        aboutMe: aboutMeState == emptyPattern ? '' : aboutMeState,
      },
    });
  };

  useEffect(() => {
    if (data) {
      props.onSaveBio();
      if (data.updateUserProfile.profile_completion >= 70) {
        authContext.setIsOnboarded();
      }
    }
  }, [data]);

  const emptyPattern = '<p><br></p>';

  return (
    <div>
      <Text
        as="h3"
        css={{
          marginBottom: '$24',
          fontSize: '$16',
          lineHeight: '1em',

          '@bp2': {
            fontSize: '$16',
            marginBottom: '$24',
          },
        }}
      >
        <String id="profile_add_aboutMe_description" />
      </Text>

      <ModalEditorWrapper className="wysiwyg-editor">
        <ReactQuill
          focus
          tabindex="0"
          theme="bubble"
          defaultValue={aboutMeState}
          onChange={onChange}
          modules={Editor.modules}
          formats={Editor.formats}
          bounds=".wysiwyg-editor"
          placeholder={'Write something...'}
        />
      </ModalEditorWrapper>

      <Button
        type="normal"
        label={<String id="save_and_continue_btn" />}
        isDisabled={isBtnDisabled()}
        colorTheme="normal"
        onClick={onSave}
        actionType="onclick"
        isLoading={loading}
        css={{
          width: '100%',
          '@bp2': {
            width: 'auto',
          },
        }}
      />
    </div>
  );
};

export default AboutMedEdit;

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = ['header', 'bold', 'italic', 'list', 'bullet', 'link'];
