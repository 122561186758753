import { gql } from '@apollo/client';

export const JOIN_GROUPS = gql`
  mutation JoinGroups($groups: [Int]!, $user_id: Int) {
    updateUserProfile(user_id: $user_id, groups: $groups) {
      groups {
        name
        id
      }
    }
  }
`;

export const UPDATE_ABOUT_ME = gql`
  mutation UpdateAboutMe($aboutMe: String!, $user_id: Int) {
    updateUserProfile(user_id: $user_id, aboutMe: $aboutMe) {
      aboutMe
      profile_completion
    }
  }
`;
export const UPDATE_RECENT_WORK = gql`
  mutation($projects: [projectInput]) {
    updateUserProfile(projects: $projects) {
      projects {
        id
        url
        title
        job_title
        order
        dateStart
        dateEnd
        location {
          country
          city
          state
          placeId
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_SCHOOLS = gql`
  mutation($schools: [schoolInput]) {
    updateUserProfile(schools: $schools) {
      schools {
        id
        name
        course
        order
        dateStart
        dateEnd
        is_current
        location {
          country
          city
          state
          placeId
        }
      }
    }
  }
`;

export const UPDATE_WORK_EXPERIENCE = gql`
  mutation($companies: [companyInput]) {
    updateUserProfile(companies: $companies) {
      profile_completion
      companies {
        job_title
        order
        dateStart
        dateEnd
        is_current
        location {
          country
          city
          state
          placeId
        }
      }
    }
  }
`;

export const LEAVE_GROUPS = gql`
  mutation LeaveGroups($groups: [Int]!, $user_id: Int) {
    updateUserProfile(user_id: $user_id, groups: $groups) {
      groups {
        name
        id
      }
    }
  }
`;

export const UPDATE_PROFILE_INDUSTRIES = gql`
  mutation UpdateMyIndustry($industries: [Int]!, $user_id: Int) {
    updateUserProfile(user_id: $user_id, industries: $industries) {
      profile_completion
      groups {
        name
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteMyProfile {
    deleteMyProfile
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($email: String!) {
    updateMySettings(email: $email) {
      id
      email
    }
  }
`;

export const UPDATE_USER_CREATE_TC_ACCEPTED = gql`
  mutation UserAcceptCreateTCMutation {
    userAcceptCreateTCMutation
  }
`;

export const UPDATE_USER_USERNAME = gql`
  mutation UpdateUserName($username: String!) {
    updateMySettings(username: $username) {
      id
      username
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($password: String!, $new_password: String!) {
    updateMySettings(password: $password, new_password: $new_password) {
      id
    }
  }
`;

export const UPDATE_PROFILE_TOOLS = gql`
  mutation UpdateMyTool($tools: [Int]!, $user_id: Int) {
    updateUserProfile(user_id: $user_id, tools: $tools) {
      id
      profile_completion
    }
  }
`;

export const UPDATE_PROFILE_TOOLS_ONBOARDING = gql`
  mutation UpdateMyTool($tools: [Int]!, $user_id: Int) {
    updateUserProfile(user_id: $user_id, tools: $tools) {
      profile_completion
      groups {
        name
      }
    }
  }
`;

export const UPDATE_PROFILE_INDUSTRIES_ONBOARDING = gql`
  mutation UpdateMyIndustry($industries: [Int]!, $user_id: Int) {
    updateUserProfile(user_id: $user_id, industries: $industries) {
      profile_completion
      groups {
        name
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUserProfile(
    $user_id: Int
    $title: String
    $gender: String
    $instagram_url: String
    $behance_url: String
    $linkedin_url: String
    $youtube_url: String
    $website_url: String
    $navigation_tab: String
    $first_name: String
    $last_name: String
    $location: locationInput
  ) {
    updateUserProfile(
      user_id: $user_id
      title: $title
      gender: $gender
      first_name: $first_name
      last_name: $last_name
      instagram_url: $instagram_url
      behance_url: $behance_url
      linkedin_url: $linkedin_url
      youtube_url: $youtube_url
      website_url: $website_url
      navigation_tab: $navigation_tab
      location: $location
    ) {
      id
    }
  }
`;

export const REPORT_USER = gql`
  mutation ReportUser($user_id: Int, $type: String) {
    createUserReport(user_id: $user_id, type: $type) {
      status
    }
  }
`;

export const CREATE_USERCONTENT = gql`
  mutation CreateUserContent(
    $input: createUserContentInput
    $admin_input: createUserContentAdminInput
  ) {
    createUserContent(input: $input, admin_input: $admin_input) {
      id
      title
      slug
      userContentType
      status
      blocs {
        id
        slug
        type
        order
        contents {
          id
          order
        }
        asset {
          filename
          size
        }
        text
        videoUrl
        sketchfabUrl
      }
      creator {
        username
      }
      industries {
        name
      }
      tags {
        name
      }
    }
  }
`;

export const UPDATE_USERCONTENT = gql`
  mutation UpdateUserContent(
    $id: Int!
    $input: updateUserContentInput
    $admin_input: updateUserContentAdminInput
  ) {
    updateUserContent(id: $id, input: $input, admin_input: $admin_input) {
      id
      title
      slug
      userContentType
      article_section {
        id
        name
      }
      article_type {
        id
        key
      }
      motm_year
      status
      blocs {
        id
        slug
        type
        order
        text
        videoUrl
        sketchfabUrl
        asset {
          filename
          size
        }
      }
      creator {
        username
      }
      industries {
        name
      }
      tags {
        name
      }
    }
  }
`;

export const REMOVE_USERCONTENT_BLOC = gql`
  mutation DeleteUserContentBloc($ids: [Int]!) {
    deleteUserContentBloc(ids: $ids)
  }
`;

export const DELETE_USERCONTENT = gql`
  mutation DeleteUserContent($ids: [Int]!) {
    deleteUserContent(ids: $ids)
  }
`;

export const CREATE_POST = gql`
  mutation CreatePost(
    $images: [Upload]
    $content: String
    $groupId: Int!
    $link: String
  ) {
    createPost(
      input: {
        content: $content
        group: $groupId
        images: $images
        link: $link
      }
    ) {
      status
      level
      id
      slug
      content
      createdAt
      link_url
      link_description
      link_title
      link_image {
        path
      }
      images {
        path
      }
      author {
        username
        first_name
        last_name
        title
        userProfile {
          profile_picture {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
        }
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment(
    $images: [Upload]
    $content: String
    $parentId: Int!
    $groupId: Int!
    $link: String
  ) {
    createPost(
      input: {
        content: $content
        group: $groupId
        parent: $parentId
        images: $images
        link: $link
      }
    ) {
      status
      level
      id
      slug
      content
      createdAt
      link_url
      link_description
      link_title
      link_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      images {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      parent {
        id
      }
      author {
        username
        first_name
        last_name
        title
        userProfile {
          profile_picture {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
        }
      }
    }
  }
`;

export const GET_LINK_EMBED = gql`
  mutation GetLinkEmbed($link: String!) {
    getLinkEmbed(link: $link) {
      link_url
      link_title
      link_description
      link_image {
        path
      }
    }
  }
`;

export const POST_REACTION = gql`
  mutation PostReaction($post_id: Int!, $input: postReactionInput) {
    postReaction(post_id: $post_id, input: $input) {
      id
      group {
        id
        slug
      }
      level
    }
  }
`;

export const POST_FAVORITE = gql`
  mutation PostFavorite($post_id: Int!, $input: postFavoriteInput) {
    postFavorite(post_id: $post_id, input: $input) {
      id
      group {
        id
        slug
      }
      level
    }
  }
`;

export const USERCONTENT_REACTION = gql`
  mutation UserContentReaction(
    $userContentId: Int!
    $input: reactionTypeInput
  ) {
    userContentReaction(usercontent_id: $userContentId, input: $input) {
      id
      isLiked
    }
  }
`;

export const USERCONTENT_FAVORITE = gql`
  mutation UserContentFavorite(
    $usercontentId: Int!
    $input: favoriteTypeInput
  ) {
    userContentFavorite(usercontent_id: $usercontentId, input: $input) {
      id
    }
  }
`;

export const DELETE_USER_FROM_GROUP = gql`
  mutation DeleteUserFromGroup($groupId: Int!, $userId: Int!) {
    deleteUserFromGroup(group_id: $groupId, user_id: $userId)
  }
`;

export const UPDATE_GROUP_DETAILS = gql`
  mutation UpdateGroupDetails(
    $groupId: Int!
    $name: String!
    $description: String!
    $slug: String!
    $header_image: Upload
    $icon_image: Upload
    $promotion_image: Upload
    $promotion_text: String
    $promotion_url: String
    $featured_content: [Int]
  ) {
    updateGroupDetails(
      id: $groupId
      input: {
        name: $name
        slug: $slug
        description: $description
        header_image: $header_image
        icon_image: $icon_image
        promotion_image: $promotion_image
        promotion_text: $promotion_text
        promotion_url: $promotion_url
        featured_content: $featured_content
      }
    ) {
      id
      slug
      name
      description
    }
  }
`;

export const POST_MODERATION = gql`
  mutation PostModeration(
    $postId: Int!
    $status: String!
    $moderatorNotes: String!
  ) {
    postModeration(
      post_id: $postId
      status: $status
      moderator_notes: $moderatorNotes
    ) {
      id
      slug
      status
    }
  }
`;

export const CREATE_TAG = gql`
  mutation CreateTag($tagInput: tagInput) {
    createTag(input: $tagInput) {
      id
      name
    }
  }
`;

export const EDIT_TAG = gql`
  mutation UpdateTag($tagId: Int!, $tagInput: tagInput) {
    updateTag(id: $tagId, input: $tagInput) {
      id
      name
    }
  }
`;

export const CREATE_INDUSTRY = gql`
  mutation CreateIndustry($industryInput: industryInput) {
    createIndustry(input: $industryInput) {
      id
      name
    }
  }
`;

export const EDIT_INDUSTRY = gql`
  mutation UpdateIndustry($industryId: Int!, $industryInput: industryInput) {
    updateIndustry(id: $industryId, input: $industryInput) {
      id
      name
    }
  }
`;

export const CREATE_TOOL = gql`
  mutation CreateTool($toolInput: toolInput) {
    createTool(input: $toolInput) {
      id
      title
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
  }
`;

export const EDIT_TOOL = gql`
  mutation UpdateTool($toolId: Int!, $toolInput: toolInput) {
    updateTool(id: $toolId, input: $toolInput) {
      id
      title
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
  }
`;

export const CREATE_POST_REPORT = gql`
  mutation CreatePostReport($postId: Int!, $type: String!) {
    createPostReport(post_id: $postId, type: $type) {
      id
    }
  }
`;

export const CREATE_USERCONTENT_REPORT = gql`
  mutation CreateUserContentReport($usercontent_id: Int!, $type: String!) {
    createUserContentReport(usercontent_id: $usercontent_id, type: $type) {
      id
    }
  }
`;
export const DELETE_USERS = gql`
  mutation DeleteUsers($userIds: [Int]!) {
    deleteUsers(ids: $userIds)
  }
`;

export const CMS_UPDATE_PAGE_DATA = gql`
  mutation CMSUpdatePageData($id: Int!, $input: pageInput) {
    updatePage(id: $id, input: $input) {
      id
      title
      introduction
      background_image {
        path
      }
      header_image_credit
      header_image_credit_link
      ... on HomeTemplate {
        userContent {
          id
        }
        userContent_feature {
          id
        }
      }
    }
  }
`;

export const CMS_DELETE_USER_CONTENTS = gql`
  mutation DeleteUserContent($userContentIds: [Int]!) {
    deleteUserContent(ids: $userContentIds)
  }
`;

export const CMS_USER_CONTENTS_ACTION = gql`
  mutation UserContentAction($userContentIds: [Int]!, $action: String!) {
    userContent(ids: $userContentIds, action: $action)
  }
`;

export const MANAGE_GROUP_USERS = gql`
  mutation GroupUser($group_user_id: Int, $group_id: Int!, $action: String!) {
    groupUser(
      group_user_id: $group_user_id
      group_id: $group_id
      action: $action
    )
  }
`;

export const CLOSE_POST_REPORT = gql`
  mutation ClosePostReport($report_ids: [Int]!, $action: String!) {
    postReport(ids: $report_ids, action: $action)
  }
`;

export const DELETE_GROUPS = gql`
  mutation DeleteGroups($groupIds: [Int]!) {
    deleteGroups(ids: $groupIds)
  }
`;

export const PUBLISH_GROUPS = gql`
  mutation PublishGroups($groupIds: [Int]!, $action: String!) {
    group(ids: $groupIds, action: $action)
  }
`;

export const UNPUBLISH_GROUPS = gql`
  mutation UnpublishGroups($groupIds: [Int]!, $action: String!) {
    group(ids: $groupIds, action: $action)
  }
`;

export const CMS_DELETE_GROUP_POSTS = gql`
  mutation DeleteGroupPosts($ids: [Int]!) {
    deletePosts(ids: $ids)
  }
`;
export const CMS_DELETE_GROUPS = gql`
  mutation DeleteGroups($groupIds: [Int]!) {
    deleteGroups(ids: $groupIds)
  }
`;

export const CMS_GROUP_ACTION = gql`
  mutation CMSGroupActions($groupIds: [Int]!, $action: String!) {
    group(ids: $groupIds, action: $action)
  }
`;

export const CMS_GROUP_POST_ACTION = gql`
  mutation CMSGroupPostAction($ids: [Int]!, $action: String!) {
    post(ids: $ids, action: $action)
  }
`;

export const CLOSE_USER_REPORT = gql`
  mutation CloseUserReport($report_id: [Int]!, $action: String!) {
    userReport(ids: $report_id, action: $action)
  }
`;

export const CLOSE_USERCONTENT_REPORT = gql`
  mutation CloseUserContentReport($usercontent_id: [Int]!, $action: String!) {
    userContentReport(ids: $usercontent_id, action: $action)
  }
`;

export const CMS_CHANGE_ROLE = gql`
  mutation changeRole($user_ids: [Int]!, $role: String!) {
    changeRole(user_ids: $user_ids, role: $role)
  }
`;

export const CMS_CHANGE_USER_STATUS = gql`
  mutation changeUserStatus($user_ids: [Int]!) {
    changeUserStatus(ids: $user_ids, action: "reactivate")
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost($id: Int!, $input: postInput, $images_id: [Int]) {
    updatePost(id: $id, input: $input, images_id: $images_id) {
      id
      slug
      content
      link_url
      link_description
      link_title
      link_image {
        path
      }
      images {
        path
      }
    }
  }
`;

export const NOTIFICATIONS_ADD_USER_DEVICE = gql`
  mutation AddUserDeviceKey($device_key: String!, $device_type: String!) {
    addUserDeviceKey(device_key: $device_key, device_type: $device_type)
  }
`;
export const NOTIFICATIONS_DELETE_USER_DEVICE = gql`
  mutation DeleteUserDeviceKey($device_key: String!, $device_type: String!) {
    deleteUserDeviceKey(device_key: $device_key, device_type: $device_type)
  }
`;

export const NOTIFICATIONS_UPDATE_LAST_VIEW = gql`
  mutation UpdateNotificationsLastViewMutation(
    $updateNotificationsLastViewDatetime: String
  ) {
    updateNotificationsLastView(datetime: $updateNotificationsLastViewDatetime)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotificationMutation($notificationId: Int!) {
    deleteNotification(notification_id: $notificationId)
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation UpdateNotificationStatusMutation(
    $notificationId: Int!
    $status: String
  ) {
    updateNotificationStatus(
      notification_id: $notificationId
      status: $status
    ) {
      id
      status
    }
  }
`;
