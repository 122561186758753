import {
  ButtonsWrapper,
  ProfileReportWrapper,
  StatusText,
} from './ProfileReport.styled';
import React, { useContext, useState } from 'react';

import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import { InputText } from '@components/atoms/input/inputText/InputText';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import { Text } from '@components/atoms/text/Text';

function ProfileReport({ onActionClick, onCancelClick, profileId }) {
  const [selectedValue, setSelectedValue] = useState('');
  const stringsContext = useContext(StringsContext);

  const options = [
    {
      name: stringsContext?.profile_report_user_suspicious?.field,
      value: 'suspicious',
    },
    {
      name: stringsContext?.profile_report_user_abusive?.field,
      value: 'abusive',
    },
    {
      name: stringsContext?.profile_report_user_adult_content?.field,
      value: 'adult-content',
    },
  ];

  const closeModal = () => {
    setSelectedValue('');
    onCancelClick();
  };

  const onReportClick = () => {
    onActionClick(profileId, selectedValue);
    onCancelClick();
  };

  const onSelectValue = e => {
    setSelectedValue(e);
  };

  return (
    <ProfileReportWrapper>
      <Heading level="h3" weight="legendBold">
        <String id="profile_report_user_title" />
      </Heading>
      <Text as="p">
        <String id="profile_report_user_subtitle" />
      </Text>
      <InputDropdownSearch
        options={options}
        value={selectedValue}
        placeholder={stringsContext?.profile_report_user_placeholder?.field}
        simpleSelect
        onSelect={onSelectValue}
      />
      <ButtonsWrapper>
        <Button
          actionType="onclick"
          colorTheme="gray"
          onClick={closeModal}
          label={<String id="profile_report_user_cancel_btn_label" />}
        />
        <Button
          actionType="onclick"
          onClick={onReportClick}
          label={<String id="profile_report_user_report_btn_label" />}
          isDisabled={selectedValue === ''}
        />
      </ButtonsWrapper>
    </ProfileReportWrapper>
  );
}

ProfileReport.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  profileId: PropTypes.number.isRequired,
};

export default ProfileReport;
