import { styled } from '@root/stitches.config';
import { Heading } from '@components/atoms/heading/Heading';

export const LinksSection = styled('div', {
  marginTop: '$40',
  '@bp3': {
    marginTop: '$64',
  },
});

export const StyledHeading = styled(Heading, {
  fontFamily: '$ArtifaktElementBold',
  marginBottom: '$32',
});

export const ThumbnailWrapper = styled('div', {
  position: 'relative',
  marginTop: '$16',
  display: 'grid',
  gap: '32px',
  gridTemplateColumns: 'repeat(1, 1fr)',
  bp0: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

export const PaginationWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '$32',
  '@bp2': {
    marginTop: '',
  },
});
