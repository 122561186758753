import { validImageUrl } from '@src/templates/create/utils';
import { validURL } from '@src/utils/validURL';

export function getImageSrc(imgObj, imageType, imgAlt, imgId) {
  if (!imgObj) return;
  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };
  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };
  const isLocalImageString = imgObj.path.split('/')[0];

  const isTestImg = () => {
    if (
      isExactMatch(isLocalImageString, 'images') ||
      imgObj.path.includes('http')
    )
      return true;
  };

  if (isTestImg()) {
    return {
      src: validURL(imgObj.path) ? `${imgObj.path}` : `${'/'}${imgObj.path}`,
    };
  }

  if (!imgObj.hasOwnProperty('sizes') || imgObj.sizes.length === 0) {
    return {
      src: validImageUrl(imgObj.path),
    };
  }

  switch (imageType) {
    case 'create_details':
      return {
        src: validImageUrl(imgObj.path),
        source: [
          {
            srcset: validImageUrl(imgObj.sizes[3].path),
            media: '(max-width: 400px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[2].path),
            media: '(max-width: 600px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[1].path),
            media: '(max-width: 768px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[0].path),
            media: '(min-width: 769px)',
          },
        ],
      };

    case 'page_hero':
      return {
        src: validImageUrl(imgObj.path),
        source: [
          {
            srcset: validImageUrl(imgObj.sizes[2].path),
            media: '(max-width: 400px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[1].path),
            media: '(max-width: 768px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[0].path),
            media: '(min-width: 769px)',
          },
        ],

        alt: imgAlt,
      };

    case 'group_header':
      return {
        src: validImageUrl(imgObj.path),
        source: [
          {
            srcset: validImageUrl(imgObj.sizes[3].path),
            media: '(max-width: 400px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[2].path),
            media: '(max-width: 600px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[1].path),
            media: '(max-width: 768px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[0].path),
            media: '(min-width: 769px)',
          },
        ],

        alt: imgAlt,
      };

    case 'group_header_medium':
      return {
        src: validImageUrl(imgObj.sizes[2].path),
        alt: imgAlt,
      };
    case 'group_icon':
      return {
        src: validImageUrl(imgObj.sizes[0].path),
        alt: imgAlt,
      };

    case 'group_header_small':
      return {
        src: validImageUrl(imgObj.sizes[3].path),
        alt: imgAlt,
      };

    case 'post_image':
      return {
        src: validImageUrl(imgObj.path),
        source: [
          {
            srcset: validImageUrl(imgObj.sizes[0].path),
            media: '(min-width: 769px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[1].path),
            media: '(max-width: 768px)',
          },
        ],
        alt: imgAlt,
        id: imgId,
      };

    case 'link_image':
      return {
        src: validImageUrl(imgObj.path),
        source: [
          {
            srcset: validImageUrl(imgObj.sizes[0].path),
            media: '(min-width: 769px)',
          },
          {
            srcset: validImageUrl(imgObj.sizes[1].path),
            media: '(max-width: 768px)',
          },
        ],
        alt: imgAlt,
        id: imgId,
      };

    case 'group_promo':
      if (!imgObj.path) return;
      return {
        src: validImageUrl(imgObj.path),
        alt: imgAlt,
        id: imgId,
      };

    default:
  }

  return;
}
