import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import { CLOSE_USERCONTENT_REPORT } from '@src/apollo/mutation';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_USERCONTENT_REPORTS } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import Options from '@components/organisms/table/components/columnItem/Options';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import Table from '@components/organisms/table/Table';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { Text } from '@components/atoms/text/Text';

const UserContentReports = context => {
  const stringsContext = useContext(StringsContext);

  const ViolenceLabel = <String id="community_admin_violence" />;
  const NudityLabel = <String id="community_admin_nudity" />;
  const SuspiciousLabel = <String id="community_admin_suspicious" />;
  const ArtworkLabel = <String id="thumbnail_artwork" />;
  const ArticleLabel = <String id="thumbnail_article" />;
  const TutorialLabel = <String id="thumbnail_tutorial" />;
  const YesLabel = <String id="admin_cms_yes_text" />;
  const NoLabel = <String id="admin_cms_no_text" />;

  const onSubmitFilters = () => {
    // All Filters
    const allSelectedFilters = {};

    //   Checkbox - Roles;
    const selectedRoles = [];
    filters.forEach(filter => {
      if (filter.name == 'role') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            selectedRoles.push(option.value);
          }
        });
      }
    });
    if (selectedRoles.length > 0) {
      allSelectedFilters.selectedRoles = selectedRoles;
    }

    //       Radio - Status
    let type = null;
    filters.forEach(filter => {
      if (filter.name == 'report-type') {
        type = filter.selectedValue;
      }
    });
    if (type) {
      allSelectedFilters.type = type;
    }

    //       Date - Last Post
    const selectedLastPostDate = {
      startDate: null,
      endDate: null,
    };
    filters.forEach(filter => {
      if (filter.name == 'last-post') {
        selectedLastPostDate.startDate = filter.startDate;
        selectedLastPostDate.endDate = filter.endDate;
      }
    });
    if (selectedLastPostDate.startDate || selectedLastPostDate.endDate) {
      allSelectedFilters.selectedLastPostDate = selectedLastPostDate;
    }

    //       Date - First Post
    const selectedFirstPostDate = {
      startDate: null,
      endDate: null,
    };
    filters.forEach(filter => {
      if (filter.name == 'first-post') {
        selectedFirstPostDate.startDate = filter.startDate;
        selectedFirstPostDate.endDate = filter.endDate;
      }
    });
    if (selectedFirstPostDate.startDate || selectedFirstPostDate.endDate) {
      allSelectedFilters.selectedFirstPostDate = selectedFirstPostDate;
    }

    setReports({
      ...reports,
      activeFilters: allSelectedFilters,
    });
    reportsData.refetch();
  };

  // const onChangeRole = e => {
  //   filters.forEach(filter => {
  //     if (filter.name == 'role') {
  //       filter.options.forEach(option => {
  //         if (option.value == e.currentTarget.value) {
  //           option.isChecked = e.currentTarget.checked;
  //         }
  //       });
  //     }
  //   });
  // };

  //   Radio - Status
  const onChangeReportType = e => {
    filters.forEach(filter => {
      if (filter.name == 'report-type') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value == e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
  };

  // //   Date - Last Post
  // const onLastPostChange = (name, date) => {
  //   filters.forEach(filter => {
  //     if (filter.name == 'last-post') {
  //       filter[name] = date;
  //     }
  //   });
  // };

  // //   Date - First Post
  // const onFirstPostChange = (name, date) => {
  //   filters.forEach(filter => {
  //     if (filter.name == 'first-post') {
  //       filter[name] = date;
  //     }
  //   });
  // };

  const filters = [
    {
      title: 'Report type',
      type: 'radio',
      name: 'report-type',
      onChange: onChangeReportType,
      selectedValue: '',
      options: [
        {
          name: 'All',
          id: 'All',
          label: 'All',
          value: '',
          isChecked: false,
        },
        {
          name: 'Violence',
          id: 'Violence',
          label: ViolenceLabel,
          value: 'abusive',
          isChecked: false,
        },
        {
          name: 'Adult content',
          id: 'Adult content',
          label: NudityLabel,
          value: 'adult-content',
          isChecked: false,
        },
        {
          name: 'Suspicious',
          id: 'Suspicious',
          label: SuspiciousLabel,
          value: 'suspicious',
          isChecked: false,
        },
      ],
    },
  ];

  const [closeReport, closeReportData] = useMutation(CLOSE_USERCONTENT_REPORT, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      reportsData.refetch();
    },
  });

  const convertUTCToLocalDate = date => {
    if (!date) {
      return date;
    }
    date = new Date(date * 1000);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );

    return format(date, 'LLL, d y');
  };

  const convertUTCtoLocalTime = date => {
    if (!date) {
      return date;
    }
    date = new Date(date * 1000);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let suffix = '';
    if (hours >= 12) {
      suffix = ' PM';
    } else {
      suffix = ' AM';
    }
    hours = hours % 12 || 12;
    minutes = ('0' + minutes).slice(-2);
    const formatedDate = `${hours}:${minutes}${suffix}`;
    return formatedDate;
  };

  const getAvatar = (profilePicture, size) => {
    return getAvatarImage(profilePicture, size);
  };

  const [reportsList, setReportsList] = useState([]);

  const [reports, setReports] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    activeFilters: {},
    totalCount: 0,
    columns: [
      {
        header: <String id="community_admin_reported_by" />,
        accessor: 'reporter',
      },
      {
        header: <String id="community_admin_author" />,
        accessor: 'author',
      },
      {
        header: <String id="admin_cms_likes_text" />,
        accessor: 'likes',
      },
      {
        header: <String id="admin_cms_editors_pick_text" />,
        accessor: 'editors_pick',
      },
      {
        header: <String id="community_admin_report_date" />,
        accessor: 'report_date',
      },
      {
        header: <String id="community_admin_content_type" />,
        accessor: 'content_type',
      },
      {
        header: <String id="community_admin_report_type" />,
        accessor: 'report_type',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const singleRowActions = [
    {
      label: <String id="community_admin_reject_usercontent" />,
      onClickAction: e => {
        closeReport({
          variables: {
            usercontent_id: [e.id],
            action: 'approve',
          },
        });
      },
    },
    {
      label: <String id="community_admin_dismiss_report" />,
      onClickAction: e => {
        closeReport({
          variables: {
            usercontent_id: [e.id],
            action: 'reject',
          },
        });
      },
    },
  ];
  const reportsData = useQuery(GET_USERCONTENT_REPORTS, {
    context: { clientName: 'cms' },
    variables: {
      input: reports.activeFilters,
      limit: reports.limitRow,
      page: reports.page,
    },
    fetchPolicy: 'no-cache',
  });

  const onNextPage = () => {
    const pageCountFirstNumber = reports.page * reports.limitRow + 1;
    let pageCountSecondNumber = pageCountFirstNumber + reports.limitRow - 1;
    if (pageCountSecondNumber > reportsData.data.userContentReports.total) {
      pageCountSecondNumber = reportsData.data.userContentReports.total;
    }

    setReports({
      ...reports,
      page: reports.page + 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber = (reports.page - 2) * reports.limitRow + 1;
    let pageCountSecondNumber = pageCountFirstNumber + reports.limitRow - 1;
    if (pageCountSecondNumber > reportsData.data.userContentReports.length) {
      pageCountSecondNumber = reportsData.data.userContentReports.length;
    }

    setReports({
      ...reports,
      page: reports.page - 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setReports({
      ...reports,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(reports.totalCount / rowCount),
    });
  };

  useEffect(() => {
    if (reportsData.data) {
      const rowDatas = [];

      reportsData.data?.userContentReports?.data.forEach(post => {
        const reporting_list = [];
        let report_type = '';
        let report_type_list = [];
        post.reports?.data.map((report, j) => {
          if (!report_type_list.find(obj => obj === report.type)) {
            report_type_list.push(report.type);
          }
        });

        let separator = ', ';
        let report_label = '';
        for (let i = 0; i < report_type_list.length; i++) {
          if (i === report_type_list.length - 1) {
            separator = '';
          }
          if (report_type_list[i] === 'abusive') {
            report_label = stringsContext['community_admin_violence'].field;
          } else if (report_type_list[i] === 'adult-content') {
            report_label = stringsContext['community_admin_nudity'].field;
          } else {
            report_label = stringsContext['community_admin_suspicious'].field;
          }
          report_type = report_type + report_label + separator;
        }

        let contentType = '';
        if (post.userContentType === 'article') {
          contentType = ArticleLabel;
        } else if (post.userContentType === 'artwork') {
          contentType = ArtworkLabel;
        } else {
          contentType = TutorialLabel;
        }

        let editorsPick = '';
        if (post.editors_pick) {
          editorsPick = YesLabel;
        } else {
          editorsPick = NoLabel;
        }

        rowDatas.push({
          reporter: (
            <>
              {post.reports?.data.map((report, i) => {
                if (i < 2) {
                  return (
                    <UserInfos
                      url={report.reporter.userProfile.profile_picture}
                      key={report.createdAt}
                      link={
                        report.reporter.first_name !== '' &&
                        report.reporter.first_name !== 'anonymized'
                          ? `/m/${report.reporter.username}`
                          : undefined
                      }
                      name={
                        report.reporter.first_name === 'anonymized'
                          ? 'Anonymized'
                          : `${report.reporter.first_name} ${report.reporter.last_name}`
                      }
                      css={i > 0 ? { mt: '$16' } : {}}
                    />
                  );
                }
              })}
              {post.reports.data.length > 3 && (
                <Text>
                  <String
                    id="admin_reports_reporter_number"
                    extras={{ count: post.reports.data.length - 3 }}
                  />
                </Text>
              )}
            </>
          ),
          author: (
            <UserInfos
              url={post.creator?.userProfile?.profile_picture}
              name={
                post.creator.first_name === 'anonymized'
                  ? 'Anonymized'
                  : `${post.creator.first_name} ${post.creator.last_name}`
              }
              job={post.creator.title}
              link={
                post?.creator?.first_name !== '' &&
                post?.creator?.first_name !== 'anonymized'
                  ? `/m/${post.creator.username}`
                  : undefined
              }
            />
          ),
          likes: post.usersLikedCount,
          editors_pick: editorsPick,
          report_date: (
            <DateIconLink
              date={convertUTCToLocalDate(
                post.reports.data[post.reports.data.length - 1].createdAt
              )}
              time={convertUTCtoLocalTime(
                post.reports.data[post.reports.data.length - 1].createdAt
              )}
              url={
                '/m/' +
                post.creator.username +
                '/' +
                post.userContentType +
                's/' +
                post.slug
              }
            />
          ),
          content_type: contentType,
          report_type: report_type,
          options: <Options actions={singleRowActions} id={post.id} />,
          id: post.id,
        });
      });
      setReportsList(rowDatas);

      if (reports.page === 1) {
        const pageCountFirstNumber = (reports.page - 1) * reports.limitRow + 1;
        let pageCountSecondNumber = pageCountFirstNumber + reports.limitRow - 1;
        if (pageCountSecondNumber > reportsData.data.userContentReports.total) {
          pageCountSecondNumber = reportsData.data.userContentReports.total;
        }
        setReports({
          ...reports,
          isLoaded: true,
          totalCount: reportsData.data.userContentReports.total,
          currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
          maxPage: Math.ceil(
            reportsData.data.userContentReports.total / reports.limitRow
          ),
        });
      } else {
        setReports({
          ...reports,
          isLoaded: true,
          totalCount: reportsData.data.userContentReports.total,
          maxPage: Math.ceil(
            reportsData.data.userContentReports.total / reports.limitRow
          ),
        });
      }
    }
  }, [reportsData.data]);

  const bulkActions = [
    {
      label: <String id="community_admin_reject_usercontent" />,
      onClickAction: userContents => {
        for (let i = 0; i < userContents.length; i++) {
          closeReport({
            variables: {
              usercontent_id: [userContents[i].id],
              action: 'approve',
            },
          });
        }
      },
    },
    {
      label: <String id="community_admin_dismiss_report" />,
      onClickAction: userContents => {
        for (let i = 0; i < userContents.length; i++) {
          closeReport({
            variables: {
              usercontent_id: [userContents[i].id],
              action: 'reject',
            },
          });
        }
      },
    },
  ];

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        <String id="admin_reports_content_title" />
      </Heading>
      <AdvanceFilters onSubmitFilters={onSubmitFilters} filters={filters} />
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={reports.totalCount}
        currentCount={reports.currentCount}
        loading={reportsData.loading || closeReportData.loading}
        rowData={reportsList}
        columns={reports.columns}
        maxPage={reports.maxPage}
        currentPage={reports.page}
        hasOptions
        bulkActions={bulkActions}
      />
    </AdminLayout>
  );
};

export default UserContentReports;
