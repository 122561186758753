import {
  ActionLabel,
  OptionsContainer,
  OptionsWrapper,
} from './Options.styled';
import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';

const Options = props => {
  const [options, setOptions] = useState({
    isOptionsOpened: false,
  });

  const onToggleOptions = () => {
    setOptions({
      isOptionsOpened: !options.isOptionsOpened,
    });
  };

  const onClickAction = action => {
    onToggleOptions();
    action(props);
  };

  return (
    <OptionsWrapper className="options">
      <Button
        type="circle"
        colorTheme="white"
        svgIcon="more"
        actionType="onclick"
        onClick={onToggleOptions}
      />
      <OptionsContainer
        isOptionsOpened={options.isOptionsOpened ? 'isOpened' : 'isNotOpened'}
        alignLeft={props.alignLeft}
      >
        {props.actions?.map((action, index) => {
          return (
            <ActionLabel
              size="14"
              onClick={
                props.isDisabled && index <= 2
                  ? () => {}
                  : onClickAction.bind(null, action.onClickAction)
              }
              key={action.label + action.status}
              status={action.status}
              isDisabled={props.isDisabled}
            >
              {action.status == 'attention' || action.status == 'carefull' ? (
                <Svg icon="block"></Svg>
              ) : null}
              {action.status == 'help' || action.status == 'helpCarefull' ? (
                <Svg icon="help"></Svg>
              ) : null}
              {action.label}
            </ActionLabel>
          );
        })}
      </OptionsContainer>
    </OptionsWrapper>
  );
};

export default Options;
