import { ComponentWrapper, NavWrapper, TextTitle } from './SidebarMenu.styled';

import PropTypes from 'prop-types';
import React from 'react';
import SidebarMenuItems from './SidebarMenuItems';
import { Text } from '@components/atoms/text/Text';

const SidebarMenu = props => {
  return (
    <ComponentWrapper css={props.css}>
      <Text
        as="h2"
        size="24"
        css={{
          fontFamily: '$ArtifaktElementBold',
          marginBottom: '$24',
          borderBottom: '1px solid $lightGrey',
          width: '100%',
          paddingBottom: '$8',
        }}
      >
        {props.title ? <TextTitle>{props.title}</TextTitle> : null}
      </Text>

      <NavWrapper>
        {props.nav.map((navItem, index) => {
          return (
            <SidebarMenuItems
              key={navItem.label + navItem.url}
              {...navItem}
              activeTab={props?.location?.pathname === navItem.url}
              location={props.location}
            />
          );
        })}
      </NavWrapper>
    </ComponentWrapper>
  );
};

SidebarMenu.propTypes = {
  title: PropTypes.string,
  nav: PropTypes.array.isRequired,
  subMenu: PropTypes.array,
};

export default SidebarMenu;
