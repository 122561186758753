import React, { useEffect, useState, useContext } from 'react';
import { getMessaging, onMessage, isSupported } from 'firebase/messaging';
import { useMutation, useQuery } from '@apollo/client';
import Button from '@components/atoms/button/Button';
import { GET_NOTIFICATIONS_COUNT_SINCE_LAST_VIEW } from '@src/apollo/query';
import {
  NOTIFICATIONS_ADD_USER_DEVICE,
  NOTIFICATIONS_UPDATE_LAST_VIEW,
} from '@src/apollo/mutation';
import { navigate } from 'gatsby';
import { format, utcToZonedTime } from 'date-fns-tz';
import { getFCMToken, getFirebase } from '@src/utils/firebaseInit';
import { NotificationsContext } from '@context/NotificationsContext';

const UserBarNotifications = () => {
  const { notificationsState, setNotificationsState } = useContext(
    NotificationsContext
  );
  const [isTokenFound, setTokenFound] = useState(false);

  const [
    addDevice,
    { data: dataAddDevice, errors: errorsAddDevice },
  ] = useMutation(NOTIFICATIONS_ADD_USER_DEVICE);

  const [
    updateNotificationLastView,
    { loading: loadingUpdateLastView, errors: errosUpdateLastView },
  ] = useMutation(NOTIFICATIONS_UPDATE_LAST_VIEW);

  const today = utcToZonedTime(Date.now());
  const formattedToday = format(today, 'yyyy-MM-dd HH:mm:ssXXX');
  const todayToString = formattedToday.toString();
  const handleNotificationsButtonClick = event => {
    navigate('/notifications');
    updateNotificationLastView({
      variables: { updateNotificationsLastViewDatetime: todayToString },
    });
  };

  const {
    loading: notificationsCountLoading,
    error: notificationsCountError,
    data: notificationsCountData,
  } = useQuery(GET_NOTIFICATIONS_COUNT_SINCE_LAST_VIEW, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (notificationsCountData?.notificationsCountSinceLastView > 0) {
      setNotificationsState({
        ...notificationsState,
        notificationsCount:
          notificationsCountData.notificationsCountSinceLastView,
      });
    }
  }, [notificationsCountData]);

  const firebase = getFirebase();
  useEffect(() => {
    let data;

    async function tokenFunc() {
      if (!firebase) return;
      const browserSupports = await isSupported();
      if (!browserSupports) return;
      data = await getFCMToken();

      if (data) {
        setTokenFound(true);

        setNotificationsState({
          ...notificationsState,
          firebaseToken: data.value,
        });
        addDevice({
          variables: {
            device_key: data.value,
            device_type: 'web',
          },
        });
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  let messaging;
  async function getMessage() {
    if (!firebase) return;
    const browserSupports = await isSupported();
    if (!browserSupports) return;
    messaging = getMessaging();
    onMessage(messaging, payload => {
      setNotificationsState({
        ...notificationsState,
        notificationsCount: notificationsState.notificationsCount + 1,
      });
    });
  }

  getMessage();

  return (
    <>
      <Button
        type="circle"
        colorTheme="headerButtons"
        svgIcon="notification"
        actionType="onclick"
        notificationBubbleActive={
          notificationsState.notificationsCount > 0 ? true : false
        }
        notificationBubbleCount={
          notificationsState.notificationsCount < 9
            ? notificationsState.notificationsCount
            : '9+'
        }
        onClick={handleNotificationsButtonClick}
      />
    </>
  );
};

export default UserBarNotifications;
