import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const WrapperPage = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '50vh',

  '@bp2': {
    margin: '0 auto',
    flexDirection: 'row',
    gap: '$92',
    padding: '$84 0',
  },
});

export const Subscribe = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '$mainTextColor',
  px: '$24',
  py: '$24',
  height: '100%',
  '@bp1': {
    px: '$48',
    py: '$48',
  },
  '@bp2': {
    px: '$64',
    py: '$48',
  },
  '@bp3': {
    px: '$72',
    py: '$48',
  },
});

export const SubscribeDescription = styled(Text, {
  marginBottom: '$40',
});

export const SubscribeIframe = styled('div', {
  iframe: {
    width: '100%',
    minHeight: '170px',
  },

  '.marketoForm': {
    maxWidth: '600px',
  },

  '.marketoForm input[type=text]': {
    width: '100%!important',
  },
  '.preferenceLabel, .marketoFormCaptcha, #pref': {
    fontSize: '$14!important',
    textAlign: 'left!important',
  },

  '.button': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '$24',

    '#submitbt': {
      fontSize: '$16',
      lineHeight: '1.4',
      display: 'inline-flex',
      fontFamily: 'ArtifaktElementBold',
      letterSpacing: '.2px',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
      border: 'none',
      textDecoration: 'none',
      textAlign: 'center',
      backgroundColor: '$blackToWhite',
      color: '$whiteToBlack',
      transition: 'color .3s ease',
      borderRadius: '$8',
      padding: '$8 $16',
      minHeight: '$48',
      'span + span': {
        marginLeft: '$4',
      },

      '&:hover': {
        backgroundColor: '$darkGrey',
        transition: 'background-color .5s ease',
      },
    },

    svg: {
      display: 'none',
    },
  },
  '#marketoDiv': {
    '.input_txt': {
      background: 'red !important',
    },
  },
});
export const SubscribeThankYou = styled('div', {
  '.content': {
    display: 'flex',
    alignItems: 'flex-start',
    'p:first-of-type': {
      width: '230px',
    },
    svg: {
      width: '32px',
      height: '32px',
      marginRight: '$8',
      fill: '$success',
    },
    color: '$blackToWhite',
    fontSize: '$18',
    textAlign: 'left',
    fontFamily: '$ArtifaktLegendBold',
  },
  marginBottom: '$32',
});
