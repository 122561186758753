import {
  CMS_DELETE_GROUP_POSTS,
  CMS_GROUP_POST_ACTION,
} from '@src/apollo/mutation';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_ALL_POSTS } from '@src/apollo/query';
import { Link } from 'gatsby';
import Options from '@components/organisms/table/components/columnItem/Options';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import Button from '@components/atoms/button/Button';
import useModal from '@components/atoms/modal/useModal';
import Modal from '@components/atoms/modal/Modal';
import CommunityPostBox from '@components/molecules/communityPostBox/CommunityPostBox';
import { postsDataToViewModel } from '@src/utils/postsDataToViewModel';

const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(date, 'LLL, d y');
};

const CommunityGroupPosts = context => {
  const onCreatedDateChange = (name, date) => {
    const updatedFilters = { ...communityGroupPostFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'created-date') {
        filter[name] = date;
      }
    });
    setCommunityGroupPostsFilters({ filters: updatedFilters.filters });
  };
  const [communityPostsList, setCommunityGroupPostsList] = useState([]);

  const onChangeStatusFilter = e => {
    const updatedFilters = { ...communityGroupPostFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name === 'status') {
        filter.options.forEach(option => {
          if (option.value === e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });
    setCommunityGroupPostsFilters({ filters: updatedFilters.filters });
  };

  const filters = [
    {
      title: 'Status',
      type: 'checkbox',
      name: 'status',
      onChange: onChangeStatusFilter,
      options: [
        {
          label: <String id="general_approved_text" />,
          value: 'approved',
          isChecked: false,
        },
        {
          label: <String id="general_pending_text" />,
          value: 'pending',
          isChecked: false,
        },
        {
          label: <String id="general_rejected_text" />,
          value: 'rejected',
          isChecked: false,
        },
        {
          label: <String id="general_reported_text" />,
          value: 'reported',
          isChecked: false,
        },
      ],
    },
    {
      title: 'Created date',
      type: 'date',
      name: 'created-date',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onCreatedDateChange,
    },
  ];

  const [communityGroupPostFilters, setCommunityGroupPostsFilters] = useState({
    filters,
  });

  const [communityGroupPosts, setCommunityGroupPosts] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    filters: {
      status: ['pending', 'approved', 'rejected', 'reported'],
    },
    columns: [
      {
        header: 'Author',
        accessor: 'author',
      },
      {
        header: 'Slug / Content',
        accessor: 'slug_content',
      },
      {
        header: 'Preview',
        accessor: 'preview',
      },
      {
        header: 'Group',
        accessor: 'group',
      },
      {
        header: 'Created at',
        accessor: 'created_at',
      },
      {
        header: 'Comments count',
        accessor: 'comments_count',
      },
      {
        header: `Likes`,
        accessor: 'likes',
      },
      {
        header: 'Status',
        accessor: 'status',
      },
      {
        header: 'Content type',
        accessor: 'content_type',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const onApplyFilters = () => {
    // All Filters
    const allSelectedFilters = {};

    // Checkbox - Status
    const status = [];
    communityGroupPostFilters.filters.forEach(filter => {
      if (filter.name === 'status') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            status.push(option.value);
          }
        });
      }
    });
    if (status.length > 0) {
      allSelectedFilters.status = status;
    }

    let created_date_start = null;
    let created_date_end = null;
    communityGroupPostFilters.filters.forEach(filter => {
      if (filter.name == 'created-date') {
        created_date_start = filter.startDate;
        created_date_end = filter.endDate;
      }
    });
    if (created_date_start) {
      allSelectedFilters.created_date_start = created_date_start.toString();
    }
    if (created_date_end) {
      allSelectedFilters.created_date_end = created_date_end.toString();
    }

    setCommunityGroupPosts({
      ...communityGroupPosts,
      filters: allSelectedFilters,
    });
  };

  const communityGroupPostsData = useQuery(GET_ALL_POSTS, {
    context: { clientName: 'cms' },
    variables: {
      limit: communityGroupPosts.limitRow,
      page: communityGroupPosts.page,
      filters: communityGroupPosts.filters,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deletePosts, deletePostsData] = useMutation(CMS_DELETE_GROUP_POSTS, {
    context: { clientName: 'cms' },
    onCompleted: () => {
      communityGroupPostsData.refetch();
    },
  });

  const [groupPostAction, groupPostActionData] = useMutation(
    CMS_GROUP_POST_ACTION,
    {
      context: { clientName: 'cms' },
      onCompleted: () => {
        communityGroupPostsData.refetch();
      },
    }
  );

  const onDeletePost = ids => {
    deletePosts({
      variables: {
        ids,
      },
    });
  };

  const onPostAction = (ids, action) => {
    groupPostAction({
      variables: {
        ids,
        action,
      },
    });
  };

  const bulkActions = [
    {
      label: <String id="admin_community_all_posts_delete_bulk_label" />,
      onClickAction: posts => {
        onDeletePost(posts.map(pt => pt.id));
      },
    },
    {
      label: <String id="admin_community_all_posts_publish_bulk_label" />,
      onClickAction: posts => {
        onPostAction(
          posts.map(pt => pt.id),
          'publish'
        );
      },
    },
    {
      label: <String id="admin_community_all_posts_unpublish_bulk_label" />,
      onClickAction: posts => {
        onPostAction(
          posts.map(pt => pt.id),
          'unpublish'
        );
      },
    },
  ];

  const singleRowActions = [
    {
      label: <String id="admin_community_all_posts_delete_label" />,
      onClickAction: item => {
        onDeletePost([item.id]);
      },
    },
    {
      label: <String id="admin_community_all_posts_publish_label" />,
      onClickAction: item => {
        onPostAction([item.id], 'publish');
      },
    },
    {
      label: <String id="admin_community_all_posts_unpublish_label" />,
      onClickAction: item => {
        onPostAction([item.id], 'unpublish');
      },
    },
  ];

  const getAvatar = (profilePicture, size) => {
    return getAvatarImage(profilePicture, size);
  };

  const onNextPage = () => {
    const pageCountFirstNumber =
      (communityGroupPosts.page - 1) * communityGroupPosts.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + communityGroupPosts.limitRow - 1;
    if (pageCountSecondNumber > communityGroupPostsData.data.posts.total) {
      pageCountSecondNumber = communityGroupPostsData.data.posts.total;
    }

    setCommunityGroupPosts({
      ...communityGroupPosts,
      page: communityGroupPosts.page + 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (communityGroupPosts.page - 2) * communityGroupPosts.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + communityGroupPosts.limitRow - 1;
    if (pageCountSecondNumber > communityGroupPostsData.data.posts.total) {
      pageCountSecondNumber = communityGroupPostsData.data.posts.total;
    }

    setCommunityGroupPosts({
      ...communityGroupPosts,
      page: communityGroupPosts.page - 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setCommunityGroupPosts({
      ...communityGroupPosts,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(communityGroupPosts.totalCount / rowCount),
    });
  };

  const { isShowing, toggle } = useModal();
  const [previewPostState, setPreviewPostState] = useState();

  const previewPost = post => {
    toggle();
    setPreviewPostState({
      ...post,
    });
  };

  useEffect(() => {
    if (communityGroupPostsData.data) {
      const rowDatas = [];
      communityGroupPostsData.data.posts.data.forEach(post => {
        let status = '';

        if (post.status === 'pending') {
          status = <String id="general_pending_text" />;
        } else if (post.status === 'approved') {
          status = <String id="general_approved_text" />;
        } else if (post.status === 'rejected') {
          status = <String id="general_rejected_text" />;
        } else {
          status = <String id="general_reported_text" />;
        }

        let type = '';

        if (post.level === 0) {
          type = <String id="community_admin_post" />;
        } else {
          type = <String id="community_admin_comment" />;
        }

        let name = '';

        if (
          post?.author?.first_name !== null &&
          post?.author?.first_name !== ''
        ) {
          name = post?.author?.first_name + ' ' + post?.author?.last_name;
        }

        const singleRow = {
          author: (
            <UserInfos
              name={name}
              job={post.author.title}
              url={post.author.userProfile.profile_picture}
              link={`/m/${post.author.username}`}
            />
          ),
          slug_content:
            post.level === 0 ? (
              <Link
                to={`${FE_URL}/community/groups/${post.group.slug}/${post.slug}`}
                target="_blank"
                css={{ textTransform: 'capitalize' }}
              >
                {post.slug}
              </Link>
            ) : null,
          group: (
            <Link
              to={`${FE_URL}/community/groups/${post.group.slug}`}
              target="_blank"
              css={{ textTransform: 'capitalize' }}
            >
              {post.group.name}
            </Link>
          ),
          preview: (
            <Button
              type="circle"
              actionType="onclick"
              onClick={() => previewPost(post)}
              svgIcon="visibility-on"
            />
          ),
          created_at: (
            <DateIconLink date={convertUTCToLocalDate(post.createdAt * 1000)} />
          ),
          comments_count: (
            <Text css={{ textTransform: 'capitalize' }}>
              {post.childrenCount}
            </Text>
          ),
          likes: (
            <Text css={{ textTransform: 'capitalize' }}>
              {post.usersLikedCount}
            </Text>
          ),
          content_type: type,
          status: <Text css={{ textTransform: 'capitalize' }}>{status}</Text>,
          options: <Options actions={singleRowActions} id={post.id} />,
          id: post.id,
        };
        rowDatas.push(singleRow);
      });

      const pageCountFirstNumber =
        (communityGroupPosts.page - 1) * communityGroupPosts.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + communityGroupPosts.limitRow - 1;
      if (pageCountSecondNumber > communityGroupPostsData.data.posts.total) {
        pageCountSecondNumber = communityGroupPostsData.data.posts.total;
      }

      setCommunityGroupPosts({
        ...communityGroupPosts,
        isLoaded: true,
        totalCount: communityGroupPostsData.data.posts.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(
          communityGroupPostsData.data.posts.total /
            communityGroupPosts.limitRow
        ),
      });

      setCommunityGroupPostsList(rowDatas);
    }
  }, [communityGroupPostsData.data]);

  const onSearchSubmit = searchInput => {
    setCommunityGroupPosts({
      ...communityGroupPosts,
      filters: searchInput !== '' ? { search: searchInput } : {},
      page: 1,
    });
    communityGroupPostsData.refetch();
  };

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <AdvanceFilters
        title="AREA All Posts"
        onSubmitFilters={onApplyFilters}
        filters={communityGroupPostFilters.filters}
        onSearchSubmit={onSearchSubmit}
      />
      <Modal
        isShowing={isShowing}
        hide={() => {
          toggle();
          setPreviewPostState();
        }}
      >
        {previewPostState && (
          <CommunityPostBox
            {...postsDataToViewModel([previewPostState])[0]}
            isInFavorites
            isInModal
            hideOptions
          />
        )}
      </Modal>
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={communityGroupPosts.totalCount}
        currentCount={communityGroupPosts.currentCount}
        loading={
          communityGroupPostsData.loading ||
          deletePostsData.loading ||
          groupPostActionData.loading
        }
        rowData={communityPostsList}
        columns={communityGroupPosts.columns}
        maxPage={communityGroupPosts.maxPage}
        currentPage={communityGroupPosts.page}
        hasOptions
        bulkActions={bulkActions}
      />
    </AdminLayout>
  );
};

export default CommunityGroupPosts;
