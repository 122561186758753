import { styled } from '@root/stitches.config';

export const SelectCreateTagsWrapper = styled('div', {});

export const Tag = styled('div', {
  fontSize: '$12',
  textTransform: 'uppercase',
  lineHeight: '1em !important',
  display: 'inline-flex',
  fontFamily: 'var(--ArtifaktElementRegular)',
  letterSpacing: '.2px',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '$16 $24',
  border: '1px solid $mainTextColor',
  borderRadius: '$pill',
  cursor: 'pointer',
  userSelect: 'none',
  marginRight: '$12',
  marginBottom: '$12',

  button: {
    opacity: 1,
    pointerEvents: 'auto',
  },

  '@bp2': {
    button: {
      opacity: 0,
      pointerEvents: 'auto',
    },
  },

  '&:hover': {
    button: {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
});

export const InputTextLabel = styled('label', {
  marginBottom: '$4',
  display: 'inline-block',
  fontSize: '$14',
  fontFamily: 'var(--ArtifaktElement)',
  display: 'flex',
  alignContent: 'center',
  marginRight: '$8',
});

export const ImageWrapper = styled('div', {
  width: '50px',
  height: '50px',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  overflow: 'hidden',
  boxShadow: '$1',
  marginRight: '$12',

  img: {
    width: '50px',
    height: '50px',
  },
});

export const RemoveButton = styled('button', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: '-5px',
  borderRadius: '50%',
  background: '$lightGrey',
  border: 'none',
  zIndex: '3',
  width: '24px',
  height: '24px',
  padding: 0,
  opacity: 0,
  pointerEvents: 'none',
  transition: 'opacity .4s ease',

  svg: {
    width: '18px',
    height: '18px',
    fill: '$mainTextColor',
  },
});
export const SelectedTagsWrapper = styled('div', {});
export const SelectedTagsTitle = styled('h5', {
  fontFamily: '$ArtifaktElementBold',
  fontSize: '$14',
  marginTop: '$16',
  marginBottom: '$12',
});
