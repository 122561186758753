import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { Chart } from 'chart.js';
import * as ChartGeo from 'chartjs-chart-geo';
import { MapChartCanvas, MapChartWrapper } from "./MapChartBlock.styled";
import { Heading } from "@components/atoms/heading/Heading";

const MapChartBlock = (props) => {
  const {
    countriesData,
    title,
  } = props;
  
  useEffect(() => {
    const mapChartArea = document.getElementById("mapCanvas").getContext("2d");
    fetch('https://unpkg.com/world-atlas/countries-50m.json').then(r => r.json()).then(data => {
      const countries = ChartGeo.topojson.feature(data, data.objects.countries).features;
      const labels = countries.map(c => c.properties.name)
      // https://en.wikipedia.org/wiki/ISO_3166-1 numeric codes from wikipedia ISO 3166-1 list
      const countryCodes = countriesData.map(d => d.numericCode);
      const values = countries.map(c => {
        if(countryCodes.includes(c.id)){
          return {   
            feature: c, value: countriesData.find(item => item.numericCode === c.id).userCount,
          }
        } else{
          return {       
            feature: c, value: 0
          }
        }      
      });
      
      const chart = new Chart(mapChartArea, {
        type: 'choropleth',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Countries',
              data:  values
            }
          ]
        },
        options: {
          showOutline: true,
          showGraticule: true,
          plugins: {
            legend: {
              display: false,
            } 
          },
          scales: {
            xy: {
              projection: 'equalEarth' 
            } 
          } 
        } 
      });
    });
    return () => {
      if(typeof mapChartArea?.destroy === "function"){
        mapChartArea.destroy()
      }
    }
  }, []);
    
  
  return (
    <MapChartWrapper>
      {title && (
        <Heading
          level="h4"
          css={{mb: '$16'}}
        >
          {title}
        </Heading>
      )}
      <MapChartCanvas id="mapCanvas"></MapChartCanvas>
    </MapChartWrapper>
  )
}

MapChartBlock.propTypes = {
  countriesData: PropTypes.arrayOf(PropTypes.shape({
    numericCode: PropTypes.string.isRequired,
    userCount: PropTypes.number.isRequired
  })).isRequired,
  title: PropTypes.string,
}

MapChartBlock.defaultProps = {
  title: undefined
}
  
export default MapChartBlock