import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  Observable,
  from,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import AuthContext from '@context/AuthContext';
import { onError } from 'apollo-link-error';
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUploadLink } from 'apollo-upload-client';
import { ClientNotificationContext } from '../context/ClientNotificationContext';
import { isErrorException, isSuccessException } from './notificationExceptions';
import useModal from '@components/atoms/modal/useModal';
import jwt_decode from 'jwt-decode';
import { setContext } from '@apollo/client/link/context';
function ApolloCustomProviderWithClientNotificationContext({
  handleUpdateClientNotification,
  children,
}) {
  const authContext = useContext(AuthContext);
  const { isShowing, toggle } = useModal();

  const handleLogout = () => {
    authContext.logout();
  };

  const httpLink = createUploadLink({
    uri: API_GRAPHQL_URL,
    credentials: 'include',
  });

  const httpLinkPublic = createUploadLink({
    uri: API_GRAPHQL_URL_PUBLIC,
    credentials: 'include',
  });

  const httpLinkCms = createUploadLink({
    uri: API_GRAPHQL_URL_CMS,
    credentials: 'include',
  });

  function getTokenState(token) {
    if (!token) {
      return { valid: false, needRefresh: true };
    }
    const decoded = jwt_decode(token);

    if (!decoded) {
      return { valid: false, needRefresh: true };
    } else if (decoded.exp && Date.now() >= decoded.exp * 1000) {
      return { valid: false, needRefresh: true };
    } else {
      return { valid: true, needRefresh: false };
    }
  }

  const refreshAuthToken = async () => {
    const userToken = localStorage.getItem('userToken');
    let token = userToken ? userToken.slice(1, -1) : '';

    let resStatus;

    fetch(`${API_URL}/refresh-token`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(res => {
        resStatus = res.status;
        return res;
      })
      .then(response => {
        switch (resStatus) {
          case 200:
            let refreshedToken = response.headers.get('authorization');
            refreshedToken = refreshedToken.replace('Bearer ', '');
            localStorage.setItem('userToken', JSON.stringify(refreshedToken));
            return refreshedToken;
          case 403:
            toggle();
            authContext.logout();
            return false;
          default:
        }
      })
      .catch(err => {
        handleLogout();
      });
  };

  const apolloAuthLink = setContext((_, { headers }) => getAuthHeaders());

  const getAuthHeaders = async headers => {
    const userToken = localStorage.getItem('userToken');
    let token = userToken ? userToken.slice(1, -1) : '';

    const tokenState = getTokenState(token);

    if (token && tokenState.needRefresh) {
      const refreshPromise = refreshAuthToken();

      if (tokenState.valid === false) {
        token = await refreshPromise;
      }
    }

    if (token) {
      return {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    } else {
      return { headers };
    }
  };

  const apolloSplit = new RetryLink().split(
    operation => operation.getContext().clientName == 'public',
    httpLinkPublic,
    new RetryLink({
      delay: { initial: 2000, max: 2000, jitter: false },
      attempts: {
        max: 1,
        retryIf: (error, _operation) => handleRetry(error, operation),
      },
    }).split(
      operation => operation.getContext().clientName == 'cms',
      from([apolloAuthLink, httpLinkCms]),
      from([apolloAuthLink, httpLink])
    )
  );

  const handleRetry = async (error, operation) => {
    let requiresRetry = false;

    if (error.statusCode === 401) {
      requiresRetry = true;
      let refreshingToken = false;

      if (!refreshingToken) {
        refreshingToken = true;
        await refreshAuthToken();
        operation.setContext(({ headers = {} }) => getAuthHeaders(headers));
        refreshingToken = false;
      }
    }

    return requiresRetry;
  };

  const successLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      if (response?.data && !response.errors) {
        isSuccessException(operation, handleUpdateClientNotification);
      }
      return response;
    });
  });

  const errorLink = onError(error => {
    isErrorException(error, handleUpdateClientNotification);
  });

  const link = ApolloLink.from([errorLink, successLink, apolloSplit]);

  const client = new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export const ApolloWithErrorHandlerProvider = ({ children }) => {
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  return useMemo(() => {
    return (
      <ApolloCustomProviderWithClientNotificationContext
        handleUpdateClientNotification={handleUpdateClientNotification}
      >
        {children}
      </ApolloCustomProviderWithClientNotificationContext>
    );
  }, []);
};

ApolloCustomProviderWithClientNotificationContext.propTypes = {
  handleUpdateClientNotification: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ApolloWithErrorHandlerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
