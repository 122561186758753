import { styled } from '@root/stitches.config';

export const FormStyled = styled('form', {
  maxWidth: '420px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@bp2': {
    alignItems: 'flex-start',
  },
});

export const ErrorMsg = styled('p', {
  marginTop: '$24',
  color: '$red',
});
