import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const TagListStyled = styled('div', {});

export const TagListWrapper = styled('ul', {
  width: '100%',
  display: 'flex',
  ai: 'center',
  jc: 'center',
  flexWrap: 'wrap',
  listStyleType: 'none',
});

export const TitleText = styled(Text, {
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: '$16',
  lineHeight: '1em',
  display: 'inline-block',
  gridArea: 'second',
});

export const TitleWrapper = styled('div', {
  textAlign: 'center',
  alignItems: 'center',
  mb: '$8',
  
  bp2: {
    display: 'grid',
    gridTemplateAreas: "'first second third fourth'",
    gridTemplateColumns: '1fr 3fr 1fr 1fr',
  },

  button: {
    gridArea: 'third',
    svg: {
      position: 'relative',
      right: '-1px',
    },
  },
});
