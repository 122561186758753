import { TextStyled, Wrapper } from './TopBarGroup.styled';

import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const TopBarGroup = props => {
  return (
    <Wrapper>
      <TextStyled size="12">{props.label}</TextStyled>
    </Wrapper>
  );
};

TopBarGroup.propTypes = {
  label: PropTypes.string,
};

export default TopBarGroup;
