import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import React from 'react';
import { SelectIndustriesStepWrapper } from './SelectIndustriesStep.styled';
import SelectTags from '@components/molecules/selectTags/SelectTags';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const SelectIndustriesStep = props => {
  window.scrollTo(0, 0);
  const isBtnDisabled = props.industriesList.findIndex(industry => {
    return industry.isSelected;
  });

  return (
    <SelectIndustriesStepWrapper>
      <Text
        as="h2"
        css={{
          marginBottom: '$24',
          fontSize: '$16',
          lineHeight: '1em',
          '@bp2': {
            fontSize: '$16',
            marginBottom: '$24',
          },
        }}
      >
        {<String id="onboarding_industries_title" />}
      </Text>

      {props.isDataSet ? (
        <>
          <SelectTags
            onToggleButtonClick={props.onIndustriesSelect}
            list={props.industriesList}
          ></SelectTags>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
          <Button
            actionType="onclick"
            isDisabled={isBtnDisabled == -1}
            onClick={props.onClickNext}
            colorTheme="normal"
            label={<String id="save_and_continue_btn" />}
            type="normal"
            isLoading={props.isSaving}
            css={{
              width: '100%',
              textTransform: 'uppercase',
              marginTop: '$40',
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
              '@bp2': {
                width: 'auto',
                display: 'flex',
                marginLeft: '0',
                marginRight: '0',
              },
            }}
          />
        </>
      ) : (
        <>
          <LoaderSkeleton height="50px" width="100%" isRadius />
          <LoaderSkeleton height="50px" width="300px" isRadius />
        </>
      )}
    </SelectIndustriesStepWrapper>
  );
};

export default SelectIndustriesStep;
