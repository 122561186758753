import React from 'react';
import { HeadingStyled, PageIntro, WrapperPage } from './Favorites.styled';

import AuthContext from '@src/context/AuthContext';
import FavoritesContent from './FavoritesContent';
import Layout from '@components/organisms/layout/Layout';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';

const Favorites = context => {
  return (
    <ProtectedRoute redirectTo="/login" userAccessReq={['auth', 'verified']}>
      <Layout location={context.location}>
        <SEO title="Favorites" slug="favorites" seomatic={context?.pageContext?.entry?.seomatic} />
        <WrapperPage>
          <HeadingStyled level="h2">
            <String id="favorites_page_title" />
          </HeadingStyled>
          {/* <PageIntro>
            <String id="favorites_page_intro" />
          </PageIntro> */}
          <AuthContext.Consumer>
            {auth => (
              <FavoritesContent
                auth={auth}
                context={context}
                // userData={{
                //   username: profileData.username,
                //   email: profileData.email,
                //   id: profileData.id,
                // }}
              />
            )}
          </AuthContext.Consumer>
        </WrapperPage>
      </Layout>
    </ProtectedRoute>
  );
};

export default Favorites;
