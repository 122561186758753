import { CreateTypeCardsWrapper, PageWrapper } from './Create.styled';
import React, { useState, useContext } from 'react';

import Button from '@components/atoms/button/Button';
import CreateTypeCard from '@components/molecules/createTypeCard/CreateTypeCard';
import CreateWarning from './sections/CreateWarning';
import Layout from '@components/organisms/layout/Layout';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import Modal from '@components/atoms/modal/Modal';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import { navigate } from 'gatsby-link';
import AuthContext from '@src/context/AuthContext';
import useModal from '@components/atoms/modal/useModal';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_CREATE_TC_ACCEPTED } from '@src/apollo/mutation';

function Create(props) {
  const { isShowing, toggle } = useModal();
  const [modalType, setModalType] = useState();
  const authContext = useContext(AuthContext);

  const [setCreateTCAccepted, createTCAcceptedData] = useMutation(
    UPDATE_USER_CREATE_TC_ACCEPTED
  );

  const isSuperMember =
    authContext.generalRole === 'admin' ||
    authContext.generalRole === 'editor' ||
    authContext.generalRole === 'contributor';

  const CREATE_TYPES = [
    'artwork',
    'article',
    'tutorial',
    'tutorial_series',
    ...(isSuperMember ? ['asset'] : []),
  ];

  function onCardClick(type) {
    if (!authContext.createTermsAndConditionsAccepted) {
      setModalType(type);
      toggle();
    } else {
      navigate(`/create/build/${type}`);
    }
  }

  return (
    <ProtectedRoute
      redirectTo="/login"
      userAccessReq={['auth', 'verified']}
      isOnboarded={authContext.isOnboarded}
    >
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <Layout location={props.location}>
        <SEO
          title="Create"
          slug="create"
          seomatic={props?.pageContext?.entry?.seomatic}
        />
        <Modal
          isShowing={isShowing}
          hide={() => {
            toggle();
            setModalType();
          }}
        >
          <CreateWarning
            gotoNextSection={() => {
              authContext.updateCreateTermsAndConditionsAccepted(true);
              setCreateTCAccepted();
              navigate(`/create/build/${modalType}`);
            }}
            type={modalType}
          />
        </Modal>
        <PageWrapper>
          <ListingHeader
            title="page_create_title"
            intro="page_create_intro"
            isCMSDynamic
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              actionType="internalLink"
              internalUrl="/create/draft"
              label={<String id="create_draft_label" />}
              svgIcon="draft"
            />
          </div>
          <CreateTypeCardsWrapper>
            {CREATE_TYPES.map(type => (
              <CreateTypeCard key={type} type={type} onClick={onCardClick} />
            ))}
          </CreateTypeCardsWrapper>
        </PageWrapper>
      </Layout>
    </ProtectedRoute>
  );
}

export default Create;
