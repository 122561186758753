import {
  BoxDescription,
  BoxHeaderWrapper,
  BoxList,
  BoxListItem,
} from './ProfileMessage.styled';
import React, { useContext, useEffect } from 'react';
import Box from '@components/atoms/box/Box';
import { Heading } from '@components/atoms/heading/Heading';
import ProfileCompletionContext from '@context/ProfileCompletionContext';
import ProgressBar from '@components/molecules/progressBar/ProgressBar';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';

const ProfileMessage = ({ data }) => {
  const [profileCompletionState, setProfileCompletionState] = useContext(
    ProfileCompletionContext
  );

  return (
    <Box
      color="grey"
      radius="medium"
      css={{
        padding: '$24',
        marginBottom: '$24',
        '@bp2': {
          padding: '$32 $48',
          marginBottom: '$24',
        },
        '@bp3': {
          padding: '$32 $54',
          marginBottom: '$32',
        },
      }}
    >
      <BoxHeaderWrapper>
        <Heading
          css={{ marginBottom: '$8', fontFamily: '$ArtifaktLegendBold' }}
          level="h4"
        >
          <String id="profile_message_incomplete_title" />
        </Heading>
        <BoxDescription size={14}>
          <String id="profile_message_info_description" />
        </BoxDescription>
        <BoxList>
          <ul>
            {!profileCompletionState.avatar && (
              <BoxListItem>
                <Svg
                  css={{
                    fill: '$mainTextColor',
                    height: '$16',
                    width: '$16',
                    paddingLeft: '$14',
                  }}
                  icon="arrow-right-short"
                />
                <String id="profile_message_incomplete_avatar" />
              </BoxListItem>
            )}
            {!profileCompletionState.aboutMe && (
              <BoxListItem>
                <Svg
                  css={{
                    fill: '$mainTextColor',
                    height: '$16',
                    width: '$16',
                    paddingLeft: '$14',
                  }}
                  icon="arrow-right-short"
                />
                <String id="profile_message_incomplete_bio" />
              </BoxListItem>
            )}
            {!profileCompletionState.tools && (
              <BoxListItem>
                <Svg
                  css={{
                    fill: '$mainTextColor',
                    height: '$16',
                    width: '$16',
                    paddingLeft: '$14',
                  }}
                  icon="arrow-right-short"
                />
                <String id="profile_message_incomplete_tools" />
              </BoxListItem>
            )}

            {!profileCompletionState.industries && (
              <BoxListItem>
                <Svg
                  css={{
                    fill: '$mainTextColor',
                    height: '$16',
                    width: '$16',
                    paddingLeft: '$14',
                  }}
                  icon="arrow-right-short"
                />
                <String id="profile_message_incomplete_industries" />
              </BoxListItem>
            )}
          </ul>
          <ProgressBar completed={profileCompletionState.profile_completion} />
        </BoxList>
      </BoxHeaderWrapper>
    </Box>
  );
};

export default ProfileMessage;
