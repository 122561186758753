import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';

export const StyledHeading = styled(Text, {
  fontSize: '30px',
  marginTop: '$16',
  color: '$white',
  fontFamily: '$ArtifaktLegendBold',
  lineHeight: '1',
  marginBottom: '$8',
  '@bp2': {
    fontSize: '$32',
  },
});

export const StyledText = styled(Text, {
  fontSize: '$14',
  color: '$white',
  '@bp2': {
    maxWidth: '486px',
  },
});

export const StyledButton = styled(Button, {
  position: 'relative',
  fontSize: '$14',
  '&.transparent': {
    textTransform: 'none',
    color: 'white',
    fontFamily: '$ArtifaktLegendMedium',
    // padding: 0,
    justifyContent: 'center',
    transition: 'color .2s ease-out',
    svg: {
      alignSelf: 'center',
      fill: '$white',
      transition: 'fill .2s ease-out',
    },

    '&:hover': {
      transition: 'background-color .2s ease-out',
      color: '$blackToWhite',

      svg: {
        fill: '$blackToWhite',
      },
    },
  },
  // '&:hover': {
  //   svg: { opacity: 0.5 },
  // },
  '@bp1': {
    fontSize: '$14',
    '&.transparent': {
      // marginLeft: '$24',
    },
  },
});

export const CourseThumbnailCard = styled('div', {
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  background: '$greyBox',
  color: '$white',
  br: 18,
  width: '100%',
  padding: '$16',
  minHeight: '424px',
  '@bp2': {
    minHeight: '380px',
    padding: '$40',
  },

  '.course_thumbnail_icons': {
    marginTop: 'auto',
    gap: '15px',
  },

  '.course_thumbnail_actions': {
    marginTop: 'auto',
    justifyContent: 'space-between',
    '@bp1': {
      justifyContent: 'flex-start',
    },
  },
});

export const ImageWrapper = styled('div', {
  width: '$54',
  height: '$54',
  br: 100,
  overflow: 'hidden',
  boxShadow: '$2',
});
