import { MainSection, MainSectionWrapper } from './AdminLayout.styled';
import { darkThemeMode, theme } from '@root/stitches.config';

import AdminHeader from './adminHeader/AdminHeader';
import AdminSidebar from './adminSidebar/AdminSidebar';
import AuthContext from '@context/AuthContext';
import PropTypes from 'prop-types';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import React from 'react';
import { useContext } from 'react';
import { useIsMobile } from '@src/utils/useIsMobile';

const AdminLayout = ({ children, className, location, roles }) => {
  const authContext = useContext(AuthContext);
  const isMobile = useIsMobile();

  return (
    <>
      <ProtectedRoute
        redirectTo="/404"
        userAccessReq={['auth', authContext.generalRole, roles || ['admin']]}
      >
        <AdminHeader siteTitle="Area - Admin Cms" />
        <MainSectionWrapper>
          <MainSection isMobile={isMobile} id="body-wrapper">
            <AdminSidebar
              location={location}
              adminRole={authContext.generalRole}
              isMobile={isMobile}
            />
            <div
              style={{
                width: '100%',
                paddingLeft: `${isMobile ? '' : theme.sizes[48]}`,
              }}
            >
              {children}
            </div>
          </MainSection>
        </MainSectionWrapper>
      </ProtectedRoute>
    </>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

AdminLayout.defaultProps = {
  className: '',
};

export default AdminLayout;
