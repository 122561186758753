import { TagTextStyle, TagWrapper, TagWrapperLink } from './Tag.styled';

import PropTypes from 'prop-types';
import React from 'react';

const Tag = props => {
  return (
    <>
      {props.url ? (
        <TagWrapperLink to={props.url}>
          <TagTextStyle size={12}>{props.label}</TagTextStyle>
        </TagWrapperLink>
      ) : (
        <TagWrapper color={props.color}>
          <TagTextStyle size={12}>{props.label}</TagTextStyle>
        </TagWrapper>
      )}
    </>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  isSmall: PropTypes.bool,
};

Tag.defaultProps = {
  url: undefined,
};

export default Tag;
