import { validImageUrl } from '@src/templates/create/utils';
import { validURL } from '@src/utils/validURL';

export const getAvatarImage = (imgObj, size) => {
  if (!imgObj) return;
  if (imgObj.path === null) return;
  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };
  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };

  const isLocalImageString = imgObj.path?.split('/')[0];

  const isTestImg = () => {
    if (
      isExactMatch(isLocalImageString, 'images') ||
      imgObj.path?.includes('http')
    )
      return true;
  };

  if (isTestImg()) {
    return {
      src: validURL(imgObj.path) ? `${imgObj.path}` : `${'/'}${imgObj.path}`,
    };
  }

  if (!imgObj.hasOwnProperty('sizes') || !imgObj.sizes.length) {
    return imgObj?.path;
  }

  switch (size) {
    case 'xsmall':
      return imgObj?.sizes[4].path;

    case 'small':
      return imgObj?.sizes[3].path;

    case 'medium':
      return imgObj?.sizes[2].path;

    case 'large':
      return imgObj?.sizes[1].path;

    case 'xlarge':
      return imgObj?.sizes[0].path;

    default:
  }
};
