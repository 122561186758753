const learnDatas = {
  courseListDatas: [
    {
      staticImage: {
        alt: 'Intro to 3ds Max',
        src: {
          src: '/images/software_logos/3ds-max.png',
        },
      },
      title: 'Intro to 3ds Max',
      intro:
        'Looking for a 3ds Max course that takes you from A-to-Z? From finding your way around the interface, to pipeline integration, and everything in between - get up to speed in 3ds Max.',
      actions: [
        {
          label: 'Take the course',
          colorTheme: 'white',
          externalUrl: 'https://area.autodesk.com/learn/3dsmax-intro/',
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImage: {
        alt: 'Intro to Maya',
        src: {
          src: '/images/software_logos/maya.png',
        },
      },
      title: 'Intro to Maya',
      intro:
        'Welcome to the Maya course that takes you from interface to integrated pipeline. From modeling basics, to diving into Bifrost, and everything in between – get up to speed with Maya.',
      actions: [
        {
          label: 'Take the course',
          colorTheme: 'white',
          externalUrl: 'https://area.autodesk.com/learn/maya-intro/',
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
  ],
};

export default learnDatas;
