import { RadioFilterWrapper, RadioOptions } from '../AdvanceFilters.styled';

import { Heading } from '@components/atoms/heading/Heading';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import InputRadio from '@components/atoms/input/inputRadio/InputRadio';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const RadioFilter = props => {
  return (
    <RadioFilterWrapper>
      <Text
        css={{
          textTransform: 'uppercase',
          color: '$mediumGrey',
          marginBottom: '$8',
        }}
        size="14"
        level="h6"
        weight="bold"
      >
        {props.title}
      </Text>
      <RadioOptions>
        <InputRadio
          css={{
            fontSize: '14px',
          }}
          options={props.options}
          onChange={props.onChange}
          selectedValue={props.selectedValue}
          name={props.name}
        ></InputRadio>
      </RadioOptions>
    </RadioFilterWrapper>
  );
};

RadioFilter.propTypes = {};

export default RadioFilter;
