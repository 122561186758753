import {
  CreateEditorInfoWrapper,
  CreateEditorWrapper,
  CreateInfoWrapper,
  EditorBuilder,
  EditorBuilderBlocks,
  EditorHeader,
  ImageWrapper,
} from './CreateEditor.styled';
import React, { useEffect, useState } from 'react';

import EditorAddBlocks from '../editorAddBlocks/EditorAddBlocks';
import EditorBlock from '../editorBlock/EditorBlock';
import { Heading } from '@components/atoms/heading/Heading';
import Image from '@components/atoms/image/Image';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { formatDate } from '@src/utils/formatDate';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import Avatar from '@components/atoms/avatar/Avatar';
import AssetBlock from '../editorBlock/blocks/assetBlock/AssetBlock';

const MAX_CONTENT_BLOCKS = 10;

const CreateEditor = props => {
  const onAddBlock = (type, { url, imageData }, callback) => {
    if (type === 'file') {
      const newBlock = {
        type: 'asset',
        asset: {
          file: null,
          title: '',
        },
      };
      props.onAddBlock(newBlock);
    }
    if (type === 'text') {
      const newBlock = {
        type: 'text',
        text: '',
      };
      props.onAddBlock(newBlock);
    }

    if (type === 'image') {
      const newBlock = {
        type: 'image',
        data: imageData,
        image: imageData.file,
      };
      props.onAddBlock(newBlock);
    }

    if (type === 'video') {
      const newBlock = {
        type: 'video',
        videoUrl: url,
      };
      props.onAddBlock(newBlock);
    }

    if (type === 'sketchfab') {
      const newBlock = {
        type: 'sketchfab',
        sketchfabUrl: url,
      };
      props.onAddBlock(newBlock);
    }

    if (type === 'content') {
      const newBlock = {
        type: 'content',
      };
      props.onAddBlock(newBlock);
    }
  };

  const userContentTypesAllowedUCBlock = ['tutorial_series', 'article'];
  const [hideContentFeature, setHideContentFeature] = useState(
    !userContentTypesAllowedUCBlock.includes(props.createState.createType)
  );

  useEffect(() => {
    if (props.createState.blockList.length >= 1) {
      const contentBlocks = props.createState.blockList.filter(
        block => block.type === 'content'
      );
      if (contentBlocks.length >= MAX_CONTENT_BLOCKS) {
        setHideContentFeature(true);
      } else {
        setHideContentFeature(false);
      }
    }
  }, [props.createState.blockList]);

  const avatar = props.creatorData
    ? getAvatarImage(props.creatorData.profilePicture, 'medium')
    : null;

  return (
    <CreateEditorWrapper>
      <EditorHeader>
        <Heading level="h1">{props.title}</Heading>
        <CreateEditorInfoWrapper>
          {props.creatorData?.profilePicture !== undefined &&
            props.creatorData?.profilePicture !== null && (
              <ImageWrapper>
                <Avatar
                  alt={`${props.creatorData.name}`}
                  src={avatar}
                  size="medium"
                />

                <div style={{ marginLeft: '8px' }}>
                  <Text size="16" weight="bold">
                    {props.creatorData.name}
                  </Text>
                  <Text size="12">{props.creatorData.title}</Text>
                </div>
              </ImageWrapper>
            )}
          <CreateInfoWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Svg icon="calendar" />
              <Text size="12">
                {formatDate(
                  new Date(
                    props.createAdminFormState.authoring.publishedDate
                  ).valueOf() / 1000
                )}
              </Text>
            </div>
          </CreateInfoWrapper>
        </CreateEditorInfoWrapper>
      </EditorHeader>
      <EditorBuilderBlocks>
        {props.createState.createType === 'asset' && (
          <AssetBlock
            currentFileName={props.createState.blockList[0]?.asset?.filename}
            currentFileSizeKb={
              props.createState.blockList[0]?.asset?.size
                ? (props.createState.blockList[0].asset.size / 1024).toFixed(0)
                : undefined
            }
            onChange={props.onAssetChange}
          />
        )}
        {props.createState.blockList.map((block, i) => {
          if (block.type === 'asset') {
            return null;
          } else {
            return (
              <EditorBlock
                onEditBlock={props.onEditBlock}
                type={block.type}
                key={block.id + block.slug}
                block={block}
                blockIndex={i}
                onChangeBlockChangeOrder={props.onChangeBlockChangeOrder}
                blockOrder={`block${block.order}`}
                onRemoveBlock={() => props.onRemoveBlock(i, block.id)}
                onAddBlock={onAddBlock}
                onEditVideoBlock={props.onEditVideoBlock}
                onEditSketchfabBlock={props.onEditSketchfabBlock}
                postId={props.postId}
                isAddingBlockDone={props.isAddingBlockDone}
                createType={props.createState.createType}
                onEditUserContentblock={props.onEditUserContentblock}
                totalBlocks={props.createState.blockList.length}
              />
            );
          }
        })}
      </EditorBuilderBlocks>
      {props.createState.blockList.length < props.createState.maxBlocks ? (
        <EditorBuilder>
          <EditorAddBlocks
            hasBlocks={props.createState.blockList.length >= 1}
            hideContentFeature={
              !userContentTypesAllowedUCBlock.includes(
                props.createState.createType
              ) || hideContentFeature
            }
            createType={props.createState.createType}
            onAddBlock={onAddBlock}
            isAddingBlockDone={props.isAddingBlockDone}
          />
        </EditorBuilder>
      ) : null}
    </CreateEditorWrapper>
  );
};

export default CreateEditor;
