import {
  Block,
  BlockImageWrapper,
  CreateSidebarContent,
  EditorStepBlocks,
  EditorStepHeader,
} from './CreateSidebar.styled';
import React, { useContext, useEffect } from 'react';

import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import ImageUploading from 'react-images-uploading';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';

function CreateCoverStep(props) {
  const {
    imageBlockList,
    currentImageCropIndex,
    onChangeImageCropIndex,
    images,
    onDrop,
    isMobile,
  } = props;

  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  function blockKey(block) {
    const isCover = block?.data?.file?.name === 'image-cover.jpeg';
    if (isCover && !block.id) {
      return 'cover';
    }
    if (!isCover && !block.id) {
      return 'uploaded-image';
    }
    return block.id;
  }

  return (
    <CreateSidebarContent currentStep="cover">
      <EditorStepHeader>
        <Text size="16" weight="bold">
          <String id="create_sidebar_crop_change_cover" />
        </Text>
      </EditorStepHeader>
      <EditorStepBlocks isMobile={isMobile}>
        {imageBlockList.map((block, i) => {
          const isCover = block?.data?.file?.name === 'image-cover.jpeg';
          const isActive = currentImageCropIndex === i ? 'isActive' : null;
          return (
            <Block
              isActive={isActive}
              isCover={isCover && !isActive}
              key={blockKey(block)}
              onClick={onChangeImageCropIndex.bind(null, i, isCover)}
            >
              <BlockImageWrapper>
                <img src={block.data.data_url} />
              </BlockImageWrapper>
            </Block>
          );
        })}

        <ImageUploading
          value={images}
          multiple
          onChange={onDrop}
          dataURLKey="data_url"
          resolutionType="more"
          resolutionWidth="400"
          resolutionHeight="400"
          onError={e => {
            if (e.resolution) {
              handleUpdateClientNotification({
                type: 'error',
                text: 'image_resolution_too_small',
                autoDismiss: true,
              });
            }
          }}
        >
          {({ onImageUpload, dragProps }) => (
            <Block onClick={onImageUpload} css={{ cursor: 'pointer' }} isCover>
              <Svg icon="plus" />
            </Block>
          )}
        </ImageUploading>
      </EditorStepBlocks>
    </CreateSidebarContent>
  );
}

CreateCoverStep.propTypes = {
  imageBlockList: PropTypes.array.isRequired,
  currentImageCropIndex: PropTypes.number.isRequired,
  onChangeImageCropIndex: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  onDrop: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

CreateCoverStep.defaultProps = {
  isMobile: false,
};

export default CreateCoverStep;
