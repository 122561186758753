import { styled } from '@root/stitches.config';

export const TabsWrapper = styled('div', {
  width: '100%',

  '.swiper-slide': {
    width: 'auto !important',
    mx: 20,
    position: 'relative',
    // display: 'flex',
    // justifyContent: 'center'
  },
});

export const TabNavWrapper = styled('ul', {
  position: 'relative',
  //borderBottom: '1px solid $lightGrey',
  //overflow: 'hidden',
  display: 'flex',
  width: '100% ',

  '@bp2': {
    justifyContent: 'center',
  },

  '&::before': {
    content: `""`,
    display: 'block',
    background: '$lightGrey',
    position: 'absolute',
    height: '1px',
    bottom: '0',
    left: '0',
    width: '100%',
  },
});

export const PanelWrapper = styled('section', {
  padding: '$32',
});

export const TabButton = styled('a', {
  fontFamily: 'ArtifaktElementBold',
  textDecoration: 'none',
  color: '$mediumGrey',
  outline: 'none',
  border: '0',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    color: '$mediumGrey',
    transition: 'color .3s ease',
  },
  variants: {
    isActive: {
      true: {
        color: '$mainTextColor',
        fontFamily: 'ArtifaktElementExtraBold',
        '&::after': {
          content: `""`,
          display: 'block',
          background: '$mainTextColor',
          position: 'absolute',
          height: '2px',
          bottom: '-10px',
          left: '0',
          width: '100%',
        },
      },
    },
  },
});

export const TabListItem = styled('li', {
  // marginRight: '$24',
  marginBottom: '$8',
  display: 'inline-block',
});
