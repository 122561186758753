import {
  NoResultContainer,
  PaginationWrapper,
  TitleSignleContent,
  WrapperThumbnail,
} from '../Search.styled';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@src/context/AuthContext';
import { Heading } from '@components/atoms/heading/Heading';
import Loader from '@components/atoms/loader/Loader';
import MultipleContent from './MultipleContent';
import Pagination from '@components/atoms/pagination/Pagination';
import { SEARCH } from '@src/apollo/query';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import UserThumbnail from '../thumbnail/UserThumbnail';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import setUsers from '@src/utils/setUsers';
import { useLazyQuery } from '@apollo/client';
import String from '@components/atoms/string/String';

const SingleContent = ({ filters, search }) => {
  const authContext = useContext(AuthContext);

  const [pageState, setPageState] = useState({
    activePage: 1,
    totalPage: 1,
  });

  const [filtersState, setFiltersState] = useState({
    isActive: filters.isActive,
    isDataSet: false,
    dropdownFilters: filters.dropdownFilters,
  });

  const [contentState, setContentState] = useState({
    content: [],
    isDataSet: false,
  });

  useEffect(() => {
    setFiltersState({
      ...filtersState,
      isActive: filters.isActive,
    });
  }, [filters]);

  const updateUrl = (filtersData, page) => {
    const keywords = search.keywords;
    const types = search.types;
    let url = `/search?query=${keywords}&type=${types}`;

    if (filtersData?.tools.length > 0) {
      url += `&tools=${filtersData.tools}`;
    }

    if (filtersData?.industries.length > 0) {
      url += `&industries=${filtersData.industries}`;
    }

    if (!isNaN(page)) {
      url += `&page=${page}`;
    }

    window.history.replaceState({}, '', url);
  };

  useEffect(() => {
    const filtersData = setFiltersQuery();
    getResearch({
      variables: {
        keywords: search.keywords,
        types: [search.types === 'members' ? 'users' : search.types],
        limit: 12,
        page: search.page,
        input: filtersData,
      },
    });

    updateUrl(filtersData, search.page);
    setPageState({ ...pageState, activePage: 1 });
  }, [filtersState]);

  useEffect(() => {
    const sendReasearch = async () => {
      const filtersData = setFiltersQuery();
      await getResearch({
        variables: {
          keywords: search.keywords,
          types: [search.types === 'members' ? 'users' : search.types],
          limit: 12,
          page: search.page || 1,
          input: filtersData,
        },
      });

      if (search.page) {
        setPageState({
          ...pageState,
          activePage: search.page,
        });
      }
      updateUrl(filtersData, search.page);
    };

    search.isSearch ? sendReasearch() : null;
  }, [search.keywords, search.types, search.page]);

  // search
  const refreshSearch = async activePage => {
    const filtersData = setFiltersQuery();
    await getResearch({
      variables: {
        keywords: search.keywords,
        types: [search.types === 'members' ? 'users' : search.types],
        limit: 12,
        page: activePage,
        input: filtersData,
      },
    });
    updateUrl(filtersData, activePage);
    setContentState({ ...contentState, isDataSet: false });
  };

  const [
    getResearch,
    { data: dataSearch, loading: loadingSearch },
  ] = useLazyQuery(SEARCH, {
    context: { clientName: 'public' },
  });

  const setContentData = data => {
    Object.keys(data).map(item => {
      if (item !== '__typename' && data[item]) {
        setContentState({
          content: item === 'users' ? setUsers(data[item]) : data[item].data,
          isDataSet: true,
        });
        const totalPage = Math.ceil(data[item].total / data[item].per_page);
        setPageState({ ...pageState, totalPage });
      }
    });
  };

  useEffect(() => {
    if (search.isSearch && dataSearch) {
      setContentData(dataSearch.search);
    }
  }, [search.isSearch, dataSearch]);

  const setFiltersQuery = () => {
    const { dropdownFilters: data } = filtersState;
    const { isActive: dataSelected } = filtersState;

    const createFilter = {};
    data.forEach(e => {
      const { options } = e;
      switch (e.filterKey) {
        case 'tools':
          const matchToolsFilter = dataSelected?.find(f => f.id == e.filterKey);
          matchToolsFilter?.selectedValues?.length > 0
            ? (createFilter.tools = matchToolsFilter?.selectedValues?.map(e => {
                return parseInt(e);
              }))
            : (createFilter.tools = []);
          break;
        case 'industries':
          const matchIndustriesFilter = dataSelected?.find(
            f => f.id == e.filterKey
          );
          matchIndustriesFilter?.selectedValues?.length > 0
            ? (createFilter.industries = matchIndustriesFilter?.selectedValues?.map(
                e => {
                  return parseInt(e);
                }
              ))
            : (createFilter.industries = []);
          break;
        default:
          null;
      }
    });
    return createFilter;
  };

  // pagination
  const changePage = activePage => {
    if (activePage === pageState.activePage) {
      return;
    }
    setPageState({ ...pageState, activePage });
    refreshSearch(activePage);
  };

  const isLoadedAndHasData =
    contentState.isDataSet && !loadingSearch && contentState.content.length > 0;

  const isLoadedAndHasNoData =
    contentState.isDataSet &&
    !loadingSearch &&
    contentState.content.length == 0;

  return (
    <>
      <TitleSignleContent level="h1">
        {<String id={`search_content_title_${search.types}`} />}
      </TitleSignleContent>

      {isLoadedAndHasData && (
        <>
          <WrapperThumbnail>
            {contentState.content.map((item, i) => {
              if (search.types === 'members') {
                return <UserThumbnail key={`user${i}`} {...item} />;
              } else {
                return (
                  <Thumbnail
                    key={item.id}
                    {...dataToThumbnailViewModel(
                      item,
                      false,
                      authContext.isAuthenticated
                    )}
                  />
                );
              }
            })}
          </WrapperThumbnail>
          <PaginationWrapper>
            <Pagination
              isUrlLink={false}
              activePage={pageState.activePage}
              onCurrentPageChange={e => changePage(e)}
              pages={pageState.totalPage}
            />
          </PaginationWrapper>
        </>
      )}

      {isLoadedAndHasNoData && (
        <>
          <NoResultContainer>
            <Svg icon="no-search-result" />
            <Heading weight="bold" level="h2">
              Sorry
            </Heading>
            <Text weight="bold" size="16" css={{ marginBottom: '$16' }}>
              We couldn’t find any results for this search!{' '}
            </Text>
            <Text>
              Try typing other keywords or browse amongst those suggestions :
            </Text>
          </NoResultContainer>
          <MultipleContent isRecommendedSearch={true} filters={filters} />
        </>
      )}

      {loadingSearch && (
        <NoResultContainer>
          <Loader isLoading isSection />
        </NoResultContainer>
      )}
    </>
  );
};

export default SingleContent;
