export function dataToLinkThumbnailViewModel(data) {
  return {
    staticImage: {
      alt: data?.label || 'Label',
      src: {
        src: data?.imgSrc || '/images/explore_more_1.jpg',
      },
    },
    link: {
      actionType: 'internalLink',
      to: data?.linkTo || '/',
      label: data?.label || 'Label',
    },
    text: data?.text || 'Text',
  };
}
