import { styled } from '@root/stitches.config';

export const BoxHeaderWrapper = styled('div', {
  px: '$24',
  color: '$mainTextColor',
  '@bp2': {
    px: '$48',
  },
});
export const BoxList = styled('div', {
  fontSize: '$14',
  ul: {
    marginBottom: '$16',
    fontFamily: '$ArtifaktLegendBold',
  },
});

export const BoxListItem = styled('li', {
  display: 'flex',
  marginBottom: '$16',
  alignItems: 'center',
  marginLeft: '$12',
});

export const BoxDescription = styled('div', {
  fontSize: '$14',
  marginBottom: '$24',
});
