import { formatDate } from '@src/utils/formatDate';
import { getAvatarImage } from '@src/utils/getAvatarHelper';

const setUsers = data => {
  let users;
  if (data?.data?.length == 0) {
    return [];
  } else {
    users = data;
  }

  const usersList = [];

  for (let i = 0; i < users.data.length; i++) {
    const userData = users.data[i];

    const firstName = userData.first_name;
    const lastName = userData.last_name;
    const title = userData.title;
    const profilePicture = userData.userProfile?.profile_picture;
    const socials = getSocials(userData.userProfile);
    const locationFlag = getLocationFlagUrl(userData.location?.country);
    const location = getLocation(
      userData.location?.city,
      userData.location?.country
    );
    const profileUrl = `/m/${userData.username}`;

    const userSingle = {
      firstName,
      lastName,
      title,
      profilePicture,
      socials,
      locationFlag,
      location,
      profileUrl,
    };

    usersList.push(userSingle);
  }
  return usersList;
};

const getLocation = (city, country) => {
  let location = '';
  if (city) {
    location += `${city}, `;
  }
  if (country) {
    location += `${country}`;
  }

  return location;
};

const getLocationFlagUrl = country => {
  let flagFilename = '';
  if (country) {
    flagFilename = `/svg/flags/${country.replaceAll(' ', '-').toLowerCase()}`;
  }
  return flagFilename;
};

const getSocials = userProfile => {
  const socials = [];

  if (userProfile?.youtube_url) {
    const youtubeBtn = {
      name: 'Youtube',
      icon: 'youtube',
      url: userProfile?.youtube_url,
    };
    socials.push(youtubeBtn);
  }

  if (userProfile?.linkedin_url) {
    const linkedinBtn = {
      name: 'LinkedIn',
      icon: 'linkedin',
      url: userProfile?.linkedin_url,
    };
    socials.push(linkedinBtn);
  }

  if (userProfile?.instagram_url) {
    const instagramBtn = {
      name: 'Instagram',
      icon: 'instagram',
      url: userProfile?.instagram_url,
    };
    socials.push(instagramBtn);
  }

  if (userProfile?.behance_url) {
    const behanceBtn = {
      name: 'Behance',
      icon: 'behance',
      url: userProfile?.behance_url,
    };
    socials.push(behanceBtn);
  }

  if (userProfile?.website_url) {
    const websiteBtn = {
      name: 'Website',
      icon: 'internet',
      url: userProfile?.website_url,
    };
    socials.push(websiteBtn);
  }

  return socials;
};

export default setUsers;
