import { styled } from '@root/stitches.config';

export const ThumbnailPreview = styled('div', {
  minWidth: '300px',
  width: '100%',
  height: '380px',
  position: 'relative',
  margin: '0',
  marginBottom: '$32',
  '&:before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    position: 'absolute',
    zIndex: '2',
    borderRadius: '$16',
  },
  '@bp2': {
    width: '300px',
    marginLeft: '$32',
  },
  variants: {
    currentCover: {
      true: {
        marginLeft: '0',
      },
    },
  },
});

export const ActionButton = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  mb: '$24',
  paddingLeft: '20%',
  '@bp2': {
    width: '30%',
    mb: '0',
    paddingLeft: '0%',
  },
});

export const RightButtonSide = styled('div', {
  textAlign: 'left',
  marginLeft: '$16',
});

export const CreateCropWrapper = styled('div', {
  minHeight: '70vh',
  padding: '$32 $24',
  maxWidth: '1100px',
  mx: 'auto',
  '@bp2': {
    padding: '$54 $24',
  },
});

export const CropAndPreviewWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@bp2': {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const UploadCover = styled('div', {
  position: 'relative',
  width: '100%',
  h3: {
    marginBottom: '$32',
    '@bp2': { marginBottom: '$64' },
  },
});

export const UploadCoverWrapper = styled('div', {
  maxWidth: '900px',
  mx: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  '.light-mode &': {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='hsla(0, 0%, 0%, 1)' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  '.dark-mode &': {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='hsl(0, 100%, 100%)' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  p: '$32',
  width: '100%',
  mt: '$48',
  mb: '$48',
  '@bp2': {
    p: '$84',
    width: '80%',
    mb: '0',
  },
});

export const CropZoneWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})