import { gql } from '@apollo/client';
import {
  ARTICLES_FRAGMENT,
  GROUP_MEMBER_FRAGMENT,
  MOTM_FRAGMENT,
  POST_DATA_FRAGMENT,
  TUTORIALS_FRAGMENT,
  USERCONTENT_DATA_FRAGMENT,
  USERCONTENT_PAGINATION_FRAGMENT,
  USER_FRAGMENT,
} from './fragments';

export const GET_PROFILE_INFO = gql`
  query GetProfileInfo($username: String!) {
    user(username: $username) {
      first_name
      last_name
      title
      gender
      username
      profile_completion
      aboutMe
      is_internal
      id
      location {
        country
        city
        country
        state
      }
      userProfile {
        youtube_url
        linkedin_url
        instagram_url
        behance_url
        website_url
        navigation_tab
        profile_picture {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
        aboutMeOrder
        schoolsOrder
        companiesOrder
        projectsOrder
      }
      industries {
        id
        name
      }
      tools {
        id
        title
        icon {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
      }
      companies {
        id
        name
        image {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
        is_current
        job_title
        order
        dateStart
        dateEnd
        status
        location {
          country
          city
          state
          placeId
        }
      }
      schools {
        id
        name
        icon {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
        is_current
        course
        order
        status
        dateStart
        dateEnd
        location {
          country
          city
          state
          placeId
        }
      }
      projects {
        id
        url
        title
        image {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
        job_title
        order
        dateStart
        dateEnd
        location {
          country
          city
          state
          placeId
        }
      }
    }
  }
`;

export const GET_PROFILE_SETTINGS_INFO = gql`
  ${USER_FRAGMENT}
  query GetProfileInfo($username: String!) {
    user(username: $username) {
      ...UserFragment
      email
      createdAt
    }
  }
`;

export const GET_USER_DATA = gql`
  ${USER_FRAGMENT}
  query GetCreatorData($username: String!) {
    user(username: $username) {
      ...UserFragment
      createdAt
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query GetIndustriesAndTools {
    industries {
      id
      name
    }
  }
`;

export const GET_TOOLS = gql`
  query GetTools {
    tools {
      id
      title
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
  }
`;

export const GET_ASSETS_TYPES = gql`
  query GetAssetsTypes {
    assetTypes {
      id
      name
    }
  }
`;

export const GET_INDUSTRIES_AND_TOOLS = gql`
  query GetIndustriesAndTools {
    industries {
      id
      name
    }
    tools {
      id
      title
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
  }
`;

export const GET_INDUSTRIES_TOOLS_LEVELS = gql`
  query GetFiltersInfos {
    industries {
      id
      name
    }
    tools {
      id
      title
    }
    tutorialLevels {
      id
      name
    }
  }
`;

export const GET_CREATE_DETAIL = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  ${MOTM_FRAGMENT}
  query GetWork($slug: String, $author_username: String, $status: status) {
    userContent(
      slug: $slug
      author_username: $author_username
      status: $status
    ) {
      ...UserContentDataFragment
      ...ArticlesFragment
      ...TutorialsFragment
      ...MotmFragment
      asset_type {
        id
        name
      }
      blocs {
        type
        order
        text
        image {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
        videoUrl
        sketchfabUrl
        id
        slug
        asset {
          filename
          size
          id
          path
        }
        contents {
          order
          ...UserContentDataFragment
          ...ArticlesFragment
          ...TutorialsFragment
          ...MotmFragment
          creator {
            ...UserFragment
          }
        }
      }
      creator {
        ...UserFragment
      }
    }
  }
`;

export const GET_CREATE_INFO = gql`
  query GetCreateInfos {
    industries {
      id
      name
    }
    tools {
      id
      title
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
    assetTypes {
      id
      name
    }
    articleSections {
      id
      name
    }
    articleTypes {
      id
      name
    }
    tutorialTypes {
      id
      name
    }
    tutorialLevels {
      id
      name
    }
  }
`;

export const GET_USER_TOOLS_INDUSTRIES = gql`
  query GetUserToolsIndustries($username: String!) {
    user(username: $username) {
      industries {
        id
        name
      }
      tools {
        id
        title
        icon {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
      }
    }
  }
`;

export const GET_USERCONTENTS = gql`
  ${USER_FRAGMENT}
  ${USERCONTENT_DATA_FRAGMENT}
  ${USERCONTENT_PAGINATION_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${MOTM_FRAGMENT}
  query userContents(
    $limit: Int
    $page: Int
    $creator_id: Int
    $content_type: [type]
    $status: [status]
    $feature: Boolean
    $search: String
    $filters: userContentFilterInput
    $order: String
  ) {
    userContents(
      limit: $limit
      page: $page
      creator_id: $creator_id
      content_type: $content_type
      status: $status
      feature: $feature
      search: $search
      filters: $filters
      order: $order
    ) {
      data {
        ...UserContentDataFragment
        ...TutorialsFragment
        ...ArticlesFragment
        ...MotmFragment
        creator {
          ...UserFragment
        }
      }
      ...UserContentPaginationFragment
    }
  }
`;

export const GET_PROFILE_WORK = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USERCONTENT_PAGINATION_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  ${USER_FRAGMENT}
  query userContents(
    $id: Int
    $limit: Int
    $page: Int
    $content_type: [type]
    $status: [status]
    $filters: userContentFilterInput
  ) {
    userContents(
      creator_id: $id
      limit: $limit
      page: $page
      content_type: $content_type
      status: $status
      order: "recent"
      filters: $filters
    ) {
      data {
        ...UserContentDataFragment
        ...ArticlesFragment
        ...TutorialsFragment
        feature
        creator {
          ...UserFragment
        }
        blocs {
          id
          type
          contents {
            id
          }
        }
      }
      ...UserContentPaginationFragment
    }
  }
`;

export const GET_ALL_GROUPS = gql`
  query GetAllGroups {
    groups {
      id
      name
      slug
      description
      header_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
  }
`;

export const CMS_GET_PAGE_DATA = gql`
  query CMSgetPageData($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      header_image_credit
      header_image_credit_link
      ... on InspireTemplate {
        userContent_feature {
          id
          slug
        }
        userContent {
          id
          slug
        }
      }
      ... on ArticlesTemplate {
        userContent_feature {
          id
          slug
        }
      }
      ... on LearnTemplate {
        userContent_feature {
          id
          slug
        }
      }
      ... on GenericTemplate {
        wysiwyg
      }
      ... on AssetsTemplate {
        userContent_feature {
          id
          slug
        }
      }
      ... on GalleryTemplate {
        userContent_feature {
          id
          slug
        }
      }
      ... on TutorialsTemplate {
        userContent_feature {
          id
          slug
        }
      }
      ... on HomeTemplate {
        userContent {
          id
          slug
        }
        userContent_feature {
          id
          slug
        }
      }
    }
  }
`;

export const GET_GROUP_BASIC_DETAILS = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${MOTM_FRAGMENT}
  ${USER_FRAGMENT}
  ${GROUP_MEMBER_FRAGMENT}
  query GetGroupDetails($slug: String!, $feed: String!) {
    group(slug: $slug) {
      id
      name
      description
      header_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      created_at
      members_count
      promotion_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      promotion_text
      promotion_url
      featured_content {
        ...UserContentDataFragment
        ...TutorialsFragment
        ...ArticlesFragment
        ...MotmFragment
        creator {
          ...UserFragment
        }
      }
      group_moderators {
        ...GroupMemberFragment
        group_role
      }
      posts(limit: 1, page: 1, feed: $feed) {
        total
      }
      latest_4_members {
        ...UserFragment
      }
    }
  }
`;

export const GET_MY_GROUPS = gql`
  query GetMyGroups {
    mygroups {
      id
      name
      slug
      description
      header_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      join_date
      group_role
    }
  }
`;

export const GET_MY_GROUPS_ID = gql`
  query GetMyGroupsId {
    mygroups {
      id
      group_role
    }
  }
`;

export const GET_POST_COMMENTS = gql`
  ${POST_DATA_FRAGMENT}
  query GetPostComment(
    $parent_id: Int
    $limit: Int!
    $page: Int!
    $feed: String
    $level: Int
    $order: String
  ) {
    posts(
      parent_id: $parent_id
      limit: $limit
      page: $page
      feed: $feed
      level: $level
      order: $order
    ) {
      data {
        ...PostDataFragment
        usersFavoriteCount
        usersFavorite {
          username
        }
        usersLikedCount
        usersLiked {
          username
        }
        parent {
          id
        }
        group {
          name
          slug
          id
        }
        children {
          id
          status
        }
      }
      total
      last_page
      current_page
      has_more_pages
    }
  }
`;
export const GET_POSTS = gql`
  query GetPosts(
    $parent_id: Int
    $group_id: Int
    $limit: Int!
    $page: Int!
    $feed: String
    $level: Int
    $group_slug: String
  ) {
    posts(
      parent_id: $parent_id
      group_id: $group_id
      limit: $limit
      page: $page
      feed: $feed
      level: $level
      group_slug: $group_slug
      order: "desc"
    ) {
      data {
        slug
      }
      total
      last_page
      current_page
      has_more_pages
    }
  }
`;

export const GET_CREATE_DRAFTS = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  query GetCreateDrafts($limit: Int, $page: Int) {
    userContents(status: [draft], limit: $limit, page: $page) {
      data {
        ...UserContentDataFragment
        creator {
          ...UserFragment
        }
      }
      total
      per_page
    }
  }
`;

export const GET_GROUPS_MEMBERS = gql`
  ${GROUP_MEMBER_FRAGMENT}
  query GetGroupMembers(
    $slug: String!
    $limit: Int
    $page: Int
    $filters: groupMemberFilterInput
  ) {
    groupMembers(slug: $slug, limit: $limit, page: $page, filters: $filters) {
      data {
        ...GroupMemberFragment
        join_date
        last_post_date
        group_posts_count
        status
        last_post_link
      }
      total
      per_page
    }
  }
`;

export const GET_TUTORIALS = gql`
  ${TUTORIALS_FRAGMENT}
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  query GetTutorials(
    $limit: Int
    $page: Int
    $tools: [Int]
    $industries: [Int]
    $levels: [Int]
    $excludedIds: [Int]
    $contentType: [type]
    $order: String
  ) {
    userContents(
      limit: $limit
      page: $page
      content_type: $contentType
      order: $order
      filters: {
        exclude_ids: $excludedIds
        tool: $tools
        industry: $industries
        tutorial_level: $levels
      }
    ) {
      data {
        ...UserContentDataFragment
        ...TutorialsFragment
        creator {
          ...UserFragment
        }
      }
      total
      per_page
    }
  }
`;

export const GET_ARTICLES = gql`
  ${ARTICLES_FRAGMENT}
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  query GetArticles(
    $limit: Int
    $page: Int
    $tools: [Int]
    $industries: [Int]
    $article_section: [Int]
    $order: String
    $excludedIds: [Int]
  ) {
    userContents(
      limit: $limit
      page: $page
      order: $order
      content_type: article
      filters: {
        tool: $tools
        industry: $industries
        article_type: 1
        article_section: $article_section
        exclude_ids: $excludedIds
      }
    ) {
      data {
        ...ArticlesFragment
        ...UserContentDataFragment
        creator {
          ...UserFragment
        }
      }
      total
      per_page
    }
  }
`;

export const GET_ARTWORKS = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  query GetArtworks(
    $limit: Int
    $page: Int
    $tools: [Int]
    $industries: [Int]
    $excludedIds: [Int]
    $editors_pick: Boolean
    $order: String
  ) {
    userContents(
      limit: $limit
      page: $page
      filters: {
        tool: $tools
        industry: $industries
        exclude_ids: $excludedIds
        editors_pick: $editors_pick
      }
      order: $order
      content_type: artwork
    ) {
      data {
        ...UserContentDataFragment
        creator {
          ...UserFragment
        }
      }
      total
      per_page
    }
  }
`;

export const GET_ASSETS = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  query GetAssets(
    $limit: Int
    $page: Int
    $tools: [Int]
    $order: String
    $assetTypes: [Int]
    $excludedIds: [Int]
  ) {
    userContents(
      limit: $limit
      page: $page
      order: $order
      filters: {
        tool: $tools
        asset_type: $assetTypes
        exclude_ids: $excludedIds
      }
      content_type: asset
    ) {
      data {
        ...UserContentDataFragment
        creator {
          ...UserFragment
        }
      }
      total
      per_page
    }
  }
`;

export const GET_POSTS_FILTERED = gql`
  ${USER_FRAGMENT}
  ${POST_DATA_FRAGMENT}
  query GetPostsFiltered(
    $groupId: Int!
    $status: String!
    $limit: Int
    $page: Int
    $input: postsFilteredFiltersInput
  ) {
    postsFiltered(
      group_id: $groupId
      status: $status
      limit: $limit
      page: $page
      filters: $input
    ) {
      ...PostDataFragment
      children {
        status
      }
      usersFavoriteCount
      usersLikedCount
      reports {
        data {
          id
          type
          createdAt
          detail
          reporter {
            ...UserFragment
          }
        }
        total
      }
    }
  }
`;

export const GET_POSTS_FILTERED_COUNT = gql`
  query GetPostsFilteredCount($groupId: Int!, $status: String!) {
    postsFilteredCount(group_id: $groupId, status: $status)
  }
`;

export const GET_ALL_USERS = gql`
  ${USER_FRAGMENT}

  query users($limit: Int, $page: Int, $input: usersFiltersInput) {
    users(
      filters: $input
      limit: $limit
      page: $page
      orderBy: { first_name: asc }
    ) {
      data {
        ...UserFragment
        createdAt
        is_onboarded
        status
        email
        posts(limit: 1, order: "desc") {
          data {
            id
            createdAt
            slug
          }
          total
        }
      }
      total
      per_page
    }
  }
`;

// TODO: $article_type: 2 = "motm" | $article_type: 3 = "moty"
export const GET_MEMBERS_OF = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  ${MOTM_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  query GetMotm(
    $limit: Int
    $page: Int
    $years: [Int]
    $months: [Int]
    $excludedIds: [Int]
    $article_type: [Int]
  ) {
    userContents(
      limit: $limit
      page: $page
      order: "recent"
      filters: {
        year: $years
        month: $months
        article_type: $article_type
        exclude_ids: $excludedIds
      }
    ) {
      data {
        ...UserContentDataFragment
        ...MotmFragment
        ...ArticlesFragment
        creator {
          ...UserFragment
        }
      }
      per_page
      total
    }
  }
`;

export const GET_YEARS = gql`
  query GetYear($article_type: [Int]) {
    userContents(filters: { article_type: $article_type }) {
      data {
        motm_year
      }
    }
  }
`;

export const GET_MIXED_CONTENT = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  ${MOTM_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  query inspireMixContentsQuery(
    $limit: Int
    $page: Int
    $types: [type]
    $filters: userContentFilterInput
  ) {
    userContents(
      limit: $limit
      page: $page
      content_type: $types
      filters: $filters
    ) {
      data {
        ...UserContentDataFragment
        ...ArticlesFragment
        ...TutorialsFragment
        ...MotmFragment
        creator {
          ...UserFragment
        }
      }
    }
  }
`;

export const GET_INSPIRE_CONTENT = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  ${MOTM_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  query inspireMixContentsQuery($limit: Int, $exclude_ids: [Int]) {
    inspirePageContent(limit: $limit, exclude_ids: $exclude_ids) {
      ...UserContentDataFragment
      ...ArticlesFragment
      ...TutorialsFragment
      ...MotmFragment
      blocs {
        type
        order
        text
        image {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
        videoUrl
        sketchfabUrl
        id
        slug
        contents {
          id
          slug
          order
        }
      }
      creator {
        ...UserFragment
      }
    }
  }
`;

export const GET_ALL_GROUPS_CMS = gql`
  query GetAllGroupsCms($limit: Int, $page: Int, $input: groupsFiltersInput) {
    groups(limit: $limit, page: $page, filters: $input) {
      data {
        id
        name
        members_count
        slug
        icon {
          path
          width
          height
          sizes {
            path
            width
            height
          }
        }
        status
        posts(limit: 1, order: "desc") {
          data {
            createdAt
          }
          total
        }
      }
      per_page
      total
    }
  }
`;

export const GET_FAVORITED_USERCONTENT = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  ${MOTM_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  query GetFavoritedUserContent(
    $username: String!
    $contentType: [type]
    $article_type: [Int]
    $page: Int
    $limit: Int
  ) {
    user(username: $username) {
      userContentsFavorited(
        content_type: $contentType
        article_type: $article_type
        page: $page
        limit: $limit
      ) {
        data {
          ...UserContentDataFragment
          ...ArticlesFragment
          ...TutorialsFragment
          ...MotmFragment
          blocs {
            type
            id
            slug
            contents {
              id
              userContentType
            }
          }
          creator {
            ...UserFragment
          }
        }
        total
        per_page
        current_page
      }
    }
  }
`;

export const GET_FAVORITED_POSTS = gql`
  query GetFavoritedPosts($username: String!) {
    user(username: $username) {
      postsFavorite {
        slug
      }
    }
  }
`;

export const GET_POST_REPORTS_ADMIN = gql`
  ${POST_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  query postReports($limit: Int, $page: Int, $input: postReportFilterInput) {
    postReports(filters: $input, limit: $limit, page: $page) {
      data {
        ...PostDataFragment
        group {
          name
          slug
          id
        }
        usersFavoriteCount
        usersLikedCount
        reports {
          data {
            id
            type
            createdAt
            detail
            reporter {
              ...UserFragment
            }
          }
          total
        }
      }
      total
    }
  }
`;

export const GET_ALL_POSTS = gql`
  ${POST_DATA_FRAGMENT}
  query GetAllPostsCMS($limit: Int, $page: Int, $filters: postsFiltersInput) {
    posts(limit: $limit, page: $page, filters: $filters) {
      total
      data {
        ...PostDataFragment
        usersLikedCount
        group {
          name
          slug
        }
        childrenCount
      }
    }
  }
`;

export const GET_USERCONTENT_REPORTS = gql`
  ${USER_FRAGMENT}
  query userContentReports(
    $input: userContentReportFilterInput
    $limit: Int!
    $page: Int!
  ) {
    userContentReports(filters: $input, limit: $limit, page: $page) {
      data {
        id
        userContentType
        slug
        status
        editors_pick
        usersLikedCount
        creator {
          ...UserFragment
        }
        reports {
          data {
            id
            type
            createdAt
            reporter {
              ...UserFragment
            }
          }
        }
      }
      total
    }
  }
`;

export const GET_USERS_REPORTS = gql`
  ${USER_FRAGMENT}
  query userReports($limit: Int, $page: Int, $input: userReportFilterInput) {
    userReports(filters: $input, limit: $limit, page: $page) {
      data {
        ...UserFragment
        posts(limit: 1, order: "desc") {
          data {
            id
            createdAt
          }
          total
        }
        reports {
          data {
            id
            type
            createdAt
            reporter {
              ...UserFragment
            }
          }
          total
        }
      }
      total
    }
  }
`;

export const GET_RELATED_CONTENT_CREATE_DETAIL = gql`
  ${USERCONTENT_DATA_FRAGMENT}
  ${TUTORIALS_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${MOTM_FRAGMENT}
  ${USER_FRAGMENT}
  query relatedUserContents(
    $usercontent_slug: String!
    $page: Int!
    $limit: Int!
    $id: Int
  ) {
    relatedUserContents(
      id: $id
      slug: $usercontent_slug
      page: $page
      limit: $limit
    ) {
      data {
        ...UserContentDataFragment
        ...TutorialsFragment
        ...ArticlesFragment
        ...MotmFragment
        blocs {
          id
          type
          contents {
            id
          }
        }
        creator {
          ...UserFragment
        }
        isLiked
      }
      total
      has_more_pages
    }
  }
`;

export const GET_POST_BY_SLUG = gql`
  ${POST_DATA_FRAGMENT}
  query getPostBySlug($slug: String!, $level: Int) {
    post(slug: $slug, level: $level) {
      ...PostDataFragment
      isFavorited
      usersFavoriteCount
      usersLikedCount
      usersLiked {
        username
      }
      group {
        id
        name
        slug
      }
      children {
        id
        status
      }
    }
  }
`;

export const SEARCH = gql`
  ${TUTORIALS_FRAGMENT}
  ${ARTICLES_FRAGMENT}
  ${MOTM_FRAGMENT}
  ${USERCONTENT_DATA_FRAGMENT}
  ${USER_FRAGMENT}
  query Search(
    $keywords: String
    $types: [String]
    $page: Int
    $limit: Int
    $input: searchFiltersInput
  ) {
    search(
      keywords: $keywords
      types: $types
      page: $page
      limit: $limit
      sort_dir: "desc"
      filters: $input
    ) {
      tutorials {
        data {
          ...UserContentDataFragment
          ...TutorialsFragment
          creator {
            ...UserFragment
          }
        }
        total
        per_page
      }
      tutorial_series {
        data {
          ...UserContentDataFragment
          blocs {
            id
            type
            contents {
              id
            }
          }
          creator {
            ...UserFragment
          }
        }
        total
        per_page
      }
      assets {
        data {
          ...UserContentDataFragment
          creator {
            ...UserFragment
          }
        }
        total
        per_page
      }
      artworks {
        data {
          ...UserContentDataFragment
          creator {
            ...UserFragment
          }
        }
        total
        per_page
      }
      articles {
        data {
          ...UserContentDataFragment
          ...ArticlesFragment
          ...MotmFragment
          creator {
            ...UserFragment
          }
        }
        total
        per_page
      }
      users {
        data {
          title
          first_name
          last_name
          username
          location {
            city
            country
          }
          userProfile {
            profile_picture {
              path
              width
              height
              sizes {
                path
                width
                height
              }
            }
            youtube_url
            linkedin_url
            instagram_url
            behance_url
            website_url
          }
        }
        total
        per_page
        current_page
      }
    }
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  ${USER_FRAGMENT}
  query Query($page: Int, $limit: Int) {
    userNotifications(page: $page, limit: $limit) {
      data {
        id
        source {
          ...UserFragment
        }
        body
        slug
        title
        read_at
        created_at
        status
        type
        parent_slug
        content_type
        section
      }
      from
      to
      last_page
      has_more_pages
      current_page
      total
      per_page
    }
  }
`;

export const GET_NOTIFICATIONS_COUNT_SINCE_LAST_VIEW = gql`
  query notificationsCountSinceLastView {
    notificationsCountSinceLastView
  }
`;

// ADMIN DASHBOARD
export const GET_DASHBOARD_DATA = gql`
  query DashboardStatsQuery {
    dashboardStats {
      members_count
      members_diff
      contents_count
      contents_diff
      posts_count
      posts_diff
      trending_content {
        title
        views
        likes
        datas {
          key
          count
        }
      }
      members_locations {
        key
        count
      }
      report_activity {
        key
        count
      }
      contents_creation {
        key
        count
      }
      top_active_creators {
        key
        count
      }
      top_page_views {
        key
        count
      }
      top_active_groups {
        key
        count
      }
      top_liked_contents {
        key
        count
      }
    }
  }
`;

export const GET_STATS_DATA = gql`
  query StatsQuery($type: String, $dateStart: String, $dateEnd: String) {
    stats(type: $type, dateStart: $dateStart, dateEnd: $dateEnd) {
      total_count
      period_current_count
      period_previous_count
      period_diff
      period_current_graph {
        count
        key
      }
      period_previous_graph {
        count
        key
      }
      ... on MembersDashboard {
        completed_profiles {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        uncompleted_profiles {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        unverified_phone {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        unverified_email {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
      }
      ... on ContentDashboard {
        content_per_member {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        top_creators {
          name
          count
        }
        articles {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        artworks {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        tutorials {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        tutorials_series {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        assets {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
      }
      ... on CommunityDashboard {
        posts_replies_per_member {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        top_community_members {
          name
          count
        }
        avg_members_per_group {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        top_group_members {
          name
          count
        }
        reactions {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        comments {
          current_count
          current_diff
          previous_count
          previous_diff
          period_diff
        }
        reactions_graph {
          count
          key
        }
        comments_graph {
          count
          key
        }
        per_groups {
          count
          key
        }
      }
    }
  }
`;
