import React, { useEffect, useState } from 'react';
import {
  SelectUserContentContainer,
  SelectUserContentHeader,
  SelectUserContentWrapper,
} from './SelectUserContent.styled';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import Button from '@components/atoms/button/Button';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_USERCONTENTS } from '@src/apollo/query';
import PropTypes from 'prop-types';
import SaveBtn from '@components/organisms/table/components/columnItem/SaveBtn';
import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import UserContent from '@components/organisms/table/components/columnItem/UserContent';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { useQuery } from '@apollo/client';

const SelectUserContent = props => {
  const [selectUserContent, setSelectUserContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    isSelectUserContent: true,
    filterContentType: props?.forceContentType
      ? props.contentType
      : props.contentType || [
          'article',
          'tutorial',
          'artwork',
          'tutorial_series',
        ],
    filterArticleType: props?.articleType || undefined,
    columns: [
      {
        header: <String id="select_user_content_content" />,
        accessor: 'title',
      },
      {
        header: <String id="community_admin_content_type" />,
        accessor: 'type',
      },
      {
        header: <String id="select_user_content_creation_date" />,
        accessor: 'publish_date',
      },
      {
        header: <String id="select_user_content_content_author" />,
        accessor: 'author',
      },
      {
        header: '',
        accessor: 'saveBtn',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
    rowData: [],
  });

  const [userContent, setUserContent] = useState({
    userContentList: [],
  });

  const onChangeContentType = e => {
    const updatedFilters = { ...selectUserContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'content-type') {
        filter.options.forEach(option => {
          if (option.value == e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });

    setSelectUserContentFilters({ filters: updatedFilters.filters });
  };

  const onChangeArticleType = e => {
    const updatedFilters = { ...selectUserContentFilters };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'article-type') {
        filter.options.forEach(option => {
          if (option.value == e.currentTarget.value) {
            option.isChecked = e.currentTarget.checked;
          }
        });
      }
    });

    setSelectUserContentFilters({ filters: updatedFilters.filters });
  };

  const filters = [
    {
      title: <String id="community_admin_content_type" />,
      type: 'checkbox',
      name: 'content-type',
      onChange: onChangeContentType,
      options: [
        {
          label: <String id="thumbnail_tutorial" />,
          value: 'tutorial',
          isChecked: false,
        },
        {
          label: <String id="thumbnail_article" />,
          value: 'article',
          isChecked: false,
        },
        {
          label: <String id="thumbnail_artwork" />,
          value: 'artwork',
          isChecked: false,
        },
        {
          label: <String id="thumbnail_tutorial_series" />,
          value: 'tutorial_series',
          isChecked: false,
        },
      ],
    },
    {
      title: <String id="community_admin_article_type" />,
      type: 'checkbox',
      name: 'article-type',
      onChange: onChangeArticleType,
      options: [
        {
          label: <String id="thumbnail_motm" />,
          value: 2,
          isChecked: false,
        },
        {
          label: <String id="thumbnail_moty" />,
          value: 3,
          isChecked: false,
        },
      ],
    },
  ];

  const [selectUserContentFilters, setSelectUserContentFilters] = useState({
    filters,
  });

  const { loading, error, data, refetch } = useQuery(GET_USERCONTENTS, {
    variables: {
      limit: selectUserContent.limitRow,
      page: selectUserContent.page,
      content_type: selectUserContent.filterContentType,
      search: selectUserContent?.search,
      filters: {
        article_type: selectUserContent.filterArticleType,
        exclude_ids: props.excludedIds,
      },
      status: props.status || null,
    },
    context: !props.isGroupSettings ? { clientName: 'cms' } : null,
    fetchPolicy: 'no-cache',
  });

  const onNextPage = () => {
    const pageCountFirstNumber =
      selectUserContent.page * selectUserContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + selectUserContent.limitRow - 1;

    if (pageCountSecondNumber > selectUserContent.totalCount) {
      pageCountSecondNumber = selectUserContent.totalCount;
    }

    setSelectUserContent({
      ...selectUserContent,
      page: selectUserContent.page + 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
      isLoaded: false,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (selectUserContent.page - 2) * selectUserContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + selectUserContent.limitRow - 1;
    if (pageCountSecondNumber > selectUserContent.totalCount) {
      pageCountSecondNumber = selectUserContent.totalCount;
    }
    setSelectUserContent({
      ...selectUserContent,
      page: selectUserContent.page - 1,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
      isLoaded: false,
    });
  };

  const onChangeLimitRow = rowCount => {
    setSelectUserContent({
      ...selectUserContent,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(selectUserContent.totalCount / rowCount),
      isLoaded: false,
      currentCount: `1 - ${
        selectUserContent.limitRow < data.userContents.total
          ? selectUserContent.limitRow
          : data.userContents.total
      }`,
    });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (data) {
      const pageCountFirstNumber =
        (selectUserContent.page - 1) * selectUserContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + selectUserContent.limitRow - 1;
      if (pageCountSecondNumber > data.userContents.total) {
        pageCountSecondNumber = data.userContents.total;
      }

      setSelectUserContent({
        ...selectUserContent,
        isLoaded: true,
        totalCount: data.userContents.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(
          data.userContents.total / selectUserContent.limitRow
        ),
      });

      const rowDatas = [];
      data.userContents.data.forEach((element, index) => {
        const image = {
          src: CDN_URL + element.image.path,
        };

        const date = new Date(element.createdAt * 1000);

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
          date
        );
        const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(
          date
        );
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
          date
        );

        const hour = new Intl.DateTimeFormat('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }).format(date);

        let name = '';

        if (
          element?.creator?.first_name !== null &&
          element?.creator?.first_name !== ''
        ) {
          name =
            element?.creator?.first_name + ' ' + element?.creator?.last_name;
        }

        const elementContentType = () => {
          if (
            element.userContentType === 'article' &&
            element.article_type.id > 1
          ) {
            if (element.article_type.id === 2) {
              return 'motm';
            } else if (element.article_type.id === 3) {
              return 'moty';
            }
          } else {
            return element.userContentType;
          }
        };

        const singleRow = {
          title: <UserContent name={element.title} image={image} />,
          index,
          type: <Text>{elementContentType()}</Text>,
          publish_date: (
            <DateIconLink
              date={`${mo}, ${da} ${ye}`}
              time={hour}
              url={`/m/${element.creator.username}/${element.userContentType}s/${element.slug}`}
            />
          ),
          author: (
            <UserInfos
              name={name}
              job={element.creator.title}
              url={element?.creator?.userProfile?.profile_picture}
            />
          ),
          ...(props.singleRowActions && {
            saveBtn: (
              <SaveBtn
                action={props.singleRowActions}
                {...element}
                isGroupSettings={props.isGroupSettings}
              />
            ),
          }),
          ...(props.onRowActionClick && {
            saveBtn: (
              <Button
                {...element}
                type="circle"
                actionType="onclick"
                onClick={() => props.onRowActionClick(element)}
                svgIcon="plus"
              />
            ),
          }),
          id: element.id,
          slug: element.slug,
        };
        rowDatas.push(singleRow);
      });

      setUserContent({
        userContentList: rowDatas,
      });
    }
  }, [data]);

  useEffect(() => {
    const pageCountFirstNumber =
      (selectUserContent.page - 1) * selectUserContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + selectUserContent.limitRow - 1;
    if (pageCountSecondNumber > selectUserContent.totalCount) {
      pageCountSecondNumber = selectUserContent.totalCount;
    }
    setSelectUserContent({
      ...selectUserContent,
      currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
    });
  }, [selectUserContent.limitRow]);

  const onApplyFilters = () => {
    // All Filters
    const allSelectedFilters = {};

    // Checkbox - Roles
    const selectedContentType = [];
    const selectedArticleType = [];
    selectUserContentFilters.filters.forEach(filter => {
      if (filter.name == 'content-type') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            selectedContentType.push(option.value);
          }
        });
      }
      if (filter.name == 'article-type') {
        filter.options.forEach(option => {
          if (option.isChecked) {
            selectedArticleType.push(parseInt(option.value, 10));
          }
        });
      }
    });
    if (selectedContentType.length > 0) {
      allSelectedFilters.selectedContentType = selectedContentType;
    }
    if (selectedArticleType.length > 0) {
      allSelectedFilters.selectedArticleType = selectedArticleType;
    }

    setSelectUserContent({
      ...selectUserContent,
      filterContentType: allSelectedFilters.selectedContentType,
      filterArticleType: allSelectedFilters.selectedArticleType,
    });
  };

  const onSearchSubmit = searchInput => {
    setSelectUserContent({
      ...selectUserContent,
      search: searchInput !== '' ? searchInput : '',
      page: 1,
    });
    refetch();
  };

  function filtersTitle() {
    if (props.title) {
      return props.title;
    } else if (props.isGroupSettings && !props.title) {
      return 'select_user_content_select_content';
    } else if (!props.title || !props.isGroupSettings) {
      return 'select_user_content_learn_things';
    }
  }

  return (
    <SelectUserContentWrapper>
      <SelectUserContentHeader />
      <SelectUserContentContainer>
        <>
          <AdvanceFilters
            title={<String id={filtersTitle()} />}
            onSubmitFilters={onApplyFilters}
            onSearchSubmit={onSearchSubmit}
            filters={selectUserContentFilters.filters}
            showFiltersButton={!props.forceContentType}
            isGroupSettings={props.isGroupSettings}
            showSearchbar={props.showSearchbar}
          />
          <Table
            nextPage={onNextPage}
            prevPage={onPrevPage}
            bulkActions={props.bulkActions}
            changeRowPerPage={onChangeLimitRow}
            totalCount={selectUserContent.totalCount}
            currentCount={selectUserContent.currentCount}
            loading={loading}
            rowData={userContent.userContentList}
            columns={selectUserContent.columns}
            maxPage={selectUserContent.maxPage}
            isSelectUserContent
            isNotMultiSelect={props.isNotMultiSelect}
            currentPage={selectUserContent.page}
          />
        </>
      </SelectUserContentContainer>
    </SelectUserContentWrapper>
  );
};

SelectUserContent.propTypes = {
  isNotMultiSelect: PropTypes.bool,
  singleRowActions: PropTypes.shape({
    text: PropTypes.string,
    label: PropTypes.string,
    onClickAction: PropTypes.func,
  }).isRequired,
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClickAction: PropTypes.func,
    })
  ),
};

export default SelectUserContent;
