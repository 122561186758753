import { CMS_GET_PAGE_DATA, GET_CREATE_DETAIL } from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import Button from '@components/atoms/button/Button';
import { CMS_UPDATE_PAGE_DATA } from '@src/apollo/mutation';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import { Heading } from '@components/atoms/heading/Heading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import Modal from '@components/atoms/modal/Modal';
import SEO from '@components/atoms/seo/Seo';
import { SectionWrapper } from './ContentPageLearnTutorials.styled';
import SelectUserContent from '@components/molecules/selectUserContent/SelectUserContent';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { useCMSPageUpdateValidation } from '../utils/usePageUpdateValidation';
import useModal from '@components/atoms/modal/useModal';

const ContentPageLearnTutorials = context => {
  const { loading, error, data, refetch } = useQuery(CMS_GET_PAGE_DATA, {
    variables: { slug: 'learn/tutorials' },
    context: { clientName: 'cms' },
  });
  const [updatePage, updatePageData] = useMutation(CMS_UPDATE_PAGE_DATA, {
    context: { clientName: 'cms' },
    onCompleted: () => refetch(),
  });

  const {
    isShowing: isShowingFeaturedModal,
    toggle: toggleFeaturedModal,
  } = useModal();

  const [heroData, setHeroData] = useState({
    title: '',
    description: '',
  });

  const [userContentFeatured, setUserContentFeatured] = useState(null);

  useEffect(() => {
    if (data && data.page) {
      setHeroData({
        title: data.page.title,
        description: data.page.introduction,
      });

      setUserContentFeatured({
        id: data.page.userContent_feature?.id,
        slug: data.page.userContent_feature?.slug,
      });
    }
  }, [data]);

  const formValidation = useCMSPageUpdateValidation(heroData);
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  function updateData() {
    if (formValidation.descriptionIsError || formValidation.titleIsError) {
      setHeroData({
        ...heroData,
        ...formValidation,
      });
      let counter = 0;

      for (let i = 0; i < Object.values(formValidation).length; i++) {
        if (Object.values(formValidation)[i] === true) {
          counter += 1;
        }
      }
      handleUpdateClientNotification({
        text: `cms_notification_validation_error_${
          counter >= 2
            ? 'cms_multiple_empty'
            : Object.keys(formValidation)
                .filter(el => !el.includes('IsError'))
                .map(el => formValidation[el])[0]
        }`,
        type: 'error',
        autoDismiss: true,
      });
    } else {
      setHeroData({
        ...heroData,
        ...formValidation,
      });
      updatePage({
        variables: {
          id: data.page.id,
          input: {
            title: heroData.title,
            introduction: heroData.description,
            userContent_feature: userContentFeatured.id,
          },
        },
      });
    }
  }

  const { data: dataSelectedUserContentFeatured } = useQuery(
    GET_CREATE_DETAIL,
    {
      variables: {
        slug: userContentFeatured?.slug,
      },
    }
  );

  const featuredThumbnailData = dataSelectedUserContentFeatured?.userContent;

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <Loader isLoading={loading || updatePageData.loading} />
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        <String id="content_page_learn_tutorials_title" />
      </Heading>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_learn_tutorials_section_hero_title" />
        </Heading>
        <InputText
          onChange={e => {
            setHeroData({
              ...heroData,
              title: e.target.value,
            });
          }}
          type="text"
          inputShape="normal"
          name="title"
          label={<String id="content_page_learn_tutorials_title_input_label" />}
          value={heroData.title}
          css={{ margin: '$16 0' }}
          placeholder="Title"
          error={heroData?.titleErrorMessage || null}
          isError={heroData.titleIsError || false}
          inputTheme="transparent"
          required
        />
        <InputText
          onChange={e => {
            setHeroData({
              ...heroData,
              description: e.target.value,
            });
          }}
          type="text"
          inputShape="normal"
          name="description"
          label={
            <String id="content_page_learn_tutorials_description_input_label" />
          }
          value={heroData.description}
          css={{ margin: '$16 0' }}
          placeholder="Description"
          error={heroData.descriptionErrorMessage || null}
          isError={heroData.descriptionIsError || false}
          inputTheme="transparent"
          required
        />
      </SectionWrapper>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_learn_tutorials_section_featured_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          css={{
            marginTop: '$32',
          }}
          label={
            <String id="content_page_learn_tutorials_section_featured_button_label" />
          }
          onClick={toggleFeaturedModal}
        />
        {(featuredThumbnailData !== undefined  && featuredThumbnailData !== null) && (
          <Thumbnail {...dataToThumbnailViewModel(featuredThumbnailData, false, false)} isMini />
        )}
        <Modal
          isShowing={isShowingFeaturedModal}
          hide={toggleFeaturedModal}
          isSelectUserContent
        >
          <SelectUserContent
            isNotMultiSelect
            status="publish"
            title="content_page_learn_tutorials_section_featured_title"
            onRowActionClick={id => {
              toggleFeaturedModal();
              setUserContentFeatured(id);
            }}
            contentType="tutorial"
          />
        </Modal>
      </SectionWrapper>
      <Button
        label={<String id="content_page_learn_tutorials_save_changes" />}
        colorTheme="normal"
        css={{ marginTop: '$32' }}
        actionType="onclick"
        onClick={updateData}
      />
    </AdminLayout>
  );
};

export default ContentPageLearnTutorials;
