import React, {
  useCallback,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import ClientNotification from '@components/molecules/clientNotification/ClientNotification';
import PropTypes from 'prop-types';
import { generateUniqueId } from '@src/utils/generateUniqueId';
import { navigate } from 'gatsby';
import { styled } from '@root/stitches.config';

export const ClientNotificationContext = React.createContext([]);

const DISMISS_TIMEOUT_MS = 3000;

const NotificationsContextWrapper = styled('div', {
  position: 'fixed',
  zIndex: '99999',
  left: 0,
  width: '100%',
  maxHeight: '100%',
  height: '100%',
  display: 'flex',
  pointerEvents: 'none',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  '@bp2': {
    left: 'unset',
    right: '$24',
    bottom: '0',
    justifyContent: 'flex-end',
    width: 'auto',
    paddingBottom: '$84',
  },
});

const notificationReducer = (state = [], action) => {
  const newState = [...state].filter(el => el.id !== action.payload);
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return [
        ...state,
        {
          ...action.payload,
          id: `client_notification_${generateUniqueId()}`,
        },
      ];
    case 'DISMISS_NOTIFICATION':
      return newState;
    default:
      return state;
  }
};

export const ClientNotificationContextProvider = React.memo(({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, []);
  const [dismissedId, setDismissedId] = useState();
  const closeTimeoutRef = useRef();

  useLayoutEffect(() => {
    clearTimeout(closeTimeoutRef.current);
    return () => {
      clearTimeout(closeTimeoutRef.current);
    };
  }, [state]);

  const handleUpdateClientNotification = useCallback(
    notif => {
      if (
        notif.text !== 'invalid_or_missing_token' &&
        notif.text !== 'unauthorized_access' &&
        notif.text !== 'post_contains_badwords'
      ) {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: notif,
        });
      }

      if (notif === 'unauthorized_access') {
        navigate('/community/groups');
      }

      return null;
    },
    [state]
  );

  function handleCloseNotification(notifCb, id) {
    // notifCb;
    closeTimeoutRef.current = setTimeout(() => {
      dispatch({
        type: 'DISMISS_NOTIFICATION',
        payload: id,
      });
    }, 500);
    return () => {
      clearTimeout(closeTimeoutRef.current);
    };
  }

  useLayoutEffect(() => {
    if (dismissedId !== undefined) {
      dispatch({
        type: 'DISMISS_NOTIFICATION',
        payload: dismissedId,
      });
    }
  }, [dismissedId]);

  const store = React.useMemo(
    () => ({ state, handleUpdateClientNotification }),
    [state]
  );

  return (
    <ClientNotificationContext.Provider value={store}>
      {state.length >= 1 && (
        <NotificationsContextWrapper>
          {state.map(notif => {
            return (
              <ClientNotification
                key={notif.id}
                type={notif?.type}
                text={notif?.text}
                extras={notif?.extras}
                autoDismiss={notif?.autoDismiss}
                autoDismissTimeoutMs={
                  notif?.type === 'error'
                    ? DISMISS_TIMEOUT_MS * 2
                    : DISMISS_TIMEOUT_MS
                }
                // TODO - Check if it's match with the business rules
                // closable={notif?.closable}
                // closable={notif.type !== 'error' ? true : false}
                onAutoDismiss={() => setDismissedId(notif.id)}
                onClose={() =>
                  handleCloseNotification(notif?.onClose, notif.id)
                }
              />
            );
          })}
        </NotificationsContextWrapper>
      )}
      {children}
    </ClientNotificationContext.Provider>
  );
});

ClientNotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
