import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const NotificationBoxWrapper = styled('div', {
  backgroundColor: '$box',
  borderRadius: '$24',
  boxShadow: '$1',
  padding: '$16',
  position: 'relative',
  width: '100%',
  marginBottom: '$16',
  display: 'flex',
  justifyContent: 'space-between',
});

export const LeftSideWrapper = styled('div', {
  a: {
    display: 'flex',
    alignItems: 'flex-start',
    color: 'unset',
    textDecoration: 'none',

    '@bp1': {
      alignItems: 'center',
    },
  },
});

export const RightSideWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
});

export const RedBubble = styled('div', {
  position: 'absolute',
  top: '0',
  right: '0',
  width: '12px',
  height: '12px',
  backgroundColor: '$red',
  borderRadius: '100%',
  display: 'none',

  variants: {
    isVisible: {
      true: {
        display: 'block',
      },
    },
  },
});

export const AvatarContainer = styled('div', {
  position: 'relative',
  marginRight: '$16',
  img: {
    width: '44px',
    height: '44px',
  },

  svg: {
    position: 'absolute',
    top: '-$4',
    right: '-$4',
    width: '$16',
    height: '$16',
    zIndex: '$1',
    fill: '$blackToWhite',
  },
  '&:after': {
    content: '',
    position: 'absolute',
    top: '-$8',
    right: '-$8',
    width: '$24',
    height: '$24',
    backgroundColor: '$elements',
    borderRadius: '$round',
  },
});

export const BoldText = styled('span', {
  fontWeight: 'bold',
});

export const DateText = styled('p', {
  color: '$darkGrey',
  fontSize: '$12',
});

export const ActionsToggleBtn = styled(Button, {
  transition: 'opacity 0.2s ease-out',
  width: '$32',
  height: '$32',
  opacity: '1',
  marginTop: '-$8',
  marginRight: '-$8',
  marginBottom: '$4',

  '@bp1': {
    width: '44px',
    height: '44px',
    opacity: '0',
    marginTop: '-$8',
    marginRight: '-$8',
    marginBottom: '$4',
  },

  variants: {
    isVisible: {
      true: {
        '@bp1': {
          opacity: '1',
        },
      },
      false: {
        '@bp1': {
          opacity: '0',
        },
      },
    },
  },
});

export const ActionsContainer = styled('div', {
  position: 'absolute',
  top: '$48',
  right: '0',
  backgroundColor: '$lightGrey',
  padding: '$16',
  borderRadius: '$24',
  boxShadow: '$1',
  display: 'none',
  zIndex: '$1',
  flexDirection: 'column',

  variants: {
    isVisible: {
      true: {
        display: 'flex',
      },
    },
  },

  '@bp1': {
    top: '$64',
  },
});

export const ActionsBtn = styled('button', {
  backgroundColor: 'unset',
  border: 'none',
  alignItems: 'center',
  marginBottom: '$8',
  cursor: 'pointer',
  display: 'none',
  color: '$mainTextColor',
  transition: 'all .3s ease',
  '&:last-of-type': {
    marginBottom: '0',
  },

  svg: {
    width: '$24',
    height: '$24',
    marginRight: '$8',
    fill: '$mainTextColor',
  },

  variants: {
    isVisible: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },

  '&:hover': {
    transition: 'all .3s ease',
    color: '$darkGrey',
    svg: {
      fill: '$darkGrey',
    },
  },
});

export const ReactionText = styled('p', {
  color: '$mainTextColor',
  fontSize: '$14',
});
