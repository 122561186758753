import { gql } from '@apollo/client';

export const GET_PAGE_DATA_HOME = gql`
  query PageHome($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      header_image_credit
      header_image_credit_link
      ... on HomeTemplate {
        userContent_feature {
          id
          title
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          slug
          createdAt
          publishedAt

          isLiked
          motm_member {
            first_name
            last_name
            title
          }
          blocs {
            id
            type
            contents {
              id
            }
          }
          article_type {
            id
            key
          }
          article_section {
            key
            id
          }
          creator {
            is_internal
            first_name
            last_name
            title
            username
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          userContentType
          tutorial_level {
            id
            name
          }
          tutorial_type {
            id
            name
          }
          tools {
            id
            title
            icon {
              path
              width
              height
              sizes {
                path
                width
                height
              }
            }
          }
          industries {
            id
            name
          }
          tutorial_duration
        }
        userContent {
          id
          title
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          slug
          isLiked
          createdAt
          publishedAt
          motm_member {
            first_name
            last_name
            title
          }
          blocs {
            id
            type
            contents {
              id
            }
          }
          article_type {
            id
            key
          }
          article_section {
            key
            id
          }
          creator {
            is_internal
            first_name
            last_name
            title
            username
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          userContentType
          isLiked
        }
      }
    }
  }
`;

export const GET_PAGE_DATA_INSPIRE = gql`
  query pages($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      header_image_credit
      header_image_credit_link
      ... on InspireTemplate {
        userContent_feature {
          id
          title
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          slug
          createdAt
          publishedAt
          motm_member {
            id
            first_name
            last_name
            title
          }

          article_type {
            id
            name
            key
          }
          article_section {
            key
            id
          }
          creator {
            is_internal
            first_name
            last_name
            title
            username
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          userContentType
          isLiked
        }
        userContent {
          id
          title
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          slug
          createdAt
          publishedAt
          motm_member {
            id
            first_name
            last_name
            title
          }
          article_type {
            id
            name
            key
          }
          article_section {
            key
            id
          }
          creator {
            is_internal
            first_name
            last_name
            title
            username
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          userContentType
          isLiked
        }
      }
    }
  }
`;

export const GET_PAGE_DATA_GALLERY = gql`
  query PageDataGallery($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      ... on GalleryTemplate {
        learn_more_1 {
          label
          imgSrc
          linkTo
          text
        }
        learn_more_2 {
          label
          imgSrc
          linkTo
          text
        }
        userContent_feature {
          id
          title
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          slug
          createdAt
          publishedAt
          isLiked
          motm_member {
            first_name
            last_name
            title
          }
          blocs {
            id
            type
            contents {
              id
            }
          }
          article_type {
            id
            key
          }
          article_section {
            key
            id
          }
          creator {
            is_internal
            first_name
            last_name
            title
            username
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          userContentType
          tutorial_level {
            id
            name
          }
          tutorial_type {
            id
            name
          }
          tools {
            id
            title
            icon {
              path
              width
              height
              sizes {
                path
                width
                height
              }
            }
          }
          industries {
            id
            name
          }
          tutorial_duration
        }
      }
    }
  }
`;

export const GET_PAGE_DATA_MEMBER_OF = gql`
  query PageDataMom($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      ... on MotmTemplate {
        learn_more_1 {
          label
          imgSrc
          linkTo
          text
        }
        learn_more_2 {
          label
          imgSrc
          linkTo
          text
        }
      }
    }
  }
`;

export const GET_PAGE_DATA_ARTICLES = gql`
  query PageInfosArticle($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      ... on ArticlesTemplate {
        learn_more_1 {
          label
          imgSrc
          linkTo
          text
        }
        learn_more_2 {
          label
          imgSrc
          linkTo
          text
        }
        userContent_feature {
          id
          title
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          slug
          createdAt
          publishedAt
          isLiked
          motm_member {
            first_name
            last_name
            title
          }
          blocs {
            id
            type
            contents {
              id
            }
          }
          article_type {
            id
            key
          }
          article_section {
            key
            id
          }
          creator {
            is_internal
            first_name
            last_name
            title
            username
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          userContentType
          tutorial_level {
            id
            name
          }
          tutorial_type {
            id
            name
          }
          tools {
            id
            title
            icon {
              path
              width
              height
              sizes {
                path
                width
                height
              }
            }
          }
          industries {
            id
            name
          }
          tutorial_duration
        }
      }
    }
  }
`;

export const GET_PAGE_DATA_LEARN = gql`
  query GetPageinfosLearn($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      header_image_credit
      header_image_credit_link
      ... on LearnTemplate {
        userContent_feature {
          id
          slug
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          title
          feature
          createdAt
          publishedAt
          userContentType
          tutorial_level {
            id
            name
          }
          article_type {
            id
            key
          }
          tools {
            id
            title
            icon {
              path
              width
              height
              sizes {
                path
                width
                height
              }
            }
          }
          creator {
            is_internal
            username
            first_name
            last_name
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          isLiked
        }
      }
    }
  }
`;

export const GET_PAGE_DATA_TUTORIALS = gql`
  query PageTutorials($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      ... on TutorialsTemplate {
        learn_more_1 {
          label
          imgSrc
          linkTo
          text
        }
        learn_more_2 {
          label
          imgSrc
          linkTo
          text
        }
        userContent_feature {
          id
          slug
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          title
          feature
          createdAt
          publishedAt
          userContentType
          tutorial_level {
            id
            name
          }
          tools {
            id
            title
            icon {
              path
              width
              height
              sizes {
                path
                width
                height
              }
            }
          }
          creator {
            is_internal
            first_name
            last_name
            username
            title
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          isLiked
        }
      }
    }
  }
`;

export const GET_PAGE_DATA_ASSETS = gql`
  query pages($slug: String!) {
    page(slug: $slug) {
      id
      title
      introduction
      background_image {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
      ... on AssetsTemplate {
        learn_more_1 {
          label
          imgSrc
          linkTo
          text
        }
        learn_more_2 {
          label
          imgSrc
          linkTo
          text
        }
        userContent_feature {
          id
          title
          image {
            path
            width
            height
            sizes {
              path
              width
              height
            }
          }
          slug
          publishedAt
          creator {
            is_internal
            first_name
            last_name
            title
            username
            userProfile {
              profile_picture {
                path
                width
                height
                sizes {
                  path
                  width
                  height
                }
              }
            }
          }
          userContentType
          isLiked
        }
      }
    }
  }
`;
