import { InputSearchWrapper, InputTextStyled } from './InputSearch.styled';
import React, { useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import Svg from '@components/atoms/svg/Svg';

const InputSearch = props => {
  return (
    <InputSearchWrapper css={props.css}>
      <InputTextStyled
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        onKeyUp={props.onKeyUp}
        className={props.className}
      ></InputTextStyled>
      <Button
        type="circle"
        actionType="onclick"
        colorTheme="transparent"
        onClick={props.onKeyUp}
        svgIcon="search"
      />
    </InputSearchWrapper>
  );
};

export default InputSearch;
