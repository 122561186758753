import { urlsData } from './craftData/UrlsData';
import React from 'react';

const SeoContext = React.createContext({
  seoData: [],
});

class SeoDataProvider extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children } = this.props;

    const seoData = {};
    urlsData.data.entries.map(entry => {
      if (entry.seomatic) {
        seoData[entry.slug] = {
          metaTagContainer: entry.seomatic.metaTagContainer,
          metaTitleContainer: entry.seomatic.metaTitleContainer,
        };
      }
    });
    return (
      <SeoContext.Provider value={seoData}>{children}</SeoContext.Provider>
    );
  }
}

export default SeoContext;

export { SeoDataProvider };
