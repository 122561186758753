import { styled } from '@root/stitches.config';

export const SectionWrapper = styled('div', {
  padding: '$32 0',
  borderBottom: '1px solid $mediumGrey',
});

export const ThumbnailWrapper = styled('div', {
  button: {
    marginBottom: '$8',
  },
})

export const SectionButtonWrapper = styled('div', {
  marginTop: '$16',
  display: 'flex',
  '.content-selection': {
    flexBasis: '50%',
  },
});