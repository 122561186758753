import {
  InputTextErrorMsg,
  InputTextLabel,
  RequiredLabel,
  SelectTagsHeader,
  SelectTagsWrapper,
} from './SelectTags.styled';

import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';
import ToggleButton from '@components/atoms/toggleButton/ToggleButton';

const SelectTags = props => {
  const {
    list,
    label,
    required,
    css,
    onToggleButtonClick,
    isError,
    error,
    maxTags,
  } = props;

  const selectedItems = list.filter(el => el.isSelected);
  const hasMaxSelected = selectedItems.length === maxTags;

  return (
    <>
      <SelectTagsHeader>
        {label && (
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <InputTextLabel isError={isError}>{label}</InputTextLabel>
            <Text size="14">
              {hasMaxSelected
                ? '(Max amount reached)'
                : `(add up to ${maxTags})`}
            </Text>
          </div>
        )}
        {required ? (
          <RequiredLabel isError={isError} size="12">
            Required
          </RequiredLabel>
        ) : null}
      </SelectTagsHeader>
      <SelectTagsWrapper css={css}>
        {list.map((item, index) => (
          <ToggleButton
            onClick={onToggleButtonClick}
            {...item}
            key={item.id}
            index={index}
            isDisabled={hasMaxSelected && !item.isSelected}
          />
        ))}
        {isError ? (
          <InputTextErrorMsg isError={isError}>{error}</InputTextErrorMsg>
        ) : null}
      </SelectTagsWrapper>
    </>
  );
};

SelectTags.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      id: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  css: PropTypes.object,
  onToggleButtonClick: PropTypes.func,
  isError: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxTags: PropTypes.number,
};

SelectTags.defaultProps = {
  label: undefined,
  required: false,
  css: {},
  onToggleButtonClick: () => {},
  isError: false,
  error: undefined,
  maxTags: 5,
};

export default SelectTags;
