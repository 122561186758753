import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_POST_BY_SLUG } from '@src/apollo/query';
import CommunityPostBox from '../communityPostBox/CommunityPostBox';
import { postBoxDataToViewModel } from '../communityPostBox/postBoxDataToViewModel';
import AuthContext from '@src/context/AuthContext';
import PostsWireFrame from './wireframe/PostsWireFrame';

function CommunityPost(props) {
  const { slug } = props;
  const [isGroupMember, setIsGroupMember] = useState(false);
  const authContext = useContext(AuthContext);

  const { loading, error, data, refetch } = useQuery(GET_POST_BY_SLUG, {
    variables: {
      slug,
      level: 0,
    },
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'public' },
  });

  if (loading && !error) {
    return (
      <PostsWireFrame
        showImage={Math.random() < 0.4}
        id={slug}
        isInFavorites={props.isInFavorites}
      />
    );
  }

  if (error || data?.post === undefined || data?.post === null) {
    return null;
  }

  let userInfos;

  if (authContext.isAuthenticated) {
    const profilePicture = authContext?.profilePicture;

    userInfos = {
      modalProps: {
        artist: {
          avatarProps: {
            alt: `${authContext.firstName} ${authContext.lastName}`,
            src: profilePicture,
          },
          name: `${authContext.firstName} ${authContext.lastName}`,
          job: authContext.title,
        },
      },
      avatarProps: {
        alt: `${authContext.firstName} ${authContext.lastName}`,
        src: profilePicture,
      },
      userId: authContext.userId,
    };
  }

  return (
    <div style={{ marginBottom: '16px' }}>
      <CommunityPostBox
        {...postBoxDataToViewModel(
          {
            ...data,
            maxPosts: props.maxPosts,
            activePage: props.activePage,
            userInfos,
            myGroupState: props.myGroupState,
            // isCurrentUserGroupMember: props.isCurrentUserGroupMember,
          },
          authContext
        )}
        onDeleteClick={props.onDeleteClick}
        isCommunity={props.isCommunity}
        isPost={props.isPost}
        onJoinGroup={props.onJoinGroup}
        isPending={props.isPending}
        postContent={props.postContent}
        isLogged={props.isLogged}
        postSaved={props.postSaved}
        onApproveClick={props.onApproveClick}
        optionsOpened={props.optionsOpened}
        updatePost={props.updatePost}
        optionsOpenedState={props.optionsOpenedState}
        onFavoriteClick={props.onFavoriteClick}
        setRejectedId={props.setRejectedId}
        onRejectClick={props.onRejectClick}
        moderatedId={props.moderatedId}
        isSinglePost={props.isSinglePost}
        isInModal={props.isInModal}
        hideOptions={props.hideOptions}
        isInFavorites={props.isInFavorites}
        isEditableByClient={props.isEditableByClient}
        generalRole={props.generalRole}
        isReported={props.isReported}
        isVerified={props.isVerified}
        isOnboarded={props.isOnboarded}
        refetch={refetch}
      />
    </div>
  );
}

CommunityPost.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default React.memo(CommunityPost, (prevProps, nextProps) => {
  if (prevProps?.slug === nextProps?.slug) {
    return true;
  } else {
    return false;
  }
});
