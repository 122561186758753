import { styled } from '@root/stitches.config';

import { Text } from '@components/atoms/text/Text';
import Svg from '@components/atoms/svg/Svg';

export const CreateFooterWrapper = styled('div', {
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '$container',
  py: '$40',
  position: 'relative',
  borderTop: '1px solid $lightGrey',
  borderBottom: '1px solid $lightGrey',

  '@bp2': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '$16',
    flexDirection: 'row',
    padding: '$40',
  },
  '@bp3': {
    padding: '$40 0px',
  },

  h6: {
    fontSize: '$16',
  },

  '.theme-0 &': {
    color: '$mainTextColor',
    borderTop: '1px solid $lightGrey',
    borderBottom: '1px solid $lightGrey',

    svg: {
      fill: '$mainTextColor',
    },

    span: {
      borderColor: '$mainTextColor',
    },
  },
});

export const FlexContainer = styled('div', {
  marginBottom: '$48',
  width: '100%',
  // padding: '0 $16',

  '@bp2': {
    marginBottom: 'unset',
    ul: {
      justifyContent: 'center',
    },
  },
  variants: {
    singleItem: {
      true: {
        ul: {
          justifyContent: 'center',
        },
      },
    },
  },
  '.theme-0 &': {
    '&:first-of-type': {
      div: {
        color: 'rgba(0,0,0,.9)',
      },

      h6: {
        color: '$mainTextColor',
      },
    },
  },

  ul: {
    padding: '0',
  },
});

export const SvgStyled = styled(Svg, {
  fill: '$mainTextColor !important',
});
