import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const ImageCircleWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  overflow: 'hidden'
});
