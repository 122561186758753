import { styled } from '@root/stitches.config';

export const EditorLoadingWrapper = styled('div', {
  display: 'flex',
  position: 'absolute',
  bottom: 0,
  right: 0,
  background: '$mediumGrey',
  padding: '$8',
  borderBottomRightRadius: '16px',
  borderTopLeftRadius: '8px',
  '> p': {
    color: '$white',
  },
  '> .loader': {
    margin: 0,
    marginLeft: '0',
    // note: I had to add the important to override the Lottie default values
    div: {
      width: '$24 !important',
      height: '$24 !important',
      svg: {
        transform: 'scale(2) !important',
      },
    },
  },
});

export const EditorBlockWrapper = styled('div', {
  position: 'relative',
  padding: '$24',
  '@bp2': {
    padding: '$28',
  },

  variants: {
    type: {
      image: {
        padding: '$24 0',
        '@bp2': {
          padding: '$28 $28 $28',
        },
      },
      text: {
        '.block-tools': {
          top: '$20',
          // right: '$32',
        },
      },
    },
    blockOrder: {
      block0: {
        order: 1,
      },
      block1: {
        order: 2,
      },
      block2: {
        order: 3,
      },
      block3: {
        order: 4,
      },
      block4: {
        order: 5,
      },
      block5: {
        order: 6,
      },
    },
  },

  '*::selection': {
    background: '$mediumGrey',
  },

  '&:before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    border: '2px solid $mediumGrey',
    borderRadius: '$16',
    transition: 'opacity .4s ease',
    opacity: 0,
    top: 0,
    left: 0,
  },

  '.block-tools': {
    opacity: 0,
    transition: 'opacity .4s ease',
  },

  '&:focus,&:hover': {
    '&:before': {
      opacity: 1,
    },
    '.block-tools': {
      opacity: 1,
    },
  },

  div: {
    outline: 'none',
  },

  '.medium-editor-element': {
    zIndex: '3',
    position: 'relative',
  },
});

export const BlockTools = styled('div', {
  position: 'absolute',
  top: '$32',
  right: '$32',
  zIndex: '7',
  display: 'flex',
});
