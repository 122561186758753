import { Heading } from '@components/atoms/heading/Heading';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import PropTypes from 'prop-types';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

import {
  DoughnutGraphBlockWrapper,
  GraphContainer,
  DoughnutGraphHeader,
} from './DoughnutGraphBlock.styled';

export const DoughnutGraphBlock = props => {
  const { title, data, insideTitle, periodTitle } = props;
  return (
    <DoughnutGraphBlockWrapper>
      {(title || periodTitle) && (
        <DoughnutGraphHeader>
          { title && (
            <Heading
              level="h4"
              css={{ marginRight: '$20', lineHeight: '1em', display: 'inline' }}
            >
              {title}
            </Heading>
          )}
          {periodTitle && (
            <Text
              css={{ color: '$mediumGrey', display: 'inline', lineHeight: '1em' }}
            >
              {periodTitle}
            </Text>
          )}
        </DoughnutGraphHeader>
      )}
      <GraphContainer hasTitle={title !== undefined}>
        <Doughnut title={insideTitle} data={data} />
      </GraphContainer>
    </DoughnutGraphBlockWrapper>
  );
};

DoughnutGraphBlock.propTypes = {
  title: PropTypes.string,
  insideTitle: PropTypes.string,
  periodTitle: PropTypes.string,
};
