import React, { useContext, useEffect, useState } from 'react';

import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import { CLOSE_POST_REPORT } from '@src/apollo/mutation';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import Loader from '@components/atoms/loader/Loader';
import Options from '@components/organisms/table/components/columnItem/Options';
import ReportedByList from '@components/organisms/table/components/columnItem/ReportedByList';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import Table from '@components/organisms/table/Table';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { useMutation } from '@apollo/client';
import { Text } from '@components/atoms/text/Text';

const ViolenceLabel = <String id="community_admin_violence" />;
const NudityLabel = <String id="community_admin_nudity" />;
const SuspiciousLabel = <String id="community_admin_suspicious" />;
const PostLabel = <String id="community_admin_post" />;
const CommentLabel = <String id="community_admin_comment" />;

const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date * 1000);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(date, 'LLL, d y');
};

const convertUTCtoLocalTime = date => {
  if (!date) {
    return date;
  }
  date = new Date(date * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let suffix = '';
  if (hours >= 12) {
    suffix = ' PM';
  } else {
    suffix = ' AM';
  }
  hours = hours % 12 || 12;
  minutes = ('0' + minutes).slice(-2);
  let formatedDate = hours + ':' + minutes + suffix;
  return formatedDate;
};

const ReportedContentTable = props => {
  const [closeReport, closeReportData] = useMutation(CLOSE_POST_REPORT);
  const stringsContext = useContext(StringsContext);

  const onSubmitFilters = () => {
    // All Filters
    const allSelectedFilters = {};

    // Checkbox - Roles
    let report_type = null;
    filters.forEach(filter => {
      if (filter.name == 'report-type') {
        report_type = filter.selectedValue;
      }
    });
    if (report_type) {
      allSelectedFilters.report_type = report_type;
    }

    // Radio - Status
    let content_type = null;
    filters.forEach(filter => {
      if (filter.name == 'content-type') {
        content_type = filter.selectedValue;
      }
    });
    if (content_type) {
      allSelectedFilters.content_type = content_type;
    }

    // Date - Last Post
    let report_date_start = null;
    let report_date_end = null;
    filters.forEach(filter => {
      if (filter.name == 'report-date') {
        report_date_start = filter.startDate;
        report_date_end = filter.endDate;
      }
    });
    if (report_date_start) {
      allSelectedFilters.report_date_start = report_date_start;
    }
    if (report_date_end) {
      allSelectedFilters.report_date_end = report_date_end;
    }

    let report_number_range_start = null;
    let report_number_range_end = null;
    let number_of_posts = null;
    filters.forEach(filter => {
      if (filter.name == 'number-of-reports') {
        number_of_posts = parseInt(filter.selectedValue);
      }
    });
    if (number_of_posts) {
      if (number_of_posts === 10) {
        report_number_range_start = 0;
        report_number_range_end = 10;
      } else if (number_of_posts === 20) {
        report_number_range_start = 11;
        report_number_range_end = 20;
      } else {
        report_number_range_start = 21;
      }
      if (report_number_range_start !== null) {
        allSelectedFilters.report_number_range_start = report_number_range_start;
      }
      if (report_number_range_end) {
        allSelectedFilters.report_number_range_end = report_number_range_end;
      }
    }

    props.fetchWithFilters(allSelectedFilters);
  };

  const onChangeReportType = e => {
    filters.forEach(filter => {
      if (filter.name == 'report-type') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value == e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
  };

  const onChangeContentType = e => {
    filters.forEach(filter => {
      if (filter.name == 'content-type') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value == e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
  };

  // Radio - Status
  const onChangeNumberOfReports = e => {
    filters.forEach(filter => {
      if (filter.name == 'number-of-reports') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value == e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
  };

  // Date - Last Post
  // const onLastPostChange = (name, date) => {
  //   filters.forEach(filter => {
  //     if (filter.name == 'last-post') {
  //       filter[name] = date;
  //     }
  //   });
  // };

  // Date - First Post
  const onReportDateChange = (name, date) => {
    filters.forEach(filter => {
      if (filter.name == 'report-date') {
        filter[name] = date;
      }
    });
  };

  const filters = [
    {
      title: <String id="community_admin_report_type" />,
      type: 'radio',
      name: 'report-type',
      selectedValue: '',
      onChange: onChangeReportType,
      options: [
        {
          name: 'all-content',
          id: 'all-content',
          label: <String id="community_admin_filter_all" />,
          value: '',
          isChecked: false,
        },
        {
          name: 'suspicious',
          id: 'suspicious',
          label: <String id="community_admin_suspicious" />,
          value: 'suspicious',
          isChecked: false,
        },
        {
          name: 'adult-content',
          id: 'adult-content',
          label: <String id="community_admin_nudity" />,
          value: 'adult-content',
          isChecked: false,
        },
        {
          name: 'abusive',
          id: 'abusive',
          label: <String id="community_admin_violence" />,
          value: 'abusive',
          isChecked: false,
        },
      ],
    },
    {
      title: <String id="community_admin_content_type" />,
      type: 'radio',
      name: 'content-type',
      selectedValue: '',
      onChange: onChangeContentType,
      options: [
        {
          name: 'all-report',
          id: 'all-report',
          label: <String id="community_admin_filter_all" />,
          value: '',
          isChecked: false,
        },
        {
          name: 'post',
          id: 'post',
          label: <String id="community_admin_post" />,
          value: 'post',
          isChecked: false,
        },
        {
          name: 'comment',
          id: 'comment',
          label: <String id="community_admin_comment" />,
          value: 'comment',
          isChecked: false,
        },
      ],
    },
    {
      title: <String id="community_admin_filter_number_reports" />,
      type: 'radio',
      name: 'number-of-reports',
      onChange: onChangeNumberOfReports,
      selectedValue: '',
      options: [
        {
          name: '0-10',
          id: '0-10',
          label: '0 to 10',
          value: '10',
          isChecked: false,
        },
        {
          name: '11-20',
          id: '11-20',
          label: '11 to 20',
          value: '20',
          isChecked: false,
        },
        {
          name: '21-x',
          id: '21-x',
          label: 'more than 20',
          value: '21',
          isChecked: false,
        },
      ],
    },
    // {
    //   title: <String id="community_admin_post_date" />,
    //   type: 'date',
    //   name: 'post-date',
    //   startDateLabel: 'From',
    //   endDateLabel: 'To',
    //   startDate: null,
    //   endDate: null,
    //   onChange: onLastPostChange,
    // },
    {
      title: <String id="community_admin_report_date" />,
      type: 'date',
      name: 'report-date',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onReportDateChange,
    },
  ];

  const [advancedFiltersState, setAdvancedFiltersState] = useState({
    title:
      stringsContext['community_admin_reported_content'].field +
      ` (${props.reportedContentCount})`,
    filters: filters,
    onSubmitFilters: onSubmitFilters,
  });

  useEffect(() => {
    setAdvancedFiltersState({
      ...advancedFiltersState,
      title:
        stringsContext['community_admin_reported_content'].field +
        ` (${props.reportedContentCount})`,
    });
  }, [props.reportedContentCount]);

  const [isLoading, setIsLoading] = useState(false);

  const bulkActions = [
    {
      label: <String id="community_admin_reject_post" />,
      onClickAction: mems => {
        const ids = mems.map(mem => mem.id);
        closeReport({
          variables: {
            report_ids: ids,
            action: 'approve',
          },
        });
      },
    },
    {
      label: <String id="community_admin_dismiss_report" />,
      onClickAction: mems => {
        const ids = mems.map(mem => mem.id);
        closeReport({
          variables: {
            report_ids: ids,
            action: 'reject',
          },
        });
      },
    },
  ];

  useEffect(() => {
    if (closeReportData.called && !closeReportData.loading) {
      props.refetch();
    }
  }, [closeReportData.called, closeReportData.loading]);

  const singleRowActions = [
    {
      label: <String id="community_admin_reject_post" />,
      onClickAction: el => {
        closeReport({
          variables: {
            report_ids: el.id,
            action: 'approve',
          },
        });
      },
    },
    {
      label: <String id="community_admin_dismiss_report" />,
      onClickAction: el => {
        closeReport({
          variables: {
            report_ids: el.id,
            action: 'reject',
          },
        });
      },
    },
  ];

  const tableProps = {
    nextPage: () => {
      props.onNextPage();
    },
    prevPage: () => {
      props.onPrevPage();
    },
    changeRowPerPage: rowCount => {
      props.onChangeLimitRow(rowCount);
    },
    currentCount: props.currentPageCount,
    totalCount: props.reportsCount,
    columns: [
      {
        header: <String id="community_admin_reported_by" />,
        accessor: 'reporter',
      },
      {
        header: <String id="community_admin_report_detail" />,
        accessor: 'report_detail',
      },
      {
        header: <String id="community_admin_author" />,
        accessor: 'author',
      },
      {
        header: <String id="community_admin_reports_number" />,
        accessor: 'reports_number',
      },
      {
        header: <String id="community_admin_post_date" />,
        accessor: 'post_date',
      },
      {
        header: <String id="community_admin_report_date" />,
        accessor: 'report_date',
      },
      {
        header: <String id="community_admin_content_type" />,
        accessor: 'content_type',
      },
      {
        header: <String id="community_admin_report_type" />,
        accessor: 'report_type',
      },
      {
        header: '',
        accessor: 'options',
      },
    ],

    rowData: [],
    bulkActions: bulkActions,
    currentPage: props.currentPage,
    maxPage: props.maxPage,
  };

  useEffect(() => {
    setIsLoading(false);
  }, [props.reportsList]);

  if (props.reportsList) {
    props.reportsList.forEach(member => {
      let report_type = '';

      if (member.report_type === 'abusive') {
        report_type = ViolenceLabel;
      } else if (member.report_type === 'adult-content') {
        report_type = NudityLabel;
      } else {
        report_type = SuspiciousLabel;
      }

      let name = '';

      if (member?.first_name !== null && member?.first_name !== '') {
        name = member?.first_name + ' ' + member?.last_name;
      }

      tableProps.rowData.push({
        reporter: (
          <>
            {member.reporting_list.map((reporter, i) => {
              if (i < 2) {
                return (
                  <UserInfos
                    key={reporter.username}
                    name={reporter.name}
                    url={reporter.avatar}
                    css={i > 0 ? { mt: '$16' } : {}}
                    link={
                      reporter.first_name !== '' &&
                      reporter.first_name !== 'anonymized'
                        ? `/m/${reporter.username}`
                        : undefined
                    }
                  />
                );
              }
            })}
            {member.reporting_list.length > 3 && (
              <Text>
                <String
                  id="admin_reports_reporter_number"
                  extras={{ count: member.reporting_list.length - 3 }}
                />
              </Text>
            )}
          </>
        ),
        report_detail: (
          <>
            {member.reporting_list.map((report, i) => {
              if (i < 2) {
                return (
                  <Text
                    key={report.createdAt + 'detail'}
                    css={i > 0 ? { mt: '$16' } : {}}
                  >
                    {report.detail}
                  </Text>
                );
              }
            })}
            {member.reporting_list.length > 3 && (
              <Text>
                <String
                  id="admin_reports_reporter_number"
                  extras={{ count: member.reporting_list.length - 3 }}
                />
              </Text>
            )}
          </>
        ),
        author: (
          <UserInfos
            name={name}
            job={member.title}
            link={
              member.first_name !== '' && member.first_name !== 'anonymized'
                ? `/m/${member.username}`
                : undefined
            }
          />
        ),
        reports_number: member.group_posts_count,
        post_date: convertUTCToLocalDate(member.post_date),
        report_date: (
          <DateIconLink
            date={convertUTCToLocalDate(member.report_date)}
            time={convertUTCtoLocalTime(member.report_date)}
            actionType={'onclick'}
            postData={member.postData}
            postType={member.content_type}
            total={member.total}
          />
        ),
        content_type: member.content_type === 0 ? PostLabel : CommentLabel,
        report_type: report_type,
        options: <Options actions={singleRowActions} id={member.id} />,
      });
    });
  }

  return (
    <>
      <AdvanceFilters
        {...advancedFiltersState}
        onSearchSubmit={props.onSearchSubmit}
      />
      <Table
        {...tableProps}
        loading={props.loading || closeReportData.loading}
      ></Table>
      {isLoading ? <Loader isLoading="true" /> : null}
    </>
  );
};

export default ReportedContentTable;
