import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';
import { Link } from 'gatsby';

export const UserContentBoxWrapper = styled('div', {
  display: 'flex',
});

export const ImageWrapper = styled('div', {
  width: '50px',
  height: '62px',
  position: 'relative',
  marginRight: '$24',
  img: {
    width: '50px',
    height: '62px',
    marginRight: '$24',
    borderRadius: '$8',
    overflow: 'hidden',
  },
});

export const LinkWrapper = styled('a', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    hasNoMargin: {
      true: {
        marginLeft: '0',
      },
    },
  },
});

export const StyledName = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  color: '$mainTextColor',
  lineHeight: '$2',
});
