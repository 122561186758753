import { Heading } from '@components/atoms/heading/Heading';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import PropTypes from 'prop-types';
import React from 'react';

import {
  SmallStatBlockWrapper,
  StatNumber,
  Icon,
  SmallStatBlockContent,
} from './SmallStatBlock.styled';

export const SmallStatBlock = props => {
  const { title, stat, percentage, iconName, negativePercent } = props;
  return (
    <SmallStatBlockWrapper>
      <Icon>
        <Svg icon={iconName} />
      </Icon>
      <SmallStatBlockContent>
        <Heading level="h5" css={{ marginBottom: '$12', color: '$white' }}>
          {title}
        </Heading>
        <Heading
          level="h3"
          weight="bold"
          css={{ color: '$yellow', marginBottom: '$32', lineHeight: '1em' }}
        >
          {stat}
        </Heading>
        <div>
          <Text css={{ color: negativePercent ? '$red' : '$green', display: 'inline', marginRight: '$4' }}>
            {percentage}
          </Text>{' '}
          <Text css={{ display: 'inline', color: '$white' }}>Since last month</Text>
        </div>
      </SmallStatBlockContent>
    </SmallStatBlockWrapper>
  );
};

SmallStatBlock.propTypes = {
  title: PropTypes.string.isRequired,
  stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.string,
  iconName: PropTypes.string,
  negativePercent: PropTypes.bool
};
