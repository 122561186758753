import {
  TableBody,
  TableCell,
  TableContainer,
  TableContainerWrapper,
  TableFooter,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableLoading,
  TableRow,
  TableTools,
  TableWrapper,
  MobileCell,
  MobileRow,
} from './Table.styled';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import 'regenerator-runtime';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import TableActions from './components/tools/TableActions';
import TablePagination from './components/tools/TablePagination';
import TableRowPerPage from './components/tools/TableRowPerPage';
import { useIsMobile } from '@src/utils/useIsMobile';
import { Text } from '@components/atoms/text/Text';
import { Heading } from '@components/atoms/heading/Heading';

const Table = props => {
  const data = React.useMemo(() => [], [props.rowData]);
  props.rowData.map((rowData, i) => {
    data.push(rowData);
  });

  const isMobile = useIsMobile(1124, 1240);

  const columns = React.useMemo(() => [], []);
  props.columns.map((column, i) => {
    const columnData = {
      Header: column.header,
      accessor: column.accessor,
    };
    columns.push(columnData);
  });

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <InputCheckBox innerRef={resolvedRef} {...rest} />
          <span style={{ display: 'none' }}>
            <input type="checkbox" ref={resolvedRef} {...rest} />
          </span>
          {rest.chooseAll ? (
            <span>
              <String id="community_admin_choose_all" />
            </span>
          ) : null}
        </>
      );
    }
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ['id'],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useAsyncDebounce,
    useRowSelect,
    hooks => {
      if (!props.isNotMultiSelect) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const onNextPage = () => {
    if (props.nextPage) {
      props.nextPage();
    }
  };

  const onPrevPage = () => {
    if (props.prevPage) {
      props.prevPage();
    }
  };

  const onChangeRowPerPage = rowCount => {
    if (props.changeRowPerPage) {
      props.changeRowPerPage(rowCount);
    }
  };

  return (
    <TableWrapper>
      <TableContainerWrapper>
        {!props.hasNoPagination ? (
          <TableTools>
            {!props.isNotMultiSelect ? (
              <TableActions
                selectAllRows={tableInstance.toggleAllRowsSelected}
                selectedRows={tableInstance.selectedFlatRows}
                deselectAllRows={tableInstance.toggleAllRowsSelected.bind(
                  null,
                  false
                )}
                bulkActions={props.bulkActions}
              ></TableActions>
            ) : (
              <div></div>
            )}

            <div>
              <TablePagination
                onNextPage={onNextPage}
                onPrevPage={onPrevPage}
                currentCount={props.currentCount}
                totalCount={props.totalCount}
                currentPage={props.currentPage}
                maxPage={props.maxPage}
                loading={props.loading}
              ></TablePagination>
            </div>
          </TableTools>
        ) : null}
        {!isMobile ? (
          <TableContainer {...getTableProps()} loading={props.loading}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <TableHeadCell
                        {...column.getHeaderProps()}
                        isNotMultiSelect={props.isNotMultiSelect}
                        isFirstColImage={props.isFirstColImage}
                      >
                        {column.render('Header')}
                      </TableHeadCell>
                    );
                  })}
                </TableHeadRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <TableRow isSelected={row.isSelected} {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          isNotMultiSelect={props.isNotMultiSelect}
                          hasOptions={props.hasOptions}
                          isFirstColImage={props.isFirstColImage}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </TableContainer>
        ) : (
          <>
            {rows.length === 0 && props.loading && (
              <>
                {[...Array(5)].map(el => {
                  return (
                    <MobileRow
                      key={'mobile-table-row-' + el}
                      css={{
                        justifyContent: 'center',
                      }}
                    >
                      <LoaderSpinner size={48} />
                    </MobileRow>
                  );
                })}
              </>
            )}
            {rows.map(row => {
              prepareRow(row);
              return (
                <MobileRow
                  {...row.getRowProps()}
                  css={{
                    justifyContent: props.loading ? 'center' : 'flex-start',
                  }}
                >
                  {props.loading ? (
                    <LoaderSpinner size={48} />
                  ) : (
                    <>
                      {row.cells.map(cell => {
                        const isOptions =
                          cell.column.Header === '' ||
                          cell.column.id === 'selection';
                        return (
                          <MobileCell
                            isOptions={isOptions}
                            {...cell.getCellProps()}
                          >
                            {!isOptions && (
                              <Text
                                size="12"
                                css={{
                                  textTransform: 'uppercase',
                                  fontFamily: '$ArtifaktLegendBold',
                                  opacity: '.5',
                                }}
                              >
                                {cell.render('Header')}
                              </Text>
                            )}
                            <Text>{cell.render('Cell')}</Text>
                          </MobileCell>
                        );
                      })}
                    </>
                  )}
                </MobileRow>
              );
            })}
          </>
        )}
        {rows.length === 0 && (
          <div
            style={{
              margin: '0 auto',
              padding: '24px',
              height: '480px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {props.loading && !isMobile && (
              <TableLoading>
                <LoaderSpinner size={48} />
              </TableLoading>
            )}
            {!props.loading && (
              <Heading
                level="h4"
                css={{ fontFamily: '$ArtifaktLegendBold', textAlign: 'center' }}
              >
                <String id="generic_empty_table" />
              </Heading>
            )}
          </div>
        )}
      </TableContainerWrapper>
      {props.totalCount > props.maxPage ? (
        <TableFooter>
          <TableRowPerPage
            onChangeRowPerPage={onChangeRowPerPage}
          ></TableRowPerPage>
          <TablePagination
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            currentCount={props.currentCount}
            totalCount={props.totalCount}
            currentPage={props.currentPage}
            maxPage={props.maxPage}
            loading={props.loading}
          ></TablePagination>
        </TableFooter>
      ) : null}
    </TableWrapper>
  );
};

export default Table;

Table.propTypes = {
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  changeRowPerPage: PropTypes.func.isRequired,
  currentCount: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  rowData: PropTypes.array,
  bulkActions: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  maxPage: PropTypes.number,
};

Table.defaultProps = {
  totalCount: 0,
  maxPage: 12,
};
