import * as animationData from './Loader.json';

import {
  LoaderWrapper,
  LoaderWrapperSection,
  ModalOverlay,
  ModalOverlaySection,
  StyledSpinner,
} from './Loader.styled';
import React, { useEffect, useRef } from 'react';

import Lottie from 'lottie-react';
import ReactDOM from 'react-dom';

const Loader = ({ isLoading, isSection, noPortal }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    if (isLoading && !isSection) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading, isSection]);

  return isLoading && isSection ? (
    <>
      <LoaderWrapperSection aria-modal aria-hidden tabIndex={-1} role="dialog">
        <ModalOverlaySection>
          <Lottie
            {...defaultOptions}
            height={200}
            width={200}
          />
        </ModalOverlaySection>
      </LoaderWrapperSection>
    </>
  ) : isLoading && noPortal ? (
    <>
      <LoaderWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          {...defaultOptions}
        />
        <ModalOverlay />
      </LoaderWrapper>
    </>
  ) : isLoading ? (
    ReactDOM.createPortal(
      <>
        <LoaderWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Lottie
            options={defaultOptions}
            height={200}
            width={200}
            {...defaultOptions}
          />
          <ModalOverlay />
        </LoaderWrapper>
      </>,
      document.body
    )
  ) : null;
};

export default Loader;
