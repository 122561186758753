import {
  ArtistProfession,
  ArtworkByBoxWrapper,
  ArtworkByTextWrapper,
  AvatarStyled,
  Label,
} from './ArtworkBy.styled';

import { Heading } from '@components/atoms/heading/Heading';
import PropTypes from 'prop-types';
import React from 'react';

const ArtworkByBox = props => (
  <ArtworkByBoxWrapper>
    <AvatarStyled {...props.avatarProps} />
    <ArtworkByTextWrapper>
      <Label size="12" css={{ textTranform: 'uppercase' }}>
        {props.label}
      </Label>
      <Heading level="h5">{props.artistName}</Heading>
    </ArtworkByTextWrapper>
  </ArtworkByBoxWrapper>
);

ArtworkByBox.propTypes = {
  avatarProps: PropTypes.shape({
    shape: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({})),
        ]),
      }).isRequired,
    }),
  }),
  label: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  artistProfession: PropTypes.string.isRequired,
};

export default ArtworkByBox;
