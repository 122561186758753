import React from 'react';
import SidebarMenu from '@components/molecules/sidebarMenu/SidebarMenu';
import String from '@components/atoms/string/String';

const GroupCommunitySideBar = props => {
  const data = {
    title: <String id="community_admin_sidebar_title" />,
    nav: [
      {
        activeName: 'members',
        label: <String id="community_admin_manage_members" />,
        url: `${props.groupUrl  }/admin/members`,
        svgIcon: 'group-users',
        noticeLabel:
          props.pendingNotifications.members.amount > 0
            ? `${props.pendingNotifications.members.amount  } New members`
            : '',
      },
      {
        activeName: 'pending-posts',
        label: <String id="community_moderation_pending_post_text" />,
        url: `${props.groupUrl  }/admin/pending-posts`,
        svgIcon: 'passing-time',
        noticeLabel:
          props.pendingNotifications.publication.amount > 0
            ? `${props.pendingNotifications.publication.amount  } Posts`
            : '',
      },
      {
        activeName: 'reported-posts',
        label: <String id="community_admin_reported_content" />,
        url: `${props.groupUrl  }/admin/reported-posts`,
        svgIcon: 'warning',
        noticeLabel:
          props.pendingNotifications.reportContent.amount > 0
            ? `${props.pendingNotifications.reportContent.amount  } Posts`
            : '',
      },
      {
        activeName: 'settings',
        label: <String id="community_admin_group_settings" />,
        url: `${props.groupUrl  }/admin/settings`,
        svgIcon: 'settings',
      },
    ],
  };

  return <SidebarMenu {...data} location={props.location} />;
};

export default GroupCommunitySideBar;
