import parse from 'html-react-parser';

const GetUrl = (handle, context) => {
  const UrlContext = context;
  const urlHandle = handle;

  const cmsApiUrls = {
    dev: 'https://area-cms.autodesk.local.com',
    staging: 'https://area-stage-cms.autodesk.com',
    staging2: 'https://area-stage2-cms.autodesk.com',
    production: 'https://area-cms.autodesk.com',
  };

  const getInternalLink = url =>
    url.replace(cmsApiUrls[`${process.env.GATSBY_ACTIVE_ENV}`], '');
  return parse(
    UrlContext[urlHandle]
      ? getInternalLink(UrlContext[urlHandle].url)
      : urlHandle
  );
};
export default GetUrl;
