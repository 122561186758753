import { LogInBoxWrapper, SignInPageWrapper } from './SignIn.styled';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import Layout from '@components/organisms/layout/Layout';
import LogInBox from '@components/organisms/logInBox/LogInBox';

import SEO from '@components/atoms/seo/Seo';
import UrlsContext from '@context/UrlsContext';
import UserSignUpConfirmation from '@components/organisms/signUpBox/userSignUpConfirmation/UserSignUpConfirmation';
import { navigate } from 'gatsby';

const SignInPage = context => {
  const authContext = useContext(AuthContext);
  const UrlContext = useContext(UrlsContext);

  const [loginState, setLoginState] = useState({
    email: '',
    emailConfirmationSent: false,
  });

  const onResendEmailVerification = email => {
    setLoginState({
      email,
      emailConfirmationSent: true,
    });
  };

  useEffect(() => {
    if (
      authContext.isAuthenticated &&
      authContext.isVerified &&
      authContext.isOnboarded
    ) {
      navigate(`/m/${authContext.userSlug}`, { replace: true });
    } else if (
      authContext.isAuthenticated &&
      !authContext.isVerified &&
      authContext.firstName === null
    ) {
      navigate(GetUrl('onboardingRegistrationName', UrlContext));
    } else if (authContext.isAuthenticated && !authContext.isVerified) {
      navigate(GetUrl('onboardingSignupValidation', UrlContext));
    } else if (
      authContext.isAuthenticated &&
      authContext.isVerified &&
      !authContext.isOnboarded
    ) {
      navigate(GetUrl('onboardingAvatarAndBio', UrlContext));
    }
  }, [authContext]);

  return (
    <Layout>
      <SEO title="Area - Sign in" slug="login" />
      {!loginState.emailConfirmationSent ? (
        <SignInPageWrapper className="container">
          <LogInBoxWrapper>
            <LogInBox onResendEmailVerification={onResendEmailVerification} />
          </LogInBoxWrapper>
        </SignInPageWrapper>
      ) : (
        <UserSignUpConfirmation email={loginState.email} />
      )}
    </Layout>
  );
};

export default SignInPage;
