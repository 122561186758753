import { styled, keyframes } from '@root/stitches.config';
import { Link } from 'gatsby';

const buttonStyled = {
  color: '$white',
  fontSize: '$16',
  lineHeight: '1.4',
  display: 'inline-flex',
  fontFamily: 'ArtifaktElementBold',
  letterSpacing: '.2px',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  border: 'none',
  textDecoration: 'none',
  textAlign: 'center',

  '&:hover': {
    backgroundColor: '$darkGrey',
    transition: 'background-color .5s ease',
  },

  variants: {
    hasChildren: {
      hasChildren: {
        '&:hover': {
          // backgroundColor: '$charcoal100',
          transition: 'background-color .5s ease',
        },
      },
    },
    circleSize: {
      small: {
        width: '$32',
        height: '$32',
        minWidth: '$32',
        minHeight: '$32',
        padding: '0px',
        svg: {
          width: '$16',
          height: '$16',
        },
      },
      normal: {
        svg: {
          width: '$24',
          height: '$24',
        },
      },
      large: {
        svg: {
          width: '$32',
          height: '$32',
        },
      },
    },
    colortheme: {
      elements: {
        backgroundColor: '$elements',
        color: '$blackToWhite',
        transition: 'color .3s ease',
        svg: {
          fill: '$blackToWhite',
        },
      },
      normal: {
        backgroundColor: '$blackToWhite',
        color: '$whiteToBlack',
        transition: 'color .3s ease',
        svg: {
          fill: '$whiteToBlack',
        },
      },
      normalReverse: {
        backgroundColor: '$whiteToBlack',
        color: '$blackToWhite',
        transition: 'color .3s ease',
        svg: {
          fill: '$blackToWhite',
        },
      },
      black: {
        backgroundColor: '$black',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: '$white',
        },
      },
      reverse: {
        backgroundColor: '$black',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: '$white',
        },
      },
      headerButtons: {
        backgroundColor: '$headerButtons',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: 'white',
        },
      },
      white: {
        backgroundColor: '$white',
        color: '$black',
        svg: {
          fill: '$black',
        },
        transition: 'backgroundColor .3s ease',
        '&:hover': {
          backgroundColor: '$lightGrey',
        },
      },
      whiteToBlack: {
        backgroundColor: '$white',
        color: '$black',
        svg: {
          fill: '$black',
        },
        transition: 'color .3s ease',
        transition: 'backgroundColor .3s ease',

        '&:hover': {
          backgroundColor: '$black',
          color: '$white',
          svg: {
            fill: '$white',
          },
        },
      },
      gray: {
        backgroundColor: '$lightGrey',
        color: '$blackToWhite',
        transition: 'color 500',
        svg: {
          fill: '$blackToWhite',
        },

        '&:hover': {
          color: '$whiteToBlack',
          svg: {
            fill: '$whiteToBlack',
          },
        },
      },
      lightgray: {
        backgroundColor: '$lightGrey',
        color: '$white',
        svg: {
          fill: '$white',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
        color: '$blackToWhite',
        svg: {
          fill: '$blackToWhite',
        },
        '&:hover': {
          '@bp2': {
            backgroundColor: '$elements',
            // color: '$blackToWhite',
            transition: 'color 500',
            svg: {
              // fill: '$blackToWhite',
            },
          },
        },
      },
      transparentFull: {
        backgroundColor: 'transparent',
        color: '$blackToWhite',
        svg: {
          fill: '$blackToWhite',
        },
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      transparentWhite: {
        backgroundColor: 'transparent',
        color: '$white',
        svg: {
          fill: '$white',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          svg: {
            fill: '$blackToWhite',
          },
        },
      },
      transparentGray: {
        backgroundColor: 'transparent',
        transition: 'color .3s ease',
        justifyContent: 'flex-end',
        svg: {
          fill: 'rgb(256, 256, 256,0.5)',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          svg: {
            fill: '$blackToWhite',
          },
        },
      },
      reactions: {
        backgroundColor: '$white',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.2)',
          transition: 'background-color .5s ease',
        },
      },
      reactionsInactive: {
        stroke: '$white',
        strokeWidth: '$3',
        fill: 'rgba(0, 0, 0, 0.5)',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.2)',
          transition: 'background-color .5s ease',
        },
      },
      linkedin: {
        backgroundColor: '$linkedin',
        color: '$white',
        transition: 'color .3s ease',
        svg: {
          fill: '$white',
        },
      },
      google: {
        backgroundColor: '$white',
        color: '$black',
        transition: 'all .3s ease',
        border: '1px solid $grey',

        '&:hover': {
          backgroundColor: '$lightGrey',
          color: '$black',
          transition: 'all .1s ease',
        },
        svg: {
          fill: '$white',
        },
      },
      apple: {
        backgroundColor: '$black',
        color: '$white',
        transition: 'color .1s ease',
        svg: {
          fill: '$white',
        },
      },
    },
    type: {
      // ds_clean
      // This type isn't used
      // reaction: {
      //   borderRadius: '$pill',
      //   height: '44px',
      //   display: 'flex',
      //   alignItems: 'center',
      //   cursor: 'pointer',
      //   color: '$charcoal300',
      //   fontFamily: 'var(--ArtifaktElementRegular)',
      //   px: '$12',
      //   span: {
      //     marginLeft: '$4',
      //     fontSize: '$12',
      //   },
      // },
      circle: {
        width: '46px',
        height: '46px',
        minWidth: '46px',
        minHeight: '46px',
        borderRadius: '50%',
        fontFamily: 'var(--ArtifaktElementBold)',
        '&:focus': {
          boxShadow: '0 0 0 2pt $darkGrey',
          outline: '0',
        },
        span: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      link: {
        width: 'auto',
        padding: '0',
        color: '$black',
        'span + span': {
          marginLeft: '$4',
        },
      },
      normal: {
        borderRadius: '$8',
        padding: '$8 $16',
        minHeight: '$48',
        'span + span': {
          marginLeft: '$4',
        },
      },
    },
    isDisabled: {
      isDisabled: {
        pointerEvents: 'none',
        backgroundColor: '$mediumGrey',
      },
    },
    isSvgIconWithLabel: {
      true: {
        padding: '11px 20px',

        minHeight: '$48',
        svg: {
          alignSelf: 'flex-end',
          // marginRight: '$8',
        },
      },
    },
    isForDropDown: {
      true: {
        borderRadius: '0',
      },
    },
  },
};

export const ButtonLinkExternalStyled = styled('a', buttonStyled);
export const ButtonLinkStyled = styled(Link, buttonStyled);
export const ButtonActionStyled = styled('button', buttonStyled);

export const ButtonNotificationBubble = styled('span', {
  backgroundColor: '$red',
  br: '50%',
  minWidth: '19px',
  height: '19px',
  fontSize: '$12',
  lineHeight: '0.83em',
  fontFamily: '$ArtifaktElementBold',
  position: 'absolute',
  top: 0,
  right: 0,
  span: {
    display: 'inline-flex',
    position: 'relative',
    color: '$black',
    padding: '$4',
    alignItems: 'center',
  },
});

export const CounterAndSVGWrapper = styled('span', {
  position: 'relative',
  lineHeight: '1em',
  display: 'flex',
  alignItems: 'center'
});

