import { styled } from '@root/stitches.config';

export const SectionWrapper = styled('div', {
  mt: '$32'
});

export const DropdownWrapper = styled('div', {
  display: 'flex',
  '.dropdown:not(:first-child)': {
    marginLeft: '$16'
  }
});

export const DatePickerWrapper = styled('div', {
  marginBottom: '$32',
  display: 'grid',
});