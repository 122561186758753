import { styled } from '@root/stitches.config';

export const SmallStatBlockWrapper = styled('div', {
  backgroundColor: '$greyBox',
  padding: '$24 $32',
  borderRadius: '$16',
  position: 'relative',
});

export const StatNumber = styled('div', {});

export const Icon = styled('div', {
  width: '$54',
  height: '$54',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$thumbnailBorder',
  borderRadius: '50%',
  position: 'absolute',
  top: '$24',
  right: '$24',
  svg: {
    width: '$32',
    height: '$32',
    fill: '$mainTextColor',
  },
});
export const SmallStatBlockContent = styled('div', {});
