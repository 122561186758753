import {
  FullBackgroundContent,
  FullBackgroundImage,
  FullBackgroundTitle,
  FullBackgroundWrapper,
} from './FullBackground.styled';

import ArtworkByBox from '../artworkByBox/ArtworkByBox';
import Image from '@components/atoms/image/Image';
import PropTypes from 'prop-types';
import React from 'react';

const FullBackground = props => {
  return (
    <FullBackgroundWrapper className="test">
      <FullBackgroundContent>
        <FullBackgroundTitle>{props.children}</FullBackgroundTitle>
        <ArtworkByBox {...props.artworkByProps} />
      </FullBackgroundContent>
      <FullBackgroundImage>
        <Image {...props.image} isBackground={true}></Image>
      </FullBackgroundImage>
    </FullBackgroundWrapper>
  );
};

FullBackground.propTypes = {
  artworkByProps: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
};

export default React.memo(FullBackground, () => true);
