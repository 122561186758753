import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const Wrapper = styled('div', {
  width: '100%',
  height: '$24',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$purple',
  marginBottom: '$32',
});

export const TextStyled = styled(Text, {
  lineHeight: '$12',
  fontFamily: '$ArtifaktElementBold',
  textTransform: 'uppercase',
  color: '$white',
  textAlign: 'center',
});
