import {
  AdvanceFiltersActions,
  AdvanceFiltersAllFilters,
  AdvanceFiltersHeader,
  AdvanceFiltersHeaderLeft,
  AdvanceFiltersHeaderRight,
  AdvanceFiltersWrapper,
  DropdownFilterWrapper,
} from './AdvanceFilters.styled';
import React, { useContext, useState, useCallback } from 'react';
import Button from '@components/atoms/button/Button';
import CheckboxFilter from './checkboxFilter/CheckboxFilter';
import DateFilter from './dateFilter/DateFilter';
import { Heading } from '@components/atoms/heading/Heading';
import InputSearch from '@components/atoms/input/inputSearch/InputSearch';
import PropTypes from 'prop-types';
import RadioFilter from './radioFilter/RadioFilter';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import { Text } from '@components/atoms/text/Text';

const AdvanceFilters = props => {
  const stringsContext = useContext(StringsContext);
  const [advanceFiltersState, setAdvanceFiltersState] = useState({
    isOpened: false,
  });

  const onToggleFilters = () => {
    setAdvanceFiltersState(prevState => ({
      ...prevState,
      isOpened: !prevState.isOpened,
    }));
  };

  const onApplyFilter = useCallback(() => {
    onToggleFilters();
    props.onSubmitFilters();
  }, [onToggleFilters, props]);

  const submitSearch = useCallback(
    e => {
      if (e.type === 'click') {
        props.onSearchSubmit(
          document.querySelector('.input-search-filters').value
        );
      } else if (e.keyCode === 13) {
        props.onSearchSubmit(e.target.value);
      }
    },
    [props]
  );

  return (
    <AdvanceFiltersWrapper>
      <AdvanceFiltersHeader>
        <AdvanceFiltersHeaderLeft>
          <Heading level="h4">{props.title}</Heading>
          {props.onAddAction ? (
            <Button
              actionType="onclick"
              colorTheme="normal"
              onClick={props.onAddAction}
              label={props.actionLabel}
              type="normal"
              css={{ marginLeft: '$16' }}
            />
          ) : null}
        </AdvanceFiltersHeaderLeft>
        <AdvanceFiltersHeaderRight>
          {(!props.isGroupSettings || props.showSearchbar) && (
            <InputSearch
              name="search"
              className="input-search-filters"
              placeholder={
                stringsContext['advanced_filters_search_placeholder']?.field
              }
              css={{ width: '100%', '@bp1': { width: '300px' } }}
              onKeyUp={submitSearch}
            />
          )}
          {props.showFiltersButton && (
            <Button
              type="normal"
              colorTheme="gray"
              actionType="onclick"
              label={<String id="advanced_filters_button_label" />}
              onClick={onToggleFilters}
              css={{
                marginLeft: '0',
                marginTop: '$16',
                color: '$mainTextColor',
                '@bp2': { marginLeft: '$8', marginTop: '0' },
              }}
            />
          )}
        </AdvanceFiltersHeaderRight>
      </AdvanceFiltersHeader>
      {advanceFiltersState.isOpened ? (
        <AdvanceFiltersAllFilters>
          <Button
            type="circle"
            colorTheme="normal"
            actionType="onclick"
            onClick={onToggleFilters}
            css={{ position: 'absolute', top: '$8', right: '$8' }}
            svgIcon="close-big"
          />
          {(props.filters || []).map((filter, index) => {
            if (filter.type === 'checkbox') {
              return (
                <CheckboxFilter
                  onChange={filter.onChange}
                  key={filter.title + filter.type + index}
                  {...filter}
                />
              );
            }
            if (filter.type === 'dropdown') {
              return (
                <DropdownFilterWrapper>
                  <Text
                    css={{
                      textTransform: 'uppercase',
                      color: '$mediumGrey',
                      marginBottom: '$8',
                    }}
                    size="14"
                    level="h6"
                    weight="bold"
                  >
                    {filter.title}
                  </Text>
                  <InputDropdownSearch
                    onChange={filter.onChange}
                    key={filter.title + filter.type + index}
                    {...filter}
                    css={{ marginBottom: '$32', width: '140px' }}
                    placeholder={filter.title}
                  />
                </DropdownFilterWrapper>
              );
            }
            if (filter.type === 'radio') {
              return (
                <RadioFilter
                  onChange={filter.onChange}
                  key={filter.title + filter.type + index}
                  {...filter}
                />
              );
            }

            if (filter.type === 'date') {
              return (
                <DateFilter
                  onChange={filter.onChange}
                  key={filter.title + filter.type + index}
                  {...filter}
                />
              );
            }
          })}
          <AdvanceFiltersActions>
            <Button
              type="normal"
              colorTheme="normal"
              actionType="onclick"
              onClick={onApplyFilter}
              label={<String id="advanced_filters_button_submit_label" />}
            />
          </AdvanceFiltersActions>
        </AdvanceFiltersAllFilters>
      ) : null}
    </AdvanceFiltersWrapper>
  );
};

AdvanceFilters.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmitFilters: PropTypes.func.isRequired,
  showFiltersButton: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // Checkbox
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            isChecked: PropTypes.bool.isRequired,
          })
        ),
      }),

      // Radio
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        selectedValue: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            isChecked: PropTypes.bool.isRequired,
          })
        ),
      }),

      // Date
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        startDateLabel: PropTypes.string.isRequired,
        endDateLabel: PropTypes.string.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        onChange: PropTypes.func,
      }),
    ])
  ),
};

AdvanceFilters.defaultProps = {
  showFiltersButton: true,
};

export default AdvanceFilters;
