import { styled } from '@root/stitches.config';

export const AddEmbedContainer = styled('div', {
  width: 'auto',
  minHeight: '70vh',
  paddingTop: '$32',
  '@bp2': {
    minHeight: 0,
    paddingTop: 0,
  },
});
