const headerMenu = [
  {
    title: 'Inspire',
    url: '/inspire',
    children: [
      {
        title: 'Gallery',
        url: '/inspire/gallery',
      },
      {
        title: 'Articles',
        url: '/inspire/articles',
      },
      {
        title: 'Member Spotlight',
        url: '/inspire/member-spotlight',
      },
      {
        title: 'Member Of The Year',
        url: '/inspire/member-of-year',
      },
    ],
  },
  {
    title: 'Learn',
    url: '/learn',
    children: [
      {
        title: 'Tutorials',
        url: '/learn/tutorials',
      },
      {
        title: 'Courses',
        url: '/learn/courses',
      },
      {
        title: 'Assets',
        url: '/learn/assets',
      },
    ],
  },
  {
    title: 'Community',
    url: '/community',
    children: [
      {
        title: 'Groups',
        url: '/community/groups',
      },
      {
        title: 'Forums',
        url: 'https://forums.autodesk.com/',
        isExternal: true,
      },
    ],
  },
];

export default headerMenu;
