import {
  ProfileBoxAvatar,
  ProfileBoxSmallGroup,
  ProfileBoxSmallInfo,
  ProfileBoxSmallInfoWrapper,
  ProfileBoxSmallWrapper,
  StyledBoldText,
  StyledSvg,
} from './ProfileBoxSmall.styled';

import Avatar from '@components/atoms/avatar/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const ProfileBoxSmall = props => {
  return (
    <ProfileBoxSmallWrapper>
      <ProfileBoxAvatar alt={props.alt} to={props.url}>
        <Avatar {...props.avatar} shape="circle" css={{ marginRight: '16' }} />
      </ProfileBoxAvatar>
      <ProfileBoxSmallInfoWrapper>
        <ProfileBoxSmallInfo type={props.isPost && 'isPost'}>
          <StyledBoldText
            size="14"
            title={props.alt}
            alt={props.alt}
            to={props.url}
            hasNoHover={props.url === undefined}
          >
            {props.name}
          </StyledBoldText>
          <Text
            size="14"
            css={{
              color: props.isPost ? '$darkGrey' : 'inherit',
            }}
          >
            {props.jobTitle}
          </Text>
        </ProfileBoxSmallInfo>
        {props.isCommunity && props.isPost ? (
          <ProfileBoxSmallGroup>
            <StyledSvg icon="arrow-in-group" />
            <StyledBoldText
              size="14"
              title={props.groupAlt}
              alt={props.groupAlt}
              to={props.groupUrl}
            >
              {props.groupName}
            </StyledBoldText>
          </ProfileBoxSmallGroup>
        ) : null}
      </ProfileBoxSmallInfoWrapper>
    </ProfileBoxSmallWrapper>
  );
};

ProfileBoxSmall.propTypes = {
  isPost: PropTypes.bool.isRequired,
  isCommunity: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  avatar: PropTypes.object,
  groupName: PropTypes.string,
};

export default ProfileBoxSmall;
