import { BoxWrapper } from './Box.styled';
import PropTypes from 'prop-types';
import React from 'react';

const Box = props => {
  const { children, color, shadow, radius, css, type } = props;
  return (
    <BoxWrapper
      color={color ? color : null}
      shadow={shadow ? shadow : null}
      radius={radius ? radius : null}
      type={type ? type : null}
      css={{ ...css }}
    >
      {children}
    </BoxWrapper>
  );
};

export default Box;

Box.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  shadow: PropTypes.string,
  radius: PropTypes.string,
  css: PropTypes.objectOf(PropTypes.any),
};
