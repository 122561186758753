import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import { navigate } from 'gatsby';

const ProtectedRoute = props => {
  const authContext = useContext(AuthContext);
  const authStatus = authContext.checkIfLoggedLocalStorage();

  if (props.isOnboarded != undefined) {
    if (props.isOnboarded == 0) {
      navigate(`/m/${authContext.userSlug}`);
      return null;
    }
  }

  // Is logged and auth
  if (
    props.userAccessReq.includes('auth') &&
    !authStatus &&
    !authStatus.localStorageHasAuth
  ) {
    navigate(props.redirectTo ? props.redirectTo : '/login');
    return null;
  }

  // Is logged and verified
  if (
    props.userAccessReq.includes('verified') &&
    !authStatus &&
    !authStatus.localStorageIsVerified
  ) {
    navigate(props.redirectTo ? props.redirectTo : '/login');
    return null;
  }

  // role protected routes
  // example userAccessReq: userAccessReq={['auth', 'contributor', ['admin', 'contributor', 'editor']]}
  // for this kind of protected routes we'll check for 3 params
  // 1. authentified user
  // 2. user role
  // 3. roles allowed to access route
  if (
    props.userAccessReq[1] !== '' &&
    props.userAccessReq[props.userAccessReq.length - 1] !== undefined
  ) {
    if (
      !props.userAccessReq[props.userAccessReq.length - 1].includes(
        props.userAccessReq[1]
      )
    ) {
      navigate(props.redirectTo ? props.redirectTo : '/login');
      return null;
    }
  }

  return <>{props.children}</>;
};

export default ProtectedRoute;
