import React, { useEffect, useState } from 'react';

import { BreadcrumbWrapper } from './Breadcrumb.styled';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';

const Breadcrumb = ({
  pathname,
  isOnClickItem,
  onClick,
  isEdit,
  customLastItem,
  css,
}) => {
  const [paths, setPaths] = useState([]);
  const [isProfile, setIsProfile] = useState(false);

  useEffect(() => {
    const rawPaths = (!isEdit
      ? pathname.split(/(?!=\/)(.*?)(?=\/)\/+(?!\s)/).filter(el => el !== '')
      : [pathname]
    ).filter(p => !['publish', 'draft', 'pending'].includes(p));

    if (customLastItem !== undefined) {
      rawPaths.push(customLastItem);
    }
    if (isEdit) {
      rawPaths.push('edit');
    }

    if (rawPaths.includes('m')) {
      setIsProfile(true);
      setPaths(
        rawPaths
          // remove the "/m"
          .filter(p => p !== 'm')
          // replace inexistant path for "tutorial_series" by "tutorials"
          .map(p => (p === 'tutorial_series' ? 'tutorials' : p))
      );
    } else {
      setIsProfile(false);
      // filter out "year" from path
      setPaths(rawPaths.filter(p => isNaN(parseInt(p, 10))));
    }
  }, [pathname]);

  if (paths.length > 1) {
    return (
      <nav aria-label="Breadcrumb">
        <BreadcrumbWrapper css={css}>
          {paths.map((path, i) => {
            const fullPath = `${isProfile ? '/m' : ''}/${paths
              .filter((item, j) => j <= i)
              .join('/')}`;
            const cleanedPath = path.replace(/-/g, ' ');

            return (
              <Text
                as="li"
                key={path}
                className={i === paths.length - 1 ? 'last-breadcrumb-item' : ''}
                aria-current={i === paths.length - 1 ? 'page' : ''}
              >
                {i !== paths.length - 1 ? (
                  <>
                    {!isOnClickItem ? (
                      <Link to={fullPath}>{cleanedPath}</Link>
                    ) : (
                      <button onClick={() => onClick(fullPath, path)}>
                        {path}
                      </button>
                    )}
                    <Svg icon="arrow-right-chevron" />
                  </>
                ) : (
                  <Text as="p">{path}</Text>
                )}
              </Text>
            );
          })}
        </BreadcrumbWrapper>
      </nav>
    );
  }
  return null;
};

Breadcrumb.propTypes = {
  css: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  titleOriginalUrl: PropTypes.string,
  onClick: PropTypes.func,
  customLastItem: PropTypes.string,
};

Breadcrumb.defaultProps = {
  isEdit: false,
  titleOriginalUrl: undefined,
  onClick: () => {},
  customLastItem: undefined,
};

export default Breadcrumb;
