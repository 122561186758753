import {
  EditorWrapper,
  SectionWrapper,
} from './ContentPageCommunityGuideline.styled';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import Button from '@components/atoms/button/Button';
import { CMS_GET_PAGE_DATA } from '@src/apollo/query';
import { CMS_UPDATE_PAGE_DATA } from '@src/apollo/mutation';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import Editor from 'react-medium-editor';
import { Heading } from '@components/atoms/heading/Heading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import { useCMSPageUpdateValidation } from '../utils/usePageUpdateValidation';
import Modal from '@components/atoms/modal/Modal';
import useModal from '@components/atoms/modal/useModal';
import AddImage from '@src/templates/create/blockModals/AddImage';

require('medium-editor/dist/css/medium-editor.css');
require('@styles/content_editor.css');
require('@styles/quill.bubble.css');
import 'react-quill/dist/quill.snow.css';

const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false;

const ContentPageCommunityGuideline = context => {
  const { loading, error, data, refetch } = useQuery(CMS_GET_PAGE_DATA, {
    variables: { slug: 'about' },
    context: { clientName: 'cms' },
  });
  const [updatePage, updatePageData] = useMutation(CMS_UPDATE_PAGE_DATA, {
    context: { clientName: 'cms' },
    onCompleted: () => refetch(),
  });

  const [pageData, setPageData] = useState({
    title: '',
    wysiwyg: '',
  });

  const { isShowing: isShowingSeoImage, toggle: toggleSeoImage } = useModal();

  const [seoData, setSeoData] = useState({
    image: undefined,
  });

  useEffect(() => {
    if (data && data.page) {
      setPageData({
        title: data.page.title,
        wysiwyg: data.page.wysiwyg,
      });
    }
  }, [data]);

  const formValidation = useCMSPageUpdateValidation(pageData);
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  function updateData() {
    if (formValidation.descriptionIsError || formValidation.titleIsError) {
      setPageData({
        ...pageData,
        ...formValidation,
      });
      let counter = 0;

      for (let i = 0; i < Object.values(formValidation).length; i++) {
        if (Object.values(formValidation)[i] === true) {
          counter += 1;
        }
      }
      handleUpdateClientNotification({
        text: `cms_notification_validation_error_${
          counter >= 2
            ? 'cms_multiple_empty'
            : Object.keys(formValidation)
                .filter(el => !el.includes('IsError'))
                .map(el => formValidation[el])[0]
        }`,
        type: 'error',
        autoDismiss: true,
      });
    } else {
      setPageData({
        ...pageData,
        ...formValidation,
      });
      updatePage({
        variables: {
          id: data.page.id,
          input: {
            title: pageData.title,
            wysiwyg: pageData.wysiwyg,
          },
        },
      });
    }
  }

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <Loader isLoading={loading || updatePageData.loading} />
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        <String id="content_page_community_guidelines_title" />
      </Heading>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_community_guidelines_section_hero_title" />
        </Heading>
        <InputText
          onChange={e => {
            setPageData({
              ...pageData,
              title: e.target.value,
            });
          }}
          type="text"
          inputShape="normal"
          name="title"
          label={
            <String id="content_page_community_guidelines_title_input_label" />
          }
          value={pageData?.title}
          css={{ margin: '$16 0' }}
          placeholder="Title"
          error={pageData?.titleErrorMessage || null}
          isError={pageData?.titleIsError || false}
          inputTheme="transparent"
          required
        />
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_community_guidelines_content_title" />
        </Heading>
        <Heading level="h5" css={{ lineHeight: '1em', mt: '$8' }}>
          <String id="content_page_community_guidelines_content_subtitle" />
        </Heading>
        {data?.page?.wysiwyg && (
          <EditorWrapper>
            <ReactQuill
              theme="bubble"
              defaultValue={data?.page?.wysiwyg}
              title="this is a label"
              onChange={e => setPageData({ ...pageData, wysiwyg: e })}
              modules={{
                toolbar: [
                  [{ header: 2 }],
                  ['bold', 'italic'],
                  ['link'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                ],
              }}
              formats={Editor.formats}
              bounds=".wysiwyg-editor"
              placeholder="Write something..."
            />
          </EditorWrapper>
        )}
      </SectionWrapper>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_community_guidelines_section_seo_title" />
        </Heading>
        <Heading level="h5" css={{ lineHeight: '1em', marginTop: '$24' }}>
          <String id="content_page_community_guidelines_section_seo_image_title" />
        </Heading>
        {seoData.image && seoData.image !== null && (
          <img
            src={seoData.image.data_url}
            alt="banner"
            style={{ marginTop: '24px' }}
          />
        )}
        <div>
          <Button
            label={
              <String id="content_page_community_guidelines_upload_banner_label" />
            }
            colorTheme="normal"
            svgIcon="image"
            css={{ marginTop: '$32' }}
            actionType="onclick"
            onClick={toggleSeoImage}
          />
          {seoData.image && seoData.image !== null && (
            <Button
              label={
                <String id="content_page_community_guidelines_remove_banner_label" />
              }
              colorTheme="gray"
              svgIcon="close-big"
              css={{ mt: '$32', ml: '$16' }}
              actionType="onclick"
              onClick={() => {
                setSeoData({
                  ...seoData,
                  image: null,
                });
              }}
            />
          )}
        </div>
        <Modal isShowing={isShowingSeoImage} hide={toggleSeoImage}>
          <AddImage
            imageMaxSizeBytes={1500000}
            onClose={toggleSeoImage}
            resolutionWidth={1920}
            resolutionHeight={560}
            onSave={(e, i) => {
              setHeroData({ ...heroData, image: i });
            }}
          />
        </Modal>
      </SectionWrapper>
      <Button
        label={<String id="content_page_community_guidelines_save_changes" />}
        colorTheme="normal"
        css={{ marginTop: '$32' }}
        actionType="onclick"
        onClick={updateData}
      />
    </AdminLayout>
  );
};

export default ContentPageCommunityGuideline;
