import {
  BtnWrapper,
  NotificationLeftSide,
  NotificationPageWrapper,
  NotificationsContainer,
} from './Notifications.styled';
import {
  DELETE_NOTIFICATION,
  // NOTIFICATIONS_UPDATE_LAST_VIEW,
  UPDATE_NOTIFICATION_STATUS,
} from '@src/apollo/mutation';
import React, { useContext, useEffect, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import Button from '@components/atoms/button/Button';
import { GET_USER_NOTIFICATIONS } from '@src/apollo/query';
import { Heading } from '@components/atoms/heading/Heading';
import Layout from '@components/organisms/layout/Layout';
import Loader from '@components/atoms/loader/Loader';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import NotificationBox from '@components/molecules/notificationBox/NotificationBox';
import { NotificationsContext } from '@context/NotificationsContext';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import { formatDistance } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';

const NotificationsPage = ({ location, context }) => {
  const { loading, error, data, fetchMore: dataFetchMore } = useQuery(
    GET_USER_NOTIFICATIONS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        page: 1,
        limit: 9,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const { notificationsState, setNotificationsState } = useContext(
    NotificationsContext
  );
  const [currentPage, setCurrentPage] = useState(1);
  const getAvatar = (profilePicture, size) => {
    return getAvatarImage(profilePicture, size);
  };

  const [notificationState, setNotificationState] = useState({
    allNewNotifications: [],
    hasMorePages: false,
  });

  const today = utcToZonedTime(Date.now());
  const formattedToday = format(today, 'yyyy-MM-dd HH:mm:ssXXX');
  const todayToString = formattedToday.toString();

  const getNotificationLink = (
    type,
    slug,
    section,
    content_type,
    parent_slug
  ) => {
    switch (type) {
      case 'post_like':
        return `${FE_URL}/community/groups/${parent_slug}/${slug}`;
        break;

      case 'post_approval':
        return `${FE_URL}/community/groups/${parent_slug}/${slug}`;
        break;
      case 'post_rejection':
        return `${FE_URL}/community/groups/${parent_slug}`;
        break;
      case 'post_new':
        return `${FE_URL}/community/groups/${parent_slug}/admin/pending-posts`;
        break;
      case 'post_comment':
        return `${FE_URL}/community/groups/${parent_slug}/${slug}`;
        break;
      case 'post_comment_reply':
        return `${FE_URL}/community/groups/${parent_slug}/${slug}`;
        break;
      case 'report_post':
        return `${FE_URL}/community/groups/${parent_slug}/admin/reported-posts`;
        break;
      case 'report_member':
        return `${FE_URL}/community/groups/${parent_slug}/admin/members`;
        break;
      case 'report_content':
        return `${FE_URL}/admin-cms/all-reports/user-contents`;
      case 'role_grant':
        return `${FE_URL}/community/groups/${slug}`;
        break;
      case 'role_removal':
        return `${FE_URL}/community/groups/${slug}`;
        break;
      case 'content_like':
        return `${FE_URL}/m/${parent_slug}/${content_type}s/${slug}`;
        break;
    }
  };

  useEffect(() => {
    if (data && data.userNotifications.data.length) {
      const allNotifications = [];
      const notifications = data?.userNotifications.data;
      notifications.map((notification, i) => {
        const avatarSrc = getAvatar(
          notification.source.userProfile.profile_picture,
          notification.source.first_name
        );
        const id = notification.id;
        const author = `${notification.source.first_name} ${notification.source.last_name}`;
        const contentType = notification.type;
        const isNew = notification.status == 'sent' ? true : false;
        const subject = notification.body;
        const title = notification.title;
        const newDate = `${notification.created_at} UTC`;
        const formattedDate = new Date(newDate.replace(/-/g, '/'));
        const notificationDate = formatDistance(formattedDate, today, {
          addSuffix: true,
        });
        const notificationId = notification.id;

        const avatarProps = {
          alt: `${notification.source.first_name} ${notification.source.last_name}`,
          src: getAvatarImage(
            notification.source.userProfile.profile_picture,
            'small'
          ),
        };

        const url = getNotificationLink(
          notification.type,
          notification.slug,
          notification.section,
          notification.content_type,
          notification.parent_slug
        );
        allNotifications.push({
          author: author,
          contentType: contentType,
          isNew: isNew,
          // likeType: likeType,
          notificationDate: notificationDate,
          notificationId: notificationId,
          url: url,
          avatarProps: avatarProps,
          subject: subject,
          title: title,
        });
      });
      setNotificationState({
        ...notificationState,
        allNewNotifications: allNotifications,
        hasMorePages: data.userNotifications.has_more_pages,
      });
    }
  }, [data]);

  const loadMoreText = <String id="notification_load_more" />;

  const [
    deleteNotification,
    { errors: errorDeleteNotification, loading: loadingDeleteNotification },
  ] = useMutation(DELETE_NOTIFICATION);

  const onDeleteNotificationClick = notificationId => {
    deleteNotification({
      variables: {
        notificationId: notificationId,
      },
    });

    const id = notificationId;
    const newArray = notificationState.allNewNotifications;
    const oldArray = notificationState.allOldNotifications;

    if (newArray.findIndex(obj => obj.notificationId === id) !== -1) {
      const index = newArray.findIndex(obj => obj.notificationId === id);
      newArray.splice(index, 1);
      setNotificationState({
        ...notificationState,
        allNewNotifications: newArray,
      });
    } else if (oldArray.findIndex(obj => obj.notificationId === id) !== -1) {
      const index = oldArray.findIndex(obj => obj.notificationId === id);
      oldArray.splice(index, 1);
      setNotificationState({
        ...notificationState,
        allOldNotifications: oldArray,
      });
    }
  };

  const [
    updateStatus,
    { errors: errorUpdateStatus, loading: loadingUpdateStatus },
  ] = useMutation(UPDATE_NOTIFICATION_STATUS);

  const onRemoveNewClick = (notificationId, status) => {
    updateStatus({
      variables: {
        notificationId: notificationId,
        status: status,
      },
    });

    const id = notificationId;
    const newArray = notificationState.allNewNotifications;

    if (newArray.findIndex(obj => obj.notificationId === id) !== -1) {
      const index = newArray.findIndex(obj => obj.notificationId === id);
      newArray[index].isNew = false;
      setNotificationState({
        ...notificationState,
        allNewNotifications: newArray,
      });
    }
  };

  useEffect(() => {
    setNotificationsState({
      notificationsCount: 0,
    });
    // updateNotificationLastView({
    //   variables: { updateNotificationsLastViewDatetime: todayToString },
    // });
  }, []);

  const onLoadMore = () => {
    dataFetchMore({
      variables: {
        page: currentPage + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (
          prev.userNotifications.data != fetchMoreResult.userNotifications.data
        ) {
          const allNotifications = [
            ...prev.userNotifications.data,
            ...fetchMoreResult.userNotifications.data,
          ];
          setCurrentPage(currentPage + 1);
          fetchMoreResult.userNotifications.data = allNotifications;
          return fetchMoreResult;
        }
      },
    });
  };

  return (
    <ProtectedRoute redirectTo="/login" userAccessReq={['auth', 'verified']}>
      <Layout location={location}>
        <NotificationPageWrapper>
          <SEO
            title="Notifications"
            slug="notifications"
            seomatic={context?.pageContext?.entry?.seomatic}
          />
          {/* <NotificationLeftSide>
            <Heading level="h1">
              <String id="notification_page_title" />
            </Heading>
            <Link alt="notifications settings" to="/">
              <String id="notification_page_settings_label" />
            </Link>
          </NotificationLeftSide> */}
          <NotificationsContainer>
            <Heading
              level="h1"
              weight="legendExtraBold"
              css={{ marginBottom: '$24' }}
            >
              Notifications
            </Heading>

            {notificationState.allNewNotifications.length > 0 ? (
              notificationState.allNewNotifications.map((notification, i) => {
                return (
                  <NotificationBox
                    key={i}
                    {...notification}
                    isNew={notification.isNew}
                    onDeleteNotificationClick={onDeleteNotificationClick}
                    onRemoveNewClick={onRemoveNewClick}
                    newNotification
                  />
                );
              })
            ) : !loading ? (
              <String id="notification_no_new_notification" />
            ) : (
              <>
                <LoaderSkeleton height="50px" width="100%" isRadius />
                <LoaderSkeleton height="50px" width="100%" isRadius />
                <LoaderSkeleton height="50px" width="100%" isRadius />
              </>
            )}
            {notificationState.hasMorePages && (
              <BtnWrapper>
                <Button
                  label={<String id="profile_load_more_button" />}
                  actionType="onclick"
                  onClick={onLoadMore}
                />
              </BtnWrapper>
            )}
          </NotificationsContainer>
        </NotificationPageWrapper>
      </Layout>
    </ProtectedRoute>
  );
};
export default NotificationsPage;
