import React, { useContext, useEffect, useState } from 'react';
import { ThumbnailWrapper, WrapperPagination } from '../../Favorites.styled';

import AuthContext from '@src/context/AuthContext';
import { GET_FAVORITED_USERCONTENT } from '@src/apollo/query';
import NoResult from '@components/molecules/noResult/NoResult';
import Pagination from '@components/atoms/pagination/Pagination';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { useQuery } from '@apollo/client';

const Articles = ({ username }) => {
  const [pageState, setPageState] = useState({
    title: '',
    intro: '',
    isDataSet: false,
    activePage: 1,
    totalPage: 1,
  });
  const [articlesState, setArticlesState] = useState({
    articles: [],
    isDataSet: false,
  });
  const authContext = useContext(AuthContext);

  const getFavoritedUserContent = useQuery(GET_FAVORITED_USERCONTENT, {
    variables: {
      username: username,
      contentType: ['article'],
      article_type: [1],
      page: pageState.activePage,
      limit: 12,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getFavoritedUserContent.data
      ? setDataArticles(getFavoritedUserContent.data)
      : null;
  }, [getFavoritedUserContent.data]);

  const setDataArticles = data => {
    const { userContentsFavorited: articles } = data.user;
    const articlesList = [];

    for (let i = 0; i < articles.data.length; i++) {
      const element = articles.data[i];
      const articleSingle = dataToThumbnailViewModel(
        element,
        false,
        authContext.isAuthenticated
      );

      articlesList.push(articleSingle);
    }

    setArticlesState({
      articles: articlesList,
      isDataSet: true,
    });
    const totalPage = Math.ceil(articles.total / articles.per_page);
    setPageState({ ...pageState, totalPage: totalPage });
  };

  const changePage = activePage => {
    setPageState({ ...pageState, activePage: activePage });
    setArticlesState({ ...articlesState, isDataSet: false });
  };

  return (
    <>
      <ThumbnailWrapper>
        {!articlesState.isDataSet ? (
          <>
            <LoaderSkeleton height="320px" width="100%" isRadius />
            <LoaderSkeleton height="320px" width="100%" isRadius />
            <LoaderSkeleton height="320px" width="100%" isRadius />
            <LoaderSkeleton height="320px" width="100%" isRadius />
          </>
        ) : articlesState.isDataSet && articlesState.articles.length > 0 ? (
          articlesState.articles.map(item => {
            return <Thumbnail key={item.id} {...item} />;
          })
        ) : (
          <NoResult
            stringId={'noResult_text_in_favorite_article'}
            isInFavorite
          />
        )}
      </ThumbnailWrapper>
      <WrapperPagination>
        <Pagination
          isUrlLink={false}
          activePage={pageState.activePage}
          onCurrentPageChange={e => changePage(e)}
          pages={pageState.totalPage}
        />
      </WrapperPagination>
    </>
  );
};

export default Articles;
