import { styled } from '@root/stitches.config';

export const NotificationPageWrapper = styled('div', {
  maxWidth: '$container',
  margin: '0 auto',
  padding: '$32 $16 $48',
  display: 'flex',
  flexDirection: 'column',

  '@bp2': {
    flexDirection: 'row',
    padding: '$48 $84 $84',
  },
});

export const NotificationLeftSide = styled('div', {
  marginBottom: '$16',

  h1: {
    fontFamily: '$ArtifaktLegendBold',
    fontSize: '$16',
  },

  a: {
    textDecoration: 'none',
    color: 'unset',
    fontFamily: '$ArtifaktLegendBold',
    fontSize: '$14',
    opacity: '1',
    transition: 'opacity 0.2s ease-out',

    '&:hover': {
      opacity: '0.5',
    },
  },
});

export const NotificationsContainer = styled('div', {
  width: '100%',
  margin: '0 auto',

  '@bp2': {
    width: '65%',
  },
  '@bp3': {
    width: '44.44%',
  },
});

export const BtnWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  my: '$40',
});
