import { styled } from '@root/stitches.config';

export const InputWrapper = styled('div', {
  marginTop: '$24',
  marginBottom: '$16',
});

export const ButtonWrapper = styled('div', {
  marginTop: '$24',
  marginBottom: '$16',
});
