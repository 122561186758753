import {
  CreateLevel,
  CreateLevelHeader,
  SvgHelp,
} from '../../sections/CreateInfo.styled';
import { DropdownWrapper, SectionWrapper } from './Sections.styled';
import {
  InputTextLabel,
  RequiredLabel,
} from '@components/atoms/input/inputText/InputText.styled';
import React, { useContext, useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import DropDown from '@components/molecules/dropDown/DropDown';
import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import InputRadio from '@components/atoms/input/inputRadio/InputRadio';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import SelectUserTable from '@components/molecules/selectUserTable/SelectUserTable';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { monthsNames } from '@src/utils/monthNames';
import { useIsMobile } from '@src/utils/useIsMobile';
import useModal from '@components/atoms/modal/useModal';
import TooltipBox from '@components/atoms/tooltip/TooltipBox';

function years(numberOfYears, yearsBefore = 0) {
  return [...Array(numberOfYears)]
    .map((item, i) => {
      if (i < yearsBefore) {
        return {
          id: new Date().getFullYear() - i - 1,
          name: new Date().getFullYear() - i - 1,
        };
      } else if (i === yearsBefore) {
        return {
          id: new Date().getFullYear(),
          name: new Date().getFullYear(),
        };
      } else if (i > yearsBefore) {
        return {
          id: new Date().getFullYear() + i - yearsBefore,
          name: new Date().getFullYear() + i - yearsBefore,
        };
      }
    })
    .sort((a, b) => a.name - b.name);
}

const hardcodedSectionOptions = [
  {
    id: null,
    value: null,
    name: 'No section',
  },
];

function PublishOptions(props) {
  const { value, onChange } = props;
  const isMobile = useIsMobile();

  const [state, setState] = useState(value);
  const { isShowing, toggle } = useModal();
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  useEffect(() => {
    setState({
      ...state,
      articleSectionOptions: value.articleSectionOptions,
      articleTypeOptions: value.articleTypeOptions,
    });
  }, [value]);

  const {
    articleTypeOptions,
    articleSectionOptions,
    year,
    month,
    published,
    selectedArticleType,
    selectedArticleSection,
    selectedMoMember,
  } = state;

  useEffect(() => {
    onChange('publish_options', state);
  }, [
    year,
    month,
    published,
    selectedArticleType,
    selectedArticleSection,
    selectedMoMember,
  ]);

  useEffect(() => {
    if (selectedArticleType === '3') {
      setState({
        ...state,
        month: undefined,
      });
    }
  }, [selectedArticleType]);

  return (
    <SectionWrapper>
      <CreateLevel>
        <CreateLevelHeader>
          <InputTextLabel>
            <String id="create_admin_published_to_label" />
            <TooltipBox
              position={isMobile ? 'top-end' : 'right'}
              trigger={isMobile ? 'click' : 'mouseenter'}
              stringId="create_admin_published_to_section_instruction"
            >
              <SvgHelp icon="circle-help" />
            </TooltipBox>
          </InputTextLabel>
        </CreateLevelHeader>
        <InputRadio
          selectedValue={
            selectedArticleSection === null ? '0' : selectedArticleSection
          }
          options={[...hardcodedSectionOptions, ...articleSectionOptions].map(
            aso => ({
              ...aso,
              value: aso.value === null ? '0' : aso.value,
              label: aso.name,
            })
          )}
          onChange={e => {
            setState({
              ...state,
              selectedArticleSection:
                e.target.value === '0' ? null : e.target.value,
              selectedArticleType: '1',
              selectedMoMember: {
                id: null,
                name: null,
                value: null,
              },
            });
          }}
        />
      </CreateLevel>
      {selectedArticleSection === '1' && (
        <CreateLevel css={{ position: 'relative', zIndex: 6 }}>
          <CreateLevelHeader>
            <InputTextLabel>
              <String id="create_admin_article_type_label" />
            </InputTextLabel>
            <RequiredLabel size="12">
              <String id="create_admin_required_label" />
            </RequiredLabel>
          </CreateLevelHeader>
          <InputDropdownSearch
            onSelect={e => {
              setState({
                ...state,
                selectedArticleType: e.value,
              });
            }}
            value={selectedArticleType}
            options={articleTypeOptions}
            css={{ marginTop: '$32' }}
            placeholder="Select type"
          />
        </CreateLevel>
      )}
      {(selectedArticleType === '2' || selectedArticleType === '3') && (
        <>
          <CreateLevel>
            <CreateLevelHeader>
              <InputTextLabel isError={value?.selectedMoMemberIsError}>
                <String id="create_admin_motm_member_label" />
              </InputTextLabel>
              <RequiredLabel size="12" isError={value?.selectedMoMemberIsError}>
                <String id="create_admin_required_label" />
              </RequiredLabel>
            </CreateLevelHeader>
            {selectedMoMember?.first_name && selectedMoMember?.last_name && (
              <Text size="16" css={{ mb: '$16' }}>
                <String id="create_admin_motm_member_selected_label" />
                {` ${selectedMoMember?.first_name} ${selectedMoMember?.last_name}`}
              </Text>
            )}
            <Button
              actionType="onclick"
              onClick={toggle}
              label={
                <String id="create_admin_motm_member_select_button_label" />
              }
            />
            <Modal isShowing={isShowing} hide={toggle} isSelectUserContent>
              <SelectUserTable
                onSelectUser={e => {
                  setState({
                    ...state,
                    selectedMoMember: e,
                  });
                  handleUpdateClientNotification({
                    type: 'info',
                    text: 'create_admin_select_motm_member',
                    autoDismiss: true,
                  });
                  toggle();
                }}
              />
            </Modal>
          </CreateLevel>
          {selectedArticleType !== '2' && (
            <CreateLevel>
              <CreateLevelHeader>
                <InputTextLabel
                  isError={value.motmDateIsError || value.motyDateIsError}
                >
                  <String id="create_admin_motm_date_label" />
                </InputTextLabel>
                <RequiredLabel
                  size="12"
                  isError={value.motmDateIsError || value.motyDateIsError}
                >
                  <String id="create_admin_required_label" />
                </RequiredLabel>
              </CreateLevelHeader>

              <DropdownWrapper>
                {selectedArticleType === '2' && (
                  <DropDown
                    title="month"
                    options={monthsNames.map((month, i) => ({
                      id: i + 1,
                      name: month,
                    }))}
                    colorTheme="transparent"
                    chevronLocation="after"
                    id="months"
                    className="test"
                    isMultiSelect={false}
                    css={{
                      width: 'auto',
                      maxWidth: '160px',
                      border: '1px solid $lightGrey',
                      br: 8,
                    }}
                    onChange={e => {
                      if (
                        !isNaN(parseInt(JSON.parse(e).selectedValues[0], 10))
                      ) {
                        setState({
                          ...state,
                          month: parseInt(JSON.parse(e).selectedValues[0], 10),
                        });
                      }
                    }}
                    defaultSelected={
                      month !== null && month !== undefined
                        ? [month]
                        : undefined
                    }
                  />
                )}
                <DropDown
                  title="year"
                  options={years(25, 10)}
                  colorTheme="transparent"
                  chevronLocation="after"
                  className="dropdown"
                  isMultiSelect={false}
                  id="year"
                  css={{
                    width: 'auto',
                    maxWidth: '160px',
                    border: '1px solid $lightGrey',
                    br: 8,
                  }}
                  onChange={e => {
                    setState({
                      ...state,
                      year: parseInt(JSON.parse(e).selectedValues[0], 10),
                    });
                  }}
                  defaultSelected={[year]}
                />
              </DropdownWrapper>
            </CreateLevel>
          )}
        </>
      )}
      {/* <CreateLevel css={{display: 'flex', justifyContent: "flex-end"}}>
        <InputCheckBox 
          checked={published}
          onChange={(e) => {
            setState({
              ...state,
              published: e.target.checked,
            })
          }}
        >
          <Text as="p" size="16">
            <String id="create_admin_published_checkbox_label"/>
          </Text>
        </InputCheckBox>
      </CreateLevel> */}
    </SectionWrapper>
  );
}

PublishOptions.propTypes = {
  value: PropTypes.shape({
    articleTypeOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    selectedArticleSection: PropTypes.string.isRequired,
    articleSectionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    motm_year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    moty_year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    published: PropTypes.bool,
    selectedArticleType: PropTypes.oneOf(['1', '2', '3']),
    selectedMoMember: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PublishOptions;
