import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import SelectTags from '@components/molecules/selectTags/SelectTags';
import { Text } from '@components/atoms/text/Text';

const SkillsEdit = props => {
  // Todo need to fetch current data
  const [skillsState, setSkillsState] = useState({
    skillsList: [
      {
        label: 'Animation',
        id: 'animation',
        isSelected: false,
      },
      {
        label: 'Lightning',
        id: 'Lightning',
        isSelected: false,
      },
      {
        label: 'Textures',
        id: 'textures',
        isSelected: false,
      },
    ],
  });

  const onSkillsSelect = index => {
    const updatedSkillsList = JSON.parse(
      JSON.stringify(skillsState.skillsList)
    );
    updatedSkillsList[index].isSelected = !updatedSkillsList[index].isSelected;
    setSkillsState({
      ...skillsState,
      skillsList: updatedSkillsList,
    });
  };

  const onSave = () => {
    let selectedSkills = skillsState.skillsList.map(skills => {
      return skills.isSelected ? skills.id : false;
    });
    selectedSkills = selectedSkills.filter(skillsId => {
      return skillsId ? true : false;
    });

    props.onClose();
  };

  return (
    <div>
      <Heading
        level="h3"
        weight="legendBold"
        css={{ lineHeight: '1.3em', marginBottom: '$24' }}
      >
        What are your skills?
      </Heading>
      <Text css={{ marginBottom: '$24' }}>You’ve gotta choose at least 1!</Text>
      <SelectTags
        onToggleButtonClick={onSkillsSelect}
        list={skillsState.skillsList}
        css={{ marginBottom: '$48' }}
      ></SelectTags>
      <Button
        type="normal"
        label="Cancel"
        colorTheme="gray"
        onClick={props.onClose}
        actionType="onclick"
        css={{ marginRight: '$16' }}
      />
      <Button
        type="normal"
        label="Save"
        colorTheme="normal"
        onClick={onSave}
        actionType="onclick"
      />
    </div>
  );
};

export default SkillsEdit;
