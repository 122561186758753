import React, { useState } from 'react';

import { AddEmbedContainer } from './AddEmbed.styled';
import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const AddEmbed = props => {
  const [addEmbed, setAddEmbed] = useState({
    url: '',
  });

  const onVideoUrlChange = e => {
    setAddEmbed({
      url: e.currentTarget.value,
    });
  };

  const onSave = () => {
    props.onSave(props.type, addEmbed.url);
    props.onClose();
  };

  return (
    <AddEmbedContainer>
      <Heading level="h3" css={{ 
        lineHeight: '1em',
        marginBottom: '$24',
        fontFamily: '$ArtifaktLegendBold',
      }}>
        <String id={`create_modal_${props.type}_title`} />
      </Heading>
      <InputText
        autoComplete={true}
        autoFocus={true}
        onChange={onVideoUrlChange}
        type="url"
        inputShape="normal"
        name="url"
        value={addEmbed.url}
        label={<String id={`create_modal_${props.type}_input_label`} />}
        inputTheme="transparent"
      />
      <Text css={{ marginBottom: '$40' }}>
        <String id={`create_modal_${props.type}_text`} />
      </Text>
      <Button
        type="normal"
        label={<String id={`create_modal_${props.type}_cancel_button`} />}
        colorTheme="gray"
        onClick={props.onClose}
        actionType="onclick"
        css={{ marginRight: '$16' }}
      />
      <Button
        type="normal"
        label={<String id={`create_modal_${props.type}_add_button`} />}
        colorTheme="normal"
        onClick={onSave}
        actionType="onclick"
      />
    </AddEmbedContainer>
  );
};

export default AddEmbed;
