import PropTypes from 'prop-types';
import React from 'react';
import ReactCongrats from '@src/assets/svg/react_congrats.svg';
import ReactCongratsInactive from '@src/assets/svg/react_congrats_inactive.svg';
import ReactLike from '@src/assets/svg/react_like.svg';
import ReactLikeInactive from '@src/assets/svg/react_like_inactive.svg';
import ReactLove from '@src/assets/svg/react_love.svg';
import ReactLoveInactive from '@src/assets/svg/react_love_inactive.svg';
import ReactLoveStrokeInactive from '@src/assets/svg/react_love_stroke_inactive.svg';
import ReactWow from '@src/assets/svg/react_wow.svg';
import ReactWowInactive from '@src/assets/svg/react_wow_inactive.svg';

const SvgReactions = props => {
  if (props.active) {
    switch (props.label) {
      case 'like':
        return <ReactLike />;
      case 'love':
        return <ReactLove />;
      case 'wow':
        return <ReactWow />;
      case 'congrats':
        return <ReactCongrats />;
      default:
        null;
    }
  }
  if (props.inactive) {
    switch (props.label) {
      case 'like':
        return <ReactLikeInactive />;
      case 'love':
        return <ReactLoveInactive />;
      case 'love-stroke':
        return <ReactLoveStrokeInactive />;
      case 'wow':
        return <ReactWowInactive height="40" width="40" />;
      case 'congrats':
        return <ReactCongratsInactive />;
      default:
        null;
    }
  }
};

export default SvgReactions;

SvgReactions.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  inactive: PropTypes.bool,
};

SvgReactions.defaultProps = {
  label: 'like',
};
