import {
  OnboardingStepItem,
  OnboardingSteps,
  SignAsideContent,
  SignAsideTitle,
  SignAsideTitleLabel,
  SignAsideWrapper,
  TagsWrapper,
} from './SignAside.styled';
import React, { useContext } from 'react';

import AuthContext from '@context/AuthContext';
import BadgeToolsList from '@components/molecules/badgesToolsList/BadgeToolsList';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import ProfileCardBuilder from '@components/molecules/profileCardBuilder/ProfileCardBuilder';
import Svg from '@components/atoms/svg/Svg';
import TagList from '@components/molecules/tagList/TagList';

const SignAside = props => {
  const [
    onboardingCompletionState,
    setOnboardingCompletionContext,
  ] = useContext(OnboardingCompletionContext);
  const authContext = useContext(AuthContext);

  return (
    // <Sticky boundaryElement=".container">
    <SignAsideWrapper>
      <SignAsideContent>
        <SignAsideTitle>
          {props.displayAvatar && (
            <ProfileCardBuilder
              fullName={authContext.firstName}
              percentage={props.percentage}
            />
          )}

          <SignAsideTitleLabel>{props.sideBarTitle}</SignAsideTitleLabel>
          <>
            {props.displaySteps && (
              <OnboardingSteps>
                {onboardingCompletionState.map((step, index) => {
                  return (
                    <OnboardingStepItem
                      key={step.title + index}
                      isActive={step.isActive}
                      isCompletedStep={step.isCompleted}
                    >
                      <Svg icon={step.isCompleted ? 'check-fill' : 'circle'} />
                      {step.title}
                    </OnboardingStepItem>
                  );
                })}
              </OnboardingSteps>
            )}

            {props.displayTags && (
              <TagsWrapper>
                <BadgeToolsList
                  isOnBoarding={true}
                  list={props.toolsList}
                  title="Tools"
                  isEditMode={false}
                  css={{ marginBottom: '$24', color: 'white' }}
                />

                <TagList
                  isOnBoarding={true}
                  list={props.industriesList}
                  title="Industries"
                  isEditMode={false}
                  css={{ marginBottom: '$24' }}
                />
              </TagsWrapper>
            )}
          </>
        </SignAsideTitle>
      </SignAsideContent>
    </SignAsideWrapper>
    // </Sticky>
  );
};

export default SignAside;
