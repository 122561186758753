import LogoCircle from '@src/assets/svg/area_logo_circle.svg';
import { styled } from '@root/stitches.config';

export const MobileNavBottomWrapper = styled('div', {
  position: 'sticky',
  left: '0',
  bottom: '0',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  zIndex: '1000',
  '@bp2': {
    display: 'none',
  },
});

export const MobileNavBottomInner = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 63px 1fr 1fr',
  alignItems: 'center',
  justifyItems: 'center',
  paddingBottom: '$16',
  paddingTop: '$8',

  '&:before': {
    content: '""',
    display: 'block',
    backgroundColor: '$headerBlack',
    height: '78px',
    width: '100%',
    position: 'absolute',
    bottom: '-12px',
  },
  variants: {
    isNotLogged: {
      true: {
        gridTemplateColumns: '2fr 63px 2fr',
      },
    },
  },
});

export const Logo = styled(LogoCircle, {
  width: '63px',
  height: '63px',
});

export const LogoBtn = styled('button', {
  border: 'none',
  padding: '0',
  backgroundColor: 'unset',
  height: '66px',
  zIndex: '999',
  marginTop: '-30px',
});
