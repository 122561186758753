import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { formatDateTimeAgo } from './datesHelper';

export function postsDataToViewModel(posts = []){
  return posts.map((post, i) => {
    const postComments = [];
    (post?.children || []).map((child, j) => {
      if (child.status === 'approved') {
        postComments.push(child);
      }
    });
    const parentId = post?.id;
    const postImages = (post?.images || []).map((image, i) => {
      return ({
        src: {
          src: CDN_URL + image.path,
          srcset: `${CDN_URL + image.path} 1x,${
            CDN_URL + image.path
          }  250w,${CDN_URL + image.path}  500w`,
        },
        alt: `${post.author.first_name} ${post.author.last_name}`,
        id: image.id,
      });
    })

    let profilPicture = getAvatarImage(
      post?.author?.userProfile?.profile_picture,
      post?.author?.first_name,
      CDN_URL
    );
    let postLinkPreview = {};
    if (post.link_url !== null && post.link_url !== undefined && post.link_url !== '') {
      postLinkPreview = {
        url: post.link_url,
        title: post.link_title,
        description: post.link_description,
        ...post.link?.image ? {previewImage: {
          src: {
            src: CDN_URL + post.link_image.path,
            srcset: `${CDN_URL + post.link_image.path} 1x,${
              CDN_URL + post.link_image.path
            }  250w,${CDN_URL + post.link_image.path}  500w`,
          },
          alt: post.link_url,
        }} : {},
      };
    }
    const postContent = {
      isLastId: '',
      postComments,
      parentId,
      postLinkPreview:
        post.link_url !== null && post.link_url !== ''
          ? postLinkPreview
          : null,
      commentCount: (post?.children || []).length,
      postLikes: post.usersLikedCount,
      postDate: formatDateTimeAgo(post.createdAt),
      isCommunity: false,
      isPost: true,
      isCurrentUserGroupMember: false,
      groupUrl: `/community/groups/${post.group.slug}`,
      groupAlt: post.group.name,
      postAuthor: {
        name: `${post.author.first_name} ${post.author.last_name}`,
        jobTitle: post.author.title,
        groupName: post.group.name,
        avatar: {
          src: {
            childImageSharp: {
              fixed: {
                height: 48,
                width: 48,
                src: profilPicture,
                srcSet: `${profilPicture} 1x,${profilPicture} 1.5x,${profilPicture} 2x`,
              },
            },
          },
          alt: `${`${post.author.first_name} ${post.author.last_name}`}`,
        },
        url: `/m/${post.author.username}`,
        username: post.author.username,
      },
      postText: post.content,
      postUrl:
        FE_URL +
        '/community/groups/' +
        post.group.slug +
        '/' +
        post.slug,
      postImages,
      groupId: post.group.id,
      likesList: post.usersLiked,
      total: post.total,
      postId: post.id,
      isFavorited: post.isFavorited,
    };

    return postContent;
  })
}