import { styled } from '@root/stitches.config';

export const CardWrapper = styled('button', {
  br: 18,
  border: 'none',
  padding: '$24',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: '$mainTextColor',
  minHeight: '280px',
  position: 'relative',
  textAlign: 'left',
  fontFamily: '$ArtifaktLegend',
  cursor: 'pointer',
  svg: {
    width: '$48',
    height: '$48',
    fill: '$mainBackgroundColor',
  },
  'h3, p': {
    color: '$mainBackgroundColor',
  },
  h3: {
    fontFamily: '$ArtifaktLegendExtraBold',
    fontWeight: 700,
  },
  '.create-type-card-content-wrapper': {
    maxWidth: 'calc(100% - 56px)',
  },
  '.create-type-card-cta': {
    width: '44px',
    height: '44px',
    br: 100,
    background: '$mainBackgroundColor',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0',
    transition: 'opacity .4s ease-out',
    flexShrink: 0,
    position: 'absolute',
    right: '$24',
    bottom: '$24',
    svg: {
      fill: '$mainTextColor',
      width: '$32',
      height: '$32',
    },
  },
  '&:hover': {
    '.create-type-card-cta': {
      opacity: 1,
    },
  },
  variants: {
    isMobile: {
      true: {
        '.create-type-card-cta': {
          opacity: 1,
        },
      },
    },
  },
});
