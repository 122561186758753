import {
  BottomContainer,
  HeaderAvatar,
  CommentWireFrameWrapper,
  SmallLinesContainer,
  RightContainer,
  TopContainer,
} from './PostsWireFrame.styled';

import React from 'react';
import PropTypes from 'prop-types';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';

const CommentWireFrame = ({ numberToPrint, id, isInFavorites, isReply }) => {
  return [...Array(numberToPrint)].map(item => (
    <CommentWireFrameWrapper
      key={`post-wireframe-${id}-${item}`}
      css={!isReply ? { padding: '$32 $16 $16 $16' } : { mt: '$24' }}
    >
      <LoaderSkeleton height="40px" width="40px" isCircle />
      <RightContainer>
        <TopContainer
          css={{
            br: '$8',
            borderBottomRightRadius: '0px',
            borderBottomLeftRadius: '0px',
            pb: '$8',
          }}
        >
          <HeaderAvatar>
            <SmallLinesContainer>
              <LoaderSkeleton height="16px" width="100%" />
            </SmallLinesContainer>
          </HeaderAvatar>
          <LoaderSkeleton height="10px" width="100%" />
          <LoaderSkeleton height="10px" width="70%" />
        </TopContainer>

        {!isInFavorites && (
          <BottomContainer
            css={{
              br: '$8',
              borderTopRightRadius: '0px',
              borderTopLeftRadius: '0px',
            }}
          >
            <LoaderSkeleton height="30px" width="30px" isCircle />
            <SmallLinesContainer
              css={{ ml: '$8', display: 'flex', alignItems: 'center' }}
            >
              <LoaderSkeleton height="30px" width="30px" isCircle />
              <LoaderSkeleton height="16px" width="60px" css={{ ml: '$8' }} />
            </SmallLinesContainer>
          </BottomContainer>
        )}
      </RightContainer>
    </CommentWireFrameWrapper>
  ));
};

CommentWireFrame.propTypes = {
  numberToPrint: PropTypes.number,
  isInFavorites: PropTypes.bool,
};

CommentWireFrame.defaultProps = {
  numberToPrint: 1,
  isInFavorites: false,
};

export default CommentWireFrame;
