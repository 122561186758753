import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Emitter from 'tiny-emitter/instance';
import { CopyPasteQuill } from '@src/utils/copyPasteQuill';
import 'react-quill/dist/quill.snow.css';

require('medium-editor/dist/css/medium-editor.css');
require('../../../styles/content_editor.css');
require('../../../styles/quill.bubble.css');

const ReactQuillInput = props => {
  const { onChangeText, maxLength } = props;

  const [quillState, setQuillState] = useState({});

  const [isMountedState, setIsMontedState] = useState(false);
  const ReactQuill =
    typeof window === 'object' ? require('react-quill') : () => false;
  const { Quill } =
    typeof window === 'object' ? require('react-quill') : () => false;

  if (typeof window === 'object' && isMountedState) {
    Quill.register('modules/clipboard', CopyPasteQuill, true);
  }

  const setQuill = ref => {
    if (!ref || ref === null) return;
    if (typeof ref.getEditor !== 'function') return;
    setQuillState(ref.getEditor());
    setIsMontedState(true);
  };
  const handleChange = html => {
    if (!isMountedState) return;

    if (maxLength) {
      quillState.on('text-change', () => {
        if (quillState.getLength() > maxLength) {
          quillState.deleteText(maxLength, quillState.getLength());
        }
      });
    }
    onChangeText(html, quillState.getText().trim().length);
  };

  if (maxLength) {
    Emitter.on('reactQuill::paste', _quill => {
      if (_quill.getLength() > maxLength) {
        _quill.deleteText(maxLength, _quill.getLength());
      }
    });
  }

  return <ReactQuill ref={setQuill} {...props} onChange={handleChange} />;
};

export default ReactQuillInput;

ReactQuillInput.propTypes = {
  onChangeText: PropTypes.func,
  maxLength: PropTypes.number,
};

ReactQuillInput.defaultProps = {
  onChangeText: () => {},
};
