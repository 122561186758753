import {
  Block,
  BlockImageWrapper,
  CreateSidebarContainer,
  CreateSidebarContent,
  CreateSidebarFooter,
  CreateSidebarSticky,
  CreateSidebarWrapper,
  EditorStepBlocks,
  EditorStepHeader,
  StyledSmall,
} from './CreateSidebar.styled';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Button from '@components/atoms/button/Button';
import CreateCoverStep from './CreateCoverStep';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { useIsMobile } from '@src/utils/useIsMobile';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';

const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

const CreateSidebar = props => {
  const {
    addImageBlock,
    onReOrderBlocks,
    currentStep,
    createState,
    onChangeImageCropIndex,
    gotoNextSection,
    isPublishBtnDisabled,
    isNextBtnDisabled,
    nextDisabledContent,
    onPublish,
    emptyBlockIndexes,
  } = props;

  const [images, setImages] = React.useState([]);
  const {handleUpdateClientNotification} = useContext(ClientNotificationContext)
  const onDrop = images => {
    if(validImageTypes.includes(images[images.length - 1].file.type)){
      setImages(images);
      addImageBlock(images[images.length - 1]);
    } else {
      handleUpdateClientNotification({
        type: 'error',
        text: 'create_cover_invalid_image_format',
        autoDismiss: true,
      })
    }
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      createState.blockList,
      result.source.index,
      result.destination.index
    );
    onReOrderBlocks(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const isMobile = useIsMobile();

  return (
    <CreateSidebarWrapper className="containers">
      <CreateSidebarSticky>
        <CreateSidebarContainer currentStep={currentStep}>
          {currentStep === 'info' ? (
            <CreateSidebarContent currentStep={currentStep}>
              <Text size="12">
                <String id="create_sidebar_notice_text" extras={{type: createState.createType === "tutorial_series" ? "tutorial series" : createState.createType + 's'}}/>
              </Text>
            </CreateSidebarContent>
          ) : null}
          {currentStep === 'cover' &&
          createState.imageBlockList.length > 0 &&
          !isMobile ? (
            <CreateCoverStep
              imageBlockList={createState.coverImagesBlockList}
              currentImageCropIndex={createState.currentImageCropIndex}
              onChangeImageCropIndex={onChangeImageCropIndex}
              images={images}
              onDrop={onDrop}
            />
          ) : null}
          {currentStep === 'editor' ? (
            <CreateSidebarContent currentStep={currentStep}>
              <EditorStepHeader>
                <Text size="16" weight="bold">
                  <String id="create_sidebar_reorder" />
                </Text>
                <Text size="12" css={{ opacity: 0.7 }}>
                  {createState.blockList.length}/{createState.maxBlocks} (
                  <String id="create_sidebar_up_to" /> {createState.maxBlocks})
                </Text>
              </EditorStepHeader>
              <EditorStepBlocks>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {createState.blockList.map((block, i) => {
                          if(block.type === 'asset'){
                            return null;
                          }
                          return (
                            <Draggable
                              isDragDisabled={props.isAddingBlockDone}
                              key={block.slug}
                              draggableId={block.slug}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <Block
                                  ref={provided.innerRef}
                                  isError={emptyBlockIndexes.includes(i)}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {block.type === 'image' && (
                                    <BlockImageWrapper>
                                      <img src={block?.data?.data_url} />
                                    </BlockImageWrapper>
                                  )}

                                  {block.type === 'text' && (
                                    <Svg icon="wysiwyg" />
                                  )}

                                  {block.type === 'content' && (
                                    <Svg icon="thumbnail-series" />
                                  )}

                                  {block.type === 'video' && (
                                    <Svg icon="circle-play" />
                                  )}

                                  {block.type === 'sketchfab' && (
                                    <Svg icon="sketchfab" />
                                  )}
                                  <Svg
                                    css={{
                                      width: '24px',
                                      height: '24px',
                                      position: 'absolute',
                                      right: '-40px',
                                      opacity: props.isAddingBlockDone ? '.3' : '1',
                                      fill: '$mainTextColor',
                                    }}
                                    icon="drag"
                                  />
                                </Block>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </EditorStepBlocks>
            </CreateSidebarContent>
          ) : null}
        </CreateSidebarContainer>
        <CreateSidebarFooter css={{ zIndex: 4 }}>
          {props.showSaveDraftBtn && (
            <Button
              type="normal"
              label={<String id="create_sidebar_button_save_draft" />}
              colorTheme="gray"
              actionType="onclick"
              css={{paddingTop: 0, paddingBottom: 0}}
              onClick={() => onPublish('draft')}
              svgIcon="draft"
            />
          )}
          <Button
            label={<String id={props.publishBtnContent} />}
            type="normal"
            actionType="onclick"
            onClick={() =>
              currentStep === 'cover' ? onPublish('publish') : gotoNextSection()
            }
            colorTheme="normal"
            isDisabled={
              (isPublishBtnDisabled && currentStep === 'cover') ||
              isNextBtnDisabled
            }
          />
          {isNextBtnDisabled && nextDisabledContent && (
            <StyledSmall as="small">
              <String id={nextDisabledContent} />
            </StyledSmall>
          )}
        </CreateSidebarFooter>
      </CreateSidebarSticky>
    </CreateSidebarWrapper>
  );
};

CreateSidebar.propTypes = {
  addImageBlock: PropTypes.func.isRequired,
  onReOrderBlocks: PropTypes.func.isRequired,
  currentStep: PropTypes.oneOf(['info', 'editor', 'cover']).isRequired,
  createState: PropTypes.shape({
    blockList: PropTypes.arrayOf(PropTypes.object),
    maxBlocks: PropTypes.number,
    imageBlockList: PropTypes.arrayOf(PropTypes.object),
    currentImageCropIndex: PropTypes.number,
  }).isRequired,
  onChangeImageCropIndex: PropTypes.func.isRequired,
  gotoNextSection: PropTypes.func.isRequired,
  isPublishBtnDisabled: PropTypes.bool.isRequired,
  isNextBtnDisabled: PropTypes.bool.isRequired,
  nextDisabledContent: PropTypes.string.isRequired,
  onPublish: PropTypes.func.isRequired,
  emptyBlockIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CreateSidebar;
