import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const CreateHeaderBarWrapper = styled('div', {
  height: '$64',

  position: 'relative',
  backgroundColor: '$box',

  '&:before': {
    content: '""',
    display: 'block',
    height: '1px',
    width: '100%',
    backgroundColor: '$lightGrey',
    bottom: '0',
    left: '0',
    position: 'absolute',
  },
});

export const CreateHeaderBarContainer = styled('div', {
  maxWidth: '$container',
  margin: 'auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  ol: {
    paddingLeft: 0,
  },
  alignItems: 'center',
  height: '100%',
  padding: '0 $12',
  '@bp2': {
    padding: '0 $32',
  },
  '@media screen and (min-width: 1024px)': {
    justifyContent: 'space-between',
  },
  '@bp5': {
    px: '0',
  },
});

export const StyledButton = styled(Button, {
  height: '44px',
  textTransform: 'initial',
  justifyContent: 'center',
  fontSize: '$12',
  backgroundColor: 'transparent !important',
  fontFamily: '$ArtifaktLegendMedium',
  span: {
    transition: 'opacity .4s ease',
  },

  svg: {
    alignSelf: 'center',
    marginRight: '$8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& > svg:first-of-type': {
    marginRight: '9px',
    '@media screen and (min-width: 800px)': {
      marginRight: '$12',
    },
  },
  '&:hover': {
    span: {
      opacity: '.5',
    },
  },
  '&:first-of-type': {
    paddingRight: 0,
  },
});

export const SvgContainer = styled('div', {
  marginLeft: '$8',
});

export const WrapperActions = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const WrapperOptions = styled('div', {
  position: 'absolute',
  padding: '$8 $24',
  top: '$40',
  right: '0',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$elements',
  borderRadius: '$8',
  whiteSpace: 'nowrap',
});

export const BtnOptions = styled(Button, {
  justifyContent: 'left',
  px: '0 !important',
  py: '$8 !important',
  textTransform: 'unset',
  minHeight: 'unset !important',
});

export const ExitContainer = styled('div', {
  marginLeft: '2rem',
  '.theme-0 &': {
    button: {
      backgroundColor: '$charcoal100',

      svg: {
        fill: '$ultraLightGrey',
      },
    },
  },
});
