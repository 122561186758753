import {
  AutodeskLogo,
  DarkmodeWrapper,
  FakeRadio,
  FakeRadioBackground,
  FakeRadioCircle,
  ImageProfileWrapper,
  ImageWrapper,
  UserBarDesktop,
  UserBarMobile,
  UserBarWrapper,
  UserMenuBox,
  UserMenuIcon,
  UserMenuItem,
  UserMenuItemProfile,
  UserMenuWrapper,
} from './UserBar.styled';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import { NOTIFICATIONS_DELETE_USER_DEVICE } from '@src/apollo/mutation';
import { NotificationsContext } from '@context/NotificationsContext';
import PropTypes from 'prop-types';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import UserBarNotifications from './UserBarNotifications';
import { navigate } from 'gatsby';
import useDarkMode from '@src/utils/use-dark-mode';
import { useMutation } from '@apollo/client';
import Avatar from '@components/atoms/avatar/Avatar';
import { deleteFCMToken } from '@src/utils/firebaseInit';
import Loader from '@components/atoms/loader/Loader';
import Modal from '@components/atoms/modal/Modal';
import useModal from '@components/atoms/modal/useModal';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';

const UserBar = ({ isLogged, auth, mobileNavOpened, onBurgerClick }) => {
  const authContext = useContext(AuthContext);
  const { isShowing, toggle } = useModal();
  const UrlContext = useContext(UrlsContext);
  const menuBoxRef = useRef(null);
  const [darkMode, setDarkMode] = useDarkMode();

  const DarkModeToggle = () => {
    return (
      <div className="dark-mode-toggle">
        <Button
          type="circle"
          colorTheme="headerButtons"
          svgIcon="darkmode"
          actionType="onclick"
          onClick={() => setDarkMode(!darkMode)}
        />
      </div>
    );
  };

  async function handleLogout() {
    try {
      await auth.logout();
      navigate('/login');
      deleteFCMToken();
    } catch (e) {
      console.error(e);
    }
  }

  const { notificationsState, setNotificationsState } = useContext(
    NotificationsContext
  );

  const onToggleUserMenu = () => {
    setUserBarState({
      ...userBarState,
      isUserMenuOpened: !userBarState.isUserMenuOpened,
    });
  };

  function navigateAndCloseMenu(to) {
    onToggleUserMenu();
    navigate(to);
  }

  const handleClickOutside = event => {
    if (
      menuBoxRef &&
      menuBoxRef.current &&
      !menuBoxRef.current.contains(event.target)
    ) {
      onToggleUserMenu();
    }
  };

  const [userBarState, setUserBarState] = useState({
    isUserMenuOpened: false,
    isDarkmodeActive: darkMode.value,
  });

  const [isVerified, setIsVerified] = useState(false);

  const [
    deleteDevice,
    {
      data: dataDeleteDevice,
      errors: errorDeleteDevice,
      loading: loadingDeleteDevice,
    },
  ] = useMutation(NOTIFICATIONS_DELETE_USER_DEVICE);

  const deleteDeviceAndLogout = () => {
    if (notificationsState.firebaseToken === null) handleLogout();

    if (notificationsState.firebaseToken) {
      deleteDevice({
        variables: {
          device_key: notificationsState.firebaseToken,
          device_type: 'web',
        },
      })
        .then(function () {
          setNotificationsState({
            ...notificationsState,
            firebaseToken: null,
          });
          handleLogout();
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      handleLogout();
    }
  };

  const onClickCreate = () => {
    if (!authContext.isOnboarded || !authContext.isAuthenticated) {
      toggle();
    } else {
      navigate('/create');
    }
  };

  useEffect(() => {
    if (userBarState.isUserMenuOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // cleanup this component
    return () => {
      if (userBarState.isUserMenuOpened) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [userBarState]);

  useEffect(() => {
    if (auth.isVerified) {
      setIsVerified(true);
    }
  }, [isVerified]);

  return (
    <>
      <UserBarWrapper>
        <UserBarMobile isLogged={isLogged}>
          <DarkModeToggle />
          <Button
            type="normal"
            colorTheme="transparent"
            svgIcon={mobileNavOpened ? 'close-big' : 'menu'}
            actionType="onclick"
            onClick={onBurgerClick}
            css={{
              svg: {
                fill: '$white',
              },
            }}
          />
        </UserBarMobile>
        <UserBarDesktop>
          <Button
            type="circle"
            colorTheme="headerButtons"
            svgIcon="search"
            actionType="onclick"
            onClick={() => navigate('/search')}
          />

          {authContext.isAuthenticated && isVerified && (
            <UserBarNotifications auth={auth}></UserBarNotifications>
          )}
          {isLogged && authContext.firstName !== null && (
            <UserMenuWrapper ref={menuBoxRef}>
              <Button
                type="circle"
                colorTheme="reverse"
                actionType="onclick"
                onClick={onToggleUserMenu}
              >
                <ImageWrapper>
                  <img
                    alt={`${authContext.firstName} ${authContext.lastName}`}
                    src={`${CDN_URL}${authContext?.profilePicture}`}
                  />
                </ImageWrapper>
              </Button>
              {userBarState.isUserMenuOpened ? (
                <nav>
                  <UserMenuBox>
                    <UserMenuItemProfile
                      onClick={() =>
                        navigateAndCloseMenu(`/m/${authContext.userSlug}`)
                      }
                    >
                      <ImageProfileWrapper className="menuIcon">
                        <img
                          alt={`${authContext.firstName} ${authContext.lastName}`}
                          src={`${CDN_URL}${authContext?.profilePicture}`}
                        />
                      </ImageProfileWrapper>
                      <div>
                        <Text
                          css={{
                            fontFamily: '$ArtifaktElementBold',
                            lineHeight: '1.2em',
                          }}
                          size="14"
                        >
                          {`${authContext.firstName} ${authContext.lastName}`}
                        </Text>
                        <Text size="14">See your Profile</Text>
                      </div>
                    </UserMenuItemProfile>
                    <UserMenuItem
                      onClick={() =>
                        navigateAndCloseMenu(
                          `/m/${authContext.userSlug}/favorites`
                        )
                      }
                    >
                      <UserMenuIcon className="menuIcon">
                        <Svg icon="favorite" />
                      </UserMenuIcon>
                      <Text size="16">My favorites</Text>
                    </UserMenuItem>
                    <UserMenuItem
                      onClick={() =>
                        navigateAndCloseMenu(
                          `/m/${authContext.userSlug}/account-settings`
                        )
                      }
                    >
                      <UserMenuIcon className="menuIcon">
                        <Svg icon="settings" />
                      </UserMenuIcon>
                      <Text size="16">Account Settings</Text>
                    </UserMenuItem>
                    {/* <UserMenuItem>
                      <a href="https://help.area.autodesk.com/" target="_blank">
                        <UserMenuIcon className="menuIcon">
                          <Svg icon="help" />
                        </UserMenuIcon>
                        <Text size="16">Help Center</Text>
                      </a>
                    </UserMenuItem> */}

                    {['admin', 'editor'].includes(authContext.generalRole) && (
                      <UserMenuItem
                        onClick={() =>
                          navigateAndCloseMenu(
                            `${GetUrl('adminDashboard', UrlContext)}`
                          )
                        }
                      >
                        <UserMenuIcon className="menuIcon">
                          <Svg icon="dashboard" />
                        </UserMenuIcon>
                        <Text size="16">Admin CMS</Text>
                      </UserMenuItem>
                    )}
                    <UserMenuItem onClick={() => setDarkMode(!darkMode)}>
                      <UserMenuIcon className="menuIcon">
                        <Svg icon="darkmode-small" />
                      </UserMenuIcon>
                      <DarkmodeWrapper>
                        <Text size="16" css={{ marginRight: '$24' }}>
                          Dark mode
                        </Text>
                        <FakeRadio>
                          <FakeRadioBackground isActive={darkMode} />
                          <FakeRadioCircle isActive={darkMode} />
                        </FakeRadio>
                      </DarkmodeWrapper>
                    </UserMenuItem>
                    <UserMenuItem onClick={deleteDeviceAndLogout}>
                      <UserMenuIcon className="menuIcon">
                        <Svg icon="power" />
                      </UserMenuIcon>
                      <Text size="16">Log Out</Text>
                    </UserMenuItem>
                  </UserMenuBox>
                </nav>
              ) : null}
            </UserMenuWrapper>
          )}
          {isLogged && auth.isVerified && (
            <Button
              type="circle"
              colorTheme="white"
              svgIcon="plus"
              actionType="onclick"
              onClick={onClickCreate}
            />
          )}
          {isLogged && authContext.firstName === null && (
            <Button
              type="normal"
              label="Log Out"
              colorTheme="headerButtons"
              actionType="onclick"
              onClick={deleteDeviceAndLogout}
            />
          )}

          {!isLogged && (
            <>
              <Button
                type="normal"
                label="Sign in"
                colorTheme="headerButtons"
                actionType="internalLink"
                internalUrl="/login"
              />
              <Button
                type="normal"
                label="Create An account"
                colorTheme="white"
                actionType="internalLink"
                internalUrl="/join"
              />
              <DarkModeToggle />
            </>
          )}
          <AutodeskLogo />
        </UserBarDesktop>
        <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
          {!authContext.isVerified && (
            <PublishNotification type={'validation'} />
          )}

          {!authContext.isOnboarded && (
            <PublishNotification
              type={'onboarding'}
              url={'/m/' + authContext.userSlug}
            />
          )}
        </Modal>
        <Loader isLoading={loadingDeleteDevice} />
      </UserBarWrapper>
    </>
  );
};

UserBar.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  mobileNavOpened: PropTypes.bool.isRequired,
};

UserBar.defaultProps = {};

export default UserBar;
