import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Box from '@components/atoms/box/Box';
import Svg from '@components/atoms/svg/Svg';
import Button from '@components/atoms/button/Button';

export const CommunityPostBoxContent = styled('div', {
  position: 'relative',
  pb: '$16',
  borderBottom: '1px solid $lightGrey',

  variants: {
    hasImage: {
      true: {
        pb: '0',
        borderBottom: '1px solid transparent',
      },
    },

    isInFavorite: {
      true: {
        borderBottom: '1px solid transparent',
      },
    },
  },
});

export const LoaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '150px',
  backgroundColor: '$elements',
  borderRadius: '0 0 18px 18px',
});

export const OptionsWrapper = styled('div', {
  zIndex: 1,
  backgroundColor: '$elements',
  borderRadius: '$8',
  padding: '$8',
  position: 'absoulte',
  right: '0',
  bottom: '0',
  display: 'none',
  flexDirection: 'column',
  boxShadow: '$2',
  position: 'relative',

  variants: {
    isVisible: {
      true: {
        display: 'flex',
      },
    },
  },
});

export const CommunityPostBoxText = styled('span', {
  display: 'inline',
  p: {
    display: 'inline',
  },
  a: {
    //color: '$purple',
    textDecoration: 'none',
    fontFamily: '$ArtifaktElementBold',
    textDecorationThickness: '1px',
    textUnderlineOffset: '2px',
    wordBreak: 'break-word',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export const PostTextWrapper = styled('div', {
  mx: '$16',
});

export const PostTextContainer = styled('div', {});

export const CommunityPostBoxHeader = styled('div', {
  position: 'relative',
  px: '$16',
  pt: '$16',
  pb: '$8',
});

export const OptionsBtns = styled(Button, {
  display: 'flex',
  justifyContent: 'left',
  px: '$8 !important',
  py: '$8 !important',
  textTransform: 'unset',
  minHeight: 'unset !important',

  '&:hover': {
    backgroundColor: 'transparent !important',
    opacity: '0.7',
  },

  variants: {
    isDelete: {
      true: {
        color: '$red',

        svg: {
          fill: '$red',
        },
      },
    },

    isFavorite: {
      true: {
        display: 'none',

        '@bp2': {
          display: 'flex',
        },
      },
    },

    isInFavorites: {
      true: {
        display: 'flex',

        '@bp2': {
          display: 'flex',
        },
      },
    },
  },
});

export const ReportBtns = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  // paddingTop: '$12',
  cursor: 'pointer',
  px: '$8',
  py: '$8',
  borderRadius: '$8',

  ':last-of-type': {
    marginBottom: '0',
  },

  svg: {
    width: '$24',
    height: '$24',
    marginRight: '$4',
    fill: '$mainTextColor',
  },

  span: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  '&:hover': {
    backgroundColor: 'transparent !important',
    opacity: '0.7',
  },
});

export const StyledReportText = styled(Text, {
  fontFamily: '$ArtifaktElementBold',
  color: '$mainTextColor',
  marginBottom: '$4',
});

export const CommunityPostActionsBtn = styled('div', {
  position: 'absolute',
  top: '$12',
  right: '$12',
});

export const CommunityPostActionsBtnWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
});

export const CommunityPostReactions = styled('div', {
  borderTop: '1px solid $lightGrey',
});

export const CommunityPostReactionsWrapper = styled('div', {
  px: '$16',

  variants: {
    isPending: {
      true: {
        borderTop: '1px solid $lightGrey',
        py: '$8',
      },
    },
  },
});

export const PendingBtnWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledDate = styled(Text, {
  color: '$darkGrey',
  display: 'none',

  '@bp0': {
    display: 'block',
  },
});

export const ImagesWrapper = styled('div', {
  paddingTop: '$64',
});

export const ModalContentWrapper = styled('div', {
  variants: {
    inModal: {
      true: {
        px: '$40',
        pb: '$4',
      },
    },
  },
});

export const StyledBox = styled(Box, {
  margin: '$0',
  width: '100%',

  '@bp2': {
    //maxWidth: '640px',
  },

  variants: {
    inModal: {
      true: {
        '@bp2': {
          //maxWidth: '864px',
        },
      },
    },
  },
});

export const DeleteBtnsContainer = styled('div', {
  display: 'flex',
  marginTop: '$24',
});

export const StyledButton = styled('button', {
  backgroundColor: 'transparent',
  border: '0',
  cursor: 'pointer',
  textDecoration: 'none',
  fontFamily: '$ArtifaktElementBold',
  color: '$blackToWhite',
  textTransform: 'uppercase',
  marginLeft: '$4',
  padding: '0 !important',
  minHeight: 'auto !important',
  display: 'inline',

  '& .btn-label': {
    position: 'relative',
    display: 'block',

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-2px',
      left: '0',
      width: '100%',
      height: '1px',
      backgroundColor: '$blackToWhite',
      opacity: '0',
    },
  },

  '&:hover': {
    textDecoration: 'underline',
  },
});

export const ModalTitle = styled(Text, {
  color: '$mainTextColor',
  fontFamily: '$ArtifaktElementBold',
  marginBottom: '$24',
});

export const ModalSubTitle = styled(Text, {
  color: '$mainTextColor',
  fontFamily: '$ArtifaktElementBold',
  marginBottom: '$32',
});
