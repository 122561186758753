import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';

export const BlockImageWrapper = styled('div', {
  textAlign: 'center',

  '.medium-editor-element': {
    fontSize: '12px',
  },
  '.ql-editor': {
    textAlign: 'center',
  },
  paddingBottom: '$8',

  '.medium-editor-placeholder': {
    width: '100%',
    textAlign: 'center',
    fontSize: '12px',

    '&:after': {
      transform: 'translateX(-50%)',
      left: '50%',
      pointerEvents: 'none',
    },
  },
});

export const ImageWrapper = styled('div', {
  width: '100%',
  mb: '$8',

  '@bp2': {
    mb: '$24',
  },
  img: {
    position: 'relative',
    maxWidth: "100%",
  },
});
