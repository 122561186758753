import {
  ErrorMsg,
  FormStyled,
  InputWrapper,
  SubmitBtnWrapper,
} from './UserLogInForm.styled';
import React, { useContext, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { InputText } from '@components/atoms/input/inputText/InputText';
import String from '@components/atoms/string/String';

const UserLogInForm = props => {
  const authContext = useContext(AuthContext);
  const [logInFormState, setLogInFormState] = useState({
    email: '',
    password: '',
    isLoading: false,
    isAuthError: false,
    authErrorMessage: '',
    emailErrorMsg: null,
    passwordErrorMsg: null,
    isEmailError: false,
    isPasswordError: false,
  });

  const handleEmailChange = e => {
    setLogInFormState({
      ...logInFormState,
      email: e.currentTarget.value,
    });
  };

  const handlePasswordChange = e => {
    setLogInFormState({
      ...logInFormState,
      password: e.currentTarget.value,
    });
  };

  const handleValidation = () => {
    const isEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      logInFormState.email
    );
    const isPasswordValid = logInFormState.password != '' ? true : false;

    if (!isEmailValid || !isPasswordValid) {
      setLogInFormState({
        ...logInFormState,
        emailErrorMsg: !isEmailValid ? (
          <String id="sign_in_error_valid_email" />
        ) : null,
        isEmailError: !isEmailValid ? true : false,
        passwordErrorMsg: !isPasswordValid ? (
          <String id="sign_in_error_valid_password" />
        ) : null,
        isPasswordError: !isPasswordValid ? true : false,
      });
    } else {
      setLogInFormState({
        ...logInFormState,
        emailErrorMsg: null,
        passwordErrorMsg: null,
        isPasswordError: false,
        isEmailError: false,
        isLoading: true,
      });
    }

    return isPasswordValid && isEmailValid ? true : false;
  };

  const onSendVerificationEmailSuccess = () => {
    setLogInFormState({
      ...logInFormState,
      isLoading: false,
      isAuthError: false,
      authErrorMessage: null,
    });
    props.onResendEmailVerification(logInFormState.email);
  };

  const onSendVerificationEmailError = () => {
    console.log('error');
  };

  const onClickResendEmail = () => {
    const data = {
      email: logInFormState.email,
      successCallback: onSendVerificationEmailSuccess,
      errorCallback: onSendVerificationEmailError,
    };

    setLogInFormState({
      ...logInFormState,
      isLoading: true,
      isAuthError: false,
      authErrorMessage: null,
    });

    authContext.sendVerificationEmail(data);
  };

  const handleAuthError = response => {
    switch (response.error) {
      case 'invalid_credentials':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="sign_in_error_invalid_credentials" />,
          emailErrorMsg: null,
          isEmailError: true,
          passwordErrorMsg: null,
          isPasswordError: true,
        });
        break;
      case 'the_email_field_is_required':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: false,
          authErrorMessage: null,
          emailErrorMsg: <String id="sign_in_error_email_required" />,
          isEmailError: true,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;
      case 'the_email_must_be_a_valid_email_address':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: false,
          authErrorMessage: null,
          emailErrorMsg: <String id="sign_in_error_valid_email" />,
          isEmailError: true,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;
      case 'the_password_field_is_required':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: false,
          authErrorMessage: null,
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: <String id="sign_in_error_valid_password" />,
          isPasswordError: true,
        });
        break;
      case 'the_password_must_be_a_string':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: false,
          authErrorMessage: null,
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: <String id="sign_in_error_valid_password" />,
          isPasswordError: true,
        });
        break;
      case 'user_lockout':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="sign_in_error_user_lockout" />,
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;
      case 'could_not_create_token':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: (
            <String id="sign_in_error_could_not_create_token" />
          ),
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;
      case 'account_deleted':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="sign_in_error_account_deleted" />,
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;
      case 'email_not_verified':
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: (
            <>
              <String id="sign_in_error_email_not_verified" />
              &nbsp;
              <a
                onClick={onClickResendEmail}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                <String id="sign_in_error_email_not_verified_resend" />
              </a>
            </>
          ),
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;

      default:
        setLogInFormState({
          ...logInFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="sign_in_login_global_error" />,
          emailErrorMsg: null,
          isEmailError: false,
          passwordErrorMsg: null,
          isPasswordError: false,
        });
        break;
    }
  };

  const handleAuthSuccess = (message, data) => {
    setLogInFormState({
      ...logInFormState,
      isLoading: true,
      isAuthError: false,
      authErrorMessage: null,
    });

    // navigate(GetUrl('signupValidation', UrlContext));
  };

  const handleFormSubmit = (e, authLoginFn) => {
    e.preventDefault();

    setLogInFormState({
      ...logInFormState,
      isAuthError: false,
      isLoading: true,
    });

    if (handleValidation()) {
      authLoginFn({
        email: logInFormState.email,
        password: logInFormState.password,
        errorCallback: handleAuthError,
        successCallback: handleAuthSuccess,
      });
    }
  };

  return (
    <AuthContext.Consumer>
      {auth => (
        <FormStyled onSubmit={e => handleFormSubmit(e, auth.login)}>
          <InputWrapper>
            <InputText
              autoComplete
              autoFocus
              onChange={handleEmailChange}
              type="text"
              inputShape="normal"
              name="email"
              value={logInFormState.email}
              label={<String id="sign_in_login_email_label" />}
              css={{ marginBottom: '$12' }}
              error={logInFormState.emailErrorMsg}
              isError={logInFormState.isEmailError}
              inputTheme="transparent"
            />
            <InputText
              autoComplete
              onChange={handlePasswordChange}
              type="password"
              name="password"
              css={{ marginBottom: '$24' }}
              value={logInFormState.password}
              label={<String id="sign_in_login_password_label" />}
              inputShape="normal"
              forgotPasswordLabel={
                <String id="sign_in_login_forgot_password_label" />
              }
              forgotPasswordUrl="/forgot-password"
              error={logInFormState.passwordErrorMsg}
              isError={logInFormState.isPasswordError}
              displayErrorIcon={false}
              inputTheme="transparent"
            />
          </InputWrapper>
          {logInFormState.isAuthError ? (
            <ErrorMsg>{logInFormState.authErrorMessage}</ErrorMsg>
          ) : null}

          <SubmitBtnWrapper>
            <Button
              type="submit"
              value="Submit"
              colorTheme="normal"
              notificationBubbleActive={false}
              actionType="onclick"
              isLoading={logInFormState.isLoading}
              css={{
                width: '100%',
                padding: '$12',
                br: 8,
              }}
            >
              <String id="sign_in_login_button_label" />
            </Button>
          </SubmitBtnWrapper>
        </FormStyled>
      )}
    </AuthContext.Consumer>
  );
};

export default UserLogInForm;
