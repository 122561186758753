import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import React from 'react';
import SelectTools from '@components/molecules/selectTools/SelectTools';
import { SelectToolsStepWrapper } from './SelectToolsStep.styled';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const SelectToolsStep = props => {
  window.scrollTo(0, 0);
  const isBtnDisabled = props.toolsList.findIndex(tool => {
    return tool.isSelected;
  });

  return (
    <SelectToolsStepWrapper css={props.css}>
      <Text
        as="h2"
        css={{
          marginBottom: '$24',
          fontSize: '$16',
          lineHeight: '1em',
          '@bp2': {
            fontSize: '$16',
            marginBottom: '$24',
          },
        }}
      >
        {<String id="onboarding_tools_title" />}
      </Text>

      <SelectTools
        toolsList={props.toolsList}
        onToolsSelect={props.onToolsSelect}
      ></SelectTools>
      <div>
        <Button
          actionType="onclick"
          isDisabled={isBtnDisabled == -1}
          toolsList={props.toolsList}
          onClick={props.onClickNext}
          colorTheme="normal"
          label={<String id="save_and_continue_btn" />}
          type="normal"
          isLoading={props.isSaving}
          css={{
            textTransform: 'uppercase',
            marginTop: '$40',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            '@bp2': {
              width: 'auto',
              display: 'flex',
              marginLeft: '0',
              marginRight: '0',
            },
          }}
        />
      </div>
    </SelectToolsStepWrapper>
  );
};

export default SelectToolsStep;
