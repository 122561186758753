import {
  LinksSection,
  PageWrapper,
  PaginationWrapper,
  StyledHeading,
  ThumbnailWrapper,
} from './InspireGallery.styled';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';

import { useLazyQuery, useQuery } from '@apollo/client';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import Pagination from '@components/atoms/pagination/Pagination';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import LinkThumbnailList from '@components/molecules/linkThumbnail/linkThumbnailList/LinkThumbnailList';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import NoResult from '@components/molecules/noResult/NoResult';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import FilterBar from '@components/organisms/filterBar/FilterBar';
import Layout from '@components/organisms/layout/Layout';
import { GET_ARTWORKS, GET_INDUSTRIES_AND_TOOLS } from '@src/apollo/query';
import { GET_PAGE_DATA_GALLERY } from '@src/apollo/queryPages';
import AuthContext from '@src/context/AuthContext';
import { dataToLinkThumbnailViewModel } from '@src/utils/dataToLinkThumbnailViewModel';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { useIsMobile } from '@src/utils/useIsMobile';
import { sortArrayByTitle } from '@src/utils/sortArray';
import React, { useContext, useEffect, useState } from 'react';

const InspireGallery = context => {
  const [filtersState, setFiltersState] = useState({
    isActive: [
      {
        filterKey: 'recent',
        title: 'listing_page_recent_filter',
        type: 'single',
      },
      {
        id: 'tools',
        selectedValues: [],
        type: 'dropdown',
      },
      {
        id: 'industries',
        selectedValues: [],
        type: 'dropdown',
      },
    ],
    isDataSet: false,
    filters: [
      {
        type: 'single',
        title: 'listing_page_recent_filter',
        filterKey: 'recent',
      },
      {
        type: 'single',
        title: 'listing_page_popular_filter',
        filterKey: 'popular',
      },
      {
        type: 'single',
        title: 'listing_page_editors_pick_filter',
        filterKey: 'editors_pick',
      },
    ],
    dropdownFilters: [
      {
        type: 'dropdown',
        title: 'listing_page_tools_filter',
        filterKey: 'tools',
        options: [],
      },
      {
        type: 'dropdown',
        title: 'listing_pages_industries_filter',
        filterKey: 'industries',
        options: [],
      },
    ],
  });

  const [urlParams, setUrlParams] = useState({});
  const [excludedIds, setExcludedIds] = useState([]);
  const authContext = useContext(AuthContext);
  const isMobile = useIsMobile();

  const {
    loading: loadingFiltersInfos,
    error: errorFiltersInfos,
    data: dataFiltersInfos,
  } = useQuery(GET_INDUSTRIES_AND_TOOLS, {
    context: { clientName: 'public' },
  });

  const { data: pageData, loading: pageDataLoading } = useQuery(
    GET_PAGE_DATA_GALLERY,
    {
      variables: {
        slug: 'inspire/gallery',
      },
      context: { clientName: 'public' },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [
    getArtworks,
    { loading: loadingArtworks, error: errorArtworks, data: dataArtworks },
  ] = useLazyQuery(GET_ARTWORKS, {
    variables: {
      limit: isMobile ? 8 : 16,
      excludedIds,
    },
    context: { clientName: 'public' },
    skip: !pageData?.page,
  });

  useEffect(() => {
    if (pageData) {
      console.log(pageData);
    }

    if (pageData?.page) {
      setExcludedIds(
        [pageData?.page?.userContent_feature?.id].filter(el => el !== undefined)
      );
    }
  }, [pageData]);

  const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  };

  const setDataFilters = data => {
    const { industries } = data;
    const { tools } = data;

    setFiltersState({
      ...filtersState,
      isDataSet: true,
      dropdownFilters: [
        {
          type: 'dropdown',
          title: 'listing_page_tools_filter',
          filterKey: 'tools',
          options: tools.slice().sort(sortArrayByTitle),
        },
        {
          type: 'dropdown',
          title: 'listing_pages_industries_filter',
          filterKey: 'industries',
          options: industries,
        },
      ],
    });
  };

  function dropdownOptionsToVM(options) {
    return options.map(option =>
      option.__typename === 'Tool'
        ? { id: option.id, name: option.title }
        : { id: option.id, name: option.name }
    );
  }

  const filtersToVM = filters =>
    filters.map(filter => {
      if (filter.type === 'dropdown') {
        return {
          ...filter,
          options: dropdownOptionsToVM(filter.options),
        };
      }
      return filter;
    });

  useEffect(() => {
    if (dataFiltersInfos && !loadingFiltersInfos && !filtersState.isDataSet) {
      setDataFilters(dataFiltersInfos);
    }
  }, [loadingFiltersInfos, filtersState]);

  const page = getQueryVariable('page') || 1;
  const order = getQueryVariable('order');
  const tools = getQueryVariable('tools');
  const industries = getQueryVariable('industries');

  useEffect(() => {
    setUrlParams({
      ...urlParams,
      ...(page && { page }),
      ...(order !== undefined && order !== '' && { order }),
      ...(tools !== undefined &&
        tools !== '' && {
          tools: tools.split(',').map(el => parseInt(el, 10)),
        }),
      ...(industries !== undefined &&
        industries !== '' && {
          industries: industries.split(',').map(el => parseInt(el, 10)),
        }),
    });
  }, [page, order, tools, industries]);

  useEffect(() => {
    const newParams = { ...urlParams };
    if (newParams?.tools?.length === 0) {
      delete newParams.tools;
    }
    if (newParams?.industries?.length === 0) {
      delete newParams.industries;
    }

    const url = Object.keys(newParams)
      .map((key, i) => `${i === 0 ? '?' : '&'}${key}=${newParams[key]}`)
      .join('');
    window.history.replaceState({}, '', url);

    if (newParams.order === 'editors_pick') {
      delete newParams.order;
      newParams.editors_pick = true;
    }

    getArtworks({ variables: newParams });

    if (tools && filtersState?.isActive) {
      const toolsParam = tools.split(',').map(el => parseInt(el, 10));
      const activeTools = filtersState.isActive.find(el => el?.id === 'tools');
      activeTools.selectedValues = [
        ...new Set([...toolsParam, ...activeTools.selectedValues]),
      ];
    }
    if (industries && filtersState?.isActive) {
      const industriesParam = industries.split(',').map(el => parseInt(el, 10));
      const activeIndustries = filtersState.isActive.find(
        el => el?.id === 'industries'
      );
      activeIndustries.selectedValues = [
        ...new Set([...industriesParam, ...activeIndustries.selectedValues]),
      ];
    }
  }, [urlParams, filtersState, tools, industries]);

  useEffect(() => {
    const activeOrderFilter = filtersState.filters.find(
      el => el.filterKey === order
    );
    if (
      order !== undefined &&
      order !== '' &&
      activeOrderFilter?.filterKey !== order
    ) {
      setFiltersState({
        ...filtersState,
        isActive: [
          ...filtersState.isActive.filter(el => el?.type !== 'single'),
          activeOrderFilter,
        ],
      });
    }
  }, [order]);

  const changePage = num => {
    setUrlParams({
      ...urlParams,
      page: num,
    });
  };

  useEffect(() => {
    if (
      parseInt(page, 10) > 1 &&
      dataArtworks?.userContents?.data.length === 0
    ) {
      changePage(1);
    }
  }, [page, dataArtworks]);

  const hasFiltersActive =
    filtersState.isActive
      .filter(el => el?.type === 'dropdown')
      .reduce((acc, i) => acc.concat(i.selectedValues), []).length > 0 ||
    !filtersState.isActive
      .filter(el => el?.type === 'single')
      .map(el => el.filterKey)
      .includes('recent');

  return (
    <Layout location={context.location} showBreadcrumb>
      <SEO
        title="Gallery"
        slug="inspire-gallery"
        seomatic={context?.pageContext?.entry?.seomatic}
      />
      <LandingPageWrapper>
        <SectionWrapper>
          {pageData?.page ? (
            <>
              <ListingHeader
                title={pageData.page.title}
                intro={pageData.page.introduction}
                isCMSDynamic
              />
              <FilterBar
                filters={filtersToVM([
                  ...filtersState.filters,
                  ...(filtersState.dropdownFilters
                    ? filtersState.dropdownFilters
                    : []),
                ])}
                onChange={e => {
                  const selectedFilters = JSON.parse(e);
                  setUrlParams({
                    ...urlParams,
                    tools: selectedFilters.find(el => el?.id === 'tools')
                      ?.selectedValues,
                    industries: selectedFilters.find(
                      el => el?.id === 'industries'
                    )?.selectedValues,
                    order: selectedFilters.find(el => el?.type === 'single')
                      ?.filterKey,
                  });
                  setFiltersState({ ...filtersState, isActive: JSON.parse(e) });
                }}
                defaultFilters={filtersState.isActive}
              />
            </>
          ) : (
            <LoaderSkeleton height="300px" width="100%" />
          )}
        </SectionWrapper>
        <SectionWrapper>
          <ThumbnailWrapper>
            {parseInt(urlParams.page, 10) === 1 && !hasFiltersActive && (
              <>
                {pageData?.page?.userContent_feature && !pageDataLoading && (
                  <Thumbnail
                    {...dataToThumbnailViewModel(
                      pageData?.page?.userContent_feature,
                      false,
                      authContext.isAuthenticated
                    )}
                    className="thumbnail-featured"
                    isFeatured={!isMobile}
                  />
                )}
                {pageDataLoading && (
                  <LoaderSkeleton
                    height="870px"
                    width="100%"
                    isFeatured={!isMobile}
                    isRadius
                  />
                )}
              </>
            )}
            {loadingArtworks ? (
              <>
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
              </>
            ) : dataArtworks?.userContents.data.length === 0 ? (
              <NoResult />
            ) : (
              dataArtworks?.userContents.data.map((item, i) => {
                return (
                  <Thumbnail
                    key={item.id}
                    {...dataToThumbnailViewModel(
                      item,
                      false,
                      authContext.isAuthenticated
                    )}
                  />
                );
              })
            )}
          </ThumbnailWrapper>

          <PaginationWrapper>
            <Pagination
              isUrlLink={false}
              activePage={parseInt(page, 10)}
              onCurrentPageChange={e => changePage(e)}
              pages={Math.ceil(
                dataArtworks?.userContents.total /
                  dataArtworks?.userContents.per_page
              )}
            />
          </PaginationWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <LinksSection>
            {pageDataLoading && (
              <LoaderSkeleton height="420px" width="100%" isRadius />
            )}
            {!pageDataLoading && pageData && (
              <>
                <StyledHeading level="h3">
                  <String id="page_inspire_links_section_title" />
                </StyledHeading>
                <LinkThumbnailList
                  listData={[
                    dataToLinkThumbnailViewModel(pageData.page.learn_more_1),
                    dataToLinkThumbnailViewModel(pageData.page.learn_more_2),
                  ]}
                />
              </>
            )}
          </LinksSection>
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};

export default InspireGallery;
