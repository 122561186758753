import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const SuggestedGroupSliderWrapper = styled('div', {
  backgroundColor: '$box',
  borderRadius: '$24',
  boxShadow: '$2',
  overflow: 'hidden',

  '& .swiper-slide': {
    opacity: '.5',
    transition: 'opacity 0.2s ease-out',

    div: {
      marginBottom: '0',

      '&:first-of-type': {
        width: '100%',
      },
    },
  },

  '& .swiper-slide.swiper-slide-visible': {
    opacity: '1',
  },
});

export const SliderHeader = styled('div', {
  backgroundColor: '$blackToWhite',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$20 $20 $20 $16',
  color: '$whiteToBlack',

  '@bp1': {
    padding: '$8 $20 $8 $16',
  },

  a: {
    color: '$whiteToBlack',
    textDecoration: 'none',
    fontFamily: '$ArtifaktLegendBold',
    fontSize: '$14',
    opacity: '1',
    transition: 'opacity .2s ease-out',

    '&:hover': {
      opacity: '.5',
    },
  },
});

export const SwiperContainer = styled('div', {
  position: 'relative',
  margin: '$24 $54 $24 $24',

  '& .swiper-container': {
    overflow: 'visible',
  },

  button: {
    padding: 'unset',
  },
});

export const PrevBtns = styled('div', {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: '$1',
  left: '-$8',
  opacity: '1',
  pointerEvents: 'all',
  transition: 'opacuty 0.2s ease-out',
  display: 'none',

  '&.is-invisible': {
    opacity: '0',
    pointerEvents: 'none',
  },

  '@bp1': {
    display: 'flex',
  },
});

export const NextBtns = styled('div', {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: '$1',
  right: '-$48',
  opacity: '1',
  pointerEvents: 'all',
  transition: 'opacuty 0.2s ease-out',
  display: 'none',

  '&.is-invisible': {
    opacity: '0',
    pointerEvents: 'none',
  },

  '@bp1': {
    display: 'flex',
  },
});

export const StyledText = styled(Text, {
  display: 'none',

  '@bp1': {
    display: 'block',
  },
});

export const StyledTitle = styled(Text, {
  fontFamily: '$ArtifaktElementBold',
});
