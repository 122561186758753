/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { ApolloWithErrorHandlerProvider } from './src/apollo/client';
import { AuthProvider } from '@context/AuthContext';
import { ClientNotificationContextProvider } from '@context/ClientNotificationContext';
import NotificationsProvider from '@context/NotificationsContext';
import React from 'react';
import { SearchProdiver } from '@context/SearchContext';
import { SeoDataProvider } from '@context/SeoContext';
import { StringsProvider } from '@context/StringsContext';
import { UrlsProvider } from '@context/UrlsContext';

import './src/styles/utils/reset.css';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <NotificationsProvider>
        <StringsProvider>
          <SeoDataProvider>
            <SearchProdiver>
              <ClientNotificationContextProvider>
                <ApolloWithErrorHandlerProvider>
                  <UrlsProvider>{element}</UrlsProvider>
                </ApolloWithErrorHandlerProvider>
              </ClientNotificationContextProvider>
            </SearchProdiver>
          </SeoDataProvider>
        </StringsProvider>
      </NotificationsProvider>
    </AuthProvider>
  );
};
