import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';
import { Text } from '@components/atoms/text/Text';

export const ArtworkByContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$32',
});

export const StyledText = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  color: '$mainTextColor',
  lineHeight: '$2',
  marginBottom: '$20',
  borderBottom: '1px solid $lightGrey',
  paddingBottom: '1rem',
});

export const StyledTextVariation = styled(Text, {
  fontFamily: '$ArtifaktElementRegular',
  lineHeight: '$2',
  color: '$mainTextColor',
});

export const StyledBtn = styled(Button, {
  marginRight: '$16',
  marginTop: '$8',
});

export const StyledButton = styled(Button, {
  position: 'absolute',
  top: '$8',
  right: '$8',
  width: '32px !important',
  height: '32px !important',
  minWidth: 'unset !important',
  minHeight: 'unset !important',

  svg: {
    fill: '$white',
    position: 'relative',
    top: '1px',
  },
});

export const ButtonContainer = styled('div', {
  marginTop: '$40',
  display: 'flex',
  alignItems: 'center',

  '& .loader': {
    width: '60px',
    height: '60px',
  },
});

export const BlockTextWrapper = styled('div', {
  textAlign: 'left',
  position: 'relative',

  '& .loader': {
    position: 'absolute',
    bottom: '$48',
    right: '0',
    width: '50px',
    height: '50px',
  },

  '.ql-editor': {
    px: '0px',
    fontSize: '$14',
  },

  '.ql-editor.ql-blank::before': {
    left: '0px',
    right: '0px',
    fontStyle: 'normal',
    color: '$mediumGrey',
    fontSize: '$16',
  },

  '.ql-bubble .ql-editor a': {
    textDecoration: 'none',
  },

  '.ql-container.ql-bubble:not(.ql-disabled) a:hover::before, .ql-container.ql-bubble:not(.ql-disabled) a:hover::after': {
    visibility: 'hidden',
  },

  '& .content-link': {
    fontFamily: '$ArtifaktElementBold',
    color: '$mainTextColor',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '&  .ql-tooltip': {
    display: 'none',
  },

  '& > div:nth-of-type(1)': {
    minHeight: '$54',
    marginTop: '-$12',
    padding: '$8 $8',
    width: 'calc(100% + 1rem)',
    marginLeft: '-$8',
    marginBottom: '$16',

    '&:focus-visible': {
      outline: 'none',
    },

    '&:after': {
      fontSize: '$14',
      fontFamily: '$ArtifaktElementRegular',
      fontStyle: 'normal',
      top: '$8',
      left: '$8',
      pointerEvents: 'none',
    },
  },

  // button: {
  //   position: 'absolute',
  //   bottom: '-$64',
  // },
});

export const ImagesContainer = styled('div', {
  marginBottom: '$8',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  div: {
    '&:nth-of-type(n + 3)': {
      marginTop: '$24',
    },
  },
});

export const SingleImageContainer = styled('div', {
  position: 'relative',
  width: '100%',

  img: {
    width: '100%',
    height: 'auto',
  },

  variants: {
    isMultiple: {
      true: {
        width: '48%',
        height: 'auto',

        '&:before': {
          content: '""',
          display: 'block',
          paddingBottom: '100%',
        },

        img: {
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      },
    },
  },
});

export const CommunityAddPostModalWrapper = styled('div', {
  maxHeight: 'calc(100vh - 100px)',
  paddingTop: '$24',
  paddingBottom: '$24',
  overflowY: 'hidden',
  overflowX: 'hidden',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '@bp2': {
    paddingTop: '0',
    paddingBottom: '0',
    maxHeight: 'unset',
  },
});

export const ErrorWrapper = styled('span', {
  position: 'absolute',
  fontSize: '$12',
  color: '$red',
  fontFamily: '$ArtifaktElementBold',
  right: '0',
  bottom: '$28',

  '@bp2': {
    bottom: '$32',
  },
});
