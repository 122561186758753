import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';

export const AvatarBoxWrapper = styled('a', {
  display: 'flex',
  position: 'relative',
  textDecoration: "none",
  maxWidth: '250px',
});

export const Wrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between'
});

export const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '$16',
  position: 'relative',
  variants: {
    hasNoMargin: {
      true: {
        marginLeft: '0',
      },
    },
  },
});

export const StyledName = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  color: '$mainTextColor',
  lineHeight: '$2',
});

export const StyledJob = styled(Text, {
  fontFamily: '$ArtifaktElementRegular',
  color: '$mainTextColor',
  lineHeight: '$2',
});

export const StyledButton = styled(Button, {
  width: 32,
  height: 32,
  minHeight: 'unset',
  minWidth: 'unset',
  marginLeft: '$32',

  svg: {
    width: '$16',
    height: '$16',
  },
});

export const DotWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const Dot = styled('span', {
  display: 'block',
  width: '$12',
  height: '$12',
  borderRadius: '50%',
  backgroundColor: 'rgba(0,0,0,.3)',
  marginRight: '$8',

  variants: {
    color: {
      blue: {
        backgroundColor: '$blue',
      },

      yellow: {
        backgroundColor: '$yellow',
      },

      green: {
        backgroundColor: '$green',
      },

      red: {
        backgroundColor: '$red',
      },

      gray: {
        backgroundColor: '$lightGrey',
      },
    },
  },
});
