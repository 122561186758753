import { styled } from '@root/stitches.config';

export const SocialListWrapper = styled('div', {
  display: 'grid',
  // justifyContent: 'space-around',
  gridTemplateColumns: 'repeat(auto-fit, minmax(44px, 1fr))',
  gridGap: '$8',
  width: 'auto',
  gridAutoFlow: 'column',
});
export const SocialListItemWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  'a, button': {
    svg: {
      width: '24px',
      height: '24px',
    },
  },
});

export const SocialListItemAlt = styled('span', {
  color: '$white',
  fontSize: '$12',
});
