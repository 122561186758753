import React, { useState } from 'react';

import { AccountSettingsContentWrapper } from './AccountSettings.styled';
import AccountSettingsSection from './sections/AccountSettingsSection';
import NotificationsSettings from './sections/NotificationsSettings';
import PrivacySettings from './sections/PrivacySettings';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { TabNav } from '@components/molecules/tabNav/TabNav';

function AccountSettingsContent(props) {
  const getCurrentSection = () => {
    switch (props.context.pageContext.pageSection) {
      case 'account-settings':
        return 'account-settings';
      case 'notifications':
        return 'notifications';
      case 'privacy':
        return 'privacy';
      default:
        return 'account-settings';
    }
  };

  const [profileContentState, setProfileContentState] = useState({
    navItems: [
      {
        label: <String id="account_settings_tab_account_settings" />,
        alt: 'Account Settings',
        sectionName: 'account-settings',
        url: `/m/${props.context.slug}/accountSettings`,
      },
      {
        label: <String id="account_settings_tab_notifications" />,
        alt: 'Notifications',
        sectionName: 'notifications',
        url: `/m/${props.context.slug}/accountSettings/notifications`,
      },
      {
        label: <String id="account_settings_tab_privacy" />,
        alt: 'Privacy',
        sectionName: 'privacy',
        url: `/m/${props.context.slug}/accountSettings/privacy`,
      },
    ],
    currentSection: getCurrentSection(),
  });

  const changeCurrentSection = section => {
    setProfileContentState({
      ...profileContentState,
      currentSection: section,
    });
  };

  const settingsSections = {
    'account-settings': <AccountSettingsSection {...props} />,
    notifications: <NotificationsSettings />,
    privacy: <PrivacySettings />,
  };

  return (
    <AccountSettingsContentWrapper>
      {/* <TabNav 
        changeCurrentSection={changeCurrentSection}
        currentSection={profileContentState.currentSection}
        tabsItems={profileContentState.navItems}
      /> */}
      <div>{settingsSections[profileContentState.currentSection]}</div>
    </AccountSettingsContentWrapper>
  );
}

export default AccountSettingsContent;
