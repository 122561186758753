import {
  DropdownAction,
  DropdownActionsContainer,
  DropdownActionsLabel,
  DropdownActionsWrapper,
} from './DropdownActions.styled';
import React, { useState } from 'react';

import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import Modal from '@components/atoms/modal/Modal';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import useModal from '@components/atoms/modal/useModal';

const DropdownActions = props => {
  const { isShowing, toggle } = useModal();
  const [dropdownActions, setDropdownActions] = useState({
    isOpened: false,
    action: null,
  });

  const onToggleDropdown = () => {
    setDropdownActions({
      isOpened: !dropdownActions.isOpened,
    });
  };

  const setActionAndToggle = action => {
    setDropdownActions({
      isOpened: !dropdownActions.isOpened,
      action: action,
    });
  };

  const onActionClick = action => {
    setActionAndToggle(action);
    toggle();
  };

  const onConfirmAction = () => {
    const ids = props.selectedRows.map(row => {
      return row.values;
    });
    dropdownActions.action(ids);
    setDropdownActions({
      action: null,
    });
    toggle();
  };

  return (
    <DropdownActionsWrapper isOpened={dropdownActions.isOpened}>
      <DropdownActionsLabel onClick={onToggleDropdown}>
        <Svg icon="arrow-down-short"></Svg>
        <span>
          <String id="community_admin_bulk_operation" />
        </span>
      </DropdownActionsLabel>
      {dropdownActions.isOpened ? (
        <DropdownActionsContainer>
          {props.bulkActions?.map((action, index) => {
            return (
              <DropdownAction
                key={action.label}
                onClick={onActionClick.bind(null, action.onClickAction)}
              >
                {action.label}
              </DropdownAction>
            );
          })}
        </DropdownActionsContainer>
      ) : null}
      <Modal isShowing={isShowing} hide={toggle}>
        <Heading level="h3" css={{ marginBottom: '$32' }}>
          <String id="table_bulk_operation_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={onConfirmAction}
          label={<String id="table_bulk_operation_confirm" />}
          type="normal"
          css={{ marginRight: '$32' }}
        />
        <Button
          actionType="onclick"
          colorTheme="gray"
          onClick={toggle}
          label={<String id="table_bulk_operation_cancel" />}
          type="normal"
        />
      </Modal>
    </DropdownActionsWrapper>
  );
};

export default DropdownActions;
