import { ModalDescription, ModalEditorWrapper } from './resumeEdit.styled';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@components/atoms/button/Button';
import Editor from 'react-medium-editor';
import { Heading } from '@components/atoms/heading/Heading';
import Loader from '@components/atoms/loader/Loader';
import ProfileCompletionContext from '@context/ProfileCompletionContext';
import String from '@components/atoms/string/String';
import ReactQuillInput from '@components/atoms/reactQuillInput/ReactQuillInput';
import CharsCount from '@components/atoms/charsCount/CharsCount';
import { UPDATE_ABOUT_ME } from '@src/apollo/mutation';
import { Text } from '@components/atoms/text/Text';
import AuthContext from '@context/AuthContext';

const AboutMedEdit = props => {
  const authContext = useContext(AuthContext);
  const [aboutMeState, setAboutMeState] = useState(
    props.data ? props.data : ''
  );
  const [charCount, setCharCount] = useState();

  const emptyPattern = '<p></p>';

  const [updateAboutMe, { loading, error, data }] = useMutation(
    UPDATE_ABOUT_ME
  );

  const [aboutMeError, setAboutMeError] = useState();

  const [profileCompletionState, setProfileCompletionState] = useContext(
    ProfileCompletionContext
  );

  const onChange = (text, characters) => {
    setAboutMeState(text);
    setCharCount(characters);
  };

  const onSave = () => {
    if (props.data !== '' || charCount > 0) {
      updateAboutMe({
        variables: {
          aboutMe: aboutMeState == emptyPattern ? '' : aboutMeState,
        },
      });
    } else {
      setAboutMeError('empty_field');
    }
  };

  useEffect(() => {
    if (data) {
      props.onSave({
        copy: aboutMeState == emptyPattern ? '' : aboutMeState,
      });

      if (data && data.updateUserProfile.profile_completion >= 70) {
        authContext.setIsOnboarded();
      }

      if (aboutMeState.length) {
        setProfileCompletionState({
          ...profileCompletionState,
          profile_completion: data.updateUserProfile.profile_completion,
          aboutMe: true,
        });
      } else {
        setProfileCompletionState({
          ...profileCompletionState,
          profile_completion: data.updateUserProfile.profile_completion,
          aboutMe: false,
        });
      }
    }
  }, [data]);

  const reactQuillMaxLength = 1000;

  return (
    <div>
      <Heading
        level="h3"
        weight="legendBold"
        css={{ lineHeight: '1.3em', marginBottom: '$24' }}
      >
        About Me
      </Heading>
      <ModalDescription>
        <String id="profile_add_aboutMe_description" />
      </ModalDescription>

      <ModalEditorWrapper className="wysiwyg-editor">
        <ReactQuillInput
          theme="bubble"
          bounds=".wysiwyg-editor"
          defaultValue={aboutMeState}
          value={aboutMeState}
          onChangeText={onChange}
          placeholder={'Write something ...'}
          maxLength={reactQuillMaxLength}

          // modules={Editor.modules}
          // formats={Editor.formats}
        />
        <CharsCount
          stringToCount={aboutMeState}
          maxLength={reactQuillMaxLength}
          isQuill={true}
        />
        {aboutMeError && (
          <Text
            size="16"
            css={{ color: '$red', fontFamily: '$ArtifaktLegendBold' }}
          >
            <String id={`profile_error_${aboutMeError}`} />
          </Text>
        )}
      </ModalEditorWrapper>
      <Button
        type="normal"
        label="Cancel"
        colorTheme="gray"
        onClick={props.onClose}
        actionType="onclick"
        css={{ marginRight: '$16' }}
      />
      <Button
        type="normal"
        label="Save"
        colorTheme="normal"
        onClick={onSave}
        actionType="onclick"
        isLoading={loading}
        isDisabled={loading}
      />
      {loading ? <Loader isLoading="true" /> : null}
    </div>
  );
};

export default AboutMedEdit;

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = ['header', 'bold', 'italic', 'list', 'bullet', 'link'];
