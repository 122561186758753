import { styled } from '@root/stitches.config';
import Avatar from '@components/atoms/avatar/Avatar';
import { Text } from '@components/atoms/text/Text';

export const AvatarStyled = styled(Avatar, {
  margin: 0,
  position: 'relative',
  top: '2px',
});

export const ArtistProfession = styled(Text, {
  letterSpacing: '.2px',
  lineHeight: '2em',
});

export const Label = styled(Text, {
  letterSpacing: '.8px',
  textTransform: 'uppercase',
});

export const ArtworkByBoxWrapper = styled('div', {
  margin: 0,
  display: 'flex',
  position: 'relative',
  zIndex: 2,
  alignItems: 'center',
});

export const ArtworkByTextWrapper = styled('div', {
  margin: 0,
  color: '$ultraLightGrey',
  marginLeft: '$16',
  lineHeight: '1.4em',
  textAlign: 'left',
});
