import { styled } from '@root/stitches.config';

export const MembersAvatars = styled('div', {
  display: 'flex',
  position: 'relative',
  height: '40px',
});

export const ShowMoreButton = styled('div', {
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  boxShadow: '$2',
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$14',
  transition: 'box-shadow .3s ease',
  backgroundColor: '$box',
  position: 'absolute',
  left: '$48',
});

export const StyledAvatar = styled('div', {
  position: 'absolute',
  left: '0',

  variants: {
    isSecond: {
      true: {
        left: '$24',
      },
    },
  },
});
