import 'swiper/swiper.scss';

import {
  NextBtns,
  PrevBtns,
  SliderHeader,
  StyledText,
  StyledTitle,
  SuggestedGroupSliderWrapper,
  SwiperContainer,
} from './SuggestedGroupSlider.styled';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { getImageSrc } from '@src/utils/getImageSrc';
import Button from '@components/atoms/button/Button';
import { GET_ALL_GROUPS } from '@src/apollo/query';
import { GET_MY_GROUPS } from '@src/apollo/query';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import GroupBox from '@components/molecules/groupBox/GroupBox';
import GroupBoxLoading from '@components/molecules/groupBox/GroupBoxLoading';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import UrlsContext from '@context/UrlsContext';
import { useLazyQuery } from '@apollo/client';

SwiperCore.use([Navigation]);

const SuggestedGroupSlider = props => {
  const UrlContext = useContext(UrlsContext);

  const [myGroupState, setMyGroupState] = useState({
    myGroupsId: [],
    allMyGroups: [],
    myGroupsLoaded: false,
  });

  const [communityGroupState, setCommunityGroupState] = useState({
    allGroups: [1, 2, 3, 4, 5, 6],
    allGroupsLoaded: false,
  });

  const [
    getMyGroups,
    { loading: loadingMyGroups, error: errorMyGroups, data: dataMyGroups },
  ] = useLazyQuery(GET_MY_GROUPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (props.isLogged) {
      getMyGroups();
    } else {
      setMyGroupState({
        ...myGroupState,
        myGroupsLoaded: true,
        allMyGroups: [],
        myGroupsId: [],
      });
    }
  }, [props.isLogged]);

  useEffect(() => {
    if (dataMyGroups) {
      const groups = dataMyGroups.mygroups;

      const groupList = [];
      const groupsIdList = [];
      groups.map((element, index) => {
        if (element) {
          const iconUrl = getImageSrc(element.icon, 'group_icon', element.name);

          const headerUrl = getImageSrc(
            element.header_image,
            'group_header_small',
            element.name
          );

          const singleGroup = {
            groupTitle: element.name,
            description: element.description,
            imageGroupIcon: iconUrl,
            imageGroupHead: {
              src: headerUrl,
              alt: element.name,
              url: GetUrl('/' + 'community/groups/' + element.slug, UrlContext),
            },
            groupId: element.id,
          };
          groupList.push(singleGroup);
          groupsIdList.push(singleGroup.groupId);
        }
      });

      setMyGroupState({
        ...myGroupState,
        allMyGroups: groupList,
        myGroupsId: groupsIdList,
        myGroupsLoaded: true,
      });
    }
  }, [dataMyGroups]);

  const [getAllGroups, { loading, error, data }] = useLazyQuery(
    GET_ALL_GROUPS,
    {
      context: { clientName: 'public' },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (myGroupState.myGroupsLoaded) {
      getAllGroups();
    }
  }, [myGroupState.myGroupsLoaded]);

  useEffect(() => {
    if (data) {
      const groups = data.groups;
      const allGroupList = [];
      let groupsCount = 0;
      for (let index = 0; groupsCount < 8; index++) {
        const element = groups[index];

        let isInMyGroups = false;
        for (let i = 0; i < myGroupState.myGroupsId.length; i++) {
          if (myGroupState.myGroupsId[i] === element?.id) {
            isInMyGroups = true;
          }
        }

        if (!isInMyGroups && index < groups.length) {
          groupsCount++;
          const iconUrl = getImageSrc(element.icon, 'group_icon', element.name);
          const headerUrl = getImageSrc(
            element.header_image,
            'group_header_small',
            element.name
          );

          const singleGroup = {
            groupTitle: element.name,
            description: element.description,
            imageGroupIcon: iconUrl,
            imageGroupHead: {
              ...headerUrl,
              url: GetUrl('/' + 'community/groups/' + element.slug, UrlContext),
            },
            groupId: element.id,
          };
          allGroupList.push(singleGroup);
        }
        if (index === groups.length && groupsCount < 8) {
          groupsCount = 8;
        }
      }

      setCommunityGroupState({
        ...communityGroupState,
        allGroups: allGroupList,
        allGroupsLoaded: true,
      });
    }
  }, [data && myGroupState.myGroupsLoaded]);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [sliderState, setSlideState] = useState({
    slidesPerView: 4,
    spaceBetween: '2.5%',
  });

  // const getInternalLink = (url) => ( url.replace(CMS_URL,'') );

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  function useCurrentWidth() {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
      let timeoutId = null;

      const howManySlides = () => {
        const width = getWidth();

        let slidesPerView = 0;
        let spaceBetween = 0;

        if (width <= 520 && width > 420) {
          slidesPerView = 2.05;
          spaceBetween = '4.27%';
        } else if (width <= 420) {
          slidesPerView = 1.4;
          spaceBetween = '6.27%';
        } else {
          slidesPerView = 3;
          spaceBetween = '2.5%';
        }

        setSlideState({
          ...sliderState,
          slidesPerView: slidesPerView,
          spaceBetween: spaceBetween,
        });
      };
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(
          () =>
            setWidth(() => {
              howManySlides();
            }),
          150
        );
      };
      window.addEventListener('resize', resizeListener);

      howManySlides();

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }, []);
    return width;
  }

  const resize = useCurrentWidth();

  const isEnd = swiper => {
    setTimeout(() => {
      if (swiper.activeIndex === 0) {
        document.querySelector('.prev-btn').classList.add('is-invisible');
        document.querySelector('.next-btn').classList.remove('is-invisible');
      } else if (
        (swiper.activeIndex ===
          swiper.slides.length - (sliderState.slidesPerView - 1) &&
          swiper.isEnd) ||
        swiper.activeIndex === swiper.slides.length - 1 ||
        swiper.isEnd
      ) {
        document.querySelector('.prev-btn').classList.remove('is-invisible');
        document.querySelector('.next-btn').classList.add('is-invisible');
      } else {
        document.querySelector('.prev-btn').classList.remove('is-invisible');
        document.querySelector('.next-btn').classList.remove('is-invisible');
      }
    }, 300);
  };

  if (
    communityGroupState.allGroups.length === 0 ||
    !communityGroupState.allGroupsLoaded
  ) {
    return null;
  }

  return (
    <SuggestedGroupSliderWrapper css={props.css}>
      <SliderHeader>
        <div>
          <StyledTitle size="14">
            <String id="general_suggested_text" />
          </StyledTitle>
          <StyledText size="12">{props.caption}</StyledText>
        </div>
        <Link alt={props.linkTitle} to={props.url}>
          <String id="general_see_all" />
        </Link>
      </SliderHeader>
      <SwiperContainer>
        <Swiper
          spaceBetween={sliderState.spaceBetween}
          slidesPerView={sliderState.slidesPerView}
          watchSlidesVisibility={true}
          speed={600}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
            isEnd(swiper);
          }}
          onSlideChangeTransitionStart={swiper => {
            isEnd(swiper);
          }}
          onSlideChangeStart={swiper => {
            isEnd(swiper);
          }}
          onResize={swiper => {
            isEnd(swiper);
          }}
        >
          {communityGroupState.allGroups.map((thumbnail, i) => {
            if (typeof thumbnail === 'number') {
              return (
                <SwiperSlide key={`slide-${i} thumbnail-type${thumbnail.type}`}>
                  <GroupBoxLoading inSlider={true} />
                </SwiperSlide>
              );
            } else {
              return (
                <SwiperSlide key={'slide-' + i}>
                  <GroupBox
                    {...thumbnail}
                    inSlider={true}
                    onJoinGroup={props.onJoinGroup}
                    isLogged={props.isLogged}
                    isVerified={props.isVerified && props.isOnboarded}
                  />
                </SwiperSlide>
              );
            }
          })}
          <PrevBtns className="prev-btn is-invisible" ref={prevRef}>
            <Button
              type="circle"
              colorTheme="normal"
              actionType="onclick"
              svgIcon="arrow-left-short"
            />
          </PrevBtns>
          <NextBtns className="next-btn is-invisible" ref={nextRef}>
            <Button
              type="circle"
              colorTheme="normal"
              actionType="onclick"
              svgIcon="arrow-right-short"
            />
          </NextBtns>
        </Swiper>
      </SwiperContainer>
    </SuggestedGroupSliderWrapper>
  );
};

export default SuggestedGroupSlider;

SuggestedGroupSlider.propTypes = {};
