import { styled } from '@root/stitches.config';

import ThumbnailSlider from '@components/organisms/thumbnailSlider/ThumbnailSlider';
import Button from '@components/atoms/button/Button';

export const StyledThumbnailSlider = styled(ThumbnailSlider, {
  marginTop: '$40',
  paddingBottom: '$16',
  '@bp3': {
    marginTop: '$64',
  },
});

export const ArticlesWrapper = styled('div', {
  marginTop: '$32',
  '.articlesWrapper_header': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@bp1': {
      flexDirection: 'row',
    },
  },
  '@bp3': {
    marginTop: '$64',
  },
});

export const StyledButton = styled(Button, {
  alignSelf: 'center',
});

export const HighlightWrapper = styled('div', {
  display: 'grid',
  gap: '$16',
  margin: '0 auto $16 auto',
  gridTemplateColumns: 'repeat(1, 1fr)',
  '@bp0': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp1': {
    margin: '0 auto 40px auto',
    gap: '32px',
  },
  '@bp2': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    '.thumbnail-featured': {
      gridColumn: '2 / 4',
    },
  },
  '@bp4': {
    gridTemplateColumns: 'repeat(4, 1fr)',

    '.thumbnail-featured': {
      gridColumn: '3 / 5',
    },
  },
});

export const SpinnerWrapper = styled('div', {
  width: '100%',
  height: '400px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
