import {
  GroupListingHeader,
  StyledH1,
  StyledText,
} from './ListingHeader.styled';

import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';

const ListingHeader = props => {
  const { title, intro, isCMSDynamic, css } = props;

  return (
    <GroupListingHeader>
      <StyledH1 as="h3" weight="legendExtraBold" isArticles={props.isArticles}>
        {isCMSDynamic ? <String id={title} /> : title}
      </StyledH1>
      {intro && (
        <StyledText as="p">
          {isCMSDynamic ? <String id={intro} /> : intro}
        </StyledText>
      )}
    </GroupListingHeader>
  );
};

ListingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  isCMSDynamic: PropTypes.bool,
};

ListingHeader.defaultProps = {
  isCMSDynamic: false,
  intro: undefined,
};

export default ListingHeader;
