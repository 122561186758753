import { LinkStyled, LogInBoxTitle, LogInBoxWrapper } from './LogInBox.styled';
import React, { useContext } from 'react';

import GetUrl from '@components/atoms/getUrl/GetUrl';
import { Heading } from '@components/atoms/heading/Heading';
import Separator from '@components/atoms/separator/Separator';
import SocialAuthBox from '@components/organisms/socialAuthBox/SocialAuthBox';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import UserLogInForm from './userLogInForm/UserLogInForm';

const LogInBox = props => {
  const UrlContext = useContext(UrlsContext);
  return (
    <LogInBoxWrapper>
      {!props.isVerifyPage && (
        <>
          <LogInBoxTitle>
            <Text
              as="h2"
              weight="legendExtraBold"
              css={{
                marginBottom: '$16',
                // Temporary
                fontSize: '$24',
                '@bp2': {
                  fontSize: '$42',
                },
              }}
            >
              <String id="sign_in_title" />
            </Text>
          </LogInBoxTitle>

          <SocialAuthBox type="signin" />

          <Separator label="OR" />
        </>
      )}
      <UserLogInForm
        onResendEmailVerification={props.onResendEmailVerification}
      />
      {!props.isVerifyPage && (
        <Text size="$14">
          <String id="sign_in_create_account_text" />{' '}
          <LinkStyled to={GetUrl('join', UrlContext)}>
            <String id="sign_in_create_account_link_label" />
          </LinkStyled>
        </Text>
      )}
    </LogInBoxWrapper>
  );
};

export default LogInBox;
