import { GET_MEMBERS_OF } from '@src/apollo/query';
import { GET_PAGE_DATA_MEMBER_OF } from '@src/apollo/queryPages';
import {
  // PageWrapper,
  StyledHeading,
  WrapperLinkList,
  WrapperPagination,
  WrapperThumbnail,
} from './InspireMom.styled';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import AuthContext from '@src/context/AuthContext';
// import FilterBar from '@components/organisms/filterBar/FilterBar';
import Layout from '@components/organisms/layout/Layout';
import LinkThumbnailList from '@components/molecules/linkThumbnail/linkThumbnailList/LinkThumbnailList';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import NoResult from '@components/molecules/noResult/NoResult';
import Pagination from '@components/atoms/pagination/Pagination';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import { dataToLinkThumbnailViewModel } from '@src/utils/dataToLinkThumbnailViewModel';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { useIsMobile } from '@src/utils/useIsMobile';

const InspireMom = context => {
  const isMobile = useIsMobile();
  const authContext = useContext(AuthContext);
  const [filtersState, setFiltersState] = useState({
    isActive: [
      {
        id: 'years',
        selectedValues: [],
        type: 'dropdown',
      },
    ],
    isDataSet: false,
    filters: [],
    dropdownFilters: [
      {
        type: 'dropdown',
        title: 'listing_pages_years_filter',
        filterKey: 'years',
        options: [],
      },
    ],
  });

  const [urlParams, setUrlParams] = useState({});

  const { data: pageData, loading: pageDataLoading } = useQuery(
    GET_PAGE_DATA_MEMBER_OF,
    {
      variables: {
        slug: 'inspire/mom',
      },
      context: { clientName: 'public' },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [
    getMotm,
    { loading: loadingMotm, error: errorMotm, data: dataMotm },
  ] = useLazyQuery(GET_MEMBERS_OF, {
    context: { clientName: 'public' },
    variables: {
      limit: 17,
      article_type: 2,
    },
  });

  const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  };

  const page = getQueryVariable('page') || 1;
  const years = getQueryVariable('years');

  useEffect(() => {
    setUrlParams({
      ...urlParams,
      ...(page && { page }),
      ...(years !== undefined &&
        years !== '' && {
          years: years.split(',').map(el => parseInt(el, 10)),
        }),
    });
  }, [page, years]);

  useEffect(() => {
    const newParams = { ...urlParams };
    if (newParams?.years && newParams?.years?.length === 0) {
      delete newParams.years;
    }

    const url = Object.keys(newParams)
      .map((key, i) => `${i === 0 ? '?' : '&'}${key}=${newParams[key]}`)
      .join('');
    window.history.replaceState({}, '', url);

    getMotm({ variables: newParams });

    if (years && filtersState?.isActive) {
      const yearsParam = years.split(',').map(el => parseInt(el, 10));
      const activeYears = filtersState.isActive.find(el => el.id === 'years');
      activeYears.selectedValues = [
        ...new Set([...yearsParam, ...activeYears?.selectedValues]),
      ];
    }
  }, [urlParams, filtersState, years]);

  useEffect(() => {
    if (parseInt(page, 10) > 1 && dataMotm?.userContents?.data.length === 0) {
      changePage(1);
    }
  }, [page, dataMotm]);

  const changePage = num => {
    setUrlParams({
      ...urlParams,
      page: num,
    });
  };

  return (
    <Layout location={context.location} showBreadcrumb>
      <SEO
        title="Member Spotlight"
        slug="inspire-amm"
        seomatic={context?.pageContext?.entry?.seomatic}
      />

      <LandingPageWrapper>
        <SectionWrapper>
          {pageData?.page ? (
            <>
              <ListingHeader
                title={pageData.page.title}
                intro={pageData.page.introduction}
                isCMSDynamic
              />
              {/* <FilterBar
                filters={filtersToVM([...filtersState.dropdownFilters])}
                onChange={e => {
                  const selectedFilters = JSON.parse(e);
                  setUrlParams({
                    ...urlParams,
                    years: selectedFilters.find(el => el?.id === 'years')
                      ?.selectedValues,
                  });
                  setFiltersState({ ...filtersState, isActive: JSON.parse(e) });
                }}
                defaultFilters={filtersState.isActive}
                isMultiSelect={false}
              /> */}
            </>
          ) : (
            <LoaderSkeleton height="300px" width="100%" />
          )}
        </SectionWrapper>
        <SectionWrapper>
          <WrapperThumbnail>
            {loadingMotm ? (
              <>
                <LoaderSkeleton
                  height="870px"
                  width="100%"
                  isFeatured={!isMobile}
                  isRadius
                />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
                <LoaderSkeleton height="420px" width="100%" isRadius />
              </>
            ) : dataMotm?.userContents?.data.length === 0 ? (
              <NoResult />
            ) : (
              dataMotm?.userContents?.data.map((item, i) => {
                return (
                  <Thumbnail
                    key={item.id}
                    {...dataToThumbnailViewModel(
                      item,
                      false,
                      authContext.isAuthenticated
                    )}
                    isFeatured={i === 0 && !isMobile}
                  />
                );
              })
            )}
          </WrapperThumbnail>
          <WrapperPagination>
            <Pagination
              isUrlLink={false}
              activePage={parseInt(page, 10)}
              onCurrentPageChange={e => changePage(e)}
              pages={Math.ceil(
                dataMotm?.userContents?.total / dataMotm?.userContents?.per_page
              )}
            />
          </WrapperPagination>
        </SectionWrapper>
        <SectionWrapper>
          <WrapperLinkList>
            {pageDataLoading && (
              <LoaderSkeleton height="420px" width="100%" isRadius />
            )}
            {!pageDataLoading && pageData && (
              <>
                <StyledHeading level="h3">
                  <String id="page_inspire_mom_links_section_title" />
                </StyledHeading>
                <LinkThumbnailList
                  listData={[
                    dataToLinkThumbnailViewModel(pageData.page.learn_more_1),
                    dataToLinkThumbnailViewModel(pageData.page.learn_more_2),
                  ]}
                />
              </>
            )}
          </WrapperLinkList>
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};

export default InspireMom;
