import { styled } from '@root/stitches.config';

export const LineChartBlockWrapper = styled('div', {

});

export const BigStatWrapper = styled('div', {
  textAlign: 'center',
  flexBasis: '35%',
  h2: {
    fontFamily: '$ArtifaktLegendBold',
    fontSize: '$32',
    color: '$yellow',
    lineHeight: '$1'
  },
  p: {
    fontSize: '$18',
    fontFamily: '$ArtifaktLegend'
  }
});

export const InputsWrapper = styled('div', {
  display: 'flex',
  width: '65%',
  padding: '$32 $48 $48',
  background: '$greyBox',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  justifyContent: 'space-between',
  alignSelf: 'flex-end',
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '$32',
    background: '$greyBox',
    bottom: '-32px',
    left: 0,
  }
});

export const BlockHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
});