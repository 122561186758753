import {
  AuthoringLinkWrapper,
  AuthoringWrapper,
  TextWrapper,
  AvatarWrapper,
} from './ThumbnailAuthoring.styled';

import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

import Avatar from '@components/atoms/avatar/Avatar';
function ThumbnailAuthoring(props) {
  const { author, profilePicture, type, title, isMini, hasMenu } = props;

  const Content = () => (
    <>
      {profilePicture && profilePicture !== null && !isMini && (
        <AvatarWrapper>
          <Avatar alt={`${author.name}`} src={profilePicture} size="small" />
        </AvatarWrapper>
      )}
      <TextWrapper hasMenu={hasMenu}>
        <Text
          as="p"
          className={`thumbnail-authoring-${
            type !== 'artwork' ? 'light' : 'bold'
          }`}
        >
          {type !== 'artwork' && author?.name && (
            <String id="thumbnail_authored_by" />
          )}
          {type === 'artwork' && title}
        </Text>
        {author?.name && (
          <Text
            as="p"
            css={{mt: '$4s'}}
            className={`thumbnail-authoring-${
              type !== 'artwork' ? 'bold' : 'light'
            }`}
          >
            {author.name}
          </Text>
        )}
      </TextWrapper>
    </>
  );

  return (
    <>
      {author?.slug ? (
        <AuthoringLinkWrapper to={`/m/${author.slug}`}>
          <Content />
        </AuthoringLinkWrapper>
      ) : (
        <AuthoringWrapper>
          <Content />
        </AuthoringWrapper>
      )}
    </>
  );
}

ThumbnailAuthoring.propTypes = {
  author: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  hasMenu: PropTypes.bool,
  type: PropTypes.oneOf([
    'artwork',
    'article',
    'tutorial',
    'tutorial_series',
    'asset',
    'ambassador',
  ]).isRequired,
  profilePicture: PropTypes.string,
  title: PropTypes.string,
  isMini: PropTypes.bool,
};

export default ThumbnailAuthoring;
