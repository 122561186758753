import { CMS_GET_PAGE_DATA, GET_CREATE_DETAIL } from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AddImage from '@src/templates/create/blockModals/AddImage';
import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import Button from '@components/atoms/button/Button';
import { CMS_UPDATE_PAGE_DATA } from '@src/apollo/mutation';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import { Heading } from '@components/atoms/heading/Heading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import Modal from '@components/atoms/modal/Modal';
import SEO from '@components/atoms/seo/Seo';
import { SectionWrapper } from './ContentPageInspire.styled';
import SelectUserContent from '@components/molecules/selectUserContent/SelectUserContent';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { useCMSPageUpdateValidation } from '../utils/usePageUpdateValidation';
import useModal from '@components/atoms/modal/useModal';
import { validURL } from '@src/utils/validURL';

const ContentPageInspire = context => {
  const { loading, error, data, refetch } = useQuery(CMS_GET_PAGE_DATA, {
    variables: { slug: 'inspire' },
    context: { clientName: 'cms' },
  });
  const [updatePage, updatePageData] = useMutation(CMS_UPDATE_PAGE_DATA, {
    context: { clientName: 'cms' },
    onCompleted: () => refetch(),
  });

  const {
    isShowing: isShowingFeaturedModal,
    toggle: toggleFeaturedModal,
  } = useModal();
  const {
    isShowing: isShowingExploreModal,
    toggle: toggleExploreModal,
  } = useModal();
  const {
    isShowing: isShowingSeoImage,
    toggle: toggleSeoImage,
  } = useModal();
  const { isShowing, toggle } = useModal();

  const [heroData, setHeroData] = useState({
    title: '',
    description: '',
    image: undefined,
  });
  const [seoData, setSeoData] = useState({
    image: undefined,
  });

  const [userContentFeatured, setUserContentFeatured] = useState(null);
  const [mixFeatured, setMixFeatured] = useState(null);

  useEffect(() => {
    if (data && data.page) {
      setHeroData({
        title: data.page.title,
        description: data.page.introduction,
        image: {
          data_url: validURL(data.page.background_image?.path)
            ? data.page.background_image?.path
            : `${CDN_URL}${data.page.background_image?.path}`,
        },
        artistCredits: {
          name: data.page?.header_image_credit,
          url: data.page?.header_image_credit_link,
        },
      });
      setUserContentFeatured({
        id: data?.page?.userContent_feature?.id,
        slug: data?.page?.userContent_feature?.slug,
      });
      setMixFeatured({
        id: data?.page?.userContent?.id,
        slug: data?.page?.userContent?.slug,
      });
    }
  }, [data]);

  const formValidation = useCMSPageUpdateValidation(heroData);
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  function updateData() {
    const image = new Image();
    image.src = heroData?.image?.data_url;
    const isSameBanner =
      `${CDN_URL}${data?.page?.background_image?.path}` ===
      heroData?.image?.data_url;

    const backgroundImage =
      isSameBanner || heroData?.image?.file === undefined
        ? {}
        : {
            background_image: {
              width: image.width,
              height: ((image.width * 600) / 1920).toFixed(0),
              x: 0,
              y: 0,
              image: heroData.image.file,
            },
          };

    if (
      formValidation.imageIsError ||
      formValidation.descriptionIsError ||
      formValidation.titleIsError
    ) {
      setHeroData({
        ...heroData,
        ...formValidation,
      });
      let counter = 0;

      for (let i = 0; i < Object.values(formValidation).length; i++) {
        if (Object.values(formValidation)[i] === true) {
          counter += 1;
        }
      }
      handleUpdateClientNotification({
        text: `cms_notification_validation_error_${
          counter >= 2
            ? 'cms_multiple_empty'
            : Object.keys(formValidation)
                .filter(el => !el.includes('IsError'))
                .map(el => formValidation[el])[0]
        }`,
        type: 'error',
        autoDismiss: true,
      });
      if (formValidation.imageIsError) {
        toggle();
      }
    } else {
      setHeroData({
        ...heroData,
        ...formValidation,
      });
      updatePage({
        variables: {
          id: data.page.id,
          input: {
            ...(heroData?.artistCredits?.name &&
              !formValidation.artistCreditsUrlisError && {
                header_image_credit: heroData?.artistCredits?.name,
                header_image_credit_link: heroData?.artistCredits?.url,
              }),
            title: heroData.title,
            introduction: heroData.description,
            userContent_feature: userContentFeatured.id,
            userContent: mixFeatured.id,
            ...backgroundImage,
          },
        },
      });
    }
  }

  const { data: dataSelectedUserContentFeatured } = useQuery(
    GET_CREATE_DETAIL,
    {
      variables: {
        slug: userContentFeatured?.slug,
      },
    }
  );

  const { data: dataSelectedMixFeatureUserContent } = useQuery(
    GET_CREATE_DETAIL,
    {
      variables: {
        slug: mixFeatured?.slug,
      },
    }
  );

  const thumbnailData = dataSelectedMixFeatureUserContent?.userContent;
  const featuredThumbnailData = dataSelectedUserContentFeatured?.userContent;

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <Loader isLoading={loading || updatePageData.loading} />
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        <String id="content_page_inspire_title" />
      </Heading>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_inspire_section_hero_title" />
        </Heading>
        <InputText
          onChange={e => {
            setHeroData({
              ...heroData,
              title: e.target.value,
            });
          }}
          type="text"
          inputShape="normal"
          name="title"
          label={<String id="content_page_inspire_title_input_label" />}
          value={heroData.title}
          css={{ margin: '$16 0' }}
          placeholder="Title"
          error={heroData?.titleErrorMessage || null}
          isError={heroData?.titleIsError || false}
          required
          inputTheme="transparent"
        />
        <InputText
          onChange={e => {
            setHeroData({
              ...heroData,
              description: e.target.value,
            });
          }}
          type="text"
          inputShape="normal"
          name="description"
          label={<String id="content_page_inspire_description_input_label" />}
          value={heroData.description}
          css={{ margin: '$16 0' }}
          placeholder="Description"
          error={heroData?.descriptionErrorMessage || null}
          isError={heroData?.descriptionIsError || false}
          required
          inputTheme="transparent"
        />
        <Heading level="h5" css={{ lineHeight: '1em', marginTop: '$24' }}>
          <String id="content_page_inspire_section_banner_title" />
        </Heading>
        {heroData.image && (
          <img
            src={heroData.image.data_url}
            alt="banner"
            style={{ marginTop: '24px' }}
          />
        )}
        <Button
          label={<String id="content_page_inspire_upload_banner_label" />}
          colorTheme="normal"
          svgIcon="image"
          css={{ marginTop: '$32' }}
          actionType="onclick"
          onClick={toggle}
        />
        {heroData.image && heroData.image !== null && (
          <div>
            <InputText
              onChange={e => {
                setHeroData({
                  ...heroData,
                  artistCredits: {
                    ...heroData?.artistCredits,
                    name: e.target.value,
                  },
                });
              }}
              type="text"
              inputShape="normal"
              name="title"
              label={
                <String id="content_page_homepage_signed_in_artist_credits_name" />
              }
              value={heroData?.artistCredits?.name}
              css={{ margin: '$16 0' }}
              placeholder="Artist credits"
              inputTheme="transparent"
            />
            <InputText
              onChange={e => {
                setHeroData({
                  ...heroData,
                  artistCredits: {
                    ...heroData?.artistCredits,
                    url: e.target.value,
                  },
                });
              }}
              type="text"
              inputShape="normal"
              name="title"
              label={
                <String id="content_page_homepage_signed_in_artist_credits_url" />
              }
              value={heroData?.artistCredits?.url}
              isError={heroData?.artistCreditsUrlIsError}
              error={heroData?.artistCreditsUrlErrorMessage}
              css={{ margin: '$16 0' }}
              placeholder="https://..."
              inputTheme="transparent"
            />
          </div>
        )}
        <Modal isShowing={isShowing} hide={toggle}>
          <AddImage
            imageMaxSizeBytes={1500000}
            onClose={toggle}
            resolutionWidth={1920}
            resolutionHeight={560}
            onSave={(e, i) => {
              setHeroData({ ...heroData, image: i });
            }}
          />
        </Modal>
      </SectionWrapper>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_inspire_section_featured_title" />
        </Heading>
        <Button
          actionType="onclick"
          colorTheme="normal"
          css={{
            marginTop: '$32',
          }}
          label={
            <String id="content_page_inspire_section_featured_button_label" />
          }
          onClick={toggleFeaturedModal}
        />
        {featuredThumbnailData !== undefined &&
        featuredThumbnailData !== null ? (
          <>
            <Text as="p" size="16">
              <String id="content_page_inspire_section_featured_selected" />
            </Text>
            <Thumbnail
              {...dataToThumbnailViewModel(featuredThumbnailData, false, false)}
              isMini
            />
          </>
        ) : null}
        <Modal
          isShowing={isShowingFeaturedModal}
          hide={toggleFeaturedModal}
          isSelectUserContent
        >
          <SelectUserContent
            isNotMultiSelect
            status="publish"
            title="content_page_inspire_section_featured_title"
            onRowActionClick={id => {
              toggleFeaturedModal();
              setUserContentFeatured(id);
            }}
          />
        </Modal>
      </SectionWrapper>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em', mb: '$32' }}>
          <String id="content_page_inspire_section_featured_userContent_title" />
        </Heading>
        <div className="content-selection">
          <Button
            actionType="onclick"
            colorTheme="normal"
            label={
              <String id="content_page_inspire_section_featured_button_label" />
            }
            onClick={toggleExploreModal}
          />
          {thumbnailData !== undefined && thumbnailData !== null && (
            <>
              <Text as="p" size="16">
                <String id="content_page_inspire_section_featured_userContent_selected" />
              </Text>
              <Thumbnail
                {...dataToThumbnailViewModel(thumbnailData, false, false)}
                isMini
              />
            </>
          )}
          <Modal
            isShowing={isShowingExploreModal}
            hide={toggleExploreModal}
            isSelectUserContent
          >
            <SelectUserContent
              isNotMultiSelect
              contentType="article"
              articleType={1}
              forceContentType
              title="content_page_inspire_section_featured_userContent_title"
              status="publish"
              onRowActionClick={element => {
                toggleExploreModal();
                setMixFeatured(element);
              }}
            />
          </Modal>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_inspire_section_seo_title" />
        </Heading>
        <Heading level="h5" css={{ lineHeight: '1em', marginTop: '$24' }}>
          <String id="content_page_inspire_section_seo_image_title" />
        </Heading>
        {seoData.image && seoData.image !== null && (
          <img
            src={seoData.image.data_url}
            alt="banner"
            style={{ marginTop: '24px' }}
          />
        )}
        <div>
          <Button
            label={
              <String id="content_page_inspire_upload_banner_label" />
            }
            colorTheme="normal"
            svgIcon="image"
            css={{ marginTop: '$32' }}
            actionType="onclick"
            onClick={toggleSeoImage}
          />
          {seoData.image && seoData.image !== null && (
            <Button
              label={
                <String id="content_page_inspire_remove_banner_label" />
              }
              colorTheme="gray"
              svgIcon="close-big"
              css={{ mt: '$32', ml: '$16' }}
              actionType="onclick"
              onClick={() => {
                setSeoData({
                  ...seoData,
                  image: null,
                });
              }}
            />
          )}
        </div>
        <Modal isShowing={isShowingSeoImage} hide={toggleSeoImage}>
          <AddImage
            imageMaxSizeBytes={1500000}
            onClose={toggleSeoImage}
            resolutionWidth={1920}
            resolutionHeight={560}
            onSave={(e, i) => {
              setHeroData({ ...heroData, image: i });
            }}
          />
        </Modal>
      </SectionWrapper>
      <Button
        label={<String id="content_page_inspire_save_changes" />}
        colorTheme="normal"
        css={{ marginTop: '$32' }}
        actionType="onclick"
        onClick={updateData}
      />
    </AdminLayout>
  );
};

export default ContentPageInspire;
