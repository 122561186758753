import React, { useEffect, useState } from 'react';

import PostsReports from './PostsReports';
import UserContentReports from './UserContentReports';
import UsersReports from './UsersReports';

const ReportDetail = context => {
  if (context.slug === 'posts') {
    return <PostsReports></PostsReports>;
  } else if (context.slug === 'users') {
    return <UsersReports></UsersReports>;
  } else if (context.slug === 'user-contents') {
    return <UserContentReports></UserContentReports>;
  }
};

export default ReportDetail;
