import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import Button from '@components/atoms/button/Button';
import { CMS_GET_PAGE_DATA } from '@src/apollo/query';
import { CMS_UPDATE_PAGE_DATA } from '@src/apollo/mutation';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import { Heading } from '@components/atoms/heading/Heading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import SEO from '@components/atoms/seo/Seo';
import { SectionWrapper } from './ContentPageInspireMoy.styled';
import String from '@components/atoms/string/String';
import { useCMSPageUpdateValidation } from '../utils/usePageUpdateValidation';

const ContentPageInspireMoy = context => {
  const { loading, error, data, refetch } = useQuery(CMS_GET_PAGE_DATA, {
    variables: { slug: 'inspire/moy' },
    context: { clientName: 'cms' },
  });
  const [updatePage, updatePageData] = useMutation(CMS_UPDATE_PAGE_DATA, {
    context: { clientName: 'cms' },
    onCompleted: () => refetch(),
  });

  const [heroData, setHeroData] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    if (data && data.page) {
      setHeroData({
        title: data.page.title,
        description: data.page.introduction,
      });
    }
  }, [data]);

  const formValidation = useCMSPageUpdateValidation(heroData);
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  function updateData() {
    if (formValidation.descriptionIsError || formValidation.titleIsError) {
      setHeroData({
        ...heroData,
        ...formValidation,
      });
      let counter = 0;

      for (let i = 0; i < Object.values(formValidation).length; i++) {
        if (Object.values(formValidation)[i] === true) {
          counter += 1;
        }
      }
      handleUpdateClientNotification({
        text: `cms_notification_validation_error_${
          counter >= 2
            ? 'cms_multiple_empty'
            : Object.keys(formValidation)
                .filter(el => !el.includes('IsError'))
                .map(el => formValidation[el])[0]
        }`,
        type: 'error',
        autoDismiss: true,
      });
    } else {
      setHeroData({
        ...heroData,
        ...formValidation,
      });
      updatePage({
        variables: {
          id: data.page.id,
          input: {
            title: heroData.title,
            introduction: heroData.description,
          },
        },
      });
    }
  }

  return (
    <AdminLayout location={context.location} roles={['admin', 'editor']}>
      <SEO title="Area" />
      <Loader isLoading={loading || updatePageData.loading} />
      <Heading level="h3" css={{ lineHeight: '1em' }}>
        <String id="content_page_inspire_moy_title" />
      </Heading>
      <SectionWrapper>
        <Heading level="h4" css={{ lineHeight: '1em' }}>
          <String id="content_page_inspire_moy_section_hero_title" />
        </Heading>
        <InputText
          onChange={e => {
            setHeroData({
              ...heroData,
              title: e.target.value,
            });
          }}
          type="text"
          inputShape="normal"
          name="title"
          label={<String id="content_page_inspire_moy_title_input_label" />}
          value={heroData.title}
          css={{ margin: '$16 0' }}
          placeholder="Title"
          error={heroData.titleErrorMessage || null}
          isError={heroData.titleIsError || false}
          required
          inputTheme="transparent"
        />
        <InputText
          onChange={e => {
            setHeroData({
              ...heroData,
              description: e.target.value,
            });
          }}
          type="text"
          inputShape="normal"
          name="description"
          label={
            <String id="content_page_inspire_moy_description_input_label" />
          }
          value={heroData.description}
          css={{ margin: '$16 0' }}
          placeholder="Description"
          error={heroData.descriptionErrorMessage || null}
          isError={heroData.descriptionIsError || false}
          inputTheme="transparent"
          required
        />
      </SectionWrapper>
      <Button
        label={<String id="content_page_inspire_moy_save_changes" />}
        colorTheme="normal"
        css={{ marginTop: '$32' }}
        actionType="onclick"
        onClick={updateData}
      />
    </AdminLayout>
  );
};

export default ContentPageInspireMoy;
