import { styled } from '@root/stitches.config';

export const InputGooglePlacesLocationWrapper = styled('div', {
  marginBottom: '$32',
  input: {
    fontFamily: 'var(--ArtifaktElementRegular)',
    letterSpacing: '0px',
    width: '400px !important',
  },

  variants: {
    isError: {
      true: {
        '.g-places-select__control': {
          border: '$red 1px solid',
        },
      },
    },
  },

  '.g-places-select__input': {
    color: '$mainTextColor',
    backgroundColor: '$box',
    lineHeight: '1em',
    width: '400px',
    height: '18px',
  },
  '.g-places-select__control--is-focused': {
    border: '1px solid $lightGrey !important',
    boxShadow: 'none',
    borderRightColor: '$mainTextColor',
    borderLeftColor: '$mainTextColor',
    borderTopColor: '$mainTextColor',
    borderBottomColor: '$mainTextColor',
    '&:hover': {
      borderColor: '$elements',
    },
  },
  '.g-places-select__value-container > *': {
    margin: '0 !important',
    padding: '0 !important',
  },
  '.g-places-select__control': {
    color: '$mainTextColor',
    backgroundColor: '$box',
    borderRadius: '$8',
    padding: '$12 $16',
    width: '100%',
    borderColor: '$lightGrey',
    '&:hover': {
      borderColor: '$elements',
    },
  },

  '.g-places-select__menu': {
    color: '$mainTextColor',
    backgroundColor: '$box',
    zIndex: 22,
    position: 'absolute',
  },
  '.g-places-select__option': {
    color: '$mainTextColor',
    backgroundColor: '$box',
    '&:hover': {
      backgroundColor: '$green',
    },
  },
  '.g-places-select__single-value': {
    color: '$mainTextColor',
    backgroundColor: '$box',
    padding: 0,
    lineHeight: '1em',
  },
  '.g-places-select__value-container': {
    padding: 0,
    lineHeight: '16px',
  },
  '.g-places-select__indicators': {
    display: 'none',
  },
  '.g-places-select__placeholder': {
    pointerEvents: 'none',
    whiteSpace: 'pre',
  },
});

export const InputTextLabel = styled('label', {
  marginBottom: '$4',
  display: 'inline-block',
  fontSize: '$14',
  fontFamily: 'var(--ArtifaktElement)',
  display: 'flex',
  alignContent: 'center',
  gridArea: 'label',
  marginRight: '$8',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
  svg: {
    display: 'inline',
  },
});

export const InputTextErrorMsg = styled('div', {
  gridArea: 'error',
  fontSize: '$12',
  marginTop: '$4',
  minHeight: '$24',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});
