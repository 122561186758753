import { styled, keyframes } from '@root/stitches.config';
import Svg from '@components/atoms/svg/Svg';
import Image from '@components/atoms/image/Image';
import { Text } from '@components/atoms/text/Text';

const fadeInOpacity = keyframes({
  '0%': { opacity: '0' },
  '100%': { opacity: '1' },
});

export const ThumbnailWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  minWidth: '204px',
  height: 'auto',
  height: '100%',
  borderRadius: '18px',
  '-webkit-border-radius': '18px',
  '-moz-border-radius': '18px',
  '-khtml-border-radius': '18px',
  overflow: 'hidden',
  transition: 'all 0.2s ease-out',
  border: '1px solid $thumbnailBorder',
  display: 'flex',
  flexDirection: 'column',
  flex: '0',
  background: '$box',
  boxSizing: 'border-box',
  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '124%',
  },
  '.thumbnail-link': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 2,
  },
  '.thumbnail-cover': {
    transform: 'scale(1)',
    transition: 'transform 0.4s ease-out',
    willChange: 'transform',
  },
  '.thumbnail-menu': {
    transition: 'opacity 0.4s ease-out',
  },
  '&:hover': {
    boxShadow: '$3',
    transform: 'translate(0px, -4px)',
    '.thumbnail-cover': {
      transform: 'scale(1.015)',
    },
    '.thumbnail-menu': {
      opacity: '1',
    },
  },

  variants: {
    isFeatured: {
      true: {
        '@bp1': {
          gridColumn: '1 / 3',
          gridRow: '1 / 3',
        },
        '&:hover': {
          '.thumbnail-cover': {
            transform: 'scale(1.01)',
          },
        },
      },
    },
    isMobile: {
      true: {
        '.thumbnail-menu': {
          opacity: '1',
        },
      },
      false: {
        '.thumbnail-menu': {
          opacity: '0',
        },
      },
    },
    isMini: {
      true: {
        maxWidth: '232px',
        maxHeight: '288px',
        '.mini-image': {
          height: '50%',
        },
      },
    },
  },
});

export const ThumbnailPendingWrapper = styled('div', {
  position: 'absolute',
  top: '$8',
  left: '$8',
  zIndex: 2,
  display: 'flex',
  padding: '4px',
  paddingRight: '$8',
  br: 100,
  background: '$whiteToBlack',
  color: '$blackToWhite',

  '.pending-icon': {
    fill: '$blackToWhite',
    width: '$24',
    height: '$24',
    marginRight: '$8',
  },
});

export const ImageWrapper = styled('div', {
  width: '100%',
  height: '65%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: '$24 $32',
  'border-top-left-radius': '18px',
  'border-top-right-radius': '18px',
  overflow: 'hidden',
  button: {
    zIndex: 2,
    pointerEvents: 'all',
  },
  '&::before': {
    content: '',
    width: '100%',
    height: '100%',
    'border-top-left-radius': '18px',
    'border-top-right-radius': '18px',
    left: '0',
    bottom: '0',
    position: 'absolute',
    background: 'linear-gradient(to top,#181818,transparent 50%)',
    // background:
    //   'linear-gradient(to Bottom, rgb(31 31 31 / 76%) 0%, #00000000 45%), linear-gradient(to Top, rgb(0 0 0) 0%, #00000000 25%)',
    zIndex: 1,
  },
  variants: {
    isHalf: {
      true: {
        height: '50%',
      },
    },
    isAllRounded: {
      true: {
        borderRadius: '18px',

        '&::before': {
          content: '',
          width: '100%',
          height: '100%',
          borderRadius: '18px',
          left: '0',
          bottom: '0',
          position: 'absolute',
          background:
            'linear-gradient(to Bottom, rgb(31 31 31 / 76%) 0%, #00000000 25%), linear-gradient(to Top, rgb(0 0 0) 0%, #00000000 25%)',
          zIndex: 1,
        },
      },
    },
    type: {
      artwork: {
        padding: '$16',
        height: '100%',
        '&::before': {
          //height: '35%',
        },
      },
      ambassador: {
        padding: '$32',
        height: '100%',
        '&::before': {
          //height: '35%',
        },
      },
    },
    isMini: {
      true: {
        padding: '$16 !important',
        '&:before': {
          background:
            'linear-gradient(to Bottom, rgb(31 31 31 / 76%) 0%, #00000000 30%), linear-gradient(to Top, rgb(0 0 0) 0%, #00000000 40%)',
        },
      },
    },
  },
  '.thumbnail-cover': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export const ImageStyled = styled(Image, {
  'border-top-left-radius': '18px',
  'border-top-right-radius': '18px',
  variants: {
    isAllRounded: {
      true: {
        borderRadius: '18px',
      },
    },
  },
});

export const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  height: '35%',
  width: '100%',
  bottom: '0',
  padding: '$16 $32',

  '.content-wrapper-inner .thumbnail-label': {
    fontSize: '$14',
    fontFamily: '$ArtifaktLegendRegular',
    lineHeight: '$4',
    color: '$darkrey',
  },
  '.content-wrapper-inner': {
    'h6, h4, h2': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      boxSizing: 'content-box',
    },
  },
  '.content-wrapper-inner h6': {
    fontSize: '$18',
    fontFamily: '$ArtifaktElementExtraBold',
    lineHeight: '$2',
    marginTop: '$8',
    wordBreak: 'break-word',
  },
  '.content-wrapper-inner h4': {
    fontSize: '$24',
    fontFamily: '$ArtifaktElementExtraBold',
    lineHeight: '$2',
    marginTop: '$4',
  },
  '.content-wrapper-inner > .thumbnail-date': {
    fontSize: '$12',
    fontFamily: '$ArtifaktElement',
    lineHeight: '$2',
  },
  '.content-wrapper-inner h2': {
    fontSize: '$42',
    lineHeight: '$2',
    fontFamily: '$ArtifaktElementExtraBold',
    marginTop: '$16',
  },
  variants: {
    isFeatured: {
      true: {
        padding: '$32',
        '.content-wrapper-inner > .thumbnail-label': {
          fontSize: '$16',
          lineHeight: '$2',
        },
      },
    },
    isHalf: {
      true: {
        height: '50%',
      },
    },
    isMini: {
      true: {
        padding: '$16',
        height: '50%',
        '.content-wrapper-inner': {
          'h6, h4, h2': {
            '-webkit-line-clamp': 2,
          },
        },
      },
    },
  },
});

export const ContentWrapperFooter = styled('div', {
  display: 'grid',
  alignItems: 'flex-end',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr auto',
  gap: '$24',
  variants: {
    isMini: {
      true: {
        gap: '$8',
      },
    },
  },
});

export const TitleStyled = styled(Text, {
  variants: {
    isInGroupPage: {
      true: {
        fontSize: '$16',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2 !important',
        'line-clamp': '2 !important',
        '-webkit-box-orient': 'vertical',
      },
    },
  },
});

export const ThumbnailLevelWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '$mainTextColor',
});

export const StyledSvg = styled(Svg, {
  fill: '$mainTextColor',
  width: '$24',
  height: '$24',
});

export const AmbassadorWrapper = styled('div', {
  zIndex: 2,
  color: 'white',
  pointerEvents: 'none',
  '> p': {
    lineHeight: 1,
  },
  '.ambassador-wrapper-header': {
    '> p': {
      lineHeight: 1,
    },
    span: {
      fontFamily: '$ArtifaktElementBold',
    },

    '.ambassador-wrapper-header-type': {
      alignItems: 'center',
      '&::after': {
        content: "''",
        display: 'block',
        position: 'relative',
        height: '100%',
        alignSelf: 'center',
        width: '1px',
        background: 'white',
        margin: '0 $8',
      },
    },
  },
  '> h3': {
    fontSize: '$28',
    fontFamily: '$ArtifaktElementExtraBold',
    lineHeight: '$2',
    margin: '$4 0',
  },
  '> h2': {
    fontSize: '$60',
    fontFamily: '$ArtifaktElementExtraBold',
    lineHeight: '$2',
    margin: '$16 0',
  },
  variants: {
    isMini: {
      true: {
        '> h3': {
          fontSize: '$20',
        },
      },
    },
  },
});

export const ThumbnailContentType = styled('div', {
  position: 'absolute',
  top: '$24',
  right: '$32',
  zIndex: '$2',
  color: '$white',
  border: '1px $white solid',
  display: 'inline-flex',
  borderRadius: '$pill',
  padding: '$4 $12',
  fontSize: '$14',
  fontFamily: '$ArtifaktElementMedium',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.20)',
  variants: {
    isMini: {
      true: {
        top: '$16',
        right: '$16',
      },
    },
  },
  svg: {
    marginRight: '$8',
  },
});
