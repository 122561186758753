import {
  ArtistName,
  CommentBoxWrapper,
  CommentFooter,
  CommentHeader,
  CommentReplyWrapper,
  CommentWrapper,
  DeleteBtnsContainer,
  LoaderWrapper,
  PreviousRepliesBtn,
  RepliesContainer,
  ReplyingContainer,
  ReportBtns,
  ReportWrapper,
  ShowRepliesBtn,
  StyledActionBtn,
  StyledBtn,
  StyledLink,
  StyledReportText,
  StyledText,
  TextContainer,
  ThreeDotsBtn,
} from './CommentBox.styled';
import React, { useContext, useEffect, useState } from 'react';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@context/AuthContext';
import Avatar from '@components/atoms/avatar/Avatar';
import CommunityLinkPreview from '@components/molecules/communityLinkPreview/CommunityLinkPreview';
import CommunityPostBoxImages from '@components/molecules/communityPostBox/communityPostBoxImages/CommunityPostBoxImages';
import { POST_REACTION } from '@src/apollo/mutation';
import PropTypes from 'prop-types';
import Separator from '@components/atoms/separator/Separator';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { formatDateTimeAgo } from '@src/utils/datesHelper';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import useModal from '@components/atoms/modal/useModal';
import Modal from '@components/atoms/modal/Modal';
import { useMutation } from '@apollo/client';
import PendingPublicationsModal from '@components/molecules/pendingPublicationsModal/PendingPublicationsModal';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import CommunityAddPostModal from '@components/molecules/communityAddPost/communityAddPostModal/CommunityAddPostModal';
import Button from '@components/atoms/button/Button';
import CommunityPostContext from '@context/CommunityPostContext';

const CommentReply = props => {
  const { reply } = props;
  const [postState, setPostState] = useContext(CommunityPostContext);

  const { isShowing, toggle } = useModal();
  const [isReporting, setIsReporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [repliesState, setRepliesState] = useState({
    reported: false,
    edited: false,
    liked: false,
  });

  const toggleReport = () => {
    setIsReporting(!isReporting);
  };
  const toggleDelete = () => {
    setIsDeleting(!isDeleting);
  };
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const likeLabel = <String id="general_like_text" />;

  const authContext = useContext(AuthContext);
  const [isLiked, setIsLiked] = useState(false);
  const [addRemoveReaction, dataReaction] = useMutation(POST_REACTION);

  useEffect(() => {
    if (reply.likesList) {
      for (let i = 0; i < reply.likesList.length; i++) {
        if (reply.likesList[i].username === authContext.userSlug) {
          setIsLiked(true);
          return;
        }
      }
    }
  }, []);

  const [reportState, setReportState] = useState({
    isVisible: false,
  });

  const showMoreMax = 2;

  const onToggleOptionsClick = id => {
    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });
    if (props.openOptionsId) {
      props.openOptionsId(id);
    }
  };
  const onPreviousRepliesClick = e => {
    setRepliesState({
      ...repliesState,
      actualLimit: repliesState.actualLimit + showMoreMax,
    });
    refetch();
  };

  const onDeleteClick = () => {
    setIsDeleting(!isDeleting);
    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });

    document.querySelector('.comment-' + props.replyId).style.display = 'none';

    props.onDeleteClick(props.replyId, props.siblingTotal, props.parentId);
  };

  const onLikeClick = e => {
    if (
      authContext.isAuthenticated &&
      authContext.isVerified &&
      authContext.isOnboarded &&
      props.isCurrentUserGroupMember
    ) {
      let action = '';
      if (isLiked) {
        action = 'remove';
        setRepliesState({
          ...repliesState,
          likes: repliesState.likes - 1,
        });
      } else {
        action = 'add';
        setRepliesState({
          ...repliesState,
          likes: repliesState.likes + 1,
        });
      }
      setIsLiked(!isLiked);
      console.log(props.replyId);
      addRemoveReaction({
        variables: {
          post_id: props.replyId,
          input: {
            reaction: 'like',
            action,
          },
        },
      });
    } else if (!props.isCurrentUserGroupMember) {
      toggle();
    } else {
      toggle();
    }
  };

  const onJoinClick = () => {
    toggle();
    props.onJoinGroup(props.groupId);
  };

  const cancelBtnText = <String id="general_cancel_btn_text" />;
  const deleteBtnText = <String id="general_delete_comments_text" />;

  const onReportClick = (id, value) => {
    props.onReportClick(id, value);
    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });
  };

  useEffect(() => {
    if (reportState.isVisible && props.optionsIds !== props.replyId) {
      setReportState({
        ...reportState,
        isVisible: false,
      });
    }
  }, [props.optionsIds]);

  const closeMenus = (data, isComment, isReply, parentId) => {
    if (isReply) {
      props.updateComment(data, isReply, parentId);
    } else if (isComment) {
      props.updateComment(data);
    }

    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });
  };

  return (
    <CommentBoxWrapper>
      <StyledLink alt={reply.postAuthor.name} to={reply.postAuthor.url}>
        <Avatar src={reply.userInfos.avatarProps.src} />
      </StyledLink>
      <CommentWrapper isReported={reply.isReported}>
        <CommentHeader>
          <TextContainer>
            <Text size="14" css={{ color: '$mediumGrey' }}>
              <ArtistName alt={reply.postAuthor.name} to={reply.postAuthor.url}>
                {reply.postAuthor.name}
              </ArtistName>{' '}
              <span>{reply.postAuthor.jobTitle}</span>
            </Text>
            <StyledText
              size="14"
              dangerouslySetInnerHTML={{ __html: reply.postText }}
            />
          </TextContainer>

          {reply.postImages.length ? (
            <CommunityPostBoxImages
              postImages={reply.postImages}
              isCommentImage
              commentImages={reply.commentImages}
            />
          ) : null}
          {reply.postImages.length === 0 && reply.postLinkPreview ? (
            <CommunityLinkPreview
              {...reply.postLinkPreview}
              className="link-preview-comment"
            />
          ) : null}
          <ReportWrapper
            isVisible={
              reportState.isVisible && props.optionsIds === reply.commentId
            }
          >
            <ReportBtns onClick={toggleReport}>
              <Svg icon="report-flag" />
              <span>
                <StyledReportText size="14">
                  <String id="general_report_comments_text" />
                </StyledReportText>
                <Text
                  size="12"
                  css={{ color: '$mainTextColor', marginTop: '$4' }}
                >
                  <String id="general_flag_comments_text" />
                </Text>
              </span>
            </ReportBtns>
            {JSON.parse(localStorage.getItem('userSlug')) ===
              reply.commentAuthor &&
            (postState.groupRole === 'moderator' ||
              postState.groupRole === 'pre_approved' ||
              postState.generalRole === 'admin' ||
              postState.generalRole === 'editor') ? (
              <ReportBtns onClick={toggleEdit}>
                <Svg icon="edit" />
                <StyledReportText size="14">
                  <String id="community_edit_comment_text" />
                </StyledReportText>
              </ReportBtns>
            ) : null}
            {reply.postAuthor.username ===
            JSON.parse(localStorage.getItem('userSlug')) ? (
              <ReportBtns onClick={toggleDelete}>
                <Svg icon="close-small" />
                <StyledReportText size="14">
                  <String id="general_delete_comments_text" />
                </StyledReportText>
              </ReportBtns>
            ) : null}
          </ReportWrapper>
          <Separator />
          {authContext.isAuthenticated &&
          authContext.isVerified &&
          authContext.isOnboarded &&
          props.openOptionsId ? (
            <ThreeDotsBtn
              type="circle"
              colorTheme="transparent"
              svgIcon="three-dots"
              actionType="onclick"
              onClick={() => {
                onToggleOptionsClick(reply.commentId);
              }}
            />
          ) : null}
        </CommentHeader>

        <CommentFooter>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledBtn>
              <StyledActionBtn
                type={reply.likes > 0 ? 'circle' : 'normal'}
                colorTheme="transparent"
                label={likeLabel}
                svgIcon={
                  isLiked && authContext.isAuthenticated
                    ? 'heart-full'
                    : 'love-empty'
                }
                actionType="onclick"
                isCounter
                counter={reply.likes > 0 ? reply.likes : 0}
                onClick={() => {
                  onLikeClick(reply.id);
                }}
                isLikesBtn
                isLiked={isLiked}
                needsTopRight={reply.likes > 0}
                isDisabled={props.isReported}
              />
            </StyledBtn>
            <StyledBtn>
              <StyledActionBtn
                className="test"
                type="circle"
                colorTheme="transparent"
                actionType="onclick"
                svgIcon="reply-comment"
                onClick={() => {
                  !authContext.isAuthenticated ||
                  (authContext.isAuthenticated &&
                    (!authContext.isVerified || !authContext.isOnboarded))
                    ? toggle()
                    : props.onReplyClick(
                        reply.parentId,
                        reply.postAuthor.name,
                        reply.postAuthor.url,
                        reply.level
                      );
                }}
                isReply
                isDisabled={props.isReported}
              />
            </StyledBtn>
          </div>
          <Text
            size="12"
            css={{
              color: '$mediumGrey',
              whitespace: 'nowrap',
              '@bp1': { fontSize: '$14' },
            }}
          >
            {reply.commentDate}
          </Text>
        </CommentFooter>
        {props.type !== 'reply' && reply.allReplies ? (
          <RepliesContainer
            isVisible={
              !props.isReported
                ? reply.whichCommentExpended.includes(reply.commentId) ||
                  props.isMobile
                : null
            }
          >
            {reply.loading && !reply.firstFetchLoading && (
              <LoaderWrapper>
                <LoaderSpinner />
              </LoaderWrapper>
            )}
            {reply.total > reply.actualLimit ? (
              reply.loading && !reply.firstFetchLoading ? null : (
                <PreviousRepliesBtn
                  onClick={() => {
                    onPreviousRepliesClick(reply.commentId);
                  }}
                >
                  <String id="general_previous_replies_text" />
                </PreviousRepliesBtn>
              )
            ) : null}
            {reply.firstFetchLoading ? (
              <LoaderWrapper>
                <LoaderSpinner />
              </LoaderWrapper>
            ) : null}
          </RepliesContainer>
        ) : null}
      </CommentWrapper>
      <Modal isShowing={isReporting} hide={toggleReport}>
        <PendingPublicationsModal
          closeModal={toggleReport}
          postId={props.replyId}
          isReporting
          onReportClick={onReportClick}
        />
      </Modal>
      <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
        {!authContext.isVerified && authContext.isAuthenticated ? (
          <PublishNotification type={'validation'} />
        ) : !authContext.isOnboarded && authContext.isAuthenticated ? (
          <PublishNotification
            type={'onboarding'}
            url={'/m/' + authContext.userSlug}
          />
        ) : (
          <PublishNotification
            type={!authContext.isAuthenticated ? 'join' : 'joinGroup'}
            groupName={!authContext.isAuthenticated ? '' : props.groupName}
            onJoinClick={onJoinClick}
          />
        )}
      </Modal>
      <Modal isShowing={isDeleting} hide={toggleDelete}>
        <Text size="45" weight="bold" css={{ color: '$mainTextColor' }}>
          <String id="community_delete_comment_message" />
        </Text>
        <DeleteBtnsContainer>
          <Button
            type="normal"
            colorTheme="gray"
            label={cancelBtnText}
            actionType="onclick"
            onClick={toggleDelete}
          />
          <Button
            type="normal"
            colorTheme="normal"
            label={deleteBtnText}
            actionType="onclick"
            onClick={onDeleteClick}
            css={{ marginLeft: '$16' }}
          />
        </DeleteBtnsContainer>
      </Modal>
      <Modal isShowing={isEditing} hide={toggleEdit}>
        {props.userInfos?.modalProps?.modalProps?.modalProps ? (
          <CommunityAddPostModal
            {...props.userInfos?.modalProps?.modalProps?.modalProps}
            hide={toggleEdit}
            imagesProp={props.commentImages}
            groupId={props.groupId}
            parentId={props.parentId}
            content={props.postText}
            isEdit
            postId={props.replyId}
            isReply={true}
            closeMenus={closeMenus}
            replyParentId={props.replyParentId}
          />
        ) : (
          <CommunityAddPostModal
            {...props.userInfos?.modalProps?.modalProps}
            hide={toggleEdit}
            imagesProp={props.commentImages}
            groupId={props.groupId}
            parentId={props.parentId}
            content={props.postText}
            isEdit
            isComment
            postId={props.replyId}
            closeMenus={closeMenus}
            replyParentId={props.replyParentId}
          />
        )}
      </Modal>
    </CommentBoxWrapper>
  );
};

CommentReply.propTypes = {
  whichLiked: PropTypes.array,
  onShowRepliesClick: PropTypes.func,
  whichCommentExpended: PropTypes.array,
  onReplyClick: PropTypes.func,
  replyingToWhich: PropTypes.string,
  onLikeClick: PropTypes.func,
  whichLiked: PropTypes.array,
  replyingTo: PropTypes.string,
  artistProfilUrl: PropTypes.string,
  onSaveClick: PropTypes.func,
  isMobile: PropTypes.bool,
  avatarProps: PropTypes.object,
  artistName: PropTypes.string,
  artistRole: PropTypes.string,
  commentContent: PropTypes.string,
  commentDate: PropTypes.string,
  commentId: PropTypes.number,
  parentId: PropTypes.number,
  replies: PropTypes.array,
  userInfos: PropTypes.object,
  onDeleteClick: PropTypes.func,
};

export default CommentReply;
