import { styled } from '@root/stitches.config';

export const DatePickerInner = styled('div', {
  label: {
    marginBottom: '$4',
    display: 'inline-block',
    fontSize: '$14',
    fontFamily: '$ArtifaktElement',
    display: 'flex',
    alignContent: 'center',
    gridArea: 'label',
    marginRight: '$8',
    variants: {
      isError: {
        true: {
          color: '$red',
        },
      },
    },
  },
  '.date-picker-input-wrapper': {
    display: 'flex',
    svg: {
      width: '$24',
      height: '$24',
      alignSelf: 'center',
      position: 'absolute',
      right: '$8',
      pointerEvents: 'none',
    },
  },
  'input.with-calendar': {
    paddingRight: '$40',
  },
  '.react-datepicker__month-container': {
    fontFamily: '$ArtifaktElementRegular',
    fontSize: '$14',
    lineHeight: '1.18em',
  },
  '.react-datepicker-popper': {
    zIndex: '$max',
  },
  input: {
    padding: '$16 $24',
    width: '100%',
    fontFamily: '$ArtifaktElementRegular',
    background: 'transparent',
    fontSize: '$16',
    lineHeight: '1.18em',
    boxShadow: 'none',
    appearance: 'none',
    color: '$mainTextcolor',
    border: '1px solid $lightGrey',
    borderRadius: '$8',
    '::placeholder': {
      color: '$black',
    },
  },
  '.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text': {
    margin: '$8',
    width: '4rem',
    padding: '$8',
    '@bp1': {
      margin: '$4',
      width: '3rem',
      padding: '$4',
    },
  },

  '.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected': {
    backgroundColor: 'black',
  },

  'html body .react-datepicker__day--keyboard-selected:hover,html body .react-datepicker__month-text--keyboard-selected:hover,html body .react-datepicker__quarter-text--keyboard-selected:hover,html body .react-datepicker__year-text--keyboard-selected:hover': {
    backgroundColor: '$mediumGrey',
  },

  'html body .react-datepicker__day--selected:hover,html body .react-datepicker__day--in-selecting-range:hover,html body .react-datepicker__day--in-range:hover,html body .react-datepicker__month-text--selected:hover,html body .react-datepicker__month-text--in-selecting-range:hover,html body .react-datepicker__month-text--in-range:hover,html body .react-datepicker__quarter-text--selected:hover,html body .react-datepicker__quarter-text--in-selecting-range:hover,html body .react-datepicker__quarter-text--in-range:hover,html body .react-datepicker__year-text--selected:hover,html body .react-datepicker__year-text--in-selecting-range:hover,html body .react-datepicker__year-text--in-range:hover': {
    backgroundColor: 'black',
  },

  'html body .react-datepicker__day--keyboard-selected,html body .react-datepicker__month-text--keyboard-selected,html body .react-datepicker__quarter-text--keyboard-selected,html body .react-datepicker__year-text--keyboard-selected': {
    backgroundColor: 'black',
  },
  'html body .react-datepicker__day--keyboard-selected, html body .react-datepicker__month-text--keyboard-selected, html body .react-datepicker__quarter-text--keyboard-selected, html body .react-datepicker__year-text--keyboard-selected': {
    backgroundColor: 'black',
  },

  'html body .react-datepicker__day--selected,html body .react-datepicker__day--in-selecting-range,html body .react-datepicker__day--in-range,html body .react-datepicker__month-text--selected,html body .react-datepicker__month-text--in-selecting-range,html body .react-datepicker__month-text--in-range,html body .react-datepicker__quarter-text--selected,html body .react-datepicker__quarter-text--in-selecting-range,html body .react-datepicker__quarter-text--in-range,html body .react-datepicker__year-text--selected,html body .react-datepicker__year-text--in-selecting-range,html body .react-datepicker__year-text--in-range': {
    backgroundColor: 'black',
  },

  'html body .react-datepicker__day--selected:hover': {
    backgroundColor: 'black',
  },

  'html body .react-datepicker__day-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected': {
    borderRadius: '$4',
    backgroundColor: 'black',
  },
  '.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover': {
    backgroundColor: 'darkGrey',
    borderRadius: '$4',
  },
  '.react-datepicker__day.react-datepicker__day--in-range': {
    backgroundColor: 'black',
  },
  '.react-datepicker__day.react-datepicker__day--in-range:hover': {
    backgroundColor: '$darkGrey',
  },

  '.react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected': {
    backgroundColor: '$mediumGrey',
  },
  '.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover': {
    backgroundColor: '$darkGrey',
  },
  '.react-datepicker__header:not(.react-datepicker__header--has-time-select)': {
    paddingTop: '$16',
    paddingBottom: '$16',
    '@bp1': {
      paddingTop: '$8',
      paddingBottom: '$8',
    },
  },

  '.react-datepicker__month--in-range': {
    backgroundColor: '$black',
    ':hover': {
      backgroundColor: '$darkGrey',
    },
  },
});
