import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LineChart from './LineChart';
import {
  LineChartBlockWrapper,
  BlockHeader,
  InputsWrapper,
  BigStatWrapper,
} from './LineChartBlock.styled';
import DatePicker from '../datePicker/DatePicker';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import { Text } from '@components/atoms/text/Text';
import { Heading } from '@components/atoms/heading/Heading';
import { BigStatBlock } from './BigStatBlock';

const inputStyle = {
  color: '$lightGrey',
  input: {
    fontFamily: '$ArtifaktLegendBold',
    border: 'none',
    borderBottom: '2px solid $lightGrey',
    borderRadius: 0,
    paddingLeft: 0,
    paddingBottom: '$8',
  },
  svg: {
    fill: '$lightGrey',
    width: '20px',
  },
};

function LineChartBlock(props) {
  const { datas, bigStat, onChange } = props;

  const [compare, setCompare] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(datas.dataSets[1].datas[0].date)
  );
  const [endDate, setEndDate] = useState(
    new Date(datas.dataSets[1].datas[datas.dataSets[1].datas.length - 1].date)
  );

  return (
    <LineChartBlockWrapper>
      <BlockHeader>
        {bigStat.label !== undefined && bigStat.value !== undefined && (
          <BigStatWrapper>
            <Heading level="h2">{bigStat.value}</Heading>
            <Text as="p">{bigStat.label}</Text>
          </BigStatWrapper>
        )}
        <InputsWrapper>
          <div style={{ display: 'flex', width: '60%' }}>
            <DatePicker
              selected={startDate}
              onChange={date => {
                setStartDate(date);
                onChange({ startDate: date, endDate });
              }}
              selectsStart
              startDate={startDate}
              maxDate={new Date()}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              css={inputStyle}
              showCalendarIcon
              label=""
            />
            <Text
              css={{
                margin: '0 $16',
                alignSelf: 'flex-end',
                color: '$lightGrey',
                paddingBottom: '$8',
                fontFamily: '$ArtifaktLegendBold',
              }}
            >
              to
            </Text>
            <DatePicker
              selected={endDate}
              onChange={date => {
                setEndDate(date);
                onChange({ startDate, endDate: date });
              }}
              selectsEnd
              startDate={startDate}
              maxDate={new Date()}
              minDate={startDate}
              endDate={endDate}
              css={inputStyle}
              showCalendarIcon
              dateFormat="dd/MM/yyyy"
              label=""
            />
          </div>
          <InputCheckBox
            onChange={e => setCompare(e.target.checked)}
            checked={compare}
            css={{
              alignSelf: 'flex-end',
              paddingBottom: '$8',
              color: '$lightGrey',
              span: {
                fontSize: '$16',
                fontFamily: '$ArtifaktLegendBold',
              },
            }}
          >
            Compare
          </InputCheckBox>
        </InputsWrapper>
      </BlockHeader>
      <LineChart
        datas={datas}
        compare={compare}
        period={[startDate.getDay(), endDate.getDay()]}
      />
    </LineChartBlockWrapper>
  );
}

LineChartBlock.propTypes = {
  datas: PropTypes.shape({
    dataSets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        datas: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
  bigStat: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

LineChartBlock.defaultProps = {
  bigStat: {},
  onChange: () => {},
};

export default LineChartBlock;
