import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@root/stitches.config';

const Image = ({ src, alt, isBackground, className, onClick }) => {
  const ImageWrapper = styled('div', {
    display: 'block',
  });

  const StyledImage = styled('img', {
    position: isBackground ? 'absolute' : 'relative',
    display: 'block',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  });

  return (
    <ImageWrapper onClick={onClick}>
      <picture>
        {src?.source?.length &&
          src.source.map((item, i) => {
            return <source key={i} srcSet={item.srcset} media={item.media} />;
          })}

        <StyledImage
          className={className}
          srcSet={src?.src}
          alt={alt}
          loading="lazy"
        />
      </picture>
    </ImageWrapper>
  );
};

Image.propTypes = {
  // alt: PropTypes.string.isRequired,
  // src: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

Image.defaultProps = {
  onClick: () => {},
};

export default Image;
