import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';

const mainTextStyle = {
  color: 'white',
};

export const ArtworkInfoWrapper = styled('div', {
  padding: '$16',
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 2,
  transition: 'opacity .4s ease-out',
  '&:hover': {
    opacity: 0.7,
  },
  a: {
    color: 'white',
    textDecoration: 'none',
  },
  '.info_title': {
    textTransform: 'uppercase',
    fontSize: '$12',
    fontFamily: '$1',
  },
  '.info_name': {
    fontSize: '$16',
    lineHeight: '$1',
    fontFamily: '$ArtifaktLegendBold',
  },
});

export const StyledHeading1 = styled(Heading, {
  ...mainTextStyle,
  fontFamily: '$ArtifaktLegendExtraBold',
  fontSize: '$60',
  lineHeight: '$1',
  '@bp3': {
    '&.normal': {
      fontSize: '$92',
    },
    '&.medium': {
      fontSize: '$152',
      letterSpacing: '-0.02em',
    },
    '&.large': {
      fontSize: '$204',
    },
  },
});

export const StyledHeading4 = styled(Heading, {
  ...mainTextStyle,
  fontFamily: '$ArtifaktElementRegular',
  fontSize: '$18',
  marginTop: '$8',
  lineHeight: '$2',
  '@bp3': {
    fontSize: '$24',
    // fontFamily: '$ArtifaktElementBold',
  },
});

export const StyledButton = styled(Button, {
  width: 'fit-content',
  '&:not(:first-of-type)': {
    marginTop: '$16',
  },
  '@bp2': {
    '&:not(:first-of-type)': {
      marginTop: '0',
      marginLeft: '$16',
    },
  },
});

export const StyledText = styled(Text, {
  ...mainTextStyle,
});

export const HeroWrapper = styled('div', {
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  mb: '$32',

  '@bp2': {
    mb: '$48',
  },

  '&::before': {
    content: '""',
    width: '100%',
    height: '100%',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
    zIndex: 1,
  },
  '.pageHero_text_wrapper': {
    '> p': {
      display: 'none',
      '@bp1': {
        display: 'block',
      },
    },
    '@bp1': {
      marginTop: '$12',
      maxWidth: '410px',
    },
  },
  '.pageHero_text_wrapper.fullWidth': {
    maxWidth: '100%',
  },
  '> .image-wrapper': {
    width: '100%',
    height: '100%',
    position: 'absolute !important',
    zIndex: '0',
    left: 0,
    right: 0,
    img: {
      objectFit: 'cover',
    },
  },
  minHeight: '430px',
  '@bp3': {
    maxWidth: '100%',
    minHeight: '560px',
  },
  '.pageHero_content_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    px: '$16',
    zIndex: 2,
    margin: '0 auto',
    width: '100%',
    maxWidth: '$container',

    '@bp2': {
      px: '$32',
    },

    '@bp5': {
      padding: '0px',
    },
  },
  '.pageHero_actions_wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '$24',
    '@bp2': {
      marginTop: '$40',
      flexDirection: 'row',
    },
  },
  variants: {
    hasArtworkInfo: {
      true: {
        '&::after': {
          content: "''",
          width: '100%',
          height: '72px',
          background:
            'linear-gradient(180deg, rgba(31, 31, 31, 0) 0%, #1F1F1F 100%)',
          position: 'absolute',
          bottom: '0',
          opacity: 0.7,
          left: '0',
        },
      },
    },
  },
});
