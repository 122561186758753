import { useEffect, useState } from 'react';

import { validURL } from '@src/utils/validURL';

export function useCMSPageUpdateValidation(herodata) {
  const {
    title,
    description,
    image,
    btnLabel,
    btnUrl,
    artistCredits,
  } = herodata;
  const [titleError, setTitleError] = useState({});
  const [descriptionError, setDescriptionError] = useState({});
  const [imageError, setImageError] = useState({});
  const [btnUrlError, setBtnUrlError] = useState({});
  const [artistCreditsUrlError, setArtistCreditsUrlError] = useState({});

  // useEffect(() => {
  //   if(title !== undefined && (title === '' || title === null)){
  //     setTitleError({
  //       titleIsError: true,
  //       titleErrorMessage: 'cms_empty_title_field',
  //     })
  //   } else {
  //     setTitleError({titleIsError: false})
  //   }
  // }, [title]);

  // useEffect(() => {
  //   if(description !== undefined && (description === '' || description === null)){
  //     setDescriptionError({
  //       descriptionIsError: true,
  //       descriptionErrorMessage: 'cms_empty_descritpion_field',
  //     })
  //   } else {
  //     setDescriptionError({descriptionIsError: false})
  //   }
  // }, [description]);

  // useEffect(() => {
  //   if(image !== undefined && (image !== null && !image.data_url)){
  //     setImageError({
  //       imageIsError: true,
  //       imageErrorMessage: 'cms_no_banner_image'
  //     })
  //   } else {
  //     setImageError({imageIsError: false})
  //   }
  // }, [image]);

  useEffect(() => {
    if (artistCredits) {
      const hasArtistName =
        artistCredits?.name !== undefined &&
        artistCredits?.name !== null &&
        artistCredits?.name !== '';
      const hasArtistUrl =
        artistCredits?.url !== undefined &&
        artistCredits?.url !== null &&
        artistCredits?.url !== '';
      if (hasArtistName && !hasArtistUrl) {
        setArtistCreditsUrlError({
          artistCreditsUrlIsError: true,
          artistCreditsUrlErrorMessage: 'cms_no_credits_url_with_credits_name',
        });
      } else {
        if (hasArtistUrl && !validURL(artistCredits.url)) {
          setArtistCreditsUrlError({
            artistCreditsUrlIsError: true,
            artistCreditsUrlErrorMessage: 'cms_invalid_url',
          });
        } else {
          setArtistCreditsUrlError({
            artistCreditsUrlIsError: false,
          });
        }
      }
    }
  }, [artistCredits]);

  useEffect(() => {
    const hasBtnLabel =
      btnLabel !== undefined && btnLabel !== null && btnLabel !== '';
    const hasBtnUrl = btnUrl !== undefined && btnUrl !== null && btnUrl !== '';
    if (hasBtnLabel && !hasBtnUrl) {
      setBtnUrlError({
        btnUrlIsError: true,
        btnUrlErrorMessage: 'cms_no_url_with_btnUrl_label',
      });
    } else {
      if (hasBtnUrl && !validURL(btnUrl)) {
        setBtnUrlError({
          btnUrlIsError: true,
          btnUrlErrorMessage: 'cms_invalid_url',
        });
      } else {
        setBtnUrlError({ btnUrlIsError: false });
      }
    }
  }, [btnUrl]);

  return {
    ...titleError,
    ...descriptionError,
    ...imageError,
    ...artistCreditsUrlError,
    ...btnUrlError,
  };
}
