import Button from '@components/atoms/button/Button';
import { styled } from '@root/stitches.config';

export const StepsWrapper = styled('div', {
  height: '100%',
  paddingTop: '60px',
  '@bp2': {
    paddingTop: '150px',
  },
});

export const SectionNavWrapper = styled('div', {
  display: 'block',
  height: '2px',
});

export const OnboardingWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  width: '100%',
  minHeight: '0',
  '@bp2': {
    gridTemplateColumns: '1fr 2fr',
    minHeight: '50vh',
  },
});

export const ContentWrapper = styled('div', {
  overflow: 'hidden',
  padding: '$48 $16',
  width: '100%',
  height: '100%',

  '@bp2': {
    padding: '$92 $72',
    // display: 'grid',
    // alignItems: 'center',
    // flexDirection: 'column',
    // gridTemplateRows: '1fr 24px',
    display: 'flex',
    position: 'relative',

    alignItems: 'center',
    maxWidth: '1240px',
  },
});

export const ContentInner = styled('div', {
  width: '100%',
  maxWidth: '800px',
  display: 'flex',
  // alignItems: 'center',
  flexDirection: 'column',
  marginBottom: '$48',
});

export const SkipOnboarding = styled(Button, {
  maxWidth: '300px',
});
export const SkipOnboardingWrapper = styled('div', {
  display: 'block',
});

export const TitleWrapper = styled('div', {
  marginBottom: '$24',
  '@bp2': {
    marginBottom: '$48',
  },
  button: {
    paddingLeft: '0px',
    marginBottom: '$16',
  },
});
