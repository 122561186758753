import Logo from '@src/assets/svg/area_logo_full.svg';
import { styled } from '@root/stitches.config';

export const ActionText = styled('span', {
  textDecoration: 'underline',
  fontSize: '$14',
  lineHeight: '2.5em',
  fontFamily: 'var(--ArtifaktElementRegular)',
  cursor: 'pointer',
  transition: 'opacity .4s ease',
  '&:hover': {
    opacity: 0.6,
  },
});

export const ContentWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  ai: 'center',
  padding: '$72 $16',

  '@bp2': {
    minHeight: '600px',
  },
  '@bp3': {
    minHeight: '800px',
  },
});

export const AreaLogo = styled(Logo, {
  mt: '$24',
  height: '64px',
  width: '125px',
  fill: '#000',
});

export const BoxMessage = styled('div', {
  fontSize: '$14',
  lineHeight: '2.5em',
});
