import { DatePickerWrapper, ModalControlsWrapper } from './resumeEdit.styled';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import Button from '@components/atoms/button/Button';
import DatePicker from '@components/molecules/datePicker/DatePicker';
import { Heading } from '@components/atoms/heading/Heading';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import InputGooglePlacesLocation from '@components/atoms/input/inputGooglePlacesLocation/InputGooglePlacesLocation';
import { InputText } from '@components/atoms/input/inputText/InputText';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { convertUTCToLocalDate } from '@src/utils/datesHelper';
import { format } from 'date-fns';
import { useIsMobile } from '@src/utils/useIsMobile';
import { UPDATE_WORK_EXPERIENCE } from '@src/apollo/mutation';

const WorkExperienceEdit = props => {
  const [workExperienceState, setWorkExperienceState] = useState({
    name: props.data === undefined ? '' : props.data.name,
    job_title: props.data === undefined ? '' : props.data.job_title,
    location: props.data === undefined ? '' : props.data.location,
    locationFull:
      props.data === undefined
        ? ''
        : props.data.name + ', ' + props.data.locationFull,
    is_current: props.data === undefined ? false : props.data.is_current,
    locationPlaceId:
      props.data === undefined ? '' : props.data.location.placeId,
  });

  const isMobile = useIsMobile();

  const currentList = [...props.list];
  const newCurrentList = [];
  currentList.map(project => {
    const newProjectObj = { ...project };

    delete newProjectObj.name;
    delete newProjectObj.locationFull;
    delete newProjectObj.image;
    newCurrentList.push(newProjectObj);
  });

  const [startDate, setStartDate] = useState(
    props.data === undefined
      ? new Date()
      : convertUTCToLocalDate(props.data.dateStart)
  );
  const [endDate, setEndDate] = useState(
    props.data === undefined
      ? new Date()
      : convertUTCToLocalDate(props.data.dateEnd)
  );

  const [updateWorkExperience, { loading, error, data }] = useMutation(
    UPDATE_WORK_EXPERIENCE,
    {
      onCompleted: () => {
        props.onClose();
        props.onSave();
      },
    }
  );

  const [errorState, setErrorState] = useState({
    isFormError: false,
    isJobTitleError: false,
    isLocationError: false,
    isNameError: false,
  });

  const handleValidation = () => {
    const isJobTitleError = workExperienceState.job_title == '';
    const isLocationError = workExperienceState.location == '';
    const isNameError = workExperienceState.name == '';
    if (isJobTitleError || isLocationError) {
      setErrorState({
        ...errorState,
        isFormError: true,
        isJobTitleError,
        isLocationError,
        isNameError,
      });
      return false;
    }
    setErrorState({
      ...errorState,
      isFormError: false,
      isJobTitleError: false,
      isLocationError: false,
      isNameError: false,
    });
    return true;
  };

  const onChange = e => {
    const { value } = e.target;
    const { name } = e.target;
    const updatedState = { ...workExperienceState };

    updatedState[name] = value;
    setWorkExperienceState(updatedState);
  };

  const onLocationChange = data => {
    const { country } = data;
    const { place_id } = data;
    const { label } = data;
    const updatedState = { ...workExperienceState };
    updatedState.location = country;
    updatedState.locationPlaceId = place_id;
    updatedState.locationFull = label;

    setWorkExperienceState(updatedState);
  };

  const onCompanyChange = data => {
    const name = data.label;
    const id = !data.id === undefined ? data.id : null;
    const updatedState = { ...workExperienceState };
    updatedState.name = name;
    !data.id === undefined ? (updatedState.id = id) : null;
    setWorkExperienceState(updatedState);
  };

  const updateOldItem = () => {
    const newArr = [...newCurrentList];

    newArr[props.id] = {
      name: workExperienceState.name,
      order: props.id,
      job_title: workExperienceState.job_title,
      dateStart: format(startDate, 'yyyy-MM-dd'),
      dateEnd: format(endDate, 'yyyy-MM-dd'),
      is_current: workExperienceState.is_current,
      location: {
        placeId: workExperienceState.locationPlaceId,
      },
    };

    return newArr;
  };

  const deleteItem = () => {
    const newArr = [...newCurrentList];
    newArr.splice(props.id, 1);
    newArr.map((item, index) => {
      item.order = index;
    });

    return newArr;
  };

  const createNewItem = [
    ...newCurrentList,
    {
      name: workExperienceState.name,
      order: props.order,
      job_title: workExperienceState.job_title,
      dateStart: format(startDate, 'yyyy-MM-dd'),
      dateEnd: format(endDate, 'yyyy-MM-dd'),
      is_current: workExperienceState.is_current,
      location: {
        placeId: workExperienceState.locationPlaceId,
      },
    },
  ];

  const onSave = () => {
    if (handleValidation()) {
      updateWorkExperience({
        variables: {
          companies: props.data === undefined ? createNewItem : updateOldItem(),
        },
      });
    }
  };

  const onDelete = () => {
    updateWorkExperience({
      variables: {
        companies: props.data === undefined ? createNewItem : deleteItem(),
      },
    });
  };

  const onCurrentJobChange = e => {
    setWorkExperienceState({
      ...workExperienceState,
      is_current: e.currentTarget.checked,
    });
  };

  return (
    <div>
      <Heading
        level="h3"
        weight="legendBold"
        css={{ lineHeight: '1.3em', marginBottom: '$24' }}
      >
        <String id="profile_resume_companies_title" />
      </Heading>

      <InputText
        inputShape="normal"
        inputTheme="white"
        name="name"
        css={{ marginBottom: '$16' }}
        value={workExperienceState.name}
        onChange={onChange}
        label={<String id="profile_resume_companies_input_label_title" />}
        error={
          <String id="profile_resume_companies_input_title_create_error" />
        }
        isError={errorState.isNameError}
        isRequired
      />
      <InputGooglePlacesLocation
        onLocationChange={onLocationChange}
        location={workExperienceState.location}
        locationFull={workExperienceState.locationFull}
        error={<String id="profile_resume_input_label_location_error" />}
        isError={errorState.isLocationError}
        required
        autoCompleteRequestTypes={['(cities)']}
        css={{ mb: '$16' }}
        label="profile_resume_projects_gplaces_input_label"
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="job_title"
        label={<String id="profile_resume_input_label_jobTitle" />}
        css={{ marginBottom: '$32' }}
        value={workExperienceState.job_title}
        onChange={onChange}
        error={<String id="profile_resume_input_label_jobTitle_error" />}
        isError={errorState.isJobTitleError}
        isRequired
      />
      <InputCheckBox
        css={{ marginBottom: '$32' }}
        checked={workExperienceState.is_current}
        error="error"
        inputTheme="transparent"
        onChange={onCurrentJobChange}
      >
        <String id="profile_resume_input_label_isCurrent" />
      </InputCheckBox>
      <DatePickerWrapper>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          withPortal={isMobile}
          label="profile_resume_input_label_from"
        />
        {!workExperienceState.is_current ? (
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            withPortal={isMobile}
            label="profile_resume_input_label_to"
          />
        ) : (
          <div>
            <label>
              <String id="profile_resume_input_label_to" />
            </label>
            <Text
              css={{
                marginTop: '$16',
                color: '$darkGrey',
              }}
            >
              <String id="profile_resume_input_label_today" />
            </Text>
          </div>
        )}
      </DatePickerWrapper>
      <ModalControlsWrapper>
        <div>
          <Button
            type="normal"
            label="Cancel"
            colorTheme="gray"
            onClick={props.onClose}
            actionType="onclick"
            css={{ marginRight: '$16' }}
          />
          <Button
            type="normal"
            label="Save"
            colorTheme="normal"
            onClick={onSave}
            actionType="onclick"
            isLoading={loading}
            isDisabled={loading}
          />
        </div>
        <div>
          {props.data === undefined ? null : (
            <Button
              type="normal"
              label="Delete"
              colorTheme="transparent"
              onClick={onDelete}
              actionType="onclick"
              css={{ textTransform: 'capitalize' }}
            />
          )}
        </div>
      </ModalControlsWrapper>
    </div>
  );
};

export default WorkExperienceEdit;
