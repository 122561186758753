import PropTypes from 'prop-types';
import React from 'react';
import sprite from '@root/static/svg/sprite.svg';
import { styled } from '@root/stitches.config';

const SvgContainer = styled('svg', {
  display: 'inline-block',
});

const Svg = props => {
  return (
    <SvgContainer
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      width="100"
      height="100"
      {...props}
    >
      <use href={`${sprite}#${props.icon}`} />
    </SvgContainer>
  );
};

Svg.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
};

Svg.defaultProps = {
  icon: 'search',
};

export default Svg;
