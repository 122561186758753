import { styled } from '@root/stitches.config';

export const FormStyled = styled('form', {
  marginTop: '$24',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@bp2': {
    alignItems: 'flex-start',
  },
});

export const ErrorMsg = styled('p', {
  marginBottom: '$24',
  color: '$red',
  fontSize: '$12',
  textAlign: 'left',
});
export const InputWrapper = styled('div', {
  width: '100%',
});

export const SubmitBtnWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '$24',
});
