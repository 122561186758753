import { Heading } from '@components/atoms/heading/Heading';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import PropTypes from 'prop-types';
import React from 'react';

import {
  SmallStatCenteredBlockWrapper,
  Percentage,
} from './SmallStatCenteredBlock.styled';

export const SmallStatCenteredBlock = props => {
  const { stat, text, percentage, percentageColor } = props;
  return (
    <SmallStatCenteredBlockWrapper>
      <Heading
        level="h3"
        css={{
          color: '$white',
          marginBottom: '$8',
          lineHeight: '1.2em',
          position: 'relative',
        }}
      >
        {stat}
        <Percentage css={{ color: `$${percentageColor}` }}>
          {percentage}
        </Percentage>
      </Heading>
      <Text size="18" css={{ maxWidth: '230px', textAlign: 'center', color: '$white' }}>
        {text}
      </Text>
    </SmallStatCenteredBlockWrapper>
  );
};

SmallStatCenteredBlock.propTypes = {
  stat: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  percentageColor: PropTypes.string.isRequired,
};
