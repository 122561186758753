import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const GroupListingWrapper = styled('div', {
  padding: '$16 0px $54',
  minHeight: '500px',

  '@bp1': {
    padding: '$48 0px $84',
  },
});

export const Listing = styled('div', {
  display: 'grid',
  gap: '$32',
  marginBottom: '$64',
  gridTemplateColumns: 'repeat(1, 1fr)',

  '@bp0': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp2': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  '& > div': {
    marginBottom: '-$12',

    '@bp1': {
      marginBottom: '0',
    },
  },
});

export const StyledText = styled(Text, {
  marginTop: '$32',
});

export const LoaderWrapper = styled('div', {
  marginTop: '$32',
  display: 'flex',
  justifyContent: 'center',
});
