import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import { SignUpBoxWrapper } from './SignUpValidation.styled';
import String from '@components/atoms/string/String';
import UrlsContext from '@context/UrlsContext';
import UserPhoneVerification from '@components/organisms/signUpBox/userSignUpVerification/UserPhoneVerification';
import { navigate } from 'gatsby';

const SignUpPageValidationPage = ({ roles }) => {
  const authContext = useContext(AuthContext);
  const UrlContext = useContext(UrlsContext);
  const [onboardingCompletionState, setOnboardingCompletionContext] = useState([
    {
      title: <String id="sign_up_page_steps_1" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_2" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_3" />,
      isActive: true,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_4" />,
      isActive: false,
      isCompleted: false,
    },
    {
      title: <String id="sign_up_page_steps_5" />,
      isActive: false,
      isCompleted: false,
    },
  ]);

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    navigate(`/m/${authContext.userSlug}`);
  } else if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    !authContext.isOnboarded
  ) {
    navigate(GetUrl('onboardingAvatarAndBio', UrlContext));
  }

  const pageTitle = (
    <>
      👋 <String id="sign_up_validation_welcome" />
    </>
  );

  return (
    <ProtectedRoute
      redirectTo={'/login'}
      userAccessReq={[
        'auth',
        authContext.generalRole,
        roles || ['member', 'contributor', 'editor', 'admin'],
      ]}
    >
      <OnboardingCompletionContext.Provider
        value={[onboardingCompletionState, setOnboardingCompletionContext]}
      >
        <OnboardingLayout
          pageTitle={pageTitle}
          sideBarTitle={<String id="sign_up_validation_sideBarTitle" />}
          displaySteps
          displayAvatar
        >
          <SignUpBoxWrapper>
            <UserPhoneVerification />
          </SignUpBoxWrapper>
        </OnboardingLayout>
      </OnboardingCompletionContext.Provider>
    </ProtectedRoute>
  );
};

export default SignUpPageValidationPage;
