import { useEffect, useState }  from 'react';

export function useTransitionMount(isMounted, unmountDelay) {
  const [hasTransitioned, setHasTransitioned] = useState(false);

  useEffect(() => {
    let timeout;

    if (isMounted && !hasTransitioned) {
      setHasTransitioned(true);
    } else if (!isMounted && hasTransitioned) {
      timeout = setTimeout(() => setHasTransitioned(false), unmountDelay);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [unmountDelay, isMounted, hasTransitioned]);

  return hasTransitioned;
}