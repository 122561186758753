import { styled } from '@root/stitches.config';

export const SocialAuthBoxWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: 'auto',
  marginBottom: '$24',
  alignItems: 'center',
  '@bp2': {
    margin: '0',
    marginBottom: '$24',
    alignItems: 'center',
  },
});
