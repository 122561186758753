import { BlockVideoErrorWrapper, BlockVideoWrapper } from './BlockVideo.styled';
import React, { useEffect, useRef, useState } from 'react';

import AddEmbed from '../../blockModals/AddEmbed';
import Button from '@components/atoms/button/Button';
import { EditorLoadingWrapper } from '../EditorBlock.styled';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import Modal from '@components/atoms/modal/Modal';
import ReactPlayer from 'react-player';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import useModal from '@components/atoms/modal/useModal';

const BlockVideo = props => {
  const { isShowing, toggle } = useModal();

  const [blockVideoState, setBlockVideoState] = useState({
    offsetWidth: 0,
    isError: false,
    error:
      'The url you entered is invalid, please enter a Youtube or Vimeo url.',
  });
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setBlockVideoState({
        ...blockVideoState,
        offsetWidth: ref.current.offsetWidth,
      });
    }
  }, [ref.current]);

  const onBlockSave = (type, data) => {
    toggle();
    setTimeout(() => {
      props.onEditVideoBlock(props.blockIndex, {
        type,
        data: { videoUrl: data },
      });
      setBlockVideoState({
        ...blockVideoState,
        isError: false,
      });
    }, 100);
  };

  return (
    <BlockVideoWrapper ref={ref}>
      {props.block.isLoading && (
        <EditorLoadingWrapper>
          {/* <Text as="p" size="12">
            <String id="create_block_loading_text" />
          </Text> */}
          <LoaderSpinner />
        </EditorLoadingWrapper>
      )}
      {!blockVideoState.isError ? (
        <ReactPlayer
          width="100%"
          height={
            blockVideoState.offsetWidth ? blockVideoState.offsetWidth * 0.56 : 0
          }
          url={props.block.videoUrl}
          controls
          onError={error => {
            setBlockVideoState({
              ...blockVideoState,
              isError: true,
            });
          }}
        />
      ) : (
        <>
          <BlockVideoErrorWrapper>
            <Text>{blockVideoState.error}</Text>
            <Button
              type="normal"
              colorTheme="normal"
              svgIcon="image"
              actionType="onclick"
              label="Change video url"
              css={{ marginBottom: '$16', marginTop: '$32' }}
              onClick={toggle}
            />
          </BlockVideoErrorWrapper>
          <Modal isShowing={isShowing} hide={toggle}>
            <AddEmbed onClose={toggle} onSave={onBlockSave} type="video" />
          </Modal>
        </>
      )}
    </BlockVideoWrapper>
  );
};

export default BlockVideo;
