import { styled } from '@root/stitches.config';

export const PolarGraphBlockWrapper = styled('div', {
  width: '100%',
});
export const PolarGraphHeader = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$16',
});
export const GraphContainer = styled('div', {
  display: 'flex',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '16px',
  padding: '10px',
});
