import { styled } from '@root/stitches.config';

export const FullBackgroundTitle = styled('div', {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  svg: {
    width: '180px',
    height: '100px',
    fill: 'white',
  },
});

export const FullBackgroundContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  zIndex: '2',
});

export const FullBackgroundWrapper = styled('div', {
  padding: '$24',
  padding: '$24',
  height: '100%',
  position: 'absolute',
  top: '0',
  bottom: '0',
  width: '100%',
});

export const FullBackgroundImage = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
