import { styled } from '@root/stitches.config';
import { Text } from '../text/Text';

export const PaginationWrapper = styled('div', {
  textAlign: 'center',
  alignSelf: 'center',
  ul: {
    display: 'flex',
    alignItems: 'center',

    li: {
      listStyleType: 'none',
      textAlign: 'center',
      fontSize: '$14',
    },

    a: {
      textDecoration: 'none',
      color: 'unset',
      fontSize: '$14',
    },
  },
});

export const NextPrevBtn = styled('li', {
  height: '$24',
  marginRight: '$16',
  color: '$blackTowWhite',

  '&:last-of-type': {
    marginRight: '0',
  },

  padding: '0',
  borderRadius: '$8',
  fontFamily: '$ArtifaktLegendBold',
  transition: 'color .3s ease',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },

  bp2: {
    marginRight: '$24',
    color: '$mainTextColor',

    padding: '$12 $20',
    backgroundColor: '$darkGrey',
  },

  variants: {
    isDisabled: {
      true: {
        opacity: '0.5',
        '&:hover': {
          cursor: 'initial',
          textDecoration: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

export const PagesBtn = styled('li', {
  position: 'relative',
  height: '$24',
  padding: '0',
  marginRight: '$16',
  border: 'none',
  color: '$mainTextColor',
  fontFamily: '$ArtifaktLegendBold',
  backgroundColor: 'unset',
  cursor: 'pointer',

  bp2: {
    marginRight: '$24',
  },

  '&:before': {
    content: '""',
    display: 'block',
    width: '44px',
    height: '44px',
    backgroundColor: '$blackToWhite',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0)',
    zIndex: '0',
  },

  '&:hover': {
    color: '$mainTextColor',
    textDecoration: 'underline',
  },

  a: {
    position: 'relative',
    display: 'block',
    width: 'fit-content',
    height: '$24',
  },

  variants: {
    active: {
      true: {
        marginRight: '$32',
        marginLeft: '$16',
        color: '$whiteToBlack',
        '&:hover': {
          color: '$whiteToBlack',
        },

        '&:before': {
          transform: 'translate(-50%, -50%) scale(1)',
        },
      },
    },

    backwards: {
      true: {},
    },

    forwards: {
      true: {},
    },
  },
});

export const TextActive = styled(Text, {
  position: 'relative',
  display: 'block',
  width: '$16',
  height: '$24',
});

export const ThreeDots = styled('li', {
  color: '$mainTextColor',
  marginRight: '$16',

  '@bp2': {
    marginRight: '$24',
  },
});
