import { CodeInputWrapper, InputNumber } from './UserPhoneVerification.styled';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import { NOTIFICATIONS_ADD_USER_DEVICE } from '@src/apollo/mutation';
import { NotificationsContext } from '@context/NotificationsContext';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';

// import { getFCMToken, getFirebase } from '@src/utils/firebaseInit';

const SmsCodeVerificationForm = () => {
  const [smsCodeVerifyFormState, setSmsCodeVerifyFormState] = useState({
    smsCode: ['', '', '', '', '', ''],
    isLoading: false,
    currentSmsCaracter: 0,
  });

  const UrlContext = useContext(UrlsContext);

  const handleAuthError = response => {
    switch (response.error) {
      case 'the_code_field_is_required':
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_sms_error_required" />,
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;

      case 'phone_wrong_code_verification':
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_sms_error_wrong_code" />,
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;

      case 'the_code_must_be_a_string':
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_sms_error_not_string" />,
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;

      case 'no_verification_ongoing':
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: (
            <String id="verify_sms_error_no_verification_ongoing" />
          ),
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;

      case 'phone_code_verification_error':
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_sms_error_generic" />,
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;

      case 'phone_wrong_code_verification':
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_sms_error_wrong_code" />,
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;
      case ' phone_code_verification_try_again':
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_sms_error_expired" />,
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;

      default:
        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_sms_error_generic" />,
          isLoading: false,
          smsCodeErrorMessage: null,
        });
        break;
    }
  };

  const handleAuthSuccess = response => {
    if (response.success) {
      setSmsCodeVerifyFormState({
        ...smsCodeVerifyFormState,
        isLoading: false,
        isAuthError: false,
        authErrorMessage: null,
        isLoading: false,
        smsCodeErrorMessage: null,
      });

      navigate(GetUrl('onboardingAvatarAndBio', UrlContext));
    } else {
      handleAuthError(response);
    }
  };

  const handleSMSCodeChange = (i, e) => {
    let smsCodes = [...smsCodeVerifyFormState.smsCode];
    smsCodes[i] = e.currentTarget.value;
    setSmsCodeVerifyFormState({
      ...smsCodeVerifyFormState,
      smsCode: smsCodes,
      currentSmsCaracter: smsCodeVerifyFormState.currentSmsCaracter + 1,
    });
  };

  const handleFormSubmit = (e, authSmsCodeVerificationFn) => {
    e.preventDefault();

    setSmsCodeVerifyFormState({
      ...smsCodeVerifyFormState,
      isAuthError: false,
      isLoading: true,
    });
    const code = smsCodeVerifyFormState.smsCode.join('');

    authSmsCodeVerificationFn({
      smsCode: code,
      errorCallback: handleAuthError,
      successCallback: handleAuthSuccess,
    });
  };

  useEffect(() => {
    window.addEventListener('paste', function (e) {
      if (e.target.type === 'text') {
        var data = e.clipboardData.getData('Text');
        data = data.split('');

        setSmsCodeVerifyFormState({
          ...smsCodeVerifyFormState,
          smsCode: data,
          currentSmsCaracter: smsCodeVerifyFormState.currentSmsCaracter + 1,
        });

        //let smsCodes = [...smsCodeVerifyFormState.smsCode];
        // [].forEach.call(
        //   document.querySelectorAll('input[type=text]'),
        //   (node, index) => {
        //     console.log(node);
        //     node.value = data[index];
        //     smsCodes[index] = node.value;
        //     setSmsCodeVerifyFormState({
        //       ...smsCodeVerifyFormState,
        //       smsCode: smsCodes,
        //       currentSmsCaracter: smsCodeVerifyFormState.currentSmsCaracter + 1,
        //     });
        //   }
        // );
        e.preventDefault();
      }
    });
  }, [InputNumber]);

  return (
    <>
      <AuthContext.Consumer>
        {auth => (
          <form
            onSubmit={e => handleFormSubmit(e, auth.verifySmsCode)}
            style={{
              opacity: smsCodeVerifyFormState.isLoading ? 0.3 : 1,
            }}
          >
            <CodeInputWrapper>
              {smsCodeVerifyFormState.smsCode.map((cara, i) => (
                <InputNumber
                  autoFocus={i == 0 ? true : false}
                  type="text"
                  onChange={handleSMSCodeChange.bind(null, i)}
                  value={smsCodeVerifyFormState.smsCode[i]}
                  style={{ marginBottom: '$24' }}
                  maxLength="1"
                  key={i}
                  ref={input => {
                    if (
                      input &&
                      smsCodeVerifyFormState.currentSmsCaracter == i
                    ) {
                      input.focus();
                    }
                  }}
                ></InputNumber>
              ))}
            </CodeInputWrapper>
            <br />
            {smsCodeVerifyFormState.isAuthError ? (
              <Text css={{ color: '$red', marginTop: '$12' }}>
                {smsCodeVerifyFormState.authErrorMessage}
              </Text>
            ) : null}
            <div
              style={{
                marginTop: '24px',
              }}
            >
              <Button
                value="Submit"
                type="normal"
                label={<String id="verify_sms_code_button" />}
                colorTheme="normal"
                notificationBubbleActive={false}
                actionType="onclick"
                isLoading={smsCodeVerifyFormState.isLoading}
                isDisabled={smsCodeVerifyFormState.isLoading}
                css={{
                  width: '100%',
                  marginBottom: '$24',
                }}
              />
              Didn't get the code?{' '}
              <Link
                to="/onboarding/signup-validation"
                css={{
                  weight: 'bold',
                }}
              >
                Try again
              </Link>
            </div>
          </form>
        )}
      </AuthContext.Consumer>
    </>
  );
};

const UserSmsCodeVerification = props => {
  return (
    <div>
      <Text size="14" css={{ lineHeight: '1.5em', marginBottom: '$24' }}>
        <String id="verify_sms_code_text" />
      </Text>
      <SmsCodeVerificationForm />
    </div>
  );
};

export default UserSmsCodeVerification;
