import Button from '@components/atoms/button/Button';
import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

export const Background = styled('div', {
  position: 'relative',
  height: '60px',
  width: '100%',
  backgroundColor: '$headerBlack',
  mb: '$54',
});

export const SearchBarWrapper = styled('div', {
  zIndex: '100',
  position: 'absolute',
  marginX: 'auto',
  top: '25px',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 40px)',
  borderRadius: '$8',
  backgroundColor: '$white',
  left: '50%',
  transform: 'translateX(-50%)',

  '.dark-mode &': {
    backgroundColor: '$altMediumGrey',
  },

  '@bp1': {
    width: '100%',
    maxWidth: '800px',
  },
});

export const StyledInput = styled('input', {
  width: '100%',
  border: 'none',
  padding: '0 $16',
  boxSizing: 'border-box',
  height: '$40',
  outline: 'none',
  fontFamily: '$ArtifaktLegendRegular',
  color: '$mainTextColor',
  backgroundColor: 'unset',
  fontSize: '$16',

  '@bp1': {
    fontSize: '$24',
  },

  '::placeholder': {
    color: '$darkGrey',
  },
});

export const LinkStyled = styled(Link, {
  height: '$32',
  width: '$32',
  minWidth: 'unset',
  minHeight: 'unset',
  position: 'absolute',
  left: '$16',
  zIndex: '1',
  marginRight: '15px',
});

export const StyledButton = styled(Button, {
  height: '$32',
  width: '$32',
  minWidth: 'unset',
  minHeight: 'unset',
  left: '$16',
  zIndex: '1',
  marginRight: '15px',
});

export const SearchSection = styled('div', {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  zIndex: '1',
  '@bp1': {
    borderRadius: '$8',
    boxShadow: '$2',
  },

  variants: {
    filtersOpened: {
      true: {
        '@bp1': {
          borderRadius: '$8 $8 0 $8',
        },
      },
    },
  },
});

export const ClearButton = styled(Button, {
  padding: '0',
  height: '$32',
  width: '$32',
  minWidth: 'unset',
  minHeight: 'unset',
  display: 'none',
  flexShrink: '0',

  '@bp1': {
    marginRight: '$8',
  },
  ':hover': {
    opacity: '.5',
    svg: {
      fill: 'rgba(0,0,0,.9)',
    },
  },

  svg: {
    fill: '$darkGrey',
  },

  variants: {
    isVisible: {
      true: {
        display: 'block',
      },
    },
  },
});

export const SearchWrapper = styled('div', {});

export const StyledDropDown = styled('div', {
  position: 'relative',
  width: '160px',
  flexShrink: '0',

  '@bp1': {
    display: 'block',
  },

  ul: {
    width: '159px',
    left: '1px',
    top: '44px',
    borderTop: '1px solid $lightGrey',
    maxHeight: 'unset',

    '.active-drowpdown-item': {
      color: '$mainTextColor',
    },

    li: {
      textAlign: 'left',
      paddingLeft: '$28',
      color: '$darkGrey',

      '&:hover': {
        color: '$mainTextColor',
        backgroundColor: 'unset',
      },
    },
  },

  button: {
    width: '100%',
    position: 'relative',
    marginLeft: '0',
    py: '$24',
    paddingLeft: '$8',
    paddingRight: '$8',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',

    '@bp1': {
      paddingLeft: '$16',
      paddingRight: '$16',
    },

    svg: {
      position: 'absolute',
      right: '$16',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },

  '&:before': {
    content: '""',
    display: 'block',
    width: '1px',
    height: '80%',
    backgroundColor: '$lightGrey',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0',
    '.dark-mode &': {
      backgroundColor: '$greyBox',
    },
  },
});
