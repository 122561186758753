import { createStitches } from '@stitches/react';

const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      mainTextColor: '#1F1F1F',
      mainBackgroundColor: '#FAFAFA',
      mainBackgroundAltColor: 'hsl(0,0%,100%,1)',

      success: 'hsl(149, 64%, 46%)',
      error: 'hsl(14, 70%, 50%)',

      // +++++++++++++++++++New Colors +++++++++++++++++++
      thumbnailBorder: '#E1E1E1',

      tableRowBorder: '#D6D6D6',
      tableRowBackgroundHover: '#D6D6D6',

      black: '#1F1F1F',
      white: '#FFF',
      grey: '#EFEFEF',

      ultraLightGrey: '#E1E1E1',
      lightGrey: '#D6D6D6',
      mediumGrey: '#9F9F9F',
      darkGrey: '#727272',

      altBlack: '#000000', // USED IN FOOTER DARK BTM
      altDarkGrey: '#202020', // FOOTER DARK TOP LEFT
      altMediumGrey: '#3A3A3A', // FOOTER DARK TOP RIGHT

      bg: '#FAFAFA',
      box: '#FFF',
      elements: '#EFEFEF',
      postBoxBg: '#EFEFEF',
      postBoxBgHover: '#E1E1E1',

      greyBox: '#2C2C2C',

      red: '#D74E26',
      purple: '#5F60FF',
      yellow: '#FFC21A',
      blue: 'hsl(232, 100%, 61%)', // TBR
      green: '#2BC275',

      headerBlack: '#1F1F1F',
      headerButtons: 'rgb(33, 33, 33)',
      headerMenu: '#FFF',

      blackToWhite: '#1F1F1F',
      whiteToBlack: '#FFF',

      postsReactionsHoverEffect: '#D6D6D6',

      linkedin: '#007DB8',

      // +++++++++++++++++++ End New Colors  +++++++++++++++++++

      dragHover: 'hsl(174,52%,89%)',
    },
    fonts: {
      ArtifaktElementRegular: 'var(--ArtifaktElementRegular)',
      ArtifaktLegendRegular: 'var(--ArtifaktLegendRegular)',
      ArtifaktElementBold: 'var(--ArtifaktElementBold)',
      ArtifaktElementExtraBold: 'var(--ArtifaktElementExtraBold)',
      ArtifaktElementBlack: 'var(--ArtifaktElementBlack)',
      ArtifaktLegendBold: 'var(--ArtifaktLegendBold)',
      ArtifaktElementLight: 'var(--ArtifaktElementLight)',
      ArtifaktLegendLight: 'var(--ArtifaktLegendLight)',
      ArtifaktElementMedium: 'var(--ArtifaktElementMedium)',
      ArtifaktLegendMedium: 'var(--ArtifaktLegendMedium)',
      ArtifaktLegendExtraBold: 'var(--ArtifaktLegendExtraBold)',
      ArtifaktLegendBlack: 'var(--ArtifaktLegendBlack)',
      mono: 'monospace',
    },
    fontWeights: {
      100: '100',
      200: '200',
      300: '0.5rem',
    },
    lineHeights: {
      1: '1',
      2: '1.2',
      3: '1.3',
      4: '1.4',
      5: '1.5',
      6: '1.6',
      7: '1.7',
      8: '1.8',
    },
    letterSpacings: {
      1: '0.0625rem',
      2: '0.125rem',
    },
    space: {
      2: '0.125rem',
      4: '0.25rem',
      8: '0.5rem',
      12: '0.75rem',
      16: '1rem',
      20: '1.25rem',
      24: '1.5rem',
      28: '1.75rem',
      32: '2rem',
      40: '2.5rem',
      48: '3rem',
      54: '3.5rem',
      64: '4rem',
      72: '4.5rem',
      84: '5.25rem',
      92: '5.75rem',
    },
    sizes: {
      2: '0.125rem',
      4: '0.25rem',
      8: '0.5rem',
      12: '0.75rem',
      16: '1rem',
      24: '1.5rem',
      32: '2rem',
      40: '2.5rem',
      48: '3rem',
      54: '3.5rem',
      64: '4rem',
      72: '4.5rem',
      84: '5.25rem',

      container: '1440px',
    },
    fontSizes: {
      12: '0.75rem',
      14: '0.875rem',
      16: '1rem',
      18: '1.125rem',
      20: '1.25rem',
      24: '1.5rem',
      28: '1.75rem',
      32: '2rem',
      36: '2.25rem',
      42: '2.625rem',
      48: '3rem',
      54: '3.375rem',
      60: '3.75rem',
      68: '4.25rem',
      76: '4.75rem',
      84: '5.25rem',
      92: '5.75rem',
      152: '9.5rem',
      204: '12.75rem',
    },
    radii: {
      2: '0.125rem',
      4: '0.25rem',
      8: '0.5rem',
      12: '0.75rem',
      16: '1rem',
      24: '1.5rem',
      round: '50%',
      pill: '9999px',
    },
    borderWidths: {},
    borderStyles: {},
    shadows: {
      1: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      2: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      3: '0px 4px 15px rgba(0, 0, 0, 0.25)',
      4: '0px 4px 10px rgba(0, 0, 0, 0.35)',
    },
    transitions: {
      links: 'color .3s ease',
    },
    zIndices: {
      1: '100',
      2: '200',
      3: '300',
      4: '400',
      max: '999',
    },
  },
  media: {
    // default:
    bp0: '(min-width: 520px)',
    bp1: '(min-width: 768px)',
    bp2: '(min-width: 900px)',
    bp3: '(min-width: 1200px)',
    bp4: '(min-width: 1300px)',
    bp5: '(min-width: 1600px)',
    bp6: '(min-width: 1800px)',
    motion: '(prefers-reduced-motion)',
    hover: '(hover: hover)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',
  },
  utils: {
    m: value => ({
      paddingTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: value => ({
      marginTop: value,
    }),
    mr: value => ({
      marginRight: value,
    }),
    mb: value => ({
      marginBottom: value,
    }),
    ml: value => ({
      marginLeft: value,
    }),
    mx: value => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: value => ({
      marginTop: value,
      marginBottom: value,
    }),

    p: value => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: value => ({
      paddingTop: value,
    }),
    pr: value => ({
      paddingRight: value,
    }),
    pb: value => ({
      paddingBottom: value,
    }),
    pl: value => ({
      paddingLeft: value,
    }),
    px: value => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: value => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    size: value => ({
      width: value,
      height: value,
    }),

    ai: value => ({
      alignItems: value,
    }),

    jc: value => ({
      justifyContent: value,
    }),

    linearGradient: value => ({
      backgroundImage: `linear-gradient({value})`,
    }),

    br: value => ({
      borderRadius: value,
    }),
  },
});

export const darkThemeMode = createTheme({
  colors: {
    // +++++++++++++++++++New Colors +++++++++++++++++++
    thumbnailBorder: '#1F1F1F',

    canvas: 'hsl(0,0%,15%)',

    tableRowBorder: '#2C2C2C',
    tableRowBackgroundHover: '#2C2C2C',

    black: '#1F1F1F',
    white: '#FFF',
    grey: '#EFEFEF',

    ultraLightGrey: '#E1E1E1',
    lightGrey: '#727272',
    mediumGrey: '#9F9F9F',
    darkGrey: '#D6D6D6',

    postBoxBg: '#1F1F1F',
    postBoxBgHover: '#575757',

    bg: '#000000',
    box: '#2C2C2C',
    elements: '#575757',

    headerMenu: '#1F1F1F',

    blackToWhite: '#FFF',
    whiteToBlack: '#1F1F1F',

    mainTextColor: '#FFF',
    mainBackgroundColor: '#0A0A0A',
    mainBackgroundAltColor: 'hsl(196deg 17% 13%)',

    postsReactionsHoverEffect: '#1F1F1F',

    linkedin: '#007DB8',

    // +++++++++++++++++++ End New Colors  +++++++++++++++++++

    red: 'hsl(0,100%,62%,1)',

    dragHover: 'hsl(211,19%,28%,1)',

    success: 'hsl(149, 64%, 46%)',
    error: 'hsl(14, 70%, 50%)',
  },
});

// TODO:
// This is a temporary fix, check there https://github.com/gatsbyjs/gatsby/issues/33998
export {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
};
