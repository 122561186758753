import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const CommunityAddPostWrapper = styled('div', {
  padding: '$16',
  position: 'relative',
  width: '-webkit-fill-available',
  backgroundColor: '$box',
  borderRadius: '$8 0 0 $8',
  marginTop: '$16',
  width: '100%',

  // color: '$white',
  // border: '1px solid $lightGrey',
  backgroundColor: '$box',
  fontSize: '$14',
  padding: '13px $16',

  div: {
    display: 'block',
  },

  input: {
    backgroundColor: '$box',
    paddingLeft: '$54',
    fontSize: '$14',

    '&::placeholder': {
      color: '$mediumGrey',
    },
  },

  '.dark-mode &': {
    input: {
      '&::placeholder': {
        color: '$darkGrey',
      },
    },
  },

  variants: {
    isAtBottom: {
      true: {
        borderRadius: '0 0 0 $16',
      },
    },

    isAtTop: {
      true: {
        marginTop: '0',
        borderRadius: '$16',

        '@bp2': {
          borderRadius: '$16 0 0 $16',
        },
      },
    },
  },
});

export const Placeholder = styled('div', {
  marginLeft: '$48',
  // border: '1px solid $lightGrey',
  borderRadius: '$pill',
  fontSize: '$16',
  color: '$darkGrey',
  padding: '$12 $16',
  backgroundColor: '$postBoxBg',
  lineHeight: '1.5',
  cursor: 'pointer',
  transition: 'background .2s ease',
  '&:hover': {
    backgroundColor: '$postBoxBgHover',
  },
  variants: {
    isMobile: {
      true: {
        marginLeft: '0'
      }
    }
  }
});

export const ErrorWrapper = styled('div', {
  position: 'absolute',
  fontSize: '$1',
  color: '$red',
  fontFamily: '$ArtifaktElementBold',
  right: '0',
  bottom: '-$32',

  '@bp2': {
    bottom: '-$12',
  },
});

export const StyledBtn = styled(Button, {
  width: '42px !important',
  height: '42px !important',
  minHeight: 'unset !important',
  minWidth: 'unset !important',
  margin: '0',
});

export const OverallWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  borderRadius: '$8',
  marginBottom: '$24',

  variants: {
    isAtTop: {
      true: {
        boxShadow: '$2',
        borderRadius: '$16',
      },
    },
  },
});

export const UploadWrapper = styled('div', {
  padding: '$16 $16 $16 0',
  backgroundColor: '$box',
  marginTop: '$16',
  borderRadius: '0 $8 $8 0',
  marginLeft: '-$8',

  '@bp2': {
    padding: '$16',
    marginLeft: 'unset',
  },

  variants: {
    isAtBottom: {
      true: {
        borderRadius: '0 0 $16 0',
      },
    },

    isAtTop: {
      true: {
        marginTop: '0',
        display: 'none',
        borderRadius: '0 $16 $16 0',

        '@bp2': {
          display: 'block',
        },

        button: {
          marginTop: '0',
        },
      },
    },

    isLogged: {
      true: {
        padding: '0',
      },
    },
  },
});

export const AvatarWrapper = styled('div', {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '$24',
  width: '$32',
  height: '$32',
  borderRadius: '50%',
  overflow: 'hidden',
});

export const AvatarContainer = styled('div', {
  position: 'relative',
  width: '$32',
  height: '$32',

  img: {
    position: 'absolute',
    width: '$32',
    height: '$32',
    top: '0',
    left: '0',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});
