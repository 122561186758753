import {
  CommunityReactionsBarButtons,
  CommunityReactionsBarContainer,
  CommunityReactionsBarWrapper,
  SaveBtn,
  StyledBtns,
  ButtonArrowContainer,
  Triangle,
} from './CommunityReactionsBar.styled';
import React, { useContext } from 'react';

import Modal from '@components/atoms/modal/Modal';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import useModal from '@components/atoms/modal/useModal';
import AuthContext from '@context/AuthContext';

const CommunityReactionsBar = props => {
  const { isShowing, toggle } = useModal();
  const authContext = useContext(AuthContext);
  const commentLabel = <String id="community_admin_comment" />;
  const likeLabel = <String id="general_like_text" />;

  const {
    triangleVisible,
    isLiked,
    isLogged,
    postLikes,
    isReported,
    isVerified,
    isOnboarded,
    total,
    onCommentClick,
    postComments,
    showComments,
    postDate,
    isCurrentUserGroupMember,
    onLikeClick: onLikeClickProps,
    groupName,
  } = props;

  const onLikeClick = () => {
    if (!isLogged) {
      toggle();
    } else if (!isCurrentUserGroupMember || !authContext.isOnboarded) {
      toggle();
    } else {
      onLikeClickProps();
    }
  };

  const onJoinClick = () => {
    toggle();
    props.onJoinGroup(props.groupId);
  };

  function handleCommentClick() {
    onCommentClick();
  }

  const maxCount = 9;
  
  function getTotalCommentsCounter(count){
    if(count < 1) {
      return 0
    } else if(count > 0 && count <= maxCount){
      return count
    } else if(count > maxCount) {
      return `${maxCount}+`;
    }
  }

  return (
    <CommunityReactionsBarWrapper>
      <CommunityReactionsBarContainer>
        <CommunityReactionsBarButtons>
          <ButtonArrowContainer>
            <StyledBtns
              actionType="onclick"
              colorTheme="transparentFull"
              onClick={onLikeClick}
              label={postLikes < 1 ? likeLabel : ''}
              svgIcon={isLiked && isLogged ? 'heart-full' : 'love-empty'}
              isCounter={true}
              counter={postLikes > 0 ? postLikes : 0}
              className="post-btn post-like"
              isLiked={isLiked}
              hasCounter={postLikes > 0}
              isDisabled={isReported}
              isCommentsOpened={triangleVisible ? 'commentsOpened' : null}
            />
          </ButtonArrowContainer>
          <ButtonArrowContainer active={showComments}>
            <StyledBtns
              actionType="onclick"
              colorTheme="transparentFull"
              onClick={handleCommentClick}
              label={commentLabel}
              svgIcon={total > 0 ? 'comment-filled' : 'add-comments'}
              isCounter={true}
              counter={getTotalCommentsCounter(total)}
              stringCounter
              type="normal"
              className="post-btn post-comment"
              isDisabled={isReported && total === 0}
            />
            <Triangle isVisible={triangleVisible && postComments > 0} />
          </ButtonArrowContainer>
        </CommunityReactionsBarButtons>
        <Text size="12" css={{ whiteSpace: 'nowrap' }}>
          {postDate}
        </Text>
      </CommunityReactionsBarContainer>
      <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
        {!isVerified && isLogged ? (
          <PublishNotification type={'validation'} />
        ) : !isOnboarded && isLogged ? (
          <PublishNotification
            type={'onboarding'}
            url={`/m/${authContext.userSlug}`}
          />
        ) : (
          <PublishNotification
            type={!isLogged ? 'join' : 'joinGroup'}
            groupName={!isLogged ? '' : groupName}
            groupId={!isLogged ? '' : props.groupId}
            myGroupsId={!isLogged ? '' : props.myGroupsId}
            onJoinClick={onJoinClick}
            onClose={toggle}
          />
        )}
      </Modal>
    </CommunityReactionsBarWrapper>
  );
};

CommunityReactionsBar.propTypes = {};

export default CommunityReactionsBar;
