import React, { useState } from 'react';
import {
  StyledButton,
  StyledJob,
  StyledName,
  TextWrapper,
  Wrapper,
} from './UserInfos.styled';

import CommentBox from '@components/organisms/communityCommentBox/commentBox/CommentBox';
import CommunityPostBox from '@components/molecules/communityPostBox/CommunityPostBox';
import Modal from '@components/atoms/modal/Modal';
import useModal from '@components/atoms/modal/useModal';

const DateIconLink = props => {
  const { isShowing, toggle } = useModal();
  return (
    <Wrapper>
      <TextWrapper hasNoMargin={true}>
        <StyledName size="14">{props.date}</StyledName>
        <StyledJob size="14" css={{ marginTop: '$4' }}>
          {props.time}
        </StyledJob>
      </TextWrapper>
      {props.url ? (
        <StyledButton
          type="circle"
          svgIcon="visibility-on"
          colorTheme="gray"
          actionType="externalLink"
          externalUrl={props.url}
          target="_blank"
        />
      ) : null}
      {props.actionType ? (
        <StyledButton
          type="circle"
          svgIcon="visibility-on"
          colorTheme="gray"
          actionType="onclick"
          onClick={toggle}
        />
      ) : null}
      {props.actionType ? (
        <Modal isShowing={isShowing} hide={toggle}>
          {props.postType === 0 ? (
            <CommunityPostBox
              {...props.postData}
              postContent={props.postData}
              parentId={props.postData.postId}
              total={props.total}
              isVerified
              isOnboarded
              isReported
              isLogged
            />
          ) : (
            <CommentBox
              {...props.postData}
              isReported={true}
              commentId={props.postData.postId}
              isVerified
              isOnboarded
              isLogged
            />
          )}
        </Modal>
      ) : null}
    </Wrapper>
  );
};

export default DateIconLink;
