import { GET_INDUSTRIES_AND_TOOLS, GET_PROFILE_INFO } from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import {
  UPDATE_PROFILE_INDUSTRIES_ONBOARDING,
  UPDATE_PROFILE_TOOLS_ONBOARDING,
} from '@src/apollo/mutation';
import { useMutation, useQuery } from '@apollo/client';
import AuthContext from '@context/AuthContext';
import OnboardingCompletionContext from '@context/OnboardingCompletionContext';
import OnboardingLayout from '@components/organisms/layout/onboarding/OnboardingLayout';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import SelectIndustriesStep from '@components/organisms/onboardingSteps/SelectIndustriesStep';
import SelectToolsStep from '@components/organisms/onboardingSteps/SelectToolsStep';
import String from '@components/atoms/string/String';
import { navigate } from 'gatsby';
import { sortArrayByTitle } from '@src/utils/sortArray';

const IndustriesAndTools = context => {
  const authContext = useContext(AuthContext);

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    //navigate(`/m/${authContext.userSlug}`);
  }

  const [
    updateProfileTools,
    { loading: loadingTools, data: dataTools },
  ] = useMutation(UPDATE_PROFILE_TOOLS_ONBOARDING, {
    onCompleted: () => {
      authContext.setIsOnboarded();
      navigate('/');
    },
  });

  // useEffect(() => {
  //   if (dataTools) {
  //     console.log(dataIndustries);
  //   }

  //   if (dataTools && dataTools.updateUserProfile.profile_completion >= 70) {
  //     authContext.setIsOnboarded();
  //   }
  // }, [dataTools]);

  const [
    updateProfileIndustries,
    { loading: loadingIndustries, data: dataIndustries },
  ] = useMutation(UPDATE_PROFILE_INDUSTRIES_ONBOARDING, {
    onCompleted: () => {
      setIndustriesAndToolsState({
        ...industriesAndToolsState,
        currentStep: 'tools',
      });
    },
  });

  // useEffect(() => {
  //   if (dataIndustries) {
  //   }
  //   if (
  //     dataIndustries &&
  //     dataIndustries.updateUserProfile.profile_completion >= 70
  //   ) {
  //     authContext.setIsOnboarded();
  //   }
  // }, [dataIndustries]);

  const [onboardingCompletionState, setOnboardingCompletionContext] = useState([
    {
      title: <String id="sign_up_page_steps_1" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_2" />,
      isActive: true,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_3" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_4" />,
      isActive: false,
      isCompleted: true,
    },
    {
      title: <String id="sign_up_page_steps_5" />,
      isActive: true,
      isCompleted: false,
    },
  ]);
  const {
    loading: loadingNewData,
    error: errorNewData,
    data: newData,
  } = useQuery(GET_INDUSTRIES_AND_TOOLS);

  const [industriesAndToolsState, setIndustriesAndToolsState] = useState({
    currentStep: 'industries',
    isDataSet: false,
    industriesList: [],
    toolsList: [],
  });

  const [displaySkipState, setDisplaySkipState] = useState(true);

  const [sidebarTitle, setSidebarTitle] = useState("You're almost done!");

  const setData = newData => {
    const industriesList = [];
    const toolsList = [];

    newData.industries.forEach(element => {
      const industry = {
        id: element.id.toString(),
        label: element.name,
        isSelected: false,
      };
      industriesList.push(industry);
    });

    const toolsSorted = newData.tools.slice().sort(sortArrayByTitle);

    toolsSorted.forEach(element => {
      const tool = {
        id: element.id.toString(),
        value: element.title,
        name: element.title,
        imageFileName: element.icon,
        isSelected: false,
      };
      toolsList.push(tool);
    });

    setIndustriesAndToolsState({
      ...industriesAndToolsState,
      isDataSet: true,
      industriesList,
      toolsList,
    });
  };

  if (!loadingNewData && !industriesAndToolsState.isDataSet) {
    setData(newData);
  }

  const goToToolsStep = () => {
    let selectedIndustries = industriesAndToolsState.industriesList.map(
      industry => {
        return industry.isSelected ? parseInt(industry.id) : false;
      }
    );
    selectedIndustries = selectedIndustries.filter(industryId => {
      return industryId ? true : false;
    });
    setIndustriesAndToolsState({
      ...industriesAndToolsState,
      selectedIndustries: selectedIndustries,
    });
    updateProfileIndustries({
      variables: { industries: selectedIndustries, isOnboarded: false },
    });
  };

  const goToWelcomeStep = () => {
    setDisplaySkipState(false);
    setSidebarTitle('Onboarding completed!');
    let selectedTools = industriesAndToolsState.toolsList.map(tool => {
      return tool.isSelected ? parseInt(tool.id) : false;
    });
    selectedTools = selectedTools.filter(toolId => {
      return toolId ? true : false;
    });
    setIndustriesAndToolsState({
      ...industriesAndToolsState,
      selectedTools: selectedTools,
    });

    updateProfileTools({
      variables: { tools: selectedTools, isOnboarded: true },
    });

    navigate(`/m/${authContext.userSlug}`);
  };

  const onIndustriesSelect = index => {
    const updatedIndustriesList = JSON.parse(
      JSON.stringify(industriesAndToolsState.industriesList)
    );

    setIndustriesAndToolsState({
      ...industriesAndToolsState,
      industriesList: updatedIndustriesList,
    });

    updatedIndustriesList[index].isSelected = !updatedIndustriesList[index]
      .isSelected;
  };

  const onToolsSelect = index => {
    const updatedToolsList = JSON.parse(
      JSON.stringify(industriesAndToolsState.toolsList)
    );
    updatedToolsList[index].isSelected = !updatedToolsList[index].isSelected;
    setIndustriesAndToolsState({
      ...industriesAndToolsState,
      toolsList: updatedToolsList,
    });

    let newArr = [...onboardingCompletionState];
    onboardingCompletionState[4].isCompleted = true;
    setOnboardingCompletionContext(newArr);
  };

  return (
    <ProtectedRoute redirectTo={'/login'} userAccessReq={['auth', 'verified']}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      ></meta>
      <OnboardingCompletionContext.Provider
        value={[onboardingCompletionState, setOnboardingCompletionContext]}
      >
        <OnboardingLayout
          pageTitle="Industries and Tools"
          sideBarTitle={sidebarTitle}
          displayAvatar
          displaySteps
          displaySkip={displaySkipState}
          percentage="60%"
        >
          {industriesAndToolsState.currentStep == 'industries' && (
            <SelectIndustriesStep
              onClickNext={goToToolsStep}
              industriesList={industriesAndToolsState.industriesList}
              onIndustriesSelect={onIndustriesSelect}
              isDataSet={industriesAndToolsState.isDataSet}
              isSaving={loadingIndustries}
            ></SelectIndustriesStep>
          )}
          {industriesAndToolsState.currentStep == 'tools' && (
            <SelectToolsStep
              onClickNext={goToWelcomeStep}
              toolsList={industriesAndToolsState.toolsList}
              onToolsSelect={onToolsSelect}
              isSaving={loadingTools}
            ></SelectToolsStep>
          )}
        </OnboardingLayout>
      </OnboardingCompletionContext.Provider>
    </ProtectedRoute>
  );
};

export default IndustriesAndTools;
