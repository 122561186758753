import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import StringsContext from '@context/StringsContext';
import parse from 'html-react-parser';
const String = props => {
  const stringsContext = useContext(StringsContext);
  const stringId = props.id;

  const string = stringsContext[stringId] ? stringsContext[stringId].field : stringId
  let stringWithExtra = string;

  stringWithExtra = Object.keys(props.extras).length >= 1 
    ? string
      .replace(
          new RegExp(Object.keys(props.extras) // extra object keys to array
          .map(el => `%${el}%`) // adding percentage to map extra property in string like so: %property%
          .join("|"),"gi"), // joining all properties to use in regular expressions
          (matched) => props.extras[matched.replace(/[^a-zA-Z ]/g, '')] // replace all matches in string by key value
        )
    : string;

  return (
    <>
      {parse(stringWithExtra)}
    </>
  );
};

String.propTypes = {
  extras: PropTypes.object,
};

String.defaultProps = {
  extras: {},
};

export default String;
