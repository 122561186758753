import {
  ArtistName,
  CommentBoxWrapper,
  CommentFooter,
  CommentHeader,
  CommentReplyWrapper,
  CommentWrapper,
  DeleteBtnsContainer,
  PreviousRepliesBtn,
  RepliesContainer,
  ReplyingContainer,
  ReportBtns,
  ReportWrapper,
  ShowRepliesBtn,
  StyledActionBtn,
  StyledBtn,
  StyledLink,
  StyledReportText,
  StyledText,
  TextContainer,
  ThreeDotsBtn,
} from './CommentBox.styled';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@context/AuthContext';
import Avatar from '@components/atoms/avatar/Avatar';
import Button from '@components/atoms/button/Button';
import CommentReply from './CommentReply';
import CommunityAddPost from '@components/molecules/communityAddPost/CommunityAddPost';
import CommunityAddPostModal from '@components/molecules/communityAddPost/communityAddPostModal/CommunityAddPostModal';
import CommunityLinkPreview from '@components/molecules/communityLinkPreview/CommunityLinkPreview';
import CommunityPostBoxImages from '@components/molecules/communityPostBox/communityPostBoxImages/CommunityPostBoxImages';
import { GET_POST_COMMENTS } from '@src/apollo/query';
import Modal from '@components/atoms/modal/Modal';
import { POST_REACTION } from '@src/apollo/mutation';
import PendingPublicationsModal from '@components/molecules/pendingPublicationsModal/PendingPublicationsModal';
import PropTypes from 'prop-types';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import Separator from '@components/atoms/separator/Separator';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import useModal from '@components/atoms/modal/useModal';
import CommentWireFrame from '@components/molecules/communityPost/wireframe/CommentWireFrame';
import CommunityFeedContext from '@context/CommunityFeedContext';
import { isGroupMember } from '@src/utils/getMemberGroupInfo';
import CommunityPostContext from '@context/CommunityPostContext';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { formatDateTimeAgo } from '@src/utils/datesHelper';

const CommentBox = props => {
  const { isShowing, toggle } = useModal();
  const [communityState, setCommunityState] = useContext(CommunityFeedContext);
  const [isReporting, setIsReporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [commentsState, setCommentsState] = useContext(CommunityPostContext);
  const currentComment = commentsState.postComments[props.indexId];

  const [repliesState, setRepliesState] = useState({
    reported: false,
    allReplies: [],
    replies: [],
    //whichCommentExpended: props.whichCommentExpended,
    // isOpened: false,
    page: 1,
    loading: false,
    firstFetchLoading: false,
    edited: false,
    replying: false,
    isVisible: false,
  });
  const isOpened = commentsState.openedComments?.includes(props.commentId);

  const toggleReport = () => {
    setIsReporting(!isReporting);
  };
  const toggleDelete = () => {
    setIsDeleting(!isDeleting);
  };
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const likeLabel = <String id="general_like_text" />;

  const authContext = useContext(AuthContext);
  const [isLiked, setIsLiked] = useState(false);
  const [addRemoveReaction, dataReaction] = useMutation(POST_REACTION);

  useEffect(() => {
    if (props.likesList) {
      for (let i = 0; i < props.likesList.length; i++) {
        if (props.likesList[i].username === authContext.userSlug) {
          setIsLiked(true);
          return;
        }
      }
    }
  }, []);

  const [
    getCommentsReplies,
    { loading, error, data, fetchMore, refetch },
  ] = useLazyQuery(GET_POST_COMMENTS, {
    variables: {
      parent_id: props.commentId,
      limit: 3,
      page: 1,
      level: 2,
      order: 'desc',
    },
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'public' },
  });

  useEffect(() => {
    var element = document.getElementById(
      `add__comment__reply-${props.replyingToWhich}`
    );
    if (commentsState.replyingToWhich) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [props.replyingToWhich]);

  useEffect(() => {
    if (data) {
      const replies = [...data.posts.data];
      let newArr = [...commentsState.postComments];

      let repliesArrange = [];

      replies.map((reply, i) => {
        let profilePicture = getAvatarImage(
          reply?.author?.userProfile?.profile_picture,
          'xsmall'
        );
        const postImages = (reply.images || []).map((image, i) => {
          const imageContent = getImageSrc(
            image,
            'post_image',
            reply?.author?.first_name
          );
          return { ...image, ...imageContent };
        });

        let postLinkPreview = {};
        if (reply.link_url !== null) {
          const linkImage = getImageSrc(
            reply.link_image,
            'link_image',
            reply.link_url
          );
          postLinkPreview = {
            url: reply.link_url,
            title: reply.link_title,
            description: reply.link_description,
            previewImage: linkImage,
          };
        }
        const newReply = {
          reported: false,
          parentId: reply.parent.id,
          page: 1,
          loading: false,
          likes: reply.usersLiked.length,
          total: reply.children.lenght,
          edited: false,
          postLinkPreview:
            reply.link_url !== null && reply.link_url !== ''
              ? postLinkPreview
              : null,
          id: reply.id,
          level: reply.level,
          userInfos: {
            avatarProps: {
              shape: 'circle',
              alt: `${reply.author.first_name} ${reply.author.last_name}`,
              src: profilePicture,
              url: `/m/${reply.author.username}`,
            },
            // modalProps: userInfos,
          },
          postLikes: reply.usersLikedCount,
          commentDate: formatDateTimeAgo(reply.createdAt),
          postAuthor: {
            username: reply.author.username,
            name: `${reply.author.first_name} ${reply.author.last_name}`,
            jobTitle: reply.author.title,
            avatar: {
              src: profilePicture,
            },
            url: `/m/${reply.author.username}`,
          },
          postText: reply.content,
          postImages,
          favoriteList: reply.usersFavorite,
          likesList: reply.usersLiked,
        };
        repliesArrange.push(newReply);
      });

      newArr[props.indexId].replies = repliesArrange.reverse();

      setCommentsState({
        ...commentsState,
        postComments: newArr,
      });
    }
  }, [data]);

  useEffect(() => {
    if (currentComment.likesList) {
      setIsLiked(false);

      for (let i = 0; i < currentComment.likesList.length; i++) {
        if (currentComment.likesList[i].username === authContext.userSlug) {
          setIsLiked(true);
          return;
        }
      }
    }
  }, [commentsState]);

  // useEffect(() => {
  //   setRepliesState({
  //     ...repliesState,
  //     edited: true,
  //   });
  //   repliesState.allReplies.map((reply, i) => {
  //     if (reply.commentId === props.updatedData.post.id) {
  //       reply.postText = props.updatedData.post.content;

  //       const postImages = [];

  //       props.updatedData.post.images ? (
  //         <>
  //           {props.updatedData.post.images.map((image, i) => {
  //             const imageContent = getImageSrc(
  //               image,
  //               'post_image',
  //               `${post.author.first_name} ${post.author.last_name}`,
  //               image.id
  //             );

  //             postImages.push(imageContent);
  //           })}
  //         </>
  //       ) : null;

  //       reply.postImages = postImages;

  //       let postLinkPreview = null;
  //       const linkImage = getImageSrc(
  //         props.updatedData.post.link_image,
  //         'link_image',
  //         props.updatedData.post.link_url
  //       );

  //       if (
  //         props.updatedData.post.link_url !== null &&
  //         props.updatedData.post.link_url !== ''
  //       ) {
  //         postLinkPreview = {
  //           url: props.updatedData.post.link_url,
  //           title: props.updatedData.post.link_title,
  //           description: props.updatedData.post.link_description,
  //           previewImage: linkImage,
  //         };
  //       }
  //       reply.postLinkPreview = postLinkPreview;
  //     }
  //   });
  //   let alreadyIn = false;
  //   const allReplies = repliesState.allReplies;
  //   repliesState.allReplies.map((reply, i) => {
  //     if (alreadyIn && reply.commentId === props.updatedData.post.id) {
  //       allReplies.splice(i, 1);
  //     }

  //     if (reply.commentId === props.updatedData.post.id) {
  //       alreadyIn = true;
  //     }
  //   });
  //   setRepliesState({
  //     ...repliesState,
  //     allReplies: allReplies,
  //     edited: false,
  //   });
  // }, [props.updatedData]);

  const onShowRepliesClick = commentId => {
    getCommentsReplies();
    let newArr = [...commentsState.postComments];
    newArr[props.indexId].isOpened = true;
    setCommentsState({
      ...commentsState,
      postComments: newArr,
      openedComments: [props.commentId],
    });
  };

  const [reportState, setReportState] = useState({
    isVisible: false,
  });

  const onToggleOptionsClick = id => {
    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });
    if (props.openOptionsId) {
      props.openOptionsId(id);
    }
  };

  const onPreviousRepliesClick = () => {
    fetchMore({
      variables: {
        page: data.posts.current_page + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const previousPosts = prev.posts.data;
        const fetchMorePosts = fetchMoreResult.posts.data;
        fetchMoreResult.posts.data = [...previousPosts, ...fetchMorePosts];
        return { ...fetchMoreResult };
      },
    });
  };

  const onDeleteClick = () => {
    setIsDeleting(!isDeleting);
    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });

    if (props.isReply) {
      document.querySelector(
        '.comment-' + repliesState.commentId
      ).style.display = 'none';
    }

    props.onDeleteClick(
      repliesState.commentId,
      props.siblingTotal,
      props.parentId,
      props.isReply
    );
    getCommentsReplies();
  };

  const onLikeClick = e => {
    if (
      authContext.isAuthenticated &&
      authContext.isVerified &&
      authContext.isOnboarded &&
      isGroupMember(communityState.myGroupsId, props.groupId)
    ) {
      let action = '';
      if (isLiked) {
        action = 'remove';
        setRepliesState({
          ...repliesState,
          likes: repliesState.likes - 1,
        });
      } else {
        action = 'add';
        setRepliesState({
          ...repliesState,
          likes: repliesState.likes + 1,
        });
      }
      setIsLiked(!isLiked);
      addRemoveReaction({
        variables: {
          post_id: props.commentId,
          input: {
            reaction: 'like',
            action,
          },
        },
      });
    } else if (!isGroupMember(communityState.myGroupsId, props.groupId)) {
      toggle();
    } else {
      toggle();
    }
  };
  const onSaveClick = () => {
    props.onSaveClick;
    getCommentsReplies();
    let newArr = [...commentsState.postComments];
    newArr[props.indexId].isOpened = true;
    setCommentsState({
      ...commentsState,
      postComments: newArr,
      openedComments: [props.commentId],
    });
  };

  const onJoinClick = () => {
    toggle();
    props.onJoinGroup(props.groupId);
  };

  const cancelBtnText = <String id="general_cancel_btn_text" />;
  const deleteBtnText = <String id="general_delete_comments_text" />;

  const onReportClick = (id, value) => {
    props.onReportClick(id, value);
    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });
  };

  useEffect(() => {
    if (reportState.isVisible && props.optionsIds !== repliesState.commentId) {
      setReportState({
        ...reportState,
        isVisible: true,
      });
    }
  }, [props.optionsIds]);

  const closeMenus = (data, isComment, isReply, parentId) => {
    if (isReply) {
      props.updateComment(data, isReply, parentId);
    } else if (isComment) {
      props.updateComment(data);
    }
    setReportState({
      ...reportState,
      isVisible: !reportState.isVisible,
    });
  };

  return (
    <CommentBoxWrapper
      id={props.commentId}
      className={'comment-' + props.commentId}
    >
      <StyledLink alt={props.postAuthor.name} to={props.postAuthor.url}>
        <Avatar src={props.userInfos.avatarProps.src} />
      </StyledLink>
      <CommentWrapper isReported={props.isReported}>
        <CommentHeader>
          <TextContainer>
            <Text size="14" css={{ color: '$mediumGrey' }}>
              <ArtistName alt={props.postAuthor.name} to={props.postAuthor.url}>
                {props.postAuthor.name}
              </ArtistName>
              {/* {' '}
              <span>{props.postAuthor.jobTitle}</span> */}
            </Text>
            <StyledText
              size="14"
              dangerouslySetInnerHTML={{ __html: props.postText }}
            />
          </TextContainer>

          {props.postImages.length ? (
            <CommunityPostBoxImages
              postImages={props.postImages}
              isCommentImage
              commentImages={props.commentImages}
            />
          ) : null}
          {props.postImages.length === 0 && props.postLinkPreview ? (
            <CommunityLinkPreview
              {...props.postLinkPreview}
              className="link-preview-comment"
            />
          ) : null}
          <ReportWrapper
            isVisible={
              reportState.isVisible && props.optionsIds === props.commentId
            }
          >
            <ReportBtns onClick={toggleReport}>
              <Svg icon="report-flag" />
              <span>
                <StyledReportText size="14">
                  <String id="general_report_comments_text" />
                </StyledReportText>
                <Text
                  size="12"
                  css={{ color: '$mainTextColor', marginTop: '$4' }}
                >
                  <String id="general_flag_comments_text" />
                </Text>
              </span>
            </ReportBtns>
            {JSON.parse(localStorage.getItem('userSlug')) ===
              props.commentAuthor &&
            (props.groupRole === 'moderator' ||
              props.groupRole === 'pre_approved' ||
              props.generalRole === 'admin' ||
              props.generalRole === 'editor') ? (
              <ReportBtns onClick={toggleEdit}>
                <Svg icon="edit" />
                <StyledReportText size="14">
                  <String id="community_edit_comment_text" />
                </StyledReportText>
              </ReportBtns>
            ) : null}
            {props.postAuthor.username ===
            JSON.parse(localStorage.getItem('userSlug')) ? (
              <ReportBtns onClick={toggleDelete}>
                <Svg icon="close-small" />
                <StyledReportText size="14">
                  <String id="general_delete_comments_text" />
                </StyledReportText>
              </ReportBtns>
            ) : null}
          </ReportWrapper>
          <Separator />
          {authContext.isAuthenticated &&
          authContext.isVerified &&
          authContext.isOnboarded &&
          props.openOptionsId ? (
            <ThreeDotsBtn
              type="circle"
              colorTheme="transparent"
              svgIcon="three-dots"
              actionType="onclick"
              onClick={() => {
                onToggleOptionsClick(props.commentId);
              }}
            />
          ) : null}
        </CommentHeader>

        <CommentFooter>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledBtn>
              <StyledActionBtn
                type={repliesState.likes > 0 ? 'circle' : 'normal'}
                colorTheme="transparent"
                label={likeLabel}
                svgIcon={
                  isLiked && authContext.isAuthenticated
                    ? 'heart-full'
                    : 'love-empty'
                }
                actionType="onclick"
                isCounter
                counter={repliesState.likes > 0 ? repliesState.likes : 0}
                onClick={() => {
                  onLikeClick(props.commentId);
                }}
                isLikesBtn
                isLiked={isLiked}
                needsTopRight={repliesState.likes > 0}
                isDisabled={props.isReported}
              />
            </StyledBtn>
            <StyledBtn>
              <StyledActionBtn
                className="test"
                type="circle"
                colorTheme="transparent"
                actionType="onclick"
                svgIcon="reply-comment"
                onClick={() => {
                  !authContext.isAuthenticated ||
                  (authContext.isAuthenticated &&
                    (!authContext.isVerified || !props.isOnboarded))
                    ? toggle()
                    : props.onReplyClick(
                        props.parentId,
                        props.postAuthor.name,
                        props.postAuthor.url,
                        props.level
                      );
                }}
                isReply
                isDisabled={props.isReported}
              />
            </StyledBtn>

            <div>
              {props.repliesCount > 0 && (
                <ShowRepliesBtn
                  onClick={() => {
                    onShowRepliesClick(props.commentId);
                  }}
                  isNotVisible={isOpened}
                >
                  {props.repliesCount}{' '}
                  {props.repliesCount > 1 ? (
                    <String id="general_replies_text" />
                  ) : (
                    <String id="community_reply_text_singular" />
                  )}
                </ShowRepliesBtn>
              )}
            </div>
          </div>
          <Text
            size="12"
            css={{
              color: '$mediumGrey',
              whitespace: 'nowrap',
              '@bp1': { fontSize: '$14' },
            }}
          >
            {props.commentDate}
          </Text>
        </CommentFooter>

        <RepliesContainer
          isVisible={!props.isReported ? isOpened || props.isMobile : null}
        >
          {data && data.posts.has_more_pages && (
            <PreviousRepliesBtn
              onClick={() => {
                onPreviousRepliesClick(props.commentId);
              }}
            >
              <String id="general_previous_replies_text" />
            </PreviousRepliesBtn>
          )}
          {loading && <CommentWireFrame isReply={true} />}
          {data &&
            currentComment.replies.map((reply, i) => {
              return (
                <CommentReplyWrapper
                  key={`reply-${i}`}
                  className={'comment-' + reply.id}
                >
                  <CommentReply
                    reply={reply}
                    replyId={reply.id}
                    parentId={reply.parentId}
                    onReplyClick={props.onReplyClick}
                    groupId={props.groupId}
                    groupRole={props.groupRole}
                    isBlocked={props.isBlocked}
                    generalRole={props.generalRole}
                    // onSaveEditPost={props.onSaveEditPost}
                    isCurrentUserGroupMember={isGroupMember(
                      communityState.myGroupsId,
                      props.groupId
                    )}
                    isReported={props.isReported}
                    openOptionsId={props.openOptionsId}
                    optionsIds={props.optionsIds}
                    onDeleteClick={props.onDeleteClick}
                  />
                </CommentReplyWrapper>
              );
            })}
        </RepliesContainer>

        {props.replyingToWhich === props.commentId ? (
          <ReplyingContainer
            isShorter={props.isMobile || props.isReported ? false : true}
          >
            {!props.isReported ? (
              <CommunityAddPost
                {...props}
                replyingTo={props.replyingTo}
                onSaveClick={onSaveClick}
                isMobile={props.isMobile}
                isAuthenticated={authContext.isAuthenticated}
                isVerified={authContext.isVerified}
                isOnboarded={props.isOnboarded}
                groupId={props.groupId}
                isComment
                parentId={props.parentId}
                isReply
                isCurrentUserGroupMember={props.isCurrentUserGroupMember}
                isReported={props.isReported}
                groupRole={props.groupRole}
                isBlocked={props.isBlocked}
                myGroupsId={communityState.myGroupsId}
              />
            ) : null}
          </ReplyingContainer>
        ) : null}
      </CommentWrapper>
      <Modal isShowing={isReporting} hide={toggleReport}>
        <PendingPublicationsModal
          closeModal={toggleReport}
          postId={props.commentId}
          isReporting
          onReportClick={onReportClick}
        />
      </Modal>
      <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
        {!authContext.isVerified && authContext.isAuthenticated ? (
          <PublishNotification type={'validation'} />
        ) : !props.isOnboarded && authContext.isAuthenticated ? (
          <PublishNotification
            type={'onboarding'}
            url={'/m/' + authContext.userSlug}
          />
        ) : (
          <PublishNotification
            type={!authContext.isAuthenticated ? 'join' : 'joinGroup'}
            groupName={!authContext.isAuthenticated ? '' : props.groupName}
            onJoinClick={onJoinClick}
            groupId={props.groupId}
            myGroupsId={communityState.myGroupsId}
            onClose={toggle}
          />
        )}
      </Modal>
      <Modal isShowing={isDeleting} hide={toggleDelete}>
        <Text size="45" weight="bold" css={{ color: '$mainTextColor' }}>
          <String id="community_delete_comment_message" />
        </Text>
        <DeleteBtnsContainer>
          <Button
            type="normal"
            colorTheme="gray"
            label={cancelBtnText}
            actionType="onclick"
            onClick={toggleDelete}
          />
          <Button
            type="normal"
            colorTheme="normal"
            label={deleteBtnText}
            actionType="onclick"
            onClick={onDeleteClick}
            css={{ marginLeft: '$16' }}
          />
        </DeleteBtnsContainer>
      </Modal>
      <Modal isShowing={isEditing} hide={toggleEdit}>
        {props.userInfos?.modalProps?.modalProps?.modalProps ? (
          <CommunityAddPostModal
            {...props.userInfos?.modalProps?.modalProps?.modalProps}
            hide={toggleEdit}
            imagesProp={props.commentImages}
            groupId={props.groupId}
            parentId={props.parentId}
            content={props.postText}
            isEdit
            refetch={props.refetch}
            postId={props.commentId}
            closeMenus={closeMenus}
            replyParentId={props.replyParentId}
            updateComment={props.updateComment}
            // onSaveEditPost={props.onSaveEditPost}
          />
        ) : (
          <CommunityAddPostModal
            {...props.userInfos?.modalProps?.modalProps}
            hide={toggleEdit}
            imagesProp={props.commentImages}
            groupId={props.groupId}
            parentId={props.parentId}
            content={props.postText}
            isEdit
            isComment
            refetch={refetch}
            postId={props.commentId}
            closeMenus={closeMenus}
            replyParentId={props.replyParentId}
          />
        )}
      </Modal>
    </CommentBoxWrapper>
  );
};

CommentBox.propTypes = {
  whichLiked: PropTypes.array,
  onShowRepliesClick: PropTypes.func,
  whichCommentExpended: PropTypes.array,
  onReplyClick: PropTypes.func,
  replyingToWhich: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onLikeClick: PropTypes.func,
  whichLiked: PropTypes.array,
  replyingTo: PropTypes.string,
  artistProfilUrl: PropTypes.string,
  onSaveClick: PropTypes.func,
  isMobile: PropTypes.bool,
  avatarProps: PropTypes.object,
  artistName: PropTypes.string,
  artistRole: PropTypes.string,
  commentContent: PropTypes.string,
  commentDate: PropTypes.string,
  commentId: PropTypes.number,
  parentId: PropTypes.number,
  replies: PropTypes.array,
  userInfos: PropTypes.object,
  onDeleteClick: PropTypes.func,
};

export default CommentBox;
