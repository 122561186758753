import { styled } from '@root/stitches.config';

export const UploadToolWrapper = styled('div', {
  marginBottom: '$48',
});

export const CtaButtonsWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '12px',
});

export const ModalEditorWrapper = styled('div', {
  width: '100%',
  minWidth: '300px',
  marginBottom: '$54',
  wordBreak: 'break-all',

  ol: {
    listStylePosition: 'inside',
    listStyleType: 'decimal',
  },
  ul: {
    listStylePosition: 'inside',
    listStyleType: 'disc',
  },
  '.ql-editor': {
    overflowY: 'visible',
  },

  '.quill': {
    border: '1px solid $lightGrey',
    borderRadius: '$8',
    padding: '$12 $16',
    minHeight: '140px',
  },
});
