import {
  ImageCropContainer,
  ImageCropImageContainer,
  SliderContainer,
} from './ImageCrop.styled';
import React, { useCallback, useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';

const ImageCrop = props => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [hideSlider, setHideSlider] = useState(false);
  const [image, setImage] = useState({
    image: undefined,
    maxZoom: undefined,
  });

  const minZoom = 1;

  const onZoomInClick = () => {
    setZoom(zoom => (minZoom && zoom < image?.maxZoom ? zoom + 0.1 : zoom));
  };

  const onZoomOutClick = () => {
    setZoom(zoom > minZoom && zoom <= image?.maxZoom ? zoom - 0.1 : zoom);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    props.onCropChange(
      !hideSlider
        ? croppedAreaPixels
        : {
            ...croppedAreaPixels,
            x: 0,
            y: 0,
          }
    );
  }, [props.image])

  useEffect(() => {
    if (props.image.data_url) {
      const newImageData = props.image.data_url;
      const newImage = new Image();
      newImage.src = newImageData;
      newImage.onload = () => {
        const maxImageSide = Math.min(newImage.width, newImage.height);
        setImage({
          image: newImageData,
          maxZoom: maxImageSide / 400,
        });
      };

      setCrop({ x: 0, y: 0 });
    }
  }, [props.image.data_url]);

  useEffect(() => {
    if (minZoom > image?.maxZoom) {
      setHideSlider(true);
    } else {
      setHideSlider(false);
    }
    setZoom(1);
  }, [minZoom, image?.maxZoom]);

  return (
    <ImageCropContainer>
      <ImageCropImageContainer css={props.cropperStyle}>
        <Cropper
          image={image?.image}
          crop={crop}
          zoom={zoom}
          aspect={props.aspect || 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          // rotation={rotation}
          cropShape={props.aspect || 'round'}
          showGrid={false}
          minZoom={minZoom}
          maxZoom={image?.maxZoom}
        />
      </ImageCropImageContainer>
      {!hideSlider && (
        <SliderContainer>
          <Button
            type="circle"
            colorTheme="transparent"
            actionType="onclick"
            onClick={onZoomOutClick}
            svgIcon="zoom-out"
          />
          <Slider
            value={zoom}
            min={minZoom}
            max={image?.maxZoom}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => {
              setZoom(zoom);
            }}
          />
          <Button
            type="circle"
            colorTheme="transparent"
            actionType="onclick"
            onClick={onZoomInClick}
            svgIcon="zoom-in"
          />
        </SliderContainer>
      )}
    </ImageCropContainer>
  );
};

export default ImageCrop;
