import {
  FeaturedWrapper,
  GroupSidebarRightWrapper,
  StyledSectionHeading,
} from './GroupSidebarRight.styled';

import AdFeature from '@components/molecules/adFeature/AdFeature';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';

const GroupSidebarRight = props => {
  const { hasAdFeature, adFeature, thumbnails } = props;

  return (
    <GroupSidebarRightWrapper>
      {hasAdFeature && (
        <AdFeature
          src={adFeature.src}
          title={adFeature.title}
          action={adFeature.action}
        />
      )}
      <FeaturedWrapper>
        {thumbnails && thumbnails.length > 0 ? (
          <StyledSectionHeading level="h6">
            <String id="group_right_sidebar_featured_thumbnails_title" />
          </StyledSectionHeading>
        ) : null}

        {thumbnails &&
          thumbnails.map(thumbnail => {
            return (
              <Thumbnail
                key={thumbnail.id}
                isInGroupPage={true}
                className="groupSidebarRight_thumbnail"
                {...thumbnail}
              />
            );
          })}
      </FeaturedWrapper>
    </GroupSidebarRightWrapper>
  );
};

// GroupSidebarRight.propTypes = {
//   adFeature: PropTypes.shape({
//     staticImage: PropTypes.shape({
//       alt: PropTypes.string.isRequired,
//       src: PropTypes.shape({
//         childImageSharp: PropTypes.shape({
//           fluid: PropTypes.oneOfType([
//             PropTypes.shape({}),
//             PropTypes.arrayOf(PropTypes.shape({})),
//           ]),
//         }).isRequired,
//       }),
//     }),
//     title: PropTypes.string.isRequired,
//     action: PropTypes.shape({
//       label: PropTypes.string,
//       colorTheme: PropTypes.string,
//       internalUrl: PropTypes.string,
//       externalUrl: PropTypes.string,
//       actionType: PropTypes.oneOf(['onclick', 'externalLink', 'internalLink']),
//       onClick: PropTypes.func,
//       svgIcon: PropTypes.string,
//       svgLocation: PropTypes.oneOf(['before', 'after']),
//     }),
//   }).isRequired,
//   thumbnails: PropTypes.arrayOf(
//     PropTypes.shape({
//       alt: PropTypes.string,
//       url: PropTypes.string.isRequired,
//       category: PropTypes.string,
//       description: PropTypes.string,
//       postDate: PropTypes.string,
//       isFeatured: PropTypes.bool,
//       isMini: PropTypes.bool,
//       type: PropTypes.string.isRequired,
//       levelTitle: PropTypes.string,
//       levelText: PropTypes.string,
//       levelUrl: PropTypes.string,
//       seriesTitle: PropTypes.string,
//       seriesText: PropTypes.string,
//       seriesUrl: PropTypes.string,
//       artworkByProps: {
//         avatarProps: {
//           shape: PropTypes.string,
//           alt: PropTypes.string,
//           src: {
//             childImageSharp: {
//               fixed: {
//                 height: PropTypes.number,
//                 width: PropTypes.number,
//                 src: PropTypes.string,
//                 srcSet: PropTypes.string,
//               },
//             },
//           },
//         },
//         label: PropTypes.string,
//         artistName: PropTypes.string,
//         artistProfession: PropTypes.string,
//         alt: PropTypes.string,
//         url: PropTypes.string,
//       },
//       badgeList: PropTypes.array,
//     })
//   ).isRequired,
// };

export default GroupSidebarRight;
