import {
  SocialListItemAlt,
  SocialListItemWrapper,
  SocialListWrapper,
} from './SocialListLink.styled';

import Button from '@components/atoms/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

export const SocialListLink = props => {
  const { socialMediaList, type, colorTheme, css, className } = props;

  return (
    <SocialListWrapper>
      {Object.keys(socialMediaList).map((key, index) => {
        if (socialMediaList[key].url) {
          return (
            <SocialListItemWrapper key={socialMediaList[key].url + key}>
              <Button
                actionType="externalLink"
                colorTheme={colorTheme}
                svgIcon={socialMediaList[key].icon}
                type="circle"
                circleSize="large"
                externalUrl={socialMediaList[key].url}
                target="_blank"
                className={className}
                css={{
                  // margin: '$4',
                  minWidth: '44px',
                  width: '44px',
                  minHeight: '44px',
                  height: '44px',
                }}
              />
              {socialMediaList[key].altTitle && (
                <SocialListItemAlt>
                  {socialMediaList[key].altTitle}
                </SocialListItemAlt>
              )}
            </SocialListItemWrapper>
          );
        }
      })}
    </SocialListWrapper>
  );
};

export default SocialListLink;

SocialListLink.propTypes = {
  colorTheme: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
