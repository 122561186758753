import { styled } from '@root/stitches.config';

export const WrapperBlock = styled('div', {
  m: 'auto',
  gridTemplateColumns: '1fr',
  display: 'grid',
  gap: '$24',
  justifyItems: "center",
  justifyContent: "center",
  '@bp1': {
    gridTemplateColumns: '1fr 1fr',
  },
  '@bp2': {
    display: 'flex',
  },
});