// import { StaticQuery, graphql } from 'gatsby';
import { stringsData } from './craftData/StringsData';

import React from 'react';

const StringsContext = React.createContext({
  strings: [],
});

class StringsProvider extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children } = this.props;
    const strings = {};
    stringsData.data.entries.map(entry => {
      if (entry.strings) {
        entry.strings.forEach(string => {
          strings[string.key] = string;
        });
      }
    });
    return (
      <StringsContext.Provider value={strings}>
        {children}
      </StringsContext.Provider>
    );
  }
}

export default StringsContext;

export { StringsProvider };
