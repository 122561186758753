import {
  AvatarBoxWrapper,
  StyledJob,
  StyledName,
  TextWrapper,
} from './UserInfos.styled';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import Avatar from '@components/atoms/avatar/Avatar';
import React from 'react';

const UserInfos = props => {
  const profilePicture = getAvatarImage(props.url, 'xsmall');

  return (
    <AvatarBoxWrapper target="_blank" href={props.link} css={props.css}>
      {props.url && (
        <Avatar
          shape="circle"
          alt={props.name}
          src={profilePicture}
          css={{ flexShrink: '0' }}
        />
      )}
      <TextWrapper hasNoMargin={props.url === undefined}>
        <StyledName size="16">{props.name}</StyledName>
        {/* <StyledJob size="12">{props.job}</StyledJob> */}
        <a href={`mailto:${props.email}`}>
          <StyledName size="12">{props.email}</StyledName>
        </a>
      </TextWrapper>
    </AvatarBoxWrapper>
  );
};

export default UserInfos;
