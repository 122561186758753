import {
  ActionButton,
  RightButtonSide,
  CreateCropWrapper,
  CropAndPreviewWrapper,
  CropZoneWrapper,
  ThumbnailPreview,
  UploadCover,
  UploadCoverWrapper,
} from './CreateCrop.styled';
import React, { useContext, useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import CreateCoverStep from '../createSidebar/CreateCoverStep';
import { Heading } from '@components/atoms/heading/Heading';
import ImageCrop from '@components/molecules/ImageCrop/ImageCrop';
import ImageUploading from 'react-images-uploading';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import { formatDate } from '@src/utils/formatDate';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import getCroppedImg from './cropImage';
import { monthsNames } from '@src/utils/monthNames';
import { useIsMobile } from '@src/utils/useIsMobile';

const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

const CreateCrop = props => {
  const {
    imageBlocks,
    currentImageCropIndex,
    editPreviousCoverSelected,
    thumbnailCropData,
    setThumbnailCropsData,
    addImageBlock,
    onChange,
    createState,
    onChangeImageCropIndex,
    createInfoFormState,
    createAdminFormState,
    creatorData,
  } = props;

  const [croppedImage, setCroppedImage] = useState(null);
  const [images, setImages] = React.useState([]);
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  const [cropData, setCropsData] = useState({
    image: '',
    cropData: { x: 0, y: 0 },
    currentImageCrop: null,
  });

  async function getPreviewImg(imageData, crop) {
    const newCroppedImage = await getCroppedImg(imageData, crop);
    setCroppedImage(newCroppedImage);
  }

  useEffect(() => {
    if (
      imageBlocks[currentImageCropIndex]?.data &&
      !editPreviousCoverSelected
    ) {
      setCropsData({
        ...cropData,
        image: imageBlocks[currentImageCropIndex].data,
        currentImageCrop: imageBlocks[currentImageCropIndex].data,
      });

      setThumbnailCropsData({
        ...cropData,
        image: imageBlocks[currentImageCropIndex].data,
        currentImageCrop: imageBlocks[currentImageCropIndex].data,
      });
    }
  }, [currentImageCropIndex]);

  useEffect(() => {
    setCropsData(thumbnailCropData);
    setThumbnailCropsData(thumbnailCropData);
  }, [thumbnailCropData]);

  useEffect(() => {
    if (Object.keys(cropData.currentImageCrop || {}).length === 0) {
      setImages([]);
    }
  }, [cropData.currentImageCrop]);

  const onImageDrop = imgs => {
    setImages(imgs);
    addImageBlock(imgs[imgs.length - 1]);
  };

  const onDrop = img => {
    if(validImageTypes.includes(img[img.length - 1].file.type)){
      setImages(img);
      onImageDrop(img);
    } else {
      handleUpdateClientNotification({
        type: 'error',
        text: 'create_cover_invalid_image_format',
        autoDismiss: true,
      })
    }
  };

  const onCropChange = crop => {
    if (imageBlocks.length >= 1 && !editPreviousCoverSelected) {
      setCropsData({
        cropData: crop,
        image: imageBlocks[currentImageCropIndex]?.data,
        currentImageCrop: imageBlocks[currentImageCropIndex]?.data,
      });

      onChange({
        cropData: crop,
        image: imageBlocks[currentImageCropIndex]?.data,
        currentImageCrop: imageBlocks[currentImageCropIndex]?.data,
      });

      setThumbnailCropsData({
        cropData: crop,
        image: imageBlocks[currentImageCropIndex]?.data,
        currentImageCrop: imageBlocks[currentImageCropIndex]?.data,
      });
      getPreviewImg(imageBlocks[currentImageCropIndex]?.data?.data_url, crop);
    } else {
      setCropsData({
        ...cropData,
        cropData: crop,
      });
      onChange({
        ...cropData,
        cropData: crop,
      });
      setThumbnailCropsData({
        ...cropData,
        cropData: crop,
      });
      getPreviewImg(cropData.currentImageCrop?.data_url, crop);
    }
  };

  useEffect(() => {
    if (imageBlocks.length !== 0) {
      setCropsData({
        ...cropData,
        image: imageBlocks[currentImageCropIndex]?.data,
        currentImageCrop: imageBlocks[currentImageCropIndex]?.data,
      });
      setThumbnailCropsData({
        ...cropData,
        image: imageBlocks[currentImageCropIndex]?.data,
        currentImageCrop: imageBlocks[currentImageCropIndex]?.data,
      });
    }
  }, [imageBlocks]);

  // const imgSrc = isNewImage
  //   ? props.block?.data?.data_url
  //   : `${CDN_URL}${props.block?.image?.sizes[0]?.path}`;

  // imageUrl={
  //   !editPreviousCoverSelected
  //     ? croppedImage
  //     : imageBlocks[currentImageCropIndex]?.data?.data_url
  // }
  // srcSet={
  //   !editPreviousCoverSelected
  //     ? croppedImage
  //     : imageBlocks[currentImageCropIndex]?.data?.data_url
  // }

  const isMobile = useIsMobile();

  const previewType = () => {
    if (
      createState.createType === 'article' &&
      parseInt(createAdminFormState.publish_options.selectedArticleType, 10) > 1
    ) {
      return 'ambassador';
    } else {
      return createState.createType;
    }
  };

  const isMemberOf = previewType() === 'ambassador';

  function dateString() {
    if (isMemberOf) {
      if (
        parseInt(
          createAdminFormState.publish_options.selectedArticleType,
          10
        ) === 2
      ) {
        return `${
          monthsNames[createAdminFormState.publish_options.month - 1]
        } ${createAdminFormState.publish_options.year}`;
      }
      return createAdminFormState.publish_options.year;
    }
    return formatDate(new Date().valueOf() / 1000);
  }

  const resolutionNotif = () =>
    handleUpdateClientNotification({
      type: 'error',
      text: 'image_resolution_too_small',
      autoDismiss: true,
    });

  const avatar = getAvatarImage(creatorData.profilePicture, 'xsmall');


  return (
    <CreateCropWrapper>
      <Heading level="h3" weight="legendBold" css={{ marginBottom: '$8' }}>
        {createState.createType === 'article' ? (
          <String id="create_crop_article_title" />
        ) : null}
        {createState.createType === 'artwork' ? (
          <String id="create_crop_artwork_title" />
        ) : null}
        {createState.createType === 'tutorial' ? (
          <String id="create_crop_tutorial_title" />
        ) : null}
      </Heading>
      <Text css={{ marginBottom: '$24' }}>
        <String
          id={
            !editPreviousCoverSelected
              ? 'create_crop_text'
              : 'create_crop_text_previous_cover'
          }
        />
      </Text>

      {isMobile && (
        <CreateCoverStep
          imageBlockList={createState.coverImagesBlockList}
          currentImageCropIndex={currentImageCropIndex}
          onChangeImageCropIndex={onChangeImageCropIndex}
          images={images}
          onDrop={onImageDrop}
          isMobile
        />
      )}
      <CropZoneWrapper>
        {cropData.currentImageCrop ? (
          <CropAndPreviewWrapper>
            {!editPreviousCoverSelected && (
              <ImageCrop
                onCropChange={onCropChange}
                image={cropData.currentImageCrop}
                aspect={
                  previewType() === 'ambassador' || previewType() === 'artwork'
                    ? 1070 / 1327
                    : 535 / 431
                }
                shape="rect"
              />
            )}
            {croppedImage || editPreviousCoverSelected ? (
              <ThumbnailPreview>
                <Thumbnail
                  imageUrl={
                    !editPreviousCoverSelected
                      ? croppedImage
                      : imageBlocks[currentImageCropIndex]?.data?.data_url
                  }
                  type={previewType()}
                  authoring={{
                    profilePicture: avatar,
                    author: {
                      name: creatorData.name,
                    },
                  }}
                  badgeList={createInfoFormState.toolsList
                    .filter(el => el.isSelected)
                    .map(tool => ({
                      imageDynamic: {
                        src: tool.imageFileName,
                        srcset: `${tool.imageFileName} 1x, ${tool.imageFileName} 250w, ${tool.imageFileName} 500w`,
                      },
                      alt: tool.name,
                    }))}
                  ambassadorType={
                    parseInt(
                      createAdminFormState?.publish_options
                        ?.selectedArticleType,
                      10
                    ) === 2
                      ? 'motm'
                      : 'moty'
                  }
                  ambassadorTitle={
                    createAdminFormState?.publish_options?.selectedMoMember
                      ?.title
                  }
                  datePosted={dateString()}
                  title={createInfoFormState.title}
                  tutorialLevel={createInfoFormState?.levelOptions?.[
                    createInfoFormState.selectedLevel - 1
                  ]?.name.toLowerCase()}
                  seriesCount={createState.blockList
                    .filter(block => block.type === 'content')
                    .map(block => block.contents.length)
                    .reduce(function (a, b) {
                      return a + b;
                    }, 0)}
                />
              </ThumbnailPreview>
            ) : null}
          </CropAndPreviewWrapper>
        ) : (
          <UploadCover>
            <UploadCoverWrapper>
              <Heading level="h3" isBold="true">
                <String id="create_crop_upload_image" />
              </Heading>
              <ActionButton>
                <ImageUploading
                  value={images}
                  onChange={onDrop}
                  maxNumber={1}
                  dataURLKey="data_url"
                  resolutionType="more"
                  resolutionWidth="400"
                  resolutionHeight="400"
                  onError={e => {
                    if (e.resolution) {
                      resolutionNotif();
                    }
                  }}
                >
                  {({ onImageUpload, dragProps }) => (
                    <Button
                      type="circle"
                      colorTheme="normal"
                      svgIcon="image"
                      actionType="onclick"
                      onClick={onImageUpload}
                    />
                  )}
                </ImageUploading>
                <RightButtonSide>
                  <Text>
                    <String id="create_crop_upload_image_button_label" />
                  </Text>
                  <Text>
                    <String id="create_crop_upload_image_button_text" />
                  </Text>
                  <Text size="14">
                    <String id="create_crop_upload_image_button_text_small" />
                  </Text>
                </RightButtonSide>
              </ActionButton>
            </UploadCoverWrapper>
          </UploadCover>
        )}
        {(images.length > 0 && !isMobile) || editPreviousCoverSelected ? (
          <ImageUploading
            value={images}
            multiple
            onChange={onDrop}
            dataURLKey="data_url"
            resolutionType="more"
            resolutionWidth="400"
            resolutionHeight="400"
            onError={e => {
              if (e.resolution) {
                resolutionNotif();
              }
            }}
          >
            {({ onImageUpload, dragProps }) => (
              <Button
                type="normal"
                colorTheme="normal"
                label={<String id="create_crop_reupload_image_button_label" />}
                actionType="onclick"
                onClick={onImageUpload}
              />
            )}
          </ImageUploading>
        ) : null}
      </CropZoneWrapper>
    </CreateCropWrapper>
  );
};

CreateCrop.propTypes = {
  imageBlocks: PropTypes.array.isRequired,
  currentImageCropIndex: PropTypes.number.isRequired,
  editPreviousCoverSelected: PropTypes.bool,
  thumbnailCropData: PropTypes.object,
  setThumbnailCropsData: PropTypes.func,
  addImageBlock: PropTypes.func,
  onChange: PropTypes.func,
  createState: PropTypes.object,
  onChangeImageCropIndex: PropTypes.func,
  createInfoFormState: PropTypes.object,
  createAdminFormState: PropTypes.object,
};

CreateCrop.defaultProps = {
  editPreviousCoverSelected: false,
  thumbnailCropData: {},
  setThumbnailCropsData: () => {},
  addImageBlock: () => {},
  onChange: () => {},
  createState: {},
  onChangeImageCropIndex: () => {},
  createInfoFormState: {},
};

export default CreateCrop;
