import {
  EditorAddBlocksWrapper,
  ActionsWrapper,
  ButtonsWrapper,
} from './EditorAddBlocks.styled';
import React, { useState } from 'react';

import AddImage from '../blockModals/AddImage';
import AddEmbed from '../blockModals/AddEmbed';
import Button from '@components/atoms/button/Button';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import useModal from '@components/atoms/modal/useModal';
import TooltipBox from '@components/atoms/tooltip/TooltipBox';

const EditorAddBlocks = ({
  createType,
  onAddBlock,
  isAddingBlockDone,
  hideContentFeature,
  hasBlocks,
}) => {
  const { isShowing, toggle } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [addBlock, setAddBlock] = useState({
    type: '',
  });

  const openBlockModal = type => {
    setAddBlock({
      ...addBlock,
      type,
    });
    toggle();
  };

  const onModalSave = (type, data) => {
    if (type === 'video' || type === 'sketchfab') {
      onAddBlock(type, { url: data });
    }
    if (type === 'image') {
      onAddBlock('image', { imageData: data });
    }
  };

  let types = ['image', 'video', 'text', 'sketchfab', 'content'];

  function getSvgForType(type) {
    switch (type) {
      case 'image':
        return 'image';
      case 'video':
        return 'code';
      case 'sketchfab':
        return 'sketchfab';
      case 'text':
        return 'wysiwyg';
      case 'content':
        return 'thumbnail-series';
      default:
        return 'image';
    }
  }

  if (hideContentFeature) {
    const newTypes = types.filter(type => type !== 'content');
    types = newTypes;
  }

  return (
    <>
      <EditorAddBlocksWrapper>
        <ActionsWrapper
          isOpen={isOpen}
          isDisabled={
            (isAddingBlockDone && isOpen) ||
            (!hasBlocks && createType === 'asset')
          }
        >
          {isOpen && (
            <ButtonsWrapper isOpen={isOpen}>
              {types.map((type, i) => {
                return (
                  <TooltipBox
                    key={type}
                    stringId={`create_build_add_block_label_${
                      type === 'content' && createType === 'tutorial_series'
                        ? 'tutorials'
                        : type
                    }`}
                  >
                    <Button
                      type="circle"
                      actionType="onclick"
                      svgIcon={getSvgForType(type)}
                      onClick={
                        type !== 'text' && type !== 'content'
                          ? openBlockModal.bind(null, type)
                          : onAddBlock.bind(null, type)
                      }
                      isDisabled={isAddingBlockDone}
                    />
                  </TooltipBox>
                );
              })}
            </ButtonsWrapper>
          )}
          <Button
            type="circle"
            actionType="onclick"
            svgIcon={isAddingBlockDone ? '' : 'close-big'}
            onClick={() => setIsOpen(!isOpen)}
            isLoading={isAddingBlockDone}
            isDisabled={!hasBlocks && createType === 'asset'}
            css={{
              pointerEvents:
                isAddingBlockDone || (!hasBlocks && createType === 'asset')
                  ? 'none'
                  : 'auto',
              transform: `rotate(${
                isOpen || (!hasBlocks && createType === 'asset') ? 0 : -45
              }deg)`,
              background: isOpen ? `$box` : `mainTextColor`,
              svg: {
                fill: `${!isOpen ? '$box' : '$mainTextColor'} !important`,
              },
            }}
          />
        </ActionsWrapper>
        <Modal
          innerCss={{ '@bp2': { maxWidth: '640px' } }}
          isShowing={isShowing}
          hide={toggle}
        >
          {(addBlock.type === 'video' || addBlock.type === 'sketchfab') && (
            <AddEmbed onClose={toggle} onSave={onModalSave} type={addBlock.type} />
          )}
          {addBlock.type === 'image' && (
            <AddImage
              imageMaxSizeBytes={10000000}
              resolutionWidth={400}
              onClose={toggle}
              onSave={onModalSave}
            />
          )}
        </Modal>
      </EditorAddBlocksWrapper>
      <Text
        as="p"
        css={{
          fontFamily: '$ArtifaktLegendBold',
          marginTop: '$8',
          marginBottom: !(!hasBlocks && createType === 'asset') ? '$64' : '0',
        }}
      >
        <String id="create_build_add_block_label" />
      </Text>
      {!hasBlocks && createType === 'asset' && (
        <Text
          as="p"
          css={{
            marginTop: '$4',
            fontSize: '$14',
            marginBottom: '$64',
          }}
        >
          <String id="create_build_add_block_no_asset_label_warning" />
        </Text>
      )}
    </>
  );
};

EditorAddBlocks.propTypes = {
  onAddBlock: PropTypes.func.isRequired,
};

export default EditorAddBlocks;
