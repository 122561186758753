import { styled } from '@root/stitches.config';

export const ProfileWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const ProfileInner = styled('div', {
  width: '100%',
  maxWidth: '1440px',
  py: '$16',
  '@bp2': {
    display: 'grid',
    gridTemplateColumns: '304px auto',
    gridGap: '$32',
    py: '$32',
  },
});

export const ProfileContentStyled = styled('div', {
  marginTop: '$48',
});

export const ProfileContentPanel = styled('div', {
  margin: '$24 0px',
});

export const LoaderWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  ai: 'center',
  jc: 'center',
  ai: 'center',
});

export const ThumbnailsListing = styled('div', {
  display: 'grid',
  gap: '32px',
  marginBottom: '$64',
  '@bp0': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  '@bp1': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});
