import { styled } from '@root/stitches.config';

export const BlockWrapper = styled('div', {
  '@bp1': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    overflowX: 'auto',
    minHeight: '308px',
  },
  padding: '$24',
  boxSizing: 'border-box'
});

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.ql-editor': {
    fontSize: '$24',
    fontFamily: '$ArtifaktLegendBold',
    textAlign: 'center',
    padding: '0 $32'
  },
  '.ql-blank': {
    fontFamily: '$ArtifaktElementRegular',
    opacity: '.6',
  }
});

export const ThumbnailControlsWrapper = styled('div', {
  position: 'relative',
  textAlign: 'left',
  '&:not(:first-of-type)': {
    mt: '$24',
  },
  "@bp1": {
    '&:not(:first-of-type)': {
      mt: '0',
      ml: '$24',
    },
  },
  '@bp3': {
    '.controls-wrapper': {
      display: 'none',
    },
    '&:hover': {
      boxShadow: "0 0 0 3px #5F60FF",
      br: 18,
      '.controls-wrapper': {
        display: 'flex',
      },
    }
  }
});

export const ControlsWrapper = styled('div', {
  position: 'absolute',
  zIndex: 3,
  right: '$8',
  top: '$8',
  display: 'flex',
  '.next, .prev': {
    transform: 'rotate(90deg)',
    '@bp1': {
      transform: 'rotate(0)'
    }
  },
});

export const ReorderWrapper = styled('div', {
  mr: '$8',
  button: {
    minWidth: '$32', 
    minHeight: '$32',
    width: '$32',
    height: '$32',
    svg: {
      transform: 'scale(0.7)'
    },
    '&:not(:first-of-type)': {
      marginLeft: '$4'
    }
  }
});

export const AddContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '> p': {
    mt: '$8',
  },
  '> button': {
    ml: '0',
  }
  // position: 'relative',
  // transform: 'translateX(-50%)',
  // left: '50%',
  // mt: '$24'
});