import { SketchfabBlockErrorWrapper, SketchfabBlockWrapper } from './SketchfabBlock.styled';
import React, { useEffect, useRef, useState } from 'react';

import AddEmbed from '../../blockModals/AddEmbed';
import Button from '@components/atoms/button/Button';
import { EditorLoadingWrapper } from '../EditorBlock.styled';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import Modal from '@components/atoms/modal/Modal';
import { Text } from '@components/atoms/text/Text';
import useModal from '@components/atoms/modal/useModal';

const SketchfabBlock = props => {
  const { isShowing, toggle } = useModal();

  const [blockSketchfabState, setSketchfabBlockState] = useState({
    offsetWidth: 0,
    isError: false,
    error:
      'The url you entered is invalid, please enter a valid Sketchfab url.',
  });
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setSketchfabBlockState({
        ...blockSketchfabState,
        offsetWidth: ref.current.offsetWidth,
      });
    }
  }, [ref.current]);

  const onBlockSave = (type, data) => {
    toggle();
    setTimeout(() => {
      props.onEditSketchfabBlock(props.blockIndex, {
        type,
        data: { sketchfabUrl: data },
      });
      setSketchfabBlockState({
        ...blockSketchfabState,
        isError: false,
      });
    }, 100);
  };

  return (
    <>
      <SketchfabBlockWrapper ref={ref}>
        {!blockSketchfabState.isError ? (
          <div class="sketchfab-embed-wrapper">
            <iframe
              frameborder="0"
              allowfullscreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-tracking
              execution-while-out-of-viewport
              execution-while-not-rendered web-share
              width="100%"
              height="500px"
              src={`${props.block.sketchfabUrl}/embed`}>
            </iframe>
          </div>
        ) : (
          <>
            <SketchfabBlockErrorWrapper>
              <Text>{blockSketchfabState.error}</Text>
              <Button
                type="normal"
                colorTheme="normal"
                svgIcon="image"
                actionType="onclick"
                label="Change video url"
                css={{ marginBottom: '$16', marginTop: '$32' }}
                onClick={toggle}
              />
            </SketchfabBlockErrorWrapper>
            <Modal isShowing={isShowing} hide={toggle}>
              <AddEmbed onClose={toggle} onSave={onBlockSave} type="sketchfan" />
            </Modal>
          </>
        )}
      </SketchfabBlockWrapper>
      {props.block.isLoading && (
        <EditorLoadingWrapper>
          <LoaderSpinner />
        </EditorLoadingWrapper>
      )}
    </>
  );
};

export default SketchfabBlock;
