import { styled } from '@root/stitches.config';

export const LandingPageWrapper = styled('div', {
  width: '100vw',
  overflow: 'hidden',
});

export const SectionWrapper = styled('section', {
  margin: '0 auto',
  padding: '0px $16 $64 $16',
  '@bp2': {
    padding: '0px $32 $84 $32',
  },

  '@bp3': {
    padding: '0px $32 $84 $32',
    width: '100%',
    maxWidth: '$container',
  },

  '@bp5': {
    padding: '0px 0px $64',
  },
});
