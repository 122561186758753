import {
  ErrorText,
  ExitModal,
  FakeModal,
  LoaderWrapper,
  ModalInner,
} from './GroupFeed.styled';
import React, { useState } from 'react';

import CommunityPostBox from '@components/molecules/communityPostBox/CommunityPostBox';
import LoaderSpinner from '@components/atoms/loaderSpinner/LoaderSpinner';
import String from '@components/atoms/string/String';
const SinglePost = props => {
  const [isVisible, setIsVisible] = useState(true);

  const closeModal = () => {
    setIsVisible(false);
    if(props.closeModal){
      props.closeModal();
    }
  };

  const onDeleteClick = id => {
    setIsVisible(false);

    props.onDeleteClick(id);
  };

  const renderPost = () => {
    if(props.isNull){
      return (
        <ErrorText size="32" weight="legendBold">
          <String id="deleted_post_error" />
        </ErrorText>
      )
    } else {
      return (
        <CommunityPostBox
          {...props.postContent}
          isInModal={props.isInModal}
          postContent={props.postContent}
          isLogged={props.isLogged}
          isVerified={props.isVerified}
          isOnboarded={props.isOnboarded}
          userInfos={props.userInfos}
          isPending={false}
          isSinglePost
          total={props.total}
          onDeleteClick={onDeleteClick}
          groupId={props.groupId}
          isCurrentUserGroupMember={props.isCurrentUserGroupMember}
          groupRole={props.groupRole}
          generalRole={props.generalRole}
        />
      )
    }
  }
  return (
    <FakeModal isShowing={isVisible}>
      <ModalInner>
        <ExitModal
          type="circle"
          colorTheme="gray"
          actionType="onclick"
          onClick={closeModal}
          svgIcon="close-small"
        />
        {!props.loaded ? (
          <LoaderWrapper>
            <LoaderSpinner />
          </LoaderWrapper>
        ) : renderPost()}
      </ModalInner>
    </FakeModal>
  );
};

export default SinglePost;
