import {
  Flag,
  ProfileInfoWrapper,
  ProfileLocation,
  ProfileSidebarBadges,
  ProfileSidebarCard,
  ProfileSidebarCardWrapper,
  ProfileSidebarSkeleton,
  ProfileSidebarTags,
  SettingButtonWrapper,
  SettingMenuBox,
  SettingMenuBoxLink,
  StyledAvatar,
} from './ProfileSidebar.styled';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import Avatar from '@components/atoms/avatar/Avatar';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import AvatarEdit from '@components/organisms/profileEdit/modals/AvatarEdit';
import BadgeToolsList from '@components/molecules/badgesToolsList/BadgeToolsList';
import Button from '@components/atoms/button/Button';
import { ClientNotificationContext } from '@context/ClientNotificationContext';
import { Heading } from '@components/atoms/heading/Heading';
import IndustriesEdit from '@components/organisms/profileEdit/modals/IndustriesEdit';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import ReactDOM from 'react-dom';
import Modal from '@components/atoms/modal/Modal';
import ProfileCompletionContext from '@context/ProfileCompletionContext';
import ProfileEdit from '@components/organisms/profileEdit/modals/ProfileEdit';
import ProfileReport from '../profileReport/ProfileReport';
import PropTypes from 'prop-types';
import { REPORT_USER } from '@src/apollo/mutation';
import SkillsEdit from '@components/organisms/profileEdit/modals/SkillsEdit';
import SocialListLink from '@components/molecules/socialListLink/SocialListLink';
import String from '@components/atoms/string/String';
import TagList from '@components/molecules/tagList/TagList';
import { Text } from '@components/atoms/text/Text';
import ToolsEdit from '@components/organisms/profileEdit/modals/ToolsEdit';
import { useIsMobile } from '@src/utils/useIsMobile';
import useModal from '@components/atoms/modal/useModal';
import { useMutation } from '@apollo/client';
import { validWebsiteUrl } from '@src/utils/validWebsiteUrl';

const ProfileSidebar = props => {
  const [reportUser, { data, error, loading }] = useMutation(REPORT_USER);
  const isMobile = useIsMobile();
  const [profileSidebarState, setProfileSidebarState] = useState({
    currentEdit: '',
    isDataSet: false,
    isEditSettingsOpened: false,
    title: null,
    gender: null,
    location: null,
    locationWithCity: null,
    firstname: null,
    lastname: null,
    socials: {
      instagramUrl: {
        name: 'Instagram',
        icon: 'instagram',
        url: '',
      },
      linkedinUrl: {
        name: 'LinkedIn',
        icon: 'linkedin',
        url: '',
      },
      behanceUrl: {
        name: 'Behance',
        icon: 'behance',
        url: '',
      },
      youtubeUrl: {
        name: 'Youtube',
        icon: 'youtube',
        url: '',
      },
      personnalWebsite: {
        name: 'Personnal Website',
        icon: 'internet',
        url: '',
      },
    },
    navigation_tab: '',
    tools: [],
    industries: [],
    isEditMode: props.isCurrentUserProfile,
  });

  const { isShowing, toggle } = useModal();
  const menuBoxRef = useRef(null);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);
  });

  const [profileCompletionState, setProfileCompletionState] = useContext(
    ProfileCompletionContext
  );

  const openEditModal = type => {
    setProfileSidebarState({
      ...profileSidebarState,
      currentEdit: type,
    });
    toggle();
  };

  const onToolEditSave = selectedTools => {
    props.profileEditSave();

    setProfileSidebarState({
      ...profileSidebarState,
      tools: selectedTools,
    });
  };

  const onIndustriesEditSave = selectedIndustries => {
    props.profileEditSave();
    setProfileSidebarState({
      ...profileSidebarState,
      industries: selectedIndustries,
    });
  };

  const onProfileSave = profileState => {
    props.profileEditSave();

    let locationWithCity = '';

    if (profileState.location && profileState.locationCity) {
      locationWithCity += profileState.locationCity;
    }

    if (profileState.location) {
      if (locationWithCity != '') {
        locationWithCity += ', ';
      }
      locationWithCity += profileState.location;
    }
    setProfileSidebarState({
      ...profileSidebarState,
      title: profileState.title,
      gender: profileState.gender,
      navigation_tab: profileState.navigation_tab,
      location: profileState.location,
      locationFull: profileState.locationFull,
      locationWithCity: locationWithCity,
      firstname: profileState.firstname,
      lastname: profileState.lastname,
      socials: {
        instagramUrl: {
          name: 'Instagram',
          icon: 'instagram',
          url: profileState.instagram_url,
        },
        linkedinUrl: {
          name: 'LinkedIn',
          icon: 'linkedin',
          url: profileState.linkedin_url,
        },
        behanceUrl: {
          name: 'Behance',
          icon: 'behance',
          url: profileState.behance_url,
        },
        youtubeUrl: {
          name: 'Youtube',
          icon: 'youtube',
          url: profileState.youtube_url,
        },
        personnalWebsite: {
          name: 'Personnal Website',
          icon: 'internet',
          url: profileState.website_url,
        },
      },
    });
  };

  const sidebarMobileContainer = document.querySelector(
    '.mobile-sidebar-portal'
  );

  const onSetAvatarImage = src => {
    props.updateProfileSave;
    setProfileCompletionState({
      ...profileCompletionState,
      profile_completion: profileCompletionState.profile_completion + 10,
      avatar: true,
    });

    const timeStampSrc = `${src}?${Date.now()}`;
    setProfileSidebarState({
      ...profileSidebarState,
      avatarNew: timeStampSrc,
    });
  };

  const onClickSettings = () => {
    setProfileSidebarState({
      ...profileSidebarState,
      isEditSettingsOpened: !profileSidebarState.isEditSettingsOpened,
    });
  };

  const handleClickOutside = event => {
    if (
      menuBoxRef &&
      menuBoxRef.current &&
      !menuBoxRef.current.contains(event.target)
    ) {
      onClickSettings();
    }
  };

  useLayoutEffect(() => {
    if (profileSidebarState.isEditSettingsOpened && !isShowing) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // cleanup this component
    return () => {
      if (profileSidebarState.isEditSettingsOpened) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [profileSidebarState]);

  useEffect(() => {
    if (props.profileData) {
      const tools = [];
      const industries = [];

      const websiteRegex = /^(https)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

      props.profileData.tools.forEach(tool => {
        const toolSingle = {
          id: tool.id,
          alt: tool.title,
          imageDynamic: {
            src: {
              path: tool?.icon?.path,
            },
          },

          infoboxActive: true,
          infoboxTitle: tool?.title,
        };
        tools.push(toolSingle);
      });

      props.profileData.industries.forEach(industry => {
        const industrySingle = {
          id: industry.id.toString(),
          label: industry.name,
          url: null,
          isSelected: false,
        };
        industries.push(industrySingle);
      });

      // If no avatar from user

      // let profilePicture = getAvatar(
      //   props?.profileData?.userProfile?.profile_picture,
      //   props?.profileData?.first_name
      // );
      // console.log(profilePicture);

      const avatar = getAvatarImage(
        props?.profileData?.userProfile?.profile_picture,
        'large'
      );

      let locationFull = '';

      if (props.profileData.location && props.profileData.location.city) {
        locationFull += props.profileData.location.city;
      }

      if (props.profileData.location && props.profileData.location.state) {
        if (locationFull != '') {
          locationFull += ', ';
        }
        locationFull += props.profileData.location.state;
      }

      if (props.profileData.location && props.profileData.location.country) {
        if (locationFull != '') {
          locationFull += ', ';
        }
        locationFull += props.profileData.location.country;
      }

      let locationWithCity = '';

      if (props.profileData.location && props.profileData.location.city) {
        locationWithCity += props.profileData.location.city;
      }

      if (props.profileData.location && props.profileData.location.country) {
        if (locationWithCity != '') {
          locationWithCity += ', ';
        }
        locationWithCity += props.profileData.location.country;
      }
      setProfileSidebarState({
        ...profileSidebarState,
        isDataSet: true,
        user_id: props.profileData.id,
        tools,
        industries,
        title: props.profileData.title,
        gender: props.profileData.gender,
        username: props.profileData.username,
        navigation_tab: props.profileData.userProfile.navigation_tab,
        location:
          props.profileData.location && props.profileData.location.country
            ? props.profileData.location.country
            : null,
        locationFull,
        locationWithCity: locationWithCity,
        firstname: props.profileData.first_name,
        lastname: props.profileData.last_name,
        socials: {
          instagramUrl: {
            name: 'Instagram',
            icon: 'instagram',
            url: props.profileData.userProfile.instagram_url
              ? props.profileData.userProfile.instagram_url
              : '',
          },
          linkedinUrl: {
            name: 'LinkedIn',
            icon: 'linkedin',
            url: props.profileData.userProfile.linkedin_url
              ? props.profileData.userProfile.linkedin_url
              : '',
          },
          behanceUrl: {
            name: 'Behance',
            icon: 'behance',
            url: props.profileData.userProfile.behance_url
              ? props.profileData.userProfile.behance_url
              : '',
          },
          youtubeUrl: {
            name: 'Youtube',
            icon: 'youtube',
            url: props.profileData.userProfile.youtube_url
              ? props.profileData.userProfile.youtube_url
              : '',
          },
          personnalWebsite: {
            name: 'Personnal Website',
            icon: 'internet',
            url:
              props.profileData.userProfile.website_url &&
              validWebsiteUrl(props.profileData.userProfile.website_url)
                ? props.profileData.userProfile.website_url
                : '',
          },
        },
        avatar,
      });
    }
  }, [props.profileData]);

  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  const onShareClick = () => {
    navigator.clipboard.writeText(props.href);
    handleUpdateClientNotification({
      text: 'add_to_clipboard_notification',
      type: 'success',
      autoDismiss: true,
    });
  };

  let flagFilename = '';
  if (profileSidebarState.location) {
    flagFilename = `/svg/flags/${profileSidebarState.location
      .replaceAll(' ', '-')
      .toLowerCase()}`;
  }

  const avatarStyle = {
    marginTop: '-110px',
    border: '4px solid $white',
    br: 100,
    boxShadow: '$1',
    width: '160px',
    height: '160px',
  };

  useEffect(() => {
    setTimeout(() => {
      if (window.location.search == '?edit=profile') {
        openEditModal('profile');
      }
    }, 1000);
  }, []);

  return (
    <ProfileSidebarCardWrapper>
      {profileSidebarState.isDataSet ? (
        <>
          <ProfileSidebarCard isMobile={isMobile}>
            <Avatar
              alt={`${props?.profileData?.first_name} ${props?.profileData?.last_name}`}
              src={profileSidebarState.avatar}
              css={avatarStyle}
              size="large"
              onEditClick={openEditModal.bind(null, 'avatar')}
              isEditMode={
                profileSidebarState.isEditMode &&
                props.isCurrentUserProfile &&
                !props.isPublic
              }
            />
            <SettingButtonWrapper>
              <Button
                className="more-button"
                actionType="onclick"
                colorTheme="gray"
                css={{
                  width: '50px',
                  height: '50px',
                }}
                onClick={onClickSettings}
                svgIcon="more"
                type="circle"
              />
              <SettingMenuBox
                ref={menuBoxRef}
                isEditSettingsOpened={
                  profileSidebarState.isEditSettingsOpened
                    ? 'isOpened'
                    : 'isNotOpened'
                }
              >
                {!props.isCurrentUserProfile ||
                  props.isPublic ||
                  (props.isAuthenticated && (
                    <>
                      <SettingMenuBoxLink
                        onClick={() => {
                          openEditModal('report');
                        }}
                      >
                        <String id="profile_sidebar_report_button_label" />
                      </SettingMenuBoxLink>
                    </>
                  ))}
                <SettingMenuBoxLink onClick={() => onShareClick()}>
                  Share
                </SettingMenuBoxLink>
                {props.isCurrentUserProfile ? (
                  <SettingMenuBoxLink onClick={props.togglePublic}>
                    {props.isPublic ? (
                      <String id="edit_my_profile_text" />
                    ) : (
                      <String id="view_pblic_profile_text" />
                    )}
                  </SettingMenuBoxLink>
                ) : null}
              </SettingMenuBox>
            </SettingButtonWrapper>

            <ProfileInfoWrapper>
              <Heading
                level="h3"
                css={{
                  fontFamily: '$ArtifaktLegendBold',
                  fontSize: '$32',
                  lineHeight: '1.2em',
                  marginTop: '$8',
                  textTransform: 'capitalize',
                }}
              >
                {profileSidebarState.firstname} {profileSidebarState.lastname}
              </Heading>
              {profileSidebarState.gender && (
                <Text css={{ marginBottom: '$8', marginTop: '$8' }} level="h3">
                  {profileSidebarState.gender}
                </Text>
              )}
              {profileSidebarState.title && (
                <Text css={{ marginBottom: '$8', marginTop: '$8' }} level="h3">
                  {profileSidebarState.title}
                </Text>
              )}
              {profileSidebarState.location ? (
                <ProfileLocation>
                  <Flag src={`${flagFilename}.svg`} />
                  <Text size="12">{profileSidebarState.locationWithCity}</Text>
                </ProfileLocation>
              ) : null}
            </ProfileInfoWrapper>
            {profileSidebarState.isEditMode &&
            props.isCurrentUserProfile &&
            !props.isPublic ? (
              <Button
                actionType="onclick"
                colorTheme="normal"
                type="normal"
                svgIcon="edit"
                svgLocation="after"
                css={{
                  marginBottom: '$16',
                  width: '100%',
                }}
                label="edit"
                onClick={openEditModal.bind(null, 'profile')}
              />
            ) : null}
            <Modal isShowing={isShowing} hide={() => toggle()}>
              {profileSidebarState.currentEdit === 'report' && (
                // suspicious, abusive, adult-content
                <ProfileReport
                  profileId={props.profileData.id}
                  onCancelClick={toggle}
                  onActionClick={(user_id, type) => {
                    onClickSettings();
                    reportUser({
                      variables: {
                        user_id,
                        type,
                      },
                    });
                  }}
                />
              )}
              {profileSidebarState.currentEdit === 'profile' && (
                <ProfileEdit
                  profileData={profileSidebarState}
                  onClose={toggle}
                  onSave={onProfileSave}
                />
              )}

              {profileSidebarState.currentEdit === 'tools' && (
                <ToolsEdit
                  userId={profileSidebarState.user_id}
                  selectedTools={profileSidebarState.tools}
                  onClose={toggle}
                  onToolEditSave={onToolEditSave}
                />
              )}

              {profileSidebarState.currentEdit === 'industries' && (
                <IndustriesEdit
                  userId={profileSidebarState.user_id}
                  selectedIndustries={profileSidebarState.industries}
                  onIndustriesEditSave={onIndustriesEditSave}
                  onClose={toggle}
                />
              )}

              {profileSidebarState.currentEdit === 'skills' && (
                <SkillsEdit
                  userId={profileSidebarState.user_id}
                  onClose={toggle}
                />
              )}

              {profileSidebarState.currentEdit === 'avatar' && (
                <>
                  <AvatarEdit
                    userId={profileSidebarState.user_id}
                    onClose={toggle}
                    onSetAvatarImage={onSetAvatarImage}
                    isAdminNotCurrentProfile={props.isAdminNotCurrentProfile}
                  />
                </>
              )}
            </Modal>
            <SocialListLink
              socialMediaList={profileSidebarState.socials}
              type="circle"
              colorTheme="gray"
            />
          </ProfileSidebarCard>
          {sidebarMobileContainer && dimensions.width < 900 ? (
            ReactDOM.createPortal(
              <>
                <ProfileSidebarBadges isMobile>
                  <BadgeToolsList
                    list={profileSidebarState.tools}
                    title="Tools"
                    onEditClick={openEditModal.bind(null, 'tools')}
                    isEditMode={
                      profileSidebarState.isEditMode &&
                      props.isCurrentUserProfile &&
                      !props.isPublic
                    }
                  />
                </ProfileSidebarBadges>
                <ProfileSidebarTags isMobile>
                  <TagList
                    list={profileSidebarState.industries}
                    title="Industries"
                    css={{ marginBottom: '$24' }}
                    onEditClick={openEditModal.bind(null, 'industries')}
                    isEditMode={
                      profileSidebarState.isEditMode &&
                      props.isCurrentUserProfile &&
                      !props.isPublic
                    }
                  />
                </ProfileSidebarTags>
              </>,
              sidebarMobileContainer
            )
          ) : (
            <>
              <ProfileSidebarBadges>
                <BadgeToolsList
                  list={profileSidebarState.tools}
                  title="Tools"
                  onEditClick={openEditModal.bind(null, 'tools')}
                  isEditMode={
                    profileSidebarState.isEditMode &&
                    props.isCurrentUserProfile &&
                    !props.isPublic
                  }
                />
              </ProfileSidebarBadges>
              <ProfileSidebarTags>
                <TagList
                  list={profileSidebarState.industries}
                  title="Industries"
                  css={{ marginBottom: '$24' }}
                  onEditClick={openEditModal.bind(null, 'industries')}
                  isEditMode={
                    profileSidebarState.isEditMode &&
                    props.isCurrentUserProfile &&
                    !props.isPublic
                  }
                />
              </ProfileSidebarTags>
            </>
          )}
        </>
      ) : (
        <ProfileSidebarSkeleton>
          <LoaderSkeleton height="160px" width="160px" isRadius isCircle />
          <LoaderSkeleton height="160px" width="100%" />
          <LoaderSkeleton height="40px" width="100%" />
          <LoaderSkeleton height="40px" width="100%" />
        </ProfileSidebarSkeleton>
      )}
    </ProfileSidebarCardWrapper>
  );
};

export default ProfileSidebar;

ProfileSidebar.propTypes = {
  name: PropTypes.string.isRequired,
  profession: PropTypes.string,
  location: PropTypes.object,
  avatar: PropTypes.object.isRequired,
  socialMediaList: PropTypes.array,
  badges: PropTypes.object,
  tools: PropTypes.object,
  tags: PropTypes.object,
};

ProfileSidebar.defaultProps = {
  name: 'Joss Bey',
  profession: 'Compositor supervisor',
  location: {
    name: 'Montreal, Canada',
    flag: 'flags-canada',
  },
  avatar: {
    src: {
      childImageSharp: {
        fixed: {
          height: 48,
          width: 48,
          src: '/images/profile_image_placeholder.jpg',
          srcSet:
            '/images/profile_image_placeholder.jpg 1x,/images/profile_image_placeholder.jpg 1.5x,/images/profile_image_placeholder.jpg 2x',
        },
      },
    },
  },
};
