import {
  CreateNavigationContent,
  CreateNavigationHeader,
  CreatePageSection,
} from './CreateForm.styled';

import CreateCrop from '../sections/CreateCrop';
import CreateEditor from '../sections/CreateEditor';
import CreateInfo from '../sections/CreateInfo';
import CreateModal from '@components/molecules/createModal/CreateModal';
import CreateNavigation from '../createNavigation/CreateNavigation';
import CreateSidebar from '../createSidebar/CreateSidebar';
import Loader from '@components/atoms/loader/Loader';
import PropTypes from 'prop-types';
import React from 'react';

function CreateForm(props) {
  const {
    isEdit,
    isDraft,
    isLoading,
    currentStepName,
    createState,
    onNavItemClick,
    gotoPrevSection,
    gotoNextSection,
    createInfoFormState,
    setCreateInfoFormState,
    createAdminFormState,
    setCreateAdminFormState,
    onAddBlock,
    onEditBlock,
    onRemoveBlock,
    onChangeBlockChangeOrder,
    onEditUserContentblock,
    onEditVideoBlock,
    onEditSketchfabBlock,
    thumbnailCropData,
    setThumbnailCropsData,
    setCreateState,
    onReOrderBlocks,
    onChangeImageCropIndex,
    addImageBlock,
    onPublish,
    isPublishBtnDisabled,
    isNextBtnDisabled,
    nextDisabledContent,
    editPreviousCoverSelected,
    slug,
    emptyBlockIndexes,
    modalData,
    creatorData,
    isAddingBlockDone,
    setModalData,
    showAdminSettings,
    pathname,
    onAssetChange,
    postId,
  } = props;

  return (
    <>
      {isLoading && <Loader isLoading />}
      <CreateNavigationHeader>
        <CreateNavigation
          pathname={pathname}
          currentStep={createState.currentStep}
          isNavActive
          isLastStepDisabled={isNextBtnDisabled}
          onNavItemClick={onNavItemClick}
          gotoPrevSection={gotoPrevSection}
          isNextBtnDisabled={isNextBtnDisabled}
          isEdit={isEdit}
          isDraft={isDraft}
          createType={createState.createType}
          title={createInfoFormState.title}
          setModalData={setModalData}
          slug={slug}
        />
      </CreateNavigationHeader>
      <CreateNavigationContent className="container">
        {modalData && modalData.isShowing && <CreateModal {...modalData} />}
        <CreatePageSection>
          {currentStepName === 'info' && (
            <CreateInfo
              gotoNextSection={gotoNextSection}
              gotoPrevSection={gotoPrevSection}
              createInfoFormState={createInfoFormState}
              createType={createState.createType}
              setCreateInfoFormState={setCreateInfoFormState}
              showAdminSettings={showAdminSettings}
              createAdminFormState={createAdminFormState}
              setCreateAdminFormState={setCreateAdminFormState}
            />
          )}
          {currentStepName === 'editor' && (
            <CreateEditor
              gotoNextSection={gotoNextSection}
              gotoPrevSection={gotoPrevSection}
              createState={createState}
              onAddBlock={onAddBlock}
              onEditBlock={onEditBlock}
              onRemoveBlock={onRemoveBlock}
              onChangeBlockChangeOrder={onChangeBlockChangeOrder}
              onEditVideoBlock={onEditVideoBlock}
              onEditSketchfabBlock={onEditSketchfabBlock}
              title={createInfoFormState.title}
              onAssetChange={onAssetChange}
              creatorData={creatorData}
              createAdminFormState={createAdminFormState}
              isAddingBlockDone={isAddingBlockDone}
              onEditUserContentblock={onEditUserContentblock}
              postId={postId}
            />
          )}
          {currentStepName === 'cover' && (
            <CreateCrop
              slug={slug}
              isEditOrDraft={isEdit || isDraft}
              editPreviousCoverSelected={editPreviousCoverSelected}
              onChangeImageCropIndex={onChangeImageCropIndex}
              imageBlocks={createState.coverImagesBlockList}
              currentImageCropIndex={
                !isEdit || !isDraft
                  ? createState.currentImageCropIndex
                  : undefined
              }
              creatorData={creatorData}
              createInfoFormState={createInfoFormState}
              createState={createState}
              thumbnailCropData={thumbnailCropData}
              setThumbnailCropsData={setThumbnailCropsData}
              createAdminFormState={createAdminFormState}
              addImageBlock={addImageBlock}
              onChange={e => {
                setCreateState({
                  ...createState,
                  createCover: { ...e },
                });
              }}
            />
          )}
        </CreatePageSection>
        <CreateSidebar
          emptyBlockIndexes={emptyBlockIndexes}
          currentStep={currentStepName}
          gotoNextSection={gotoNextSection}
          gotoPrevSection={gotoPrevSection}
          createState={createState}
          onReOrderBlocks={onReOrderBlocks}
          onChangeImageCropIndex={onChangeImageCropIndex}
          addImageBlock={addImageBlock}
          onPublish={onPublish}
          publishBtnContent={`create_sidebar_button_${
            currentStepName !== 'cover'
              ? 'next'
              : `${isEdit ? 'edit' : 'publish'}`
          }`}
          showSaveDraftBtn={
            (currentStepName === 'editor' || currentStepName === 'cover') &&
            createState.blockList.length >= 1
          }
          isPublishBtnDisabled={isPublishBtnDisabled}
          isAddingBlockDone={isAddingBlockDone}
          isNextBtnDisabled={isNextBtnDisabled}
          nextDisabledContent={nextDisabledContent}
        />
      </CreateNavigationContent>
    </>
  );
}

CreateForm.propTypes = {
  isEdit: PropTypes.bool,
  isDraft: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPublishBtnDisabled: PropTypes.bool,
  isNextBtnDisabled: PropTypes.bool,
  currentStepName: PropTypes.oneOf([
    'intro',
    'warning',
    'info',
    'editor',
    'cover',
  ]).isRequired,
  createState: PropTypes.shape({
    // createType: PropTypes.oneOf(['article', 'tutorial', 'artwork']),
    imageBlockList: PropTypes.arrayOf(PropTypes.object),
    currentImageCropIndex: PropTypes.number,
    currentStep: PropTypes.number.isRequired,
  }).isRequired,
  onNavItemClick: PropTypes.func.isRequired,
  gotoPrevSection: PropTypes.func.isRequired,
  gotoNextSection: PropTypes.func.isRequired,
  setCreateType: PropTypes.func,
  setCreateInfoFormState: PropTypes.func.isRequired,
  onAddBlock: PropTypes.func.isRequired,
  onEditBlock: PropTypes.func.isRequired,
  onRemoveBlock: PropTypes.func.isRequired,
  onChangeBlockChangeOrder: PropTypes.func.isRequired,
  onEditUserContentblock: PropTypes.func.isRequired,
  onEditVideoBlock: PropTypes.func.isRequired,
  onEditSketchfabBlock: PropTypes.func.isRequired,
  setThumbnailCropsData: PropTypes.func.isRequired,
  setCreateState: PropTypes.func.isRequired,
  onReOrderBlocks: PropTypes.func.isRequired,
  onChangeImageCropIndex: PropTypes.func.isRequired,
  addImageBlock: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  thumbnailCropData: PropTypes.object.isRequired,
  createInfoFormState: PropTypes.object.isRequired,
  editPreviousCoverSelected: PropTypes.bool,
  nextDisabledContent: PropTypes.string,
  slug: PropTypes.string,
  emptyBlockIndexes: PropTypes.arrayOf(PropTypes.number),
  showAdminSettings: PropTypes.bool,
  pathname: PropTypes.string,
  creatorData: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
  }),
};

CreateForm.defaultProps = {
  isEdit: false,
  isDraft: false,
  isLoading: false,
  isPublishBtnDisabled: false,
  isNextBtnDisabled: false,
  slug: undefined,
  editPreviousCoverSelected: false,
  nextDisabledContent: undefined,
  emptyBlockIndexes: [],
  showAdminSettings: false,
  setCreateType: () => {},
};

export default CreateForm;
