import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  InputRadioStyled,
  InputRadioOptionsWrapper,
  LabelStyled,
  OptionLabelStyled,
  InputRadioWrapper,
  InputRadioOptionWrapper,
  InputRadioErrorMsg,
} from './InputRadio.styled';

const InputRadio = props => {
  const [selectionOption, setSelectionOption] = useState(props.selectedValue || null);

  const onChange = e => {
    setSelectionOption(e.target.value);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <InputRadioWrapper>
      {props.label && (
        <LabelStyled isError={props.error ? true : false}>
          {props.label}
        </LabelStyled>
      )}
      <InputRadioOptionsWrapper
        css={props.css}
        mobileVerticalDispay={props.mobileVerticalDispay}
        isProfileEdit={props.isProfileEdit}
      >
        {props.options.map((option, i) => {
          return (
            <InputRadioOptionWrapper
              key={option.id + option.value}
              mobileVerticalDispay={props.mobileVerticalDispay}
            >
              <InputRadioStyled
                checked={option.value === selectionOption}
                onChange={onChange}
                type="radio"
                id={option.id + option.value}
                name={props.name}
                value={option.value}
              />
              <label className="label-radio" htmlFor={option.id + option.value}></label>
              <OptionLabelStyled htmlFor={option.id + option.value}>
                {option.label}
              </OptionLabelStyled>
            </InputRadioOptionWrapper>
          );
        })}
      </InputRadioOptionsWrapper>
      {props.error && (
        <InputRadioErrorMsg isError={props.error ? true : false}>
          {props.error}
        </InputRadioErrorMsg>
      )}
    </InputRadioWrapper>
  );
};

InputRadio.defaultProps = {
  onChange: () => {},
};

InputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default InputRadio;
