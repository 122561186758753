import {
  MoreButton,
  SingleBadgeWrapper,
  ThreeBadgesWrapper,
  TwoBadgesWrapper,
} from './ThumbnailBadgeList.styled';
// ds_clean
// This component isn't used
import React, { useState } from 'react';

import Badge from '@components/molecules/badgeToolsIcon/BadgeToolsIcon';

const ThumbnailBadgeList = props => {
  const [badgeToolsListState, setBadgeToolsListState] = useState({
    showAll: false,
  });

  const onClickShowAll = () => {
    setBadgeToolsListState({
      showAll: true,
    });
  };
  const maxToolsCount = 2;

  return (
    <div>
      {props.badgeList.length > maxToolsCount ? (
        <ThreeBadgesWrapper>
          {props.badgeList.map((badge, i) => {
            if (i >= maxToolsCount && !badgeToolsListState.showAll) return;
            return (
              <div key={badge.alt + i}>
                <Badge {...badge} />
              </div>
            );
          })}
          {/* {props.badgeList.length > maxToolsCount &&
          !badgeToolsListState.showAll ? (
            <MoreButton
              onClick={props.isFeatured ? onClickShowAll : null}
              style={props.isFeatured ? { cursor: 'pointer' } : null}
            >
              +{props.badgeList.length - maxToolsCount}
            </MoreButton>
          ) : null} */}
        </ThreeBadgesWrapper>
      ) : null}
      {props.badgeList.length == maxToolsCount ? (
        <TwoBadgesWrapper
          siblings={
            props.levelTitle !== undefined || props.seriesTitle !== undefined
          }
        >
          {props.badgeList.map((badge, i) => {
            return (
              <div key={i}>
                <Badge {...badge} />
              </div>
            );
          })}
        </TwoBadgesWrapper>
      ) : null}
      {props.badgeList.length == 1 ? (
        <SingleBadgeWrapper
          siblings={
            props.levelTitle !== undefined || props.seriesTitle !== undefined
          }
        >
          {props.badgeList.map((badge, i) => {
            return <Badge {...badge} key={i} />;
          })}
        </SingleBadgeWrapper>
      ) : null}
    </div>
  );
};

export default ThumbnailBadgeList;
