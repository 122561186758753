import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import { Link } from 'gatsby';
import Svg from '@components/atoms/svg/Svg';

export const PageWrapper = styled('div', {
  width: '100%',
});

export const StyledSvg = styled(Svg, {
  fill: '$mainTextColor',
  width: '$24',
  height: '$24',
  marginRight: '$12',
});

export const TopBanner = styled('div', {
  width: '100%',
  height: '$24',
  backgroundColor: '$purple',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '$16',

  '@bp2': {
    marginBottom: '$32',
  },
});

export const BannerText = styled(Text, {
  fontFamily: '$ArtifaktElementBold',
  color: 'white',
  textTransform: 'uppercase',
});

export const ContentWrapper = styled('div', {
  width: '100%',
  maxWidth: '$container',
  padding: '0 $16 $64',
  margin: 'auto',

  '@bp2': {
    padding: '0 $64 $84 $24',
  },
});

export const ContentTopContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '$16',

  '@bp2': {
    flexDirection: 'row',
    marginBottom: '$48',
  },
});

export const ContentBottomContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@bp2': {
    flexDirection: 'row',
  },
});

export const BackBtn = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  width: '232px',
  marginRight: '$32',
  flexShrink: '0',
  marginBottom: '$16',

  '@bp2': {
    marginBottom: '0',
  },
});

export const BackLinkText = styled(Text, {
  fontFamily: '$ArtifaktElementBold',
  color: '$mainTextColor',
});

export const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const Title = styled(Text, {
  fontFamily: '$ArtifaktLegendBold',
  color: '$mainTextColor',
  fontSize: '$32',
  lineHeight: '$2',

  '@bp2': {
    fontSize: '$48',
  },
});

export const IconImage = styled('div', {
  img: {
    width: '$48',
    height: '$48',
    borderRadius: '$8',
    marginRight: '$16',
  },
});

export const SidebarWrapper = styled('div', {
  width: '100%',
  marginRight: '$32',
  flexShrink: '0',
  marginBottom: '$24',

  '@bp2': {
    marginBottom: '0',
    width: '232px',
  },
});

export const TableWrapper = styled('div', {
  width: '100%',
  overflowX: 'clip',
  overflowY: 'visible',
  paddingRight: '$4',
});

export const LoadingOverlay = styled('div', {
  width: '100%',
  height: '400px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
