import { NavWrapper, StyledLink } from './Nav.styled';
import PropTypes, { element } from 'prop-types';

import React from 'react';
import Svg from '@components/atoms/svg/Svg';
import { useIsMobile } from '@src/utils/useIsMobile';

const getInternalLink = url => url.replace(CMS_URL, '');

export const Nav = props => {
  const {
    display,
    navItems,
    className,
    location,
    isHeader,
    isExternal,
  } = props;
  const isMobile = useIsMobile();

  return (
    <NavWrapper display={display}>
      <ul tabIndex="0" className={className}>
        {navItems.map((navItem, index) => {
          const isCurrentPage = location?.pathname?.includes(
            navItem.title.toLowerCase()
          );

          return (
            <li key={`main-${navItem.title}-${index}`}>
              <span>
                {navItem.url.includes(CMS_URL) ? (
                  <StyledLink
                    alt={navItem.title}
                    to={getInternalLink(navItem.url)}
                    isCurrentPage={isCurrentPage}
                  >
                    {navItem.title}
                  </StyledLink>
                ) : (
                  <a
                    alt={navItem.title}
                    href={getInternalLink(navItem.url)}
                    className={
                      navItem.newWindow == 1 && props.isSideBarNav
                        ? 'external-link-sidebar'
                        : ''
                    }
                    target={
                      (navItem.newWindow == 1 && props.isSideBarNav) ||
                      isExternal
                        ? '_blank'
                        : ''
                    }
                    rel="noreferrer"
                  >
                    {navItem.title}
                    {navItem.newWindow == 1 && props.isSideBarNav ? (
                      <Svg
                        icon="arrow-diag"
                        css={{
                          width: '18px',
                          height: '18px',
                          fill: '$mediumGrey',
                          transition: 'fill 0.2s ease-out',
                        }}
                      />
                    ) : null}
                  </a>
                )}
              </span>
              {navItem.children && !isHeader && (
                <ul style={{ marginLeft: '8px' }}>
                  {navItem.children.map((navSubItem, index) => {
                    return (
                      <li key={`second-${navItem.title}-${index}`}>
                        {navSubItem.url.includes(CMS_URL) ? (
                          <StyledLink
                            alt={navSubItem.title}
                            to={getInternalLink(navSubItem.url)}
                            isCurrentPage={
                              getInternalLink(navSubItem.url) ===
                              location?.pathname
                            }
                          >
                            {navSubItem.title}
                          </StyledLink>
                        ) : (
                          <a
                            alt={navSubItem.title}
                            href={getInternalLink(navSubItem.url)}
                          >
                            {navSubItem.title}
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
              {navItem?.children?.length >= 1 && !isMobile && isHeader && (
                <div className="header-submenu">
                  <ul>
                    {navItem.children.map((navSubItem, index) => {
                      return (
                        <li key={`third-${navItem.title}-${index}`}>
                          <StyledLink
                            target={navSubItem.isExternal && '_blank'}
                            alt={navSubItem.title}
                            to={getInternalLink(navSubItem.url)}
                            isCurrentPage={
                              getInternalLink(navSubItem.url) ===
                              location?.pathname
                            }
                          >
                            {navSubItem.title}
                            {navSubItem.isExternal && (
                              <Svg
                                icon="arrow-diag"
                                css={{
                                  width: '18px',
                                  height: '18px',
                                  fill: '$mediumGrey',
                                  transition: 'fill 0.2s ease-out',
                                }}
                              />
                            )}
                          </StyledLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </NavWrapper>
  );
};

Nav.propTypes = {
  display: PropTypes.string,
  location: PropTypes.object,
  className: PropTypes.string,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
};
