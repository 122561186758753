import {
  SignAsideTitleLabel,
  SignUpBoxWrapper,
  SignUpPageWrapper,
} from '@src/templates/signup-validation/SignUpValidation.styled';

import Layout from '@components/organisms/layout/Layout';
import Logo from '@src/assets/svg/area_logo_full.svg';
import React from 'react';
import SEO from '@components/atoms/seo/Seo';
import SignAside from '@components/molecules/signAside/SignAside';
import String from '@components/atoms/string/String';
import UserPhoneVerification from '@components/organisms/signUpBox/userSignUpVerification/UserPhoneVerification';

const SignAsideProps = {
  artworkByProps: {
    avatarProps: {
      shape: 'circle',
      alt: 'Alternative User Name',
      src: {
        childImageSharp: {
          fixed: {
            height: 60,
            width: 60,
            src: '/images/profile_image_placeholder.jpg',
            srcSet:
              '/images/profile_image_placeholder.jpg 1x,/images/profile_image_placeholder.jpg 1.5x,/images/profile_image_placeholder.jpg 2x',
          },
        },
      },
    },
    label: 'Artwork by',
    artistName: 'Yuriy Dulich',
    artistProfession: 'FX Artist / 3D Artist',
  },
  image: {
    src: {
      src: '/images/inward-the-observer.jpg',
      srcset:
        '/images/inward-the-observer.jpg 1x,/images/inward-the-observer.jpg  250w,/images/inward-the-observer.jpg  500w',
    },

    alt: 'Yuriy Dulich',
  },
};

const VerifyByPhonePage = () => {
  return (
    <Layout>
      <SEO title="Area - Sign up" slug="verify-by-phone" />

      <SignUpPageWrapper className="container">
        <SignAside {...SignAsideProps}>
          <SignAsideTitleLabel>
            <String id="aside_generic_subtitle" />
          </SignAsideTitleLabel>
          <Logo />
        </SignAside>
        <SignUpBoxWrapper>
          <UserPhoneVerification />
        </SignUpBoxWrapper>
      </SignUpPageWrapper>
    </Layout>
  );
};

export default VerifyByPhonePage;
