import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';

export const StyledButton = styled(Button, {
  width: '100%',
  '.btn-counter': {
    background: '$mediumGrey',
    width: '18px',
    height: '18px',
    display: 'block',
    borderRadius: '100px',
    fontSize: '$12',
    textAlign: 'center',
    lineHeight: '18px',
  }
});

export const FilterBarWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@bp1': {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export const MobileWrapper = styled('div', {
  position: 'fixed',
  width: '90%',
  right: 0,
  top: '$48',
  padding: '$24',
  background: '$white',
  height: '100%',
  zIndex: '$max',
  '&:before': {
    content: '""',
    display: 'block',
    background: 'rgba(0,0,0, .5)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '-100%',
    top: '0',
  },
});

export const MobileWrapperHeader = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const FiltersWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$8',
  '&:nth-of-type(n+1)': {
    marginTop: '$24',
  },
  '@bp1': {
    flexDirection: 'row',
    marginTop: '0',
    '&:nth-of-type(n+1)': {
      marginTop: '0',
    },
  },
});
