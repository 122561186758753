import { styled, keyframes } from '@root/stitches.config';
const fadeIn = keyframes({
  '0%': {
    opacity: '0',
  },
  '100%': {
    opacity: '1',
  },
});
export const ModalWrapper = styled('div', {
  position: 'fixed',
  display: 'block',
  zIndex: '10000000',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  background: 'hsla(0, 0%, 0%, .25)',
  animation: `${fadeIn} ease .4s`,
});

export const ModalInner = styled('div', {
  width: '100%',
  background: '$white',
  position: 'absolute',
  maxHeight: '100vh',
  overflowY: 'auto',
  top: 0,
  padding: '$32 $48',
  left: 0,
  '::-webkit-scrollbar-track': {
    display: 'none',
  },
  '@bp1': {
    maxWidth: '640px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    br: 18,
  },
  '@bp2': {
    padding: '$32 $92',
  },
  variants: {
    hasImageHeader: {
      true: {
        paddingTop: '264px',
        'img:first-of-type': {
          position: 'absolute',
          width: '100%',
          maxHeight: '240px',
          objectFit: 'cover',
          marginBottom: '$24',
        },
      },
    },
    isSelectUserContent: {
      true: {
        width: '90%',
        padding: 'unset',
        minWidth: '0',
        maxWidth: '90%',
      },
    },
  },
});
