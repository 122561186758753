import React, { useEffect, useState } from 'react';
import {
  SelectUserTableContainer,
  SelectUserTableHeader,
  SelectUserTableWrapper,
} from './SelectUserTable.styled';

import AdvanceFilters from '../advanceFilters/AdvanceFilters';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import { GET_ALL_USERS } from '@src/apollo/query';
import Options from '@components/organisms/table/components/columnItem/Options';
import String from '@components/atoms/string/String';
import Table from '@components/organisms/table/Table';
import { Text } from '@components/atoms/text/Text';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import { useQuery } from '@apollo/client';

const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(date, 'LLL, d y');
};

const SelectUserTable = props => {
  const [usersList, setUsersList] = useState([]);

  const [usersContent, setUsersContent] = useState({
    isLoaded: false,
    limitRow: 12,
    page: 1,
    currentCount: '1-12',
    maxPage: 1,
    totalCount: 0,
    columns: [
      {
        header: <String id="community_admin_profile_tab" />,
        accessor: 'profile',
      },
      {
        header: <String id="community_admin_usercontents_tab" />,
        accessor: 'user_contents',
      },
      {
        header: <String id="community_admin_last_usercontent_tab" />,
        accessor: 'last_user_content',
      },
      {
        header: <String id="community_admin_posts_tab" />,
        accessor: 'posts',
      },
      {
        header: <String id="community_admin_last_post_tab" />,
        accessor: 'last_post',
      },
      {
        header: <String id="community_admin_joined_tab" />,
        accessor: 'joined',
      },
      {
        header: 'Status',
        accessor: 'status',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],
  });

  const onChangeReportType = e => {
    const updatedFilters = { ...filtersState };
    updatedFilters.filters.forEach(filter => {
      if (filter.name == 'roles') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value == e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
    setFiltersState({ filters: updatedFilters.filters });
  };

  const filters = [
    {
      title: <String id="community_admin_role_tab" />,
      type: 'radio',
      name: 'roles',
      onChange: onChangeReportType,
      selectedValue: '',
      options: [
        {
          name: 'All',
          id: 'All',
          label: 'All',
          value: '',
          isChecked: false,
        },
        {
          name: 'Member',
          id: 'Member',
          label: <String id="community_admin_filter_member" />,
          value: 'member',
          isChecked: false,
        },
        {
          name: 'Admin',
          id: 'Admin',
          label: <String id="admin_cms_admin" />,
          value: 'admin',
          isChecked: false,
        },
        {
          name: 'Editor',
          id: 'Editor',
          label: <String id="admin_cms_editor" />,
          value: 'editor',
          isChecked: false,
        },
        {
          name: 'Contributor',
          id: 'Contributor',
          label: <String id="admin_cms_contributor" />,
          value: 'contributor',
          isChecked: false,
        },
      ],
    },
  ];

  const [filtersState, setFiltersState] = useState({
    filters,
  });

  const userData = useQuery(GET_ALL_USERS, {
    context: { clientName: 'cms' },
    variables: {
      input: usersContent.filters,
      limit: usersContent.limitRow,
      page: usersContent.page,
    },
    fetchPolicy: 'cache-and-network',
  });

  const singleRowActions = [
    {
      label: props.isSelectNewAuthor ? (
        <String id="select_new_author" />
      ) : (
        <String id="select_motm" />
      ),
      onClickAction: item => {
        props.onSelectUser(item);
      },
    },
  ];

  const getAvatar = (profilePicture, size) => {
    return getAvatarImage(profilePicture, size);
  };

  const onNextPage = () => {
    const pageCountFirstNumber =
      (usersContent.page - 1) * usersContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + usersContent.limitRow - 1;
    if (pageCountSecondNumber > userData.data.users?.total) {
      pageCountSecondNumber = userData.data.users?.total;
    }

    setUsersContent({
      ...usersContent,
      page: usersContent.page + 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onPrevPage = () => {
    const pageCountFirstNumber =
      (usersContent.page - 2) * usersContent.limitRow + 1;
    let pageCountSecondNumber =
      pageCountFirstNumber + usersContent.limitRow - 1;
    if (pageCountSecondNumber > userData.data.users?.total) {
      pageCountSecondNumber = userData.data.users?.total;
    }

    setUsersContent({
      ...usersContent,
      page: usersContent.page - 1,
      currentPageCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
      loading: true,
    });
  };

  const onChangeLimitRow = rowCount => {
    setUsersContent({
      ...usersContent,
      limitRow: rowCount,
      page: 1,
      maxPage: Math.ceil(usersContent.totalCount / rowCount),
    });
  };

  const onApplyFilters = () => {
    const allSelectedFilters = {};
    let roles = null;
    filtersState.filters.forEach(filter => {
      if (filter.name == 'roles') {
        roles = filter.selectedValue;
      }
    });
    if (roles) {
      allSelectedFilters.roles = roles;
    }
    setUsersContent({
      ...usersContent,
      filters: allSelectedFilters,
    });
    userData.refetch();
  };

  const onSearchSubmit = searchInput => {
    setUsersContent({
      ...usersContent,
      filters: searchInput !== '' ? { search: searchInput } : {},
      page: 1,
    });
    userData.refetch();
  };

  useEffect(() => {
    if (userData.data) {
      const rowDatas = [];
      userData.data.users.data.forEach(element => {
        let name = '';

        if (element?.first_name !== null && element?.first_name !== '') {
          name = `${element?.first_name} ${element?.last_name}`;
        }

        const singleRow = {
          profile: (
            <UserInfos
              name={name}
              job={element.title}
              url={element.userProfile.profile_picture}
              link={`/m/${element.username}`}
            />
          ),
          user_contents: <Text>{element.userContents?.total}</Text>,
          last_user_content: (
            <DateIconLink
              date={
                element.userContents?.data?.[0]
                  ? convertUTCToLocalDate(
                      element.userContents?.data?.[0]?.createdAt * 1000
                    )
                  : null
              }
            />
          ),
          posts: <Text>{element.posts?.total}</Text>,

          last_post: (
            <DateIconLink
              date={
                element.posts?.data?.[0]
                  ? convertUTCToLocalDate(
                      element.posts?.data?.[0]?.createdAt * 1000
                    )
                  : null
              }
            />
          ),
          status: (
            <Text css={{ textTransform: 'capitalize' }}>{element.status}</Text>
          ),
          joined: (
            <DateIconLink
              date={convertUTCToLocalDate(element.createdAt * 1000)}
            />
          ),
          options: <Options actions={singleRowActions} {...element} />,
          id: element.id,
        };
        rowDatas.push(singleRow);
      });

      const pageCountFirstNumber =
        (usersContent.page - 1) * usersContent.limitRow + 1;
      let pageCountSecondNumber =
        pageCountFirstNumber + usersContent.limitRow - 1;
      if (pageCountSecondNumber > userData.data.users?.total) {
        pageCountSecondNumber = userData.data.users?.total;
      }

      setUsersContent({
        ...usersContent,
        isLoaded: true,
        totalCount: userData.data.users?.total,
        currentCount: `${pageCountFirstNumber} - ${pageCountSecondNumber}`,
        maxPage: Math.ceil(userData.data.users?.total / usersContent.limitRow),
      });

      setUsersList(rowDatas);
    }
  }, [userData.data]);

  return (
    <SelectUserTableWrapper>
      <SelectUserTableHeader />
      <SelectUserTableContainer>
        <AdvanceFilters
          title="Members"
          onSubmitFilters={onApplyFilters}
          filters={filtersState.filters}
          onSearchSubmit={onSearchSubmit}
        />
        <Table
          nextPage={onNextPage}
          prevPage={onPrevPage}
          changeRowPerPage={onChangeLimitRow}
          totalCount={usersContent.totalCount}
          currentCount={usersContent.currentCount}
          loading={userData.loading}
          rowData={usersList}
          columns={usersContent.columns}
          maxPage={usersContent.maxPage}
          currentPage={usersContent.page}
          hasOptions
          isNotMultiSelect
        />
      </SelectUserTableContainer>
    </SelectUserTableWrapper>
  );
};

SelectUserTable.propTypes = {};

export default SelectUserTable;
