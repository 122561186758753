import { GET_PROFILE_INFO } from '@src/apollo/query';
import { ProfileInner, ProfileWrapper } from './Profile.styled';
import React, { useContext, useEffect, useState } from 'react';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';
import AuthContext from '@context/AuthContext';
import Layout from '@components/organisms/layout/Layout';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import ProfileCompletionContext from '@context/ProfileCompletionContext';
import ProfileContent from './ProfileContent';
import ProfileSidebar from '@components/organisms/profileSidebar/ProfileSidebar';
import SEO from '@components/atoms/seo/Seo';
import { navigate } from 'gatsby-link';
import { useQuery } from '@apollo/client';

const ProfilePage = context => {
  const authContext = useContext(AuthContext);
  const { loading, error, data, refetch } = useQuery(GET_PROFILE_INFO, {
    variables: { username: context.slug },
    context: { clientName: authContext.isAuthenticated ? 'private' : 'public' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const isCurrentUserProfile = !!(
    authContext.isAuthenticated &&
    (authContext.userSlug === context.slug ||
      authContext.generalRole == 'admin')
  );

  const [isPublic, setIsPublic] = useState(!isCurrentUserProfile);
  const [isRefecth, setIsRefecth] = useState(false);

  if (
    !authContext.isVerified &&
    authContext.isAuthenticated &&
    isCurrentUserProfile
  ) {
    navigate('/onboarding/signup-validation');
  }

  const [profileCompletionState, setProfileCompletionState] = useState({
    profile_completion: null,
    aboutMe: false,
    avatar: false,
    tools: false,
    industries: false,
    loaded: false,
  });

  useEffect(() => {
    if (data) {
      setProfileCompletionState({
        profile_completion: data.user.profile_completion,
        aboutMe: !!data.user.aboutMe.length,
        industries: !!data.user.industries.length,
        tools: !!data.user.tools.length,
        avatar: !!data.user.userProfile.profile_picture,
        loaded: true,
      });

      if (!data.user || data.user?.is_internal) {
        navigate('/404');
      }
    }
  }, [data]);

  const profileMobileSidebarRef = React.createRef();

  const togglePublic = () => {
    setIsRefecth(true);
    refetch();
    setIsPublic(!isPublic);
  };

  // const profileEditSave = () => {
  //   setIsRefecth(true);
  // };

  const updateProfileSave = () => {
    setIsRefecth(true);
    refetch();
  };

  const isLoadedAndHasData =
    !loading && !error && data !== undefined && data.user !== null;

  return (
    <Layout location={context.location}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <ProfileCompletionContext.Provider
        value={[profileCompletionState, setProfileCompletionState]}
      >
        <SEO title="Profile" slug="profile" />
        <LandingPageWrapper>
          <SectionWrapper>
            <ProfileInner>
              <ProfileSidebar
                isCurrentUserProfile={isCurrentUserProfile}
                profileData={data ? data.user : null}
                mobileRef={profileMobileSidebarRef}
                href={context.location.href}
                isPublic={isPublic}
                togglePublic={togglePublic}
                profileEditSave={updateProfileSave}
                hasData={isLoadedAndHasData || isRefecth}
                isAdminNotCurrentProfile={
                  !(authContext.userSlug === context.slug) &&
                  authContext.generalRole === 'admin'
                }
              />

              {isLoadedAndHasData ? (
                <ProfileContent
                  isCurrentUserProfile={isCurrentUserProfile && !isPublic}
                  authContext={authContext}
                  context={context}
                  profileData={data ? data.user : null}
                  isPublic={isPublic}
                  updateProfileSave={updateProfileSave}
                  hasData={isLoadedAndHasData || isRefecth}
                />
              ) : (
                <LoaderSkeleton height="50px" width="100%" isRadius />
              )}

              {(!loading && error) ||
                (data && !data.user && (
                  <div
                    style={{
                      minHeight: '70vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <h1>User not found</h1>
                  </div>
                ))}
            </ProfileInner>
          </SectionWrapper>
        </LandingPageWrapper>
        <div className="mobile-sidebar-portal" />
      </ProfileCompletionContext.Provider>
    </Layout>
  );
};

export default ProfilePage;
