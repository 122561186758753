import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@components/atoms/button/Button';

const getPopupPositionProperties = ({ width = 600, height = 600 }) => {
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  return `left=${left},top=${top},width=${width},height=${height}`;
};
export class SocialAuthButton extends Component {
  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
    if (this.popup && !this.popup.closed) this.popup.close();
  }

  getUrl = () => {
    const { redirectUri, clientId, scope } = this.props;
    const supportIE = false;
    const scopeParam = `&scope=${supportIE ? scope : encodeURI(scope)}`;

    const state = 'fdsf78fyds7fm';

    //LINKEDIN
    const linkedInAuthenLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${state}`;
    const linkedinRedirectPath = `${FE_URL}/linkedin?linkedin_redirect_url=${encodeURIComponent(
      linkedInAuthenLink
    )}`;

    //GOOGLE
    const googleAuthenLink = `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20email%20profile&state=77770396dfae6867020faa0c6003ed00&response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}`;
    const googleRedirectPath = `${FE_URL}/google?redirect_uri=${encodeURIComponent(
      googleAuthenLink
    )}`;

    //Apple
    const appleAuthenLink = `${API_URL}/login-external/apple`;
    const appleRedirectPath = `${FE_URL}/apple?redirect_uri=${encodeURIComponent(
      appleAuthenLink
    )}`;

    const getProviderConfig = () => {
      switch (this.props.provider) {
        case 'linkedin':
          return {
            authLink: linkedInAuthenLink,
            redirectPath: linkedinRedirectPath,
          };
          break;

        case 'google':
          return {
            authLink: googleAuthenLink,
            redirectPath: googleRedirectPath,
          };
          break;

        case 'apple':
          return {
            authLink: appleAuthenLink,
            redirectPath: appleRedirectPath,
          };
          break;

        default:
          break;
      }
    };
    const authLink = getProviderConfig().authLink;
    const redirectPath = getProviderConfig().redirectPath;

    if (supportIE) {
      const redirectLink = `${window.location.origin}${redirectPath}`;
      return redirectLink;
    }
    return authLink;
  };

  receiveMessage = event => {
    const { state } = 'fdsf78fyds7fm';

    if (event.origin === window.location.origin) {
      if (event.data.errorMessage && event.data.from === this.props.provider) {
        // Prevent CSRF attack by testing state

        if (event.data.state !== state) {
          this.popup && this.popup.close();
          return;
        }
        this.props.onFailure(event.data);
        this.popup && this.popup.close();
      } else if (event.data.code && event.data.from === this.props.provider) {
        // Prevent CSRF attack by testing state
        if (event.data.state !== state) {
          this.popup && this.popup.close();
          return;
        }

        this.props.onSuccess({ token: event.data.code });
        this.popup && this.popup.close();
      }
    }
  };

  handleOnClick = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.onClick && this.props.onClick();
    this.popup = window.open(
      this.getUrl(),
      '_blank',
      getPopupPositionProperties({ width: 600, height: 600 })
    );
    window.removeEventListener('message', this.receiveMessage, false);
    window.addEventListener('message', this.receiveMessage, false);
  };

  render() {
    const { disabled, renderElement, label } = this.props;
    if (renderElement) {
      return renderElement({
        onClick: this.handleOnClick,
        disabled,
      });
    }
    return (
      <Button
        type="normal"
        label={label}
        colorTheme={this.props.provider}
        svgIcon={this.props.provider}
        actionType="onclick"
        onClick={this.handleOnClick}
        css={{ marginBottom: '$8', width: '100%' }}
      />
    );
  }
}

export default SocialAuthButton;
