import { WrapperBlock, WrapperTextAsset } from './BlockAsset.styled';

import Button from '@components/atoms/button/Button';
import React from 'react';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import formatFileSize from '@src/utils/formatFileSize';

const BlockAsset = props => {
  const { block } = props;

  const { filename, path, size } = block.asset;

  return (
    <WrapperBlock>
      <Button
        css={{ marginBottom: '$4' }}
        svgIcon="download"
        label={<String id="download_asset_label"></String>}
        colorTheme="gray"
        externalUrl={`${CDN_URL}${path}`}
        rel="noopener noreferrer"
        download={filename}
        target="_blank"
        actionType="externalLink"
      />
      <WrapperTextAsset>
        <Text size="12">{`${filename}`}</Text>
        <Text size="12">{formatFileSize(size)}</Text>
      </WrapperTextAsset>
    </WrapperBlock>
  );
};

export default BlockAsset;
