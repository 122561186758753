import { styled } from '@root/stitches.config';

export const PostsWireFrameWrapper = styled('div', {
  width: '100%',
  backgroundColor: '$elements',
  border: '1px solid $elements',
  borderRadius: '18px',
  overflow: 'hidden',
  marginBottom: '$16',
});

export const CommentWireFrameWrapper = styled('div', {
  backgroundColor: '$elements',
  width: '100%',
  overflow: 'hidden',
  marginBottom: '$16',
  display: 'flex',
  alignItems: 'flex-start',
});

export const TopContainer = styled('div', {
  padding: '$16 $16 $24',
  backgroundColor: '$box',
});
export const RightContainer = styled('div', {
  width: '100%',
  paddingBottom: '$4',
  ml: '$16',
  br: '$8',
});

export const BottomContainer = styled('div', {
  padding: '$8 $16',
  backgroundColor: '$box',
  display: 'flex',
  alignItems: 'center',
});

export const SmallLinesContainer = styled('div', {
  width: '100%',
});

export const HeaderAvatar = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  marginBottom: '$8',
});
