import { styled } from '@root/stitches.config';

export const SmallStatCenteredBlockWrapper = styled('div', {
  backgroundColor: '$greyBox',
  padding: '$48 $32',
  borderRadius: '$16',
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '$16',
});

export const Percentage = styled('div', {
  position: 'absolute',
  right: '-40px',
  top: '-15px',
  fontSize: '$16',
  lineHeight: '1.1em',
});
