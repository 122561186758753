import { styled } from '@root/stitches.config';

export const LottieWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    width: '100px !important',
    height: '100px !important',

    '& *': {
      stroke: '$blackToWhite',
    },
  },

  variants: {
    isFeedChange: {
      true: {
        svg: {
          width: '150px !important',
          height: '150px !important',
        },
      }
    }
  }
});
