import { AvatarWrapper, StyledButton } from './Avatar.styled';
import Button from '@components/atoms/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const Avatar = props => {
  return (
    <AvatarWrapper
      style={props.style}
      css={props.css}
      shape={props.shape}
      size={props.size}
    >
      <img
        alt={props.alt}
        title={props.alt}
        src={CDN_URL + props.src}
        width={props.width}
        height={props.height}
      />
      {props.isEditMode && (
        <StyledButton
          type="circle"
          colorTheme="normal"
          actionType="onclick"
          svgIcon="camera"
          onClick={props.onEditClick}
          circleSize="small"
        />
      )}
      {props.isRandomMode && (
        <Button
          type="circle"
          colorTheme="normal"
          actionType="onclick"
          svgIcon="refresh"
          onClick={props.onRefreshAvatar}
          css={{
            minWidth: '32px',
            minHeight: '32px',
            width: '32px',
            height: '32px',
            display: 'inline-block',
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            svg: {
              position: 'relative',
              width: '18px',
              height: '18px',
            },
          }}
        />
      )}
    </AvatarWrapper>
  );
};

Avatar.propTypes = {
  shape: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
  shape: 'circle',
  alt: 'Alternative User Name',
  src: 'images/profile_image_placeholder.jpg',
  isLoading: 'false',
};

export default Avatar;
