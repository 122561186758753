import {
  DateFilterWrapper,
  DatePickerWrapper,
} from '../AdvanceFilters.styled';
import React, { useState } from 'react';

import DatePicker from '@components/molecules/datePicker/DatePicker';
import { Text } from '@components/atoms/text/Text';
import { convertUTCToLocalDate } from '@src/utils/datesHelper';
import { useIsMobile } from '@src/utils/useIsMobile';

const DateFilter = props => {
  const [startDate, setStartDate] = useState(
    props.startDate ? new Date() : convertUTCToLocalDate(props.startDate)
  );
  const [endDate, setEndDate] = useState(
    props.endDate ? new Date() : convertUTCToLocalDate(props.endDate)
  );
  const isMobile = useIsMobile();

  const onChangeStartDate = date => {
    setStartDate(date);
    const timestamp = new Date(date).getTime() / 1000;
    props.onChange('startDate', timestamp);
  };

  const onChangeEndDate = date => {
    setEndDate(date);
    const timestamp = new Date(date).getTime() / 1000;
    props.onChange('endDate', timestamp);
  };

  return (
    <DateFilterWrapper>
      <Text
        css={{
          textTransform: 'uppercase',
          color: '$mediumGrey',
          marginBottom: '$8',
        }}
        size="14"
        level="h6"
        weight="bold"
      >
        {props.title}
      </Text>
      <DatePickerWrapper>
        <DatePicker
          selected={startDate}
          onChange={onChangeStartDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          showDayMonthYearPicker
          showCalendarIcon
          label="from"
          withPortal={isMobile}
        />

        <DatePicker
          selected={endDate}
          onChange={onChangeEndDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          showDayMonthYearPicker
          label="to"
          withPortal={isMobile}
          showCalendarIcon
        />
      </DatePickerWrapper>
    </DateFilterWrapper>
  );
};

DateFilter.propTypes = {};

export default DateFilter;
