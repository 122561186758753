import React, { useContext, useEffect, useState } from 'react';
import {
  SignUpBoxStyled,
  SignUpBoxWrapper,
  SignUpPageWrapper,
} from './Join.styled';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import AuthContext from '@context/AuthContext';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import Layout from '@components/organisms/layout/Layout';
import SEO from '@components/atoms/seo/Seo';
import UrlsContext from '@context/UrlsContext';
import UserSignUpConfirmation from '@components/organisms/signUpBox/userSignUpConfirmation/UserSignUpConfirmation';
import Image from '@components/atoms/image/Image';

const JoinPage = (context) => {

  const authContext = useContext(AuthContext);
  const UrlContext = useContext(UrlsContext);
  const [signInPageState, setSignInPageState] = useState({
    email: '',
    isSigningUp: true,
  });

  const signUpComplete = email => {
    window.scrollTo(0, 0);

    setSignInPageState({
      ...signInPageState,
      isSigningUp: false,
      email: email,
    });
  };

  if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    authContext.isOnboarded
  ) {
    navigate(`/m/${authContext.userSlug}`);
  } else if (
    authContext.isAuthenticated &&
    !authContext.isVerified &&
    authContext.firstName === null
  ) {
    navigate(GetUrl('onboardingRegistrationName', UrlContext));
  } else if (authContext.isAuthenticated && !authContext.isVerified) {
    navigate(GetUrl('onboardingSignupValidation', UrlContext));
  } else if (
    authContext.isAuthenticated &&
    authContext.isVerified &&
    !authContext.isOnboarded
  ) {
    navigate(GetUrl('onboardingSignupValidation', UrlContext));
  }

  return (
    <Layout>
      <SEO title="Area - Sign up" slug="join" seomatic={context?.pageContext?.entry?.seomatic}/>
      {signInPageState.isSigningUp ? (
        <SignUpPageWrapper className="container">
          <>
            <SignUpBoxWrapper>
              <div>
                <Image src={{src: '/images/AREA_registration_bg.png'}} alt="registration banner" />
              </div>

              <div>
                <SignUpBoxStyled signUpComplete={signUpComplete} />
              </div>
            </SignUpBoxWrapper>
          </>
        </SignUpPageWrapper>
      ) : (
        <UserSignUpConfirmation email={signInPageState.email} />
      )}
    </Layout>
  );
};

export default JoinPage;
