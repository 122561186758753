import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

export const CreateNavigationWrapper = styled('div', {
  borderTop: '1px solid $lightGrey',
  backgroundColor: '$box',
  height: 'auto',
  '@bp2': {
    height: '74px',
    borderBottom: '1px solid $lightGrey',
  },
});

export const LeftBlock = styled('div', {
  display: 'none',
  button: {
    padding: '0',
    minHeight: 'auto',
    textTransform: 'initial',
    '&:hover':{
      background: 'transparent',
      opacity: .5,
    }
  },
  '@bp2': {
    display: 'flex',
    alignItems: 'center'
  },
});

export const CenterBlock = styled('div', {
  display: 'flex',
  alignItems: 'center',
  order: 2,
  padding: '$16 0',
  width: 'calc(100% - 150px)',
  justifyContent: 'center',

  '@bp2': {
    width: 'auto',
    paddingBottom: '0',
    paddingTop: '0',
    'div + &': {
      marginTop: '0',
    },
  },
});

export const CreateNavigationContainer = styled('div', {
  display: 'flex',
  margin: 'auto',
  height: '100%',
  maxWidth: '1140px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$20 0',
  ol: {
    paddingLeft: 0,
  },
  '@bp2': {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export const Step = styled('div', {
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
  transition: 'opacity .4s ease',
  position: 'relative',
  marginRight: '$8',
  width: '100%',
  '@bp2': {
    marginRight: 0,
    width: 'auto',
  },

  '&:last-of-type': {
    marginRight: 0,
    '@bp2': {
      marginRight: 0,
    },
  },

  '&:hover': {
    opacity: '.4',
    cursor: 'pointer',
  },

  '.text': {
    display: 'none',
    '@bp2': {
      display: 'inline-block',
    },
  },

  variants: {
    isActive: {
      isActive: {
        '.circle': {
          backgroundColor: '$blackToWhite',
          color: '$whiteToBlack',
        },
      },
    },
    isDisabled: {
      true: {
        //opacity: 0.4,
        cursor: 'initial !important',
      },
    },
  },
});

export const CircleNumber = styled('div', {
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$elements',
  display: 'block',
  height: '2px',
  width: '100%',
  color: '$black',

  '@bp2': {
    borderRadius: '50%',
    width: '$32',
    height: '$32',
    display: 'flex',
    overflow: 'hidden',
    marginRight: '$12',
  },

  span: {
    display: 'none',
    '@bp2': {
      display: 'inline',
    },
  },
});

export const Separator = styled('div', {
  width: '64px',
  height: '1px',
  backgroundColor: '$elements',
  margin: '0 $16',
  display: 'none',
  '@bp2': {
    display: 'block',
  },
});
