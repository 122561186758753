import { Grid } from '@components/atoms/grid/Grid';
import LinkThumbnail from '../LinkThumbnail';
import PropTypes from 'prop-types';
import React from 'react';

const LinkThumbnailList = props => {
  const { listData } = props;
  return (
    <Grid
      css={{
        gap: '$16',
        '@bp1': {
          gap: '$32',
          gridTemplateColumns: '1fr 1fr',
        },
      }}
    >
      {listData.map((item, index) => {
        return <LinkThumbnail key={`${item.link.label + index}`} {...item} />;
      })}
    </Grid>
  );
};

export default LinkThumbnailList;

LinkThumbnailList.propTypes = {
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      staticImage: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        src: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.oneOfType([
              PropTypes.shape({}),
              PropTypes.arrayOf(PropTypes.shape({})),
            ]),
          }),
        }),
      }).isRequired,
      link: PropTypes.shape({
        actionType: PropTypes.oneOf(['internalLink', 'externalLink'])
          .isRequired,
        to: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
        label: PropTypes.string,
      }).isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
};
