import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';

export const StyledHeading = styled(Heading, {
  color: '$black',
});

export const ButtonsWrapper = styled('div', {
  display: 'flex',
});

export const StyledButton = styled(Button, {
  marginLeft: '$16',
  '&:first-of-type': {
    marginLeft: 0,
  },
});

export const ContentWrapper = styled('div', {
  marginTop: '$48',
  marginBottom: '$54',
});

export const StyledText = styled(Text, {
  color: '$black',
});

export const ModalContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});
