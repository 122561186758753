import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';
import Svg from '@components/atoms/svg/Svg';

export const GroupInfo = styled('div', {
  marginBottom: '$32',
});

export const StyledSvg = styled(Svg, {
  height: '20px',
  width: '20px',
  marginRight: '$12',
  fill: '$mainTextColor',
});

export const ShowGuidelinesBtn = styled('button', {
  backgroundColor: 'transparent',
  lineHeight: '1em',
  display: 'inline-flex',
  fontFamily: '$ArtifaktElementBold',
  padding: 0,
  cursor: 'pointer',
  border: 'none',
  textDecoration: 'none',
  alignItems: 'center',
  fontSize: '$12',
  color: '$blackToWhite',

  svg: {
    marginRight: '$12',
    fill: '$mainTextColor',
  },
});

export const GroupDescription = styled('div', {
  marginBottom: '$16',
  fontSize: '$14',
});

export const GroupIconsWrapper = styled('div', {
  marginBottom: '$12',

  '& > div': {
    '&:nth-of-type(2)': {
      fontFamily: '$ArtifaktElementBold',
    },
  },
});

export const GroupIconsRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$12',
  fontSize: '$12',
});

export const GroupModeratorsWrapper = styled('div', {
  marginBottom: '$32',
});
export const GroupModerator = styled('div', {
  marginBottom: '$24',
});

export const GroupMembersWrapper = styled('div', {});

export const GroupMembersAvatars = styled('div', {
  display: 'flex',
  paddingRight: '$32',
});

export const ShowMoreButton = styled('div', {
  width: '48px',
  height: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  boxShadow: '$2',
  fontFamily: '$ArtifaktLegendBold',
  fontSize: '$14',
  transition: 'box-shadow .3s ease',
  backgroundColor: '$box',
  flexShrink: '0',
});

export const SideNavContainer = styled('div', {
  marginTop: '$40',
  width: '100%',
});

export const BtnContainer = styled('div', {
  margin: '0 $28 0 0',
});

export const BtnText = styled('span', {
  fontFamily: 'var(--ArtifaktLegendRegular)',
  fontSize: '$12',
  color: '$mediumGrey',
  position: 'absolute',
  bottom: '-$20',
  left: '50%',
  whiteSpace: 'nowrap',
  transform: 'translateX(-50%)',
  transition: 'opacity 0.2s ease-out',
});

export const BtnLink = styled('a', {
  display: 'block',
  width: '$24',
  height: '$24',
  position: 'relative',

  svg: {
    width: '100%',
    height: '100%',
    fill: '$mediumGrey',
    transition: 'opacity 0.2s ease-out',
  },

  '&:hover': {
    span: {
      opacity: '.5'
    },
    svg: {
      opacity: '.5'
    },
  },
});

export const SideSocialsContainer = styled('div', {
  marginTop: '$20',
  display: 'flex',
  alignItems: 'center',
});

export const SideNav = styled('div', {
  fontFamily: 'var(--ArtifaktLegendRegular)',
  '& .side-nav-sidebar': {
    display: 'flex',
    flexWrap: 'wrap',
    li: {
      mr: '$16',
      fontSize: '13px',
      textTransform: 'initial',
      position: 'relative',
    },
  },

  '& .add-side-seperator': {
    '&:before': {
      content: '""',
      display: 'block',
      width: '1px',
      height: '50%',
      backgroundColor: '$mediumGrey',
      position: 'absolute',
      top: '25%',
      right: '-$8',
    },
  },

  '& .external-link-sidebar': {
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      svg: {
        opacity: '.5'
      },
    },
  },

  span: {
    display: 'block',
  },
  a: {
    color: '$mediumGrey',
    textDecoration: 'none',

    '&:hover': {
      transition: 'opacity .3s ease',
      opacity: '.5'
    },
  },
});
