import { Container, Label, ProgressBarWrapper } from './ProgressBar.styled';

import React from 'react';
import { styled } from '@root/stitches.config';

const ProgressBar = props => {
  const { completed } = props;

  const Filler = styled('div', {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: '$green',
    borderRadius: 'inherit',
    textAlign: 'right',
  });
  return (
    <ProgressBarWrapper>
      <Container>
        <Filler />
      </Container>
      <Label>{`${completed}%`}</Label>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
