import React from 'react';

import PropTypes from 'prop-types';

import quillRemoveBalise from '@src/utils/quillRemoveBalise';

import { Counter } from './CharsCount.styled';

const CharsCount = props => {
  const { stringToCount, maxLength, isQuill, css } = props;

  const currentLength = isQuill
    ? quillRemoveBalise(stringToCount).length
    : stringToCount.length;
  const isMax = currentLength >= maxLength;

  return <Counter css={css} isMax={isMax}>{`(${currentLength} / ${maxLength})`}</Counter>;
};

export default CharsCount;

CharsCount.defaultProps = {
  currentLength: '',
  maxLength: 0,
  isQuill: true,
};

CharsCount.propTypes = {
  stringToCount: PropTypes.string,
  maxLength: PropTypes.number,
  isQuill: PropTypes.bool,
};
