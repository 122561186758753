// import AuthContext from 'AuthContext';
import {
  AreaLogo,
  HeaderContainer,
  HeaderGrid,
  HeaderWrapper,
  NavWrapper,
} from './Header.styled';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Headroom from 'react-headroom';
import { MobileNav } from '@components/atoms/nav/mobile/MobileNav';
import { Nav } from '@components/atoms/nav/Nav';
import PropTypes from 'prop-types';
import UserBar from './userBar/UserBar';
import headerMenu from '@src/menus/HeaderMenu';
import footerMenu from '@src/menus/FooterMenu';

const Header = ({ siteTitle, navItems, className, location }) => {
  const [mobileNavState, setMobileNavState] = useState({
    isMobileNavOpened: false,
  });

  const onToggleMenuOpen = () => {
    setMobileNavState({
      ...mobileNavState,
      isMobileNavOpened: !mobileNavState.isMobileNavOpened,
    });
  };

  useEffect(() => {
    if (mobileNavState.isMobileNavOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [mobileNavState.isMobileNavOpened]);

  return (
    <AuthContext.Consumer>
      {auth => (
        <Headroom
          style={{
            zIndex: 999,
          }}
        >
          <HeaderContainer
            css={{ zIndex: mobileNavState.isMobileNavOpened ? '2100' : '1000' }}
          >
            <HeaderWrapper className={className}>
              <HeaderGrid>
                <Link to="/" alt={siteTitle}>
                  <AreaLogo />
                </Link>
                <NavWrapper>
                  <Nav
                    display="horizontal"
                    navItems={headerMenu}
                    location={location}
                    isHeader
                  />
                </NavWrapper>
                <UserBar
                  isLogged={auth.isAuthenticated}
                  auth={auth}
                  mobileNavOpened={mobileNavState.isMobileNavOpened}
                  onBurgerClick={onToggleMenuOpen}
                />
              </HeaderGrid>
            </HeaderWrapper>
            <MobileNav
              onMenuClick={onToggleMenuOpen}
              mainNavItems={headerMenu}
              secondNavItems={footerMenu.footerSecondary}
              isLogged={auth.isAuthenticated}
              auth={auth}
              mobileNavOpened={mobileNavState.isMobileNavOpened}
            />
          </HeaderContainer>
        </Headroom>
      )}
    </AuthContext.Consumer>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
};

Header.defaultProps = {
  siteTitle: 'AREA Autodesk',
  navItems: [
    { label: 'INSPIRE', url: '/login', alt: 'INSPIRE' },
    { label: 'LEARN', url: '/login', alt: 'LEARN' },
    { label: 'COMMUNITY', url: '/login', alt: 'COMMUNITY' },
  ],
};

export default Header;
