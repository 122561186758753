import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';
export const SignUpBoxWrapper = styled('div', {
  width: '100%',
});

export const SignUpBoxTitle = styled('div', {
  marginBottom: '$24',
});

export const LinkStyled = styled(Link, {
  marginBottom: '$24',
  fontFamily: 'var(--ArtifaktLegendBold)',
  color: '$mainTextColor',
  textDecoration: 'none',
  transition: 'opacity .4s ease',
  '&:hover': {
    opacity: 0.5,
  },
});

export const SignUpBoxList = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  ul: {
    maxWidth: '300px',
    '@bp1': {
      maxWidth: '100%',
    },
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '$16',
    fontFamily: '$ArtifaktLegendBold',
  },
  svg: {
    marginRight: '$24',
    height: '$24',
    width: '$24',
    fill: '$blackToWhite',
  },
  '@bp1': {
    display: 'block',
  },
});
