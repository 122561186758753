import React from 'react';
import PropTypes from 'prop-types';

import Table from '@components/organisms/table/Table';

import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';

export const CreateDraftList = props => {
  const {
    loading,
    userContent,
    onDeleteDraft,
    onChangeLimitRow,
    onNextPage,
    selectUserContent,
    onPrevPage,
  } = props;
  if (loading && !userContent) {
    return <LoaderSkeleton height="200px" width="100%" isRadius />;
  } else {
    return (
      <Table
        nextPage={onNextPage}
        prevPage={onPrevPage}
        changeRowPerPage={onChangeLimitRow}
        totalCount={selectUserContent.totalCount}
        currentCount={selectUserContent.currentCount}
        loading={loading}
        rowData={userContent.userContentList}
        columns={selectUserContent.columns}
        maxPage={selectUserContent.maxPage}
        isSelectUserContent
        isNotMultiSelect
        currentPage={selectUserContent.page}
      />
    );
  }
};

CreateDraftList.propTypes = {
  drafts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      postDate: PropTypes.string,
    })
  ).isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
};
export default CreateDraftList;
