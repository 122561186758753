import {
  AccountSettingWrapper,
  ButtonWrapper,
  InputWrapper,
  StyledBtn,
} from './AccountSettingsSection.styled';
import {
  DELETE_ACCOUNT,
  UPDATE_USER_EMAIL,
  UPDATE_USER_USERNAME,
} from '@src/apollo/mutation';
import React, { useContext, useEffect, useState } from 'react';

import AccountSettingsModalContent from '@components/molecules/accountSettingsModalContent/AccountSettingsModalContent';
import Button from '@components/atoms/button/Button';
import { ClientNotificationContext } from '@context/ClientNotificationContext';
import { Heading } from '@components/atoms/heading/Heading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { deleteAllCookies } from '@src/utils/deleteAllCookies';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';

function AccountSettingsSection({ userData, auth }) {
  const [accountSettingsState, setAccountSettingsState] = useState({
    // email: userData.email,
    username: userData.username,
    id: userData.id,
  });

  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  const [deleteAccount, deleteAccountData] = useMutation(DELETE_ACCOUNT, {
    onCompleted: () => {
      handleUpdateClientNotification({
        text: 'delete_user_account_notification',
        type: 'success',
        autoDismiss: true,
      });
      auth.logout();
      deleteAllCookies();
      navigate('/join');
    },
  });

  const [modalState, setModalState] = useState({
    isShowing: false,
  });

  const [updateUserEmail, updateUserEmailData] = useMutation(UPDATE_USER_EMAIL);
  const [updateUserUsername, updateUserUsernameData] = useMutation(
    UPDATE_USER_USERNAME,
    {
      onCompleted: () => {
        auth.updateUserSlug(accountSettingsState.username);
        window.history.replaceState(
          'title',
          'Title',
          `/m/${accountSettingsState.username}/account-settings`
        );
        setModalState({
          isShowing: true,
          type: 'username_changed',
        });
      },
    }
  );

  function handleSubmit(e, type) {
    if (type === 'username_changed') {
      setModalState({
        isShowing: false,
      });
    }
    if (type === 'delete_account') {
      deleteAccount();
      setModalState({
        isShowing: false,
      });
    }
  }

  // useEffect(() => {
  //   if (updateUserEmailData?.data?.updateMySettings.email) {
  //     setAccountSettingsState({
  //       ...accountSettingsState,
  //       ...updateUserEmailData.data.updateMySettings.email,
  //     });
  //   }
  //   if (updateUserEmailData?.error) {
  //     setAccountSettingsState({
  //       ...accountSettingsState,
  //       emailError: (
  //         <String
  //           id={`account_settings_email_error_${updateUserEmailData.error.message}`}
  //         />
  //       ),
  //       isEmailError: true,
  //     });
  //     window.scrollTo({ top: 150, behavior: 'smooth' });
  //   } else {
  //     setAccountSettingsState({
  //       ...accountSettingsState,
  //       emailError: undefined,
  //       isEmailError: false,
  //     });
  //   }
  // }, [updateUserEmailData.data, updateUserEmailData.error]);

  useEffect(() => {
    if (updateUserUsernameData?.data?.updateMySettings.username) {
      setAccountSettingsState({
        ...accountSettingsState,
        ...updateUserUsernameData.data.updateMySettings.username,
      });
    }
    if (updateUserUsernameData?.error) {
      setAccountSettingsState({
        ...accountSettingsState,
        usernameError: updateUserUsernameData.error.message === "validation" ? (
            <Text>
              {updateUserUsernameData.error.graphQLErrors[0].extensions.validation.username[0]}
            </Text>
          ) : (
            <String
              id={`account_settings_username_error_${updateUserUsernameData.error.message}`}
            />
        ),
        isUserNameError: true,
      });
      window.scrollTo({ top: 150, behavior: 'smooth' });
    } else {
      setAccountSettingsState({
        ...accountSettingsState,
        usernameError: undefined,
        isUserNameError: false,
      });
    }
  }, [updateUserUsernameData.data, updateUserUsernameData.error]);

  const disableSaveButton =
    // accountSettingsState.email === userData.email &&
    accountSettingsState.username === userData.username;

  const isLoading =
    deleteAccountData.loading ||
    updateUserUsernameData.loading ||
    updateUserEmailData.loading;

  return (
    <>
      {modalState.isShowing && (
        <Modal
          isShowing={modalState.isShowing}
          hide={() => setModalState({ isShowing: false })}
        >
          <Heading level="h3">
            <String id={`account_settings_${modalState.type}_modal`} />
          </Heading>
          {modalState.type == 'delete_account' && (
            <String id={`delete_user_account_warning`} />
          )}
          <AccountSettingsModalContent
            type={modalState.type}
            setModalState={setModalState}
            onSubmit={e => {
              handleSubmit(e, modalState.type);
            }}
          />
        </Modal>
      )}

      <AccountSettingWrapper id="#login-and-security">
        {/* <Heading level="h4">
          <String id="account_settings_account_login_and_security"/>
        </Heading> */}
        <InputWrapper>
          {/* <InputText
            onChange={e => {
              setAccountSettingsState({
                ...accountSettingsState,
                email: e.target.value,
                isEmailError: false,
                emailError: undefined,
              });
            }}
            type="text"
            inputShape="normal"
            name="email"
            forgotPasswordUrl="/autodesk-change-id"
            // forgotPasswordLabel={
            //   <String id="account_settings_account_login_and_security_form_change_adsk_id" />
            // }
            label={
              <String id="account_settings_account_login_and_security_form_email" />
            }
            value={accountSettingsState.email}
            css={{ marginBottom: '$16' }}
            error={accountSettingsState.emailError || null}
            isError={accountSettingsState.isEmailError || false}
            inputTheme="transparent"
          /> */}
          <InputText
            onChange={e => {
              setAccountSettingsState({
                ...accountSettingsState,
                username: e.target.value,
                isUserNameError: false,
                usernameError: undefined,
              });
            }}
            type="text"
            inputShape="normal"
            name="username"
            label={
              <String id="account_settings_account_login_and_security_form_username" />
            }
            value={accountSettingsState.username}
            css={{ marginBottom: '$16' }}
            error={accountSettingsState.usernameError || null}
            isError={accountSettingsState.isUserNameError || false}
            inputTheme="transparent"
          />
        </InputWrapper>
        <Heading
          level="h5"
          weight="legendBold"
          css={{ fontSize: '$14 !important' }}
        >
          <String id="account_settings_account_login_and_security_warning_title" />
        </Heading>
        <Text as="p" size="12">
          <String id="account_settings_account_login_and_security_warning_text" />
          {` ${FE_URL}/${accountSettingsState.username}`}
        </Text>
      </AccountSettingWrapper>
      <AccountSettingWrapper id="#autodesk-password">
        {/* <Heading
          level="h4"
          weight="legendBold"
          css={{ marginTop: '$40', fontSize: '$24 !important' }}
        >
          <String id="account_settings_account_autodesk_password" />
        </Heading> */}
        <ButtonWrapper isPassword>
          <StyledBtn
            label={
              <String id="account_settings_account_autodesk_password_button_label" />
            }
            actionType="onclick"
            colorTheme="gray"
            onClick={() =>
              setModalState({
                isShowing: true,
                type: 'password',
              })
            }
          />
        </ButtonWrapper>
      </AccountSettingWrapper>
      <AccountSettingWrapper id="#delete-account">
        <Heading
          level="h4"
          weight="legendBold"
          css={{ marginTop: '$40', fontSize: '$24 !important' }}
        >
          <String id="account_settings_account_autodesk_delete_account_subtitle" />
        </Heading>
        <ButtonWrapper>
          <Text as="p" size="12" css={{ marginBottom: '$12' }}>
            <String id="account_settings_account_autodesk_delete_account_text" />
          </Text>
          <StyledBtn
            label={
              <String id="account_settings_account_autodesk_delete_account_button_label" />
            }
            actionType="onclick"
            colorTheme="gray"
            onClick={() =>
              setModalState({
                isShowing: true,
                type: 'delete_account',
              })
            }
          />
        </ButtonWrapper>
      </AccountSettingWrapper>
      <Button
        isDisabled={disableSaveButton}
        css={{
          marginTop: '$54',
        }}
        label={<String id="account_settings_account_autodesk_save_settings" />}
        actionType="onclick"
        onClick={() => {
          // if (userData.email !== accountSettingsState.email) {
          //   updateUserEmail({
          //     variables: {
          //       email: accountSettingsState.email,
          //     },
          //   });
          // }
          if (userData.username !== accountSettingsState.username) {
            updateUserUsername({
              variables: {
                username: accountSettingsState.username,
              },
            });
          }
        }}
      />
      {isLoading && <Loader isLoading={isLoading} />}
    </>
  );
}

AccountSettingsSection.propTypes = {
  userData: PropTypes.shape({
    // email: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default AccountSettingsSection;
