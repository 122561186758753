import { DatePickerWrapper, ModalControlsWrapper } from './resumeEdit.styled';
import React, { forwardRef, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { UPDATE_RECENT_WORK } from '@src/apollo/mutation';
import Button from '@components/atoms/button/Button';
import DatePicker from '@components/molecules/datePicker/DatePicker';
import { Heading } from '@components/atoms/heading/Heading';
import InputGooglePlacesLocation from '@components/atoms/input/inputGooglePlacesLocation/InputGooglePlacesLocation';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import String from '@components/atoms/string/String';
import { convertUTCToLocalDate } from '@src/utils/datesHelper';
import { format } from 'date-fns';
import { useIsMobile } from '@src/utils/useIsMobile';

const RecentWorkEdit = props => {
  const [recentWorkState, setRecentWorkState] = useState({
    title: props.data === undefined ? '' : props.data.title,
    url: props.data === undefined ? '' : props.data.url,
    job_title: props.data === undefined ? '' : props.data.job_title,
    location: props.data === undefined ? '' : props.data.location,
    locationFull: props.data === undefined ? '' : props.data.locationFull,
    locationPlaceId:
      props.data === undefined ? '' : props.data.location.placeId,
  });

  const [errorState, setErrorState] = useState({
    isUrlError: false,
    isFormError: false,
    isTitleError: false,
    isJobTitleError: false,
    isLocationError: false,
  });

  const currentList = [...props.list];
  const newCurrentList = [];
  currentList.map(project => {
    const newProjectObj = { ...project };

    delete newProjectObj.image;
    delete newProjectObj.locationFull;

    newCurrentList.push(newProjectObj);
  });

  const [startDate, setStartDate] = useState(
    props.data === undefined
      ? new Date()
      : convertUTCToLocalDate(props.data.dateStart)
  );
  const [endDate, setEndDate] = useState(
    props.data === undefined
      ? new Date()
      : convertUTCToLocalDate(props.data.dateEnd)
  );

  const [updateRecentWorkState, { loading, error, data }] = useMutation(
    UPDATE_RECENT_WORK,
    {
      onCompleted: data => {
        props.onClose();
        props.onSave({
          projects: data.updateUserProfile.projects,
        });
      },
    }
  );

  const isMobile = useIsMobile();

  const onChange = e => {
    const { value } = e.target;
    const { name } = e.target;
    const updatedState = { ...recentWorkState };

    updatedState[name] = value;
    setRecentWorkState(updatedState);
  };

  const onLocationChange = data => {
    const { country } = data;
    const { place_id } = data;
    const { label } = data;
    const updatedState = { ...recentWorkState };
    updatedState.location = country;
    updatedState.locationPlaceId = place_id;
    updatedState.locationFull = label;

    setRecentWorkState(updatedState);
  };

  const updateOldItem = () => {
    const newArr = [...newCurrentList];
    newArr[props.id] = {
      title: recentWorkState.title,
      url: recentWorkState.url,
      order: props.id,
      job_title: recentWorkState.job_title,
      dateStart: format(startDate, 'yyyy-MM-dd'),
      dateEnd: format(endDate, 'yyyy-MM-dd'),
      location: {
        placeId: recentWorkState.locationPlaceId,
      },
    };
    return newArr;
  };

  const deleteItem = () => {
    const newArr = [...newCurrentList];
    newArr.splice(props.id, 1);
    newArr.map((item, index) => {
      item.order = index;
    });

    return newArr;
  };

  const deleteItemProps = () => {
    const newArr = [...props.list];
    newArr.splice(props.id, 1);
    newArr.map((item, index) => {
      item.order = index;
    });

    return newArr;
  };

  const createNewItem = [
    ...newCurrentList,
    {
      title: recentWorkState.title,
      url: recentWorkState.url,
      order: props.order,
      job_title: recentWorkState.job_title,
      dateStart: format(startDate, 'yyyy-MM-dd'),
      dateEnd: format(endDate, 'yyyy-MM-dd'),
      location: {
        placeId: recentWorkState.locationPlaceId,
      },
    },
  ];

  const handleValidation = () => {
    const isTitleError = recentWorkState.title === '';
    const isJobTitleError = recentWorkState.job_title === '';
    const isLocationError = recentWorkState.location === '';
    if (isTitleError || isJobTitleError || isLocationError) {
      setErrorState({
        ...errorState,
        isFormError: true,
        isTitleError,
        isJobTitleError,
        isLocationError,
      });
      return false;
    }
    setErrorState({
      ...errorState,
      isFormError: false,
      isTitleError: false,
      isJobTitleError: false,
      isLocationError: false,
    });
    return true;
  };

  const onSave = () => {
    if (handleValidation()) {
      updateRecentWorkState({
        variables: {
          projects: props.data === undefined ? createNewItem : updateOldItem(),
        },
      });
    }
  };

  const onDelete = () => {
    updateRecentWorkState({
      variables: {
        projects: deleteItem(),
      },
    });

    props.onSave({
      projects: deleteItemProps(),
    });
  };

  function handleIMDBValidation(e) {
    const regex = /^https:\/\/www\.imdb\.com\/title\/(\?.*)?/;
    if (recentWorkState.url && !regex.test(e.target.value)) {
      setErrorState({ isUrlError: true });
    } else {
      setErrorState({ isUrlError: false });
    }
  }

  return (
    <div>
      <Heading
        level="h3"
        weight="legendBold"
        css={{ lineHeight: '1.3em', marginBottom: '$24' }}
      >
        <String id="profile_resume_projects_title" />
      </Heading>
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="title"
        label={<String id="profile_resume_projects_input_label_title" />}
        css={{ marginBottom: '$32' }}
        value={recentWorkState.title}
        onChange={onChange}
        error={<String id="profile_resume_projects_input_label_title_error" />}
        isError={errorState.isTitleError}
        required
      />
      <InputText
        inputShape="normal"
        inputTheme="white"
        name="url"
        label={<String id="profile_resume_projects_input_label_url" />}
        css={{ marginBottom: '$32' }}
        value={recentWorkState.url}
        onChange={onChange}
        onBlur={handleIMDBValidation}
        error={<String id="profile_resume_projects_input_error_url" />}
        isError={errorState.isUrlError}
        // instruction={
        //   <String id="profile_resume_projects_input_instructions_url" />
        // }
        isInstruction
        required={false}
      />
      <InputGooglePlacesLocation
        onLocationChange={onLocationChange}
        location={recentWorkState.location}
        locationFull={recentWorkState.locationFull}
        css={{ marginBottom: '$32' }}
        error={<String id="profile_resume_input_label_location_error" />}
        isError={errorState.isLocationError}
        required
        autoCompleteRequestTypes={['(cities)']}
        label="profile_resume_projects_gplaces_input_label"
      />

      <InputText
        inputShape="normal"
        inputTheme="white"
        name="job_title"
        label={<String id="profile_resume_input_label_jobTitle" />}
        css={{ marginBottom: '$32' }}
        value={recentWorkState.job_title}
        onChange={onChange}
        error={<String id="profile_resume_input_label_jobTitle_error" />}
        isError={errorState.isJobTitleError}
        required
      />

      <DatePickerWrapper>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          withPortal={isMobile}
          label="profile_resume_input_label_from"
        />
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          withPortal={isMobile}
          label="profile_resume_input_label_to"
        />
      </DatePickerWrapper>
      <ModalControlsWrapper>
        <div>
          <Button
            type="normal"
            label="Cancel"
            colorTheme="gray"
            onClick={props.onClose}
            actionType="onclick"
            css={{ marginRight: '$16' }}
          />
          <Button
            type="normal"
            label="Save"
            colorTheme="normal"
            onClick={onSave}
            actionType="onclick"
            isLoading={loading}
            isDisabled={loading}
          />
        </div>
        <div>
          {props.data === undefined ? null : (
            <Button
              type="normal"
              label="Delete"
              colorTheme="transparent"
              onClick={onDelete}
              actionType="onclick"
              css={{ textTransform: 'capitalize' }}
            />
          )}
        </div>
      </ModalControlsWrapper>

      {loading ? <Loader isLoading="true" /> : null}
    </div>
  );
};

export default RecentWorkEdit;
