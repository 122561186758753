import {
  BadgeCTA,
  CTAWrapper,
  LinkStyled,
  NavItems,
  PendingNotification,
  RedBubble,
  SubMenu,
  SubMenuLink,
  SvgCTA,
  TextPending,
  TextStyled,
} from './SidebarMenu.styled';
import React, { useEffect, useState } from 'react';

import Svg from '@components/atoms/svg/Svg';

const SidebarMenuItems = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (props.subMenu) {
      const pathName = props.subMenu.filter(
        el => el.url === props.location.pathname
      );
      if (pathName.length === 1) {
        setIsMenuOpen(true);
      }
    }
  }, [props.subMenu]);

  return (
    <NavItems isMenuOpen={isMenuOpen}>
      {props.subMenu ? (
        <SubMenuLink
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
          isMenuOpen={isMenuOpen}
          isActive={props.activeTab || isMenuOpen}
        >
          <BadgeCTA>
            <SvgCTA icon={props.svgIcon} className="menuIcon" />
          </BadgeCTA>
          <div>
            <TextStyled>{props.label}</TextStyled>
          </div>
          <Svg className="arrow" icon="arrow-down-short" />
        </SubMenuLink>
      ) : (
        <CTAWrapper alt={props.label} to={props.url} isActive={props.activeTab}>
          <BadgeCTA>
            <SvgCTA icon={props.svgIcon} />
          </BadgeCTA>
          <div>
            <TextStyled>{props.label}</TextStyled>
            {props.noticeLabel ? (
              <PendingNotification>
                <RedBubble />
                <TextPending size="12">{props.noticeLabel}</TextPending>
              </PendingNotification>
            ) : null}
          </div>
        </CTAWrapper>
      )}

      {props.subMenu && isMenuOpen ? (
        <SubMenu>
          {props.subMenu?.map((subMenuItem, index) => {
            return (
              <LinkStyled
                key={subMenuItem.label + subMenuItem.url}
                to={subMenuItem.url}
                isActive={props.location.pathname === subMenuItem.url}
              >
                {subMenuItem.label}
              </LinkStyled>
            );
          })}
        </SubMenu>
      ) : null}
    </NavItems>
  );
};

export default SidebarMenuItems;
