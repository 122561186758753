import { ActionWrapper, MenuWrapper } from './ThumbnailMenu.styled';
import React, { useEffect, useRef, useState } from 'react';

import Button from '@components/atoms/button/Button';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { useOnClickOutside } from '@src/utils/useOnClickOutside';

function ThumbnailMenu({actions}){
  const [showSettings, setShowSettings] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowSettings(false));
  
  return (
    <MenuWrapper className="thumbnail-menu" ref={ref}>
      {showSettings && (
        <ActionWrapper>
          {actions.map((action, i) => {
            return (
              <Button
                key={action.label + i}
                actionType="onclick"
                onClick={action.onActionClick}
                svgIcon={action.svgIcon}
                label={
                  <String id={action.label} />
                }
                colorTheme="white"
                svgLocation="after"
                className="thumbnail-menu-action"
              />
            )
          })}
        </ActionWrapper>
      )}
      <Button 
        actionType="onclick"
        onClick={() => setShowSettings(!showSettings)}
        type="circle"
        svgIcon="settings"
        colorTheme="white"
      />
    </MenuWrapper>
  )
}

ThumbnailMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onActionClick: PropTypes.func.isRequired,
    svgIcon: PropTypes.string
  })).isRequired,
}

export default ThumbnailMenu;