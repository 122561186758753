import {
  ActionsMenuWrapper,
  GroupActions,
  GroupBGImage,
  GroupHeaderWrapper,
  GroupTitle,
  GroupTitleCenter,
  GroupTitleWrapper,
  IconImage,
  ImageWrapper,
  StyledBtn,
  StyledBtnMobile,
  StyledHeading,
  StyledMenuBtn,
} from './GroupHeader.styled';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { isGroupMember } from '@src/utils/getMemberGroupInfo';
import Button from '@components/atoms/button/Button';
import Image from '@components/atoms/image/Image';
import Modal from '@components/atoms/modal/Modal';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import String from '@components/atoms/string/String';
import useModal from '@components/atoms/modal/useModal';
import { useOnClickOutside } from '@src/utils/useOnClickOutside';
import CommunityFeedContext from '@context/CommunityFeedContext';

const GroupHeader = props => {
  const { isShowing, toggle } = useModal();
  const [viewAsUser, setViewAsUser] = useState(true);
  const actionsRef = useRef();
  const [isActionsVisible, setIsActionsVisible] = useState(false);
  const joinLabel = <String id="community_group_join_text" />;
  const adminViewLabel = <String id="community_group_admin_view_text" />;
  const viewAsAdminLabel = <String id="community_group_as_admin_text" />;
  const leaveGroupLabel = <String id="group_header_leave_group" />;
  const [communityState, setCommunityState] = useContext(CommunityFeedContext);

  useEffect(() => {
    if (props.groupRole === 'moderator' || props.generalRole === 'admin') {
      setViewAsUser(false);
    }
  }, [props.groupRole, props.generalRole]);

  const onClickJoinGroup = () => {
    props.isOnboarded
      ? props.authContext.isVerified && props.authContext.isOnboarded
        ? props.onJoinGroup && props.onJoinGroup(props.groupId)
        : toggle()
      : toggle();
  };

  useOnClickOutside(actionsRef, () => setIsActionsVisible(false));

  const onOptionsClick = () => {
    setIsActionsVisible(!isActionsVisible);
  };

  const onReportGroupClick = () => {
    setIsActionsVisible(!isActionsVisible);
    props.onReportGroupClick();
  };

  const onLeaveGroupClick = () => {
    setIsActionsVisible(!isActionsVisible);
    props.onLeaveGroupClick(props.groupId);
  };

  const onViewAsUserClick = () => {
    setViewAsUser(!viewAsUser);
  };

  return (
    <>
      <GroupHeaderWrapper>
        <GroupBGImage>
          <ImageWrapper>
            <Image src={props.image} isBackground={true}></Image>
          </ImageWrapper>

          <GroupTitleWrapper>
            <IconImage>
              <Image src={props.imageGroupIcon} alt={props.title} />
            </IconImage>
          </GroupTitleWrapper>
        </GroupBGImage>
      </GroupHeaderWrapper>
      <GroupTitle>
        <div></div>
        <GroupTitleCenter>
          <StyledHeading as="h3" weight="legendExtraBold">
            {props.title}
          </StyledHeading>

          <GroupActions>
            {!isGroupMember(communityState.myGroupsId, props.groupId) && (
              <StyledBtn
                onClick={onClickJoinGroup}
                actionType="onclick"
                colorTheme="normal"
                label={joinLabel}
                svgIcon="plus"
                isLoading={props.isJoinLoading}
                isDisabled={props.isJoinLoading}
              />
            )}
            {(props.groupRole === 'moderator' ||
              props.generalRole === 'admin') &&
              !viewAsUser && (
                <StyledBtn
                  type="normal"
                  actionType="internalLink"
                  colorTheme="white"
                  svgIcon="visibility-on"
                  svgLocation="before"
                  label={adminViewLabel}
                  internalUrl={props.adminUrl}
                  css={{
                    whiteSpace: 'nowrap',
                  }}
                />
              )}
            {isGroupMember(communityState.myGroupsId, props.groupId) && (
              <Button
                actionType="onclick"
                colorTheme="gray"
                type="circle"
                svgIcon="more"
                onClick={onOptionsClick}
              />
            )}

            <ActionsMenuWrapper
              isVisible={isActionsVisible}
              ref={actionsRef}
              isAdmin={
                (props.groupRole === 'moderator' ||
                  props.generalRole === 'admin') &&
                viewAsUser &&
                !isGroupMember(communityState.myGroupsId, props.groupId)
              }
              makeLower={
                (props.groupRole === 'moderator' ||
                  props.generalRole === 'admin') &&
                viewAsUser &&
                isGroupMember(communityState.myGroupsId, props.groupId)
              }
            >
              {/* <StyledMenuBtn
                      actionType="onclick"
                      colorTheme="transparent"
                      type="normal"
                      onClick={onReportGroupClick}
                      label={reportGroupLabel}
                    /> */}
              {isGroupMember(communityState.myGroupsId, props.groupId) && (
                <>
                  <StyledMenuBtn
                    actionType="onclick"
                    colorTheme="transparent"
                    type="normal"
                    onClick={onLeaveGroupClick}
                    label={leaveGroupLabel}
                  />
                </>
              )}
              {(props.groupRole === 'moderator' ||
                props.generalRole === 'admin') &&
                viewAsUser && (
                  <StyledMenuBtn
                    type="normal"
                    actionType="onclick"
                    colorTheme="transparent"
                    label={viewAsAdminLabel}
                    onClick={onViewAsUserClick}
                  />
                )}
            </ActionsMenuWrapper>
          </GroupActions>
        </GroupTitleCenter>

        <div></div>
      </GroupTitle>
      {props.isLogged &&
        props.isVerified &&
        props.isOnboarded &&
        !isGroupMember(communityState.myGroupsId, props.groupId) && (
          <StyledBtnMobile
            onClick={onClickJoinGroup}
            actionType="onclick"
            colorTheme="normal"
            display="block"
            label={joinLabel}
            svgIcon="plus"
            isLoading={props.isJoinLoading}
            isDisabled={props.isJoinLoading}
          />
        )}

      {(props.groupRole === 'moderator' || props.generalRole === 'admin') &&
        !viewAsUser && (
          <StyledBtnMobile
            type="normal"
            actionType="internalLink"
            colorTheme="white"
            svgIcon="visibility-on"
            svgLocation="before"
            label={adminViewLabel}
            internalUrl={props.adminUrl}
          />
        )}

      <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
        {(!props.authContext.isOnboarded || !props.authContext.isVerified) &&
        props.authContext.isAuthenticated ? (
          <PublishNotification
            type={!props.authContext.isOnboarded ? 'onboarding' : 'validation'}
            url={!props.authContext.isOnboarded ? `/m/${props.authContext.userSlug}` : ''}
          />
        ) : (
          <PublishNotification type="join" />
        )}
      </Modal>
    </>
  );
};

export default GroupHeader;
