import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';
import { Text } from '@components/atoms/text/Text';
import { Link } from 'gatsby';

export const CommentBoxWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  marginTop: '$16',
  a: {
    wordBreak: 'break-all',
  },
  '& .link-preview-comment': {
    color: '$mainTextColor',
    marginBottom: '$16',
  },
});

export const StyledLink = styled(Link, {
  textDecoration: 'none',
  color: 'unset',
  transition: 'opacity .2s ease-out',

  '&:hover': {
    opacity: '.75',
  },
});

export const StyledBtn = styled('div', {
  height: '$12',
  marginRight: '$8',
  display: 'flex',

  '& .btn-label, & .btn-counter': {
    lineHeight: '1em',
  },

  '&:nth-of-type(1)': {
    height: '20px',

    svg: {
      width: '20px',
      height: '20px',
      flexShrink: '0',
    },
  },

  '&:nth-of-type(2)': {
    width: '$12',
    position: 'relative',
    top: '-1px',
  },
});

export const CommentWrapper = styled('div', {
  position: 'relative',
  marginLeft: '$16',
  width: '100%',

  variants: {
    isReported: {
      true: {
        boxShadow: '$2',
        borderRadius: '$16',
      },
    },
  },
});

export const TextContainer = styled('div', {
  padding: '0 $16 $16',

  '@bp2': {
    padding: '0 $16',
  },

  div: {
    '&:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',

      '@bp1': {
        flexDirection: 'row',
      },
    },
  },
});

export const ArtistName = styled(Link, {
  fontFamily: '$ArtifaktElementBold',
  color: '$mainTextColor !important',
  marginRight: '$8',
  marginBottom: '-$4',
  textDecoration: 'none',
  position: 'relative',

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '3px',
    left: '0',
    width: '100%',
    height: '1px',
    backgroundColor: '$blackToWhite',
    opacity: '0',
  },

  '&:hover': {
    '&:before': {
      opacity: '1',
    },
  },

  '@bp1': {
    marginBottom: '0',
  },
});

export const CommentHeader = styled('div', {
  padding: '$16 0 0',
  borderRadius: '$8 $8 0 0',
  backgroundColor: '$box',
  position: 'relative',
  a: {
    fontFamily: '$ArtifaktElementBold',
    color: '$mainTextColor',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
    },
  },

  '& > button': {
    transition: 'opacity .2s ease-out',
    width: '33px',
    height: '33px',

    '@bp1': {
      width: '44px',
      height: '44px',
    },
  },
});

export const CommentFooter = styled('div', {
  padding: '$12',
  borderRadius: '0 0 $8 $8',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '$box',

  '@bp1': {
    padding: '$8 $16',
  },
});

export const ShowRepliesBtn = styled('button', {
  backgroundColor: 'unset',
  color: '$mediumGrey',
  textDecoration: 'underline',
  border: 'none',
  margin: '0',

  '&:hover': {
    cursor: 'pointer',
  },

  variants: {
    isNotVisible: {
      true: {
        display: 'none',

        '@bp1': {
          display: 'none',
        },
      },
    },
  },
});

export const RepliesContainer = styled('div', {
  display: 'none',

  variants: {
    isVisible: {
      true: {
        display: 'block',
      },
    },
  },
});

export const ReplyingContainer = styled('div', {
  variants: {
    isShorter: {
      true: {
        width: 'calc(100% - (44px + 1rem))',
        marginLeft: 'calc(44px + 1rem)',
      },
    },

    isMobile: {
      true: {
        display: 'none',
      },
    },
  },
});

export const PreviousRepliesBtn = styled('button', {
  backgroundColor: 'unset',
  textDecoration: 'underline',
  border: 'none',
  marginTop: '$16',
  color: '$mainTextColor',
  fontFamily: '$ArtifaktElementBold',

  '&:hover': {
    cursor: 'pointer',
  },

  variants: {
    isNotVisible: {
      true: {
        display: 'none',
      },
    },
  },
});

export const CommentReplyWrapper = styled('div', {
  marginTop: '$16',
});

export const ThreeDotsBtn = styled(Button, {
  position: 'absolute',
  top: '$8',
  right: '$8',

  '&:hover': {
    backgroundColor: '$elements !important',
  },
});

export const ReportWrapper = styled('div', {
  position: 'absolute',
  top: '$64',
  right: '$8',
  backgroundColor: '$box',
  borderRadius: '$8',
  padding: '$16',
  flexDirection: 'column',
  boxShadow: '$1',
  display: 'none',
  zIndex: '$1',

  variants: {
    isVisible: {
      true: {
        display: 'flex',
      },
    },
  },
});

export const ReportBtns = styled('button', {
  backgroundColor: 'unset',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$20',
  cursor: 'pointer',
  whiteSpace: 'nowrap',

  '&:last-of-type': {
    marginBottom: '0',
  },

  '&:hover': {
    '@bp2': {
      opacity: '0.7',
    },
  },

  svg: {
    width: '$24',
    height: '$24',
    marginRight: '$4',
    fill: '$mainTextColor',
  },

  span: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export const StyledText = styled(Text, {
  lineHeight: '$4',
  color: '$mainTextColor',
  margin: '$8 0 0',
  width: '100%',

  '@bp1': {
    margin: '$8 0 $16',
    width: '90%',
  },
});

export const StyledReportText = styled(Text, {
  fontFamily: '$ArtifaktElementBold',
  color: '$mainTextColor',
});

export const LoaderWrapper = styled('div', {
  textAlign: 'center',
  py: '$24',
});

export const StyledActionBtn = styled(Button, {
  width: '100% !important',
  height: '100%  !important',
  padding: '0 !important',
  minWidth: 'unset !important',
  minHeight: 'unset !important',
  margin: '0',

  '& > svg': {
    fill: '$mainTextColor',
  },

  '&:focus': {
    boxShadow: 'none',
  },

  '& .btn-counter': {
    position: 'relative',
    fontSize: '$12',
    textTransform: 'capitalize',
    color: '$mainTextColor',
  },

  '& .btn-label': {
    textTransform: 'capitalize',
    color: '$mainTextColor',
  },

  '&:hover': {
    backgroundColor: 'transparent !important',

    '@bp2': {
      opacity: '0.7',
    },
  },

  variants: {
    isLikesBtn: {
      true: {
        span: {
          display: 'flex',
          alignItems: 'center',
        },

        '& .btn-counter': {
          marginLeft: '$4',
        },

        marginRight: '$8',
      },
    },

    needsTopRight: {
      false: {
        span: {
          top: '0',
          right: '0',
        },
      },
    },

    isReply: {
      true: {
        svg: {
          width: '$12',
          height: '$12',
          position: 'relative',
          top: '2px',
        },
      },
    },

    isLiked: {
      true: {
        span: {
          color: '$red',
        },

        svg: {
          fill: '$red',
        },
      },
    },
  },
});

export const DeleteBtnsContainer = styled('div', {
  display: 'flex',
  marginTop: '$24',
});
