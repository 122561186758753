import Button from '@components/atoms/button/Button';
import { styled } from '@root/stitches.config';

export const DropdownWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  '@bp1': {
    width: 'fit-content',
  },
});

export const DropDownToggleButton = styled(Button, {
  width: '100%',
});

export const SelectedItemCount = styled('p', {
  marginLeft: '$8',
  background: '$mediumGrey',
  width: '18px',
  height: '18px',
  br: 100,
  fontSize: '$12',
  textAlign: 'center',
  lineHeight: '18px',
});

export const OptionsWrapper = styled('div', {
  display: 'grid',
  position: 'absolute',
  marginTop: '$8',
  br: '$16',
  background: '#fff',
  padding: '0',
  boxShadow: '$4',
  maxHeight: '300px',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '0px',
  },
  textAlign: 'left',
  width: '100%',
  zIndex: '$1',
  padding: '$16 $24',
  '@bp1': {
    minWidth: '245px',
  },
  variants: {
    alignOptions: {
      left: {
        left: '0',
      },
      right: {
        right: '0',
      },
    },
  },
});

export const Option = styled('div', {
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$16 $12',
  transition: 'all .3s ease-out',
  fontFamily: '$ArtifaktLegendBold',
  // color: '$blackToWhite',
  '&:hover': {
    backgroundColor: 'lightGray',
    borderRadius: '$8',
  },
  variants: {
    isSelected: {
      true: {
        backgroundColor: '$grey',
      },
    },
  },
});
