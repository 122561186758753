import AdminLayout from '@components/organisms/adminLayout/AdminLayout';
import { Heading } from '@components/atoms/heading/Heading';
import React, { useEffect } from 'react';
import SEO from '@components/atoms/seo/Seo';
import { Row2Col, RowFull } from './StatsContent.styled';
import { DoughnutGraphBlock } from '@components/molecules/statsBlocks/DoughnutGraphBlock';
import TableBlock from '@components/molecules/statsBlocks/TableBlock';
import { useLazyQuery } from '@apollo/client';
import { GET_STATS_DATA } from '@src/apollo/query';
import String from '@components/atoms/string/String';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import { generateRandomColor } from '@src/utils/generateRandomColor';
import LineChartBlock from '@components/molecules/statsBlocks/LineChartBlock';
import { monthsNames } from '@src/utils/monthNames';
import { Text } from '@components/atoms/text/Text';
import { BigStatBlock } from '@components/molecules/statsBlocks/BigStatBlock';
import { SmallStatCenteredBlock } from '@components/molecules/statsBlocks/SmallStatCenteredBlock';
import { format, parseISO } from 'date-fns';

const StatsContent = context => {
  const [getStats, { data, loading, error, called }] = useLazyQuery(GET_STATS_DATA, {
    context: { clientName: 'cms' },
    variables: {
      type: 'content'
    }
  });

  useEffect(() => {
    const dateEarly = new Date();
    getStats({
      variables: {
        dateStart: dateEarly.setMonth(dateEarly.getMonth() - 1) / 1000, 
        dateEnd: new Date().valueOf() / 1000
      }
    })
  }, [])
  
  return (
    <AdminLayout
      location={context.location}
      roles={['admin', 'editor', 'contributor']}
    >
      <SEO title="Area" />
      <Heading level="h1" weight="bold" css={{ fontSize: '$48' }}>
        <String id="admin_cms_stats_content_title" />
      </Heading>
      <div style={{ position: 'relative', width: '100%' }}>
        <RowFull>
          {loading || !data ? (
            <LoaderSkeleton height="600px" width="100%" isRadius />
          ) : (
            <LineChartBlock
              bigStat={{
                label: 'Total Contents',
                value: data.stats.total_count,
              }}
              onChange={({ startDate, endDate }) => {
                getStats({
                  variables: {
                    dateStart: startDate.valueOf() / 1000,
                    dateEnd: endDate.valueOf() / 1000,
                  },
                });
              }}
              datas={{
                dataSets: [
                  {
                    label: 'Previous period',
                    datas: data.stats.period_previous_graph.map(item => {
                      return {
                        date: `${item.key}`,
                        label: format(parseISO(item.key), 'MMM/dd/yyyy'),
                        value: item.count,
                      };
                    }),
                  },
                  {
                    label: 'Current period',
                    datas: data.stats.period_current_graph.map(item => {
                      return {
                        date: `${item.key}`,
                        label: format(parseISO(item.key), 'MMM/dd/yyyy'),
                        value: item.count,
                      };
                    }),
                  },
                ],
              }}
            />
          )}
        </RowFull>
        <Row2Col>
          {loading || !data ? (
            <>
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Total new content',
                    accessor: 'key',
                  },
                  {
                    Header: 'Contents',
                    accessor: 'count',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.period_current_count,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.period_previous_count,
                  },
                  {
                    key: '% Change',
                    count: (
                      <Text
                        css={{
                          color: data.stats.period_diff < 0 ? '$red' : '$green',
                        }}
                      >{`${data.stats.period_diff < 0 ? '' : '+'}${
                        data.stats.period_diff
                      }%`}</Text>
                    ),
                  },
                ]}
              />
              <BigStatBlock
                stat={data.stats.period_current_count}
                text="Total new content for the period"
                percentage={`${data.stats.period_diff < 0 ? '' : '+'}${
                  data.stats.period_diff
                }%`}
                percentageColor={data.stats.period_diff < 0 ? 'red' : 'green'}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading || !data ? (
            <>
              <Row2Col smallGap>
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
                <LoaderSkeleton height="196px" width="100%" isRadius />
              </Row2Col>
              <LoaderSkeleton height="400px" width="100%" isRadius />
            </>
          ) : (
            <>
              <Row2Col smallGap>
                <SmallStatCenteredBlock
                  stat={data.stats.artworks.current_count}
                  text="Artworks"
                  percentage={`${
                    data.stats.artworks.period_diff > 0 ? '+' : ''
                  }${data.stats.artworks.period_diff}%`}
                  percentageColor={
                    data.stats.artworks.period_diff > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.articles.current_count}
                  text="Articles"
                  percentage={`${
                    data.stats.articles.period_diff > 0 ? '+' : ''
                  }${data.stats.articles.period_diff}%`}
                  percentageColor={
                    data.stats.articles.period_diff > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.tutorials.current_count}
                  text="Tutorials"
                  percentage={`${
                    data.stats.tutorials.period_diff > 0 ? '+' : ''
                  }${data.stats.tutorials.period_diff}%`}
                  percentageColor={
                    data.stats.tutorials.period_diff > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.tutorials_series.current_count}
                  text="Tutorial Series"
                  percentage={`${
                    data.stats.tutorials_series.period_diff > 0 ? '+' : ''
                  }${data.stats.tutorials_series.period_diff}%`}
                  percentageColor={
                    data.stats.tutorials_series.period_diff > 0
                      ? 'green'
                      : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.assets.current_count}
                  text="Assets"
                  percentage={`${data.stats.assets.period_diff > 0 ? '+' : ''}${
                    data.stats.assets.period_diff
                  }%`}
                  percentageColor={
                    data.stats.assets.period_diff > 0 ? 'green' : 'red'
                  }
                />
                <SmallStatCenteredBlock
                  stat={data.stats.content_per_member.current_count}
                  text="Contents per member"
                  percentage={`${data.stats.content_per_member.period_diff > 0 ? '+' : ''}${
                    data.stats.content_per_member.period_diff
                  }%`}
                  percentageColor={
                    data.stats.content_per_member.period_diff > 0 ? 'green' : 'red'
                  }
                />
              </Row2Col>
              <DoughnutGraphBlock
                insideTitle="AREA New User Contents"
                data={{
                  labels: [
                    'Artworks',
                    'Articles',
                    'Tutorials',
                    'Tutorials Series',
                    'Assets',
                  ],
                  datasets: [
                    {
                      data: [
                        data.stats.artworks.current_count,
                        data.stats.articles.current_count,
                        data.stats.tutorials.current_count,
                        data.stats.tutorials_series.current_count,
                        data.stats.assets.current_count,
                      ],
                      backgroundColor: [
                        generateRandomColor(),
                        generateRandomColor(),
                        generateRandomColor(),
                        generateRandomColor(),
                        generateRandomColor(),
                      ],
                    },
                  ],
                }}
              />
            </>
          )}
        </Row2Col>
        <Row2Col>
          {loading || !data ? (
            <>
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
              <LoaderSkeleton height="300px" width="100%" isRadius />
            </>
          ) : (
            <>
              <TableBlock
                headColumns={[
                  {
                    Header: 'Artworks',
                    accessor: 'key',
                  },
                  {
                    Header: 'contents',
                    accessor: 'count',
                  },
                  {
                    Header: 'contents%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.artworks.current_count,
                    diff: `${data.stats.artworks.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.artworks.previous_count,
                    diff: `${data.stats.artworks.previous_diff}%`,
                  },
                  {
                    key: '% Change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.artworks.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${data.stats.artworks.period_diff < 0 ? '' : '+'}${
                        data.stats.artworks.period_diff
                      }%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Articles',
                    accessor: 'key',
                  },
                  {
                    Header: 'contents',
                    accessor: 'count',
                  },
                  {
                    Header: 'contents%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.articles.current_count,
                    diff: `${data.stats.articles.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.articles.previous_count,
                    diff: `${data.stats.articles.previous_diff}%`,
                  },
                  {
                    key: '% Change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.articles.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${data.stats.articles.period_diff < 0 ? '' : '+'}${
                        data.stats.articles.period_diff
                      }%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Tutorials',
                    accessor: 'key',
                  },
                  {
                    Header: 'contents',
                    accessor: 'count',
                  },
                  {
                    Header: 'contents%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.tutorials.current_count,
                    diff: `${data.stats.tutorials.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.tutorials.previous_count,
                    diff: `${data.stats.tutorials.previous_diff}%`,
                  },
                  {
                    key: '% Change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.tutorials.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${data.stats.tutorials.period_diff < 0 ? '' : '+'}${
                        data.stats.tutorials.period_diff
                      }%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Tutorials Series',
                    accessor: 'key',
                  },
                  {
                    Header: 'contents',
                    accessor: 'count',
                  },
                  {
                    Header: 'contents%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.tutorials_series.current_count,
                    diff: `${data.stats.tutorials_series.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.tutorials_series.previous_count,
                    diff: `${data.stats.tutorials_series.previous_diff}%`,
                  },
                  {
                    key: '% Change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.tutorials_series.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${
                        data.stats.tutorials_series.period_diff < 0 ? '' : '+'
                      }${data.stats.tutorials_series.period_diff}%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Assets',
                    accessor: 'key',
                  },
                  {
                    Header: 'contents',
                    accessor: 'count',
                  },
                  {
                    Header: 'contents%',
                    accessor: 'diff',
                  },
                ]}
                dataRows={[
                  {
                    key: 'Current period',
                    count: data.stats.assets.current_count,
                    diff: `${data.stats.assets.current_diff}%`,
                  },
                  {
                    key: 'Previous period',
                    count: data.stats.assets.previous_count,
                    diff: `${data.stats.assets.previous_diff}%`,
                  },
                  {
                    key: '% Change',
                    diff: (
                      <Text
                        css={{
                          color:
                            data.stats.assets.period_diff < 0
                              ? '$red'
                              : '$green',
                        }}
                      >{`${data.stats.assets.period_diff < 0 ? '' : '+'}${
                        data.stats.assets.period_diff
                      }%`}</Text>
                    ),
                  },
                ]}
              />
              <TableBlock
                headColumns={[
                  {
                    Header: 'Top Creators',
                    accessor: 'name',
                  },
                  {
                    Header: 'contents',
                    accessor: 'count',
                  },
                ]}
                dataRows={data.stats.top_creators}
              />
            </>
          )}
        </Row2Col>
      </div>
    </AdminLayout>
  );
};

export default StatsContent;
