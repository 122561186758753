import {
  Flag,
  LocationContainer,
  ProfilePictureContainer,
  ProfilePictureImage,
  ThumbnailContainer,
  ThumbnailSocials,
  ThumbnailTop,
  ThumbnailWrapper,
  AvatarWrapper,
} from './UserThumbnail.styled';
import React from 'react';

import { Heading } from '@components/atoms/heading/Heading';
import SocialListLink from '@components/molecules/socialListLink/SocialListLink';
import { Text } from '@components/atoms/text/Text';
import Avatar from '@components/atoms/avatar/Avatar';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
const UserThumbnail = props => {
  const profilePicture = getAvatarImage(props.profilePicture, 'large');
  return (
    <ThumbnailWrapper>
      <ThumbnailTop to={props.profileUrl}>
        <ProfilePictureContainer>
          <ProfilePictureImage className="profilePicture">
            <AvatarWrapper>
              <Avatar alt={props.name} src={profilePicture} size="xlarge" />
            </AvatarWrapper>
          </ProfilePictureImage>
        </ProfilePictureContainer>
        <ThumbnailContainer>
          <Heading level="h4" weight="legendBold" css={{ lineHeight: '1.4em' }}>
            {props.firstName} {props.lastName}
          </Heading>
          <Text size="16">{props.title}</Text>
          {props.location && (
            <LocationContainer>
              {props.locationFlag && <Flag src={`${props.locationFlag}.svg`} />}
              <Text size="12">{props.location}</Text>
            </LocationContainer>
          )}
        </ThumbnailContainer>
      </ThumbnailTop>

      <ThumbnailSocials>
        {props.socials && (
          <SocialListLink
            socialMediaList={props.socials}
            type="circle"
            colorTheme="gray"
            className={'userThumbnailSocials'}
          />
        )}
      </ThumbnailSocials>
    </ThumbnailWrapper>
  );
};

export default UserThumbnail;
