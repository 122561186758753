import {
  AddBtn,
  AddBtnSvg,
  BackLinkText,
  ButtonSave,
  CheckMark,
  CloseImgBtn,
  CoverImage,
  CoverImageContainer,
  DoDontText,
  DoDontWrapper,
  EditorWrapper,
  HeadingStyled,
  InputLabel,
  LayoutStyled,
  MainImage,
  MainImageContainer,
  PromoImage,
  PromoImageContainer,
  StyledAddBtnSvg,
  StyledThumbnail,
  TitleWrapper,
  UploadBtn,
  WrapperContent,
  WrapperImageUploader,
  WrapperInput,
  WrapperPage,
  WrapperSide,
} from './GroupSettings.styled';
import {
  BackBtn,
  ContentTopContainer,
  ContentWrapper,
  IconImage,
  StyledSvg,
  Title,
  TitleContainer,
} from '../community/groupMembersModeration/GroupMembersModeration.styled';
import {
  GET_CREATE_DETAIL,
  GET_GROUP_BASIC_DETAILS,
  GET_MY_GROUPS_ID,
  GET_POSTS_FILTERED_COUNT,
} from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AddRuleModal from './addRuleModal/AddRuleModal';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import GroupCommunitySideBar from '@components/molecules/groupCommunitySidebar/GroupCommunitySideBar';
import Image from '@components/atoms/image/Image';
import ImageUploading from 'react-images-uploading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import Loader from '@components/atoms/loader/Loader';
import Modal from '@components/atoms/modal/Modal';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import SEO from '@components/atoms/seo/Seo';
import SelectUserContent from '@components/molecules/selectUserContent/SelectUserContent';
import Separator from '@components/atoms/separator/Separator';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import TopBarGroup from '@components/atoms/topbarGroup/TopBarGroup';
import { UPDATE_GROUP_DETAILS } from '@src/apollo/mutation';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { navigate } from 'gatsby';
import useModal from '@components/atoms/modal/useModal';

const GroupSettingsPage = context => {
  const [featuredEdited, setFeaturedEdited] = useState();
  const [changingDescription, setChangingDescription] = useState(false);
  const [description, setDescription] = useState();
  const [isNewFeaturedState, setIsNewFeatured] = useState({
    firstFeatured: false,
    firstFeaturedId: null,
    firstFeaturedSlug: null,
    secondFeaturedId: null,
    secondFeatured: false,
    secondFeaturedSlug: null,
  });

  const { loading, error, data, refetch } = useQuery(GET_GROUP_BASIC_DETAILS, {
    variables: {
      slug: context.params.slug,
      feed: 'recent',
    },
    context: { clientName: 'public' },
    fetchPolicy: 'no-cache',
  });

  const onSelectUserContent = e => {
    toggle();
    if (featuredEdited === 1) {
      setIsNewFeatured({
        ...isNewFeaturedState,
        firstFeatured: true,
        firstFeaturedId: e.id,
        firstFeaturedSlug: e.slug,
      });
    } else {
      setIsNewFeatured({
        ...isNewFeaturedState,
        secondFeatured: true,
        secondFeaturedId: e.id,
        secondFeaturedSlug: e.slug,
      });
    }
  };

  const { data: dataSecondFeatureUserContent } = useQuery(GET_CREATE_DETAIL, {
    variables: {
      slug: isNewFeaturedState.secondFeaturedSlug,
    },
  });
  const { data: dataFirstFeatureUserContent } = useQuery(GET_CREATE_DETAIL, {
    variables: {
      slug: isNewFeaturedState.firstFeaturedSlug,
    },
  });

  const authContext = useContext(AuthContext);
  const [userRole, setUserRole] = useState('');

  const [pendingPostsCount, setPendingPostsCount] = useState(0);
  const [reportedPostsCount, setReportedPostsCount] = useState(0);
  const [updateGroupDetails, updateGroupDetailsData] = useMutation(
    UPDATE_GROUP_DETAILS,
    {
      onCompleted: () => {
        navigate(context.uri.replace(groupState.oldSlug, groupState.newSlug));
        setIsNewFeatured({
          ...isNewFeaturedState,
          firstFeatured: false,
          secondFeatured: false,
        });
        setGroupState({
          ...groupState,
          isLoading: false,
        });
        setImages({
          ...images,
          mainChanged: false,
          cover: [],
          coverUrl: '',
          coverChanged: false,
          promo: [],
          promoUrl: '',
          promoChanged: false,
        });
        refetch();
      },
    }
  );
  const { isShowing, toggle } = useModal();

  const [groupState, setGroupState] = useState({
    name: '',
    loaded: false,
    memberCount: null,
    doOrDont: '',
    id: null,
    newName: '',
    isLoading: true,
    newSlug: '',
    oldSlug: context.params.slug,
    promoText: null,
    promotionUrl: null,
  });
  const [images, setImages] = useState({
    main: [],
    mainUrl: '',
    mainChanged: false,
    cover: [],
    coverUrl: '',
    coverChanged: false,
    promo: [],
    promoUrl: '',
    promoChanged: false,
  });

  const [groupRoleState, setGroupRole] = useState({
    role: '',
    loaded: false,
  });

  const [getgroupRoleData, groupRoleData] = useLazyQuery(GET_MY_GROUPS_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (groupState.id !== null) {
      getgroupRoleData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (groupRoleData.data) {
      let role = '';
      for (let i = 0; i < groupRoleData.data.mygroups.length; i++) {
        if (groupRoleData.data.mygroups[i].id === groupState.id) {
          role = groupRoleData.data.mygroups[i].group_role;
        }
      }
      setGroupRole({
        ...groupRoleState,
        role,
        loaded: true,
      });
    }
  }, [groupRoleData.data]);

  useEffect(() => {
    if (groupRoleState.loaded) {
      setUserRole(
        authContext.generalRole === 'admin' ||
          groupRoleState.role === 'moderator'
          ? 'admin'
          : 'member'
      );
    }
  }, [groupRoleState.loaded]);

  useEffect(() => {
    if (data) {
      setGroupState({
        ...groupState,
        name: data.group.name,
        newName: data.group.name,
        loaded: true,
        memberCount: data.group.members_count,
        id: data.group.id,
        isLoading: false,
        promoText: data.group.promotion_text,
        promotionUrl: data.group.promotion_url,
      });

      setDescription(data.group.description);

      setIsNewFeatured({
        ...isNewFeaturedState,
        firstFeaturedId: data.group.featured_content[0]
          ? data.group.featured_content[0].id
          : null,
        firstFeaturedSlug: data.group.featured_content[0]
          ? data.group.featured_content[0].slug
          : null,
        secondFeaturedId: data.group.featured_content[1]
          ? data.group.featured_content[1].id
          : null,
        secondFeaturedSlug: data.group.featured_content[1]
          ? data.group.featured_content[1].slug
          : null,
      });

      setImages({
        ...images,
        coverUrl: getImageSrc(
          data.group.header_image,
          'group_header',
          data.group.title
        ),
        mainUrl: getImageSrc(data.group.icon, 'group_icon', data.group.title),
        promoUrl: getImageSrc(
          data.group.promotion_image,
          'group_promo',
          data.group.title
        ),
      });
    }
  }, [data]);

  const onDropMain = imageList => {
    if (imageList.length === 0) {
      setImages({ ...images, main: imageList, mainChanged: false });
    } else {
      setImages({ ...images, main: imageList, mainChanged: true });
    }
  };
  const onDropCover = imageList => {
    if (imageList.length === 0) {
      setImages({ ...images, cover: imageList, coverChanged: false });
    } else {
      setImages({ ...images, cover: imageList, coverChanged: true });
    }
  };
  const onDropPromo = imageList => {
    if (imageList.length === 0) {
      setImages({ ...images, promo: imageList, promoChanged: false });
    } else {
      setImages({ ...images, promo: imageList, promoChanged: true });
    }
  };

  useEffect(() => {
    if (groupState.loaded) {
      document.querySelector('.text-editor').style.minHeight = '5px';

      document.querySelector('.text-editor').style.minHeight =
        document.querySelector('.text-editor').scrollHeight + 'px';
    }
  }, [groupState.loaded]);

  const groupUrl = context.location.pathname.substring(
    0,
    context.location.pathname.indexOf(`/${context.params.slug}`) +
      `/${context.params.slug}`.length
  );

  const activeTab = context.location.pathname.substring(
    context.location.pathname.indexOf('/admin/') + '/admin/'.length
  );

  const [getPendingCountData, pendingCountData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'pending',
      },
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getPendingCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (pendingCountData.data) {
      setPendingPostsCount(pendingCountData.data.postsFilteredCount);
    }
  }, [pendingCountData.data]);

  const [getReportedCountData, reportedCountDataData] = useLazyQuery(
    GET_POSTS_FILTERED_COUNT,
    {
      variables: {
        groupId: groupState.id,
        status: 'reported',
      },
    }
  );

  useEffect(() => {
    if (groupState.id !== null) {
      getReportedCountData();
    }
  }, [groupState.id]);

  useEffect(() => {
    if (reportedCountDataData.data) {
      setReportedPostsCount(reportedCountDataData.data.postsFilteredCount);
    }
  }, [reportedCountDataData.data]);

  const adDo = () => {
    setGroupState({
      ...groupState,
      doOrDont: 'do',
    });
    toggle();
  };

  const adDont = () => {
    setGroupState({
      ...groupState,
      doOrDont: 'dont',
    });
    toggle();
  };

  const onApplyClick = (doOrDont, title, description) => {
    // console.log(doOrDont, title, description);
  };

  const onSaveClick = () => {
    const slug = groupState.newName
      .trim()
      .replace(/[^\w\s]/gi, ' ') // remove all special chars from name
      .replace(/[^\w\s]|(.)(?=\1)/gi, '') // remove duplicate special chars from name
      .replace(/\s/g, '-') // replace spaces with dash
      .toLowerCase();

    setGroupState({
      ...groupState,
      isLoading: true,
      newSlug: slug,
    });

    const featuredContents = [];

    if (isNewFeaturedState.firstFeaturedId) {
      featuredContents.push(isNewFeaturedState.firstFeaturedId);
    }
    if (isNewFeaturedState.secondFeaturedId) {
      featuredContents.push(isNewFeaturedState.secondFeaturedId);
    }

    updateGroupDetails({
      variables: {
        groupId: groupState.id,
        name: groupState.newName,
        slug,
        description: description,
        icon_image: images.main.length !== 0 ? images.main[0].file : null,
        // icon_image: getImageSrc(
        //   data.group.icon,
        //   'group_icon',
        //   data.group.title
        // ),
        header_image: images.cover.length !== 0 ? images.cover[0].file : null,
        promotion_image:
          images.promo.length !== 0 ? images.promo[0].file : null,
        promotion_text: groupState.promoText,
        promotion_url: groupState.promotionUrl,
        featured_content: featuredContents,
      },
    });
  };

  const onTitleChange = e => {
    setGroupState({
      ...groupState,
      newName: e.target.value,
    });
  };

  const onDescriptionChange = e => {
    e.target.style.minHeight = '5px';
    e.target.style.minHeight = e.target.scrollHeight + 'px';
    setChangingDescription(true);
    setTimeout(() => {
      setChangingDescription(false);
    }, 1000);
    if (!changingDescription) {
      setDescription(e.target.value);
    }
  };

  const onSelect1stFeatured = () => {
    setFeaturedEdited(1);
    toggle();
  };

  const onSelect2ndFeatured = () => {
    setFeaturedEdited(2);
    toggle();
  };

  const onPromoChange = e => {
    setGroupState({
      ...groupState,
      promoText: e.target.value,
    });
  };

  const onPromoUrlChange = e => {
    setGroupState({
      ...groupState,
      promotionUrl: e.target.value,
    });
  };

  return (
    <ProtectedRoute
      redirectTo={groupUrl}
      userAccessReq={['auth', userRole, ['admin']]}
    >
      <LayoutStyled location={context.location}>
        <SEO title="Area - Group Settings" slug="group-settings" />
        <TopBarGroup label="group Admin and moderation" />
        <ContentWrapper>
          <ContentTopContainer>
            <BackBtn title={`back to ${context.params.slug}`} to={groupUrl}>
              <StyledSvg icon="arrow-left-short" />
              <BackLinkText size="12">
                <String id="community_moderation_back_text" />
              </BackLinkText>
            </BackBtn>
            {groupState.loaded ? (
              <TitleContainer>
                <IconImage>
                  <Image src={images.mainUrl} alt="icon" isBackground={false} />
                </IconImage>
                <Title>{groupState.name}</Title>
              </TitleContainer>
            ) : null}
          </ContentTopContainer>
          <WrapperPage>
            <WrapperSide>
              <GroupCommunitySideBar
                activeTab={activeTab}
                groupUrl={groupUrl}
                location={context.location}
                pendingNotifications={{
                  members: {
                    amount: 0,
                  },
                  publication: {
                    amount: pendingPostsCount,
                  },
                  reportContent: {
                    amount: reportedPostsCount,
                  },
                }}
              />
            </WrapperSide>
            <WrapperContent>
              <HeadingStyled level="h4">
                <String id="page_group_settings_form_title" />
              </HeadingStyled>
              <WrapperInput>
                <InputLabel level="h6" weight="legendBold">
                  <Text>
                    <String id="page_group_settings_label_input_title" />
                  </Text>
                </InputLabel>
                <InputText
                  name="Group Title"
                  value={groupState.newName}
                  onChange={onTitleChange}
                  css={{ fontSize: '$14' }}
                />
              </WrapperInput>
              <Separator css={{ margin: '0 0 $40 0' }} />
              <WrapperInput>
                <InputLabel level="h6" weight="legendBold">
                  <Text>
                    <String id="page_group_settings_label_input_desc" />
                  </Text>
                </InputLabel>
                <EditorWrapper>
                  {groupState.loaded && (
                    <textarea
                      maxlength="255"
                      className="text-editor"
                      onChange={onDescriptionChange}
                    >
                      {description}
                    </textarea>
                  )}
                </EditorWrapper>
              </WrapperInput>
              <Separator css={{ margin: '0 0 $40 0' }} />
              {/* <WrapperInput>
                <InputLabel level="h6">
                  <Text>
                    <String id="page_group_settings_label_input_rules" />
                  </Text>
                </InputLabel>
                <DoDontWrapper>
                  <DoDontText size="14">
                    <String id="community_guidelines_dos" />
                  </DoDontText>
                  <AddBtn onClick={adDo}>
                    <AddBtnSvg>
                      <StyledAddBtnSvg icon="plus" />
                    </AddBtnSvg>
                    <String id="community_guidelines_dos" />
                  </AddBtn>
                </DoDontWrapper>
                <DoDontWrapper>
                  <DoDontText size="14">
                    <String id="community_admin_add_do" />
                  </DoDontText>
                  <AddBtn onClick={adDont}>
                    <AddBtnSvg>
                      <StyledAddBtnSvg icon="plus" />
                    </AddBtnSvg>
                    <String id="community_admin_add_dont" />
                  </AddBtn>
                </DoDontWrapper>
                <Modal isShowing={isShowing} hide={toggle}>
                  <AddRuleModal
                    hide={toggle}
                    onApplyClick={onApplyClick}
                    doOrDont={groupState.doOrDont}
                  />
                </Modal>
              </WrapperInput>
              <Separator css={{ margin: '0 0 $40 0' }} /> */}
              <WrapperInput>
                <WrapperImageUploader>
                  <TitleWrapper>
                    <InputLabel level="h6" weight="legendBold">
                      <Text>
                        <String id="page_group_settings_label_input_main_picture" />
                      </Text>
                    </InputLabel>
                    <Text size="12" css={{ marginLeft: '$16' }}>
                      (130px * 130px minimum)
                    </Text>
                  </TitleWrapper>

                  <ImageUploading
                    value={images.main}
                    onChange={onDropMain}
                    maxNumber={1}
                    dataURLKey="data_url"
                    acceptType={['jpeg', 'jpg', 'gif', 'png', 'svg']}
                  >
                    {({ imageList, onImageUpload, onImageRemove, errors }) => (
                      <div className="upload__image-wrapper">
                        {images.mainChanged ? (
                          imageList.map((image, index) => (
                            <MainImageContainer key={image.data_url}>
                              <img src={image.data_url} />
                              <CloseImgBtn
                                type="circle"
                                colorTheme="gray"
                                actionType="onclick"
                                svgIcon="trash"
                                onClick={() => onImageRemove(index)}
                              />
                            </MainImageContainer>
                          ))
                        ) : (
                          <MainImage
                            src={images.mainUrl}
                            alt="main"
                            isBackground={false}
                          />
                        )}

                        <UploadBtn
                          colorTheme="gray"
                          actionType="onclick"
                          onClick={onImageUpload}
                          label={
                            <String id="general_change_picture_btn_text" />
                          }
                        />
                        {errors && (
                          <Text css={{ color: 'red', marginTop: '$8' }}>
                            Erreur
                          </Text>
                        )}
                      </div>
                    )}
                  </ImageUploading>
                </WrapperImageUploader>
              </WrapperInput>
              <Separator css={{ margin: '0 0 $40 0' }} />
              <WrapperInput>
                <WrapperImageUploader>
                  <TitleWrapper>
                    <InputLabel level="h6" weight="legendBold">
                      <Text>
                        <String id="page_group_settings_label_input_cover_picture" />
                      </Text>
                    </InputLabel>
                    <Text size="12" css={{ marginLeft: '$16' }}>
                      (300px * 1920px minimum)
                    </Text>
                  </TitleWrapper>
                  <ImageUploading
                    value={images.cover}
                    onChange={onDropCover}
                    maxNumber={1}
                    dataURLKey="data_url"
                    resolutionType="more"
                    acceptType={['jpeg', 'jpg', 'gif', 'png', 'svg']}
                  >
                    {({ imageList, onImageUpload, onImageRemove, errors }) => (
                      <div className="upload__image-wrapper">
                        {images.coverChanged ? (
                          imageList.map((image, index) => (
                            <CoverImageContainer key={image.data_url}>
                              <img src={image.data_url} />
                              <CloseImgBtn
                                type="circle"
                                colorTheme="gray"
                                actionType="onclick"
                                svgIcon="trash"
                                onClick={() => onImageRemove(index)}
                              />
                            </CoverImageContainer>
                          ))
                        ) : (
                          <CoverImageContainer>
                            <CoverImage
                              src={images.coverUrl}
                              alt="cover"
                              isBackground={false}
                            />
                          </CoverImageContainer>
                        )}
                        <UploadBtn
                          colorTheme="gray"
                          actionType="onclick"
                          onClick={onImageUpload}
                          label={
                            <String id="general_change_picture_btn_text" />
                          }
                        />
                        {errors && (
                          <Text css={{ color: 'red', marginTop: '$8' }}>
                            <String id="profile_edit_avatar_crop_error_size" />
                          </Text>
                        )}
                      </div>
                    )}
                  </ImageUploading>
                </WrapperImageUploader>
              </WrapperInput>
              <Separator css={{ margin: '0 0 $40 0' }} />
              <WrapperInput>
                <InputLabel level="h6" weight="legendBold">
                  <Text>
                    <String id="page_group_settings_label_input_promo" />
                  </Text>
                </InputLabel>
                <InputText
                  css={{ margin: '0 0 $8 0' }}
                  name="promotionBox"
                  placeholder=" Introducing Bitfrost for maya"
                  onChange={onPromoChange}
                  value={groupState.promoText}
                />
                <WrapperImageUploader>
                  <ImageUploading
                    value={images.promo}
                    onChange={onDropPromo}
                    maxNumber={1}
                    dataURLKey="data_url"
                    resolutionType="more"
                    acceptType={['jpeg', 'jpg', 'gif', 'png', 'svg']}
                  >
                    {({ imageList, onImageUpload, onImageRemove, errors }) => (
                      <div className="upload__image-wrapper">
                        {images.promoChanged ? (
                          imageList.map((image, index) => (
                            <PromoImageContainer key={image.data_url}>
                              <img src={image.data_url} />
                              <CloseImgBtn
                                type="circle"
                                colorTheme="gray"
                                actionType="onclick"
                                svgIcon="trash"
                                onClick={() => onImageRemove(index)}
                              />
                            </PromoImageContainer>
                          ))
                        ) : images.promoUrl !== undefined ? (
                          <PromoImageContainer>
                            <PromoImage
                              src={images.promoUrl}
                              alt="promo"
                              isBackground={false}
                            />
                          </PromoImageContainer>
                        ) : null}
                        <UploadBtn
                          colorTheme="gray"
                          actionType="onclick"
                          onClick={onImageUpload}
                          label={
                            <String id="general_change_picture_btn_text" />
                          }
                        />
                        {errors && (
                          <Text css={{ color: 'red', marginTop: '$8' }}>
                            Erreur
                          </Text>
                        )}
                      </div>
                    )}
                  </ImageUploading>
                </WrapperImageUploader>
                <InputText
                  css={{ margin: '$16 0 $20 0' }}
                  name="promotionBox"
                  placeholder="http://..."
                  onChange={onPromoUrlChange}
                  value={groupState.promotionUrl}
                />
              </WrapperInput>
              <Separator css={{ margin: '0 0 $40 0' }} />
              <WrapperInput>
                <InputLabel level="h6" weight="legendBold">
                  <Text>
                    <String id="page_group_settings_label_input_first_featured" />
                  </Text>
                </InputLabel>
                <Button
                  type="normal"
                  colorTheme="gray"
                  actionType="onclick"
                  onClick={onSelect1stFeatured}
                  css={{ marginBottom: '$12' }}
                  label={<String id="select_user_content_select_content" />}
                />
                {isNewFeaturedState.firstFeatured && (
                  <CheckMark icon="circle-check" />
                )}
                {isNewFeaturedState.firstFeaturedSlug &&
                  dataFirstFeatureUserContent && (
                    <Thumbnail
                      {...dataToThumbnailViewModel(
                        dataFirstFeatureUserContent.userContent,
                        true,
                        false
                      )}
                      isMini
                    />
                  )}
              </WrapperInput>
              <Separator css={{ margin: '0 0 $40 0' }} />
              <WrapperInput>
                <InputLabel level="h6" weight="legendBold">
                  <Text>
                    <String id="page_group_settings_label_input_second_featured" />
                  </Text>
                </InputLabel>
                <Button
                  type="normal"
                  colorTheme="gray"
                  actionType="onclick"
                  onClick={onSelect2ndFeatured}
                  css={{ marginBottom: '$12' }}
                  label={<String id="select_user_content_select_content" />}
                />
                {isNewFeaturedState.secondFeatured && (
                  <CheckMark icon="circle-check" />
                )}
                {isNewFeaturedState.secondFeaturedSlug &&
                  dataSecondFeatureUserContent && (
                    <Thumbnail
                      {...dataToThumbnailViewModel(
                        dataSecondFeatureUserContent.userContent,
                        true,
                        false
                      )}
                      isMini
                    />
                  )}
              </WrapperInput>
              <ButtonSave
                colorTheme="normal"
                actionType="onclick"
                onClick={onSaveClick}
                label={<String id="general_save_btn_text" />}
                isDisabled={
                  description === '<p><br></p>' ||
                  description === '<p></p>' ||
                  groupState.newName === ''
                }
              />
            </WrapperContent>
            <Modal isShowing={isShowing} hide={toggle} isSelectUserContent>
              <SelectUserContent
                singleRowActions={{
                  text: (
                    <Text weight="bold">
                      <String id="admin_confirm_select_user_content_label" />
                    </Text>
                  ),
                  label: 'Yes',
                  onClickAction: onSelectUserContent,
                }}
                status="publish"
                isNotMultiSelect
                isGroupSettings
              />
            </Modal>
          </WrapperPage>
          {groupState.isLoading ? <Loader isLoading="true" /> : null}
        </ContentWrapper>
      </LayoutStyled>
    </ProtectedRoute>
  );
};

export default GroupSettingsPage;
