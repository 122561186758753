import { styled } from '@root/stitches.config';
import Svg from '@components/atoms/svg/Svg';

export const CheckboxContainer = styled('div', {
  display: 'inline-block',
  verticalAlign: 'middle',
  position: 'relative',
  height: '24px',
  width: '24px',
  top: '2px',
});

export const HiddenCheckbox = styled('input', {
  border: 0,
  clip: 'rect(0 0 0 0)',
  clippath: 'inset(50%)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
  outline: '0',
  ':focus': {
    '~ div': {
      borderColor: '$black',
      top: '-2px',
    },
  },
  ':checked': {
    '~ div': {
      backgroundColor: '$darkGrey',
      border: '0',
      top: '-2px',
    },
    '~ svg': {
      fill: '$whiteToBlack',
    },
  },
});

export const StyledCheckbox = styled('div', {
  display: 'inline-block',
  height: '24px',
  width: '24px',
  borderRadius: '6px',
  border: '2px solid $mediumGrey',
  top: '-2px',
  position: 'relative',
  ':hover': {
    borderColor: '$black',
  },

  variants: {
    ischecked: {
      true: {
        backgroundColor: '$blackToWhite',
        border: '2px solid $blackToWhite',
      },
    },
  },
});

export const CheckboxLabel = styled('span', {
  display: 'inline-block',
  marginLeft: '$8',
  fontSize: '$12',
  lineHeight: '1.5em',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});

export const CheckboxWrapper = styled('label', {
  display: 'flex',
  alignItems: 'center',
});

export const CheckboxIcon = styled(Svg, {
  width: '20px',
  height: '20px',
  position: 'absolute',
  left: '2px',
  top: '0px',
  fill: '$whiteToBlack',

  variants: {
    ischecked: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },
});
