import {
  CheckboxContainer,
  CheckboxIcon,
  CheckboxLabel,
  CheckboxWrapper,
  HiddenCheckbox,
  StyledCheckbox,
} from './InputCheckBox.styled';
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const InputCheckBox = props => {
  const [checked, setChecked] = useState(props.checked);

  const onChange = e => {
    setChecked(!checked);
    props.onChange(e);
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const updatedProps = { ...props };
  delete updatedProps.children;

  return (
    <CheckboxWrapper css={props.css}>
      <CheckboxContainer>
        <HiddenCheckbox
          type="checkbox"
          checked={checked}
          onChange={onChange}
          value={props.value}
          name={props.name}
        />
        <StyledCheckbox ischecked={checked.toString()} />
        <CheckboxIcon ischecked={checked.toString()} icon="check" />
      </CheckboxContainer>
      <CheckboxLabel className={props.labelClassName} isError={props.isError}>{props.children}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

InputCheckBox.defaultProps = {
  name: 'checkbox-test',
};

export default InputCheckBox;
