import { styled } from '@root/stitches.config';

export const Listing = styled('div', {
  display: 'grid',
  gap: '32px',
  marginBottom: '$64',
  '@bp0': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  '@bp1': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

export const ListingWrapper = styled('div', {});
