import { AdFeatureCard, StyledButton, StyledHeading } from './AdFeature.styled';

import Image from '@components/atoms/image/Image';
import PropTypes from 'prop-types';
import React from 'react';

const AdFeature = props => {
  const { src, title, action } = props;

  return (
    <AdFeatureCard>
      {src && <Image {...src} alt={src.alt} isBackground />}
      <StyledHeading level="h2">{title}</StyledHeading>
      <StyledButton
        label={action.label}
        internalUrl={action.internalUrl}
        externalUrl={action.externalUrl}
        colorTheme={action.colorTheme}
        onClick={action.onClick}
        actionType={action.actionType}
        svgIcon={action.svgIcon}
        svgLocation={action.svgLocation}
        target={action.target}
      />
    </AdFeatureCard>
  );
};

export default AdFeature;

AdFeature.propTypes = {
  staticImage: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({})),
        ]),
      }).isRequired,
    }),
  }),
  title: PropTypes.string.isRequired,
  action: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colorTheme: PropTypes.string,
    internalUrl: PropTypes.string,
    externalUrl: PropTypes.string,
    actionType: PropTypes.oneOf(['onclick', 'externalLink', 'internalLink']),
    onClick: PropTypes.func,
    svgIcon: PropTypes.string,
    svgLocation: PropTypes.oneOf(['before', 'after']),
  }),
};

AdFeature.defaultProps = {
  staticImage: {},
  action: undefined,
};
