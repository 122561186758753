import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

export const NavWrapper = styled('nav', {
  boxSizing: 'border-box',
  display: 'block',
  listStyle: 'none',

  variants: {
    display: {
      horizontal: {
        ul: {
          display: 'inline-flex',
        },
      },
    },
    vertical: {
      ul: {
        display: 'block',
      },
    },
  },
});

export const StyledLink = styled(Link, {
  '&:hover': {
    opacity: '0.5',
    transition: 'opacity .3s ease',
    transition: 'color .3s ease',
  },
  variants: {
    isCurrentPage: {
      true: {
        opacity: '0.5',
        transition: 'opacity .3s ease',
      },
    },
  },
});
