import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

export const TagWrapperLink = styled(Link, {
  border: '1px $lightGrey solid',
  display: 'inline-block',
  br: '$pill',
  padding: '$16 $16',
  margin: '0 $8 $8',
  lineHeight: '1.1em',
  fontSize: '$12',
  color: '$mainTextColor',
  textDecoration: 'none',
  variants: {
    color: {
      white: {
        color: '$white',
      },
    },
  },
});
export const TagWrapper = styled('span', {
  border: '1px $lightGrey solid',
  display: 'inline-block',
  br: '$pill',
  padding: '$16 $16',
  margin: '0 $8 $8',
  lineHeight: '1.1em',
  fontSize: '$12',
  color: '$mainTextColor',
  textDecoration: 'none',
  variants: {
    color: {
      white: {
        border: '1px $white solid',
        color: '$white',
      },
    },
  },
});

export const TagTextStyle = styled('span', {
  br: '$8',
  textTransform: 'uppercase',
  letterSpacing: '.2px',
  fontFamily: '$ArtifaktElementBold',
  userSelect: 'none',
});
