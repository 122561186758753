import Button from '@components/atoms/button/Button';
import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const StyledButton = styled(Button, {
  // 'a, button': {
  margin: '$4 0',
  // '&:first-of-type': {
  //   marginTop: '$32',
  // },
  // },
});

export const CommunitySidebarWrapper = styled('div', {
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  margin: '0',
  '@bp2': {
    width: '240px',
  },
  flexShrink: '0'
});

export const CommunityContentActionCard = styled('div', {
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  'div:first-child': {
    marginBottom: '$4',
  },

  marginBottom: '$40',

  '@bp2': {
    marginBottom: '0',
  },
});

export const DontShowBtn = styled(Button, {
  textTransform: 'unset',
  width: 'fit-content',
  padding: '0 !important',
  textAlign: 'left',
  minHeight: 'unset !important',
  marginTop: '0',
  marginBottom: '$16',
  display: 'flex',

  '&:hover':{
    backgroundColor: 'transparent !important'
  },

  '@bp2': {
    display: 'none',
  },
});

export const StyledText = styled(Text, {
  marginBottom: '$16',

  '@bp2': {
    marginBottom: '$32',
  },
});

export const TextWrapper = styled('div', {
  maxHeight: 'unset',
  overflow: 'hidden',
  transition: 'max-height 0.2s ease-out',

  variants: {
    isHidden: {
      true: {
        maxHeight: '0',

        '@bp2': {
          maxHeight: 'unset',
        },
      },
    },
  },
});

export const NavContainer = styled('div', {
  display: 'none',

  '@bp2': {
    display: 'block',
  },
});

export const CommunitySidebarFixed = styled('div', {
  '@bp3': {
    position: 'fixed',
    width: '240px',
  },
});
