import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { LineChartWrapper } from './LineChart.styled';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

function LineChart(props) {
  const { compare, datas } = props;

  const chosenPediodLabels = datas.dataSets[1].datas.map(el => el.label);
  const previousPeriodLabels = datas.dataSets[0].datas.map(el => el.label);

  const labelByPairs = chosenPediodLabels.map((item, i) => [
    item,
    compare ? previousPeriodLabels[i] || '' : '',
  ]);

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: true,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      ...(compare
        ? {
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          }
        : {}),
    },
  };

  const data = {
    labels: labelByPairs,
    datasets: [
      ...(compare
        ? [
            {
              label: `Previous period (${previousPeriodLabels[0]} to ${
                previousPeriodLabels[previousPeriodLabels.length - 1]
              })`,
              data: previousPeriodLabels.map(
                item =>
                  datas.dataSets[0].datas.find(el => el.label === item).value
              ),
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderDash: [10, 10],
              yAxisID: 'y1',
            },
          ]
        : []),
      {
        label: `Current period (${chosenPediodLabels[0]} to ${
          chosenPediodLabels[chosenPediodLabels.length - 1]
        })`,
        data: chosenPediodLabels.map(
          item => datas.dataSets[1].datas.find(el => el.label === item).value
        ),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y',
      },
    ],
  };

  return (
    <LineChartWrapper>
      <Line options={options} data={data} />
    </LineChartWrapper>
  );
}

LineChart.propTypes = {
  compare: PropTypes.bool,
  datas: PropTypes.shape({
    dataSets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      })
    ),
  }).isRequired,
};

LineChart.defaultProps = {
  compare: false,
};

export default LineChart;
