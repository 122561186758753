import {
  DELETE_USER_FROM_GROUP,
  MANAGE_GROUP_USERS,
} from '@src/apollo/mutation';
import React, { useContext, useEffect, useState } from 'react';
import { validURL } from '@src/utils/validURL';
import AdvanceFilters from '@components/molecules/advanceFilters/AdvanceFilters';
import DateIconLink from '@components/organisms/table/components/columnItem/DateIconLink';
import Loader from '@components/atoms/loader/Loader';
import Options from '@components/organisms/table/components/columnItem/Options';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import Table from '@components/organisms/table/Table';
import TextWithDot from '@components/organisms/table/components/columnItem/TextWithDot';
import UserInfos from '@components/organisms/table/components/columnItem/UserInfos';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';

const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(date, 'LLL, d y');
};

const convertUTCtoLocalTime = date => {
  if (!date) {
    return date;
  }
  let formatedDate = new Date(date + ' UTC');
  let hours = formatedDate.getHours();
  let minutes = formatedDate.getMinutes();

  let suffix;
  if (hours >= 12) {
    suffix = ' PM';
  } else {
    suffix = ' AM';
  }
  hours = hours % 12 || 12;
  minutes = ('0' + minutes).slice(-2);
  let time = hours + ':' + minutes + suffix;
  return time;
};
const MembersTable = props => {
  const stringsContext = useContext(StringsContext);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitFilters = () => {
    // All Filters
    const allSelectedFilters = {};

    // Checkbox - Roles
    let group_role = null;
    filters.forEach(filter => {
      if (filter.name == 'role') {
        group_role = filter.selectedValue;
      }
    });
    if (group_role) {
      allSelectedFilters.group_role = group_role;
    }

    // Radio - Status
    let number_of_posts = null;
    filters.forEach(filter => {
      if (filter.name == 'post-number') {
        number_of_posts = parseInt(filter.selectedValue);
      }
    });
    if (number_of_posts) {
      if (number_of_posts === 50) {
        number_of_posts = '0-50';
      } else if (number_of_posts === 100) {
        number_of_posts = '51-100';
      } else {
        number_of_posts = '101-x';
      }
      allSelectedFilters.number_of_posts = number_of_posts;
    }

    // Date - Last Post
    let last_post_date_start = null;
    let last_post_date_end = null;
    filters.forEach(filter => {
      if (filter.name == 'last-post') {
        last_post_date_start = filter.startDate;
        last_post_date_end = filter.endDate;
      }
    });
    if (last_post_date_start) {
      allSelectedFilters.last_post_date_start = last_post_date_start.toString();
    }
    if (last_post_date_end) {
      allSelectedFilters.last_post_date_end = last_post_date_end.toString();
    }

    // Date - First Post
    let date_joined_start = null;
    let date_joined_end = null;
    filters.forEach(filter => {
      if (filter.name == 'date-joined') {
        date_joined_start = filter.startDate;
        date_joined_end = filter.endDate;
      }
    });
    if (date_joined_start) {
      allSelectedFilters.date_joined_start = date_joined_start.toString();
    }
    if (date_joined_end) {
      allSelectedFilters.date_joined_end = date_joined_end.toString();
    }
    props.fetchWithFilters(allSelectedFilters);
  };

  const onChangeRole = e => {
    filters.forEach(filter => {
      if (filter.name == 'role') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value == e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
  };

  // Radio - Status
  const onChangePostNumber = e => {
    filters.forEach(filter => {
      if (filter.name == 'post-number') {
        filter.selectedValue = e.currentTarget.value;
        filter.options.forEach(option => {
          option.isSelected = false;
          if (option.value == e.currentTarget.value) {
            option.isSelected = e.currentTarget.checked;
          }
        });
      }
    });
  };

  // Date - Last Post
  const onLastPostChange = (name, date) => {
    filters.forEach(filter => {
      if (filter.name == 'last-post') {
        filter[name] = date;
      }
    });
  };

  // Date - First Post
  const onJoinDateChange = (name, date) => {
    filters.forEach(filter => {
      if (filter.name == 'date-joined') {
        filter[name] = date;
      }
    });
  };

  const filters = [
    {
      title: <String id="community_admin_filter_role" />,
      type: 'radio',
      name: 'role',
      selectedValue: '',
      onChange: onChangeRole,
      options: [
        {
          name: 'All',
          id: 'All',
          label: <String id="community_admin_filter_all" />,
          value: '',
          isChecked: false,
        },
        {
          name: 'Moderator',
          id: 'Moderator',
          label: <String id="community_admin_filter_moderator" />,
          value: 'moderator',
          isChecked: false,
        },
        {
          name: 'Member',
          id: 'Member',
          label: <String id="community_admin_filter_member" />,
          value: 'member',
          isChecked: false,
        },
        // {
        //   name: 'Pre-approved',
        //   id: 'Pre-approved',
        //   label: <String id="community_admin_filter_pre_approved" />,
        //   value: 'pre_approved',
        //   isChecked: false,
        // },
        {
          name: 'Blocked',
          id: 'Blocked',
          label: <String id="community_admin_filter_blocked" />,
          value: 'blocked',
          isChecked: false,
        },
        {
          name: 'Contributor',
          id: 'Contributor',
          label: <String id="community_admin_filter_contributor" />,
          value: 'pre_approved',
          isChecked: false,
        },
      ],
    },
    {
      title: <String id="community_admin_filter_number_posts" />,
      type: 'radio',
      name: 'post-number',
      onChange: onChangePostNumber,
      selectedValue: '',
      options: [
        {
          name: '0 to 50',
          id: '0 to 50',
          label: <String id="community_admin_filter_0_50" />,
          value: '50',
          isChecked: false,
        },
        {
          name: '51 to 100',
          id: '51 to 100',
          label: <String id="community_admin_filter_51_100" />,
          value: '100',
          isChecked: false,
        },
        {
          name: 'More than 101',
          id: 'More than 101',
          label: <String id="community_admin_filter_more_than" />,
          value: '101',
          isChecked: false,
        },
      ],
    },
    {
      title: <String id="community_admin_filter_last_post" />,
      type: 'date',
      name: 'last-post',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onLastPostChange,
    },
    {
      title: <String id="community_admin_filter_date_joined" />,
      type: 'date',
      name: 'date-joined',
      startDateLabel: 'From',
      endDateLabel: 'To',
      startDate: null,
      endDate: null,
      onChange: onJoinDateChange,
    },
  ];

  const [advancedFiltersState, setAdvancedFiltersState] = useState({
    title: `${stringsContext.community_admin_members_title.field} (${props.memberCount})`,
    filters,
    onSubmitFilters,
  });
  const [deleteUser, deleteUserData] = useMutation(DELETE_USER_FROM_GROUP, {
    onCompleted: () => {
      props.refetch();
    },
  });

  const [manageUser, manageUserData] = useMutation(MANAGE_GROUP_USERS, {
    onCompleted: () => {
      props.refetch();
    },
  });

  const bulkActions = [
    {
      label: <String id="community_delete_members" />,
      onClickAction: mems => {
        for (let i = 0; i < mems.length; i++) {
          deleteUser({
            variables: {
              groupId: props.groupId,
              userId: mems[i].id,
            },
          });
        }
      },
    },
    {
      label: <String id="community_admin_block_members" />,
      onClickAction: mems => {
        for (let i = 0; i < mems.length; i++) {
          manageUser({
            variables: {
              group_user_id: mems[i].id,
              group_id: props.groupId,
              action: 'blocked',
            },
          });
        }
      },
    },
    {
      label: <String id="community_admin_pre_approve_member" />,
      onClickAction: mems => {
        for (let i = 0; i < mems.length; i++) {
          manageUser({
            variables: {
              group_user_id: mems[i].id,
              group_id: props.groupId,
              action: 'pre_approved',
            },
          });
        }
      },
    },
  ];

  // const singleRowActions = [
  //   {
  //     label: <String id="community_admin_appoint_moderator" />,
  //     onClickAction: item => {
  //       manageUser({
  //         variables: {
  //           group_user_id: item.id,
  //           group_id: props.groupId,
  //           action: 'moderator',
  //         },
  //       });
  //     },
  //   },
  //   {
  //     label: <String id="community_admin_appoint_contributor" />,
  //     onClickAction: item => {
  //       manageUser({
  //         variables: {
  //           group_user_id: item.id,
  //           group_id: props.groupId,
  //           action: 'contributor',
  //         },
  //       });
  //     },
  //   },
  //   {
  //     label: <String id="community_admin_pre_approve_publications" />,
  //     onClickAction: item => {
  //       manageUser({
  //         variables: {
  //           group_user_id: item.id,
  //           group_id: props.groupId,
  //           action: 'pre_approved',
  //         },
  //       });
  //     },
  //   },
  //   {
  //     label: <String id="community_admin_block_member" />,
  //     onClickAction: item => {
  //       manageUser({
  //         variables: {
  //           group_user_id: item.id,
  //           group_id: props.groupId,
  //           action: 'blocked',
  //         },
  //       });
  //     },
  //     status: 'attention',
  //   },
  // ];

  const tableProps = {
    nextPage: () => {
      props.onNextPage();
    },
    prevPage: () => {
      props.onPrevPage();
    },
    changeRowPerPage: rowCount => {
      props.onChangeLimitRow(rowCount);
    },
    currentCount: props.currentPageCount,
    totalCount: props.memberCount,
    columns: [
      {
        header: <String id="community_admin_profile_tab" />,
        accessor: 'profile',
      },
      {
        header: <String id="community_admin_posts_tab" />,
        accessor: 'posts',
      },
      {
        header: <String id="community_admin_last_post_tab" />,
        accessor: 'last_post',
      },
      {
        header: <String id="community_admin_joined_tab" />,
        accessor: 'joined',
      },
      {
        header: <String id="community_admin_role_tab" />,
        accessor: 'role',
      },
      {
        header: '',
        accessor: 'options',
      },
      {
        header: '',
        accessor: 'id',
      },
    ],

    rowData: [],
    bulkActions,
    currentPage: props.currentPage,
    maxPage: props.maxPage,
  };

  useEffect(() => {
    setIsLoading(false);
  }, [props.membersList]);

  useEffect(() => {
    setAdvancedFiltersState({
      ...advancedFiltersState,
      title: `${stringsContext.community_admin_members_title.field} (${props.memberCount})`,
    });
  }, [props.memberCount]);

  if (props.membersList) {
    props.membersList.forEach(member => {
      const singleRowActions = [
        {
          label:
            member.group_role === 'moderator' ? (
              <String id="community_admin_appoint_memberr" />
            ) : (
              <String id="community_admin_appoint_moderator" />
            ),
          onClickAction: el => {
            manageUser({
              variables: {
                group_user_id: el.id,
                group_id: props.groupId,
                action:
                  member.group_role === 'moderator' ? 'member' : 'moderator',
              },
            });
          },
        },
        // {
        //   label:
        //     member.group_role === 'contributor' ? (
        //       <String id="community_admin_appoint_memberr" />
        //     ) : (
        //       <String id="community_admin_appoint_contributor" />
        //     ),
        //   onClickAction: el => {
        //     manageUser({
        //       variables: {
        //         group_user_id: el.id,
        //         group_id: props.groupId,
        //         action:
        //           member.group_role === 'contributor'
        //             ? 'member'
        //             : 'contributor',
        //       },
        //     });
        //   },
        // },
        {
          label:
            member.group_role === 'pre_approved' ? (
              <String id="community_admin_appoint_memberr" />
            ) : (
              <String id="community_admin_pre_approve_publications" />
            ),
          onClickAction: el => {
            manageUser({
              variables: {
                group_user_id: el.id,
                group_id: props.groupId,
                action:
                  member.group_role === 'pre_approved'
                    ? 'member'
                    : 'pre_approved',
              },
            });
          },
        },
        {
          label:
            member.group_role === 'blocked' ? (
              <String id="community_admin_appoint_memberr" />
            ) : (
              <String id="community_admin_block_member" />
            ),
          onClickAction: el => {
            manageUser({
              variables: {
                group_user_id: el.id,
                group_id: props.groupId,
                action: member.group_role === 'blocked' ? 'member' : 'blocked',
              },
            });
          },
          status: member.group_role === 'blocked' ? 'help' : 'attention',
        },
      ];

      let color = '';
      let role = '';
      if (member.group_role === 'member') {
        color = 'gray';
        role = <String id="community_admin_filter_member" />;
      } else if (member.group_role === 'pre_approved') {
        color = 'blue';
        role = <String id="community_admin_filter_contributor" />;
      } else if (member.group_role === 'contributor') {
        color = 'blue';
        role = <String id="community_admin_filter_contributor" />;
      } else if (member.group_role === 'moderator') {
        color = 'yellow';
        role = <String id="community_admin_filter_moderator" />;
      } else if (member.group_role === 'blocked') {
        role = <String id="community_admin_filter_blocked" />;
        color = 'red';
      }

      let name = '';

      if (member?.first_name !== null && member?.first_name !== '') {
        name = member?.first_name + ' ' + member?.last_name;
      }

      tableProps.rowData.push({
        profile: (
          <UserInfos
            name={name}
            job={member.title}
            url={member.userProfile.profile_picture}
          />
        ),
        posts: member.group_posts_count,
        last_post: (
          <DateIconLink
            date={convertUTCToLocalDate(member.last_post_date)}
            time={convertUTCtoLocalTime(member.last_post_date)}
            // url={member.last_post_link}
            url={props.locationOrigin + '/m/' + member.username}
          />
        ),
        joined: convertUTCToLocalDate(member.join_date),
        role: <TextWithDot status={role} color={color} />,
        options: <Options actions={singleRowActions} id={member.id} />,
        id: member.id,
      });
    });
  }

  return (
    <>
      <AdvanceFilters
        {...advancedFiltersState}
        onSearchSubmit={props.onSearchSubmit}
      />
      <Table
        {...tableProps}
        loading={
          props.loading || manageUserData.loading || deleteUserData.loading
        }
      />
      {isLoading ? <Loader isLoading="true" /> : null}
    </>
  );
};

export default MembersTable;
