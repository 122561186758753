import { styled } from '@root/stitches.config';

export const TooltipContentWrapper = styled('div', {
  fontFamily: 'ArtifaktElementBold',
  maxWidth: '350px',
  fontSize: '12px',
  padding: '6px',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    fill: 'black',
    height: '$24',
    width: '$24',
    position: 'relative',
    marginRight: '$4',
  },
});
