import { useEffect } from 'react';
import useLocalStorage from './use-local-storage';
import useMedia from './use-media';
import { darkThemeMode } from '@root/stitches.config';

function useDarkMode() {
  //use our iseLocalStorage to persist state through a page refresh
  const [enabledState, setEnabledState] = useLocalStorage('darkMode');

  //if user has set a browser or OS preference for dark mode
  const prefersDarkMode = usePrefersdarkMode();

  //this allows user to override OS level setting on our website
  const enabled =
    typeof enabledState !== 'undefined' ? enabledState : prefersDarkMode;

  //effect that add/ removes dark mode class
  useEffect(
    () => {
      const className = 'dark-mode';
      const element = document.getElementById('___gatsby');

      const bodyWrapper = document.getElementById('body-wrapper');
      bodyWrapper.style.backgroundImage = `url(/images/bg_dark.svg)`;
      if (enabled) {
        element.classList.add(className);
        element.classList.add(darkThemeMode);
        element.classList.remove('light-mode');
      } else {
        element.classList.add('light-mode');
        element.classList.remove(className);
        element.classList.remove(darkThemeMode);
        bodyWrapper.style.backgroundImage = `url(/images/bg_light.svg)`;
      }
    },
    [enabled] //only re-call effect when value changes
  );

  return [enabled, setEnabledState];
}

function usePrefersdarkMode() {
  return useMedia(['(prefers-color-scheme: dark)'], [true], false);
}

export default useDarkMode;
