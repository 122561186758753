import {
  BannerWrapper,
  CirclePlus,
  ContentWrapper,
  EmptySectionWrapper,
  HeadingStyled,
  TextWrapper,
} from './CreateProfileBanner.styled';
import React, { useContext } from 'react';

import Button from '@components/atoms/button/Button';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import { Heading } from '@components/atoms/heading/Heading';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';

function CreateProfileBanner({ type, isEmptySection, onCreateButtonClick }) {
  // const {} = props;

  const UrlContext = useContext(UrlsContext);

  return (
    <>
      {!isEmptySection ? (
        <BannerWrapper onClick={onCreateButtonClick}>
          <ContentWrapper>
            <CirclePlus>
              <Svg icon="plus" />
            </CirclePlus>
            <TextWrapper>
              <HeadingStyled level="h4">
                <String id={`profile_banner_create_${type}_title`} />
              </HeadingStyled>
              <Text size="14" as="p">
                <String id={`profile_banner_create_${type}_subtitle`} />
              </Text>
            </TextWrapper>
          </ContentWrapper>
        </BannerWrapper>
      ) : (
        <EmptySectionWrapper>
          <Heading css={{ fontWeight: '600' }} level="h3">
            <String id={`profile_banner_create_${type}_empty_section_title`} />
          </Heading>
          <Button
            actionType="onclick"
            onClick={onCreateButtonClick}
            label={
              <String
                id={`profile_banner_create_${type}_empty_section_button`}
              />
            }
            svgIcon="plus"
          />
        </EmptySectionWrapper>
      )}
    </>
  );
}

export default CreateProfileBanner;
