import { gql } from '@apollo/client';

// USERS
export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    is_internal
    first_name
    last_name
    title
    role
    id
    username
    userProfile {
      profile_picture {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
  }
`;

// USERCONTENTS
export const USERCONTENT_DATA_FRAGMENT = gql`
  fragment UserContentDataFragment on UserContent {
    id
    slug
    image {
      path
      width
      height
      sizes {
        path
        width
        height
      }
    }
    title
    createdAt
    publishedAt
    isLiked
    views
    usersLikedCount
    hasDraft
    status
    userContentType
    tools {
      id
      title
      icon {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
    industries {
      id
      name
    }
  }
`;

export const USERCONTENT_PAGINATION_FRAGMENT = gql`
  fragment UserContentPaginationFragment on userContentPagination {
    total
    current_page
    has_more_pages
    last_page
    per_page
  }
`;

export const TUTORIALS_FRAGMENT = gql`
  fragment TutorialsFragment on UserContent {
    tutorial_level {
      id
      name
    }
    tutorial_type {
      id
      name
    }
    blocs {
      type
      contents {
        id
        userContentType
      }
    }
    tutorial_duration
  }
`;

export const MOTM_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment MotmFragment on UserContent {
    motm_year
    motm_member {
      ...UserFragment
    }
  }
`;

export const ARTICLES_FRAGMENT = gql`
  fragment ArticlesFragment on UserContent {
    article_type {
      name
      id
      key
    }
    article_section {
      key
      id
      name
    }
  }
`;

// COMMUNITY
export const POST_DATA_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment PostDataFragment on Post {
    status
    level
    id
    slug
    content
    createdAt
    link_url
    link_description
    link_title
    link_image {
      path
      width
      height
      sizes {
        path
        width
        height
      }
    }
    images {
      id
      path
      width
      height
      sizes {
        path
        width
        height
      }
    }
    author {
      ...UserFragment
    }
  }
`;

export const GROUP_MEMBER_FRAGMENT = gql`
  fragment GroupMemberFragment on GroupMember {
    first_name
    last_name
    title
    id
    group_role
    username
    userProfile {
      profile_picture {
        path
        width
        height
        sizes {
          path
          width
          height
        }
      }
    }
  }
`;
