import {
  CreateHeaderBarContainer,
  CreateHeaderBarWrapper,
  ExitContainer,
  StyledButton,
  SvgContainer,
} from './CreateHeaderBar.styled';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Button from '@components/atoms/button/Button';
import { CREATE_USERCONTENT_REPORT } from '@src/apollo/mutation';
import { ClientNotificationContext } from '@context/ClientNotificationContext';
import Modal from '@components/atoms/modal/Modal';
import PendingPublicationsModal from '@components/molecules/pendingPublicationsModal/PendingPublicationsModal';
import PropTypes from 'prop-types';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import { useIsMobile } from '@src/utils/useIsMobile';
import useModal from '@components/atoms/modal/useModal';
import { useMutation } from '@apollo/client';
import TooltipBox from '@components/atoms/tooltip/TooltipBox';
import SvgReactions from '@components/atoms/svgReactions/SvgReactions';

const CreateHeaderBar = props => {
  const {
    editable,
    hasExit,
    editUrl,
    onDeleteClick,
    counter,
    onCloseClick,
    onLikeClick,
    onFavoriteClick,
    isPending,
    isDraft,
    isFavorited,
    href,
    isLiked,
    isDataSet,
    pathname,
    title,
  } = props;

  const [isFavoritedState, setIsFavoritedState] = useState(isFavorited);
  const [isLikedState, setIsLikedState] = useState(isLiked);

  const isMobile = useIsMobile();
  const authContext = useContext(AuthContext);

  const [modalType, setModalType] = useState();

  const [createUsercontentReport, createUsercontentReportData] = useMutation(
    CREATE_USERCONTENT_REPORT
  );
  const onReportUserContentClick = type => {
    createUsercontentReport({
      variables: {
        usercontent_id: props.userContentId,
        type,
      },
    });
  };

  function toggleModal(type) {
    toggle();
    setModalType(type);
  }

  useEffect(() => {
    setIsFavoritedState(isFavorited);
  }, [isFavorited, authContext]);

  useEffect(() => {
    setIsLikedState(isLiked);
  }, [isLiked, authContext]);

  const { isShowing, toggle } = useModal();

  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  const onShareClick = () => {
    navigator.clipboard.writeText(href);
    handleUpdateClientNotification({
      text: 'add_to_clipboard_notification',
      type: 'success',
      autoDismiss: true,
    });
  };

  return (
    <CreateHeaderBarWrapper>
      {isDataSet ? (
        <CreateHeaderBarContainer>
          {!isMobile && (
            <Breadcrumb pathname={pathname} customLastItem={title} />
          )}
          <div
            style={{
              display: 'flex',
            }}
          >
            {editable && (
              <>
                {!isPending && (
                  <Button
                    actionType="internalLink"
                    colorTheme="normal"
                    css={{
                      width: '44px',
                      height: '44px',
                      svg: {
                        width: '22px',
                        height: '22px',
                        position: 'relative',
                        left: '1px',
                      },
                    }}
                    internalUrl={editUrl}
                    svgIcon="edit"
                    type="circle"
                  />
                )}
                <Button
                  actionType="onclick"
                  colorTheme="normal"
                  css={{ marginLeft: '$8' }}
                  onClick={onDeleteClick}
                  svgIcon="trash"
                  type="circle"
                />
              </>
            )}

            {!isDraft && !isPending && (
              <>
                <SvgContainer>
                  <TooltipBox
                    delay={100}
                    stringId={
                      !isLiked
                        ? 'create_header_like_it'
                        : 'create_header_unlike_it'
                    }
                    iconName={!isLikedState ? 'heart' : 'heart-full'}
                  >
                    <StyledButton
                      label={counter > 0 ? counter : ''}
                      actionType="onclick"
                      colorTheme="transparent"
                      onClick={() => onLikeClick()}
                      svgIcon={!isLikedState ? 'heart' : null}
                      type={isMobile ? 'circle' : 'normal'}
                      children={
                        isLikedState ? (
                          <SvgReactions
                            label={'love'}
                            active={true}
                            inactive={false}
                          />
                        ) : null
                      }
                    />
                  </TooltipBox>
                </SvgContainer>

                <SvgContainer>
                  <TooltipBox
                    delay={100}
                    stringId={
                      !isFavoritedState
                        ? 'create_header_add_favorites'
                        : 'create_header_remove_favorites'
                    }
                    iconName={
                      !isFavoritedState ? 'favorite' : 'favorite-filled'
                    }
                  >
                    <StyledButton
                      actionType="onclick"
                      colorTheme="transparent"
                      onClick={() => onFavoriteClick()}
                      svgIcon={
                        !isFavoritedState ? 'favorite' : 'favorite-filled'
                      }
                      type={isMobile ? 'circle' : 'normal'}
                    />
                  </TooltipBox>
                </SvgContainer>
                <SvgContainer>
                  <TooltipBox
                    delay={100}
                    stringId="create_header_copy_link"
                    iconName={'chain'}
                  >
                    <StyledButton
                      actionType="onclick"
                      colorTheme="transparent"
                      onClick={onShareClick}
                      svgIcon="chain"
                      type={isMobile ? 'circle' : 'normal'}
                    />
                  </TooltipBox>
                </SvgContainer>
                {/* <WrapperActions> */}
                <SvgContainer>
                  <TooltipBox
                    delay={100}
                    stringId="create_header_report"
                    iconName={'report-flag'}
                  >
                    <StyledButton
                      actionType="onclick"
                      colorTheme="transparent"
                      // onClick={onReportClick}
                      onClick={() => toggleModal('report')}
                      svgIcon="report-flag"
                      type="circle"
                    />
                  </TooltipBox>
                </SvgContainer>
                {/* <WrapperOptions>
                  <BtnOptions
                    type="normal"
                    colorTheme="transparent"
                    svgIcon="edit"
                    label="Report this content"
                  />
                  <BtnOptions
                    type="normal"
                    colorTheme="transparent"
                    svgIcon="edit"
                    label="Save as Favorite"
                  />
                </WrapperOptions>
              </WrapperActions> */}
              </>
            )}
          </div>
          {hasExit ? (
            <ExitContainer>
              <Button
                actionType="onclick"
                colorTheme="gray"
                css={{
                  width: '44px',
                  height: '44px',
                }}
                onClick={onCloseClick}
                svgIcon="close-small"
                type="circle"
              />
            </ExitContainer>
          ) : null}
        </CreateHeaderBarContainer>
      ) : null}
      {/* <Modal
        isShowing={isShowing}
        hide={toggle}
        hasImageHeader={modalType && modalType !== 'report'}
      >
        {!props.isVerified && props.isLogged && (
          <PublishNotification type={'validation'} />
        )}
        {!props.isOnboarded && props.isLogged && (
          <PublishNotification
            type={'onboarding'}
            url={'/m/' + authContext.userSlug}
          />
        )}
        {!props.isLogged && (
          <PublishNotification
            type="join"
            groupName={''}
            // onJoinClick={onJoinClick}
          />
        )}
      {modalType === "report" && (
          <PendingPublicationsModal
            closeModal={() => toggleModal(undefined)}
            postId={props.userContentId}
            onReportUserContentClick={onReportUserContentClick}
            isReporting
            isReportingUserContent
          />
        )}
      </Modal> */}

      <Modal
        isShowing={isShowing}
        hide={toggle}
        hasImageHeader={!props.isOnboarded}
      >
        {!props.isVerified && props.isLogged && (
          <PublishNotification type={'validation'} />
        )}
        {!props.isOnboarded && props.isLogged && (
          <PublishNotification
            type={'onboarding'}
            url={'/m/' + authContext.userSlug}
          />
        )}
        {!props.isLogged && <PublishNotification type="join" />}
        {props.isVerified && props.isOnboarded && (
          <PendingPublicationsModal
            closeModal={() => toggleModal(undefined)}
            postId={props.userContentId}
            onReportUserContentClick={onReportUserContentClick}
            isReporting
            isReportingUserContent
          />
        )}
      </Modal>
    </CreateHeaderBarWrapper>
  );
};

CreateHeaderBar.propTypes = {
  counter: PropTypes.number,
  editable: PropTypes.bool,
  hasExit: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  onShareClick: PropTypes.func,
  editUrl: PropTypes.string,
  onDeleteClick: PropTypes.func,
  onLikeClick: PropTypes.func,
  isPending: PropTypes.bool,
  isDraft: PropTypes.bool,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

CreateHeaderBar.defaultProps = {
  counter: 0,
  editable: false,
  hasExit: false,
  isPending: false,
  isDraft: false,
  pathname: '',
  title: '',
  onCloseClick: () => {},
  onFavoriteClick: () => {},
  onShareClick: () => {},
  editUrl: '',
  onDeleteClick: () => {},
  onLikeClick: () => {},
};

export default CreateHeaderBar;
