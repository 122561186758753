import { styled } from '@root/stitches.config';

export const DoughnutGraphBlockWrapper = styled('div', {
  width: '100%',
});
export const DoughnutGraphHeader = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$16',
});
export const GraphContainer = styled('div', {
  display: 'flex',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '16px',
  variants: {
    hasTitle: {
      true: {
        padding: '10px',
      }
    }
  }
});
