import {
  BottomContainer,
  HeaderAvatar,
  PostsWireFrameWrapper,
  SmallLinesContainer,
  TopContainer,
} from './PostsWireFrame.styled';

import React from 'react';
import PropTypes from 'prop-types';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';

const PostsWireFrame = ({numberToPrint, showImage, id, isInFavorites}) => {
  return [...Array(numberToPrint)].map(item => (
    <PostsWireFrameWrapper key={`post-wireframe-${id}-${item}`}>
      <TopContainer css={showImage ? {paddingBottom: '$4'} : {}}>
        <HeaderAvatar>
          <LoaderSkeleton height="40px" width="40px" isCircle />
          <SmallLinesContainer css={{ml: '$8'}}>
            <LoaderSkeleton height="16px" width="100%" />
            <LoaderSkeleton height="16px" width="100%" />
          </SmallLinesContainer>
        </HeaderAvatar>
        <LoaderSkeleton height="10px" width="100%" />
        <LoaderSkeleton height="10px" width="100%" />
        <LoaderSkeleton height="10px" width="70%" />
      </TopContainer>
      {showImage && (
        <LoaderSkeleton height="300px" width="100%" />
      )}
      {!isInFavorites && (
        <BottomContainer css={showImage ? {paddingTop: '$16'} : {}}>
          <LoaderSkeleton height="30px" width="30px" isCircle />
          <SmallLinesContainer css={{ml: '$8', display: "flex", alignItems: "center" }}>
            <LoaderSkeleton height="30px" width="30px" isCircle />
            <LoaderSkeleton height="16px" width="60px" css={{ml: '$8'}} />
          </SmallLinesContainer>
        </BottomContainer>
      )}
    </PostsWireFrameWrapper>
  ))
};

PostsWireFrame.propTypes = {
  numberToPrint: PropTypes.number,
  isInFavorites: PropTypes.bool
};

PostsWireFrame.defaultProps = {
  numberToPrint: 1,
  isInFavorites: false,
};

export default PostsWireFrame;
