export const stringsData = {
  data: {
    entries: [
      {
        strings: [
          {
            field: 'Content',
            typeHandle: 'text',
            key: 'admin_cms_stats_content_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Community',
            typeHandle: 'text',
            key: 'admin_cms_stats_community_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Members',
            typeHandle: 'text',
            key: 'admin_cms_stats_members_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Delete',
            typeHandle: 'text',
            key: 'admin_create_content_delete_label',
          },
          {
            field: "Set Editor's pick",
            typeHandle: 'text',
            key: 'admin_create_content_set_editor_pick_label',
          },
          {
            field: "Remove Editor's Pick",
            typeHandle: 'text',
            key: 'admin_create_content_unset_editor_pick_label',
          },
          {
            field: 'Publish',
            typeHandle: 'text',
            key: 'admin_create_content_publish_label',
          },
          {
            field: 'Unpublish',
            typeHandle: 'text',
            key: 'admin_create_content_unpublish_label',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Legal',
            typeHandle: 'text',
            key: 'content_page_legal_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_legal_title_input_label',
          },
          {
            field: 'Content',
            typeHandle: 'text',
            key: 'content_page_legal_content_title',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_legal_save_changes',
          },
          {
            field: 'SEO',
            typeHandle: 'text',
            key: 'content_page_legal_section_seo_title',
          },
          {
            field: 'OG Image',
            typeHandle: 'text',
            key: 'content_page_legal_section_seo_image_title',
          },
          {
            field: 'Add image',
            typeHandle: 'text',
            key: 'content_page_legal_upload_banner_label',
          },
          {
            field: 'Remove image',
            typeHandle: 'text',
            key: 'content_page_legal_remove_banner_label',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Special AREA Website Terms',
            typeHandle: 'text',
            key: 'legal_title_page',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Member Spotlight',
            typeHandle: 'text',
            key: 'page_inspire_mom_title',
          },
          {
            field: 'AREA Member Spotlight',
            typeHandle: 'text',
            key: 'page_inspire_mom_intro',
          },
          {
            field: 'Explore more',
            typeHandle: 'text',
            key: 'page_inspire_mom_links_section_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Support',
            typeHandle: 'text',
            key: 'support_title',
          },
          {
            field:
              "Questions, bugs reports, feedback, feature requests, we're here for it all!",
            typeHandle: 'text',
            key: 'support_description',
          },
          {
            field: 'Full name',
            typeHandle: 'text',
            key: 'support_field_name',
          },
          {
            field: 'Email',
            typeHandle: 'text',
            key: 'support_field_email',
          },
          {
            field: 'How can we help you today?',
            typeHandle: 'text',
            key: 'support_field_feedback',
          },
          {
            field:
              'Oops, there was an error with your request, please try again!',
            typeHandle: 'text',
            key: 'support_form_error',
          },
          {
            field: 'Provide comments',
            typeHandle: 'text',
            key: 'support_field_feedback_placeholder',
          },
          {
            field: 'Please enter a brief description of your request!',
            typeHandle: 'text',
            key: 'support_field_feedback_error',
          },
          {
            field: 'Please provide a valid name',
            typeHandle: 'text',
            key: 'support_field_name_error',
          },
          {
            field: 'Please provide a valid email',
            typeHandle: 'text',
            key: 'support_field_email_error',
          },
          {
            field:
              '<p>\n Thank you for your feedback.\n <br />\n Your request has been received and is being reviewed by our support staff.\n </p>',
            typeHandle: 'richText',
            key: 'support_form_success',
          },
          {
            field: 'Send Message',
            typeHandle: 'text',
            key: 'support_submit',
          },
          {
            field: 'ReCaptcha token expired, please re-validate again',
            typeHandle: 'text',
            key: 'support_recaptcha_token_expired',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Leaving the builder?',
            typeHandle: 'text',
            key: 'create_build_artwork_leaving_create_notification_title',
          },
          {
            field: 'All unsaved changes to your artwork will be lost.',
            typeHandle: 'text',
            key: 'create_build_artwork_leaving_create_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_build_artwork_leaving_create_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_build_artwork_leaving_create_notification_link_2',
          },
          {
            field: 'Leaving the builder?',
            typeHandle: 'text',
            key: 'create_build_asset_leaving_create_notification_title',
          },
          {
            field: 'All unsaved changes to your asset will be lost.',
            typeHandle: 'text',
            key: 'create_build_asset_leaving_create_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_build_asset_leaving_create_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_build_asset_leaving_create_notification_link_2',
          },
          {
            field: 'Leaving the builder?',
            typeHandle: 'text',
            key: 'create_build_article_leaving_create_notification_title',
          },
          {
            field: 'All unsaved changes to your article will be lost.',
            typeHandle: 'text',
            key: 'create_build_article_leaving_create_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_build_article_leaving_create_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_build_article_leaving_create_notification_link_2',
          },
          {
            field: 'Leaving the builder?',
            typeHandle: 'text',
            key: 'create_build_tutorial_leaving_create_notification_title',
          },
          {
            field: 'All unsaved changes to your tutorial will be lost.',
            typeHandle: 'text',
            key: 'create_build_tutorial_leaving_create_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_build_tutorial_leaving_create_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_build_tutorial_leaving_create_notification_link_2',
          },
          {
            field: 'Leaving the builder?',
            typeHandle: 'text',
            key:
              'create_build_tutorial_series_leaving_create_notification_title',
          },
          {
            field: 'All unsaved changes to your tutorial series will be lost.',
            typeHandle: 'text',
            key:
              'create_build_tutorial_series_leaving_create_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key:
              'create_build_tutorial_series_leaving_create_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key:
              'create_build_tutorial_series_leaving_create_notification_link_2',
          },
          {
            field: 'Your article was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_build_article_draft_notification_title',
          },
          {
            field:
              'Your article was successfully saved to your drafts. You can access it via your drafts page.',
            typeHandle: 'text',
            key: 'create_build_article_draft_notification_text',
          },
          {
            field: 'Your asset was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_build_asset_draft_notification_title',
          },
          {
            field:
              'Your asset was successfully saved to your drafts. You can access via the drafts page.',
            typeHandle: 'text',
            key: 'create_build_asset_draft_notification_text',
          },
          {
            field: 'Your artwork was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_build_artwork_draft_notification_title',
          },
          {
            field:
              'Your artwork was successfully saved to your drafts. You can access via the drafts page.',
            typeHandle: 'text',
            key: 'create_build_artwork_draft_notification_text',
          },
          {
            field: 'Your tutorial was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_build_tutorial_draft_notification_title',
          },
          {
            field:
              'Your tutorial was successfully saved to your drafts. You can access via the drafts page.',
            typeHandle: 'text',
            key: 'create_build_tutorial_draft_notification_text',
          },
          {
            field: 'Your tutorial series post was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_build_tutorial_series_draft_notification_title',
          },
          {
            field:
              'Your tutorial series post was successfully saved to your drafts. You can access via the drafts page.',
            typeHandle: 'text',
            key: 'create_build_tutorial_series_draft_notification_text',
          },
          {
            field: 'Congratulations on publishing your article!',
            typeHandle: 'text',
            key: 'create_build_article_publish_notification_title',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'create_build_article_publish_notification_text',
          },
          {
            field: 'Congratulations on publishing your article!',
            typeHandle: 'text',
            key: 'create_build_asset_publish_notification_title',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'create_build_asset_publish_notification_text',
          },
          {
            field: 'Congratulations on publishing a piece of art!',
            typeHandle: 'text',
            key: 'create_build_artwork_publish_notification_title',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'create_build_artwork_publish_notification_text',
          },
          {
            field: 'Congratulations on publishing your tutorial!',
            typeHandle: 'text',
            key: 'create_build_tutorial_publish_notification_title',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'create_build_tutorial_publish_notification_text',
          },
          {
            field: 'Congratulations on publishing your tutorial series!',
            typeHandle: 'text',
            key: 'create_build_tutorial_series_publish_notification_title',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'create_build_tutorial_series_publish_notification_text',
          },
          {
            field: 'Your tutorial is not public yet.',
            typeHandle: 'text',
            key: 'create_build_tutorial_pending_notification_title',
          },
          {
            field:
              "Your tutorial is being reviewed. You'll get a notification once it has been approved.",
            typeHandle: 'text',
            key: 'create_build_tutorial_pending_notification_text',
          },
          {
            field: 'Upload asset',
            typeHandle: 'text',
            key: 'create_information_field_upload_asset',
          },
          {
            field: 'Replace asset',
            typeHandle: 'text',
            key: 'create_information_field_replace_asset',
          },
          {
            field: 'The asset file you submitted is invalid. Try again.',
            typeHandle: 'text',
            key: 'create_disabled_next_button_asset_block_error',
          },
          {
            field: 'You must upload an asset file to continue.',
            typeHandle: 'text',
            key: 'create_disabled_next_button_asset_no_blocks',
          },
          {
            field: 'Image',
            typeHandle: 'text',
            key: 'create_build_add_block_label_image',
          },
          {
            field: 'Video',
            typeHandle: 'text',
            key: 'create_build_add_block_label_video',
          },
          {
            field: 'Text',
            typeHandle: 'text',
            key: 'create_build_add_block_label_text',
          },
          {
            field: 'AREA Tutorials',
            typeHandle: 'text',
            key: 'create_build_add_block_label_tutorials',
          },
          {
            field: 'AREA Content',
            typeHandle: 'text',
            key: 'create_build_add_block_label_content',
          },
          {
            field: 'Add content',
            typeHandle: 'text',
            key: 'create_build_add_block_label',
          },
          {
            field:
              'You must upload an asset before adding other content to your post.',
            typeHandle: 'text',
            key: 'create_build_add_block_no_asset_label_warning',
          },
          {
            field: 'Asset types',
            typeHandle: 'text',
            key: 'create_information_field_asset_type',
          },
          {
            field: 'Sketchfab',
            typeHandle: 'text',
            key: 'create_build_add_block_label_sketchfab',
          },
          {
            field: 'Sketchfab link',
            typeHandle: 'text',
            key: 'create_modal_sketchfab_title',
          },
          {
            field: 'Type your link here',
            typeHandle: 'text',
            key: 'create_modal_sketchfab_input_label',
          },
          {
            field: 'Only urls from Sketchfab are accepted',
            typeHandle: 'text',
            key: 'create_modal_sketchfab_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_modal_sketchfab_cancel_button',
          },
          {
            field: 'Add',
            typeHandle: 'text',
            key: 'create_modal_sketchfab_add_button',
          },
          {
            field:
              'A tutorial series should contain at least one AREA Tutorials block',
            typeHandle: 'text',
            key: 'create_disabled_next_button_tutorial_series',
          },
          {
            field:
              'Invalid cover format. Try with another file. Supported formats are: JPEG, JPG and PNG',
            typeHandle: 'text',
            key: 'create_cover_invalid_image_format',
          },
          {
            field: 'Leaving the builder',
            typeHandle: 'text',
            key: 'create_build_leaving_create_notification_title',
          },
          {
            field:
              'Are you sure you want to live the builder? All unsaved changes will be lost',
            typeHandle: 'text',
            key: 'create_build_leaving_create_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_build_leaving_create_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_build_leaving_create_notification_link_2',
          },
        ],
      },
      {},
      {
        strings: [
          {
            field: 'Explore more',
            typeHandle: 'text',
            key: 'page_inspire_moy_links_section_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'You published an artwork',
            typeHandle: 'text',
            key: 'publish_artwork_notification_title',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'publish_artwork_notification_text',
          },
          {
            field: 'Copy link to share',
            typeHandle: 'text',
            key: 'publish_artwork_notification_copy_link',
          },
          {
            field: 'Congratulations on publishing your art!',
            typeHandle: 'text',
            key: 'create_artwork_publish_notification_title',
          },
          {
            field: 'Now why not share the love with the community?',
            typeHandle: 'text',
            key: 'create_artwork_publish_notification_text',
          },
          {
            field: 'Copy link to share the love',
            typeHandle: 'text',
            key: 'create_artwork_publish_notification_link',
          },
          {
            field: 'Join AREA now!',
            typeHandle: 'text',
            key: 'join_area_notification_title',
          },
          {
            field: 'Build your profile and personalize your experience.',
            typeHandle: 'text',
            key: 'join_area_notification_text',
          },
          {
            field: 'reacted on',
            typeHandle: 'text',
            key: 'notification_reacted_on',
          },
          {
            field: 'bookmarked',
            typeHandle: 'text',
            key: 'notification_bookmarked',
          },
          {
            field: 'commented on',
            typeHandle: 'text',
            key: 'notification_commented_on',
          },
          {
            field: 'published',
            typeHandle: 'text',
            key: 'notification_published',
          },
          {
            field: 'in',
            typeHandle: 'text',
            key: 'notification_in',
          },
          {
            field: 'your artwork',
            typeHandle: 'text',
            key: 'notification_your_artwork',
          },
          {
            field: 'your post',
            typeHandle: 'text',
            key: 'notification_your_post',
          },
          {
            field: 'your tutorial',
            typeHandle: 'text',
            key: 'notification_your_tutorial',
          },
          {
            field: 'a post',
            typeHandle: 'text',
            key: 'notification_a_post',
          },
          {
            field: 'a tutorial',
            typeHandle: 'text',
            key: 'notification_a_tutorial',
          },
          {
            field: 'an artwork',
            typeHandle: 'text',
            key: 'notification_an_artwork',
          },
          {
            field: 'Mark as read',
            typeHandle: 'text',
            key: 'notification_mark_as_read',
          },
          {
            field: 'Delete this notification',
            typeHandle: 'text',
            key: 'notification_delete_this_notification',
          },
          {
            field: 'your asset',
            typeHandle: 'text',
            key: 'notification_your_asset',
          },
          {
            field: 'your article',
            typeHandle: 'text',
            key: 'notification_your_article',
          },
          {
            field: 'your comment',
            typeHandle: 'text',
            key: 'notification_your_comment',
          },
          {
            field: 'an asset',
            typeHandle: 'text',
            key: 'notification_an_asset',
          },
          {
            field: 'an article',
            typeHandle: 'text',
            key: 'notification_an_article',
          },
          {
            field: 'Your notifications',
            typeHandle: 'text',
            key: 'notification_page_title',
          },
          {
            field: 'Settings',
            typeHandle: 'text',
            key: 'notification_page_settings_label',
          },
          {
            field: 'New',
            typeHandle: 'text',
            key: 'notification_new',
          },
          {
            field: 'Earlier',
            typeHandle: 'text',
            key: 'notification_earlier',
          },
          {
            field: 'Load more',
            typeHandle: 'text',
            key: 'notification_load_more',
          },
          {
            field: 'No new notification',
            typeHandle: 'text',
            key: 'notification_no_new_notification',
          },
          {
            field: 'No notification to load',
            typeHandle: 'text',
            key: 'notification_no_notification',
          },
          {
            field: 'Notification settings',
            typeHandle: 'text',
            key: 'group_header_notification_settings',
          },
          {
            field: 'User content successfully deleted',
            typeHandle: 'text',
            key: 'client_notification_DeleteUserContent_success',
          },
          {
            field: 'User content successfully minimized',
            typeHandle: 'text',
            key: 'client_notification_UpdateUserContent_feature_false_success',
          },
          {
            field: 'User content successfully featured',
            typeHandle: 'text',
            key: 'client_notification_UpdateUserContent_feature_true_success',
          },
          {
            field: 'Tools successfully updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateMyTool_success',
          },
          {
            field: 'Industries successfully updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateMyIndustry_success',
          },
          {
            field: 'Post successfully added to favorites',
            typeHandle: 'text',
            key: 'client_notification_PostFavorite_add_success',
          },
          {
            field: 'Post successfully created',
            typeHandle: 'text',
            key: 'client_notification_CreatePost_success',
          },
          {
            field: 'Comment added',
            typeHandle: 'text',
            key: 'client_notification_CreateComment_success',
          },
          {
            field: 'Post successfully removed from favorites',
            typeHandle: 'text',
            key: 'client_notification_PostFavorite_remove_success',
          },
          {
            field: 'Post successfully reported',
            typeHandle: 'text',
            key: 'client_notification_CreatePostReport_success',
          },
          {
            field: 'Group joined',
            typeHandle: 'text',
            key: 'client_notification_JoinGroups_success',
          },
          {
            field: 'Group left',
            typeHandle: 'text',
            key: 'client_notification_LeaveGroups_success',
          },
          {
            field: 'Email successfully updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateUserEmail_success',
          },
          {
            field: 'Username successfully updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateUserName_success',
          },
          {
            field: 'Password successfully updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateUserPassword_success',
          },
          {
            field: 'Resume sections reordered sucessfully',
            typeHandle: 'text',
            key: 'client_notification_UpdateResumeOrderSections_success',
          },
          {
            field: 'Oops!',
            typeHandle: 'text',
            key: 'client_notification_error_title',
          },
          {
            field: 'Great!',
            typeHandle: 'text',
            key: 'client_notification_success_title',
          },
          {
            field: 'Notice',
            typeHandle: 'text',
            key: 'client_notification_info_title',
          },
          {
            field: 'Resume boxes reordered successfully',
            typeHandle: 'text',
            key: 'client_notification_UpdateResumeOrderBoxes_success',
          },
          {
            field: 'Group details updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateGroupDetails_success',
          },
          {
            field: 'Tag created',
            typeHandle: 'text',
            key: 'client_notification_CreateTag_success',
          },
          {
            field: 'Tag updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateTag_success',
          },
          {
            field: 'Industry created',
            typeHandle: 'text',
            key: 'client_notification_CreateIndustry_success',
          },
          {
            field: 'Industry updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateIndustry_success',
          },
          {
            field: 'Tool created',
            typeHandle: 'text',
            key: 'client_notification_CreateTool_success',
          },
          {
            field: 'Tool updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateTool_success',
          },
          {
            field: 'User content successfully reported',
            typeHandle: 'text',
            key: 'client_notification_CreateUserContentReport_success',
          },
          {
            field: 'User removed from group',
            typeHandle: 'text',
            key: 'client_notification_DeleteUserFromGroup_success',
          },
          {
            field: 'Multiple users were successfully deleted',
            typeHandle: 'text',
            key: 'client_notification_DeleteUsers_success',
          },
          {
            field: 'Page data successfully updated',
            typeHandle: 'text',
            key: 'client_notification_CMSUpdatePageData_success',
          },
          {
            field: "User content added to Editor's Pick",
            typeHandle: 'text',
            key:
              'client_notification_UserContentAction_add_editors_pick_success',
          },
          {
            field: "User content removed from Editor's Pick",
            typeHandle: 'text',
            key:
              'client_notification_UserContentAction_remove_editors_pick_success',
          },
          {
            field: 'User content published',
            typeHandle: 'text',
            key: 'client_notification_UserContentAction_publish_success',
          },
          {
            field: 'User content unpublished',
            typeHandle: 'text',
            key: 'client_notification_UserContentAction_unpublish_success',
          },
          {
            field: 'User successfully blocked',
            typeHandle: 'text',
            key: 'client_notification_GroupUser_blocked_success',
          },
          {
            field: 'User(s) successfully pre-approved',
            typeHandle: 'text',
            key: 'client_notification_GroupUser_pre_approved_success',
          },
          {
            field: 'User(s) are now moderators',
            typeHandle: 'text',
            key: 'client_notification_GroupUser_moderator_success',
          },
          {
            field: 'User(s) are now members',
            typeHandle: 'text',
            key: 'client_notification_GroupUser_member_success',
          },
          {
            field: 'User(s) are now contributors',
            typeHandle: 'text',
            key: 'client_notification_GroupUser_contributor_success',
          },
          {
            field: 'Post report closed',
            typeHandle: 'text',
            key: 'client_notification_ClosePostReport_success',
          },
          {
            field: 'Groups deleted',
            typeHandle: 'text',
            key: 'client_notification_DeleteGroups_success',
          },
          {
            field: 'Groups published',
            typeHandle: 'text',
            key: 'client_notification_PublishGroups_success',
          },
          {
            field: 'Groups unpublished',
            typeHandle: 'text',
            key: 'client_notification_UnpublishGroups_success',
          },
          {
            field: 'Post removed from group',
            typeHandle: 'text',
            key: 'client_notification_DeleteGroupPosts_success',
          },
          {
            field: 'Group published',
            typeHandle: 'text',
            key: 'client_notification_CMSGroupActions_publish_success',
          },
          {
            field: 'Group unpublished',
            typeHandle: 'text',
            key: 'client_notification_CMSGroupActions_unpublish_success',
          },
          {
            field: 'Post published in group',
            typeHandle: 'text',
            key: 'client_notification_CMSGroupPostAction_publish_success',
          },
          {
            field: 'Post unpublished from group',
            typeHandle: 'text',
            key: 'client_notification_CMSGroupPostAction_unpublish_success',
          },
          {
            field: 'Report successfully closed',
            typeHandle: 'text',
            key: 'client_notification_CloseUserReport_success',
          },
          {
            field: 'Report successfully closed',
            typeHandle: 'text',
            key: 'client_notification_CloseUserContentReport_success',
          },
          {
            field: 'Report already exist',
            typeHandle: 'text',
            key: 'client_notification_report_already_exist_error',
          },
          {
            field: 'User content added to favorites',
            typeHandle: 'text',
            key: 'client_notification_UserContentFavorite_add_success',
          },
          {
            field: 'User content removed from favorites',
            typeHandle: 'text',
            key: 'client_notification_UserContentFavorite_remove_success',
          },
          {
            field: 'Url has been added to your clipboard',
            typeHandle: 'text',
            key: 'add_to_clipboard_notification',
          },
          {
            field: 'Profile information successfully updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateMyProfile_success',
          },
          {
            field: 'Publication moderation saved',
            typeHandle: 'text',
            key: 'client_notification_PostModeration_sucess',
          },
          {
            field: 'Role member saved',
            typeHandle: 'text',
            key: 'client_notification_GroupeUser_sucess',
          },
          {
            field: 'Publication moderation saved',
            typeHandle: 'text',
            key: 'client_notification_PostModeration_success',
          },
          {
            field: 'Role member saved',
            typeHandle: 'text',
            key: 'client_notification_GroupUser_success',
          },
          {
            field: 'Empty text field',
            typeHandle: 'text',
            key: 'cms_notification_validation_error_cms_empty_title_field',
          },
          {
            field: 'Empty description field',
            typeHandle: 'text',
            key:
              'cms_notification_validation_error_cms_empty_description_field',
          },
          {
            field: 'No hero image',
            typeHandle: 'text',
            key: 'cms_notification_validation_error_cms_no_banner_image',
          },
          {
            field: 'Multiple required fields are empty',
            typeHandle: 'text',
            key: 'cms_notification_validation_error_cms_multiple_empty',
          },
          {
            field: 'Banner image too large, Max 1.5mb',
            typeHandle: 'text',
            key: 'client_notification_cms_banner_image_too_large_error',
          },
          {
            field: 'Invalid image type',
            typeHandle: 'text',
            key: 'client_notification_content_data_edit_type_image_error',
          },
          {
            field: 'Invalid image resolution',
            typeHandle: 'text',
            key: 'client_notification_content_data_edit_resolution_image_error',
          },
          {
            field:
              "The group moderators have blocked you. You can't publish any more posts for the moment.",
            typeHandle: 'text',
            key: 'client_notification_blocked_user_title',
          },
          {
            field: 'About Me successfully updated',
            typeHandle: 'text',
            key: 'client_notification_UpdateAboutMe_success',
          },
          {
            field: 'User successfully reported',
            typeHandle: 'text',
            key: 'client_notification_ReportUser_success',
          },
          {
            field: 'Please fill in the button url field',
            typeHandle: 'text',
            key: 'cms_notification_validation_error_cms_no_banner_btnUrl',
          },
          {
            field: 'Please fill in the description field',
            typeHandle: 'text',
            key:
              'cms_notification_validation_error_cms_empty_descritpion_field',
          },
          {
            field: 'Please fill in the button label field',
            typeHandle: 'text',
            key: 'cms_notification_validation_error_cms_no_banner_btnLabel',
          },
          {
            field: 'Content liked~',
            typeHandle: 'text',
            key: 'client_notification_UserContentReaction_success',
          },
          {
            field: 'Publication successfully updated',
            typeHandle: 'text',
            key: 'client_notification_updatePost_success',
          },
          {
            field: 'Successfully reactivated user account.',
            typeHandle: 'text',
            key: 'client_notification_changeUserStatus_success',
          },
          {
            field:
              'The image you are trying to upload has a too small resolution. Please try with a larger image',
            typeHandle: 'text',
            key: 'image_resolution_too_small',
          },
          {
            field: 'By',
            typeHandle: 'text',
            key: 'thumbnail_authored_by',
          },
          {
            field: 'Hall of Fame',
            typeHandle: 'text',
            key: 'thumbnail_hall_of_fame_title',
          },
          {
            field: 'Assets',
            typeHandle: 'text',
            key: 'thumbnail_assets',
          },
          {
            field: 'Delete',
            typeHandle: 'text',
            key: 'thumbnail_menu_delete',
          },
          {
            field: 'Edit',
            typeHandle: 'text',
            key: 'thumbnail_menu_edit',
          },
          {
            field: 'Feature',
            typeHandle: 'text',
            key: 'thumbnail_menu_feature',
          },
          {
            field:
              'Your AREA account has been deactivated and the deletion process has been initiated.',
            typeHandle: 'text',
            key: 'delete_user_account_notification',
          },
          {
            field: 'Successfully updated your profile information',
            typeHandle: 'text',
            key: 'client_notification_UpdateUserProfile_success',
          },
          {
            field: 'User not found',
            typeHandle: 'text',
            key: 'client_notification_user_not_found_error',
          },
          {
            field: 'Unauthorized access',
            typeHandle: 'text',
            key: 'client_notification_unauthorized_access_error',
          },
          {
            field: "You're not logged in",
            typeHandle: 'text',
            key: 'client_notification_user_not_logged_error',
          },
          {
            field: "You're not logged in",
            typeHandle: 'text',
            key: 'client_notification_user_not_logged_error',
          },
          {
            field: 'Insufficient credentials',
            typeHandle: 'text',
            key: 'client_notification_insufficient_credentials_error',
          },
          {
            field: "Can't find the content you're looking for",
            typeHandle: 'text',
            key: 'client_notification_usercontent_not_found_error',
          },
          {
            field: 'Could not create post',
            typeHandle: 'text',
            key: 'client_notification_could_not_create_post_error',
          },
          {
            field: "Can't find the post you're looking for",
            typeHandle: 'text',
            key: 'client_notification_post_not_found_error',
          },
          {
            field: 'Could not update some fields',
            typeHandle: 'text',
            key: 'client_notification_could_not_update_some_fields_error',
          },
          {
            field: 'Duplicate order number',
            typeHandle: 'text',
            key: 'client_notification_duplicate_order_number_error',
          },
          {
            field: 'Duplicate order number',
            typeHandle: 'text',
            key: 'client_notification_duplicate_order_number_error',
          },
          {
            field: 'Could not create content',
            typeHandle: 'text',
            key: 'client_notification_could_not_create_user_content_error',
          },
          {
            field: 'Invalid file extension',
            typeHandle: 'text',
            key: 'client_notification_wrong_file_extension_error',
          },
          {
            field: 'Could not create content thumbnail',
            typeHandle: 'text',
            key: 'client_notification_could_create_thumbnail_error',
          },
          {
            field: "Can't delete image block",
            typeHandle: 'text',
            key: 'client_notification_usercontent_bloc_image_not_deleted_error',
          },
          {
            field: 'Missing a required field',
            typeHandle: 'text',
            key: 'client_notification_missing_required_field_error',
          },
          {
            field: 'Multiple required fields are missing',
            typeHandle: 'text',
            key: 'client_notification_missing_required_fields_error',
          },
          {
            field: 'Could not edit user',
            typeHandle: 'text',
            key: 'client_notification_could_not_edit_user_error',
          },
          {
            field: 'Could not edit reported content',
            typeHandle: 'text',
            key: 'client_notification_could_not_edit_report_error',
          },
          {
            field: "Can't find the content you're looking for",
            typeHandle: 'text',
            key: 'client_notification_could_not_find_user_content_error',
          },
          {
            field: 'User not reported',
            typeHandle: 'text',
            key: 'client_notification_user_not_reported_error',
          },
          {
            field: 'Could not close reported content',
            typeHandle: 'text',
            key: 'client_notification_could_not_close_reports_error',
          },
          {
            field: 'Could not delete image',
            typeHandle: 'text',
            key: 'client_notification_could_not_delete_image_error',
          },
          {
            field: 'Missing Place ID',
            typeHandle: 'text',
            key: 'client_notification_missing_placeid_error',
          },
          {
            field: 'Invalid place ID',
            typeHandle: 'text',
            key: 'client_notification_placeid_error_error',
          },
          {
            field: "Can't find the company you're looking for",
            typeHandle: 'text',
            key: 'client_notification_company_not_found_error',
          },
          {
            field: "Can't create this company",
            typeHandle: 'text',
            key: 'client_notification_cannot_create_company_error',
          },
          {
            field: "Can't find the school you're looking for",
            typeHandle: 'text',
            key: 'client_notification_school_not_found_error',
          },
          {
            field: "Can't find the place you're looking for",
            typeHandle: 'text',
            key: 'client_notification_place_not_found_error',
          },
          {
            field: "Can't create this school",
            typeHandle: 'text',
            key: 'client_notification_cannot_create_school_error',
          },
          {
            field: "Can't find the project you're looking for",
            typeHandle: 'text',
            key: 'client_notification_project_not_found_error',
          },
          {
            field: "Can't create this project",
            typeHandle: 'text',
            key: 'client_notification_cannot_create_project_error',
          },
          {
            field:
              "You haven't fully completed your onboarding. Go to your profile to see what's missing.",
            typeHandle: 'text',
            key: 'client_notification_user_not_onboarded_error',
          },
          {
            field: 'Invalid name',
            typeHandle: 'text',
            key: 'client_notification_invalid_name_error',
          },
          {
            field: 'Could not save content to favorites',
            typeHandle: 'text',
            key: 'client_notification_could_not_favorite_user_content_error',
          },
          {
            field: "This content wasn't published",
            typeHandle: 'text',
            key: 'client_notification_usercontent_not_published_error',
          },
          {
            field: 'Invalid block type',
            typeHandle: 'text',
            key: 'client_notification_invalid_bloc_type_error',
          },
          {
            field: 'Invalid status',
            typeHandle: 'text',
            key: 'client_notification_wrong_status_error',
          },
          {
            field: 'Member not found',
            typeHandle: 'text',
            key: 'client_notification_member_not_found_error',
          },
          {
            field: 'Author not found',
            typeHandle: 'text',
            key: 'client_notification_author_not_found_error',
          },
          {
            field: "Couldn't upload your file",
            typeHandle: 'text',
            key: 'client_notification_cannot_upload_file_error',
          },
          {
            field: "This block doesn't belong to this content",
            typeHandle: 'text',
            key: 'client_notification_block_doesnt_belong_to_usercontent_error',
          },
          {
            field: 'This email is already in use',
            typeHandle: 'text',
            key: 'client_notification_email_unavailable_error',
          },
          {
            field: 'This password is identical to your previous password',
            typeHandle: 'text',
            key: 'client_notification_identical_password_error',
          },
          {
            field: 'Wrong password',
            typeHandle: 'text',
            key: 'client_notification_wrong_password_error',
          },
          {
            field:
              "Can't send the verification link to the email address you provided",
            typeHandle: 'text',
            key:
              'client_notification_user_email_verification_link_sent_error_error',
          },
          {
            field: "Can't find the notification you're looking for",
            typeHandle: 'text',
            key: 'client_notification_notification_not_found_error',
          },
          {
            field: "Couldn't update user information",
            typeHandle: 'text',
            key: 'client_notification_could_not_update_user_error',
          },
          {
            field: "Couldn't upload image",
            typeHandle: 'text',
            key: 'client_notification_could_not_upload_image_error',
          },
          {
            field: "Couldn't create block",
            typeHandle: 'text',
            key: 'client_notification_could_not_create_bloc_error',
          },
          {
            field: 'Invalid content type',
            typeHandle: 'text',
            key: 'client_notification_invalid_userContentType_error',
          },
          {
            field: 'You already reported this post',
            typeHandle: 'text',
            key: 'client_notification_report_already_exist_error',
          },
          {
            field: "Can't create report for this post",
            typeHandle: 'text',
            key: 'client_notification_could_not_create_report_error',
          },
          {
            field: "Couldn't delete this group",
            typeHandle: 'text',
            key: 'client_notification_group_not_deleted_error',
          },
          {
            field: "You're not logged in",
            typeHandle: 'text',
            key: 'client_notification_user_not_connected_error',
          },
          {
            field: "Couldn't delete notification",
            typeHandle: 'text',
            key: 'client_notification_count_not_delete_notification_error',
          },
          {
            field: "Couldn't delete notification",
            typeHandle: 'text',
            key: 'client_notification_count_not_delete_notification_error',
          },
          {
            field: "Couldn't delete this post",
            typeHandle: 'text',
            key: 'client_notification_could_not_delete_post_error',
          },
          {
            field: "This block doesn't exist",
            typeHandle: 'text',
            key: 'client_notification_usercontent_bloc_does_not_exist_error',
          },
          {
            field: 'Internal server error, try again later',
            typeHandle: 'text',
            key: 'client_notification_internal_server_error_error',
          },
          {
            field: "Couldn't delete content",
            typeHandle: 'text',
            key: 'client_notification_usercontent_not_deleted_error',
          },
          {
            field: "Couldn't delete user",
            typeHandle: 'text',
            key: 'client_notification_user_not_deleted_error',
          },
          {
            field: "Couldn't edit group details",
            typeHandle: 'text',
            key: 'client_notification_could_not_edit_group_error',
          },
          {
            field: "Couldn't save this post to your favorites",
            typeHandle: 'text',
            key: 'client_notification_could_not_favorite_post_error',
          },
          {
            field: "Can't find the group you're looking for",
            typeHandle: 'text',
            key: 'client_notification_group_not_found_error',
          },
          {
            field: "Can't find the post you're looking for",
            typeHandle: 'text',
            key: 'client_notification_could_not_find_post_error',
          },
          {
            field: "Can't find the report you're looking for",
            typeHandle: 'text',
            key: 'client_notification_report_not_found_error',
          },
          {
            field: 'This report was already treated',
            typeHandle: 'text',
            key: 'client_notification_report_already_treated_error',
          },
          {
            field: "Couldn't edit this post",
            typeHandle: 'text',
            key: 'client_notification_could_not_edit_post_error',
          },
          {
            field: "Can't find featured content",
            typeHandle: 'text',
            key: 'client_notification_featured_content_not_found_error',
          },
          {
            field: "Couldn't update group",
            typeHandle: 'text',
            key: 'client_notification_could_not_update_group_error',
          },
          {
            field: "Couldn't update your settings",
            typeHandle: 'text',
            key: 'client_notification_could_not_update_settings_error',
          },
          {
            field: 'Your username contains forbidden words',
            typeHandle: 'text',
            key: 'client_notification_username_contains_badwords_error',
          },
          {
            field: 'This username is already taken',
            typeHandle: 'text',
            key: 'client_notification_username_unavailable_error',
          },
          {
            field: 'Missing permissions',
            typeHandle: 'text',
            key: 'client_notification_unauthorized_error',
          },
          {
            field: 'An error occured while uploading the image',
            typeHandle: 'text',
            key: 'client_notification_could_crop_image_error',
          },
          {
            field: "You're not logged in",
            typeHandle: 'text',
            key: 'client_notification_forbidden_error',
          },
          {
            field: 'Invalid changes',
            typeHandle: 'text',
            key: 'client_notification_missing_arguments_error',
          },
          {
            field: 'Invalid changes',
            typeHandle: 'text',
            key: 'client_notification_invalid_relation_error',
          },
          {
            field: 'Unable to create or update a block',
            typeHandle: 'text',
            key: 'client_notification_could_not_create_update_bloc_error',
          },
          {
            field: 'An error occured while uploading the image',
            typeHandle: 'text',
            key: 'client_notification_could_create_original_error',
          },
          {
            field: "Couldn't rotate the image",
            typeHandle: 'text',
            key: 'client_notification_could_create_rotation_error',
          },
          {
            field: 'Image type not supported',
            typeHandle: 'text',
            key: 'client_notification_invalid_mime_type_error',
          },
          {
            field: 'An error occured while uploading the image',
            typeHandle: 'text',
            key: 'client_notification_missing_args_error',
          },
          {
            field: 'Wrong search type',
            typeHandle: 'text',
            key: 'client_notification_invalid_search_type_error',
          },
          {
            field: "Couldn't delete old image",
            typeHandle: 'text',
            key: 'client_notification_unable_to_delete_parent_image_error',
          },
          {
            field: "Can't add or update company",
            typeHandle: 'text',
            key: 'client_notification_company_error_error',
          },
          {
            field: "Can't add or update school",
            typeHandle: 'text',
            key: 'client_notification_school_error_error',
          },
          {
            field: "Can't add or update projet",
            typeHandle: 'text',
            key: 'client_notification_project_error_error',
          },
          {
            field: "Couldn't update like on this post",
            typeHandle: 'text',
            key: 'client_notification_could_not_store_reaction_error',
          },
          {
            field: 'Unable to add content block',
            typeHandle: 'text',
            key:
              'client_notification_could_not_create_update_bloc_contents_error',
          },
          {
            field: "Can't publish draft content",
            typeHandle: 'text',
            key: 'client_notification_missing_parent_version_error',
          },
          {
            field: 'Product Key is already in use',
            typeHandle: 'text',
            key: 'client_notification_product_key_in_use_error',
          },
          {
            field: 'An error occured with the notification',
            typeHandle: 'text',
            key: 'client_notification_count_not_update_notification_error',
          },
          {
            field: 'Missing required Header image',
            typeHandle: 'text',
            key: 'client_notification_required_header_image_error',
          },
          {
            field: "Can't update username or email more than twice a year",
            typeHandle: 'text',
            key: 'client_notification_too_soon_error',
          },
          {
            field:
              'The referenced account is currently locked out for %duration% sec',
            typeHandle: 'text',
            key: 'client_notification_user_lockout_error',
          },
          {
            field: "Couldn't update the user",
            typeHandle: 'text',
            key: 'client_notification_user_save_failed_error',
          },
          {
            field: "Couldn't update the user",
            typeHandle: 'text',
            key: 'client_notification_user_slug_history_creation_fail_error',
          },
          {
            field: 'An error occured with the notification',
            typeHandle: 'text',
            key: 'client_notification_count_not_save_datetime_error',
          },
          {
            field: "Couldn't connect the user",
            typeHandle: 'text',
            key: 'client_notification_could_not_save_device_key_error',
          },
          {
            field: "Couldn't create comment, %message%",
            typeHandle: 'text',
            key: 'client_notification_cannot_create_child_error',
          },
          {
            field: "Couldn't disconnect the user",
            typeHandle: 'text',
            key: 'client_notification_could_not_delete_device_key_error',
          },
          {
            field: "Couldn't close report(s) on this post",
            typeHandle: 'text',
            key: 'client_notification_could_not_edit_related_report_error',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Member content',
            typeHandle: 'text',
            key: 'community_admin_usercontents_tab',
          },
          {
            field: 'Last member content',
            typeHandle: 'text',
            key: 'community_admin_last_usercontent_tab',
          },
          {
            field: 'My groups',
            typeHandle: 'text',
            key: 'community_sidebar_my_groups',
          },
          {
            field: 'Explore more groups',
            typeHandle: 'text',
            key: 'community_sidebar_explore_groups',
          },
          {
            field: 'Suggest a group',
            typeHandle: 'text',
            key: 'community_groups_suggest_group',
          },
          {
            field: 'Start a post',
            typeHandle: 'text',
            key: 'community_post_title',
          },
          {
            field: 'What do you want to talk about?',
            typeHandle: 'text',
            key: 'community_post_placeholder',
          },
          {
            field: 'Your selected file type is not allowed',
            typeHandle: 'text',
            key: 'community_post_bad_image_type',
          },
          {
            field: 'Number of selected images exceeded',
            typeHandle: 'text',
            key: 'community_post_image_number_exceed',
          },
          {
            field: 'tutorials found',
            typeHandle: 'text',
            key: 'community_tutorials_found',
          },
          {
            field: 'assets found',
            typeHandle: 'text',
            key: 'community_assets_found',
          },
          {
            field: 'Group rules',
            typeHandle: 'text',
            key: 'community_guidelines_group_rules',
          },
          {
            field: "DO's",
            typeHandle: 'text',
            key: 'community_guidelines_dos',
          },
          {
            field: 'Do give your work a title.',
            typeHandle: 'text',
            key: 'community_guidelines_do_title_1',
          },
          {
            field: 'Do submit a description of your work.',
            typeHandle: 'text',
            key: 'community_guidelines_do_title_2',
          },
          {
            field: 'Do submit multiple images to showcase your work.',
            typeHandle: 'text',
            key: 'community_guidelines_do_title_3',
          },
          {
            field: "Do check your title and description's grammar.",
            typeHandle: 'text',
            key: 'community_guidelines_do_title_4',
          },
          {
            field: 'Do submit work that it watermarked.',
            typeHandle: 'text',
            key: 'community_guidelines_do_title_5',
          },
          {
            field: 'Don’t submit disrespectful artwork.',
            typeHandle: 'text',
            key: 'community_guidelines_donts_title_1',
          },
          {
            field: 'Don’t submit a disrespectful title or description.',
            typeHandle: 'text',
            key: 'community_guidelines_donts_title_2',
          },
          {
            field:
              'If your work goes against our guidelines, we will not publish it.',
            typeHandle: 'text',
            key: 'community_guidelines_donts_title_3',
          },
          {
            field:
              'Want people to find your work through search? Make sure your title reflects what your project is – getting creative with your title doesn’t work to your advantage.',
            typeHandle: 'text',
            key: 'community_guidelines_do_text_1',
          },
          {
            field:
              'This can range from your inspiration and backstory to your technique.',
            typeHandle: 'text',
            key: 'community_guidelines_do_text_2',
          },
          {
            field:
              'This can range from WIP (work-in-progress) imagery to multiple angles of your work.',
            typeHandle: 'text',
            key: 'community_guidelines_do_text_3',
          },
          {
            field:
              'We strongly urge you to watermark your work with your name and social media handle or website so that if someone shares it without your knowing, people will know where to find you and more of your work.',
            typeHandle: 'text',
            key: 'community_guidelines_do_text_5',
          },
          {
            field:
              'Don’t submit work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or graphic violence.',
            typeHandle: 'text',
            key: 'community_guidelines_donts_text_1',
          },
          {
            field:
              'Don’t submit a title or a description that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or violence.',
            typeHandle: 'text',
            key: 'community_guidelines_donts_text_2',
          },
          {
            field: 'See full guidelines',
            typeHandle: 'text',
            key: 'community_guidelines_action_label_1',
          },
          {
            field: "DON'T's",
            typeHandle: 'text',
            key: 'community_guidelines_donts',
          },
          {
            field: 'Pending publications',
            typeHandle: 'text',
            key: 'community_moderation_pending_post_text',
          },
          {
            field: 'Edit post',
            typeHandle: 'text',
            key: 'community_edit_post_text',
          },
          {
            field: 'Add to my favorites',
            typeHandle: 'text',
            key: 'community_save_post_text',
          },
          {
            field: 'Copy link',
            typeHandle: 'text',
            key: 'community_copy_link_text',
          },
          {
            field: 'Delete post',
            typeHandle: 'text',
            key: 'community_delete_post_text',
          },
          {
            field: 'Find help or flag this post',
            typeHandle: 'text',
            key: 'community_report_sub_text',
          },
          {
            field: 'group Admin and moderation',
            typeHandle: 'text',
            key: 'community_moderation_title_bar',
          },
          {
            field: 'Back to Group Feed',
            typeHandle: 'text',
            key: 'community_moderation_back_text',
          },
          {
            field: 'Remove post',
            typeHandle: 'text',
            key: 'community_remove_post_text',
          },
          {
            field: 'Thank you. Your post is being reviewed by our moderators.',
            typeHandle: 'text',
            key: 'community_post_thanks',
          },
          {
            field: 'Posts',
            typeHandle: 'text',
            key: 'community_admin_posts',
          },
          {
            field: 'Admin and Moderation Tools',
            typeHandle: 'text',
            key: 'community_admin_sidebar_title',
          },
          {
            field: 'Manage members',
            typeHandle: 'text',
            key: 'community_admin_manage_members',
          },
          {
            field: 'Reported Content',
            typeHandle: 'text',
            key: 'community_admin_reported_content',
          },
          {
            field: 'Group Settings',
            typeHandle: 'text',
            key: 'community_admin_group_settings',
          },
          {
            field: 'New members',
            typeHandle: 'text',
            key: 'community_admin_new_members',
          },
          {
            field: 'Bulk operation',
            typeHandle: 'text',
            key: 'community_admin_bulk_operation',
          },
          {
            field: 'Choose all',
            typeHandle: 'text',
            key: 'community_admin_choose_all',
          },
          {
            field: 'Delete members',
            typeHandle: 'text',
            key: 'community_delete_members',
          },
          {
            field: 'Block members',
            typeHandle: 'text',
            key: 'community_admin_block_members',
          },
          {
            field: 'Pre-approve members',
            typeHandle: 'text',
            key: 'community_admin_pre_approve_member',
          },
          {
            field: 'Appoint Moderator',
            typeHandle: 'text',
            key: 'community_admin_appoint_moderator',
          },
          {
            field: 'Appoint Contributor',
            typeHandle: 'text',
            key: 'community_admin_appoint_contributor',
          },
          {
            field: 'Pre-approve publications',
            typeHandle: 'text',
            key: 'community_admin_pre_approve_publications',
          },
          {
            field: 'Block this member',
            typeHandle: 'text',
            key: 'community_admin_block_member',
          },
          {
            field: 'Profile',
            typeHandle: 'text',
            key: 'community_admin_profile_tab',
          },
          {
            field: 'Posts',
            typeHandle: 'text',
            key: 'community_admin_posts_tab',
          },
          {
            field: 'Last Post',
            typeHandle: 'text',
            key: 'community_admin_last_post_tab',
          },
          {
            field: 'Joined',
            typeHandle: 'text',
            key: 'community_admin_joined_tab',
          },
          {
            field: 'Role',
            typeHandle: 'text',
            key: 'community_admin_role_tab',
          },
          {
            field: 'of',
            typeHandle: 'text',
            key: 'community_admin_pagination_of',
          },
          {
            field: 'Rows per page:',
            typeHandle: 'text',
            key: 'community_admin_pagination_row_per_page',
          },
          {
            field: 'add a do',
            typeHandle: 'text',
            key: 'community_admin_add_do',
          },
          {
            field: "add a don't",
            typeHandle: 'text',
            key: 'community_admin_add_dont',
          },
          {
            field: 'Back to',
            typeHandle: 'text',
            key: 'community_admin_back_to',
          },
          {
            field: 'Author',
            typeHandle: 'text',
            key: 'community_admin_author',
          },
          {
            field: 'Reports #',
            typeHandle: 'text',
            key: 'community_admin_reports_number',
          },
          {
            field: 'Reported by',
            typeHandle: 'text',
            key: 'community_admin_reported_by',
          },
          {
            field: 'Post date',
            typeHandle: 'text',
            key: 'community_admin_post_date',
          },
          {
            field: 'Report date',
            typeHandle: 'text',
            key: 'community_admin_report_date',
          },
          {
            field: 'content type',
            typeHandle: 'text',
            key: 'community_admin_content_type',
          },
          {
            field: 'Report type',
            typeHandle: 'text',
            key: 'community_admin_report_type',
          },
          {
            field: 'Violence',
            typeHandle: 'text',
            key: 'community_admin_violence',
          },
          {
            field: 'Nudity',
            typeHandle: 'text',
            key: 'community_admin_nudity',
          },
          {
            field: 'Post',
            typeHandle: 'text',
            key: 'community_admin_post',
          },
          {
            field: 'Comment',
            typeHandle: 'text',
            key: 'community_admin_comment',
          },
          {
            field: 'Reject post',
            typeHandle: 'text',
            key: 'community_admin_reject_post',
          },
          {
            field: 'Why do you want to reject this?',
            typeHandle: 'text',
            key: 'community_admin_reject_title',
          },
          {
            field: 'There’s a mention of firearms',
            typeHandle: 'text',
            key: 'community_admin_reject_firearm',
          },
          {
            field: 'There’s adult content',
            typeHandle: 'text',
            key: 'community_admin_reject_adult_content',
          },
          {
            field: 'It is insulting to people',
            typeHandle: 'text',
            key: 'community_admin_reject_insulting',
          },
          {
            field: 'It is spreading fake news',
            typeHandle: 'text',
            key: 'community_admin_reject_fake_news',
          },
          {
            field: 'Choose here',
            typeHandle: 'text',
            key: 'community_admin_reject_choose',
          },
          {
            field: 'Members',
            typeHandle: 'text',
            key: 'community_admin_members_title',
          },
          {
            field: 'Report post',
            typeHandle: 'text',
            key: 'community_admin_report_title',
          },
          {
            field: 'Why do you want to report this?',
            typeHandle: 'text',
            key: 'community_admin_report_subtilte',
          },
          {
            field: 'Content is suspicious',
            typeHandle: 'text',
            key: 'community_admin_report_suspicious',
          },
          {
            field: 'Content is abusive',
            typeHandle: 'text',
            key: 'community_admin_report_abbusive',
          },
          {
            field: 'It features adult content',
            typeHandle: 'text',
            key: 'community_admin_report_adult_content',
          },
          {
            field: 'Join',
            typeHandle: 'text',
            key: 'community_group_join_text',
          },
          {
            field: 'Admin view',
            typeHandle: 'text',
            key: 'community_group_admin_view_text',
          },
          {
            field: 'View as user',
            typeHandle: 'text',
            key: 'community_group_as_user_text',
          },
          {
            field: 'View as admin',
            typeHandle: 'text',
            key: 'community_group_as_admin_text',
          },
          {
            field: 'Thank you.',
            typeHandle: 'text',
            key: 'community_post_thank_you',
          },
          {
            field: 'Your post is being reviewed by our moderators.',
            typeHandle: 'text',
            key: 'community_post_reviewed',
          },
          {
            field: 'Suspicious',
            typeHandle: 'text',
            key: 'community_admin_suspicious',
          },
          {
            field: 'Dismiss report',
            typeHandle: 'text',
            key: 'community_admin_dismiss_report',
          },
          {
            field: 'All',
            typeHandle: 'text',
            key: 'community_admin_filter_all',
          },
          {
            field: 'Moderator',
            typeHandle: 'text',
            key: 'community_admin_filter_moderator',
          },
          {
            field: 'Pre-approved',
            typeHandle: 'text',
            key: 'community_admin_filter_pre_approved',
          },
          {
            field: 'Blocked',
            typeHandle: 'text',
            key: 'community_admin_filter_blocked',
          },
          {
            field: 'Contributor',
            typeHandle: 'text',
            key: 'community_admin_filter_contributor',
          },
          {
            field: 'Member',
            typeHandle: 'text',
            key: 'community_admin_filter_member',
          },
          {
            field: 'Role',
            typeHandle: 'text',
            key: 'community_admin_filter_role',
          },
          {
            field: 'Last Post',
            typeHandle: 'text',
            key: 'community_admin_filter_last_post',
          },
          {
            field: 'Date joined',
            typeHandle: 'text',
            key: 'community_admin_filter_date_joined',
          },
          {
            field: 'Number of posts',
            typeHandle: 'text',
            key: 'community_admin_filter_number_posts',
          },
          {
            field: 'to',
            typeHandle: 'text',
            key: 'community_admin_filter_to',
          },
          {
            field: 'More than 100',
            typeHandle: 'text',
            key: 'community_admin_filter_more_than',
          },
          {
            field: '0 to 50',
            typeHandle: 'text',
            key: 'community_admin_filter_0_50',
          },
          {
            field: '51 to 100',
            typeHandle: 'text',
            key: 'community_admin_filter_51_100',
          },
          {
            field: 'reply',
            typeHandle: 'text',
            key: 'community_reply_text_singular',
          },
          {
            field: 'feed',
            typeHandle: 'text',
            key: 'community_mobile_menu_feed',
          },
          {
            field: 'about',
            typeHandle: 'text',
            key: 'community_mobile_menu_about',
          },
          {
            field: 'see all groups',
            typeHandle: 'text',
            key: 'community_mobile_group_slider_link',
          },
          {
            field: 'Appoint member',
            typeHandle: 'text',
            key: 'community_admin_appoint_memberr',
          },
          {
            field: 'Learn more',
            typeHandle: 'text',
            key: 'community_promo_link_text',
          },
          {
            field: 'Type your comment',
            typeHandle: 'text',
            key: 'community_comment_title',
          },
          {
            field: 'Are you sure you want to delete this post?',
            typeHandle: 'text',
            key: 'community_delete_post_message',
          },
          {
            field: 'Are you sure you want to delete this comment?',
            typeHandle: 'text',
            key: 'community_delete_comment_message',
          },
          {
            field: 'Please remove any profanity before posting.',
            typeHandle: 'text',
            key: 'community_remove_bad_words',
          },
          {
            field: 'Reject User Content',
            typeHandle: 'text',
            key: 'community_admin_reject_usercontent',
          },
          {
            field: 'Delete user',
            typeHandle: 'text',
            key: 'community_admin_delete_user',
          },
          {
            field: 'Suspend user',
            typeHandle: 'text',
            key: 'community_admin_suspend_user',
          },
          {
            field: 'Number of reports',
            typeHandle: 'text',
            key: 'community_admin_filter_number_reports',
          },
          {
            field: 'Edit comment',
            typeHandle: 'text',
            key: 'community_edit_comment_text',
          },
          {
            field: 'Article type',
            typeHandle: 'text',
            key: 'community_admin_article_type',
          },
          {
            field: 'This is spam',
            typeHandle: 'text',
            key: 'community_admin_report_spam',
          },
          {
            field: 'It contains violence',
            typeHandle: 'text',
            key: 'community_admin_report_violence',
          },
          {
            field: 'It contains explicit imagery/nudity',
            typeHandle: 'text',
            key: 'community_admin_report_nudity',
          },
          {
            field: 'It contains disinformation',
            typeHandle: 'text',
            key: 'community_admin_report_disinformation',
          },
          {
            field: 'Other reason',
            typeHandle: 'text',
            key: 'community_admin_report_other',
          },
          {
            field: 'It contains racism',
            typeHandle: 'text',
            key: 'community_admin_report_racism',
          },
          {
            field:
              '<p>Please note that AREA Groups are not a support platform. For all technical support questions, please contact the <a href="https://knowledge.autodesk.com/support" target="_blank">Autodesk Support team!</a></p>',
            typeHandle: 'text',
            key: 'community_groups_info_support',
          },
        ],
      },
      {},
      {},
      {
        strings: [
          {
            field: 'Which industries appeal to you most?',
            typeHandle: 'text',
            key: 'onboarding_industries_title',
          },
          {
            field: 'Please select at least one industry',
            typeHandle: 'text',
            key: 'onboarding_industries_desc',
          },
          {
            field: 'What tool(s) are vital to your workflow?',
            typeHandle: 'text',
            key: 'onboarding_tools_title',
          },
          {
            field:
              'Choose as many as apply, with a maximum of 12. E.g. “3ds Max”',
            typeHandle: 'text',
            key: 'onboarding_tools_desc',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Show yourself to the community',
            typeHandle: 'text',
            key: 'avatar_bio_sideBar_title',
          },
          {
            field: 'About Me',
            typeHandle: 'text',
            key: 'avatar_and_bio_aboutTitle',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Sign Up',
            typeHandle: 'text',
            key: 'sign_up_page_title',
          },
          {
            field: 'Get Started with your account!',
            typeHandle: 'text',
            key: 'sign_up_page_sidebarTitle',
          },
          {
            field: 'Registration',
            typeHandle: 'text',
            key: 'sign_up_page_steps_1',
          },
          {
            field: 'Email Validation',
            typeHandle: 'text',
            key: 'sign_up_page_steps_2',
          },
          {
            field: 'Account Verification',
            typeHandle: 'text',
            key: 'sign_up_page_steps_3',
          },
          {
            field: 'Avatar and Bio',
            typeHandle: 'text',
            key: 'sign_up_page_steps_4',
          },
          {
            field: 'Sign Up successful!',
            typeHandle: 'text',
            key: 'sign_up_page_title_success',
          },
          {
            field: 'Industries and Tools',
            typeHandle: 'text',
            key: 'sign_up_page_steps_5',
          },
          {
            field: 'Your account is deleted',
            typeHandle: 'text',
            key: 'sign_in_error_account_deleted_label',
          },
        ],
      },
      {
        strings: [
          {
            field:
              'You are now member of the AREA by Autodesk group, go check it out!',
            typeHandle: 'text',
            key: 'onboarding_welcome_desc2',
          },
          {
            field: 'You’ve unlocked all the great features of AREA',
            typeHandle: 'text',
            key: 'onboarding_welcome_desc',
          },
          {
            field: 'And don’t forget to join other amazing groups:',
            typeHandle: 'text',
            key: 'onboarding_welcome_desc3',
          },
        ],
      },
      {
        strings: [
          {
            field: 'How do I create assets?',
            typeHandle: 'text',
            key: 'create_banner_title',
          },
          {
            field: 'Learn more',
            typeHandle: 'text',
            key: 'link_list_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Favorites',
            typeHandle: 'text',
            key: 'favorites_page_title',
          },
          {
            field: 'No artwork has been added to your favorites~',
            typeHandle: 'text',
            key: 'no_favorite_artwork_text',
          },
          {
            field: 'No article has been added to your favorites~',
            typeHandle: 'text',
            key: 'no_favorite_article_text',
          },
          {
            field: 'No tutorial has been added to your favorites',
            typeHandle: 'text',
            key: 'no_favorite_tutorial_text',
          },
          {
            field: 'No member of the month has been added to your favorites',
            typeHandle: 'text',
            key: 'no_favorite_motm_text',
          },
          {
            field: 'No post has been added to your favorites',
            typeHandle: 'text',
            key: 'no_favorite_post_text',
          },
          {
            field:
              'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi tempore tempora hic et fugit saepe harum, placeat quo suscipit praesentium quod odit amet aperiam dicta eveniet culpa non, dignissimos eligendi!',
            typeHandle: 'text',
            key: 'favorites_page_intro',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Learn / Tutorials',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_title',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_save_changes',
          },
          {
            field: 'Hero data',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_section_hero_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_title_input_label',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_description_input_label',
          },
          {
            field: 'Featured  User Content',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_section_featured_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_section_featured_button_label',
          },
          {
            field: 'Selected content',
            typeHandle: 'text',
            key: 'content_page_learn_tutorials_section_featured_selected',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_save_changes',
          },
          {
            field: 'Selected content',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_section_featured_selected',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_section_featured_button_label',
          },
          {
            field: 'Featured  User Content',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_section_featured_title',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_description_input_label',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_title_input_label',
          },
          {
            field: 'Hero data',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_section_hero_title',
          },
          {
            field: 'Inspire / Articles',
            typeHandle: 'text',
            key: 'content_page_inspire_articles_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_inspire_mom_save_changes',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_inspire_mom_description_input_label',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_inspire_mom_title_input_label',
          },
          {
            field: 'Hero data',
            typeHandle: 'text',
            key: 'content_page_inspire_mom_section_hero_title',
          },
          {
            field: 'Inspire / Members of the month',
            typeHandle: 'text',
            key: 'content_page_inspire_mom_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Learn / Assets',
            typeHandle: 'text',
            key: 'content_page_learn_assets_title',
          },
          {
            field: 'Hero data',
            typeHandle: 'text',
            key: 'content_page_learn_assets_section_hero_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_learn_assets_title_input_label',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_learn_assets_description_input_label',
          },
          {
            field: 'Featured content',
            typeHandle: 'text',
            key: 'content_page_learn_assets_section_featured_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_learn_assets_section_featured_button_label',
          },
          {
            field: 'Selected content',
            typeHandle: 'text',
            key: 'content_page_learn_assets_section_featured_selected',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_learn_assets_save_changes',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Inspire',
            typeHandle: 'text',
            key: 'content_page_inspire_title',
          },
          {
            field: 'Hero banner',
            typeHandle: 'text',
            key: 'content_page_inspire_section_hero_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_inspire_title_input_label',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_inspire_description_input_label',
          },
          {
            field: 'Banner image',
            typeHandle: 'text',
            key: 'content_page_inspire_section_banner_title',
          },
          {
            field: 'Banner image',
            typeHandle: 'text',
            key: 'content_page_inspire_upload_banner_label',
          },
          {
            field: 'Featured user content',
            typeHandle: 'text',
            key: 'content_page_inspire_section_featured_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_inspire_section_featured_button_label',
          },
          {
            field: 'Selected featured content:',
            typeHandle: 'text',
            key: 'content_page_inspire_section_featured_selected',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_inspire_save_changes',
          },
          {
            field: 'Select article featured content',
            typeHandle: 'text',
            key: 'content_page_inspire_section_featured_userContent_selected',
          },
          {
            field: 'Select article featured content',
            typeHandle: 'text',
            key: 'content_page_inspire_section_featured_userContent_selected',
          },
          {
            field: 'SEO',
            typeHandle: 'text',
            key: 'content_page_inspire_section_seo_title',
          },
          {
            field: 'OG Image',
            typeHandle: 'text',
            key: 'content_page_inspire_section_seo_image_title',
          },
        ],
      },
      {
        strings: [],
      },
      {
        strings: [
          {
            field: 'Learn',
            typeHandle: 'text',
            key: 'content_page_learn_title',
          },
          {
            field: 'Hero banner',
            typeHandle: 'text',
            key: 'content_page_learn_section_hero_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_learn_title_input_label',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_learn_description_input_label',
          },
          {
            field: 'Banner image',
            typeHandle: 'text',
            key: 'content_page_learn_section_banner_title',
          },
          {
            field: 'Upload image',
            typeHandle: 'text',
            key: 'content_page_learn_upload_banner_label',
          },
          {
            field: 'Featured content',
            typeHandle: 'text',
            key: 'content_page_learn_section_featured_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_learn_section_featured_button_label',
          },
          {
            field: 'Selected content :',
            typeHandle: 'text',
            key: 'content_page_learn_section_featured_selected',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_learn_section_save_changes',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_learn_save_changes',
          },
          {
            field: 'SEO',
            typeHandle: 'text',
            key: 'content_page_learn_section_seo_title',
          },
          {
            field: 'OG Image',
            typeHandle: 'text',
            key: 'content_page_learn_section_seo_image_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_save_changes',
          },
          {
            field: 'Selected content',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_section_featured_selected',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_section_featured_button_label',
          },
          {
            field: 'Featured  User Content',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_section_featured_title',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_description_input_label',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_title_input_label',
          },
          {
            field: 'Hero data',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_section_hero_title',
          },
          {
            field: 'Inspire / Gallery',
            typeHandle: 'text',
            key: 'content_page_inspire_gallery_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Delete selected',
            typeHandle: 'text',
            key: 'admin_community_all_posts_delete_bulk_label',
          },
          {
            field: 'Publish Selected',
            typeHandle: 'text',
            key: 'admin_community_all_posts_publish_bulk_label',
          },
          {
            field: 'Unpublish Selected',
            typeHandle: 'text',
            key: 'admin_community_all_posts_unpublish_bulk_label',
          },
          {
            field: 'Delete',
            typeHandle: 'text',
            key: 'admin_community_all_posts_delete_label',
          },
          {
            field: 'Publish',
            typeHandle: 'text',
            key: 'admin_community_all_posts_publish_label',
          },
          {
            field: 'Unpublish',
            typeHandle: 'text',
            key: 'admin_community_all_posts_unpublish_label',
          },
        ],
      },
      {
        strings: [],
      },
      {
        strings: [
          {
            field: 'Are you sure you want to delete user?',
            typeHandle: 'text',
            key: 'admin_member_delete_title',
          },
          {
            field: 'Appoint trusted member',
            typeHandle: 'text',
            key: 'admin_cms_appoint_trusted_member',
          },
          {
            field: 'Appoint admin',
            typeHandle: 'text',
            key: 'admin_cms_appoint_admin',
          },
          {
            field: 'Appoint editor',
            typeHandle: 'text',
            key: 'admin_cms_appoint_editor',
          },
          {
            field: 'Trusted member',
            typeHandle: 'text',
            key: 'admin_cms_trusted_member',
          },
          {
            field: 'Admin',
            typeHandle: 'text',
            key: 'admin_cms_admin',
          },
          {
            field: 'Editor',
            typeHandle: 'text',
            key: 'admin_cms_editor',
          },
          {
            field: 'Deleted',
            typeHandle: 'text',
            key: 'admin_cms_deleted',
          },
          {
            field: 'Role succesfully changed!01~',
            typeHandle: 'text',
            key: 'client_notification_changeRole_success',
          },
          {
            field: 'Admins cannot be demoted',
            typeHandle: 'text',
            key:
              'client_notification_insufficient_privilege_same_higher_role_error',
          },
          {
            field: 'Are you sure you want to bring back this user?',
            typeHandle: 'text',
            key: 'admin_member_bring_back_title',
          },
          {
            field: 'Contributor',
            typeHandle: 'text',
            key: 'admin_cms_contributor',
          },
          {
            field: 'Appoint contributor',
            typeHandle: 'text',
            key: 'admin_cms_appoint_contributor',
          },
          {
            field:
              "<ul>\n <li>\n  <b>Do give your asset a title. </b>\n  <br />\n  Want people to find your work through search? Make sure your\n  title reflects what your project is – getting creative with your\n  title doesn't work to your advantage.\n </li>\n <li>\n  <b>Do submit a description of your work. </b>\n  <br />\n  This can range from your inspiration and backstory to your\n  technique.\n </li>\n <li>\n  <b>Do submit multiple images to showcase your work. </b>\n  <br />\n  This can range from WIP (work-in-progress) imagery to multiple\n  angles of your work.\n </li>\n <li>\n  <b>Do run a grammar check on your title and description. </b>\n </li>\n <li>\n  <b>Do submit work that it watermarked. </b>\n  <br />\n  We strongly urge you to watermark your work with your name and\n  social media handle or website so that if someone shares it\n  without your knowing, people will know where to find you and\n  more of your work.\n </li>\n</ul>",
            typeHandle: 'text',
            key: 'create_warning_dos_asset_text',
          },
          {
            field:
              "<ul>\n <li>\n  <b>Don't submit disrespectful asset</b>\n  <br />\n  Don't submit work that is sexually explicit, hateful, degrading\n  or that includes illicit drugs, profanities, or graphic\n  violence.\n </li>\n <li>\n  <b>Don't submit a disrespectful title or description </b>\n  <br />\n  Don't submit a title or a description of your work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or violence. \n </li>\n <li>\n  <b>If your work goes against our guidelines, we will not publish it. </b>\n </li>\n</ul>",
            typeHandle: 'text',
            key: 'create_warning_donts_asset_text',
          },
          {
            field: 'Upload field has an error or is empty',
            typeHandle: 'text',
            key: 'create_disabled_next_button_empty_asset_file',
          },
          {
            field: 'Members',
            typeHandle: 'text',
            key: 'admin_cms_members_title',
          },
          {
            field: 'Pending',
            typeHandle: 'text',
            key: 'community_admin_filter_status_pending',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Homepage - Signed In',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_title',
          },
          {
            field: 'Hero banner',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_hero_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_title_input_label',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_description_input_label',
          },
          {
            field: 'Banner image',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_banner_title',
          },
          {
            field: 'Upload image',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_upload_banner_label',
          },
          {
            field: 'Section explore featured user content',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_explore_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_explore_button_label',
          },
          {
            field: 'Section learn featured user content',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_learn_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_learn_button_label',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_save_changes',
          },
          {
            field: 'Selected member of the month:',
            typeHandle: 'text',
            key:
              'content_page_homepage_signed_in_section_explore_motm_selected',
          },
          {
            field: 'Select member',
            typeHandle: 'text',
            key:
              'content_page_homepage_signed_in_section_explore_motm_button_label',
          },
          {
            field: 'Button label',
            typeHandle: 'text',
            key: 'home_signed_in_btn_label',
          },
          {
            field: 'Button url',
            typeHandle: 'text',
            key: 'home_signed_in_btn_url',
          },
          {
            field: 'Remove image',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_remove_banner_label',
          },
          {
            field: 'Credits',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_artist_credits_name',
          },
          {
            field: 'Credits URL',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_artist_credits_url',
          },
          {
            field: 'SEO',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_seo_title',
          },
          {
            field: 'OG Image',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_seo_image_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Homepage - Visitor',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_title',
          },
          {
            field: 'Hero banner',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_hero_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_title_input_label',
          },
          {
            field: 'Description',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_description_input_label',
          },
          {
            field: 'Banner image',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_banner_title',
          },
          {
            field: 'Upload image',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_upload_banner_label',
          },
          {
            field: 'Section explore featured member content',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_explore_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key:
              'content_page_homepage_not_signed_in_section_explore_button_label',
          },
          {
            field: 'Section learn featured member content',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_learn_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key:
              'content_page_homepage_not_signed_in_section_learn_button_label',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_save_changes',
          },
          {
            field: 'Selected member content:',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_explore_selected',
          },
          {
            field: 'Selected featured content:',
            typeHandle: 'text',
            key: 'content_page_homepage_signed_in_section_learn_selected',
          },
          {
            field: 'Selected member of the month:',
            typeHandle: 'text',
            key:
              'content_page_homepage_not_signed_in_section_explore_motm_selected',
          },
          {
            field: 'Selected featured content:',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_learn_selected',
          },
          {
            field: 'Select member',
            typeHandle: 'text',
            key:
              'content_page_homepage_not_signed_in_section_explore_motm_button_label',
          },
          {
            field: 'Select post ID:',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_explore_selected',
          },
          {
            field: 'Remove image',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_remove_banner_label',
          },
          {
            field: 'Credits',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_artist_credits_name',
          },
          {
            field: 'Credits URL',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_artist_credits_url',
          },
          {
            field: 'SEO',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_seo_title',
          },
          {
            field: 'OG Image',
            typeHandle: 'text',
            key: 'content_page_homepage_not_signed_in_section_seo_image_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'AREA Activity Report',
            typeHandle: 'text',
            key: 'admin_cms_dashboard_title',
          },
        ],
      },
      {
        strings: [],
      },
      {
        strings: [
          {
            field: 'Username successfully changed !',
            typeHandle: 'text',
            key: 'account_settings_username_changed_modal',
          },
          {
            field: 'Close',
            typeHandle: 'text',
            key: 'account_settings_username_changed_modal_confirm_button',
          },
          {
            field: 'Member since',
            typeHandle: 'text',
            key: 'account_settings_member_since',
          },
          {
            field: 'Login and Security',
            typeHandle: 'text',
            key: 'account_settings_login_and_security_link',
          },
          {
            field: 'Autodesk Password',
            typeHandle: 'text',
            key: 'account_settings_autodesk_password_link',
          },
          {
            field: 'Two-Factor Identification',
            typeHandle: 'text',
            key: 'account_settings_two_factor_identification_link',
          },
          {
            field: 'Connexion',
            typeHandle: 'text',
            key: 'account_settings_connexion_link',
          },
          {
            field: 'App Access',
            typeHandle: 'text',
            key: 'account_settings_app_access_link',
          },
          {
            field: 'Delete Account',
            typeHandle: 'text',
            key: 'account_settings_delete_account_link',
          },
          {
            field: 'Account Settings',
            typeHandle: 'text',
            key: 'account_settings_tab_account_settings',
          },
          {
            field: 'Notifications',
            typeHandle: 'text',
            key: 'account_settings_tab_notifications',
          },
          {
            field: 'Privacy',
            typeHandle: 'text',
            key: 'account_settings_tab_privacy',
          },
          {
            field: 'Login and Security',
            typeHandle: 'text',
            key: 'account_settings_account_login_and_security',
          },
          {
            field: 'Email',
            typeHandle: 'text',
            key: 'account_settings_account_login_and_security_form_email',
          },
          {
            field: 'Edit this ID on autodesk',
            typeHandle: 'text',
            key:
              'account_settings_account_login_and_security_form_change_adsk_id',
          },
          {
            field: 'Username',
            typeHandle: 'text',
            key: 'account_settings_account_login_and_security_form_usename',
          },
          {
            field: 'Warning',
            typeHandle: 'text',
            key: 'account_settings_account_login_and_security_warning_title',
          },
          {
            field:
              'Changing your username will change the URL of your AREA profiles. You will need to update any links that use these URL’s :',
            typeHandle: 'text',
            key: 'account_settings_account_login_and_security_warning_text',
          },
          {
            field: 'Autodesk Password',
            typeHandle: 'text',
            key: 'account_settings_account_autodesk_password',
          },
          {
            field: 'Change my password',
            typeHandle: 'text',
            key: 'account_settings_account_autodesk_password_button_label',
          },
          {
            field: 'Delete Account',
            typeHandle: 'text',
            key: 'account_settings_account_autodesk_delete_account',
          },
          {
            field: 'Would you like to delete your AREA account?',
            typeHandle: 'text',
            key: 'account_settings_account_autodesk_delete_account_subtitle',
          },
          {
            field:
              'Please note that once you remove your account, it will delete all artworks that you’ve posted',
            typeHandle: 'text',
            key: 'account_settings_account_autodesk_delete_account_text',
          },
          {
            field: 'I want to delete my account',
            typeHandle: 'text',
            key:
              'account_settings_account_autodesk_delete_account_button_label',
          },
          {
            field: 'Save settings',
            typeHandle: 'text',
            key: 'account_settings_account_autodesk_save_settings',
          },
          {
            field: 'Change password',
            typeHandle: 'text',
            key: 'account_settings_password_modal',
          },
          {
            field: 'Current password',
            typeHandle: 'text',
            key: 'account_settings_change_password_form_current_label',
          },
          {
            field: 'Forgot password?',
            typeHandle: 'text',
            key:
              'account_settings_change_password_form_current_forgot_password_label',
          },
          {
            field: 'New password',
            typeHandle: 'text',
            key: 'account_settings_change_password_form_new_label',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'account_settings_password_modal_cancel_button',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'account_settings_delete_account_modal_cancel_button',
          },
          {
            field: 'Yes, Delete!',
            typeHandle: 'text',
            key: 'account_settings_delete_account_modal_confirm_button',
          },
          {
            field: 'Save new password',
            typeHandle: 'text',
            key: 'account_settings_password_modal_confirm_button',
          },
          {
            field: 'Delete account ?',
            typeHandle: 'text',
            key: 'account_settings_delete_account_modal',
          },
          {
            field: 'Username',
            typeHandle: 'text',
            key: 'account_settings_account_login_and_security_form_username',
          },
          {
            field: 'Please fill in this field',
            typeHandle: 'text',
            key: 'account_settings_input_error_empty',
          },
          {
            field: 'Invalid email',
            typeHandle: 'text',
            key: 'account_settings_email_error_validation',
          },
          {
            field:
              'You can change your account email only twice every 365 days',
            typeHandle: 'text',
            key: 'account_settings_email_error_too_soon',
          },
          {
            field:
              'You can change your account username only twice every 365 days',
            typeHandle: 'text',
            key: 'account_settings_username_error_too_soon',
          },
          {
            field: 'Your username contains forbidden words, try something else',
            typeHandle: 'text',
            key: 'account_settings_username_error_username_contains_badwords',
          },
          {
            field: 'Your new password is identical to your previous password',
            typeHandle: 'text',
            key:
              'account_settings_update_password_new_password_error_identical_password',
          },
          {
            field:
              'Your new password must contain at least one capital letter, one number and one special character with a minimum length of eight characters',
            typeHandle: 'text',
            key:
              'account_settings_update_password_current_password_error_validation',
          },
          {
            field:
              'Your new password must contain at least one capital letter, one number and one special character with a minimum length of eight characters',
            typeHandle: 'text',
            key:
              'account_settings_update_password_new_password_error_validation',
          },
          {
            field: 'Wrong password, try again',
            typeHandle: 'text',
            key:
              'account_settings_update_password_current_password_error_wrong_password',
          },
          {
            field:
              'You have exceeded the number of attempts allowed, please try again later. Time left:',
            typeHandle: 'text',
            key:
              'account_settings_update_password_current_password_error_user_lockout',
          },
          {
            field: 'Username successfully changed !',
            typeHandle: 'text',
            key: 'account_settings_username_changed_modal',
          },
          {
            field: 'Close',
            typeHandle: 'text',
            key: 'account_settings_username_changed_modal_confirm_button',
          },
          {
            field:
              "We're sad to see you leave. By confirming, you agree that your AREA account and all your data will be permanently deleted",
            typeHandle: 'text',
            key: 'delete_user_account_warning',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Add a Tag',
            typeHandle: 'text',
            key: 'admin_content_tags_add_tags_label',
          },
          {
            field: 'Tag Name',
            typeHandle: 'text',
            key: 'admin_content_tags_input_text_label',
          },
          {
            field: 'Edit a Tag',
            typeHandle: 'text',
            key: 'admin_content_tags_modal_edit_title',
          },
          {
            field: 'Add a Tag',
            typeHandle: 'text',
            key: 'admin_content_tags_modal_add_title',
          },
          {
            field: 'Tags',
            typeHandle: 'text',
            key: 'admin_content_tags_title',
          },
          {
            field: 'Add a Industry',
            typeHandle: 'text',
            key: 'admin_content_industries_add_industry_label',
          },
          {
            field: 'Industry Name',
            typeHandle: 'text',
            key: 'admin_content_industries_input_text_label',
          },
          {
            field: 'Edit a Industry',
            typeHandle: 'text',
            key: 'admin_content_industries_modal_edit_title',
          },
          {
            field: 'Add a Industry',
            typeHandle: 'text',
            key: 'admin_content_industries_modal_add_title',
          },
          {
            field: 'Industries',
            typeHandle: 'text',
            key: 'admin_content_industries_title',
          },
          {
            field: 'Add a Tool',
            typeHandle: 'text',
            key: 'admin_content_tools_add_tool_label',
          },
          {
            field: 'Tool Name',
            typeHandle: 'text',
            key: 'admin_content_tools_input_text_label',
          },
          {
            field: 'Tool Icon',
            typeHandle: 'text',
            key: 'admin_content_tools_input_image_label',
          },
          {
            field: 'Edit a Tool',
            typeHandle: 'text',
            key: 'admin_content_tools_modal_edit_title',
          },
          {
            field: 'Add a Tool',
            typeHandle: 'text',
            key: 'admin_content_tools_modal_add_title',
          },
          {
            field: 'Tools',
            typeHandle: 'text',
            key: 'admin_content_tools_title',
          },
        ],
      },
      {
        strings: [],
      },
      {
        strings: [
          {
            field: 'Community guidelines',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_title',
          },
          {
            field: 'Hero data',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_section_hero_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_title_input_label',
          },
          {
            field: 'Page content',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_content_title',
          },
          {
            field: 'Edit the page content in the wysiwyg editor',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_content_subtitle',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_save_changes',
          },
          {
            field: 'SEO',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_section_seo_title',
          },
          {
            field: 'OG Image',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_section_seo_image_title',
          },
          {
            field: 'Add image',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_upload_banner_label',
          },
          {
            field: 'Remove image',
            typeHandle: 'text',
            key: 'content_page_community_guidelines_remove_banner_label',
          },
        ],
      },
      {
        strings: [],
      },
      {
        strings: [],
      },
      {
        strings: [],
      },
      {
        strings: [],
      },
      {
        strings: [],
      },
      {
        strings: [],
      },
      {
        strings: [],
      },
      {
        strings: [],
      },
      {},
      {},
      {
        strings: [],
      },
      {
        strings: [],
      },
      {
        strings: [
          {
            field: 'Group Settings',
            typeHandle: 'text',
            key: 'page_group_settings_form_title',
          },
          {
            field: 'Group Title',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_title',
          },
          {
            field: 'Group Description',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_desc',
          },
          {
            field: 'Group Rules',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_rules',
          },
          {
            field: 'Group Main Picture',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_main_picture',
          },
          {
            field: 'Group Cover',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_cover_picture',
          },
          {
            field: 'Promotion Box',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_promo',
          },
          {
            field: '1st Featured AREA Content',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_first_featured',
          },
          {
            field: '2nd Featured AREA Content',
            typeHandle: 'text',
            key: 'page_group_settings_label_input_second_featured',
          },
          {
            field: 'Back to Group Feed',
            typeHandle: 'text',
            key: 'page_group_settings_backlink',
          },
        ],
      },
      {
        strings: [],
      },
      {
        strings: [
          {
            field: 'Courses',
            typeHandle: 'text',
            key: 'page_courses_title',
          },
          {
            field:
              'Improve your skillset with courses presented by industry professionals for 3ds Max, Maya and more.',
            typeHandle: 'text',
            key: 'page_courses_intro',
          },
          {
            field: 'Learn more',
            typeHandle: 'text',
            key: 'page_courses_links_section_title',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Tutorial series',
            typeHandle: 'text',
            key: 'search_content_title_tutorial_series',
          },
          {
            field: 'Assets',
            typeHandle: 'text',
            key: 'search_content_title_assets',
          },
          {
            field: 'Articles',
            typeHandle: 'text',
            key: 'search_content_title_articles',
          },
          {
            field: 'Artworks',
            typeHandle: 'text',
            key: 'search_content_title_artworks',
          },
          {
            field: 'Members',
            typeHandle: 'text',
            key: 'search_content_title_members',
          },
          {
            field: 'Tutorials',
            typeHandle: 'text',
            key: 'search_content_title_tutorials',
          },
          {
            field:
              'Start your search now! Type any keyword in the search bar. Specify your search with the drop down menu on the right.',
            typeHandle: 'text',
            key: 'search_intro',
          },
        ],
      },
      {
        strings: [],
      },
      {
        strings: [
          {
            field: 'Delete draft?',
            typeHandle: 'text',
            key: 'create_draft_delete_modal_title',
          },
          {
            field: "You currently don't have any drafts.",
            typeHandle: 'text',
            key: 'create_draft_no_draft',
          },
          {
            field: 'Delete',
            typeHandle: 'text',
            key: 'create_draft_delete_modal_delete_button_label',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_draft_delete_modal_cancel_button_label',
          },
          {
            field: 'Choose a draft',
            typeHandle: 'text',
            key: 'create_draft_title',
          },
          {
            field: 'My drafts',
            typeHandle: 'text',
            key: 'create_draft_page_title',
          },
          {
            field:
              'You can create drafts for any content type. When you create a draft, your post remains unpublished and is only visible to you. You can publish a draft at any time.',
            typeHandle: 'text',
            key: 'create_draft_page_intro',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Add a Bio',
            typeHandle: 'text',
            key: 'profile_add_aboutMe_label',
          },
          {
            field: 'Add a Recent Work',
            typeHandle: 'text',
            key: 'profile_add_recentWork_label',
          },
          {
            field: 'Add a Work Experience',
            typeHandle: 'text',
            key: 'profile_add_workExperience_label',
          },
          {
            field: 'Add a School or Training',
            typeHandle: 'text',
            key: 'profile_add_schoolAndTraining_label',
          },
          {
            field: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            typeHandle: 'text',
            key: 'profile_add_aboutMe_description',
          },
          {
            field: 'Your profile is invisible in the research results',
            typeHandle: 'text',
            key: 'profile_message_incomplete_title',
          },
          {
            field:
              'Your actions, like comments, are blocked until your profile is 70% completed.',
            typeHandle: 'text',
            key: 'profile_message_info_description',
          },
          {
            field: 'Complete your work experience',
            typeHandle: 'text',
            key: 'profile_message_incomplete_bullets_1',
          },
          {
            field: 'Add a bio',
            typeHandle: 'text',
            key: 'profile_message_incomplete_bullets_2',
          },
          {
            field: 'Choose 3 skill',
            typeHandle: 'text',
            key: 'profile_message_incomplete_bullets_3',
          },
          {
            field: 'We are sorry',
            typeHandle: 'text',
            key: 'profile_avatar_error_upload_recognition_title',
          },
          {
            field:
              'It seems the visual or image you are trying to upload goes against our guidelines. We invite you to select another one. If you think this is a mistake, please reach us here on our support page.',
            typeHandle: 'text',
            key: 'profile_avatar_error_upload_recognition_text',
          },
          {
            field: 'Upload again',
            typeHandle: 'text',
            key: 'profile_avatar_error_upload_recognition_button',
          },
          {
            field: 'About Me',
            typeHandle: 'text',
            key: 'profile_resume_aboutMe_title',
          },
          {
            field: 'Projects',
            typeHandle: 'text',
            key: 'profile_resume_projects_title',
          },
          {
            field: 'School & Training',
            typeHandle: 'text',
            key: 'profile_resume_schools_title',
          },
          {
            field: 'School Name',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_label_title',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'profile_resume_projects_input_label_title',
          },
          {
            field: 'Generate a thumbnail from a IMDB Link (optional)',
            typeHandle: 'text',
            key: 'profile_resume_projects_input_label_url',
          },
          {
            field:
              'Paste a IMDB link with the following format: https://www.imdb.com/title/*',
            typeHandle: 'text',
            key: 'profile_resume_projects_input_instructions_url',
          },
          {
            field: 'Please a valid IMDB Link',
            typeHandle: 'text',
            key: 'profile_resume_projects_input_error_url',
          },
          {
            field: 'Location',
            typeHandle: 'text',
            key: 'profile_resume_input_label_location',
          },
          {
            field: 'Job Title',
            typeHandle: 'text',
            key: 'profile_resume_input_label_jobTitle',
          },
          {
            field: 'From',
            typeHandle: 'text',
            key: 'profile_resume_input_label_from',
          },
          {
            field: 'To',
            typeHandle: 'text',
            key: 'profile_resume_input_label_to',
          },
          {
            field: 'Today',
            typeHandle: 'text',
            key: 'profile_resume_input_label_today',
          },
          {
            field: 'This is my current job',
            typeHandle: 'text',
            key: 'profile_resume_input_label_isCurrent',
          },
          {
            field: 'Course',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_label_course',
          },
          {
            field: 'Work Experience',
            typeHandle: 'text',
            key: 'profile_resume_companies_title',
          },
          {
            field: 'Company Name',
            typeHandle: 'text',
            key: 'profile_resume_companies_input_label_title',
          },
          {
            field: 'Add a new company',
            typeHandle: 'text',
            key: 'profile_resume_companies_input_title_create',
          },
          {
            field: 'Add a new school',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_title_create',
          },
          {
            field: 'Add your own avatar',
            typeHandle: 'text',
            key: 'profile_message_incomplete_avatar',
          },
          {
            field: 'Add a Bio',
            typeHandle: 'text',
            key: 'profile_message_incomplete_bio',
          },
          {
            field: 'Complete your work experience',
            typeHandle: 'text',
            key: 'profile_message_incomplete_workExperience',
          },
          {
            field: 'I currently study there',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_label_isCurrent',
          },
          {
            field: 'Name',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_label_title',
          },
          {
            field:
              'Enter the name (College, Training Facility, University, etc.)',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_instructions_title',
          },
          {
            field: 'Course/Program',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_label_course',
          },
          {
            field:
              'Introduce yourself to the community, a small sentence on what you’re passionate about',
            typeHandle: 'text',
            key: 'profile_add_aboutMe_description',
          },
          {
            field: 'Please enter a school name',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_label_title_error',
          },
          {
            field: 'Please enter a location',
            typeHandle: 'text',
            key: 'profile_resume_input_label_location_error',
          },
          {
            field: 'Please enter the name of the course',
            typeHandle: 'text',
            key: 'profile_resume_schools_input_label_course_error',
          },
          {
            field: 'Please enter the title of this project',
            typeHandle: 'text',
            key: 'profile_resume_projects_input_label_title_error',
          },
          {
            field: 'Please enter a company name',
            typeHandle: 'text',
            key: 'profile_resume_companies_input_title_create_error',
          },
          {
            field: 'Please enter your job title',
            typeHandle: 'text',
            key: 'profile_resume_input_label_jobTitle_error',
          },
          {
            field: 'Generate a thumbnail from a IMDB Link',
            typeHandle: 'text',
            key: 'profile_resume_projects_input_label_url',
          },
          {
            field: 'Load more',
            typeHandle: 'text',
            key: 'profile_load_more_button',
          },
          {
            field: 'Feeling inspired? Create a new artwork!',
            typeHandle: 'text',
            key: 'profile_banner_create_artwork_title',
          },
          {
            field: 'Show your best work with the community',
            typeHandle: 'text',
            key: 'profile_banner_create_artwork_subtitle',
          },
          {
            field: 'You haven’t created any Artwork yet',
            typeHandle: 'text',
            key: 'profile_banner_create_artwork_empty_section_title',
          },
          {
            field: 'Upload your first artwork',
            typeHandle: 'text',
            key: 'profile_banner_create_artwork_empty_section_button',
          },
          {
            field: 'Feeling inspired? Create a new article!',
            typeHandle: 'text',
            key: 'profile_banner_create_article_title',
          },
          {
            field: 'Show your best work with the community',
            typeHandle: 'text',
            key: 'profile_banner_create_article_subtitle',
          },
          {
            field: 'You haven’t created any article yet',
            typeHandle: 'text',
            key: 'profile_banner_create_article_empty_section_title',
          },
          {
            field: 'Write your first article',
            typeHandle: 'text',
            key: 'profile_banner_create_article_empty_section_button',
          },
          {
            field: 'Feeling inspired? Create a new tutorial!',
            typeHandle: 'text',
            key: 'profile_banner_create_tutorial_title',
          },
          {
            field: 'Share your best tips with the community',
            typeHandle: 'text',
            key: 'profile_banner_create_tutorial_subtitle',
          },
          {
            field: 'You haven’t created any tutorial yet',
            typeHandle: 'text',
            key: 'profile_banner_create_tutorial_empty_section_title',
          },
          {
            field: 'Create your first tutorial',
            typeHandle: 'text',
            key: 'profile_banner_create_tutorial_empty_section_button',
          },
          {
            field: 'This user is suspicious',
            typeHandle: 'text',
            key: 'profile_report_user_suspicious',
          },
          {
            field: 'This user is posting abusive content',
            typeHandle: 'text',
            key: 'profile_report_user_abusive',
          },
          {
            field: 'This user promotes adult content',
            typeHandle: 'text',
            key: 'profile_report_user_adult_content',
          },
          {
            field: 'Report profile ?',
            typeHandle: 'text',
            key: 'profile_report_user_title',
          },
          {
            field: 'Tell us what happened',
            typeHandle: 'text',
            key: 'profile_report_user_subtitle',
          },
          {
            field: 'Select report options',
            typeHandle: 'text',
            key: 'profile_report_user_placeholder',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'profile_report_user_cancel_btn_label',
          },
          {
            field: 'Report',
            typeHandle: 'text',
            key: 'profile_report_user_report_btn_label',
          },
          {
            field: 'User reported',
            typeHandle: 'text',
            key: 'profile_report_user_status_open',
          },
          {
            field: 'Report',
            typeHandle: 'text',
            key: 'profile_sidebar_report_button_label',
          },
          {
            field: 'School or establishment',
            typeHandle: 'text',
            key: 'profile_resume_schools_gplaces_input_label',
          },
          {
            field: 'Location',
            typeHandle: 'text',
            key: 'profile_resume_projects_gplaces_input_label',
          },
          {
            field: 'Location',
            typeHandle: 'text',
            key: 'profile_resume_work_gplaces_input_label',
          },
          {
            field: 'Location',
            typeHandle: 'text',
            key: 'profile_gplaces_input_label',
          },
          {
            field: 'First name',
            typeHandle: 'text',
            key: 'edit_first_name',
          },
          {
            field: 'Last name',
            typeHandle: 'text',
            key: 'edit_last_name',
          },
          {
            field: 'Occupation',
            typeHandle: 'text',
            key: 'edit_occupation',
          },
          {
            field: 'Gender',
            typeHandle: 'text',
            key: 'edit_gender',
          },
          {
            field: 'Resume successfully updated',
            typeHandle: 'text',
            key: 'client_notification_undefined_success',
          },
          {
            field: 'Empty field',
            typeHandle: 'text',
            key: 'profile_input_empty_field',
          },
          {
            field: "'s resume is empty",
            typeHandle: 'text',
            key: 'profile_empty_resume_title',
          },
          {
            field:
              'It might still be a work in progress, come back later to see it',
            typeHandle: 'text',
            key: 'profile_empty_resume_paragraph',
          },
          {
            field: 'Add the tools you use to your profile',
            typeHandle: 'text',
            key: 'profile_message_incomplete_tools',
          },
          {
            field: 'Add the industries you are interested in to your profile',
            typeHandle: 'text',
            key: 'profile_message_incomplete_industries',
          },
          {
            field: 'Feeling inspired? Add a new asset!',
            typeHandle: 'text',
            key: 'profile_banner_create_asset_title',
          },
          {
            field: 'Share your best tools with the community',
            typeHandle: 'text',
            key: 'profile_banner_create_asset_subtitle',
          },
          {
            field: "You haven't uploaded any asset yet",
            typeHandle: 'text',
            key: 'profile_banner_create_asset_empty_section_title',
          },
          {
            field: 'Upload your best tools',
            typeHandle: 'text',
            key: 'profile_banner_create_asset_empty_section_button',
          },
          {
            field: 'This field cannot be left empty',
            typeHandle: 'text',
            key: 'profile_error_empty_field',
          },
          {
            field: 'At least one tool must be selected',
            typeHandle: 'text',
            key: 'profile_tools_error_empty_tools',
          },
          {
            field: 'This field cannot be left empty.',
            typeHandle: 'text',
            key: 'profile_tools_error_empty_industries',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Enter your code',
            typeHandle: 'text',
            key: 'verify_sms_code_title',
          },
          {
            field: 'Enter the code sent to your phone',
            typeHandle: 'text',
            key: 'verify_sms_code_text',
          },
          {
            field: 'Code',
            typeHandle: 'text',
            key: 'verify_sms_code_input',
          },
          {
            field: 'Verify',
            typeHandle: 'text',
            key: 'verify_sms_code_button',
          },
          {
            field: 'This code is not valid',
            typeHandle: 'text',
            key: 'verify_sms_error_wrong_code',
          },
          {
            field: 'You need to enter a code',
            typeHandle: 'text',
            key: 'verify_sms_error_required',
          },
          {
            field: 'The code must be a string',
            typeHandle: 'text',
            key: 'verify_sms_error_not_string',
          },
          {
            field: 'Verification not initialized',
            typeHandle: 'text',
            key: 'verify_sms_error_no_verification_ongoing',
          },
          {
            field: 'Verification code has expired',
            typeHandle: 'text',
            key: 'verify_sms_error_expired',
          },
          {
            field: 'Code verificationn failed, please try again',
            typeHandle: 'text',
            key: 'verify_sms_error_generic',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Thank you!',
            typeHandle: 'text',
            key: 'verify_email_title',
          },
          {
            field: 'login to AREA',
            typeHandle: 'text',
            key: 'verify_email_login_label',
          },
          {
            field: 'Your account is now verified, you can now',
            typeHandle: 'text',
            key: 'verify_email_text',
          },
          {
            field: 'Verification failed',
            typeHandle: 'text',
            key: 'verify_email_failed_text',
          },
          {
            field: 'Send Token again',
            typeHandle: 'text',
            key: 'verify_email_send_token',
          },
          {
            field: 'Verifying...',
            typeHandle: 'text',
            key: 'verify_email_verifying',
          },
          {
            field: "Couldn't send verification email, retry later",
            typeHandle: 'text',
            key: 'verify_email_resend_token_error',
          },
          {
            field: 'Verification email sent, check your inbox',
            typeHandle: 'text',
            key: 'verify_email_resend_token_success',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Verify by phone',
            typeHandle: 'text',
            key: 'verify_by_phone_validation_title',
          },
          {
            field:
              'Enter your phone number and we’ll text you to enter a verification code.',
            typeHandle: 'text',
            key: 'verify_by_phone_validation_text',
          },
          {
            field: 'You need to enter a valid phone number',
            typeHandle: 'text',
            key: 'verify_by_phone_error_required',
          },
          {
            field: 'The code must be a string',
            typeHandle: 'text',
            key: 'verify_by_phone_error_not_string',
          },
          {
            field: 'The verification has failed',
            typeHandle: 'text',
            key: 'verify_by_phone_verification_failed',
          },
          {
            field: 'Send Verification Code',
            typeHandle: 'text',
            key: 'verify_by_phone_validation_button',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Welcome back',
            typeHandle: 'text',
            key: 'sign_in_aside_title',
          },
          {
            field: "Don't have an account yet?",
            typeHandle: 'text',
            key: 'sign_in_create_account_text',
          },
          {
            field: 'Sign in to AREA',
            typeHandle: 'text',
            key: 'sign_in_title',
          },
          {
            field: 'Sign in with apple',
            typeHandle: 'text',
            key: 'sign_in_apple_button_label',
          },
          {
            field: 'Join now',
            typeHandle: 'text',
            key: 'sign_in_create_account_link_label',
          },
          {
            field: 'Sign in with google',
            typeHandle: 'text',
            key: 'sign_in_google_button_label',
          },
          {
            field: 'Sign in with Linkedin',
            typeHandle: 'text',
            key: 'sign_in_linkedin_button_label',
          },
          {
            field: 'Email Address',
            typeHandle: 'text',
            key: 'sign_in_login_email_label',
          },
          {
            field: 'Password',
            typeHandle: 'text',
            key: 'sign_in_login_password_label',
          },
          {
            field: 'Sign in',
            typeHandle: 'text',
            key: 'sign_in_login_button_label',
          },
          {
            field: 'Password must contain at least 8 characters',
            typeHandle: 'richText',
            key: 'sign_in_password_instruction',
          },
          {
            field: 'Please enter a valid email.',
            typeHandle: 'text',
            key: 'sign_in_error_valid_email',
          },
          {
            field: 'Please enter a valid password.',
            typeHandle: 'text',
            key: 'sign_in_error_valid_password',
          },
          {
            field: 'An email is required',
            typeHandle: 'text',
            key: 'sign_in_error_email_required',
          },
          {
            field: 'Your credentials is not valid.',
            typeHandle: 'text',
            key: 'sign_in_error_invalid_credentials',
          },
          {
            field: 'Your account is locked. Please try again later.',
            typeHandle: 'text',
            key: 'sign_in_error_user_lockout',
          },
          {
            field:
              'An error has occured on our server. Please try again later.',
            typeHandle: 'text',
            key: 'sign_in_error_could_not_create_token',
          },
          {
            field: 'Your account is not verified.',
            typeHandle: 'text',
            key: 'sign_in_error_email_not_verified',
          },
          {
            field: 'Forgot password?',
            typeHandle: 'text',
            key: 'sign_in_login_forgot_password_label',
          },
          {
            field: 'An error has occured. Please try again later.',
            typeHandle: 'text',
            key: 'sign_in_login_global_error',
          },
          {
            field: 'Resend email',
            typeHandle: 'text',
            key: 'sign_in_error_email_not_verified_resend',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Articles',
            typeHandle: 'text',
            key: 'page_inspire_articles_title',
          },
          {
            field:
              'Get inspired with articles featuring artist interviews, career advice, industry news, and more.',
            typeHandle: 'text',
            key: 'page_inspire_articles_intro',
          },
          {
            field: 'Explore more',
            typeHandle: 'text',
            key: 'page_inspire_articles_links_section_title',
          },
        ],
      },
      {},
      {},
      {
        strings: [
          {
            field: 'What are we building today?',
            typeHandle: 'text',
            key: 'create_intro_title',
          },
          {
            field: 'What are we building today?',
            typeHandle: 'text',
            key: 'create_intro_title',
          },
          {
            field: 'What are we building today?',
            typeHandle: 'text',
            key: 'create_intro_title',
          },
          {
            field: 'What are we building today?',
            typeHandle: 'text',
            key: 'create_intro_title',
          },
          {
            field: 'What are we building today?',
            typeHandle: 'text',
            key: 'create_intro_title',
          },
          {
            field: 'Artwork',
            typeHandle: 'text',
            key: 'create_intro_artwork_title',
          },
          {
            field:
              'Upload artwork for the community to see, favorite, and share.',
            typeHandle: 'text',
            key: 'create_intro_artwork_text',
          },
          {
            field: 'Start creating',
            typeHandle: 'text',
            key: 'create_intro_artwork_button_label',
          },
          {
            field: 'Tutorial',
            typeHandle: 'text',
            key: 'create_intro_tutorial_title',
          },
          {
            field: 'Help the community and share your experience.',
            typeHandle: 'text',
            key: 'create_intro_tutorial_text',
          },
          {
            field: 'Start teaching',
            typeHandle: 'text',
            key: 'create_intro_tutorial_button_label',
          },
          {
            field: 'Article',
            typeHandle: 'text',
            key: 'create_intro_article_title',
          },
          {
            field: 'Upload textures, 3D models, plugins & more.',
            typeHandle: 'text',
            key: 'create_intro_article_text',
          },
          {
            field: 'Start writing',
            typeHandle: 'text',
            key: 'create_intro_article_button_label',
          },
          {
            field:
              '<ul><li>\n <strong>Do give your work a title. </strong>\n <br />\n Want people to find your work through the search bar? Make sure your\n title reflects what your project is – getting too creative with your\n title doesn’t always work to your advantage.\n </li>\n <li>\n <strong>Do submit a description of your work. </strong>\n <br />\n This can range from your inspiration and backstory to your\n techniques.\n </li>\n <li>\n <strong>Do submit multiple images to showcase your work. </strong>\n <br />\n This can range from WIP (work-in-progress) imagery to different angles of your work.\n </li>\n <li>\n <strong>Do run a grammar check on your title and description. </strong>\n </li>\n <li>\n <strong>Do submit work that is watermarked. </strong>\n <br />\n We strongly urge you to watermark your work with your name and\n social media handle or website. This way, if someone shares it\n without your knowledge, people will know where to find you and\n more of your work.\n </li>\n</ul>',
            typeHandle: 'richText',
            key: 'create_warning_dos_text',
          },
          {
            field: 'DON’Ts',
            typeHandle: 'text',
            key: 'create_warning_donts_title',
          },
          {
            field: 'DOs',
            typeHandle: 'text',
            key: 'create_warning_dos_title',
          },
          {
            field:
              'In order to maintain our high quality community on AREA, we ask that you please respect our guidelines.',
            typeHandle: 'text',
            key: 'create_warning_text',
          },
          {
            field: 'Before you start creating with AREA',
            typeHandle: 'text',
            key: 'create_warning_title',
          },
          {
            field:
              '<ul><li>\n <strong>Don’t submit disrespectful artwork</strong>\n <br />\n Don’t submit work that is sexually explicit, hateful, degrading, or that includes illicit drugs, profanities, or graphic\n violence.\n </li>\n <li>\n <strong>Don’t submit a disrespectful title or description </strong>\n <br />\n Don’t submit a title or a description of your work that is sexually explicit, hateful, degrading, or that includes illicit drugs, profanities, or violence. \n </li>\n <li>\n <strong>If your work goes against our guidelines, we will not publish it. </strong>\n </li>\n</ul>',
            typeHandle: 'richText',
            key: 'create_warning_donts_text',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_warning_button_label',
          },
          {
            field: 'Ok, I agree with that!',
            typeHandle: 'text',
            key: 'create_warning_checkbox_label',
          },
          {
            field: 'Submit your work',
            typeHandle: 'text',
            key: 'create_navigation_title',
          },
          {
            field: 'Draft',
            typeHandle: 'text',
            key: 'create_navigation_draft_label',
          },
          {
            field: 'Help',
            typeHandle: 'text',
            key: 'create_navigation_help_label',
          },
          {
            field: 'Information',
            typeHandle: 'text',
            key: 'create_navigation_steps_information',
          },
          {
            field: 'Build',
            typeHandle: 'text',
            key: 'create_navigation_steps_build',
          },
          {
            field: 'Cover Image',
            typeHandle: 'text',
            key: 'create_navigation_steps_cover',
          },
          {
            field: 'Next',
            typeHandle: 'text',
            key: 'create_sidebar_button_next',
          },
          {
            field:
              'Your artworks are public and can be viewed by anyone on or off AREA. Artworks with nudity, drugs, weapons, or those that do not respect intellectual property are prohibited on AREA. See rules.',
            typeHandle: 'text',
            key: 'create_sidebar_notice_text',
          },
          {
            field: 'Save as draft',
            typeHandle: 'text',
            key: 'create_sidebar_button_save_draft',
          },
          {
            field: 'Draft',
            typeHandle: 'text',
            key: 'create_navigation_draft_label',
          },
          {
            field: 'Project information',
            typeHandle: 'text',
            key: 'create_information_title',
          },
          {
            field: null,
            typeHandle: 'text',
            key: 'create_information_text',
          },
          {
            field: 'Title',
            typeHandle: 'text',
            key: 'create_information_field_title',
          },
          {
            field: 'Industries',
            typeHandle: 'text',
            key: 'create_information_field_industries',
          },
          {
            field: 'Tools',
            typeHandle: 'text',
            key: 'create_information_field_tools',
          },
          {
            field: 'Tags',
            typeHandle: 'text',
            key: 'create_information_field_Tags',
          },
          {
            field: 'Artwork Cover',
            typeHandle: 'text',
            key: 'create_crop_artwork_title',
          },
          {
            field: 'Article Cover',
            typeHandle: 'text',
            key: 'create_crop_article_title',
          },
          {
            field: 'Tutorial Cover',
            typeHandle: 'text',
            key: 'create_crop_tutorial_title',
          },
          {
            field: 'To crop this image, resize the area below and publish.',
            typeHandle: 'text',
            key: 'create_crop_text',
          },
          {
            field: 'Upload a cover image',
            typeHandle: 'text',
            key: 'create_crop_upload_image',
          },
          {
            field: 'Image',
            typeHandle: 'text',
            key: 'create_crop_upload_image_button_label',
          },
          {
            field: 'JPG, PNG',
            typeHandle: 'text',
            key: 'create_crop_upload_image_button_text',
          },
          {
            field: 'Upload new image',
            typeHandle: 'text',
            key: 'create_crop_reupload_image_button_label',
          },
          {
            field: 'by',
            typeHandle: 'text',
            key: 'create_editor_artwork_by',
          },
          {
            field: 'Back',
            typeHandle: 'text',
            key: 'create_layout_back',
          },
          {
            field: 'Video link',
            typeHandle: 'text',
            key: 'create_modal_video_title',
          },
          {
            field: 'Type your link here',
            typeHandle: 'text',
            key: 'create_modal_video_input_label',
          },
          {
            field: 'Only videos from Youtube and Vimeo are accepted.',
            typeHandle: 'text',
            key: 'create_modal_video_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_modal_video_cancel_button',
          },
          {
            field: 'Add',
            typeHandle: 'text',
            key: 'create_modal_video_add_button',
          },
          {
            field: 'Reorder Content',
            typeHandle: 'text',
            key: 'create_sidebar_reorder',
          },
          {
            field: 'up to',
            typeHandle: 'text',
            key: 'create_sidebar_up_to',
          },
          {
            field: 'Change your cover',
            typeHandle: 'text',
            key: 'create_sidebar_crop_change_cover',
          },
          {
            field:
              '<ul><li><strong>Do give your article a title.</strong><br />Want people to find your work through the search bar? Make sure your title reflects what your project is – getting too creative with your title doesn’t always work to your advantage.</li><li><strong>Do submit a description of your work.</strong><br />This can range from your inspiration and backstory to your technique.</li><li><strong>Do submit multiple images to showcase your work.</strong><br />This can range from WIP (work-in-progress) imagery to multiple angles of your work.</li><li><strong>Do run a grammar check on your title and description.</strong></li><li><strong>Do submit work that it watermarked.</strong><br />We strongly urge you to watermark your work with your name and social media handle or website. This way, if someone shares it without your knowledge, people will know where to find you and more of your work.</li></ul>',
            typeHandle: 'richText',
            key: 'create_warning_dos_article_text',
          },
          {
            field:
              '<ul><li><strong>Do give your work a title.</strong><br />Want people to find your work through the search bar? Make sure your title reflects what your project is – getting too creative with your title doesn’t always work to your advantage.</li><li><strong>Do submit a description of your work.</strong><br />This can range from your inspiration and backstory to your technique.</li><li><strong>Do submit multiple images to showcase your work.</strong><br />This can range from WIP (work-in-progress) imagery to multiple angles of your work.</li><li><strong>Do run a grammar check on your title and description.</strong></li><li><strong>Do submit work that it watermarked.</strong><br />We strongly urge you to watermark your work with your name and social media handle or website. This way, if someone shares it without your knowledge, people will know where to find you and more of your work.</li></ul>',
            typeHandle: 'richText',
            key: 'create_warning_dos_artwork_text',
          },
          {
            field:
              '<ul><li>\n <strong>Do give your tutorial a title. </strong>\n <br />\n Want people to find your work through the search bar? Make sure your title reflects what your project is – getting too creative with your title doesn’t always work to your advantage.\n </li>\n <li>\n <strong>Do submit a description of your work. </strong>\n <br />\n This can range from your inspiration and backstory to your\n technique.\n </li>\n <li>\n <strong>Do submit multiple images to showcase your work. </strong>\n <br />\n This can range from WIP (work-in-progress) imagery to multiple\n angles of your work.\n </li>\n <li>\n <strong>Do run a grammar check on your title and description. </strong>\n </li>\n <li>\n <strong>Do submit work that it watermarked. </strong>\n <br />\n We strongly urge you to watermark your work with your name and social media handle or website. This way, if someone shares it without your knowledge, people will know where to find you and more of your work.\n </li>\n</ul>',
            typeHandle: 'richText',
            key: 'create_warning_dos_tutorial_text',
          },
          {
            field:
              '<ul><li>\n <strong>Don’t submit a disrespectful article</strong>\n <br />\n Don’t submit work that is sexually explicit, hateful, degrading, or that includes illicit drugs, profanities, or graphic\n violence.\n </li>\n <li>\n <strong>Don’t submit a disrespectful title or description </strong>\n <br />\n Don’t submit a title or a description of your work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or violence. \n </li>\n <li>\n <strong>If your work goes against our guidelines, we will not publish it. </strong>\n </li>\n</ul>',
            typeHandle: 'richText',
            key: 'create_warning_donts_article_text',
          },
          {
            field:
              '<ul><li>\n <strong>Don’t submit disrespectful artwork</strong>\n <br />\n Don’t submit work that is sexually explicit, hateful, degrading, or that includes illicit drugs, profanities, or graphic\n violence.\n </li>\n <li>\n <strong>Don’t submit a disrespectful title or description </strong>\n <br />\n Don’t submit a title or a description of your work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or violence. \n </li>\n <li>\n <strong>If your work goes against our guidelines, we will not publish it. </strong>\n </li>\n</ul>',
            typeHandle: 'richText',
            key: 'create_warning_donts_artwork_text',
          },
          {
            field:
              '<ul><li>\n <strong>Don’t submit a disrespectful tutorial</strong>\n <br />\n Don’t submit work that is sexually explicit, hateful, degrading, or that includes illicit drugs, profanities, or graphic\n violence.\n </li>\n <li>\n <strong>Don’t submit a disrespectful title or description </strong>\n <br />\n Don’t submit a title or a description of your work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or violence. \n </li>\n <li>\n <strong>If your work goes against our guidelines, we will not publish it. </strong>\n </li>\n</ul>',
            typeHandle: 'richText',
            key: 'create_warning_donts_tutorial_text',
          },
          {
            field: 'Type',
            typeHandle: 'text',
            key: 'create_information_field_article_type_label',
          },
          {
            field: 'Select article type',
            typeHandle: 'text',
            key: 'create_information_field_article_type_placeholder',
          },
          {
            field: 'Section',
            typeHandle: 'text',
            key: 'create_information_field_article_section_label',
          },
          {
            field: 'Select article section',
            typeHandle: 'text',
            key: 'create_information_field_article_section_placeholder',
          },
          {
            field: 'Level',
            typeHandle: 'text',
            key: 'create_information_field_tutorial_level_label',
          },
          {
            field: 'Select tutorial level',
            typeHandle: 'text',
            key: 'create_information_field_tutorial_level_placeholder',
          },
          {
            field: 'Type',
            typeHandle: 'text',
            key: 'create_information_field_tutorial_type_label',
          },
          {
            field: 'Select tutorial type',
            typeHandle: 'text',
            key: 'create_information_field_tutorial_type_placeholder',
          },
          {
            field: 'Upload your image',
            typeHandle: 'text',
            key: 'create_modal_image_title',
          },
          {
            field: 'Duration',
            typeHandle: 'text',
            key: 'create_detail_tutorial_duration',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'create_sidebar',
          },
          {
            field: 'Save changes',
            typeHandle: 'text',
            key: 'create_sidebar_button_edit',
          },
          {
            field: 'Publish',
            typeHandle: 'text',
            key: 'create_sidebar_button_publish',
          },
          {
            field: 'Ok, I agree with that!',
            typeHandle: 'text',
            key: 'create_warning_rules_agreement_label',
          },
          {
            field: 'Images',
            typeHandle: 'text',
            key: 'create_editor_btn_image_title',
          },
          {
            field: 'Embed',
            typeHandle: 'text',
            key: 'create_editor_btn_embed_title',
          },
          {
            field: 'Text',
            typeHandle: 'text',
            key: 'create_editor_btn_text_title',
          },
          {
            field: 'Text block',
            typeHandle: 'text',
            key: 'create_editor_btn_text_subtitle',
          },
          {
            field: 'Youtube or Vimeo only',
            typeHandle: 'text',
            key: 'create_editor_btn_embed_subtitle',
          },
          {
            field: 'JPG, PNG, GIF...',
            typeHandle: 'text',
            key: 'create_editor_btn_image_subtitle',
          },
          {
            field:
              'This is your current cover. You can upload or select a new image from the post',
            typeHandle: 'text',
            key: 'create_crop_text_previous_cover',
          },
          {
            field:
              'Add at least one image or one video to your post to continue',
            typeHandle: 'text',
            key: 'create_disabled_next_button_artwork',
          },
          {
            field: 'Add at least one block to your post to continue',
            typeHandle: 'text',
            key: 'create_disabled_next_button_no_blocks',
          },
          {
            field:
              "Your post contains at least one empty text block. Don't forget to edit or delete it to continue.",
            typeHandle: 'text',
            key: 'create_disabled_next_button_text',
          },
          {
            field:
              "Your post contains at least one empty text block. Don't forget to edit or delete it to continue.",
            typeHandle: 'text',
            key: 'create_disabled_next_button_empty_text_block',
          },
          {
            field: 'Congratulations on publishing your artwork!',
            typeHandle: 'text',
            key: 'create_modal_title_publish',
          },
          {
            field: 'Now why not share the love with the community?',
            typeHandle: 'text',
            key: 'create_modal_content_publish',
          },
          {
            field: 'We are sorry',
            typeHandle: 'text',
            key: 'create_modal_title_nsfw',
          },
          {
            field:
              'It seems the visual or image you are trying to upload goes against our guidelines. We invite you to select another one. If you think this is a mistake, please reach us here on our support page.',
            typeHandle: 'text',
            key: 'create_modal_content_nsfw',
          },
          {
            field: 'Your artwork was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_artwork_draft_notification_title',
          },
          {
            field: 'We are sorry',
            typeHandle: 'text',
            key: 'create_artwork_nsfw_notification_title',
          },
          {
            field: 'Congratulations on publishing your article!',
            typeHandle: 'text',
            key: 'create_article_publish_notification_title',
          },
          {
            field: 'Your article was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_article_draft_notification_title',
          },
          {
            field: 'We are sorry',
            typeHandle: 'text',
            key: 'create_article_nsfw_notification_title',
          },
          {
            field: 'Congratulations on publishing your tutorial!',
            typeHandle: 'text',
            key: 'create_tutorial_publish_notification_title',
          },
          {
            field: 'Your tutorial was saved to your drafts.',
            typeHandle: 'text',
            key: 'create_tutorial_draft_notification_title',
          },
          {
            field: 'We are sorry',
            typeHandle: 'text',
            key: 'create_tutorial_nsfw_notification_title',
          },
          {
            field:
              'Your artwork was successfully saved to your drafts. You can access it via the Create toolbar.',
            typeHandle: 'text',
            key: 'create_artwork_draft_notification_text',
          },
          {
            field:
              'It seems the visual or image you are trying to upload goes against our guidelines. We invite you to please select another one. If you think this is a mistake, please reach out to us via the support page.',
            typeHandle: 'text',
            key: 'create_artwork_nsfw_notification_text',
          },
          {
            field:
              'Your article was successfully saved to your drafts. You can access it via the Create toolbar.',
            typeHandle: 'text',
            key: 'create_article_draft_notification_text',
          },
          {
            field:
              'It seems the visual or image you are trying to upload goes against our guidelines. We invite you to please select another one. If you think this is a mistake, please reach out to us via the support page.',
            typeHandle: 'text',
            key: 'create_article_nsfw_notification_text',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'create_tutorial_publish_notification_text',
          },
          {
            field:
              'Your tutorial was successfully saved to your drafts. You can access it via the Create toolbar.',
            typeHandle: 'text',
            key: 'create_tutorial_draft_notification_text',
          },
          {
            field:
              'It seems the visual or image you are trying to upload goes against our guidelines. We invite you to please select another one. If you think this is a mistake, please reach out to us via the support page.',
            typeHandle: 'text',
            key: 'create_tutorial_nsfw_notification_text',
          },
          {
            field: 'Copy link to share the love',
            typeHandle: 'text',
            key: 'create_article_publish_notification_link',
          },
          {
            field: 'Copy link to share the love',
            typeHandle: 'text',
            key: 'create_tutorial_publish_notification_link',
          },
          {
            field: 'Upload again',
            typeHandle: 'text',
            key: 'create_artwork_nsfw_notification_link',
          },
          {
            field: 'Upload again',
            typeHandle: 'text',
            key: 'create_article_nsfw_notification_link',
          },
          {
            field: 'Upload again',
            typeHandle: 'text',
            key: 'create_tutorial_nsfw_notification_link',
          },
          {
            field: 'Cover image too large',
            typeHandle: 'text',
            key: 'create_artwork_cover_too_large_notification_title',
          },
          {
            field:
              'Your cover image exceeds 10mb. Please reupload another cover image',
            typeHandle: 'text',
            key: 'create_artwork_cover_too_large_notification_text',
          },
          {
            field: 'Reupload image',
            typeHandle: 'text',
            key: 'create_artwork_cover_too_large_notification_link',
          },
          {
            field: 'Max 10mb',
            typeHandle: 'text',
            key: 'create_crop_upload_image_button_text_small',
          },
          {
            field: 'Now why not share it with the community?',
            typeHandle: 'text',
            key: 'create_article_publish_notification_text',
          },
          {
            field:
              "Your tutorial is being reviewed. You'll get a notification once it has been approved.",
            typeHandle: 'text',
            key: 'create_tutorial_pending_notification_text',
          },
          {
            field: 'Your tutorial is not public yet',
            typeHandle: 'text',
            key: 'create_tutorial_pending_notification_title',
          },
          {
            field: 'Your changes are being saved.',
            typeHandle: 'text',
            key: 'create_block_loading_text',
          },
          {
            field: '“Your',
            typeHandle: 'text',
            key: 'create_artwork_pending_notification_title',
          },
          {
            field: '“Your',
            typeHandle: 'text',
            key: 'create_article_pending_notification_title',
          },
          {
            field: '“Your',
            typeHandle: 'text',
            key: 'create_artwork_pending_notification_text',
          },
          {
            field: '“Your',
            typeHandle: 'text',
            key: 'create_article_pending_notification_text',
          },
          {
            field: 'Copy link to share the love',
            typeHandle: 'text',
            key: 'create_publish_modal_link_not_copied',
          },
          {
            field: 'Link copied',
            typeHandle: 'text',
            key: 'create_publish_modal_link_copied',
          },
          {
            field: 'Download asset',
            typeHandle: 'text',
            key: 'download_asset_label',
          },
          {
            field: 'Back to type selection?',
            typeHandle: 'text',
            key: 'create_artwork_reset_state_notification_title',
          },
          {
            field: 'All unsaved changes to your artwork will be lost.',
            typeHandle: 'text',
            key: 'create_artwork_reset_state_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_artwork_reset_state_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_artwork_reset_state_notification_link_2',
          },
          {
            field: 'Back to type selection?',
            typeHandle: 'text',
            key: 'create_tutorial_reset_state_notification_title',
          },
          {
            field: 'All unsaved changes to your tutorial will be lost.',
            typeHandle: 'text',
            key: 'create_tutorial_reset_state_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_tutorial_reset_state_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_tutorial_reset_state_notification_link_2',
          },
          {
            field: 'Back to type selection?',
            typeHandle: 'text',
            key: 'create_article_reset_state_notification_title',
          },
          {
            field: 'All unsaved changes to your article will be lost.',
            typeHandle: 'text',
            key: 'create_article_reset_state_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_article_reset_state_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_article_reset_state_notification_link_2',
          },
          {
            field: 'At least 1 industry is required.',
            typeHandle: 'text',
            key: 'at_least_one_industry',
          },
          {
            field: 'At least 1 tool is required.',
            typeHandle: 'text',
            key: 'at_least_one_tool',
          },
          {
            field: 'Title is required.',
            typeHandle: 'text',
            key: 'title_required',
          },
          {
            field: 'Please enter a duration for this tutorial.',
            typeHandle: 'text',
            key: 'enter_duration',
          },
          {
            field: 'Please enter a valid duration for this tutorial.',
            typeHandle: 'text',
            key: 'enter_valid_duration',
          },
          {
            field: 'Please select a level for this tutorial.',
            typeHandle: 'text',
            key: 'select_level',
          },
          {
            field: 'Please select a type for this tutorial.',
            typeHandle: 'text',
            key: 'select_type_tutorial',
          },
          {
            field: 'Please select a type',
            typeHandle: 'text',
            key: 'select_type',
          },
          {
            field: 'Please select a section',
            typeHandle: 'text',
            key: 'select_section',
          },
          {
            field: 'One or more required inputs are empty or contain errors.',
            typeHandle: 'text',
            key: 'create_disabled_next_button_empty_info_inputs',
          },
          {
            field: 'Admin',
            typeHandle: 'text',
            key: 'create_admin_title',
          },
          {
            field: 'Published to:',
            typeHandle: 'text',
            key: 'create_admin_published_to_label',
          },
          {
            field: 'Article type',
            typeHandle: 'text',
            key: 'create_admin_article_type_label',
          },
          {
            field: 'Required',
            typeHandle: 'text',
            key: 'create_admin_required_label',
          },
          {
            field: 'Member',
            typeHandle: 'text',
            key: 'create_admin_motm_member_label',
          },
          {
            field: 'Date',
            typeHandle: 'text',
            key: 'create_admin_motm_date_label',
          },
          {
            field: 'Authored by',
            typeHandle: 'text',
            key: 'create_admin_author_label',
          },
          {
            field: 'Publish Date',
            typeHandle: 'text',
            key: 'create_admin_published_date_label',
          },
          {
            field: 'Published',
            typeHandle: 'text',
            key: 'create_admin_published_checkbox_label',
          },
          {
            field: 'You can add up to 10 content blocks',
            typeHandle: 'text',
            key: 'create_user_content_block_title',
          },
          {
            field: 'Add content block',
            typeHandle: 'text',
            key: 'create_user_content_block_add_block_label',
          },
          {
            field:
              'It seems you’ve already added this content block. Duplicates have been removed.',
            typeHandle: 'text',
            key: 'create_user_content_removed_duplicates',
          },
          {
            field:
              'You have reached the maximum number of content blocks. Please remove some if you wish to add more.',
            typeHandle: 'text',
            key: 'create_user_content_block_max_contents',
          },
          {
            field: 'User content',
            typeHandle: 'text',
            key: 'create_editor_btn_userContent_title',
          },
          {
            field: 'Max 10.',
            typeHandle: 'text',
            key: 'create_editor_btn_userContent_subtitle',
          },
          {
            field: 'Add selected to content block',
            typeHandle: 'text',
            key: 'create_user_content_block_add_multiple_contents',
          },
          {
            field: 'Add',
            typeHandle: 'text',
            key: 'create_user_content_block_add_single_content_label',
          },
          {
            field: 'Add user content to content block?',
            typeHandle: 'text',
            key: 'create_user_content_block_add_single_content',
          },
          {
            field: 'Tutorial series',
            typeHandle: 'text',
            key: 'create_intro_tutorial_series_title',
          },
          {
            field: 'Help the community and share your experience.',
            typeHandle: 'text',
            key: 'create_intro_tutorial_series_text',
          },
          {
            field: 'Start teaching',
            typeHandle: 'text',
            key: 'create_intro_tutorial_series_button_label',
          },
          {
            field:
              '<ul>\n  <li>\n    <b>Don’t submit disrespectful tutorial series</b>\n    <br />\n    Don’t submit work that is sexually explicit, hateful, degrading\n    or that includes illicit drugs, profanities, or graphic\n    violence.\n  </li>\n  <li>\n    <b>Don’t submit a disrespectful title or description </b>\n    <br />\n    Don’t submit a title or a description of your work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or violence. \n  </li>\n  <li>\n    <b>If your work goes against our guidelines, we will not publish it. </b>\n  </li>\n</ul>',
            typeHandle: 'text',
            key: 'create_warning_donts_tutorial_series_text',
          },
          {
            field:
              '<ul>  <li>    <b>Do give your tutorial series a title. </b>    <br />    Want people to find your work through search? Make sure your    title reflects what your project is – getting too creative with your    title doesn’t always work to your advantage.  </li>  <li>    <b>Do submit a description of your work. </b>    <br />    This can range from your inspiration and backstory to your    technique.  </li>  <li>    <b>Do submit tutorials to showcase your work. </b>    <br />    This can range from WIP (work-in-progress) imagery to multiple    angles of your work.  </li>  <li>    <b>Do run a grammar check on your title and description. </b>  </li>  <li>    <b>Do submit work that it watermarked. </b>    <br />    We strongly urge you to watermark your work with your name and    social media handle or website so that if someone shares it    without your knowing, people will know where to find you and    more of your work.  </li></ul>',
            typeHandle: 'text',
            key: 'create_warning_dos_tutorial_series_text',
          },
          {
            field: 'AREA User Content',
            typeHandle: 'text',
            key: 'create_editor_btn_content_title',
          },
          {
            field: 'Add up to 10 content blocks',
            typeHandle: 'text',
            key: 'create_editor_btn_content_subtitle',
          },
          {
            field: 'AREA Tutorials',
            typeHandle: 'text',
            key: 'create_editor_btn_content_title_tutorial_series',
          },
          {
            field:
              'Your post contains at least one AREA member content block without any content',
            typeHandle: 'text',
            key: 'create_disabled_next_button_empty_user_content_block',
          },
          {
            field: 'Your post contains multiple blocks without content inside.',
            typeHandle: 'text',
            key: 'create_disabled_next_button_empty_blocks',
          },
          {
            field: 'All unsaved changes to your tutorial series will be lost.',
            typeHandle: 'text',
            key: 'create_tutorial_series_reset_state_notification_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'create_tutorial_series_reset_state_notification_link_1',
          },
          {
            field: 'Continue',
            typeHandle: 'text',
            key: 'create_tutorial_series_reset_state_notification_link_2',
          },
          {
            field: 'You can add up to 10 tutorials.',
            typeHandle: 'text',
            key: 'create_editor_btn_content_subtitle_tutorial_series',
          },
          {
            field: 'Tutorial series',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_series',
          },
          {
            field: 'Back to type selection?',
            typeHandle: 'text',
            key: 'create_tutorial_series_reset_state_notification_title',
          },
          {
            field:
              'The author has changed and this content will be listed on the Inspire page.',
            typeHandle: 'text',
            key: 'create_different_author_inspire',
          },
          {
            field:
              'The author of this content has changed and will only be editable by the new author.',
            typeHandle: 'text',
            key: 'create_different_author',
          },
          {
            field: 'Congratulations on publishing a tutorial series!',
            typeHandle: 'text',
            key: 'create_tutorial_series_publish_notification_title',
          },
          {
            field: 'Now why not share the love with the community?',
            typeHandle: 'text',
            key: 'create_tutorial_series_publish_notification_text',
          },
          {
            field:
              'You’re not the author of this content but as an admin you can edit it.',
            typeHandle: 'text',
            key: 'edit_create_admin_different_author',
          },
          {
            field:
              'You’re not authorized to edit this content. You’ve been automatically redirected.',
            typeHandle: 'text',
            key: 'edit_create_different_author_unauthorized',
          },
          {
            field: 'AREA',
            typeHandle: 'text',
            key: 'create_detail_internal_user_label',
          },
          {
            field:
              'If no section is selected the article will only published to the user’s profile.',
            typeHandle: 'text',
            key: 'create_admin_published_to_section_instruction',
          },
          {
            field: 'Current member:',
            typeHandle: 'text',
            key: 'create_admin_motm_member_selected_label',
          },
          {
            field: 'Select member',
            typeHandle: 'text',
            key: 'create_admin_motm_member_select_button_label',
          },
          {
            field: 'You must add a cover image to your post to publish it.',
            typeHandle: 'text',
            key: 'create_disabled_next_button_no_cover',
          },
          {
            field:
              'As you’ve selected a member for this article, the title has been replaced with their name. You can still edit the title as you wish',
            typeHandle: 'text',
            key: 'create_admin_select_motm_member',
          },
          {
            field: 'My drafts',
            typeHandle: 'text',
            key: 'create_draft_label',
          },
          {
            field: 'Create',
            typeHandle: 'text',
            key: 'page_create_title',
          },
          {
            field:
              'What are we building today? Select the type of content you want to create.',
            typeHandle: 'text',
            key: 'page_create_intro',
          },
          {
            field: 'Asset',
            typeHandle: 'text',
            key: 'create_intro_asset_title',
          },
          {
            field: 'Upload textures, 3D models, plugins & more.',
            typeHandle: 'text',
            key: 'create_intro_asset_text',
          },
          {
            field: 'Member Spotlight',
            typeHandle: 'text',
            key: 'create_detail_type_motm',
          },
          {
            field: 'Member of the Year',
            typeHandle: 'text',
            key: 'create_detail_type_moty',
          },
          {
            field: 'Like it!',
            typeHandle: 'text',
            key: 'create_header_like_it',
          },
          {
            field: 'Unlike it!',
            typeHandle: 'text',
            key: 'create_header_unlike_it',
          },
          {
            field: 'Add to favorites',
            typeHandle: 'text',
            key: 'create_header_add_favorites',
          },
          {
            field: 'Remove from favorites',
            typeHandle: 'text',
            key: 'create_header_remove_favorites',
          },
          {
            field: 'Copy link to content',
            typeHandle: 'text',
            key: 'create_header_copy_link',
          },
          {
            field: 'Report content',
            typeHandle: 'text',
            key: 'create_header_report',
          },
        ],
      },
      {
        strings: [
          {
            field: "Can't see the email?",
            typeHandle: 'text',
            key: 'forgot_password_success_text_resend',
          },
          {
            field: 'Send it again.',
            typeHandle: 'text',
            key: 'forgot_password_success_text_resend_label',
          },
          {
            field: 'Forgot password?',
            typeHandle: 'text',
            key: 'forgot_password_label',
          },
          {
            field: 'Email address',
            typeHandle: 'text',
            key: 'forgot_password_email_label',
          },
          {
            field:
              'Enter the email address associated with your account. We will send you a secure link to reset your password.',
            typeHandle: 'richText',
            key: 'forgot_password_text',
          },
          {
            field: 'Forgot your password?',
            typeHandle: 'text',
            key: 'forgot_password_title',
          },
          {
            field: 'Send me a link.',
            typeHandle: 'text',
            key: 'forgot_password_button_label',
          },
          {
            field:
              'Check your email. You will get a link to change your password.',
            typeHandle: 'text',
            key: 'forgot_password_success_text',
          },
          {
            field: 'Email has been sent.',
            typeHandle: 'text',
            key: 'forgot_password_success_title',
          },
          {
            field: 'Back to home.',
            typeHandle: 'text',
            key: 'forgot_password_success_button_label',
          },
          {
            field: 'We could not send the link to your email.',
            typeHandle: 'text',
            key: 'forgot_password_error_send_link_error',
          },
          {
            field: 'Please enter a valid email.',
            typeHandle: 'text',
            key: 'forgot_password_error_valid_email',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Change your password',
            typeHandle: 'text',
            key: 'change_password_page_title',
          },
          {
            field: 'Change password',
            typeHandle: 'text',
            key: 'change_password_button_label',
          },
          {
            field: 'Confirm password',
            typeHandle: 'text',
            key: 'change_password_confirmation_label',
          },
          {
            field: 'New password',
            typeHandle: 'text',
            key: 'change_password_label',
          },
          {
            field: 'Enter new password.',
            typeHandle: 'text',
            key: 'change_password_title',
          },
          {
            field: 'Back to login',
            typeHandle: 'text',
            key: 'change_password_success_button_label',
          },
          {
            field: 'Your password has been changed.',
            typeHandle: 'text',
            key: 'change_password_success_text',
          },
          {
            field: 'An error has occured. Try again later.',
            typeHandle: 'text',
            key: 'change_password_error_token_required',
          },
          {
            field: 'An error has occured. Try again later.',
            typeHandle: 'text',
            key: 'change_password_error_general',
          },
          {
            field: "Passwords doesn't match",
            typeHandle: 'text',
            key: 'change_password_error_password_not_match',
          },
          {
            field:
              'Password must contain at least 8 characters, one uppercase, one number and one special case character',
            typeHandle: 'text',
            key: 'change_password_error_password_requirements',
          },
          {
            field: 'Please enter a valid password.',
            typeHandle: 'text',
            key: 'change_password_error_valid_password',
          },
          {
            field:
              'Token validation failed, if the token has expired you can get a new one at the',
            typeHandle: 'text',
            key: 'change_password_validation_error',
          },
          {
            field: 'forgot password page',
            typeHandle: 'text',
            key: 'change_password_validation_link_label',
          },
          {
            field: 'Validating token...',
            typeHandle: 'text',
            key: 'change_password_validation_token',
          },
        ],
      },
      {
        strings: [
          {
            field: 'About this group',
            typeHandle: 'text',
            key: 'community_groups_info_about_label',
          },
          {
            field: 'Created',
            typeHandle: 'text',
            key: 'community_groups_info_created_label',
          },
          {
            field: 'See group rules',
            typeHandle: 'text',
            key: 'community_groups_info_rules_label',
          },
          {
            field: 'Members',
            typeHandle: 'text',
            key: 'community_groups_info_members_label',
          },
          {
            field: 'Moderators',
            typeHandle: 'text',
            key: 'community_groups_info_moderators_label',
          },
          {
            field:
              'A community for FX Professionals including Visual Arts, Modeling, Texturing and Game design to network, and collaborate, share ideas, etc.',
            typeHandle: 'text',
            key: 'community_groups_info_description',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Groups',
            typeHandle: 'text',
            key: 'groups_page_title',
          },
          {
            field:
              'Social communities focused on products, industries, and specialties. All are welcome to talk about their work as a career, a hobby, or just an enthusiast starting out. Tools, techniques, & career talk – let’s make this our AREA!',
            typeHandle: 'text',
            key: 'groups_page_intro',
          },
        ],
      },
      {},
      {
        strings: [
          {
            field: 'Articles',
            typeHandle: 'text',
            key: 'page_learn_articles_title',
          },
          {
            field:
              'Get inspired with articles featuring artist interviews, career advice, industry news, and more.',
            typeHandle: 'text',
            key: 'page_learn_articles_intro',
          },
          {
            field: 'Learn more',
            typeHandle: 'text',
            key: 'page_learn_articles_links_section_title',
          },
        ],
      },
      {
        strings: [
          {
            field:
              'Explore artwork created by 3D artists using 3ds Max, Maya, Arnold, Flame, MotionBuilder, and more.',
            typeHandle: 'text',
            key: 'page_inspire_gallery_intro',
          },
          {
            field: 'Explore more',
            typeHandle: 'text',
            key: 'page_inspire_links_section_title',
          },
          {
            field: 'Gallery',
            typeHandle: 'text',
            key: 'page_inspire_gallery_title',
          },
          {
            field: 'Gallery',
            typeHandle: 'text',
            key: 'page_inspire_gallery_title',
          },
          {
            field: 'Explore more',
            typeHandle: 'text',
            key: 'page_inspire_links_section_title',
          },
          {
            field:
              'Explore artwork created by 3D artists using 3ds Max, Maya, Arnold, Flame, MotionBuilder, and more.',
            typeHandle: 'text',
            key: 'page_inspire_gallery_intro',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Already have an account?',
            typeHandle: 'text',
            key: 'sign_up_have_an_account',
          },
          {
            field: 'Join AREA today!',
            typeHandle: 'text',
            key: 'sign_up_title',
          },
          {
            field: 'Sign in with Apple',
            typeHandle: 'text',
            key: 'sign_up_apple_button_label',
          },
          {
            field: 'Sign in with Google',
            typeHandle: 'text',
            key: 'sign_up_google_button_label',
          },
          {
            field: 'Sign in',
            typeHandle: 'text',
            key: 'sign_up_have_an_account_link_label',
          },
          {
            field: 'Sign in with Linkedin',
            typeHandle: 'text',
            key: 'sign_up_linkedin_button_label',
          },
          {
            field: 'First Name',
            typeHandle: 'text',
            key: 'sign_up_form_first_name',
          },
          {
            field: 'Last Name',
            typeHandle: 'text',
            key: 'sign_up_form_last_name',
          },
          {
            field:
              'To ensure that AREA is great community full of real people, we kindly ask you take an extra step to validate you exist!',
            typeHandle: 'text',
            key: 'sign_up_form_verification_text',
          },
          {
            field: 'Account verification will be required.',
            typeHandle: 'text',
            key: 'sign_up_form_verification_title',
          },
          {
            field: 'Email Address',
            typeHandle: 'text',
            key: 'sign_up_form_email_address',
          },
          {
            field: 'Password',
            typeHandle: 'text',
            key: 'sign_up_form_password',
          },
          {
            field: 'Password Confirmation',
            typeHandle: 'text',
            key: 'sign_up_form_password_confirmation',
          },
          {
            field: 'Create My Account',
            typeHandle: 'text',
            key: 'sign_up_form_button_label',
          },
          {
            field:
              'I agree to the Autodesk Terms of Use and acknowledge the Privacy Statement.',
            typeHandle: 'text',
            key: 'sign_up_form_terms',
          },
          {
            field:
              '<p>• At least 8 characters.<br />• A mixture of both uppercase and lowercase letters.<br />• A mixture of letters and numbers.<br />• Inclusion of at least one special character. e.g., !, @, #, ?, ]</p>',
            typeHandle: 'richText',
            key: 'sign_up_password_instruction',
          },
          {
            field: 'We just sent an email to',
            typeHandle: 'text',
            key: 'sign_up_confirmation_text_line1',
          },
          {
            field: 'Thank you!',
            typeHandle: 'text',
            key: 'sign_up_confirmation_title',
          },
          {
            field: 'Send it again.',
            typeHandle: 'text',
            key: 'sign_up_confirmation_send_again',
          },
          {
            field: 'Email didn’t find its way?',
            typeHandle: 'text',
            key: 'sign_up_confirmation_text_line2',
          },
          {
            field: 'Change the email address associated with this account.',
            typeHandle: 'text',
            key: 'sign_up_confirmation_change_email_label',
          },
          {
            field:
              '<p>Join AREA by Autodesk<br /> AREA is your online community for the fields of animation, games, film and TV, VFX, design visualization, and advertising.</p>',
            typeHandle: 'richText',
            key: 'sign_up_aside_title',
          },
          {
            field:
              'Connect with your peers | Get inspired | Learn new workflows and tools',
            typeHandle: 'text',
            key: 'sign_up_aside_text',
          },
          {
            field: 'Please enter a valid email.',
            typeHandle: 'text',
            key: 'sign_up_error_valid_email',
          },
          {
            field: 'Please enter a valid password.',
            typeHandle: 'text',
            key: 'sign_up_error_valid_password',
          },
          {
            field: 'Please enter a valid first name.',
            typeHandle: 'text',
            key: 'sign_up_error_valid_first_name',
          },
          {
            field: 'Please enter a valid last name.',
            typeHandle: 'text',
            key: 'sign_up_error_valid_last_name',
          },
          {
            field: 'The password must contain at least 8 characters.',
            typeHandle: 'text',
            key: 'sign_up_error_password_length',
          },
          {
            field: 'You must accept the terms and conditions.',
            typeHandle: 'text',
            key: 'sign_up_error_term_accept',
          },
          {
            field: "Passwords don't match.",
            typeHandle: 'text',
            key: 'sign_up_error_password_not_match',
          },
          {
            field: 'Could not register user.',
            typeHandle: 'text',
            key: 'sign_up_error_could_not_register',
          },
          {
            field:
              'Password must contain at least 8 characters, one uppercase, one number and one special case character.',
            typeHandle: 'text',
            key: 'sign_up_error_password_requirements',
          },
          {
            field: 'Please confirm your password.',
            typeHandle: 'text',
            key: 'sign_up_error_confirm_password',
          },
          {
            field: 'Please check your inbox.',
            typeHandle: 'text',
            key: 'sign_up_confirmation_text_line4',
          },
          {
            field: 'We sent a new confirmation e-mail to',
            typeHandle: 'text',
            key: 'sign_up_confirmation_text_line3',
          },
          {
            field: 'This email is already taken.',
            typeHandle: 'text',
            key: 'sign_up_error_email_taken',
          },
          {
            field: 'An error has occured, please try again later.',
            typeHandle: 'text',
            key: 'sign_up_error_verification_resend',
          },
          {
            field: 'Change phone number.',
            typeHandle: 'text',
            key: 'verify_sms_code_button_back',
          },
          {
            field: 'Sign in here.',
            typeHandle: 'text',
            key: 'sign_up_error_email_taken_goto_sign_in',
          },
          {
            field: 'Complete your profile and unlock the full AREA experience.',
            typeHandle: 'text',
            key: 'sign_up_description',
          },
          {
            field: 'Joining Groups',
            typeHandle: 'text',
            key: 'sign_up_features_1',
          },
          {
            field: 'Commenting, liking, sharing',
            typeHandle: 'text',
            key: 'sign_up_features_2',
          },
          {
            field: 'Create and upload content',
            typeHandle: 'text',
            key: 'sign_up_features_3',
          },
          {
            field: 'Join AREA today!',
            typeHandle: 'text',
            key: 'join_area_label',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Tutorials',
            typeHandle: 'text',
            key: 'page_learn_section_tutorials',
          },
          {
            field: 'Assets',
            typeHandle: 'text',
            key: 'page_learn_section_assets',
          },
          {
            field: 'More articles',
            typeHandle: 'text',
            key: 'learn_more_articles',
          },
        ],
      },
      {
        strings: [],
      },
      {},
      {
        strings: [
          {
            field: 'Inspire',
            typeHandle: 'text',
            key: 'inspire_heroData_heading',
          },
          {
            field:
              'Discover beautiful artwork, industry articles, career advice, and artist features',
            typeHandle: 'text',
            key: 'inspire_heroData_subHeading',
          },
          {
            field: 'Gallery',
            typeHandle: 'text',
            key: 'inspire_gallery_section_title',
          },
          {
            field: 'Articles',
            typeHandle: 'text',
            key: 'inspire_articles_section_title',
          },
          {
            field: 'Member spotlight',
            typeHandle: 'text',
            key: 'inspire_mom_section_title',
          },
        ],
      },
      {},
      {},
      {},
      {
        strings: [
          {
            field: 'Let’s make sure you’re who you say you are.',
            typeHandle: 'text',
            key: 'sign_up_validation_title',
          },
          {
            field:
              'To ensure a trusted environment on AREA, please verify your account.',
            typeHandle: 'text',
            key: 'sign_up_validation_text',
          },
          {
            field: 'Verify with linkedin',
            typeHandle: 'text',
            key: 'sign_up_validation_linkedin_button',
          },
          {
            field: 'Verify with phone',
            typeHandle: 'text',
            key: 'sign_up_validation_phone_button',
          },
          {
            field: 'We have sent a link to',
            typeHandle: 'text',
            key: 'sign_up_validation_message_1',
          },
          {
            field: 'Please follow the instructions to validate your email.',
            typeHandle: 'text',
            key: 'sign_up_validation_message_2',
          },
          {
            field: 'Welcome to AREA',
            typeHandle: 'text',
            key: 'sign_up_validation_welcome',
          },
          {
            field: 'Let’s setup your account!',
            typeHandle: 'text',
            key: 'sign_up_validation_sideBarTitle',
          },
          {
            field:
              "Haven't received the verification email? Check your spams or",
            typeHandle: 'text',
            key: 'sign_up_validation_message_3',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Let’s setup \nyour account!',
            typeHandle: 'text',
            key: 'onboarding_title_step1',
          },
          {
            field: 'Save and Continue',
            typeHandle: 'text',
            key: 'save_and_continue_btn',
          },
          {
            field: 'Good Job',
            typeHandle: 'text',
            key: 'onboarding_good_job',
          },
          {
            field: "All set, let's go!",
            typeHandle: 'text',
            key: 'onboarding_welcome_final_cta',
          },
          {
            field: 'SKIP ONBOARDING',
            typeHandle: 'text',
            key: 'onboarding_skip',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Welcome back to',
            typeHandle: 'text',
            key: 'aside_generic_subtitle',
          },
          {
            field: '© Copyright 2022 Autodesk Inc. All rights reserved',
            typeHandle: 'text',
            key: 'footer_copyright',
          },
          {
            field: 'Get AREA in your inbox',
            typeHandle: 'text',
            key: 'footer_newsletter_title',
          },
          {
            field: 'Subscribe to our monthly newsletter',
            typeHandle: 'text',
            key: 'footer_newsletter_description',
          },
          {
            field:
              'Thank you for subscribing to the AREA by Autodesk newsletter.',
            typeHandle: 'text',
            key: 'footer_newsletter_thank_you',
          },
          {
            field: 'What tool(s) are vital to your workflow?',
            typeHandle: 'text',
            key: 'profile_edit_tools_title',
          },
          {
            field:
              'Choose as many that apply, with a maximum of twelve. e.g. “3ds Max”',
            typeHandle: 'text',
            key: 'profile_edit_tools_text',
          },
          {
            field: 'Which industries appeal to you most?',
            typeHandle: 'text',
            key: 'profile_edit_industries_title',
          },
          {
            field: 'You must choose at least one.',
            typeHandle: 'text',
            key: 'profile_edit_industries_text',
          },
          {
            field: 'Edit profile',
            typeHandle: 'text',
            key: 'profile_edit_avatar_upload_title',
          },
          {
            field: 'Upload your best picture',
            typeHandle: 'text',
            key: 'profile_edit_avatar_upload_text',
          },
          {
            field: 'Upload a picture',
            typeHandle: 'text',
            key: 'profile_edit_avatar_crop_title',
          },
          {
            field: 'Crop the picture as you want to present on your profile',
            typeHandle: 'text',
            key: 'profile_edit_avatar_crop_text',
          },
          {
            field:
              'We support JPG, GIF or PNG. Max file size is 20mb. Max file resolution is 512x512.',
            typeHandle: 'text',
            key: 'profile_edit_avatar_crop_instruction',
          },
          {
            field: 'Choose file',
            typeHandle: 'text',
            key: 'profile_edit_avatar_crop_button',
          },
          {
            field: 'Join',
            typeHandle: 'text',
            key: 'groups_join',
          },
          {
            field: 'Pending',
            typeHandle: 'text',
            key: 'groups_join_pending',
          },
          {
            field:
              'Upload an image with minimum of 400px for the width and height.',
            typeHandle: 'text',
            key: 'profile_edit_avatar_crop_error_size',
          },
          {
            field: 'Article by',
            typeHandle: 'text',
            key: 'thumbnail_article_by',
          },
          {
            field: 'Article',
            typeHandle: 'text',
            key: 'thumbnail_article',
          },
          {
            field: 'Tutorial by',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_by',
          },
          {
            field: 'Tutorial series by',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_serie_by',
          },
          {
            field: 'Tutorial',
            typeHandle: 'text',
            key: 'thumbnail_tutorial',
          },
          {
            field: 'Beginner',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_level_beginner',
          },
          {
            field: 'tutorials',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_series_title',
          },
          {
            field: 'Tutorial series',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_serie',
          },
          {
            field: 'Created by',
            typeHandle: 'text',
            key: 'thumbnail_created_by',
          },
          {
            field: 'Get the AREA mobile app now!',
            typeHandle: 'text',
            key: 'mobile_nav_get_app',
          },
          {
            field: 'https://www.apple.com/ca/app-store/',
            typeHandle: 'text',
            key: 'mobile_nav_apple_store_link',
          },
          {
            field: 'https://play.google.com/store',
            typeHandle: 'text',
            key: 'mobile_nav_google_store_link',
          },
          {
            field: 'See all',
            typeHandle: 'text',
            key: 'general_see_all',
          },
          {
            field: 'See more',
            typeHandle: 'text',
            key: 'general_see_more',
          },
          {
            field: 'Sign in',
            typeHandle: 'text',
            key: 'general_sign_in',
          },
          {
            field: 'Join now',
            typeHandle: 'text',
            key: 'general_join_now',
          },
          {
            field: 'Write your comment',
            typeHandle: 'text',
            key: 'write_comment_placeholder',
          },
          {
            field: 'Save',
            typeHandle: 'text',
            key: 'general_save_btn_text',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'general_cancel_btn_text',
          },
          {
            field: 'filters',
            typeHandle: 'text',
            key: 'general_filters_btn_text',
          },
          {
            field: 'See previous comments',
            typeHandle: 'text',
            key: 'general_previous_comments_text',
          },
          {
            field: 'replies',
            typeHandle: 'text',
            key: 'general_replies_text',
          },
          {
            field: 'Report',
            typeHandle: 'text',
            key: 'general_report_comments_text',
          },
          {
            field: 'Find help or flag this comment',
            typeHandle: 'text',
            key: 'general_flag_comments_text',
          },
          {
            field: 'Delete comment',
            typeHandle: 'text',
            key: 'general_delete_comments_text',
          },
          {
            field: 'Suggested',
            typeHandle: 'text',
            key: 'general_suggested_text',
          },
          {
            field: 'Good morning',
            typeHandle: 'text',
            key: 'profile_morning_greetings',
          },
          {
            field: 'My profile',
            typeHandle: 'text',
            key: 'profile_my_profile',
          },
          {
            field: 'Configuration and preferences',
            typeHandle: 'text',
            key: 'profile_configuration_preferences',
          },
          {
            field: 'Account settings',
            typeHandle: 'text',
            key: 'profile_account_settings',
          },
          {
            field: 'Profile settings',
            typeHandle: 'text',
            key: 'profile_settings',
          },
          {
            field: 'My Groups',
            typeHandle: 'text',
            key: 'general_my_groups',
          },
          {
            field: 'Favorites',
            typeHandle: 'text',
            key: 'general_favorites',
          },
          {
            field: 'FEATURED',
            typeHandle: 'text',
            key: 'group_right_sidebar_featured_thumbnails_title',
          },
          {
            field: 'Report this group',
            typeHandle: 'text',
            key: 'group_header_report_group',
          },
          {
            field: 'Leave this group',
            typeHandle: 'text',
            key: 'group_header_leave_group',
          },
          {
            field: 'Articles',
            typeHandle: 'text',
            key: 'page_inspire_articles_section_title',
          },
          {
            field: 'NEXT',
            typeHandle: 'text',
            key: 'pagination_next',
          },
          {
            field: 'PREV',
            typeHandle: 'text',
            key: 'pagination_prev',
          },
          {
            field: 'How do I create tutorials?',
            typeHandle: 'text',
            key: 'how_to_create_tutorial',
          },
          {
            field: 'Read our guidelines',
            typeHandle: 'text',
            key: 'read_guidelines',
          },
          {
            field: 'Delete?',
            typeHandle: 'text',
            key: 'delete_modal_title',
          },
          {
            field: 'Are you sure you want to delete this?',
            typeHandle: 'text',
            key: 'delete_modal_first_paragraph',
          },
          {
            field: 'It cannot be undone!',
            typeHandle: 'text',
            key: 'delete_modal_second_paragraph',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'delete_modal_cancel_button',
          },
          {
            field: 'Yes, delete',
            typeHandle: 'text',
            key: 'delete_modal_confirm_button',
          },
          {
            field: 'Approve',
            typeHandle: 'text',
            key: 'general_approve_text',
          },
          {
            field: 'Reject',
            typeHandle: 'text',
            key: 'general_reject_text',
          },
          {
            field: 'Approve all',
            typeHandle: 'text',
            key: 'general_approve_all_text',
          },
          {
            field: 'New',
            typeHandle: 'text',
            key: 'general_new_text',
          },
          {
            field: 'Intermediate',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_level_intermediate',
          },
          {
            field: 'Expert',
            typeHandle: 'text',
            key: 'thumbnail_tutorial_level_expert',
          },
          {
            field: 'Confirm actions?',
            typeHandle: 'text',
            key: 'table_bulk_operation_title',
          },
          {
            field: 'Confirm',
            typeHandle: 'text',
            key: 'table_bulk_operation_confirm',
          },
          {
            field: 'Cancel',
            typeHandle: 'text',
            key: 'table_bulk_operation_cancel',
          },
          {
            field: 'Profile settings',
            typeHandle: 'text',
            key: 'account_settings',
          },
          {
            field: 'Change Picture',
            typeHandle: 'text',
            key: 'general_change_picture_btn_text',
          },
          {
            field: 'Recent',
            typeHandle: 'text',
            key: 'general_recent_filter_text',
          },
          {
            field: 'Popular',
            typeHandle: 'text',
            key: 'general_popular_filter_text',
          },
          {
            field: 'See previous replies',
            typeHandle: 'text',
            key: 'general_previous_replies_text',
          },
          {
            field: 'Type your search here',
            typeHandle: 'text',
            key: 'advanced_filters_search_placeholder',
          },
          {
            field: 'ADVANCED FILTERS',
            typeHandle: 'text',
            key: 'advanced_filters_button_label',
          },
          {
            field: 'Apply Filters',
            typeHandle: 'text',
            key: 'advanced_filters_button_submit_label',
          },
          {
            field: 'Are you sure you want to select this content?',
            typeHandle: 'text',
            key: 'admin_confirm_select_user_content_label',
          },
          {
            field: 'No result found',
            typeHandle: 'text',
            key: 'noResult_text',
          },
          {
            field: 'Like',
            typeHandle: 'text',
            key: 'general_like_text',
          },
          {
            field: 'Introduction to Maya',
            typeHandle: 'text',
            key: 'courses_course1_title',
          },
          {
            field: 'Introduction to Maya',
            typeHandle: 'text',
            key: 'courses_course2_title',
          },
          {
            field:
              'Looking for a 3ds Max course that takes you from A-to-Z? From finding your way atound the interface, to pipeline integration, and eveything in between - get up to speed in 3ds Max.',
            typeHandle: 'text',
            key: 'courses_course1_description',
          },
          {
            field:
              'Looking for a 3ds Max course that takes you from A-to-Z? From finding your way atound the interface, to pipeline integration, and eveything in between - get up to speed in 3ds Max. Discover the menus, options, tools, and controls that are commonly used in Maya.',
            typeHandle: 'text',
            key: 'courses_course2_description',
          },
          {
            field: 'Assets',
            typeHandle: 'text',
            key: 'courses_thumbnail1_title',
          },
          {
            field:
              'Download free textures, scripts, shaders, plugins, and more.',
            typeHandle: 'text',
            key: 'courses_thumbnail1_description',
          },
          {
            field: 'Tutorials',
            typeHandle: 'text',
            key: 'courses_thumbnail2_title',
          },
          {
            field:
              'Free tutorials for everyone from beginners to advanced users.',
            typeHandle: 'text',
            key: 'courses_thumbnail2_description',
          },
          {
            field: 'https://google.com/',
            typeHandle: 'text',
            key: 'courses_course1_take_course_url',
          },
          {
            field: 'https://google.com/',
            typeHandle: 'text',
            key: 'courses_course2_take_course_url',
          },
          {
            field: 'https://google.com/',
            typeHandle: 'text',
            key: 'courses_course1_get_asset_url',
          },
          {
            field: 'https://google.com/',
            typeHandle: 'text',
            key: 'courses_course2_get_asset_url',
          },
          {
            field: 'Take the course',
            typeHandle: 'text',
            key: 'courses_take_course',
          },
          {
            field: 'Get the assets',
            typeHandle: 'text',
            key: 'courses_get_assets',
          },
          {
            field: 'Pending',
            typeHandle: 'text',
            key: 'thumbnail_pending_user_content',
          },
          {
            field: 'A whole new experience',
            typeHandle: 'text',
            key: 'app_banner_title',
          },
          {
            field:
              'The AREA app, the best way to stay up to date with the 3D world',
            typeHandle: 'text',
            key: 'app_banner_text_1',
          },
          {
            field: 'Available on App Store',
            typeHandle: 'text',
            key: 'get_on_appstore_text',
          },
          {
            field: 'Available on Google Play',
            typeHandle: 'text',
            key: 'get_on_playstore_text',
          },
          {
            field: 'Add a tutorial',
            typeHandle: 'text',
            key: 'tutorial_series_selector_title',
          },
          {
            field: 'Artwork',
            typeHandle: 'text',
            key: 'thumbnail_artwork',
          },
          {
            field: 'Creation date',
            typeHandle: 'text',
            key: 'select_user_content_creation_date',
          },
          {
            field: 'Content Author',
            typeHandle: 'text',
            key: 'select_user_content_content_author',
          },
          {
            field: 'Content',
            typeHandle: 'text',
            key: 'select_user_content_content',
          },
          {
            field: 'Learn',
            typeHandle: 'text',
            key: 'select_user_content_learn_things',
          },
          {
            field: 'Select featured content',
            typeHandle: 'text',
            key: 'select_user_content_select_content',
          },
          {
            field: 'https://www.instagram.com/autodesk_me/',
            typeHandle: 'text',
            key: 'footer_social_instagram_url',
          },
          {
            field:
              'https://www.linkedin.com/showcase/autodesk-media-&-entertainment/',
            typeHandle: 'text',
            key: 'footer_social_linkedin_url',
          },
          {
            field: 'https://www.youtube.com/user/3dsMaxHowTos',
            typeHandle: 'text',
            key: 'footer_social_youtube_3ds_url',
          },
          {
            field: 'https://www.youtube.com/user/MayaHowTos',
            typeHandle: 'text',
            key: 'footer_social_youtube_maya_url',
          },
          {
            field: 'https://www.youtube.com/user/FlameHowTos',
            typeHandle: 'text',
            key: 'footer_social_youtube_flame_url',
          },
          {
            field: 'Likes',
            typeHandle: 'text',
            key: 'admin_cms_likes_text',
          },
          {
            field: "Editor's Pick",
            typeHandle: 'text',
            key: 'admin_cms_editors_pick_text',
          },
          {
            field: 'Yes',
            typeHandle: 'text',
            key: 'admin_cms_yes_text',
          },
          {
            field: 'No',
            typeHandle: 'text',
            key: 'admin_cms_no_text',
          },
          {
            field: 'Member since',
            typeHandle: 'text',
            key: 'group_member_since',
          },
          {
            field: 'Complete my profile',
            typeHandle: 'text',
            key: 'complete_profile_text',
          },
          {
            field: "This post has either been deleted or doesn't exist.",
            typeHandle: 'text',
            key: 'deleted_post_error',
          },
          {
            field: "This post has either been deleted or doesn't exist.",
            typeHandle: 'text',
            key: 'deleted_post_error_modal',
          },
          {
            field: 'View public profile',
            typeHandle: 'text',
            key: 'view_pblic_profile_text',
          },
          {
            field: 'Edit my profile',
            typeHandle: 'text',
            key: 'edit_my_profile_text',
          },
          {
            field: 'See more tutorials',
            typeHandle: 'text',
            key: 'learn_see_more_tutorials',
          },
          {
            field: 'Courses',
            typeHandle: 'text',
            key: 'learn_freshen_skills',
          },
          {
            field: 'Copied to clipboard',
            typeHandle: 'text',
            key: 'copied_to_clipboard_text',
          },
          {
            field: 'Edit your profile',
            typeHandle: 'text',
            key: 'profile_edit_profile_text',
          },
          {
            field: 'Social',
            typeHandle: 'text',
            key: 'profile_edit_social_text',
          },
          {
            field: 'Add socials links to your profile',
            typeHandle: 'text',
            key: 'profile_edit_add_socials_links_text',
          },
          {
            field: 'Navigation',
            typeHandle: 'text',
            key: 'profile_edit_navigation_text',
          },
          {
            field: 'Choose the first tab on your profile',
            typeHandle: 'text',
            key: 'profile_edit_choose_first_tab_text',
          },
          {
            field: 'Resume',
            typeHandle: 'text',
            key: 'profile_resume_tab_text',
          },
          {
            field: 'Required',
            typeHandle: 'text',
            key: 'input_required_text',
          },
          {
            field: 'Artwork by',
            typeHandle: 'text',
            key: 'thumbnail_artwork_by',
          },
          {
            field: 'Please fill in the title field',
            typeHandle: 'text',
            key: 'cms_empty_title_field',
          },
          {
            field: 'Please fill in the description field',
            typeHandle: 'text',
            key: 'cms_empty_description_field',
          },
          {
            field: 'Write your amazing post',
            typeHandle: 'text',
            key: 'write_amazing_post_placeholder',
          },
          {
            field: "You haven't added any artwork to Favorites yet",
            typeHandle: 'text',
            key: 'noResult_text_in_favorite_artwork',
          },
          {
            field: "You haven't added any articles to Favorites yet",
            typeHandle: 'text',
            key: 'noResult_text_in_favorite_article',
          },
          {
            field: "You haven't added any tutorials to Favorites yet",
            typeHandle: 'text',
            key: 'noResult_text_in_favorite_tutorial',
          },
          {
            field: "You haven't added any posts to Favorites yet",
            typeHandle: 'text',
            key: 'noResult_text_in_favorite_post',
          },
          {
            field:
              "You haven't added any Member of the Month posts to Favorites yet",
            typeHandle: 'text',
            key: 'noResult_text_in_favorite_motm',
          },
          {
            field: 'Approved',
            typeHandle: 'text',
            key: 'general_approved_text',
          },
          {
            field: 'Pending',
            typeHandle: 'text',
            key: 'general_pending_text',
          },
          {
            field: 'Rejected',
            typeHandle: 'text',
            key: 'general_rejected_text',
          },
          {
            field: 'Reported',
            typeHandle: 'text',
            key: 'general_reported_text',
          },
          {
            field: 'Please fill in the button label field',
            typeHandle: 'text',
            key: 'cms_no_banner_btnLabel',
          },
          {
            field: 'Please fill in the button url field',
            typeHandle: 'text',
            key: 'cms_no_banner_btnUrl',
          },
          {
            field: 'Edit your post',
            typeHandle: 'text',
            key: 'edit_your_post_label',
          },
          {
            field: 'Edit your comment',
            typeHandle: 'text',
            key: 'edit_your_comment_label',
          },
          {
            field: 'New date',
            typeHandle: 'text',
            key: 'change_date_label',
          },
          {
            field: 'Select as new author',
            typeHandle: 'text',
            key: 'select_new_author',
          },
          {
            field: 'Select as Member of the Month',
            typeHandle: 'text',
            key: 'select_motm',
          },
          {
            field: 'Secondary featured user content',
            typeHandle: 'text',
            key: 'content_page_inspire_section_featured_userContent_title',
          },
          {
            field: 'Select content',
            typeHandle: 'text',
            key:
              'content_page_inspire_section_featured_usercontent_button_label',
          },
          {
            field: 'Member Spotlight',
            typeHandle: 'text',
            key: 'thumbnail_motm',
          },
          {
            field: 'Member of the year',
            typeHandle: 'text',
            key: 'thumbnail_moty',
          },
          {
            field: 'Asset',
            typeHandle: 'text',
            key: 'thumbnail_asset',
          },
          {
            field: 'Artwork by',
            typeHandle: 'text',
            key: 'page_artwork_credits',
          },
          {
            field: 'URL is required when adding a credit to an artist',
            typeHandle: 'text',
            key: 'cms_no_credits_url_with_credits_name',
          },
          {
            field: 'Invalid URL',
            typeHandle: 'text',
            key: 'cms_invalid_url',
          },
          {
            field: 'URL is required when adding a button label',
            typeHandle: 'text',
            key: 'cms_no_url_with_btnUrl_label',
          },
          {
            field: 'Join the community!',
            typeHandle: 'text',
            key: 'general_join_community',
          },
          {
            field:
              'To interact with posts, create your own, or use all of our amazing features, please join AREA or log-in.',
            typeHandle: 'text',
            key: 'join_community_notification_text',
          },
          {
            field: 'Mark as unread',
            typeHandle: 'text',
            key: 'notification_mark_as_unread',
          },
          {
            field: 'Status',
            typeHandle: 'text',
            key: 'community_admin_status_tab',
          },
          {
            field: 'Active',
            typeHandle: 'text',
            key: 'community_admin_filter_status_active',
          },
          {
            field: 'Deleted',
            typeHandle: 'text',
            key: 'community_admin_filter_status_deleted',
          },
          {
            field: 'Archived',
            typeHandle: 'text',
            key: 'community_admin_filter_status_archived',
          },
          {
            field: 'File too big, max size:',
            typeHandle: 'text',
            key: 'file_input_error_size',
          },
          {
            field: 'Incorrect file type, accepted type(s):',
            typeHandle: 'text',
            key: 'file_input_error_type',
          },
          {
            field: 'Read more',
            typeHandle: 'text',
            key: 'community_post_box_read_more_label',
          },
          {
            field: 'Recent',
            typeHandle: 'text',
            key: 'listing_page_recent_filter',
          },
          {
            field: 'Trending',
            typeHandle: 'text',
            key: 'listing_page_popular_filter',
          },
          {
            field: "Editor's Pick",
            typeHandle: 'text',
            key: 'listing_page_editors_pick_filter',
          },
          {
            field: 'All Tools',
            typeHandle: 'text',
            key: 'listing_page_tools_filter',
          },
          {
            field: 'All Industries',
            typeHandle: 'text',
            key: 'listing_pages_industries_filter',
          },
          {
            field: 'All Levels',
            typeHandle: 'text',
            key: 'listing_page_levels_filter',
          },
          {
            field: 'Series',
            typeHandle: 'text',
            key: 'listing_pages_series_filter',
          },
          {
            field: 'Years',
            typeHandle: 'text',
            key: 'listing_pages_years_filter',
          },
          {
            field: 'Subscribe now',
            typeHandle: 'text',
            key: 'footer_newsletter_cta_label',
          },
          {
            field: 'Asset types',
            typeHandle: 'text',
            key: 'listing_pages_asset_types_filter',
          },
          {
            field: 'Newsletter',
            typeHandle: 'text',
            key: 'newsletter_page_title',
          },
          {
            field: 'Get monthly news from AREA your inbox',
            typeHandle: 'text',
            key: 'newsletter_page_description',
          },
          {
            field: 'Please enter a valid Instagram URL',
            typeHandle: 'text',
            key: 'form_field_error_instagram',
          },
          {
            field: 'Please enter a valid Behance URL',
            typeHandle: 'text',
            key: 'form_field_error_behance',
          },
          {
            field: 'Please enter a valid LinkedIn URL',
            typeHandle: 'text',
            key: 'form_field_error_linkedin',
          },
          {
            field: 'Please enter a valid YouTube URL',
            typeHandle: 'text',
            key: 'form_field_error_youtube',
          },
          {
            field: 'Please enter a valid and secure (https) URL',
            typeHandle: 'text',
            key: 'form_field_error_website',
          },
          {
            field: 'Oops!',
            typeHandle: 'text',
            key: 'form_general_error_title',
          },
          {
            field:
              'One or more fields have an error. Please check your entries and try again',
            typeHandle: 'text',
            key: 'form_general_error_desc',
          },
          {
            field:
              '<p>File supported: JPG, GIF or PNG. Max file size is %size%mb. Minimum width of %width%px. Minimum height of %height%px. Please follow the <a href=https://help.area.autodesk.com/hc/en-us/articles/4406146157975-AREA-Content-Guidelines target=_blank>AREA Content Guidelines</a>, <a href=https://www.autodesk.com/company/legal-notices-trademarks/intellectual-property/copyright target=_blank>Copyright Information</a> & <a href=https://www.autodesk.com/company/legal-notices-trademarks/autodesk-participant-guidelines-lite target=_blank>Autodesk Participant Guidelines</a></p>',
            typeHandle: 'text',
            key: 'add_image_height_limit_width_limit_instructions',
          },
          {
            field:
              '<p>File supported: JPG, GIF or PNG. Max file size is %size%mb. Minimum height of %height%px. Please follow the <a href=https://help.area.autodesk.com/hc/en-us/articles/4406146157975-AREA-Content-Guidelines target=_blank>AREA Content Guidelines</a>, <a href=https://www.autodesk.com/company/legal-notices-trademarks/intellectual-property/copyright target=_blank>Copyright Information</a> & <a href=https://www.autodesk.com/company/legal-notices-trademarks/autodesk-participant-guidelines-lite target=_blank>Autodesk Participant Guidelines</a></p>',
            typeHandle: 'text',
            key: 'add_image_height_limit_instructions',
          },
          {
            field:
              '<p>File supported: JPG, GIF or PNG. Max file size is %size%mb. Minimum width of %width%px. Please follow the <a href=https://help.area.autodesk.com/hc/en-us/articles/4406146157975-AREA-Content-Guidelines target=_blank>AREA Content Guidelines</a>, <a href=https://www.autodesk.com/company/legal-notices-trademarks/intellectual-property/copyright target=_blank>Copyright Information</a> & <a href=https://www.autodesk.com/company/legal-notices-trademarks/autodesk-participant-guidelines-lite target=_blank>Autodesk Participant Guidelines</a></p>',
            typeHandle: 'text',
            key: 'add_image_width_limit_instructions',
          },
          {
            field:
              '<p>File supported: JPG, GIF or PNG. Max file size is%size% mb. Please follow the <a href=https://help.area.autodesk.com/hc/en-us/articles/4406146157975-AREA-Content-Guidelines target=_blank>AREA Content Guidelines</a>, <a href=https://www.autodesk.com/company/legal-notices-trademarks/intellectual-property/copyright target=_blank>Copyright Information</a> & <a href=https://www.autodesk.com/company/legal-notices-trademarks/autodesk-participant-guidelines-lite target=_blank>Autodesk Participant Guidelines</a></p>',
            typeHandle: 'text',
            key: 'add_image_instructions',
          },
          {
            field: 'Reports • Community',
            typeHandle: 'text',
            key: 'admin_reports_community_title',
          },
          {
            field: 'Reports • Members',
            typeHandle: 'text',
            key: 'admin_reports_members_title',
          },
          {
            field: 'Reports • Content',
            typeHandle: 'text',
            key: 'admin_reports_content_title',
          },
          {
            field: 'And %count% more...',
            typeHandle: 'text',
            key: 'admin_reports_reporter_number',
          },
          {
            field: 'Report details',
            typeHandle: 'text',
            key: 'community_admin_report_detail',
          },
          {
            field: 'My groups',
            typeHandle: 'text',
            key: 'general_mygroups_filter_text',
          },
          {
            field: 'No data to display in this table',
            typeHandle: 'text',
            key: 'generic_empty_table',
          },
        ],
      },
      {
        strings: [
          {
            field: 'Learn',
            typeHandle: 'text',
            key: 'page_home_learn_section_title',
          },
          {
            field: 'Learn',
            typeHandle: 'text',
            key: 'page_home_learn_section_title_part1',
          },
          {
            field: 'New Things',
            typeHandle: 'text',
            key: 'page_home_learn_section_title_part2',
          },
          {
            field: 'New AREA',
            typeHandle: 'text',
            key: 'explore_area_banner_title',
          },
          {
            field:
              'AREA is the best place to connect with like-minded 3D artists and other community members. Join today!',
            typeHandle: 'text',
            key: 'expore_area_banner_paragraph',
          },
        ],
      },
    ],
  },
};
