import {
  AddContentWrapper,
  BoxContentWrapper,
  BoxHeaderWrapper,
  BoxToolsWrapper,
  BoxWrapper,
} from './ProfileBox.styled';
import React, { useState } from 'react';

import Box from '@components/atoms/box/Box';
import Button from '@components/atoms/button/Button';
import ProfileBoxListItem from '@components/molecules/profileBox/profileBoxListItem/ProfileBoxListItem';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

function createMarkup(copy) {
  return {
    __html: copy,
  };
}

const ProfileBox = props => {
  const {
    copy,
    title,
    type,
    section,
    box,
    onEditClick,
    isCurrentUserProfile,
  } = props;

  const defaultMaxListLength = 4;
  const [maxListLength, setMaxListLength] = useState(defaultMaxListLength);

  const BoxTools = () => {
    return (
      <BoxToolsWrapper>
        {/* {!isFirst && moveUpButton}
        {!isLast && moveDownButton} */}
        {type == 'text' ? (
          <Button
            actionType="onclick"
            colorTheme="normal"
            onClick={onEditClick}
            css={{
              margin: '4px',
              width: '$32',
              height: '$32',
            }}
            svgIcon="edit"
            type="circle"
            circleSize="small"
          />
        ) : (
          <Button
            actionType="onclick"
            onClick={onEditClick}
            css={{
              margin: '4px',
              width: '$32',
              height: '$32',
            }}
            svgIcon="plus"
            type="circle"
            circleSize="small"
          />
        )}
      </BoxToolsWrapper>
    );
  };

  const BoxHeader = () => {
    return (
      <BoxHeaderWrapper>
        <Text
          as="h3"
          weight="legendBold"
          css={{
            lineHeight: '1.3em',
            marginBottom: '0px',
            fontSize: '$18',
            '@bp1': {
              fontSize: '$32',
            },
          }}
        >
          <String id={title} />
        </Text>
        {isCurrentUserProfile && (type === 'text' || box?.list?.length > 0) ? (
          <BoxTools />
        ) : null}
      </BoxHeaderWrapper>
    );
  };
  const addContentString = () => {
    switch (section) {
      case 'about_me':
        return <String id="profile_add_aboutMe_label" />;

      case 'recent_work':
        return <String id="profile_add_recentWork_label" />;

      case 'work_experience':
        return <String id="profile_add_workExperience_label" />;

      case 'schools':
        return <String id="profile_add_schoolAndTraining_label" />;
    }
  };

  const AddContent = () => {
    if (isCurrentUserProfile) {
      return (
        <AddContentWrapper>
          <Button
            actionType="onclick"
            onClick={onEditClick}
            css={{
              margin: '$4',
              marginRight: '$16',
            }}
            svgIcon="plus"
            type="circle"
            circleSize="large"
          />
          {addContentString()}
        </AddContentWrapper>
      );
    }
    return null;
  };

  const BoxList = () => {
    return (
      <>
        <BoxHeader />
        {box.list.length ? (
          box.list.slice(0, maxListLength).map((item, id) => {
            return (
              <div key={id}>
                <BoxWrapper>
                  <ProfileBoxListItem
                    key={id}
                    title={item.title ? item.title : item.name}
                    type={type}
                    section={section}
                    image={item.image}
                    location={item.locationFull}
                    date={item.date}
                    dateStart={item.dateStart}
                    dateEnd={item.dateEnd}
                    course={item.course}
                    jobTitle={item.job_title}
                    is_current={item.is_current}
                    onClick={onEditClick.bind(null, id)}
                    isCurrentUserProfile={isCurrentUserProfile}
                  />
                </BoxWrapper>
              </div>
            );
          })
        ) : (
          <BoxHeaderWrapper>
            <AddContent />
          </BoxHeaderWrapper>
        )}
      </>
    );
  };

  return (
    <Box
      color="white"
      radius="medium"
      shadow="medium"
      css={{
        padding: '$24',
        marginBottom: '$24',
        '@bp2': {
          padding: '$32 $48',
          marginBottom: '$24',
        },
        '@bp3': {
          padding: '$48 $24 $48 $54',
          marginBottom: '$24',
        },
      }}
    >
      {type == 'text' ? (
        <>
          <BoxHeader />
          <BoxContentWrapper>
            {copy ? (
              <div dangerouslySetInnerHTML={createMarkup(copy)} />
            ) : (
              <AddContent />
            )}
          </BoxContentWrapper>
        </>
      ) : (
        <BoxList />
      )}
    </Box>
  );
};
export default ProfileBox;

ProfileBox.propTypes = {
  copy: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  list: PropTypes.array,
};
