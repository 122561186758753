import {
  CMS_DELETE_GROUP_POSTS,
  JOIN_GROUPS,
  LEAVE_GROUPS,
} from '@src/apollo/mutation';
import {
  CenterContent,
  GroupFeedPageContent,
  GroupFeedWrapper,
  GroupSidebarLeftStyled,
  GroupSidebarRightStyled,
  MobileNavBtns,
  MobileNavWrapper,
} from './GroupFeed.styled';

import {
  GET_GROUP_BASIC_DETAILS,
  GET_MY_GROUPS,
  GET_MY_GROUPS_ID,
  GET_POSTS,
  GET_POST_BY_SLUG,
} from '@src/apollo/query';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  createContext,
} from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@context/AuthContext';
import CommunityAddPost from '@components/molecules/communityAddPost/CommunityAddPost';
import CommunityFeed from '@components/organisms/communityFeed/CommunityFeed';
import GroupHeader from '@components/organisms/groupHeader/GroupHeader';
import GroupSidebarLeft from '@components/organisms/groupSidebarLeft/GroupSidebarLeft';
import GroupSidebarRight from '@components/organisms/groupSidebarRight/GroupSidebarRight';
import Layout from '@components/organisms/layout/Layout';
import LoaderSkeleton from '@components/atoms/loaderSkeleton/LoaderSkeleton';
import SEO from '@components/atoms/seo/Seo';
import SinglePost from './SinglePost';
import String from '@components/atoms/string/String';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { navigate } from 'gatsby';
import { formatDateTimeAgo } from '@src/utils/datesHelper';
import useInfiniteScroll from '@src/utils/useInfiniteScroll';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import CommunityFeedContext from '@context/CommunityFeedContext';

const GroupFeed = React.memo(context => {
  const promoLinkText = <String id="community_promo_link_text" />;
  const recentText = <String id="general_recent_filter_text" />;
  const popularText = <String id="general_popular_filter_text" />;
  const Context = createContext('Default Value');
  const authContext = useContext(AuthContext);

  const [communityState, setCommunityState] = useState({
    myGroupsId: [],
  });

  const [myGroupState, setMyGroupState] = useState({
    myGroupsId: [],
    groupsIdLoaded: false,
    myGroupsLoaded: false,
  });

  const [activeFilter, setActiveFilter] = useState('recent');

  const {
    loading: loadingMyGroups,
    error: errorMyGroups,
    data: dataMyGroups,
  } = useQuery(GET_MY_GROUPS, {
    notifyOnNetworkStatusChange: true,
    skip: !authContext.isAuthenticated,
  });

  useEffect(() => {
    if (dataMyGroups) {
      const groupsIdList = [];
      const myGroupsJoinDate = [];
      const myGroupsRoles = [];
      dataMyGroups.mygroups.map((group, i) => {
        groupsIdList.push(group.id);
        myGroupsJoinDate.push({ id: group.id, date: group.join_date });
        myGroupsRoles.push({ id: group.id, role: group.group_role });
      });
      if (authContext.isAuthenticated) {
        setCommunityState({
          myGroupsId: groupsIdList,
        });
        setMyGroupState({
          ...myGroupState,
          myGroupsId: groupsIdList,
          myGroupsLoaded: true,
          myGroupsJoinDate: myGroupsJoinDate,
          myGroupsRoles: myGroupsRoles,
        });
      } else {
        setMyGroupState({
          ...myGroupState,
          myGroupsLoaded: true,
        });
      }
    }
    if (!authContext.isAuthenticated) {
      setMyGroupState({
        ...myGroupState,
        myGroupsLoaded: true,
      });
    }
  }, [dataMyGroups]);

  const [isInitialFetching, setIsInitialFetching] = useState(true);

  const [groupFeedState, setGroupFeedState] = useState({
    baseUri:
      context.uri.indexOf('/page/') === -1
        ? context.uri
        : context.uri.substring(0, context.uri.indexOf('/page/')),
    selected: 'feed',
    filteredBy: 'recent',
    groupHeaderData: [],
    headerLoaded: false,
    activityFeed: {},
    groupTitle: '',
    groupDescription: '',
    feedLoaded: false,
    communitySidebarLeftData: [],
    sideBarLeftLoaded: false,
    totalPosts: 0,
    infinityOn: false,
    actualPrev: [],
    communitySidebarRightData: [],
    placeholderFeed: {
      posts: [],
      filtersList: [
        { title: 'recent', id: 0 },
        { title: 'popular', id: 1 },
      ],
    },
    isJoined: false,
    activePage: context.page ? parseInt(context.page) : 1,
    showPopup: false,
    activePage: 1,
    lastPage: 1,
    hasMore: false,
    postId: null,
    isNotEdit: true,
  });
  const max_posts = 10;

  const { loading, error, data } = useQuery(GET_GROUP_BASIC_DETAILS, {
    variables: {
      slug: context.slug,
      feed: groupFeedState.filteredBy,
    },
    context: { clientName: 'public' },
    fetchPolicy: 'no-cache',
  });

  const [
    getPosts,
    {
      loading: loadingFeed,
      error: errorFeed,
      data: dataFeed,
      fetchMore: dataFetchMore,
      refetch,
      networkStatus,
    },
  ] = useLazyQuery(GET_POSTS, {
    variables: {
      limit: max_posts,
      page: 1,
      feed: activeFilter,
      group_slug: context.slug,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    context: { clientName: 'public' },
  });

  const [deletePosts, deletePostsData] = useMutation(CMS_DELETE_GROUP_POSTS, {
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    if (groupFeedState.groupHeaderData.length !== 0) {
      getPosts();
    }
  }, [groupFeedState.groupHeaderData]);

  useEffect(() => {
    if (dataFeed) {
      const filtersList = [
        { title: recentText, id: 0 },
        { title: popularText, id: 1 },
      ];
      const activityFeed = {
        filtersList,
      };

      setGroupFeedState({
        ...groupFeedState,
        activityFeed,
        feedLoaded: true,
        userInfos,
        lastPage: dataFeed?.posts?.last_page,
        activePage: dataFeed?.posts?.current_page,
        hasMore: dataFeed?.posts?.has_more_pages,
      });
      setIsInitialFetching(false);
    }
  }, [dataFeed]);

  useEffect(() => {
    if (!loadingFeed) {
      setFeedChange(false);
    }
  }, [loadingFeed]);

  useEffect(() => {
    if (data?.group === null) {
      navigate('/404');
    }

    if (data?.group && data?.group !== null) {
      const { group } = data;
      const groupHeaderData = {
        image: getImageSrc(group.header_image, 'group_header', group.title),
        groupTitle: group.name,
        imageGroupIcon: getImageSrc(group.icon, 'group_icon', group.title),
        title: group.name,
        groupId: group.id,
      };

      const membersList = [];
      for (let i = 0; i < group.latest_4_members.length; i++) {
        const profilePicture = getAvatarImage(
          group?.latest_4_members[i]?.userProfile?.profile_picture,
          'small'
        );

        const user = {
          avatar: {
            shape: 'circle',
            alt: `${group.latest_4_members[i].first_name} ${group.latest_4_members[i].last_name}`,
            src: profilePicture,
          },
        };
        membersList.push(user);
      }

      const moderatorsList = [];
      group.group_moderators.map((moderator, i) => {
        const profilePicture = getAvatarImage(
          moderator?.userProfile?.profile_picture,
          'small'
        );

        const moderatorsData = {
          title: `${moderator.first_name} ${moderator.last_name}`,
          subtitle: moderator.title,
          avatar: {
            shape: 'circle',
            alt: `${moderator.first_name} ${moderator.last_name}`,
            src: profilePicture,
          },
        };
        moderatorsList.push(moderatorsData);
      });

      const communitySidebarLeftData = {
        created: group.created_at ? group.created_at : 'Mar 17, 2020',
        moderators: moderatorsList,
        members: membersList,
        mermbersCount: group.members_count ? group.members_count : 0,
      };
      const thumbnails = group.featured_content.map((featured, k) =>
        dataToThumbnailViewModel(featured, false, authContext.isAuthenticated)
      );

      const communitySidebarRightData = {
        hasAdFeature: group.promotion_image === null ? false : true,
        adFeature: {
          src: {
            alt: group.promotion_text,
            src: getImageSrc(group.promotion_image, 'group_promo', group.title),
          },
          title: group.promotion_text,
          action: {
            label: promoLinkText,
            externalUrl: group.promotion_url,
            actionType: 'externalLink',
            target: '_blank',
          },
        },
        thumbnails,
      };

      setGroupFeedState({
        ...groupFeedState,
        groupHeaderData,
        headerLoaded: true,
        groupTitle: group.name,
        groupDescription: group.description,
        communitySidebarLeftData,
        communitySidebarRightData,
        sideBarLeftLoaded: true,
        totalPosts: group.posts.total,
      });
    }
  }, [data]);

  const [getMyGroupsData, myGroupsData] = useLazyQuery(GET_MY_GROUPS_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (groupFeedState.headerLoaded) {
      if (authContext.isAuthenticated) {
        getMyGroupsData();
      } else {
        setMyGroupFeedState({
          ...myGroupFeedState,
          myGroupsId: [],
          myGroupsRole: '',
          groupsIdLoaded: true,
        });
      }
    }
  }, [groupFeedState.headerLoaded]);

  useEffect(() => {
    if (myGroupsData.data) {
      const groups = myGroupsData.data.mygroups;
      const groupIdList = [];
      for (let i = 0; i < groups.length; i++) {
        groupIdList.push(groups[i].id);
      }

      let groupRole = '';
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].id === groupFeedState.groupHeaderData.groupId) {
          groupRole = groups[i].group_role;
        }
      }

      setMyGroupFeedState({
        ...myGroupFeedState,
        myGroupsId: groupIdList,
        myGroupsRole: groupRole,
        groupsIdLoaded: true,
      });
    }
  }, [myGroupsData.data]);

  const [myGroupFeedState, setMyGroupFeedState] = useState({
    myGroupsId: [],
    myGroupsRole: '',
    groupsIdLoaded: false,
    myGroupsLoaded: false,
  });

  const onReportGroupClick = () => {
    alert('group reported');
  };

  const onLeaveGroupClick = id => {
    const newArray = myGroupFeedState.myGroupsId;
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i] === id) {
        newArray.splice(i, 1);
      }
    }
    setGroupFeedState({
      ...groupFeedState,
      isJoined: false,
    });
    leaveGroups({ variables: { groups: myGroupFeedState.myGroupsId } });
  };

  const onNavClick = e => {
    setGroupFeedState({
      ...groupFeedState,
      selected: e.innerHTML,
    });
  };

  let userInfos;

  if (authContext.isAuthenticated) {
    const profilePicture = authContext?.profilePicture;

    userInfos = {
      modalProps: {
        artist: {
          avatarProps: {
            alt: `${authContext.firstName} ${authContext.lastName}`,
            src: profilePicture,
          },
          name: `${authContext.firstName} ${authContext.lastName}`,
          job: authContext.title,
        },
      },
      avatarProps: {
        alt: `${authContext.firstName} ${authContext.lastName}`,
        src: profilePicture,
      },
      userId: authContext.userId,
    };
  }

  const [
    joinGroups,
    { loading: loadingJoin, error: errorJoin, data: dataJoinGroups },
  ] = useMutation(JOIN_GROUPS);

  useEffect(() => {
    if (dataJoinGroups) {
      let groupsIds = [];
      dataJoinGroups.updateUserProfile.groups.map((group, i) => {
        groupsIds.push(group.id);
      });

      setCommunityState({
        myGroupsId: groupsIds,
      });
    }
  }, [dataJoinGroups]);

  const [
    leaveGroups,
    { loading: loadingLeave, error: errorLeave, data: dataLeaveGroups },
  ] = useMutation(LEAVE_GROUPS);

  useEffect(() => {
    if (dataLeaveGroups) {
      let groupsIds = [];
      dataLeaveGroups.updateUserProfile.groups.map((group, i) => {
        groupsIds.push(group.id);
      });

      setCommunityState({
        myGroupsId: groupsIds,
      });
    }
  }, [dataLeaveGroups]);

  const onJoinGroup = id => {
    const newArray = myGroupFeedState.myGroupsId;
    newArray.push(id);

    setMyGroupFeedState({
      ...myGroupFeedState,
      myGroupsId: newArray,
    });
    setGroupFeedState({
      ...groupFeedState,
      isJoined: true,
    });
    joinGroups({ variables: { groups: myGroupFeedState.myGroupsId } });
  };
  useEffect(() => {
    if (myGroupFeedState.groupsIdLoaded && groupFeedState.headerLoaded) {
      for (let i = 0; i < myGroupFeedState.myGroupsId.length; i++) {
        if (
          myGroupFeedState.myGroupsId[i] ===
          groupFeedState.groupHeaderData.groupId
        ) {
          setGroupFeedState({
            ...groupFeedState,
            isJoined: true,
          });
        }
      }
    }
  }, [myGroupFeedState.groupsIdLoaded, groupFeedState.headerLoaded]);

  const [feedChange, setFeedChange] = useState(false);

  const postSaved = () => {
    refetch();
    setGroupFeedState({
      ...groupFeedState,
      showPopup: true,
    });
  };

  const onLoadMore = () => {
    const nextPage = dataFeed && dataFeed.posts.current_page + 1;

    dataFetchMore({
      variables: {
        page: nextPage,
        feed: activeFilter,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (prev?.posts.data != fetchMoreResult.posts.data) {
          const allPosts = [...prev.posts.data, ...fetchMoreResult.posts.data];
          fetchMoreResult.posts.data = allPosts;
          return fetchMoreResult;
        }
      },
    });
  };

  const onDeleteClick = id => {
    deletePosts({
      variables: {
        ids: [id],
      },
    });
  };

  const { element } = useInfiniteScroll({
    threshold: 0.1,
    onBottomHit: onLoadMore,
    hasMore: dataFeed?.posts?.has_more_pages,
    currentPage: groupFeedState.activePage,
    isLoading: loadingFeed,
  });

  const [getPostBySlug, postBySlugData] = useLazyQuery(GET_POST_BY_SLUG, {
    variables: {
      slug: context.postslug,
    },
    context: { clientName: 'public' },
    fetchPolicy: 'no-cache',
  });

  const [singlePostState, setSinglePostState] = useState({
    postContent: {},
    loaded: false,
    loading: false,
  });

  useLayoutEffect(() => {
    if (context.postslug && myGroupState.myGroupsLoaded) {
      getPostBySlug();
    }
  }, [myGroupState.myGroupsLoaded]);

  useEffect(() => {
    if (postBySlugData.data) {
      if (postBySlugData.data.post) {
        const postComments = [];
        postBySlugData.data.post.children.map((child, j) => {
          if (child.status === 'approved') {
            postComments.push(child);
          }
        });
        const parentId = postBySlugData.data.post.id;
        const postImages = [];
        postBySlugData.data.post.images ? (
          <>
            {postBySlugData.data.post.images.map((image, i) => {
              const imageContent = getImageSrc(
                image,
                'post_image',
                `${postBySlugData.data.post.author.first_name} ${postBySlugData.data.post.author.last_name}`
              );

              postImages.push({ ...image, ...imageContent });
            })}
          </>
        ) : null;

        const profilePicture = getAvatarImage(
          postBySlugData?.data?.post?.author?.userProfile?.profile_picture,
          'small'
        );

        let postLinkPreview = {};

        if (
          postBySlugData.data.post.link_url !== null &&
          postBySlugData.data.post.link_url !== ''
        ) {
          const previewImage = getImageSrc(
            postBySlugData.data.post.link_image,
            'post_image',
            postBySlugData.data.post.link_url
          );
          postLinkPreview = {
            url: postBySlugData.data.post.link_url,
            title: postBySlugData.data.post.link_title,
            description: postBySlugData.data.post.link_description,
            previewImage: previewImage,
          };
        }
        const postContent = {
          postComments: postComments,
          parentId,
          postLinkPreview:
            postBySlugData.data.post.link_url !== null &&
            postBySlugData.data.post.link_url !== ''
              ? postLinkPreview
              : null,
          commentCount: postBySlugData.data.post.children.length,
          userInfos: {
            avatarProps: {
              shape: 'circle',
              alt: `${postBySlugData.data.post.author.first_name} ${postBySlugData.data.post.author.last_name}`,
              src: profilePicture,
              url: `/m/${authContext.userSlug}`,
            },
            modalProps: userInfos,
          },
          postLikes: postBySlugData.data.post.usersLikedCount,
          postDate: formatDateTimeAgo(postBySlugData.data.post.createdAt),
          isCommunity: false,
          isPost: true,
          isCurrentUserGroupMember: myGroupState.myGroupsId.includes(
            postBySlugData.data.post.group.id
          ),
          groupUrl: `/community/groups/${postBySlugData.data.post.group.slug}`,
          groupAlt: postBySlugData.data.post.group.name,
          postAuthor: {
            name: `${postBySlugData.data.post.author.first_name} ${postBySlugData.data.post.author.last_name}`,
            jobTitle: postBySlugData.data.post.author.title,
            groupName: postBySlugData.data.post.group.name,
            avatar: {
              src: profilePicture,
              alt: `${`${postBySlugData.data.post.author.first_name} ${postBySlugData.data.post.author.last_name}`}`,
            },
            url: `/m/${postBySlugData.data.post.author.username}`,
            username: postBySlugData.data.post.author.username,
          },
          postText: postBySlugData.data.post.content,
          postImages,
          groupId: postBySlugData.data.post.group.id,
          likesList: postBySlugData.data.post.usersLiked,
          total: postBySlugData.data.post.total,
          postId: postBySlugData.data.post.id,
          isFavorited: postBySlugData.data.post.isFavorited,
        };
        setSinglePostState({
          ...singlePostState,
          postContent: postContent,
          loaded: true,
        });
      } else {
        setSinglePostState({
          ...singlePostState,
          postContent: null,
          loaded: true,
        });
      }
    }
  }, [postBySlugData]);

  const closeModal = () => {
    window.history.replaceState(
      {
        html: context.uri.substring(
          0,
          context.uri.indexOf('/' + context.postslug)
        ),
        pageTitle: `AREA - ${groupFeedState.groupTitle}`,
      },
      '',
      context.uri.substring(0, context.uri.indexOf('/' + context.postslug))
    );
  };

  useEffect(() => {
    if (postBySlugData.loading) {
      setSinglePostState({
        ...singlePostState,
        loading: true,
      });
    }
  }, [postBySlugData.loading]);

  return (
    <Layout location={context.location}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />

      <SEO title={`${groupFeedState.groupTitle}`} slug="group-feed" />
      <CommunityFeedContext.Provider
        value={[communityState, setCommunityState]}
      >
        {groupFeedState.headerLoaded && myGroupFeedState.groupsIdLoaded ? (
          <GroupHeader
            {...groupFeedState.groupHeaderData}
            onReportGroupClick={onReportGroupClick}
            onLeaveGroupClick={onLeaveGroupClick}
            isLogged={authContext.isAuthenticated}
            isVerified={authContext.isAuthenticated && authContext.isVerified}
            isOnboarded={
              authContext.isAuthenticated &&
              JSON.parse(localStorage.getItem('isOnboarded')) === 1
            }
            isJoinLoading={loadingJoin}
            onJoinGroup={onJoinGroup}
            myGroupsId={myGroupFeedState.myGroupsId}
            adminUrl={`${context.location.pathname}/admin/members`}
            groupRole={myGroupFeedState.myGroupsRole}
            generalRole={authContext?.generalRole}
            groupId={
              groupFeedState.groupHeaderData
                ? groupFeedState.groupHeaderData.groupId
                : null
            }
            authContext={authContext}
          />
        ) : (
          <LoaderSkeleton height="300px" width="100%" />
        )}

        <MobileNavWrapper>
          <MobileNavBtns
            isSelected={groupFeedState.selected === 'feed'}
            onClick={e => {
              onNavClick(e.target);
            }}
          >
            <String id="community_mobile_menu_feed" />
          </MobileNavBtns>
          <MobileNavBtns
            isSelected={groupFeedState.selected === 'about'}
            onClick={e => {
              onNavClick(e.target);
            }}
          >
            <String id="community_mobile_menu_about" />
          </MobileNavBtns>
        </MobileNavWrapper>
        <GroupFeedWrapper>
          <GroupFeedPageContent>
            <GroupSidebarLeftStyled
              isNotShowing={groupFeedState.selected === 'feed'}
            >
              {groupFeedState.sideBarLeftLoaded ? (
                <GroupSidebarLeft
                  {...groupFeedState.communitySidebarLeftData}
                  description={groupFeedState.groupDescription}
                  guidelinesModal={context.guidelinesModal}
                />
              ) : (
                <>
                  <LoaderSkeleton height="200px" width="100%" isRadius />
                  <LoaderSkeleton height="50px" width="100%" isRadius />
                  <LoaderSkeleton height="50px" width="100%" isRadius />
                </>
              )}
            </GroupSidebarLeftStyled>

            <CenterContent isNotShowing={groupFeedState.selected === 'about'}>
              {groupFeedState.isJoined && (
                <>
                  <CommunityAddPost
                    {...userInfos}
                    isCurrentUserGroupMember={groupFeedState.isJoined}
                    isAtTop
                    isGroupFeed
                    isLogged={authContext.isAuthenticated}
                    isVerified={
                      authContext.isAuthenticated && authContext.isVerified
                    }
                    isOnboarded={
                      authContext.isAuthenticated &&
                      JSON.parse(localStorage.getItem('isOnboarded')) === 1
                    }
                    groupId={
                      groupFeedState.groupHeaderData
                        ? groupFeedState.groupHeaderData.groupId
                        : null
                    }
                    onSaveClick={postSaved}
                    groupRole={myGroupFeedState.myGroupsRole}
                  />
                </>
              )}

              {dataFeed &&
              groupFeedState.headerLoaded &&
              myGroupFeedState.groupsIdLoaded ? (
                <div ref={element}>
                  <CommunityFeed
                    {...groupFeedState.activityFeed}
                    postSlugs={dataFeed?.posts?.data.map(post => post.slug)}
                    loading={loadingFeed}
                    isCurrentUserGroupMember={groupFeedState.isJoined}
                    isGroupFeed
                    activeFilter={activeFilter}
                    onFilterClick={e => setActiveFilter(e)}
                    isLogged={authContext.isAuthenticated}
                    isVerified={
                      authContext.isAuthenticated && authContext.isVerified
                    }
                    isOnboarded={
                      authContext.isAuthenticated &&
                      JSON.parse(localStorage.getItem('isOnboarded')) === 1
                    }
                    suggestedGroupSlider={context.suggestedGroupSlider}
                    isFeedLoaded={groupFeedState.feedLoaded}
                    placeholderFeed={groupFeedState.placeholderFeed}
                    onJoinGroup={onJoinGroup}
                    groupId={
                      groupFeedState.groupHeaderData
                        ? groupFeedState.groupHeaderData.groupId
                        : null
                    }
                    infinityLoad={!!(loadingFeed && !isInitialFetching)}
                    isInitialFetching={isInitialFetching}
                    onDeleteClick={onDeleteClick}
                    hasGroups={!!myGroupState.myGroupsId.length}
                    totalPosts={groupFeedState.totalPosts}
                    hasMore={groupFeedState.hasMore}
                    myGroupState={myGroupFeedState}
                    feedChange={feedChange}
                    groupRole={myGroupFeedState.myGroupsRole}
                    generalRole={authContext?.generalRole}
                  />
                </div>
              ) : (
                <>
                  <LoaderSkeleton height="100px" width="100%" isRadius />
                  <LoaderSkeleton height="100px" width="100%" isRadius />
                  <LoaderSkeleton height="100px" width="100%" isRadius />
                </>
              )}
            </CenterContent>
            <GroupSidebarRightStyled>
              {groupFeedState.sideBarLeftLoaded ? (
                <GroupSidebarRight
                  {...groupFeedState.communitySidebarRightData}
                />
              ) : (
                <>
                  <LoaderSkeleton height="200px" width="100%" isRadius />
                </>
              )}
            </GroupSidebarRightStyled>
          </GroupFeedPageContent>
          {context.postslug &&
            (singlePostState.postContent !== null ? (
              <SinglePost
                onDeleteClick={onDeleteClick}
                isInModal={singlePostState.postContent.postImages?.length > 3}
                postContent={singlePostState.postContent}
                isLogged={authContext.isAuthenticated}
                isVerified={authContext.isVerified}
                isCurrentUserGroupMember={groupFeedState.isJoined}
                groupId={singlePostState.postContent.groupId}
                isOnboarded={
                  authContext.isAuthenticated &&
                  JSON.parse(localStorage.getItem('isOnboarded')) === 1
                }
                userInfos={userInfos}
                closeModal={closeModal}
                loaded={singlePostState.loaded}
                total={singlePostState.postContent.postComments?.length}
                groupRole={myGroupFeedState.myGroupsRole}
                generalRole={authContext?.generalRole}
              />
            ) : (
              <SinglePost isNull={true} loaded={singlePostState.loaded} />
            ))}
        </GroupFeedWrapper>
      </CommunityFeedContext.Provider>
    </Layout>
  );
});

export default GroupFeed;

GroupFeed.defaultProps = {
  guidelinesModal: {
    modalTitle: 'community_guidelines_group_rules',
    sections: [
      {
        title: 'community_guidelines_dos',
        contents: [
          {
            title: 'Do give your work a title.',
            bulletSvg: 'check',
            text:
              'Want people to find your work through search? Make sure your title reflects what your project is – getting creative with your title doesn’t work to your advantage. ',
          },
          {
            title: 'Do submit a description of your work.',
            bulletSvg: 'check',
            text:
              'This can range from your inspiration and backstory to your technique.',
          },
          {
            title: 'Do submit multiple images to showcase your work.',
            bulletSvg: 'check',
            text:
              'This can range from WIP (work-in-progress) imagery to multiple angles of your work.',
          },
          {
            title: 'Do run a grammar check on your title and description.',
            bulletSvg: 'check',
          },
          {
            title: 'Do submit work that it watermarked.',
            bulletSvg: 'check',
            text:
              'We strongly urge you to watermark your work with your name and social media handle or website so that if someone shares it without your knowing, people will know where to find you and more of your work.',
          },
        ],
      },
      {
        title: 'community_guidelines_donts',
        contents: [
          {
            title: 'Don’t submit disrespectful artwork',
            bulletSvg: 'close-big',
            text:
              'Don’t submit work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or graphic violence.',
          },
          {
            title: 'Don’t submit a disrespectful title or description',
            bulletSvg: 'close-big',
            text:
              'Don’t submit a title or a description of your work that is sexually explicit, hateful, degrading or that includes illicit drugs, profanities, or violence.',
          },
          {
            title:
              'If your work goes against our guidelines, we will not publish it.',
            bulletSvg: 'close-big',
          },
        ],
      },
    ],
  },
};
