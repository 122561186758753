import {
  Background,
  ClearButton,
  SearchBarWrapper,
  SearchSection,
  StyledButton,
  StyledDropDown,
  StyledInput,
} from './SearchBarWithTags.styled';
import React, { useEffect, useRef, useState } from 'react';

import DropdownComponent from '@components/molecules/listingHeader/listingNav/dropdown/Dropdown';
import PropTypes from 'prop-types';

const MIN_QUERY_LENGTH = 2;

const SearchBarWithTags = props => {
  const [searchQueryState, setSearchQueryState] = useState('');
  const inputRef = useRef();

  const [dropdownState, setDropdownState] = useState({
    filters: props.filters.items,
    title: props.activeFilter,
    isOpened: false,
    activeOption: 0,
  });

  useEffect(() => {
    if(inputRef.current){
      inputRef.current.focus();
    }
  }, [inputRef])

  useEffect(() => {
    if(props.activeFilter){
      setDropdownState({
        ...dropdownState,
        title: props.filters.items[props.filters.items.findIndex(el => el.title === props.activeFilter)].title,
        activeOption: props.filters.items.findIndex(el => el.title === props.activeFilter),
      })
    }
  }, [props.activeFilter]);

  useEffect(() => {
    setSearchQueryState(props.query)
  }, [props.query])

  const onSendResearch = (query, title) => {
    props.onChange({
      type: 'update',
      payload: {
        keywords: query,
        types: title || dropdownState.title,
        filters: {},
        isSearch: true,
      },
    });
  };

  function onChange(e) {
    setSearchQueryState(e.target.value);
  }

  const onDropdownItemClick = (title, parentTitle) => {
    let activeOption;
    props.filters?.items?.forEach((element, index) => {
      if (element.title == title) {
        activeOption = index;
        return;
      }
    });
    setDropdownState({
      ...dropdownState,
      title: title,
      isOpened: !dropdownState.isOpened,
      activeOption,
    });
    if(searchQueryState !== ''){
      onSendResearch(searchQueryState, title)
    }
  };

  const onFilterDropdownClick = e => {
    setDropdownState({
      ...dropdownState,
      isOpened: !dropdownState.isOpened,
    });
  };

  const onClearClick = () => {
    setSearchQueryState('');
  };

  return (
    <Background>
      <SearchBarWrapper>
        <SearchSection
          OptionsOpened={false}
          filtersOpened={dropdownState.isOpened}
        >
          <StyledButton
            svgIcon="search"
            type="circle"
            colorTheme="transparent"
            actionType="onclick"
            onClick={() => onSendResearch(searchQueryState)}
            isDisabled={searchQueryState.length < MIN_QUERY_LENGTH}
          />
          <StyledInput
            className={'search-box'}
            ref={inputRef}
            type="text"
            autofocus
            placeholder="Type here"
            onChange={onChange}
            value={searchQueryState}
            onKeyDown={e => {
              if (
                e.key === 'Enter' &&
                searchQueryState.length >= MIN_QUERY_LENGTH
                ) {
                e.target.blur()
                onSendResearch(searchQueryState);
              }
            }}
          />
          <ClearButton
            svgIcon="close-circled"
            type="circle"
            colorTheme="transparent"
            actionType="onclick"
            onClick={onClearClick}
            isVisible={searchQueryState.length > 0}
          />
          <StyledDropDown>
            <DropdownComponent
              {...props.filters}
              title={dropdownState.title.replace('_', ' ')}
              items={dropdownState.filters}
              onFilterDropdownClick={onFilterDropdownClick}
              onDropdownItemClick={onDropdownItemClick}
              index={'mobile' + 0}
              isOpened={dropdownState.isOpened}
              svgLocation="after"
              activeOption={dropdownState.activeOption}
            />
          </StyledDropDown>
        </SearchSection>
      </SearchBarWrapper>
    </Background>
  );
};

SearchBarWithTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default SearchBarWithTags;
