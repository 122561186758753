import { styled } from '@root/stitches.config';

export const ContentDataEditActions = styled('div', {
  display: 'flex',
  position: 'absolute',
  top: '$8',
  right: '$8',
});

export const ContentDataEditWrapper = styled('div', {
  position: 'relative',
  marginBottom: '$16',
  borderRadius: '$8',
  padding: '$24',
  border: '1px solid $blackToWhite',
});

export const PreviewIcon = styled('img', {
  maxWidth: '100%',
  height: 'auto',
});

export const BtnWrapper = styled('div', {});

export const PreviewIconWrapper = styled('div', {
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  overflow: 'hidden',
  marginRight: '$16',
  position: 'relative',
});

export const ContentDataEditFooter = styled('div', {
  marginTop: '$16',
});

export const IconAndInputWrapper = styled('div', {
  display: 'grid',
  marginBottom: '$32',
  gridTemplateColumns: '80px auto',
});
