import {
  StyledHeading,
  ButtonsWrapper,
  ContentWrapper,
  StyledText,
  ModalContent,
  StyledButton,
} from './DeleteModal.styled';

import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';

function DeleteModal(props) {
  const { isShowing, hide, onConfirmClick, isContainedModal } = props;

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isContainedModal={isContainedModal}
    >
      <ModalContent>
        <StyledHeading level="h3">
          <String id="delete_modal_title" />
        </StyledHeading>
        <ContentWrapper>
          <StyledText as="p">
            <String id="delete_modal_first_paragraph" />
          </StyledText>
          <StyledText as="p">
            <String id="delete_modal_second_paragraph" />
          </StyledText>
        </ContentWrapper>
        <ButtonsWrapper>
          <StyledButton
            type="normal"
            actionType="onclick"
            onClick={hide}
            label={<String id="delete_modal_cancel_button" />}
            colorTheme="gray"
          />
          <StyledButton
            type="normal"
            actionType="onclick"
            onClick={onConfirmClick}
            label={<String id="delete_modal_confirm_button" />}
            colorTheme="normal"
          />
        </ButtonsWrapper>
      </ModalContent>
    </Modal>
  );
}

DeleteModal.propTypes = {
  isShowing: PropTypes.bool,
  isContainedModal: PropTypes.bool,
  hide: PropTypes.func,
  onConfirmClick: PropTypes.func.isRequired,
};

DeleteModal.defaultProps = {
  isShowing: false,
  isContainedModal: false,
  hide: () => {},
};

export default DeleteModal;
