import {
  BadgeListBadges,
  BadgeListWrapper,
  ShowMoreButton,
  TitleText,
  TitleWrapper,
} from './BadgeToolsList.styled';
import React, { useState } from 'react';

import Badge from '@components/molecules/badgeToolsIcon/BadgeToolsIcon';
import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import PropTypes from 'prop-types';
import Svg from '@components/atoms/svg/Svg';

const BadgeToolsList = props => {
  const { list, title, css } = props;
  const [badgeToolsListState, setBadgeToolsListState] = useState({
    showAll: false,
  });

  const maxToolsCount = props.maxTools ? props.maxTools : 8;

  const onClickShowAll = () => {
    setBadgeToolsListState({
      showAll: true,
    });
  };
  return (
    <BadgeListWrapper css={{ ...css }}>
      <TitleWrapper>
        <TitleText as="h6">{title}</TitleText>

        {props.isEditMode ? (
          <Button
            css={{ ml: '$24' }}
            type="circle"
            actionType="onclick"
            svgIcon="edit"
            onClick={props.onEditClick}
            circleSize="small"
          />
        ) : null}
      </TitleWrapper>
      <BadgeListBadges>
        {list.map((badge, i) => {
          if (i >= maxToolsCount && !badgeToolsListState.showAll) return;
          return <Badge {...badge} key={i} />;
        })}

        {list.length > maxToolsCount && !badgeToolsListState.showAll ? (
          <ShowMoreButton onClick={onClickShowAll}>
            +{list.length - maxToolsCount}
          </ShowMoreButton>
        ) : null}
      </BadgeListBadges>
    </BadgeListWrapper>
  );
};

BadgeToolsList.propTypes = {
  title: PropTypes.string.isRequired,
};

export default React.memo(BadgeToolsList, (prevProps, nextProps) => {
  if (
    prevProps.list !== nextProps.list ||
    prevProps.isEditMode !== nextProps.isEditMode
  ) {
    return false;
  }
  return true;
});
