import { Dot, DotWrapper } from './UserInfos.styled';

import React from 'react';
import { Text } from '@components/atoms/text/Text';

const TextWithDot = props => {
  return (
    <DotWrapper>
      <Dot color={props.color} />
      <Text size="14" css={{ textTransform: 'capitalize' }}>
        {props.status}
      </Text>
    </DotWrapper>
  );
};

export default TextWithDot;
