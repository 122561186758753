import {
  CREATE_POST_REPORT,
  POST_FAVORITE,
  POST_REACTION,
} from '@src/apollo/mutation';
import {
  CommunityPostActionsBtn,
  CommunityPostActionsBtnWrapper,
  CommunityPostBoxContent,
  CommunityPostBoxHeader,
  CommunityPostBoxText,
  CommunityPostReactionsWrapper,
  DeleteBtnsContainer,
  ModalContentWrapper,
  OptionsBtns,
  OptionsWrapper,
  PendingBtnWrapper,
  PostTextWrapper,
  ReportBtns,
  StyledBox,
  StyledButton,
  StyledDate,
  StyledReportText,
} from './CommunityPostBox.styled';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getAvatarImage } from '@src/utils/getAvatarHelper';
import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { ClientNotificationContext } from '@context/ClientNotificationContext';
import CommunityAddPost from '@components/molecules/communityAddPost/CommunityAddPost';
import CommunityAddPostModal from '@components/molecules/communityAddPost/communityAddPostModal/CommunityAddPostModal';
import CommunityCommentBox from '@components/organisms/communityCommentBox/CommunityCommentBox';
import CommunityLinkPreview from '@components/molecules/communityLinkPreview/CommunityLinkPreview';
import CommunityPostBoxImages from './communityPostBoxImages/CommunityPostBoxImages';
import CommunityReactionsBar from '@components/molecules/communityReactionsBar/CommunityReactionsBar';
import { GET_POST_COMMENTS, GET_FAVORITED_POSTS } from '@src/apollo/query';
import Modal from '@components/atoms/modal/Modal';
import PendingPublicationsModal from '@components/molecules/pendingPublicationsModal/PendingPublicationsModal';
import ProfileBoxSmall from '@components/molecules/profileBoxSmall/ProfileBoxSmall';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import { formatDateTimeAgo } from '@src/utils/datesHelper';
import trimText from '@src/utils/trimText';
import { useOnClickOutside } from '@src/utils/useOnClickOutside';
import { getImageSrc } from '@src/utils/getImageSrc';
import CommentWireFrame from '@components/molecules/communityPost/wireframe/CommentWireFrame';
import CommunityFeedContext from '@context/CommunityFeedContext';
import { isGroupMember } from '@src/utils/getMemberGroupInfo';
import CommunityPostContext from '@context/CommunityPostContext';

const CommunityPostBox = props => {
  const {
    postText,
    postImages,
    postAuthor,
    isCommunity,
    postDate,
    postLinkPreview,
    userInfos,
    isPending,
    postId,
    postContent,
    likesList,
    isPost,
    isFavorited,
    isLogged,
    css,
  } = props;

  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  const [communityState, setCommunityState] = useContext(CommunityFeedContext);

  const [addRemoveFavorite, dataFavorite] = useMutation(POST_FAVORITE);
  const [addRemoveReaction, dataReaction] = useMutation(POST_REACTION);
  const [postReport, dataPostReport] = useMutation(CREATE_POST_REPORT);
  const editPostText = <String id="community_edit_post_text" />;
  const savePostText = <String id="community_save_post_text" />;
  const removePostText = <String id="community_remove_post_text" />;
  const deletePostText = <String id="community_delete_post_text" />;
  const copyLinkText = <String id="community_copy_link_text" />;
  const authContext = useContext(AuthContext);
  const [isFavorite, setIsFavorite] = useState(isFavorited);
  const [isEditing, setIsEditing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const maxText = 3000;
  const minText = 200;
  const idealText = 1000;

  useEffect(() => {
    if (likesList) {
      for (let i = 0; i < likesList.length; i++) {
        if (likesList[i].username === authContext.userSlug) {
          setIsLiked(true);
          return;
        }
      }
    }
  }, []);

  useEffect(() => {
    setIsFavorite(isFavorited);
  }, [isFavorited]);

  const [commentsState, setCommentsState] = useState({
    groupRole: props.groupRole,
    generalRole: props.generalRole,
    postComments: [],
    commentsLoaded: false,
    commentImages: [],
    limit: 3,
    page: 1,
    total: props.commentCount,
    loading: false,
    initialTotal: 0,
    likes: likesList ? likesList.length : 0,
    isNotEdit: true,
    hasMorePages: false,
  });

  const [getPostComments, { loading, error, data, fetchMore }] = useLazyQuery(
    GET_POST_COMMENTS,
    {
      variables: {
        parent_id: props.parentId,
        limit: 3,
        page: commentsState.page,
        level: 1,
        order: 'desc',
      },
      context: { clientName: 'public' },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !commentsState.loading) {
    setCommentsState({
      ...commentsState,
      loading: true,
    });
  }
  useEffect(() => {
    if (data?.posts) {
      const postComments = [];
      const { posts } = data;

      posts.data.map((comment, i) => {
        let postLinkPreview = {};

        if (comment.link_url !== '' && comment.link_image !== null) {
          const linkImage = getImageSrc(
            comment.link_image,
            'link_image',
            comment.link_url,
            comment.link_image.id
          );

          postLinkPreview = {
            url: comment.link_url,
            title: comment.link_title,
            description: comment.link_description,
            previewImage: linkImage,
          };
        }

        const postImages = (comment.images || []).map((image, i) => {
          const imageContent = getImageSrc(
            image,
            'post_image',
            comment?.author?.first_name,
            image.id
          );
          return { ...image, ...imageContent };
        });

        const replies = (comment.children || [])
          .filter((reply, i) => reply.status === 'approved')
          .map((reply, i) => reply.id);

        let profilePicture = getAvatarImage(
          comment?.author?.userProfile?.profile_picture,
          'xsmall'
        );

        const postCommentContent = {
          replies: [],
          repliesCount: replies.length,
          postLinkPreview:
            comment.link_url !== null && comment.link_url !== ''
              ? postLinkPreview
              : null,
          commentId: comment.id,
          level: comment.level,
          userInfos: {
            avatarProps: {
              shape: 'circle',
              alt: `${comment.author.first_name} ${comment.author.last_name}`,

              src: profilePicture,
              url: `/m/${comment.author.username}`,
            },
            modalProps: userInfos,
          },
          postLikes: comment.usersLikedCount,
          commentDate: formatDateTimeAgo(comment.createdAt),
          postAuthor: {
            username: comment.author.username,
            name: `${comment.author.first_name} ${comment.author.last_name}`,
            jobTitle: comment.author.title,
            avatar: {
              src: profilePicture,
            },
            url: `/m/${comment.author.username}`,
          },
          postText: comment.content,
          postImages,
          favoriteList: comment.usersFavorite,
          likesList: comment.usersLiked,
          reported: false,
          isOpened: false,
          page: 1,
          loading: false,
          edited: false,
        };

        postComments.push(postCommentContent);
      });

      for (let i = 0; i < commentsState.postComments.length; i++) {
        let isIn = false;
        for (let j = 0; j < postComments.length; j++) {
          if (
            commentsState.postComments[i].commentId ===
            postComments[j].commentId
          ) {
            isIn = true;
          }
        }
        if (!isIn) {
          postComments.push(commentsState.postComments[i]);
        }
      }
      setCommentsState({
        ...commentsState,
        postComments: postComments.sort((a, b) => a.commentId - b.commentId),
        commentsLoaded: true,
        commentImages: postImages,
        total: posts.total,
        loading: false,
        initialTotal: posts.total,
        isNotEdit: false,
        hasMorePages: posts.has_more_pages,
        page: posts.current_page,
        openedComments: [],
      });
    }
  }, [data]);

  const [showComments, setShowComments] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  let htmlText = postText;
  const [fontsReady, setFontsReady] = useState(false);
  const [trimState, setTrimState] = useState({
    showOriginalHTML: false,
    originalHTML: '',
    trimmedHTML: '',
  });
  const boxRef = useRef();

  useOnClickOutside(boxRef, () => setShowOptions(false));
  useEffect(() => {
    htmlText = postText || '';

    const pTag = htmlText.match(/<p>/g);
    const endPTag = htmlText.match(/<\/p>/g);
    const brTag = htmlText.match(/<br>/g);
    const spaces = htmlText.match(/&nbsp;/g);
    const aTag = htmlText.match(/<a>;/g);

    if (aTag) {
      for (let i = 0; i < aTag.length; i++) {
        htmlText = htmlText.replace(aTag[i], '');
      }
    }

    if (brTag) {
      for (let i = 0; i < brTag.length; i++) {
        htmlText = htmlText.replace(brTag[i], '');
      }
    }

    if (spaces) {
      for (let i = 0; i < spaces.length; i++) {
        htmlText = htmlText.replace(spaces[i], '');
      }
    }

    if (pTag) {
      for (let i = 0; i < pTag.length; i++) {
        if (i !== 0) {
          htmlText = htmlText.replace(pTag[i], '<br>');
        } else {
          htmlText = htmlText.replace(pTag[i], '');
        }
      }
    }

    if (endPTag) {
      for (let i = 0; i < endPTag.length; i++) {
        htmlText = htmlText.replace(endPTag[i], '');
      }
    }
    const trimmedHTML = trimText(htmlText, minText, idealText, maxText);

    setTrimState({
      ...trimState,
      showOriginalHTML: false,
      originalHTML: htmlText,
      trimmedHTML: trimmedHTML,
    });
  }, [postText]);

  const [imageState, setImageState] = useState({
    postImages,
  });

  useEffect(() => {
    setImageState({
      ...imageState,
      postImages,
    });
  }, [postImages]);

  const handleShowText = React.useCallback(() => {
    setTrimState(prevState => ({
      ...prevState,
      showOriginalHTML: !prevState.showOriginalHTML,
    }));
  }, [setTrimState]);

  const onCommentClick = () => {
    getPostComments({
      variables: {
        parent_id: props.parentId,
      },
    });
    setShowComments(!showComments);
  };

  useEffect(() => {
    if (fontsReady) return;
    document.fonts.ready.then(function () {
      setFontsReady(true);
    });
  }, [fontsReady]);

  const onSaveClick = (data, parentId, isReply, isEdit) => {
    if (!isEdit) {
      if (data && !isReply) {
        let postLinkPreview = {};
        if (data.createPost.link_url !== null) {
          const linkImage = getImageSrc(
            data.createPost.link_image,
            'link_image',
            data.createPost.link_url
          );
          postLinkPreview = {
            url: data.createPost.link_url,
            title: data.createPost.link_title,
            description: data.createPost.link_description,
            previewImage: linkImage,
          };
        }

        if (parentId) {
          const replyImages = [];

          data.createPost.images ? (
            <>
              {data.createPost.images.map((image, i) => {
                const imageContent = getImageSrc(
                  image,
                  'post_image',
                  '',
                  image.id
                );
                replyImages.push(imageContent);
              })}
            </>
          ) : null;

          let profilePicture = getAvatarImage(
            data.createPost.author.userProfile.profile_picture,
            'xsmall'
          );

          const commentReplyContent = {
            commentId: data.createPost.id,
            level: data.createPost.level,
            postLinkPreview:
              data.createPost.link_url !== null ? postLinkPreview : null,
            type: 'reply',
            userInfos: {
              avatarProps: {
                alt: `${data.createPost.author.first_name} ${data.createPost.author.last_name}`,
                src: profilePicture,
                url: `/m/${authContext.userSlug}`,
              },
              modalProps: userInfos,
            },
            postLikes: data.createPost.usersLikedCount,
            commentDate: formatDateTimeAgo(data.createPost.createdAt),
            postAuthor: {
              username: data.createPost.author.username,
              name: `${data.createPost.author.first_name} ${data.createPost.author.last_name}`,
              jobTitle: data.createPost.author.title,
              avatar: {
                src: profilePicture,
              },
              url: `/m/${data.createPost.author.username}`,
            },
            postText: data.createPost.content,
            postImages: replyImages,
          };

          const newArray = commentsState.postComments;

          const index = newArray.findIndex(obj => obj.commentId === parentId);
          if (index !== -1) {
            newArray[index].replies.push(commentReplyContent);
            setCommentsState({
              ...commentsState,
              commentImages: [],
              postComments: newArray,
            });
          } else {
            const { postComments } = commentsState;

            const postImages = [];

            data.createPost.images ? (
              <>
                {data.createPost.images.map((image, i) => {
                  const imageContent = getImageSrc(
                    image,
                    'post_image',
                    '',
                    image.id
                  );
                  postImages.push(imageContent);
                })}
              </>
            ) : null;

            let postLinkPreview = {};
            if (data.createPost.link_url !== null) {
              const linkImage = getImageSrc(
                data.createPost.link_image,
                'link_image',
                data.createPost.link_url
              );
              postLinkPreview = {
                url: data.createPost.link_url,
                title: data.createPost.link_title,
                description: data.createPost.link_description,
                previewImage: linkImage,
              };
            }
            const postCommentContent = {
              replies: [],
              commentCount: 0,
              postLinkPreview:
                data.createPost.link_url !== null ? postLinkPreview : null,
              commentId: data.createPost.id,
              level: data.createPost.level,
              userInfos: {
                avatarProps: {
                  shape: 'circle',
                  alt: `${data.createPost.author.first_name} ${data.createPost.author.last_name}`,

                  src: profilePicture,
                  url: `/m/${authContext.userSlug}`,
                },
                modalProps: userInfos,
              },
              postLikes: data.createPost.usersLikedCount,
              commentDate: formatDateTimeAgo(data.createPost.createdAt),
              postAuthor: {
                username: data.createPost.author.username,
                name: `${data.createPost.author.first_name} ${data.createPost.author.last_name}`,
                jobTitle: data.createPost.author.title,
                avatar: {
                  src: profilePicture,
                },
                url: `/m/${data.createPost.author.username}`,
              },
              postText: data.createPost.content,
              postImages,
              fake: true,
              likesList: [],
            };

            postComments.push(postCommentContent);
            setCommentsState({
              ...commentsState,
              postComments,
              total: postComments.length,
              commentImages: postImages,
              commentsLoaded: true,
              loading: false,
            });
          }
        }
      } else {
        props.postSaved();
      }
    }
  };

  const PostText = () => {
    return (
      <PostTextWrapper>
        <Text as="div" size={14}>
          <CommunityPostBoxText
            dangerouslySetInnerHTML={{
              __html: `${
                !trimState.showOriginalHTML
                  ? `${trimState.trimmedHTML}${
                      trimState.originalHTML.length >= idealText &&
                      !trimState.showOriginalHTML
                        ? '...'
                        : ''
                    }`
                  : trimState.originalHTML
              }`,
            }}
          ></CommunityPostBoxText>
          {trimState.originalHTML.length >= idealText &&
            !trimState.showOriginalHTML && (
              <StyledButton onClick={handleShowText} actionType="onclick">
                <String id="community_post_box_read_more_label" />
              </StyledButton>
            )}
        </Text>
      </PostTextWrapper>
    );
  };

  const approveBtnText = <String id="general_approve_text" />;
  const rejectBtnText = <String id="general_reject_text" />;

  const onEditClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };
  const onReportClick = (postId, type) => {
    if (isPending) {
      props.onApproveClick(postId);
    } else {
      postReport({
        variables: {
          postId,
          type,
        },
      });
      setShowOptions(!showOptions);
    }
  };
  const onCopyClick = () => {
    navigator.clipboard.writeText(props.postUrl);

    if (!isVisible) {
      setIsVisible(true);
      handleUpdateClientNotification({
        type: 'success',
        text: 'copied_to_clipboard_text',
        autoDismiss: true,
        id: `notification-${new Date().getTime()}`,
      });
      setTimeout(() => {
        setIsVisible(false);
      }, 7000);
    }
  };
  const onOptionsClick = () => {
    setShowOptions(!showOptions);
    if (props.optionsOpened) {
      props.optionsOpened(props.parentId);
    }
  };

  const onSaveEditPostClick = data => {
    setShowOptions(false);
  };

  useEffect(() => {
    if (props.optionsOpenedState !== props.parentId) {
      setShowOptions(false);
    }
  }, [props.optionsOpenedState]);

  const onFavoriteClick = () => {
    let action = '';
    if (isFavorite) {
      action = 'remove';
    } else {
      action = 'add';
    }
    setIsFavorite(!isFavorite);

    props.onFavoriteClick(action);

    addRemoveFavorite({
      variables: {
        post_id: props.parentId,
        input: {
          action,
        },
      },
      refetchQueries: [
        {
          query: GET_FAVORITED_POSTS,
          variables: {
            username: authContext.userSlug,
          },
        },
      ],
    });
  };

  const onLikeClick = () => {
    let action = '';
    if (isLiked) {
      action = 'remove';
      setCommentsState({
        ...commentsState,
        likes: commentsState.likes - 1,
      });
    } else {
      action = 'add';

      setCommentsState({
        ...commentsState,
        likes: commentsState.likes + 1,
      });
    }
    setIsLiked(!isLiked);

    addRemoveReaction({
      variables: {
        post_id: props.parentId,
        input: {
          reaction: 'like',
          action,
        },
      },
    });
  };

  const onPreviousCommentsClick = () => {
    fetchMore({
      variables: {
        page: commentsState.page + 1,
        parent_id: props.parentId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (
          JSON.stringify(prev.posts.data) !==
          JSON.stringify(fetchMoreResult.posts.data)
        ) {
          const allPosts = [...prev.posts.data, ...fetchMoreResult.posts.data];
          fetchMoreResult.posts.data = allPosts;
          return fetchMoreResult;
        }
      },
    });
  };

  const onRejectClick = () => {
    props.setRejectedId(postId);
    props.onRejectClick();
  };

  useEffect(() => {
    if (props.moderatedId === postId) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [props.moderatedId]);

  const toggleReport = () => {
    setIsReporting(!isReporting);
  };

  const toggleDelete = () => {
    setIsDeleting(!isDeleting);
  };

  const onDeleteClick = () => {
    toggleDelete();
    props.onDeleteClick(postId);
  };

  const cancelBtnText = <String id="general_cancel_btn_text" />;
  const deleteBtnText = <String id="community_delete_post_text" />;

  const onDeleteCommentClick = (id, isComment) => {
    if (isComment) {
      const newArray = commentsState.postComments;
      let spliced = false;
      for (let i = 0; i < newArray.length; i++) {
        if (newArray[i].commentId === id) {
          newArray.splice(i, 1);
          spliced = true;
        }
      }
      setCommentsState({
        ...commentsState,
        postComments: newArray,
        total: spliced ? commentsState.total - 1 : commentsState.total,
      });
    }

    props.onDeleteClick(id);
  };

  const hideModal = () => {
    setIsEditing(false);
  };
  const isCurrentUserGroupMember = isGroupMember(
    communityState.myGroupsId,
    props.groupId
  );

  const isEditableByClient =
    authContext.userSlug === postAuthor?.username ||
    props.groupRole === 'moderator' ||
    props.groupRole === 'pre_approved' ||
    props.generalRole === 'admin' ||
    props.generalRole === 'editor';

  return (
    <CommunityPostContext.Provider value={[commentsState, setCommentsState]}>
      <StyledBox
        color="white"
        radius="medium"
        shadow={props.isSinglePost ? null : 'medium'}
        inModal={props.isInModal || props.isSinglePost}
        className={'post-' + postId}
        css={css}
      >
        <ModalContentWrapper inModal={props.isInModal || props.isSinglePost}>
          <CommunityPostBoxHeader>
            <ProfileBoxSmall
              name={postAuthor?.name}
              // jobTitle={postAuthor.jobTitle}
              avatar={postAuthor?.avatar}
              isCommunity={isCommunity}
              groupName={postAuthor?.groupName}
              isPost={isPost}
              alt={postAuthor?.name}
              url={postAuthor?.url}
              groupUrl={props.groupUrl}
              groupAlt={props.groupAlt}
            />
          </CommunityPostBoxHeader>
          <CommunityPostActionsBtn>
            <CommunityPostActionsBtnWrapper>
              {!props.hideOptions && (
                <Button
                  actionType="onclick"
                  colorTheme="transparent"
                  type="circle"
                  svgIcon="more"
                  onClick={onOptionsClick}
                />
              )}
            </CommunityPostActionsBtnWrapper>
            <OptionsWrapper ref={boxRef} isVisible={showOptions}>
              {isEditableByClient && (
                <OptionsBtns
                  type="normal"
                  colorTheme="transparent"
                  svgIcon="edit"
                  label={editPostText}
                  actionType="onclick"
                  onClick={onEditClick}
                />
              )}
              {authContext.isAuthenticated && (
                <OptionsBtns
                  type="normal"
                  colorTheme="transparent"
                  svgIcon={!isFavorite ? 'favorite' : 'favorite-filled'}
                  label={isFavorite ? removePostText : savePostText}
                  actionType="onclick"
                  onClick={onFavoriteClick}
                  isFavorite
                  isInFavorites={props.isInFavorites}
                />
              )}
              <OptionsBtns
                type="normal"
                colorTheme="transparent"
                svgIcon="chain"
                label={copyLinkText}
                actionType="onclick"
                onClick={onCopyClick}
              ></OptionsBtns>
              {isEditableByClient && (
                <OptionsBtns
                  type="normal"
                  colorTheme="transparent"
                  svgIcon="trash"
                  label={deletePostText}
                  actionType="onclick"
                  onClick={toggleDelete}
                  isDelete={true}
                />
              )}
              {props.isVerified && props.isOnboarded ? (
                <ReportBtns onClick={toggleReport}>
                  <Svg icon="report-flag" />
                  <span>
                    <StyledReportText size="14">
                      <String id="general_report_comments_text" />
                    </StyledReportText>
                    <Text size="12" css={{ color: '$mainTextColor' }}>
                      <String id="community_report_sub_text" />
                    </Text>
                  </span>
                </ReportBtns>
              ) : null}
            </OptionsWrapper>
          </CommunityPostActionsBtn>
          <CommunityPostBoxContent
            hasImage={imageState.postImages?.length > 0 && !props.isInModal}
            isInFavorite={props.isInFavorites}
          >
            {fontsReady && <PostText />}
            {!!(imageState.postImages?.length >= 1) && (
              <CommunityPostBoxImages
                userInfos={userInfos}
                postImages={imageState.postImages}
                groupId={props.groupId}
                postContent={
                  imageState.postImages.length >= 4 ? postContent : ''
                }
                commentImages={
                  imageState.postImages.length < 4 ? imageState.postImages : ''
                }
              />
            )}
            {imageState.postImages?.length === 0 &&
            postLinkPreview &&
            Object.keys(postLinkPreview).length >= 1 ? (
              <CommunityLinkPreview
                {...postLinkPreview}
                isSinglePost={props.isSinglePost}
              />
            ) : null}
          </CommunityPostBoxContent>
          <CommunityPostReactionsWrapper isPending={isPending}>
            {isPending ? (
              <PendingBtnWrapper>
                <div>
                  <Button
                    type="normal"
                    actionType="onclick"
                    onClick={onRejectClick}
                    colorTheme="gray"
                    label={rejectBtnText}
                    isDisabled={props.loading && isDisabled}
                  />
                  <Button
                    type="normal"
                    actionType="onclick"
                    onClick={() => {
                      onReportClick(postId);
                    }}
                    isDisabled={props.loading && isDisabled}
                    colorTheme="normal"
                    label={approveBtnText}
                    css={{ marginLeft: '$8' }}
                  />
                </div>
                <StyledDate size="12">{postDate}</StyledDate>
              </PendingBtnWrapper>
            ) : !props.isInFavorites ? (
              <CommunityReactionsBar
                myGroupsId={communityState.myGroupsId}
                groupId={props.groupId}
                groupUrl={props.groupUrl}
                groupName={props.groupAlt}
                postLikes={commentsState.likes}
                postDate={postDate}
                postComments={props.commentCount}
                onCommentClick={onCommentClick}
                showComments={showComments}
                onJoinGroup={props.onJoinGroup}
                triangleVisible={showComments}
                onLikeClick={onLikeClick}
                isLiked={isLiked}
                isLogged={authContext.isAuthenticated}
                isVerified={props.isVerified}
                isOnboarded={props.isOnboarded}
                total={commentsState.total}
                isCurrentUserGroupMember={isCurrentUserGroupMember}
                onFavoriteClick={onFavoriteClick}
                url={'/m/' + authContext.userSlug}
                isReported={props.isReported}
              />
            ) : null}
          </CommunityPostReactionsWrapper>
          {commentsState.total !== 0 &&
            showComments &&
            (commentsState.commentsLoaded ? (
              <CommunityCommentBox
                isCurrentUserGroupMember={isCurrentUserGroupMember}
                userInfos={userInfos}
                onSaveClick={onSaveClick}
                isAtBottom
                isLogged={isLogged}
                isVerified={props.isVerified}
                isOnboarded={props.isOnboarded}
                groupId={props.groupId}
                groupName={props.groupAlt}
                groupUrl={props.groupUrl}
                parentId={props.parentId}
                isComment
                onJoinGroup={props.onJoinGroup}
                loading={loading}
                onDeleteClick={onDeleteCommentClick}
                onPreviousCommentsClick={onPreviousCommentsClick}
                isReported={props.isReported}
                groupRole={props.groupRole}
                isBlocked={props.isBlocked}
                generalRole={props.generalRole}
                hasMorePages={commentsState?.hasMorePages}
                myGroupsId={communityState.myGroupsId}
              />
            ) : (
              <CommentWireFrame />
            ))}
          {loading && commentsState.commentsLoaded && <CommentWireFrame />}
          {commentsState.total === 0 && showComments ? (
            <CommunityAddPost
              {...userInfos}
              onSaveClick={onSaveClick}
              isAtBottom
              refetch={props.refetch}
              isLogged={isLogged}
              isVerified={props.isVerified}
              isOnboarded={props.isOnboarded}
              groupId={props.groupId}
              parentId={props.parentId}
              groupName={props.groupAlt}
              isComment={true}
              onJoinGroup={props.onJoinGroup}
              isCurrentUserGroupMember={isCurrentUserGroupMember}
              groupRole={props.groupRole}
              isBlocked={props.isBlocked}
              myGroupsId={communityState.myGroupsId}
            />
          ) : null}
          {authContext.isAuthenticated && !isPending ? (
            <Modal isShowing={isEditing} hide={hideModal}>
              <CommunityAddPostModal
                {...userInfos?.modalProps}
                hide={hideModal}
                refetch={props.refetch}
                imagesProp={imageState.postImages}
                content={postText}
                groupId={props.groupId}
                parentId={props.parentId}
                closeMenus={onSaveEditPostClick}
                isEdit
                postId={postId}
                myGroupsId={communityState.myGroupsId}
              />
            </Modal>
          ) : null}
        </ModalContentWrapper>
        <Modal isShowing={isReporting} hide={toggleReport} scrollable={false}>
          <PendingPublicationsModal
            closeModal={toggleReport}
            postId={props.parentId}
            isReporting
            onReportClick={onReportClick}
          />
        </Modal>
        <Modal isShowing={isDeleting} hide={toggleDelete}>
          <Text size="45" weight="bold" css={{ color: '$mainTextColor' }}>
            <String id="community_delete_post_message" />
          </Text>
          <DeleteBtnsContainer>
            <Button
              type="normal"
              colorTheme="gray"
              label={cancelBtnText}
              actionType="onclick"
              onClick={toggleDelete}
            />
            <Button
              type="normal"
              colorTheme="normal"
              label={deleteBtnText}
              actionType="onclick"
              onClick={onDeleteClick}
              css={{ marginLeft: '$16' }}
            />
          </DeleteBtnsContainer>
        </Modal>
      </StyledBox>
    </CommunityPostContext.Provider>
  );
};

CommunityPostBox.defaultProps = {
  isInFavorites: false,
  hideOptions: false,
  onFavoriteClick: () => {},
};

export default CommunityPostBox;
