import React, { useEffect, useRef, useState } from 'react';

import { BlockVideoWrapper } from './BlockVideo.styled';
import ReactPlayer from 'react-player';

const BlockVideo = props => {
  const [blockVideoState, setBlockVideoState] = useState();
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setBlockVideoState(ref.current.offsetWidth);
    }
  }, [ref.current]);
  return (
    <BlockVideoWrapper ref={ref}>
      <ReactPlayer
        width="100%"
        height={blockVideoState ? blockVideoState * 0.56 : 0}
        url={props.block.videoUrl}
        controls
        onError={error => {
          console.log('eror', error);
        }}
      />
    </BlockVideoWrapper>
  );
};
export default BlockVideo;
