import { styled } from '@root/stitches.config';
import { Link } from 'gatsby';

export const ThumbnailWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  minWidth: '204px',
  overflow: 'hidden',

  flex: '0',
  background: '$whiteToBlack',

  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  justifyContent: 'space-around',
  aspectRatio: '0.8',
  padding: '16px 26px',
  textAlign: 'center',
  borderRadius: '18px',
  overflow: 'hidden',
  transition: 'all 0.2s ease-out',
  border: '1px solid $thumbnailBorder',
  '&:hover': {
    boxShadow: '$3',
    transform: 'translate(0px, -4px)',
  },
});

export const ThumbnailContainer = styled('div', {
  position: 'relative',
  width: '100%',
});

export const ProfilePictureContainer = styled('div', {
  textAlign: 'center',
});

export const ThumbnailTop = styled(Link, {
  textDecoration: 'none',
  '&:hover': {
    '.profilePicture': {
      transform: 'scale(1.03)',
    },
  },
});

export const ThumbnailSocials = styled('div', {
  '.userThumbnailSocials': {
    width: '44px',
    minWidth: '44px',
    minHeight: '44px',
    height: '44px',
    marginBottom: '0',
  },
});

export const ProfilePictureImage = styled('div', {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '$16',
  transition: 'transform .4s ease',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '160px',
    height: '160px',
    background:
      'linear-gradient(213.69deg, #F3F3F3 10.18%, #B5B5B5 59.94%, #424242 107.68%)',
    display: 'block',
    borderRadius: '50%',
  },
});

export const AvatarWrapper = styled('div', {
  width: '152px',
  height: '152px',
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'relative',
});

export const Flag = styled('img', {
  width: '26px',
  overflow: 'hidden',
  marginRight: '$8',
});

export const LocationContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '$8',
});
