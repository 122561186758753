import {
  NextPrevBtn,
  PagesBtn,
  PaginationWrapper,
  ThreeDots,
} from './Pagination.styled';
import React, { useEffect, useState } from 'react';

import GetUrl from '@components/atoms/getUrl/GetUrl';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import { Text } from '../text/Text';

const Pagination = props => {
  let pagesArray = [];

  const [paginationState, setPaginationState] = useState({
    activePage: props.activePage,
    forward: true,
  });

  useEffect(() => {
    if (props.currentPage) {
      setPaginationState({
        activePage: props.currentPage,
        forward: props.currentPage !== props.page,
      });
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (props.activePage) {
      setPaginationState({
        ...paginationState,
        activePage: props.activePage,
      });
    }
  }, [props.activePage]);

  const isControlled = props.onCurrentPageChange !== undefined;

  useEffect(() => {
    if (isControlled) {
      props.onCurrentPageChange(paginationState.activePage);
    }
  }, [paginationState.activePage]);

  const changePagination = (current, max) => {
    const range = [];

    if (props.pages > 3) {
      if (current < 3) {
        for (let i = 1; i < 4; i++) {
          range.push(i);
        }
        range.push('...');
        range.push(max);
      } else if (current > max - 2) {
        range.push(1);
        range.push('...');

        for (let i = max - 2; i < max + 1; i++) {
          range.push(i);
        }
      } else {
        range.push(1);
        range.push('...');

        range.push(current - 1);
        range.push(current);
        range.push(current + 1);

        range.push('...');
        range.push(max);
      }
    } else {
      for (let i = 1; i < props.pages + 1; i++) {
        range.push(i);
      }
    }

    return range;
  };

  for (let i = 0; i < props.pages; i++) {
    pagesArray[i] = i + 1;
  }

  const onPageClick = e => {
    const lastActivePage = paginationState.activePage;
    if (lastActivePage > e) {
      setPaginationState({
        ...paginationState,
        activePage: e,
        forward: false,
      });
    } else {
      setPaginationState({
        ...paginationState,
        activePage: e,
        forward: true,
      });
    }
    if (!isControlled) {
      pagesArray = changePagination(paginationState.activePage, props.pages);
    }
    pagesArray = changePagination(paginationState.activePage, props.pages);

    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  const onNextClick = () => {
    if (paginationState.activePage !== props.pages) {
      if (!paginationState.forward) {
        setPaginationState({
          ...paginationState,
          activePage: paginationState.activePage + 1,
          forward: !paginationState.forward,
        });
      } else {
        setPaginationState({
          ...paginationState,
          activePage: paginationState.activePage + 1,
        });
      }
    }
    pagesArray = changePagination(paginationState.activePage, props.pages);

    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  const onPreviousClick = () => {
    if (paginationState.activePage !== 1) {
      if (paginationState.forward) {
        setPaginationState({
          ...paginationState,
          activePage: paginationState.activePage - 1,
          forward: !paginationState.forward,
        });
      } else {
        setPaginationState({
          ...paginationState,
          activePage: paginationState.activePage - 1,
        });
      }
    }
    pagesArray = changePagination(paginationState.activePage, props.pages);

    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  pagesArray = changePagination(paginationState.activePage, props.pages);

  const prevText = <String id="pagination_prev" />;
  const nextText = <String id="pagination_next" />;

  return (
    <PaginationWrapper>
      {props.pages > 0 && props.pages > 1 ? (
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul>
            <NextPrevBtn
              isDisabled={paginationState.activePage === 1}
              onClick={onPreviousClick}
            >
              {props.isUrlLink ? (
                <Link
                  alt="pagination"
                  to={GetUrl(
                    `page-${paginationState.activePage - 1}`,
                    UrlContext
                  )}
                  aria-label={`Goto Page ${paginationState.activePage - 1}`}
                >
                  {prevText}
                </Link>
              ) : (
                <Text>{prevText}</Text>
              )}
            </NextPrevBtn>
            {pagesArray.map(function (page, i) {
              if (page === '...') {
                return <ThreeDots key={`dots${i}`}>{page}</ThreeDots>;
              }
              return (
                <PagesBtn
                  active={paginationState.activePage === page}
                  key={i}
                  onClick={() => {
                    onPageClick(page);
                  }}
                >
                  <a
                    alt="pagination"
                    // to={
                    //   page === 1
                    //     ? GetUrl('/', UrlContext)
                    //     : GetUrl('page/' + page, UrlContext)
                    // }
                    aria-label={
                      paginationState.activePage === page
                        ? `Current Page, Page ${page}`
                        : `Goto Page ${page}`
                    }
                    aria-current={paginationState.activePage === page}
                  >
                    {page}
                  </a>
                </PagesBtn>
              );
            })}
            <NextPrevBtn
              isDisabled={paginationState.activePage === props.pages}
              onClick={onNextClick}
            >
              {props.isUrlLink ? (
                <Link
                  alt="pagination"
                  to={GetUrl(
                    `page-${paginationState.activePage + 1}`,
                    UrlContext
                  )}
                  aria-label={`Goto Page ${paginationState.activePage + 1}`}
                >
                  {nextText}
                </Link>
              ) : (
                <Text>{nextText}</Text>
              )}
            </NextPrevBtn>
          </ul>
        </nav>
      ) : null}
    </PaginationWrapper>
  );
};

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  isUrlLink: PropTypes.bool,
};

export default Pagination;
