import 'swiper/swiper.scss';

import {
  NextBtns,
  PrevBtns,
  StyledText,
  StyledTitle,
  SwiperContainer,
  ThumbnailSliderHeader,
  ThumbnailSliderWrapper,
} from './ThumbnailSlider.styled';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import Button from '@components/atoms/button/Button';
import GroupBox from '@components/molecules/groupBox/GroupBox';
import GroupBoxLoading from '@components/molecules/groupBox/GroupBoxLoading';
import PropTypes from 'prop-types';
import String from '@components/atoms/string/String';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';

SwiperCore.use([Navigation]);

const ThumbnailSlider = props => {
  const btnString = props.btnString ? (
    props.btnString
  ) : (
    <String id="general_see_all" />
  );
  const { btnUrl } = props;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [sliderState, setSlideState] = useState({
    slidesPerView: 4,
  });

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  function useCurrentWidth() {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
      let timeoutId = null;

      const howManySlides = () => {
        const width = getWidth();

        if (props.type) {
          if (width <= 1440 && width > 1150) {
            setSlideState({
              ...sliderState,
              slidesPerView: 3,
            });
          } else if (width <= 1150 && width > 768) {
            setSlideState({
              ...sliderState,
              slidesPerView: 2,
            });
          } else if (width <= 768) {
            setSlideState({
              ...sliderState,
              slidesPerView: 1.1,
            });
          } else {
            setSlideState({
              ...sliderState,
              slidesPerView: 4,
            });
          }
        } else {
          const wrapper = document.querySelector('.swiper-container');
          const wrapperWidth = wrapper.offsetWidth;
          let slidesPerView = 0;

          slidesPerView = (wrapperWidth / 186).toFixed(1);

          setSlideState({
            ...sliderState,
            slidesPerView,
          });
        }
      };
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(
          () =>
            setWidth(() => {
              howManySlides();
            }),
          150
        );
      };
      window.addEventListener('resize', resizeListener);

      howManySlides();

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }, []);
    return width;
  }

  const resize = useCurrentWidth();

  const isEnd = swiper => {
    setTimeout(() => {
      if (swiper && swiper.slides && prevRef.current) {
        if (swiper.activeIndex === 0) {
          prevRef.current.classList.add('is-invisible');
          nextRef.current.classList.remove('is-invisible');
        } else if (
          (swiper.activeIndex ===
            swiper.slides.length - (sliderState.slidesPerView - 1) &&
            swiper.isEnd) ||
          swiper.activeIndex === swiper.slides.length - 1 ||
          swiper.isEnd
        ) {
          prevRef.current.classList.remove('is-invisible');
          nextRef.current.classList.add('is-invisible');
        } else {
          prevRef.current.classList.remove('is-invisible');
          nextRef.current.classList.remove('is-invisible');
        }
      }
    }, 350);
  };

  return (
    <ThumbnailSliderWrapper className={props.className}>
      <ThumbnailSliderHeader needMargin={!props.type}>
        {props.type ? (
          <StyledTitle as="h3" weight="legendExtraBold">
            {props.title}
          </StyledTitle>
        ) : (
          <StyledText as="h3" weight="legendExtraBold">
            {props.title}
          </StyledText>
        )}
        <Button
          type="normal"
          colorTheme="normal"
          label={btnString}
          actionType={props.isInternalLink ? 'internalLink' : 'onclick'}
          onClick={props.onClick}
          internalUrl={btnUrl || '/'}
          css={{ display: 'none', '@bp2': { display: 'inline-flex' } }}
        />
      </ThumbnailSliderHeader>
      <SwiperContainer>
        <Swiper
          spaceBetween="2.2%"
          slidesPerView={sliderState.slidesPerView}
          watchSlidesVisibility
          speed={600}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
            isEnd(swiper);
          }}
          onSlideChangeTransitionStart={swiper => {
            isEnd(swiper);
          }}
          onSlideChangeStart={swiper => {
            isEnd(swiper);
          }}
          onResize={swiper => {
            isEnd(swiper);
          }}
        >
          {props.thumbnailList.map((thumbnail, i) => {
            if (typeof thumbnail === 'number') {
              return (
                <SwiperSlide key={`slide-${i} thumbnail-type${thumbnail.type}`}>
                  <GroupBoxLoading inSlider={true} />
                </SwiperSlide>
              );
            } else {
              if (props.type) {
                return (
                  <SwiperSlide
                    key={`slide-${i} thumbnail-type${thumbnail.type}`}
                  >
                    <Thumbnail key={thumbnail.id} displayType {...thumbnail} />
                  </SwiperSlide>
                );
              }
              return (
                <SwiperSlide key={`slide-${i} thumbnail-type${thumbnail.type}`}>
                  <GroupBox
                    {...thumbnail}
                    inSlider
                    myGroupsId={props.myGroupsId}
                    onJoinGroup={props.onJoinGroup}
                    isLogged={props.isLogged}
                    isVerified={props.isVerified && props.isOnboarded}
                    myGroupsJoinDate={props.myGroupsJoinDate}
                  />
                </SwiperSlide>
              );
            }
          })}
          {props.thumbnailList.length > sliderState.slidesPerView && (
            <>
              <PrevBtns className="prev-btn is-invisible" ref={prevRef}>
                <Button
                  type="circle"
                  colorTheme="normal"
                  actionType="onclick"
                  svgIcon="arrow-left-short"
                />
              </PrevBtns>
              <NextBtns className="next-btn is-invisible" ref={nextRef}>
                <Button
                  type="circle"
                  colorTheme="normal"
                  actionType="onclick"
                  svgIcon="arrow-right-short"
                />
              </NextBtns>
            </>
          )}
        </Swiper>
      </SwiperContainer>
      {props.thumbnailList.length > sliderState.slidesPerView && (
        <Button
          type="normal"
          colorTheme="gray"
          label={btnString}
          actionType="internalLink"
          internalUrl={btnUrl}
          css={{
            display: 'flex',
            marginTop: '$16',
            '@bp2': { display: 'none' },
          }}
        />
      )}
    </ThumbnailSliderWrapper>
  );
};

export default ThumbnailSlider;

ThumbnailSlider.propTypes = {
  title: PropTypes.object,
  type: PropTypes.string,
  thumbnailList: PropTypes.array,
  isInternalLink: PropTypes.bool,
  onClick: PropTypes.func,
};

ThumbnailSlider.defaultProps = {
  title: undefined,
  type: undefined,
  thumbnailList: [],
  isInternalLink: true,
  onClick: () => {},
};
