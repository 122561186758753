import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';

export const CreateSidebarWrapper = styled('div', {
  backgroundColor: '$bg',
  minWidth: '300px',
  borderLeft: '1px solid $lightGrey',
  height: 'auto',
  position: 'relative',
  background: '$mainBackgroundColor',

  '@bp2': {
    height: 'auto',
    width: '300px',
    minHeight: '100vh',
  },
});

export const CreateSidebarSticky = styled('div', {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const CreateSidebarContainer = styled('div', {
  // height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  variants: {
    currentStep: {
      editor: {
        justifyContent: 'flex-start',
      },
    },
  },
});

export const EditorStepHeader = styled('div', {
  marginBottom: '$16',
});

export const BlockImageWrapper = styled('div', {
  overflow: 'hidden',
  borderRadius: '$16',
  width: '100%',
  height: '100%',
  img: {
    borderRadius: '$16',
  },
  // position: 'absolute',
  // top: 0,
  // left: 0
});

export const StyledSmall = styled(Text, {
  display: 'block',
  opacity: 0.8,
  margin: '0 auto',
  marginTop: '$8',
  fontSize: '$12',
  textAlign: 'center',
  width: '80%',
});

export const Block = styled('div', {
  width: '94px',
  height: '66px',

  borderRadius: '$16',
  border: '1px solid $mediumGrey',
  mb: '$8',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '$bg',
  svg: {
    width: '24px',
    height: '24px',
    fill: '$mainTextColor',
  },
  img: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  '&:first-of-type': {
    marginLeft: 0,
  },
  '@bp2': {
    marginLeft: 0,
  },
  variants: {
    isActive: {
      isActive: {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: '4px solid $green',
          borderRadius: '$16',
          zIndex: 2,
        },
      },
    },
    isError: {
      true: {
        borderWidth: '4px',
        borderColor: '$red',
      },
    },
    isCover: {
      true: {
        opacity: 0.9,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: '4px solid $geen',
          borderRadius: '$16',
          zIndex: 2,
        },
      },
    },
  },
});

export const EditorStepBlocks = styled('div', {
  position: 'relative',

  '@bp2': {
    minHeight: '295px',
  },
  variants: {
    isMobile: {
      true: {
        display: 'grid',
        overflow: 'scroll',
        gap: '$4',
        'grid-template-columns': 'repeat(7, 1fr)',
        marginBottom: '$32',
      },
    },
  },
});

export const CreateSidebarContent = styled('div', {
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  width: '80%',
  paddingTop: '$32',

  '@bp2': {
    width: '160px',
  },
  variants: {
    currentStep: {
      editor: {
        paddingTop: '$32',
      },
      cover: {
        width: '100%',
        paddingTop: 0,
        '@bp2': {
          paddingTop: '$32',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  },
});

export const CreateSidebarFooter = styled('div', {
  padding: '$32',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: '$32',
  backgroundColor: '$whiteToBlack',
  flexDirection: 'column',
  width: '100%',
  '@bp2': {
    display: 'block',
    position: '-webkit-sticky',
    position: 'sticky',
    width: 'auto',
    bottom: '0',
    marginTop: '$24',
  },

  button: {
    flexBasis: 'auto',
    width: '100%',
    marginTop: '$16',
    '&:first-of-type': {
      marginTop: '$0',
    },
  },
});
