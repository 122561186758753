import { styled } from '@root/stitches.config';
import 'react-phone-input-2/lib/bootstrap.css';

export const PhoneVerificationWrapper = styled('div', {
  width: '100%',
  '@bp2': {
    maxWidth: '420px',
  },
});

export const PhoneInputWrapper = styled('div', {
  marginBottom: '$32',
  '.phone__verify__input': {
    fontSize: '15px',
    position: 'relative',
    width: ' 100%',

    '.form-control': {
      border: '1px solid $mediumGrey',
      borderRadius: '$8',
      width: '100%',
      backgroundColor: 'transparent',
      color: '$mainTextColor',
      outline: 0,

      ':focus': {
        borderColor: '$darkGrey',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },

      ' &.invalid-number': {
        borderColor: '$red',
      },
    },
    '.form-control:focus': {
      borderColor: '$darkGrey',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },

    '.country-list': {
      backgroundColor: '$box',
      marginTop: '0px',
      color: '$mainTextColor',

      '.country:hover': {
        background: '$elements',
      },
      '.country.highlight': {
        backgroundColor: '$white',
        color: '$black',
      },
      '.country .dial-code': {
        color: '$mediumGrey',
      },
    },
  },
});

export const CodeInputWrapper = styled('div', {
  display: 'flex',
  // justifyContent: 'space-between',
  justifyContent: 'center',
});

export const InputNumber = styled('input', {
  padding: '$16',
  marginRight: '$8',
  maxWidth: '45px',
  borderRadius: '$8',
  fontFamily: 'var(--ArtifaktElementRegular)',
  fontSize: '$16',
  lineHeight: '1.18em',
  boxShadow: 'none',
  appearance: 'none',
  textAlign: 'center',
  backgroundColor: 'transparent',
  color: '$mainTextColor',
  border: '1px solid $lightGrey',
  outline: '0',
  svg: {
    fill: '#gray900',
  },

  '&:last-of-type': {
    marginRight: 0,
  },

  '@bp1': {
    maxWidth: '64px',
  },

  '&:focus': {
    borderColor: '$mediumGrey',
  },
  '&:hover': {
    borderColor: '$mediumGrey',
  },
});
