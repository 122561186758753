//* ***********************//
//   SUCCESS EXCEPTIONS   //----------------------------------
//* ***********************//

const successMutationsExceptionsStrings = [
  'UpdateUserContent',
  'CreateUserContent',
  'UpdateMyOnBoarded',
  'DeleteUserContentBloc',
  'GetLinkEmbed',
  'PostReaction',
  'DeleteMyProfile',
  'UpdateNotificationsLastViewMutation',
  'UpdateMyTool',
  'UpdateMyIndustry',
  'UpdateUserEmail',
  'UpdateUserName',
  'UserContentReaction',
  'UserContentFavorite',
  'UpdateAboutMe',
  'AddUserDeviceKey',
  'DeleteUserDeviceKey',
  'DeleteNotificationMutation',
  'UpdateNotificationStatusMutation',
  'UserAcceptCreateTCMutation',
];

export function isSuccessException(operation, callback) {
  const isMutation = operation.query.loc.source.body.includes('mutation');

  const isContentFeatureMutation =
    operation.operationName === 'UpdateUserContent' &&
    Object.keys(operation?.variables?.input || {}).join('') === 'feature';

  const isCMSUserContentAction =
    operation.operationName === 'UserContentAction';
  const isPostFeatureMutation = operation.operationName === 'PostFavorite';
  const isUserContentFavoriteMutation =
    operation.operationName === 'UserContentFavorite';
  const isCMSGroupAction = operation.operationName === 'CMSGroupActions';
  const isCMSGroupPostAction = operation.operationName === 'CMSGroupPostAction';

  function specificMutationContent() {
    if (isContentFeatureMutation) {
      return `_feature_${operation?.variables?.input?.feature}`;
    }
    if (isPostFeatureMutation || isUserContentFavoriteMutation) {
      return `_${operation?.variables?.input?.action}`;
    }
    if (isCMSUserContentAction || isCMSGroupAction || isCMSGroupPostAction) {
      return `_${operation?.variables.action}`;
    }
    return '';
  }

  if (
    (isMutation &&
      !successMutationsExceptionsStrings.includes(operation.operationName)) ||
    isContentFeatureMutation
  ) {
    return callback({
      text: `client_notification_${
        operation.operationName
      }${specificMutationContent()}_success`,
      type: 'success',
      autoDismiss: true,
      id: `notification-${new Date().getTime()}`,
    });
  }
  return false;
}

//* *********************//
//   ERROR EXCEPTIONS   //----------------------------------
//* *********************//

const errorMutationsExceptionsStrings = [
  'UpdateUserContent',
  'CreateUserContent',
  'updateMySettings',
  'UpdateUserName',
  // 'GetLinkEmbed',
];

const errorDebugMessageExceptionStrings = [
  'could_not_create_user_content : inappropriate_content',
  'could_not_update_some_fields : inappropriate_content',
];

function errorContent(error, isCMSPageHeroImageTooLarge) {
  if (isCMSPageHeroImageTooLarge) {
    return 'cms_banner_image_too_large';
  }
  return error;
}

export function isErrorException(error, callback) {
  if (error.networkError?.statusCode === 401) {
    return error;
  }

  const isGraphQLError = error.graphQLErrors !== undefined;
  const gqlErrorDebugMessage = error?.graphQLErrors?.[0]?.debugMessage || '';

  const gqlErrorPath = error?.graphQLErrors?.[0]?.path?.[0] || '';

  const errorOperationName = error?.operation?.operationName || '';
  const networkErrorStatusText = error.networkError?.response?.statusText || '';
  const validationGQLError =
    (isGraphQLError && error?.graphQLErrors?.[0]?.extensions?.validation) || '';
  const isCoverImageTooLarge =
    validationGQLError &&
    validationGQLError['input.image.image']?.includes(
      'The input.image.image must not be greater than 1500 kilobytes.'
    );
  const isCMSPageHeroImageTooLarge =
    validationGQLError &&
    validationGQLError['input.background_image.image']?.includes(
      'The input.background image.image must not be greater than 1500 kilobytes.'
    );

  const isInvalidPreviewLink = errorOperationName === 'GetLinkEmbed';

  if (isInvalidPreviewLink) return null;

  if (isGraphQLError) {
    const errors = error.graphQLErrors.map(({ message }) => message).join(',');
    const extras = error.graphQLErrors.map(
      ({ exception }) => exception?.extra
    )?.[0];

    if (
      errorDebugMessageExceptionStrings.includes(gqlErrorDebugMessage) ||
      isCoverImageTooLarge
    ) {
      return null;
    }
    if (errorMutationsExceptionsStrings.includes(errorOperationName)) {
      return null;
    }

    callback({
      text: `client_notification_${errorContent(
        errors,
        isCMSPageHeroImageTooLarge
      )}_error`,
      type: 'error',
      autoDismiss: true,
      extras,
    });
  }

  if (
    errorMutationsExceptionsStrings.includes(errorOperationName) &&
    networkErrorStatusText === 'Request Entity Too Large'
  ) {
    return null;
  }
  console.log(error);

  // if (error.networkError.statusCode === 401) {
  //   callback({
  //     text: `client_notification_${errorContent(networkErrorStatusText)}_error`,
  //     type: 'error',
  //     autoDismiss: true,
  //   });
  // }

  return null;
}
