import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FileUploadWrapper, FileInfoWrapper } from './FileUpload.styled';
import String from '@components/atoms/string/String';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';

function FileUpload(props){
  const {
    fileType,
    maxSizeKb,
    onChange,
    onDelete,
    css,
    id,
    label,
    currentFileName,
    currentFileSizeKb,
  } = props;

  const formRef = useRef()

  const [error, setError] = useState({
    size: false,
    type: false,
  });

  const [file, setFile] = useState();
  
  const fileTypes = fileType.split(',').map(item => item.replace(/[^a-zA-Z0-9]/g, ''));

  function handleChange(e){
    const sizeToKb = (e.target.files[0]?.size || 0)/1024;
    const isTypeIncluded = fileTypes.map(item => (e.target.files[0]?.type || "").includes(item)).some(el => el === true);
    
    setError({
      size: sizeToKb > maxSizeKb,
      type: !isTypeIncluded
    });
    setFile(e.target.files[0]);

  }

  function handleDeleteFile(){
    setFile();
    if(formRef.current){
      formRef.current.reset()
    }
    onChange({
      hasError: false,
      file: undefined,
    })
  }

  useEffect(() => {
    if(file){
      onChange({
        hasError: Object.keys(error).some(item => error[item] === true),
        file,
      })
    }
  }, [file])

  return (
    <FileUploadWrapper css={css}>
      <form ref={formRef}>
        <FileInfoWrapper>
        <label htmlFor={id}>
          <Svg icon="upload" />
          <String id={label} />
        </label>
        <input id={id} type="file" accept={fileType} onChange={handleChange} />
        {!Object.keys(error).some(item => error[item] === true) && (
          <>
            {(file || (currentFileName && currentFileSizeKb)) ? (
              <>
                <div className="file-details">
                  <Text as="p">{currentFileName || file.name}</Text>
                  <Text as="p">{currentFileSizeKb || (file.size/1024).toFixed(0)} KO</Text>
                </div>
              </>
            ): (
              <Text as="p" className="file-input-info">
                Only {`"${fileType}"`} types of files. Max {`${maxSizeKb}`} KO.
              </Text>
            )}
          </>
        )}
      </FileInfoWrapper>
      {Object.keys(error).some(item => error[item] === true) && (
        Object.keys(error).map(item => {
          if(error[item]){
            return (
              <Text as="p" className="file-input-info file-input-error" key={item}>
                <String id={`file_input_error_${item}`}/>{item === 'size' ? ` ${maxSizeKb} KO` : ` ${fileType}`}
              </Text>
            )
          }
        })
      )}
      </form>
    </FileUploadWrapper>
  )
}

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  maxSizeKb: PropTypes.number,
  onChange: PropTypes.func,
  currentFileName: PropTypes.string,
  currentFileSizeKb: PropTypes.number,
}

FileUpload.defaultProps = {
  maxSizeKb: 1000,
  onChange: () => {},
  currentFileName: undefined,
  currentFileSizeKb: undefined,
}

export default FileUpload;