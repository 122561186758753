import { styled } from '@root/stitches.config';

export const InfoboxWrapper = styled('div', {
  display: 'inline-block',
  padding: '$4 $12',
  br: '$8',
  textAlign: 'center',
  backgroundColor: '$box',
  position: 'relative',
  zIndex: 3,

  '&:before': {
    content: '""',
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundColor: '$box',
    position: 'absolute',
    transform: 'translateX(-50%) rotate(45deg)',
    left: '50%',
    bottom: '-10%',
    zIndex: 0,
  },

  '& > *': {
    lineHeight: '1.3em',
    position: 'relative',
  },
});
