import {
  ContentInner,
  ContentWrapper,
  OnboardingWrapper,
  SkipOnboarding,
  SkipOnboardingWrapper,
  TitleWrapper,
} from './OnboardingLayout.styled';
import React, { useContext } from 'react';

import AuthContext from '@context/AuthContext';
import Layout from '@components/organisms/layout/Layout';
import Modal from '@components/atoms/modal/Modal';
import PublishNotification from '@components/molecules/publishNotification/PublishNotification';
import SEO from '@components/atoms/seo/Seo';
import SignAside from '@components/molecules/signAside/SignAside';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { navigate } from 'gatsby';
import useModal from '@components/atoms/modal/useModal';

const OnboardingLayout = ({
  children,
  sideBarTitle,
  pageTitle,
  displayAvatar,
  displaySkip,
  displayTags,
  displaySteps,
  industriesList,
  toolsList,
  percentage,
  pageContext,
}) => {
  const onSkipOnboarding = () => {
    toggle();
    //updateProfileIsOnboarded({ variables: { isOnboarded: true } });
  };

  const authContext = useContext(AuthContext);
  const { isShowing, toggle } = useModal();
  return (
    <Layout>
      <SEO title="Area - Onboarding" slug="onboarding" />
      <OnboardingWrapper className="container">
        <SignAside
          sideBarTitle={sideBarTitle}
          pageTitle={pageTitle}
          displayAvatar={displayAvatar}
          displaySteps={displaySteps}
          displayTags={displayTags}
          industriesList={industriesList}
          toolsList={toolsList}
          percentage={percentage}
        />
        <ContentWrapper>
          <ContentInner>
            <TitleWrapper>
              {displaySkip && (
                <SkipOnboardingWrapper>
                  <SkipOnboarding
                    type="normal"
                    label={<String id="onboarding_skip" />}
                    colorTheme="transparent"
                    onClick={onSkipOnboarding}
                    actionType="onclick"
                    svgIcon="arrow-right-short"
                    svgLocation="after"
                  />
                </SkipOnboardingWrapper>
              )}
              {pageTitle && (
                <Text
                  as="h1"
                  css={{
                    lineHeight: '1',

                    fontSize: '$24',
                    '@bp1': {
                      fontSize: '$48',
                    },
                  }}
                  weight="legendExtraBold"
                >
                  {pageTitle}
                </Text>
              )}
            </TitleWrapper>

            {children}
          </ContentInner>
        </ContentWrapper>
      </OnboardingWrapper>

      <Modal isShowing={isShowing} hide={toggle} hasImageHeader>
        <PublishNotification
          type={'completeOnboarding'}
          url={'/m/' + authContext.userSlug}
          onClose={onSkipOnboarding}
        />
      </Modal>
    </Layout>
  );
};

export default OnboardingLayout;
