import ForgotPasswordBox from '@components/organisms/forgotPasswordBox/ForgotPasswordBox';
import { ForgotPasswordPageWrapper } from './ForgotPassword.styled';
import Layout from '@components/organisms/layout/Layout';
import React from 'react';
import SEO from '@components/atoms/seo/Seo';

const ForgotPasswordPage = context => {
  return (
    <Layout location={context.location}>
      <SEO title="Area - Forgot Password" slug="forgot-password" seomatic={context?.pageContext?.entry?.seomatic} />
      <ForgotPasswordPageWrapper className="container">
        <ForgotPasswordBox />
      </ForgotPasswordPageWrapper>
    </Layout>
  );
};

export default ForgotPasswordPage;
