import { GET_ALL_GROUPS, GET_MY_GROUPS } from '@src/apollo/query';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { getImageSrc } from '@src/utils/getImageSrc';
import AuthContext from '@context/AuthContext';
import GroupListing from '@components/molecules/groupListing/GroupListing';
import { JOIN_GROUPS } from '@src/apollo/mutation';
import Layout from '@components/organisms/layout/Layout';
import SEO from '@components/atoms/seo/Seo';
import { navigate } from 'gatsby';
import CommunityFeedContext from '@context/CommunityFeedContext';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';

const GroupsPage = context => {
  const getCurrentFilter = () => {
    if (context.pageContext.matchPath) {
      return 2;
    }
    return 1;
  };

  const [communityState, setCommunityState] = useState({
    myGroupsId: [],
  });

  const [groupsDataState, setGroupsDataState] = useState({
    allGroups: [],
    allGroupsShown: [],
    allGroupsLoaded: false,
    isActive: getCurrentFilter(),
    allLoaded: false,
  });

  const [myGroupsDataState, setMyGroupsDataState] = useState({
    allMyGroups: [],
    myGroupsLoaded: false,
    myGroupsId: [],
    myGroupsJoinDate: [],
  });
  const authContext = useContext(AuthContext);

  const { loading, error, data, refetch } = useQuery(GET_ALL_GROUPS, {
    context: { clientName: 'public' },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      const { groups } = data;
      const groupsList = [];
      for (let index = 0; index < groups.length; index++) {
        const element = groups[index];

        const singleGroup = {
          groupTitle: element.name,
          description: element.description,
          groupId: element.id,
          imageGroupIcon: getImageSrc(
            element.icon,
            'group_icon',
            element.title
          ),
          imageGroupHead: {
            ...getImageSrc(
              element.header_image,
              'group_header_medium',
              element.name
            ),
            url: `${element.slug}`,
          },
        };

        groupsList.push(singleGroup);
      }

      if (authContext.isAuthenticated) {
        setGroupsDataState({
          ...groupsDataState,
          allGroups: groupsList,
          allGroupsLoaded: true,
        });
      } else {
        setGroupsDataState({
          ...groupsDataState,
          allGroups: groupsList,
          allGroupsLoaded: true,
        });

        setMyGroupsDataState({
          ...myGroupsDataState,
          myGroupsLoaded: true,
        });
      }
    }
  }, [data]);

  const myGroupsData = useQuery(GET_MY_GROUPS, {
    context: { clientName: 'private' },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (
      myGroupsData.data &&
      authContext.isAuthenticated &&
      authContext.isVerified
    ) {
      const groups = myGroupsData.data.mygroups;
      const myGroupsList = [];
      const myGroupsIdList = [];
      const myGroupsJoinDate = [];
      for (let index = 0; index < groups.length; index++) {
        const element = groups[index];

        const singleGroup = {
          groupTitle: element.name,
          description: element.description,
          imageGroupIcon: getImageSrc(
            element.icon,
            'group_icon',
            element.title
          ),
          imageGroupHead: {
            ...getImageSrc(
              element.header_image,
              'group_header_medium',
              element.name
            ),
            url: `${element.slug}`,
          },
          groupId: element.id,
        };
        myGroupsIdList.push(element.id);
        myGroupsList.push(singleGroup);
        myGroupsJoinDate.push({ id: element.id, date: element.join_date });
      }
      setCommunityState({
        myGroupsId: myGroupsIdList,
      });

      setMyGroupsDataState({
        ...myGroupsDataState,
        allMyGroups: myGroupsList,
        myGroupsId: myGroupsIdList,
        myGroupsLoaded: true,
        myGroupsJoinDate: myGroupsJoinDate,
      });
    } else {
      setMyGroupsDataState({
        ...myGroupsDataState,
        myGroupsLoaded: true,
      });
    }
  }, [myGroupsData.data]);

  useEffect(() => {
    if (groupsDataState.allGroupsLoaded && myGroupsDataState.myGroupsLoaded) {
      if (context.pageContext.matchPath) {
        if (authContext.isAuthenticated) {
          const groupsList = myGroupsDataState.allMyGroups;

          setGroupsDataState({
            ...groupsDataState,
            allGroupsShown: groupsList,
            allLoaded: true,
          });
        } else {
          navigate('/login');
        }
      } else {
        const groupsList = groupsDataState.allGroups;

        setGroupsDataState({
          ...groupsDataState,
          allGroupsShown: groupsList,
          allLoaded: true,
        });
      }
    }
  }, [groupsDataState.allGroupsLoaded, myGroupsDataState.myGroupsLoaded]);

  const onFilterClick = e => {
    if (e === 1) {
      window.history.replaceState(
        null,
        null,
        context.path.replace('my-groups', '')
      );
      setGroupsDataState({
        ...groupsDataState,
        allGroupsShown: groupsDataState.allGroups,
      });
    } else {
      window.history.replaceState(
        null,
        null,
        `${context.path.replace('my-groups/', '')}my-groups/`
      );
      setGroupsDataState({
        ...groupsDataState,
        allGroupsShown: myGroupsDataState.allMyGroups,
      });
    }
  };

  const filtersList = [
    ...(authContext.isAuthenticated
      ? [
          {
            title: 'All groups',
          },
          {
            title: 'My groups',
          },
        ]
      : []),
  ];

  const [joinGroups, { dataGroups }] = useMutation(JOIN_GROUPS, {
    refetchQueries: [{ query: GET_MY_GROUPS }],
  });

  const onJoinGroup = id => {
    const newArray = myGroupsDataState.myGroupsId;
    newArray.push(id);
    setGroupsDataState({
      ...groupsDataState,
      myGroupsId: newArray,
    });
    authContext.isVerified
      ? joinGroups({ variables: { groups: myGroupsDataState.myGroupsId } })
      : null;
  };

  return (
    <Layout location={context.location}>
      <SEO
        title="Community Groups"
        slug="groups"
        seomatic={context?.pageContext?.entry?.seomatic}
      />
      <CommunityFeedContext.Provider
        value={[communityState, setCommunityState]}
      >
        <LandingPageWrapper>
          <SectionWrapper>
            <GroupListing
              title="groups_page_title"
              intro="groups_page_intro"
              groupList={groupsDataState.allGroupsShown}
              filtersList={filtersList}
              type="groups"
              myGroupsId={communityState.myGroupsId}
              myGroupsJoinDate={myGroupsDataState.myGroupsJoinDate}
              onFilterClick={onFilterClick}
              isActive={groupsDataState.isActive}
              loadingComplete={groupsDataState.allLoaded}
              onJoinGroup={onJoinGroup}
              isLogged={authContext.isAuthenticated}
              isVerified={authContext.isAuthenticated && authContext.isVerified}
              isOnboarded={
                authContext.isAuthenticated &&
                JSON.parse(localStorage.getItem('isOnboarded')) === 1
              }
            />
          </SectionWrapper>
        </LandingPageWrapper>
      </CommunityFeedContext.Provider>
    </Layout>
  );
};
export default GroupsPage;
