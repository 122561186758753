import {
  PhoneInputWrapper,
  PhoneVerificationWrapper,
} from './UserPhoneVerification.styled';
import React, { useContext, useState } from 'react';

import AuthContext from '@context/AuthContext';
import Button from '@components/atoms/button/Button';
import { ErrorMsg } from '../userSignUpForm/UserSignUpForm.styled';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import PhoneInput from 'react-phone-input-2';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import UrlsContext from '@context/UrlsContext';
import { navigate } from 'gatsby';

const PhoneVerificationForm = () => {
  const [PhoneVerifyFormState, setPhoneVerifyFormState] = useState({
    phoneNumber: '',
    isLoading: false,
  });
  const UrlContext = useContext(UrlsContext);

  const handleAuthError = response => {
    switch (response.error) {
      case 'the_phone_number_field_is_required':
        setPhoneVerifyFormState({
          ...PhoneVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_by_phone_error_required" />,
          isLoading: false,
          phoneErrorMsg: null,
        });
        break;
      case 'the_phone_number_must_be_a_string':
        setPhoneVerifyFormState({
          ...PhoneVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_by_phone_error_not_string" />,
          isLoading: false,
          phoneErrorMsg: null,
        });
        break;
      case 'phone_verification_failed':
        setPhoneVerifyFormState({
          ...PhoneVerifyFormState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: <String id="verify_by_phone_verification_failed" />,
          isLoading: false,
          phoneErrorMsg: null,
        });
        break;

      default:
        break;
    }
  };

  const handleAuthSuccess = () => {
    setPhoneVerifyFormState({
      ...PhoneVerifyFormState,
      isLoading: false,
      isAuthError: false,
      authErrorMessage: null,
      isLoading: false,
      phoneErrorMessage: null,
    });

    navigate(GetUrl('verifySmsCode', UrlContext));
  };

  const handlePhoneNumberChange = value => {
    if (!value.startsWith('+')) value = '+'.concat(value);
    setPhoneVerifyFormState({
      ...PhoneVerifyFormState,
      phoneNumber: value,
    });
  };

  const handleFormSubmit = (e, authPhoneVerificationFn) => {
    e.preventDefault();

    setPhoneVerifyFormState({
      ...PhoneVerifyFormState,
      isAuthError: false,
      isLoading: true,
    });

    authPhoneVerificationFn({
      phoneNumber: PhoneVerifyFormState.phoneNumber,
      errorCallback: handleAuthError,
      successCallback: handleAuthSuccess,
    });
  };

  return (
    <PhoneVerificationWrapper>
      <AuthContext.Consumer>
        {auth => (
          <form onSubmit={e => handleFormSubmit(e, auth.verifyByPhone)}>
            <PhoneInputWrapper
              css={{ opacity: PhoneVerifyFormState.isLoading ? 0.3 : 1 }}
            >
              <PhoneInput
                country="us"
                containerClass="phone__verify__input"
                value={PhoneVerifyFormState.phoneNumber}
                onChange={handlePhoneNumberChange}
                enableLongNumbers
                inputProps={{
                  required: true,
                  autoFocus: true,
                  prefix: '+',
                }}
              />
            </PhoneInputWrapper>
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <Button
                type="normal"
                value="Submit"
                label={<String id="verify_by_phone_validation_button" />}
                colorTheme="normal"
                notificationBubbleActive={false}
                actionType="onclick"
                isLoading={PhoneVerifyFormState.isLoading}
                isDisabled={PhoneVerifyFormState.isLoading}
                css={{
                  width: '100%',
                  marginBottom: '$24',
                }}
              />
            </div>

            {PhoneVerifyFormState.isAuthError ? (
              <ErrorMsg>{PhoneVerifyFormState.authErrorMessage}</ErrorMsg>
            ) : null}
          </form>
        )}
      </AuthContext.Consumer>
    </PhoneVerificationWrapper>
  );
};

const UserPhoneVerification = props => {
  return (
    <div>
      <Text size="14" css={{ lineHeight: '1.5em', marginBottom: '$24' }}>
        <String id="verify_by_phone_validation_text" />
      </Text>
      <PhoneVerificationForm />
    </div>
  );
};

export default UserPhoneVerification;
