import {
  CheckboxFilterWrapper,
  CheckboxOptions,
} from '../AdvanceFilters.styled';

import { Heading } from '@components/atoms/heading/Heading';
import InputCheckBox from '@components/atoms/input/inputCheckBox/InputCheckBox';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@components/atoms/text/Text';

const CheckboxFilter = props => {
  return (
    <CheckboxFilterWrapper>
      <Text
        css={{
          textTransform: 'uppercase',
          color: '$mediumGrey',
          marginBottom: '$8',
        }}
        size="14"
        level="h6"
        weight="bold"
      >
        {props.title}
      </Text>
      <CheckboxOptions>
        {props.options.map((option, index) => {
          return (
            <InputCheckBox
              css={{
                marginRight: '$16',
                marginBottom: '$8',
                fontSize: '14px',
              }}
              checked={option.isChecked}
              key={option.value + option.label}
              onChange={props.onChange}
              value={option.value}
            >
              {option.label}
            </InputCheckBox>
          );
        })}
      </CheckboxOptions>
    </CheckboxFilterWrapper>
  );
};

CheckboxFilter.propTypes = {};

export default CheckboxFilter;
