import { Heading } from '@components/atoms/heading/Heading';
import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';

export const StyledHeading2 = styled(Heading, {
  marginTop: '$16',
});

export const StyledHeading6 = styled(Heading, {
  fontWeight: '700',
});

export const GuidelineModalContent = styled('div', {
  '.community_guidelines_svg': {
    minWidth: '$24',
    maxWidth: '$24',
    height: '$24',
    marginRight: '$8',
  },
  '.community_guidelines_actions': {
    marginTop: '$40',
  },
  '.community_guidelines_content': {
    padding: '$8 0',
    [`${Text}:not(:first-child)`]: {
      marginTop: '$8',
    },
  },
  '.check': {
    fill: '$darkGrey',
  },
  '.close-big': {
    fill: '$red',
  },
});
