import {
  ContentDataEditActions,
  ContentDataEditFooter,
  ContentDataEditWrapper,
  IconAndInputWrapper,
  PreviewIcon,
  PreviewIconWrapper,
  BtnWrapper,
} from './ContentDataEdit.styled';
import React, { useContext, useEffect, useState } from 'react';

import Button from '@components/atoms/button/Button';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import ImageUploading from 'react-images-uploading';
import { InputText } from '@components/atoms/input/inputText/InputText';
import PropTypes from 'prop-types';
import { Text } from '@components/atoms/text/Text';

const ContentDataEdit = props => {
  const [images, setImages] = React.useState([]);
  const [error, setError] = React.useState({
    isError: false,
  });

  const [fieldsData, setFieldsData] = React.useState([]);
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  useEffect(() => {
    const fieldsData = [];
    props.fields.forEach(field => {
      fieldsData.push({
        name: field.name,
        value: field.value,
        type: field.type,
        label: field.label,
      });
    });

    setFieldsData(fieldsData);
  }, []);

  const onDrop = (name, images) => {
    const updatedCurrentData = [...fieldsData];
    updatedCurrentData.forEach(field => {
      if (field.name == name) {
        field.value = images[0];
      }
    });

    setFieldsData(updatedCurrentData);
  };

  const onChangeText = (id, name, e) => {
    const updatedCurrentData = [...fieldsData];
    updatedCurrentData.forEach(field => {
      if (field.name == name) {
        field.value = e.currentTarget.value;
      }
    });

    setFieldsData(updatedCurrentData);
  };

  const onSave = id => {
    props.onSave(id, fieldsData);
  };

  const isFieldsFilled = fieldsData.some(field => {
    return field.value == '';
  });

  useEffect(() => {
    if (error.isError) {
      handleUpdateClientNotification({
        type: 'error',
        text: `client_notification_content_data_edit_${
          error.acceptType ? 'type' : 'resolution'
        }_image_error`,
        autodismiss: true,
      });
    }
  }, [error]);

  return (
    <ContentDataEditWrapper>
      {fieldsData.map((field, i) => {
        if (field.type == 'text') {
          return (
            <InputText
              label={field.label}
              value={field.value}
              name={`${field.name}`}
              onChange={onChangeText.bind(null, props.id, field.name)}
              key={props.id}
              css={{ marginBottom: '$32' }}
            ></InputText>
          );
        }
        if (field.type == 'image') {
          return (
            <>
              <Text size="14" css={{ marginBottom: '$8' }}>
                {field.label}
              </Text>
              <IconAndInputWrapper>
                {field.value && (
                  <PreviewIconWrapper>
                    <PreviewIcon
                      src={
                        field.value.data_url
                          ? field.value.data_url
                          : field.value
                      }
                    />
                  </PreviewIconWrapper>
                )}
                <div>
                  <ImageUploading
                    value={images}
                    onChange={onDrop.bind(null, field.name)}
                    maxNumber={1}
                    dataURLKey="data_url"
                    resolutionType="more"
                    resolutionWidth="128"
                    acceptType={['jpeg', 'jpg', 'gif', 'png']}
                    onError={(e, a, b) => {
                      setError({ ...e, isError: true });
                    }}
                  >
                    {({ onImageUpload, dragProps }) => (
                      <Button
                        type="normal"
                        colorTheme="normal"
                        svgIcon="image"
                        actionType="onclick"
                        label="Upload"
                        onClick={onImageUpload}
                      />
                    )}
                  </ImageUploading>
                  <Text
                    size="12"
                    css={{
                      mt: '$8',
                      color: error.isError ? '$red' : '$mainTextColor',
                    }}
                  >
                    We support JPG, GIF or PNG. Max file size is 1.5mb. Minimum
                    width of 128px.
                  </Text>
                </div>
              </IconAndInputWrapper>
            </>
          );
        }
      })}
      <ContentDataEditFooter>
        <Button
          actionType="onclick"
          colorTheme="normal"
          onClick={onSave.bind(null, props.id)}
          label={'Confirm'}
          type="normal"
          css={{ marginRight: '$32' }}
          isDisabled={isFieldsFilled}
        />
        <Button
          actionType="onclick"
          colorTheme="gray"
          onClick={props.toggleEdit}
          label={'Cancel'}
          type="normal"
        />
      </ContentDataEditFooter>
    </ContentDataEditWrapper>
  );
};

ContentDataEdit.propTypes = {};

export default ContentDataEdit;
