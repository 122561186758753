import { styled } from '@root/stitches.config';

export const EditorAddBlocksWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '90%',
  margin: '0 auto',
  marginTop: '$64',
  '@bp2': {
    width: '80%',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    backgroundImage: 'linear-gradient(to right, $box 33%, $lightGrey 0%)',
    backgroundPosition: 'bottom',
    backgroundSize: '15px 2px',
    backgroundRepeat: 'repeat-x',
  }
});

export const ButtonsWrapper = styled('div', {
  display: 'flex',
  '.arrow-regular': {
    left: 'unset'
  },
  variants: {
    isOpen: {
      true: {
        marginRight: '$64',
      }
    }
  }
})

export const ActionsWrapper = styled('div', {
  display: 'flex',
  zIndex: 1,
  padding: '$4',
  background: '$blackToWhite',
  br: 100, 
  width: 'fit-content',
  margin: '0 auto',
  maxWidth: '330px',
  '@bp1': {
    maxWidth: '465px',
  },
  transition: 'width 1s ease-in', 
  justifyContent: 'space-between',
  variants: {
    isDisabled: {
      true: {
        background: '$mediumGrey'
      }
    }
  }
})
