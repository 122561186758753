import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactionNotification from '@components/molecules/notificationBox/types/ReactionNotification';
import String from '@components/atoms/string/String';

import {
  NotificationBoxWrapper,
  RightSideWrapper,
  RedBubble,
  ActionsContainer,
  ActionsBtn,
  ActionsToggleBtn,
  DateText,
} from './NotificationBox.styled';
import { useOnClickOutside } from '@src/utils/useOnClickOutside';
import Svg from '@components/atoms/svg/Svg';

const NotificationBox = props => {
  const [notificationState, setNotificationState] = useState({
    isNew: props.isNew,
    isActionsOpened: false,
  });

  const [isShown, setIsShown] = useState(false);

  const onThreeDotsClick = () => {
    setNotificationState({
      ...notificationState,
      isActionsOpened: !notificationState.isActionsOpened,
    });
  };

  const boxRef = useRef();
  useOnClickOutside(boxRef, () => setNotificationState({
    ...notificationState,
    isActionsOpened: false,
  }));

  const onRemoveNewClick = notificationId => {
    if (notificationState.isNew) {
      setNotificationState({
        ...notificationState,
        // isNew: !notificationState.isNew,
        isActionsOpened: false,
      });
    }
    const status = !props.isNew ? 'sent' : 'read';
    props.onRemoveNewClick(notificationId, status);
  };

  const onDeleteNotificationClick = notificationId => {
    props.onDeleteNotificationClick(notificationId);
    onThreeDotsClick();
  };

  return (
    <NotificationBoxWrapper
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      {/* {props.type === 'published' ? (
        <PublishedNotification
          {...props}
          onRemoveNewClick={() => {
            onRemoveNewClick(props.notificationId);
          }}
          notifiedAbout={
            props.type === 'comment' ? typePublished : notifiedAbout
          }
          reactionType="comment"
        />
      ) : null} */}

      <ReactionNotification
        {...props}
        reactionType={props.contentType}
        onRemoveNewClick={() => {
          onRemoveNewClick(props.notificationId);
        }}
        notifiedAbout={props.title}
        subject={props.subject}
      />

      <RightSideWrapper>
        <ActionsToggleBtn
          type="circle"
          colorTheme="gray"
          actionType="onclick"
          svgIcon="three-dots"
          onClick={onThreeDotsClick}
          isVisible={isShown || notificationState.isActionsOpened}
        />
        <DateText>{props.notificationDate}</DateText>
        <ActionsContainer
          ref={boxRef}
          isVisible={notificationState.isActionsOpened}
        >
          <ActionsBtn
            isVisible={true}
            onClick={() => {
              onRemoveNewClick(props.notificationId);
            }}
          >
            <Svg icon={props.isNew ? 'visibility-on' : 'visibility-off'} />
            <String
              id={
                props.isNew
                  ? 'notification_mark_as_read'
                  : 'notification_mark_as_unread'
              }
            />
          </ActionsBtn>
          <ActionsBtn
            isVisible={true}
            onClick={() => {
              onDeleteNotificationClick(props.notificationId);
            }}
          >
            <Svg icon="close-small" />
            <String id="notification_delete_this_notification" />
          </ActionsBtn>
        </ActionsContainer>
      </RightSideWrapper>
      <RedBubble isVisible={props.isNew} />
    </NotificationBoxWrapper>
  );
};

NotificationBox.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  notificationDate: PropTypes.string,
  type: PropTypes.string,
  avatarProps: PropTypes.object,
  isNew: PropTypes.bool,
  reactionType: PropTypes.string,
  author: PropTypes.string,
  notificationId: PropTypes.number,
  onDeleteNotificationClick: PropTypes.func,
  notifiedAbout: PropTypes.string,
  shortDescription: PropTypes.string,
};

export default NotificationBox;
