import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';

export const GroupBGImage = styled('div', {
  position: 'relative',
  width: '100%',
  maxHeight: '200px',

  '&:first-child': {
    height: '180px',
  },

  img: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },

  '@bp3': {
    height: '240px',
    maxHeight: '240px',
    '&:first-child': {
      height: '240px',
    },
  },
});
export const ImageWrapper = styled('div', {
  '&:after': {
    content: '""',

    // width: '100%',
    // height: '100%',
    // position: 'absolute',
    // top: '0px',
    // left: '0px',
    // background: 'linear-gradient(180deg, rgba(31, 31, 31, 0) 0%, #1F1F1F 100%)',
  },
});
export const GroupHeaderWrapper = styled('div', {
  display: 'block',
  position: 'relative',
  width: '100%',
  height: '180px',

  '@bp3': {
    height: '240px',
  },

  // '@bp2': {
  //   zIndex: '1',
  // },
});

export const GroupHeaderInner = styled('div', {
  display: 'flex',
  // justifyContent: 'center',
  width: '100%',
  height: '200px',
  color: '$white',
  position: 'absolute',
  '@bp3': {
    height: '240px',
  },
});

export const FakeColumn = styled('div', {
  display: 'none',
  marginRight: '$32',
  minWidth: '242px',

  '@bp2': {
    display: 'block',
  },
  '@bp3': {
    display: 'block',
    minWidth: 'unset',
  },
  '@bp4': {
    marginRight: '$84',
    minWidth: '242px',
  },
});

export const GroupTitleWrapper = styled('div', {
  width: '100%',
  position: 'absolute',
  // justifyContent: 'space-between',
  bottom: '-$32',
  textAlign: 'center',

  '@bp2': {
    display: 'grid',
    bottom: '-$92',
    left: '50%',
    transform: 'translateX(-50%)',
    gridTemplateColumns: '260px 1fr',
    gridGap: '$84',
    maxWidth: '$container',
    margin: '0 auto',
    padding: '0 $32',
  },
  '@bp3': {
    padding: '0 $72',
  },
  '@bp5': {
    padding: '0px',
  },
});

export const GroupTitle = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pt: '$48',
  '@bp2': {
    margin: '0 auto',
    maxWidth: '$container',
    display: 'grid',
    ai: 'flex-end',
    gridTemplateColumns: '260px 640px 1fr',
    gridGap: '$84',
    pt: '$48',
    lineHeight: '1.3',
    px: '$84',
  },
  '@bp6': {
    px: '$64',
  },
});

export const GroupTitleCenter = styled('div', {
  h3: {
    textAlign: 'center',
  },

  '@bp2': {
    display: 'grid',
    ai: 'flex-end',
    gridTemplateColumns: '2fr 1fr',
    gridGap: '$24',
    h3: {
      textAlign: 'left',
    },
  },
});

export const IconImage = styled('div', {
  position: 'relative',
  display: 'inline-block',

  width: '84px',
  height: '84px',
  border: '4px solid $white',
  borderRadius: '$8',
  // marginBottom: '$16',
  img: {
    width: '100%',
    //borderRadius: '$8',
  },

  '@bp2': {
    width: '128px',
    height: '128px',
    border: '8px solid $white',
    // marginBottom: '$24',
  },
});

export const GroupActions = styled('div', {
  position: 'absolute',
  top: '70px',
  right: '$16',

  '@bp2': {
    position: 'relative',
    display: 'flex',
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export const ActionsMenuWrapper = styled('div', {
  position: 'absolute',
  right: '0',
  padding: '0 $24 $16',
  backgroundColor: '$box',
  borderRadius: '$8',
  flexDirection: 'column',
  boxShadow: '$2',
  display: 'none',
  minWidth: '200px',
  bottom: '-35px',
  zIndex: 1,

  '@bp2': {
    bottom: '-54px',
  },

  variants: {
    isVisible: {
      true: {
        display: 'flex',
      },
    },

    isAdmin: {
      true: {
        bottom: '-70px',

        '@bp2': {
          bottom: '-94px',
        },
      },
    },

    makeLower: {
      true: {
        bottom: '-106px',

        '@bp2': {
          bottom: '-130px',
        },
      },
    },
  },
});

export const JoinedLabel = styled('p', {
  fontWeight: '700',
  fontSize: '$14',
  color: '$darkGrey',
  marginRight: '$32',
  display: 'none',

  variants: {
    isForDesktop: {
      true: {
        '@bp2': {
          display: 'block',
        },
      },
    },

    isForMobile: {
      true: {
        display: 'block',
        textAlign: 'center',
        marginTop: '$24',
        marginRight: '0',

        '@bp2': {
          display: 'none',
        },
      },
    },
  },
});

export const StyledHeading = styled(Text, {
  fontSize: '$24',
  '@bp3': {
    fontSize: '$36',
  },
});

export const StyledBtn = styled(Button, {
  marginRight: '$16',
  display: 'none',

  '& > span:first-of-type': {
    height: '$24',
  },

  '@bp2': {
    display: 'flex',
  },
});

export const StyledBtnMobile = styled(Button, {
  margin: '$16',
  width: 'calc(100% - 2rem)',

  '& > span:first-of-type': {
    height: '$24',
  },
  '@bp2': {
    display: 'none',
  },
});

export const StyledMenuBtn = styled(Button, {
  color: '$mainTextColor',
  padding: '$16 0 0 !important',
  justifyContent: 'left',
  textTransform: 'unset',
  minHeight: 'unset !important',
  zIndex: '$1',

  '&:hover': {
    backgroundColor: 'transparent !important',
    opacity: '0.7',
  },
});
