import {
  CompWrapper,
  LinksWrapper,
  ProfileDataContent,
  ProfileDataWrapper,
} from './AccountSettingsSidebar.styled';

import Avatar from '@components/atoms/avatar/Avatar';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';
import { formatDate } from '@src/utils/formatDate';

function AccountSettingsSidebar(props) {
  const { profileData, links } = props;

  return (
    <CompWrapper>
      <ProfileDataWrapper>
        <Avatar
          alt={`${profileData.firstName} ${profileData.lastName}`}
          src={profileData.avatar}
          size="small"
        />
        <ProfileDataContent>
          <Text as="p" size="14" weight="bold" css={{ lineHeight: '20px' }}>
            {`${profileData.firstName} ${profileData.lastName}`}
          </Text>
          <Text as="p" size="14" css={{ lineHeight: '20px' }}>
            <String id="account_settings_member_since" />
            {` ${formatDate(profileData.memberSince)}`}
          </Text>
        </ProfileDataContent>
      </ProfileDataWrapper>
      {/* <LinksWrapper>
        {links.map(link => (
          <li key={link.to}>
            <Link to={link.to}>
              <String id={link.label} />
            </Link>
          </li>)
        )}
      </LinksWrapper> */}
    </CompWrapper>
  );
}

AccountSettingsSidebar.propTypes = {
  profileData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    memberSince: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AccountSettingsSidebar;
