import { StyledHeading4 } from './Courses.styled';
import {
  LandingPageWrapper,
  SectionWrapper,
} from '@components/organisms/layout/Layouts.styled';
import React, { useContext } from 'react';
import CourseThumbnailList from '@components/molecules/courseThumbnail/courseThumbnailList/CourseThumbnailList';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import Layout from '@components/organisms/layout/Layout';
import LinkThumbnailList from '@components/molecules/linkThumbnail/linkThumbnailList/LinkThumbnailList';
import ListingHeader from '@components/molecules/listingHeader/ListingHeader';
import SEO from '@components/atoms/seo/Seo';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';
import UrlsContext from '@context/UrlsContext';
import { dataToLinkThumbnailViewModel } from '@src/utils/dataToLinkThumbnailViewModel';

const Courses = context => {
  const UrlContext = useContext(UrlsContext);
  const stringsContext = useContext(StringsContext);

  const course1TakeCourseLink =
    stringsContext.courses_course1_take_course_url.field;
  const course1GetAssetsLink =
    stringsContext.courses_course1_get_asset_url.field;
  const course2TakeCourseLink =
    stringsContext.courses_course2_take_course_url.field;
  const course2GetAssetsLink =
    stringsContext.courses_course2_get_asset_url.field;

  const coursesSectionData = [
    {
      staticImages: [
        {
          alt: '3ds Max',
          src: {
            src: '/images/software_logos/3ds-max.png',
          },
        },
      ],
      title: 'Intro to 3ds Max',
      intro:
        'Looking for a 3ds Max course that takes you from A-to-Z? From finding your way around the interface, to pipeline integration, and everything in between - get up to speed in 3ds Max.',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/3dsmax-intro/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: 'Maya',
          src: {
            src: '/images/software_logos/maya.png',
          },
        },
      ],
      title: 'Intro to Maya',
      intro:
        'Welcome to the Maya course that takes you from interface to integrated pipeline. From modeling basics, to diving into Bifrost, and everything in between – get up to speed with Maya.',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/maya-intro/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: 'Maya',
          src: {
            src: '/images/software_logos/maya.png',
          },
        },
        {
          alt: 'Substance Painter',
          src: {
            src: '/images/software_logos/substance-painter.png',
          },
        },
      ],
      title: 'Creature Texturing for Film/TV/Animation',
      intro:
        'In this series, Justin Marshall shows you how to use Maya and Substance Painter to prepare a film-quality texture on a 3D model.​',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/001-creature-texturing-for-film/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: 'Realtime Creature Rigging',
          src: {
            src: '/images/software_logos/maya.png',
          },
        },
      ],
      title: 'Realtime Creature Rigging',
      intro:
        'Over the duration of this course, Antony Ward will walk you through the process of creating a realtime rig, which can be animated and exported to a game engine, like Unreal.',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/002-realtime-creature-rigging/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: 'Maya',
          src: {
            src: '/images/software_logos/maya.png',
          },
        },
        {
          alt: 'ZBrush',
          src: {
            src: '/images/software_logos/zbrush.png',
          },
        },
      ],
      title: 'Creature Modeling for film with Maya and ZBrush',
      intro:
        'Full lesson on using Maya and ZBrush together to model a creature for film.​​',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/003-creature-modeling-for-film-with-maya-and-zbrush/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: 'Maya',
          src: {
            src: '/images/software_logos/maya.png',
          },
        },
        {
          alt: 'Substance Painter',
          src: {
            src: '/images/software_logos/substance-painter.png',
          },
        },
        {
          alt: 'Unity',
          src: {
            src: '/images/software_logos/unity.png',
          },
        },
      ],
      title: 'Creature texturing for games',
      intro:
          'In this course, Justin Marshall shows you how to texture your creature for games using Maya, Substance Painter, and Unity.​​',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/004-creature-texturing-for-games/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: 'Maya',
          src: {
            src: '/images/software_logos/maya.png',
          },
        },
        {
          alt: 'ZBrush',
          src: {
            src: '/images/software_logos/zbrush.png',
          },
        },
      ],
      title: 'Creature Modeling for Games',
      intro:
          'In this series, Justin Marshall shows you how to use Maya and ZBrush together to concept a character straight from your imagination to the screen!​​',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/005-creature-modeling-for-games/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: 'Maya',
          src: {
            src: '/images/software_logos/maya.png',
          },
        },
        {
          alt: 'ZBrush',
          src: {
            src: '/images/software_logos/zbrush.png',
          },
        },
      ],
      title: 'Creature Concepting with Maya and Zbrush',
      intro:
          'In this series, Justin Marshall shows you how to take a high poly ZBrush concept and retopologize it in Maya for use in a game engine.​​',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/006-creature-concepting-with-maya-and-zbrush/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: '3ds Max',
          src: {
            src: '/images/software_logos/3ds-max.png',
          },
        },
        {
          alt: 'AutoCAD',
          src: {
            src: '/images/software_logos/autocad.png',
          },
        },
      ],
      title: 'Creating an ArchViz Scene: AutoCad to 3ds Max Workflow',
      intro:
          'In this lesson, Sara Moheimani shows you how to create an Architetectural Visualization scene using an AutoCad to 3ds Max workflow.​​',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/007-creating-an-archviz-scene-autocad-to-3ds-max-workflow/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
    {
      staticImages: [
        {
          alt: '3ds Max',
          src: {
            src: '/images/software_logos/3ds-max.png',
          },
        },
        {
          alt: 'Arnold',
          src: {
            src: '/images/software_logos/arnold.png',
          },
        },
      ],
      title: 'Product Viz: From CAD Model to Final',
      intro:
          'In this course, Aaron Ross shows you how to use 3ds Max and Arnold for Product Visualization.',
      actions: [
        {
          label: <String id="courses_take_course" />,
          colorTheme: 'white',
          externalUrl: `${FE_URL}${'/learn/courses/008-product-viz-from-cad-model-to-final/'}`,
          actionType: 'externalLink',
          target: '_blank',
        },
      ],
    },
  ];

  const LinkThumbnailListDatas = [
    dataToLinkThumbnailViewModel({
      label: <String id="courses_thumbnail1_title" />,
      imgSrc: '/images/explore_more_1.jpg',
      linkTo: GetUrl('learnAssets', UrlContext),
      text: <String id="courses_thumbnail1_description" />,
    }),
    dataToLinkThumbnailViewModel({
      label: <String id="courses_thumbnail2_title" />,
      imgSrc: '/images/explore_more_3.jpg',
      linkTo: GetUrl('learnTutorials', UrlContext),
      text: <String id="courses_thumbnail2_description" />,
    }),
  ];

  return (
    <Layout
      location={context.location}
      showBreadcrumb
      seomatic={context?.pageContext?.entry?.seomatic}
    >
      <SEO title="Courses" slug="courses" />
      <LandingPageWrapper>
        <SectionWrapper>
          <ListingHeader
            title="page_courses_title"
            intro="page_courses_intro"
            isCMSDynamic
          />
          <CourseThumbnailList listData={coursesSectionData} />
        </SectionWrapper>
        <SectionWrapper>
          <StyledHeading4 level="h4">
            <String id="page_courses_links_section_title" />
          </StyledHeading4>
          <LinkThumbnailList listData={LinkThumbnailListDatas} />
        </SectionWrapper>
      </LandingPageWrapper>
    </Layout>
  );
};

export default Courses;
