import { Heading } from '@components/atoms/heading/Heading';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import PropTypes from 'prop-types';
import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

import {
  PolarGraphBlockWrapper,
  GraphContainer,
  PolarGraphHeader,
} from './PolarGraphBlock.styled';

export const PolarGraphBlock = props => {
  const { title, data } = props;
  return (
    <PolarGraphBlockWrapper>
      <PolarGraphHeader>
        <Heading
          level="h4"
          css={{ marginRight: '$20', lineHeight: '1em', display: 'inline' }}
        >
          {title}
        </Heading>
        <Text
          css={{ color: '$mediumGrey', display: 'inline', lineHeight: '1em' }}
        >
          Last 7 days
        </Text>
      </PolarGraphHeader>

      <GraphContainer>
        <PolarArea data={data} />
      </GraphContainer>
    </PolarGraphBlockWrapper>
  );
};

PolarGraphBlock.propTypes = {
  title: PropTypes.string.isRequired,
};
