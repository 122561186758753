import { Heading } from '@components/atoms/heading/Heading';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';
import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import {
  TrendingContentWrapper,
  TrendingContentHeader,
  TrendingContentContent,
  GraphContainer,
  InfoContainer,
  InfoContainerFooter,
} from './TrendingContentBlock.styled';

export const TrendingContentBlock = props => {
  const {
    title,
    percentagePageView,
    percentagePageViewColor,
    percentageLikes,
    percentageLikesColor,
    data,
  } = props;

  return (
    <TrendingContentWrapper>
      <TrendingContentHeader>
        <Heading level="h4" css={{ marginRight: '$20', color: '$white' }}>
          🔥Trending Content
        </Heading>
        <Text css={{ color: '$mediumGrey' }}>Last 7 days</Text>
      </TrendingContentHeader>
      <TrendingContentContent>
        <GraphContainer>
          <Line data={data} datasetIdKey="id" />
        </GraphContainer>
        <InfoContainer>
          <Heading
            level="h4"
            weight="bold"
            css={{
              fontSize: '$48',
              lineHeight: '1.2em',
              width: '100%',
              marginBottom: '$54',
              color: '$white'
            }}
          >
            {title}
          </Heading>
          <InfoContainerFooter>
            <div style={{ display: 'flex' }}>
              <Text
                css={{
                  color: `$${percentagePageViewColor}`,
                  marginRight: '$4',
                }}
              >
                {percentagePageView}
              </Text>
              <Text css={{ color: '$mediumGrey' }}>Page views</Text>
            </div>
            <div style={{ display: 'flex' }}>
              <Text
                css={{
                  color: `$${percentageLikesColor}`,
                  marginRight: '$4',
                }}
              >
                {percentageLikes}
              </Text>
              <Text css={{ color: '$mediumGrey' }}>Likes</Text>
            </div>
          </InfoContainerFooter>
        </InfoContainer>
      </TrendingContentContent>
    </TrendingContentWrapper>
  );
};

TrendingContentBlock.propTypes = {
  title: PropTypes.string.isRequired,
  percentagePageView: PropTypes.string,
  percentagePageViewColor: PropTypes.string,
  percentageLikes: PropTypes.string,
  percentageLikesColor: PropTypes.string,
  data: PropTypes.object,
};
