import Logo from '@src/assets/svg/area_logo_full.svg';
import { Nav } from '@components/atoms/nav/Nav';
import { Text } from '@components/atoms/text/Text';
import { styled } from '@root/stitches.config';
import Button from '@components/atoms/button/Button';
import Svg from '@components/atoms/svg/Svg';

export const FooterWrapper = styled('footer', {
  backgroundColor: '$altDarkGrey',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'auto minmax(auto, 1440px) auto',
  gridTemplateAreas: '"left center right"',
  overflow: 'hidden',
});

export const FooterEmptyLeft = styled('span', {
  backgroundColor: '$altDarkGrey',
  gridTemplateAreas: '"left"',
});

export const FooterEmptyRight = styled('span', {
  backgroundColor: '$altMediumGrey',
  gridTemplateAreas: '"right"',
});

export const FooterGrid = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  '@bp1': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  '@bp3': {
    display: 'grid',
    gridTemplateColumns: '3fr minmax(max-content, 1fr)',
  },
});

export const FooterNavWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '$32',
  px: '$24',
  py: '$48',

  alignItems: 'center',
  '@bp1': {
    px: '$24',
    py: '$48',
  },
  '@bp2': {
    gridTemplateColumns: '1fr 2fr',
    px: '$16',
    py: '$48',
    alignItems: 'flex-start',
  },

  '@bp3': {
    gridTemplateColumns: '1fr 2fr',
    px: '$16',
    py: '$48',
  },
  '@bp5': {
    gridTemplateColumns: '2fr 1fr',
    px: '0',
    py: '$48',
  },
  '@bp6': {
    gridTemplateColumns: '250px 2fr',
    px: '0',
    py: '$48',
  },

  a: {
    textDecoration: 'none',
  },
});

export const FooterMenu = styled('div', {
  '@bp2': {
    display: 'grid',
    gridGap: '$16',
    gridTemplateColumns: '1fr 1fr',
  },

  '@bp3': {
    gridTemplateColumns: '1fr 1fr ',
    gridGap: '$32',
    li: {
      pb: '$4',
      mb: '$24',
      fontSize: '$16',
    },
  },
  '@bp4': {
    gridTemplateColumns:
      'minmax(200px, max-content) minmax(200px, max-content)',
  },
  '@bp5': {
    gridTemplateColumns:
      'minmax(300px, max-content) minmax(300px, max-content)',
  },
  '@bp6': {
    gridTemplateColumns:
      'minmax(300px, max-content) minmax(300px, max-content)',
  },
});

export const FooterNav = styled(Nav, {
  fontFamily: 'var(--ArtifaktLegendBold)',

  ul: {
    li: {
      mb: '$2',
      fontSize: '$12',
      textTransform: 'initial',
      fontFamily: 'var(--ArtifaktElememt)',
    },
  },

  span: {
    mb: '$4',
    display: 'block',
  },
  a: {
    color: '$white',
    textDecoration: 'none',
    letterSpacing: '0.5px',

    ':hover': {
      //color: '$teal700',
      transition: 'color .3s ease',
    },
  },

  '&.footer__mobile_nav': {
    a: {
      color: '$white',
      fontSize: '$12',
    },
  },
});

export const Subscribe = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  px: '$24',
  py: '$24',
  backgroundColor: '$altMediumGrey',
  height: '100%',
  '@bp1': {
    px: '$48',
    py: '$48',
  },
  '@bp2': {
    px: '$64',
    py: '$48',
  },
  '@bp3': {
    px: '$72',
    py: '$48',
    width: '500px',
  },
  '.subscribe-button': {
    background: 'transparent',
    textTransform: 'none',
    justifyContent: 'space-between',
    border: '1px solid $mediumGrey',
    fontFamily: '$ArtifaktLegend',
    marginBottom: '$64',
    padding: '11px $16',
    '&:hover': {
      background: 'rgba(255, 255, 255, .2)',
      svg: {
        transform: 'translateX(4px)',
      },
    },
    svg: {
      transition: 'transform .2s ease-in',
      width: '$16',
    },
  },
});

export const SubscribeDescription = styled(Text, {
  marginBottom: '$40',
  color: '#fff',
});

export const AreaLogo = styled(Logo, {
  height: '60px',
  width: 'auto',
  fill: '#FFF',
});

export const FooterBottom = styled('div', {
  display: 'flex',
  backgroundColor: '$altBlack',
  color: '$white',
  p: '$12',
  fontSize: '$12',
  justifyContent: 'center',
  paddingBottom: '$24',
  '@bp2': {
    justifyContent: 'flex-start',
    paddingBottom: '$12',
  },

  '@bp2': {
    justifyContent: 'space-between',
  },

  '.dark-mode & ': {
    backgroundColor: '$bg',
  },
});

export const PolicyNav = styled('nav', {
  textAlign: 'right',
  display: 'none',

  li: {
    '&::after': {
      content: `"\u007C"`,
      display: 'inline-block',
      paddingRight: '$4',
      paddingLeft: '$4',
      '@bp3': {
        paddingRight: '$12',
        paddingLeft: '$12',
      },
    },
    '&:last-child:after': {
      display: 'none',
    },
  },
  ul: {
    display: 'inline-flex',
  },

  '@bp2': {
    display: 'block',
  },
  '@bp3': {
    mr: '$72',
  },
});

export const PolicyNavLink = styled('a', {
  color: '$white',
  textDecoration: 'none',
  fontFamily: '$ArtifaktLegendMedium',
  '&:hover': {
    fontWeight: 900,
    transition: 'color .3s ease',
  },

  variants: {
    isCurrentPage: {
      true: {
        opacity: '0.5',
        transition: 'opacity .3s ease',
      },
    },
  },
});

export const AppButton = styled(Button, {
  width: `auto`,
  height: `auto`,
  padding: `$16`,
  borderRadius: '$8',
});

export const SvgStyled = styled(Svg, {
  margin: '0 $16 0 0',
});
export const StyledAppsLinks = styled('a', {
  color: '$white',
  display: 'grid',
  gridTemplateColumns: '$24 1fr',
  gridGap: '$8',
  padding: '$8 $16',
  backgroundColor: '$altMediumGrey',
  borderRadius: '$12',
  textDecoration: 'none',
  fontSize: '$12',
  ai: 'center',
  maxWidth: '230px',
  transition: 'all .5s ease',

  svg: {
    fill: '$white',
    height: '$24',
    width: '$24',
  },
  '&:hover': {
    backgroundColor: '$darkGrey',
    transition: 'all .5s ease',
  },
});
export const StyledAppsLinksWrapper = styled('div', {
  display: 'none',

  '@bp2': {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridRowGap: '$8',
    marginTop: '$48',
  },
});
