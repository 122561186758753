import {
  ActionText,
  AreaLogo,
  BoxMessage,
  ContentWrapper,
  ModalStyled,
} from './UserSignUpConfirmation.styled';
import React, { useState } from 'react';

import AuthContext from '@context/AuthContext';
import Box from '@components/atoms/box/Box';
import FullBackground from '@components/molecules/fullBackground/FullBackground';
import { Heading } from '@components/atoms/heading/Heading';
import Loader from '@components/atoms/loader/Loader';
import String from '@components/atoms/string/String';
import { Text } from '@components/atoms/text/Text';

const UserSignUpConfirmation = props => {
  const [sendEmailState, setSendEmailState] = useState({
    isLoading: false,
    emailErrorMsg: null,
    isEmailSent: false,
  });

  const handleAuthSuccess = response => {
    if (response.success) {
      setSendEmailState({
        ...sendEmailState,
        isLoading: false,
        isAuthError: false,
        isEmailSent: true,
        authErrorMessage: null,
      });
    } else {
      setErrorMessage(response);
    }
  };

  const setErrorMessage = response => {
    switch (response.error) {
      case 'the_email_field_is_required':
        setSendEmailState({
          ...sendEmailState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: false,
          isEmailSent: false,
          emailErrorMsg: <String id="sign_up_error_valid_email" />,
        });
        break;
      case 'user_email_verification_link_sent_error':
        setSendEmailState({
          ...sendEmailState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: false,
          isEmailSent: false,
          emailErrorMsg: <String id="sign_up_error_verification_resend" />,
        });
        break;

      default:
        setSendEmailState({
          ...sendEmailState,
          isLoading: false,
          isAuthError: true,
          authErrorMessage: false,
          isEmailSent: false,
          emailErrorMsg: <String id="sign_up_error_verification_resend" />,
        });
        break;
    }
  };

  const handleAuthError = response => {
    setErrorMessage(response);
  };

  const onSendItAgainClick = (e, sendLinkFn) => {
    e.preventDefault();

    setSendEmailState({
      ...sendEmailState,
      isAuthError: false,
      isLoading: true,
    });

    sendLinkFn({
      email: props.email,
      errorCallback: handleAuthError,
      successCallback: handleAuthSuccess,
    });
  };

  const FullBackgroundProps = {
    artworkByProps: {
      avatarProps: {
        shape: 'circle',
        alt: 'Alternative User Name',
        src: {
          childImageSharp: {
            fixed: {
              height: 60,
              width: 60,
              src: '/images/profile_image_placeholder.jpg',
              srcSet:
                '/images/profile_image_placeholder.jpg 1x,/images/profile_image_placeholder.jpg 1.5x,/images/profile_image_placeholder.jpg 2x',
            },
          },
        },
      },
      label: 'Artwork by',
      artistName: 'Yuriy Dulich',
      artistProfession: 'FX Artist / 3D Artist',
    },
    image: {
      src: {
        src: '/images/maya_bg.jpg',
        srcset:
          '/images/maya_bg.jpg 1x,/images/maya_bg.jpg  250w,/images/maya_bg.jpg  500w',
      },

      alt: 'Yuriy Dulich',
    },
  };

  return (
    <AuthContext.Consumer>
      {auth => (
        <ContentWrapper>
          <FullBackground {...FullBackgroundProps}></FullBackground>
          <Box
            color="white"
            radius="medium"
            shadow="medium"
            type="centeredMessage"
          >
            <Heading level="h2" weight="legendExtraBold">
              <String id="sign_up_confirmation_title" />
              <div>
                <AreaLogo />
              </div>
            </Heading>
            {!sendEmailState.isEmailSent ? (
              <ModalStyled>
                <BoxMessage>
                  <String id="sign_up_confirmation_text_line1" />{' '}
                  <strong>{props.email}</strong>
                </BoxMessage>
                <BoxMessage>
                  <String id="sign_up_confirmation_text_line2" />{' '}
                  <ActionText
                    onClick={e =>
                      onSendItAgainClick(e, auth.sendVerificationEmail)
                    }
                  >
                    <String id="sign_up_confirmation_send_again" />
                  </ActionText>
                </BoxMessage>
                {sendEmailState.emailErrorMsg ? (
                  <BoxMessage>
                    <Text css={{ color: '$red' }}>
                      {sendEmailState.emailErrorMsg}
                    </Text>
                  </BoxMessage>
                ) : null}
              </ModalStyled>
            ) : (
              <ModalStyled>
                <BoxMessage>
                  <String id="sign_up_confirmation_text_line3" />
                  <strong> {props.email}</strong>{' '}
                </BoxMessage>
                <BoxMessage>
                  <String id="sign_up_confirmation_text_line4" />
                </BoxMessage>
              </ModalStyled>
            )}
          </Box>
          {sendEmailState.isLoading && (
            <Loader isLoading={sendEmailState.isLoading} />
          )}
        </ContentWrapper>
      )}
    </AuthContext.Consumer>
  );
};

export default UserSignUpConfirmation;
