import {
  InputTextLabel,
  RemoveButton,
  SelectCreateTagsWrapper,
  SelectedTagsTitle,
  SelectedTagsWrapper,
  Tag,
} from './SelectCreateTags.styled';

import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import PropTypes from 'prop-types';
import React from 'react';
import Svg from '@components/atoms/svg/Svg';
import { Text } from '@components/atoms/text/Text';

const SelectCreateTags = props => {
  const {
    maxTags,
    onCreateTagsSelect,
    css,
    isError,
    label,
    createTagsList,
  } = props;

  const selectedTags = createTagsList.filter(tag => {
    return tag.isSelected ? tag : false;
  });

  const onSelectCreateTag = (value, isRemove = false) => {
    const selectedIndex = createTagsList.findIndex(tag => {
      return tag.value === value;
    });

    if (selectedTags.length >= maxTags && !isRemove) {
      return;
    }

    onCreateTagsSelect(selectedIndex);
  };

  const hasMaxSelected = selectedTags.length === maxTags;

  return (
    <SelectCreateTagsWrapper css={css}>
      {label && (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <InputTextLabel>{label}</InputTextLabel>
          <Text size="14">
            {hasMaxSelected ? '(Max amount reached)' : `(add up to ${maxTags})`}
          </Text>
        </div>
      )}
      <InputDropdownSearch
        onSelect={onSelectCreateTag}
        options={createTagsList}
        multiSelect="true"
        placeholder="Type your tags here"
      />
      {selectedTags.length > 0 ? (
        <SelectedTagsWrapper>
          <SelectedTagsTitle>Selected tags:</SelectedTagsTitle>
          {selectedTags.map((item, i) => {
            return (
              <Tag key={i}>
                <RemoveButton
                  onClick={onSelectCreateTag.bind(null, item.value, true)}
                >
                  <Svg icon="close-small" />
                </RemoveButton>
                <span>{item.name}</span>
              </Tag>
            );
          })}
        </SelectedTagsWrapper>
      ) : null}
    </SelectCreateTagsWrapper>
  );
};

SelectCreateTags.propTypes = {
  maxTags: PropTypes.number,
  onCreateTagsSelect: PropTypes.func,
  css: PropTypes.object,
  isError: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  createTagsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

SelectCreateTags.defaultProps = {
  maxTags: 12,
  onCreateTagsSelect: () => {},
  css: {},
  isError: false,
  label: undefined,
};

export default SelectCreateTags;
