import { styled } from '@root/stitches.config';

export const SkeletonWrapper = styled('div', {
  lineHeight: 1,
  marginBottom: '$8',
  variants: {
    isFeatured: {
      true: {
        '@bp1': {
          gridArea: '1 / 1 / 3 / 3',
        },
      },
    },
    isFeaturedReverse: {
      true: {
        '@bp1': {
          gridColumn: '3 / 5',
        },
      },
    },
  },
});
