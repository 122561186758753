import Logo from '@src/assets/svg/autodesk_logo.svg';
import { styled } from '@root/stitches.config';

export const UserBarWrapper = styled('div', {
  position: 'relative',
  fontSize: 0,
  color: '$mainTextColor',
  willChange: 'transform',
});

export const UserBarMobile = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',

  variants: {
    isLogged: {
      true: {
        '& .dark-mode-toggle': {
          opacity: '0',
          pointerEvents: 'none',
        },
      },
    },
  },

  button: {
    padding: '$8',
    marginRight: '$4',
    span: {
      lineHeight: '1em',
    },
    svg: {
      marginRight: '0',
    },
    '.btn-label': {
      display: 'none',
    },
  },

  div: {
    marginRight: '$16',
  },

  '@bp2': {
    display: 'none',
    button: {
      px: '$20',
      py: '$16',
      marginRight: '0',
    },
  },
});

export const UserMenuBox = styled('ul', {
  position: 'absolute',
  backgroundColor: '$headerMenu',
  borderRadius: '$8',
  padding: '$16 $24',
  right: 0,
  top: '60px',
  boxShadow: '$2',

  '.theme-0 &': {
    border: '1px solid hsl(211,19%,28%,1)',
  },

  variants: {
    isUserMenuOpened: {
      isOpened: {
        display: 'block',
      },
      isNotOpened: {
        display: 'none',
      },
    },
  },
});

export const UserBarDesktop = styled('div', {
  display: 'none',
  '@bp2': {
    zindex: '999',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    '& > *': {
      marginRight: '$8',
    },
    '& > *:last-child': {
      marginRight: '0',
      marginLeft: '$16',
    },
  },
  '@bp3': {
    '& > *': {
      marginRight: '$24',
    },
    '& > *:last-child': {
      marginRight: '0',
      marginLeft: '0',
    },
  },
});

export const ImageProfileWrapper = styled('div', {
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'relative',
  width: '48px',
  height: '48px',
  marginRight: '$16',
  transition: 'box-shadow .4s ease',
});

export const UserMenuWrapper = styled('div', {});

export const UserMenuItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$16',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  borderRadius: '$24',
  transition: 'background .2s ease',
  a: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    borderRadius: '$24',
  },
  '&:hover, &:focus, &:visited': {
    transition: 'background .1s easeInOut',
    backgroundColor: '$elements',
    '.menuIcon': {
      boxShadow: '$2',
    },
  },
});

export const ImageWrapper = styled('div', {
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0,
  border: '$white solid  2px',
  img: {
    height: '46px',
    width: '46px',
  },

  '&:hover, &:focus, &:visited': {
    transition: 'all .3s ease',
    boxShadow: '0px 0px 5px #E1E1E1',
  },
});

export const UserMenuIcon = styled('div', {
  width: '40px',
  height: '40px',
  overflow: 'hidden',
  backgroundColor: '$elements',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '$16',
  transition: 'box-shadow .4s ease',

  svg: {
    width: '24px',
    height: '24px',
    fill: '$blackToWhite',
  },
});

export const FakeRadio = styled('div', {
  display: 'block',
  width: '60px',
  height: '30px',
  position: 'relative',
});

export const FakeRadioBackground = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: '$ultraLightGrey',
  borderRadius: '100px',
  transition: 'background-color .4s ease',
  variants: {
    isActive: {
      true: {
        backgroundColor: '$darkGrey',
      },
    },
  },
});

export const FakeRadioCircle = styled('div', {
  width: '26px',
  height: '26px',
  backgroundColor: '$whiteToBlack',
  borderRadius: '100px',
  position: 'absolute',
  top: '2px',
  left: '2px',
  transform: 'translateX(0)',
  transition: 'transform .3s ease',
  variants: {
    isActive: {
      true: {
        transform: 'translateX(30px)',
      },
    },
  },
});

export const UserMenuItemProfile = styled('li', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  cursor: 'pointer',
  borderRadius: '$24',
  transition: 'background .2s ease',
  '&:hover, &:focus, &:visited': {
    transition: 'background .1s easeInOut',
    backgroundColor: '$elements',
    '.menuIcon': {
      boxShadow: '$2',
    },
  },
});

export const DarkmodeWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const AutodeskLogo = styled(Logo, {
  display: 'flex',
  width: '100px',
  fill: '#FFF',
});
