import { styled } from '@root/stitches.config';

export const InputSearchWrapper = styled('div', {
  position: 'relative',
  button: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    right: '$8',
    transform: 'translateY(-50%)',
    top: '50%',
  },
});

export const InputTextStyled = styled('input', {
  padding: '$12 $16',
  width: '100%',
  fontFamily: 'var(--ArtifaktElementRegular)',
  fontSize: '$16',
  lineHeight: '1.18em',
  boxShadow: 'none',
  appearance: 'none',
  margin: '0px',
  backgroundColor: 'transparent',
  color: '$mainTextColor',
  border: '1px solid $mainTextColor',
  outline: '0',
  borderRadius: '$8',
  svg: {
    fill: '#gray900',
  },
  '::placeholder': {
    color: '$mediumGrey',
  },
  ':focus': {
    borderColor: '$mediumGrey',
  },
  ':hover': {
    borderColor: '$mediumGrey',
  },
});
