import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Button from '@components/atoms/button/Button';

export const CommunityGroupSideBarWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',

  '@bp3': {
    // position: 'fixed',
    width: '240px',
  },
});

export const StyledBtn = styled(Button, {
  margin: '$16 auto 0',
  display: 'table',
});

export const GroupBoxContainer = styled('div', {
  marginBottom: '$28',

  '& > div': {
    marginBottom: '$8',

    '&:last-of-type': {
      marginBottom: '0',
    },
  },
});

export const StyledSlider = styled('div', {
  display: 'block',
  marginBottom: '$32',

  '@bp2': {
    display: 'none',
  },

  '& .prev-btn, & .next-btn': {
    display: 'none',
  },
});

export const ShowMoreButton = styled('a', {
  color: '$mainTextColor',
  fontWeight: '700',
  fontSize: '$16',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '$16',
  cursor: 'pointer',
  opacity: '1',
  transition: 'opacity .2s ease-out',

  '&:hover': {
    opacity: '0.5',
  },

  svg: {
    width: '$24',
    height: '$24',
  },
});

export const AllGroupBoxContainer = styled('div', {
  marginBottom: '$16',

  '& > div': {
    marginBottom: '$8',

    '&:last-of-type': {
      marginBottom: '0',
    },
  },
});

export const StyledText = styled(Text, {
  textTransform: 'uppercase',
  color: '$mainTextColor',
  whiteSpace: 'nowrap',
  marginBottom: '$8',

  '@bp2':{
    marginBottom: '$16',
  }
});

export const BtnWrapper = styled('div', {
  margin: '$16 auto 0',
  display: 'flex',
  justifyContent: 'center',
});

export const DesktopLayout = styled('div', {
  display: 'none',

  '@bp2': {
    display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  },
});

export const MobileLayout = styled('div', {
  display: 'block',
  width: '100%',

  '@bp2': {
    display: 'none',
  },
});
