import firebase from 'firebase/compat/app';
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import localforage from 'localforage';

const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTHDOMAIN,
  projectId: FIREBASE_PROJECTID,
  storageBucket: FIREBASE_STORAGEBUCKET,
  messagingSenderId: FIREBASE_MESSAGINGSENDERID,
  appId: FIREBASE_APPID,
  measurementId: FIREBASE_MEASUREMENTID,
};

let instance;
export function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }

  return null;
}

async function tokenInlocalforage() {
  return localforage.getItem('fcm_token');
}

function setWithExpiry(value) {
  //Store Token on LocalForage with a expire date
  const now = new Date();
  const item = {
    value: value,
    // expiry: now.getTime() + 5000,
    expiry: now.getTime() + 3600000 * 24 * 2,
  };
  localforage.setItem('fcm_token', item);
}

export const getFCMToken = async () => {
  try {
    const messaging = await getMessaging();
    const tokenInLocalForage = await tokenInlocalforage();

    //if FCM token is on LocalForage and it hasn't expired return the stored token
    if (tokenInLocalForage !== null) {
      const tokenDate = new Date(tokenInLocalForage.expiry);
      const now = new Date();

      if (tokenDate > now.getTime()) {
        return tokenInLocalForage;
      } else {
        deleteToken(messaging).then(resp => {
          localforage.removeItem('fcm_token').then(resp => {
            console.log('deletedToken from local');
          });
        });
      }
    }

    //Generate a new Token
    const status = await Notification.requestPermission();
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else if (status !== 'denied' || status === 'default') {
      Notification.requestPermission();
    }

    if (status && status === 'granted') {
      const fcm_token = await getToken(messaging, {
        vapidKey:
          'BBCXtP0Ea8PrHeMLUfbfPpXdWtOXlPJ4l6nyp5-KP-kSDWqtZh4xgrK_ihjVjSyvw6LuZjnke7Aw9Ef59FfUu3A',
      });

      if (fcm_token) {
        const tokenValue = {
          value: fcm_token,
          isNewToken: true,
        };
        setWithExpiry(fcm_token);
        return tokenValue;
      }
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
    return null;
  }
};

export const deleteFCMToken = async () => {
  localforage.removeItem('fcm_token').catch(err => {
    console.log('errors', err);
  });
};
