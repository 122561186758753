import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';
import Svg from '@components/atoms/svg/Svg';

export const CreateInfoWrapper = styled('div', {
  minHeight: '70vh',
  height: '100%',
  padding: '$54 $24 0',
  textAlign: 'center',
  //backgroundColor: '$mainBackgroundAltColor',
  '@bp2': {
    padding: '$54 $24 $72',
  },
});

export const InfoContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  maxWidth: '640px',
  margin: '0 auto',
  textAlign: 'left',
  h2: {
    fontSize: '$32',
    lineHeight: '$1',
    fontFamily: '$ArtifaktLegendExtraBold',
    mb: '$48',
  },
});

export const SvgHelp = styled(Svg, {
  fill: '$darkGrey',
  height: '$16',
  width: '$16',
  verticalAlign: 'middle',
  position: 'relative',
  ml: '$4',
  color: '$darkGrey',
});

export const InfoFooter = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const InfoFooterDesktop = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'block',
  },
});

export const CreateLevel = styled('div', {
  mb: '$32',
});

export const RequiredLabel = styled(Text, {
  color: '$darkGrey',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});

export const CreateLevelHeader = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const InputTextLabel = styled('label', {
  marginBottom: '$4',
  display: 'inline-block',
  fontSize: '$14',
  fontFamily: 'var(--ArtifaktElement)',
  display: 'flex',
  alignContent: 'center',
  marginRight: '$8',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});

export const InputTextErrorMsg = styled('div', {
  gridArea: 'error',
  fontSize: '$12',
  marginTop: '$4',
  minHeight: '$24',
  variants: {
    isError: {
      true: {
        color: '$red',
      },
    },
  },
});
