import { styled } from '@root/stitches.config';

export const SectionWrapper = styled('div', {
  padding: '$32 0',
  borderBottom: '1px solid $mediumGrey',
  '.page-content': {
    marginTop: '$16',
  },
});

export const EditorWrapper = styled('div', {
  padding: '$8',
  border: '1px solid $lightGrey',
  br: 8,
  marginTop: '$16',
});