import Button from '@components/atoms/button/Button';
import { Heading } from '@components/atoms/heading/Heading';
import { styled } from '@root/stitches.config';

export const StyledButton = styled(Button, {
  zIndex: 2,
  width: 'fit-content',
  marginTop: '$16',
});

export const StyledHeading = styled(Heading, {
  zIndex: '1',
  fontSize: '$24',
  lineHeight: '$3',
  margin: 0,
  marginTop: 'auto',
  fontFamily: '$ArtifaktLegendBold',

  '@bp2': {
    fontSize: '$24 !important',
    lineHeight: '$3',
  },
});

export const AdFeatureCard = styled('div', {
  position: 'relative',
  with: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  color: 'white',
  borderRadius: '18px',
  '-webkit-border-radius': '18px',
  '-moz-border-radius': '18px',
  '-khtml-border-radius': '18px',
  boxShadow: '$1',
  padding: '$24',
  overflow: 'hidden',
  minHeight: '336px',
  maxHeight: '336px',
  boxSizing: 'border-box',
  border: '1px solid $thumbnailBorder',
  transition: 'transform .4s ease',

  '&::before': {
    content: '',
    width: '105%',
    height: '105%',
    borderRadius: '5px',
    '-webkit-border-radius': '5px',
    '-moz-border-radius': '5px',
    '-khtml-border-radius': '5px',
    left: 0,
    bottom: 0,
    position: 'absolute',
    background:
      'linear-gradient(rgba(31, 31, 31, 0.76) 0%, rgba(0, 0, 0, 0) 45%), linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 55%)',
    zIndex: 1,
  },

  '> .image-wrapper': {
    width: '102%',
    height: '102%',
    position: 'absolute',
    zIndex: 0,
    left: 0,
    bottom: 0,
    img: {
      objectFit: 'cover',
      position: 'absolute',
      borderRadius: '16px',
      '-webkit-border-radius': '16px',
      '-moz-border-radius': '16px',
      '-khtml-border-radius': '16px',
      transform: 'scale(1)',
      transition: 'transform .4s ease',
      willChange: 'transform',
    },
  },

  '&:hover': {
    transform: 'translate(0px, -4px)',

    '> .image-wrapper': {
      img: {
        transform: 'scale(1.015)',
      },
    },
  },
});
