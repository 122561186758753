import { Heading } from '@components/atoms/heading/Heading';
import { styled } from '@root/stitches.config';

export const GroupSidebarRightWrapper = styled('div', {
  width: '100%',
  maxWidth: '232px',
  display: 'none',

  '@bp2': {
    display: 'block',
  },
});

export const StyledSectionHeading = styled(Heading, {
  'text-transform': 'uppercase',
  marginTop: 0,
  marginBottom: '$16',
  fontSize: '$16',
  alignSelf: 'center',
  textAlign: 'center',
  lineHeight: '$2',
});

export const FeaturedWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  marginTop: '$32',
  flexDirection: 'column',
  '.groupSidebarRight_thumbnail:not(:first-of-type)': {
    marginTop: '$16',
  },
});
