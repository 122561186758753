import {
  GET_CREATE_DETAIL,
  GET_CREATE_INFO,
  GET_PROFILE_WORK,
} from '@src/apollo/query';
import {
  REMOVE_USERCONTENT_BLOC,
  UPDATE_USERCONTENT,
} from '@src/apollo/mutation';
import React, { useContext, useEffect, useState } from 'react';
import {
  addImageBlock,
  base64regex,
  createViewToDataModel,
  goToSection,
  initialAdminFormState,
  initialCreateState,
  initialInfoFormState,
  initialThumbnailCropData,
  onAddBlock,
  onChangeBlockChangeOrder,
  onChangeImageCropIndex,
  onEditBlock,
  onEditUserContentblock,
  onEditVideoBlock,
  onEditSketchfabBlock,
  onNavItemClick,
  onReOrderBlocks,
  onRemoveBlock,
  onUpdatePostToDataModel,
  setData,
  useCreateNextButtonValidation,
  useShowAdminSettings,
  validImageUrl,
  VALID_COVER_IMAGE_FORMATS,
} from './utils';

import { useMutation, useQuery } from '@apollo/client';
import {
  userContentType,
  userContentUrl,
} from '@src/utils/dataToThumbnailViewModel';

import AuthContext from '@context/AuthContext';
import { ClientNotificationContext } from '@src/context/ClientNotificationContext';
import CreateForm from './createForm/CreateForm';
import { CreatePageWrapper } from './Create.styled';
import Layout from '@components/organisms/layout/Layout';
import PropTypes from 'prop-types';
import ProtectedRoute from '@components/atoms/protectedRoute/ProtectedRoute';
import { navigate } from 'gatsby';

const toDataURL = url => {
  if (url?.includes('null')) {
    return null;
  }
  const ms = Date.now();

  return fetch(url + `?x-request=html`, {})
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .catch(e => console.log(e));
};

function isBase64image(image) {
  return base64regex.test(image.replace(/^data:image\/[a-z]+;base64,/, ''));
}

async function urlToBase64(url) {
  let base64;
  await toDataURL(url)?.then(res => {
    base64 = res;
  });
  return base64;
}

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(res => {
      return res.arrayBuffer();
    })
    .then(buf => {
      return new File([buf], filename, { type: mimeType });
    });
}

async function coverStateToDataModel(state, slug) {
  const { image, currentImageCrop } = state;
  let newImage;
  let newCurrentImageCrop;
  let newImageFile;
  let newCurrentImageCropFile;

  if (
    image.data_url &&
    !isBase64image(image.data_url) &&
    !isBase64image(currentImageCrop.data_url)
  ) {
    newImage = await urlToBase64(image.data_url);
    newCurrentImageCrop = await urlToBase64(currentImageCrop.data_url);
  }

  if (newImage && newCurrentImageCrop) {
    newImageFile = await urltoFile(
      newImage,
      `cover-image-${slug}.${newImage.match(/[^:/]\w+(?=;|,)/)[0]}`,
      newImage.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
    );
    newCurrentImageCropFile = await urltoFile(
      newCurrentImageCrop,
      `cover-image-crop-${slug}.${
        newCurrentImageCrop.match(/[^:/]\w+(?=;|,)/)[0]
      }`,
      newCurrentImageCrop.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
    );

    return {
      ...state,
      image: {
        ...image,
        data_url: newImage,
        file: newImageFile,
      },
      currentImageCrop: {
        ...currentImageCrop,
        data_url: newImage,
        file: newCurrentImageCropFile,
      },
    };
  }

  return state;
}

async function imageUrlToImageFileData(image, index) {
  let newImage;
  let newImageFile;

  if (image.data_url) {
    newImage = await urlToBase64(image.data_url);
  }

  if (newImage) {
    newImageFile = await urltoFile(
      newImage,
      `image-${index}.${newImage.match(/[^:/]\w+(?=;|,)/)[0]}`,
      newImage.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
    );
    return {
      ...image,
      data_url: newImage,
      file: newImageFile,
    };
  }

  return image;
}

async function coverConvertState(cropData, slug) {
  const convertedState = await coverStateToDataModel(cropData, slug);
  return convertedState;
}

async function convertImage(image, i) {
  const convertedImage = await imageUrlToImageFileData(image, i);
  return convertedImage;
}

const CreateEdit = props => {
  // props
  const { location, params } = props;
  const { handleUpdateClientNotification } = useContext(
    ClientNotificationContext
  );

  const isEdit =
    location.pathname.includes('edit') && !location.pathname.includes('draft');
  const isDraft = location.pathname.includes('draft');

  function contentStatus() {
    const { pathname } = location;
    if (pathname.includes('publish')) {
      return 'publish';
    }
    if (pathname.includes('pending')) {
      return 'pending';
    }
    if (pathname.includes('draft')) {
      return 'draft';
    }
    return undefined;
  }

  // gql queries
  const { data, loading } = useQuery(GET_CREATE_DETAIL, {
    variables: { slug: params.slug, status: contentStatus() },
    fetchPolicy: 'no-cache',
  });

  const {
    loading: createInfoLoading,
    data: createInfoData,
  } = useQuery(GET_CREATE_INFO, { fetchPolicy: 'cache-and-network' });
  const authContext = useContext(AuthContext);

  const isSuperMember =
    authContext.generalRole === 'admin' ||
    authContext.generalRole === 'editor' ||
    authContext.generalRole === 'contributor';

  useEffect(() => {
    if (data?.userContent?.userContentType === 'asset' && !isSuperMember) {
      navigate('/404');
    }
  }, [data]);

  const showAdminSettings = useShowAdminSettings(
    authContext.isAuthenticated,
    authContext.generalRole
  );

  const [isPublishBtnDisabled, setIsPublishBtnDisabled] = useState(true);
  const [createState, setCreateState] = useState(
    initialCreateState(authContext)
  );
  const [createInfoFormState, setCreateInfoFormState] = useState(
    initialInfoFormState
  );
  const [thumbnailCropData, setThumbnailCropsData] = useState(
    initialThumbnailCropData
  );
  const [createAdminFormState, setCreateAdminFormState] = useState(
    initialAdminFormState
  );
  const [postIdToEdit, setPostIdToEdit] = useState(data?.userContent?.id);

  const [userImagesBlocks, setUserImageBlocks] = useState([]);
  const [userBlocks, setUserBlocks] = useState([]);
  const [creatorData, setCreatorData] = useState();
  const [modal, setModalData] = useState({
    isShowing: false,
  });

  const [publishCreate, dataPublishCreate] = useMutation(UPDATE_USERCONTENT, {
    refetchQueries: [
      {
        query: GET_PROFILE_WORK,
        variables: { id: authContext.userId },
      },
      {
        query: GET_CREATE_DETAIL,
        variables: { slug: params.slug, status: contentStatus() },
      },
    ],
    onCompleted: res => {
      const { updateUserContent } = res;
      const isInspireArticle =
        updateUserContent?.article_section?.name === 'Inspire';
      const isNotSameAuthor =
        createAdminFormState.authoring.author !== null &&
        createAdminFormState.authoring.author !== authContext.userId;
      const contentType = userContentType(
        updateUserContent.userContentType,
        updateUserContent?.article_type?.key
      );
      const postSlug = `${userContentUrl(
        contentType,
        updateUserContent?.article_type?.key,
        isInspireArticle,
        updateUserContent?.creator?.username,
        updateUserContent.slug,
        updateUserContent.status,
        updateUserContent.motm_year
      )}/${
        updateUserContent.status !== 'pending' ? updateUserContent.status : ''
      }`;

      if (isNotSameAuthor && isInspireArticle) {
        handleUpdateClientNotification({
          text: 'create_different_author_inspire',
          type: 'success',
          autoDismiss: true,
        });
      } else if (isNotSameAuthor && !isInspireArticle) {
        handleUpdateClientNotification({
          text: 'create_different_author',
          type: 'success',
          autoDismiss: true,
        });
      }
      navigate(postSlug);
    },
  });

  // gql mutations
  const [updateUserContent, dataUpdateUserContent] = useMutation(
    UPDATE_USERCONTENT,
    {
      onCompleted: res => {
        const sortedResBlocks = res.updateUserContent.blocs.sort(
          (a, b) => a.order - b.order
        );
        const mergedBlocks = createState.blockList.map((el, i) => ({
          ...el,
          isLoading: false,
          ...sortedResBlocks[i],
        }));
        setPostIdToEdit(res.updateUserContent.id);

        setCreateState({
          ...createState,
          blockList: mergedBlocks,
        });
        if (!isDraft) {
          history.replaceState(
            {},
            '',
            `/m/${data.userContent.creator.username}/${
              data.userContent.userContentType !== 'tutorial_series'
                ? `${data.userContent.userContentType}s`
                : data.userContent.userContentType
            }/${data.userContent.slug}-draft/draft/edit`
          );
        }
      },
    }
  );

  useEffect(() => {
    setPostIdToEdit(data?.userContent?.id);
  }, [data?.userContent?.id]);

  const [removeUserContentBloc, dataRemoveUserContentBloc] = useMutation(
    REMOVE_USERCONTENT_BLOC
  );

  useEffect(() => {
    const currentUserID = authContext?.userId;
    const isCurrentUserAdmin =
      authContext?.generalRole === 'admin' ||
      authContext?.generalRole === 'editor';
    const creatorUserId = data?.userContent?.creator?.id;
    if (currentUserID && creatorUserId) {
      const isSame = currentUserID === creatorUserId;
      if (!isSame && !isCurrentUserAdmin) {
        handleUpdateClientNotification({
          text: 'edit_create_different_author_unauthorized',
          type: 'error',
          autoDismiss: true,
        });
        navigate(`/m/${authContext.userSlug}`);
      } else if (!isSame && isCurrentUserAdmin) {
        handleUpdateClientNotification({
          text: 'edit_create_admin_different_author',
          type: 'info',
          autoDismiss: true,
        });
      }
    }
  }, [authContext, data]);

  useEffect(() => {
    const error =
      (dataUpdateUserContent.error || dataPublishCreate.error)?.graphQLErrors ||
      [].map(({ debugMessage }) => debugMessage).join('');

    if (
      error === 'could_not_create_user_content : inappropriate_content' ||
      error === 'could_not_update_some_fields : inappropriate_content' ||
      dataUpdateUserContent.error?.[0]?.exception?.prev?.prev?.message ===
        'inappropriate_content'
    ) {
      setModalData({
        isShowing: true,
        modalType: 'nsfw',
        createType: 'artwork',
        actions: [
          {
            label: 'reupload image',
            onClick: () => {
              setModalData({ isShowing: false });
              if (dataUpdateUserContent.error) {
                const newBlockList = createState.blockList;
                newBlockList.pop();
                setCreateState({
                  ...createState,
                  blockList: newBlockList,
                });
              }
              if (dataPublishCreate.error) {
                const newBlockList = createState.imageBlockList;
                newBlockList.pop();
                setCreateState({
                  ...createState,
                  createCover: undefined,
                  imageBlockList: newBlockList,
                });
                setThumbnailCropsData({
                  image: '',
                  cropData: {},
                  currentImageCrop: '',
                });
              }
            },
            colorTheme: 'normal',
          },
        ],
      });
    }
  }, [dataUpdateUserContent.error, dataPublishCreate.error]);

  useEffect(() => {
    const coverError =
      dataPublishCreate.error?.networkError?.response.statusText ||
      dataPublishCreate?.error?.message;
    if (
      (coverError && coverError === 'Request Entity Too Large') ||
      coverError === 'validation'
    ) {
      setModalData({
        isShowing: true,
        modalType: 'cover_too_large',
        actions: [
          {
            label: 'reupload image',
            onClick: () => {
              setModalData({ isShowing: false });
              if (dataPublishCreate.error) {
                const newBlockList = createState.imageBlockList;
                newBlockList.pop();
                setCreateState({
                  ...createState,
                  createCover: undefined,
                  imageBlockList: newBlockList,
                });
                setThumbnailCropsData({
                  image: '',
                  cropData: {},
                  currentImageCrop: '',
                });
              }
            },
            colorTheme: 'normal',
          },
        ],
      });
    }
  }, [dataPublishCreate.error]);

  useEffect(() => {
    if (!createInfoLoading && !loading) {
      setData(
        createInfoData,
        createState,
        setCreateState,
        createInfoFormState,
        setCreateInfoFormState,
        createAdminFormState,
        setCreateAdminFormState
      );
    }
  }, [createInfoLoading, loading]);

  useEffect(() => {
    if (
      typeof createAdminFormState.publish_options.selectedMoMember
        .first_name === 'string' &&
      typeof createAdminFormState.publish_options.selectedMoMember.last_name ===
        'string'
    ) {
      setCreateInfoFormState({
        ...createInfoFormState,
        title: `${createAdminFormState.publish_options.selectedMoMember.first_name} ${createAdminFormState.publish_options.selectedMoMember.last_name}`,
      });
    }
  }, [
    createAdminFormState.publish_options.selectedMoMember.first_name,
    createAdminFormState.publish_options.selectedMoMember.last_name,
  ]);

  useEffect(() => {
    if (createState.isDataSet) {
      if (data && data?.userContent?.image) {
        coverConvertState(
          {
            image: {
              data_url: validImageUrl(data?.userContent.image.path),
            },
            currentImageCrop: {
              data_url: validImageUrl(data?.userContent.image.path),
            },
          },
          params.slug
        ).then(res => {
          setThumbnailCropsData(res);
        });
      }

      if (data?.userContent && createInfoData) {
        const { userContent } = data;
        const userIndustries = userContent.industries.map(el => el.name);
        const userTools = userContent.tools.map(el => el.title);
        const userAssetType = userContent?.asset_type?.id;

        const userImageBlockList = userContent.blocs
          .filter(el => el.type === 'image')
          .map(el => ({
            ...el,
            id: el.id,
            data: {
              data_url: validImageUrl(el.image?.path),
              file: {},
            },
          }));

        if (data?.userContent?.blocs) {
          setUserBlocks(
            data.userContent.blocs
              .filter(el => el.type !== 'image')
              .map(el => {
                if (el.type === 'text') {
                  return {
                    ...el,
                    type: el.type,
                    text: el.text,
                    id: el.id,
                  };
                }
                return {
                  ...el,
                  type: el.type,
                  videoUrl: el.videoUrl,
                  id: el.id,
                };
              })
          );
        }

        Promise.all(
          userImageBlockList.map(async (el, i) => {
            const newImage = await convertImage(el.data, i);
            return { ...el, data: newImage };
          })
        ).then(res => {
          setUserImageBlocks(res);
        });

        setCreateAdminFormState({
          ...createAdminFormState,
          publish_options: {
            ...createAdminFormState.publish_options,
            selectedArticleType:
              createAdminFormState.publish_options.articleTypeOptions.find(
                el => el.name === userContent?.article_type?.name
              )?.value || null,
            selectedArticleSection:
              createAdminFormState.publish_options.articleSectionOptions.find(
                el => el.name === userContent?.article_section?.name
              )?.value || null,
            year: userContent?.motm_year,
            selectedMoMember: {
              id: userContent?.motm_member?.id,
              first_name: userContent?.motm_member?.first_name,
              last_name: userContent?.motm_member?.last_name,
            },
          },
          authoring: {
            ...createAdminFormState.authoring,
            author: userContent?.creator?.id,
            role: userContent?.creator?.role,
            publishedDate: new Date(
              parseInt(userContent?.publishedAt || userContent?.createdAt, 10) *
                1000
            ),
          },
        });

        setCreateInfoFormState({
          ...createInfoFormState,
          title: userContent.title,
          industriesList: createInfoFormState.industriesList.map(el => {
            if (userIndustries.includes(el.label)) {
              return {
                ...el,
                isSelected: true,
              };
            }
            return el;
          }),
          assetTypesList: createInfoFormState.assetTypesList.map(el => {
            if (el.id === userAssetType) {
              return {
                ...el,
                isSelected: true,
              };
            }
            return el;
          }),
          toolsList: createInfoFormState.toolsList.map(el => {
            if (userTools.includes(el.name)) {
              return {
                ...el,
                isSelected: true,
              };
            }
            return el;
          }),
          selectedAssetType: userAssetType?.toString(),
          duration: userContent?.tutorial_duration,
          selectedLevel:
            createInfoFormState.levelOptions.find(
              el => el.name === userContent?.tutorial_level?.name
            )?.value || null,
          selectedTutorialType:
            createInfoFormState.tutorialTypeOptions.find(
              el => el.name === userContent?.tutorial_type?.name
            )?.value || null,
        });
      }
    }
  }, [createState.isDataSet]);

  useEffect(() => {
    if (createState.isDataSet) {
      const { userContent } = data;

      const userImageBlockList = userContent.blocs
        .filter(el => el.type === 'image')
        .map(el => ({
          ...el,
          id: el.id,
          data: {
            data_url: validImageUrl(el.image),
            file: {},
          },
        }));

      if (userImageBlockList && userBlocks && userImagesBlocks) {
        if (data?.userContent.image) {
          convertImage(
            { data_url: validImageUrl(data?.userContent.image.path) },
            'cover'
          ).then(res => {
            const imageBlockList = [
              ...createState.imageBlockList,
              { data: res },
            ];

            const mergedImages = [...userImagesBlocks, ...imageBlockList];
            const coverImagesBlockList = mergedImages.filter(el =>
              VALID_COVER_IMAGE_FORMATS.includes(el.data.file?.type)
            );

            setCreateState({
              ...createState,
              editPreviousCoverSelected: !!data?.userContent.image,
              imageBlockList: mergedImages,
              coverImagesBlockList,
              blockList: [...userBlocks, ...userImagesBlocks].sort((a, b) =>
                a.order > b.order ? 1 : -1
              ),
              createType: data?.userContent?.userContentType,
            });
          });
        } else {
          const imageBlockList = [...createState.imageBlockList];
          const mergedImages = [...userImagesBlocks, ...imageBlockList];
          const coverImagesBlockList = mergedImages.filter(el =>
            VALID_COVER_IMAGE_FORMATS.includes(el.data.file.type)
          );

          setCreateState({
            ...createState,
            editPreviousCoverSelected: !!data?.userContent.image,
            imageBlockList: mergedImages,
            coverImagesBlockList,
            blockList: [...userBlocks, ...userImagesBlocks].sort((a, b) =>
              a.order > b.order ? 1 : -1
            ),
            createType: data?.userContent?.userContentType,
          });
        }
      }
    }
  }, [userImagesBlocks, data]);

  useEffect(() => {
    if (createState.coverImagesBlockList.length > 0) {
      setCreateState({
        ...createState,
        currentImageCropIndex: createState.coverImagesBlockList.length - 1,
      });
    }
  }, [createState.coverImagesBlockList]);

  const onAssetChange = asset => {
    const blockId = createState?.blockList[0]?.id;

    const newBlock = {
      type: 'asset',
      slug: 'block-0',
      order: 0,
      asset: {
        file: asset.file,
        title: asset.file.name,
      },
    };

    setCreateState({
      ...createState,
      assetBlockHasError: asset.hasError,
    });

    if (!asset.hasError && blockId) {
      updateUserContent({
        variables: {
          id: postIdToEdit,
          input: {
            status: 'draft',
            blocs: [
              {
                ...newBlock,
                id: blockId,
              },
            ],
          },
        },
      });
    }
  };

  const addBlock = block => {
    onAddBlock(
      block,
      createState,
      setCreateState,
      createState.imageBlockList[createState.imageBlockList.length - 1]
    );

    const newBlock =
      block.type === 'text'
        ? {
            ...block,
            text: '<p></p>',
          }
        : block;

    updateUserContent({
      variables: {
        id: postIdToEdit,
        input: onUpdatePostToDataModel({ blocks: [newBlock] }),
      },
    });
  };

  const handleEditBlock = (index, value) => {
    let newBlockData = {};
    const blockId = createState?.blockList[index]?.id;
    const blockOrder = createState?.blockList[index]?.order;

    if (value?.type && value?.type === 'content') {
      const valueToDataModel = items =>
        items.map(item => ({
          id: item.id,
          order: item.order,
        }));

      newBlockData = {
        contents: valueToDataModel(value.value),
        order: blockOrder,
        text: value.text,
        id: blockId,
      };
    }

    if (!value?.type || (value?.type && value?.type !== 'content')) {
      onEditBlock(
        index,
        value,
        createState,
        setCreateState,
        createState.imageBlockList[createState.imageBlockList.length - 1]
      );
      newBlockData = {
        text: value,
        order: blockOrder,
        id: blockId,
      };
    }

    if (blockId !== undefined && blockOrder !== undefined) {
      updateUserContent({
        variables: {
          id: postIdToEdit,
          input: {
            blocs: [newBlockData],
          },
        },
      });
    }
  };

  const onPublish = type => {
    const finalData = createViewToDataModel({
      createInfoFormState,
      createState,
      createAdminFormState,
      thumbnailCropData: createState.createCover || thumbnailCropData,
      isSameCover: createState.editPreviousCoverSelected,
      isDraft: type === 'draft',
      isAdmin: showAdminSettings,
    });

    if (dataPublishCreate.called && type === 'draft') {
      delete finalData.status;
    }

    if (createState.editPreviousCoverSelected) {
      delete finalData.userInput.image;
    }

    publishCreate({
      variables: {
        id: postIdToEdit,
        input: finalData.userInput,
        admin_input: finalData.adminInput,
      },
    });
  };

  useEffect(() => {
    if (
      !(
        createState.blockList.length < 1 ||
        (!createState.createCover && !createState.editPreviousCoverSelected)
      )
    ) {
      setIsPublishBtnDisabled(false);
    } else {
      setIsPublishBtnDisabled(true);
    }
  }, [createState]);

  useEffect(() => {
    if (createState.createCover) {
      convertImage(
        { data_url: validImageUrl(data?.userContent?.image?.path) },
        'cover'
      );
    }
  }, [createState?.createCover]);

  useEffect(() => {
    if (data && data.userContent?.creator) {
      setCreatorData({
        name: `${data?.userContent.creator?.first_name} ${data?.userContent.creator?.last_name}`,
        profilePicture:
          data?.userContent?.creator?.userProfile?.profile_picture,
        title: data?.userContent.creator?.title,
      });
    }
  }, [data]);

  const currentStepName = createState.steps[createState.currentStep];
  const isLoading =
    dataRemoveUserContentBloc?.loading ||
    dataPublishCreate?.loading ||
    createInfoLoading ||
    loading;

  const {
    isNextDisabled,
    content: nextDisabledContent,
    emptyBlockIndexes,
  } = useCreateNextButtonValidation(
    createState,
    currentStepName,
    createInfoFormState,
    createAdminFormState,
    showAdminSettings,
    data?.userContent?.image && data?.userContent?.image !== null
  );

  return (
    <ProtectedRoute redirectTo="/login" userAccessReq={['auth', 'verified']}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <Layout location={props.location}>
        <CreatePageWrapper>
          <CreateForm
            pathname={props.location.pathname}
            modalData={modal}
            creatorData={creatorData}
            setModalData={setModalData}
            onAssetChange={onAssetChange}
            emptyBlockIndexes={emptyBlockIndexes}
            isNextBtnDisabled={
              isNextDisabled || dataUpdateUserContent?.loading || isLoading
            }
            nextDisabledContent={nextDisabledContent}
            editPreviousCoverSelected={createState.editPreviousCoverSelected}
            showAdminSettings={showAdminSettings}
            slug={params.slug}
            isLoading={isLoading}
            isEdit={isEdit}
            isDraft={isDraft}
            currentStepName={currentStepName}
            createState={createState}
            isAddingBlockDone={dataUpdateUserContent.loading}
            createAdminFormState={createAdminFormState}
            setCreateAdminFormState={setCreateAdminFormState}
            onEditUserContentblock={(i, v) => {
              onEditUserContentblock(i, v, createState, setCreateState);
              handleEditBlock(i, { type: 'content', ...v });
            }}
            onNavItemClick={idx =>
              onNavItemClick(
                idx,
                createState,
                createInfoFormState,
                createAdminFormState,
                setCreateState,
                setCreateInfoFormState,
                setCreateAdminFormState,
                showAdminSettings
              )
            }
            gotoPrevSection={() =>
              goToSection(
                'prev',
                createState,
                createInfoFormState,
                createAdminFormState,
                setCreateState,
                setCreateInfoFormState,
                setCreateAdminFormState,
                showAdminSettings
              )
            }
            gotoNextSection={() =>
              goToSection(
                'next',
                createState,
                createInfoFormState,
                createAdminFormState,
                setCreateState,
                setCreateInfoFormState,
                setCreateAdminFormState,
                showAdminSettings
              )
            }
            createInfoFormState={createInfoFormState}
            setCreateInfoFormState={setCreateInfoFormState}
            onAddBlock={blc => addBlock(blc)}
            onEditBlock={(idx, val) => handleEditBlock(idx, val)}
            onRemoveBlock={(idx, id) => {
              onRemoveBlock(
                idx,
                createState,
                setCreateState,
                createState.imageBlockList[
                  createState.imageBlockList.length - 1
                ]
              );
              if (id) {
                removeUserContentBloc({
                  variables: {
                    ids: [parseInt(id, 10)],
                  },
                });
              }
            }}
            onChangeBlockChangeOrder={(idx, dir) => {
              const reordredStateBlocks = onChangeBlockChangeOrder(
                idx,
                dir,
                createState.blockList
              );

              setCreateState({
                ...createState,
                blockList: reordredStateBlocks,
              });
              updateUserContent({
                variables: {
                  id: postIdToEdit,
                  input: {
                    blocs: reordredStateBlocks.map(el => ({
                      order: el.order,
                      id: el.id,
                      slug: el.slug,
                    })),
                  },
                },
              });
            }}
            onEditVideoBlock={(idx, blc) =>
              onEditVideoBlock(idx, blc, createState, setCreateState)
            }
            onEditSketchfabBlock={(idx, blc) =>
              onEditSketchfabBlock(idx, blc, createState, setCreateState)
            }
            thumbnailCropData={thumbnailCropData}
            setThumbnailCropsData={setThumbnailCropsData}
            setCreateState={setCreateState}
            postId={postIdToEdit}
            onReOrderBlocks={bl => {
              const reordredStateBlocks = onReOrderBlocks(bl);

              setCreateState({
                ...createState,
                blockList: reordredStateBlocks,
              });

              updateUserContent({
                variables: {
                  id: postIdToEdit,
                  input: {
                    blocs: reordredStateBlocks.map(el => ({
                      order: el.order,
                      id: el.id,
                      slug: el.slug,
                    })),
                  },
                },
              });
            }}
            onChangeImageCropIndex={(idx, isCover) => {
              onChangeImageCropIndex(idx, createState, setCreateState, isCover);
            }}
            addImageBlock={img =>
              addImageBlock(img, createState, setCreateState)
            }
            onPublish={onPublish}
            isPublishBtnDisabled={isPublishBtnDisabled}
            isNextButtonDisabled={
              (currentStepName === 'editor' &&
                createState.blockList.length === 0) ||
              (currentStepName === 'editor' &&
                createState.createType === 'artwork' &&
                createState.imageBlockList.length === 0)
            }
          />
        </CreatePageWrapper>
      </Layout>
    </ProtectedRoute>
  );
};

CreateEdit.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default CreateEdit;
