import React from 'react';
import Thumbnail from '@components/molecules/thumbnail/Thumbnail';
import { WrapperBlock } from './BlockUserContent.styled';
import { formatDate } from '@src/utils/formatDate';
import { monthsNames } from '@src/utils/monthNames';
import { useIsMobile } from '@src/utils/useIsMobile';
import { validURL } from '@src/utils/validURL';
import { Heading } from '@components/atoms/heading/Heading';

const BlockUserContent = props => {
  const { block, isAuthenticated } = props;

  const isMobile = useIsMobile();

  const blockContents = [...block?.contents].sort((a, b) => a.order - b.order);

  return (
    <>
      {props.title && (
        <Heading
          level="h3"
          css={{
            mb: '$24',
            textAlign: 'center',
            fontFamily: '$ArtifaktLegendBold',
          }}
        >
          {props.title}
        </Heading>
      )}
      <WrapperBlock>
        {blockContents.map(content => {
          const isMemberOf =
            content.userContentType === 'article' &&
            content.article_type?.key !== 'article';
          function dateString() {
            if (isMemberOf) {
              if (content.article_type?.key === 'motm') {
                return `${monthsNames[content.motm_month + 1]} ${
                  content.motm_year
                }`;
              }
              return content.motm_year;
            }
            return formatDate(content.createdAt);
          }
          const profilePicture = content.creator.userProfile.profile_picture;
          const hasProfilePicture = profilePicture && profilePicture !== null;
          const validProfilePicture = () => {
            if (hasProfilePicture) {
              if (validURL(profilePicture)) {
                return profilePicture;
              }
              return `${CDN_URL}${profilePicture}`;
            }
            return null;
          };

          return (
            <Thumbnail
              key={content.id}
              id={content.id}
              imageUrl={
                validURL(content.image.path)
                  ? content.image.path
                  : `${CDN_URL}${content.image.path}`
              }
              srcSet={content.image.sizes}
              css={{ flexBasis: '25%' }}
              type={isMemberOf ? 'ambassador' : content.userContentType}
              ambassadorTitle={content.motm_member?.title}
              datePosted={dateString()}
              showLikeButton={isAuthenticated}
              isLiked={content.isLiked}
              ambassadorType={isMemberOf && content.article_type?.key}
              urlTarget="_blank"
              authoring={{
                profilePicture: validProfilePicture(),
                author: {
                  name: `${content.creator.first_name} ${content.creator.last_name}`,
                  slug: content.creator.username,
                },
              }}
              badgeList={content.tools.map(tool => ({
                imageDynamic: {
                  src: tool.icon,
                  srcset: `${tool.icon} 1x, ${tool.icon} 250w, ${tool.icon} 500w`,
                },
                alt: tool.title,

                infoboxActive: false,
              }))}
              title={content.title}
              tutorialLevel={content?.tutorial_level?.name.toLowerCase()}
              isMini={window.innerWidth >= 1200 && !isMobile}
              url={`${FE_URL}${
                content?.article_section?.name
                  ? `/${content?.article_section?.name.toLowerCase()}/articles/${
                      content.slug
                    }`
                  : `/m/${content.creator.username}/${content.userContentType}s/${content.slug}`
              }`}
            />
          );
        })}
      </WrapperBlock>
    </>
  );
};

export default BlockUserContent;
