import React, { useContext } from 'react';

import GetUrl from '@components/atoms/getUrl/GetUrl';
import SidebarMenu from '@components/molecules/sidebarMenu/SidebarMenu';
import UrlsContext from '@context/UrlsContext';

const AdminSidebar = props => {
  const UrlContext = useContext(UrlsContext);

  const data = {
    title: '',
    activeTab: '',
    nav: [
      {
        label: 'Stats',
        url: GetUrl('adminDashboard', UrlContext),
        svgIcon: 'dashboard',
        subMenu: [
          {
            label: 'AREA Activity Report',
            url: GetUrl('adminDashboard', UrlContext),
          },
          {
            label: 'Members',
            url: GetUrl('adminStatsMembers', UrlContext),
          },
          {
            label: 'Content',
            url: GetUrl('adminStatsContent', UrlContext),
          },
          {
            label: 'Community',
            url: GetUrl('adminStatsCommunity', UrlContext),
          },
        ],
      },
      {
        label: 'Content',
        url: GetUrl('adminAreaCreateContent', UrlContext),
        svgIcon: 'thumbnail-series',
      },
      ...(props.adminRole === 'admin') ? [
        {
          label: 'Members',
          url: GetUrl('adminMembers', UrlContext),
          svgIcon: 'thumbnail-level',
        }
      ] : [],

      ...(props.adminRole === 'admin' || props.adminRole === 'editor') ? [
        {
          label: 'Community',
          url: '',
          svgIcon: 'group-users',
          subMenu: [
            {
              label: 'All Groups',
              url: GetUrl('adminCommunityGroupsAllGroups', UrlContext),
            },
            {
              label: 'All Posts',
              url: GetUrl('adminCommunityGroupsAllPosts', UrlContext),
            },
          ],
        },
        {
          label: 'Categories',
          url: '',
          svgIcon: 'dashboard',
          subMenu: [
            {
              label: 'Tools',
              url: GetUrl('adminContentTools', UrlContext),
            },
            {
              label: 'Industries',
              url: GetUrl('adminContentIndustries', UrlContext),
            },
            // {
            //   label: 'Tags',
            //   url: GetUrl('adminContentTags', UrlContext),
            // },
          ],
        },
        {
          label: 'Landing Pages',
          url: '',
          svgIcon: 'internet',
          subMenu: [
            {
              label: 'Homepage (Member)',
              url: GetUrl('adminHomepageSignedIn', UrlContext),
            },
            {
              label: 'Homepage (Visitors)',
              url: GetUrl('adminHomepageNotSignedIn', UrlContext),
            },
            {
              label: 'Learn',
              url: GetUrl('adminContentPagesLearn', UrlContext),
            },
            {
              label: 'Inspire',
              url: GetUrl('adminContentPagesInspire', UrlContext),
            },
            {
              label: 'About AREA',
              url: GetUrl('adminContentPagesCommunityGuidelines', UrlContext),
            },
            {
              label: 'Legal',
              url: GetUrl('adminContentPagesLegal', UrlContext),
            },
          ],
        },
        {
          label: 'Sections',
          url: '',
          svgIcon: 'internet',
          subMenu: [
            {
              label: 'Inspire / Articles',
              url: GetUrl('adminContentPagesInspireArticles', UrlContext),
            },
            {
              label: 'Inspire / Gallery',
              url: GetUrl('adminContentPagesInspireGallery', UrlContext),
            },
            {
              label: 'Inspire / Member spotlight',
              url: GetUrl(
                'adminContentPagesInspireMembersofthemonth',
                UrlContext
              ),
            },
            {
              label: 'Inspire / Member of the year',
              url: GetUrl(
                'adminContentPagesInspireMembersoftheyear',
                UrlContext
              ),
            },
            {
              label: 'Learn / Assets',
              url: GetUrl('adminContentPagesLearnAssets', UrlContext),
            },
            {
              label: 'Learn / Tutorials',
              url: GetUrl('adminContentPagesLearnTutorials', UrlContext),
            },
          ],
        },
        {
          label: 'Reports',
          url: '',
          svgIcon: 'warning',
          subMenu: [
            {
              label: 'Community',
              url: GetUrl('/admin-cms/all-reports/posts', UrlContext),
              noticeLabel: '3 New',
            },
            {
              label: 'Members',
              url: GetUrl('/admin-cms/all-reports/users', UrlContext),
              noticeLabel: '3 New',
            },
            {
              label: 'Content',
              url: GetUrl('/admin-cms/all-reports/user-contents', UrlContext),
              noticeLabel: '3 New',
            },
          ],
        },
      ] : [],

      ...(props.adminRole === 'admin') ? [
        // {
        //   label: 'Support',
        //   url: GetUrl('adminSupport', UrlContext),
        //   svgIcon: 'help',
        // },
        {
          label: 'Settings',
          url: GetUrl('adminSettings', UrlContext),
          svgIcon: 'settings',
        }
      ] : [],
    ],
  };
  
  return (
    <SidebarMenu
      css={{ 
        maxWidth: props.isMobile ? '100%' : '280px',
        marginBottom: props.isMobile ? '$32' : '0',
      }}
      {...data}
      isMobile={props.isMobile}
      location={location}
      title={'Admin CMS'}
    />
  );
};

export default React.memo(AdminSidebar, (prevProps, nextProps) => {
  return false;
});
