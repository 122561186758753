import { GET_ARTICLES, GET_ARTWORKS, GET_TUTORIALS } from '@src/apollo/query';
import {
  Intro,
  ThumbnailSliderStyled,
  Title,
  WrapperHeading,
} from '../Search.styled';
import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '@src/context/AuthContext';
import GetUrl from '@components/atoms/getUrl/GetUrl';
import Loader from '@components/atoms/loader/Loader';
import SearchContext from '@context/SearchContext';
import UrlsContext from '@context/UrlsContext';
import { dataToThumbnailViewModel } from '@src/utils/dataToThumbnailViewModel';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/client';
import String from '@components/atoms/string/String';

const MultipleContent = ({ filters, isRecommendedSearch }) => {
  const UrlContext = useContext(UrlsContext);
  const authContext = useContext(AuthContext);

  const { data: dataArtworks, loading: loadingArtworks } = useQuery(
    GET_ARTWORKS,
    {
      context: { clientName: 'public' },
      variables: {
        limit: 4,
      },
    }
  );

  const { data: dataArticles, loading: loadingArticles } = useQuery(
    GET_ARTICLES,
    {
      context: { clientName: 'public' },
      variables: {
        limit: 4,
      },
    }
  );

  const { data: dataTutorials, loading: loadingTutorials } = useQuery(
    GET_TUTORIALS,
    {
      context: { clientName: 'public' },
      variables: {
        contentType: 'tutorial',
        limit: 4,
      },
    }
  );

  return (
    <>
      {!isRecommendedSearch && (
        <WrapperHeading>
          <Title level="h1">Start your research now!</Title>
          <Intro level="h2">
            <String id="search_intro" />
          </Intro>
        </WrapperHeading>
      )}
      <Loader
        isSection={true}
        isLoading={loadingArtworks || loadingArticles || loadingTutorials}
      />
      {!loadingArtworks && (
        <ThumbnailSliderStyled
          title="artworks"
          thumbnailList={dataArtworks?.userContents?.data?.map(el =>
            dataToThumbnailViewModel(el, false, authContext.isAuthenticated)
          )}
          type="artworks"
          isInternalLink={false}
          onClick={() => navigate(GetUrl('inspireGallery', UrlContext))}
        />
      )}
      {!loadingArticles && (
        <ThumbnailSliderStyled
          title="articles"
          thumbnailList={dataArticles?.userContents?.data?.map(el =>
            dataToThumbnailViewModel(el, false, authContext.isAuthenticated)
          )}
          type="articles"
          isInternalLink={false}
          onClick={() => navigate(GetUrl('inspireArticles', UrlContext))}
        />
      )}
      {!loadingTutorials && (
        <ThumbnailSliderStyled
          title="tutorials"
          thumbnailList={dataTutorials?.userContents?.data?.map(el =>
            dataToThumbnailViewModel(el, false, authContext.isAuthenticated)
          )}
          type="tutorials"
          isInternalLink={false}
          onClick={() => navigate(GetUrl('learnTutorials', UrlContext))}
        />
      )}
    </>
  );
};

export default MultipleContent;
