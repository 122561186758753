import React from 'react';
import { StyledSpinner } from './LoaderSpinner.styled';

import PropTypes from 'prop-types';

const LoaderSpinner = ({ size, stroke, css }) => {
  return (
    <StyledSpinner
      viewBox="0 0 50 50"
      width={`${size}px`}
      height={`${size}px`}
      className="button__loader"
      css={{css}}
    >
      <circle
        className="spinner_bg"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth={parseInt(stroke, 10)}
      ></circle>
      <circle
        className="spinner_path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth={parseInt(stroke, 10)}
      ></circle>
    </StyledSpinner>
  );
};

export default LoaderSpinner;

LoaderSpinner.propTypes = {
  size: PropTypes.number,
  stroke: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  css: PropTypes.object,
};

LoaderSpinner.defaultProps = {
  size: 16,
  stroke: 2,
  css: {}
};
