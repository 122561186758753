import { styled } from '@root/stitches.config';

export const AddImageContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '$24',
});

export const AddImageContainer = styled('div', {
  width: 'auto',
  paddingTop: '$32',
  '@bp2': {
    width: '100%',
    minHeight: 0,
    paddingTop: 0,
  },
});
