import { styled } from '@root/stitches.config';
import { Text } from '@components/atoms/text/Text';

export const ChangePasswordPageWrapper = styled('div', {
  padding: '$32 $16',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  maxWidth: '420px',
});

export const ChangePasswordBoxWrapper = styled('div', {
  //padding: '$32 $16',
  display: 'block',
});

export const FormStyled = styled('form', {
  // maxWidth: '500px',
  // width: '100%',
  // '@bp2': {
  //   width: '600px',
  // },
});

export const SignAsideTitleLabel = styled(Text, {
  fontSize: '$18',
  color: '$ultraLightGrey',
  letterSpacing: '1.4px',
  textTransform: 'uppercase',
  fontFamily: 'var(--ArtifaktElementBold)',
  mb: '$24',
});

export const SuccessBox = styled('div', {
  maxWidth: '600px',
  width: '100%',
  '@bp2': {
    maxWidth: '600px',
  },
});

export const InputWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const ThankYouWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  ai: 'center',
  padding: '$72 $16',
  maxWidth: '420px',
});
export const ErrorMsg = styled('p', {
  marginBottom: '$24',
  color: '$red',
  fontSize: '$12',
  textAlign: 'left',
});
